/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import ruleTypes from './constants';
import PanelContext from 'State/panelContext';
import { analyzeValue } from 'Components/Form/Validator';
import Alert from 'Components/Alert';
import Wrapper from 'Components/Sections/Wrapper';
import withRequest from 'Components/Sections/withRequest';
import Grid from 'Components/Grid';
import Form, { FormNew, FormError } from 'Components/Form';
import Skeleton from 'Components/Skeletons';
import { exampleTextForConversionValue, getOccupancy, occupancyForSummary, formatDatesForSummary, formatHotelFilterForSummary } from './helpers';
import { applyToDefaultOption, rangeMode, countriesMode, rulesFilterConfigurations, display } from 'Constants/global';
import TranslationList from './Translations';
import Button from 'Components/Button';
import { transformCamelToSpacesOnlyFirstCapital } from 'Utils/text';
import convertRanges, { getConversionsInitialValues, getUsedBalanceTypesIds, convertBalanceTypes as initializeBalanceTypes } from './dynamic-ranges/utils';
import Summary from './dynamic-ranges/Summary';
import DynamicRangeEditor from './dynamic-ranges/DynamicRangeEditor';

const CONVERSION_MODES = ['Simple', 'Incremental'];

// Properties used in the hotels and hotel types inputs.
const ALL = 'all';
const FILTER = 'filter';

const typeList = 'List';
const typeString = 'String';
const typeInt = 'Integer';
const typeFloat = 'Float';
const typeDate = 'Date';
const typeBoolean = 'Bool';
const BETWEEN_OPERATOR = 'between';

function convertBalanceTypes(types) {
    if (!types || !types.length) {
        return [];
    }

    return types.map((balanceType) => ({
        content  : balanceType.name,
        value    : { id: balanceType.id, name: balanceType.name },
        key      : `bt_${balanceType.id}`,
        currency : balanceType.currency,
    }));
}

function getInitialExtraAttributesState(attributes, availableEntities, extraAttributes) {
    const conf = {
        List    : 'valueSelect',
        String  : 'valueString',
        Integer : 'valueInt',
        Float   : 'valueInt',
        Date    : 'valueDate',
        Bool    : 'valueBoolean',
    };
    const entitiesMap = availableEntities?.reduce((map, key) => {
        const filteredAttributes = attributes?.filter((e) => e.category === key) || [];

        return {
            ...map,
            [key]: {
                isEnabled: false,
                key,

                list: [
                    {
                        name       : null,
                        operator   : null,
                        value      : null,
                        id         : (filteredAttributes?.length || 0) + 1,
                        isTemplate : true,
                        isValid    : false,
                    },
                    ...(filteredAttributes
                        ?.map((e, index) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                                const original = { ...e };
                                const isDateRange = e.dataType === 'Date' && e.operator === BETWEEN_OPERATOR;
                                const valueKey = isDateRange ? 'valueRange' : conf[e.dataType];
                                let val;
                                switch (e.dataType) {
                                    case typeBoolean:
                                    case typeList:
                                        [val] = e.values;
                                        break;
                                    case typeDate:
                                        val = isDateRange ? { begin: e.values?.[0], end: e.values?.[1] } : e.values?.[0];
                                        break;
                                    default:
                                        [val] = e.values;
                                        break;
                                }
                                original[valueKey] = val;

                                const foundAttribute = extraAttributes[e.category]?.find((a) => a.slug === e.key);
                                const { id, slug } = foundAttribute || { id: e.key, slug: e.key };

                                return {
                                    ...original,
                                    slug,
                                    name         : id,
                                    value        : e.values,
                                    percentage   : String(e.percentage),
                                    label        : e.label,
                                    id           : index + 1,
                                    isTemplate   : false,
                                    // customOption is used for the extraAttributes that have been disabled, so the 'name' has the option
                                    customOption : foundAttribute ? [] : [{ ...e, content: e.name, key: slug, value: e.key }],
                                };
                            }
                        })
                        .sort((first, second) => Number(first.percentage) - Number(second.percentage)) || []),
                ],
                lastId  : filteredAttributes.length + 2,
                changed : false,
            },
        };
    }, {});

    if (attributes?.length) {
        attributes.forEach((e) => {
            if (e.category && entitiesMap[e.category]) {
                entitiesMap[e.category].isEnabled = true;
                entitiesMap[e.category].list.push(e);
            }
        });
    }

    return {
        entities  : entitiesMap,
        isEnabled : !!availableEntities?.length,
    };
}

function getExtraAttributeEntityLabel(key, intl) {
    return (
        intl.formatMessage({
            id             : translated.rules.extraAttributes.entities[key],
            defaultMessage : translated.rules.extraAttributes.entities[key],
        }) || key
    );
}

const getAvailableExtraAttributes = (list) => {
    const result = {};

    // eslint-disable-next-line no-unused-expressions
    list?.forEach((eachAttribute) => {
        // eslint-disable-next-line no-unused-expressions
        eachAttribute.categories?.forEach((eachCategory) => {
            const category = result[eachCategory] || [];

            category.push({ ...eachAttribute, content: eachAttribute.name, value: eachAttribute.id });

            result[eachCategory] = category;
        });
    });

    Object.values(result).sort((first, second) => (first.name < second.name ? -1 : 1));

    return result;
};

const getExtraAttributesEntities = (list) => {
    const uniqueEntities = new Set();
    if (list && list.length) {
        list.forEach((e) => {
            if (e.categories?.length) {
                e.categories.forEach((eachCategory) => {
                    uniqueEntities.add(eachCategory);
                });
            }
        });
    }

    return [...uniqueEntities].sort();
};

const getExtraAttributesOptions = (list) => {
    const result = {};

    if (list && list.length) {
        list.forEach((eachExtraAttribute) => {
            if (eachExtraAttribute.options?.length && eachExtraAttribute.categories?.length) {
                eachExtraAttribute.categories.forEach((eachCategory) => {
                    result[eachCategory + eachExtraAttribute.id] = eachExtraAttribute.options;
                });
            }
        });
    }

    return result;
};

const convertLoadedExtraAttributes = (elements) => {
    const result = [];

    Object.values(elements.entities).forEach((entity) => {
        // eslint-disable-next-line no-unused-expressions
        entity.list
            ?.filter((e) => (e.slug || e.operator) && e.id && !e.isTemplate)
            ?.forEach((eachAttribute) => {
                const values = eachAttribute.value?.filter((e) => e !== null);

                result.push({
                    key      : eachAttribute.slug,
                    category : entity.key,
                    dataType : eachAttribute.dataType,
                    operator : eachAttribute.operator,
                    values,
                });
            });
    });

    return result;
};

const MIN_AMOUNT_NIGHT = 'night';
const MIN_AMOUNT_RESERVATION = 'reservation';

const convertNumberFields = (numberValue) => (numberValue ? Number(numberValue) : numberValue);

function Editor({
    data, isEditing, name: sectionLabel, resources, arePriceRulesEnabled, arePointRulesEnabled, isSystemRule, navigateToParent,
}) {
    const intl = useIntl();
    const { snackbar, confirmation, navigator, dateManager } = useContext(PanelContext);
    const { additional, available } = resources;

    const isReadOnly = !(available?.create || available?.update);
    // DropDowns information
    // Assigning the vars with the deconstruction of data.additionalResources breaks the site
    // when data is not loaded (for instance, after the rule submit).

    const availableBalanceTypes = convertBalanceTypes(additional?.balanceTypes || []);
    const availableCurrencies = useMemo(() => (additional?.currencies || []), [additional?.currencies]);
    const availableLanguages = additional?.languages || [];
    const availableApplyTo = additional?.applyTo || [];
    const availableCountries = additional?.countries || [];
    const availableViews = additional?.views || [];
    const availableConversionModes = CONVERSION_MODES;

    // Its an object with the entity as key, and as value the list of extra attributes. For instance { Client: [EXTRA_ATTRIBUTES] }
    const availableExtraAttributes = getAvailableExtraAttributes(additional?.extraAttributes);
    // Its the list of available attributes entities. For instance: [Client, User ...]
    const extraAttributesEntities = getExtraAttributesEntities(additional?.extraAttributes);
    // Its an object with the attributes lists options. For instance { Client1: ['A', 'A+' ...]}
    const extraAttributesOptions = getExtraAttributesOptions(additional?.extraAttributes);
    // Its an object with the operation valid for each attribute type
    const extraAttributesOperations = additional?.extraAttributesDataTypes?.reduce(
        (acc, current) => ({ ...acc, [current.type]: current.operators?.map((e) => ({ value: e, content: e })) }),
        {},
    );

    // The conversionType is used to determine which inputs we need to show, like the Property Filters
    const [conversionType, setConversionType] = useState(data.pricingType || ruleTypes.FIXED_NIGHT);
    const [ruleType, setRuleType] = useState(data.type || (!isSystemRule && arePriceRulesEnabled && ruleTypes.PRICING) || ruleTypes.EXCEPTION);
    const [isDynamicRangesEditorOpen, setDynamicRangesEditorOpen] = useState(false);
    const [ranges, setRanges] = useState(data?.ranges || []);
    const [conversionMode, setConversionMode] = useState(data?.conversionMode || CONVERSION_MODES[0]);
    // Used to force the form to acknowledge the changes in some inputs.
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const dynamicSummaryRef = useRef();

    const conversionModeOptions = useMemo(() => availableConversionModes?.map((mode) => ({
        label : translated.rules.dynamicRanges.conversionRates.modes[mode],
        value : mode,
    }) || []), [availableConversionModes]);

    const isFixedPerNight = conversionType === ruleTypes.FIXED_NIGHT;
    const isFixedOnTotal = conversionType === ruleTypes.FIXED_TOTAL;
    const isPercentage = conversionType === ruleTypes.RELATIVE;

    const openDynamicRangeEditor = useCallback(() => {
        setDynamicRangesEditorOpen(true);
    }, []);

    const closeDynamicRangeEditor = useCallback(() => {
        setDynamicRangesEditorOpen(false);
    }, []);

    const handleSaveDynamicRanges = useCallback((dynamicRanges) => {
        setRanges(dynamicRanges.convertedRanges);
        setConversionMode(dynamicRanges.conversionMode);

        dynamicSummaryRef.current.reset({
            ranges                : dynamicRanges.ranges,
            conversions           : dynamicRanges.conversions,
            balanceTypes          : dynamicRanges.balanceTypes,
            availableBalanceTypes : dynamicRanges.availableBalanceTypes,
        });
        closeDynamicRangeEditor();

        setHasFormChanged(true);
    }, [closeDynamicRangeEditor]);

    // conversionValue replaces the key 'conversionValue' in the Form, which has a 'avoidOnSubmit'
    let initialConversionValue;
    let initialOccupancy = { value: undefined, condition: 'More', summary: null };
    const amountFloat = parseFloat(data.amount);

    if (isEditing && !isReadOnly) {
        initialConversionValue = isPercentage ? (Math.abs(amountFloat) * 100).toFixed(2) : Math.abs(amountFloat);
        const formattedConditionAndValue = getOccupancy(data.minOccupancy, data.maxOccupancy);

        if (formattedConditionAndValue) {
            initialOccupancy = occupancyForSummary(formattedConditionAndValue);
        }
    }

    const [conversionValue, setConversionValue] = useState({
        selectedOption : amountFloat > 0 ? 'Plus' : 'Less',
        inputValue     : initialConversionValue,
    });

    // Occupancy initialization
    const [isOccupancyDisabled, setIsOccupancyDisabled] = useState(!initialOccupancy.value);
    const [occupancy, setOccupancy] = useState(initialOccupancy);
    const minOccupancyAccordingToCondition = occupancy.condition === 'Less' ? 2 : 1;
    const [isCountriesDisabled, setIsCountriesDisabled] = useState(!data.countriesMode || data.countriesMode === countriesMode.ALWAYS);

    const minPerNight = convertNumberFields(data.minAmountPerNight || 0);
    const minPerReservation = convertNumberFields(data.minAmountPerReservation || 0);
    const [isFiatMinAmountDisabled, setIsFiatMinAmountDisabled] = useState(minPerNight === 0 && minPerReservation === 0);

    const [propertiesModes, setPropertiesModes] = useState(isEditing && (!!data?.properties?.length || !!data?.propertyGroups?.length) ? FILTER : ALL);

    const [isTravelWindowModeAlways, setIsTravelWindowModeAlways] = useState(isEditing ? data.travelWindowMode === rangeMode.ALWAYS : true);
    const [isBookingWindowModeAlways, setIsBookingWindowModeAlways] = useState(isEditing ? data.bookingWindowMode === rangeMode.ALWAYS : true);

    const [translations, setTranslations] = useState(
        data?.translations
            ? data.translations.map((e) => {
                const key = Object.keys(e)?.[0];

                return {
                    id           : key,
                    language     : key,
                    title        : e[key]?.title,
                    description  : e[key]?.description,
                    languageName : availableLanguages.find((eachAvailableLanguage) => eachAvailableLanguage.id === key)?.name,
                };
            })
            : [],
    );
    const [translationsChanged, setTranslationsChanged] = useState(false);
    const [displayIn, setDisplayIn] = useState(data?.configuration?.displayMode || display.ALL);

    const [extraAttributes, setExtraAttributes] = useState(
        getInitialExtraAttributesState(data?.extraAttributes, extraAttributesEntities, availableExtraAttributes),
    );

    const buildURL = (propertyGroups) => {
        if (propertyGroups) {
            const { propertyGroups: field } = additional.hotel.fields;
            const params = propertyGroups.map((a) => a.id).join(`&${field}`);
            const url = `${additional.hotel.query.url}${field}${params}&`;
            return {
                ...additional.hotel,
                query: {
                    ...additional.hotel.query,
                    url,
                },
            };
        }
        return null;
    };
    const [filterPropertiesURL, setFilterPropertiesURL] = useState(buildURL(data.propertyGroups));
    const [propertiesOriginalURL] = useState(additional?.hotel?.query?.url || '');
    const [selectedPropertyGroups, setSelectedPropertyGroups] = useState(data.propertyGroups || []);
    const extraAttributeRangeMinDate = dateManager.format('1900-01-01', 'yyyy-MM-dd');
    const extraAttributeRangeMaxDate = dateManager.format('2100-01-01', 'yyyy-MM-dd');

    const onExtraAttributeEntityChange = (newValues) => {
        setExtraAttributes((prev) => {
            const newEntities = { ...prev.entities };

            Object.keys(prev.entities).forEach((eachKey) => {
                newEntities[eachKey].isEnabled = !!newValues.find((e) => e.id === eachKey);
            });

            return {
                ...prev,
                entities: newEntities,
            };
        });
    };

    const removeExtraAttributeEntity = (entity) => {
        setExtraAttributes((prev) => {
            const entities = { ...prev.entities };

            const element = entities[entity];
            element.list = element.list.filter((e) => e.name === null);

            return {
                ...prev,
                entities,
            };
        });
    };

    const addExtraAttribute = (entityKey) => {
        setExtraAttributes((prev) => {
            const entityPrevData = prev.entities[entityKey];

            return {
                ...prev,
                entities: {
                    ...prev.entities,
                    [entityKey]: {
                        ...entityPrevData,
                        list    : [{ id: entityPrevData.lastId, isTemplate: true }, ...entityPrevData.list.map((e) => ({ ...e, isTemplate: false }))],
                        lastId  : entityPrevData.lastId + 1,
                        changed : true,
                    },
                },
            };
        });
    };

    const removeExtraAttribute = (entityKey, elementId) => {
        setExtraAttributes((prev) => {
            const entityPrevData = prev.entities[entityKey];

            return {
                ...prev,
                entities: {
                    ...prev.entities,
                    [entityKey]: {
                        ...entityPrevData,
                        list    : entityPrevData.list?.filter((e) => e.id !== elementId),
                        changed : true,
                    },
                },
            };
        });
    };

    const onExtraAttributeChange = useCallback(
        (entityKey, elementId, attribute, newValue) => {
            setExtraAttributes((prev) => {
                const entityPrevData = prev.entities[entityKey];
                const usedAttribute = additional?.extraAttributes?.find((e) => e.id === newValue);

                const updatedElement = {
                    ...(entityPrevData.list.find((e) => e.id === elementId) || {}),
                    [attribute]: newValue,
                };

                if (attribute === 'name') {
                    // When the input is changed and the dataType changes, we update the input dataType.
                    if (updatedElement.dataType !== usedAttribute?.dataType) {
                        updatedElement.dataType = usedAttribute?.dataType;
                        updatedElement.valueSelect = '';
                        updatedElement.valueString = '';
                        updatedElement.valueInt = '';
                        updatedElement.valueDate = '';
                        updatedElement.valueRange = '';
                        updatedElement.valueBoolean = false;
                        updatedElement.value = usedAttribute?.dataType === typeBoolean ? [false] : '';
                        updatedElement.operator = '';
                    }
                    updatedElement.slug = usedAttribute?.slug;
                }

                if (attribute.indexOf('value') > -1) {
                    // The value is changed.
                    updatedElement.value = [newValue];

                    if (attribute === 'valueRange') {
                        updatedElement.value = [newValue?.begin, newValue?.end];
                    }

                    if (attribute === 'valueInt') {
                        updatedElement.value = usedAttribute?.dataType === typeInt ? [+newValue] : [parseFloat(newValue)];
                    }
                }

                return {
                    ...prev,
                    entities: {
                        ...prev.entities,
                        [entityKey]: {
                            ...entityPrevData,
                            list: entityPrevData.list.map((e) => (e.id === elementId ? updatedElement : e)),
                        },
                    },
                };
            });
        },
        [additional],
    );

    const getExtraAttributesLists = useCallback(() => {
        if (!extraAttributes.isEnabled) {
            return null;
        }

        const list = [];

        // eslint-disable-next-line no-unused-expressions
        Object.values(extraAttributes.entities)
            ?.filter((e) => e.isEnabled)
            .forEach((e) => {
                list.push(<Form.Title className="margin-left-xxlarge margin-top-medium">{e.key}</Form.Title>);
                list.push(
                    <Form.Input
                        // Flag used to identify when the block list changed.
                        type="hidden"
                        submitKey={`extra-attributes-${e.key}-changed`}
                        avoidOnSubmit
                        markAsChanged
                        value={e.changed}
                    />,
                );

                let entityExtraAttributes = e?.list || [];
                entityExtraAttributes = entityExtraAttributes.filter((entity) => !!entity.id);

                entityExtraAttributes.forEach(
                    ({
                        name,
                        operator,
                        valueSelect,
                        valueString,
                        valueInt,
                        valueDate,
                        valueRange,
                        valueBoolean,
                        value,
                        id,
                        isTemplate,
                        isValid,
                        dataType,
                        customOption,
                    }) => {
                        list.push(
                            <Form.Column className="form-column-group is-extra-attributes">
                                <Form.Input
                                    type="select"
                                    id={`${e.key}-name-${id}`}
                                    submitKey={`${e.key}-name-${id}`}
                                    label={translated.rules.extraAttributes.name}
                                    options={availableExtraAttributes[e.key] ? [...availableExtraAttributes[e.key], ...(customOption || [])] : []}
                                    className="margin-left-large"
                                    onChange={(newValue) => {
                                        onExtraAttributeChange(e.key, id, 'name', newValue);
                                    }}
                                    value={name}
                                    isRequired={!isTemplate && operator}
                                    isDense
                                    avoidOnSubmit
                                    avoidOnSummary
                                    isBasedOnState
                                    isDisabled={!!customOption?.length}
                                />
                                <Form.Input
                                    type="select"
                                    id={`${e.key}-operation-${id}`}
                                    submitKey={`${e.key}-operation-${id}`}
                                    label={translated.rules.extraAttributes.operator}
                                    options={(name && extraAttributesOperations[dataType]) || []}
                                    className="margin-left-xsmall"
                                    onChange={(newValue) => {
                                        onExtraAttributeChange(e.key, id, 'operator', newValue);
                                    }}
                                    value={operator}
                                    isRequired={!isTemplate && !!name}
                                    isDense
                                    avoidOnSubmit
                                    avoidOnSummary
                                    isBasedOnState
                                    isDisabled={!!customOption?.length}
                                />
                                {dataType === typeList && (
                                    <Form.Input
                                        type="select"
                                        id={`${e.key}-value-select-${id}`}
                                        submitKey={`${e.key}-value-select-${id}`}
                                        label={translated.rules.extraAttributes.value}
                                        options={
                                            (name
                                                && extraAttributesOptions[e.key + name]?.map((eachOption) => ({ content: eachOption, value: eachOption })))
                                            || []
                                        }
                                        className="margin-left-xsmall"
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueSelect', newValue);
                                        }}
                                        isRequired={!isTemplate && name}
                                        value={valueSelect}
                                        isDense
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeInt && (
                                    <Form.Input
                                        type="number"
                                        id={`${e.key}-value-int-${id}`}
                                        submitKey={`${e.key}-value-int-${id}`}
                                        className="range-input margin-left-xsmall"
                                        validations={{ minValue: -999999999, maxValue: 999999999, unary: ['numbers'] }}
                                        label={translated.rules.extraAttributes.value}
                                        isDense
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueInt', newValue);
                                        }}
                                        value={valueInt}
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeFloat && (
                                    <Form.Input
                                        type="number"
                                        id={`${e.key}-value-float-${id}`}
                                        submitKey={`${e.key}-value-float-${id}`}
                                        className="range-input margin-left-xsmall"
                                        validations={{ minValue: -99999999, maxValue: 99999999, unary: ['float'] }}
                                        label={translated.rules.extraAttributes.value}
                                        isDense
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueInt', newValue);
                                        }}
                                        value={valueInt}
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeString && (
                                    <Form.Input
                                        type="text"
                                        id={`${e.key}-value-string-${id}`}
                                        submitKey={`${e.key}-value-string-${id}`}
                                        className="range-input margin-left-xsmall"
                                        validations={{ maxLength: 1000 }}
                                        label={translated.rules.extraAttributes.value}
                                        isDense
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueString', newValue);
                                        }}
                                        value={valueString}
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeBoolean && (
                                    <Form.Input
                                        type="checkbox"
                                        id={`${e.key}-value-boolean-${id}`}
                                        submitKey={`${e.key}-value-boolean-${id}`}
                                        className="range-input margin-left-xsmall"
                                        validations={{ maxLength: 1000 }}
                                        label={translated.rules.extraAttributes.value}
                                        isDense
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueBoolean', newValue);
                                        }}
                                        value={!!valueBoolean}
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeDate && operator !== BETWEEN_OPERATOR && (
                                    <Form.Input
                                        type="picker"
                                        id={`${e.key}-value-date-date-${id}`}
                                        submitKey={`${e.key}-value-date-date-${id}`}
                                        label={translated.rules.extraAttributes.value}
                                        icon={{ name: 'Calendar', position: 'right' }}
                                        minDate={extraAttributeRangeMinDate}
                                        maxDate={extraAttributeRangeMaxDate}
                                        minRangeLength={1}
                                        className="margin-left-xsmall range-input"
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueDate', newValue);
                                        }}
                                        value={valueDate}
                                        isDense
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                {dataType === typeDate && operator === BETWEEN_OPERATOR && (
                                    <Form.Input
                                        type="picker"
                                        isRange
                                        id={`${e.key}-value-date-range-${id}`}
                                        submitKey={`${e.key}-value-date-range-${id}`}
                                        label={translated.rules.extraAttributes.value}
                                        icon={{ name: 'Calendar', position: 'right' }}
                                        minDate={extraAttributeRangeMinDate}
                                        maxDate={extraAttributeRangeMaxDate}
                                        minRangeLength={1}
                                        className="margin-left-xsmall range-input"
                                        isRequired={!isTemplate && !!(operator || name)}
                                        onChange={(newValue) => {
                                            onExtraAttributeChange(e.key, id, 'valueRange', newValue);
                                        }}
                                        value={valueRange}
                                        isDense
                                        avoidOnSubmit
                                        avoidOnSummary
                                        isBasedOnState
                                        isDisabled={!!customOption?.length}
                                    />
                                )}
                                <Form.Custom>
                                    {!isTemplate && (
                                        <Button
                                            id={`${e.key}-${id}-remove-button`}
                                            className="margin-left-xsmall"
                                            icon="MinusCircle"
                                            onClick={() => removeExtraAttribute(e.key, id)}
                                            tooltip={intl.formatMessage({
                                                id             : translated.rules.extraAttributes.removeField,
                                                defaultMessage : translated.rules.extraAttributes.removeField,
                                            })}
                                        />
                                    )}
                                    {isTemplate && (
                                        <Button
                                            id={`${e.key}-add-button`}
                                            key="add-extra-attribute-button"
                                            className="add-column-button margin-left-xsmall"
                                            onClick={() => {
                                                addExtraAttribute(e.key);
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            disabled={
                                                !isValid || !name || !operator || value === '' || value == null || value?.[0] == null || value?.[0] === ''
                                            }
                                        >
                                            <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                                        </Button>
                                    )}
                                </Form.Custom>
                            </Form.Column>,
                        );
                    },
                );
            });

        return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraAttributes.isEnabled, extraAttributes.entities, availableExtraAttributes, extraAttributesOperations,
        extraAttributesOptions, extraAttributeRangeMinDate, extraAttributeRangeMaxDate]);

    // According the type, some inputs are shown
    const isPricingType = ruleType === ruleTypes.PRICING;
    const isExceptionType = ruleType === ruleTypes.EXCEPTION;
    const isPointType = ruleType === ruleTypes.POINTS;
    const isFlatFeeType = ruleType === ruleTypes.FLAT_FEE;
    const isFiatType = ruleType === ruleTypes.FIAT;

    const summaryExtraFields = useMemo(() => {
        let fields = [];

        if (!isOccupancyDisabled && occupancy.summary) {
            fields = [occupancy.summary];
        }

        if (isFlatFeeType && translations?.length) {
            fields.push({
                label        : translated.rules.translations.title,
                summaryIndex : 12,
                isCompleted  : true,
                isValid      : true,
                value        : translations.map(({ title, description, languageName }) => <div>{`${languageName} - ${title}: ${description}`}</div>),
            });
        }

        return fields;
    }, [isFlatFeeType, isOccupancyDisabled, occupancy.summary, translations]);

    // Convert the currencies
    const convertedCurrencies = availableCurrencies
        .map((each) => ({
            content : each.name,
            value   : each.id,
        }));

    // Ranges limit dates
    const travelWindowsMinValue = dateManager.defaultDateFormat(dateManager.getCurrentDay());
    const travelWindowsMaxValue = dateManager.maxDateInDefaultFormat();
    const bookingWindowMinValue = dateManager.defaultDateFormat(dateManager.getCurrentDay());
    const bookingWindowMaxValue = dateManager.maxDateInDefaultFormat();

    // When the chosen conversion is percentage, the percentage symbols (if not 'pts') have to be shown,
    // in turn the maximum number changes, within percentage if you choose Plus the maximum
    // is unlimited (actually 99999999), but if you choose 'Less' the maximum becomes 100
    // (because you can not subtract less than 100 percent). Within the occupation, when you choose
    // 'More' or 'Equal', the minimum is 1, whereas with 'Less' it is 2.

    const minConversionValue = 1;
    let maxConversionValue = 99999999;
    let requiredTypeForConversionValue = 'numbers';
    let suffixForConversionValue = ' pts';

    if (isPercentage) {
        maxConversionValue = conversionValue.selectedOption === 'Plus' ? 99999999 : 100;
        requiredTypeForConversionValue = 'float';
        suffixForConversionValue = '%';
    }

    const exampleConversionVal = conversionValue.inputValue ? (
        exampleTextForConversionValue(isFixedPerNight, isFixedOnTotal, conversionValue.inputValue, conversionValue.selectedOption)
    ) : (
        <FormattedMessage id={translated.rules.enterAValue} defaultMessage={translated.rules.enterAValue} />
    );

    const submitRule = async (rule, resource) => {
        try {
            await navigator.requestForCurrentPath({ reqConfig: { ...resource, shouldReloadDate: true, data: { ...rule } }, resources });
        } catch (err) {
            throw new FormError(isEditing ? translated.rules.errors.ruleSaved : translated.rules.errors.ruleAdded, err, translated.rules.errors);
        }
    };

    const onChange = ({ rawValues }) => {
        setExtraAttributes((prev) => {
            const newEntities = {};
            Object.entries(prev.entities).forEach(([key, value]) => {
                const lastId = value.lastId - 1;

                const isValid = rawValues[`${key}-name-${lastId}`]?.isValid !== false
                    && rawValues[`${key}-name-${lastId}`]?.value
                    && rawValues[`${key}-operation-${lastId}`]?.isValid !== false;

                newEntities[key] = {
                    ...value,
                    list: value.list.map((e) => (e.id === lastId ? { ...e, isValid } : e)),
                };
            });

            return {
                ...prev,
                entities: newEntities,
            };
        });
    };

    const onFinish = (submittedValues, hasSubmitted) => {
        if (hasSubmitted) {
            snackbar.show({
                content   : isEditing ? translated.rules.success.ruleSaved : translated.rules.success.ruleAdded,
                isSuccess : true,
            });

            if (navigateToParent && navigator[navigateToParent]) {
                navigator[navigateToParent]();
            } else {
                navigator.goToRules();
            }
        }
    };

    const prepareDataForSubmit = async ({ values: formData }) => {
        const {
            type, properties, excludeProperties, propertyGroups, viewsOnly, viewsExcept, displayMode,
        } = formData;

        if (!available && ((!isEditing && !available.create) || (isEditing && !available.update))) {
            throw new FormError(translated.global.missingPermissions);
        } else if (
            propertiesModes === FILTER // There always must be at least one property
            && !propertyGroups?.length
            && !properties?.length
        ) {
            throw new FormError(translated.rules.hotelAndHotelTypeRequired);
        } else if (type === ruleTypes.FLAT_FEE && !translations?.length) {
            throw new FormError(translated.rules.flatFeeWithNoTranslation);
        } else {
            const { roomAllotment, occupancyCondition, occupancyValue, balanceTypes, ...restOfData } = formData;

            let amount = null;
            let minOccupancy = null;
            let maxOccupancy = null;
            const [minRoomAllotment, maxRoomAllotment] = roomAllotment ? [roomAllotment.min, roomAllotment.max] : [null, null];

            if (formData.type === ruleTypes.PRICING) {
                const { inputValue, selectedOption } = formData.amount;
                // When the value is a percentage we convert it. When its not a percentage, we erase any decimal.
                const formattedConversion = formData.pricingType === ruleTypes.RELATIVE ? inputValue / 100 : Number(inputValue).toFixed();
                // Amount is received as string, so we should send it as a string
                amount = String(selectedOption === 'Plus' ? formattedConversion : -formattedConversion);
            }

            if (formData.type === ruleTypes.FLAT_FEE) {
                amount = formData.flatFeeAmount;
            }

            if (analyzeValue(occupancyValue).isFullyComplete) {
                const parsedValue = parseInt(occupancyValue, 10);

                if (occupancyCondition === 'More') {
                    minOccupancy = parsedValue + 1;
                    maxOccupancy = 999;
                } else if (occupancyCondition === 'Less') {
                    minOccupancy = 1;
                    maxOccupancy = parsedValue - 1;
                } else if (occupancyCondition === 'Equal') {
                    minOccupancy = parsedValue;
                    maxOccupancy = parsedValue;
                }
            }

            const submitExtraAttributes = convertLoadedExtraAttributes(extraAttributes);

            if (submitExtraAttributes?.find((e) => !e.key || !e.category || !e.operator || !e.values?.length)) {
                // Incomplete extra attributes
                throw new FormError(translated.rules.incompleteExtraAttributes);
            }
            if (
                submitExtraAttributes?.find((each, index) => submitExtraAttributes?.find(
                    (e, i) => index !== i && each.category === e.category && each.operator === e.operator && each.key === e.key,
                ))
            ) {
                // Same attribute and operations
                throw new FormError(translated.rules.repeatedExtraAttributes);
            }

            const submitData = {
                ...restOfData,
                minRoomAllotment,
                maxRoomAllotment,
                minOccupancy,
                maxOccupancy,
                excludeProperties : propertyGroups?.length > 0 ? excludeProperties || [] : [],
                extraAttributes   : submitExtraAttributes,
                configuration     : null,
            };

            if (isPointType) {
                delete submitData.bookingCounter;
            }

            delete submitData.displayMode;
            delete submitData.viewsOnly;
            delete submitData.viewsExcept;

            if (formData.type === ruleTypes.FIAT) {
                if (ranges.length === 0) {
                    throw new FormError(translated.rules.fiatOptionsRulesNotConfigured);
                }
                submitData.minAmountPerReservation = 0;
                submitData.minAmountPerNight = 0;

                if (!isFiatMinAmountDisabled) {
                    if (submitData.minAmountType === MIN_AMOUNT_RESERVATION) {
                        submitData.minAmountPerReservation = submitData.minAmount;
                    } else {
                        submitData.minAmountPerNight = submitData.minAmount;
                    }
                }
                submitData.conversionMode = conversionMode;
                const translatedRanges = ranges
                    .map((range) => {
                        const { id, begin, end, steps, label, balanceTypeConversionRates } = range;
                        const translatedBalanceTypes = balanceTypeConversionRates
                            .filter((bt) => !bt.isHidden)
                            .map((bt) => {
                                const { id: btId, currency, fromBalanceType, rate, auxiliaryRate } = bt;

                                return {
                                    id: (typeof btId === 'number') ? btId : undefined,
                                    currency,
                                    fromBalanceType,
                                    rate,
                                    auxiliaryRate,
                                };
                            });

                        return {
                            id                         : (typeof id === 'number') ? id : undefined,
                            balanceTypeConversionRates : translatedBalanceTypes,
                            begin,
                            end,
                            steps,
                            label,
                        };
                    });
                submitData.ranges = translatedRanges;
            } else {
                delete submitData.minAmountPerReservation;
                delete submitData.minAmountPerNight;
            }
            delete submitData.minAmountType;
            delete submitData.minAmount;

            submitData.amount = amount;
            submitData.priority = typeof data.priority === 'number' ? data.priority : 0;
            submitData.pricingType = submitData.pricingType || null; // For the system rules, pricing rules are hidden, but the field must be send.

            if (formData.type === ruleTypes.POINTS) {
                // The backend uses the key 'includes' to decide whether to enable or disable the balance types chosen.
                // Now we only use the includes in 'true'.
                submitData.includes = true;
                submitData.balanceTypes = balanceTypes.map((each) => ({ id: each.id }));
            }

            if (formData.type === ruleTypes.FLAT_FEE) {
                let views;
                switch (displayMode) {
                    case display.ONLY:
                        views = viewsOnly;
                        break;
                    case display.EXCEPT:
                        views = viewsExcept || [];
                        break;
                    default:
                        views = [];
                        break;
                }
                submitData.configuration = {
                    views,
                    displayMode,
                };
                const convertedTranslations = [];

                translations.forEach((e) => {
                    convertedTranslations.push({
                        [e.language]: {
                            title       : e.title,
                            description : e.description,
                        },
                    });
                });
                submitData.translations = convertedTranslations;
            }

            // Clear the country related fields.
            if (submitData.countriesMode === countriesMode.ALWAYS) {
                delete submitData.countries;
            }

            // Handle the hotel types and hotels
            if (submitData.propertiesModes === ALL) {
                submitData.propertyGroups = [];
                submitData.properties = [];
            }
            delete submitData.propertiesModes;

            if (isEditing) {
                submitData.conditionId = data.conditionId;
                submitData.id = data.id;

                return new Promise((resolve, reject) => {
                    confirmation.show({
                        title    : translated.rules.modifyRule,
                        message  : translated.rules.areYouSure,
                        onAccept : async () => {
                            try {
                                await submitRule(submitData, available.update);
                                resolve(true);
                            } catch (e) {
                                reject(e);
                            }
                        },
                        onCancel: () => resolve(false),
                    });
                });
            }
            return submitRule(submitData, available.create);
        }
    };

    const handleOnError = (result) => {
        let content;
        if (result.isInvalid && result.isIncomplete) {
            content = translated.global.incompleteAndErrors;
        } else {
            content = result.isInvalid ? translated.global.formErrors : translated.global.incomplete;
        }

        snackbar.show({
            content,
            contentValues : { fields: result?.labels?.join?.(', ') || '' },
            isError       : true,
        });
    };

    const steps = [
        <FormattedMessage id={translated.rules.wizard.step1} defaultMessage={translated.rules.wizard.step1} />,
        <FormattedMessage id={translated.rules.wizard.step2} defaultMessage={translated.rules.wizard.step2} />,
        <FormattedMessage id={translated.rules.wizard.step3} defaultMessage={translated.rules.wizard.step3} />,
        <FormattedMessage id={translated.rules.wizard.step4} defaultMessage={translated.rules.wizard.step4} />,
    ];

    const initialValues = {
        ...data,
        conversionMode,
        primaryConversionRate   : convertNumberFields(data.primaryConversionRate),
        secondaryConversionRate : convertNumberFields(data.secondaryConversionRate),
        minAmountType           : minPerReservation !== 0 ? MIN_AMOUNT_RESERVATION : MIN_AMOUNT_NIGHT,
        minAmount               : (minPerReservation > 0 && minPerReservation) || (minPerNight > 0 && minPerNight) || null,
    };

    if (isEditing && data.type === ruleTypes.FLAT_FEE) {
        initialValues.flatFeeAmount = data.amount;
        switch (data.configuration.displayMode) {
            case display.ONLY:
                [initialValues.viewsOnly] = data.configuration.views ? data.configuration.views : [];
                initialValues.viewsExcept = [];
                break;
            case display.EXCEPT:
                initialValues.viewsExcept = data.configuration.views ? data.configuration.views : [];
                initialValues.viewsOnly = null;
                break;
            default:
                initialValues.views = [];
                break;
        }
    }

    const selectedExtraAttributesEntities = Object.values(extraAttributes?.entities)
        .filter((entity) => entity.isEnabled)
        .map((e) => ({ id: e.key, name: e.key }));

    const handlePropertyGroupsChange = (selected) => {
        const { propertyGroups: field } = additional.hotel.fields;
        if (selected.length > 0) {
            const params = selected.map((a) => a.id).join(`&${field}`);
            // We clone the object, because otherwise, the original object will be changed and the 'Include' filter uses it.
            const url = JSON.parse(JSON.stringify(additional.hotel));
            url.query.url = `${propertiesOriginalURL}${field}${params}&`;
            setFilterPropertiesURL(url);
        } else {
            setFilterPropertiesURL(null);
        }

        setSelectedPropertyGroups(selected);
    };

    const buttons = isReadOnly
        ? [
            <Form.Secondary key="fs" onClick={(navigateToParent && navigator[navigateToParent]) || navigator.goToRules}>
                <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
            </Form.Secondary>,
        ]
        : [
            <Form.Secondary key="fs" onClick={(navigateToParent && navigator[navigateToParent]) || navigator.goToRules}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary key="fp">
                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
            </Form.Primary>,
        ];

    return (
        <>
            <div className={isDynamicRangesEditorOpen ? 'hidden' : ''}>
                <Form.Wrapper>
                    <Wrapper
                        title={(isReadOnly && sectionLabel) || (isEditing ? translated.rules.edit : translated.rules.new)}
                        titleValues={!isReadOnly && isEditing ? { name: sectionLabel } : null}
                        actionButtons={buttons}
                        steps={steps}
                    >
                        <Grid addMargin="onStackedColumns">
                            <Grid.Column width={{ base: 12, small: 6 }}>
                                <Form
                                    onChange={onChange}
                                    onSubmit={prepareDataForSubmit}
                                    onFinish={onFinish}
                                    onError={handleOnError}
                                    initialValues={initialValues}
                                    className="rules-form"
                                    id="rule-editor"
                                    isDisabled={isReadOnly}
                                >
                                    <Form.Input
                                        type="hidden"
                                        submitKey="changed"
                                        avoidOnSubmit
                                        markAsChanged
                                        value={hasFormChanged}
                                    />
                                    {/* GENERAL */}
                                    <Form.Title className="margin-left-medium" summaryIndex={0}>
                                        <FormattedMessage id={translated.global.general} defaultMessage={translated.global.general} />
                                    </Form.Title>
                                    <Form.Column>
                                        <Form.Input
                                            summaryIndex={1}
                                            type="checkbox"
                                            submitKey="isActive"
                                            label={translated.global.state.enabled}
                                            value={isEditing ? data.isActive : true}
                                            className="step-1"
                                            isRequired
                                        />
                                        {!isPricingType && !isFlatFeeType && !isFiatType && (
                                            <Form.Input
                                                summaryIndex={2}
                                                type="checkbox"
                                                submitKey="override"
                                                label={translated.rules.overrideSystemRules}
                                                value={isEditing ? data.override || false : false}
                                                className="margin-remove-top"
                                                helperText={{ label: translated.rules.overrideSystemRulesExplain }}
                                                isRequired
                                            />
                                        )}
                                    </Form.Column>

                                    {/* INFORMATION */}
                                    <Form.Title className="margin-left-medium margin-top-xlarge margin-bottom-xsmall" summaryIndex={3}>
                                        <FormattedMessage id={translated.global.information} defaultMessage={translated.global.information} />
                                    </Form.Title>
                                    <Form.Column>
                                        <Form.Input
                                            summaryIndex={4}
                                            isDense
                                            type="text"
                                            label={translated.global.name}
                                            submitKey="name"
                                            validations={{ maxLength: 50, unary: ['no-numbers'] }}
                                            charCount={{ total: 50 }}
                                            className="step-2"
                                            isRequired
                                        />
                                        <Form.Group
                                            summaryIndex={5}
                                            label={translated.rules.type}
                                            submitKey="type"
                                            type="chip"
                                            name="type"
                                            value={ruleType}
                                            className="step-3"
                                            isRequired
                                        >
                                            {(!isSystemRule && (
                                                <Form.Input
                                                    onChange={() => setRuleType(ruleTypes.PRICING)}
                                                    label={translated.rules.types.pricing}
                                                    value={ruleTypes.PRICING}
                                                    isDisabled={!arePriceRulesEnabled || isEditing}
                                                />
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            )) || <></>}
                                            <Form.Input
                                                onChange={() => setRuleType(ruleTypes.EXCEPTION)}
                                                label={translated.rules.types.exception}
                                                value={ruleTypes.EXCEPTION}
                                                isDisabled={isEditing}
                                            />
                                            <Form.Input
                                                onChange={() => setRuleType(ruleTypes.POINTS)}
                                                label={translated.rules.typePoints}
                                                value={ruleTypes.POINTS}
                                                isDisabled={!arePointRulesEnabled || isEditing}
                                            />
                                            {(!isSystemRule && (
                                                <Form.Input
                                                    onChange={() => setRuleType(ruleTypes.FLAT_FEE)}
                                                    label={translated.rules.typeFlatFee}
                                                    value={ruleTypes.FLAT_FEE}
                                                    isDisabled={isEditing}
                                                />
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            )) || <></>}
                                            <Form.Input
                                                onChange={() => setRuleType(ruleTypes.FIAT)}
                                                label={translated.rules.typeFiat}
                                                value={ruleTypes.FIAT}
                                                isDisabled={isEditing}
                                            />
                                        </Form.Group>
                                    </Form.Column>

                                    {/* CONDITIONS */}
                                    <Form.Title summaryIndex={6} className="margin-left-medium margin-top-xxlarge margin-bottom-xsmall step-4">
                                        <FormattedMessage id={translated.rules.conditions} defaultMessage={translated.rules.conditions} />
                                    </Form.Title>
                                    <Form.Column>
                                        {isFiatType && ranges.length > 0 && (
                                            <Form.WrapperNew
                                                key="dynamic-ranges-form"
                                                formId="dynamic-ranges-form"
                                                initialValues={{
                                                    ranges                : convertRanges(ranges),
                                                    conversions           : getConversionsInitialValues(ranges, availableBalanceTypes),
                                                    balanceTypes          : getUsedBalanceTypesIds(ranges),
                                                    availableBalanceTypes : initializeBalanceTypes(availableBalanceTypes, availableCurrencies),
                                                }}
                                                ref={dynamicSummaryRef}
                                                keepValuesOnInputRemove
                                            >
                                                <FormNew>
                                                    <Summary
                                                        title={translated.rules.dynamicRanges.title}
                                                        openConfiguration={!isReadOnly ? openDynamicRangeEditor : null}
                                                        className="dynamic-ranges-card margin-left-large margin-bottom-small"
                                                    />
                                                </FormNew>
                                            </Form.WrapperNew>
                                        )}

                                        {isFiatType && ranges.length === 0 && (
                                            <Alert
                                                className="margin-left-large margin-bottom-xsmall"
                                                type="info"
                                                content={translated.rules.dynamicRanges.summary.empty}
                                                actionText={translated.rules.dynamicRanges.summary.configure}
                                                onClick={openDynamicRangeEditor}
                                            />
                                        )}

                                        {isFiatType && ranges.length !== 0 && (
                                            <Form.Group
                                                submitKey="conversionMode"
                                                type="chip"
                                                className="range-input margin-left-large"
                                                label={translated.rules.dynamicRanges.conversionRates.sourceBalances}
                                                value={conversionMode}
                                                isDense
                                                isRequired
                                                isDisabled
                                                isBasedOnState
                                            >
                                                <Form.Input
                                                    value={conversionModeOptions[0].value}
                                                    label={conversionModeOptions[0].label}
                                                />
                                                <Form.Input
                                                    value={conversionModeOptions[1].value}
                                                    label={conversionModeOptions[1].label}
                                                />
                                            </Form.Group>
                                        )}
                                    </Form.Column>
                                    <Form.Column>
                                        {isPricingType && (
                                            <Form.Group
                                                submitKey="pricingType"
                                                label={translated.rules.conversion}
                                                value={conversionType}
                                                type="chip"
                                                name="conversion"
                                                className="margin-left-large"
                                                summaryIndex={7}
                                                isRequired
                                            >
                                                <Form.Input
                                                    onChange={() => setConversionType(ruleTypes.FIXED_NIGHT)}
                                                    value={ruleTypes.FIXED_NIGHT}
                                                    label={translated.rules.fixedPerNight}
                                                />
                                                <Form.Input
                                                    onChange={() => setConversionType(ruleTypes.FIXED_TOTAL)}
                                                    value={ruleTypes.FIXED_TOTAL}
                                                    label={translated.rules.fixedOnTotal}
                                                />
                                                <Form.Input
                                                    onChange={() => setConversionType(ruleTypes.RELATIVE)}
                                                    value={ruleTypes.RELATIVE}
                                                    label={translated.rules.percentage}
                                                />
                                            </Form.Group>
                                        )}
                                        {isPricingType && (
                                            <Form.Input
                                                type="split"
                                                submitKey="amount"
                                                options={[
                                                    {
                                                        value   : 'Plus',
                                                        content : intl.formatMessage({
                                                            id             : translated.global.buttons.plus,
                                                            defaultMessage : translated.global.buttons.plus,
                                                        }),
                                                    },
                                                    {
                                                        value   : 'Less',
                                                        content : intl.formatMessage({
                                                            id             : translated.global.buttons.less,
                                                            defaultMessage : translated.global.buttons.less,
                                                        }),
                                                    },
                                                ]}
                                                value={{ ...conversionValue }}
                                                onChange={(val) => {
                                                    setConversionValue(val);
                                                }}
                                                min={minConversionValue}
                                                max={maxConversionValue}
                                                validations={{
                                                    minValue    : minConversionValue,
                                                    maxValue    : maxConversionValue,
                                                    maxDecimals : 2,
                                                    unary       : [requiredTypeForConversionValue],
                                                }} // Higher numbers are not accepted at the DB
                                                suffix={suffixForConversionValue}
                                                helperText={{ label: exampleConversionVal }}
                                                label={translated.rules.conversionValue}
                                                className="margin-left-large margin-bottom-medium"
                                                summaryFormat={({ value, suffix }) => `${value.selectedOption === 'Plus' ? '+' : '-'} ${value.inputValue ? value.inputValue : 0}${suffix}`}
                                                summaryIndex={8}
                                                isRequired
                                            />
                                        )}
                                        {isPointType && (
                                            <Form.Input
                                                type="selectWithFilter"
                                                submitKey="balanceTypes"
                                                label={translated.rules.points}
                                                options={availableBalanceTypes}
                                                sortBy="content"
                                                className="margin-left-large"
                                                isMultiSelect
                                                isDense
                                                isRequired
                                            />
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Input
                                                type="select"
                                                submitKey="currency"
                                                label={translated.rules.currency}
                                                options={convertedCurrencies}
                                                className="margin-left-large"
                                                value={data?.currency?.id}
                                                summaryFormat={({ value }) => availableCurrencies.find((e) => e.id === value)?.name}
                                                submitFormat={(id) => ({ id })}
                                                summaryIndex={6}
                                                isDense
                                                isRequired
                                            />
                                        )}
                                        {isFlatFeeType && <Form.Input type="hidden" submitKey="level" summaryIndex={7} value={0} avoidOnSummary />}
                                        {isFlatFeeType && (
                                            <Form.Group
                                                type="chip"
                                                name="feeCalculation"
                                                submitKey="feeCalculation"
                                                className="margin-left-large"
                                                label={translated.rules.feeCalculation}
                                                summaryIndex={8}
                                                isRequired
                                            >
                                                <Form.Input value={1} label={translated.rules.feeCalculationOptions.reservation} />
                                                <Form.Input value={2} label={translated.rules.feeCalculationOptions.night} />
                                            </Form.Group>
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Group
                                                type="chip"
                                                name="applicationType"
                                                submitKey="applicationType"
                                                className="margin-left-large"
                                                label={translated.rules.applicationType}
                                                summaryIndex={9}
                                                isRequired
                                            >
                                                <Form.Input value={1} label={translated.rules.applicationTypeOptions.stay} />
                                                <Form.Input value={2} label={translated.rules.applicationTypeOptions.room} />
                                            </Form.Group>
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Input
                                                type="number"
                                                submitKey="flatFeeAmount"
                                                validations={{ minValue: 0.01, maxValue: 99999999, unary: ['float'] }}
                                                label={translated.rules.amount}
                                                className="margin-left-large small-number-input"
                                                summaryIndex={10}
                                                isDense
                                                isRequired
                                            />
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Input
                                                type="text"
                                                submitKey="code"
                                                className="margin-left-large range-input"
                                                label={translated.rules.code}
                                                summaryIndex={11}
                                                validations={{ maxLength: 24, unary: ['onlyLetters'] }}
                                                charCount={{ total: 24 }}
                                                isDense
                                                isRequired
                                            />
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Input
                                                type="hidden"
                                                submitKey="translationChanged"
                                                value={translationsChanged}
                                                avoidOnSummary
                                                avoidOnSubmit
                                                markAsChanged
                                            />
                                        )}
                                        {isFlatFeeType && (
                                            <Form.Group
                                                type="chip"
                                                value={data?.configuration?.displayMode || display.ALL}
                                                name="display"
                                                submitKey="displayMode"
                                                label={translated.rules.display}
                                                className="margin-left-large"
                                                summaryIndex={24}
                                                onChange={(value) => setDisplayIn(value)}
                                                isRequired
                                            >
                                                <Form.Input value={display.ALL} label={translated.rules.all} />
                                                <Form.Input value={display.ONLY} label={translated.rules.only} />
                                                <Form.Input value={display.EXCEPT} label={translated.rules.except} />
                                            </Form.Group>
                                        )}
                                        {isFlatFeeType && displayIn === display.EXCEPT && (
                                            <Form.Group
                                                type="chip"
                                                name="views"
                                                submitKey="viewsExcept"
                                                value={data?.configuration?.views}
                                                label={translated.rules.views}
                                                className="margin-left-large"
                                                summaryIndex={25}
                                                summaryFormat={({ value }) => value
                                                    ?.map((each) => intl.formatMessage({
                                                        id             : translated.rules.viewOptions[each],
                                                        defaultMessage : translated.rules.viewOptions[each],
                                                    }))
                                                    .join(', ')}
                                                isMultiSelect
                                            >
                                                {availableViews.map((each) => (
                                                    <Form.Input
                                                        value={each}
                                                        label={translated.rules.viewOptions[each]}
                                                    />
                                                ))}
                                            </Form.Group>
                                        )}
                                        {isFlatFeeType && displayIn === display.ONLY && (
                                            <Form.Group
                                                type="chip"
                                                name="views"
                                                submitKey="viewsOnly"
                                                label={translated.rules.views}
                                                className="margin-left-large"
                                                summaryIndex={25}
                                                summaryFormat={({ value }) => (value ? transformCamelToSpacesOnlyFirstCapital(value) : undefined)}
                                                submitFormat={(value) => [value]}
                                            >
                                                {availableViews.map((each) => (
                                                    <Form.Input
                                                        value={each}
                                                        label={translated.rules.viewOptions[each]}
                                                    />
                                                ))}
                                            </Form.Group>
                                        )}

                                        {isFiatType && (
                                            <Form.Group
                                                type="chip"
                                                value={initialValues?.minAmountType || MIN_AMOUNT_NIGHT}
                                                name="occupancy"
                                                submitKey="minAmountType"
                                                label={translated.rules.minAmount}
                                                onSwitch={(val) => setIsFiatMinAmountDisabled(!val)}
                                                isRequired={!isFiatMinAmountDisabled}
                                                avoidOnSummary
                                                isSelectable
                                                isSelected={!isFiatMinAmountDisabled}
                                                className="margin-top-small"
                                            >
                                                <Form.Input value={MIN_AMOUNT_NIGHT} label={translated.rules.minAmountPerNight} />
                                                <Form.Input value={MIN_AMOUNT_RESERVATION} label={translated.rules.minAmountPerReservation} />
                                            </Form.Group>
                                        )}
                                        {isFiatType && (
                                            <Form.Input
                                                type="number"
                                                value={occupancy.value}
                                                isDisabled={isFiatMinAmountDisabled}
                                                validations={{
                                                    minValue    : 0.0001,
                                                    maxValue    : 99999999,
                                                    maxDecimals : 4,
                                                    unary       : ['float'],
                                                }}
                                                submitKey="minAmount"
                                                label={translated.rules.amount}
                                                className="margin-left-large margin-top-small small-number-input"
                                                avoidOnSummary
                                                isRequired={!isFiatMinAmountDisabled}
                                                isDense
                                            />
                                        )}

                                        <Form.Group
                                            type="chip"
                                            submitKey="applyTo"
                                            value={data.applyTo || applyToDefaultOption}
                                            label={translated.rules.applyTo}
                                            className="margin-left-large"
                                            summaryIndex={20}
                                            isRequired
                                        >
                                            {availableApplyTo.map((each) => (
                                                <Form.Input
                                                    value={each}
                                                    label={translated.rules.applyToOptions[each]}
                                                    key={`apply-to-${each}`}
                                                />
                                            ))}
                                        </Form.Group>
                                        <Form.Group
                                            type="chip"
                                            submitKey="travelWindowMode"
                                            onChange={(val) => {
                                                setIsTravelWindowModeAlways(val === rangeMode.ALWAYS);
                                                setHasFormChanged(true);
                                            }}
                                            value={data.travelWindowMode || rangeMode.ALWAYS}
                                            label={translated.rules.stayDates}
                                            summaryFormat={({ value, formValues }) => formatDatesForSummary(dateManager, value, formValues.travelWindow)}
                                            className="margin-left-large"
                                            summaryIndex={21}
                                            isRequired
                                        >
                                            <Form.Input value={rangeMode.ALWAYS} label={translated.rules.always} />
                                            <Form.Input value={rangeMode.ONLY} label={translated.rules.only} />
                                            <Form.Input value={rangeMode.EXCEPT} label={translated.rules.except} />
                                        </Form.Group>
                                        {!isTravelWindowModeAlways && (
                                            <Form.Input
                                                type="picker"
                                                isMultiRange
                                                submitKey="travelWindow"
                                                value={data.travelWindows}
                                                label={translated.global.dates}
                                                icon={{ name: 'Calendar', position: 'right' }}
                                                minDate={travelWindowsMinValue}
                                                maxDate={travelWindowsMaxValue}
                                                minRangeLength={1}
                                                className="margin-left-large margin-top-small type-multiDates"
                                                isDense
                                                avoidOnSummary
                                                isRequired
                                            />
                                        )}
                                        <Form.Group
                                            type="chip"
                                            submitKey="bookingWindowMode"
                                            onChange={(val) => {
                                                setIsBookingWindowModeAlways(val === rangeMode.ALWAYS);
                                                setHasFormChanged(true);
                                            }}
                                            value={data.bookingWindowMode || rangeMode.ALWAYS}
                                            label={translated.rules.bookingWindow}
                                            summaryFormat={({ value, formValues }) => formatDatesForSummary(dateManager, value, formValues.bookingWindow)}
                                            className="margin-left-large"
                                            summaryIndex={22}
                                            isRequired
                                        >
                                            <Form.Input value={rangeMode.ALWAYS} label={translated.rules.always} />
                                            <Form.Input value={rangeMode.ONLY} label={translated.rules.only} />
                                            <Form.Input value={rangeMode.EXCEPT} label={translated.rules.except} />
                                        </Form.Group>
                                        {!isBookingWindowModeAlways && (
                                            <Form.Input
                                                type="picker"
                                                isMultiRange
                                                submitKey="bookingWindow"
                                                label={translated.rules.dates}
                                                icon={{ name: 'Calendar', position: 'right' }}
                                                className="margin-left-large  type-multiDates"
                                                minDate={bookingWindowMinValue}
                                                maxDate={bookingWindowMaxValue}
                                                minRangeLength={1}
                                                isDense
                                                avoidOnSummary
                                                isRequired
                                            />
                                        )}
                                        <Form.Group
                                            type="chip"
                                            value={data?.countriesMode || countriesMode.ALWAYS}
                                            name="countriesMode"
                                            submitKey="countriesMode"
                                            label={translated.rules.countriesMode}
                                            onChange={(newValue) => {
                                                setIsCountriesDisabled(newValue === countriesMode.ALWAYS);
                                                setHasFormChanged(true);
                                            }}
                                            className="margin-left-large"
                                            summaryIndex={23}
                                            summaryFormat={({ value }) => (
                                                <FormattedMessage id={translated.rules[value]} defaultMessage={translated.rules[value]} />
                                            )}
                                            isRequired
                                        >
                                            <Form.Input value={countriesMode.ALWAYS} label={translated.rules.always} />
                                            <Form.Input value={countriesMode.ONLY} label={translated.rules.only} />
                                            <Form.Input value={countriesMode.EXCEPT} label={translated.rules.except} />
                                        </Form.Group>
                                        {!isCountriesDisabled && (
                                            <Form.Input
                                                type="select"
                                                submitKey="countries"
                                                label={translated.rules.countries}
                                                options={availableCountries.map((e) => ({ content: e.name, value: { id: e.id, name: e.name }, key: `c_${e.id}` }))}
                                                submitFormat={(values) => values?.length && values.map((e) => ({ id: e.id }))}
                                                isDisabled={isCountriesDisabled}
                                                isRequired={!isCountriesDisabled}
                                                className="margin-left-large"
                                                defaultOptionText={translated.global.selectAnOption}
                                                summaryIndex={24}
                                                isMultiSelect
                                                isDense
                                            />
                                        )}
                                        {isExceptionType && (
                                            <Form.Group
                                                type="chip"
                                                name="includes"
                                                submitKey="includes"
                                                label={translated.rules.includes}
                                                className="margin-left-large"
                                                summaryFormat={formatHotelFilterForSummary}
                                                summaryIndex={25}
                                                isRequired
                                            >
                                                <Form.Input
                                                    label={translated.rules.includesAvailable}
                                                    value={rulesFilterConfigurations.INCLUDE}
                                                />
                                                <Form.Input
                                                    label={translated.rules.excludesHide}
                                                    value={rulesFilterConfigurations.EXCLUDE_HIDE}
                                                />
                                                <Form.Input
                                                    label={translated.rules.excludesShowUpgrade}
                                                    value={rulesFilterConfigurations.EXCLUDE_SHOW_UPGRADE}
                                                />
                                            </Form.Group>
                                        )}
                                        <Form.Group
                                            type="chip"
                                            value={propertiesModes}
                                            name="propertiesModes"
                                            submitKey="propertiesModes"
                                            label={translated.rules.propertiesMode}
                                            onChange={(newValue) => setPropertiesModes(newValue)}
                                            className="margin-left-large"
                                            summaryIndex={26}
                                            isRequired
                                        >
                                            <Form.Input value={ALL} label={translated.rules.all} />
                                            <Form.Input value={FILTER} label={translated.rules.filter} />
                                        </Form.Group>

                                        {propertiesModes === FILTER && (
                                            <Form.Input
                                                type="selectWithFilter"
                                                submitKey="properties"
                                                className="margin-left-large"
                                                label={translated.rules.hotel}
                                                summaryIndex={27}
                                                isMultiSelect
                                                isDense
                                                isRequired={false}
                                                filterLink={additional?.hotel}
                                                filterFunction={(response) => response?.data?.map((each) => ({
                                                    content : each.name,
                                                    value   : { id: each.id, name: each.name },
                                                    key     : `ht_${each.id}`,
                                                })) || []}
                                                filterOnError={(error) => {
                                                    snackbar.show({ error, content: translated.rules.errors.hotelsFilterError, isError: true });
                                                }}
                                            />
                                        )}

                                        {propertiesModes === FILTER && (
                                            <Form.Title summaryIndex={28} className="margin-top-small margin-bottom-xsmall margin-left-large" isSmall>
                                                <FormattedMessage id={translated.rules.hotelTypeTitle} defaultMessage={translated.rules.hotelTypeTitle} />
                                            </Form.Title>
                                        )}

                                        {propertiesModes === FILTER && (
                                            <Form.Input
                                                type="selectWithFilter"
                                                submitKey="propertyGroups"
                                                className="margin-left-large"
                                                label={translated.rules.hotelType}
                                                summaryIndex={29}
                                                isMultiSelect
                                                isDense
                                                isRequired={false}
                                                filterLink={additional?.hotelTypes}
                                                filterFunction={(response) => response?.data?.map((each) => ({
                                                    content : each.name,
                                                    value   : { id: each.id, name: each.name },
                                                    key     : `ht_${each.id}`,
                                                })) || []}
                                                filterOnError={(error) => {
                                                    snackbar.show({ error, content: translated.rules.errors.hotelsTypesFilterError, isError: true });
                                                }}
                                                onChange={handlePropertyGroupsChange}
                                            />
                                        )}

                                        {propertiesModes === FILTER && (
                                            <Form.Input
                                                type="selectWithFilter"
                                                submitKey="excludeProperties"
                                                className="margin-left-large"
                                                label={translated.rules.excludes}
                                                summaryIndex={30}
                                                isMultiSelect
                                                isDense
                                                isDisabled={selectedPropertyGroups.length === 0}
                                                isRequired={false}
                                                filterLink={filterPropertiesURL}
                                                filterFunction={(response) => response?.data?.map((each) => ({
                                                    content : each.name,
                                                    value   : { id: each.id, name: each.name },
                                                    key     : `ht_${each.id}`,
                                                })) || []}
                                                filterOnError={(error) => {
                                                    snackbar.show({ error, content: translated.rules.errors.hotelsFilterError, isError: true });
                                                }}
                                            />
                                        )}

                                        <Form.Group
                                            type="chip"
                                            value={occupancy.condition || 'More'}
                                            name="occupancy"
                                            submitKey="occupancyCondition"
                                            label={translated.rules.occupancy}
                                            onSwitch={(val) => setIsOccupancyDisabled(!val)}
                                            onChange={(condition) => setOccupancy(occupancyForSummary({ value: occupancy.value, condition }))}
                                            isRequired={!isOccupancyDisabled}
                                            avoidOnSummary
                                            isSelectable={!isReadOnly}
                                            isSelected={!isOccupancyDisabled}
                                            isReadOnly={isReadOnly}
                                        >
                                            <Form.Input value="More" label={translated.rules.more} />
                                            <Form.Input value="Less" label={translated.rules.less} />
                                            <Form.Input value="Equal" label={translated.rules.equals} />
                                        </Form.Group>
                                        <Form.Input
                                            type="number"
                                            value={occupancy.value}
                                            isDisabled={isOccupancyDisabled}
                                            onChange={(value, properties) => setOccupancy(
                                                occupancyForSummary({
                                                    value,
                                                    properties,
                                                    condition: occupancy.condition,
                                                }),
                                            )}
                                            validations={{ minValue: minOccupancyAccordingToCondition, maxValue: 99, unary: ['numbers'] }}
                                            submitKey="occupancyValue"
                                            label={translated.rules.occupancyValue}
                                            className="margin-left-large margin-top-small small-number-input"
                                            avoidOnSummary
                                            isRequired={!isOccupancyDisabled}
                                            isDense
                                        />
                                        <Form.Input
                                            type="number"
                                            submitKey="cutoff"
                                            validations={{ minValue: 1, maxValue: 999, unary: ['numbers'] }}
                                            label={translated.rules.cutoff}
                                            summaryFormat={({ value }) => (
                                                <FormattedMessage
                                                    id={translated.rules.summary.numberOfDays}
                                                    defaultMessage={translated.rules.summary.numberOfDays}
                                                    values={{ value }}
                                                />
                                            )}
                                            isSelectable={!isReadOnly}
                                            isDense
                                            className="small-number-input"
                                            isRequired
                                        />
                                        <Form.Input
                                            type="number"
                                            submitKey="maxLeadTime"
                                            validations={{ minValue: 1, maxValue: 999, unary: ['numbers'] }}
                                            label={translated.rules.maxLeadTime}
                                            summaryFormat={({ value }) => (
                                                <FormattedMessage
                                                    id={translated.rules.summary.numberOfDays}
                                                    defaultMessage={translated.rules.summary.numberOfDays}
                                                    values={{ value }}
                                                />
                                            )}
                                            isSelectable={!isReadOnly}
                                            isDense
                                            className="small-number-input"
                                            isRequired
                                        />
                                        <Form.Input
                                            type="number"
                                            submitKey="lengthOfStay"
                                            validations={{ minValue: 1, maxValue: 999, unary: ['numbers'] }}
                                            label={translated.rules.lengthOfStay}
                                            summaryFormat={({ value }) => (
                                                <FormattedMessage
                                                    id={translated.rules.summary.lengthOfStay}
                                                    defaultMessage={translated.rules.summary.lengthOfStay}
                                                    values={{ value }}
                                                />
                                            )}
                                            isSelectable={!isReadOnly}
                                            isDense
                                            className="small-number-input"
                                            isRequired
                                        />
                                        <Form.Input
                                            type="slider"
                                            validations={{ sliderMinValue: 1, sliderMaxValue: 100, unary: ['slider', 'numbers'] }}
                                            summaryFormat={({ value: { min, max } }) => (
                                                <FormattedMessage
                                                    id={translated.rules.summary.roomAllotment}
                                                    defaultMessage={translated.rules.summary.roomAllotment}
                                                    values={{ min, max }}
                                                />
                                            )}
                                            value={{ min: data.minRoomAllotment, max: data.maxRoomAllotment }}
                                            defaultValue={{ min: 1, max: 100 }}
                                            min={1}
                                            max={100}
                                            submitKey="roomAllotment"
                                            label={translated.rules.roomAllotment}
                                            isSelectable={!isReadOnly}
                                            isDense
                                            isRequired
                                        />
                                        {!isPointType && (
                                            <Form.Input
                                                type="number"
                                                submitKey="bookingCounter"
                                                validations={{ minValue: 1, maxValue: 999, unary: ['numbers'] }}
                                                summaryFormat={({ value }) => (
                                                    <FormattedMessage
                                                        id={translated.rules.summary.bookingCounter}
                                                        defaultMessage={translated.rules.summary.bookingCounter}
                                                        values={{ value }}
                                                    />
                                                )}
                                                label={translated.rules.bookingCounter}
                                                isSelectable={!isReadOnly}
                                                isDense
                                                className="small-number-input"
                                                isRequired
                                            />
                                        )}

                                        {extraAttributes.isEnabled && (
                                            <Form.Input
                                                id="extra-attributes-select"
                                                type="select"
                                                submitKey="extra-attributes-entities"
                                                label={translated.rules.extraAttributes.title}
                                                options={extraAttributesEntities.map((e) => ({
                                                    content  : getExtraAttributeEntityLabel(e, intl),
                                                    value    : { id: e, name: e },
                                                    key      : `da_${e}`,
                                                    callback : () => removeExtraAttributeEntity(e),
                                                }))}
                                                onChange={onExtraAttributeEntityChange}
                                                value={selectedExtraAttributesEntities}
                                                avoidOnSubmit
                                                isMultiSelect
                                                isDense
                                                isSelectable={!isReadOnly}
                                                isSelected={!!data?.extraAttributes}
                                            />
                                        )}
                                    </Form.Column>

                                    {getExtraAttributesLists()}

                                    {isFlatFeeType && (
                                        <TranslationList
                                            elements={translations}
                                            updateLanguages={(newValue) => {
                                                setTranslations(newValue);
                                                setTranslationsChanged(true);
                                            }}
                                            availableLanguages={availableLanguages}
                                        />
                                    )}
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={{ small: 6 }} className="hidden@s">
                                <Form.Summary className="rules-summary margin-left-large" extraFields={summaryExtraFields} />
                            </Grid.Column>
                        </Grid>
                    </Wrapper>
                </Form.Wrapper>
            </div>
            {isDynamicRangesEditorOpen && (
                <DynamicRangeEditor
                    ranges={ranges}
                    conversionMode={conversionMode}
                    availableBalanceTypes={availableBalanceTypes}
                    availableCurrencies={availableCurrencies}
                    availableConversionModes={availableConversionModes}
                    onSave={handleSaveDynamicRanges}
                    onCancel={closeDynamicRangeEditor}
                />
            )}
        </>
    );
}

Editor.defaultProps = {
    arePriceRulesEnabled : true,
    arePointRulesEnabled : true,
    isSystemRule         : false,
    navigateToParent     : '',
};

Editor.propTypes = {
    name                 : PropTypes.string.isRequired,
    data                 : PropTypes.shape({}).isRequired,
    resources            : PropTypes.shape({}).isRequired,
    isEditing            : PropTypes.bool.isRequired,
    arePriceRulesEnabled : PropTypes.bool,
    arePointRulesEnabled : PropTypes.bool,
    isSystemRule         : PropTypes.bool,
    navigateToParent     : PropTypes.string,
};

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid addMargin="onStackedColumns">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    {/* General */}
                    <Skeleton.Title isSubHeading width={95} className="margin-bottom-small" />
                    <Skeleton.Form type="checkbox" />
                    {/* Information */}
                    <Skeleton.Title isSubHeading width={135} className="margin-top-xxlarge margin-bottom-small" />
                    <Skeleton.Form type="input" className="margin-bottom-small" />
                    <Skeleton.Chips quantity={5} />
                    {/* Conditions */}
                    <Skeleton.Title isSubHeading width={125} className="margin-top-xxlarge margin-bottom-small" />
                    <Skeleton.Chips quantity={3} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Form type="input" className="margin-left-large margin-bottom-small" />
                    <Skeleton.Chips quantity={3} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Chips quantity={3} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Chips quantity={3} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Chips quantity={3} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Chips quantity={2} className="margin-left-large margin-bottom-small" />
                    <Skeleton.Form type="inputWithCheckbox" quantity={6} smallInput />
                    <Skeleton.Form type="inputWithCheckbox" />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Card unelevated className="margin-left-large" hasPicture={false} hasActions={false} hasDescription={{ paragraphs: 5 }} />
                </Grid.Column>
            </Grid>
        </>
    );
};
export default withRequest(Editor);
