import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'Components/Tooltip';
import IconPath from 'Utils/IconsPath';
import Icon from '@mdi/react';
import { clientStatus, csvStatus, bookingsStatus, transactionsStatus, statusStats } from 'Constants/global';
import getClassName from 'Utils/getClassName';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import panelContext from 'State/panelContext';

function Status({ status, details, className, highlightedAttributes }) {
    const { dateManager } = useContext(panelContext);

    let currentState;
    let typeClassName;

    switch (status) {
        case clientStatus.ACTIVE:
            currentState = statusStats.ENABLED;
            typeClassName = 'status-enabled';
            break;
        case clientStatus.CANCELLED:
        case bookingsStatus.CANCELLED:
        case transactionsStatus.CANCELLED:
            currentState = statusStats.CANCELLED;
            typeClassName = 'status-error';
            break;
        case clientStatus.ERROR:
            currentState = statusStats.ERROR;
            typeClassName = 'status-error';
            break;
        case csvStatus.INCOMPLETE:
            currentState = statusStats.INCOMPLETE;
            typeClassName = 'status-pending';
            break;
        case clientStatus.PENDING:
        case csvStatus.PENDING:
        case transactionsStatus.PENDING:
        case transactionsStatus.PENDING_APPROVAL:
            currentState = statusStats.PENDING;
            typeClassName = 'status-pending';
            break;
        case clientStatus.SUSPENDED:
            currentState = statusStats.SUSPENDED;
            typeClassName = 'status-disabled';
            break;
        case csvStatus.COMPLETE:
        case csvStatus.COMPLETED:
            currentState = statusStats.COMPLETED;
            typeClassName = 'status-enabled';
            break;
        case csvStatus.DOWNLOADING:
            currentState = statusStats.DOWNLOADING;
            typeClassName = 'status-pending';
            break;
        case csvStatus.INVALID:
            currentState = statusStats.INVALID;
            typeClassName = 'status-error';
            break;
        case csvStatus.LOCKED:
            currentState = statusStats.LOCKED;
            typeClassName = 'status-error';
            break;
        case csvStatus.ATTENTION_REQUIRED:
            currentState = statusStats.ATTENTION_REQUIRED;
            typeClassName = 'status-pending';
            break;
        case bookingsStatus.RESERVED:
            currentState = statusStats.RESERVED;
            typeClassName = 'status-enabled';
            break;
        case bookingsStatus.EXPIRED:
            currentState = statusStats.EXPIRED;
            typeClassName = 'status-disabled';
            break;
        case bookingsStatus.MODIFY:
            currentState = statusStats.MODIFY;
            typeClassName = 'status-pending';
            break;
        case bookingsStatus.ON_HOLD:
            currentState = statusStats.ON_HOLD;
            typeClassName = 'status-pending';
            break;
        case bookingsStatus.ON_REQUEST:
            currentState = statusStats.REQUEST;
            typeClassName = 'status-pending';
            break;
        case bookingsStatus.DENIED:
            currentState = statusStats.DENIED;
            typeClassName = 'status-error';
            break;
        case bookingsStatus.IMPORTED:
            currentState = statusStats.IMPORTED;
            typeClassName = 'status-enabled';
            break;
        case transactionsStatus.CONFIRMED:
            currentState = statusStats.CONFIRMED;
            typeClassName = 'status-enabled';
            break;
        case transactionsStatus.INSUFFICIENT_FOUNDS:
            currentState = statusStats.INSUFFICIENT_FOUNDS;
            typeClassName = 'status-error';
            break;
        case transactionsStatus.MODIFIED:
            currentState = statusStats.MODIFIED;
            typeClassName = 'status-enabled';
            break;
        default:
            currentState = statusStats.NOT_DEFINED;
            typeClassName = 'status-disabled';
    }

    const tooltipId = Tooltip.generateId();

    const renderTooltipContent = (detailedElements) => {
        const elements = detailedElements.map(({ key, value, name, dataType }) => {
            switch (dataType) {
                case 'boolean':
                    return (
                        <div className="status-tooltip" key={`tooltip-status-$${key}`}>
                            <Icon
                                className={`mdi-icon ${value ? 'status-enabled' : 'status-error'}`}
                                path={value ? IconPath.mdiCheckCircle : IconPath.mdiCloseCircle}
                            />
                            <span>{name}</span>
                        </div>
                    );
                case 'Date':
                    return (
                        <div key={`tooltip-status-$${key}`}>
                            <div className="status-tooltip">
                                <Icon className="mdi-icon status-default" path={IconPath.mdiClock} />
                                <span>{name}</span>
                            </div>
                            <div className="status-tooltip" key="tooltip-status-login-date">
                                <span className="status-date-label">{dateManager.defaultDateTimeFormat(value)}</span>
                            </div>
                        </div>
                    );

                default:
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    return <></>;
            }
        });

        return elements;
    };

    const statusClassName = getClassName({ hasTooltip: details }, 'status', typeClassName, className);

    return (
        <div className={statusClassName}>
            <WrappedFormattedMessage content={translated.global.statusList[currentState.toLocaleLowerCase()]} />
            {details?.find((e) => e.isCompleted !== undefined) && (
                <>
                    <Tooltip id={tooltipId}>{renderTooltipContent(details)}</Tooltip>
                    <Icon data-for={tooltipId} data-tip={renderTooltipContent(details)} className="mdi-icon" path={IconPath.mdiInformationOutline} />
                </>
            )}
            {highlightedAttributes?.length && (
                <>
                    <Tooltip id={tooltipId}>{renderTooltipContent(highlightedAttributes)}</Tooltip>
                    <Icon
                        data-for={tooltipId}
                        data-tip={renderTooltipContent(highlightedAttributes)}
                        className="mdi-icon"
                        path={IconPath.mdiInformationOutline}
                    />
                </>
            )}
        </div>
    );
}

Status.defaultProps = {
    details               : null,
    className             : '',
    status                : '',
    highlightedAttributes : null,
};

Status.propTypes = {
    status                : PropTypes.string,
    className             : PropTypes.string,
    details               : PropTypes.arrayOf(PropTypes.shape({})),
    highlightedAttributes : PropTypes.arrayOf(PropTypes.shape({})),
};

export default Status;
