import { mdiCashMultiple } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const currencyId = null;

export default {
    [sectionType.CURRENCIES]: {
        isEnabled : false,
        config    : { url: '/panel/currencies', icon: mdiCashMultiple },
        sections  : {
            [sectionType.CURRENCIES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.CURRENCIES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.CURRENCIES_EDITOR]: {
                ids       : { currencyId, self: 'currencyId' },
                resources : { templates: { name: resourceNames.CURRENCY } },
                config    : { url: '/(\\d+)' },
            },
        },
    },
};
