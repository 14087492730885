module.exports = Object.freeze({
    title                    : 'apf.roles.title',
    creationTitle            : 'apf.roles.creationTitle',
    editionTitle             : 'apf.roles.editionTitle',
    viewTitle                : 'apf.roles.viewTitle',
    permissions              : 'apf.roles.permissions',
    name                     : 'apf.roles.name',
    publicPermissionsTooltip : 'apf.roles.publicPermissionsTooltip',
    all                      : 'apf.roles.all',
    enable                   : { success: 'apf.roles.enable.success', error: 'apf.roles.enable.error' },
    disable                  : { success: 'apf.roles.disable.success', error: 'apf.roles.disable.error' },
    delete                   : {
        confirmation               : 'apf.roles.delete.confirmation',
        success                    : 'apf.roles.delete.success',
        error                      : 'apf.roles.delete.error',
        errorEntityCannotBeDeleted : 'apf.roles.delete.errorEntityCannotBeDeleted',
    },
    create : { success: 'apf.roles.create.success', error: 'apf.roles.create.error' },
    save   : { success: 'apf.roles.save.success', error: 'apf.roles.save.error' },
    errors : { entityIsReadonly: 'apf.roles.errors.entityIsReadonly' },
    wizard : {
        step1   : 'apf.roles.wizard.step1',
        step2   : 'apf.roles.wizard.step2',
        step3   : 'apf.roles.wizard.step3',
        step4_1 : 'apf.roles.wizard.step4_1',
        step4_2 : 'apf.roles.wizard.step4_2',
        step5_1 : 'apf.roles.wizard.step5_1',
        step5_2 : 'apf.roles.wizard.step5_2',
    },
    modal: {
        title       : 'apf.roles.modal.title',
        description : 'apf.roles.modal.description',
    },
    permissionNames: {
        // System
        ASYNC_TASK_PROPERTY_SYNC: 'apf.roles.permissionNames.propertiesSync',

        // Owners
        OWNERS_CREATE               : 'apf.roles.permissionNames.create',
        OWNERS_READ                 : 'apf.roles.permissionNames.read',
        OWNER_UPDATE                : 'apf.roles.permissionNames.update',
        USERS_SEND_INVITATION_LINK  : 'apf.roles.permissionNames.resendInvitation',
        POINTS_STATEMENT            : 'apf.roles.permissionNames.pointsStament',
        CHECK_FINANCIAL_INFORMATION : 'apf.roles.permissionNames.checkFinancialStatus',
        CLIENTS_ONBOARDING_READ     : 'apf.roles.permissionNames.onboardingRead',
        CLIENTS_ONBOARDING_DELETE   : 'apf.roles.permissionNames.onboardingDelete',

        // Partners
        PARTNERS_CREATE : 'apf.roles.permissionNames.create',
        PARTNERS_READ   : 'apf.roles.permissionNames.read',
        PARTNERS_UPDATE : 'apf.roles.permissionNames.update',

        // Agents
        AGENTS_CREATE : 'apf.roles.permissionNames.create',
        AGENTS_READ   : 'apf.roles.permissionNames.read',
        AGENTS_UPDATE : 'apf.roles.permissionNames.update',
        AGENTS_DELETE : 'apf.roles.permissionNames.delete',

        // Contract Rules
        CONTRACT_RULES_CREATE : 'apf.roles.permissionNames.create',
        CONTRACT_RULES_READ   : 'apf.roles.permissionNames.read',
        CONTRACT_RULES_UPDATE : 'apf.roles.permissionNames.update',
        CONTRACT_RULES_DELETE : 'apf.roles.permissionNames.delete',

        // Wallet
        WALLETS_MANAGE            : 'apf.roles.permissionNames.walletsManage',
        BALANCES_MANAGE           : 'apf.roles.permissionNames.balancesManage',
        TRANSACTIONS_MANAGE       : 'apf.roles.permissionNames.transactionsManage',
        WALLET_ALLOCATION_CREATE  : 'apf.roles.permissionNames.walletAllocationCreate',
        WALLET_CONVERSION_CREATE  : 'apf.roles.permissionNames.walletConversionCreate',
        WALLET_READ               : 'apf.roles.permissionNames.read',
        WALLET_TRANSFERS          : 'apf.roles.permissionNames.walletTransfer',
        CONVERSIONS_READ          : 'apf.roles.permissionNames.conversionsRead',
        TRANSACTIONS_UPDATE       : 'apf.roles.permissionNames.transactionsUpdate',
        CONVERSIONS_REASON_UPDATE : 'apf.roles.permissionNames.conversionReasonUpdate',

        // Conversion rules
        CONTRACTS_CONVERSION_RULES_CREATE : 'apf.roles.permissionNames.create',
        CONTRACTS_CONVERSION_RULES_DELETE : 'apf.roles.permissionNames.delete',
        CONTRACTS_CONVERSION_RULES_UPDATE : 'apf.roles.permissionNames.update',
        CONTRACTS_CONVERSION_RULES_READ   : 'apf.roles.permissionNames.read',

        // Contract types conversion rules
        CONTRACT_TYPES_CONVERSION_RULES_CREATE : 'apf.roles.permissionNames.create',
        CONTRACT_TYPES_CONVERSION_RULES_DELETE : 'apf.roles.permissionNames.delete',
        CONTRACT_TYPES_CONVERSION_RULES_UPDATE : 'apf.roles.permissionNames.update',
        CONTRACT_TYPES_CONVERSION_RULES_READ   : 'apf.roles.permissionNames.read',

        // Club tiers conversion rules
        CLUB_TIERS_CONVERSION_RULES_CREATE : 'apf.roles.permissionNames.create',
        CLUB_TIERS_CONVERSION_RULES_DELETE : 'apf.roles.permissionNames.delete',
        CLUB_TIERS_CONVERSION_RULES_UPDATE : 'apf.roles.permissionNames.update',
        CLUB_TIERS_CONVERSION_RULES_READ   : 'apf.roles.permissionNames.read',

        // Contract
        OWNERS_CONTRACT_CREATE   : 'apf.roles.permissionNames.ownersContractCreate',
        OWNER_CONTRACTS_READ     : 'apf.roles.permissionNames.ownersContractRead',
        PARTNERS_CONTRACT_CREATE : 'apf.roles.permissionNames.partnersContractCreate',
        PARTNER_CONTRACTS_READ   : 'apf.roles.permissionNames.partnersContractRead',

        // Bookings
        BOOKINGS_DETAILS          : 'apf.roles.permissionNames.bookingsDetails',
        BOOKINGS_READ             : 'apf.roles.permissionNames.read',
        BOOKINGS_UPDATE           : 'apf.roles.permissionNames.update',
        BOOKING_CANCEL            : 'apf.roles.permissionNames.bookingsCancellation',
        OWNERS_BOOKINGS_READ      : 'apf.roles.permissionNames.ownersBookingsRead',
        PARTNERS_BOOKINGS_READ    : 'apf.roles.permissionNames.partnersBookingsRead',
        SKIP_PAYMENT_CHECK        : 'apf.roles.permissionNames.skipPayment',
        SKIP_CASH_PAYMENTS        : 'apf.roles.permissionNames.skipCashPayment',
        BOOKINGS_CREATE           : 'apf.roles.permissionNames.bookingsCreate',
        BOOKINGS_CHARGE_OR_REFUND : 'apf.roles.permissionNames.chargeOrRefund',
        EXCHANGE_PERFORM          : 'apf.roles.permissionNames.exchangePerform',
        WAIVE_PENALTIES           : 'apf.roles.permissionNames.waivePenalities',

        // Notes
        NOTES_CREATE : 'apf.roles.permissionNames.create',
        NOTES_READ   : 'apf.roles.permissionNames.read',
        NOTES_UPDATE : 'apf.roles.permissionNames.update',
        NOTES_DELETE : 'apf.roles.permissionNames.delete',

        // Tiers
        TIERS_CREATE : 'apf.roles.permissionNames.create',
        TIERS_READ   : 'apf.roles.permissionNames.read',
        TIERS_UPDATE : 'apf.roles.permissionNames.update',
        TIERS_DELETE : 'apf.roles.permissionNames.delete',

        // Balance Types
        BALANCE_TYPES_CREATE : 'apf.roles.permissionNames.create',
        BALANCE_TYPES_READ   : 'apf.roles.permissionNames.read',
        BALANCE_TYPES_UPDATE : 'apf.roles.permissionNames.update',
        BALANCE_TYPES_DELETE : 'apf.roles.permissionNames.delete',

        // Clubs
        CLUBS_CREATE : 'apf.roles.permissionNames.create',
        CLUBS_READ   : 'apf.roles.permissionNames.read',
        CLUBS_UPDATE : 'apf.roles.permissionNames.update',
        CLUBS_DELETE : 'apf.roles.permissionNames.delete',

        // Contract Types Rules
        CONTRACT_TYPE_RULES_CREATE  : 'apf.roles.permissionNames.create',
        CONTRACT_TYPE_RULES_READ    : 'apf.roles.permissionNames.read',
        CONTRACT_TYPE_RULES_UPDATE  : 'apf.roles.permissionNames.update',
        CONTRACT_TYPES_RULES_DELETE : 'apf.roles.permissionNames.delete',

        // System Rules
        SYSTEM_RULES_CREATE : 'apf.roles.permissionNames.create',
        SYSTEM_RULES_READ   : 'apf.roles.permissionNames.read',
        SYSTEM_RULES_UPDATE : 'apf.roles.permissionNames.update',
        SYSTEM_RULES_DELETE : 'apf.roles.permissionNames.delete',

        // Contract Types
        CONTRACT_TYPES_CREATE      : 'apf.roles.permissionNames.create',
        CONTRACT_TYPES_READ        : 'apf.roles.permissionNames.read',
        CONTRACT_TYPES_UPDATE      : 'apf.roles.permissionNames.update',
        CONTRACT_TYPES_DELETE      : 'apf.roles.permissionNames.delete',
        CONTRACT_TYPE_RULES_DELETE : 'apf.roles.permissionNames.delete',

        // Roles
        ROLES_CREATE : 'apf.roles.permissionNames.create',
        ROLES_READ   : 'apf.roles.permissionNames.read',
        ROLES_UPDATE : 'apf.roles.permissionNames.update',
        ROLES_DELETE : 'apf.roles.permissionNames.delete',

        // Upgrades
        UPGRADES_CREATE : 'apf.roles.permissionNames.upgradesCreate',
        UPGRADES_READ   : 'apf.roles.permissionNames.upgradesRead',
        UPGRADES_PATCH  : 'apf.roles.permissionNames.upgradesPatch',

        // Users
        USERS_CREATE       : 'apf.roles.permissionNames.usersCreate',
        USERS_READ         : 'apf.roles.permissionNames.usersRead',
        USERS_UPDATE       : 'apf.roles.permissionNames.usersUpdate',
        USERS_DISASSOCIATE : 'apf.roles.permissionNames.usersDisassociate',
        USER_RELATIONSHIPS : 'apf.roles.permissionNames.usersRelationships',

        // Users
        SYSTEM_USERS_CREATE         : 'apf.roles.permissionNames.create',
        SYSTEM_USERS_READ           : 'apf.roles.permissionNames.read',
        SYSTEM_USERS_UPDATE         : 'apf.roles.permissionNames.update',
        SYSTEM_USERS_DELETE         : 'apf.roles.permissionNames.delete',
        AGENTS_SEND_INVITATION_LINK : 'apf.roles.permissionNames.resendInvitation',

        // Reports
        REPORTS_READ: 'apf.roles.permissionNames.reportsRead',

        // Notifications
        NOTIFICATIONS_READ   : 'apf.roles.permissionNames.read',
        NOTIFICATIONS_UPDATE : 'apf.roles.permissionNames.notificationsUpdate',

        // External Clubs
        EXTERNAL_CLUBS_READ   : 'apf.roles.permissionNames.read',
        EXTERNAL_CLUBS_CREATE : 'apf.roles.permissionNames.create',
        EXTERNAL_CLUBS_UPDATE : 'apf.roles.permissionNames.update',
        EXTERNAL_CLUBS_DELETE : 'apf.roles.permissionNames.delete',

        // Rates
        RATES_READ   : 'apf.roles.permissionNames.read',
        RATES_CREATE : 'apf.roles.permissionNames.create',
        RATES_UPDATE : 'apf.roles.permissionNames.update',
        RATES_DELETE : 'apf.roles.permissionNames.delete',

        // Attachments
        ATTACHMENTS_READ   : 'apf.roles.permissionNames.read',
        ATTACHMENTS_CREATE : 'apf.roles.permissionNames.create',
        ATTACHMENTS_UPDATE : 'apf.roles.permissionNames.update',
        ATTACHMENTS_DELETE : 'apf.roles.permissionNames.delete',

        // Attachments categories
        ATTACHMENT_CATEGORIES_READ   : 'apf.roles.permissionNames.read',
        ATTACHMENT_CATEGORIES_CREATE : 'apf.roles.permissionNames.create',
        ATTACHMENT_CATEGORIES_UPDATE : 'apf.roles.permissionNames.update',
        ATTACHMENT_CATEGORIES_DELETE : 'apf.roles.permissionNames.delete',

        // Extra Attributes
        EXTRA_ATTRIBUTES_READ   : 'apf.roles.permissionNames.read',
        EXTRA_ATTRIBUTES_CREATE : 'apf.roles.permissionNames.create',
        EXTRA_ATTRIBUTES_UPDATE : 'apf.roles.permissionNames.update',
        EXTRA_ATTRIBUTES_DELETE : 'apf.roles.permissionNames.delete',

        ATTRIBUTES_READ   : 'apf.roles.permissionNames.read',
        ATTRIBUTES_CREATE : 'apf.roles.permissionNames.create',
        ATTRIBUTES_UPDATE : 'apf.roles.permissionNames.update',
        ATTRIBUTES_DELETE : 'apf.roles.permissionNames.delete',

        // Property groups
        PROPERTY_GROUPS_READ       : 'apf.roles.permissionNames.read',
        PROPERTY_GROUPS_CREATE     : 'apf.roles.permissionNames.create',
        PROPERTY_GROUPS_UPDATE     : 'apf.roles.permissionNames.update',
        PROPERTY_GROUPS_DELETE     : 'apf.roles.permissionNames.delete',
        PROPERTY_GROUPS_RULES_READ : 'apf.roles.permissionNames.rulesRead',

        // Property groups
        CONTRACTS_CONVERSION_RULE_READ   : 'apf.roles.permissionNames.read',
        CONTRACTS_CONVERSION_RULE_CREATE : 'apf.roles.permissionNames.create',
        CONTRACTS_CONVERSION_RULE_UPDATE : 'apf.roles.permissionNames.update',
        CONTRACTS_CONVERSION_RULE_DELETE : 'apf.roles.permissionNames.delete',

        // Property groups
        EXCHANGE_OVERRIDE_RULES_READ   : 'apf.roles.permissionNames.read',
        EXCHANGE_OVERRIDE_RULES_CREATE : 'apf.roles.permissionNames.create',
        EXCHANGE_OVERRIDE_RULES_UPDATE : 'apf.roles.permissionNames.update',
        EXCHANGE_OVERRIDE_RULES_DELETE : 'apf.roles.permissionNames.delete',

        PROPERTIES_RULES_READ: 'apf.roles.permissionNames.rulesRead',

        ACCOUNT_STATEMENTS_READ   : 'apf.roles.permissionNames.read',
        ACCOUNT_STATEMENTS_REPORT : 'apf.roles.permissionNames.report',
        ACCOUNT_STATEMENTS_UPDATE : 'apf.roles.permissionNames.update',

        // PUBLIC
        read          : 'apf.roles.permissionNames.read',
        importInit    : 'apf.roles.permissionNames.importInit',
        importList    : 'apf.roles.permissionNames.importList',
        importReadAll : 'apf.roles.permissionNames.importReadAll',

        owners                      : { title: 'apf.roles.permissionNames.owners' },
        partners                    : { title: 'apf.roles.permissionNames.partners' },
        notes                       : { title: 'apf.roles.permissionNames.notes' },
        tiers                       : { title: 'apf.roles.permissionNames.tiers' },
        reports                     : { title: 'apf.roles.permissionNames.reports' },
        wallet                      : { title: 'apf.roles.permissionNames.wallet' },
        roles                       : { title: 'apf.roles.permissionNames.roles' },
        users                       : { title: 'apf.roles.permissionNames.users' },
        attachments                 : { title: 'apf.roles.permissionNames.attachments' },
        clubs                       : { title: 'apf.roles.permissionNames.clubs' },
        contractTypes               : { title: 'apf.roles.permissionNames.contractTypes' },
        agents                      : { title: 'apf.roles.permissionNames.agents' },
        notifications               : { title: 'apf.roles.permissionNames.notifications' },
        contracts                   : { title: 'apf.roles.permissionNames.contracts' },
        contractTypesRules          : { title: 'apf.roles.permissionNames.contractTypesRules' },
        externalClubs               : { title: 'apf.roles.permissionNames.externalClubs' },
        contractsConversionRule     : { title: 'apf.roles.permissionNames.contractsConversionRule' },
        contractTypesConversionRule : { title: 'apf.roles.permissionNames.contractTypesConversionRule' },
        clubTiersConversionRule     : { title: 'apf.roles.permissionNames.clubTiersConversionRule' },
        attachmentCategories        : { title: 'apf.roles.permissionNames.attachmentCategories' },
        extraAttributes             : { title: 'apf.roles.permissionNames.extraAttributes' },
        rates                       : { title: 'apf.roles.permissionNames.rates' },
        configurations              : { title: 'apf.roles.permissionNames.configurations' },
        systemRules                 : { title: 'apf.roles.permissionNames.systemRules' },
        upgrades                    : { title: 'apf.roles.permissionNames.upgrades' },
        balanceTypes                : { title: 'apf.roles.permissionNames.balanceTypes' },
        contractsRules              : { title: 'apf.roles.permissionNames.contractsRules' },
        bookings                    : {
            title                   : 'apf.roles.permissionNames.bookings',
            hotelInfo               : 'apf.roles.permissionNames.hotelInfo',
            hotelInfoByPropertyCode : 'apf.roles.permissionNames.hotelInfoByPropertyCode',
        },
        properties            : { title: 'apf.roles.permissionNames.properties', list: 'apf.roles.permissionNames.list' },
        languages             : { title: 'apf.roles.permissionNames.languages', list: 'apf.roles.permissionNames.list' },
        propertyGroups        : { title: 'apf.roles.permissionNames.propertyGroups', list: 'apf.roles.permissionNames.list' },
        exchangeOverrideRules : { title: 'apf.roles.permissionNames.exchangeOverrideRules', list: 'apf.roles.permissionNames.list' },
        requiredResources     : { title: 'apf.roles.permissionNames.requiredResources', read: 'apf.roles.permissionNames.read' },
        accountStatements     : { title: 'apf.roles.permissionNames.accountStatements' },
    },
});
