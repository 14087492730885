import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Table from 'Components/Table';
import Subsection from 'Components/Sections/Subsection';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import BankingConfigurationEditor from './Editor';
import PanelContext from 'State/panelContext';

export function BankingConfigurations({
    balanceTypes,
    elements,
    useBankingConfigurationBalance,
    isTemplate,
    contractData,
    isAddEnabled,
    onAdd,
    isEditEnabled,
    onEdit,
    isEditEnabledWhen,
    isRemoveEnabled,
    onRemove,
    isRemoveEnabledWhen,
    isEnabled,
    disableWarning,
    resources,
    showValidation,
    headerButtons,
}) {
    const { navigator, snackbar } = useContext(PanelContext);

    const [bankingModal, setBankingModal] = useState({ isVisible: false, data: {} });
    const [balanceTypesInfo, setBalanceTypesInfo] = useState({ loaded: !!balanceTypes?.length, list: balanceTypes });

    useEffect(() => {
        async function fetch() {
            try {
                const response = await navigator.directRequest({ ...resources.available.init });

                if (response?.additionalResources?.balanceTypes) {
                    setBalanceTypesInfo({ loaded: true, list: response.additionalResources.balanceTypes });
                }
            } catch (e) {
                snackbar.show({
                    content : translated.owners.contracts.edition.bankingConfiguration.initError,
                    isError : true,
                });
            }
        }

        if (!balanceTypes?.length && resources?.available?.init) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (balanceTypes?.length && balanceTypesInfo.loaded?.length !== balanceTypes.length) {
            setBalanceTypesInfo({ loaded: !!balanceTypes?.length, list: balanceTypes });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceTypes]);

    const addBankingConfiguration = () => {
        setBankingModal((prev) => ({ ...prev, isVisible: true, data: {}, isEditing: false }));
    };

    const editBankingConfiguration = (bankingConfiguration) => {
        setBankingModal((prev) => ({
            ...prev,
            isVisible : true,
            data      : { ...bankingConfiguration, balanceType: bankingConfiguration?.balanceType?.id },
            isEditing : true,
        }));
    };

    const bankingButtons = [...headerButtons];

    if (isAddEnabled && onAdd && elements?.length) {
        bankingButtons.push(
            <Button
                id="banking-configurations-add-button"
                variant="outlined"
                color="primary"
                onClick={addBankingConfiguration}
                key="add_banking"
                disabled={!isEnabled || !balanceTypesInfo.loaded}
            >
                <FormattedMessage defaultMessage={translated.global.buttons.add} id={translated.global.buttons.add} />
            </Button>,
        );
    }

    const bankingModalOnCancel = () => {
        setBankingModal((prev) => ({ ...prev, isVisible: false }));
    };

    const bankingModalOnSave = async (bankingConfiguration) => {
        const mustClose = bankingConfiguration.id && onEdit ? await onEdit(bankingConfiguration) : await onAdd(bankingConfiguration);

        if (mustClose) {
            setBankingModal((prev) => ({ ...prev, isVisible: false }));
        } else {
            throw new Error();
        }
    };

    const enabledActions = [];

    if (isEditEnabled && onEdit) {
        enabledActions.push({
            key               : 'edit',
            content           : translated.global.buttons.edit,
            callback          : editBankingConfiguration,
            isOutsideDropdown : true,
            icon              : 'Pencil',
            tooltip           : translated.global.buttons.edit,
            disabled          : !isEnabled || !balanceTypesInfo.loaded,
            when              : isEditEnabledWhen || null,
        });
    }

    if (isRemoveEnabled && onRemove) {
        enabledActions.push({
            key          : 'delete',
            content      : translated.global.buttons.delete,
            callback     : onRemove,
            confirmation : {
                message       : translated.owners.contracts.edition.bankingConfiguration.deleteConfirmation,
                acceptMessage : translated.global.buttons.confirm,
            },
            isOutsideDropdown : true,
            icon              : 'TrashCan',
            tooltip           : translated.global.buttons.delete,
            disabled          : !isEnabled,
            when              : isRemoveEnabledWhen || null,
        });
    }

    const convertedElements = elements.map((e) => ({
        ...e,
        balanceTypeName : useBankingConfigurationBalance ? e.balanceType?.name : balanceTypesInfo?.list?.find((bt) => bt.id === e.balanceType?.id)?.name,
        localName       : e.local
            ? translated.owners.contracts.edition.bankingConfiguration.applyContract
            : translated.owners.contracts.edition.bankingConfiguration.applyGlobal,
        className: showValidation && !e.isValid ? 'is-invalid' : '',
    }));

    const datesColumns = isTemplate
        ? []
        : [
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.effectiveDate,
                key       : 'effectiveDate',
                isVisible : true,
            },
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.expiryDate,
                key       : 'expiryDate',
                isVisible : true,
            },
        ];

    const otherColumns = isTemplate
        ? []
        : [
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.repeatAnniversary,
                key       : 'repeatAnniversary',
                isVisible : true,
            },
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.amount,
                key       : 'amount',
                isVisible : true,
            },
        ];

    return (
        <Subsection
            title={translated.owners.contracts.edition.bankingConfiguration.title}
            actionButtons={bankingButtons}
        >
            {bankingModal.isVisible && (
                <BankingConfigurationEditor
                    title={
                        bankingModal.data?.id
                            ? translated.owners.contracts.edition.bankingConfiguration.editTitle
                            : translated.owners.contracts.edition.bankingConfiguration.createTitle
                    }
                    data={bankingModal.data}
                    balanceTypes={balanceTypesInfo.list}
                    onSave={bankingModalOnSave}
                    onCancel={bankingModalOnCancel}
                    isTemplate={isTemplate}
                    contractData={contractData}
                    isEditing={bankingModal.isEditing}
                />
            )}

            {!isEnabled && disableWarning && (
                <Alert
                    id="banking-configurations-info"
                    type="info"
                    content={disableWarning}
                />
            )}

            {isEnabled
            && elements?.find((e) => e.isValid === false) && ( // Only when isValid is defined and "false"
                <Alert
                    id="banking-configurations-warning"
                    type="warning"
                    content={translated.owners.contracts.edition.bankingConfiguration.invalidBankingConfigurationWarning}
                />
            )}

            <Table
                id="banking-configurations"
                key="banking-configurations"
                hasTools={false}
                columns={[
                    {
                        title     : translated.owners.contracts.edition.bankingConfiguration.balanceType,
                        key       : 'balanceTypeName',
                        isVisible : true,
                    },
                    {
                        title     : translated.owners.contracts.edition.bankingConfiguration.applyOn,
                        key       : 'localName',
                        isVisible : true,
                    },
                    ...datesColumns,
                    {
                        title     : translated.owners.contracts.edition.bankingConfiguration.repeatDelta,
                        key       : 'repeatDelta',
                        isVisible : true,
                    },
                    ...otherColumns,
                    {
                        title     : translated.owners.contracts.edition.bankingConfiguration.shoulderLeft,
                        key       : 'shoulderLeft',
                        isVisible : true,
                    },
                    {
                        title     : translated.owners.contracts.edition.bankingConfiguration.shoulderRight,
                        key       : 'shoulderRight',
                        isVisible : true,
                    },
                ]}
                rows={{ actions: [...enabledActions] }}
                items={convertedElements}
                whenEmpty={
                    (!isEnabled && disableWarning)
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        ? <></>
                        : (
                            <Alert
                                id="banking-configurations-empty"
                                content={translated.owners.contracts.edition.bankingConfiguration.empty}
                                buttonId="banking-configurations-add-button"
                                actionText={isAddEnabled && onAdd ? translated.global.buttons.add : null}
                                onClick={isAddEnabled && onAdd ? addBankingConfiguration : null}
                                actionDisabled={isAddEnabled && onAdd ? !isEnabled : null}
                            />
                        )
                }
            />
        </Subsection>
    );
}

BankingConfigurations.defaultProps = {
    link                           : null,
    elements                       : [],
    isAddEnabled                   : true,
    onAdd                          : null,
    isEditEnabled                  : false,
    onEdit                         : null,
    isEditEnabledWhen              : null,
    isRemoveEnabled                : true,
    onRemove                       : null,
    isRemoveEnabledWhen            : null,
    isTemplate                     : false,
    isEnabled                      : true,
    disableWarning                 : '',
    resources                      : null,
    showValidation                 : true,
    useBankingConfigurationBalance : false,
    headerButtons                  : [],
};

BankingConfigurations.propTypes = {
    balanceTypes                   : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    link                           : PropTypes.shape({}),
    elements                       : PropTypes.arrayOf(PropTypes.shape({})),
    contractData                   : PropTypes.shape({}).isRequired,
    isAddEnabled                   : PropTypes.bool,
    onAdd                          : PropTypes.func,
    isEditEnabled                  : PropTypes.bool,
    onEdit                         : PropTypes.func,
    isEditEnabledWhen              : PropTypes.func,
    isRemoveEnabled                : PropTypes.bool,
    onRemove                       : PropTypes.func,
    isRemoveEnabledWhen            : PropTypes.func,
    isTemplate                     : PropTypes.bool,
    isEnabled                      : PropTypes.bool,
    disableWarning                 : PropTypes.string,
    resources                      : PropTypes.shape({}),
    showValidation                 : PropTypes.bool,
    useBankingConfigurationBalance : PropTypes.bool,
    headerButtons                  : PropTypes.arrayOf(PropTypes.element),
};

export default BankingConfigurations;
