import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import withRequest from 'Components/Sections/withRequest';

const getActions = (handleClickChangeStatus, handleOnEditClick) => {
    const actions = [
        {
            content  : translated.global.buttons.disable,
            callback : (tag) => handleClickChangeStatus(tag, false),
            key      : 'disable',
            when     : (tag) => tag?.links?.self?.patch && tag.isActive,
        },
        {
            content  : translated.global.buttons.enable,
            callback : (tag) => handleClickChangeStatus(tag, true),
            key      : 'enable',
            when     : (tag) => tag?.links?.self?.patch && !tag.isActive,
        },
        {
            content           : translated.global.buttons.edit,
            callback          : handleOnEditClick,
            key               : 'edit',
            isOutsideDropdown : true,
            icon              : 'Pencil',
            tooltip           : translated.global.buttons.edit,
            when              : (tag) => tag?.links?.self?.patch,
        },
    ];

    return actions;
};

const convertList = (list) => list?.map((each) => ({
    ...each,
    convertedCategories: each.categories?.join(', ') || '',
})) || [];

function List({
    data, sections, fetching, reloadData, resources, setSectionState, state: { appliedFilters },
}) {
    const { navigator, snackbar } = useContext(PanelContext);
    const handleOnAddClick = () => navigator.goToTagsEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });

    const handleOnEditClick = (tag) => {
        navigator.goToTagsEditor({ available: tag && tag.links ? tag.links.self : {} });
    };

    const handleClickChangeStatus = async (tag, enable) => {
        const { id, links } = tag;
        const reqConfig = {
            ...links.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.TAGS, resources });

            snackbar.show({ content: enable ? translated.tags.enable.success : translated.tags.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content     : enable ? translated.tags.enable.success : translated.tags.disable.success,
                errorLabels : translated.tags.errors,
                isError     : true,
                error,
            });
        }
    };

    const { links, data: list, order, pagination, filter: filterLinks } = data || {};

    const tags = convertList(list);

    const rows = { actions: getActions(handleClickChangeStatus, handleOnEditClick) };

    const { Section, props: subsectionProps } = Sections.get(sections);

    const filterResources = {
        filters: [
            {
                type        : 'text',
                submitKey   : 'slug',
                resourceKey : 'slug',
                label       : translated.tags.slug,
            },
            {
                type        : 'text',
                submitKey   : 'label',
                resourceKey : 'label',
                label       : translated.tags.label,
            },
            {
                type        : 'select',
                submitKey   : 'isActive',
                resourceKey : 'isActive',
                label       : translated.global.status,
                options     : [
                    { id: 'true', name: translated.global.state.enabled },
                    { id: 'false', name: translated.global.state.disabled },
                ],
            },
        ],
        filterLinks,
        resources,
    };

    const columns = [
        {
            title     : translated.tags.id,
            key       : 'id',
            isVisible : true,
        },
        {
            title     : translated.tags.slug,
            key       : 'slug',
            isVisible : true,
        },
        {
            title     : translated.tags.label,
            key       : 'label',
            isVisible : true,
        },
        {
            title     : translated.tags.status,
            key       : 'isActive',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
        },
    ];

    const actionButtons = links?.self?.create
        ? [
            <Button id="tags-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <>
            {Section && <Section {...subsectionProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            <Wrapper title={translated.tags.title} actionButtons={actionButtons}>
                <Table
                    id="tags"
                    key="tags"
                    columns={columns}
                    rows={rows}
                    items={tags}
                    onSearch={() => {
                        // Default
                    }}
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    filterResources={filterResources}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config);
                    }}
                    appliedFilters={appliedFilters}
                    whenEmpty={
                        <Alert id="tags-empty" content={translated.tags.empty} actionText={translated.global.buttons.add} onClick={handleOnAddClick} />
                    }
                />
            </Wrapper>
        </>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={100} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    data            : [],
    fetching        : {},
    state           : null,
    setSectionState : null,
};

List.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

export default withRequest(List);
