module.exports = Object.freeze({
    title         : 'Extra attributes',
    empty         : 'There are no extra attributes',
    createTitle   : 'Create extra attribute',
    editTitle     : 'Edit extra attribute',
    updateFailed  : 'The extra attribute could not be updated',
    updateSuccess : 'The extra attribute has been updated',
    saveSuccess   : 'The extra attribute has been added',
    selection     : 'Select extra attributes',

    name       : 'Name',
    categories : 'Categories',
    slug       : 'Slug',
    dataType   : 'Data type',
    hidden     : 'Is hidden',
    status     : 'Status',
    isActive   : 'Is active',

    confirmation: {
        title   : 'Are you sure',
        message : 'Make sure that the field \'Slug\' is valid, you will no longer be able to modify it',
    },

    options: {
        empty: {
            title   : 'No options found',
            message : 'Please, add an option using the \'Modify List\' button',
        },

        title      : 'Options',
        editButton : 'Modify List',
        option     : 'Option',

        repeated: 'There can\'t be repeated options',

        edition: {
            title     : 'Modify Options',
            addButton : 'Add new',

            errors: {
                repeated : 'There is an element repeated',
                length   : 'Please add at least 2 options',
            },
        },
    },

    enable: {
        success : 'The extra attribute has been enabled',
        error   : 'The extra attribute could not be enabled',
    },

    disable: {
        success : 'The extra attribute has been disabled',
        error   : 'The extra attribute could not be disabled',
    },
});
