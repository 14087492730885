import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { useDeepEffect } from 'Hooks';
import Form from 'Components/Form';
import Grid from 'Components/Grid';
import { useFormContext } from 'react-hook-form';

function ConversionGridInputs({ id, balance, hasFee, source, destination, firstLoad }) {
    const { setValue, trigger, watch } = useFormContext();

    useEffect(
        () => () => {
            setValue(`${id}`, null, { shouldValidate: true });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [id, setValue],
    );

    useEffect(() => {
        // We set the field, to use it in the 'fee' and 'minimum' validations
        setValue(`${id}.isPaymentFloat`, !balance?.currency?.integer, { shouldValidate: true });
    }, [balance, id, setValue, source]);

    const isEnabled = watch(`${id}.isEnabled`);

    useEffect(() => {
        // When the conversion is added, it should be enabled by default
        if (!firstLoad && isEnabled == null) {
            setValue(`${id}.isEnabled`, true, { shouldValidate: true });
        }
    }, [firstLoad, id, isEnabled, setValue]);

    // We force all the conversion fields validations, to update the validations when adding a value to one field, or removing the last one.
    const forceValidations = () => {
        setTimeout(() => {
            // To avoid visual errors with the invalid fields
            trigger([`${id}.rate`, `${id}.fee`, `${id}.minimum`]);
        }, 100);
    };

    return (
        <Grid className="conversions-block-grid" key={`grid-${id}`}>
            <Grid.Column width={{ base: 12, small: 3 }}>
                <div className="flex flex-middle">
                    <Form.InputNew
                        isDense
                        className="margin-remove"
                        submitKey={`${id}.isEnabled`}
                        name={`${id}.isEnabled`}
                        type="checkbox"
                        onChange={forceValidations}
                    />
                    <span key={`${destination?.id}-name`} className={isEnabled ? '' : 'text-color-muted'}>
                        {destination?.name}
                    </span>
                </div>
            </Grid.Column>

            <Grid.Column width={{ base: 12, small: 9 }}>
                <div className="conversions-block-grid-inputs">
                    <Form.InputNew
                        className="margin-remove"
                        submitKey={`${id}.rate`}
                        name={`${id}.rate`}
                        label={translated.conversionRules.editorAlt.rate}
                        isDense
                        type="number"
                        onChange={forceValidations}
                        isDisabled={!isEnabled}
                    />

                    {hasFee && (
                        <Form.InputNew
                            className="margin-remove-top margin-left-small"
                            submitKey={`${id}.fee`}
                            name={`${id}.fee`}
                            label={translated.conversionRules.editorAlt.fee}
                            isDense
                            type="number"
                            suffix={balance?.currency?.shorthand_code}
                            onChange={forceValidations}
                            isDisabled={!isEnabled}
                        />
                    )}

                    <Form.InputNew
                        className="margin-remove-top margin-left-small"
                        submitKey={`${id}.minimum`}
                        name={`${id}.minimum`}
                        label={translated.conversionRules.editorAlt.minValue}
                        isDense
                        type="number"
                        onChange={forceValidations}
                        isDisabled={!isEnabled}
                    />
                </div>
            </Grid.Column>
        </Grid>
    );
}
ConversionGridInputs.defaultProps = {
    hasFee    : true,
    firstLoad : true,
};

ConversionGridInputs.propTypes = {
    id          : PropTypes.string.isRequired,
    balance     : PropTypes.shape({}).isRequired,
    hasFee      : PropTypes.bool,
    source      : PropTypes.shape({}).isRequired,
    destination : PropTypes.shape({}).isRequired,
    firstLoad   : PropTypes.bool,
};

function ConversionGrid({ xAxis, yAxis, balance, hasFee, firstLoad }) {
    const [headers, setHeaders] = useState({ x: [], y: [] });

    useDeepEffect(() => {
        const convertedXHeaders = [];
        const convertedYHeaders = [];

        if (xAxis?.length) {
            xAxis
                .filter((e) => e) // We filter the balance that are selected but not available
                .forEach((each) => {
                    if (each) {
                        // To avoid problems when dealing with balance not available
                        convertedXHeaders.push({
                            name      : each.name,
                            id        : `x-${each.id}`,
                            balanceId : each.id,
                            tierLevel : each.level,
                            element   : each,
                        });
                    }
                });
        }

        if (yAxis?.length) {
            yAxis
                .filter((e) => e) // We filter the balance that are selected but not available
                .forEach((each) => {
                    convertedYHeaders.push({
                        name      : each.name,
                        id        : `y-${each.id}`,
                        balanceId : each.id,
                        tierLevel : each.level,
                        element   : each,
                    });
                });
        }

        setHeaders({ x: convertedXHeaders, y: convertedYHeaders });
    }, [xAxis, yAxis]);

    const isTierConversionInvalid = (first, second) => first.balanceId === second.balanceId && first.tierLevel <= second.tierLevel;

    if (!xAxis?.length || !yAxis?.length) {
        return null;
    }

    return (
        <div className="conversions-form-group">
            <Form.Title>
                <span className="display-block text-small font-weight-bold text-color-secondary">
                    <FormattedMessage
                        id={translated.conversionRules.editorAlt.balanceLabel}
                        defaultMessage={translated.conversionRules.editorAlt.balanceLabel}
                    />
                </span>
                {balance.name}
            </Form.Title>

            {headers.y.map((eachY) => (
                <div className="conversions-block margin-left-small" key={`${eachY.id}-section`}>
                    <Grid>
                        <Grid.Column width={{ base: 12, medium: 1 }}>
                            <span className="text-small font-weight-bold text-color-secondary margin-right-small">
                                <FormattedMessage
                                    id={translated.conversionRules.editorAlt.sourceLabel}
                                    defaultMessage={translated.conversionRules.editorAlt.sourceLabel}
                                />
                            </span>
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, medium: 11 }}>
                            <span className="font-weight-bold" key={`${eachY.id}-name`}>
                                {eachY.name}
                            </span>
                        </Grid.Column>
                    </Grid>

                    <Grid className="margin-top-small">
                        <Grid.Column width={{ base: 12, medium: 1 }}>
                            <span className="text-small font-weight-bold text-color-secondary margin-right-small margin-top-small display-block">
                                <FormattedMessage
                                    id={translated.conversionRules.editorAlt.destinationLabel}
                                    defaultMessage={translated.conversionRules.editorAlt.destinationLabel}
                                />
                            </span>
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, medium: 11 }}>
                            {headers.x.map((eachX) => {
                                const key = `${balance.id}-${eachX.id}-${eachY.id}`;

                                if (isTierConversionInvalid(eachX, eachY)) {
                                    return null;
                                }

                                return (
                                    <ConversionGridInputs
                                        id={`conversions.${key}`}
                                        balance={balance}
                                        hasFee={hasFee}
                                        source={eachY}
                                        destination={eachX}
                                        firstLoad={firstLoad}
                                    />
                                );
                            })}
                        </Grid.Column>
                    </Grid>
                </div>
            ))}
        </div>
    );
}

ConversionGrid.defaultProps = {
    xAxis     : null,
    yAxis     : null,
    hasFee    : true,
    firstLoad : true,
};

ConversionGrid.propTypes = {
    xAxis     : PropTypes.arrayOf(PropTypes.shape({})),
    yAxis     : PropTypes.arrayOf(PropTypes.shape({})),
    balance   : PropTypes.shape({}).isRequired,
    hasFee    : PropTypes.bool,
    firstLoad : PropTypes.bool,
};

export default React.memo(ConversionGrid);
