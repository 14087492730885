import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import List from './List';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Table from 'Components/Table';
import Alert from 'Components/Alert';
import Loading from 'Components/Loading';

export function BankingConfigurations({
    data,
    fetching,
    resources,
    isEmbedded,
    isTemplate,
    contractData,
    isEnabled,
    disableWarning,
    isEditEnabled,
    showValidation,
    showHistory,
}) {
    const { navigator, snackbar } = useContext(PanelContext);

    const [history, setHistory] = useState({ isLoaded: false, isLoading: false, list: [] });

    const loadHistory = async (link) => {
        try {
            setHistory((prev) => ({ ...prev, isLoading: true }));
            const contractTypes = await navigator.directRequest(link);

            setHistory({
                isLoaded  : true,
                isLoading : false,
                list      :
                    contractTypes?.data?.map((e) => ({
                        ...e,
                        balanceTypeName : e.balanceType?.name,
                        localName       : e.local ? (
                            <FormattedMessage
                                defaultMessage={translated.owners.contracts.edition.bankingConfiguration.applyContract}
                                id={translated.owners.contracts.edition.bankingConfiguration.applyContract}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage={translated.owners.contracts.edition.bankingConfiguration.applyGlobal}
                                id={translated.owners.contracts.edition.bankingConfiguration.applyGlobal}
                            />
                        ),
                    })) || [],
            });
        } catch (e) {
            snackbar.show({
                content : translated.owners.contracts.edition.bankingConfiguration.history.initError,
                isError : true,
            });
        }
    };

    useEffect(() => {
        // When the list changes, we update the history
        const { filter } = data;

        if (showHistory && filter?.query && filter?.fields?.isDeleted) {
            const link = {
                ...filter.query,
                url: `${filter.query.url + filter.fields.isDeleted}true`,
            };

            loadHistory(link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onAdd = async (newElement) => {
        try {
            const { links } = data;

            const reqConfig = {
                ...links.self.create,
                isCritical       : false,
                isGlobal         : false,
                shouldReloadData : true,
                data             : newElement,
            };

            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BANKING_CONFIGURATIONS, resources, isEmbedded });

            snackbar.show({
                content   : translated.owners.contracts.edition.bankingConfiguration.create.success,
                isSuccess : true,
            });

            return true;
        } catch (error) {
            snackbar.show({
                isError : true,
                content : translated.owners.contracts.edition.bankingConfiguration.create.error,
            });

            return false;
        }
    };

    const onEdit = async (newData) => {
        try {
            const elementToUpdate = data?.data?.find((e) => e.id === newData.id);

            const reqConfig = {
                ...elementToUpdate.links.self.update,
                isCritical       : false,
                isGlobal         : false,
                shouldReloadData : true,
                data             : newData,
            };

            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BANKING_CONFIGURATIONS, resources, isEmbedded });

            snackbar.show({
                content   : translated.owners.contracts.edition.bankingConfiguration.edit.success,
                isSuccess : true,
            });

            return true;
        } catch (error) {
            snackbar.show({
                content : translated.owners.contracts.edition.bankingConfiguration.edit.error,
                isError : true,
            });

            return false;
        }
    };

    const onRemove = async (elementToRemove) => {
        try {
            const { id, links } = elementToRemove;
            const reqConfig = {
                ...links.self.delete,
                isCritical       : false,
                isGlobal         : false,
                ids              : [id],
                shouldReloadData : true,
            };

            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BANKING_CONFIGURATIONS, resources, isEmbedded });

            snackbar.show({
                content   : translated.owners.contracts.edition.bankingConfiguration.delete.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                isError : true,
                content : translated.owners.contracts.edition.bankingConfiguration.delete.error,
            });
        }
    };

    const datesColumns = isTemplate
        ? []
        : [
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.effectiveDate,
                key       : 'effectiveDate',
                isVisible : true,
            },
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.expiryDate,
                key       : 'expiryDate',
                isVisible : true,
            },
        ];

    const otherColumns = isTemplate
        ? []
        : [
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.repeatAnniversary,
                key       : 'repeatAnniversary',
                isVisible : true,
            },
            {
                title     : translated.owners.contracts.edition.bankingConfiguration.amount,
                key       : 'amount',
                isVisible : true,
            },
        ];

    const handleRestoreHistory = async (bankingConfiguration) => {
        try {
            const { id, links } = bankingConfiguration;
            const reqConfig = {
                ...links.self.patch,
                isCritical       : false,
                isGlobal         : false,
                ids              : [id],
                shouldReloadData : true,
            };

            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BANKING_CONFIGURATIONS, resources, isEmbedded });

            snackbar.show({
                content   : translated.owners.contracts.edition.bankingConfiguration.history.restoreSuccess,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                content : translated.owners.contracts.edition.bankingConfiguration.history.restoreError,
                isError : true,
                error,
            });
        }
    };

    return (
        <>
            <List
                elements={data?.data}
                balanceTypes={data?.additionalResources?.balanceTypes}
                useBankingConfigurationBalance
                isTemplate={isTemplate}
                isAddEnabled={isEditEnabled}
                onAdd={data?.links?.self?.create ? onAdd : null}
                isEditEnabled={isEditEnabled}
                onEdit={onEdit}
                isEditEnabledWhen={(each) => each?.links?.self?.update}
                isRemoveEnabled={isEditEnabled}
                onRemove={onRemove}
                isRemoveEnabledWhen={(each) => each?.links?.self?.delete}
                contractData={contractData}
                isEnabled={isEnabled}
                disableWarning={disableWarning}
                resources={resources}
                showValidation={showValidation}
            />

            {showHistory && history.isLoading && <Loading />}

            {showHistory && history.isLoaded && (
                <Table
                    id="banking-configurations-history"
                    key="banking-configurations-history"
                    className="margin-top-small margin-bottom-small"
                    title={translated.owners.contracts.edition.bankingConfiguration.history.title}
                    canCollapse
                    columns={[
                        {
                            title     : translated.owners.contracts.edition.bankingConfiguration.balanceType,
                            key       : 'balanceTypeName',
                            isVisible : true,
                        },
                        {
                            title     : translated.owners.contracts.edition.bankingConfiguration.applyOn,
                            key       : 'localName',
                            isVisible : true,
                        },
                        ...datesColumns,
                        {
                            title     : translated.owners.contracts.edition.bankingConfiguration.repeatDelta,
                            key       : 'repeatDelta',
                            isVisible : true,
                        },
                        ...otherColumns,
                        {
                            title     : translated.owners.contracts.edition.bankingConfiguration.shoulderLeft,
                            key       : 'shoulderLeft',
                            isVisible : true,
                        },
                        {
                            title     : translated.owners.contracts.edition.bankingConfiguration.shoulderRight,
                            key       : 'shoulderRight',
                            isVisible : true,
                        },
                    ]}
                    rows={{
                        actions: [
                            {
                                key               : 'restore',
                                content           : translated.global.buttons.restore,
                                when              : (each) => each?.links?.self?.patch,
                                tooltip           : translated.global.buttons.restore,
                                isOutsideDropdown : true,
                                icon              : 'Restore',
                                callback          : handleRestoreHistory,
                            },
                        ],
                    }}
                    items={history.list}
                    whenEmpty={
                        data?.data?.length
                            ? (
                                <Alert
                                    id="banking-configurations-history-empty"
                                    content={translated.owners.contracts.edition.bankingConfiguration.history.empty}
                                    className="margin-top-small"
                                />

                            )
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            : <></>
                    }
                    loadingIds={fetching.ids}
                />
            )}
        </>
    );
}

BankingConfigurations.defaultProps = {
    isTemplate     : false,
    isEmbedded     : false,
    isEnabled      : true,
    disableWarning : '',
    isEditEnabled  : false,
    showValidation : true,
    showHistory    : true,
    fetching       : {},
};

BankingConfigurations.propTypes = {
    data           : PropTypes.shape({}).isRequired,
    fetching       : PropTypes.shape({}),
    resources      : PropTypes.shape({}).isRequired,
    isEmbedded     : PropTypes.bool,
    isTemplate     : PropTypes.bool,
    contractData   : PropTypes.shape({}).isRequired,
    isEnabled      : PropTypes.bool,
    disableWarning : PropTypes.string,
    isEditEnabled  : PropTypes.bool,
    showValidation : PropTypes.bool,
    showHistory    : PropTypes.bool,
};

BankingConfigurations.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="animated fade-in-up-small fastest">
                <Skeleton.Table hasTitle />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="animated fade-in-up-small fastest delay-01">
                <Skeleton.Table hasTitle />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="animated fade-in-up-small fastest delay-02">
                <Skeleton.Table hasTitle />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(BankingConfigurations);
