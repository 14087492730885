import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';
import PanelContext from 'State/panelContext';
import { useEventScroll } from 'Hooks';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

// NOTE This component was NOT used in a form with a submit, it might need some rework to use correctly the form submitValue

function Search({
    id,
    value,
    label,
    onFocus,
    onBlur,
    isDisabled,
    onChange,
    onChangeKey,
    searchValidation, // Function that decides when the search value is valid to search
    searchTimeout, // Timeout used to trigger the search after an input change
    searchFunction, // Function that executes the search
    onSearch, // When this is received, the result is handled by the component that rendered this Search
    onSearchStart, // Function used to tell the component that renders this Search, when a search starts
    onSearchEnd, // Function used to tell the component that renders this Search, when a search ends
    onSearchError, // When this is received, the error is handled by the component that rendered this Search
    children,
    renderSearch, // When the results are rendered in the search as a Dropdown, this function decide how to render them
    onSearchSelect, // Triggered once an element is selected
    showDefaultError, // Flag to indicate when to show a generic error message
    showLoading,
    innerInputRef,
    inputValue, // Used to set the initial value shown in the search input
}) {
    const { snackbar } = useContext(PanelContext);

    const [search, setSearch] = useState({
        input    : inputValue || value,
        enabled  : !!renderSearch,
        isOpen   : false,
        elements : [],
        error    : null,
        loading  : false,
    });

    const timerRef = useRef(null);
    const searchBoxRef = useRef();

    const updateComputedStyles = useCallback(() => {
        if (
            innerInputRef.current
            && innerInputRef.current.getBoundingClientRect()
            && searchBoxRef.current
            && searchBoxRef.current.getBoundingClientRect()
        ) {
            const { top, left, height } = innerInputRef.current.getBoundingClientRect();
            const { height: searchBoxHeight } = searchBoxRef.current.getBoundingClientRect();

            const computedTop = top + height + searchBoxHeight <= window.innerHeight ? top + height : top - searchBoxHeight;

            searchBoxRef.current.style.top = `${computedTop}px`;
            searchBoxRef.current.style.left = `${left}px`;
        }
    }, [innerInputRef]);

    useEffect(() => {
        if (search.isOpen) {
            updateComputedStyles();
        }
    }, [search.isOpen, updateComputedStyles]);

    useEffect(() => {
        if (!value && search.input) {
            // We reset the value shown on form resets
            setSearch((prev) => ({ ...prev, input: value }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEventScroll(innerInputRef.current, updateComputedStyles, () => search.isOpen);

    const onInputChange = (event) => {
        const { value: newValue } = event.target;
        setSearch((previous) => ({ ...previous, input: newValue, isOpen: false }));

        if (searchValidation && !searchValidation(newValue)) {
            // The value is not valid for a search.
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }

            return null;
        }

        if (onChange) {
            onChange(event);
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        timerRef.current = setTimeout(async () => {
            timerRef.current = null;

            setSearch((previous) => ({
                ...previous,
                elements : [],
                error    : null,
                isOpen   : false,
                loading  : true,
            }));

            try {
                if (onSearchStart) {
                    onSearchStart();
                }

                const result = await searchFunction(newValue);

                if (onSearchEnd) {
                    onSearchEnd();
                }

                if (onSearch) {
                    onSearch(result);
                }

                setSearch((previous) => ({
                    ...previous,
                    elements : Array.isArray(result) ? result : [],
                    error    : null,
                    isOpen   : true,
                    loading  : false,
                }));
            } catch (requestError) {
                setSearch((previous) => ({
                    ...previous,
                    elements : [],
                    error    : requestError,
                    isOpen   : false,
                    loading  : false,
                }));

                if (onSearchError) {
                    onSearchError(requestError);
                }

                if (showDefaultError) {
                    snackbar.show({
                        content : translated.global.search.error,
                        isError : true,
                    });
                }
            }
        }, searchTimeout || 500);

        return null;
    };

    const handleCloseSearchResults = () => {
        setSearch({
            input    : '',
            enabled  : !!renderSearch,
            isOpen   : false,
            elements : [],
            error    : null,
        });
    };

    return (
        <div className="form-field-input form-icon is-dense">
            {showLoading && <Icon className="mdi-icon" spin={search.loading} path={search.loading ? IconPath.mdiLoading : IconPath.mdiMagnify} />}
            <input
                id={id}
                type="text"
                value={search.input}
                onChange={onInputChange}
                className="search-input"
                disabled={isDisabled}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={isDisabled ? '-1' : '0'}
                ref={innerInputRef}
            />
            {label && (
                <label className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {children && (
                // Show the filter icon when it received filter options on the children prop.
                <Button icon="Eye" />
            )}
            {search.enabled && search.isOpen && (
                <ul id={`${id}-search-result`} className="search-box" ref={searchBoxRef}>
                    <li className="search-result search-result-title-wrapper">
                        <div className="search-result-title">Search results</div>
                        <Button id={`${id}-search-close`} icon="Close" onClick={handleCloseSearchResults} key="search-result-close" />
                    </li>
                    {search?.elements?.length === 0 && (
                        <li className="search-result no-results" key="search-result-empty">
                            {renderSearch(null)}
                        </li>
                    )}
                    {search?.elements?.length > 0
                        && search.elements.map((eachElement) => (
                            <li
                                className="search-result"
                                key={`search-result-${eachElement.id}`}
                                onClick={() => {
                                    setSearch((previous) => ({
                                        ...previous,
                                        input  : eachElement.display,
                                        isOpen : false,
                                    }));
                                    onSearchSelect(eachElement);
                                    if (onChangeKey) {
                                        onChange({ target: { value: eachElement[onChangeKey], label: eachElement.display } });
                                    }
                                }}
                            >
                                {renderSearch(eachElement)}
                            </li>
                        ))}
                </ul>
            )}
            {children && (
                <ul id={`search-filter-${id}`} className="search-box" ref={searchBoxRef}>
                    {
                        // TODO For each child, render an option of the filter.
                    }
                </ul>
            )}
        </div>
    );
}

Search.defaultProps = {
    value            : '',
    onChangeKey      : '',
    isDisabled       : false,
    showDefaultError : true,
    showLoading      : false,
    searchValidation : null,
    searchTimeout    : null,
    onChange         : null,
    children         : null,
    onSearch         : null,
    onSearchStart    : null,
    onSearchEnd      : null,
    onSearchError    : null,
    renderSearch     : null,
    label            : null,
    innerInputRef    : null,
    onSearchSelect   : () => {
        // Default
    },
    onFocus: () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    inputValue: null,
};

Search.propTypes = {
    id               : PropTypes.string.isRequired,
    value            : PropTypes.string,
    label            : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChangeKey      : PropTypes.string,
    isDisabled       : PropTypes.bool,
    searchValidation : PropTypes.func,
    searchTimeout    : PropTypes.number,
    searchFunction   : PropTypes.func.isRequired,
    onChange         : PropTypes.func,
    onSearch         : PropTypes.func,
    onSearchStart    : PropTypes.func,
    onSearchEnd      : PropTypes.func,
    onSearchError    : PropTypes.func,
    children         : PropTypes.shape({}),
    renderSearch     : PropTypes.func,
    onSearchSelect   : PropTypes.func,
    onFocus          : PropTypes.func,
    onBlur           : PropTypes.func,
    showDefaultError : PropTypes.bool,
    showLoading      : PropTypes.bool,
    innerInputRef    : PropTypes.shape({}),
    inputValue       : PropTypes.string,
};

export default Search;
