import React, { useContext } from 'react';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';

function RatesList({ data, fetching, reloadData }) {
    const { navigator } = useContext(PanelContext);

    const { data: list, order, pagination } = data || {};

    const rows = {
        actions: [
            {
                callback          : (currency) => navigator.goToRatesEditor({ available: currency.links.self }),
                key               : 'view',
                isOutsideDropdown : true,
                getIcon           : (currency) => (currency?.links?.levelUpgradeRates?.create ? 'Pencil' : 'Eye'),
                getTooltip        : (currency) => (currency?.links?.levelUpgradeRates?.create
                    ? translated.global.buttons.edit
                    : translated.global.buttons.view
                ),
                when: (currency) => currency?.links?.levelUpgradeRates?.read,
            },
        ],
    };

    return (
        <Wrapper title={translated.currencies.title}>
            <Table
                id="currencies"
                key="currencies"
                columns={[
                    {
                        title     : translated.currencies.name,
                        key       : 'name',
                        isVisible : true,
                    },
                    {
                        title     : translated.currencies.code,
                        key       : 'code',
                        isVisible : true,
                    },
                    {
                        title     : translated.currencies.linear,
                        key       : 'linear',
                        isVisible : true,
                        whenTrue  : {
                            text      : translated.global.state.yes,
                            className : 'status status-enabled',
                        },
                        whenFalse: {
                            text      : translated.global.state.no,
                            className : 'status status-disabled',
                        },
                    },
                ]}
                rows={rows}
                items={list}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="currencies-empty"
                        content={translated.currencies.empty}
                    />
                )}
            />
        </Wrapper>
    );
}

RatesList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Title isHeading width={220} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={5} hasPagination={false} />
            </Grid.Column>
        </Grid>
    );
};

RatesList.defaultProps = {
    data     : [],
    fetching : {},
};

RatesList.propTypes = {
    sections   : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({}),
    fetching   : PropTypes.shape({}),
    reloadData : PropTypes.func.isRequired,
};

export default withRequest(RatesList);
