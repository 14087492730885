import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { restrictedAppReasons } from 'Constants/global';
import Button from 'Components/Button';
import { logout } from 'Utils/authMethods';

function RestrictedAppScreen({ message, reason }) {
    const renderTitle = () => {
        switch (reason) {
            case restrictedAppReasons.MAINTENANCE:
                return (
                    <h3 className="error-code">
                        {message || (
                            <FormattedMessage
                                id={translated.global.restrictedApp.maintenance.title}
                                defaultMessage={translated.global.restrictedApp.maintenance.title}
                            />
                        )}
                    </h3>
                );

            case restrictedAppReasons.NO_PERMISSIONS:
                return (
                    <h3 className="error-code">
                        <FormattedMessage
                            id={translated.global.restrictedApp.noPermissions.title}
                            defaultMessage={translated.global.restrictedApp.noPermissions.title}
                        />
                    </h3>
                );

            default:
                return (
                    <h3 className="error-code">
                        {message || (
                            <FormattedMessage
                                id={translated.global.restrictedApp.maintenance.title}
                                defaultMessage={translated.global.restrictedApp.maintenance.title}
                            />
                        )}
                    </h3>
                );
        }
    };

    const renderSubTitle = () => {
        switch (reason) {
            case restrictedAppReasons.MAINTENANCE:
                return (
                    <h4 className="error-message">
                        <FormattedMessage
                            id={translated.global.restrictedApp.maintenance.subTitle}
                            defaultMessage={translated.global.restrictedApp.maintenance.subTitle}
                        />
                    </h4>
                );

            case restrictedAppReasons.NO_PERMISSIONS:
                return (
                    <h4 className="error-message">
                        <FormattedMessage
                            id={translated.global.restrictedApp.noPermissions.subTitle}
                            defaultMessage={translated.global.restrictedApp.noPermissions.subTitle}
                        />
                    </h4>
                );

            default:
                return (
                    <h4 className="error-message">
                        <FormattedMessage
                            id={translated.global.restrictedApp.maintenance.subTitle}
                            defaultMessage={translated.global.restrictedApp.maintenance.subTitle}
                        />
                    </h4>
                );
        }
    };

    return (
        <div className="error-wrapper restricted-message">
            <div className="error-content">
                <div className="error-text">
                    {renderTitle()}
                    {renderSubTitle()}
                    {reason === restrictedAppReasons.NO_PERMISSIONS ? (
                        <Button id="logout-button" variant="unelevated" color="primary" onClick={() => logout()}>
                            <FormattedMessage id={translated.settings.buttons.logout} defaultMessage={translated.settings.buttons.logout} />
                        </Button>
                    ) : null}
                </div>
                <img className="moonland" src="/static/img/moonland.svg" alt="moon" />
                {reason === restrictedAppReasons.NO_PERMISSIONS && <img className="astronaut4" src="/static/img/astronaut4.svg" alt="astronaut" />}
                {reason === restrictedAppReasons.MAINTENANCE && (
                    <>
                        <img className="platform" src="/static/img/platform.svg" alt="platform" />
                        <img className="astronaut3" src="/static/img/astronaut3.svg" alt="astronaut" />
                    </>
                )}
            </div>
        </div>
    );
}

RestrictedAppScreen.defaultProps = { message: null, reason: null };
RestrictedAppScreen.propTypes = { message: PropTypes.string, reason: PropTypes.string };

export default RestrictedAppScreen;
