import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import { startDateInput, endDateInput } from './constants';
import IconPath from 'Utils/IconsPath';
import Button from 'Components/Button';

/**
 * Component that draws the header of the calendar. It shows the date(s) selected and the button to change
 * between the view of the calendar and the text input.
 */
function PickerCalendarHeader({
    label,
    labelFrom,
    labelTo,
    editableInput,
    showInputDate,
    isRange,
    getSelectedDay,
    getSelectedStartDay,
    getSelectedEndDay,
    onTypeSelect,
    onStartDayClick,
    onEndDayClick,
}) {
    const intl = useIntl();

    return (
        <div className="calendar-head">
            <small className="calendar-head-title">{label}</small>
            <div className="calendar-head-date-wrapper">
                <h4 className="calendar-head-date-selected">
                    {!isRange
                        && (getSelectedDay() || labelFrom || (
                            <FormattedMessage id={translated.global.calendar.dateLabel} defaultMessage={translated.global.calendar.dateLabel} />
                        ))}
                    {isRange && (
                        <>
                            <span className={`${editableInput === startDateInput ? 'is-editing' : ''}`} onClick={onStartDayClick}>
                                {getSelectedStartDay() || labelFrom || (
                                    <FormattedMessage id={translated.global.calendar.fromLabel} defaultMessage={translated.global.calendar.fromLabel} />
                                )}
                            </span>
                            <Icon className="mdi-icon color-text-disabled" path={IconPath.mdiArrowRight} />
                            <span className={`${editableInput === endDateInput ? 'is-editing' : ''}`} onClick={onEndDayClick}>
                                {getSelectedEndDay() || labelTo || (
                                    <FormattedMessage id={translated.global.calendar.toLabel} defaultMessage={translated.global.calendar.toLabel} />
                                )}
                            </span>
                        </>
                    )}
                </h4>
                <Button
                    id="calendar-switch"
                    className="margin-left-medium"
                    icon={showInputDate ? 'Calendar' : 'FormTextbox'}
                    tooltip={intl.formatMessage({
                        id             : translated.global.calendar[showInputDate ? 'switchToCalendar' : 'switchToInput'],
                        defaultMessage : translated.global.calendar[showInputDate ? 'switchToCalendar' : 'switchToInput'],
                    })}
                    onClick={onTypeSelect}
                />
            </div>
        </div>
    );
}

PickerCalendarHeader.propTypes = {
    label               : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    labelFrom           : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    labelTo             : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    editableInput       : PropTypes.string,
    showInputDate       : PropTypes.bool.isRequired,
    isRange             : PropTypes.bool.isRequired,
    getSelectedDay      : PropTypes.func.isRequired,
    getSelectedStartDay : PropTypes.func.isRequired,
    getSelectedEndDay   : PropTypes.func.isRequired,
    onTypeSelect        : PropTypes.func.isRequired,
    onStartDayClick     : PropTypes.func.isRequired,
    onEndDayClick       : PropTypes.func.isRequired,
};

PickerCalendarHeader.defaultProps = {
    labelFrom     : null,
    labelTo       : null,
    editableInput : null,
};

export default React.memo(PickerCalendarHeader);
