module.exports = Object.freeze({
    title : 'Currencies',
    empty : 'There are no currencies',

    name   : 'Name',
    linear : 'Linear',
    code   : 'Code',

    editor: {
        information : 'Information',
        rates       : 'Upgrade rates',

        from  : 'From',
        to    : 'To',
        value : 'Rate',
        type  : 'Type',
        types : {
            perStay  : 'Per Stay',
            perNight : 'Per Night',
        },

        addRateSuccess    : 'The rate has been added',
        removeRateSuccess : 'The rate has been removed',
        editRateSuccess   : 'The rate has been updated',

        errors: {
            ratesInitError  : 'The currencies rates could not be initialized',
            addRateError    : 'The rate could not be added',
            removeRateError : 'The rate could not be removed',
            editRateError   : 'The rate could not be updated',
        },
    },
});
