import { mdiCalendarMultiselect } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

export default {
    [sectionType.RESERVATIONS]: {
        config    : { url: '/panel/bookings', icon: mdiCalendarMultiselect },
        isEnabled : false,
        sections  : {
            [sectionType.RESERVATIONS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.RESERVATIONS } },
                config    : {
                    isDefaultSection     : true,
                    isHiddenInBreadcrumb : true,
                    resourceName         : resourceNames.RESERVATIONS,
                    requestTimeout       : 20000,
                },
            },
        },
    },
};
