import React, { useContext, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';

const getActions = (navigator, handleClickChangeStatus, handleClickDelete) => [
    {
        content  : translated.global.buttons.disable,
        callback : (attribute) => handleClickChangeStatus(attribute, false),
        key      : 'disable',
        when     : (attribute) => attribute?.links?.self?.patch && attribute.isActive,
    },
    {
        content  : translated.global.buttons.enable,
        callback : (attribute) => handleClickChangeStatus(attribute, true),
        key      : 'enable',
        when     : (attribute) => attribute?.links?.self?.patch && !attribute.isActive,
    },
    {
        content  : translated.global.buttons.delete,
        callback : (attribute) => handleClickDelete(attribute),
        key      : 'delete',
        when     : (attribute) => attribute?.links?.self?.delete,
    },
    {
        callback          : (attribute) => navigator.goToExtraAttributesEditor({ available: attribute.links.self }),
        key               : 'view',
        isOutsideDropdown : true,
        getIcon           : (attribute) => (attribute?.links?.self?.patch ? 'Pencil' : 'Eye'),
        getTooltip        : (attribute) => (attribute?.links?.self?.patch ? translated.global.buttons.edit : translated.global.buttons.view),
        when              : (attribute) => attribute?.links?.self?.read,
    },
];

const convertList = (list) => list?.map((each) => ({
    ...each,
    convertedCategories: each.categories?.join(', ') || '',
})) || [];

function ExtraAttributesList({ data, fetching, reloadData, resources, setSectionState, state: { appliedFilters } }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const handleClickPerformDelete = useCallback(async (extraAttribute) => {
        try {
            const reqConfig = {
                ...extraAttribute.links.self.delete,
                isCritical       : false,
                isGlobal         : false,
                ids              : [extraAttribute.id],
                shouldReloadData : true,
            };
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.EXTRA_ATTRIBUTES, resources });

            snackbar.show({
                content   : translated.extraAttributes.delete.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                error,
                isError     : true,
                errorLabels : translated.extraAttributes.delete,
            });
        }
    }, [navigator, resources, snackbar]);
    const handleClickDelete = useCallback((attribute) => {
        confirmation.show({
            message       : translated.extraAttributes.delete.confirmation,
            acceptMessage : translated.global.buttons.delete,
            onAccept      : () => handleClickPerformDelete(attribute),
        });
    }, [confirmation, handleClickPerformDelete]);
    const handleOnAddClick = useCallback(() => navigator.goToExtraAttributesEditor({ available: data && data.links ? data.links.self : {}, current: 'init' }), [data, navigator]);

    const handleClickChangeStatus = async (attribute, enable) => {
        const { id, links } = attribute;
        const reqConfig = {
            ...links.self.patch,
            data: {
                isActive   : enable,
                name       : attribute.name,
                categories : attribute.categories,
                dataType   : attribute.dataType,
                options    : attribute.options,
            },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.EXTRA_ATTRIBUTES, resources });

            snackbar.show({ content: enable ? translated.extraAttributes.enable.success : translated.extraAttributes.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.extraAttributes.enable.error : translated.extraAttributes.disable.error,
                isError : true,
                error,
            });
        }
    };

    const { links, data: list, order, pagination, filter: filterLinks } = data || {};

    const attributes = convertList(list);

    const rows = { actions: getActions(navigator, handleClickChangeStatus, handleClickDelete) };

    const categoriesOptions = filterLinks?.additionalResources?.categories.map((category) => ({ id: category, name: category }));

    const filterResources = useMemo(() => (
        {
            filters: [
                {
                    type        : 'text',
                    submitKey   : 'name',
                    resourceKey : 'name',
                    label       : translated.extraAttributes.name,
                },
                {
                    type        : 'text',
                    submitKey   : 'slug',
                    resourceKey : 'slug',
                    label       : translated.extraAttributes.slug,
                },
                {
                    type        : 'select',
                    submitKey   : 'isActive',
                    resourceKey : 'isActive',
                    label       : translated.global.status,
                    options     : [
                        {
                            id   : 'true',
                            name : translated.global.state.enabled,
                        },
                        {
                            id   : 'false',
                            name : translated.global.state.disabled,
                        },
                    ],
                },
                {
                    type        : 'select',
                    submitKey   : 'hidden',
                    resourceKey : 'hidden',
                    label       : translated.extraAttributes.hidden,
                    options     : [
                        {
                            id   : 'true',
                            name : translated.global.buttons.yes,
                        },
                        {
                            id   : 'false',
                            name : translated.global.buttons.no,
                        },
                    ],
                },
                {
                    type        : 'select',
                    submitKey   : 'category',
                    resourceKey : 'category',
                    label       : translated.extraAttributes.categories,
                    options     : categoriesOptions,
                },
            ],
            filterLinks,
            resources,
        }
    ), [categoriesOptions, filterLinks, resources]);

    const columns = useMemo(() => ([
        {
            title     : translated.extraAttributes.name,
            key       : 'name',
            isVisible : true,
        },
        {
            title     : translated.extraAttributes.categories,
            key       : 'convertedCategories',
            isVisible : true,
        },
        {
            title     : translated.extraAttributes.slug,
            key       : 'slug',
            isVisible : true,
        },
        {
            title     : translated.extraAttributes.dataType,
            key       : 'dataType',
            isVisible : true,
        },
        {
            title     : translated.extraAttributes.hidden,
            key       : 'hidden',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.yes,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.no,
                className : 'status status-disabled',
            },
        },
        {
            title     : translated.extraAttributes.status,
            key       : 'isActive',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
        },
    ]), []);

    const actionButtons = useMemo(() => (
        links?.self?.create
            ? [
                <Button id="attributes-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                    <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                </Button>,
            ]
            : []
    ), [handleOnAddClick, links?.self?.create]);

    return (
        <Wrapper title={translated.extraAttributes.title} actionButtons={actionButtons}>
            <Table
                id="attributes"
                key="attributes"
                columns={columns}
                rows={rows}
                items={attributes}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                filterResources={filterResources}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                whenEmpty={(
                    <Alert
                        id="attributes-empty"
                        content={translated.extraAttributes.empty}
                        actionText={translated.global.buttons.add}
                        onClick={handleOnAddClick}
                    />
                )}
            />
        </Wrapper>
    );
}

ExtraAttributesList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={320} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination={false} />
            </Grid.Column>
        </Grid>
    );
};

ExtraAttributesList.defaultProps = {
    data            : [],
    fetching        : {},
    state           : null,
    setSectionState : null,
};

ExtraAttributesList.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

export default withRequest(ExtraAttributesList);
