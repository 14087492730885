import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Quantity({
    label,
    value,
    onChange,
    onFocus,
    onBlur,
    isReadOnlyWithoutInputs,
    onClickMinus,
    onClickPlus,
    minDisabled,
    maxDisabled,
    isDisabled,
    id,
    innerInputRef,
}) {
    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {!isReadOnlyWithoutInputs && (
                <>
                    <Button
                        id={`${id}-minus`}
                        className="quantity-remove"
                        icon="Minus"
                        onClick={onClickMinus}
                        disabled={minDisabled}
                        tabIndex={isDisabled ? '-1' : '0'}
                        isMultiClickEnabled
                    />
                    <input
                        id={id}
                        type="text"
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        tabIndex={isDisabled ? '-1' : '0'}
                        autoComplete="off"
                        ref={innerInputRef}
                    />
                    <Button
                        id={`${id}-plus`}
                        className="quantity-add"
                        icon="Plus"
                        onClick={onClickPlus}
                        disabled={maxDisabled}
                        tabIndex={isDisabled ? '-1' : '0'}
                        isMultiClickEnabled
                    />
                </>
            )}
            {isReadOnlyWithoutInputs && <span id={id}>{value}</span>}
        </>
    );
}

Quantity.defaultProps = {
    id       : '',
    label    : '',
    value    : '',
    onChange : null,
    onFocus  : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onClickMinus: () => {
        // Default
    },
    onClickPlus: () => {
        // Default
    },
    isReadOnlyWithoutInputs : false,
    minDisabled             : false,
    maxDisabled             : false,
    isDisabled              : false,
    innerInputRef           : null,
};

Quantity.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.number,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onClickMinus            : PropTypes.func,
    onClickPlus             : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    minDisabled             : PropTypes.bool,
    maxDisabled             : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Quantity);
