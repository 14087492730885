import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import Button from 'Components/Button';
import Dropdown from 'Components/Dropdown';
import PropTypes from 'prop-types';
import { notificationTypes } from 'Constants/global';
import translated from 'Constants/labels/translated';
import Notification from './Notification';
import Loading from 'Components/Loading';

function Notifications({
    isLoading, data, amount, error, update, updateAmount, isEnabled,
}) {
    const intl = useIntl();
    const { snackbar, navigator, dateManager } = useContext(PanelContext);

    if (!isEnabled) {
        return null;
    }

    const onMarkAsReadClick = async (notification) => {
        if (notification.read) {
            return;
        }

        const { links } = notification;

        if (!links?.self?.patch) {
            snackbar.show({
                content : translated.notifications.markAsReadNoLink,
                isError : true,
            });
            return;
        }

        try {
            await navigator.directRequest({ ...links?.self?.patch, data: { read: true } });

            update();
            updateAmount();
        } catch (e) {
            snackbar.show({
                error   : e,
                content : translated.notifications.markAsReadError,
                isError : true,
            });
        }
    };

    const onNotificationClick = (notification) => {
        switch (notification.type) {
            case notificationTypes.CONVERSION:
                const owner = notification?.data?.client || {};

                if (owner?.links?.self?.read) {
                    navigator.goToBalances(owner);

                    onMarkAsReadClick(notification);
                } else {
                    snackbar.show({
                        content : translated.notifications.navigateToNotificationError,
                        isError : true,
                    });
                }

                break;
            default:
                break;
        }
    };

    const onNotificationsOpen = () => {
        update();
        updateAmount();
    };

    return (
        <div id="user-notifications-wrapper" className="user-notifications-wrapper">
            <Dropdown
                id="notifications"
                className="user-notifications-button"
                classNameMenu="notifications-dropdown"
                icon="BellOutline"
                onOpen={onNotificationsOpen}
                isLoading={isLoading && !data}
                tooltip={intl.formatMessage({
                    id             : translated.notifications.title,
                    defaultMessage : translated.notifications.title,
                })}
            >
                {(!isLoading || data) && (
                    <>
                        {error && (
                            <Dropdown.Option className="dropdown-menu-item-header" isEnabled={false} key="notification-error" itemKey="notification-error">
                                <div className="user-notification-title">{error}</div>
                            </Dropdown.Option>
                        )}
                        {!error && (
                            <>
                                <Dropdown.Option
                                    className="dropdown-menu-item-header"
                                    isEnabled={false}
                                    key="notification-title"
                                    itemKey="notification-title"
                                >
                                    {isLoading && <Loading />}
                                    <div className="user-notification-title">
                                        <FormattedMessage id={translated.notifications.title} defaultMessage={translated.notifications.title} />
                                    </div>
                                    <span className="user-notification-quantity">{amount}</span>
                                </Dropdown.Option>
                                <div className="dropdown-menu-item-wrapper">
                                    {data
                                        && data.map((eachNotification) => (
                                            <Dropdown.Option
                                                id={`notification${eachNotification.id}`}
                                                key={`notification${eachNotification.id}`}
                                                itemKey={`notification${eachNotification.id}`}
                                                onClick={() => {
                                                    onNotificationClick(eachNotification);
                                                }}
                                            >
                                                <Notification notification={eachNotification} dateManager={dateManager} />
                                                {!eachNotification.read && (
                                                    <Button
                                                        id={`notification${eachNotification.id}-mark-button`}
                                                        className="margin-left-small"
                                                        icon="CheckAll"
                                                        tooltip={intl.formatMessage({
                                                            id             : translated.notifications.markAsRead,
                                                            defaultMessage : translated.notifications.markAsRead,
                                                        })}
                                                        onClick={(e) => {
                                                            // Without this, the click of the Dropdown.Options is triggered
                                                            // According to the react doc's 'preventDefault' is the correct event to use, but it works with 'stopPropagation'.
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onMarkAsReadClick(eachNotification);
                                                        }}
                                                    />
                                                )}
                                            </Dropdown.Option>
                                        ))}

                                    {(!data || !data.length) && (
                                        <Dropdown.Option
                                            className="user-notification-header"
                                            isEnabled={false}
                                            key="notification-empty-list"
                                            itemKey="notification-empty-list"
                                        >
                                            <div className="user-notification-title">
                                                <FormattedMessage
                                                    id={translated.notifications.emptyList}
                                                    defaultMessage={translated.notifications.emptyList}
                                                />
                                            </div>
                                        </Dropdown.Option>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </Dropdown>
            {amount > 0 && <div className="user-notification-mark" />}
        </div>
    );
}

Notifications.defaultProps = { error: null, data: null };

Notifications.propTypes = {
    isLoading    : PropTypes.bool.isRequired,
    data         : PropTypes.arrayOf(PropTypes.shape({})),
    amount       : PropTypes.number.isRequired,
    error        : PropTypes.string,
    update       : PropTypes.func.isRequired,
    updateAmount : PropTypes.func.isRequired,
    isEnabled    : PropTypes.bool.isRequired,
};

export default Notifications;
