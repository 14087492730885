import React from 'react';
import PropTypes from 'prop-types';

function Form({ type, quantity, className, smallInput }) {
    const input = (
        <div className="skeleton-element skeleton-form-input" />
    );

    const checkbox = (
        <div className="skeleton-form-checkboxes">
            <div className="skeleton-element skeleton-form-checkbox" />
            <div className="skeleton-element skeleton-form-input" />
        </div>
    );

    const radio = (
        <div className="skeleton-form-radios">
            <div className="skeleton-element skeleton-form-radio" />
            <div className="skeleton-element skeleton-form-input" />
        </div>
    );

    const inputWithCheckbox = (
        <div className="skeleton-form-input-with-checkbox">
            <div className="skeleton-element skeleton-form-checkbox" />
            <div className="skeleton-element skeleton-form-input" />
        </div>
    );

    let selectedInput = '';
    switch (type) {
        case 'input': selectedInput = input; break;
        case 'checkbox': selectedInput = checkbox; break;
        case 'radio': selectedInput = radio; break;
        case 'inputWithCheckbox': selectedInput = inputWithCheckbox; break;
        default: break;
    }

    return (
        <div className={`skeleton skeleton-form ${smallInput ? 'skeleton-form-small' : ''} ${className}`}>
            {Array.apply(0, Array(quantity)).map((x) => (
                { ...selectedInput, key: { x } }
            ))}
        </div>
    );
}

Form.defaultProps = {
    className  : '',
    quantity   : 1,
    smallInput : false,
};

Form.propTypes = {
    type       : PropTypes.string.isRequired,
    className  : PropTypes.string,
    quantity   : PropTypes.number,
    smallInput : PropTypes.bool,
};

export default Form;
