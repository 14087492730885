import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import Button from 'Components/Button';
import { getOwnerName } from './utils';

const isValidPhoneInput = (phone) => phone && /\d+$/.test(String(phone));

export function OwnersCard({ id, owner, configureModal }) {
    return (
        <>
            <Form isReadOnlyWithoutInputs id={id}>
                {owner.email && (
                    <Form.Input
                        id={`${id}-email`}
                        value={owner.email}
                        type="text"
                        label={translated.global.email}
                    />
                )}
                {isValidPhoneInput(owner.phone) && (
                    <Form.Input
                        id={`${id}-phone`}
                        value={owner.phone}
                        type="text"
                        label={translated.global.phone}
                        linkHref={`tel:${owner.phone}`}
                        linkClassName="clickable-link"
                    />
                )}
                {isValidPhoneInput(owner.mobile) && (
                    <Form.Input
                        id={`${id}-mobile`}
                        value={owner.mobile}
                        type="text"
                        label={translated.global.mobile}
                        linkHref={`tel:${owner.mobile}`}
                        linkClassName="clickable-link"
                    />
                )}
                {owner.birthday && (
                    <Form.Input
                        id={`${id}-birthday`}
                        value={owner.birthday}
                        type="text"
                        label={translated.global.birthday}
                    />
                )}
                {owner.language && (
                    <Form.Input
                        id={`${id}-language`}
                        value={owner.language}
                        type="text"
                        label={translated.global.language}
                    />
                )}
                {owner.loginToken && (
                    <Form.Input
                        id={`${id}-login-token`}
                        value={owner.loginToken}
                        type="text"
                        label={translated.global.loginToken}
                    />
                )}
            </Form>
            {owner.extraAttributes && Object.keys(owner.extraAttributes).length > 0 && (
                <div className="card-extra-attributes">
                    <span>
                        <FormattedMessage defaultMessage={translated.extraAttributes.title} id={translated.extraAttributes.title} />
                    </span>
                    <Button
                        id={`${id}-details-button`}
                        variant="text"
                        color="primary"
                        className="button-details"
                        onClick={() => configureModal({
                            isVisible : true,
                            items     : owner.extraAttributes,
                            type      : 'owner',
                            selected  : { name: { value: getOwnerName(owner.extraAttributes?.salutation?.value, owner.firstName, owner.lastName) } },
                        })}
                    >
                        <FormattedMessage defaultMessage={translated.global.buttons.viewDetails} id={translated.global.buttons.viewDetails} />
                    </Button>
                </div>
            )}
        </>
    );
}

OwnersCard.defaultProps = {};

OwnersCard.propTypes = {
    id             : PropTypes.string.isRequired,
    owner          : PropTypes.shape({}).isRequired,
    configureModal : PropTypes.func.isRequired,
};

export default OwnersCard;
