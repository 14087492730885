module.exports = Object.freeze({
    sectionTitle                        : 'Conversion Rules',
    noWalletsConfig                     : 'There are no conversions',
    addWalletConfigWhenUnsaved          : 'It is not possible to add conversions until the Contract Type is saved',
    addExchangeOverrideRulesWhenUnsaved : 'It is not possible to add exchange rules until the Contract Type is saved',

    conversionRate : 'Rate',
    conversionFee  : 'Fee',
    club           : 'External Club',
    minValue       : 'Min value',
    allowedPoints  : 'Allowed Points',

    enable: {
        success : 'The conversion rule has been enabled',
        error   : 'The conversion rule could not be enabled',
    },
    disable: {
        success : 'The conversion rule has been disabled',
        error   : 'The conversion rule could not disabled',
    },

    delete: {
        title   : 'Are you sure to delete the conversion configuration?',
        success : 'The conversion rule has been removed',
        error   : 'The conversion rule could not be removed',
    },

    editor: {
        title                    : 'Add configuration',
        conversionHelperText1    : 'One point corresponds to',
        conversionHelperText2    : 'points',
        conversionRate           : 'Conversion Rate',
        conversionFee            : 'Conversion Fee',
        conversionFeeSupportText : 'It is the amount that will be charged. If this is not configured, the conversions will have no administrative charges',
        club                     : 'External Club',
        minValue                 : 'Min value',
        allowedPoints            : 'Allowed Points',

        errors: {
            default                         : 'The conversion configuration could not be updated',
            allowedPointRequired            : 'Select at least one allowed point',
            conversionRuleExternalClubInUse : 'There is a conversion configuration for the selected external club',
        },
    },

    listAlt: {
        sectionTitle    : 'Exchange rules',
        noWalletsConfig : 'There are no exchange rules',

        conversionRate : 'Rate',
        conversionFee  : 'Fee',
        club           : 'External Club',
        minValue       : 'Min value',
        allowedPoints  : 'Allowed Points',

        enable: {
            success : 'The exchange rule has been enabled',
            error   : 'The exchange rule could not be enabled',
        },
        disable: {
            success : 'The exchange rule has been disabled',
            error   : 'The exchange rule could not disabled',
        },

        delete: {
            title   : 'Are you sure to delete the exchange rule?',
            success : 'The exchange rule has been removed',
            error   : 'The exchange rule could not be removed',
        },
    },

    editorAlt: {
        title                    : 'New exchange rule',
        titleEdition             : 'Edit exchange rule',
        conversionHelperText1    : 'One point corresponds to',
        conversionHelperText2    : 'points',
        conversionRate           : 'Conversion Rate',
        conversionFee            : 'Conversion Fee',
        conversionFeeSupportText : 'It is the amount that will be charged. If this is not configured, the conversions will have no administrative charges',
        club                     : 'Club',
        internalClubs            : 'Internal',
        externalClubs            : 'External',
        source                   : 'Source balance types',
        destination              : 'Destination balance types',
        balances                 : 'Payment balance types',
        balancesSupport          : 'These will be used to pay for the conversion fee',
        rate                     : 'Rate',
        fee                      : 'Fee',
        minValue                 : 'Minimum',
        noPayment                : 'No payment',
        noPaymentMessage         : 'Display conversions without fee payment',
        balanceLabel             : 'Payment',
        sourceLabel              : 'Source',
        destinationLabel         : 'Destination',

        createSuccess : 'The exchange rule has been added',
        saveSuccess   : 'The exchange rule has been updated',
        errors        : {
            create                             : 'The exchange rule could not be added',
            save                               : 'The exchange rule could not be updated',
            init                               : 'It was not possible to load the required data',
            conversionRuleExternalClubInUse    : 'There is an exchange rule for the selected external club',
            invalidPaymentBalance              : 'It is not possible to have a balance type as source and payment',
            exchangeOverrideRuleOneRulePerClub : 'The club selected already has an exchange rule',
            noExchangeOverrideRules            : 'There must be at least one exchange rule',
        },
    },
});
