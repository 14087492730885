import Dropdown from './Dropdown';
import Divider from './Divider';
import Option from './Option';
import Submenu from './Submenu';

Dropdown.Divider = Divider;
Dropdown.Option = Option;
Dropdown.Submenu = Submenu;

export default Dropdown;
