module.exports = Object.freeze({
    title                  : 'apf.conversions.title',
    emptyList              : 'apf.conversions.emptyList',
    add                    : 'apf.conversions.add',
    filterText             : 'apf.conversions.filterText',
    club                   : 'apf.conversions.club',
    fee                    : 'apf.conversions.fee',
    pointsUsed             : 'apf.conversions.pointsUsed',
    pointsConverted        : 'apf.conversions.pointsConverted',
    reference              : 'apf.conversions.reference',
    date                   : 'apf.conversions.date',
    code                   : 'apf.conversions.code',
    status                 : 'apf.conversions.status',
    reason                 : 'apf.conversions.reason',
    destinationClub        : 'apf.conversions.destinationClub',
    type                   : 'apf.conversions.type',
    external               : 'apf.conversions.external',
    internal               : 'apf.conversions.internal',
    originBalanceType      : 'apf.conversions.originBalanceType',
    amount                 : 'apf.conversions.amount',
    destinationBalanceType : 'apf.conversions.destinationBalanceType',
    destinationAmount      : 'apf.conversions.destinationAmount',
    balanceName            : 'apf.conversions.balanceName',
    feeAmount              : 'apf.conversions.feeAmount',
    legacyId               : 'apf.conversions.legacyId',
    reasonModal            : {
        title   : 'apf.conversions.reason.title',
        text    : 'apf.conversions.reason.text',
        success : { update: 'apf.conversions.reason.success.update' },
        error   : { update: 'apf.conversions.reason.error.update' },
    },
    cancelConfirmation : 'apf.conversions.cancelConfirmation',
    cancel             : {
        success : 'apf.conversions.cancel.success',
        error   : 'apf.conversions.cancel.error',
    },
});
