import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import { clientStatus } from 'Constants/global';
import Wrapper from '../../Wrapper';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Tab from 'Components/Tab';
import Button, { Link } from 'Components/Button';
import Dropdown from 'Components/Dropdown';
import ProfileDetails from '../../ProfileDetails';
import { Collection, SelectedSection } from 'Components/Sections/Collection';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';
import translated from 'Constants/labels/translated';
import Status from 'Components/Status';
import FinancialStatus from './FinancialStatus';
import { transformToSnake } from 'Utils/text';

function Details({ sections, data, resources: parentResources }) {
    const intl = useIntl();
    const { navigator, confirmation, snackbar } = useContext(PanelContext);

    const isPartner = data?.type === 'Partner';

    const blockedUsers = [data?.primary?.id];

    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current) {
            // Reload the subsection data when this section entity changes.
            const sectionToReload = Object.keys(sections).find((secName) => {
                const section = sections[secName];
                return section && section.isEnabled && section.isSelected && section.data;
            });

            // We need to reload the section data when the user navigates through the referral/referred links
            if (sectionToReload && sectionToReload === sectionType.INFO) {
                navigator.goAndReload([sectionToReload]);
            }
        }

        firstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id]);

    const [modalFinancialStatus, setModalFinancialStatus] = useState({ isVisible: false, isLoading: false });

    if (!isPartner && data?.secondary?.id) {
        blockedUsers.push(data.secondary.id);
    }
    // Render sections outside the tabs
    const { Section, props } = SelectedSection.getAny([
        { [sectionType.CLIENT_CONTRACTS_EDITOR]: sections[sectionType.CLIENT_CONTRACTS_EDITOR] },
        { [sectionType.RULE]: sections[sectionType.RULE] },
        { [sectionType.CONTRACT_EDITOR]: sections[sectionType.CONTRACT_EDITOR] },
        { [sectionType.USER]: sections[sectionType.USER] },
    ]);
    if (Section) {
        const onFinish = async () => {
            const path = isPartner ? [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS] : [sectionType.OWNERS, sectionType.DETAILS];
            await navigator.setSectionData(path, { shouldReloadData: true });
            if (props?.customProps?.navigateToParent) {
                // eslint-disable-next-line react/prop-types
                navigator[props.customProps.navigateToParent]();
            }
        };
        return <Section {...props} isCreatedOutsideUserRequest onFinish={onFinish} />;
    }

    // Creates an array with the data in the store, formatted for the Tab
    const subsectionsTabs = [];

    const tabList = Object.keys(sections)
        .filter((s) => sections[s]?.config?.isTab && data.links[sections[s].config.resourceName]?.read)
        .map((type) => {
            const resources = {
                id        : data.id,
                ...sections[type].resources,
                // If the section has data loaded and resources.available, the section has been filtered and we need to use the resource filtered.
                available :
                    sections[type]?.data && sections[type]?.resources?.available && Object.keys(sections[type].resources.available).length
                        ? {
                            ...sections[type].resources.available,
                            // We force the resource ID update to avoid bugs with the navigation of the tab info.
                            id: data.links[sections[type].config.resourceName]?.id || sections[type].resources.available.id,
                        }
                        : { ...data.links[sections[type].config.resourceName] },
            };
            const Subsection = Collection[type];
            const name = translated.sectionName[type];
            subsectionsTabs.push(
                <Subsection
                    {...sections[type]}
                    resources={resources}
                    currentSection={type}
                    id={data.id}
                    key={type}
                    isPartner={isPartner}
                    sectionErrors={isPartner ? translated.partners.errors : translated.owners.errors}
                    parentResources={data?.links}
                    parentPath={[sectionType.OWNERS, sectionType.DETAILS, type]}
                    blockedUsers={blockedUsers}
                    primaryOwner={data?.primary}
                    secondaryOwner={data?.secondary}
                />,
            );
            return {
                ...sections[type],
                id      : `tab-${transformToSnake(type)}`,
                name    : <FormattedMessage defaultMessage={name} id={name} />,
                onClick : () => navigator.goTo([type], resources, parentResources),
            };
        });

    const {
        id, status, primary, secondary, company, legacyId, links, highlightedContract,
    } = data;

    let sectionButtons = [];

    const activateAccountHandler = () => {
        confirmation.show({
            message       : translated.owners.activateAccount.confirm,
            acceptMessage : translated.owners.activateAccount.action,
            onAccept      : async () => {
                const reqConfig = {
                    ...data.links.self.patch,
                    data             : { status: 'Active' },
                    isCritical       : false,
                    isGlobal         : false,
                    ids              : [id],
                    shouldReloadData : true,
                };

                try {
                    await navigator.requestForOwnerDetails({ reqConfig, parentResources });
                    snackbar.show({
                        content   : translated.owners.activateAccount.success,
                        isSuccess : true,
                    });
                } catch (error) {
                    snackbar.show({
                        content : translated.owners.activateAccount.error,
                        isError : true,
                        error,
                    });
                }
            },
        });
    };

    const handleClickCloseModal = () => {
        setModalFinancialStatus({ isVisible: false });
    };

    const getFinancialStatus = async () => {
        if (!links?.financial?.read) {
            return;
        }
        setModalFinancialStatus({ isVisible: false, isLoading: true });

        try {
            const response = await navigator.directRequest(links.financial.read);

            setModalFinancialStatus({ data: response, isVisible: true, isLoading: false });
        } catch (error) {
            snackbar.show({
                content : translated.owners.financialStatus.error,
                isError : true,
                error,
            });

            setModalFinancialStatus({ isVisible: false, isLoading: false });
        }
    };

    if (!isPartner) {
        sectionButtons = [
            <Dropdown id="user" className="visible@s" icon="DotsVertical" key="vertical_dots">
                <Dropdown.Option isEnabled={false} itemKey="user-disable" key="user-disable">
                    <FormattedMessage defaultMessage={translated.global.buttons.disable} id={translated.global.buttons.disable} />
                </Dropdown.Option>
                <Dropdown.Option isEnabled={false} itemKey="user-delete" key="user-delete">
                    <FormattedMessage defaultMessage={translated.global.buttons.delete} id={translated.global.buttons.delete} />
                </Dropdown.Option>
            </Dropdown>,
            <Button
                id="owners-details-upgrade-button"
                className="margin-right-small hidden"
                icon="SwapVertical"
                tooltip={intl.formatMessage({
                    id             : translated.upgrades.title,
                    defaultMessage : translated.upgrades.title,
                })}
                key="upgradeDowngrade"
                disabled
            />,
            <div className="hidden@s display-inline-block" key="user-actions">
                <Button id="owners-details-disable-button" className="hidden" icon="AccountOff" tooltip="Disable" key="account_off" disabled />
                <Button id="owners-details-remove-button" className="hidden" icon="AccountRemove" tooltip="Delete" key="account_remove" disabled />
            </div>,
        ];

        if (links?.accountStatementReport?.download) {
            sectionButtons.push(
                <Link
                    id="owners-account-statement-download"
                    icon="FileDownload"
                    className="margin-top-small margin-bottom-small"
                    link={data.links.accountStatementReport.download}
                    tooltip={translated.owners.balance.downloadAccountStatements}
                    withoutRipple
                />,
            );
        }

        if (links?.pointsStatement?.read) {
            sectionButtons.push(
                <Link
                    withoutRipple
                    id="owners-details-points-statement-button"
                    key="points-statement"
                    icon="Cash100"
                    // variant="link"
                    link={links.pointsStatement.read}
                    tooltip={intl.formatMessage({
                        id             : translated.owners.pointStatement,
                        defaultMessage : translated.owners.pointStatement,
                    })}
                />,
            );
        }

        if (links?.financial?.read) {
            sectionButtons.push(
                <Button
                    id="owners-details-financial-button"
                    icon="AccountCash"
                    tooltip={intl.formatMessage({
                        id             : translated.owners.financialStatus.button,
                        defaultMessage : translated.owners.financialStatus.button,
                    })}
                    key="financialStatus"
                    onClick={getFinancialStatus}
                    isLoading={modalFinancialStatus.isLoading}
                    disabled={modalFinancialStatus.isLoading}
                />,
            );
        }

        if (window.env.CLIENT_NAME === 'CV' && (links?.conversions?.create || links?.exchanges?.read)) {
            sectionButtons.push(
                <Dropdown id="owners-details-exchange" className="margin-left-xsmall button-exchange" icon="Autorenew" key="owners-details-exchange">
                    {links?.conversions?.create && (
                        <Dropdown.Option
                            withoutRipple
                            itemKey="conversion"
                            key="conversion"
                            onClick={() => {
                                const { create } = links.conversions;
                                if (create.shouldRedirect) {
                                    window.open(create.url, '_blank');

                                    return;
                                }
                                navigator.goToConversions(owner);
                            }}
                        >
                            <FormattedMessage defaultMessage={translated.owners.balance.conversion} id={translated.owners.balance.conversion} />
                        </Dropdown.Option>
                    )}
                    {links?.exchanges?.read?.url && (
                        <Dropdown.Option withoutRipple itemKey="exchange" key="exchange" onClick={() => window.open(links.exchanges.read.url, '_blank')}>
                            <FormattedMessage defaultMessage={translated.owners.exchange} id={translated.owners.exchange} />
                        </Dropdown.Option>
                    )}
                </Dropdown>,
            );
        }

        // Adds a dropdown with the link to book for each owner
        if ((primary?.links?.bookings?.create || secondary?.links?.bookings?.create) && status === clientStatus.ACTIVE) {
            sectionButtons.push(
                <Dropdown
                    id="owners-details-book"
                    className="margin-left-xsmall"
                    classNameMenu="dropdown-book-for"
                    icon="RoomService"
                    tooltip={intl.formatMessage({
                        id             : translated.owners.bookDropdown.title,
                        defaultMessage : translated.owners.bookDropdown.title,
                    })}
                    key="owners-details-book"
                >
                    {primary?.links?.bookings?.create && (
                        <Dropdown.Option itemKey="primaryBook" key="primaryBook">
                            <Link withoutRipple variant="link" key="primaryBookLink" link={primary?.links?.bookings?.create}>
                                {`${primary.firstName} ${primary.lastName}`}
                            </Link>
                        </Dropdown.Option>
                    )}
                    {secondary?.links?.bookings?.create && (
                        <Dropdown.Option itemKey="secondaryBook" key="secondaryBook">
                            <Link withoutRipple variant="link" key="secondaryBookLink" link={secondary?.links?.bookings?.create}>
                                {`${secondary.firstName} ${secondary.lastName}`}
                            </Link>
                        </Dropdown.Option>
                    )}
                </Dropdown>,
            );
        } else if (data?.links?.self?.patch && status === clientStatus.PENDING) {
            sectionButtons.push(
                <Button
                    id="owners-details-account-check-button"
                    className="margin-left-xsmall"
                    icon="AccountCheck"
                    onClick={activateAccountHandler}
                    tooltip={intl.formatMessage({
                        id             : translated.owners.activateAccount.tooltip,
                        defaultMessage : translated.owners.activateAccount.tooltip,
                    })}
                    key="activateAccount"
                />,
            );
        } else {
            sectionButtons.push(
                <Button
                    id="owners-details-book-button"
                    className="margin-left-xsmall"
                    icon="RoomService"
                    tooltip={intl.formatMessage({
                        id             : translated.owners.bookDropdown.title,
                        defaultMessage : translated.owners.bookDropdown.title,
                    })}
                    key="bookDisabled"
                    disabled
                />,
            );
        }
    }

    const profileDetails = [];

    if (isPartner) {
        const partnerName = {
            title   : translated.global.name,
            content : company,
            id      : 'profile-partner-name',
        };
        profileDetails.splice(0, 0, partnerName);
    } else {
        profileDetails.splice(0, 0, {
            title   : translated.owners.primaryOwner,
            content : `${primary && primary.firstName} ${primary && primary.lastName}`,
            id      : 'profile-primary-owner-name',
        });
    }
    if (secondary && secondary.firstName) {
        profileDetails.splice(1, 0, {
            title   : translated.owners.secondaryOwner,
            content : `${secondary.firstName} ${secondary.lastName}`,
            id      : 'profile-secondary-owner-name',
        });
    }

    if (highlightedContract) {
        const {
            contractType: { clubTier },
            extraAttributes: { anniversaryDate },
            end,
        } = highlightedContract;
        const { club, tier } = clubTier;
        const handleClubClick = () => {
            navigator.goToClubEditor({ available: club && club.links ? club.links.self : {} });
        };
        const handleTierClick = () => {
            if (clubTier?.club?.links?.self?.update && clubTier?.links?.self?.update) {
                navigator.goToClubTierEditor({
                    available    : clubTier.links.self,
                    previousPath : [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL],
                    pathProps    : [
                        {
                            with : { resources: { available: clubTier.club.links.self, current: 'read' } },
                            on   : sectionType.CLUBS_GENERAL,
                        },
                    ],
                });
            }
        };
        profileDetails.push({
            title     : translated.global.club,
            id        : 'profile-club',
            content   : club.name,
            onClick   : club?.links?.self?.update && club?.links?.self?.read ? handleClubClick : null,
            className : club?.links?.self?.update && club?.links?.self?.read ? 'clickable-link' : null,
        });
        profileDetails.push({
            title     : translated.global.tier,
            id        : 'profile-tier',
            content   : tier.name,
            onClick   : tier?.links?.self?.update && tier?.links?.self?.read ? handleTierClick : null,
            className : tier?.links?.self?.update && tier?.links?.self?.read ? 'clickable-link' : null,
        });
        profileDetails.push({
            title   : translated.owners.contractAnniversaryDate,
            content : anniversaryDate,
            id      : 'profile-contract-start-date',
        });
        profileDetails.push({
            title   : translated.owners.contractTypeEndDate,
            content : end,
            id      : 'profile-contract-end-date',
        });
    }

    const profileTitle = (
        <>
            {(isPartner && (
                <FormattedMessage defaultMessage={translated.partners.accountEdition.title} id={translated.partners.accountEdition.title} />
            )) || <FormattedMessage defaultMessage={translated.owners.id} id={translated.owners.id} />}
            <span className="section-header-id">{isPartner ? id : legacyId}</span>
            <Status status={status} className="margin-left-small" highlightedAttributes={data.highlightedAttributes} />
        </>
    );

    return (
        <Wrapper title={profileTitle} actionButtons={sectionButtons} className="owner-wrapper">
            {modalFinancialStatus.isVisible && <FinancialStatus {...modalFinancialStatus} onClose={handleClickCloseModal} />}
            <ProfileDetails items={profileDetails} />
            <Tab tabs={tabList} className="margin-top-large">
                {subsectionsTabs}
            </Tab>
        </Wrapper>
    );
}

Details.propTypes = {
    sections  : PropTypes.shape({}).isRequired,
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
};

Details.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-large">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 6 }}>
                            <Skeleton.Title isHeading width={400} />
                        </Grid.Column>
                        <Grid.Column width={{ base: 6 }} className="text-align-right">
                            <Skeleton.Button isIcon quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            <Grid className="owners-details-loading" childWidth={{ base: 12 }}>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
                <div>
                    <Skeleton.Paragraph quantity={1} className="margin-bottom-xxsmall" />
                    <Skeleton.Paragraph quantity={1} />
                </div>
            </Grid>
            <Grid className="margin-top-large owners-tabs-loading" childWidth={{ base: 12 }}>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
            </Grid>
        </>
    );
};

export default withRequest(Details);
