// This strings will populate the breadcrumb

const resourceNames = Object.freeze({
    SELF          : 'self',
    AGENT         : 'agent',
    AGENTS        : 'agents',
    AGENTS_LIST   : 'agentsList',
    AGENTS_EDITOR : 'agentsEditor',
    BALANCE_TYPES : 'balanceTypes',
    BALANCE_TYPE  : 'balanceType',
    BOOKINGS      : 'bookings',
    WALLET        : 'wallet',

    CLUB                          : 'club',
    CLUB_TIER                     : 'clubTier',
    CLUB_TIER_CONTRACT_TYPE       : 'contractType',
    CLUB_TIER_CONTRACT_TYPE_RULE  : 'clubTierContractTypesRule',
    CLUB_TIER_CONTRACT_TYPE_RULES : 'clubTierContractTypesRules',
    CLUB_TIER_RULE                : 'clubTierRule',
    CLUB_TIER_RULES               : 'clubTierRules',
    CLUB_TIERS                    : 'clubTiers',
    CLUB_BALANCE_TYPES            : 'balanceTypes',
    CLUBS                         : 'clubs',

    CONTRACT_TYPE_RULE  : 'contractTypeRule',
    CONTRACT_TYPE_RULES : 'contractTypeRules',
    CONTRACTS           : 'contracts',
    CONTRACT_TYPES      : 'contractTypes',
    CONTRACT_RULE       : 'contractRule',

    NOTE                   : 'note',
    NOTES                  : 'notes',
    OWNERS                 : 'owners',
    OWNERS_IMPORT          : 'imports',
    OWNER                  : 'owner',
    POINTS                 : 'points',
    addBalance             : 'clubPointManage',
    OWNER_POINTS           : 'pointsOwner',
    PROFILE                : 'profile',
    RESERVATION            : 'booking',
    RESERVATIONS           : 'bookings',
    ROLE                   : 'role',
    ROLES                  : 'roles',
    RULE                   : 'rule',
    RULES                  : 'rules',
    BANKING_CONFIGURATIONS : 'bankingConfigurations',
    TIER                   : 'tier',
    TIERS                  : 'tiers',
    USER                   : 'user',
    USERS                  : 'users',
    ATTACHMENTS            : 'attachments',
    ATTACHMENT             : 'attachment',
    CLIENT_CONTRACTS       : 'contracts',
    CLIENT_CONTRACT        : 'contract',

    OWNERS_IMPORT_CSV_CLIENTS: 'clients',

    OWNER_USER      : 'ownerUser',
    OWNER_USERS     : 'ownerUsers',
    OWNER_NOTE      : 'ownerNote',
    OWNER_NOTES     : 'ownerNotes',
    OWNER_BALANCES  : 'ownerBalances',
    OWNER_BOOKINGS  : 'ownerBookings',
    OWNER_CONTRACTS : 'ownerContracts',
    OWNER_CONTRACT  : 'ownerContract',
    OWNER_WALLET    : 'ownerWallet',
    OWNER_TAGS      : 'ownerTags',

    PARTNERS          : 'partners',
    PARTNER           : 'partner',
    PARTNER_USER      : 'partnerUser',
    PARTNER_USERS     : 'partnerUsers',
    PARTNER_NOTE      : 'partnerNote',
    PARTNER_NOTES     : 'partnerNotes',
    PARTNER_WALLET    : 'partnerWallet',
    PARTNER_BOOKINGS  : 'partnerBookings',
    PARTNER_POINTS    : 'pointsPartner',
    PARTNER_CONTRACTS : 'partnerContracts',
    PARTNER_CONTRACT  : 'partnerContract',
    PARTNER_TAGS      : 'partnerTags',

    RELATIONSHIP  : 'relationship',
    RELATIONSHIPS : 'relationships',

    UPGRADES_DOWNGRADES     : 'upgradeRequests',
    OWNER_UPGRADE_DOWNGRADE : 'upgradeRequest',
    UPGRADE_DOWNGRADE       : 'upgradeRequest',

    WALLET_CONFIG                : 'conversionRules',
    OWNER_CONVERSION_RULES       : 'contractsConversionRules',
    CLUB_TIER_WALLET_CONFIG      : 'clubTierWalletConfig',
    OWNER_CONVERSION_RULE        : 'contractConversionRule',
    CLUB_TIER_WALLET_CONFIG_LIST : 'clubTierWalletConfigs',
    CONVERSION_RULES_ALT         : 'exchangeOverrideRules',

    EXTERNAL_CLUBS : 'externalClubs',
    EXTERNAL_CLUB  : 'externalClub',

    CATEGORIES : 'attachmentCategories',
    CATEGORY   : 'attachmentCategory',

    EXTRA_ATTRIBUTES : 'extraAttributes',
    EXTRA_ATTRIBUTE  : 'extraAttribute',

    TAG  : 'tag',
    TAGS : 'tags',

    PROPERTY_GROUPS : 'propertyGroups',
    PROPERTY_GROUP  : 'propertyGroup',

    PROPERTIES : 'properties',
    PROPERTY   : 'property',

    SYSTEM_RULES : 'systemRules',
    SYSTEM_RULE  : 'systemRule',

    CONVERSIONS                    : 'conversions',
    OWNER_CONVERSIONS              : 'ownerConversions',
    OWNER_CONVERSIONS_REASON       : 'ownerConversionsReason',
    OWNER_CONVERSIONS_MODIFICATION : 'ownerConversionsModification',

    CURRENCIES : 'currencies',
    CURRENCY   : 'currency',

    DOCUMENTATION : 'documentation',
    RELEASE       : 'releases',

    ACCOUNT_STATEMENTS: 'accountStatements',
});

const sectionType = Object.freeze({
    AGENTS        : 'agents',
    AGENTS_EDITOR : 'agentsEditor',
    AGENTS_LIST   : 'agentsList',

    OWNERS                    : 'owners',
    OWNERS_LIST               : 'ownersList',
    OWNERS_EDITOR             : 'ownersEditor',
    OWNERS_GENERAL            : 'ownersGeneral',
    OWNERS_GENERAL_IMPORTED   : 'ownersGeneralImported',
    OWNERS_CREATOR            : 'ownersCreator',
    OWNERS_IMPORT             : 'ownersImport',
    OWNERS_IMPORT_CSV         : 'ownersImportCsv',
    OWNERS_IMPORT_CSV_CLIENTS : 'ownersImportCsvClients',
    OWNERS_DETAILS            : 'ownersDetails',

    TIERS      : 'tiers',
    TIERS_LIST : 'tiersList',
    TIER       : 'tier',

    CLUBS               : 'clubs',
    CLUBS_LIST          : 'clubsList',
    CLUBS_EDITOR        : 'clubsEditor',
    CLUBS_GENERAL       : 'clubsGeneral',
    CLUBS_TIERS         : 'clubsTiers',
    CLUBS_BALANCE_TYPES : 'clubsBalanceTypes',
    CLUBS_TIER_EDITOR   : 'clubsTierEditor',

    USERS        : 'users',
    USER         : 'user',
    USERS_LIST   : 'usersList',
    USERS_EDITOR : 'usersEditor',

    CONTRACT_TYPE  : 'contractType',
    CONTRACT_TYPES : 'contractTypes',

    WALLET             : 'wallet',
    BOOKINGS           : 'entityBookings',
    NOTES              : 'notes',
    NOTE               : 'note',
    INFO               : 'info',
    RULES              : 'rules',
    RULE               : 'rule',
    WALLET_MANAGER     : 'walletManager',
    CONVERSION_MANAGER : 'conversionManager',
    WALLET_ADD_BALANCE : 'walletAddBalance',
    LIST               : 'list',
    DETAILS            : 'details',
    EDITOR             : 'edit',
    GENERAL            : 'general',
    TIER_EDITOR        : 'tierEditor',

    RESERVATIONS        : 'bookings',
    RESERVATIONS_LIST   : 'bookingsList',
    RESERVATIONS_EDITOR : 'bookingsEditor',

    ROLES        : 'roles',
    ROLES_LIST   : 'rolesList',
    ROLES_EDITOR : 'rolesEditor',

    BALANCE_TYPES        : 'balanceTypes',
    BALANCE_TYPES_LIST   : 'balanceTypesList',
    BALANCE_TYPES_EDITOR : 'balanceTypesEditor',

    CATEGORIES        : 'attachmentCategories',
    CATEGORIES_LIST   : 'attachmentCategoriesList',
    CATEGORIES_EDITOR : 'attachmentCategoriesEditor',

    BANKING_CONFIGURATIONS: 'bankingConfigurations',

    ATTACHMENTS : 'attachments',
    ATTACHMENT  : 'attachment',

    PARTNERS           : 'partners',
    PARTNERS_LIST      : 'partnersList',
    PARTNERS_EDITOR    : 'partnersEditor',
    PARTNERS_GENERAL   : 'partnersGeneral',
    PARTNERS_DETAILS   : 'partnersDetails',
    PARTNERS_INFO      : 'partnerInfo',
    PARTNERS_USERS     : 'partnerUsers',
    PARTNERS_USER      : 'partnerUser',
    PARTNERS_CONTRACTS : 'partnerContracts',

    // Contracts upgrades
    CONTRACT              : 'upgradeRequests',
    CONTRACT_LIST         : 'upgradeRequestsList',
    OWNER_CONTRACT_EDITOR : 'ownerContractsEditor',
    CONTRACT_EDITOR       : 'contractsEditor',

    RELATIONSHIPS : 'relationships',
    RELATIONSHIP  : 'relationship',

    EXTERNAL_CLUBS        : 'externalClubs',
    EXTERNAL_CLUBS_LIST   : 'externalClubsList',
    EXTERNAL_CLUBS_EDITOR : 'externalClubEditor',

    EXTRA_ATTRIBUTES        : 'extraAttributes',
    EXTRA_ATTRIBUTES_LIST   : 'extraAttributesList',
    EXTRA_ATTRIBUTES_EDITOR : 'extraAttributeEditor',

    CURRENCIES        : 'currencies',
    CURRENCIES_LIST   : 'currenciesList',
    CURRENCIES_EDITOR : 'currenciesEditor',

    TAGS        : 'tags',
    TAG         : 'tag',
    TAGS_LIST   : 'tagsList',
    TAGS_EDITOR : 'tagsEditor',
    ENTITY_TAGS : 'entityTagsList',

    PROPERTY_GROUPS        : 'propertyGroups',
    PROPERTY_GROUPS_LIST   : 'propertyGroupsList',
    PROPERTY_GROUPS_EDITOR : 'propertyGroupsEditor',

    PROPERTIES      : 'properties',
    PROPERTIES_LIST : 'propertiesList',
    PROPERTY        : 'property',

    // Owner and partner contracts
    CLIENT_CONTRACTS        : 'contracts',
    CLIENT_CONTRACTS_EDITOR : 'contract',

    // Global rules
    SYSTEM_RULES      : 'systemRules',
    SYSTEM_RULES_LIST : 'systemRulesList',

    CONVERSIONS             : 'walletConversions',
    REASON                  : 'reason',
    CONVERSION_MODIFICATION : 'modification',

    CONVERSION_RULES       : 'conversionRules',
    CONVERSION_RULE_EDITOR : 'conversionRuleEditor',

    CONVERSION_RULES_ALT       : 'conversionRulesAlt',
    CONVERSION_RULE_EDITOR_ALT : 'conversionRuleEditorAlt',

    DOCUMENTATION : 'documentation',
    DOCUMENTS     : 'documents',
    RELEASES      : 'releases',
    RELEASE       : 'release',

    ACCOUNT_STATEMENTS : 'accountStatements',
    ACCOUNT_STATEMENT  : 'accountStatement',
});

module.exports = { resourceNames, sectionType };
