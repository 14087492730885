import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Switch({
    value, className, label, id, onChange, onClick, isDisabled, innerInputRef,
}) {
    const labelClass = label ? 'has-label' : '';

    return (
        <div className={`switch ${className} ${labelClass}`} onClick={onClick}>
            {label && (
                <span>
                    <WrappedFormattedMessage content={label} />
                </span>
            )}
            <input
                type="checkbox"
                onChange={(e) => onChange({ target: { value: e.target.checked } })}
                checked={value}
                autoComplete="off"
                id={id}
                className="switch-input"
                tabIndex={isDisabled ? '-1' : '0'}
                ref={innerInputRef}
            />
            <label className="switch-label" />
        </div>
    );
}

Switch.defaultProps = {
    id        : '',
    className : '',
    label     : '',
    onChange  : () => {
        // Default
    },
    onClick: () => {
        // Default
    },
    value         : false,
    isDisabled    : false,
    innerInputRef : null,
};

Switch.propTypes = {
    className     : PropTypes.string,
    label         : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    id            : PropTypes.string,
    onChange      : PropTypes.func,
    onClick       : PropTypes.func,
    value         : PropTypes.bool,
    isDisabled    : PropTypes.bool,
    innerInputRef : PropTypes.shape({}),
};

export default React.memo(Switch);
