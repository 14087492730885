import React, { useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import SectionsWrapper from '../../Wrapper';
import PanelContext from 'State/panelContext';
import Form, { FormError } from 'Components/Form';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';
import { extraAttributesFilters, clubDuration } from 'Constants/global';
import Loading from 'Components/Loading';
import Status from 'Components/Status';
import BankingConfigurationList from 'Components/Sections/Shared/BankingConfigurations/ListWithoutRequest';
import isBankingConfigurationValid from 'Components/Sections/Shared/BankingConfigurations/utils';
import convertBankingConfigurations from 'Components/Sections/Contracts/utils';
import Tab from 'Components/Tab';
import Button from 'Components/Button';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';
import requestErrors from 'Constants/requestErrors';

const convertInitialContracts = (contracts) => contracts.map((e, index) => {
    const converted = { ...e };
    converted.id = index + 1;
    converted.isSelected = index === 0;

    return converted;
});

const validateBankingConfiguration = (list, begin, end, dateManager) => {
    if (!list?.length) {
        return [];
    }

    return list.map((e) => ({
        ...e,
        isValid: isBankingConfigurationValid(e, begin, end, dateManager),
    }));
};

const getBankingConfigurationProps = (list, begin, end, dateManager) => {
    const convertedList = validateBankingConfiguration(list, begin, end, dateManager);

    return {
        bankingConfigurations         : convertedList,
        areBankingConfigurationsValid : convertedList.every((each) => each.isValid),
    };
};

const validateDates = (begin, end, dateManager) => dateManager.isValidDate(begin) && dateManager.isValidDate(end)
    && dateManager.isSameOrBefore(begin, end);

const validateMinMaxDates = (begin, value, end, dateManager) => dateManager.isValidDate(value)
            && (!dateManager.isValidDate(begin)
            || dateManager.isSameOrBefore(begin, value))
            && (!dateManager.isValidDate(end)
            || dateManager.isSameOrBefore(value, end));

const MAX_DATE = '2100-01-01';

export function OwnersGeneralForImported({ resources, data, isCreation }) {
    const intl = useIntl();
    const {
        links, status, primary, secondary, contracts = [], referral, extraAttributes, legacyId, department, additionalResources,
    } = data;

    const createLink = isCreation ? resources?.available?.create : links?.self?.create;

    const availableCurrencies = additionalResources?.currencies?.map((e) => ({ content: e.name, value: e.id })) || [];
    const consumptionModes = additionalResources?.consumptionModes.map((e) => ({ value: e.id, content: e.code }));
    const availableExtraAttributes = additionalResources?.extraAttributes || [];
    const availableAccountAttributes = availableExtraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.CLIENT));
    const availableUserAttributes = availableExtraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.USER));
    const availableContractAttributes = availableExtraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.CONTRACT));

    const { navigator, snackbar, dateManager } = useContext(PanelContext);

    const [clubs, setClubs] = useState([]);
    const [tiers, setTiers] = useState({});
    const [contractTypes, setContractTypes] = useState({});

    const [secondaryOwnerData, setSecondaryOwnerData] = useState({
        firstName  : secondary?.firstName?.length > 0,
        lastName   : secondary?.lastName?.length > 0,
        email      : secondary?.email?.length > 0,
        phone      : secondary?.phone?.length > 0,
        mobile     : secondary?.mobile?.length > 0,
        birthday   : secondary?.birthday?.length > 0,
        language   : !!secondary?.language,
        loginToken : secondary?.loginToken?.length > 0,
    });

    const isSecondaryOwnerRequired = Object.values(secondaryOwnerData).some((aValue) => aValue);

    const contractsData = useRef({
        list   : data?.contracts?.length ? convertInitialContracts(data.contracts) : [{ id: 1, isSelected: true }],
        lastId : data.contracts?.length || 1,
    });

    const [contractsTabs, setContractsTabs] = useState(
        data?.contracts?.length
            ? data.contracts.map((e, i) => ({
                id                     : i + 1,
                key                    : `contract-${i + 1}`,
                isSelected             : i === 0,
                clubId                 : e.contractType?.clubTier?.club?.id,
                tierId                 : e.contractType?.clubTier?.id,
                contractTypeId         : e.contractType?.id,
                primaryCurrencyId      : e.primaryCurrency?.id,
                primaryCurrencyDelta   : e.primaryCurrencyDelta,
                secondaryCurrencyId    : e.secondaryCurrency?.id,
                secondaryCurrencyDelta : e.secondaryCurrencyDelta,
                begin                  : e.begin,
                end                    : e.end,
                endMinDate             : e.begin && dateManager.getNextDayFormatted(e.begin) ? dateManager.getNextDayFormatted(e.begin) : null,
                legacyId               : e.legacyId,
                isLoading              : false,
                highlight              : false,
                // Flag used to hide the tab. Fixes the following bug:
                // when deleting a tab with data, it would show the other tab with the former's data.
                isHidden               : false,
                extraAttributes        : e.extraAttributes,
                ...getBankingConfigurationProps(e.bankingConfigurations, e.begin, e.end, dateManager),
            }))
            : [{ id: 1, isSelected: true }],
    );

    const [hasContractsChanged, setHasContractsChanged] = useState(false);

    const { additional } = resources;

    // Sets up the club, tier and contract types lists for the contracts.
    const setLists = async (cancel) => {
        if (!cancel) {
            try {
                setContractsTabs((prev) => prev.map((e) => ({ ...e, isLoading: true })));
                const clubsList = await navigator.directRequest(data.additionalResources.clubs.read);
                const parsedClubsList = clubsList.map((club) => {
                    const { clubDuration: cd } = club;

                    const dateRange = {};

                    if (cd === clubDuration.DYNAMIC) {
                        dateRange.begin = club?.dateRange?.begin;
                        dateRange.end = club?.dateRange?.end;
                        dateRange.type = 'dynamic';
                    }

                    if (cd === clubDuration.FIXED) {
                        dateRange.begin = club?.begin || null;
                        if (dateManager.isValidDate(dateRange.begin)) {
                            const endDate = dateManager.format(
                                dateManager.sub(
                                    dateManager.add(
                                        dateRange.begin,
                                        club.duration,
                                        'months',
                                    ),
                                    1,
                                    'days',
                                ),
                                'yyyy-MM-dd',
                            );

                            dateRange.end = endDate;
                        }
                        dateRange.duration = club?.duration;
                        dateRange.type = 'fixed';
                    }

                    return {
                        content : club.name,
                        value   : club.id,
                        id      : club.id,
                        links   : club.links,
                        dateRange,
                    };
                });

                const contractsContractTypes = {};
                const loadedTiers = {};
                const loadedContractTypes = {};

                await Promise.all(
                    contracts.map(async (eachContract, index) => {
                        if (!eachContract?.contractType?.clubTier?.club?.id || !eachContract?.contractType?.clubTier?.tier?.id) {
                            return;
                        }

                        const { contractType: ct } = eachContract;

                        const selectedClub = parsedClubsList.find((club) => club.value === ct.clubTier.club.id);
                        const tiersList = await navigator.directRequest(selectedClub.links.tiers.read);
                        const parsedTiersList = tiersList.map((element) => ({
                            content : element.tier.name,
                            value   : element.id,
                            id      : element.id,
                            links   : element.links,
                        }));
                        const selectedTier = parsedTiersList.find((tier) => tier.id === ct.clubTier.id);
                        const contractTypeList = await navigator.directRequest(selectedTier.links.contractTypes.read);
                        const parsedContractTypeList = contractTypeList.map((element) => ({
                            ...element,
                            content : element.name,
                            value   : element.id,
                            id      : element.id,
                        }));

                        if (ct.clubTier && ct.clubTier.club && ct.clubTier.tier) {
                            loadedTiers[selectedClub.id] = parsedTiersList;
                            loadedContractTypes[selectedTier.id] = parsedContractTypeList;
                        }

                        contractsContractTypes[index] = parsedContractTypeList.find((e) => e.id === ct.id);
                    }),
                );

                setClubs(parsedClubsList);
                setTiers(loadedTiers);
                setContractTypes(loadedContractTypes);

                // Mark the contracts as loaded and set the currencies data
                setContractsTabs((prev) => prev.map((e, index) => ({
                    ...e,
                    isLoading              : false,
                    primaryCurrencyId      : contractsContractTypes?.[index]?.primaryCurrency?.id,
                    primaryCurrencyDelta   : contractsContractTypes?.[index]?.primaryCurrencyDelta,
                    secondaryCurrencyId    : contractsContractTypes?.[index]?.secondaryCurrency?.id,
                    secondaryCurrencyDelta : contractsContractTypes?.[index]?.secondaryCurrencyDelta,
                })));
            } catch (e) {
                snackbar.show({ error: e, content: translated.imports.initializeError, isError: true });
            }
        }
    };

    useEffect(() => {
        let cancel = false;
        setLists(cancel);

        return () => {
            cancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClubOnChange = useCallback(
        async (clubId, contractId) => {
            try {
                if (!clubId) {
                    return;
                }

                // Look for the tiers only when they are not already loaded
                if (!tiers?.[clubId]) {
                    setContractsTabs((prev) => prev.map((e) => (e.id === contractId ? { ...e, isLoading: true } : { ...e })));
                    const club = clubs?.find((clubElement) => clubElement.value === clubId);
                    const link = club.links.tiers.read;
                    const response = await navigator.directRequest(link);
                    const parsedTiersList = response?.map((element) => ({
                        content : element.tier.name,
                        value   : element.id,
                        id      : element.id,
                        links   : element.links,
                    }));
                    setTiers((prev) => ({ ...prev, [clubId]: parsedTiersList }));
                }

                setContractsTabs((prev) => prev.map((e) => (e.id === contractId
                    ? {
                        ...e,
                        clubId,
                        tierId         : null,
                        contractTypeId : null,
                        isLoading      : false,
                    }
                    : { ...e })));
            } catch (e) {
                snackbar.show({
                    content : translated.imports.loadTiersError,
                    error   : e,
                    isError : true,
                });
            }
        },
        [clubs, navigator, snackbar, tiers],
    );

    const handleTierOnChange = useCallback(
        async (tierId, contractId) => {
            try {
                if (!tierId) {
                    return;
                }

                // Look for the contractTypes only when they are not already loaded
                if (!contractTypes?.[tierId]) {
                    setContractsTabs((prev) => prev.map((e) => (e.id === contractId ? { ...e, isLoading: true } : { ...e })));
                    const clubId = contractsTabs.find((e) => e.id === contractId)?.clubId;
                    const tier = tiers[clubId].find((e) => e.value === tierId);
                    const link = tier.links.contractTypes.read;
                    const response = await navigator.directRequest(link);
                    const parsedContractTypeList = response?.map((element) => ({
                        ...element,
                        content : element.name,
                        value   : element.id,
                        id      : element.id,
                    }));
                    setContractTypes((prev) => ({ ...prev, [tierId]: parsedContractTypeList }));
                }

                setContractsTabs((prev) => prev.map((e) => (e.id === contractId ? { ...e, tierId, contractTypeId: null, isLoading: false } : { ...e })));
            } catch (e) {
                snackbar.show({
                    content : translated.imports.loadContractTypesError,
                    error   : e,
                    isError : true,
                });
            }
        },
        [contractTypes, contractsTabs, navigator, snackbar, tiers],
    );

    const handleContractTypeOnChange = useCallback(
        (contractTypeId, contractId) => {
            if (!contractTypeId) {
                return;
            }
            const tierId = contractsTabs.find((e) => e.id === contractId)?.tierId;
            const selectedContractType = contractTypes[tierId]?.find((contractElement) => contractElement.value === contractTypeId);

            setContractsTabs((prev) => prev.map((e) => (e.id === contractId
                ? {
                    ...e,
                    contractTypeId,
                    primaryCurrencyId      : selectedContractType?.primaryCurrency?.id,
                    primaryCurrencyDelta   : selectedContractType?.primaryCurrencyDelta,
                    secondaryCurrencyId    : selectedContractType?.secondaryCurrency?.id,
                    secondaryCurrencyDelta : selectedContractType?.secondaryCurrencyDelta,
                }
                : { ...e })));
        },
        [contractTypes, contractsTabs],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const prepareDataForSubmit = (formData) => {
        let formattedData = {
            ...formData.values,
            primary: {
                ...formData.values.primary,
                extraAttributes: formData.values.primary.extraAttributes,
            },
        };

        if (Object.values(secondaryOwnerData).every((aValue) => !aValue)) {
            formattedData = { ...formattedData, secondary: null };
        } else {
            formattedData = {
                ...formattedData,
                secondary: {
                    ...formattedData.secondary,
                    email           : formattedData.secondary.email || null,
                    language        : formattedData.secondary.language || null,
                    birthday        : formattedData.secondary.birthday || null,
                    extraAttributes : formattedData.secondary.extraAttributes,
                },
            };
        }

        formattedData.contracts = contractsTabs
            ?.filter((e) => !e.isHidden)
            .map((e) => {
                const eachContract = contractsData.current.list?.find((each) => each.id === e.id);

                return {
                    ...eachContract,
                    contractType          : { id: eachContract.contractType },
                    bankingConfigurations : e?.bankingConfigurations.map((each) => convertBankingConfigurations(each)),
                    id                    : undefined,
                    club                  : undefined,
                    tier                  : undefined,
                    isValid               : undefined,
                    areDatesValid         : undefined,
                };
            });

        formattedData.isCsv = !isCreation;
        if (!isCreation) {
            formattedData.csvId = data.csvId;
            formattedData.csvRowId = data.csvRowId;
        }

        if (!formattedData.country.id) {
            delete formattedData.country;
        }

        // We erase the fields that are send in a different way
        delete formattedData.primarySalutation;
        delete formattedData.secondarySalutation;

        return formattedData;
    };

    const handleOnChangeBeginDate = useCallback((inputValue, contractId) => {
        if (dateManager.isValidDate(inputValue)) {
            const newMinDate = dateManager.getNextDayFormatted(inputValue);

            setContractsTabs((prev) => prev.map((e) => ({
                ...e,
                ...(e.id === contractId ? getBankingConfigurationProps(e.bankingConfigurations, inputValue, e.end, dateManager) : {}),
                begin      : e.id === contractId ? inputValue : e.begin,
                endMinDate : e.id === contractId ? newMinDate : e.endMinDate,
            })));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeEndDate = useCallback((inputValue, contractId) => {
        if (dateManager.isValidDate(inputValue)) {
            setContractsTabs((prev) => prev.map((e) => ({
                ...e,
                ...(e.id === contractId ? getBankingConfigurationProps(e.bankingConfigurations, e.begin, inputValue, dateManager) : {}),
                end: e.id === contractId ? inputValue : e.end,
            })));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWarningsContent = useCallback((warnings) => {
        if (!warnings?.length) {
            return null;
        }

        return warnings.map(({ code, value }) => {
            const key = requestErrors[code] || 'default';
            const message = intl.formatMessage(
                { id: translated.imports.saveWarnings[key] },
                { value },
            );

            return { message, code };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = useCallback(
        async (formData) => {
            // Validate each contract data
            const index = contractsTabs?.filter((e) => !e.isHidden).findIndex((e) => !e.isValid || !e.areBankingConfigurationsValid);
            if (index > -1) {
                const content = (
                    <FormattedMessage
                        defaultMessage={translated.imports.saveInvalidContract}
                        id={translated.imports.saveInvalidContract}
                        values={{ number: index + 1 }}
                    />
                );
                throw new FormError(content);
            }

            // Validate extra attributes in manual mode
            const { values } = formData;
            validateDynamicInputs(values.extraAttributes, dateManager);
            validateDynamicInputs(values.primary.extraAttributes, dateManager);
            validateDynamicInputs(values.secondary.extraAttributes, dateManager);

            // Validate contract's extra attributes
            // eslint-disable-next-line max-len
            const enabledContracts = contractsData.current?.list?.filter((e) => contractsTabs.find((eachTab) => !eachTab.isHidden && eachTab.id === e.id)) || [];
            enabledContracts.forEach((contract) => {
                validateDynamicInputs(contract.extraAttributes, dateManager);
            });

            // Validate contract's unique data
            const highlighted = enabledContracts?.filter((e) => e.highlight);
            if (highlighted.length > 1) {
                throw new FormError(translated.imports.saveInvalidQuantityOfHighlight);
            }
            const contractLegacyIds = enabledContracts?.map((e) => e.legacyId);
            const set = new Set(contractLegacyIds);
            if (set.size < contractLegacyIds.length) {
                throw new FormError(translated.imports.saveInvalidLegacyContractsId);
            }

            const parsedData = prepareDataForSubmit(formData);

            try {
                const owner = await navigator.requestForCurrentPath({
                    reqConfig: {
                        ...createLink,
                        data                     : parsedData,
                        avoidSetDataWithResponse : true,
                    },
                    resources,
                });

                const snackbarProps = {
                    content    : translated.imports.success,
                    actionText : translated.imports.viewProfile,
                    onClick    : () => navigator.goToDetails(owner),
                    isSuccess  : true,
                };

                if (owner?.warnings?.length) {
                    snackbarProps.content = (
                        <>
                            <FormattedMessage id={translated.imports.saveWarning} />
                            <ul className="margin-top-small padding-left">
                                {getWarningsContent(owner?.warnings).map(({ message, code }) => (
                                    <li key={code}>{message}</li>
                                ))}
                            </ul>
                        </>
                    );
                    snackbarProps.isWarning = true;
                    snackbarProps.isSuccess = false;
                    snackbarProps.timeout = 20 * 1000; // 20 seconds
                }

                snackbar.show(snackbarProps);
            } catch (saveError) {
                snackbar.show({
                    error       : saveError,
                    errorLabels : translated.owners.errors,
                    content     : translated.imports.saveError,
                    isError     : true,
                });
                throw saveError;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contractsTabs, createLink, resources],
    );

    const getDatesExtraInfo = useCallback(
        (minDate, maxDate) => (
            <div>
                <span>
                    {intl.formatMessage({
                        id             : translated.global.feb29Warn,
                        defaultMessage : translated.global.feb29Warn,
                    })}
                </span>
                <br />
                {minDate ? (
                    <span>
                        {intl.formatMessage(
                            {
                                id             : translated.global.validMinDate,
                                defaultMessage : translated.global.validMinDate,
                            },
                            { date: minDate },
                        )}
                    </span>
                ) : null}
                {minDate ? <br /> : null}
                {maxDate ? (
                    <span>
                        <FormattedMessage defaultMessage={translated.global.validMaxDate} id={translated.global.validMaxDate} values={{ date: maxDate }} />
                    </span>
                ) : null}
            </div>
        ),
        [intl],
    );

    // Turn the languages, countries and salutations list to the needed structure.
    const languages = additional?.languages?.length ? additional.languages.map((each) => ({ value: each.id, content: each.name })) : [];

    const countries = additional?.countries?.length ? additional.countries.map((each) => ({ value: each.id, content: each.name })) : [];

    const birthdayMinValue = dateManager.yearInDefaultFormat();
    const birthdayMaxValue = dateManager.defaultDateFormat(dateManager.getCurrentDay());

    const profileTitle = isCreation ? (
        <FormattedMessage id={translated.imports.titleCreate} defaultMessage={translated.imports.titleCreate} />
    ) : (
        <>
            <FormattedMessage id={translated.imports.titleImport} defaultMessage={translated.imports.titleImport} />
            <span className="section-header-id">{`${primary?.firstName || ''} ${primary?.lastName || ''}`}</span>
            <Status status={status} className="margin-left-small" />
        </>
    );

    const actionButtons = createLink
        ? [
            <Form.Secondary key="fs" onClick={isCreation ? navigator.goToRoot : navigator.goToParentAndReload}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary key="fp" isEnabled>
                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
            </Form.Primary>,
        ]
        : [
            <Form.Secondary key="fs">
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
        ];

    const changeTabHandle = useMemo(
        () => (newTabId) => {
            let selectedTab = null;

            setContractsTabs((prev) => {
                const updated = prev.map((e) => ({ ...e, isSelected: e.id === newTabId }));
                selectedTab = updated.find((e) => e.id === newTabId);

                return updated;
            });

            if (!contractTypes?.[selectedTab?.tierId]) {
                handleTierOnChange(selectedTab?.tierId, newTabId);
            } else if (!selectedTab?.contractTypeId) {
                handleContractTypeOnChange(selectedTab?.contractTypeId, newTabId);
            }
        },
        [contractTypes, handleContractTypeOnChange, handleTierOnChange],
    );

    const updateSelectedContract = useMemo(
        () => ({ values, validity }) => {
            const isValid = !validity.isInvalid && values?.club && values?.tier && values?.contractType;

            contractsData.current.list = contractsData.current.list.map((e) => (e.id === values.id ? { ...values, isValid } : { ...e }));

            setContractsTabs((prev) => prev.map((e) => {
                const isSameContract = e.id === values.id;

                let { areDatesValid } = e;
                if (isSameContract) {
                    const { endMinDate = null, clubId } = e;

                    const contractDateRange = clubId ? clubs?.find((eachClub) => eachClub.id === clubId)?.dateRange : null;

                    const beginMin = contractDateRange?.begin;
                    const beginMax = contractDateRange?.end;
                    const endMin = endMinDate && dateManager.isValidDate(endMinDate) ? endMinDate : contractDateRange?.begin;
                    const endMax = contractDateRange?.end || MAX_DATE;

                    areDatesValid = validateDates(values.begin, values.end, dateManager)
                            && ((!beginMin && !beginMax) || validateMinMaxDates(beginMin, values.begin, beginMax, dateManager))
                            && ((!endMin && !endMax) || validateMinMaxDates(endMin, values.end, endMax, dateManager));
                }

                return {
                    ...e,
                    legacyId : isSameContract ? values.legacyId : e.legacyId,
                    isValid  : isSameContract ? isValid : e.isValid,
                    areDatesValid,
                };
            }));

            if (!hasContractsChanged) {
                setHasContractsChanged(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clubs, hasContractsChanged],
    );

    const tabList = useMemo(
        () => contractsTabs?.map((currentTab, index) => {
            const iconProps = contractsTabs.filter((e) => !e.isHidden).length < 2 || currentTab?.isLoading
                ? {}
                : {
                    key         : `${index}-tab`,
                    icon        : 'CloseCircle',
                    iconOnClick : () => {
                        setContractsTabs((prev) => {
                            // Detects when the tab deleted is the one that is selected
                            const shouldChangeSelected = prev.find((e) => e.id === currentTab.id)?.isSelected;

                            const updatedList = prev.map((e) => (e.id === currentTab.id ? { isHidden: true, isSelected: false } : e));

                            if (shouldChangeSelected) {
                                const first = updatedList.find((e) => !e.isHidden);
                                if (first) {
                                    first.isSelected = true;
                                }
                            }

                            return updatedList;
                        });
                    },
                };

            return {
                ...currentTab,
                ...iconProps,
                key  : `contract-${currentTab.id}`,
                id   : `contract-${currentTab.id}`,
                name : currentTab.legacyId ? (
                    <FormattedMessage
                        id={translated.global.contractWithId}
                        defaultMessage={translated.global.contractWithId}
                        values={{ id: currentTab.legacyId }}
                    />
                ) : (
                    <FormattedMessage id={translated.global.contract} defaultMessage={translated.global.contract} />
                ),
                isEnabled : true,
                onClick   : () => changeTabHandle(currentTab.id),
                className : !currentTab.isValid || !currentTab.areBankingConfigurationsValid ? 'is-invalid' : '',
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        [changeTabHandle, contractsTabs],
    );

    const tabs = useMemo(
        () => contractsTabs?.map((currentTab) => {
            const currentContract = contractsData.current.list.find((e) => e.id === currentTab.id);

            if (!currentContract) {
                // A contract that has been deleted
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }

            const club = currentTab.clubId ? clubs.find((e) => e.id === currentTab.clubId) : null;
            const contractDateRange = club ? club.dateRange : null;

            const {
                clubId = null,
                tierId = null,
                contractTypeId = null,
                primaryCurrencyId = null,
                primaryCurrencyDelta = null,
                secondaryCurrencyId = null,
                secondaryCurrencyDelta = null,
                begin = null,
                end = null,
                endMinDate = null,
                isLoading = false,
                lastLoadedLink = null,
                areDatesValid,
            } = currentTab;

            const beginMin = contractDateRange?.begin;
            const beginMax = contractDateRange?.end;
            let endMin = endMinDate && dateManager.isValidDate(endMinDate) ? endMinDate : contractDateRange?.begin;
            let endMax = contractDateRange?.end;
            if (club?.dateRange?.type === 'fixed') {
                if (dateManager.isValidDate(begin)) {
                    endMin = dateManager.format(
                        dateManager.add(
                            begin,
                            1,
                            'days',
                        ),
                        'yyyy-MM-dd',
                    );

                    endMax = dateManager.format(
                        dateManager.sub(
                            dateManager.add(
                                begin,
                                club?.dateRange.duration,
                                'months',
                            ),
                            1,
                            'days',
                        ),
                        'yyyy-MM-dd',
                    );
                }
            } else {
                endMin = dateManager.isValidDate(endMinDate) ? endMinDate : begin;
                endMax = beginMax;
            }

            return (
                <Form.Wrapper>
                    <Form
                        id={`owner-import-contract-${currentContract.id}`}
                        buttonsWidth={{ base: 12, medium: 6 }}
                        onChange={updateSelectedContract}
                        forceValidationsOnFirstRender
                        scrollToError={false}
                        initialValues={currentContract}
                        avoidSetComponentWithChanges
                    >
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Hidden submitKey="id" />

                            <Form.Input
                                submitKey="club"
                                isDense
                                type="select"
                                label={translated.owners.club}
                                isRequired
                                value={clubId}
                                options={clubs || []}
                                onChange={(newId) => {
                                    handleClubOnChange(newId, currentContract.id);
                                }}
                                isDisabled={isLoading}
                                defaultOptionText={translated.global.selectAnOption}
                                isBasedOnState
                            />
                            <Form.Input
                                submitKey="tier"
                                isDense
                                isRequired
                                type="select"
                                label={translated.owners.tier}
                                isBasedOnState
                                value={tierId}
                                options={tiers?.[clubId] || []}
                                isDisabled={isLoading}
                                defaultOptionText={
                                    clubId && !tiers?.[clubId]?.length && !isLoading
                                        ? translated.owners.contracts.edition.clubWithNoTiers
                                        : translated.global.selectAnOption
                                }
                                onChange={(newId) => {
                                    handleTierOnChange(newId, currentContract.id);
                                }}
                            />
                            <Form.Input
                                submitKey="contractType"
                                isDense
                                type="select"
                                label={translated.owners.contractType}
                                isRequired
                                isBasedOnState
                                value={contractTypeId}
                                options={contractTypes?.[tierId] || []}
                                isDisabled={isLoading}
                                defaultOptionText={
                                    tierId && !contractTypes?.[tierId]?.length && !isLoading
                                        ? translated.owners.contracts.edition.tierWithNoContractTypes
                                        : translated.global.selectAnOption
                                }
                                onChange={(newId) => {
                                    handleContractTypeOnChange(newId, currentContract.id);
                                }}
                            />

                            <Form.Input
                                submitKey="consumptionMode"
                                isDense
                                isRequired
                                type="select"
                                label={translated.owners.contracts.consumptionMode}
                                value={currentContract?.consumptionMode?.id}
                                options={consumptionModes}
                                submitFormat={(id) => ({ id })}
                                defaultOptionText={translated.global.selectAnOption}
                            />

                            <Form.Input
                                submitKey="begin"
                                onChange={(newValue) => handleOnChangeBeginDate(newValue, currentContract.id)}
                                isDense
                                isRequired
                                supportText={getDatesExtraInfo(beginMin, beginMax)}
                                type="date"
                                icon={{ name: 'Calendar', position: 'right' }}
                                label={translated.owners.contractTypeStartDate}
                                minDate={beginMin}
                                maxDate={beginMax}
                                value={currentContract?.begin}
                                validations={{ minDate: beginMin, maxDate: beginMax, unary: ['date'] }}
                                helperText={{ label: translated.global.date }}
                                className="small-number-input"
                            />
                            <Form.Input
                                submitKey="end"
                                isDense
                                isRequired
                                onChange={(newValue) => handleOnChangeEndDate(newValue, currentContract.id)}
                                supportText={getDatesExtraInfo(endMin, endMax)}
                                type="date"
                                icon={{ name: 'Calendar', position: 'right' }}
                                label={translated.owners.contractTypeEndDate}
                                value={currentContract?.end}
                                minDate={endMin}
                                maxDate={endMax}
                                validations={{
                                    minDate : endMin,
                                    maxDate : endMax,
                                    unary   : ['date'],
                                }}
                                helperText={{ label: translated.global.date }}
                                className="small-number-input"
                            />

                            <Form.Input
                                type="text"
                                label={translated.owners.contractLegacyId}
                                isDense
                                isRequired
                                value={currentContract?.legacyId}
                                submitKey="legacyId"
                                submitFormat={(val) => String(val)}
                                validations={{ maxLength: 36, unary: ['alphanumericUnderScore'] }}
                                charCount={{ total: 36 }}
                                onChange={(newValue) => {
                                    setContractsTabs((prev) => prev.map((e) => (e.id === currentContract.id ? { ...e, legacyId: newValue } : { ...e })));
                                }}
                            />

                            <Form.Input
                                key="highlight"
                                submitKey="highlight"
                                isDense
                                type="checkbox"
                                value={currentContract?.highlight || false}
                                label={translated.owners.contracts.featured.label}
                            />

                            <Form.Title className="margin-top-xsmall margin-bottom-xsmall" isSmall>
                                <FormattedMessage
                                    id={translated.owners.contracts.primaryCurrency}
                                    defaultMessage={translated.owners.contracts.primaryCurrency}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="primaryCurrency"
                                type="select"
                                label={translated.owners.contracts.currency}
                                options={availableCurrencies}
                                value={primaryCurrencyId}
                                submitFormat={(value) => ({ id: value })}
                                isDense
                                isDisabled
                                isBasedOnState
                            />
                            <Form.Input
                                type="number"
                                submitKey="primaryCurrencyDelta"
                                value={primaryCurrencyDelta}
                                validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                                label={translated.owners.contracts.delta}
                                className="small-number-input"
                                onChange={(newValue) => {
                                    // eslint-disable-next-line max-len
                                    setContractsTabs((prev) => prev.map((e) => (e.id === currentContract.id ? { ...e, primaryCurrencyDelta: newValue } : { ...e })));
                                }}
                                isDense
                                isRequired
                                isBasedOnState
                            />

                            <Form.Title className="margin-top-xsmall margin-bottom-xsmall" isSmall>
                                <FormattedMessage
                                    id={translated.owners.contracts.secondaryCurrency}
                                    defaultMessage={translated.owners.contracts.secondaryCurrency}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="secondaryCurrency"
                                type="select"
                                label={translated.owners.contracts.currency}
                                options={availableCurrencies}
                                value={secondaryCurrencyId}
                                submitFormat={(value) => ({ id: value })}
                                isDense
                                isDisabled
                                isBasedOnState
                            />
                            <Form.Input
                                type="number"
                                submitKey="secondaryCurrencyDelta"
                                value={secondaryCurrencyDelta}
                                validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                                label={translated.owners.contracts.delta}
                                className="small-number-input"
                                onChange={(newValue) => {
                                    // eslint-disable-next-line max-len
                                    setContractsTabs((prev) => prev.map((e) => (e.id === currentContract.id ? { ...e, secondaryCurrencyDelta: newValue } : { ...e })));
                                }}
                                isDense
                                isRequired
                                isBasedOnState
                            />
                            <Form.Input
                                id="extra-attributes-contract"
                                key="extra-attributes-contract"
                                type="dynamicInputs"
                                submitKey="extraAttributes"
                                className="margin-top-xxlarge margin-bottom-xxlarge"
                                label={translated.extraAttributes.title}
                                value={currentContract.extraAttributes}
                                availableAttributes={availableContractAttributes}
                                onChange={(newValue) => {
                                    // eslint-disable-next-line max-len
                                    setContractsTabs((prev) => prev.map((e) => (e.id === currentContract.id ? { ...e, extraAttributes: newValue } : { ...e })));
                                }}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                        </Form.Column>
                    </Form>

                    <BankingConfigurationList
                        link={tiers?.[clubId]?.find((e) => e.id === tierId)?.links?.clubTierBankingConfiguration?.read}
                        lastLoadedLink={lastLoadedLink}
                        balanceTypes={currentTab.balanceTypes}
                        elements={currentTab.bankingConfigurations}
                        updateBankingConfigurations={(updated, balanceTypes, linkLoaded = null) => {
                            setContractsTabs((prev) => {
                                const updatedList = prev.map((e) => (e.id !== currentContract.id
                                    ? { ...e }
                                    : {
                                        ...e,
                                        ...getBankingConfigurationProps(updated, begin, end, dateManager),
                                        lastLoadedLink : linkLoaded || e.lastLoadedLink,
                                        balanceTypes   : balanceTypes || e.balanceTypes,
                                    }));

                                return updatedList;
                            });
                        }}
                        isEditEnabled
                        contractData={{ begin, end, extraAttributes }}
                        isEnabled={!!(begin && end && clubId && tierId && contractTypeId && areDatesValid)}
                        disableWarning={translated.owners.bankingConfigurationDisabledWarning}
                    />
                </Form.Wrapper>
            );
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [availableContractAttributes, availableCurrencies, clubs, consumptionModes, contractTypes, contractsTabs,
            extraAttributes, tiers, updateSelectedContract],
    );

    const addContract = () => {
        const newId = contractsData.current.lastId + 1;

        const newContract = {
            id         : newId,
            isSelected : true,
        };

        contractsData.current = {
            list   : [...contractsData.current.list, newContract],
            lastId : newId,
        };

        setContractsTabs((prev) => [...prev.map((e) => ({ ...e, isSelected: false })), newContract]);
    };

    const tabButtons = [
        <Button
            id="tabs-add-button"
            key="tabs-add-button"
            icon="PlusCircleOutline"
            iconRight
            onClick={addContract}
            tooltip="Add Contract"
            variant="text"
            color="primary"
        >
            <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
        </Button>,
    ];

    return (
        <>
            {contractsTabs.find((e) => !e.isHidden && e.isLoading) && <Loading />}

            <Form.Wrapper>
                <SectionsWrapper title={profileTitle} className="owner-wrapper" actionButtons={actionButtons} isStickyHeader>
                    <Form
                        id="owners-import"
                        buttonsWidth={{ base: 12, medium: 6 }}
                        onSubmit={handleOnSubmit}
                        onFinish={navigator.goToParentAndReload}
                        forceValidationsOnFirstRender={!isCreation}
                    >
                        <Form.Hidden submitKey="membershipId" value={data.membershipId} />
                        <Form.Hidden submitKey="type" value="Owner" />
                        <Form.Hidden submitKey="referral" value={referral && referral.id} submitFormat={(id) => (id ? { id: String(id) } : null)} />
                        <Form.Hidden submitKey="department" value={department} />
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Title className="margin-bottom-xsmall">
                                <FormattedMessage id={translated.owners.accountInformation} defaultMessage={translated.owners.accountInformation} />
                            </Form.Title>
                            <Form.Input
                                submitKey="address1"
                                isDense
                                type="text"
                                label={translated.owners.address1}
                                value={data.address1}
                                validations={{ maxLength: 100 }}
                                isRequired
                            />
                            <Form.Input
                                submitKey="address2"
                                isDense
                                type="text"
                                label={translated.owners.address2}
                                value={data.address2}
                                validations={{ maxLength: 100 }}
                            />
                            <Form.Input
                                submitKey="zipCode"
                                isDense
                                type="text"
                                label={translated.global.zipCode}
                                value={data.zipCode}
                                validations={{ maxLength: 25 }}
                                className="zip-input"
                            />
                            <Form.Input
                                submitKey="city"
                                isDense
                                type="text"
                                label={translated.global.city}
                                value={data.city}
                                validations={{ maxLength: 25 }}
                                isRequired
                            />
                            <Form.Input
                                submitKey="state"
                                isDense
                                type="text"
                                label={translated.global.estate}
                                value={data.state}
                                validations={{ maxLength: 25 }}
                            />
                            <Form.Input
                                submitKey="country"
                                isDense
                                type="select"
                                label={translated.global.country}
                                value={data?.country?.id}
                                submitFormat={(id) => ({ id })}
                                options={countries}
                            />
                            <Form.Input
                                type="text"
                                label={translated.owners.legacyId}
                                isDense
                                value={legacyId}
                                submitKey="legacyId"
                                className="small-number-input"
                                validations={{ maxLength: 36, unary: ['alphanumericUnderScore'] }}
                                charCount={{ total: 36 }}
                                isRequired
                            />
                            <Form.Input
                                id="extra-attributes-account"
                                key="extra-attributes-account"
                                submitKey="extraAttributes"
                                type="dynamicInputs"
                                className="margin-top-xxlarge"
                                label={translated.owners.extraAttributes.accountTitle}
                                value={extraAttributes}
                                availableAttributes={availableAccountAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                            <Form.Title className="margin-top-xxlarge margin-bottom-xsmall">
                                <FormattedMessage
                                    id={translated.owners.primaryOwnerInformation}
                                    defaultMessage={translated.owners.primaryOwnerInformation}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="primary.firstName"
                                isDense
                                type="text"
                                label={translated.global.firstName}
                                isRequired
                                value={primary?.firstName}
                                validations={{ minLength: 2, maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="primary.lastName"
                                isDense
                                type="text"
                                label={translated.global.lastName}
                                isRequired
                                value={primary?.lastName}
                                validations={{ minLength: 2, maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="primary.email"
                                isDense
                                type="text"
                                label={translated.global.email}
                                value={primary?.email}
                                validations={{ minLength: 2, maxLength: 255, unary: ['email'] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Input
                                submitKey="primary.phone"
                                isDense
                                type="number"
                                label={translated.global.phone}
                                value={primary?.phone}
                                validations={{ minLength: 2, maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="primary.mobile"
                                isDense
                                type="number"
                                label={translated.global.mobile}
                                value={primary?.mobile}
                                validations={{ minLength: 2, maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="primary.birthday"
                                icon={{ name: 'Calendar', position: 'right' }}
                                type="date"
                                label={translated.global.birthday}
                                value={primary?.birthday}
                                minDate={birthdayMinValue}
                                maxDate={birthdayMaxValue}
                                validations={{ minDate: birthdayMinValue, maxDate: birthdayMaxValue, unary: ['date'] }}
                                isDense
                                helperText={{ label: 'Year, month, day' }}
                                className="date-input"
                            />
                            <Form.Input
                                submitKey="primary.language"
                                isDense
                                type="select"
                                label={translated.global.language}
                                value={primary?.language}
                                options={languages}
                                sortBy="content"
                            />
                            <Form.Input
                                submitKey="primary.loginToken"
                                isDense
                                type="text"
                                label={translated.global.loginToken}
                                value={primary?.loginToken}
                                validations={{ maxLength: 255, ignoreChars: [' '] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Input
                                id="extra-attributes-primary"
                                key="extra-attributes-primary"
                                submitKey="primary.extraAttributes"
                                className="margin-top-xxlarge"
                                label={translated.owners.extraAttributes.primaryTitle}
                                type="dynamicInputs"
                                value={primary?.extraAttributes}
                                availableAttributes={availableUserAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                            <Form.Hidden submitKey="primary.pictureUrl" value={primary?.pictureUrl} />
                            <Form.Title className="margin-top-xxlarge margin-bottom-xsmall">
                                <FormattedMessage
                                    id={translated.owners.secondaryOwnerInformation}
                                    defaultMessage={translated.owners.secondaryOwnerInformation}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="secondary.firstName"
                                isDense
                                type="text"
                                label={translated.global.firstName}
                                value={secondary?.firstName}
                                validations={{ minLength: 2, maxLength: 100 }}
                                charCount={{ total: 100 }}
                                isRequired={isSecondaryOwnerRequired}
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, firstName: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.lastName"
                                isDense
                                type="text"
                                label={translated.global.lastName}
                                value={secondary?.lastName}
                                validations={{ minLength: 2, maxLength: 100 }}
                                charCount={{ total: 100 }}
                                isRequired={isSecondaryOwnerRequired}
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, lastName: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.email"
                                isDense
                                type="text"
                                label={translated.global.email}
                                value={secondary?.email}
                                validations={{ maxLength: 255, unary: ['email'] }}
                                charCount={{ total: 255 }}
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, email: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.phone"
                                isDense
                                type="number"
                                label={translated.global.phone}
                                value={secondary?.phone}
                                validations={{ minLength: 3, maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, phone: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.mobile"
                                isDense
                                type="number"
                                label={translated.global.mobile}
                                value={secondary?.mobile}
                                validations={{ minLength: 3, maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, mobile: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.birthday"
                                icon={{ name: 'Calendar', position: 'right' }}
                                type="date"
                                label={translated.global.birthday}
                                value={secondary?.birthday}
                                minDate={birthdayMinValue}
                                maxDate={birthdayMaxValue}
                                validations={{ minDate: birthdayMinValue, maxDate: birthdayMaxValue, unary: ['date'] }}
                                isDense
                                helperText={{ label: 'Year, month, day' }}
                                className="date-input"
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, birthday: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                submitKey="secondary.language"
                                isDense
                                type="select"
                                label={translated.global.language}
                                value={secondary?.language}
                                options={languages}
                                sortBy="content"
                                hasCleanButton
                                onChange={(id) => setSecondaryOwnerData((prev) => ({ ...prev, language: !!id }))}
                            />
                            <Form.Input
                                submitKey="secondary.loginToken"
                                isDense
                                type="text"
                                label={translated.global.loginToken}
                                value={secondary?.loginToken}
                                validations={{ maxLength: 255, ignoreChars: [' '] }}
                                charCount={{ total: 255 }}
                                onChange={(text) => setSecondaryOwnerData((prev) => ({ ...prev, loginToken: text.trim().length > 0 }))}
                            />
                            <Form.Input
                                id="extra-attributes-secondary"
                                key="extra-attributes-secondary"
                                submitKey="secondary.extraAttributes"
                                className="margin-top-xxlarge"
                                label={translated.owners.extraAttributes.secondaryTitle}
                                type="dynamicInputs"
                                value={secondary?.extraAttributes}
                                availableAttributes={availableUserAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                            <Form.Hidden submitKey="secondary.pictureUrl" value={secondary?.pictureUrl} />
                            <Form.Input
                                // Flag used to identify when the form changed.
                                type="hidden"
                                submitKey="changed"
                                avoidOnSubmit
                                markAsChanged
                                value={hasContractsChanged}
                            />
                        </Form.Column>
                    </Form>
                    <Form.Title className="margin-top-xxlarge margin-bottom-xsmall">
                        <FormattedMessage id={translated.owners.contracts.title} defaultMessage={translated.owners.contracts.title} />
                    </Form.Title>
                    <Tab key="contracts" tabs={tabList} headButtons={tabButtons}>
                        {tabs}
                    </Tab>
                </SectionsWrapper>
            </Form.Wrapper>
        </>
    );
}

OwnersGeneralForImported.defaultProps = { isCreation: false };

OwnersGeneralForImported.propTypes = {
    data       : PropTypes.shape({}).isRequired,
    resources  : PropTypes.shape({}).isRequired,
    isCreation : PropTypes.bool,
};

OwnersGeneralForImported.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={240} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            {/* Account Information */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={230} />
                    <Skeleton.Form type="input" quantity={2} />
                    <Skeleton.Form type="input" smallInput />
                    <Skeleton.Form type="input" quantity={3} />
                    <Skeleton.Form type="input" smallInput />
                </Grid.Column>
            </Grid>
            {/* Account Extra Attributes */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={270} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Primary Owner Information */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={290} />
                    <Skeleton.Form type="input" quantity={3} />
                    <Skeleton.Form type="input" quantity={3} smallInput />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
            {/* Primary Extra Attributes */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={265} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Secondary Owner Information */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={320} />
                    <Skeleton.Form type="input" quantity={3} />
                    <Skeleton.Form type="input" quantity={3} smallInput />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
            {/* Secondary Extra Attributes */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={295} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Contract */}
            <Grid className="margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" />
                    <Skeleton.Form type="input" quantity={4} />
                    <Skeleton.Form type="input" quantity={2} smallInput />
                    <Skeleton.Form type="input" />
                    <Skeleton.Form type="checkbox" />
                    <Skeleton.Title isSubtitle className="margin-top-medium margin-bottom-small" />
                    <Skeleton.Form type="input" />
                    <Skeleton.Form type="input" smallInput />
                    <Skeleton.Title isSubtitle className="margin-top-medium margin-bottom-small" />
                    <Skeleton.Form type="input" />
                    <Skeleton.Form type="input" smallInput />
                </Grid.Column>
            </Grid>
            {/*  Extra Attributes */}
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={295} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(OwnersGeneralForImported);
