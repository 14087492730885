import React, { useContext, useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Home from '../../pages/index';
import Modal from 'Components/Modal';
import Loading from 'Components/Loading';
import { login, syncLogout, getLoggedUser, getToken, getUrlError } from './authMethods';
import PanelContext from 'State/panelContext';
import { REFRESH_NOW, LANGUAGE_SWITCH, restrictedAppReasons } from 'Constants/global';
import { getErrorDetails } from 'Utils/errorService';
import { Socket } from 'Services';

// eslint-disable-next-line import/prefer-default-export
export const withAuthSync = (WrappedComponent) => function WithAuthSync({ ...properties }) {
    WithAuthSync.displayName = `withAuthSync(${WrappedComponent.name})`;

    const { currentState } = properties;
    const { isAppRestricted } = currentState.restrictedApp;
    const { shouldRefreshUser } = currentState.error;
    const { user, app, url } = useContext(PanelContext);

    const [state, setState] = useState({
        isReady           : false,
        isUserInitialized : false,
        token             : undefined,
        loggedUser        : null,
        error             : null,
        shouldForceLogin  : false,
    });

    // With useRef we avoid re-renders that we don't need (is not necessary to show the loading on this component)
    const isLoading = useRef(false);

    useEffect(() => {
        window.addEventListener('storage', syncLogout);

        const token = getToken(url.pushToUrl);
        const error = getUrlError();

        setState((previous) => ({ ...previous, error, token, isReady: true, shouldForceLogin: !error }));

        if (!error && !token) {
            login();
        }

        return () => {
            window.localStorage.removeItem('logout');
            window.removeEventListener('storage', syncLogout);
        };
    }, [url.pushToUrl]);

    useEffect(() => {
        // When the user is erased of the redux state, but it is in the state, the user no longer has a valid token.
        if (!user && state.loggedUser) {
            setState((prev) => ({
                ...prev,
                error            : translated.login.errors.noLongerValidToken,
                token            : null,
                shouldForceLogin : true,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const { isReady, loggedUser, error, token, isUserInitialized, shouldForceLogin } = state;

    if (error && !shouldRefreshUser) {
        return <Home error={error} shouldForceLogin={shouldForceLogin} />;
    }

    let refreshPermissionsModal;

    if (isReady && token && (!loggedUser || shouldRefreshUser) && !isLoading.current) {
        // We have the token, but not the user or we need to refresh the user permissions and data
        const refreshResource = shouldRefreshUser ? currentState.user.links.self.refresh : undefined;

        const initUser = (reqConfig) => getLoggedUser(reqConfig)
            .then(async (agentData) => {
                setState((previous) => ({ ...previous, loggedUser: agentData }));
                const {
                    agent: { user: _user, links, role },
                    urls,
                    notifications,
                } = agentData;

                user.set({ ..._user, links, role: role?.name });
                await app.setRootAndTemplates(urls);
                // Initializes the notifications.
                if (urls?.templates?.notifications && properties?.initializeNotifications) {
                    properties.initializeNotifications(urls?.templates?.notifications);
                }

                setState((prev) => ({ ...prev, isUserInitialized: true }));
                isLoading.current = false;

                if (notifications?.token && notifications?.url && notifications?.payload) {
                    const { token: notificationToken, url: notificationUrl, payload } = notifications;
                    Socket.initialize(
                        {
                            url   : notificationUrl,
                            token : notificationToken,
                            payload,
                        },
                        app.handleNotification,
                    );
                } else {
                    // We cant initialize the notifications because we don't receive all the necessary data.
                }
            })
            .catch((e) => {
                const { shouldRestrictApp, shouldRefreshUser: isUserError, error: requestError } = getErrorDetails(e);
                if (shouldRestrictApp) {
                    const restrictedAppErrorMessage = requestError?.data?.errors[0].messages[0];
                    app.setAsRestricted(true, restrictedAppErrorMessage || null, restrictedAppReasons.MAINTENANCE);
                    return;
                }

                if (isUserError || requestError?.status === 403) {
                    // The user is not allowed to access the system. When an owner tries to access APF,
                    //   we will receive a 403 error on the logged-agent service.
                    app.setAsRestricted(true, null, restrictedAppReasons.NO_PERMISSIONS);
                    return;
                }

                setState((prev) => ({
                    ...prev,
                    error            : translated.login.errors.loggedUser,
                    token            : null,
                    shouldForceLogin : true,
                }));

                if (refreshResource) {
                    app.setErrorState(translated.global.errors.cantGetTheData, e.statusCode, true);
                }

                isLoading.current = false;
            });

        // First initialization, get the initial data
        if (!shouldRefreshUser) {
            isLoading.current = true;
            initUser();
            // Force refresh (change in permissions, in API, etc)
        } else if (shouldRefreshUser === REFRESH_NOW) {
            isLoading.current = true;
            initUser(refreshResource);
            // Let know the user before refresh (refresh on click)
        } else {
            const message = shouldRefreshUser === LANGUAGE_SWITCH ? translated.global.languageChange : translated.agents.onPermissionsChange;
            refreshPermissionsModal = (
                <Modal
                    title={translated.global.warning}
                    closeButton={{
                        onClick: async () => {
                            await initUser(refreshResource); // We need to tell the APT to update the user's permissions
                            // eslint-disable-next-line no-restricted-globals
                            location.reload(); // We reload the page completely, to avoid problems with the permission change on the current page
                        },
                        text: translated.global.buttons.refresh,
                    }}
                >
                    <span>
                        <FormattedMessage id={message} />
                    </span>
                </Modal>
            );
        }
    }

    // The user has no permissions, set no permissions page
    if (loggedUser?.urls?.root?.length === 0 && !isAppRestricted) {
        app.setAsRestricted(true, null, restrictedAppReasons.NO_PERMISSIONS);
    }
    // The token and user are loaded
    if (isUserInitialized || isAppRestricted) {
        return (
            <>
                {isLoading.current && !isAppRestricted && <Loading />}
                {refreshPermissionsModal}
                <WrappedComponent {...properties} />
            </>
        );
    }

    return <Home />;
};
