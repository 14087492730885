import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import panelContext from 'State/panelContext';

function FileDetails({ className, id, creation, modification, filename, invalidRows }) {
    const { dateManager } = useContext(panelContext);

    return (
        <table className={`table-details ${className}`}>
            <tbody>
                {id && (
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage defaultMessage={translated.global.id} id={translated.global.id} />
                        </td>
                        <td className="text-small padding-left-small">{id}</td>
                    </tr>
                )}
                {filename && (
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage defaultMessage={translated.owners.imports.filename} id={translated.owners.imports.filename} />
                        </td>
                        <td className="text-small padding-left-small">{filename}</td>
                    </tr>
                )}
                {!!invalidRows?.length && (
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-error font-weight-bold">
                            <FormattedMessage defaultMessage={translated.owners.imports.row} id={translated.owners.imports.row} />
                        </td>
                        <td className="text-small padding-left-small text-color-error font-weight-bold">{invalidRows.join(', ')}</td>
                    </tr>
                )}
                {creation && (
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage defaultMessage={translated.owners.imports.creation} id={translated.owners.imports.creation} />
                        </td>
                        <td className="text-small padding-left-small">{dateManager.defaultDateTimeFormat(creation)}</td>
                    </tr>
                )}
                {modification && (
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage defaultMessage={translated.owners.imports.modification} id={translated.owners.imports.modification} />
                        </td>
                        <td className="text-small padding-left-small">{dateManager.defaultDateTimeFormat(modification)}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

FileDetails.defaultProps = {
    className   : '',
    filename    : '',
    invalidRows : null,
    sections    : {},
};

FileDetails.propTypes = {
    id           : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    creation     : PropTypes.string.isRequired,
    modification : PropTypes.string.isRequired,
    className    : PropTypes.string,
    filename     : PropTypes.string,
    invalidRows  : PropTypes.arrayOf(PropTypes.number),
    sections     : PropTypes.shape({}),
};

export default FileDetails;
