/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fetchingShape } from 'Constants/PropTypes';
import { sectionType } from 'Constants/types';
import PanelContext from 'State/panelContext';
import Button from 'Components/Button';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Subsection from 'Components/Sections/Subsection';
import Card from 'Components/Card';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import RelationshipEditor from './Relationship';
import { getOwnerName } from '../../Owners/Details/utils';
import OwnerCard from '../../Owners/Details/OwnerCard';
import ExtraAttributesModal from '../../Owners/Details/ExtraAttributesModal';

export function Users({
    data,
    sections,
    navigateToUserEdition,
    pathForResource,
    reloadData,
    resources,
    primaryOwner,
    secondaryOwner,
}) {
    const { navigator, confirmation, snackbar } = useContext(PanelContext);
    const [selectedUser, setSelectedUser] = useState(null);

    const onAddRelationship = (user) => {
        setSelectedUser(user);

        navigator.goToRelationshipEditor({
            path      : pathForResource,
            available : user.links.relationships,
            current   : 'init',
        });
    };

    const onUserEdit = (user = null) => {
        if (navigator[navigateToUserEdition] && (!user || (user?.links?.self?.read))) {
            navigator[navigateToUserEdition]({
                available : user ? user?.links?.self : data.links.self,
                current   : user ? 'read' : 'init',
            });
        }
    };

    const [extraAttributesModal, setExtraAttributesModal] = useState({ isVisible: false, items: {}, type: '' });

    const actions = [];

    if (data?.links?.self?.create) {
        actions.push(
            <Button id="user-add-button" variant="outlined" key="button_primary" onClick={() => onUserEdit()}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        );
    }

    const users = data?.data || [];

    const sortedUsers = users.sort((a, b) => {
        // Prioritization of users according to match
        const getPriority = (user) => {
            if (user.id === primaryOwner?.id) {
                return 1; // highest priority
            }
            if (user.id === secondaryOwner?.id) {
                return 2; // second priority
            }
            return 3; // lower priority
        };
        // Compare priorities
        return getPriority(a) - getPriority(b);
    });

    const onUserActivate = async (user) => confirmation.show({
        title         : translated.owners.users.activateAccount.confirmationTitle,
        message       : translated.owners.users.activateAccount.confirmationMessage,
        acceptMessage : translated.global.buttons.confirm,
        onAccept      : async () => {
            try {
                await navigator.directRequest(user.links.self.activation);
                snackbar.show({
                    content   : translated.owners.users.activateAccount.success,
                    isSuccess : true,
                });
            } catch (error) {
                snackbar.show({
                    error,
                    content : translated.owners.users.activateAccount.error,
                    isError : true,
                });
            }

            reloadData();
        },
    });

    const handleUserDelete = async (user) => {
        try {
            const reqConfig = {
                ...user.links.self.delete,
                ids              : [user.id],
                shouldReloadData : true,
                isCritical       : false,
                isGlobal         : false,
            };
            await navigator.requestForCurrentPath({ reqConfig, path: pathForResource, resources });

            snackbar.show({ content: translated.owners.relationships.delete.userDeleted, isSuccess: true });
        } catch (error) {
            snackbar.show({ error, content: translated.owners.relationships.delete.errorOnUser, isError: true });
        }
    };

    const onUserDelete = (user) => {
        confirmation.show({
            message       : translated.owners.relationships.delete.userConfirm,
            acceptMessage : translated.owners.relationships.delete.acceptMessage,
            onAccept      : () => handleUserDelete(user),
        });
    };

    return (
        <Subsection actionButtons={actions} title={translated.owners.users.title}>
            {sections[sectionType.RELATIONSHIP]?.isSelected && (
                <RelationshipEditor {...sections[sectionType.RELATIONSHIP]} users={users} initLinks={data.links} user={selectedUser} />
            )}
            <Grid className="margin-bottom-medium">
                            {sortedUsers.map((user, index) => {
                                const convertedPrimary = {
                                    ...user,
                                    language: user?.language && user?.additionalResources?.languages?.find((e) => e.id === user.language)?.name,
                                };

                                let subtitle = null;
                                if (user.id === primaryOwner?.id) {
                                    subtitle = translated.owners.primaryOwner;
                                } else if (user.id === secondaryOwner?.id) {
                                    subtitle = translated.owners.secondaryOwner;
                                }

                                return (
                                    <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                                        <Card
                                            id="primary-owner"
                                            className="info-card"
                                            unelevated
                                            title={getOwnerName(user.extraAttributes?.salutation?.value, user.firstName, user.lastName)}
                                            subtitle={subtitle}
                                            primaryButton={
                                                user?.links?.self?.read && user?.links?.self?.update
                                                    ? {
                                                        id      : 'owners-primary-owner-edit-button',
                                                        text    : translated.global.buttons.edit,
                                                        onClick : () => onUserEdit(user),
                                                    }
                                                    : {
                                                        id      : 'owners-primary-owner-edit-button',
                                                        text    : translated.global.buttons.view,
                                                        onClick : () => onUserEdit(user),
                                                    }
                                            }
                                            secondaryButton={
                                                user?.links?.self?.delete && (primaryOwner.id !== user.id)
                                                    ? {
                                                        id      : 'owners-primary-owner-delete-button',
                                                        icon    : 'TrashCan',
                                                        onClick : () => onUserDelete(user),
                                                        tooltip : translated.global.buttons.remove,
                                                        text    : translated.global.buttons.remove,
                                                    }
                                                    : null
                                            }
                                            primaryRightButton={
                                                user?.links?.relationships?.create
                                                    ? {
                                                        id      : 'owners-primary-owner-add-relationship-button',
                                                        icon    : 'AccountPlus',
                                                        onClick : () => onAddRelationship(user),
                                                        tooltip : translated.owners.relationships.addRelationship,
                                                        color   : 'primary',
                                                    }
                                                    : null
                                            }
                                            secondaryRightButton={
                                                user?.links?.self?.activation
                                                    ? {
                                                        id      : 'owners-primary-owner-mail-send-button',
                                                        icon    : 'EmailSend',
                                                        onClick : () => onUserActivate(user),
                                                        tooltip : translated.global.resendEmail,
                                                        color   : 'primary',
                                                    }
                                                    : null
                                            }
                                        >
                                            <OwnerCard id="owners-primary-owner" owner={convertedPrimary} configureModal={setExtraAttributesModal} />
                                        </Card>
                                        {extraAttributesModal.isVisible && (
                                            <ExtraAttributesModal
                                                {...extraAttributesModal}
                                                onClose={() => setExtraAttributesModal({ isVisible: false, items: {}, type: '' })}
                                            />
                                        )}
                                    </Grid.Column>
                                );
                            })}
            </Grid>
        </Subsection>
    );
}

Users.defaultProps = {
    primaryOwner    : null,
    secondaryOwner  : null,
    blockedUsers    : [],
    setSectionState : () => {
        // Default
    },
    state      : {},
    reloadData : () => {
        // Default
    },
};

Users.propTypes = {
    data                  : PropTypes.shape({ links: PropTypes.shape({}) }).isRequired,
    resources             : PropTypes.shape({}).isRequired,
    fetching              : fetchingShape.isRequired,
    sections              : PropTypes.shape({}).isRequired,
    primaryOwner          : PropTypes.shape({}),
    secondaryOwner        : PropTypes.shape({}),
    navigateToUserEdition : PropTypes.string.isRequired,
    pathForResource       : PropTypes.arrayOf(PropTypes.string).isRequired,
    blockedUsers          : PropTypes.arrayOf(PropTypes.number),
    setSectionState       : PropTypes.func,
    state                 : PropTypes.shape({}),
    reloadData            : PropTypes.func,
};

Users.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isSubHeading width={75} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table hasTitle={false} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(Users);
