import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { useFormContext } from 'react-hook-form';
import Button from 'Components/Button';

function PreviewButton({ id, onClick }) {
    const { formState, getValues } = useFormContext() || {};

    const { errors } = formState || {};

    const isButtonDisabled = !!Object.keys(errors)?.length;

    const handleOnClick = () => {
        onClick(getValues());
    };

    return (
        <Button
            id={id}
            variant="outlined"
            onClick={handleOnClick}
            disabled={isButtonDisabled}
            className="margin-right-small"
            size="small"
        >
            <FormattedMessage id={translated.accountStatements.preview.title} defaultMessage={translated.accountStatements.preview.title} />
        </Button>
    );
}

PreviewButton.defaultProps = {};

PreviewButton.propTypes = {
    id      : PropTypes.string.isRequired,
    onClick : PropTypes.func.isRequired,
};

export default PreviewButton;
