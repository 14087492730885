import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tab from 'Components/Tab';
import Loading from 'Components/Loading';
import Wrapper from '../../Wrapper';
import panelContext from 'State/panelContext';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
import { SelectedSection } from 'Components/Sections/Collection';
import Grid from 'Components/Grid';
import Form from 'Components/Form';
import translated from 'Constants/labels/translated';
import { csvStatus } from 'Constants/global';
import FileManager from './FileManager';
import CSVHelp from './CSVHelp';
import Button from 'Components/Button';

const isEnabled = true;
const { PENDING, COMPLETED, LOCKED, ERROR, INVALID } = csvStatus;
const UPLOAD_TIMEOUT = 40000;

export function Import({ data, reloadData, resources, sections }) {
    const { navigator, snackbar } = useContext(panelContext);

    const [selectedTab, setSelectedTab] = useState(0);
    const [isFetching, setIsFetching] = useState();
    const [isHelpVisible, setIsHelpVisible] = useState(false);

    const { filter } = data;

    const handleOnUpload = useCallback(
        async (file) => {
            setIsFetching(true);
            let newTab = 0;
            try {
                const reqConfig = {
                    ...resources.available.create,
                    file,
                    isTypeFile : true,
                    timeout    : UPLOAD_TIMEOUT,
                };
                await navigator.directRequest(reqConfig);

                await reloadData();
            } catch (e) {
                newTab = 1;
                snackbar.show({ content: translated.imports.theFileHasErrors, isError: true });
            } finally {
                if (selectedTab === newTab) {
                    await reloadData();
                } else {
                    setSelectedTab(newTab);
                }

                setIsFetching(false);
            }
        },
        [navigator, reloadData, resources.available.create, selectedTab, snackbar],
    );

    // Section: Add Owner
    const { Section, props } = SelectedSection.get(sections);
    if (Section) {
        return <Section {...props} />;
    }

    return (
        <Wrapper
            title={translated.sectionName.ownersImport}
            className="owners-import"
        >
            {isFetching && <Loading />}
            {isHelpVisible && <CSVHelp onClose={() => setIsHelpVisible(false)} />}
            <Grid>
                <Grid.Column width={{ base: 12, small: 5, medium: 6 }} />
                <Grid.Column width={{ base: 12, small: 7, medium: 6 }} className="file-upload-wrapper">
                    {resources.available.create && (
                        <>
                            <Form.Input
                                id="owners-import-upload-input"
                                type="upload"
                                icon={{ name: 'FileUpload' }}
                                onUpload={handleOnUpload}
                                uploadText={translated.owners.imports.upload}
                                isDense
                            />
                            <Button
                                id="owners-import-csv-help"
                                className="margin-left-xsmall"
                                icon="InformationOutline"
                                tooltip={translated.owners.imports.title}
                                onClick={() => setIsHelpVisible(true)}
                            />
                        </>
                    )}
                </Grid.Column>
            </Grid>
            <Tab
                className="margin-top-small"
                tabs={[
                    {
                        isSelected : selectedTab === 0,
                        name       : translated.imports.valid,
                        onClick    : () => setSelectedTab(0),
                        isEnabled,
                    },
                    {
                        isSelected : selectedTab === 1,
                        name       : translated.imports.error,
                        onClick    : () => setSelectedTab(1),
                        isEnabled,
                    },
                ]}
            >
                {[
                    <FileManager
                        type="csvs"
                        key="csv-valids"
                        filterBy={{ status: [PENDING, COMPLETED] }}
                        filterLinks={filter}
                        onCompletedFieldClick={navigator.goToDetails}
                        onIncompleteFieldClick={navigator.goToOwnerImport}
                        performRequest={navigator.directRequest}
                        sections={sections}
                    />,
                    <FileManager
                        type="csvs"
                        key="csv-invalids"
                        filterBy={{ status: [ERROR, LOCKED, INVALID] }}
                        filterLinks={filter}
                        onCompletedFieldClick={navigator.goToDetails}
                        onIncompleteFieldClick={navigator.goToOwnerImport}
                        performRequest={navigator.directRequest}
                        avoidFetchingFiles
                        sections={sections}
                    />,
                ]}
            </Tab>
        </Wrapper>
    );
}

Import.defaultProps = { data: { csvs: [], order: {} }, sections: {} };

Import.propTypes = {
    reloadData : PropTypes.func.isRequired,
    resources  : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({
        csvs  : PropTypes.arrayOf(PropTypes.shape({})),
        order : PropTypes.shape({ orderedBy: PropTypes.shape({}) }),
    }),
    sections: PropTypes.shape({}),
};

Import.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={305} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }} />
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" />
                </Grid.Column>
            </Grid>
            <Grid className="margin-top-small margin-bottom-small owners-tabs-loading" childWidth={{ base: 12 }}>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
                <div>
                    <Skeleton.Title isSubHeading width={90} />
                </div>
            </Grid>
        </>
    );
};

export default withRequest(Import);
