import React from 'react';
/* eslint-disable no-useless-escape */
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { getTextBeforeSeparator } from 'Utils/text';
import validDomains from 'Constants/validDomains';

function isNotYetComplete(value) {
    return typeof value === 'undefined';
}

function isNotNullUndefinedOrEmpty(originalValue) {
    const value = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
    return typeof value !== 'undefined' && value !== null && value !== '';
}

export function analyzeValue(value, isList = false, validValues = null) {
    if (isList) {
        const isListCompleted = Array.isArray(value) && value.length;

        return {
            isFullyComplete     : isListCompleted,
            isPartiallyComplete : isListCompleted,
            isNotYetComplete    : isNotYetComplete(value),
        };
    }

    // Null is an object, thank you javascript
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        let isFirstFieldCompleted;
        let isSecondFieldCompleted;
        let isFieldNotYetComplete;

        if ('inputValue' in value || 'selectedOption' in value) {
            isFirstFieldCompleted = isNotNullUndefinedOrEmpty(value.inputValue);
            isSecondFieldCompleted = isNotNullUndefinedOrEmpty(value.selectedOption);
            isFieldNotYetComplete = isNotYetComplete(value.inputValue);
        } else if ('min' in value || 'max' in value) {
            isFirstFieldCompleted = isNotNullUndefinedOrEmpty(value.min);
            isSecondFieldCompleted = isNotNullUndefinedOrEmpty(value.max);
            isFieldNotYetComplete = isNotYetComplete(value.min) && isNotYetComplete(value.max);
        } else if ('begin' in value || 'end' in value) {
            isFirstFieldCompleted = isNotNullUndefinedOrEmpty(value.begin);
            isSecondFieldCompleted = isNotNullUndefinedOrEmpty(value.end);
            isFieldNotYetComplete = isNotYetComplete(value.begin) && isNotYetComplete(value.end);
        } else if ('addedValues' in value) {
            isFirstFieldCompleted = (Array.isArray(value.addedValues) && value.addedValues.length) || value?.text;

            isSecondFieldCompleted = isFirstFieldCompleted;
            isFieldNotYetComplete = isNotYetComplete(value.text) && !value?.addedValues?.length;
        }

        return {
            isFullyComplete     : isFirstFieldCompleted && isSecondFieldCompleted,
            isPartiallyComplete : isFirstFieldCompleted || isSecondFieldCompleted,
            isNotYetComplete    : isFieldNotYetComplete,
        };
    }

    let isFieldCompleted; // When value is undefined, the field 'complete' is returned as undefined also.

    if (value !== undefined) {
        isFieldCompleted = validValues?.length
            ? isNotNullUndefinedOrEmpty(value) && !!validValues.find((e) => String(e.value) === String(value))
            : isNotNullUndefinedOrEmpty(value);
    }

    return {
        isFullyComplete     : isFieldCompleted,
        isPartiallyComplete : isFieldCompleted,
        isNotYetComplete    : isNotYetComplete(value),
    };
}

// NOTE: ValidationValue will be used when translate message error
// eslint-disable-next-line no-unused-vars
const getError = (key, validationValue) => {
    // Get translated message
    switch (key) {
        case 'email':
            return <FormattedMessage id={translated.global.invalidEmail} defaultMessage={translated.global.invalidEmail} />;
        case 'pickerDate':
        case 'date':
            return <FormattedMessage id={translated.global.invalidDate} defaultMessage={translated.global.invalidDate} />;
        case 'alphanumeric':
            return <FormattedMessage id={translated.global.alphanumeric} defaultMessage={translated.global.alphanumeric} />;
        case 'noNumbers':
            return <FormattedMessage id={translated.global.invalidNoNumber} defaultMessage={translated.global.invalidNoNumber} />;
        case 'numbers':
            return <FormattedMessage id={translated.global.invalidOnlyNumber} defaultMessage={translated.global.invalidOnlyNumber} />;
        case 'numbersWithSeparator':
            return <FormattedMessage id={translated.global.invalidOnlyNumber} defaultMessage={translated.global.invalidOnlyNumber} />;
        case 'float':
            return <FormattedMessage id={translated.global.invalidNumber} defaultMessage={translated.global.invalidNumber} />;
        case 'phone':
            return <FormattedMessage id={translated.global.invalidPhone} defaultMessage={translated.global.invalidPhone} />;
        case 'slider':
            return <FormattedMessage id={translated.global.invalidRange} defaultMessage={translated.global.invalidRange} />;
        case 'required':
            return <FormattedMessage id={translated.global.required} defaultMessage={translated.global.required} />;
        case 'minValue':
            return (
                <FormattedMessage id={translated.global.invalidMin} defaultMessage={translated.global.invalidMin} values={{ value: validationValue }} />
            );
        case 'maxValue':
            return (
                <FormattedMessage id={translated.global.invalidMax} defaultMessage={translated.global.invalidMax} values={{ value: validationValue }} />
            );
        default:
            return <FormattedMessage id={translated.global.invalid} defaultMessage={translated.global.invalid} />;
    }
};

const extractPropFromObject = (container, propName) => (container != null && typeof container === 'object' ? container[propName] : null);

const isValidEmail = (value) => {
    const re = new RegExp(
        `^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+(${validDomains}))$`,
        'i',
    );
    return re.test(String(value).toLowerCase());
};

const hasOnlyLetters = (value) => /^[a-zA-Z]+$/.test(String(value));

const isAlphanumeric = (value) => /^[a-zA-Z0-9]+$/.test(String(value));

const isAlphanumericUnderScore = (value) => /^[a-zA-Z0-9\_]+$/.test(String(value));

const isAlphanumericLowercaseUnderScore = (value) => /^[a-z0-9][a-zA-Z0-9\_]+$/.test(String(value));

const getDecimalsLength = (value) => {
    const decimals = String(value).split('.')?.[1] || '';

    return decimals.length;
};

const hasNumbers = (value) => /\d/g.test(value);

const hasOnlyNumbers = (value) => /^-?\d*(\.\d+)?$/.test(value);

const isInteger = (value) => /^-?\d*$/.test(value);

const isFloat = (value) => hasOnlyNumbers(value);

const isValidMinValue = (value, validationValue) => !hasOnlyNumbers(validationValue) || +value >= +validationValue;

const isValidMaxValue = (value, validationValue) => !hasOnlyNumbers(validationValue) || +value <= +validationValue;

const isValidMinDecimalLength = (value, validationValue) => !hasOnlyNumbers(validationValue) || getDecimalsLength(value) >= +validationValue;

const isValidMaxDecimalLength = (value, validationValue) => !hasOnlyNumbers(validationValue) || getDecimalsLength(value) <= +validationValue;

const hasMinLength = (value, validationValue) => !hasOnlyNumbers(validationValue) || String(value).trim().length >= +validationValue;

const hasMaxLength = (value, validationValue) => !hasOnlyNumbers(validationValue) || String(value).trim().length <= +validationValue;

// eslint-disable-next-line max-len
const hasMinDate = (value, validationValue, dateManager) => !dateManager.isValidDate(value) || !dateManager.isValidDate(validationValue) || dateManager.isSameOrBefore(validationValue, value);

// eslint-disable-next-line max-len
const hasMaxDate = (value, validationValue, dateManager) => !dateManager.isValidDate(value) || !dateManager.isValidDate(validationValue) || dateManager.isSameOrAfter(validationValue, value);

const isPhoneNumber = (value) => !value || /^\d+([\ \-]\d+)*$/.test(value);

const ignoreChars = (value, validationValue) => validationValue.every((e) => String(value).indexOf(e) === -1);

const getUnaryRulesErrors = (rules, value, isRequired, dateManager) => {
    const errors = [];

    if (isRequired && !analyzeValue(value).isFullyComplete) {
        errors.push(getError('required'));
    }

    if (rules && rules.length && value) {
        rules.forEach((eachRule) => {
            let isValid = true;

            switch (eachRule) {
                case 'email':
                    isValid = isValidEmail(value);
                    break;
                case 'onlyLetters':
                    isValid = hasOnlyLetters(value);
                    break;
                case 'alphanumeric':
                    isValid = isAlphanumeric(value);
                    break;
                case 'alphanumericUnderScore':
                    isValid = isAlphanumericUnderScore(value);
                    break;
                case 'alphanumericLowercaseUnderScore':
                    isValid = isAlphanumericLowercaseUnderScore(value);
                    break;
                case 'date':
                    isValid = dateManager.isValidDate(value);
                    break;
                case 'pickerDate':
                    const begin = extractPropFromObject(value, 'begin');
                    const end = extractPropFromObject(value, 'end');

                    isValid = begin == null || end == null || (dateManager.isValidDate(begin)
                        && dateManager.isValidDate(end) && dateManager.isSameOrBefore(begin, end));
                    break;
                case 'noNumbers':
                    isValid = !hasNumbers(value);
                    break;
                case 'numbers':
                    if (typeof value === 'object') {
                        const minValue = extractPropFromObject(value, 'min');
                        const maxValue = extractPropFromObject(value, 'max');
                        isValid = isInteger(minValue) && isInteger(maxValue);
                    } else {
                        isValid = isInteger(value);
                    }
                    break;
                case 'numbersWithSeparator':
                    const firstPart = getTextBeforeSeparator(extractPropFromObject(value, 'text'));
                    if (firstPart) {
                        isValid = isInteger(firstPart);
                    }

                    break;
                case 'float':
                    isValid = isFloat(value);
                    break;
                case 'phone':
                    isValid = isPhoneNumber(value);
                    break;
                case 'slider':
                    const minValue = extractPropFromObject(value, 'min');
                    const maxValue = extractPropFromObject(value, 'max');

                    isValid = minValue == null || maxValue == null || +minValue <= +maxValue;
                    break;
                default:
                    break;
            }

            if (!isValid) {
                errors.push(getError(eachRule));
            }
        });
    }

    return errors;
};

const getAllRulesErrors = (rules = {}, value, isRequired, dateManager) => {
    const errors = [...getUnaryRulesErrors(rules.unary, value, isRequired, dateManager)];

    if (value) {
        Object.keys(rules).forEach((eachRule) => {
            let isValid = true;

            const validationValue = rules[eachRule];
            let minValue;
            let maxValue;
            let begin;
            let end;

            switch (eachRule) {
                case 'minValue':
                    isValid = isValidMinValue(value, validationValue);
                    break;
                case 'maxValue':
                    isValid = isValidMaxValue(value, validationValue);
                    break;
                case 'minSeparatedValue':
                    const parsedValue = extractPropFromObject(value, 'text');
                    isValid = !parsedValue || isValidMinValue(getTextBeforeSeparator(parsedValue), validationValue);
                    break;
                case 'maxSeparatedValue':
                    const parsed = extractPropFromObject(value, 'text');
                    isValid = !parsed || isValidMaxValue(getTextBeforeSeparator(parsed), validationValue);
                    break;
                case 'minDecimals':
                    isValid = isValidMinDecimalLength(value, validationValue);
                    break;
                case 'maxDecimals':
                    isValid = isValidMaxDecimalLength(value, validationValue);
                    break;
                case 'minLength':
                    isValid = hasMinLength(value, validationValue);
                    break;
                case 'maxLength':
                    isValid = hasMaxLength(value, validationValue);
                    break;
                case 'minDate':
                    isValid = hasMinDate(value, validationValue, dateManager);
                    break;
                case 'pickerMinDate':
                    begin = extractPropFromObject(value, 'begin');
                    end = extractPropFromObject(value, 'end');

                    isValid = (begin == null || hasMinDate(begin, validationValue, dateManager))
                        && (end == null || hasMinDate(end, validationValue, dateManager));
                    break;
                case 'maxDate':
                    isValid = hasMaxDate(value, validationValue, dateManager);
                    break;
                case 'pickerMaxDate':
                    begin = extractPropFromObject(value, 'begin');
                    end = extractPropFromObject(value, 'end');

                    isValid = (begin == null || hasMaxDate(begin, validationValue, dateManager))
                        && (end == null || hasMaxDate(end, validationValue, dateManager));
                    break;
                case 'sliderMinValue':
                    minValue = extractPropFromObject(value, 'min');
                    maxValue = extractPropFromObject(value, 'max');

                    isValid = (minValue == null || isValidMinValue(minValue, validationValue))
                        && (maxValue == null || isValidMinValue(maxValue, validationValue));
                    break;
                case 'sliderMaxValue':
                    minValue = extractPropFromObject(value, 'min');
                    maxValue = extractPropFromObject(value, 'max');

                    isValid = (minValue == null || isValidMaxValue(minValue, validationValue))
                        && (maxValue == null || isValidMaxValue(maxValue, validationValue));
                    break;
                case 'ignoreChars':
                    isValid = ignoreChars(value, validationValue);
                    break;
                default:
                    break;
            }

            if (!isValid) {
                errors.push(getError(eachRule, validationValue));
            }
        });
    }

    return errors;
};

/**
 * Return a list of all the validation errors for the given value.
 */
const areRulesValid = (rules, value, isRequired, dateManager) => {
    if (Array.isArray(rules)) {
        return [...getUnaryRulesErrors(rules, value, isRequired, dateManager)];
    }

    return [...getAllRulesErrors(rules, value, isRequired, dateManager)];
};

export default areRulesValid;
