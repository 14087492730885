import React, { useState, useEffect, forwardRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Alert from 'Components/Alert';
// eslint-disable-next-line import/no-cycle
import Form from 'Components/Form';
import Button from 'Components/Button';
// eslint-disable-next-line import/no-cycle
import dynamicInputGenerator from 'Components/Form/Input/dynamicInputGenerator';
import { transformToCamel } from 'Utils/text';
import { contractAmount } from 'Constants/translator';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import panelContext from 'State/panelContext';

const buildStoredValues = (values, available) => {
    const transformed = {};
    Object.keys(values || {}).forEach((key) => {
        const found = available.find((a) => a.slug === values[key].slug);
        const value = values[key].value !== null ? values[key].value : null;

        transformed[key] = {
            dataType   : found ? found.dataType : values[key].dataType,
            name       : values[key].name,
            options    : found?.options,
            slug       : values[key].slug,
            isDisabled : values[key].isDisabled || !found,
            value,
        };
    });

    return transformed;
};

/**
 * Component used to group a multi selector input and dynamic inputs. First, choose a type
 * a new input from the selector. On each selection a new input is created using provided data
 * to generate an input for number, strings, dates, etc.
 */
function DynamicInputsWrapper(
    {
        id, label, className, value, availableAttributes, onChange, isReadOnly, isDisabled, isReadOnlyWithoutInputs, forceAmountUpdate,
    },
    ref,
) {
    const [available, setAvailable] = useState({ list: null, selected: null });
    const [storedValues, setStoredValues] = useState(buildStoredValues(value, availableAttributes));
    const [hasChanged, setHasChanged] = useState(false);

    const { dateManager } = useContext(panelContext);

    const updateList = () => {
        const slugs = Object.values(storedValues).map((a) => a.slug);
        const filtered = availableAttributes.filter((e) => !slugs.includes(e.slug));
        const list = filtered.map((attribute) => ({
            id    : attribute.id,
            value : {
                id   : attribute.id,
                name : attribute.name,
            },
            content: attribute.name,
        }));
        setAvailable({ list });
    };

    useEffect(() => {
        setStoredValues(buildStoredValues(value, availableAttributes));
    }, [availableAttributes, value]);

    const update = useCallback(
        (values) => {
            setStoredValues(values);
            setHasChanged(true);
            if (onChange) {
                const event = { target: { value: values } };
                onChange(event);
            }
        },
        [onChange],
    );

    useEffect(() => {
        // We use this to force the update of the contractAmount prop
        if (forceAmountUpdate && forceAmountUpdate !== -1) {
            const updated = { ...storedValues };
            Object.keys(updated).forEach((e) => {
                if (updated[e]?.slug === contractAmount) {
                    updated[e].value = Number(forceAmountUpdate);
                }
            });
            update(updated);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceAmountUpdate]);

    useEffect(() => {
        if (availableAttributes) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedValues]);

    const handleOnChange = (newValue) => {
        setAvailable((prev) => ({ ...prev, selected: newValue }));
    };

    const handleOnRemove = useCallback(
        (inputId) => {
            const updated = { ...storedValues };
            delete updated[inputId];
            update(updated);
            setHasChanged(true);
        },
        [storedValues, update],
    );

    const handleOnInputChange = useCallback(
        (inputId, updatedValue) => {
            const updated = { ...storedValues };
            updated[inputId] = updatedValue;
            update(updated);
        },
        [storedValues, update],
    );

    const handleOnAdd = async () => {
        const selected = available.selected.map((attribute) => {
            const found = availableAttributes.find((a) => a.id === attribute.id);
            const transformed = {};
            const data = found.dataType === 'Bool' ? false : null;
            transformed[transformToCamel(found.slug)] = {
                dataType : found.dataType,
                name     : found.name,
                options  : found.options,
                slug     : found.slug,
                value    : data,
            };

            return transformed;
        });
        let newAttributes = {};
        selected.forEach((attribute) => {
            newAttributes = { ...newAttributes, ...attribute };
        });
        setStoredValues((prev) => {
            const newValue = { ...prev, ...newAttributes };
            if (onChange) {
                const event = { target: { value: newValue } };
                onChange(event);
            }

            return newValue;
        });
        setHasChanged(true);
    };

    const inputs = dynamicInputGenerator(storedValues, handleOnInputChange, handleOnRemove, dateManager);

    return (
        <div className={className} ref={ref}>
            {label && (
                <Form.Title className="margin-bottom-xsmall">
                    <WrappedFormattedMessage content={label} />
                </Form.Title>
            )}
            <Form.Wrapper>
                <Form
                    id={`dynamic-inputs-form-${id}`}
                    buttonsWidth={{ base: 12, small: 6 }}
                    isReadOnly={isReadOnly}
                    isReadOnlyWithoutInputs={isReadOnlyWithoutInputs}
                    isDisabled={isDisabled}
                    avoidSetComponentWithChanges
                    className="dynamic-attributes-form"
                >
                    <Form.Input type="hidden" submitKey="changed" avoidOnSubmit markAsChanged forceValidationsOnFirstRender value={hasChanged} />
                    {!isReadOnly && !isDisabled && !isReadOnlyWithoutInputs && (
                        <Form.Column width={{ base: 12, small: 6 }} className="add-group">
                            <Form.Input
                                id={`dynamic-inputs-selector-${id}`}
                                type="select"
                                submitKey="dynamicInputs"
                                label={translated.extraAttributes.selection}
                                options={available?.list || []}
                                onChange={handleOnChange}
                                value={available?.selected}
                                isDense
                                isMultiSelect
                                isBasedOnState
                                avoidMarkAsChangedOnValidationsChange
                            />
                        </Form.Column>
                    )}
                    {!isReadOnly && !isDisabled && !isReadOnlyWithoutInputs && (
                        <Form.Column width={{ base: 12, small: 6 }} className="add-group">
                            <Button
                                id={`add-button-${id}`}
                                key={`add-button-${id}`}
                                onClick={() => handleOnAdd()}
                                disabled={!available?.selected?.length}
                                variant="outlined"
                                color="primary"
                            >
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Button>
                        </Form.Column>
                    )}
                    {inputs.length === 0 && (
                        <Form.Column width={{ base: 12, small: 6 }} className="margin-top-xsmall">
                            <Alert
                                id={`dynamic-inputs-alert-${id}`}
                                content={translated.extraAttributes.empty}
                            />
                        </Form.Column>
                    )}
                    <Form.Column width={{ base: 12, small: 6 }} className="margin-top-medium">{inputs}</Form.Column>
                </Form>
            </Form.Wrapper>
        </div>
    );
}
const inputWithRef = forwardRef(DynamicInputsWrapper);
DynamicInputsWrapper.defaultProps = {
    id                      : '',
    className               : '',
    label                   : '',
    onChange                : null,
    isReadOnly              : false,
    isDisabled              : false,
    isReadOnlyWithoutInputs : false,
    availableAttributes     : null,
    value                   : {},
    forceAmountUpdate       : '',
};

DynamicInputsWrapper.propTypes = {
    id        : PropTypes.string,
    className : PropTypes.string,
    label     : PropTypes.shape({
        title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    onChange                : PropTypes.func,
    isReadOnly              : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    availableAttributes     : PropTypes.arrayOf(PropTypes.shape({})),
    value                   : PropTypes.shape({}),
    forceAmountUpdate       : PropTypes.number,
};

inputWithRef.displayName = 'Form.DynamicInputsWrapper';

export default inputWithRef;
