import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import Button from 'Components/Button';
import { Waypoint } from 'react-waypoint';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function handleBlockScroll(arg) {
    document.body.style.overflowY = arg;
}

function Wrapper({
    className, title, titleValues, actionButtons, children, isFetching, isStickyHeader, steps, onAfterTour, onBeforeTour,
}) {
    const [isSticky, setIsSticky] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);

    const setStickyWhenHitsTop = ({ currentPosition }) => {
        const { inside, above, below } = Waypoint;

        if (!isSticky && currentPosition === above) {
            setIsSticky(true);
        } else if (isSticky && (currentPosition === inside || currentPosition === below)) {
            setIsSticky(false);
        }
    };

    const handleOnAfterTourOpen = () => {
        handleBlockScroll('hidden');
        if (onAfterTour) {
            onAfterTour();
        }
    };

    const handleOnBeforeTourOpen = () => {
        handleBlockScroll('auto');
        if (onBeforeTour) {
            onBeforeTour();
        }
    };

    return (
        <>
            {steps && (
                <Tour
                    steps={steps.map((content, i) => ({ selector: `.step-${i + 1}`, content }))}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    className="tour"
                    rounded={4}
                    accentColor="#b4a258"
                    disableInteraction
                    showCloseButton={false}
                    onAfterOpen={handleOnAfterTourOpen}
                    onBeforeClose={handleOnBeforeTourOpen}
                />
            )}
            {isFetching && <div className="loading-line" />}
            <div className={`section ${className}`}>
                {isStickyHeader && <Waypoint onPositionChange={setStickyWhenHitsTop} topOffset={80} />}
                {title && (
                    <div id="section-header" className={`section-header ${isSticky ? 'is-sticky' : ''}`}>
                        <div className="section-header-title h3">
                            <WrappedFormattedMessage content={title} values={titleValues} />
                        </div>
                        {(actionButtons || steps) && (
                            <div className="section-header-buttons">
                                {actionButtons}
                                {steps && (
                                    <Button
                                        id="tour-button"
                                        className="button-tour"
                                        icon="HelpCircle"
                                        variant="unelevated"
                                        color="secondary"
                                        onClick={() => setIsTourOpen(true)}
                                    >
                                        Need help
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div id="section-content" className="section-content">{children}</div>
            </div>
        </>
    );
}

Wrapper.defaultProps = {
    className      : '',
    title          : '',
    titleValues    : null,
    isFetching     : false,
    isStickyHeader : false,
    steps          : null,
    actionButtons  : [],
    onAfterTour    : null,
    onBeforeTour   : null,
};

Wrapper.propTypes = {
    className      : PropTypes.string,
    isFetching     : PropTypes.bool,
    isStickyHeader : PropTypes.bool,
    children       : PropTypes.node.isRequired,
    title          : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleValues    : PropTypes.shape({}),
    actionButtons  : PropTypes.arrayOf(PropTypes.node),
    steps          : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    onAfterTour    : PropTypes.func,
    onBeforeTour   : PropTypes.func,
};

export default Wrapper;
