import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import PhotoGallery from 'Components/PhotoGallery';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';

function ImageGallery({
    label,
    isReadOnlyWithoutInputs,
    isDisabled,
    id,
    name,
    defaultValue,
    images,
    placeholder,
}) {
    const intl = useIntl();
    const imageAlt = useMemo(() => getMessage(intl, translated.global.imagePicker.imageAlt), [intl]);
    const imagePlaceholder = useMemo(() => getMessage(intl, placeholder || translated.global.imagePicker.placeHolder), [intl, placeholder]);

    const inputIsDisabled = useMemo(() => isReadOnlyWithoutInputs || isDisabled, [isDisabled, isReadOnlyWithoutInputs]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const context = useFormContext();
    const { setValue, control } = context || {};

    const selectImage = (newImage) => {
        if (newImage) {
            setValue(name, newImage?.id, { shouldDirty: true, shouldValidate: true });
        }

        setIsModalOpen(false);
    };

    const getImage = useCallback((imageId) => images?.find?.((e) => e.id === imageId), [images]);

    const removeImage = () => {
        setValue(name, '', { shouldDirty: true, shouldValidate: true });
    };

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {/* We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types). */}
            <Controller
                render={
                    ({ field }) => {
                        const image = getImage(field.value);

                        return (
                            <>
                                {isModalOpen && (
                                    <PhotoGallery
                                        id={`${id}-modal`}
                                        title={translated.global.imagePicker.placeHolder}
                                        onSelect={selectImage}
                                        photos={images}
                                        selected={field.value}
                                    />
                                )}

                                <div className={`img-file ${image ? 'has-image' : ''}`}>
                                    {image
                                        ? (
                                            <img src={image?.url} alt={imageAlt} />
                                        )
                                        : (
                                            <div
                                                id={`${id}-add`}
                                                onClick={() => setIsModalOpen(true)}
                                                className="img-file-empty"
                                            >
                                                <Icon path={IconPath.mdiImageOutline} className="mdi-icon" size="28px" />
                                                <span>{imagePlaceholder}</span>
                                            </div>
                                        )}
                                    {image && (
                                        <Button
                                            id={`${id}-remove`}
                                            icon="TrashCan"
                                            onClick={removeImage}
                                            disabled={inputIsDisabled}
                                            color="primary"
                                            tooltip={translated.global.buttons.remove}
                                        />
                                    )}
                                </div>
                            </>
                        );
                    }
                }
                control={control}
                name={name}
                defaultValue={defaultValue || ''}
            />
        </>
    );
}

ImageGallery.defaultProps = {
    id                      : '',
    label                   : '',
    name                    : '',
    isReadOnlyWithoutInputs : false,
    isDisabled              : false,
    defaultValue            : null,
    placeholder             : '',
};

ImageGallery.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    placeholder             : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name                    : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    defaultValue            : PropTypes.string,
    images                  : PropTypes.arrayOf(PropTypes.shape({
        id        : PropTypes.string,
        thumbnail : PropTypes.string,
        caption   : PropTypes.string,
    })).isRequired,
};

export default React.memo(ImageGallery);
