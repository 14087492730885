module.exports = Object.freeze({
    title         : 'apf.extraAttributes.title',
    empty         : 'apf.extraAttributes.empty',
    createTitle   : 'apf.extraAttributes.createTitle',
    editTitle     : 'apf.extraAttributes.editTitle',
    viewTitle     : 'apf.extraAttributes.viewTitle',
    updateFailed  : 'apf.extraAttributes.updateFailed',
    updateSuccess : 'apf.extraAttributes.updateSuccess',
    saveSuccess   : 'apf.extraAttributes.saveSuccess',
    selection     : 'apf.extraAttributes.selection',
    name          : 'apf.extraAttributes.name',
    categories    : 'apf.extraAttributes.categories',
    slug          : 'apf.extraAttributes.slug',
    dataType      : 'apf.extraAttributes.dataType',
    hidden        : 'apf.extraAttributes.hidden',
    status        : 'apf.extraAttributes.status',
    isActive      : 'apf.extraAttributes.isActive',
    confirmation  : { title: 'apf.extraAttributes.confirmation.title', message: 'apf.extraAttributes.confirmation.message' },
    errors        : { empty: 'apf.extraAttributes.errors.empty' },
    options       : {
        empty      : { title: 'apf.extraAttributes.options.empty.title', message: 'apf.extraAttributes.options.empty.message' },
        title      : 'apf.extraAttributes.options.title',
        editButton : 'apf.extraAttributes.options.editButton',
        repeated   : 'apf.extraAttributes.options.repeated',
        edition    : {
            title     : 'apf.extraAttributes.options.edition.title',
            option    : 'apf.extraAttributes.options.edition.option',
            addButton : 'apf.extraAttributes.options.edition.addButton',
            errors    : { repeated: 'apf.extraAttributes.options.edition.errors.repeated', length: 'apf.extraAttributes.options.edition.errors.length' },
        },
    },
    enable  : { success: 'apf.extraAttributes.enable.success', error: 'apf.extraAttributes.enable.error' },
    disable : { success: 'apf.extraAttributes.disable.success', error: 'apf.extraAttributes.disable.error' },
    delete  : {
        confirmation               : 'apf.extraAttributes.delete.confirmation',
        success                    : 'apf.extraAttributes.delete.success',
        error                      : 'apf.extraAttributes.delete.error',
        errorEntityCannotBeDeleted : 'apf.extraAttributes.delete.errorEntityCannotBeDeleted',
    },
});
