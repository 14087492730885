import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import getClassName from 'Utils/getClassName';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function defaultFormat(value, type) {
    if (type === 'slider') {
        const { min, max } = value;
        return `${min ? `Minimum: ${min}` : ''}${min && max ? ', ' : ''}${max ? `Maximum: ${max}` : ''}`;
    }

    if (type === 'range') {
        const { begin, end } = value;
        return `${begin ? `Begin: ${begin}` : ''}${begin && end ? ', ' : ''}${end ? `End: ${end}` : ''}`;
    }

    if (type === 'split') {
        return `Option: ${value.selectedOption}, Value: ${value.inputValue ? value.inputValue : ''}`;
    }

    if (type === 'select' || type === 'selectWithFilter') {
        if (Array.isArray(value)) {
            return value.reduce((prev, curr) => [...prev, curr.name], []).join(', ');
        }
        if (typeof value === 'object') {
            return value.name;
        }
    }

    if (type === 'checkbox' || type === 'switch') {
        return value ? (
            <span className="status status-enabled">
                <FormattedMessage id={translated.global.state.yes} defaultMessage={translated.global.state.yes} />
            </span>
        ) : (
            <span className="status status-disabled">
                <FormattedMessage id={translated.global.state.no} defaultMessage={translated.global.state.no} />
            </span>
        );
    }

    return value;
}

function SummaryField({
    label, value, summaryFormat, type, isValid, isCompleted, optionLabel, suffix, prefix, formValues, id, isRequired,
}) {
    // First we indicate that the field is required, and second if it is invalid
    let validity = '';
    if (isValid === false) {
        validity = translated.global.invalid;

        if (isRequired && isCompleted === false) {
            validity = translated.global.required;
        }
    }

    const titleRowClass = type !== 'title' ? '' : 'has-title';
    const titleCellClass = type !== 'title' ? 'card-summary-label' : 'card-summary-title';

    return (
        <tr id={id} className={getClassName({ textColorError: validity }, titleRowClass)}>
            <td colSpan={type !== 'title' ? '' : 2} className={titleCellClass}>
                <WrappedFormattedMessage content={label} />
            </td>
            {type !== 'title' && (
                <td>
                    <WrappedFormattedMessage content={validity || (
                        (summaryFormat
                            && summaryFormat({
                                value,
                                suffix,
                                prefix,
                                label,
                                isValid,
                                isCompleted,
                                formValues,
                            }))
                        || optionLabel
                        || defaultFormat(value, type)
                    )}
                    />
                </td>
            )}
        </tr>
    );
}

SummaryField.defaultProps = {
    id            : '',
    optionLabel   : '',
    suffix        : null,
    prefix        : null,
    summaryFormat : null,
    isValid       : null,
    isCompleted   : null,
    formValues    : null,
    type          : '',
    isRequired    : false,
    value         : undefined,
};

SummaryField.propTypes = {
    id            : PropTypes.string,
    summaryFormat : PropTypes.func,
    formValues    : PropTypes.shape({}),
    label         : PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    value         : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape({})]),
    type          : PropTypes.string,
    optionLabel   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    suffix        : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    prefix        : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    isCompleted   : PropTypes.bool,
    isValid       : PropTypes.bool,
    isRequired    : PropTypes.bool,
};

export default SummaryField;
