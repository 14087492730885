/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { getClassName, transformToSnake } from 'Utils';
import { useDeepEffect } from 'Hooks';
import Checkbox from './Checkbox';
import Chip from './Chip';
import Date from './Date';
import Number from './Number';
import Radio from './Radio';
import Select from './Select';
import SelectWithFilter from './SelectWithFilter';
import Slider from './Slider';
import Switch from './Switch';
import Text from './Text';
import Split from './Split';
import Textarea from './Textarea';
import Wrapper from './Wrapper';
import Quantity from './Quantity';
import Password from './Password';
import Picker from './Picker';
import Search from './Search';
import Upload from './Upload';
import InputWithChip from './InputWithChip';
import areRulesValid, { analyzeValue } from '../Validator';
import FormContext from '../formContext';
import DynamicInputWrapper from 'Components/Form/DynamicInputWrapper';
import panelContext from 'State/panelContext';

function getInputClass(
    isDisabled,
    isDense,
    isSelected,
    isSelectable,
    isValid,
    isCompleted,
    isFocused,
    formIcon,
    hasPrefix,
    hasSuffix,
    type,
    hasChanged,
    isReadOnlyWithoutInputs,
    isMultiSelect,
    displayOptionsAmount,
) {
    let classesObject = {
        isDisabled,
        isDense,
        formIcon,
        isFocused,
        hasPrefix,
        hasSuffix,
        isMultiSelect,
        isMultiLine: !!displayOptionsAmount,
    };
    const iconClass = formIcon && formIcon.position === 'right' ? 'form-icon-flip' : '';

    if (!isReadOnlyWithoutInputs && ((isSelectable && isSelected) || !isSelectable)) {
        if (isValid) {
            classesObject.isValid = true;
        } else if (isValid !== undefined && hasChanged) {
            classesObject.isInvalid = true;
        }
    }

    if (isCompleted !== undefined) {
        classesObject = isCompleted ? { ...classesObject, isCompleted } : { ...classesObject, isIncomplete: true };
    }

    return getClassName(classesObject, iconClass, `type-${type}`);
}

/**
 * 'validations' is either an array or an object.
 * * When the input only has unary validations (do not require a value to compare), it will
 *      be an array with the validation's names.
 *      For instance: ['numbers', 'email']
 * * When the input has validations that require a value to compare (for instance, 'min-value'
 *      require a value to compare) it will be a object with the structure: a key for each
 *      parameterized validation, and the key 'unary' with the list of all unary validations.
 *      For instance: { 'min-value': 1, 'max-value': 10 , 'unary': ['numbers']}
 * 'avoidMarkAsChangedOnValidationsChange' the flag indicates that the input should not update the form's input
 *      data when 'isDisabled' changes. It is used in the selects that get disabled when the
 *      options are loaded. Without this ,when the input is enabled it is also marked as edited.
 * 'markAsChanged' the flag indicates that the input should be marked as changed when a change is triggered,
 *      even when the input type is 'hidden'.
 */

function Input(props, ref) {
    const {
        type,
        icon,
        submitKey,
        validations,
        className,
        onChange,
        onSelect,
        onPropsChange,
        onSwitch,
        onUpload,
        isCompleted: isInitiallyCompleted,
        isReadOnlyWithoutInputs,
        isSelectable,
        isDense,
        isDisabled,
        isSelected,
        isMultiSelect,
        isRequired,
        helperText,
        charCount,
        prefix,
        suffix,
        summaryFormat,
        value: inputValue,
        supportText,
        displayOptionsAmount,
        onFocus: inputOnFocus,
        onBlur: inputOnBlur,
        isBasedOnState,
        isFullReset,
        toggleLabel,
        formId,
        onClean,
        avoidMarkAsChangedOnValidationsChange,
        hasCleanButton,
        markAsChanged = false,
        hideErrorText,
        options,
        forceValidationsOnFirstRender,
        ...restOfProps
    } = props;
    const [currentValue, setCurrentValue] = useState(inputValue);
    const [isFocused, setIsFocused] = useState(false);
    const [isPreviouslyDisabled, setIsPreviouslyDisabled] = useState(isDisabled);
    const [isPreviouslyRequired, setIsPreviouslyRequired] = useState(isRequired);

    // Allows us an extra flag, util when we need to check if focus or not an selectable input with children.
    const [isTargetFocused, setIsTargetFocused] = useState(false);

    const firstRender = useRef(true);
    const inputRef = useRef();
    const innerInputRef = useRef();

    const { dateManager } = useContext(panelContext);
    const { isSubmitting } = useContext(FormContext);

    const [state, setState] = useState({
        validationErrors : [],
        isValid          : undefined,
        isCompleted      : isInitiallyCompleted,
        hasChanged       : false,
        currentCharCount : charCount ? { ...charCount, current: String(inputValue || '').trim().length } : null,
    });

    const performValidations = ({
        originalValue = currentValue,
        isCurrentlySelected = isSelected,
        forceValidation = false,
        hasBeenDisabled = false,
    } = {}) => {
        // When the input in disabled AND it wasn't disabled recently. If the input was
        // disabled, it must run the validations to remove the errors it might have.

        // When a filter is used, we need to avoid to show validations if it is selectable and is not selected, this avoid to show validations messages after a reset and data re-fetch on filters.
        if ((isDisabled && !hasBeenDisabled) || (!isSelected && isSelectable && isFullReset)) {
            return state;
        }

        const updatedState = { ...state };
        const { isFullyComplete, isNotYetComplete, isPartiallyComplete } = analyzeValue(originalValue, isMultiSelect, options);
        let value = originalValue;

        if (type === 'split') {
            // The split can fire the onChange with the dropdown, this way
            // we avoid to show an error when he has not entered a value yet

            if (isNotYetComplete && isPartiallyComplete && !forceValidation) {
                return state;
            }
            value = originalValue.inputValue;
        }

        let errors = [];

        const isCurrentlyCompleted = !isRequired || isDisabled || (((isSelectable && isCurrentlySelected) || !isSelectable) && isFullyComplete);
        if (!isDisabled) {
            // If the value is not required, the 'isCompleted' variable will be true
            errors = areRulesValid(validations, value, false, dateManager);

            if (!isCurrentlyCompleted) {
                errors.push(<FormattedMessage id={translated.global.required} defaultMessage={translated.global.required} />);
            }
        }

        updatedState.isCompleted = isCurrentlyCompleted;
        updatedState.isValid = !errors.length;
        updatedState.validationErrors = errors;
        updatedState.hasChanged = true;

        // Update the char count
        if (state.currentCharCount) {
            const { length } = value && value.trim() !== '' ? String(value).trim() : '';
            updatedState.currentCharCount = { ...state.currentCharCount, current: length };
        }
        setState(updatedState);
        return { ...updatedState };
    };

    useEffect(() => {
        const [value, storedValue] = type === 'split' ? [inputValue.inputValue, currentValue.inputValue] : [inputValue, currentValue];
        let isTheValueChanged;
        let isValid;
        let isCompleted;

        if (type === 'hidden' && typeof value === 'object') {
            isTheValueChanged = JSON.stringify(storedValue) !== JSON.stringify(value);
        } else if (value !== undefined) {
            // NOTE check that the undef is not breaking anything
            isTheValueChanged = storedValue !== value;
        } else if ((type === 'select' || type === 'selectWithFilter') && value === undefined && storedValue != null) {
            // For the selects when the value is erased.
            isTheValueChanged = storedValue !== value;
        }

        if (
            isTheValueChanged
            || (isDisabled && !isPreviouslyDisabled) // The input was disabled
            || (value && isDisabled !== isPreviouslyDisabled) // The input was enabled and it had data loaded
            || isRequired !== isPreviouslyRequired
        ) {
            setCurrentValue(inputValue);
            if (value !== undefined) {
                ({ isValid, isCompleted } = performValidations({ originalValue: inputValue, hasBeenDisabled: isDisabled }));
            }
        }

        // When the change is made inside a 'hidden' input, this does not come
        // from an html event, so we must manually trigger the onChange event
        if ((!firstRender.current && type === 'hidden' && onChange && isTheValueChanged) || (isBasedOnState && onChange && isTheValueChanged)) {
            if (isBasedOnState && !firstRender.current && isValid === undefined && isCompleted === undefined) {
                ({ isValid, isCompleted } = performValidations({ originalValue: inputValue, hasBeenDisabled: isDisabled }));
            }
            // The hidden inputs shouldn't be marked as changed
            onChange(value, submitKey, { isValid, isCompleted, summaryFormat, isSelectable, isDisabled, isRequired }, type !== 'hidden' || markAsChanged);
        }

        // If disabled or required was changed, we must bubble up current props like isValid, isCompleted, etc.
        if (isDisabled !== isPreviouslyDisabled) {
            setIsPreviouslyDisabled(isDisabled);

            if (onPropsChange) {
                onPropsChange(
                    submitKey,
                    {
                        prefix,
                        suffix,
                        isSelectable,
                        isDisabled,
                        isRequired,
                        isValid,
                        isCompleted,
                    },
                    !avoidMarkAsChangedOnValidationsChange,
                );
            }
        }

        if (isRequired !== isPreviouslyRequired) {
            setIsPreviouslyRequired(isRequired);

            if (onPropsChange) {
                onPropsChange(
                    submitKey,
                    {
                        prefix,
                        suffix,
                        isSelectable,
                        isDisabled,
                        isRequired,
                        isValid,
                        isCompleted,
                    },
                    !avoidMarkAsChangedOnValidationsChange,
                );
            }
        }
    }, [inputValue, isDisabled, isInitiallyCompleted, isRequired, isSelectable, isSelected, currentValue, type]);

    // We must perform new validations when the validations prop changes, this allow us to have dynamic validations
    // useDeepEffect compares objects and other variable types to be equals or not
    useDeepEffect(() => {
        if (!firstRender.current && !analyzeValue(currentValue, isMultiSelect, options).isNotYetComplete) {
            const lastState = { ...state };

            if (state.isValid !== undefined) {
                // The input changed previously
                const { isValid, isCompleted } = performValidations();
                if ((isCompleted !== lastState.isCompleted || !!isValid !== !!lastState.isValid) && onChange) {
                    onChange(currentValue, submitKey, { isValid, isCompleted }, false);
                }
            }
        }
    }, [validations]);

    useEffect(() => {
        // Updates the form data if the props have changed, we avoid the call in the first render
        if (!firstRender.current && onPropsChange) {
            onPropsChange(submitKey, { prefix, suffix, isSelectable, isDisabled, isRequired });
        }
    }, [prefix, suffix, isSelectable, isRequired]);

    useEffect(() => {
        firstRender.current = false;
    });

    useEffect(() => {
        if (forceValidationsOnFirstRender) {
            if (onChange) {
                // We send the validation data to the form, to update said data.
                const { isValid, isCompleted } = performValidations({ forceValidation: true });

                onChange(currentValue, submitKey, { isValid, isCompleted, summaryFormat, isSelectable, isDisabled, isRequired });
            } else {
                // We just update the input validation
                performValidations({ forceValidation: true });
            }
        }
    }, []);

    const runValidations = () => performValidations({ forceValidation: true });
    const reset = () => {
        firstRender.current = true;
        const updatedState = { ...state };
        updatedState.isValid = undefined;
        updatedState.validationErrors = undefined;
        updatedState.isCompleted = isInitiallyCompleted;
        if (isFullReset) {
            setCurrentValue('');
        }
        setState(updatedState);
    };

    // Provide to the father the ability to run the validations
    useImperativeHandle(ref, () => ({ runValidations, reset, element: inputRef }));

    // Replaces the onChange function to process certain validations each time changes occur,
    // then invokes the onChange function and transmits the processed information.
    // When 'propertyKey' is received, the input's value is an object. The received key is
    // the name of the property to change inside the object value. Split input uses this.

    const handleOnChange = (e) => {
        if (isDisabled) {
            return;
        }
        const { value, label: optionLabel } = e.target;

        setCurrentValue(value);
        const { isValid, isCompleted } = performValidations({ originalValue: value });

        if (onChange) {
            onChange(value, submitKey, {
                isValid,
                isCompleted,
                summaryFormat,
                optionLabel,
                isSelectable,
                isDisabled,
                isRequired,
            });
        }
    };

    // Set focus on inputs that are selectable and was previously selected
    useEffect(() => {
        if (isFocused && innerInputRef?.current && isSelectable && isSelected) {
            if (!isTargetFocused) {
                innerInputRef.current.focus();
            }
        }
    }, [isFocused, isSelected]);

    // Checkbox Toggle
    const handleOnSelect = (checked) => {
        if (isDisabled) {
            return;
        }
        if (onSwitch) {
            onSwitch(checked);
        }

        setIsFocused(true);

        if (!checked) {
            setIsTargetFocused(false);
            // Reset the validation state
            const updatedState = { ...state };
            updatedState.isValid = undefined;
            updatedState.validationErrors = undefined;
            updatedState.isCompleted = isInitiallyCompleted;
            setState(updatedState);

            if (onPropsChange) {
                onPropsChange(submitKey, {
                    isSelected  : checked,
                    isValid     : undefined,
                    isCompleted : undefined,
                });
            }
        } else {
            if (onSelect) {
                onSelect(checked);
            }
            // When is unchecked whe perform the validations to set the completed feedback in screen

            if (analyzeValue(currentValue, isMultiSelect, options).isPartiallyComplete) {
                const { isValid, isCompleted } = performValidations({ originalValue: currentValue, isCurrentlySelected: checked });

                if (onPropsChange) {
                    onPropsChange(submitKey, { isSelected: checked, isValid, isCompleted });
                }
            }
        }
    };

    const handleOnFocus = useCallback(
        (value) => {
            setIsTargetFocused(true);
            setIsFocused(true);
            if (inputOnFocus) {
                inputOnFocus(value);
            }
        },
        [inputOnFocus],
    );

    const handleOnBlur = useCallback(
        (value) => {
            setIsFocused(false);
            if (inputOnBlur) {
                inputOnBlur(value);
            }
        },
        [inputOnBlur],
    );

    // Render–
    const processedProps = {
        ...restOfProps,
        isReadOnlyWithoutInputs,
        isMultiSelect,
        displayOptionsAmount,
        summaryFormat,
        icon,
        innerInputRef,
        onUpload,
        isBasedOnState,
        options,
        onChange   : handleOnChange,
        value      : currentValue,
        onFocus    : handleOnFocus,
        onBlur     : handleOnBlur,
        isDisabled : isDisabled || (isSelectable && !isSelected),
        id         : restOfProps.id || (submitKey ? `${formId || 'default'}-${transformToSnake(submitKey)}` : ''),
    };

    let selectedInput;
    switch (type) {
        case 'text':
            selectedInput = <Text {...processedProps} validations={validations} />;
            break;
        case 'hidden':
            selectedInput = <Text {...processedProps} />;
            break;
        case 'number':
            selectedInput = <Number {...processedProps} />;
            break;
        case 'date':
            selectedInput = <Date {...processedProps} />;
            break;
        case 'select':
            selectedInput = <Select {...processedProps} />;
            break;
        case 'selectWithFilter':
            selectedInput = <SelectWithFilter {...processedProps} />;
            break;
        case 'radio':
            selectedInput = <Radio {...processedProps} />;
            break;
        case 'slider':
            selectedInput = <Slider {...processedProps} />;
            break;
        case 'switch':
            selectedInput = <Switch {...processedProps} />;
            break;
        case 'split':
            selectedInput = <Split {...processedProps} />;
            break;
        case 'quantity':
            selectedInput = <Quantity {...processedProps} />;
            break;
        case 'textarea':
            selectedInput = <Textarea {...processedProps} />;
            break;
        case 'chip':
            selectedInput = <Chip {...processedProps} />;
            break;
        case 'checkbox':
            selectedInput = <Checkbox {...processedProps} shouldPreventDefault={!onChange} />;
            break;
        case 'password':
            selectedInput = <Password {...processedProps} />;
            break;
        case 'picker':
            selectedInput = <Picker {...processedProps} />;
            break;
        case 'search':
            selectedInput = <Search {...processedProps} />;
            break;
        case 'upload':
            selectedInput = <Upload {...processedProps} />;
            break;
        case 'inputWithChip':
            selectedInput = <InputWithChip {...processedProps} validations={validations} />;
            break;
        default:
            break;
    }

    if (type === 'dynamicInputs') {
        return <DynamicInputWrapper {...processedProps} ref={inputRef} className={className} />;
    }

    const inputClass = getInputClass(
        isDisabled || isSubmitting,
        isDense,
        isSelected,
        isSelectable,
        state.isValid,
        state.isCompleted,
        isFocused,
        icon,
        prefix,
        suffix,
        type,
        state.hasChanged,
        isReadOnlyWithoutInputs,
        isMultiSelect,
        displayOptionsAmount,
    );

    return (
        type !== 'hidden' && (
            <Wrapper
                id={processedProps.id}
                icon={icon}
                prefix={prefix}
                suffix={suffix}
                className={className}
                inputClass={inputClass}
                helperText={helperText}
                supportText={supportText}
                isSelected={isSelected}
                hasToggle={isSelectable}
                toggleLabel={toggleLabel}
                onToggleChange={handleOnSelect}
                onCleanClick={
                    hasCleanButton
                        ? () => {
                            if (onClean) {
                                onClean();
                            } else {
                                onChange('', submitKey);
                            }
                        }
                        : null
                }
                isCleanDisabled={onClean ? false : !currentValue}
                charCount={state.currentCharCount}
                validationErrors={state.hasChanged ? state.validationErrors : []}
                isReadOnlyWithoutInputs={isReadOnlyWithoutInputs}
                hideErrorText={hideErrorText}
                isDisabled={isDisabled}
                ref={inputRef}
            >
                {selectedInput}
            </Wrapper>
        )
    );
}

const inputWithRef = forwardRef(Input);
inputWithRef.displayName = 'Form.Input';

inputWithRef.defaultProps = {
    value                                 : undefined,
    className                             : '',
    submitKey                             : '',
    supportText                           : '',
    type                                  : 'text',
    validations                           : [],
    prefix                                : null,
    suffix                                : null,
    isDisabled                            : false,
    isRequired                            : false,
    isCompleted                           : false,
    isSelectable                          : false,
    isSelected                            : null, // NOTE Why this can bee null and not only booleans?
    isMultiSelect                         : false,
    isDense                               : false,
    isReadOnlyWithoutInputs               : false,
    displayOptionsAmount                  : null,
    onSwitch                              : null,
    onPropsChange                         : null,
    onChange                              : null,
    onFocus                               : null,
    onBlur                                : null,
    onSelect                              : null,
    onUpload                              : null,
    summaryFormat                         : null,
    helperText                            : null,
    charCount                             : null,
    icon                                  : null,
    isBasedOnState                        : false,
    isFullReset                           : false,
    avoidMarkAsChangedOnValidationsChange : false,
    onClean                               : null,
    forceValidationsOnFirstRender         : false,
};

inputWithRef.propTypes = {
    className               : PropTypes.string,
    isSelectable            : PropTypes.bool,
    isCompleted             : PropTypes.bool,
    isSelected              : PropTypes.bool,
    charCount               : PropTypes.shape({ total: PropTypes.number }),
    isDense                 : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    isRequired              : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    displayOptionsAmount    : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    prefix                  : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    suffix                  : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onSelect                : PropTypes.func,
    onPropsChange           : PropTypes.func,
    onUpload                : PropTypes.func,
    onClean                 : PropTypes.func,
    summaryFormat           : PropTypes.func,
    supportText             : PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({})]),
    submitKey               : PropTypes.string,
    type                    : PropTypes.string,
    onSwitch                : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isBasedOnState          : PropTypes.bool,
    isFullReset             : PropTypes.bool,
    validations             : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
    value                   : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.shape({ inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        PropTypes.shape({}),
    ]),
    icon: PropTypes.shape({
        name     : PropTypes.string,
        position : PropTypes.string,
    }),
    helperText: PropTypes.shape({
        label     : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        className : PropTypes.string,
        onClick   : PropTypes.func,
    }),
    avoidMarkAsChangedOnValidationsChange : PropTypes.bool,
    forceValidationsOnFirstRender         : PropTypes.bool,
};

export default inputWithRef;
