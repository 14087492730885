import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from '../Collection';

function BalanceTypes({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

BalanceTypes.propTypes = sectionPropTypes;

export default BalanceTypes;
