import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import { orderKeys } from 'Constants/translator';
import Button from 'Components/Button';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Header({
    id,
    items,
    hasActions,
    canCheck,
    onCheck,
    checked,
    hasSubTable,
    isSortable,
    handleOnMount,
    styleConfig,
    order,
    onOrder,
    onSetOrderReference,
    appliedFilters,
}) {
    const intl = useIntl();
    const isReorderEnabled = !!order && !!onOrder;

    const currentSortField = isReorderEnabled && order.orderedBy ? order.orderedBy.field : '';
    const currentSortOrder = isReorderEnabled && order.orderedBy ? order.orderedBy.order : '';
    const sortableFields = isReorderEnabled ? Object.keys(order).filter((eachKey) => eachKey !== 'orderedBy') : [];

    const visibleFields = items
        .filter((item) => item.isVisible)
        .map((item) => ({
            ...item,
            isSortable : isReorderEnabled && sortableFields.indexOf(item.key) > -1,
            sortOrder  : isReorderEnabled && currentSortField === item.key ? currentSortOrder : '',
            sortAsc    : isReorderEnabled && currentSortField === item.key && currentSortOrder === orderKeys.ASC,
            sortDesc   : isReorderEnabled && currentSortField === item.key && currentSortOrder === orderKeys.DESC,
            sortLinks  : isReorderEnabled && order[item.key],
        }));

    const onSetOrder = (item) => {
        if (isReorderEnabled && item.sortLinks) {
            const linkKey = item.sortAsc ? orderKeys.DESC : orderKeys.ASC;
            const orderKeyUsed = `order=${item.key},${linkKey}`;
            const config = item.sortLinks[linkKey];
            config.params = appliedFilters?.filters;
            onOrder(config);
            onSetOrderReference(orderKeyUsed);
        }
    };

    const rowRef = useRef();
    const checkboxRef = useRef();

    useEffect(() => {
        handleOnMount(rowRef);
    });

    if (checkboxRef.current) {
        switch (checked) {
            case 'checked':
                checkboxRef.current.checked = true;
                checkboxRef.current.indeterminate = false;
                break;
            case 'indeterminate':
                checkboxRef.current.indeterminate = true;
                checkboxRef.current.checked = false;
                break;
            default:
                checkboxRef.current.checked = false;
                checkboxRef.current.indeterminate = false;
                break;
        }
    }
    const { sortable, subTable, checkbox, actions: actionsPadding } = styleConfig;

    return (
        <thead>
            <tr ref={rowRef}>
                {isSortable && <th aria-label="table-header-sortable" className={`table-sortable ${sortable.customClassName}`} style={sortable.customStyle} />}
                {hasSubTable && <th aria-label="table-header-subtable" className={`table-subtable ${subTable.customClassName}`} style={subTable.customStyle} />}
                {canCheck && (
                    <th className={`table-checkbox ${checkbox.customClassName}`} style={checkbox.customStyle}>
                        <label className="checkbox" id={`${id}-check`}>
                            <input ref={checkboxRef} type="checkbox" onChange={(e) => onCheck(e.target.checked)} />
                            <span />
                        </label>
                    </th>
                )}
                {visibleFields.map((item, index) => {
                    const { customStyle, customClassName } = styleConfig.columns[index];
                    const className = `${customClassName} ${item.isSortable ? 'table-sortable-head' : ''} ${item.isNumeric ? 'text-align-right' : ''} ${
                        item.hasMaxWidth ? 'text-overflow' : ''
                    }`;
                    const sortIcon = item.sortAsc ? 'ArrowUp' : 'ArrowDown';

                    return (
                        <th id={`${id}-${item.key}`} key={item.key} onClick={() => onSetOrder(item)} className={className} style={customStyle}>
                            {item.sortOrder && (
                                <Button
                                    id={`${id}-${item.key}-${item.sortAsc ? 'sort-up' : 'sort-down'}`}
                                    icon={sortIcon}
                                    size="small"
                                    tooltip={intl.formatMessage({
                                        id             : translated.global.table.header.reverseSort,
                                        defaultMessage : translated.global.table.header.reverseSort,
                                    })}
                                />
                            )}
                            <span><WrappedFormattedMessage content={item.title} /></span>
                        </th>
                    );
                })}
                {hasActions && <th aria-label="table-header-actions" className={`table-actions ${actionsPadding.customClassName}`} style={actionsPadding.customStyle} />}
            </tr>
        </thead>
    );
}

Header.defaultProps = {
    id          : '',
    hasActions  : false,
    canCheck    : false,
    hasSubTable : false,
    isSortable  : false,
    onCheck     : () => {
        // Default
    },
    handleOnMount: () => {
        // Default
    },
    checked     : 'unchecked',
    styleConfig : {
        sortable : { customClassName: '', customStyle: '' },
        subTable : { customClassName: '', customStyle: '' },
        checkbox : { customClassName: '', customStyle: '' },
        actions  : { customClassName: '', customStyle: '' },
        columns  : [],
    },
    order               : null,
    onOrder             : null,
    onSetOrderReference : null,
    appliedFilters      : null,
};

Header.propTypes = {
    id            : PropTypes.string,
    items         : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onCheck       : PropTypes.func,
    handleOnMount : PropTypes.func,
    checked       : PropTypes.string,
    hasActions    : PropTypes.bool,
    canCheck      : PropTypes.bool,
    hasSubTable   : PropTypes.bool,
    isSortable    : PropTypes.bool,
    styleConfig   : PropTypes.shape({
        sortable : PropTypes.shape({}),
        subTable : PropTypes.shape({
            customClassName : PropTypes.string,
            customStyle     : PropTypes.shape({}),
        }),
    }),
    order               : PropTypes.shape({ orderedBy: PropTypes.shape({ field: PropTypes.string }) }),
    onOrder             : PropTypes.func,
    onSetOrderReference : PropTypes.func,
    appliedFilters      : PropTypes.shape({}),
};

export default Header;
