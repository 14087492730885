module.exports = Object.freeze({
    title         : 'apf.externalClubs.title',
    empty         : 'apf.externalClubs.empty',
    createTitle   : 'apf.externalClubs.createTitle',
    editTitle     : 'apf.externalClubs.editTitle',
    updateFailed  : 'apf.externalClubs.updateFailed',
    updateSuccess : 'apf.externalClubs.updateSuccess',
    saveSuccess   : 'apf.externalClubs.saveSuccess',
    enable        : { success: 'apf.externalClubs.enable.success', error: 'apf.externalClubs.enable.error' },
    disable       : { success: 'apf.externalClubs.disable.success', error: 'apf.externalClubs.disable.error' },
    delete        : {
        confirmation               : 'apf.externalClubs.delete.confirmation',
        success                    : 'apf.externalClubs.delete.success',
        error                      : 'apf.externalClubs.delete.error',
        errorEntityCannotBeDeleted : 'apf.externalClubs.delete.errorEntityCannotBeDeleted',
        defaultError               : 'apf.externalClubs.delete.defaultError',
    },
});
