import React from 'react';
import withRequest from 'Components/Sections/withRequest';
// eslint-disable-next-line import/no-cycle
import ContractEditor from './EditorWithoutRequest';
import PropTypes from 'prop-types';

export function Editor({ data, ...props }) {
    return (
        <ContractEditor isUpgrade isUpgradeOutsideUserRequest data={data} {...props} />
    );
}

Editor.defaultProps = {};

Editor.propTypes = { data: PropTypes.shape({}).isRequired };

export default withRequest(Editor);
