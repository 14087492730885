import { transactionType } from 'Constants/global';

// Wallet Utils

const formatPointItems = (summary) => {
    if (!summary || !summary.data || !summary.data.length) {
        // There are no Club Points for this owner
        return null;
    }

    const { data } = summary;

    return data?.map((e) => ({
        ...e,
        currencyName     : e?.balanceType?.currency?.name,
        balanceTypeLevel : e?.balanceType?.level,
    }));
};

function transformTransaction(item) {
    const newItem = { ...item };

    newItem.balances = newItem.balances?.map((each) => ({
        ...each,
        transactionType        : newItem.type,
        belongsToCurrentWallet : newItem.belongsToCurrentWallet,
        currency               : each.balanceType?.currency?.name,
    }));

    if (item.type === transactionType.ALLOCATION || item.type === transactionType.MANAGEMENT) {
        return { ...newItem, processedName: item.type };
    }
    return { ...newItem, processedName: item.name };
}

const getTransactionsObject = (transactions) => {
    if (!transactions || !transactions.data || !transactions.data.length) {
        // There are no transactions for this owner
        return { items: null, pagination: null };
    }

    return { items: transactions.data.map(transformTransaction), pagination: transactions.pagination };
};

export const walletUtils = { formatPointItems, addTypeAndReasonToTransactionName: transformTransaction, getTransactionsObject };

export const getOwnerName = (salutation, firstName, lastName) => `${salutation ? `${salutation} ` : ''}${firstName} ${lastName}`;
