import React, { useContext, useMemo, useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form, { FormError, FormNew } from 'Components/Form';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import ConversionGrid from './ConversionGrid';
import Grid from 'Components/Grid';
import yup from 'Utils/yupHelper';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Loading from 'Components/Loading';
import { getErrorDetails } from 'Utils/errorService';

const NO_PAYMENT_ID = 99999999999999;

const schema = yup.object().shape({
    club           : yup.string().required(),
    source         : yup.array().of(yup.number()).min(1),
    destination    : yup.array().of(yup.number()).min(1),
    paymentBalance : yup
        .array()
        .of(yup.number())
        // We check that the paymentBalances are different of the sources selected
        .when('source', (source, entitySchema) => entitySchema.test({
            test: (paymentBalance) => {
                if (Array.isArray(paymentBalance) && paymentBalance.length && Array.isArray(source) && source.length) {
                    return !paymentBalance.filter((value) => source.includes(value))?.length;
                }
                return true;
            },
            message: (
                <FormattedMessage
                    id={translated.conversionRules.editorAlt.errors.invalidPaymentBalance}
                    defaultMessage={translated.conversionRules.editorAlt.errors.invalidPaymentBalance}
                />
            ),
        }))
        .when('noPayment', {
            is   : false,
            then : (currentSchema) => currentSchema.min(1),
        }),
    conversions: yup.lazy((value) => {
        const convertedBody = {};

        if (value) {
            Object.entries(value).forEach(([eachKey]) => {
                const hasFee = eachKey.split('-')[0] !== String(NO_PAYMENT_ID);

                convertedBody[eachKey] = yup
                    .object()
                    .shape({
                        isPaymentFloat : yup.boolean(), // Used for the validations of fee and minimum of the 'fee'
                        isSourceFloat  : yup.boolean(), // Used for the validations of fee and minimum of the 'minimum'
                        isEnabled      : yup.boolean(),
                        rate           : yup.number().when('isEnabled', {
                            is   : true,
                            then : (currentSchema) => currentSchema.required().min(0.01),
                        }),
                        fee: hasFee
                            ? yup
                                .number()
                                .when('isEnabled', {
                                    is   : true,
                                    then : (currentSchema) => currentSchema.required(),
                                })
                                .when('isPaymentFloat', {
                                    is        : true,
                                    then      : (currentSchema) => currentSchema.min(0),
                                    otherwise : (currentSchema) => currentSchema.min(0).integer(),
                                })
                            : yup.number(),
                        minimum: yup
                            .number()
                            .when('isEnabled', {
                                is   : true,
                                then : (currentSchema) => currentSchema.required(),
                            })
                            .when('isSourceFloat', {
                                is        : true,
                                then      : (currentSchema) => currentSchema.min(0.01),
                                otherwise : (currentSchema) => currentSchema.min(1).integer(),
                            }),
                    })
                    .nullable(true);
            });
        }

        return yup.object().shape(convertedBody);
    }),
    noPayment: yup.bool(),
});
export function Editor({ data, resources, isEditing }) {
    const intl = useIntl();
    const { navigator, snackbar } = useContext(PanelContext);

    const formRef = useRef();

    const { additionalResources } = data;
    const { externalClubs = [], internalClubs = [], balanceTypes = [] } = additionalResources || {};

    const availableClubs = useMemo(
        () => [
            {
                content: intl.formatMessage({
                    id             : translated.conversionRules.editorAlt.externalClubs,
                    defaultMessage : translated.conversionRules.editorAlt.externalClubs,
                }),
                value      : 'external',
                isDisabled : true,
            },
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...externalClubs?.map((e) => ({ ...e, value: `ext-${e.id}`, content: e.name })),
            {
                content: intl.formatMessage({
                    id             : translated.conversionRules.editorAlt.internalClubs,
                    defaultMessage : translated.conversionRules.editorAlt.internalClubs,
                }),
                value      : 'internal',
                isDisabled : true,
            },
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...internalClubs?.map((e) => ({ ...e, value: `int-${e.id}`, content: e.name })),
        ].filter((e) => isEditing || e.isActive !== false), // We need to filter the clubs disabled when creating a rule (in the edition the input is disabled)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [externalClubs, internalClubs],
    );

    const availableBalances = useMemo(() => {
        const list = balanceTypes.map((e) => ({
            ...e,
            content : e.name,
            value   : e.id,
        }));

        if (isEditing && data?.balanceTypeConversionRates?.length) {
            data.balanceTypeConversionRates.forEach((balanceConversion) => {
                const paymentBalance = balanceConversion?.fromBalanceType;

                if (!list.find((eachConvertedBalance) => eachConvertedBalance.id === paymentBalance.id)) {
                    list.push({ ...paymentBalance, value: paymentBalance.id, content: paymentBalance.name });
                }
            });
        }

        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceTypes]);

    const availablePaymentBalances = useMemo(() => {
        const list = [
            ...balanceTypes.map((e) => ({
                ...e,
                content : e.name,
                value   : e.id,
            })),
        ];

        if (isEditing && data?.balanceTypeConversionRates?.length) {
            data.balanceTypeConversionRates.forEach((balanceConversion) => {
                const paymentBalance = balanceConversion?.paymentBalanceType;

                if (paymentBalance?.id && !list.find((eachConvertedBalance) => eachConvertedBalance.id === paymentBalance.id)) {
                    list.push({ ...paymentBalance, value: paymentBalance.id, content: paymentBalance.name });
                }
            });
        }

        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceTypes]);

    const [conversionConf, setConversionConf] = useState({
        availableDestinations   : [],
        selectedClub            : null,
        selectedSources         : [],
        selectedDestinations    : [],
        selectedPaymentBalances : [],
        isInitialized           : !isEditing,
        noPayment               : false,
        isFirstLoad             : true,
    });

    const initialValues = useMemo(() => {
        const source = [];
        const destination = [];
        const paymentBalance = [];
        const conversions = {};
        let noPayment = false;

        if (data?.balanceTypeConversionRates) {
            data.balanceTypeConversionRates.forEach((balanceConversion) => {
                const sourceBalance = balanceConversion?.fromBalanceType?.id;
                const destinationBalance = balanceConversion?.toBalanceType?.id;
                const payment = balanceConversion?.paymentBalanceType?.id;

                if (sourceBalance && !source.find((e) => e === sourceBalance)) {
                    source.push(sourceBalance);
                }
                if (destinationBalance && !destination.find((e) => e === destinationBalance)) {
                    destination.push(destinationBalance);
                }
                if (payment && !paymentBalance.find((e) => e === payment)) {
                    paymentBalance.push(payment);
                }

                const conversionKey = `${payment || NO_PAYMENT_ID}-x-${destinationBalance}-y-${sourceBalance}`;

                const { fee, minimum, rate } = balanceConversion;

                conversions[conversionKey] = {
                    fee,
                    rate,
                    minimum,
                    isEnabled: true,
                };

                // If there is at least one conversion without payment, we mark the 'noPayment' input
                if (!payment) {
                    noPayment = true;
                }
            });
        }

        return {
            club: data?.destinationClub?.id ? `${data.destinationClub.isExternal ? 'ext' : 'int'}-${data.destinationClub.id}` : null,
            source,
            destination,
            paymentBalance,
            conversions,
            noPayment,
        };
    }, [data]);

    const loadClubBalances = useCallback(
        async (club, isInitialLoad = false) => {
            try {
                if (!club?.links?.balanceTypes?.read) {
                    snackbar.show({
                        content : translated.conversionRules.editorAlt.errors.init,
                        isError : true,
                    });

                    if (isInitialLoad) {
                        setConversionConf((prev) => ({
                            ...prev,
                            isInitialized: true,
                        }));
                    }

                    return;
                }

                const clubBalances = await navigator.directRequest({
                    ...club.links.balanceTypes.read,
                    // We filter the active balance types
                    url: `${club.links.balanceTypes.read.url}${club.links.balanceTypes.read.url.indexOf('?') > -1 ? '&' : '?'}isActive=true`,
                });
                const convertedBalances = clubBalances?.data?.map((e) => ({ ...e, value: e.id, content: e.name })) || [];

                if (isEditing && !conversionConf?.isInitialized && data?.balanceTypeConversionRates?.length) {
                    data.balanceTypeConversionRates.forEach((balanceConversion) => {
                        const destinationBalance = balanceConversion?.toBalanceType;

                        if (!convertedBalances.find((eachConvertedBalance) => eachConvertedBalance.id === destinationBalance.id)) {
                            convertedBalances.push({ ...destinationBalance, value: destinationBalance.id, content: destinationBalance.name });
                        }
                    });
                }

                const initializedProps = isInitialLoad
                    ? {
                        selectedSources: initialValues?.source
                            ?.map((e) => availableBalances.find((eachAvailable) => eachAvailable.id === e))
                            .filter((e) => e != null)
                            .sort((first, second) => (first?.name < second?.name ? -1 : 1)),
                        selectedDestinations: initialValues?.destination
                            ?.map((e) => convertedBalances?.find((eachAvailable) => eachAvailable.id === e))
                            .filter((e) => e != null)
                            .sort((first, second) => (first?.name < second?.name ? -1 : 1)),
                        selectedPaymentBalances: initialValues?.paymentBalance
                            ?.map((e) => availablePaymentBalances.find((eachAvailable) => eachAvailable.id === e))
                            .filter((e) => e != null)
                            .sort((first, second) => (first?.name < second?.name ? -1 : 1)),
                        noPayment: initialValues.noPayment,
                    }
                    : { selectedDestinations: [] };

                if (clubBalances?.data) {
                    setConversionConf((prev) => ({
                        ...prev,
                        ...initializedProps,
                        selectedClub          : club,
                        availableDestinations : convertedBalances,
                        isInitialized         : true,
                    }));

                    if (!isInitialLoad && formRef?.current?.setValue) {
                        formRef.current.setValue('destination', []);
                        formRef.current.setValue('conversions', {});
                    }
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            } catch (e) {
                snackbar.show({
                    content : translated.conversionRules.editorAlt.errors.init,
                    isError : true,
                });
            }
        },
        [availableBalances, availablePaymentBalances, conversionConf, data, initialValues, isEditing, navigator, snackbar],
    );

    useEffect(() => {
        if (isEditing && data?.destinationClub?.id) {
            let club = null;
            if (data.destinationClub.isExternal) {
                club = data.additionalResources?.externalClubs?.find((e) => e.id === data.destinationClub.id);
            } else {
                club = data.additionalResources?.internalClubs?.find((e) => e.id === data.destinationClub.id);
            }

            loadClubBalances(club, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttons = [
        <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={() => navigator.goToParentAndReload(true, false)} />,
        <Form.PrimaryNew variant="text" color="primary" key="fp">
            {isEditing ? (
                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
            ) : (
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            )}
        </Form.PrimaryNew>,
    ];

    const handleOnSubmit = async (values) => {
        if (!values?.conversions || !Object.values(values.conversions)?.find((e) => e?.isEnabled)) {
            throw new FormError(translated.conversionRules.editorAlt.errors.noExchangeOverrideRules);
        }

        try {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const [clubType, clubId] = values.club?.split('-');

            const balanceTypeConversionRates = [];

            Object.keys(values?.conversions).forEach((eachConversionKey) => {
                if (
                    values?.conversions[eachConversionKey] !== null // We skip all the conversions removed. (the conversions removed are kept, but their value is null)
                    && values?.conversions[eachConversionKey]?.isEnabled // We skip the disabled conversions
                ) {
                    const ids = eachConversionKey.split('-');
                    let balanceId = null;
                    let destinationId = null;
                    let sourceId = null;
                    if (ids.length === 5) {
                        // The conversion HAS a payment balance type (the key is ID1-x-ID2-Y-ID3)
                        [balanceId, , destinationId, , sourceId] = ids;
                    } else {
                        // The conversion does not have payment balance type (the key is x-ID1-Y-ID2)
                        [, destinationId, , sourceId] = ids;
                    }

                    // eslint-disable-next-line no-unsafe-optional-chaining
                    const { fee, rate, minimum } = values?.conversions[eachConversionKey];

                    balanceTypeConversionRates.push({
                        fromBalanceType    : sourceId ? { id: sourceId } : null,
                        toBalanceType      : destinationId ? { id: destinationId } : null,
                        paymentBalanceType : balanceId && balanceId !== String(NO_PAYMENT_ID) ? { id: balanceId } : null,
                        fee                : fee || 0, // APT needs this param to NOT be null
                        minimum,
                        rate,
                    });
                }
            });

            const body = {
                destinationClub: {
                    id         : clubId,
                    isExternal : clubType === 'ext',
                },
                pointTypes : [],
                isActive   : data?.isActive != null ? data.isActive : true,
                balanceTypeConversionRates,
            };

            await navigator.directRequest({
                data: body,
                ...resources.available[isEditing ? 'update' : 'create'],
            });

            snackbar.show({
                content   : isEditing ? translated.conversionRules.editorAlt.saveSuccess : translated.conversionRules.editorAlt.createSuccess,
                isSuccess : true,
            });
        } catch (e) {
            const { error } = getErrorDetails(e);

            throw new FormError(
                isEditing ? translated.conversionRules.editorAlt.errors.save : translated.conversionRules.editorAlt.errors.create,
                error,
                translated.conversionRules.editorAlt.errors,
            );
        }
    };

    const handleOnFinish = () => {
        navigator.goToParentAndReload(true, false);
    };

    const handleClubOnChange = async (newId) => loadClubBalances(availableClubs.find((e) => (e.isExternal ? `ext-${e?.id}` === newId : `int-${e?.id}` === newId)));

    if (isEditing && !conversionConf.isInitialized) {
        // When editing, it need to load the club balances to fully load the 'destination' input
        return <Loading />;
    }

    return (
        <Form.WrapperNew
            schema={schema}
            initialValues={initialValues}
            ref={formRef}
            formId="conversion-rule"
        >
            <SectionsWrapper
                title={isEditing ? translated.conversionRules.editorAlt.titleEdition : translated.conversionRules.editorAlt.title}
                actionButtons={buttons}
            >
                <FormNew
                    buttonsWidth={{ base: 12, small: 6 }}
                    onSubmit={handleOnSubmit}
                    onFinish={handleOnFinish}
                    className="margin-top-small"
                >
                    <Grid className="margin-bottom-medium">
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            {data.id && <Form.Hidden submitKey="id" value={data.id} />}
                            <Form.InputNew
                                submitKey="club"
                                isDense
                                type="select"
                                label={translated.conversionRules.editorAlt.club}
                                options={availableClubs}
                                isRequired
                                onChange={handleClubOnChange}
                                isDisabled={isEditing}
                            />

                            <Form.InputNew
                                submitKey="source"
                                type="select"
                                label={translated.conversionRules.editorAlt.source}
                                options={availableBalances || []}
                                isDense
                                isMultiSelect
                                onChange={(newValues) => {
                                    if (formRef?.current?.trigger) {
                                        // We trigger the paymentBalance validation so it updates any error
                                        formRef.current.trigger('paymentBalance');
                                    }

                                    setConversionConf((prev) => ({
                                        ...prev,
                                        selectedSources: newValues
                                            ?.map((e) => availableBalances.find((eachAvailable) => eachAvailable.id === e))
                                            ?.filter((e) => e),
                                        isFirstLoad: false,
                                    }));
                                }}
                                sortBy="content"
                            />

                            <Form.InputNew
                                submitKey="destination"
                                type="select"
                                label={translated.conversionRules.editorAlt.destination}
                                options={conversionConf.availableDestinations || []}
                                isDense
                                isMultiSelect
                                onChange={(newValues) => setConversionConf((prev) => ({
                                    ...prev,
                                    selectedDestinations: newValues
                                        ?.map((e) => prev?.availableDestinations?.find((eachAvailable) => eachAvailable.id === e))
                                        ?.filter((e) => e),
                                    isFirstLoad: false,
                                }))}
                                sortBy="content"
                            />

                            <Form.InputNew
                                submitKey="paymentBalance"
                                type="select"
                                label={translated.conversionRules.editorAlt.balances}
                                options={availablePaymentBalances || []}
                                isDense
                                isMultiSelect
                                onChange={(newValues) => setConversionConf((prev) => ({
                                    ...prev,
                                    selectedPaymentBalances: newValues
                                        ?.map((e) => availablePaymentBalances.find((eachAvailable) => String(eachAvailable.id) === String(e)))
                                        ?.filter((e) => e),
                                    isFirstLoad: false,
                                }))}
                                supportText={translated.conversionRules.editorAlt.balancesSupport}
                                sortBy="content"
                            />

                            <Form.InputNew
                                submitKey="noPayment"
                                type="switch"
                                label={translated.conversionRules.editorAlt.noPaymentMessage}
                                isDense
                                onChange={(newValues) => {
                                    if (formRef?.current?.trigger) {
                                        // We trigger the paymentBalance validation so it updates any error
                                        formRef.current.trigger('paymentBalance');
                                    }

                                    setConversionConf((prev) => ({
                                        ...prev,
                                        noPayment   : newValues,
                                        isFirstLoad : false,
                                    }));
                                }}
                            />
                        </Grid.Column>
                    </Grid>

                    {!!conversionConf?.selectedDestinations?.length
                        && !!conversionConf?.selectedSources?.length
                        && conversionConf?.selectedPaymentBalances?.map((e) => (
                            <ConversionGrid
                                key={`conversions-${e.id}`}
                                xAxis={conversionConf?.selectedDestinations}
                                yAxis={conversionConf?.selectedSources}
                                balance={e}
                                firstLoad={conversionConf.isFirstLoad}
                                hasFee
                            />
                        ))}

                    {conversionConf?.noPayment && (
                        <ConversionGrid
                            key="conversions-no-payment"
                            xAxis={conversionConf?.selectedDestinations}
                            yAxis={conversionConf?.selectedSources}
                            balance={{
                                name: (
                                    <FormattedMessage
                                        id={translated.conversionRules.editorAlt.noPayment}
                                        defaultMessage={translated.conversionRules.editorAlt.noPayment}
                                    />
                                ),
                                id: NO_PAYMENT_ID,
                            }}
                            hasFee={false}
                            firstLoad={conversionConf.isFirstLoad}
                        />
                    )}
                </FormNew>
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.defaultProps = {};

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
};

export default withRequest(Editor);
