import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Table from 'Components/Table';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import translated from 'Constants/labels/translated';

function ConversionGroupRate({ rangeId, currency }) {
    const { watch } = useFormContext();
    const conversions = watch('conversions');

    if (!conversions) {
        return null;
    }

    const currencyId = currency?.id;

    const balanceTypes = currency.balanceTypes?.map?.((balanceType) => ({
        name         : balanceType?.name,
        roomValue    : conversions[`${rangeId}-${currencyId}-${balanceType.id}`]?.roomValue,
        serviceValue : conversions[`${rangeId}-${currencyId}-${balanceType.id}`]?.serviceValue,
    })) || [];

    const roomValueAreEquals = new Set(balanceTypes?.map((e) => e.roomValue)).size === 1;
    const roomValue = roomValueAreEquals ? balanceTypes[0]?.roomValue : '';

    const serviceValueAreEquals = new Set(balanceTypes?.map((e) => e.serviceValue)).size === 1;
    const serviceValue = serviceValueAreEquals ? balanceTypes[0]?.serviceValue : '';

    return (
        <div key={`summary-rate-${rangeId}-${currencyId}`}>
            <div className="dynamic-range-field-item">
                <span className="dynamic-range-field-item-name">{currency?.name}</span>
                <div className="dynamic-range-field-item-room">
                    {roomValueAreEquals && (
                        <span>{roomValue}</span>
                    )}
                    {!roomValueAreEquals && (
                        <span><WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.variable} /></span>
                    )}
                </div>
                <div className="dynamic-range-field-item-service">
                    {serviceValueAreEquals && (
                        <span>{serviceValue}</span>
                    )}
                    {!serviceValueAreEquals && (
                        <span><WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.variable} /></span>
                    )}
                </div>
            </div>
            {(!roomValueAreEquals || !serviceValueAreEquals) && (
                <Table
                    id={`${rangeId}-currency-group`}
                    key={`${rangeId}-currency-group`}
                    hasTools={false}
                    className="dynamic-range-table-summary"
                    columns={[
                        {
                            title     : translated.rules.types.balanceType,
                            key       : 'name',
                            isVisible : true,
                        },
                        {
                            title     : translated.rules.dynamicRanges.rangeConfiguration.subtitles.roomRate,
                            key       : 'roomValue',
                            isVisible : true,
                            isNumeric : true,
                        },
                        {
                            title     : translated.rules.dynamicRanges.rangeConfiguration.subtitles.serviceRate,
                            key       : 'serviceValue',
                            isVisible : true,
                            isNumeric : true,
                        },
                    ]}
                    items={balanceTypes}
                />
            )}
        </div>
    );
}

function SummaryRangeField({ range }) {
    const { watch } = useFormContext();
    const { id, label, from, to } = range;
    const title = `${label} (${from} - ${to})`;
    const [conversions, balanceTypes, availableBalanceTypes] = watch(['conversions', 'balanceTypes', 'availableBalanceTypes']);

    const items = useMemo(
        () => {
            const currencies = [];

            balanceTypes?.forEach?.((eachBalanceType) => {
                const foundBalanceType = availableBalanceTypes?.find?.((eachBalanceCurrency) => eachBalanceCurrency.id === eachBalanceType);

                if (foundBalanceType) {
                    let foundCurrency = currencies.find((eachConverted) => eachConverted.id === foundBalanceType.currency?.id);

                    if (!foundCurrency) {
                        foundCurrency = { ...foundBalanceType.currency, balanceTypes: [] };
                        currencies.push(foundCurrency);
                    }

                    foundCurrency.balanceTypes.push({
                        id   : foundBalanceType.id,
                        name : foundBalanceType.name,
                    });
                }
            });

            return currencies.map((eachCurrency) => ({
                ...eachCurrency,
                balanceTypes: eachCurrency?.balanceTypes
                    ?.map((eachBalanceType) => ({
                        ...eachBalanceType,
                        currency: eachCurrency.id,
                    })) || [],
            }));
        },
        // We need 'conversions' as dependency because otherwise, it wont update the list
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [balanceTypes, availableBalanceTypes, conversions],
    );

    return (
        <div key={`summary-range-field-${id}`} className="summary-range-field-wrapper">
            <div className="font-weight-bold">{title}</div>
            {
                items?.map?.((eachCurrency) => <ConversionGroupRate rangeId={id} currency={eachCurrency} />)
            }
        </div>
    );
}

SummaryRangeField.propTypes = { range: PropTypes.shape({}).isRequired };

export default SummaryRangeField;
