import React, { useContext, useState } from 'react';
import PanelContext from 'State/panelContext';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import Form, { FormError } from 'Components/Form';
import SectionsWrapper from 'Components/Sections/Wrapper';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import { extraAttributesFilters } from 'Constants/global';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';
import EntityTags from 'Components/Sections/Tags/EntityTags';
import { sectionType } from 'Constants/types';
// eslint-disable-next-line import/no-named-as-default
import Relationships from 'Components/Sections/Shared/Users/Relationships';

function getParams(data, availableResources, isEditing) {
    let title;
    let resource;
    let primaryButtonText;

    if (isEditing) {
        if (!data.links.self.update) {
            // eslint-disable-next-line prefer-destructuring
            title = translated.partners.user.viewDetails.title;
            primaryButtonText = translated.global.buttons.save;
            resource = data.links.self.update;
        } else {
            // eslint-disable-next-line prefer-destructuring
            title = translated.partners.user.edition.title;
            primaryButtonText = translated.global.buttons.save;
            resource = data.links.self.update;
        }
    } else {
        // eslint-disable-next-line prefer-destructuring
        title = translated.partners.user.creation.title;
        primaryButtonText = translated.global.buttons.create;
        resource = availableResources.create;
    }
    return { title, resource, primaryButtonText, shouldReloadData: true };
}

export function User({ isEditing, data: user, resources, navigateBack, sections }) {
    const isReadOnly = isEditing && (!user.links.self.update);
    const { navigator, snackbar, dateManager } = useContext(PanelContext);
    const [isLoading, setIsLoading] = useState(false);

    const { links } = user;
    const { available = {}, additional } = resources;

    const { title, primaryButtonText, shouldReloadData } = getParams(user, available, isEditing, additional);

    const birthdayMinValue = dateManager.yearInDefaultFormat();
    const birthdayMaxValue = dateManager.defaultDateFormat(dateManager.getCurrentDay());

    const goBack = () => {
        if (navigateBack && navigator[navigateBack]) {
            navigator[navigateBack]();
        } else {
            navigator.goToParentAndReload();
        }
    };

    const onFinish = () => {
        snackbar.show({ content: isEditing ? translated.partners.user.edition.success : translated.partners.user.creation.success, isSuccess: true });
        goBack();
    };

    const update = async (payload) => {
        setIsLoading(true);
        const url = isEditing ? user.links.self.update : available.create;
        const reqConfig = { ...url, data: payload };

        try {
            await navigator.requestForCurrentPath({ data: payload, reqConfig, shouldReloadData, resources });
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);

            throw new FormError(
                isEditing ? translated.partners.user.edition.error : translated.partners.user.creation.error,
                e,
                translated.partners.errors,
            );
        }
    };

    const handleOnSubmit = async ({ values }) => {
        const extraAttributes = isEditing ? { ...user.extraAttributes } : values.user.extraAttributes;
        validateDynamicInputs(extraAttributes, dateManager);

        const payload = {
            ...values,
            extraAttributes,
        };
        await update(payload);
    };

    const handleOnSubmitExtraAttributes = async ({ values }) => {
        validateDynamicInputs(values.user.extraAttributes, dateManager);
        const {
            firstName, lastName, pictureUrl, email, birthday, phone, mobile, language, loginToken,
        } = user;
        const updatedUser = {
            firstName,
            lastName,
            pictureUrl,
            email,
            birthday,
            phone,
            mobile,
            language,
            loginToken,
        };

        const payload = {
            ...updatedUser,
            extraAttributes: { ...values.user.extraAttributes },
        };
        await update(payload);

        snackbar.show({ content: isEditing ? translated.partners.user.edition.success : translated.partners.user.creation.success, isSuccess: true });
    };

    const buttons = [
        <Form.Secondary key="fs" isDisabled={isLoading} variant="text" color="primary" onClick={() => goBack()} />,
        <Form.Primary id="save" key="fp" variant="text" color="primary" isDisabled={isLoading}>
            <FormattedMessage id={primaryButtonText} defaultMessage={primaryButtonText} />
        </Form.Primary>,
    ];

    const backButton = [
        <Form.Secondary key="fs" isDisabled={isLoading} variant="text" color="primary" onClick={() => goBack()}>
            <FormattedMessage defaultMessage={translated.global.buttons.back} id={translated.global.buttons.back} />
        </Form.Secondary>,
    ];

    const languages = additional?.languages?.length ? additional.languages.map((each) => ({ value: each.id, content: each.name })) : [];

    const availableExtraAttributes = additional?.extraAttributes || [];
    const availableUserAttributes = availableExtraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.USER));

    return (
        <SectionsWrapper title={title} className="owner-wrapper owner-editor">
            <Form.Wrapper>
                <Subsection
                    title={translated.owners.personalInfo}
                    className="account-subsection margin-top-medium"
                    shouldShowFormInfoOnCollapse
                    actionButtons={isReadOnly ? backButton : buttons}
                >
                    <Form className="margin-top-small" id="user" onSubmit={handleOnSubmit} onFinish={onFinish} isReadOnlyWithoutInputs={isReadOnly}>
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Hidden submitKey="pictureUrl" value={user?.pictureUrl} />
                            <Form.Input
                                isDense
                                id="firstName"
                                submitKey="firstName"
                                type="text"
                                label={translated.global.firstName}
                                isRequired
                                value={user?.firstName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                isDense
                                id="lastName"
                                submitKey="lastName"
                                type="text"
                                label={translated.global.lastName}
                                isRequired
                                value={user?.lastName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                isDense
                                id="email"
                                submitKey="email"
                                type="text"
                                label={translated.global.email}
                                value={user?.email}
                                validations={{ maxLength: 255, unary: ['email'] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Input
                                id="birthday"
                                submitKey="birthday"
                                icon={{ name: 'Calendar', position: 'right' }}
                                type="date"
                                label={translated.global.birthday}
                                value={user?.birthday}
                                minDate={birthdayMinValue}
                                maxDate={birthdayMaxValue}
                                validations={{ minDate: birthdayMinValue, maxDate: birthdayMaxValue, unary: ['date'] }}
                                isDense
                                helperText={{ label: 'Year, month, day' }}
                                className="date-input"
                            />
                            <Form.Input
                                isDense
                                id="phone"
                                submitKey="phone"
                                type="number"
                                label={translated.global.phone}
                                value={user?.phone}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                            />
                            <Form.Input
                                isDense
                                id="mobile"
                                submitKey="mobile"
                                type="number"
                                label={translated.global.mobile}
                                value={user?.mobile}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                            />
                            <Form.Input
                                isDense
                                id="language"
                                submitKey="language"
                                type="select"
                                label={translated.global.language}
                                value={user?.language}
                                options={languages}
                                sortBy="content"
                                isRequired
                            />
                            <Form.Input
                                isDense
                                id="loginToken"
                                submitKey="loginToken"
                                type="text"
                                label={translated.global.loginToken}
                                value={user?.loginToken}
                                validations={{ maxLength: 255, ignoreChars: [' '] }}
                                charCount={{ total: 255 }}
                            />
                            {!isEditing && (
                                <Form.Input
                                    id="extra-attributes"
                                    key="extra-attributes"
                                    type="dynamicInputs"
                                    submitKey="user.extraAttributes"
                                    className="margin-top-small"
                                    label={translated.extraAttributes.title}
                                    value={user?.extraAttributes}
                                    availableAttributes={availableUserAttributes}
                                    submitFormat={(values) => transformDynamicInputs(values)}
                                />
                            )}
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            {isEditing && (
                <Form.Wrapper>
                    <Subsection
                        title={translated.extraAttributes.title}
                        className="account-subsection margin-top-medium"
                        canCollapse
                        shouldShowFormInfoOnCollapse
                        actionButtons={
                            (!isReadOnly)
                                ? [
                                    <Form.Reset key="fs" isDisabled={isLoading} color="primary" />,
                                    <Form.Primary key="fp" isDisabled={isLoading} id="save-extra" />,
                                ]
                                : null
                        }
                    >
                        <Form id="extra-attributes-form" onSubmit={handleOnSubmitExtraAttributes} isReadOnlyWithoutInputs={isReadOnly}>
                            <Form.Column width={{ base: 12, small: 6 }}>
                                <Form.Input
                                    id="extra-attributes"
                                    key="extra-attributes"
                                    type="dynamicInputs"
                                    submitKey="user.extraAttributes"
                                    className="margin-top-small"
                                    value={user?.extraAttributes}
                                    availableAttributes={availableUserAttributes}
                                    submitFormat={(values) => transformDynamicInputs(values)}
                                />
                            </Form.Column>
                        </Form>
                    </Subsection>
                </Form.Wrapper>
            )}

            {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                <EntityTags
                    {...sections[sectionType.ENTITY_TAGS]}
                    resources={{
                        ...sections[sectionType.ENTITY_TAGS].resources,
                        available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                    }}
                />
            )}

            {isEditing && links?.[sections[sectionType.RELATIONSHIPS]?.config?.resourceName] && (
                <Relationships
                    {...sections[sectionType.RELATIONSHIPS]}
                    resources={{
                        ...sections[sectionType.RELATIONSHIPS].resources,
                        available: links[sections[sectionType.RELATIONSHIPS].config.resourceName],
                    }}
                    user={user}
                />
            )}
        </SectionsWrapper>
    );
}

User.defaultProps = { data: null };

User.propTypes = {
    isEditing       : PropTypes.bool.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    navigateBack    : PropTypes.string.isRequired,
    sections        : PropTypes.shape({}).isRequired,
    pathForResource : PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withRequest(User);
