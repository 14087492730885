module.exports = Object.freeze({
    // General
    information : 'Information',
    general     : 'General',
    name        : 'Name',
    type        : 'Type',
    wallet      : 'Wallet',
    client      : 'Client',
    dates       : 'Dates',
    user        : 'User',
    loginToken  : 'Login Token',

    // Common
    firstName          : 'First Name',
    lastName           : 'Last Name',
    id                 : 'ID',
    status             : 'Status',
    email              : 'Email',
    phoneOrMobile      : 'Phone or Mobile',
    resendEmail        : 'Resend Salesforce invitation email',
    dissociateSecOwner : 'Dissociate Secondary Owner',
    phone              : 'Phone',
    mobile             : 'Mobile',
    department         : 'Department',
    role               : 'Role',
    birthday           : 'Date of Birth',
    total              : 'Total',
    notProvided        : 'Not provided',
    club               : 'Club',
    tier               : 'Tier',
    address            : 'Address',
    zipCode            : 'Zip code',
    country            : 'Country',
    city               : 'City',
    estate             : 'State',
    tags               : 'Tags',
    tagsFilter         : 'Filter using a tag',
    language           : 'Language',
    contractType       : 'Contract Type',
    contractId         : 'Contract ID',
    goToStart          : 'Go to start',
    goToInit           : 'Go to initial screen',
    devEnvironment     : 'User Acceptance Testing Environment',

    messageForNoData: 'There is no data added',

    // Status
    state: {
        enabled  : 'Enabled',
        disabled : 'Disabled',
        yes      : 'Yes',
        no       : 'No',
        error    : 'Error',
    },

    // Buttons
    buttons: {
        delete         : 'Delete',
        edit           : 'Edit',
        new            : 'New',
        add            : 'Add',
        view           : 'View',
        enable         : 'Enable',
        disable        : 'Disable',
        save           : 'Save',
        confirm        : 'Confirm',
        ok             : 'Ok',
        refresh        : 'Refresh',
        cancel         : 'Cancel',
        close          : 'Close',
        reset          : 'Reset',
        remove         : 'Remove',
        retry          : 'Retry',
        download       : 'Download',
        preview        : 'Preview',
        import         : 'Import',
        clear          : 'Clear',
        create         : 'Create',
        apply          : 'Apply',
        search         : 'Search',
        back           : 'Back',
        sendInvitation : 'Re Send invitation Link',
        viewDetails    : 'View details',
        moreInfo       : 'More info',
        upload         : 'Upload',
        yes            : 'yes',
        no             : 'no',
        update         : 'Update',
        disassociate   : 'Dissociate',
        restore        : 'Restore',
        show           : 'Show',
        hide           : 'Hide',
        reload         : 'Reload',
        unselect       : 'Unselect',
        select         : 'Select',
        collapse       : 'Collapse',
        expand         : 'Expand',
        plus           : 'Plus',
        less           : 'Less',
        more           : 'More',
        equal          : 'Equal',
    },

    errors: {
        error                : 'Error',
        default              : 'An error has occurred. Try again later',
        cantGetTheData       : 'We have some problem getting the data',
        retrying             : 'Please wait...',
        retryingEntity       : 'Trying to get',
        serverDoesNotRespond : 'Server is not responding',
        reloadDataFail       : "Can't load this resource, check your permissions",
        sectionNotFound      : 'Section not found',

        // Generic entities errors
        errorEntityNotFound             : "The resource couldn't be found in the system",
        errorContractTypeInUse          : 'The contract type is being used',
        errorUserRelationshipWithItself : "A user can't have a relationship with itself",
        errorResourceNotActive          : 'The resource is not active',
        errorEntityCannotBeDeleted      : 'This resource could not be removed since it is being used',
        entityAlreadyExists             : 'This resource already exists in the system',
        failedDependency                : 'The petition has failed, some data is missing in the server',

        errorUnauthorized      : 'An error has occurred. Try again later',
        errorForbidden         : 'You have no permissions for this action',
        errorBadRequest        : 'An error has occurred. Try again later',
        errorServerFails       : 'The server is failing',
        errorOnExtraAttributes : 'Invalid extra attribute name: ',
    },

    noResults      : 'There are no results',
    // Text helpers
    range          : 'Range between',
    date           : 'Year, month, day',
    feb29Warn      : 'Feb-29 will be changed to Mar-01',
    selectFile     : 'Select file',
    selectAnOption : 'Select an option',

    // Dates
    from      : 'From',
    to        : 'to',
    duration  : 'Duration',
    startDate : 'Start date',
    endDate   : 'End date',

    // Validations
    advice            : 'Advice',
    warning           : 'Warning',
    required          : 'This field is required',
    invalid           : 'Invalid value',
    invalidMin        : 'Invalid value, min:',
    invalidMax        : 'Invalid value, max:',
    invalidEmail      : 'The email is invalid',
    invalidNumber     : 'The number is invalid',
    invalidNoNumber   : 'Numbers are not allowed',
    invalidOnlyNumber : 'Only numbers are allowed',
    invalidPhone      : 'The phone number is invalid',
    invalidRange      : 'The range is invalid',
    invalidDate       : 'The date is invalid',
    alphanumeric      : 'Only alphanumeric characters are allowed',
    validMinDate      : 'Valid min date:',
    validMaxDate      : 'Valid max date:',

    // Components
    days                : 'days',
    incompleteAndErrors : 'There are incomplete fields and errors in the form',
    formErrors          : 'There are errors in the form',
    incomplete          : 'There are incomplete fields in the form',
    selectOption        : 'Select an option',
    missingPermissions  : 'Permissions for this action are missing',

    // Custom leave form changed confirmation
    sectionChangedConfirmation: {
        title   : 'Are you sure?',
        message : 'You will lose all changes made',
    },

    // Search
    search: { error: 'It was not possible to complete the search' },

    resetConfirmation: {
        title   : 'Reset changes?',
        message : 'Unsaved data will be lost.',
    },

    // Calendar component
    calendar: {
        fromLabel        : 'From',
        toLabel          : 'To',
        dateLabel        : 'Date',
        switchToCalendar : 'Switch to Calendar',
        switchToInput    : 'Switch to Input',
        errors           : {
            invalidDate    : 'The date is invalid',
            startBeforeEnd : "The start date can't be after the end date",
            rangeMinLength : "The start and end date can't be the same date",
            includesRange  : "The range selected can't include another range",
        },
    },
    restrictedApp: {
        noPermissions: {
            title    : 'No Permissions',
            subTitle : 'Contact an administrator',
        },
        maintenance: {
            title    : 'Under Maintenance',
            subTitle : 'We will be back shortly',
        },
    },

    longRequestWarning: 'Loading, it might take a while',

    uploadingSnackbar: 'Uploading…',

    pleaseWait: 'Please wait a moment...',

    about: {
        appName : 'Administration Panel',
        version : 'Version',
        build   : 'Build',
    },

    sideMenu: {
        global : 'Global',
        more   : 'More',
    },

    alert: {
        error   : 'Error',
        warning : 'Warning',
        success : 'Success',
    },

    breadcrumb: {
        closeGoTo  : 'Close Go To',
        goTo       : 'Go to',
        upperLevel : 'Upper level',
    },

    pickerInput: { format: 'Year, month, day' },

    card: { avatarError: "Avatar can't be loaded" },

    modal: { confirmation: 'Are you sure?' },

    errorHandler: { title: 'The action could not be completed' },

    floatingActionButton: { goUp: 'Go up' },

    walletSummary: { field: 'Awaiting data' },

    column: { error: 'Invalid width (1 to 12)' },

    grid: { error: 'Invalid width in Grid (1 to 6 or 12)' },

    table: {
        header : { reverseSort: 'Reverse sort direction' },
        footer : {
            prev  : 'Prev page',
            next  : 'Next Page',
            first : 'First page',
            last  : 'Last page',
            count : 'Rows per page: ',
        },
        buttons: {
            edition: {
                enable  : 'Enable edit',
                disable : 'Disable edit',
            },
        },
        empty         : 'No matches found',
        selectedItems : 'item(s) selected',
        filters       : 'Filters',
        quantity      : 'Quantity',
    },
});
