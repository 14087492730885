module.exports = Object.freeze({
    title       : 'Tags',
    empty       : 'There are no tags',
    createTitle : 'Create a tag',
    editTitle   : 'Edit a tag',
    update      : {
        success : 'The tag has been updated',
        error   : 'The tag could not be updated',
    },
    save: {
        success : 'The tag has been saved',
        error   : 'The tag could not be saved',
    },
    errors: {
        errorAlphanumeric : 'Only lowercase alphanumeric or underscore characters',
        entityIsReadonly  : 'This entity is read-only, cannot be modified',
    },

    id       : 'Id',
    name     : 'Name',
    slug     : 'Slug',
    label    : 'Label',
    hidden   : 'Is hidden',
    status   : 'Status',
    isActive : 'Is active',

    enable: {
        success : 'The tag has been enabled',
        error   : 'The tag could not be enabled',
    },
    disable: {
        success : 'The tag has been disabled',
        error   : 'The tag could not be disabled',
    },

    entityTags: {
        title : 'Tags',
        empty : 'There are no tags associated',

        associate: {
            message : 'Associate tag',
            success : 'The tag has been associated',
            error   : 'The tag could not be associated',
        },

        disassociate: {
            success : 'The tag has been dissociated',
            error   : 'The tag could not be dissociated',
        },
    },
    search: 'Filter by tag name',
});
