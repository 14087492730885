import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchingShape } from 'Constants/PropTypes';
import { sectionType } from 'Constants/types';
import PanelContext from 'State/panelContext';
import Table from 'Components/Table';
import Button from 'Components/Button';
import Subsection from 'Components/Sections/Subsection';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import RelationshipEditor from './Relationship';
import withRequest from 'Components/Sections/withRequest';

const getTableConfig = (list, user, onRelationshipEdit, onRelationshipDelete, intl) => {
    const convertedRelationships = [];

    const relationships = list || [];

    relationships.forEach((eachRelationship) => {
        const isInvertedRelationships = eachRelationship.userA.id === user.id;
        const userRelationship = isInvertedRelationships ? eachRelationship.userB : eachRelationship.userA;

        const connector = intl.formatMessage({
            id             : translated.owners.relationships.relationshipConnector,
            defaultMessage : translated.owners.relationships.relationshipConnector,
        });
        const b = eachRelationship.relationshipType.relationshipRoleB.toLowerCase();
        const a = eachRelationship.relationshipType.relationshipRoleA.toLowerCase();
        const type = isInvertedRelationships
            ? intl.formatMessage({
                id             : translated.owners.relationships.types[b],
                defaultMessage : translated.owners.relationships.types[b],
            })
            : intl.formatMessage({
                id             : translated.owners.relationships.types[a],
                defaultMessage : translated.owners.relationships.types[a],
            });

        const relationship = `${type} ${connector} ${user.firstName} ${user.lastName}`;

        convertedRelationships.push({
            ...userRelationship,
            relationship,
            user,
            links: eachRelationship.links,
        });
    });

    return {
        items : convertedRelationships,
        rows  : {
            actions: [
                {
                    content           : translated.owners.relationships.editRelationship,
                    callback          : onRelationshipEdit,
                    key               : 'editRelationship',
                    icon              : 'Pencil',
                    isOutsideDropdown : true,
                    tooltip           : translated.owners.relationships.editRelationship,
                    when              : (relationship) => relationship?.links?.self?.update,
                },
                {
                    content           : translated.owners.relationships.removeRelationship,
                    callback          : onRelationshipDelete,
                    key               : 'removeRelationship',
                    isOutsideDropdown : true,
                    icon              : 'AccountRemove',
                    tooltip           : translated.owners.relationships.removeRelationship,
                    when              : (relationship) => relationship?.links?.self?.delete,
                },
            ],
        },
        columns: [
            {
                title           : translated.global.firstName,
                key             : 'firstName',
                isVisible       : true,
                showTooltipWhen : 80,
                customClassName : 'text-overflow',
            },
            {
                title           : translated.global.lastName,
                key             : 'lastName',
                isVisible       : true,
                showTooltipWhen : 80,
                customClassName : 'text-overflow',
            },
            {
                title           : translated.global.email,
                key             : 'email',
                isVisible       : true,
                showTooltipWhen : 80,
                customClassName : 'text-overflow',
            },
            {
                title           : translated.owners.relationships.relationship,
                key             : 'relationship',
                isVisible       : true,
                showTooltipWhen : 80,
                customClassName : 'text-overflow',
            },
        ],
    };
};

export function Relationships({ data, user, resources, sections, pathForResource }) {
    const intl = useIntl();
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const { links, data : list } = data;

    const handleClickPerformDelete = async (relationship) => {
        try {
            const reqConfig = {
                ...relationship.links.self.delete,
                shouldReloadData : true,
                isCritical       : false,
                isGlobal         : false,
            };
            await navigator.requestForCurrentPath({ reqConfig, path: pathForResource, resources });

            snackbar.show({ content: translated.owners.relationships.delete.deleted, isSuccess: true });
        } catch (error) {
            snackbar.show({ error, content: translated.owners.relationships.delete.error, isError: true });
        }
    };

    const onRelationshipDelete = (relationship) => {
        confirmation.show({
            message       : translated.owners.relationships.delete.confirm,
            acceptMessage : translated.owners.relationships.delete.acceptMessage,
            onAccept      : () => handleClickPerformDelete(relationship),
        });
    };

    const onRelationshipEdit = (relationship) => {
        navigator.goToRelationshipEditor({
            path      : pathForResource,
            available : relationship.links.self,
            current   : 'read',
        });
    };

    const onRelationshipAdd = () => {
        navigator.goToRelationshipEditor({
            path      : pathForResource,
            available : links?.self,
            current   : 'init',
        });
    };

    const { columns, rows, items } = getTableConfig(list, user, onRelationshipEdit, onRelationshipDelete, intl);

    const actions = [];

    if (links?.self?.create && items?.length) {
        actions.push(
            <Button id="user-add-button" variant="unelevated" key="button_primary" onClick={() => onRelationshipAdd()}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        );
    }

    return (
        <Subsection actionButtons={actions} title={translated.owners.relationships.relationship}>
            {sections[sectionType.RELATIONSHIP]?.isSelected && (
                <RelationshipEditor {...sections[sectionType.RELATIONSHIP]} initLinks={user.links} user={user} />
            )}

            <Table
                id="relationships"
                key="relationships"
                columns={columns}
                rows={rows}
                items={items}
                canViewColumns
                canChangeSettings
                whenEmpty={(
                    <Alert
                        id="relationships-empty"
                        content={translated.owners.relationships.emptyList}
                        onClick={links?.self?.create ? () => onRelationshipAdd() : null}
                        actionText={links?.self?.create ? translated.global.buttons.add : null}
                    />
                )}
            />
        </Subsection>
    );
}

Relationships.defaultProps = {
    primaryOwner    : null,
    secondaryOwner  : null,
    blockedUsers    : [],
    setSectionState : () => {
        // Default
    },
    state      : {},
    reloadData : () => {
        // Default
    },
};

Relationships.propTypes = {
    resources             : PropTypes.shape({}).isRequired,
    fetching              : fetchingShape.isRequired,
    sections              : PropTypes.shape({}).isRequired,
    primaryOwner          : PropTypes.shape({}),
    secondaryOwner        : PropTypes.shape({}),
    navigateToUserEdition : PropTypes.string.isRequired,
    pathForResource       : PropTypes.arrayOf(PropTypes.string).isRequired,
    blockedUsers          : PropTypes.arrayOf(PropTypes.number),
    setSectionState       : PropTypes.func,
    state                 : PropTypes.shape({}),
    reloadData            : PropTypes.func,
    user                  : PropTypes.shape({}).isRequired,
    data                  : PropTypes.shape({}).isRequired,
};

export default withRequest(Relationships);
