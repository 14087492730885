import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import getClassName from 'Utils/getClassName';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';

function Chips({ id, className, isMultiSelect, outlined, list }) {
    const [selectedToggles, setSelectedToggles] = useState(Array(list.length).fill(false));
    const chipsClassName = getClassName({ isMultiSelect, isOutlined: outlined, isChoice: !isMultiSelect }, 'chips-wrapper', className);

    return (
        <div id={id} className={chipsClassName}>
            {list.map((chip, i) => {
                const key = `${chip.key}_${i}`;
                const isSelected = selectedToggles[i];

                const chipClassName = getClassName(
                    {
                        isSelected,
                        hasIcon      : chip.icon && isMultiSelect,
                        hasThumbnail : chip.thumbnail && isMultiSelect,
                        hasClose     : chip.onClose,
                        isDisabled   : !!chip.isDisabled,
                    },
                    'chip',
                );

                const handleCurrentToggleClick = () => {
                    if (isMultiSelect) {
                        const newToggles = [...selectedToggles];
                        newToggles[i] = !isSelected;
                        setSelectedToggles(newToggles);
                    }
                    if (chip.callback) {
                        chip.callback(chip);
                    }
                };

                return (
                    <div id={`${id}-${key}`} className={chipClassName} onClick={handleCurrentToggleClick} key={key}>
                        {isMultiSelect && isSelected && (
                            <div className="chip-selected chip-icon">
                                <Icon className="mdi-icon" path={IconPath.mdiCheck} />
                            </div>
                        )}
                        {isMultiSelect && (chip.icon || chip.thumbnail) && (
                            <>
                                {chip.icon && !isSelected && (
                                    <div className="chip-icon">
                                        <Icon className="mdi-icon" path={IconPath[`mdi${chip.icon}`]} />
                                    </div>
                                )}
                                {chip.thumbnail && !isSelected && (
                                    <div className="chip-thumbnail">
                                        <img src={chip.thumbnail} alt="chip-thumb" />
                                    </div>
                                )}
                            </>
                        )}
                        {chip.text && <div className="chip-text">{chip.text}</div>}
                        {chip.onClose && (
                            <Button
                                id={`close-chip-${key}`}
                                icon="CloseCircle"
                                className="chip-close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    chip.onClose(e);
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

Chips.defaultProps = {
    id            : '',
    className     : '',
    isMultiSelect : false,
    outlined      : false,
    list          : [
        {
            text       : '',
            isDisabled : false,
            callback   : null,
            onClose    : null,
            icon       : null,
            thumbnail  : undefined,
        },
    ],
};

Chips.propTypes = {
    id            : PropTypes.string,
    className     : PropTypes.string,
    isMultiSelect : PropTypes.bool,
    outlined      : PropTypes.bool,
    list          : PropTypes.arrayOf(
        PropTypes.shape({
            text       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            isDisabled : PropTypes.bool,
            callback   : PropTypes.func,
            onClose    : PropTypes.func,
            icon       : PropTypes.string,
            thumbnail  : PropTypes.string,
        }),
    ),
};

export default Chips;
