
import React from 'react';
import PropTypes from 'prop-types';

function Paragraph({ className, quantity, width }) {
    return (
        <div className={`skeleton skeleton-paragraphs ${className}`} style={{ width: `${width}px` }}>
            { Array.apply(0, Array(quantity)).map((x, i) => <div className="skeleton-element skeleton-paragraph" key={`${quantity + i}`} />)}
        </div>
    );
}

Paragraph.defaultProps = {
    className : '',
    quantity  : 5,
    width     : 85,
};

Paragraph.propTypes = {
    className : PropTypes.string,
    quantity  : PropTypes.number,
    width     : PropTypes.number,
};

export default Paragraph;
