import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';
import Form, { Input } from 'Components/Form';

const MAX_SELECTOR_LENGTH = 100;
const SEARCH_TIMEOUT = 1000;

function Footer({ id, pagination, onPaginationClick, isPaginationSmall, onSetPaginationReference }) {
    const intl = useIntl();
    const { page, prevPage, nextPage, pages, links, perPage } = pagination;

    const [selectedPage, setSelectedPage] = useState(page);

    useEffect(() => {
        setSelectedPage(page);
    }, [page]);

    const timerRef = useRef(null);

    const pagesViewOptions = Object.keys(links.perPages).map((key) => ({ content: key, value: key }));
    const pagesOptions = MAX_SELECTOR_LENGTH >= pages
        ? Array(pages)
            .fill('')
            .map((pageElement, index) => ({ content: String(index + 1), value: String(index + 1) }))
        : null;
    const lastPage = pages;
    const firstPage = 1;

    const handlePaginationByPage = (selected) => {
        const link = pagination.links.template;
        const updatedUrlBasedOnSelection = link.url.replace(':id', selected);
        link.url = updatedUrlBasedOnSelection;
        onPaginationClick(link);
    };

    const handleTextInputPaginationOnChange = (selected) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        setSelectedPage(selected);

        if (selected < 1 || selected > pages) {
            return;
        }
        timerRef.current = setTimeout(async () => {
            timerRef.current = null;

            const link = pagination.links.template;
            const updatedUrlBasedOnSelection = link.url.replace(':id', selected);
            link.url = updatedUrlBasedOnSelection;
            onPaginationClick(link);
        }, SEARCH_TIMEOUT);
    };

    const handlePaginationByOneOnClick = (link) => {
        onPaginationClick(link);
    };

    const handlePaginationPerPageOnChange = (selectedPerPages) => {
        const link = links.perPages[selectedPerPages];
        const perPageUsed = link.rel.replace('-', '=');
        onPaginationClick(link);
        onSetPaginationReference(perPageUsed);
    };

    const smallPaginationControls = (
        <>
            <Button
                id={`${id}-prev-page`}
                icon="ChevronLeft"
                tooltip={intl.formatMessage({
                    id             : translated.global.table.footer.prev,
                    defaultMessage : translated.global.table.footer.prev,
                })}
                disabled={!prevPage}
                onClick={() => handlePaginationByOneOnClick(links.previous)}
            />
            <Form.Wrapper>
                {pagesOptions && (
                    <Input
                        id={`${id}-selector-page`}
                        className="display-inline-block"
                        withoutDefaultOption
                        hasLabel={false}
                        type="select"
                        isDense
                        onChange={handlePaginationByPage}
                        options={pagesOptions}
                        value={selectedPage}
                    />
                )}
                {!pagesOptions && (
                    <Input
                        id={`${id}-number-page`}
                        className="display-inline-block"
                        hasLabel={false}
                        type="number"
                        isDense
                        onChange={handleTextInputPaginationOnChange}
                        value={selectedPage}
                        validations={{ minValue: 1, maxValue: pages, unary: ['numbers'] }}
                        hideErrorText
                    />
                )}
            </Form.Wrapper>
            <Button
                id={`${id}-next-page`}
                icon="ChevronRight"
                tooltip={intl.formatMessage({
                    id             : translated.global.table.footer.next,
                    defaultMessage : translated.global.table.footer.next,
                })}
                disabled={!nextPage}
                onClick={() => handlePaginationByOneOnClick(links.next)}
            />
        </>
    );

    return (
        <div className="table-pagination-wrapper">
            {!isPaginationSmall && (
                <>
                    <div className="table-pagination-select">
                        <FormattedMessage id={translated.global.table.footer.count} defaultMessage={translated.global.table.footer.count} />
                        <Input
                            id={`${id}-page-size`}
                            withoutDefaultOption
                            className="display-inline-block"
                            hasLabel={false}
                            type="select"
                            value={perPage}
                            isDense
                            options={pagesViewOptions}
                            onChange={handlePaginationPerPageOnChange}
                        />
                    </div>
                    <div id={`${id}-page-position`} className="table-pagination-count">
                        <FormattedMessage
                            id={translated.global.table.footer.pageCount}
                            defaultMessage={translated.global.table.footer.pageCount}
                            values={{ page: pagination.page, pages: pagination.pages }}
                        />
                    </div>
                    <div className="table-pagination-controls">
                        <Button
                            id={`${id}-first-page`}
                            icon="ChevronDoubleLeft"
                            tooltip={intl.formatMessage({
                                id             : translated.global.table.footer.first,
                                defaultMessage : translated.global.table.footer.first,
                            })}
                            disabled={String(firstPage) === String(page)}
                            onClick={() => handlePaginationByPage(firstPage)}
                        />
                        {smallPaginationControls}
                        <Button
                            id={`${id}-last-page`}
                            icon="ChevronDoubleRight"
                            tooltip={intl.formatMessage({
                                id             : translated.global.table.footer.last,
                                defaultMessage : translated.global.table.footer.last,
                            })}
                            disabled={String(lastPage) === String(page)}
                            onClick={() => handlePaginationByPage(lastPage)}
                        />
                    </div>
                </>
            )}
            {isPaginationSmall && <div className="table-pagination-controls">{smallPaginationControls}</div>}
        </div>
    );
}

Footer.defaultProps = { id: '', isPaginationSmall: false };

Footer.propTypes = {
    id                       : PropTypes.string,
    isPaginationSmall        : PropTypes.bool,
    onPaginationClick        : PropTypes.func.isRequired,
    pagination               : PropTypes.shape({}).isRequired,
    onSetPaginationReference : PropTypes.func.isRequired,
};

export default Footer;
