import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import LocaleContext from 'State/localeContext';
import Form from 'Components/Form';

function LanguageSelector({ onClose }) {
    const { snackbar } = useContext(PanelContext);
    const { locale: [currentLanguage, setLocale] } = useContext(LocaleContext);

    const initialValues = useMemo(() => ({ language: currentLanguage }), [currentLanguage]);

    const handleOnSubmit = async ({ values }) => {
        const { language } = values;
        try {
            setLocale(language);
            const content = <FormattedMessage id={translated.settings.changeLanguage.success} />;
            snackbar.show({ content, isSuccess: true });
        } catch (error) {
            const content = <FormattedMessage id={translated.settings.changeLanguage.error} />;
            snackbar.show({ error, content, isError: true });
        }
    };

    const modalProperties = useMemo(
        () => ({
            title   : <FormattedMessage id={translated.settings.changeLanguage.title} />,
            buttons : [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={onClose}>
                    <FormattedMessage id={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    <FormattedMessage id={translated.global.buttons.save} />
                </Form.Primary>,
            ],
        }),
        [onClose],
    );

    return (
        <Form.Wrapper modalProperties={modalProperties}>
            <Form id="language-selector-form" initialValues={initialValues} onSubmit={handleOnSubmit} onFinish={onClose}>
                <Form.Group
                    type="radio"
                    submitKey="language"
                    name="language"
                    label={<FormattedMessage id={translated.settings.changeLanguage.label} />}
                    isRequired
                >
                    <Form.Input value="en" label={<FormattedMessage id={translated.settings.changeLanguage.languages.english} />} />
                    <Form.Input value="es" label={<FormattedMessage id={translated.settings.changeLanguage.languages.spanish} />} />
                </Form.Group>
            </Form>
        </Form.Wrapper>
    );
}

LanguageSelector.propTypes = { onClose: PropTypes.func.isRequired };

export default LanguageSelector;
