import React from 'react';
import PropTypes from 'prop-types';
import Error from '../../../pages/_error';
import Modal from 'Components/Modal';
import RestrictedAppScreen from 'Components/RestrictedAppScreen';
import translated from 'Constants/labels/translated';

function ErrorHandler({
    children, title, status, isCritical, mustShow, clearErrors, restrictedApp,
}) {
    if (restrictedApp.isAppRestricted) {
        return <RestrictedAppScreen reason={restrictedApp.reason} message={restrictedApp.message} />;
    }

    return (
        <>
            {mustShow && isCritical && <Error errorMessage={title} status={status} />}
            {!isCritical && (
                <>
                    {children}
                    {mustShow && (
                        <Modal
                            title={translated.global.errors.error}
                            closeButton={{
                                text    : translated.global.buttons.close,
                                onClick : clearErrors,
                            }}
                            isVisible={mustShow}
                            className="small-error"
                        >
                            <Error errorMessage={title || translated.global.errorHandler.title} />
                        </Modal>
                    )}
                </>
            )}
        </>
    );
}

ErrorHandler.defaultProps = {
    title  : '',
    status : null,
};

ErrorHandler.propTypes = {
    title         : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    status        : PropTypes.shape({}),
    isCritical    : PropTypes.bool.isRequired,
    mustShow      : PropTypes.bool.isRequired,
    clearErrors   : PropTypes.func.isRequired,
    children      : PropTypes.node.isRequired,
    restrictedApp : PropTypes.shape({}).isRequired,
};

export default ErrorHandler;
