import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form, { FormError, FormNew } from 'Components/Form';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import yup from 'Utils/yupHelper';

const schema = yup.object().shape({
    name   : yup.string().required().max(50),
    parent : yup.number(),
});

function CategoryEditor({ onClose, data, isEditing, resources, parentId }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const { additional } = resources;

    const modalPropertiesForForm = useMemo(
        () => ({
            title   : isEditing ? translated.categories.editTitle : translated.categories.createTitle,
            buttons : [
                <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={onClose}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.SecondaryNew>,
                <Form.PrimaryNew variant="text" color="primary" key="fp">
                    {isEditing ? (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.create} defaultMessage={translated.global.buttons.create} />
                    )}
                </Form.PrimaryNew>,
            ],
        }),
        [isEditing, onClose],
    );

    const categories = useMemo(() => {
        const list = additional?.attachmentCategories?.length
            ? additional.attachmentCategories.map((each) => ({ value: each.id, content: each.name }))
            : [];

        if (data?.parent?.id && !list.find((e) => e.value === data.parent.id)) {
            // When the category's parent is disabled, it wont be on the categories list.
            list.push({ value: data.parent.id, content: data.parent.name });
        }

        return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = async (values) => {
        const reqConfig = {
            data: {
                ...values,
                isActive : typeof data.isActive !== 'undefined' ? data.isActive : true,
                parent   : values.parent ? { id: values.parent } : null,
            },
            ...resources.available[isEditing ? 'update' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(isEditing ? translated.categories.update.error : translated.categories.save.error, e);
        }
    };

    const onFinish = () => {
        snackbar.show({ content: isEditing ? translated.categories.update.success : translated.categories.save.success, isSuccess: true });

        navigator.goToParentAndReload(false, false);
    };

    const initialValues = useMemo(() => ({
        name   : data?.name || '',
        parent : parentId || data?.parent?.id || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [data]);

    return (
        <Form.WrapperNew
            modalProperties={modalPropertiesForForm}
            schema={schema}
            initialValues={initialValues}
            formId="categories-form"
        >
            <FormNew buttonsWidth={{ base: 12, small: 6 }} onSubmit={handleOnSubmit} onFinish={onFinish}>
                <Form.InputNew
                    isDense
                    isRequired
                    submitKey="name"
                    type="text"
                    label={translated.global.name}
                    charCount={{ total: 50 }}
                    key="name"
                />
                <Form.InputNew
                    isDense
                    submitKey="parent"
                    type="select"
                    label={translated.categories.parentName}
                    options={categories}
                    isDisabled={parentId}
                    hasCleanButton={!parentId}
                    sortBy="content"
                />
            </FormNew>
        </Form.WrapperNew>
    );
}

CategoryEditor.defaultProps = { parentId: null };

CategoryEditor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    parentId  : PropTypes.number,
};

export default withRequest(CategoryEditor);
