import React, { useMemo, useContext, useState, useCallback, useRef, useEffect } from 'react';
import Form, { FormError, FormNew } from 'Components/Form';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import Alert from 'Components/Alert';
import PanelContext from 'State/panelContext';
import Subsection from 'Components/Sections/Subsection';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Button from 'Components/Button';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import yup, { alphanumericUnderScore } from 'Utils/yupHelper';
import { EditorOptions } from './EditorOptions';

const DATA_TYPE_LIST_ID = 'List';

const isDataTypeList = (id) => id === DATA_TYPE_LIST_ID;

const schema = yup.object().shape({
    hidden     : yup.bool(),
    isActive   : yup.bool(),
    name       : yup.string().required().max(25),
    categories : yup.array().of(yup.string()).min(1),
    slug       : alphanumericUnderScore.required().max(50),
    dataType   : yup.string().required(),
    options    : yup.array().of(yup.string())
        .when('dataType', {
            is   : DATA_TYPE_LIST_ID,
            then : (currentSchema) => currentSchema.required().min(2),
        }),
    optionsText: yup.string(),
});

const schemaModal = yup.object().shape({
    newOption : yup.string().max(100),
    options   : yup.array().of(
        yup.object().shape({
            name: yup.string().max(100).required().unique(translated.extraAttributes.options.repeated, ({ originalValue, from }) => {
                const list = from?.[1]?.value?.options?.map((e) => e?.name) || [];
                const found = list?.filter((e) => String(e).trim() === String(originalValue).trim()) || [];

                return found?.length <= 1;
            }),
        }),
    ).min(2),
});

export function Editor({ data, isEditing, resources }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const [subDataTypeEnabled, setSubDataTypeEnabled] = useState(isDataTypeList(data?.dataType));
    const [editedOptions, setEditedOptions] = useState({ elements: [], lastId: 0, isActive: false });
    const formOptions = useRef(data?.options || []);

    const formRef = useRef();
    const initialized = useRef(false);

    const initialValues = useMemo(() => ({
        hidden      : !!data?.hidden,
        isActive    : !!data?.isActive,
        name        : data?.name,
        categories  : data?.categories || [],
        slug        : data?.slug,
        dataType    : data?.dataType,
        options     : data?.options || [],
        optionsText : data?.options?.join(', ') || '',
    }), [data]);

    useEffect(() => {
        if (formRef.current.setValue && !initialized?.current && initialValues?.dataType === DATA_TYPE_LIST_ID) {
            initialized.current = true;
            formRef.current.setValue('options', data?.options || []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef?.current?.setValue]);

    const openOptionModal = () => {
        setEditedOptions({
            elements: formOptions?.current?.length
                ? formOptions?.current.map((each, index) => ({ name: each, id: index }))
                : [],
            lastId   : formOptions?.current.length,
            isActive : true,
        });
    };

    const modalOnClose = (newValue) => {
        setEditedOptions({ elements: [], lastId: 0, isActive: false });

        if (newValue) {
            formOptions.current = newValue;

            if (formRef?.current?.setValue) {
                formRef.current.setValue('options', newValue, { shouldDirty: true, shouldValidate: true });
                formRef.current.setValue('optionsText', newValue?.join(', ') || '');
            }
        }
    };

    const { additional } = resources;
    const categories = additional?.categories?.map((each) => ({
        value      : each,
        content    : each,
        key        : `h_${each}`,
        isDisabled : data?.wasUsed && !!data?.categories?.find((eachSelectedCategory) => eachSelectedCategory === each),
    })) || [];

    const dataTypes = additional?.dataTypes?.map((each) => ({ value: each, content: each })) || [];

    const sendData = async (values) => {
        const reqConfig = {
            data: {
                ...values,
                slug        : isEditing ? undefined : values.slug,
                options     : isDataTypeList(values.dataType) ? values.options : null,
                optionsText : undefined,
            },
            ...(isEditing ? data?.links?.self?.patch : resources?.available?.create),
        };

        if (isEditing && data.wasUsed) {
            delete reqConfig.data.dataType;
        }

        try {
            await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(translated.extraAttributes.updateFailed, e);
        }
    };

    const handleOnSubmit = async (values) => {
        if (isEditing) {
            await sendData(values);
            return null;
        }

        return new Promise((resolve, reject) => {
            confirmation.show({
                title    : translated.extraAttributes.confirmation.title,
                message  : translated.extraAttributes.confirmation.message,
                onAccept : async () => {
                    try {
                        await sendData(values);
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                },
                onCancel: () => {
                    reject();
                },
            });
        });
    };

    const onFinish = useCallback(() => {
        snackbar.show({ content: isEditing ? translated.extraAttributes.updateSuccess : translated.extraAttributes.saveSuccess, isSuccess: true });
        navigator.goToParentAndReload(false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canMakeChanges = isEditing ? data?.links?.self?.patch : resources?.available?.create;

    const commonProps = { isDisabled: !canMakeChanges };
    const title = (isEditing && (canMakeChanges ? translated.extraAttributes.editTitle : translated.extraAttributes.viewTitle))
        || translated.extraAttributes.createTitle;

    const modalPropertiesForForm = useMemo(
        () => ({
            className : 'dynamic-attribute-option-modal',
            title     : <FormattedMessage
                id={translated.extraAttributes.options.edition.title}
                defaultMessage={translated.extraAttributes.options.edition.title}
            />,
            buttons: [
                <Form.SecondaryNew color="primary" key="fs" onClick={() => modalOnClose()}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.SecondaryNew>,
                <Form.PrimaryNew
                    variant="text"
                    color="primary"
                    key="fp"
                >
                    <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                </Form.PrimaryNew>,
            ],
        }),
        [],
    );

    const formButtons = useMemo(() => {
        if (canMakeChanges) {
            return [
                <Form.SecondaryNew key="fs" color="primary" onClick={navigator.goToRoot}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.SecondaryNew>,
                <Form.PrimaryNew key="fp">
                    <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                </Form.PrimaryNew>,
            ];
        }
        return [
            <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
            </Form.SecondaryNew>,
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canMakeChanges]);

    return (
        <>
            {editedOptions.isActive && (
                <Form.WrapperNew
                    modalProperties={modalPropertiesForForm}
                    schema={schemaModal}
                    initialValues={{ options: formOptions?.current?.map((e, i) => ({ id: i, name: e })) }}
                    formId="dynamic-attributes-options-form"
                >
                    <EditorOptions
                        options={editedOptions.elements}
                        onFinish={modalOnClose}
                    />
                </Form.WrapperNew>
            )}

            <Form.WrapperNew
                schema={schema}
                initialValues={initialValues}
                ref={formRef}
                formId="dynamic-attributes-form"
            >
                <SectionsWrapper
                    title={title}
                    actionButtons={formButtons}
                >
                    <Subsection title={translated.global.information}>
                        <FormNew
                            buttonsWidth={{ base: 12, small: 6 }}
                            onSubmit={handleOnSubmit}
                            onFinish={onFinish}
                        >
                            <Form.ColumnNew width={{ base: 12, small: 6 }}>
                                <Form.InputNew
                                    submitKey="hidden"
                                    isDense
                                    type="checkbox"
                                    label={translated.extraAttributes.hidden}
                                    key="hidden"
                                    {...commonProps}
                                />
                                <Form.InputNew
                                    submitKey="isActive"
                                    isDense
                                    type="checkbox"
                                    label={translated.extraAttributes.isActive}
                                    key="isActive"
                                    className="margin-remove-top"
                                    {...commonProps}
                                />
                                <Form.InputNew
                                    submitKey="name"
                                    isDense
                                    type="text"
                                    label={translated.extraAttributes.name}
                                    maxCharCount={25}
                                    key="name"
                                    {...commonProps}
                                />
                                <Form.InputNew
                                    submitKey="categories"
                                    isDense
                                    type="select"
                                    label={translated.extraAttributes.categories}
                                    options={categories}
                                    isMultiSelect
                                    {...commonProps}
                                />
                                <Form.InputNew
                                    submitKey="slug"
                                    isDense
                                    type="text"
                                    label={translated.extraAttributes.slug}
                                    maxCharCount={50}
                                    key="slug"
                                    {...commonProps}
                                    isDisabled={isEditing || commonProps.isDisabled}
                                />
                                <Form.InputNew
                                    submitKey="dataType"
                                    isDense
                                    type="select"
                                    label={translated.extraAttributes.dataType}
                                    options={dataTypes}
                                    onChange={(newId) => setSubDataTypeEnabled(newId === DATA_TYPE_LIST_ID)}
                                    {...commonProps}
                                    isDisabled={data.wasUsed || commonProps.isDisabled}
                                />

                                {subDataTypeEnabled && (
                                    <div className="form-field form-column-group">
                                        {formOptions?.current?.length
                                            ? (
                                                <>
                                                    <Form.InputNew
                                                        submitKey="optionsText"
                                                        isDense
                                                        type="text"
                                                        label={translated.extraAttributes.options.title}
                                                        key="optionsText"
                                                        {...commonProps}
                                                        isDisabled
                                                        className="flex-1"
                                                    />
                                                    {canMakeChanges && (
                                                        <Button
                                                            id="edit-options"
                                                            variant="text"
                                                            color="primary"
                                                            key="button_primary"
                                                            onClick={openOptionModal}
                                                            className="margin-left-xsmall"
                                                        >
                                                            <FormattedMessage
                                                                id={translated.extraAttributes.options.editButton}
                                                                defaultMessage={translated.extraAttributes.options.editButton}
                                                            />
                                                        </Button>
                                                    )}
                                                    <Form.InputError submitKey="options" />
                                                </>
                                            )
                                            : (
                                                <Alert
                                                    id="list-options"
                                                    title={translated.extraAttributes.options.empty.title}
                                                    content={translated.extraAttributes.options.empty.message}
                                                    actionText={(
                                                        <FormattedMessage
                                                            id={translated.extraAttributes.options.editButton}
                                                            defaultMessage={translated.extraAttributes.options.editButton}
                                                        />
                                                    )}
                                                    onClick={openOptionModal}
                                                />
                                            )}
                                    </div>
                                )}
                            </Form.ColumnNew>
                        </FormNew>
                    </Subsection>
                </SectionsWrapper>
            </Form.WrapperNew>
        </>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={440} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={135} className="margin-bottom-medium" />
                    <Skeleton.Form type="checkbox" quantity={2} />
                    <Skeleton.Form type="input" quantity={4} />
                </Grid.Column>
            </Grid>
        </>
    );
};

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
};

export default withRequest(Editor);
