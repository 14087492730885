import React from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import Subsection from 'Components/Sections/Subsection';

function History({ list }) {
    const columns = [
        {
            title     : translated.owners.contracts.club,
            key       : 'clubName',
            isVisible : true,
        },
        {
            title     : translated.owners.contracts.tier,
            key       : 'tierName',
            isVisible : true,
        },
        {
            title     : translated.owners.contracts.contractType,
            key       : 'contractTypeName',
            isVisible : true,
        },
        {
            title     : translated.owners.contracts.consumptionMode,
            key       : 'consumptionModeName',
            isVisible : true,
        },
        {
            title     : translated.owners.contracts.creation,
            key       : 'creationDateTime',
            isVisible : true,
            isDate    : true,
        },
        {
            title     : translated.owners.contracts.start,
            key       : 'begin',
            isVisible : true,
            isDate    : true,
        },
        {
            title     : translated.owners.contracts.end,
            key       : 'end',
            isVisible : true,
            isDate    : true,
        },
        {
            title     : translated.owners.contracts.legacyId,
            key       : 'legacyId',
            isVisible : true,
        },
    ];

    const history = list.map((e) => ({
        ...e,
        clubName            : e.contractType?.clubTier?.club?.name,
        tierName            : e.contractType?.clubTier?.tier?.name,
        contractTypeName    : e.contractType?.name,
        consumptionModeName : e.consumptionMode?.code,
    }));

    return (
        <Subsection title={translated.owners.contracts.history.title}>
            <Table
                id="contract-history"
                key="contract-history"
                columns={columns}
                items={history}
                hasTools={false}
                whenEmpty={(
                    <Alert
                        id="contract-history-empty"
                        content={translated.owners.contracts.history.empty}
                    />
                )}
            />
        </Subsection>
    );
}

History.propTypes = { list: PropTypes.arrayOf(PropTypes.shape({})).isRequired };

export default History;
