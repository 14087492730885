/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-cycle */
import { sectionType } from 'Constants/types';
import Owners from './Owners';
import OwnersList from './Owners/List';
import OwnersEditor from './Owners/Editor';
import OwnersImport from './Owners/Import';
import General from './Owners/Editor/General';
import OwnersDetails from './Owners/Details';
import Bookings from './Owners/Details/Bookings';
import Info from './Owners/Details/Info';
// eslint-disable-next-line import/no-useless-path-segments
import TierEditor from '../Sections/Tiers/Editor';
import ClubTierEditor from './Shared/Tiers/Editor';
// eslint-disable-next-line import/no-named-as-default
import Wallet from './Owners/Details/Wallet';
import WalletManager from './Owners/Details/WalletManager';
import ConversionManager from './Owners/Details/ConversionManager';
import WalletAddBalance from './Owners/Details/WalletAddBalance';
import Attachments from './Owners/Details/Attachments/List';
import Attachment from './Owners/Details/Attachments/Editor';
import Contracts from './Owners/Details/Contracts/List';
import ContractsEditor from './Owners/Details/Contracts/Editor';
import BankingConfigurationList from './Shared/BankingConfigurations/ListWithRequest';
import Notes from './Owners/Details/Notes';
import SystemRules from './Rules/Rules';
import SystemRulesList from './Rules/SystemList';
import RulesList from './Rules/List';
import Rule from './Rules/Editor';
import Tiers from './Tiers';
import TiersList from './Tiers/List';
import Clubs from './Clubs';
import ClubsList from './Clubs/List';
import ClubsEditor from './Clubs/Editor';
import ClubGeneral from './Clubs/Editor/General';
import ContractTypes from './Shared/ContractTypes/List';
import ContractType from './Shared/ContractTypes/Editor';
import Agents from './Agents';
import UsersList from './Agents/List';
import UsersEditor from './Agents/Editor';
import Roles from './Roles';
import RolesListComponent from './Roles/List';
import RolesEditor from './Roles/Editor';
import BalanceTypes from './BalanceTypes';
import BalanceTypesList from './BalanceTypes/List';
import BalanceTypesEditor from './BalanceTypes/Editor';
import Categories from './Categories';
import CategoriesList from './Categories/List';
import CategoriesEditor from './Categories/Editor';
import Reservations from './Reservations';
import ReservationsList from './Reservations/List';
import Partners from './Partners';
import PartnersList from './Partners/List';
import PartnersEditor from './Partners/Editor';
import PartnersGeneral from './Partners/Editor/General';
import PartnersInfo from './Partners/Info';
import SharedUsers from './Shared/Users/List';
import SharedUser from './Shared/Users/Editor';
import Contract from './Contracts';
import ContractList from './Contracts/List';
import ContractEditor from './Contracts/Editor';
import ExternalClubs from './ExternalClubs';
import ExternalClubsList from './ExternalClubs/List';
import ExternalClubsEditor from './ExternalClubs/Editor';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorMessage from 'Components/ErrorMessage';
import GeneralForImported from './Owners/Editor/GeneralForImported';
import Button from 'Components/Button';
import PanelContext from 'State/panelContext';
import WalletConfigList from './Shared/ConversionRules/List';
import WalletConfigEditor from './Shared/ConversionRules/Editor';
import WalletConfigListAlt from './Shared/ConversionRules/ListAlt';
import WalletConfigEditorAlt from './Shared/ConversionRules/EditorAlt';
import ConversionList from './Shared/Conversions/List';
import ExtraAttributes from './ExtraAttributes';
import ExtraAttributesList from './ExtraAttributes/List';
import ExtraAttributesEditor from './ExtraAttributes/Editor';
import Currencies from './Currencies';
import CurrenciesList from './Currencies/List';
import CurrenciesEditor from './Currencies/Editor';
import Tags from './Tags';
import TagsList from './Tags/List';
import TagsEditor from './Tags/Editor';
import EntityTags from './Tags/EntityTags';
import PropertyGroups from './PropertyGroups';
import PropertyGroupsList from './PropertyGroups/List';
import PropertyGroupsEditor from './PropertyGroups/Editor';
import Properties from './Properties';
import PropertiesList from './Properties/List';
import Relationship from './Shared/Users/Relationship';
// eslint-disable-next-line import/no-named-as-default
import Relationships from './Shared/Users/Relationships';
import Docs from './Docs';
import ViewDoc from './Docs/Doc';
import AccountStatements from './AccountStatement';
import AccountStatement from './AccountStatement/Editor';
import translated from 'Constants/labels/translated';

export const Collection = {
    [sectionType.OWNERS]                  : Owners,
    [sectionType.OWNERS_LIST]             : OwnersList,
    [sectionType.OWNERS_EDITOR]           : OwnersEditor,
    [sectionType.OWNERS_GENERAL]          : General,
    [sectionType.OWNERS_GENERAL_IMPORTED] : GeneralForImported,
    [sectionType.OWNERS_CREATOR]          : GeneralForImported,
    [sectionType.OWNERS_IMPORT]           : OwnersImport,

    [sectionType.DETAILS]            : OwnersDetails,
    [sectionType.BOOKINGS]           : Bookings,
    [sectionType.INFO]               : Info,
    [sectionType.NOTES]              : Notes,
    [sectionType.WALLET]             : Wallet,
    [sectionType.WALLET_MANAGER]     : WalletManager,
    [sectionType.CONVERSION_MANAGER] : ConversionManager,
    [sectionType.WALLET_ADD_BALANCE] : WalletAddBalance,
    [sectionType.ATTACHMENTS]        : Attachments,
    [sectionType.ATTACHMENT]         : Attachment,
    [sectionType.USERS]              : SharedUsers,
    [sectionType.USER]               : SharedUser,

    [sectionType.CLIENT_CONTRACTS]        : Contracts,
    [sectionType.CLIENT_CONTRACTS_EDITOR] : ContractsEditor,

    [sectionType.BANKING_CONFIGURATIONS]: BankingConfigurationList,

    [sectionType.SYSTEM_RULES]      : SystemRules,
    [sectionType.SYSTEM_RULES_LIST] : SystemRulesList,

    [sectionType.RULES] : RulesList,
    [sectionType.RULE]  : Rule,

    [sectionType.TIERS]       : Tiers,
    [sectionType.TIERS_LIST]  : TiersList,
    [sectionType.TIER_EDITOR] : TierEditor,

    [sectionType.CLUBS]             : Clubs,
    [sectionType.CLUBS_LIST]        : ClubsList,
    [sectionType.CLUBS_EDITOR]      : ClubsEditor,
    [sectionType.CLUBS_TIER_EDITOR] : ClubTierEditor,
    [sectionType.CLUBS_GENERAL]     : ClubGeneral,

    [sectionType.CONTRACT_TYPES] : ContractTypes,
    [sectionType.CONTRACT_TYPE]  : ContractType,

    [sectionType.AGENTS]        : Agents,
    [sectionType.AGENTS_LIST]   : UsersList,
    [sectionType.AGENTS_EDITOR] : UsersEditor,

    [sectionType.ROLES]        : Roles,
    [sectionType.ROLES_LIST]   : RolesListComponent,
    [sectionType.ROLES_EDITOR] : RolesEditor,

    [sectionType.BALANCE_TYPES]        : BalanceTypes,
    [sectionType.BALANCE_TYPES_LIST]   : BalanceTypesList,
    [sectionType.BALANCE_TYPES_EDITOR] : BalanceTypesEditor,

    [sectionType.CATEGORIES]        : Categories,
    [sectionType.CATEGORIES_LIST]   : CategoriesList,
    [sectionType.CATEGORIES_EDITOR] : CategoriesEditor,

    [sectionType.RESERVATIONS]      : Reservations,
    [sectionType.RESERVATIONS_LIST] : ReservationsList,

    [sectionType.RELATIONSHIP]  : Relationship,
    [sectionType.RELATIONSHIPS] : Relationships,

    [sectionType.PARTNERS]           : Partners,
    [sectionType.PARTNERS_LIST]      : PartnersList,
    [sectionType.PARTNERS_EDITOR]    : PartnersEditor,
    [sectionType.PARTNERS_GENERAL]   : PartnersGeneral,
    [sectionType.PARTNERS_INFO]      : PartnersInfo,
    [sectionType.PARTNERS_USERS]     : SharedUsers,
    [sectionType.PARTNERS_USER]      : SharedUser,
    [sectionType.PARTNERS_DETAILS]   : OwnersDetails,
    [sectionType.PARTNERS_CONTRACTS] : Contracts,

    [sectionType.CONTRACT]        : Contract,
    [sectionType.CONTRACT_LIST]   : ContractList,
    [sectionType.CONTRACT_EDITOR] : ContractEditor,

    [sectionType.CONVERSIONS]                : ConversionList,
    [sectionType.CONVERSION_RULES]           : WalletConfigList,
    [sectionType.CONVERSION_RULE_EDITOR]     : WalletConfigEditor,
    [sectionType.CONVERSION_RULES_ALT]       : WalletConfigListAlt,
    [sectionType.CONVERSION_RULE_EDITOR_ALT] : WalletConfigEditorAlt,

    [sectionType.EXTERNAL_CLUBS]        : ExternalClubs,
    [sectionType.EXTERNAL_CLUBS_LIST]   : ExternalClubsList,
    [sectionType.EXTERNAL_CLUBS_EDITOR] : ExternalClubsEditor,

    [sectionType.EXTRA_ATTRIBUTES]        : ExtraAttributes,
    [sectionType.EXTRA_ATTRIBUTES_LIST]   : ExtraAttributesList,
    [sectionType.EXTRA_ATTRIBUTES_EDITOR] : ExtraAttributesEditor,

    [sectionType.CURRENCIES]        : Currencies,
    [sectionType.CURRENCIES_LIST]   : CurrenciesList,
    [sectionType.CURRENCIES_EDITOR] : CurrenciesEditor,

    [sectionType.TAGS]        : Tags,
    [sectionType.TAGS_LIST]   : TagsList,
    [sectionType.TAGS_EDITOR] : TagsEditor,
    [sectionType.ENTITY_TAGS] : EntityTags,

    [sectionType.PROPERTY_GROUPS]        : PropertyGroups,
    [sectionType.PROPERTY_GROUPS_LIST]   : PropertyGroupsList,
    [sectionType.PROPERTY_GROUPS_EDITOR] : PropertyGroupsEditor,

    [sectionType.PROPERTIES]      : Properties,
    [sectionType.PROPERTIES_LIST] : PropertiesList,

    [sectionType.DOCUMENTATION] : Docs,
    [sectionType.DOCUMENTS]     : ViewDoc,
    [sectionType.RELEASES]      : ViewDoc,

    [sectionType.ACCOUNT_STATEMENTS] : AccountStatements,
    [sectionType.ACCOUNT_STATEMENT]  : AccountStatement,
};

function SelectedSection({ selectFrom, showErrorOnMissingSection, ...restOfProps }) {
    const intl = useIntl();
    const { Section, props } = SelectedSection.get(selectFrom);
    const { navigator } = useContext(PanelContext);

    if (Section) {
        return <Section {...props} {...restOfProps} />;
    }

    // By default shows an error message if the section is not found
    if (showErrorOnMissingSection) {
        return (
            <ErrorMessage title="Error">
                <FormattedMessage id={translated.global.errors.sectionNotFound} defaultMessage={translated.global.errors.sectionNotFound} />
                <br />
                <Button
                    id="go-to-start-button"
                    icon="ChevronLeft"
                    color="grey"
                    className="margin-top-small"
                    tooltip={intl.formatMessage({
                        id             : translated.global.goToInit,
                        defaultMessage : translated.global.goToInit,
                    })}
                    onClick={navigator.goToDefaultSection}
                >
                    <FormattedMessage id={translated.global.goToStart} defaultMessage={translated.global.goToStart} />
                </Button>
            </ErrorMessage>
        );
    }

    return null;
}

SelectedSection.get = (state = {}) => {
    const currentType = Object.keys(state).find(
        (section) => !state[section]?.isHidden && state[section]?.isEnabled && state[section].isSelected && !state[section].isEmbedded,
    );

    return currentType
        ? {
            Section : Collection[currentType],
            props   : { ...state[currentType], currentSection: currentType },
        }
        : {};
};

SelectedSection.getAny = (list = []) => {
    const found = list.find((e) => SelectedSection.get(e)?.Section);

    return found ? SelectedSection.get(found) : {};
};

SelectedSection.defaultProps = {
    showErrorOnMissingSection : true,
    selectFrom                : {},
};

SelectedSection.propTypes = {
    selectFrom                : PropTypes.shape({}),
    showErrorOnMissingSection : PropTypes.bool,
};

export { SelectedSection };
