import React, { useContext, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Subsection from 'Components/Sections/Subsection';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import Card from 'Components/Card';
import Form, { FormError, FormNew } from 'Components/Form';
import { getInitialPermissions, getAllPermissions } from './utils';
import { scrollToTop } from 'Utils';
import PermissionsHelp from './PermissionsHelp';
import yup from 'Utils/yupHelper';

const generateSchema = (permissions, data) => {
    const permissionsSchema = {};
    const initialValues = { name: data?.name, permissions: {} };

    const ownerPermissions = data?.permissions || [];

    permissions.forEach((eachEntity) => {
        if (eachEntity?.deps?.length) {
            eachEntity.deps.forEach((eachPermission) => {
                if (eachPermission.name) {
                    permissionsSchema[eachPermission.name] = yup.bool();
                    initialValues.permissions[eachPermission.name] = !!ownerPermissions.find((e) => e === eachPermission.name);
                }
            });
        }
    });

    return {
        schema: yup.object().shape({
            name        : yup.string().required().max(50),
            permissions : yup.object().shape(permissionsSchema),
        }),
        initialValues,
    };
};

function Editor({ data, isEditing, resources: { available, additional }, name: sectionName }) {
    const { permissions: userPermissions } = data;
    const { navigator, snackbar } = useContext(PanelContext);
    const permissions = useMemo(() => getInitialPermissions(additional.permissions, userPermissions), [additional.permissions, userPermissions]);
    const allPermissions = useMemo(() => getAllPermissions(additional?.permissions), [additional]);
    const [isHelpVisible, setIsHelpVisible] = useState(false);
    const [cardsState, setCardsState] = useState({
        arePermissionCardsOpen : false,
        areAllSelected         : false,
        wasExpandedOnEdition   : false,
    });

    const [styles, setStyles] = useState({});

    const formRef = useRef();
    const formPermissions = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { schema, initialValues } = useMemo(() => generateSchema(allPermissions, data), []);

    const handleOnPermissionChange = (isAddingPermission, permissionName) => {
        setStyles({});

        if (formRef?.current?.setValue) {
            const updatedValues = { ...formPermissions.current };

            const listName = isAddingPermission ? 'provides' : 'dependents';
            const associatedPermissions = permissions[permissionName][listName];
            associatedPermissions.forEach((each) => {
                updatedValues[each] = isAddingPermission;
            });
            updatedValues[permissionName] = isAddingPermission;

            formRef.current.setValue('permissions', updatedValues, { shouldDirty: true });
        }
    };

    const handleShowLinkedActions = useCallback((actionName, cardName, isOnFocus) => {
        if (!isOnFocus) {
            setStyles({}); // We 'clean' all styles
            return;
        }

        const isSelected = !!formPermissions.current[actionName];

        const { dependents, provides } = permissions[actionName];
        const actionsToUpdate = (isSelected ? dependents : provides) || [];

        const newStyles = {};

        actionsToUpdate.forEach((each) => {
            if (actionName !== each) {
                newStyles[each] = isSelected ? 'is-linked-remove' : 'is-linked';
            }
        });

        allPermissions
            .filter(({ deps }) => deps.find(({ name }) => actionsToUpdate.find((eachAction) => eachAction === name)))
            .forEach(({ key }) => { newStyles[key] = isSelected ? 'is-linked-remove' : 'is-linked'; });

        setStyles(newStyles);
    }, [allPermissions, permissions]);

    const handleOnSubmit = useCallback(
        async (values) => {
            try {
                const link = isEditing ? available.update : available.create;
                if (!link) {
                    return null;
                }

                const newPermissions = [];
                Object.entries(permissions).forEach(([key, { isSelected }]) => {
                    if (isSelected) {
                        newPermissions.push(key);
                    }
                });

                const newData = {
                    name        : values.name,
                    permissions : Object.entries(values.permissions)
                        .filter(([, permissionValue]) => !!permissionValue)
                        .map(([permissionKey]) => permissionKey),
                };

                return await navigator.requestForCurrentPath({ reqConfig: { data: newData, ...link } });
            } catch (e) {
                throw new FormError(isEditing ? translated.roles.save.error : translated.roles.create.error, e, translated.roles.errors);
            }
        },
        [available.create, available.update, isEditing, permissions, navigator],
    );

    const handleOnFinish = () => {
        snackbar.show({
            content   : isEditing ? translated.roles.save.success : translated.roles.create.success,
            isSuccess : true,
        });
        navigator.goToRoot();
    };

    const handlePrepareForTour = useCallback(() => {
        // Shows the first Card and the second step goes there
        allPermissions[0].cardRef.current.setVisibility(true);
        allPermissions[0].cardRef.current.element.current.classList.add('step-3');

        // The second card is used to show a linked permission
        allPermissions[1].cardRef.current.element.current.classList.add('step-4');
        allPermissions[1].cardRef.current.element.current.classList.add('is-linked');

        // In the 4th step we show the links that will be removed
        allPermissions[2].cardRef.current.element.current.classList.add('step-5');
        allPermissions[2].cardRef.current.element.current.classList.add('is-linked-remove');
    }, [allPermissions]);

    const handleResetAfterTour = useCallback(() => {
        // We close the card, remove the classes that shown the icons and scroll to top
        allPermissions[0].cardRef.current.setVisibility(false);
        allPermissions[1].cardRef.current.element.current.classList.remove('is-linked');
        allPermissions[2].cardRef.current.element.current.classList.remove('is-linked-remove');
        scrollToTop();
    }, [allPermissions]);

    const handleExpandCards = useCallback(() => {
        const updatedStateForCards = {};

        if (isEditing) {
            updatedStateForCards.wasExpandedOnEdition = true;
        }
        setCardsState((prev) => ({
            ...prev,
            ...updatedStateForCards,
            arePermissionCardsOpen: !cardsState.arePermissionCardsOpen,
        }));
    }, [cardsState, isEditing]);

    const handleSelectAll = useCallback((isMarked) => {
        if (formRef?.current?.setValue) {
            const updatedValues = {};

            allPermissions
                .forEach(({ deps }) => deps.forEach(({ name }) => { updatedValues[name] = !isMarked; }));

            formRef.current.setValue('permissions', updatedValues, { shouldDirty: true });
        }

        if (!cardsState.arePermissionCardsOpen) {
            handleExpandCards();
        }
        setCardsState((prev) => ({ ...prev, areAllSelected: !prev.areAllSelected }));
    }, [allPermissions, cardsState, handleExpandCards]);

    useEffect(() => {
        const updatedStateForCards = { areAllSelected: cardsState.areAllSelected };

        if (isEditing) {
            const permissionsBeingEditedAreAllSelected = Object.keys(permissions).every((permissionKey) => permissions[permissionKey].isSelected);

            if (permissionsBeingEditedAreAllSelected) {
                updatedStateForCards.areAllSelected = true;
            }
        }

        if (updatedStateForCards.areAllSelected) {
            updatedStateForCards.arePermissionCardsOpen = true;
        }

        setCardsState((prev) => ({ ...prev, ...updatedStateForCards }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, permissions]);

    const getIsToggleVisible = (deps) => {
        const permissionsGroup = deps.map((perm) => perm.name);
        const isRolesPreSelectedOnEdition = !!userPermissions?.filter((permission) => permissionsGroup.includes(permission)).length;
        if (cardsState.arePermissionCardsOpen || (isEditing && cardsState.wasExpandedOnEdition)) {
            return cardsState.arePermissionCardsOpen;
        }

        return isRolesPreSelectedOnEdition;
    };

    // Constants to generate the columns
    const numberOfColumns = 3;
    const elementsPerColumn = allPermissions.length / numberOfColumns;
    const iterationColumns = [...Array(numberOfColumns)].map((c, i) => i);

    const steps = [
        <FormattedMessage id={translated.roles.wizard.step1} defaultMessage={translated.roles.wizard.step1} />,
        <FormattedMessage id={translated.roles.wizard.step2} defaultMessage={translated.roles.wizard.step2} />,
        <FormattedMessage id={translated.roles.wizard.step3} defaultMessage={translated.roles.wizard.step3} />,
        <>
            <FormattedMessage id={translated.roles.wizard.step4_1} defaultMessage={translated.roles.wizard.step4_1} />
            <span className="is-linked" />
            <FormattedMessage id={translated.roles.wizard.step4_2} defaultMessage={translated.roles.wizard.step4_2} />
        </>,
        <>
            <FormattedMessage id={translated.roles.wizard.step5_1} defaultMessage={translated.roles.wizard.step5_1} />
            <span className="is-linked-remove" />
            <FormattedMessage id={translated.roles.wizard.step5_2} defaultMessage={translated.roles.wizard.step5_2} />
        </>,
    ];
    const permissionsTitle = (
        <>
            <FormattedMessage id={translated.roles.permissions} defaultMessage={translated.roles.permissions} />
            <Button
                id="role-public-permissions-button"
                className="margin-left-xsmall"
                icon="InformationOutline"
                tooltip={translated.roles.publicPermissionsTooltip}
                onClick={() => setIsHelpVisible(true)}
                disabled={!data?.additionalResources?.permissions?.length}
            />
        </>
    );

    const canMakeChanges = !isEditing || !!available.update;

    const title = (isEditing && (canMakeChanges ? translated.roles.editionTitle : translated.roles.viewTitle))
        || translated.roles.creationTitle;

    const commonProps = { isDisabled: !canMakeChanges };

    const formButtons = useMemo(() => (canMakeChanges
        ? [
            <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.SecondaryNew>,
            <Form.PrimaryNew key="fp">
                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
            </Form.PrimaryNew>,
        ]
        : [
            <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
            </Form.SecondaryNew>,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ]), [canMakeChanges]);

    const sectionButtons = useMemo(() => (canMakeChanges
        ? [
            <Button id="roles-select-button" size="small" variant="text" onClick={() => handleSelectAll(cardsState.areAllSelected)} className="step-1" ignoreTimer>
                {cardsState.areAllSelected ? (
                    <FormattedMessage id={translated.global.buttons.unselect} defaultMessage={translated.global.buttons.unselect} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.select} defaultMessage={translated.global.buttons.select} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
            <Button id="roles-collapse-button" size="small" variant="text" onClick={handleExpandCards} className="step-2" ignoreTimer>
                {cardsState.arePermissionCardsOpen ? (
                    <FormattedMessage id={translated.global.buttons.collapse} defaultMessage={translated.global.buttons.collapse} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.expand} defaultMessage={translated.global.buttons.expand} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
        ]
        : [
            <Button id="roles-collapse-button" size="small" variant="text" onClick={handleExpandCards} className="step-2" ignoreTimer>
                {cardsState.arePermissionCardsOpen ? (
                    <FormattedMessage id={translated.global.buttons.collapse} defaultMessage={translated.global.buttons.collapse} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.expand} defaultMessage={translated.global.buttons.expand} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
        ]), [cardsState, canMakeChanges, handleExpandCards, handleSelectAll]);

    const onInputChange = useCallback((newValues) => {
        formPermissions.current = newValues?.permissions; // We keep a copy of the permissions to show the checkbox styles
    }, []);

    return (
        <Form.WrapperNew
            formId="roles-form"
            schema={schema}
            initialValues={initialValues}
            onInputChange={onInputChange}
            ref={formRef}
            keepValuesOnInputRemove
        >
            <SectionsWrapper
                title={title}
                titleValues={isEditing ? { name: sectionName } : null}
                actionButtons={formButtons}
                onAfterTour={handlePrepareForTour}
                onBeforeTour={handleResetAfterTour}
                steps={canMakeChanges && steps}
            >
                {data?.additionalResources?.permissions && isHelpVisible && (
                    <PermissionsHelp onClose={() => setIsHelpVisible(false)} permissions={data?.additionalResources?.permissions} />
                )}
                <Subsection
                    title={permissionsTitle}
                    actionButtons={sectionButtons}
                    className="has-info"
                >
                    <FormNew
                        buttonsWidth={{ base: 12, small: 6 }}
                        onSubmit={handleOnSubmit}
                        onFinish={handleOnFinish}
                    >
                        <Form.ColumnNew width={{ base: 12, small: 6, medium: 4 }}>
                            <Form.InputNew
                                isDense
                                submitKey="name"
                                type="text"
                                label={translated.roles.name}
                                charCount={{ total: 50 }}
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="hasChanged"
                                type="hidden"
                            />
                        </Form.ColumnNew>

                        {additional && (
                            <Grid className="roles-form margin-top-medium">
                                {/* We create some columns */}
                                {iterationColumns.map((column, colIndx) => (
                                    <Grid.Column width={{ base: 12, small: 4 }} key={column}>
                                        {/* Show in each column the corresponding group of permissions */}
                                        {allPermissions
                                            // eslint-disable-next-line max-len
                                            .filter((p, permIndx) => permIndx >= colIndx * elementsPerColumn && permIndx < (colIndx + 1) * elementsPerColumn)
                                            .map(({ deps, key, cardRef }) => (
                                                // One card and a list of permissions for each group
                                                <div className="roles-card margin-bottom-small" key={key}>
                                                    <Card
                                                        id={`role-group-${key}`}
                                                        title={translated.roles.permissionNames[key]?.title}
                                                        isToggleVisible={getIsToggleVisible(deps)}
                                                        ref={cardRef}
                                                        expandableContent
                                                        className={styles[key]}
                                                        unelevated
                                                    >
                                                        {deps.map(({ name, ref, label }) => (
                                                            <Form.InputNew
                                                                id={`permissions-${name}`}
                                                                onChange={(isAddingPermission) => handleOnPermissionChange(isAddingPermission, name)}
                                                                onFocus={() => handleShowLinkedActions(name, key, true)}
                                                                onBlur={() => handleShowLinkedActions(name, key, false)}
                                                                label={label || name}
                                                                key={`permissions.${name}`}
                                                                submitKey={`permissions.${name}`}
                                                                ref={ref}
                                                                type="checkbox"
                                                                className={styles[name]}
                                                                {...commonProps}
                                                            />
                                                        ))}
                                                    </Card>
                                                </div>
                                            ))}
                                    </Grid.Column>
                                ))}
                            </Grid>
                        )}
                    </FormNew>
                </Subsection>
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" smallInput />
                </Grid.Column>
            </Grid>
            <Grid addMargin="onStackedColumns" childWidth={{ base: 12, small: 3 }}>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
            </Grid>
        </>
    );
};

Editor.propTypes = {
    name      : PropTypes.string.isRequired,
    data      : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    resources : PropTypes.shape({}).isRequired,
};

export default withRequest(Editor);
