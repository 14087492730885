import React, { useMemo, useState, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import PanelContext from 'State/panelContext';

const getExtraInfoForBeginDate = (minDate, maxDate) => {
    const beginDate = minDate ? (
        <FormattedMessage defaultMessage={translated.global.validMinDate} id={translated.global.validMinDate} values={{ date: minDate }} />
    ) : null;
    const endDate = maxDate ? (
        <FormattedMessage defaultMessage={translated.global.validMaxDate} id={translated.global.validMaxDate} values={{ date: maxDate }} />
    ) : null;

    return (
        <div>
            {beginDate && <span>{beginDate}</span>}
            {beginDate && <br />}
            {endDate && <span>{endDate}</span>}
        </div>
    );
};

const amountIsInteger = (id, balanceTypes) => balanceTypes?.length && balanceTypes.find((bt) => bt.id === id)?.currency?.isInteger;

export function BankingConfiguration({
    title, data, balanceTypes, onSave, onCancel, isTemplate, contractData, isEditing,
}) {
    const { confirmation, dateManager } = useContext(PanelContext);

    const [isRepeatEnabled, setIsRepeatEnabled] = useState(!!data?.repeat);
    const [effectiveDate, setEffectiveDate] = useState(data?.effectiveDate);
    const [isInteger, setIsInteger] = useState(amountIsInteger(data?.balanceType, balanceTypes));
    const hasChanged = useRef(null);

    const customOnClose = useCallback(() => {
        if (hasChanged.current) {
            confirmation.show({
                title    : translated.global.sectionChangedConfirmation.title,
                message  : translated.global.sectionChangedConfirmation.message,
                onAccept : () => {
                    if (onCancel) {
                        onCancel();
                    }
                },
            });
        } else if (onCancel) {
            onCancel();
        }
    }, [confirmation, onCancel]);

    const modalPropertiesForForm = useMemo(
        () => ({
            title,
            buttons: [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={customOnClose}>
                    <FormattedMessage defaultMessage={translated.global.buttons.cancel} id={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    {data?.id ? (
                        <FormattedMessage defaultMessage={translated.global.buttons.save} id={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage defaultMessage={translated.balanceTypes.create} id={translated.balanceTypes.create} />
                    )}
                </Form.Primary>,
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        [customOnClose, data, title],
    );

    const onSubmit = async ({ values }) => onSave({
        ...values,
        local             : !!values.local,
        repeat            : !!values.repeat,
        repeatDelta       : values.repeatDelta || null,
        repeatAnniversary : values.repeatAnniversary || null,
        shoulderLeft      : values.shoulderLeft || 0,
        shoulderRight     : values.shoulderRight || 0,
    });

    const handleOnBalanceTypeChange = (id) => {
        setIsInteger(amountIsInteger(id, balanceTypes));
    };

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form
                id="banking-configurations-form"
                buttonsWidth={{ base: 12, small: 6 }}
                initialValues={{
                    ...data,
                    repeatAnniversary: data?.repeatAnniversary,
                }}
                onSubmit={onSubmit}
                forceValidationsOnFirstRender={isEditing}
                avoidSetComponentWithChanges
                onChange={({ hasChanged: changedRecently }) => {
                    hasChanged.current = changedRecently;
                }}
            >
                {data.id && <Form.Input type="hidden" submitKey="id" value={data.id} />}

                <Form.Input
                    type="select"
                    submitKey="balanceType"
                    options={balanceTypes.map((e) => ({ ...e, content: e.name, value: e.id }))}
                    label={translated.owners.contracts.edition.bankingConfiguration.balanceType}
                    submitFormat={(id) => ({ id })}
                    onChange={handleOnBalanceTypeChange}
                    isRequired
                    isDense
                />

                <Form.Group
                    type="radio"
                    name="local"
                    submitKey="local"
                    label={translated.owners.contracts.edition.bankingConfiguration.applyOn}
                    value={!!data?.local}
                >
                    <Form.Input
                        value={false}
                        label={translated.owners.contracts.edition.bankingConfiguration.applyGlobal}
                    />
                    <Form.Input
                        value
                        label={translated.owners.contracts.edition.bankingConfiguration.applyContract}
                    />
                </Form.Group>

                <Form.Input
                    type="checkbox"
                    submitKey="repeat"
                    label={translated.owners.contracts.edition.bankingConfiguration.isRepeated}
                    isDense
                    onChange={(value) => setIsRepeatEnabled(value)}
                />

                {!isTemplate && !isRepeatEnabled && (
                    <Form.Input
                        type="date"
                        icon={{ name: 'Calendar', position: 'right' }}
                        submitKey="effectiveDate"
                        label={translated.owners.contracts.edition.bankingConfiguration.effectiveDate}
                        isDense
                        minDate={contractData.begin}
                        maxDate={contractData.end}
                        onChange={(value) => setEffectiveDate(value)}
                        validations={{
                            minDate : contractData.begin,
                            maxDate : contractData.end,
                            unary   : ['date'],
                        }}
                        helperText={{ label: translated.global.date }}
                        supportText={contractData.begin || contractData.end ? getExtraInfoForBeginDate(contractData.begin, contractData.end) : null}
                        isRequired
                    />
                )}

                {!isTemplate && !isRepeatEnabled && (
                    <Form.Input
                        type="date"
                        icon={{ name: 'Calendar', position: 'right' }}
                        submitKey="expiryDate"
                        label={translated.owners.contracts.edition.bankingConfiguration.expiryDate}
                        isDense
                        minDate={(effectiveDate && dateManager.getNextDayFormatted(effectiveDate)) || contractData.begin}
                        maxDate={contractData.end}
                        validations={{
                            minDate : (effectiveDate && dateManager.getNextDayFormatted(effectiveDate)) || contractData.begin,
                            maxDate : contractData.end,
                            unary   : ['date'],
                        }}
                        helperText={{ label: translated.global.date }}
                        supportText={
                            contractData.begin || (effectiveDate && dateManager.getNextDayFormatted(effectiveDate)) || contractData.begin
                                ? getExtraInfoForBeginDate((effectiveDate && dateManager.getNextDayFormatted(effectiveDate))
                                        || contractData.begin, contractData.end)
                                : null
                        }
                        isRequired
                    />
                )}

                {isRepeatEnabled && (
                    <Form.Input
                        type="number"
                        submitKey="repeatDelta"
                        label={translated.owners.contracts.edition.bankingConfiguration.repeatDelta}
                        validations={{ minValue: 1, maxValue: 999, unary: ['numbers'] }}
                        suffix="months"
                        isDense
                        isRequired
                    />
                )}

                {!isTemplate && isRepeatEnabled && (
                    <Form.Input
                        type="date"
                        icon={{ name: 'Calendar', position: 'right' }}
                        submitKey="repeatAnniversary"
                        label={translated.owners.contracts.edition.bankingConfiguration.repeatAnniversary}
                        isDense
                        minDate={contractData.begin}
                        maxDate={contractData.end}
                        validations={{
                            minDate : contractData.begin,
                            maxDate : contractData.end,
                            unary   : ['date'],
                        }}
                        helperText={{ label: translated.global.date }}
                        supportText={contractData.begin || contractData.end ? getExtraInfoForBeginDate(contractData.begin, contractData.end) : null}
                        isRequired
                    />
                )}

                {!isTemplate && (
                    <Form.Input
                        type="number"
                        submitKey="amount"
                        label={translated.owners.contracts.edition.bankingConfiguration.amount}
                        value={data?.amount && Number(data?.amount)}
                        validations={
                            isInteger
                                ? { minValue: 1, maxValue: 999999999, unary: ['numbers'] }
                                : { minValue: 0.01, maxValue: 999999999, unary: ['float'] }
                        }
                        isDense
                        isRequired
                    />
                )}

                <Form.Input
                    type="number"
                    submitKey="shoulderLeft"
                    label={translated.owners.contracts.edition.bankingConfiguration.shoulderLeft}
                    validations={{ minValue: 0, maxValue: 999999999, unary: ['numbers'] }}
                    isDense
                />

                <Form.Input
                    type="number"
                    submitKey="shoulderRight"
                    label={translated.owners.contracts.edition.bankingConfiguration.shoulderRight}
                    validations={{ minValue: 0, maxValue: 999999999, unary: ['numbers'] }}
                    isDense
                />
            </Form>
        </Form.Wrapper>
    );
}

BankingConfiguration.defaultProps = {
    isTemplate   : false,
    contractData : {},
    isEditing    : false,
    clientData   : null,
};

BankingConfiguration.propTypes = {
    title        : PropTypes.string.isRequired,
    data         : PropTypes.shape({}).isRequired,
    balanceTypes : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSave       : PropTypes.func.isRequired,
    onCancel     : PropTypes.func.isRequired,
    isTemplate   : PropTypes.bool,
    contractData : PropTypes.shape({}),
    isEditing    : PropTypes.bool,
    clientData   : PropTypes.shape({}),
};

export default BankingConfiguration;
