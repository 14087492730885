const TITLE_SET = 'TITLE_SET';
const USERNAME_SET = 'USERNAME_SET';
const APP_STATE_SET = 'APP_STATE_SET';

const SIDE_DRAWER_VISIBLE_TOGGLE = 'SIDE_DRAWER_VISIBLE_TOGGLE';
const SIDE_DRAWER_COLLAPSE_TOGGLE = 'SIDE_DRAWER_COLLAPSE_TOGGLE';
const DARK_MODE_TOGGLE = 'DARK_MODE_TOGGLE';
const SIDE_SHEET_TOGGLE = 'SIDE_SHEET_TOGGLE';
const BREADCRUMB_TABS_TOGGLE = 'BREADCRUMB_TABS_TOGGLE';
const USE_MOCK_TOGGLE = 'USE_MOCK_TOGGLE';
const IS_LOCAL_STORAGE_LOADED_TOGGLE = 'IS_LOCAL_STORAGE_LOADED_TOGGLE';
const TOUR_TOGGLE = 'TOUR_TOGGLE';

const APP_ERROR_STATE = 'APP_ERROR_STATE';
const APP_ERROR_CLEAR = 'APP_ERROR_CLEAR';

const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

const PROGRESS_SHOW = 'PROGRESS_SHOW';
const PROGRESS_HIDE = 'PROGRESS_HIDE';

const CONFIRMATION_SHOW = 'CONFIRMATION_SHOW';
const CONFIRMATION_HIDE = 'CONFIRMATION_HIDE';

const USER_SHOULD_REFRESH = 'USER_SHOULD_REFRESH';
const USER_SET = 'USER_SET';
const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET';

const SET_APP_AS_RESTRICTED = 'SET_APP_AS_RESTRICTED';

const SET_LANGUAGE = 'SET_LANGUAGE';

export {
    USERNAME_SET,
    SIDE_DRAWER_VISIBLE_TOGGLE,
    SIDE_DRAWER_COLLAPSE_TOGGLE,
    DARK_MODE_TOGGLE,
    SIDE_SHEET_TOGGLE,
    BREADCRUMB_TABS_TOGGLE,
    USE_MOCK_TOGGLE,
    SET_LANGUAGE,
    IS_LOCAL_STORAGE_LOADED_TOGGLE,
    TOUR_TOGGLE,
    TITLE_SET,
    APP_STATE_SET,
    APP_ERROR_STATE,
    APP_ERROR_CLEAR,
    SNACKBAR_SHOW,
    SNACKBAR_HIDE,
    PROGRESS_SHOW,
    PROGRESS_HIDE,
    CONFIRMATION_SHOW,
    CONFIRMATION_HIDE,
    USER_SHOULD_REFRESH,
    USER_SET,
    NOTIFICATIONS_SET,
    SET_APP_AS_RESTRICTED,
};
