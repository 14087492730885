import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Chips from 'Components/Chips';
import CalendarDate from 'Components/CalendarDate';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import formContext from '../formContext';

function Picker({
    label,
    value,
    onChange,
    isReadOnlyWithoutInputs,
    onFocus,
    onBlur,
    isDisabled,
    id,
    isInline,
    isRange,
    isMultiRange,
    minDate,
    maxDate,
    labelFrom,
    labelTo,
    minRangeLength,
}) {
    const { dateManager } = useContext(formContext);

    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const [isOpen, setIsOpen] = useState(false);

    let formattedValue;
    if (isRange && !isMultiRange) {
        formattedValue = value?.begin && value.end ? `${dateManager.convertToDisplayableDate(value.begin) || ''} → ${dateManager.convertToDisplayableDate(value.end) || ''}` : '';
    } else if (isMultiRange) {
        formattedValue = '';
    } else {
        formattedValue = dateManager.convertToDisplayableDate(value);
    }

    const removeOption = (valueToRemove) => {
        const updatedList = value?.filter((e) => e !== valueToRemove);

        onChange({ target: { value: updatedList, label } });
    };

    return (
        <>
            {!isReadOnlyWithoutInputs && !isMultiRange && (
                <input
                    id={id}
                    type="text"
                    value={formattedValue}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={isDisabled ? '-1' : '0'}
                    onClick={() => setIsOpen((prev) => !prev)}
                    autoComplete="off"
                    onChange={() => {
                        // Default
                    }}
                />
            )}
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {isReadOnlyWithoutInputs && <span id={id}>{formattedValue}</span>}
            {isOpen && (
                <CalendarDate
                    label={getMessage(label)}
                    isInline={isInline}
                    isRange={isRange || isMultiRange}
                    onClose={(newValue) => {
                        if (newValue) {
                            let val;
                            if (isMultiRange) {
                                val = value ? [...value, newValue] : [newValue];
                            } else {
                                val = newValue;
                            }

                            onChange({ target: { value: val, label } });
                        }
                        setIsOpen(false);
                    }}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    labelFrom={labelFrom}
                    labelTo={labelTo}
                    minRangeLength={minRangeLength}
                    dateManager={dateManager}
                />
            )}
            {!isReadOnlyWithoutInputs && isMultiRange && (
                <>
                    <input
                        id={id}
                        type="text"
                        value={formattedValue}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        tabIndex={isDisabled ? '-1' : '0'}
                        onClick={() => setIsOpen((prev) => !prev)}
                        autoComplete="off"
                        onChange={() => {
                            // Default
                        }}
                    />
                    {!!value?.length && (
                        <Chips
                            outlined
                            list={value
                                .sort((first, second) => {
                                    if (first.begin < second.begin) {
                                        return -1;
                                    }

                                    if (first.begin > second.begin) {
                                        return 1;
                                    }

                                    return 0;
                                })
                                .map((opt, index) => ({
                                    onClose : () => removeOption(opt),
                                    text    : `${dateManager.convertToDisplayableDate(opt.begin) || ''} → ${dateManager.convertToDisplayableDate(opt.end) || ''}`,
                                    key     : index,
                                }))}
                        />
                    )}
                </>
            )}
        </>
    );
}

Picker.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : null,
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange       : null,
    isDisabled     : false,
    isInline       : false,
    isRange        : false,
    isMultiRange   : false,
    labelFrom      : null,
    labelTo        : null,
    minRangeLength : 0,
};

Picker.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.shape({}),
    isInline                : PropTypes.bool,
    isRange                 : PropTypes.bool,
    isMultiRange            : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    minDate                 : PropTypes.string.isRequired,
    maxDate                 : PropTypes.string.isRequired,
    labelFrom               : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelTo                 : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    minRangeLength          : PropTypes.number,
};

export default React.memo(Picker);
