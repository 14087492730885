module.exports = Object.freeze({
    primaryOwner            : 'apf.owners.primaryOwner',
    secondaryOwner          : 'apf.owners.secondaryOwner',
    membership              : 'apf.owners.membership',
    club                    : 'apf.owners.club',
    tier                    : 'apf.owners.tier',
    contractType            : 'apf.owners.contractType',
    contractTypeStartDate   : 'apf.owners.contractTypeStartDate',
    contractAnniversaryDate : 'apf.owners.contractAnniversaryDate',
    contractTypeEndDate     : 'apf.owners.contractTypeEndDate',
    view                    : 'apf.owners.view',
    importActionTooltip     : 'apf.owners.importActionTooltip',
    importAction            : 'apf.owners.importAction',
    viewDetails             : 'apf.owners.viewDetails',
    id                      : 'apf.owners.id',
    base                    : 'apf.owners.baseContract',
    contractId              : 'apf.owners.contractId',
    legacyId                : 'apf.owners.legacyId',
    internalUse             : 'apf.owners.internalUse',
    contractLegacyId        : 'apf.owners.contractLegacyId',
    bookings                : 'apf.owners.bookings',
    cantBook                : 'apf.owners.cantBook',
    noOwners                : 'apf.owners.noOwners',
    salutation              : 'apf.owners.salutation',
    activateAccount         : {
        tooltip : 'apf.owners.activateAccount.tooltip',
        confirm : 'apf.owners.activateAccount.confirm',
        action  : 'apf.owners.activateAccount.action',
        success : 'apf.owners.activateAccount.success',
        error   : 'apf.owners.activateAccount.error',
    },
    personalInfo                        : 'apf.owners.personalInfo',
    bankingConfigurationDisabledWarning : 'apf.owners.bankingConfigurationDisabledWarning',
    contractTypeFilterEmpty             : 'apf.owners.contractTypeFilterEmpty',
    address1                            : 'apf.owners.address1',
    address2                            : 'apf.owners.address2',
    zipCode                             : 'apf.owners.zipCode',
    city                                : 'apf.owners.city',
    state                               : 'apf.owners.state',
    country                             : 'apf.owners.country',
    accountCard                         : 'apf.owners.accountCard',
    bookDropdown                        : { title: 'apf.owners.bookDropdown.title' },
    accountInformation                  : 'apf.owners.accountInformation',
    primaryOwnerInformation             : 'apf.owners.primaryOwnerInformation',
    secondaryOwnerInformation           : 'apf.owners.secondaryOwnerInformation',
    listActions                         : { book: 'apf.owners.listActions.book', bookDisabled: 'apf.owners.listActions.bookDisabled' },
    profile                             : { success: 'apf.owners.profile.success', error: 'apf.owners.profile.error' },
    update                              : {
        primary   : { success: 'apf.owners.update.primary.success', error: 'apf.owners.update.primary.error' },
        secondary : {
            success : 'apf.owners.update.secondary.success',
            added   : 'apf.owners.update.secondary.added',
            error   : 'apf.owners.update.secondary.error',
        },
    },
    referredReferral : 'apf.owners.referredReferral',
    referred         : { title: 'apf.owners.referred.title', emptyList: 'apf.owners.referred.emptyList' },
    referral         : {
        title : 'apf.owners.referral.title',
        empty : 'apf.owners.referral.empty',
        edit  : {
            noUser      : 'apf.owners.referral.edit.noUser',
            searchEmpty : 'apf.owners.referral.edit.searchEmpty',
            saveSuccess : 'apf.owners.referral.edit.saveSuccess',
            errors      : { save: 'apf.owners.referral.edit.errors.save', userSelfReferred: 'apf.owners.referral.edit.errors.userSelfReferred' },
        },
        remove: {
            confirmationTitle   : 'apf.owners.referral.remove.confirmationTitle',
            confirmationMessage : 'apf.owners.referral.remove.confirmationMessage',
            success             : 'apf.owners.referral.remove.success',
            error               : 'apf.owners.referral.remove.error',
        },
    },
    extraAttributes: {
        contractTitle    : 'apf.owners.extraAttributes.contractTitle',
        accountTitle     : 'apf.owners.extraAttributes.accountTitle',
        primaryTitle     : 'apf.owners.extraAttributes.primaryTitle',
        secondaryTitle   : 'apf.owners.extraAttributes.secondaryTitle',
        basicInformation : 'apf.owners.extraAttributes.basicInformation',
        extraInformation : 'apf.owners.extraAttributes.extraInformation',
        basic            : {
            club        : 'apf.owners.extraAttributes.basic.club',
            tier        : 'apf.owners.extraAttributes.basic.tier',
            email       : 'apf.owners.extraAttributes.basic.email',
            phone       : 'apf.owners.extraAttributes.basic.phone',
            mobile      : 'apf.owners.extraAttributes.basic.mobile',
            dateOfBirth : 'apf.owners.extraAttributes.basic.dateOfBirth',
            address     : 'apf.owners.extraAttributes.basic.address',
            zipCode     : 'apf.owners.extraAttributes.basic.zipCode',
            city        : 'apf.owners.extraAttributes.basic.city',
            state       : 'apf.owners.extraAttributes.basic.state',
            country     : 'apf.owners.extraAttributes.basic.country',
            language    : 'apf.owners.extraAttributes.basic.language',
            loginToken  : 'apf.owners.extraAttributes.basic.loginToken',
        },
    },
    balance: {
        title      : 'apf.owners.balance.title',
        buttons    : { show: 'apf.owners.balance.buttons.show', hide: 'apf.owners.balance.buttons.hide' },
        clubPoints : {
            title           : 'apf.owners.balance.clubPoints.title',
            empty           : 'apf.owners.balance.clubPoints.empty',
            pointsType      : 'apf.owners.balance.clubPoints.pointsType',
            date            : 'apf.owners.balance.clubPoints.date',
            expiryDate      : 'apf.owners.balance.clubPoints.expiryDate',
            leftShoulder    : 'apf.owners.balance.clubPoints.leftShoulder',
            rightShoulder   : 'apf.owners.balance.clubPoints.rightShoulder',
            contract        : 'apf.owners.balance.clubPoints.contract',
            currency        : 'apf.owners.balance.clubPoints.currency',
            points          : 'apf.owners.balance.clubPoints.points',
            balance         : 'apf.owners.balance.clubPoints.balance',
            total           : 'apf.owners.balance.clubPoints.total',
            partnersSummary : 'apf.owners.balance.clubPoints.partnersSummary',
            balanceType     : 'apf.owners.balance.clubPoints.balanceType',
        },
        balanceTotals: {
            title    : 'apf.owners.balance.balanceTotals.title',
            currency : 'apf.owners.balance.balanceTotals.currency',
            amount   : 'apf.owners.balance.balanceTotals.amount',
            balance  : 'apf.owners.balance.balanceTotals.balance',
            level    : 'apf.owners.balance.balanceTotals.level',
        },
        transactions: {
            title  : 'apf.owners.balance.transactions.title',
            info   : 'apf.owners.balance.transactions.info',
            editor : {
                title         : 'apf.owners.balance.transactions.editor.title',
                indicate      : 'apf.owners.balance.transactions.editor.indicate',
                creation      : 'apf.owners.balance.transactions.editor.creation',
                cantEditDates : 'apf.owners.balance.transactions.editor.cantEditDates',
            },
            empty       : 'apf.owners.balance.transactions.empty',
            date        : 'apf.owners.balance.transactions.date',
            name        : 'apf.owners.balance.transactions.name',
            reason      : 'apf.owners.balance.transactions.reason',
            currency    : 'apf.owners.balance.transactions.currency',
            points      : 'apf.owners.balance.transactions.points',
            balance     : 'apf.owners.balance.transactions.balance',
            contract    : 'apf.owners.balance.transactions.contract',
            loadError   : 'apf.owners.balance.transactions.loadError',
            deleteError : 'apf.owners.balance.transactions.deleteError',
            code        : 'apf.owners.balance.transactions.code',
            cancel      : {
                confirmationMessage : 'apf.owners.balance.transactions.cancel.confirmationMessage',
                success             : 'apf.owners.balance.transactions.cancel.success',
                error               : 'apf.owners.balance.transactions.cancel.error',
                errors              : { transactionCantBeErased: 'apf.owners.balance.transactions.cancel.errors.transactionCantBeErased' },
            },
        },
        editor: {
            title           : 'apf.owners.balance.editor.title',
            previewTitle    : 'apf.owners.balance.editor.previewTitle',
            previewBefore   : 'apf.owners.balance.editor.previewBefore',
            previewExplains : 'apf.owners.balance.editor.previewExplains',
            indicate        : 'apf.owners.balance.editor.indicate',
            emptySummary    : 'apf.owners.balance.editor.emptySummary',
            acceptOrRefuse  : 'apf.owners.balance.editor.acceptOrRefuse',
            cancelWarning   : 'apf.owners.balance.editor.cancelWarning',
            balanceUpdated  : 'apf.owners.balance.editor.balanceUpdated',
            errors          : {
                loadingPreview                 : 'apf.owners.balance.editor.errors.loadingPreview',
                datesAreNotEditable            : 'apf.owners.balance.editor.errors.datesAreNotEditable',
                anniversaryDatesAreNotEditable : 'apf.owners.balance.editor.errors.anniversaryDatesAreNotEditable',
                shouldEnterSomeValue           : 'apf.owners.balance.editor.errors.shouldEnterSomeValue',
                defaultError                   : 'apf.owners.balance.editor.errors.defaultError',
                insufficientBalanceFounds      : 'apf.owners.balance.editor.errors.insufficientBalanceFounds',
            },
        },
        availableBalance               : 'apf.owners.balance.availableBalance',
        balanceHelper                  : 'apf.owners.balance.helper',
        balanceAdd                     : 'apf.owners.balance.add',
        balanceSub                     : 'apf.owners.balance.sub',
        amount                         : 'apf.owners.balance.amount',
        reason                         : 'apf.owners.balance.reason',
        errorGettingPoints             : 'apf.owners.balance.errorGettingPoints',
        errorNotConversionOrAllocation : 'apf.owners.balance.errorNotConversionOrAllocation',
        gettingData                    : 'apf.owners.balance.gettingData',
        selectPeriod                   : 'apf.owners.balance.selectPeriod',
        errorGettingData               : 'apf.owners.balance.errorGettingData',
        noData                         : 'apf.owners.balance.noData',
        conversionInfo                 : 'apf.owners.balance.conversionInfo',
        allocationInfo                 : 'apf.owners.balance.allocationInfo',
        transferInfo                   : 'apf.owners.balance.transferInfo',
        chargeInfo                     : 'apf.owners.balance.chargeInfo',
        refundInfo                     : 'apf.owners.balance.refundInfo',
        vacationClub                   : 'apf.owners.balance.vacationClub',
        contract                       : 'apf.owners.balance.contract',
        balanceType                    : 'apf.owners.balance.balanceType',
        currency                       : 'apf.owners.balance.currency',
        available                      : 'apf.owners.balance.available',
        availablePeriods               : 'apf.owners.balance.availablePeriods',
        balanceDestination             : 'apf.owners.balance.balanceDestination',
        effective                      : 'apf.owners.balance.effective',
        expiry                         : 'apf.owners.balance.expiry',
        from                           : 'apf.owners.balance.from',
        to                             : 'apf.owners.balance.to',
        dates                          : 'apf.owners.balance.dates',
        newPeriodShoulders             : 'apf.owners.balance.newPeriodShoulders',
        shoulderLeft                   : 'apf.owners.balance.shoulderLeft',
        shoulderRight                  : 'apf.owners.balance.shoulderRight',
        newPeriod                      : 'apf.owners.balance.newPeriod',
        periodDates                    : 'apf.owners.balance.periodDates',
        duration                       : 'apf.owners.balance.duration',
        year                           : 'apf.owners.balance.year',
        walletOperations               : 'apf.owners.balance.walletOperations',
        pointsOperations               : 'apf.owners.balance.pointsOperations',
        pointsOperationsButton         : 'apf.owners.balance.pointsOperationsButton',
        downloadAccountStatements      : 'apf.owners.balance.downloadAccountStatements',
        addMissingBalance              : 'apf.owners.balance.addMissingBalance',
        allocation                     : 'apf.owners.balance.allocation',
        conversion                     : 'apf.owners.balance.conversion',
        transfer                       : 'apf.owners.balance.transfer',
        allocate                       : 'apf.owners.balance.allocate',
        convert                        : 'apf.owners.balance.convert',
        transact                       : 'apf.owners.balance.transact',
        refund                         : 'apf.owners.balance.refund',
        charge                         : 'apf.owners.balance.charge',
        selectedOperation              : 'apf.owners.balance.selectedOperation',
        convertedPoints                : 'apf.owners.balance.convertedPoints',
        availablePoints                : 'apf.owners.balance.availablePoints',
        availableNights                : 'apf.owners.balance.availableNights',
        suffixPoints                   : 'apf.owners.balance.suffixPoints',
        suffixNights                   : 'apf.owners.balance.suffixNights',
        name                           : 'apf.owners.balance.name',
        rate                           : 'apf.owners.balance.rate',
        minimumPoints                  : 'apf.owners.balance.minimumPoints',
        remainingPoints                : 'apf.owners.balance.remainingPoints',
        remainingInPeriod              : 'apf.owners.balance.remainingInPeriod',
        conversionPreference           : {
            title              : 'apf.owners.balance.conversionPreference.title',
            default            : 'apf.owners.balance.conversionPreference.default',
            defaultSupport     : 'apf.owners.balance.conversionPreference.defaultSupport',
            clubPoints         : 'apf.owners.balance.conversionPreference.clubPoints',
            clubPointsSupport  : 'apf.owners.balance.conversionPreference.clubPointsSupport',
            bonusPoints        : 'apf.owners.balance.conversionPreference.bonusPoints',
            bonusPointsSupport : 'apf.owners.balance.conversionPreference.bonusPointsSupport',
            summary            : {
                title             : 'apf.owners.balance.conversionPreference.summary.title',
                loading           : 'apf.owners.balance.conversionPreference.summary.loading',
                error             : 'apf.owners.balance.conversionPreference.summary.error',
                total             : 'apf.owners.balance.conversionPreference.summary.total',
                clubPoints        : 'apf.owners.balance.conversionPreference.summary.clubPoints',
                transactionPoints : 'apf.owners.balance.conversionPreference.summary.transactionPoints',
                bonusPoints       : 'apf.owners.balance.conversionPreference.summary.bonusPoints',
                fee               : 'apf.owners.balance.conversionPreference.summary.fee',
            },
        },
        balanceTypeInitError : 'apf.owners.balance.balanceTypeInitError',
        cantSaveConversion   : 'apf.owners.balance.cantSaveConversion',
        conversionInitError  : 'apf.owners.balance.conversionInitError',
        conversionSaved      : 'apf.owners.balance.conversionSaved',
        chargeSaved          : 'apf.owners.balance.chargeSaved',
        balanceUnpaid        : 'apf.owners.balance.balanceUnpaid',
    },
    relationships: {
        relationship          : 'apf.owners.relationships.relationship',
        alertNonRelated       : 'apf.owners.relationships.alertNonRelated',
        addRelationship       : 'apf.owners.relationships.addRelationship',
        editRelationship      : 'apf.owners.relationships.editRelationship',
        removeRelationship    : 'apf.owners.relationships.removeRelationship',
        removeUser            : 'apf.owners.relationships.removeUser',
        relationshipConnector : 'apf.owners.relationships.relationshipConnector',
        none                  : 'apf.owners.relationships.none',
        multipleRelationships : 'apf.owners.relationships.multipleRelationships',
        errorCreating         : 'apf.owners.relationships.errorCreating',
        errorUpdating         : 'apf.owners.relationships.errorUpdating',
        errorCreatingUser     : 'apf.owners.relationships.errorCreatingUser',
        errorUpdatingUser     : 'apf.owners.relationships.errorUpdatingUser',
        userAdded             : 'apf.owners.relationships.userAdded',
        userUpdated           : 'apf.owners.relationships.userUpdated',
        errors                : { entityAlreadyExists: 'apf.owners.relationships.errors.entityAlreadyExists' },
        success               : {
            add    : 'apf.owners.relationships.success.add',
            update : 'apf.owners.relationships.success.update',
        },
        delete: {
            error         : 'apf.owners.relationships.delete.error',
            confirm       : 'apf.owners.relationships.delete.confirm',
            deleted       : 'apf.owners.relationships.delete.deleted',
            acceptMessage : 'apf.owners.relationships.delete.acceptMessage',
            userDeleted   : 'apf.owners.relationships.delete.userDeleted',
            errorOnUser   : 'apf.owners.relationships.delete.errorOnUser',
            userConfirm   : 'apf.owners.relationships.delete.userConfirm',
        },
        emptyList         : 'apf.owners.relationships.emptyList',
        relationshipModal : {
            userSearch        : 'apf.owners.relationships.relationshipModal.userSearch',
            notFound          : 'apf.owners.relationships.relationshipModal.notFound',
            searchError       : 'apf.owners.relationships.relationshipModal.searchError',
            summary           : 'apf.owners.relationships.relationshipModal.summary',
            summaryConnector  : 'apf.owners.relationships.relationshipModal.summaryConnector',
            summaryIncomplete : 'apf.owners.relationships.relationshipModal.summaryIncomplete',
        },
        phone     : 'apf.owners.relationships.phone',
        mobile    : 'apf.owners.relationships.mobile',
        firstName : 'apf.owners.relationships.firstName',
        lastName  : 'apf.owners.relationships.lastName',
        email     : 'apf.owners.relationships.email',
        birthday  : 'apf.owners.relationships.birthday',
        language  : 'apf.owners.relationships.language',
        types     : {
            father           : 'apf.owners.relationships.types.father',
            mother           : 'apf.owners.relationships.types.mother',
            son              : 'apf.owners.relationships.types.son',
            daughter         : 'apf.owners.relationships.types.daughter',
            brother          : 'apf.owners.relationships.types.brother',
            sister           : 'apf.owners.relationships.types.sister',
            uncle            : 'apf.owners.relationships.types.uncle',
            aunt             : 'apf.owners.relationships.types.aunt',
            nephew           : 'apf.owners.relationships.types.nephew',
            niece            : 'apf.owners.relationships.types.niece',
            grandmother      : 'apf.owners.relationships.types.grandmother',
            grandfather      : 'apf.owners.relationships.types.grandfather',
            grandson         : 'apf.owners.relationships.types.grandson',
            granddaughter    : 'apf.owners.relationships.types.granddaughter',
            fatherinlaw      : 'apf.owners.relationships.types.fatherinlaw',
            motherinlaw      : 'apf.owners.relationships.types.motherinlaw',
            soninlaw         : 'apf.owners.relationships.types.soninlaw',
            daughterinlaw    : 'apf.owners.relationships.types.daughterinlaw',
            brotherinlaw     : 'apf.owners.relationships.types.brotherinlaw',
            sisterinlaw      : 'apf.owners.relationships.types.sisterinlaw',
            cousin           : 'apf.owners.relationships.types.cousin',
            friend           : 'apf.owners.relationships.types.friend',
            boss             : 'apf.owners.relationships.types.boss',
            privateassistant : 'apf.owners.relationships.types.privateassistant',
            colleague        : 'apf.owners.relationships.types.colleague',
            husband          : 'apf.owners.relationships.types.husband',
            wife             : 'apf.owners.relationships.types.wife',
        },
    },
    changeStatus: {
        enable: {
            confirmationTitle   : 'apf.owners.changeStatus.enable.confirmationTitle',
            confirmationMessage : 'apf.owners.changeStatus.enable.confirmationMessage',
            success             : 'apf.owners.changeStatus.enable.success',
            error               : 'apf.owners.changeStatus.enable.error',
        },
        disable: {
            confirmationTitle   : 'apf.owners.changeStatus.disable.confirmationTitle',
            confirmationMessage : 'apf.owners.changeStatus.disable.confirmationMessage',
            success             : 'apf.owners.changeStatus.disable.success',
            error               : 'apf.owners.changeStatus.disable.error',
        },
    },
    edition : { clubWithNoTiers: 'apf.owners.edition.clubWithNoTiers', tierWithNoContractType: 'apf.owners.edition.tierWithNoContractType' },
    info    : {
        confirmationActivationEmail    : 'apf.owners.info.confirmationActivationEmail',
        errorSendingEmail              : 'apf.owners.info.errorSendingEmail',
        emailSuccessfullySent          : 'apf.owners.info.emailSuccessfullySent',
        sendSecondaryOwnerInvitation   : 'apf.owners.info.sendSecondaryOwnerInvitation',
        resendEmailButton              : 'apf.owners.info.resendEmailButton',
        noContract                     : 'apf.owners.info.noContract',
        confirmationDissociateSecOwner : 'apf.owners.info.confirmationDissociateSecOwner',
        secondaryOwnerDissociated      : 'apf.owners.info.secondaryOwnerDissociated',
        secondaryOwnerDissociatedError : 'apf.owners.info.secondaryOwnerDissociatedError',
        contractTitle                  : 'apf.owners.info.contractTitle',
        contractUpdateWarning          : 'apf.owners.info.contractUpdateWarning',
        contractUpdated                : 'apf.owners.info.contractUpdated',
        contractUpdateError            : 'apf.owners.info.contractUpdateError',
        sendingEmail                   : 'apf.owners.info.sendingEmail',
    },
    errors: {
        entityAlreadyExists : 'apf.owners.errors.entityAlreadyExists',
        channelIdRequired   : 'apf.owners.errors.channelIdRequired',
        failedDependency    : 'apf.owners.errors.failedDependency',
        sameToken           : 'apf.owners.errors.sameToken',
    },
    usersErrors     : { entityAlreadyExists: 'apf.owners.usersErrors.entityAlreadyExists' },
    financialStatus : {
        button               : 'apf.owners.financialStatus.button',
        title                : 'apf.owners.financialStatus.title',
        error                : 'apf.owners.financialStatus.error',
        currentYear          : 'apf.owners.financialStatus.currentYear',
        nextYear             : 'apf.owners.financialStatus.nextYear',
        fees                 : 'apf.owners.financialStatus.fees',
        loans                : 'apf.owners.financialStatus.loans',
        noReservationAllowed : 'apf.owners.financialStatus.reservationAllowed',
    },
    exchange         : 'apf.owners.exchange',
    pointStatement   : 'apf.owners.pointStatement',
    accountStatement : 'apf.owners.accountStatement',
    attachments      : {
        title             : 'apf.owners.attachments.title',
        emptyList         : 'apf.owners.attachments.emptyList',
        name              : 'apf.owners.attachments.name',
        creationDate      : 'apf.owners.attachments.creationDate',
        fileTitle         : 'apf.owners.attachments.fileTitle',
        fileHelper        : 'apf.owners.attachments.fileHelper',
        category          : 'apf.owners.attachments.category',
        categoryChild     : 'apf.owners.attachments.categoryChild',
        noCategory        : 'apf.owners.attachments.noCategory',
        subCategory       : 'apf.owners.attachments.subCategory',
        extension         : 'apf.owners.attachments.extension',
        invalidFileName   : 'apf.owners.attachments.invalidFileName',
        invalidFileFormat : 'apf.owners.attachments.invalidFileFormat',
        invalidFileSize   : 'apf.owners.attachments.invalidFileSize',
        edition           : {
            title   : 'apf.owners.attachments.edition.title',
            success : 'apf.owners.attachments.edition.success',
            errors  : { default: 'apf.owners.attachments.edition.errors.default' },
        },
        save: {
            title   : 'apf.owners.attachments.save.title',
            success : 'apf.owners.attachments.save.success',
            errors  : { default: 'apf.owners.attachments.save.errors.default' },
        },
        delete: {
            confirmation : 'apf.owners.attachments.delete.confirmation',
            success      : 'apf.owners.attachments.delete.success',
            error        : 'apf.owners.attachments.delete.error',
        },
    },
    walletAddBalance: {
        title     : 'apf.owners.walletAddBalance.title',
        typeLabel : 'apf.owners.walletAddBalance.typeLabel',
        warning   : 'apf.owners.walletAddBalance.warning',
        types     : {
            prev    : 'apf.owners.walletAddBalance.types.prev',
            current : 'apf.owners.walletAddBalance.types.current',
            next    : 'apf.owners.walletAddBalance.types.next',
        },
        errors: { default: 'apf.owners.walletAddBalance.errors.default' },
    },
    contracts: {
        title             : 'apf.owners.contracts.title',
        status            : 'apf.owners.contracts.status',
        empty             : 'apf.owners.contracts.empty',
        warning           : 'apf.owners.contracts.warning',
        contractColumn    : 'apf.owners.contracts.contractColumn',
        club              : 'apf.owners.contracts.club',
        tier              : 'apf.owners.contracts.tier',
        consumptionMode   : 'apf.owners.contracts.consumptionMode',
        creation          : 'apf.owners.contracts.creation',
        start             : 'apf.owners.contracts.start',
        end               : 'apf.owners.contracts.end',
        contractType      : 'apf.owners.contracts.contractType',
        startDate         : 'apf.owners.contracts.startDate',
        endDate           : 'apf.owners.contracts.endDate',
        currency          : 'apf.owners.contracts.currency',
        ownerId           : 'apf.owners.contracts.ownerId',
        legacyId          : 'apf.owners.contracts.legacyId',
        contractId        : 'apf.owners.contracts.contractId',
        primaryCurrency   : 'apf.owners.contracts.primaryCurrency',
        secondaryCurrency : 'apf.owners.contracts.secondaryCurrency',
        delta             : 'apf.owners.contracts.delta',
        actions           : { suspend: 'apf.owners.contracts.actions.suspend', activate: 'apf.owners.contracts.actions.activate' },
        statusChange      : { success: 'apf.owners.contracts.statusChange.success' },
        upgrade           : 'apf.owners.contracts.upgrade',
        details           : 'apf.owners.contracts.details',
        featured          : {
            label     : 'apf.owners.contracts.featured.label',
            highlight : 'apf.owners.contracts.featured.highlight',
            disable   : 'apf.owners.contracts.featured.disable',
        },
        wallet: {
            title      : 'apf.owners.contracts.wallet.title',
            empty      : 'apf.owners.contracts.wallet.empty',
            newBalance : 'apf.owners.contracts.wallet.newBalance',
            operation  : 'apf.owners.contracts.wallet.operation',
        },
        history : { title: 'apf.owners.contracts.history.title', empty: 'apf.owners.contracts.history.empty' },
        errors  : { contractLoad: 'apf.owners.contracts.errors.contractLoad' },
        edition : {
            title                              : 'apf.owners.contracts.edition.title',
            titleUpgrade                       : 'apf.owners.contracts.edition.titleUpgrade',
            configurationTitle                 : 'apf.owners.contracts.edition.configurationTitle',
            requiredTier                       : 'apf.owners.contracts.edition.requiredTier',
            requiredContractType               : 'apf.owners.contracts.edition.requiredContractType',
            saveError                          : 'apf.owners.contracts.edition.saveError',
            clubSearchError                    : 'apf.owners.contracts.edition.clubSearchError',
            tierSearchError                    : 'apf.owners.contracts.edition.tierSearchError',
            contractTypeSearchError            : 'apf.owners.contracts.edition.contractTypeSearchError',
            clubWithNoTiers                    : 'apf.owners.contracts.edition.clubWithNoTiers',
            tierWithNoContractTypes            : 'apf.owners.contracts.edition.tierWithNoContractTypes',
            balanceTypesError                  : 'apf.owners.contracts.edition.balanceTypesError',
            bookingConfigurationError          : 'apf.owners.contracts.edition.bookingConfigurationError',
            bankingConfigurationMissingFields  : 'apf.owners.contracts.edition.bankingConfigurationMissingFields',
            bankingConfigurationDateValidation : 'apf.owners.contracts.edition.bankingConfigurationDateValidation',
            disabledWarning                    : 'apf.owners.contracts.edition.disabledWarning',
            bankingConfiguration               : {
                title              : 'apf.owners.contracts.edition.bankingConfiguration.title',
                empty              : 'apf.owners.contracts.edition.bankingConfiguration.empty',
                deleteConfirmation : 'apf.owners.contracts.edition.bankingConfiguration.deleteConfirmation',
                generate           : {
                    title               : 'apf.owners.contracts.edition.bankingConfiguration.generate.title',
                    modalTitle          : 'apf.owners.contracts.edition.bankingConfiguration.generate.modalTitle',
                    button              : 'apf.owners.contracts.edition.bankingConfiguration.generate.button',
                    message1            : 'apf.owners.contracts.edition.bankingConfiguration.generate.message1',
                    message2            : 'apf.owners.contracts.edition.bankingConfiguration.generate.message2',
                    confirmationMessage : 'apf.owners.contracts.edition.bankingConfiguration.generate.confirmationMessage',
                    errors              : { default: 'apf.owners.contracts.edition.bankingConfiguration.generate.errors.default' },
                },
                editTitle   : 'apf.owners.contracts.edition.bankingConfiguration.editTitle',
                createTitle : 'apf.owners.contracts.edition.bankingConfiguration.createTitle',
                create      : {
                    success : 'apf.owners.contracts.edition.bankingConfiguration.create.success',
                    error   : 'apf.owners.contracts.edition.bankingConfiguration.create.error',
                },
                edit: {
                    success : 'apf.owners.contracts.edition.bankingConfiguration.edit.success',
                    error   : 'apf.owners.contracts.edition.bankingConfiguration.edit.error',
                },
                delete: {
                    success : 'apf.owners.contracts.edition.bankingConfiguration.delete.success',
                    error   : 'apf.owners.contracts.edition.bankingConfiguration.delete.error',
                },
                initError : 'apf.owners.contracts.edition.bankingConfiguration.initError',
                history   : {
                    title          : 'apf.owners.contracts.edition.bankingConfiguration.history.title',
                    empty          : 'apf.owners.contracts.edition.bankingConfiguration.history.empty',
                    restoreSuccess : 'apf.owners.contracts.edition.bankingConfiguration.history.restoreSuccess',
                    restoreError   : 'apf.owners.contracts.edition.bankingConfiguration.history.restoreError',
                    initError      : 'apf.owners.contracts.edition.bankingConfiguration.history.initError',
                },
                isActive                           : 'apf.owners.contracts.edition.bankingConfiguration.isActive',
                balanceType                        : 'apf.owners.contracts.edition.bankingConfiguration.balanceType',
                applyOn                            : 'apf.owners.contracts.edition.bankingConfiguration.applyOn',
                applyGlobal                        : 'apf.owners.contracts.edition.bankingConfiguration.applyGlobal',
                applyContract                      : 'apf.owners.contracts.edition.bankingConfiguration.applyContract',
                isRepeated                         : 'apf.owners.contracts.edition.bankingConfiguration.isRepeated',
                effectiveDate                      : 'apf.owners.contracts.edition.bankingConfiguration.effectiveDate',
                expiryDate                         : 'apf.owners.contracts.edition.bankingConfiguration.expiryDate',
                repeatDelta                        : 'apf.owners.contracts.edition.bankingConfiguration.repeatDelta',
                repeatAnniversary                  : 'apf.owners.contracts.edition.bankingConfiguration.repeatAnniversary',
                amount                             : 'apf.owners.contracts.edition.bankingConfiguration.amount',
                shoulderLeft                       : 'apf.owners.contracts.edition.bankingConfiguration.shoulderLeft',
                shoulderRight                      : 'apf.owners.contracts.edition.bankingConfiguration.shoulderRight',
                editInvalidTooltip                 : 'apf.owners.contracts.edition.bankingConfiguration.editInvalidTooltip',
                invalidBankingConfigurationWarning : 'apf.owners.contracts.edition.bankingConfiguration.invalidBankingConfigurationWarning',
            },
            extraAttributes: {
                success : 'apf.owners.contracts.edition.extraAttributes.success',
                error   : 'apf.owners.contracts.edition.extraAttributes.error',
            },
            errors: { contractLegacyIdInUse: 'apf.owners.contracts.edition.errors.contractLegacyIdInUse' },
        },
    },
    users: {
        title             : 'apf.owners.users.title',
        emptyList         : 'apf.owners.users.emptyList',
        name              : 'apf.owners.users.name',
        creationDate      : 'apf.owners.users.creationDate',
        fileTitle         : 'apf.owners.users.fileTitle',
        fileHelper        : 'apf.owners.users.fileHelper',
        category          : 'apf.owners.users.category',
        categoryChild     : 'apf.owners.users.categoryChild',
        noCategory        : 'apf.owners.users.noCategory',
        subCategory       : 'apf.owners.users.subCategory',
        extension         : 'apf.owners.users.extension',
        invalidFileName   : 'apf.owners.users.invalidFileName',
        invalidFileFormat : 'apf.owners.users.invalidFileFormat',
        invalidFileSize   : 'apf.owners.users.invalidFileSize',
        edition           : {
            title   : 'apf.owners.users.edition.title',
            success : 'apf.owners.users.edition.success',
            errors  : { default: 'apf.owners.users.edition.errors.default' },
        },
        save: {
            title   : 'apf.owners.users.save.title',
            success : 'apf.owners.users.save.success',
            errors  : { default: 'apf.owners.users.save.errors.default' },
        },
        delete: {
            confirmation : 'apf.owners.users.delete.confirmation',
            success      : 'apf.owners.users.delete.success',
            error        : 'apf.owners.users.delete.error',
        },
        activateAccount: {
            tooltip             : 'apf.owners.activateAccount.tooltip',
            confirm             : 'apf.owners.activateAccount.confirm',
            action              : 'apf.owners.activateAccount.action',
            confirmationTitle   : 'apf.owners.changeStatus.enable.confirmationTitle',
            confirmationMessage : 'apf.owners.changeStatus.enable.confirmationMessage',
            success             : 'apf.owners.users.activateAccount.enable.success',
            error               : 'apf.owners.users.activateAccount.enable.error',
        },
    },
    notes: {
        title              : 'apf.owners.notes.title',
        id                 : 'apf.owners.notes.id',
        new                : 'apf.owners.notes.new',
        category           : 'apf.owners.notes.category',
        name               : 'apf.owners.notes.name',
        text               : 'apf.owners.notes.text',
        creator            : 'apf.owners.notes.creator',
        creationDate       : 'apf.owners.notes.creationDate',
        details            : 'apf.owners.notes.details',
        deleteConfirmation : 'apf.owners.notes.deleteConfirmation',
        empty              : 'apf.owners.notes.empty',
        success            : { new: 'apf.owners.notes.success.new', delete: 'apf.owners.notes.success.delete', update: 'apf.owners.notes.success.update' },
        error              : { add: 'apf.owners.notes.error.add', delete: 'apf.owners.notes.error.delete' },
    },
    imports: {
        filename     : 'apf.owners.imports.filename',
        upload       : 'apf.owners.imports.upload',
        row          : 'apf.owners.imports.row',
        creation     : 'apf.owners.imports.creation',
        modification : 'apf.owners.imports.modification',
        title        : 'apf.owners.imports.title',
        template     : 'apf.owners.imports.template',
        help         : 'apf.owners.imports.help',
        download     : 'apf.owners.imports.download',
        pdf          : 'apf.owners.imports.pdf',
        csv          : 'apf.owners.imports.csv',
        csvFormat    : 'apf.owners.imports.csvFormat',
    },
});
