import React, {
    forwardRef, useState, useEffect, useRef, useContext, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { getClassName, transformToSnake } from 'Utils';
import Checkbox from './CheckboxNew';
import Chip from './Chip';
import Date from './Date';
import Number from './NumberNew';
import Radio from './Radio';
import Select from './SelectNew';
import SelectWithFilter from './SelectWithFilterNew';
import Slider from './Slider';
import Switch from './SwitchNew';
import Text from './TextNew';
import Split from './Split';
import Textarea from './Textarea';
import WrapperNew from './WrapperNew';
import Quantity from './Quantity';
import Password from './Password';
import PickerNew from './PickerNew';
import Search from './Search';
import Upload from './Upload';
import InputWithChip from './InputWithChip';
import ChipPicker from './ChipPicker';
import Color from './Color';
import Code from './Code';
import ImageGallery from './ImageGallery';
import FormContext from '../formContext';
// eslint-disable-next-line import/no-cycle
import DynamicInputWrapper from 'Components/Form/DynamicInputWrapper';
import { useFormContext, get } from 'react-hook-form';

function getInputClass(
    isDisabled,
    isDense,
    isSelected,
    isSelectable,
    isValid,
    isCompleted,
    isFocused,
    formIcon,
    hasPrefix,
    hasSuffix,
    type,
    hasChanged,
    isReadOnlyWithoutInputs,
    isMultiSelect,
    displayOptionsAmount,
) {
    let classesObject = {
        isDisabled,
        isDense,
        formIcon,
        isFocused,
        hasPrefix,
        hasSuffix,
        isMultiSelect,
        isMultiLine: !!displayOptionsAmount,
    };
    const iconClass = formIcon && formIcon.position === 'right' ? 'form-icon-flip' : '';

    if (!isReadOnlyWithoutInputs && ((isSelectable && isSelected) || !isSelectable)) {
        if (isValid) {
            classesObject.isValid = true;
        } else if (isValid !== undefined && hasChanged) {
            classesObject.isInvalid = true;
        }
    }

    if (isCompleted !== undefined) {
        classesObject = isCompleted ? { ...classesObject, isCompleted } : { ...classesObject, isIncomplete: true };
    }

    return getClassName(classesObject, iconClass, `type-${type}`);
}

/**
 * 'validations' is either an array or an object.
 * * When the input only has unary validations (do not require a value to compare), it will
 *      be an array with the validation's names.
 *      For instance: ['numbers', 'email']
 * * When the input has validations that require a value to compare (for instance, 'min-value'
 *      require a value to compare) it will be a object with the structure: a key for each
 *      parameterized validation, and the key 'unary' with the list of all unary validations.
 *      For instance: { 'min-value': 1, 'max-value': 10 , 'unary': ['numbers']}
 * 'avoidMarkAsChangedOnValidationsChange' the flag indicates that the input should not update the form's input
 *      data when 'isDisabled' changes. It is used in the selects that get disabled when the
 *      options are loaded. Without this ,when the input is enabled it is also marked as edited.
 * 'markAsChanged' the flag indicates that the input should be marked as changed when a change is triggered,
 *      even when the input type is 'hidden'.
 */

function Input(props) {
    const {
        type, icon, submitKey, validations, className, onChange, onSelect, onPropsChange, onSwitch, onUpload,
        isReadOnlyWithoutInputs, isSelectable, isDense, isDisabled, isSelected, isMultiSelect,
        isRequired, helperText, charCount, prefix, suffix, summaryFormat, value: inputValue, supportText, displayOptionsAmount,
        onFocus: inputOnFocus, onBlur: inputOnBlur, isBasedOnState, isFullReset, toggleLabel, onClean,
        avoidMarkAsChangedOnValidationsChange, hasCleanButton, hideErrorText, options,
        forceValidationsOnFirstRender, maxCharCount, showWhen, ...restOfProps
    } = props;
    const [isFocused, setIsFocused] = useState(false);

    const firstRender = useRef(true);
    const inputRef = useRef();
    const innerInputRef = useRef();

    const { isSubmitting, formId } = useContext(FormContext);

    const { formState, setFocus, watch, trigger, setValue } = useFormContext();

    const inputError = get(formState?.errors, `${submitKey}.message`);
    const hasChanged = !!get(formState?.dirtyFields, submitKey) || !!inputError;

    const updatedValue = watch(submitKey);

    const isCompleted = updatedValue != null && updatedValue !== '';

    const dependentValue = showWhen?.field ? watch(showWhen.field) : null;

    const isFieldHidden = useMemo(() => {
        if (!showWhen?.field || showWhen?.is == null) {
            return false;
        }

        if (typeof showWhen.is === 'function') {
            return !showWhen.is(dependentValue);
        }

        return showWhen.is !== dependentValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependentValue]);

    useEffect(() => {
        // Updates the form data if the props have changed, we avoid the call in the first render
        if (!firstRender.current && onPropsChange) {
            onPropsChange(submitKey, { prefix, suffix, isSelectable, isDisabled, isRequired });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefix, suffix, isSelectable, isRequired, submitKey, isDisabled]);

    useEffect(() => {
        firstRender.current = false;
    });

    useEffect(() => {
        if (forceValidationsOnFirstRender) {
            trigger(submitKey);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Replaces the onChange function to process certain validations each time changes occur,
    // then invokes the onChange function and transmits the processed information.
    // When 'propertyKey' is received, the input's value is an object. The received key is
    // the name of the property to change inside the object value. Split input uses this.

    const handleOnChange = (e) => {
        if (isDisabled) {
            return;
        }

        const value = e?.target?.value || e;

        if (onChange) {
            onChange(value, submitKey);
        }
    };

    // Set focus on inputs that are selectable and was previously selected
    useEffect(() => {
        if (isFocused && innerInputRef?.current && isSelectable && isSelected && setFocus) {
            setFocus(submitKey);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocused, isSelectable, isSelected]);

    // Checkbox Toggle
    const handleOnSelect = useCallback((checked) => {
        if (isDisabled) {
            return;
        }
        if (onSwitch) {
            onSwitch(checked);
        }

        setIsFocused(true);

        if (checked) {
            onSelect(checked);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    const handleOnFocus = useCallback(
        (value) => {
            setIsFocused(true);
            // setFocus(submitKey);
            if (inputOnFocus) {
                inputOnFocus(value);
            }
        },
        [inputOnFocus],
    );

    const handleOnBlur = useCallback(
        (value) => {
            setIsFocused(false);
            if (inputOnBlur) {
                inputOnBlur(value);
            }
        },
        [inputOnBlur],
    );

    const processedProps = useMemo(() => ({
        ...restOfProps,
        isReadOnlyWithoutInputs,
        isMultiSelect,
        displayOptionsAmount,
        summaryFormat,
        icon,
        innerInputRef,
        onUpload,
        isBasedOnState,
        options,
        onFocus    : handleOnFocus,
        onChange   : handleOnChange,
        onBlur     : handleOnBlur,
        isDisabled : isDisabled || (isSelectable && !isSelected),
        id         : restOfProps.id || (submitKey ? `${formId || 'default'}-${transformToSnake(submitKey)}` : ''),
        name       : submitKey,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        restOfProps,
        isReadOnlyWithoutInputs,
        isMultiSelect,
        displayOptionsAmount,
        summaryFormat,
        icon,
        onUpload,
        isBasedOnState,
        options,
        isDisabled,
        isSelectable,
        isSelected,
        submitKey,
        formId,
    ]);

    if (isFieldHidden) {
        return null;
    }

    let selectedInput;
    switch (type) {
        case 'text': selectedInput = <Text {...processedProps} />; break;
        case 'hidden': selectedInput = <Text {...processedProps} />; break;
        case 'number': selectedInput = <Number {...processedProps} />; break;
        case 'date': selectedInput = <Date {...processedProps} />; break;
        case 'select': selectedInput = <Select {...processedProps} />; break;
        case 'selectWithFilter': selectedInput = <SelectWithFilter {...processedProps} />; break;
        case 'radio': selectedInput = <Radio {...processedProps} />; break;
        case 'slider': selectedInput = <Slider {...processedProps} />; break;
        case 'switch': selectedInput = <Switch {...processedProps} />; break;
        case 'split': selectedInput = <Split {...processedProps} />; break;
        case 'quantity': selectedInput = <Quantity {...processedProps} />; break;
        case 'textarea': selectedInput = <Textarea {...processedProps} />; break;
        case 'chip': selectedInput = <Chip {...processedProps} />; break;
        case 'checkbox': selectedInput = <Checkbox {...processedProps} shouldPreventDefault={!onChange} />; break;
        case 'password': selectedInput = <Password {...processedProps} />; break;
        case 'picker': selectedInput = <PickerNew {...processedProps} />; break;
        case 'search': selectedInput = <Search {...processedProps} />; break;
        case 'upload': selectedInput = <Upload {...processedProps} />; break;
        case 'inputWithChip': selectedInput = <InputWithChip {...processedProps} validations={validations} />; break;
        case 'chipPicker': selectedInput = <ChipPicker {...processedProps} />; break;
        case 'color': selectedInput = <Color {...processedProps} />; break;
        case 'code': selectedInput = <Code {...processedProps} />; break;
        case 'imageGallery': selectedInput = <ImageGallery {...processedProps} />; break;
        default: break;
    }

    if (type === 'dynamicInputs') {
        return <DynamicInputWrapper {...processedProps} ref={inputRef} className={className} />;
    }

    const inputClass = getInputClass(
        isDisabled || isSubmitting,
        isDense,
        isSelected,
        isSelectable,
        !inputError,
        isCompleted,
        isFocused,
        icon,
        prefix,
        suffix,
        type,
        hasChanged,
        isReadOnlyWithoutInputs,
        isMultiSelect,
        displayOptionsAmount,
    );

    return (
        type !== 'hidden' && (
            <WrapperNew
                id={processedProps.id}
                icon={icon}
                prefix={prefix}
                suffix={suffix}
                className={className}
                inputClass={inputClass}
                helperText={helperText}
                supportText={supportText}
                isSelected={isSelected}
                hasToggle={isSelectable}
                toggleLabel={toggleLabel}
                onToggleChange={handleOnSelect}
                onCleanClick={
                    hasCleanButton
                        ? () => {
                            if (onClean) {
                                onClean();
                            }

                            if (onChange) {
                                onChange('', submitKey);
                            }

                            setValue(submitKey, '', { shouldDirty: true, shouldValidate: true });
                        }
                        : null
                }
                isCleanDisabled={onClean ? false : !updatedValue}
                isReadOnlyWithoutInputs={isReadOnlyWithoutInputs}
                hideErrorText={hideErrorText}
                isDisabled={isDisabled}
                inputError={inputError}
                maxCharCount={maxCharCount}
                value={updatedValue}
            >
                {selectedInput}
            </WrapperNew>
        )
    );
}

Input.displayName = 'Form.Input';

Input.defaultProps = {
    value                                 : undefined,
    className                             : '',
    submitKey                             : '',
    supportText                           : '',
    type                                  : 'text',
    validations                           : [],
    prefix                                : null,
    suffix                                : null,
    isDisabled                            : false,
    isRequired                            : false,
    isSelectable                          : false,
    isSelected                            : null, // NOTE Why this can bee null and not only booleans?
    isMultiSelect                         : false,
    isDense                               : false,
    isReadOnlyWithoutInputs               : false,
    displayOptionsAmount                  : null,
    onSwitch                              : null,
    onPropsChange                         : null,
    onChange                              : null,
    onFocus                               : null,
    onBlur                                : null,
    onSelect                              : null,
    onUpload                              : null,
    summaryFormat                         : null,
    helperText                            : null,
    charCount                             : null,
    icon                                  : null,
    isBasedOnState                        : false,
    isFullReset                           : false,
    avoidMarkAsChangedOnValidationsChange : false,
    onClean                               : null,
    forceValidationsOnFirstRender         : false,
    maxCharCount                          : null,
};

Input.propTypes = {
    className               : PropTypes.string,
    isSelectable            : PropTypes.bool,
    isSelected              : PropTypes.bool,
    charCount               : PropTypes.shape({ total: PropTypes.number }),
    isDense                 : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    isRequired              : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    displayOptionsAmount    : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    prefix                  : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    suffix                  : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onSelect                : PropTypes.func,
    onPropsChange           : PropTypes.func,
    onUpload                : PropTypes.func,
    onClean                 : PropTypes.func,
    summaryFormat           : PropTypes.func,
    supportText             : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    submitKey               : PropTypes.string,
    type                    : PropTypes.string,
    onSwitch                : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isBasedOnState          : PropTypes.bool,
    isFullReset             : PropTypes.bool,
    validations             : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
    value                   : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.shape({ inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        PropTypes.shape({}),
    ]),
    icon: PropTypes.shape({
        name     : PropTypes.string,
        position : PropTypes.string,
    }),
    helperText: PropTypes.shape({
        label     : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        className : PropTypes.string,
        onClick   : PropTypes.func,
    }),
    avoidMarkAsChangedOnValidationsChange : PropTypes.bool,
    forceValidationsOnFirstRender         : PropTypes.bool,
    maxCharCount                          : PropTypes.number,
};

function InputWithRef(props, ref) {
    return <Input {...props} wrapperRef={ref} />;
}
InputWithRef.displayName = 'Input';

export default forwardRef(InputWithRef);
