export default {
    PRICING     : 'Pricing',
    EXCEPTION   : 'Exception',
    POINTS      : 'Points',
    FLAT_FEE    : 'FlatFee',
    FIAT        : 'FiatOption',
    RELATIVE    : 'Relative',
    FIXED_NIGHT : 'FixedNight',
    FIXED_TOTAL : 'FixedTotal',
};
