import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { mustTranslate } from './utils';

function WrappedFormattedMessage({ content, values }) {
    if (mustTranslate(content)) {
        return <FormattedMessage id={content} defaultMessage={content} values={values} />;
    }

    return content !== undefined ? content : null; // We do this to avoid errors when content is undefined
}

WrappedFormattedMessage.defaultProps = { values: {} };

WrappedFormattedMessage.propTypes = {
    content : PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    values  : PropTypes.shape({}),
};

export default WrappedFormattedMessage;
