import React from 'react';

// Used to communicate to the Form and PrimaryButton components
export default React.createContext({
    // Flag used to check whether the context is loaded or not.
    isContextInitialized : false,
    submitData           : {},
    setSubmitData        : () => {
        // Default
    },
    // Object containing the form events.
    events    : {},
    setEvents : () => {
        // Default
    },
    // Object used to reset the form values
    contextInitialValues    : {},
    setContextInitialValues : () => {
        // Default
    },
    // Object used to reset the form 's submit data
    contextInitialSubmitData    : {},
    setContextInitialSubmitData : () => {
        // Default
    },
    // Flag used to show the user feedback when the form is being submitted.
    isSubmitting    : false,
    setIsSubmitting : () => {
        // Default
    },
    formId    : null,
    setFormId : () => {
        // Default
    },
    // Object that wraps all date related functionalities
    dateManager: {},
});
