import React from 'react';
import { FormError } from 'Components/Form';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';

function validateDynamicInputs(values, dateManager) {
    if (values) {
        const error = values
            && Object.values(values).find(({ dataType, value }) => {
                const valueIsEmpty = value === null || value === '';
                const stringIsInvalid = dataType === 'String' && String(value).trim().length === 0;
                const numberIsInvalid = (dataType === 'Integer' || dataType === 'Float') && Number.isNaN(Number(value));
                const dateIsInvalid = dataType === 'Date' && !dateManager.isValidDate(value);

                return valueIsEmpty || stringIsInvalid || numberIsInvalid || dateIsInvalid;
            });
        if (error) {
            const { name } = error;
            const description = (
                <FormattedMessage
                    id={translated.global.errors.errorOnExtraAttributes}
                    defaultMessage={translated.global.errors.errorOnExtraAttributes}
                    values={{ name }}
                />
            );

            throw new FormError(description);
        }
    }
}

function transformDynamicInputs(values) {
    const result = {};
    Object.keys(values).forEach((key) => {
        const val = values[key];
        const { dataType, value, slug, name } = val;
        let transformedValue = value;
        switch (dataType) {
            case 'Integer':
                transformedValue = value != null && value !== '' ? Number(value) : value;
                break;
            case 'Float':
                transformedValue = value != null && value !== '' ? parseFloat(value) : value;
                break;
            case 'Bool':
                transformedValue = Boolean(value);
                break;
            default:
                break;
        }

        // eslint-disable-next-line no-dupe-keys
        result[key] = { dataType, value, slug, name, value: transformedValue };
    });

    return result;
}

export { validateDynamicInputs, transformDynamicInputs };
