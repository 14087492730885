import React from 'react';
import PropTypes from 'prop-types';
import { monthsPerRow } from './constants';

/**
 * Draws the month picker
 */
function PickerCalendarMonth({
    elementKey,
    displayedDate,
    onMonthClick,
    getMonthYearClasses,
    calendarMonthsRef,
    isDateInRange,
    dateManager,
}) {
    const getDates = () => {
        const dateArray = [];
        let currentDate = dateManager.create('2000-01-12');
        const stopDates = dateManager.create('2000-12-12');
        while (currentDate <= stopDates) {
            // dateArray.push(dateManager.format(currentDate, 'MMM'));
            dateArray.push(currentDate);
            currentDate = dateManager.add(currentDate, 1, 'months');
        }
        return dateArray;
    };

    const months = [];

    const monthsList = getDates();

    monthsList.forEach((eachMonth) => {
        const monthNumber = dateManager.getMonth(eachMonth);
        const monthName = dateManager.format(eachMonth, 'MMM');
        const date = dateManager.setMonth(displayedDate, monthNumber);

        months.push(
            <td
                className={`calendar-month${getMonthYearClasses(date, 'month')}`}
                onClick={() => isDateInRange(date, 'month') && onMonthClick(monthNumber)}
                key={`month-${elementKey}-${monthNumber}`}
            >
                <span className="calendar-month-name">{monthName}</span>
            </td>,
        );
    });

    const rows = [];
    let cells = [];

    months.forEach((row, i) => {
        if (i % monthsPerRow !== 0 || i === 0) {
            cells.push(row);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(row);
        }
    });
    rows.push(cells);

    return (
        <tbody className="calendar-months" ref={calendarMonthsRef}>
            {
                // eslint-disable-next-line react/no-array-index-key
                rows.map((d, i) => <tr className="calendar-row-months" key={`month-${i}${elementKey}`}>{d}</tr>)
            }
        </tbody>
    );
}

PickerCalendarMonth.propTypes = {
    elementKey          : PropTypes.string.isRequired,
    displayedDate       : PropTypes.shape({}).isRequired,
    onMonthClick        : PropTypes.func.isRequired,
    getMonthYearClasses : PropTypes.func.isRequired,
    calendarMonthsRef   : PropTypes.shape({}).isRequired,
    isDateInRange       : PropTypes.func.isRequired,
    dateManager         : PropTypes.shape({}).isRequired,
};

PickerCalendarMonth.defaultProps = {};

export default React.memo(PickerCalendarMonth);
