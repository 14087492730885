import React, { useContext, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Subsection from 'Components/Sections/Subsection';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import Card from 'Components/Card';
import Form, { FormError, FormNew } from 'Components/Form';
import yup from 'Utils/yupHelper';
import PreviewButton from './PreviewButton';

const EXPIRED_BALANCE_SLUG = 'expired_balances_summary';
const ACTIVE_BALANCE_SLUG = 'active_balances_summary';

const LEGACY_RESERVATION_SLUG = 'legacy_reservation_list';
const ACTIVE_RESERVATION_SLUG = 'reservation_list';

const TOTAL_COLUMNS = 3;
const { combinedLabel, combinedReservationsLabel } = translated.accountStatements;

const availableFonts = [
    'Montserrat',
    'Nunito',
    'Raleway',
    'Oswald',
    'Poppins',
    'Lato',
    'Open Sans',
    'Roboto',
    'Fira Sans',
    'Playfair Display',
].map((e) => ({ value: e, content: e, customStyles: { fontFamily: e } }));

const convertOptionList = (list) => list?.map?.((e) => ({ content: e, value: e }));

const initializeData = (data) => {
    const initialValues = {
        header                       : data?.banner?.eeId || '',
        fontFamilyStyle              : data?.fontFamilyStyle || '',
        accentColorStyle             : data?.accentColorStyle || '',
        backgroundColorStyle         : data?.backgroundColorStyle || '',
        footerConfiguration          : data?.footerConfiguration || '',
        sectionsCombined             : data?.combinedSections != null ? data?.combinedSections : true,
        combinedReservations         : data?.combinedReservations != null ? data?.combinedReservations : true,
        summarizedExpiredBalances    : data?.summarizedExpiredBalances || false,
        summarizedLegacyReservations : data?.summarizedLegacyReservations || false,
        sections                     : {},
    };

    const sectionList = [];
    const sectionsShape = {};

    const { sections, additionalResources } = data;

    sections.forEach((eachSection) => {
        initialValues.sections[eachSection.id] = { isEnabled: eachSection.isEnabled };

        // eslint-disable-next-line prefer-destructuring
        let name = eachSection.name;
        if (eachSection.slug === ACTIVE_BALANCE_SLUG && initialValues?.sectionsCombined) {
            name = combinedLabel;
        }
        if (eachSection.slug === ACTIVE_RESERVATION_SLUG && initialValues?.combinedReservations) {
            name = combinedReservationsLabel;
        }

        let isEnabled = false;
        if (EXPIRED_BALANCE_SLUG !== eachSection.slug || !initialValues?.sectionsCombined) {
            isEnabled = true;
        }
        if (LEGACY_RESERVATION_SLUG !== eachSection.slug || !initialValues?.combinedReservations) {
            isEnabled = true;
        }
        sectionList.push({
            name,
            isEnabled,
            id           : eachSection.id,
            slug         : eachSection.slug,
            originalName : eachSection.name,
            isSelected   : eachSection.isEnabled,
            columns      : eachSection?.columns?.map((eachColumn) => ({
                id         : eachColumn.id,
                slug       : eachColumn.slug,
                name       : eachColumn.name,
                langKey    : eachColumn.langKey,
                options    : eachColumn.additionalResourceKey ? convertOptionList(additionalResources[eachColumn.additionalResourceKey]) : undefined,
                isSelected : !!eachColumn.isEnabled,
            })) || [],
        });

        const inputSchemas = {};
        eachSection?.columns?.forEach((eachColumn) => {
            const columnSchema = { isSelected: yup.boolean() };

            initialValues.sections[eachSection.id][eachColumn.id] = { isSelected: eachColumn.isEnabled };

            if (eachColumn.name != null) {
                columnSchema.value = yup.string().when('isSelected', {
                    is   : true,
                    then : () => yup.string().required(),
                });
                initialValues.sections[eachSection.id][eachColumn.id].value = eachColumn.name;
            }

            inputSchemas[eachColumn.id] = yup.object().shape(columnSchema);
        });

        sectionsShape[eachSection.id] = yup.object().shape(inputSchemas);
    });

    return {
        schema: yup.object().shape({
            header               : yup.string(),
            fontFamilyStyle      : yup.string().required(),
            accentColorStyle     : yup.color().required(),
            backgroundColorStyle : yup.color().required(),
            footerConfiguration  : yup.string(),
            sectionsCombined     : yup.boolean(),
            combinedReservations : yup.boolean(),
            sections             : yup.object().shape(sectionsShape),
        }),
        initialValues,
        sections: sectionList,
    };
};

const getSelectedSections = (sections) => sections?.filter?.((e) => e.isSelected && e.isEnabled)?.map((e) => e.id) || [];

const calculateCardStateSelectedFlag = (cards) => {
    const isCombined = !!Object.values(cards)?.find((e) => e.slug === ACTIVE_BALANCE_SLUG && e.name !== e.originalName);

    const reservationsIsCombined = !!Object.values(cards)?.find((e) => e.slug === ACTIVE_RESERVATION_SLUG && e.name !== e.originalName);

    return !!Object.values(cards).find((eachCard) => eachCard.isSelected
        && (eachCard.slug !== EXPIRED_BALANCE_SLUG || !isCombined) // We have to ignore the expired balance when 'combined' is selected
        && (eachCard.slug !== LEGACY_RESERVATION_SLUG || !reservationsIsCombined)
        && eachCard.columns?.find((eachColumn) => eachColumn.isSelected));
};

const calculateCardStateOpenFlag = (cards) => {
    const isCombined = !!Object.values(cards)?.find((e) => e.slug === ACTIVE_BALANCE_SLUG && e.name !== e.originalName);

    const reservetionIsCombined = !!Object.values(cards)?.find((e) => e.slug === ACTIVE_RESERVATION_SLUG && e.name !== e.originalName);

    return !!Object.values(cards).find((eachCard) => eachCard.isSelected
        && (eachCard.slug !== EXPIRED_BALANCE_SLUG || !isCombined) // We have to ignore the expired balance when 'combined' is selected
        && (eachCard.slug !== LEGACY_RESERVATION_SLUG || !reservetionIsCombined) // We have to ignore the expired balance when 'combined' is selected
        && eachCard.isOpen);
};

function Editor({ data }) {
    const { navigator, snackbar } = useContext(PanelContext);
    const formRef = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { schema, initialValues, sections } = useMemo(() => initializeData(data), []);
    const [isSectionsCombined, setSectionCombined] = useState(initialValues.sectionsCombined);
    const [isCombinedReservations, setCombinedReservations] = useState(initialValues.combinedReservations);

    const [cardsState, setCardsState] = useState(() => {
        const updatedCards = sections?.reduce?.((prev, current) => ({
            ...prev,
            [current.id]: {
                ...current,
                isOpen      : true,
                allSelected : false,
            },
        }), {}) || {};

        return {
            areCardsOpen     : true,
            allSelected      : calculateCardStateSelectedFlag(updatedCards),
            // The sections's card status: 'title' (for the section with dynamic title), 'isOpen', 'allSelected'
            cards            : updatedCards,
            // The list of sections ids that are enabled in the section configuration
            selectedSections : getSelectedSections(sections),
        };
    });

    const getCardsSectionColumn = (cards, sectionId, columnId) => cards?.[sectionId].columns?.find?.((e) => String(e.id) === String(columnId));

    const onColumnCheckChange = (sectionId, columnId, newValue) => {
        setCardsState((prev) => {
            const updatedCards = { ...prev.cards };

            const columnToUpdate = getCardsSectionColumn(updatedCards, sectionId, columnId);
            if (columnToUpdate) {
                columnToUpdate.isSelected = newValue;
            }

            return {
                ...prev,
                allSelected : calculateCardStateSelectedFlag(updatedCards),
                cards       : updatedCards,
            };
        });
    };

    const updatedSections = useRef(JSON.parse(JSON.stringify(initialValues.sections)));

    const [images, setImages] = useState({ list: [], loaded: false });

    const initializeImages = async (link) => {
        try {
            const response = await navigator.directRequest(link);

            setImages({
                list: response?.data?.map((e) => ({
                    id      : e.eeId,
                    url     : e.alternativeSizes?.[0]?.url,
                    caption : e.name,
                })) || [],
                loaded: true,
            });
        } catch (e) {
            snackbar.show({
                content : translated.accountStatements.initializeImages.error,
                isError : true,
                error   : e,
            });
        }
    };

    useEffect(() => {
        // We get the images available
        if (data?.additionalResources?.images?.read) {
            initializeImages(data.additionalResources.images.read);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSectionsChange = ([newSections, newSectionsCombined, combinedReservations]) => {
        if (newSections && updatedSections.current) {
            updatedSections.current = newSections;
        }

        if (!newSections) {
            return;
        }

        setCardsState((prev) => {
            const { cards, selectedSections } = prev;

            let hasChanged = false;

            let newSelectedSections = [];
            const newCards = {};

            Object.entries(newSections).forEach(([eachKey, eachValue]) => {
                const isSelected = !!eachValue.isEnabled;

                const newCardData = JSON.parse(JSON.stringify(cards[eachKey]));
                const wasEnabled = cards[eachKey]?.isEnabled;

                const wasSelected = !!selectedSections.find((e) => String(e) === String(eachKey));
                hasChanged = hasChanged || (isSelected !== wasSelected);

                if (isSelected && wasEnabled) {
                    newSelectedSections.push(eachKey);
                }

                if (newCardData.slug === EXPIRED_BALANCE_SLUG) {
                    const shouldBeEnabled = !newSectionsCombined;
                    if (wasEnabled !== shouldBeEnabled) {
                        hasChanged = true;

                        if (!shouldBeEnabled) {
                            newSelectedSections = newSelectedSections.filter((e) => e !== eachKey);
                        } else if (isSelected && !wasEnabled) {
                            newSelectedSections.push(eachKey);
                        }

                        newCardData.isEnabled = shouldBeEnabled;
                    }
                }

                if (newCardData.slug === ACTIVE_BALANCE_SLUG) {
                    if (newSectionsCombined && cards[eachKey].name !== combinedLabel) {
                        hasChanged = true;
                        newCardData.name = combinedLabel;
                    }
                    if (!newSectionsCombined && cards[eachKey].name === combinedLabel) {
                        hasChanged = true;
                        newCardData.name = newCardData.originalName;
                    }
                }

                if (newCardData.slug === LEGACY_RESERVATION_SLUG) {
                    const shouldBeEnabled = !combinedReservations;
                    if (wasEnabled !== shouldBeEnabled) {
                        hasChanged = true;

                        if (!shouldBeEnabled) {
                            newSelectedSections = newSelectedSections.filter((e) => e !== eachKey);
                        } else if (isSelected && !wasEnabled) {
                            newSelectedSections.push(eachKey);
                        }

                        newCardData.isEnabled = shouldBeEnabled;
                    }
                }

                if (newCardData.slug === ACTIVE_RESERVATION_SLUG) {
                    if (combinedReservations && cards[eachKey].name !== combinedReservationsLabel) {
                        hasChanged = true;
                        newCardData.name = combinedReservationsLabel;
                    }
                    if (!combinedReservations && cards[eachKey].name === combinedReservationsLabel) {
                        hasChanged = true;
                        newCardData.name = newCardData.originalName;
                    }
                }

                newCards[eachKey] = newCardData;
            });

            if (!hasChanged) {
                return prev;
            }

            return {
                ...prev,
                allSelected      : calculateCardStateSelectedFlag(newCards),
                areCardsOpen     : calculateCardStateOpenFlag(newCards),
                cards            : newCards,
                selectedSections : newSelectedSections,
            };
        });
    };

    const convertFormData = useCallback(
        (values) => {
            const { sections: valuesSections } = values;

            // The data to send MUST be the data received modified.
            const convertedData = {
                ...data,
                additionalResources          : undefined,
                links                        : undefined,
                banner                       : values.header ? { id: values.header } : null,
                accentColorStyle             : values.accentColorStyle,
                backgroundColorStyle         : values.backgroundColorStyle,
                footerConfiguration          : values.footerConfiguration || null,
                combinedSections             : values.sectionsCombined,
                combinedReservations         : values.combinedReservations,
                fontFamilyStyle              : values.fontFamilyStyle,
                summarizedExpiredBalances    : values.summarizedExpiredBalances,
                summarizedLegacyReservations : values.summarizedLegacyReservations,
            };

            Object.entries(valuesSections).forEach(([eachKey, eachValue]) => {
                const sectionToChange = convertedData?.sections?.find?.((e) => String(e?.id) === String(eachKey));

                if (sectionToChange) {
                    sectionToChange.isEnabled = eachValue.isEnabled;

                    const convertedColumns = sectionToChange?.columns?.map?.((eachColumn) => {
                        const updatedColumn = eachValue[eachColumn.id];
                        if (updatedColumn) {
                            return { ...eachColumn, isEnabled: !!updatedColumn.isSelected, name: updatedColumn.value };
                        }

                        return { ...eachColumn };
                    });

                    sectionToChange.columns = convertedColumns;
                }
            });

            return convertedData;
        },
        [data],
    );

    const handleOnSubmit = useCallback(
        async (values) => {
            try {
                const link = data?.links?.self?.update;
                if (!link) {
                    return;
                }

                await navigator.directRequest({
                    ...link,
                    data: convertFormData(values),
                });

                if (formRef?.current?.reset) {
                    formRef.current.reset(values);
                }
            } catch (e) {
                throw new FormError(translated.accountStatements.save.error, e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handlePreview = async (values) => {
        try {
            const link = data?.links?.self?.download;
            if (!link) {
                return;
            }

            const convertedData = convertFormData(values);

            const response = await navigator.directRequest({
                ...link,
                data           : convertedData,
                isFileDownload : true,
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            const blobFile = new Blob([response?.data], { type: response?.headers?.['content-type'] });

            const url = window.URL.createObjectURL(blobFile);
            a.href = url;
            a.download = 'accountStatement_preview';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            snackbar.show({
                error   : e,
                content : translated.accountStatements.preview.error,
                isError : true,
            });
        }
    };

    const handleOnFinish = () => {
        snackbar.show({
            content   : translated.accountStatements.save.success,
            isSuccess : true,
        });
    };

    const handleSelectAll = useCallback(() => {
        if (formRef?.current?.setValue && updatedSections.current) {
            let shouldSelect = null;
            const newSections = JSON.parse(JSON.stringify(updatedSections.current));

            setCardsState((prev) => {
                shouldSelect = !prev.allSelected;

                Object.keys(updatedSections.current)?.forEach((eachSectionKey) => {
                    if (updatedSections.current[eachSectionKey]) {
                        Object.keys(updatedSections.current[eachSectionKey])?.forEach((eachColumnKey) => {
                            if (newSections[eachSectionKey][eachColumnKey] && typeof newSections[eachSectionKey][eachColumnKey] === 'object') {
                                newSections[eachSectionKey][eachColumnKey].isSelected = !!shouldSelect;
                            }
                        });
                    }
                });
                formRef.current.setValue('sections', newSections, { shouldDirty: true });

                const updatedCards = { ...prev.cards };

                Object.entries(updatedCards).forEach(([cardKey, cardValue]) => {
                    Object.keys(cardValue?.columns).forEach((columnKey) => {
                        updatedCards[cardKey].columns[columnKey].isSelected = shouldSelect;
                    });
                });

                return ({
                    ...prev,
                    cards       : updatedCards,
                    allSelected : shouldSelect,
                });
            });
        }
    }, []);

    const handleExpandCards = () => {
        setCardsState((prev) => {
            const shouldOpen = !prev.areCardsOpen;

            const updatedCards = {};

            Object.entries(prev.cards).forEach(([eachKey, eachValue]) => {
                updatedCards[eachKey] = eachValue?.isOpen != null
                    ? { ...eachValue, isOpen: shouldOpen }
                    : eachValue;
            });

            return {
                ...prev,
                areCardsOpen : shouldOpen,
                cards        : updatedCards,
            };
        });
    };

    // Constants to generate the columns
    const iterationColumns = [...Array(TOTAL_COLUMNS)].map((c, i) => (i + 1) % TOTAL_COLUMNS);

    const canMakeChanges = data?.links?.self?.update;

    const commonProps = { isDisabled: !canMakeChanges };

    const formButtons = useMemo(
        () => {
            const buttons = [];

            if (data?.links?.self?.download) {
                buttons.push(<PreviewButton id="account-statement-preview" onClick={handlePreview} key="preview" />);
            }

            if (canMakeChanges) {
                buttons.push(
                    <Form.ResetNew key="fs" color="primary">
                        <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                    </Form.ResetNew>,
                );
                buttons.push(
                    <Form.PrimaryNew key="fp">
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    </Form.PrimaryNew>,
                );
            }

            return buttons;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canMakeChanges],
    );

    const sectionButtons = useMemo(() => (canMakeChanges
        ? [
            <Button id="roles-select-button" variant="text" size="small" onClick={() => handleSelectAll()} className="step-1" ignoreTimer>
                {cardsState.allSelected ? (
                    <FormattedMessage id={translated.global.buttons.unselect} defaultMessage={translated.global.buttons.unselect} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.select} defaultMessage={translated.global.buttons.select} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
            <Button id="roles-collapse-button" variant="text" size="small" onClick={handleExpandCards} className="step-2" ignoreTimer>
                {cardsState.areCardsOpen ? (
                    <FormattedMessage id={translated.global.buttons.collapse} defaultMessage={translated.global.buttons.collapse} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.expand} defaultMessage={translated.global.buttons.expand} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
        ]
        : [
            <Button id="roles-collapse-button" variant="text" size="small" onClick={handleExpandCards} className="step-2" ignoreTimer>
                {cardsState.areCardsOpen ? (
                    <FormattedMessage id={translated.global.buttons.collapse} defaultMessage={translated.global.buttons.collapse} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.expand} defaultMessage={translated.global.buttons.expand} />
                )}
                {' '}
                <FormattedMessage id={translated.roles.all} defaultMessage={translated.roles.all} />
            </Button>,
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ]), [cardsState.allSelected, cardsState.areCardsOpen]);

    const onColumnToggle = useCallback(
        (sectionId, newValue) => {
            setCardsState((prev) => {
                const updatedCards = {};

                Object.entries(prev.cards).forEach(([eachKey, eachValue]) => {
                    updatedCards[eachKey] = String(eachKey) === String(sectionId)
                        ? { ...eachValue, isOpen: newValue }
                        : eachValue;
                });

                return {
                    ...prev,
                    areCardsOpen : calculateCardStateSelectedFlag(updatedCards),
                    cards        : updatedCards,
                };
            });
        },
        [],
    );

    const onSectionToggle = useCallback(
        (sectionId, newValue) => {
            setCardsState((prev) => {
                const updatedCards = {
                    ...prev.cards,
                    [sectionId]: {
                        ...prev.cards[sectionId],
                        isSelected: newValue,
                    },
                };

                return {
                    ...prev,
                    cards       : updatedCards,
                    allSelected : calculateCardStateSelectedFlag(updatedCards),
                };
            });
        },
        [],
    );

    return (
        <Form.WrapperNew
            formId="account-statement-form"
            ref={formRef}
            schema={schema}
            initialValues={initialValues}
            onInputChange={onSectionsChange}
            watchFields={['sections', 'sectionsCombined', 'combinedReservations']}
            keepValuesOnInputRemove
        >
            <SectionsWrapper
                title={translated.accountStatements.title}
                actionButtons={formButtons}
            >
                <FormNew
                    buttonsWidth={{ base: 12, small: 6 }}
                    onSubmit={handleOnSubmit}
                    onFinish={handleOnFinish}
                >
                    <Form.ColumnNew width={{ base: 12, small: 6 }}>
                        <Form.Title>
                            <FormattedMessage
                                id={translated.accountStatements.banner}
                                defaultMessage={translated.accountStatements.banner}
                            />
                        </Form.Title>
                        <Form.InputNew
                            isDense
                            submitKey="header"
                            type="imageGallery"
                            images={images?.list}
                            {...commonProps}
                            isDisabled={!images?.loaded || commonProps.isDisabled}
                            helperText={{ label: translated.accountStatements.preview.helper }}
                        />

                        <Form.Title className="margin-top-xlarge">
                            <FormattedMessage
                                id={translated.accountStatements.styles}
                                defaultMessage={translated.accountStatements.styles}
                            />
                        </Form.Title>

                        <Form.InputNew
                            isDense
                            submitKey="fontFamilyStyle"
                            type="select"
                            label={translated.accountStatements.fontFamily}
                            options={availableFonts}
                            sortBy="content"
                            {...commonProps}
                        />

                        <Form.InputNew
                            isDense
                            submitKey="accentColorStyle"
                            type="color"
                            label={translated.accountStatements.accentColor}
                            {...commonProps}
                        />

                        <Form.InputNew
                            isDense
                            submitKey="backgroundColorStyle"
                            type="color"
                            label={translated.accountStatements.backgroundColor}
                            {...commonProps}
                        />

                        <Form.Title className="margin-top-xlarge">
                            <FormattedMessage
                                id={translated.accountStatements.footerConfiguration}
                                defaultMessage={translated.accountStatements.footerConfiguration}
                            />
                        </Form.Title>
                        <Form.InputNew
                            isDense
                            submitKey="footerConfiguration"
                            type="code"
                            {...commonProps}
                        />

                        <Form.Title className="margin-top-xlarge">
                            <FormattedMessage
                                id={translated.accountStatements.sections}
                                defaultMessage={translated.accountStatements.sections}
                            />
                        </Form.Title>
                        <Form.InputNew
                            isDense
                            submitKey="sectionsCombined"
                            type="switch"
                            label={translated.accountStatements.sectionsCombined}
                            className="margin-bottom-xsmall"
                            onChange={(newValue) => setSectionCombined(newValue)}
                            {...commonProps}
                        />
                        <Form.InputNew
                            isDense
                            submitKey="combinedReservations"
                            type="switch"
                            label={translated.accountStatements.combinedReservations}
                            className="margin-bottom-xsmall"
                            onChange={(newValue) => setCombinedReservations(newValue)}
                            {...commonProps}
                        />
                        {sections?.map((e) => (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {cardsState?.cards?.[e.id]?.isEnabled && (
                                    <Form.InputNew
                                        isDense
                                        submitKey={`sections.${e.id}.isEnabled`}
                                        type="checkbox"
                                        label={cardsState?.cards?.[e.id]?.name}
                                        className="margin-remove"
                                        onChange={(newValue) => onSectionToggle(e.id, newValue)}
                                        {...commonProps}
                                    />
                                )}
                            </>
                        ))}
                        {isSectionsCombined && (
                            <Form.InputNew
                                isDense
                                submitKey="summarizedExpiredBalances"
                                type="checkbox"
                                className="margin-remove"
                                label={translated.accountStatements.summarizedExpiredBalances}
                                {...commonProps}
                            />
                        )}
                        {isCombinedReservations && (
                            <Form.InputNew
                                isDense
                                submitKey="summarizedLegacyReservations"
                                type="checkbox"
                                className="margin-remove"
                                label={translated.accountStatements.summarizedLegacyReservations}
                                {...commonProps}
                            />
                        )}
                    </Form.ColumnNew>

                    <Subsection
                        title={translated.accountStatements.columnsTitle}
                        actionButtons={sectionButtons}
                        className="margin-top-xlarge"
                    >
                        <Grid className="statement-form">
                            {/* We create some columns */}
                            {iterationColumns.map((column) => (
                                <Grid.Column width={{ base: 12, small: 4 }} key={column}>
                                    {/* Show in each column the corresponding group of permissions */}
                                    {cardsState?.selectedSections
                                        ?.filter((enabledSection, enabledSectionIndex) => column === ((enabledSectionIndex + 1) % TOTAL_COLUMNS))
                                        ?.map((sectionId) => {
                                            const currentSection = cardsState?.cards?.[sectionId];

                                            if (!currentSection) {
                                                return null;
                                            }

                                            return (
                                                <div className="statement-card margin-bottom-small" key={`card-${sectionId}`}>
                                                    <Card
                                                        id={`role-group-${sectionId}`}
                                                        title={currentSection.name}
                                                        isToggleVisible={currentSection.isOpen}
                                                        onToggle={(newValue) => onColumnToggle(sectionId, newValue)}
                                                        expandableContent
                                                        unelevated
                                                    >
                                                        {currentSection.columns.map(({ langKey, id, options }) => (
                                                            <div key={`permissions.${id}`} className={`statement-card-input-wrapper ${options?.length ? 'with-select' : ''}`}>
                                                                <Form.InputNew
                                                                    submitKey={`sections.${sectionId}.${id}.isSelected`}
                                                                    label={options?.length ? '' : langKey}
                                                                    type="checkbox"
                                                                    onChange={(newValue) => onColumnCheckChange(sectionId, id, newValue)}
                                                                    {...commonProps}
                                                                />
                                                                {!!options?.length && (
                                                                    <Form.InputNew
                                                                        isDense
                                                                        submitKey={`sections.${sectionId}.${id}.value`}
                                                                        label={langKey}
                                                                        type="select"
                                                                        options={options}
                                                                        {...commonProps}
                                                                    />
                                                                )}
                                                            </div>
                                                        ))}
                                                    </Card>
                                                </div>
                                            );
                                        })}
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Subsection>

                </FormNew>
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={450} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={3} />
                </Grid.Column>
            </Grid>
            {/* Banner */}
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={85} className="margin-bottom-small" />
                    <Skeleton.Form type="input" />
                </Grid.Column>
            </Grid>
            {/* Styles */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={70} className="margin-bottom-small" />
                    <Skeleton.Form type="input" quantity={3} />
                </Grid.Column>
            </Grid>
            {/* Footer configuration */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={220} className="margin-bottom-small" />
                    <Skeleton.Form type="input" />
                </Grid.Column>
            </Grid>
            {/* Sections */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={100} className="margin-bottom-small" />
                    <Skeleton.Form type="checkbox" quantity={7} />
                </Grid.Column>
            </Grid>
            {/* Columns customization */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={240} className="margin-bottom-small" />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid childWidth={{ base: 12, small: 3 }}>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={false} />
                </div>
            </Grid>
        </>
    );
};

Editor.propTypes = { data: PropTypes.shape({}).isRequired };

export default withRequest(Editor);
