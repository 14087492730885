module.exports = Object.freeze(
    {
        title                 : 'apf.upgrades.title',
        titleShort            : 'apf.upgrades.titleShort',
        emptyList             : 'apf.upgrades.emptyList',
        buttonUpgrade         : 'apf.upgrades.buttonUpgrade',
        markProcessed         : { button: 'apf.upgrades.markProcessed.button', success: 'apf.upgrades.markProcessed.success', error: 'apf.upgrades.markProcessed.error' },
        list                  : { name: 'apf.upgrades.list.name', email: 'apf.upgrades.list.email', phone: 'apf.upgrades.list.phone', hotel: 'apf.upgrades.list.hotel' },
        request               : { name: 'apf.upgrades.request.name', email: 'apf.upgrades.request.email', phone: 'apf.upgrades.request.phone', hotel: 'apf.upgrades.request.hotel', creationDate: 'apf.upgrades.request.creationDate', preferredContactTime: 'apf.upgrades.request.preferredContactTime' },
        currentContract       : 'apf.upgrades.currentContract',
        newContract           : 'apf.upgrades.newContract',
        updateContractWarning : 'apf.upgrades.updateContractWarning',
        balances              : { empty: 'apf.upgrades.balances.empty' },
        contract              : {
            club: 'apf.upgrades.contract.club', tier: 'apf.upgrades.contract.tier', tierEmptySelect: 'apf.upgrades.contract.tierEmptySelect', contractType: 'apf.upgrades.contract.contractType', requiredTier: 'apf.upgrades.contract.requiredTier', requiredContractType: 'apf.upgrades.contract.requiredContractType', contractTypeEmptySelect: 'apf.upgrades.contract.contractTypeEmptySelect', anniversaryPoints: 'apf.upgrades.contract.anniversaryPoints', legacyId: 'apf.upgrades.contract.legacyId',
        },
        saveSuccess                 : 'apf.upgrades.saveSuccess',
        saveError                   : 'apf.upgrades.saveError',
        saveErrorMarkingAsProcessed : 'apf.upgrades.saveErrorMarkingAsProcessed',
        clubSearchError             : 'apf.upgrades.clubSearchError',
        tierSearchError             : 'apf.upgrades.tierSearchError',
        contractTypeSearchError     : 'apf.upgrades.contractTypeSearchError',
        edition                     : { clubWithNoTiers: 'apf.upgrades.edition.clubWithNoTiers', tierWithNoContractTypes: 'apf.upgrades.edition.tierWithNoContractTypes' },
        errors                      : { contractLegacyIdInUse: 'apf.upgrades.errors.contractLegacyIdInUse' },
    },
);
