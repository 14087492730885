import {
    mdiAccountArrowRight,
    mdiAccountCash,
    mdiAccountCheck,
    mdiAccountMultiple,
    mdiAccountOff,
    mdiAccountPlus,
    mdiAccountRemove,
    mdiAccountSettingsOutline,
    mdiAccountTie,
    mdiAlertCircle,
    mdiAlertCircleOutline,
    mdiAlertOutline,
    mdiAnimationOutline,
    mdiArrowDown,
    mdiArrowRight,
    mdiArrowUp,
    mdiBellOutline,
    mdiCalendar,
    mdiCalendarMultiselect,
    mdiCheckAll,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiChevronUp,
    mdiClock,
    mdiClose,
    mdiCloseCircle,
    mdiContentSave,
    mdiDomain,
    mdiDotsVertical,
    mdiDragVertical,
    mdiEmailSend,
    mdiEye,
    mdiFileDownload,
    mdiFileEye,
    mdiFileUpload,
    mdiFilterVariantPlus,
    mdiFilterVariant,
    mdiFormSelect,
    mdiHelpCircle,
    mdiHelpCircleOutline,
    mdiInformationOutline,
    mdiLinkVariantOff,
    mdiLoading,
    mdiMagnify,
    mdiMenu,
    mdiMenuRight,
    mdiMinus,
    mdiOfficeBuilding,
    mdiPencil,
    mdiPhoneOutline,
    mdiPhoneInTalkOutline,
    mdiPhoneOffOutline,
    mdiPlus,
    mdiPlusCircleOutline,
    mdiReload,
    mdiRoomService,
    mdiSeal,
    mdiStar,
    mdiStarOff,
    mdiSubdirectoryArrowLeft,
    mdiSwapVertical,
    mdiSync,
    mdiTableSettings,
    mdiFormTextbox,
    mdiTrashCan,
    mdiUndoVariant,
    mdiViewColumnOutline,
    mdiCreditCardRemove,
    mdiMinusCircle,
    mdiMinusCircleOutline,
    mdiCalendarClock,
    mdiRestore,
    mdiAutorenew,
    mdiFilterMenu,
    mdiCash100,
    mdiHomeCityOutline,
    mdiCogOutline,
    mdiTableSearch,
    mdiFilterOutline,
    mdiImageOutline,
} from '@mdi/js';

const iconsPath = {
    mdiAccountArrowRight,
    mdiAccountCash,
    mdiAccountCheck,
    mdiAccountMultiple,
    mdiAccountOff,
    mdiAccountPlus,
    mdiAccountRemove,
    mdiAccountSettingsOutline,
    mdiAccountTie,
    mdiAlertCircle,
    mdiAlertCircleOutline,
    mdiAlertOutline,
    mdiAnimationOutline,
    mdiArrowDown,
    mdiArrowRight,
    mdiArrowUp,
    mdiBellOutline,
    mdiCalendar,
    mdiCalendarMultiselect,
    mdiCheckAll,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiChevronUp,
    mdiClock,
    mdiClose,
    mdiCloseCircle,
    mdiContentSave,
    mdiDomain,
    mdiDotsVertical,
    mdiDragVertical,
    mdiEmailSend,
    mdiEye,
    mdiFileDownload,
    mdiFileEye,
    mdiFileUpload,
    mdiFilterVariantPlus,
    mdiFilterVariant,
    mdiFormSelect,
    mdiHelpCircle,
    mdiHelpCircleOutline,
    mdiInformationOutline,
    mdiLinkVariantOff,
    mdiLoading,
    mdiMagnify,
    mdiMenu,
    mdiMenuRight,
    mdiMinus,
    mdiOfficeBuilding,
    mdiPencil,
    mdiPhoneOutline,
    mdiPhoneInTalkOutline,
    mdiPhoneOffOutline,
    mdiPlus,
    mdiPlusCircleOutline,
    mdiReload,
    mdiRoomService,
    mdiSeal,
    mdiStar,
    mdiStarOff,
    mdiSubdirectoryArrowLeft,
    mdiSwapVertical,
    mdiSync,
    mdiTableSettings,
    mdiFormTextbox,
    mdiTrashCan,
    mdiUndoVariant,
    mdiViewColumnOutline,
    mdiCreditCardRemove,
    mdiMinusCircle,
    mdiMinusCircleOutline,
    mdiCalendarClock,
    mdiRestore,
    mdiAutorenew,
    mdiFilterMenu,
    mdiCash100,
    mdiHomeCityOutline,
    mdiCogOutline,
    mdiTableSearch,
    mdiFilterOutline,
    mdiImageOutline,
};

export default iconsPath;
