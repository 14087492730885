import React from 'react';
import { useFormContext } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import Range from './Range';
import RangeSlider from './RangeSlider';
import Alert from 'Components/Alert';
import { transformToSortList } from './utils';

function RangeConfiguration() {
    const { watch } = useFormContext();
    const ranges = watch('ranges');
    const list = transformToSortList(ranges);

    return (
        <Subsection title={translated.rules.dynamicRanges.rangeConfiguration.title}>
            <Alert
                type="warning"
                isBasic
                withoutTitle
                content={<WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.warning} />}
                className="margin-bottom-medium"
            />

            <RangeSlider />

            {!!list?.length && (
                <div className="subsection-header h6 margin-top-medium margin-bottom-remove text-color-secondary">
                    <WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.title} />
                </div>
            )}

            {list?.map?.((range, index) => (<Range key={range.id} id={range.id} index={index + 1} />))}
        </Subsection>
    );
}

export default RangeConfiguration;
