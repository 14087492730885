import { mdiHomeCityOutline } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const propertyGroupId = null;

export default {
    [sectionType.PROPERTY_GROUPS]: {
        isEnabled : false,
        config    : { url: '/panel/property-groups', icon: mdiHomeCityOutline },
        sections  : {
            [sectionType.PROPERTY_GROUPS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.PROPERTY_GROUPS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.PROPERTY_GROUPS_EDITOR]: {
                        isRoot    : true,
                        ids       : { propertyGroupId, self: 'propertyGroupId' },
                        resources : { templates: { name: resourceNames.PROPERTY_GROUP } },
                        config    : {
                            isModal              : true,
                            isHiddenInBreadcrumb : true,
                            url                  : {
                                withId    : '/(\\d+)',
                                withoutId : '/add',
                            },
                            resourceName: resourceNames.PROPERTY_GROUP,
                        },
                    },
                    [sectionType.RULES]: {
                        resources : { templates: { name: resourceNames.SYSTEM_RULE } },
                        config    : {
                            isModal              : true,
                            isHiddenInBreadcrumb : true,
                            resourceName         : resourceNames.SYSTEM_RULE,
                            requestTimeout       : 40000,
                            cleanWhenLeaving     : sectionType.RULES,
                        },
                        customProps: { navigateToParent: 'goToRoot' },
                    },
                    [sectionType.RULE]: {
                        resources : { templates: { name: resourceNames.SYSTEM_RULE } },
                        config    : {
                            resourceName               : resourceNames.SYSTEM_RULE,
                            hasTypePreffixInBreadcrumb : true,
                            requestTimeout             : 40000,
                        },
                        customProps: { navigateToParent: 'goToRoot' },
                    },
                },
            },
        },
    },
};
