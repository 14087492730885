import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import { scrollToTop } from 'Utils';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';
import translated from 'Constants/labels/translated';
import { fetchingShape } from 'Constants/PropTypes';

function List({ data, fetching, reloadData, resources }) {
    const { navigator, snackbar } = useContext(PanelContext);

    useEffect(() => {
        scrollToTop();
    }, []);

    const { data: upgrades, order, pagination } = data || {};

    const handleClickEditUpgradeDowngrade = (upgradeDowngrade) => {
        navigator.selectUpgradeDowngradeEditor(upgradeDowngrade, 'read');
    };

    const getUpgradeDowngradeTableConfig = () => {
        const items = upgrades
            ? upgrades.map((eachUpgrade) => ({
                ...eachUpgrade,
                hotel: eachUpgrade?.property?.name,
            }))
            : [];

        const columns = [
            {
                title     : translated.upgrades.list.name,
                key       : 'name',
                isVisible : true,
            },
            {
                title     : translated.upgrades.list.email,
                key       : 'email',
                isVisible : true,
            },
            {
                title     : translated.upgrades.list.phone,
                key       : 'phone',
                isVisible : true,
            },
            {
                title     : translated.upgrades.list.hotel,
                key       : 'hotel',
                isVisible : true,
            },
        ];

        const actions = [
            {
                content  : translated.upgrades.markProcessed.button,
                callback : async (upgrade) => {
                    const link = upgrade?.links?.self?.patch;

                    if (!link) {
                        return;
                    }

                    const reqConfig = {
                        ...link,
                        data             : { isProcessed: true },
                        shouldReloadData : true,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [upgrade?.id],
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CONTRACT, resources });

                        snackbar.show({ content: translated.upgrades.markProcessed.success, isSuccess: true });
                    } catch (error) {
                        snackbar.show({ error, content: translated.upgrades.markProcessed.error, isError: true });
                    }
                },
                key  : 'processed',
                when : (upgrade) => upgrade?.links?.self?.patch && !upgrade.isProcessed,
            },
            {
                content           : translated.upgrades.buttonUpgrade,
                callback          : (upgradeDowngrade) => handleClickEditUpgradeDowngrade(upgradeDowngrade),
                key               : 'upgradeDowngrade',
                isOutsideDropdown : true,
                icon              : 'SwapVertical',
                tooltip           : translated.upgrades.buttonUpgrade,
                when              : (upgrade) => upgrade?.links?.contracts?.create && !upgrade.isProcessed,
            },
        ];

        return {
            items,
            columns,
            rows: { actions },
        };
    };

    const { items, columns, rows } = getUpgradeDowngradeTableConfig();

    return (
        <Wrapper title={translated.upgrades.title}>
            <Table
                id="upgradeDowngrade"
                key="upgradeDowngrade"
                columns={columns}
                rows={rows}
                items={items}
                canViewColumns
                canChangeSettings
                currentPage={1}
                totalPages={5}
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="contracts-empty"
                        content={translated.upgrades.emptyList}
                    />
                )}
            />
        </Wrapper>
    );
}

List.propTypes = {
    fetching   : fetchingShape.isRequired,
    data       : PropTypes.shape({}).isRequired,
    reloadData : PropTypes.func.isRequired,
    sections   : PropTypes.shape({}).isRequired,
    resources  : PropTypes.shape({}).isRequired,
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium">
            <Grid.Column width={{ base: 6 }}>
                <Skeleton.Title isHeading width={420} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={3} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
