/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import Sections from 'Components/Sections';
import Subsection from 'Components/Sections/Subsection';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';
import UpgradeEditor from 'Components/Sections/Contracts/Editor';
import { ContractCard } from './ContractCard';

const convert = (list) => list?.map((each) => ({
    ...each,
    clubName     : each?.contractType?.clubTier?.club?.name,
    tierName     : each?.contractType?.clubTier?.tier?.name,
    contractName : each?.contractType?.name,
})) || [];

function Contracts({ data, sections, isPartner, resources }) {
    const { navigator } = useContext(PanelContext);

    const { links, data: list } = data || {};

    const { Section, props: subsecProps } = Sections.get(sections);

    const contracts = convert(list);

    const upgradeProps = isPartner ? sections[sectionType.CONTRACT_EDITOR] : sections[sectionType.OWNER_CONTRACT_EDITOR];

    const isUpgradeModalOpen = upgradeProps?.isEnabled && upgradeProps?.isSelected;

    const handleOnAddClick = () => {
        if (isPartner) {
            navigator.goToPartnerContractEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
        } else {
            navigator.goToOwnerContractEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
        }
    };

    return (
        <>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            {isUpgradeModalOpen && <UpgradeEditor {...upgradeProps} isCreatedOutsideUserRequest hasOldContract />}

            <Subsection
                title={translated.owners.contracts.title}
                actionButtons={
                    links?.self?.create && contracts?.length
                        ? [
                            <Button id="contracts-add-button" variant="outlined" color="primary" key="button_primary" onClick={handleOnAddClick}>
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Button>,
                        ]
                        : []
                }
            >
                {!contracts?.length && (
                    <Alert
                        id="contracts-empty"
                        content={translated.owners.contracts.empty}
                        actionText={translated.global.buttons.add}
                        onClick={handleOnAddClick}
                    />
                )}

                {!!contracts?.length && (
                    <Grid childWidth={{ base: 1, small: 2, medium: 3 }}>
                        {contracts.map((e, i) => (
                            <div key={`${i + 1}-extra-attribute-container`}>
                                <ContractCard
                                    key={`${i + 1}-extra-attribute-form`}
                                    className="margin-bottom-small"
                                    contract={e}
                                    isPartner={!!isPartner}
                                    resources={resources}
                                />
                            </div>
                        ))}
                    </Grid>
                )}
            </Subsection>
        </>
    );
}

Contracts.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium" addMargin="onStackedColumns">
                <Grid.Column width={{ base: 12 }}>
                    <Skeleton.Title isSubHeading width={118} />
                </Grid.Column>
            </Grid>
            <Grid addMargin="onStackedColumns" childWidth={{ base: 1, small: 2, medium: 3 }}>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasDescription={{ paragraphs: 7 }} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasDescription={{ paragraphs: 7 }} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasDescription={{ paragraphs: 7 }} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasDescription={{ paragraphs: 7 }} />
                </div>
                <div className="margin-bottom-small">
                    <Skeleton.Card unelevated hasPicture={false} hasDescription={{ paragraphs: 7 }} />
                </div>
            </Grid>
        </>
    );
};

Contracts.defaultProps = {
    data      : [],
    fetching  : {},
    isPartner : false,
};

Contracts.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func.isRequired,
    state           : PropTypes.shape({}).isRequired,
    isPartner       : PropTypes.bool,
};

export default withRequest(Contracts);
