module.exports = Object.freeze({
    title                       : 'apf.notifications.title',
    emptyList                   : 'apf.notifications.emptyList',
    markAsReadNoLink            : 'apf.notifications.markAsReadNoLink',
    markAsReadError             : 'apf.notifications.markAsReadError',
    navigateToNotificationError : 'apf.notifications.navigateToNotificationError',
    notificationConversion      : 'apf.notifications.notificationConversion',
    updateNotificationNoLink    : 'apf.notifications.updateNotificationNoLink',
    updateNotificationGetError  : 'apf.notifications.updateNotificationGetError',
    callNotification            : {
        title        : 'apf.notifications.callNotification.title',
        mobile       : 'apf.notifications.callNotification.mobile',
        phone        : 'apf.notifications.callNotification.phone',
        callFrom     : 'apf.notifications.callNotification.callFrom',
        unknownOwner : 'apf.notifications.callNotification.unknownOwner',
        goToProfile  : 'apf.notifications.callNotification.goToProfile',
    },
    markAsRead: 'apf.notifications.markAsRead',
});
