module.exports = Object.freeze({
    titleImport : 'Add:',
    titleCreate : 'New Owner',

    selectFile       : 'This file is empty or you have to select a CSV file from the list to see its content',
    noFiles          : 'There are no files in this section',
    upload           : 'Upload',
    create           : 'Create',
    go               : 'Go',
    status           : 'Status',
    createThisOwner  : 'Add Owner',
    openFile         : 'View file',
    goToThisOwner    : 'Go to Owner Profile',
    firstName        : 'First Name',
    filename         : 'Uploaded files',
    lastName         : 'Last Name',
    email            : 'Email',
    cantSaveTheFile  : 'The file could not be saved',
    theFileHasErrors : 'The file has errors, check what lines are wrong',
    valid            : 'Ready to Add',
    owners           : {
        title : 'Owners',
        empty : 'There are no owners',
    },

    saveInvalidContractPart1       : 'The contract number ',
    saveInvalidContractPart2       : ' has invalid data',
    saveInvalidQuantityOfHighlight : 'More than one contract was highlighted',
    saveInvalidLegacyContractsId   : 'More than one contract has the same legacy Id',

    error                   : 'With error',
    contractInitializeError : 'The contract configuration could not be initialized',
    initializeError         : 'The membership configuration could not be initialized',
    loadTiersError          : 'The club tiers could not be initialized',
    loadContractTypesError  : 'The tier contract types could not be initialized',
    fileManagerError        : 'The files could not be initialized',
});
