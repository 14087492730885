import { useState, useEffect, useCallback } from 'react';
import { getPageParameters } from 'Utils/serverHelper';
import globalConstants from 'Constants/global';
import { setUp as setUpDebugMode } from 'Utils/debugMode';

export default function useSectionsConstructor(url, app, QA, navigator, sections, reduxPath) {
    const [isReady, setIsReady] = useState(false);

    const queryParams = window.location.search;
    const searchParams = new URLSearchParams(queryParams);
    const qaActive = searchParams.get('qa');

    const setInitiallySelectedSections = useCallback(async (shouldReloadData, performBackAction = false) => {
        const req = {};

        try {
            const propsToAddToSection = [{ shouldReloadData }];
            const { ids, path, sectionProps } = await getPageParameters(req, window.location.pathname, sections, propsToAddToSection);

            if (performBackAction) {
                if (reduxPath.selectionsHistory.length === 1) {
                    // When there is no more history in our redux history, it is necessary to use browser to move to previous pages.
                    window.location.assign(window.location.pathname);
                    return;
                }
                navigator.goToPrevSection();
            } else {
                // We set up the 'debugMode' on the first load
                setUpDebugMode(searchParams.get('debug'));

                // We set up the qa mode on the first load
                if (qaActive !== null) {
                    await QA.setActive();
                }

                await navigator.initialRequestAndGoTo(ids, path, sectionProps, shouldReloadData);
            }

            setIsReady(true);
        } catch (error) {
            app.setErrorState(error.errorMessage, error.statusCode, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections, qaActive, QA, reduxPath.selectionsHistory.length, navigator, app]);

    useEffect(() => {
        // On the first render, we use the templates (shouldReloadData='useTemplate') to get the information
        setInitiallySelectedSections('useTemplate');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // On the Back Button's click, we use shouldReloadData = true to get the information
        window.onpopstate = () => {
            const performBackAction = true;
            const shouldReloadData = null;
            setInitiallySelectedSections(shouldReloadData, performBackAction);
        };

        if (url.current && url.shouldReloadUrlStage === globalConstants.DONE) {
            if (window.location.pathname !== url.current) {
                url.pushToUrl(url.current);
            } else {
                url.setShouldReloadUrlStage(globalConstants.INIT);
            }
        }
    }, [url, setInitiallySelectedSections, reduxPath]);

    return isReady;
}
