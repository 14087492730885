module.exports = Object.freeze(
    {
        noBonusPoints : 'apf.balanceTypes.noBonusPoints',
        title         : 'apf.balanceTypes.title',
        updateTitle   : 'apf.balanceTypes.updateTitle',
        createTitle   : 'apf.balanceTypes.createTitle',
        viewTitle     : 'apf.balanceTypes.viewTitle',
        updated       : 'apf.balanceTypes.updated',
        added         : 'apf.balanceTypes.added',
        create        : 'apf.balanceTypes.create',

        labels: {
            name     : 'apf.balanceTypes.labels.name',
            currency : 'apf.balanceTypes.labels.currency',
            level    : 'apf.balanceTypes.labels.level',
        },

        errors: {
            createError      : 'apf.balanceTypes.errors.createError',
            updateError      : 'apf.balanceTypes.errors.updateError',
            entityInUse      : 'apf.balanceTypes.errors.entityInUse',
            entityIsReadonly : 'apf.balanceTypes.errors.entityIsReadonly',
        },

        delete: {
            confirmation               : 'apf.balanceTypes.delete.confirmation',
            success                    : 'apf.balanceTypes.delete.success',
            error                      : 'apf.balanceTypes.delete.error',
            errorEntityCannotBeDeleted : 'apf.balanceTypes.delete.errorEntityCannotBeDeleted',
            entityIsReadonly           : 'apf.balanceTypes.delete.entityIsReadonly',
        },

        enable: {
            success : 'apf.balanceTypes.enable.success',
            error   : 'apf.balanceTypes.enable.error',
        },

        disable: {
            success : 'apf.balanceTypes.disable.success',
            error   : 'apf.balanceTypes.disable.error',
        },
    },
);
