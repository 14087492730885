import React, { useState, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Chips from 'Components/Chips';
import CalendarDate from 'Components/CalendarDate';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import { useFormContext, Controller } from 'react-hook-form';
import formContext from 'Components/Form/formContext';

function Picker({
    label,
    value,
    onChange,
    isReadOnlyWithoutInputs,
    onFocus,
    onBlur,
    isDisabled,
    id,
    isInline,
    isRange,
    isMultiRange,
    minDate,
    maxDate,
    labelFrom,
    labelTo,
    minRangeLength,
    name,
    defaultValue,
}) {
    const { dateManager } = useContext(formContext);

    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const [isOpen, setIsOpen] = useState(false);

    const context = useFormContext();
    const { setValue, control, watch } = context || {};

    const updatedValue = watch(name);

    const getFormattedValue = useCallback((newValue) => {
        if (isRange && !isMultiRange) {
            return newValue?.begin && newValue?.end ? `${dateManager.convertToDisplayableDate(newValue.begin) || ''} → ${dateManager.convertToDisplayableDate(newValue.end) || ''}` : '';
        }

        if (isMultiRange) {
            return '';
        }

        return dateManager.convertToDisplayableDate(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMultiRange, isRange]);

    const formattedValue = useMemo(() => getFormattedValue(updatedValue), [getFormattedValue, updatedValue]);

    const removeOption = (valueToRemove) => {
        const updatedList = value?.filter((e) => e !== valueToRemove);

        onChange({ target: { value: updatedList, label } });
    };

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!isReadOnlyWithoutInputs && (
                <Controller
                    render={
                        ({ field }) => (
                            <>
                                <input
                                    id={id}
                                    type="text"
                                    value={getFormattedValue(field.value)}
                                    placeholder={placeholder}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    tabIndex={isDisabled ? '-1' : '0'}
                                    onClick={() => setIsOpen((prev) => !prev)}
                                    autoComplete="off"
                                    onChange={() => {
                                    // Default
                                    }}
                                />

                                {isMultiRange && !!value?.length && (
                                    <Chips
                                        outlined
                                        list={value
                                            .sort((first, second) => {
                                                if (first.begin < second.begin) {
                                                    return -1;
                                                }

                                                if (first.begin > second.begin) {
                                                    return 1;
                                                }

                                                return 0;
                                            })
                                            .map((opt, index) => ({
                                                onClose : () => removeOption(opt),
                                                text    : `${dateManager.convertToDisplayableDate(opt.begin) || ''} → ${dateManager.convertToDisplayableDate(opt.end) || ''}`,
                                                key     : index,
                                            }))}
                                    />
                                )}
                            </>
                        )
                    }
                    control={control}
                    name={name}
                    defaultValue={defaultValue || ''}
                />
            )}

            {isReadOnlyWithoutInputs && <span id={id}>{formattedValue}</span>}

            {isOpen && (
                <CalendarDate
                    label={getMessage(label)}
                    isInline={isInline}
                    isRange={isRange || isMultiRange}
                    onClose={(newValue) => {
                        if (newValue) {
                            const val = (isMultiRange && (value ? [...value, newValue] : [newValue]))
                                || newValue;

                            setValue(name, val, { shouldDirty: true, shouldValidate: true });

                            if (onChange) {
                                onChange(val);
                            }
                        }

                        setIsOpen(false);
                    }}
                    value={updatedValue}
                    minDate={minDate}
                    maxDate={maxDate}
                    labelFrom={labelFrom}
                    labelTo={labelTo}
                    minRangeLength={minRangeLength}
                    dateManager={dateManager}
                />
            )}
        </>
    );
}

Picker.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : null,
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange       : null,
    isDisabled     : false,
    isInline       : false,
    isRange        : false,
    isMultiRange   : false,
    labelFrom      : null,
    labelTo        : null,
    minRangeLength : 0,
    defaultValue   : null,
};

Picker.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.shape({}),
    isInline                : PropTypes.bool,
    isRange                 : PropTypes.bool,
    isMultiRange            : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    minDate                 : PropTypes.string.isRequired,
    maxDate                 : PropTypes.string.isRequired,
    labelFrom               : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelTo                 : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    minRangeLength          : PropTypes.number,
    name                    : PropTypes.string.isRequired,
    defaultValue            : PropTypes.shape({}),
};

export default React.memo(Picker);
