import React from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import translated from 'Constants/labels/translated';
import Status from 'Components/Status';

function Transactions({ onDelete, loadTransactionData, itemsAndPagination, id, getContractRow }) {
    return (
        <Table
            title={translated.owners.balance.transactions.title}
            className="margin-top-small"
            id={id}
            key={id}
            canCollapse
            columns={[
                {
                    title     : translated.owners.balance.transactions.date,
                    key       : 'creationDatetime',
                    isVisible : true,
                    isDate    : true,
                },
                {
                    title     : translated.owners.balance.transactions.name,
                    key       : 'processedName',
                    isVisible : true,
                },
                {
                    title     : translated.owners.balance.transactions.code,
                    key       : 'code',
                    isVisible : true,
                },
                {
                    title           : translated.owners.balance.transactions.reason,
                    key             : 'reason',
                    isVisible       : true,
                    showTooltipWhen : 20,
                },
                {
                    title                : translated.global.status,
                    key                  : 'status',
                    isVisible            : true,
                    customFieldComponent : (transaction) => <Status status={transaction.state} />,
                },
            ]}
            rows={{
                actions: [
                    {
                        key               : 'Delete',
                        content           : translated.global.buttons.delete,
                        callback          : (transaction) => onDelete?.(transaction),
                        when              : (transaction) => transaction?.links?.self?.delete || transaction?.links?.manage,
                        isOutsideDropdown : true,
                        icon              : 'CreditCardRemove',
                        tooltip           : translated.global.buttons.delete,
                    },
                ],
                subTable: {
                    id      : `children-balances-${id}`,
                    key     : 'balances',
                    columns : [
                        {
                            title     : translated.owners.balance.transactions.balance,
                            key       : 'name',
                            isVisible : true,
                        },
                        {
                            title       : translated.owners.balance.transactions.contract,
                            key         : 'legacyContractId',
                            isVisible   : true,
                            fieldFormat : getContractRow
                                ? (transaction) => getContractRow(transaction?.legacyContractId, transaction?.links?.contracts)
                                : null,
                            isNumeric: !getContractRow,
                        },
                        {
                            title     : translated.owners.balance.clubPoints.balanceType,
                            key       : 'level',
                            isVisible : true,
                            isNumeric : true,
                        },
                        {
                            title     : translated.owners.balance.clubPoints.date,
                            key       : 'effectiveDate',
                            isVisible : true,
                            isDate    : true,
                        },
                        {
                            title     : translated.owners.balance.clubPoints.expiryDate,
                            key       : 'expiryDate',
                            isVisible : true,
                            isDate    : true,
                        },
                        {
                            title     : translated.owners.balance.transactions.currency,
                            key       : 'currency',
                            isVisible : true,
                            isNumeric : true,
                        },
                        {
                            title     : translated.owners.balance.transactions.points,
                            key       : 'amount',
                            isVisible : true,
                            isNumeric : true,
                        },
                    ],
                    rows: { actions: [] },
                },
            }}
            items={itemsAndPagination.items}
            onPaginationClick={(link) => loadTransactionData?.(link)}
            pagination={itemsAndPagination.pagination}
        />
    );
}

Transactions.defaultProps = {
    id                  : 'transaction',
    onDelete            : null,
    loadTransactionData : null,
    getContractRow      : null,
};

Transactions.propTypes = {
    id                  : PropTypes.string,
    onDelete            : PropTypes.func,
    loadTransactionData : PropTypes.func,
    itemsAndPagination  : PropTypes.shape({}).isRequired,
    getContractRow      : PropTypes.func,
};

export default Transactions;
