import React, { createRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that draws the calendar with the days of the month.
 */
function PickerCalendarDay({
    date, elementKey, getDateClasses, isDateInRange, onDayClick, calendarOnMouseEnter,
    calendarOnMouseLeave, addDayRef, dateManager,
}) {
    const firstDayOfMonth = () => dateManager.getDayOfWeek(dateManager.firstDayOfMonth(date));

    const blankDays = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
        blankDays.push(<td className="calendar-day empty" key={`calendar-empty-${elementKey}-${i}`} />);
    }

    const daysInMonths = [];
    for (let d = 1; d <= dateManager.getDaysInMonth(date); d++) {
        const elementDate = dateManager.setDayOfMonth(date, d);
        const dayClassName = `calendar-day${dateManager.isToday(elementDate) ? ' today' : ''}${getDateClasses(elementDate, 'day')}`;

        const elementRef = { ref: createRef(), date: elementDate };
        addDayRef(dateManager.format(elementDate, 'yyyy-MM-dd'), elementRef);

        daysInMonths.push(
            <td
                className={dayClassName}
                onClick={() => isDateInRange(elementDate, 'day', elementRef) && onDayClick(elementDate)}
                onMouseEnter={() => calendarOnMouseEnter(elementDate, 'day')}
                onMouseLeave={calendarOnMouseLeave}
                key={`day-${dateManager.format(elementDate, 'yyyy-MM-dd')}`}
                ref={elementRef.ref}
            >
                <span className="calendar-day-number">{d}</span>
            </td>,
        );
    }

    const totalDays = [...blankDays, ...daysInMonths];
    const rows = [];
    let cells = [];

    totalDays.forEach((row, i) => {
        if (i % 7 === 0 && i !== 0) {
            rows.push(cells);
            cells = [];
            cells.push(row);
        } else {
            cells.push(row);
        }
        if (i === totalDays.length - 1) {
            rows.push(cells);
        }
    });

    const weekdays = dateManager.getDaysOfWeek().map((weekday) => (
        <th className="calendar-weekday" key={`${elementKey}-weekday-${weekday}`}>
            {weekday.slice(0, -1)}
        </th>
    ));
    const totalDaysInMonth = rows.map((day, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="calendar-row-week" key={`${elementKey}-day-${i}`}>
            {day}
        </tr>
    ));

    return (
        <>
            <thead className="calendar-weekdays">
                <tr key={`${elementKey}-weekdays`}>{weekdays}</tr>
            </thead>
            <tbody className="calendar-weeks">{totalDaysInMonth}</tbody>
        </>
    );
}

PickerCalendarDay.propTypes = {
    date                 : PropTypes.shape({}).isRequired,
    elementKey           : PropTypes.string.isRequired,
    getDateClasses       : PropTypes.func.isRequired,
    isDateInRange        : PropTypes.func.isRequired,
    onDayClick           : PropTypes.func.isRequired,
    calendarOnMouseEnter : PropTypes.func.isRequired,
    calendarOnMouseLeave : PropTypes.func.isRequired,
    addDayRef            : PropTypes.func.isRequired,
    dateManager          : PropTypes.shape({}).isRequired,
};

PickerCalendarDay.defaultProps = {};

export default React.memo(PickerCalendarDay);
