import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';
import Grid from 'Components/Grid';
import Card from 'Components/Card';
import Form from 'Components/Form';
import Button from 'Components/Button';
import ReferredReferral from './ReferredReferral';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
import ExtraAttributesModal from './ExtraAttributesModal';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import Loading from 'Components/Loading';
import Modal from 'Components/Modal';
import OwnerCard from './OwnerCard';
import { getOwnerName } from './utils';
import EntityTags from 'Components/Sections/Tags/EntityTags';

export function OwnersInfo({ data, sections, reloadData }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const {
        primary,
        secondary,
        referredAccounts,
        contract,
        referral,
        links,
        address1,
        address2,
        country,
        city,
        state,
        zipCode,
        extraAttributes,
        id,
        additionalResources,
    } = data;

    const convertedPrimary = {
        ...primary,
        language: primary?.language && additionalResources?.languages?.find((e) => e.id === primary.language)?.name,
    };

    const convertedSecondary = {
        ...secondary,
        language: secondary?.language && additionalResources?.languages?.find((e) => e.id === secondary.language)?.name,
    };

    const hasSecondaryOwners = !!secondary?.id;
    const activationPrimaryOwnerLink = data?.primary?.links?.self?.activation;
    const activationSecondaryOwnerLink = data?.secondary?.links?.self?.activation;
    const dissociateSecondaryOwnerLink = data?.secondary?.links?.self?.delete;

    const [extraAttributesModal, setExtraAttributesModal] = useState({ isVisible: false, items: {}, type: '' });
    const [contractEdition, setContractEdition] = useState({ isEditing: false, isLoading: false });

    const editOwner = (selectedForm) => navigator.goToOwnerEditor({ available: data?.links?.self || {}, selectedForm });

    const handleShowConfirmation = (owner) => confirmation.show({
        message       : translated.owners.info.confirmationActivationEmail,
        acceptMessage : translated.global.buttons.confirm,
        onAccept      : async () => {
            try {
                setIsSendingEmail(true);

                await navigator.directRequest(owner === 'primary' ? activationPrimaryOwnerLink : activationSecondaryOwnerLink);

                snackbar.show({
                    content   : translated.owners.info.emailSuccessfullySent,
                    isSuccess : true,
                });
            } catch (e) {
                snackbar.show({
                    content : translated.owners.info.errorSendingEmail,
                    isError : true,
                });
            } finally {
                setIsSendingEmail(false);
            }
        },
    });

    const dissociateSecondaryOwner = async () => {
        const link = dissociateSecondaryOwnerLink;

        try {
            await navigator.directRequest(link);

            reloadData();

            snackbar.show({
                content   : translated.owners.info.secondaryOwnerDissociated,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content : translated.owners.info.secondaryOwnerDissociatedError,
                isError : true,
            });
        }
    };

    const handleDissociateSecondaryOwnerConfirmation = () => confirmation.show({
        message       : translated.owners.info.confirmationDissociateSecOwner,
        acceptMessage : translated.global.buttons.confirm,
        onAccept      : () => dissociateSecondaryOwner(),
    });

    const address = address1 ? `${address1}${address2 ? `, ${address2}` : ''}` : '';

    return (
        <div className="owner-info">
            {isSendingEmail && (
                <>
                    <Loading />
                    <Modal title={translated.global.pleaseWait}>
                        <span>
                            <FormattedMessage defaultMessage={translated.owners.info.sendingEmail} id={translated.owners.info.sendingEmail} />
                        </span>
                    </Modal>
                </>
            )}

            {contractEdition.isEditing && (
                <Form.Wrapper
                    modalProperties={{
                        title     : translated.owners.info.contractTitle,
                        isLoading : contractEdition.isLoading,
                        buttons   : [
                            <Form.Secondary
                                key="fs"
                                isDisabled={contractEdition.isLoading}
                                variant="text"
                                color="primary"
                                onClick={() => {
                                    setContractEdition({
                                        isEditing : false,
                                        isLoading : false,
                                    });
                                }}
                            >
                                <FormattedMessage defaultMessage={translated.global.buttons.cancel} id={translated.global.buttons.cancel} />
                            </Form.Secondary>,
                            <Form.Primary key="fp" variant="text" color="primary" isDisabled={contractEdition.isLoading}>
                                <FormattedMessage defaultMessage={translated.global.buttons.save} id={translated.global.buttons.save} />
                            </Form.Primary>,
                        ],
                    }}
                >
                    <Form
                        id="owners-contract-edit"
                        avoidConfirmation
                        initialValues={{
                            clubName     : contract?.contractType?.clubTier?.club?.name,
                            tierName     : contract?.contractType?.clubTier?.tier?.name,
                            contractName : contract?.contractType?.name,
                            begin        : contract.begin,
                            end          : contract.end,
                        }}
                        onSubmit={async ({ values }) => {
                            setContractEdition((prev) => ({ ...prev, isEditing: true, isLoading: true }));

                            try {
                                await navigator.directRequest({ ...links.contracts.patch, data: values });

                                setContractEdition({ isEditing: false, isLoading: false });
                                snackbar.show({
                                    content   : translated.owners.info.contractUpdated,
                                    isSuccess : true,
                                });

                                reloadData();
                            } catch (e) {
                                setContractEdition((prev) => ({ ...prev, isLoading: false }));

                                snackbar.show({
                                    content : translated.owners.info.contractUpdateError,
                                    error   : e,
                                    isError : true,
                                });

                                return e;
                            }

                            return null;
                        }}
                    >
                        <Alert
                            id="contract-edit-warning"
                            type="warning"
                            title={translated.global.advice}
                            className="margin-bottom-medium"
                            content={translated.owners.info.contractUpdateWarning}
                        />
                        <Form.Input
                            submitKey="begin"
                            isDense
                            isRequired
                            type="date"
                            icon={{ name: 'Calendar', position: 'right' }}
                            label={translated.owners.contractTypeStartDate}
                            maxDate={contract.end}
                            validations={{
                                maxDate : contract.end,
                                unary   : ['date'],
                            }}
                            helperText={{ label: translated.global.pickerInput.format }}
                            className="small-number-input"
                        />
                    </Form>
                </Form.Wrapper>
            )}

            {extraAttributesModal.isVisible && (
                <ExtraAttributesModal {...extraAttributesModal} onClose={() => setExtraAttributesModal({ isVisible: false, items: {}, type: '' })} />
            )}

            <Grid className="margin-bottom-medium" addMargin="onStackedColumns">
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    <Card
                        id="account"
                        key="account"
                        className="info-card"
                        unelevated
                        title={translated.owners.accountCard}
                        primaryButton={
                            links?.self?.read && links?.self?.update
                                ? {
                                    id      : 'owners-account-edit-button',
                                    text    : translated.global.buttons.edit,
                                    onClick : () => editOwner('account'),
                                }
                                : null
                        }
                    >
                        <Form isReadOnlyWithoutInputs id="account">
                            {address && (
                                <Form.Input
                                    id="account-address"
                                    value={address}
                                    type="text"
                                    label={translated.global.address}
                                />
                            )}
                            {zipCode && (
                                <Form.Input
                                    id="account-zip-code"
                                    value={zipCode}
                                    type="text"
                                    label={translated.global.zipCode}
                                />
                            )}
                            {city && (
                                <Form.Input
                                    id="account-city"
                                    value={city}
                                    type="text"
                                    label={translated.global.city}
                                />
                            )}
                            {state && (
                                <Form.Input
                                    id="account-state"
                                    value={state}
                                    type="text"
                                    label={translated.global.estate}
                                />
                            )}
                            {country && (
                                <Form.Input
                                    id="account-country"
                                    value={country?.name}
                                    type="text"
                                    label={translated.global.country}
                                />
                            )}
                        </Form>
                        {extraAttributes && Object.keys(extraAttributes).length > 0 && (
                            <div className="card-extra-attributes">
                                <span>
                                    <FormattedMessage defaultMessage={translated.extraAttributes.title} id={translated.extraAttributes.title} />
                                </span>
                                <Button
                                    id={`${id}-details-button`}
                                    variant="text"
                                    color="primary"
                                    className="button-details"
                                    onClick={() => setExtraAttributesModal({
                                        isVisible : true,
                                        items     : extraAttributes,
                                        type      : 'owner',
                                        selected  : {
                                            name: {
                                                value: (
                                                    <FormattedMessage
                                                        defaultMessage={translated.owners.accountCard}
                                                        id={translated.owners.accountCard}
                                                    />
                                                ),
                                            },
                                        },
                                    })}
                                >
                                    <FormattedMessage defaultMessage={translated.global.buttons.viewDetails} id={translated.global.buttons.viewDetails} />
                                </Button>
                            </div>
                        )}
                    </Card>
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    {primary && (
                        <Card
                            id="primary-owner"
                            key={primary.id}
                            className="info-card"
                            unelevated
                            title={getOwnerName(primary.extraAttributes?.salutation?.value, primary.firstName, primary.lastName)}
                            subtitle={translated.owners.primaryOwner}
                            primaryButton={
                                links?.self?.read && links?.self?.update && primary.links?.self?.update
                                    ? {
                                        id      : 'owners-primary-owner-edit-button',
                                        text    : translated.global.buttons.edit,
                                        onClick : () => editOwner('primary'),
                                    }
                                    : null
                            }
                            primaryRightButton={
                                primary.extraAttributes?.uniqueSalesforceId && activationPrimaryOwnerLink
                                    ? {
                                        id      : 'owners-primary-owner-mail-send-button',
                                        icon    : 'EmailSend',
                                        onClick : () => handleShowConfirmation('primary'),
                                        tooltip : translated.global.resendEmail,
                                        color   : 'primary',
                                    }
                                    : null
                            }
                        >
                            <OwnerCard id="owners-primary-owner" owner={convertedPrimary} configureModal={setExtraAttributesModal} />
                        </Card>
                    )}
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    {hasSecondaryOwners && (
                        <Card
                            id="secondary-owner"
                            key={secondary.id}
                            className="info-card"
                            unelevated
                            title={getOwnerName(secondary.extraAttributes?.salutation?.value, secondary.firstName, secondary.lastName)}
                            subtitle={translated.owners.secondaryOwner}
                            primaryButton={
                                links?.self?.read && links?.self?.update && secondary.links?.self?.update
                                    ? {
                                        id      : 'owners-secondary-owner-edit-button',
                                        text    : translated.global.buttons.edit,
                                        onClick : () => editOwner('secondary'),
                                    }
                                    : null
                            }
                            primaryRightButton={
                                secondary.extraAttributes?.uniqueSalesforceId && activationSecondaryOwnerLink
                                    ? {
                                        id      : 'owners-secondary-owner-mail-send-button',
                                        icon    : 'EmailSend',
                                        onClick : () => handleShowConfirmation('secondary'),
                                        tooltip : translated.global.resendEmail,
                                        color   : 'primary',
                                    }
                                    : null
                            }
                            secondaryRightButton={
                                dissociateSecondaryOwnerLink
                                    ? {
                                        id      : 'owners-secondary-owner-remove-button',
                                        icon    : 'AccountRemove',
                                        onClick : handleDissociateSecondaryOwnerConfirmation,
                                        tooltip : translated.global.dissociateSecOwner,
                                        color   : 'primary',
                                    }
                                    : null
                            }
                        >
                            <OwnerCard id="owners-secondary-owner" owner={convertedSecondary} configureModal={setExtraAttributesModal} />
                        </Card>
                    )}
                </Grid.Column>
            </Grid>

            {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                <EntityTags
                    {...sections[sectionType.ENTITY_TAGS]}
                    resources={{
                        ...sections[sectionType.ENTITY_TAGS].resources,
                        available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                    }}
                />
            )}

            <ReferredReferral data={data} referred={referredAccounts} referral={referral} />
        </div>
    );
}

OwnersInfo.defaultProps = {};

OwnersInfo.propTypes = {
    data       : PropTypes.shape({}).isRequired,
    sections   : PropTypes.shape({}).isRequired,
    reloadData : PropTypes.func.isRequired,
};

OwnersInfo.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns">
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    <Skeleton.Card unelevated key="Membership" hasPicture={false} hasDescription={{ paragraphs: 6 }} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    <Skeleton.Card unelevated key="Primary" hasPicture={false} hasDescription={{ paragraphs: 6 }} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6, medium: 4 }}>
                    <Skeleton.Card unelevated key="Secondary" hasPicture={false} hasDescription={{ paragraphs: 6 }} />
                </Grid.Column>
            </Grid>
            {/* Referral/Referred */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-medium" width={194} />
                    <Skeleton.Title isSubtitle className="margin-bottom-small" width={65} />
                    <Skeleton.Form type="input" />
                    <Skeleton.Title isSubtitle className="margin-top-medium margin-bottom-small" width={65} />
                    <Skeleton.Form type="input" />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(OwnersInfo);
