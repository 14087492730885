import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import { sectionType, resourceNames } from 'Constants/types';
import { clientStatus } from 'Constants/global';
import translated from 'Constants/labels/translated';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import withRequest from 'Components/Sections/withRequest';
import getOwnerLinks from './utils';
import Status from 'Components/Status';
import Alert from 'Components/Alert';

const getOwnersActions = (ownersConfig, navigator, handleChangeStatus) => {
    let actions = [
        {
            content   : translated.owners.viewDetails,
            key       : 'details',
            isSubmenu : true,
            actions   : [],
        },
    ];

    const subMenu = actions[0].actions;

    Object.keys(ownersConfig).forEach((key) => {
        switch (key) {
            case sectionType.INFO:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'info',
                    content  : translated.sectionName.info,
                    callback : (params) => navigator.goToDetails(params),
                    when     : (owner) => owner?.links?.[resourceNames.SELF]?.read,
                });
                break;

            case sectionType.BOOKINGS:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'bookings',
                    content  : translated.sectionName.bookings,
                    callback : (params) => navigator.goToBookings(params),
                    when     : (owner) => owner?.links?.[resourceNames.BOOKINGS]?.read,
                });
                break;

            case sectionType.WALLET:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'wallet',
                    content  : translated.sectionName.wallet,
                    callback : (params) => navigator.goToBalances(params),
                    when     : (owner) => owner?.links?.[resourceNames.WALLET]?.read,
                });
                break;

            case sectionType.CONVERSIONS:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'conversions',
                    content  : translated.sectionName.walletConversions,
                    callback : (params) => navigator.goToConversions(params),
                    when     : (owner) => owner?.links?.[resourceNames.CONVERSIONS]?.read,
                });
                break;

            case sectionType.NOTES:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'notes',
                    content  : translated.sectionName.notes,
                    callback : (params) => navigator.goToNotes(params),
                    when     : (owner) => owner?.links?.[resourceNames.NOTES]?.read,
                });
                break;

            case sectionType.CONVERSION_RULES:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'conversion-rules',
                    content  : translated.sectionName.conversionRules,
                    callback : (params) => navigator.goToOwnerWalletManager(params),
                    when     : (owner) => owner?.links?.[resourceNames.WALLET_CONFIG]?.read,
                });
                break;

            case sectionType.ATTACHMENTS:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'attachments',
                    content  : translated.sectionName.attachments,
                    callback : (params) => navigator.goToOwnerAttachments(params),
                    when     : (owner) => owner?.links?.[resourceNames.ATTACHMENTS]?.read,
                });
                break;

            case sectionType.CLIENT_CONTRACTS:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'contracts',
                    content  : translated.sectionName.contracts,
                    callback : (params) => navigator.goToOwnerContracts(params),
                    when     : (owner) => owner?.links?.[resourceNames.CLIENT_CONTRACTS]?.read,
                });
                break;

            case sectionType.USERS:
                subMenu.push({
                    ...ownersConfig[key],
                    key      : 'users',
                    content  : translated.sectionName.users,
                    callback : (params) => navigator.goToOwnerUsers(params),
                    when     : (owner) => owner?.links?.[resourceNames.USERS]?.read,
                });
                break;

            default:
                break;
        }
    });

    // We add the points statement button
    actions.push({
        key     : 'pointsStatement',
        content : translated.owners.pointStatement,
        getLink : (owner) => owner.links.pointsStatement.read,
        when    : (owner) => owner?.links?.pointsStatement?.read,
    });

    // We add the account statement button
    actions.push({
        key     : 'accountStatement',
        content : translated.owners.accountStatement,
        getLink : (owner) => owner.links.accountStatementReport.download,
        when    : (owner) => owner?.links?.accountStatementReport?.download,
    });

    if (ownersConfig?.[sectionType.EDITOR]) {
        const { isEnabled } = ownersConfig[sectionType.EDITOR];
        actions = [
            ...actions,
            { isDivider: true, key: 'divider' },
            {
                content  : translated.global.buttons.edit,
                key      : 'edit',
                isEnabled,
                callback : (params) => navigator.goToOwnerEditor({ available: params?.links?.self || {} }),
                when     : (owner) => owner?.links?.self?.update,
            },
            {
                content  : translated.global.buttons.disable,
                key      : 'disable',
                callback : (owner) => handleChangeStatus(owner, false),
                when     : (owner) => owner?.links?.self?.patch && owner.status === clientStatus.ACTIVE,
            },
        ];
    }

    let exchange;
    if (window.env.CLIENT_NAME === 'CV') {
        exchange = {
            key               : 'exchange',
            icon              : 'Autorenew',
            className         : 'button-exchange',
            isOutsideDropdown : true,
            hasDropdown       : true,
            actions           : [
                {
                    key      : 'conversions',
                    content  : translated.owners.balance.conversion,
                    callback : (owner) => {
                        const { create } = owner.links.conversions;
                        if (create.shouldRedirect) {
                            window.open(create.url, '_blank');

                            return;
                        }
                        navigator.goToConversions(owner);
                    },
                    when: (owner) => owner?.links?.conversions?.create,
                },
            ],
            when: (owner) => owner?.links?.exchanges?.read,
        };

        exchange.actions.push({
            key     : 'exchange',
            content : translated.owners.exchange,
            getLink : (owner) => owner.links.exchanges.read,
        });
    }

    if (window.env.CLIENT_NAME === 'AVC') {
        exchange = {
            key               : 'exchange',
            icon              : 'Autorenew',
            tooltip           : translated.owners.exchange,
            isOutsideDropdown : true,
            getLink           : (owner) => owner.links.exchanges.read,
            when              : (owner) => owner?.links?.exchanges?.read,
        };
    }

    const moreActions = [
        {
            content           : translated.owners.listActions.book,
            key               : 'book',
            icon              : 'RoomService',
            tooltip           : translated.owners.listActions.book,
            isOutsideDropdown : true,
            getLink           : (owner) => owner.links.bookings.create,
            when              : (owner) => owner?.links?.bookings?.create && owner.status === clientStatus.ACTIVE,
        },
        {
            // eslint-disable-next-line max-len
            content           : translated.owners.listActions.bookDisabled,
            key               : 'book',
            icon              : 'RoomService',
            tooltip           : translated.owners.listActions.bookDisabled,
            disabled          : true,
            isOutsideDropdown : true,
            // eslint-disable-next-line max-len
            when              : (owner) => owner?.status !== clientStatus.ACTIVE && owner.status !== clientStatus.PENDING && owner?.links?.bookings?.create,
        },
        {
            // eslint-disable-next-line max-len
            content           : translated.owners.activateAccount.tooltip,
            key               : 'activate',
            icon              : 'AccountCheck',
            isOutsideDropdown : true,
            tooltip           : translated.owners.activateAccount.tooltip,
            when              : (owner) => owner?.status === clientStatus.PENDING && owner?.links?.self?.patch,
            callback          : (owner) => handleChangeStatus(owner, true),
        },
        {
            content  : translated.global.buttons.enable,
            key      : 're-activate-account',
            icon     : 'AccountCheck',
            tooltip  : translated.owners.activateAccount.tooltip,
            when     : (owner) => (owner?.status === clientStatus.SUSPENDED || owner?.status === clientStatus.CANCELLED) && owner?.links?.self?.patch,
            callback : (owner) => handleChangeStatus(owner, true),
        },
    ];

    if (exchange && window.env.CLIENT_NAME === 'CV') {
        return [...actions, exchange, ...moreActions];
    }

    return [...actions, ...moreActions];
};

const getPhoneInput = (number) => {
    let value = ' '; // To avoid showing invalid phones (like '-') we must initialize this with a valid string

    if (number && /\d+$/.test(String(number))) {
        value = (
            <a className="clickable-link" href={`tel:${number}`}>
                {number}
            </a>
        );
    }

    return { value };
};

function List({ data, reloadData, resources, setSectionState, state: { appliedFilters } }) {
    const [loadingId, setLoadingId] = useState(null);
    const { sharedConfigurations, navigator, snackbar, confirmation } = useContext(PanelContext);
    const { order, data: owners, pagination, filter: filterLinks } = data || {};

    // We dont want to set the response of the API in the store, so we use local states performing a direct request
    const handleChangeStatus = async (owner, isEnable) => confirmation.show({
        title         : isEnable ? translated.owners.changeStatus.enable.confirmationTitle : translated.owners.changeStatus.disable.confirmationTitle,
        message       : isEnable ? translated.owners.changeStatus.enable.confirmationMessage : translated.owners.changeStatus.disable.confirmationMessage,
        acceptMessage : translated.global.buttons.confirm,
        onAccept      : async () => {
            try {
                setLoadingId(owner.id);
                await navigator.directRequest({
                    ...owner.links.self.patch,
                    data: { status: isEnable ? clientStatus.ACTIVE : clientStatus.SUSPENDED },
                });
                snackbar.show({
                    content   : isEnable ? translated.owners.changeStatus.enable.success : translated.owners.changeStatus.disable.success,
                    isSuccess : true,
                });
            } catch (error) {
                snackbar.show({
                    error,
                    content : isEnable ? translated.owners.changeStatus.enable.error : translated.owners.changeStatus.disable.error,
                    isError : true,
                });
            }

            reloadData();
            setLoadingId(null);
        },
    });

    // The order of the array determines the order of the columns
    const rowsActions = getOwnersActions(sharedConfigurations.owners, navigator, handleChangeStatus);

    const buttons = [];

    if (data?.links?.imports?.create && data?.links?.imports?.init) {
        // We add the button to import an owner
        buttons.push(
            <Button
                id="import-owner-button"
                tooltip={translated.owners.importActionTooltip}
                variant="outlined"
                key="button_primary"
                onClick={() => navigator.goToOwnersImport({ available: data.links.imports, current: 'init' })}
            >
                <FormattedMessage defaultMessage={translated.owners.importAction} id={translated.owners.importAction} />
            </Button>,
        );
    }

    if (data?.links?.self?.create && data?.links?.self?.init) {
        // We add the button to create an owner
        buttons.push(
            <Button
                id="create-owner-button"
                variant="unelevated"
                key="button_create"
                onClick={() => navigator.goToOwnersCreator({ links: data.links, current: 'init' })}
            >
                <FormattedMessage defaultMessage={translated.global.buttons.create} id={translated.global.buttons.create} />
            </Button>,
        );
    }

    return (
        <Wrapper
            title={translated.sectionName.owners}
            actionButtons={buttons}
        >
            <Table
                id="owners"
                key="owners"
                columns={[
                    {
                        title                : translated.global.status,
                        key                  : 'status',
                        isVisible            : true,
                        customFieldComponent : (owner) => <Status status={owner.status} highlightedAttributes={owner.highlightedAttributes} />,
                    },
                    {
                        title     : translated.global.firstName,
                        key       : 'firstName',
                        isVisible : true,
                    },
                    {
                        title     : translated.global.lastName,
                        key       : 'lastName',
                        isVisible : true,
                    },
                    {
                        title     : translated.owners.id,
                        key       : 'legacyId',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title       : translated.global.mobile,
                        key         : 'mobile',
                        isVisible   : true,
                        fieldFormat : (owner) => getPhoneInput(owner?.primary?.mobile),
                    },
                    {
                        title       : translated.global.phone,
                        key         : 'phone',
                        isVisible   : true,
                        fieldFormat : (owner) => getPhoneInput(owner?.primary?.phone),
                    },
                    {
                        title     : translated.global.email,
                        key       : 'email',
                        isVisible : true,
                    },
                    {
                        title     : translated.global.country,
                        key       : 'country',
                        isVisible : true,
                    },
                    {
                        title     : translated.global.city,
                        key       : 'city',
                        isVisible : true,
                    },
                    {
                        title           : translated.global.tags,
                        key             : 'tagNames',
                        isVisible       : true,
                        showTooltipWhen : 20,
                    },
                ]}
                rows={{
                    actions  : rowsActions,
                    subTable : {
                        id   : 'secondary',
                        key  : 'secondary',
                        rows : {
                            actions: [
                                {
                                    content           : translated.owners.listActions.book,
                                    key               : 'book',
                                    icon              : 'RoomService',
                                    tooltip           : translated.owners.listActions.book,
                                    isOutsideDropdown : true,
                                    getLink           : (owner) => owner.links.bookings.create,
                                    when              : (owner) => owner?.links?.bookings?.create && owner.status === clientStatus.ACTIVE,
                                },
                                {
                                    content           : translated.owners.listActions.bookDisabled,
                                    key               : 'book',
                                    icon              : 'RoomService',
                                    tooltip           : translated.owners.listActions.bookDisabled,
                                    disabled          : true,
                                    isOutsideDropdown : true,
                                    when              : (owner) => owner?.status !== clientStatus.ACTIVE
                                            && owner.status !== clientStatus.PENDING
                                            && owner?.links?.bookings?.create,
                                },
                            ],
                        },
                        columns: [
                            {
                                title           : translated.global.firstName,
                                key             : 'firstName',
                                isVisible       : true,
                                showTooltipWhen : 80,
                                customClassName : 'text-overflow',
                            },
                            {
                                title           : translated.global.lastName,
                                key             : 'lastName',
                                isVisible       : true,
                                showTooltipWhen : 80,
                                customClassName : 'text-overflow',
                            },
                            {
                                title       : translated.global.mobile,
                                key         : 'mobile',
                                isVisible   : true,
                                fieldFormat : (owner) => getPhoneInput(owner?.mobile),
                            },
                            {
                                title       : translated.global.phone,
                                key         : 'phone',
                                isVisible   : true,
                                fieldFormat : (owner) => getPhoneInput(owner?.phone),
                            },
                            {
                                title     : translated.global.email,
                                key       : 'email',
                                isVisible : true,
                            },
                        ],
                    },
                }}
                items={
                    !owners
                        ? []
                        : owners.map((owner) => {
                            const {
                                id,
                                links: ownerLinks,
                                primary,
                                secondary: secondaryOwner,
                                contract,
                                country: ownerCountry,
                                status,
                                tags,
                            } = owner;

                            // Both, the primary and secondary owners, use the links of the owner for the navigation.
                            const sec = { ...secondaryOwner, id, links: getOwnerLinks(ownerLinks, secondaryOwner), status };
                            const country = ownerCountry?.id;
                            const secondary = secondaryOwner?.id ? [sec] : [];
                            const links = getOwnerLinks(ownerLinks, primary);
                            const isActive = true;
                            const contractLegacyId = contract?.legacyId;

                            return {
                                ...owner,
                                contractLegacyId,
                                country,
                                primary,
                                secondary,
                                links,
                                isActive,
                                firstName : primary.firstName,
                                lastName  : primary.lastName,
                                email     : primary.email,
                                tagNames  : tags
                                    ?.map((e) => e.label)
                                    .sort()
                                    .join(', '),
                            };
                        })
                }
                onSearch={() => {
                    // Default
                }}
                canViewColumns
                canChangeSettings
                order={order}
                currentSortOrder="legacyId"
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                loadingIds={[loadingId]}
                whenEmpty={(
                    <Alert
                        id="owners-empty"
                        content={translated.owners.noOwners}
                    />
                )}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                filterResources={{
                    filters: [
                        {
                            type        : 'text',
                            submitKey   : 'ownerId',
                            resourceKey : 'ownerId',
                            label       : translated.owners.id,
                            validations : ['alphanumericUnderScore'],
                        },
                        {
                            type        : 'number',
                            submitKey   : 'contractId',
                            resourceKey : 'contractId',
                            label       : translated.global.contractId,
                            validations : { unary: ['numbers'] },
                        },
                        {
                            type             : 'search',
                            submitKey        : 'contractType',
                            resourceKey      : 'contractType',
                            onChangeKey      : 'name',
                            // eslint-disable-next-line max-len
                            label            : translated.global.contractType,
                            showLoading      : true,
                            searchValidation : (newValue) => newValue && newValue.length > 2,
                            searchFunction   : async (contractTypeName) => {
                                const { query } = filterLinks.additionalResources.contractTypes;
                                const { method } = query;
                                const { data: result } = await navigator.directRequest({
                                    method,
                                    url    : query.url,
                                    params : { name: contractTypeName },
                                });

                                return result?.map((contractType) => ({ ...contractType, display: contractType.name }));
                            },
                            renderSearch: (contractType) => {
                                if (!contractType) {
                                    return (
                                        <span className="no-results">
                                            <FormattedMessage
                                                defaultMessage={translated.owners.contractTypeFilterEmpty}
                                                id={translated.owners.contractTypeFilterEmpty}
                                            />
                                        </span>
                                    );
                                }

                                return <span className="result-user-name">{`${contractType.name}`}</span>;
                            },
                        },
                        {
                            type        : 'text',
                            submitKey   : 'firstName',
                            resourceKey : 'firstName',
                            label       : translated.global.firstName,
                        },
                        {
                            type        : 'text',
                            submitKey   : 'lastName',
                            resourceKey : 'lastName',
                            label       : translated.global.lastName,
                        },
                        {
                            type        : 'select',
                            options     : filterLinks?.additionalResources?.countries,
                            submitKey   : 'country',
                            resourceKey : 'country',
                            label       : translated.global.country,
                        },
                        {
                            type        : 'text',
                            submitKey   : 'city',
                            resourceKey : 'city',
                            label       : translated.global.city,
                        },
                        {
                            type        : 'text',
                            submitKey   : 'email',
                            resourceKey : 'email',
                            label       : translated.global.email,
                        },
                        {
                            type        : 'number',
                            submitKey   : 'phoneNumber',
                            resourceKey : 'phoneNumber',
                            label       : translated.global.phoneOrMobile,
                            validations : { maxLength: 30, unary: ['phone'] },
                        },
                        {
                            id               : 'owners-filter-tags',
                            type             : 'search',
                            submitKey        : 'slug',
                            resourceKey      : 'slug',
                            onChangeKey      : 'slug',
                            label            : translated.global.tags,
                            showLoading      : true,
                            searchValidation : (newValue) => newValue && newValue.length > 2,
                            searchFunction   : async (tagName) => {
                                const tags = filterLinks?.additionalResources?.tags?.map((tag) => ({ ...tag, lower: tag.label.toLowerCase() })) || [];
                                const lowerTag = tagName.toLowerCase();
                                const found = tags.filter((tag) => tag.lower.includes(lowerTag));

                                return found?.map((tag) => ({ ...tag, display: tag.label }));
                            },
                            renderSearch: (tag) => {
                                if (!tag) {
                                    return (
                                        <span className="no-results">
                                            <FormattedMessage defaultMessage={translated.tags.empty} id={translated.tags.empty} />
                                        </span>
                                    );
                                }

                                return <span className="result-user-name">{`${tag.label}`}</span>;
                            },
                        },
                    ],
                    filterLinks,
                    resources,
                }}
            />
        </Wrapper>
    );
}

List.defaultProps = {
    data            : { data: [], order: {} },
    setSectionState : null,
    state           : null,
};

List.propTypes = {
    data: PropTypes.shape({
        data  : PropTypes.arrayOf(PropTypes.shape({})),
        order : PropTypes.shape({ orderedBy: PropTypes.shape({}) }),
    }),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={160} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button quantity={2} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
