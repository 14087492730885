import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';

function Text({
    label,
    value,
    isReadOnlyWithoutInputs,
    onFocus,
    isDisabled,
    id,
    innerInputRef,
    linkHref,
    linkClassName,
    onClick,
    onKeyPress,
    name,
    onChange,
    onBlur,
    defaultValue,
}) {
    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const context = useFormContext();
    const { setValue, control } = context || {};

    useEffect(() => {
        if (onKeyPress && innerInputRef?.current) {
            innerInputRef.current.addEventListener('keyup', onKeyPress, false);
        }
        return () => {
            if (onKeyPress && innerInputRef?.current?.removeEventListener) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                innerInputRef.current.removeEventListener('keyup', onKeyPress, false);
            }
        };
    }, [innerInputRef, onKeyPress]);

    const handleOnChange = useCallback((props) => {
        const newValue = props?.target?.value;

        if (onChange) {
            onChange(props);
        }

        setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
    }, [name, onChange, setValue]);

    const handleOnBlur = useCallback((props) => {
        if (onBlur) {
            onBlur(props);
        }
    }, [onBlur]);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!isReadOnlyWithoutInputs && (
                // We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types).
                <Controller
                    render={
                        ({ field }) => (
                            <input
                                id={id}
                                key={id}
                                name={name}
                                type="text"
                                placeholder={placeholder}
                                onFocus={onFocus}
                                tabIndex={isDisabled ? '-1' : '0'}
                                autoComplete="off"
                                {...field}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                disabled={isDisabled}
                            />
                        )
                    }
                    control={control}
                    name={name}
                    defaultValue={defaultValue || ''}
                />
            )}

            {isReadOnlyWithoutInputs && (linkHref || onClick) && (
                <a className={linkClassName} href={linkHref || null} onClick={onClick}>
                    <span id={id}>{value}</span>
                </a>
            )}

            {isReadOnlyWithoutInputs && !linkHref && !onClick && <span id={id}>{value}</span>}
        </>
    );
}

Text.defaultProps = {
    id                      : '',
    label                   : '',
    name                    : '',
    value                   : '',
    defaultValue            : null,
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange      : null,
    isDisabled    : false,
    innerInputRef : null,
    linkHref      : '',
    linkClassName : '',
    onClick       : null,
    onKeyPress    : null,
};

Text.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name                    : PropTypes.string,
    value                   : PropTypes.string,
    defaultValue            : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
    linkHref                : PropTypes.string,
    linkClassName           : PropTypes.string,
    onClick                 : PropTypes.func,
    onKeyPress              : PropTypes.func,
};

export default React.memo(Text);
