import global from './global';
import owners from './owners';
import clubs from './clubs';
import contractTypes from './contractTypes';
import rules from './rules';
import tiers from './tiers';
import agents from './agents';
import roles from './roles';
import balanceTypes from './balanceTypes';
import login from './login';
import imports from './imports';
import partners from './partners';
import upgrades from './upgrades';
import notifications from './notifications';
import bookings from './bookings';
import categories from './categories';
import externalClubs from './externalClubs';
import extraAttributes from './extraAttributes';
import conversions from './conversions';
import conversionRules from './conversionRules';
import currencies from './currencies';
import tags from './tags';
import settings from './settings';
import propertyGroups from './propertyGroups';
import properties from './properties';
import documentation from './documentation';
import sectionName from './sectionName';
import accountStatements from './accountStatements';

/*
    The translations service is https://webtranslateit.com/projects/22818-eec-admin-panel/locales/en..es/strings

    If you don't have access to it, ask the PL permission.
*/

export default {
    global,
    rules,
    contractTypes,
    clubs,
    owners,
    tiers,
    agents,
    roles,
    balanceTypes,
    login,
    imports,
    partners,
    upgrades,
    notifications,
    bookings,
    categories,
    externalClubs,
    extraAttributes,
    conversions,
    conversionRules,
    currencies,
    tags,
    settings,
    propertyGroups,
    properties,
    documentation,
    sectionName,
    accountStatements,
};
