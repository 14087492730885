module.exports = Object.freeze({
    agents                     : 'apf.sectionName.agents',
    agentsEditor               : 'apf.sectionName.agentsEditor',
    agentsList                 : 'apf.sectionName.agentsList',
    owners                     : 'apf.sectionName.owners',
    ownersList                 : 'apf.sectionName.ownersList',
    ownersEditor               : 'apf.sectionName.ownersEditor',
    ownersGeneral              : 'apf.sectionName.ownersGeneral',
    ownersGeneralImported      : 'apf.sectionName.ownersGeneralImported',
    ownersCreator              : 'apf.sectionName.ownersCreator',
    ownersDetails              : 'apf.sectionName.ownersDetails',
    ownersImport               : 'apf.sectionName.ownersImport',
    tiers                      : 'apf.sectionName.tiers',
    tiersList                  : 'apf.sectionName.tiersList',
    tier                       : 'apf.sectionName.tier',
    tiersEditor                : 'apf.sectionName.tier',
    clubsTierEditor            : 'apf.sectionName.clubsTierEditor',
    clubs                      : 'apf.sectionName.clubs',
    clubsList                  : 'apf.sectionName.clubsList',
    clubsEditor                : 'apf.sectionName.clubsEditor',
    clubsGeneral               : 'apf.sectionName.clubsGeneral',
    users                      : 'apf.sectionName.users',
    usersList                  : 'apf.sectionName.usersList',
    usersEditor                : 'apf.sectionName.usersEditor',
    user                       : 'apf.sectionName.usersEditor',
    contractType               : 'apf.sectionName.contractType',
    contractTypes              : 'apf.sectionName.contractTypes',
    wallet                     : 'apf.sectionName.wallet',
    entityBookings             : 'apf.sectionName.entityBookings',
    notes                      : 'apf.sectionName.notes',
    note                       : 'apf.sectionName.note',
    info                       : 'apf.sectionName.info',
    rules                      : 'apf.sectionName.rules',
    rule                       : 'apf.sectionName.rule',
    walletManager              : 'apf.sectionName.walletManager',
    list                       : 'apf.sectionName.list',
    details                    : 'apf.sectionName.details',
    edit                       : 'apf.sectionName.edit',
    general                    : 'apf.sectionName.general',
    tierEditor                 : 'apf.sectionName.tierEditor',
    conversionManager          : 'apf.sectionName.conversionRules',
    conversionRules            : 'apf.sectionName.conversionRules',
    conversionRulesAlt         : 'apf.sectionName.conversionRulesAlt',
    conversionRuleEditorAlt    : 'apf.sectionName.conversionRuleEditorAlt',
    attachments                : 'apf.sectionName.attachments',
    attachment                 : 'apf.sectionName.attachment',
    contracts                  : 'apf.sectionName.contracts',
    contract                   : 'apf.sectionName.contract',
    contractsEditor            : 'apf.sectionName.contract',
    bookings                   : 'apf.sectionName.bookings',
    bookingsList               : 'apf.sectionName.bookingsList',
    roles                      : 'apf.sectionName.roles',
    rolesList                  : 'apf.sectionName.rolesList',
    rolesEditor                : 'apf.sectionName.rolesEditor',
    balanceTypes               : 'apf.sectionName.balanceTypes',
    balanceTypesList           : 'apf.sectionName.balanceTypesList',
    balanceTypesEditor         : 'apf.sectionName.balanceTypesEditor',
    categories                 : 'apf.sectionName.attachmentCategories',
    categoriesEditor           : 'apf.sectionName.attachmentCategories',
    categoriesList             : 'apf.sectionName.attachmentCategories',
    attachmentCategories       : 'apf.sectionName.attachmentCategories',
    attachmentCategoriesList   : 'apf.sectionName.attachmentCategoriesList',
    attachmentCategoriesEditor : 'apf.sectionName.attachmentCategoriesEditor',
    partners                   : 'apf.sectionName.partners',
    partnersList               : 'apf.sectionName.partnersList',
    partnersEditor             : 'apf.sectionName.partnersEditor',
    partnersGeneral            : 'apf.sectionName.partnersGeneral',
    partnersDetails            : 'apf.sectionName.partnersDetails',
    partnerInfo                : 'apf.sectionName.partnerInfo',
    partnerUsers               : 'apf.sectionName.partnerUsers',
    partnerContracts           : 'apf.sectionName.partnerContracts',
    upgradeRequests            : 'apf.sectionName.upgradeRequests',
    upgradeRequestsList        : 'apf.sectionName.upgradeRequestsList',
    relationship               : 'apf.sectionName.relationship',
    externalClubs              : 'apf.sectionName.externalClubs',
    externalClubEditor         : 'apf.sectionName.externalClubs',
    externalClubsList          : 'apf.sectionName.externalClubs',
    extraAttributes            : 'apf.sectionName.extraAttributes',
    extraAttributesList        : 'apf.sectionName.extraAttributes',
    extraAttributeEditor       : 'apf.sectionName.extraAttributeEditor',
    currencies                 : 'apf.sectionName.currencies',
    currenciesList             : 'apf.sectionName.currencies',
    currenciesEditor           : 'apf.sectionName.currenciesEditor',
    systemRules                : 'apf.sectionName.systemRules',
    systemRulesEditor          : 'apf.sectionName.systemRules',
    systemRulesList            : 'apf.sectionName.systemRules',
    walletConversions          : 'apf.sectionName.walletConversions',
    tags                       : 'apf.sectionName.tags',
    tagsList                   : 'apf.sectionName.tags',
    tagsEditor                 : 'apf.sectionName.tagsEditor',
    entityTagsList             : 'apf.sectionName.entityTagsList',
    propertyGroups             : 'apf.sectionName.propertyGroups',
    propertyGroupsList         : 'apf.sectionName.propertyGroups',
    propertyGroupsEditor       : 'apf.sectionName.propertyGroupsEditor',
    property                   : 'apf.sectionName.properties',
    properties                 : 'apf.sectionName.properties',
    propertiesList             : 'apf.sectionName.properties',
    accountStatements          : 'apf.sectionName.accountStatements',
});
