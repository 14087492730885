import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import getSizeClass from './Utils';
import Alert from 'Components/Alert';
import { colWidthShape } from 'Constants/PropTypes';
import translated from 'Constants/labels/translated';

function Column({
    id, refIndex, columnRef, setMarginOnStack, className, children, width,
}) {
    const [conf] = useState({
        setMarginOnStack,
        className,
        width,
    });

    const refCall = useCallback((el) => columnRef(el, refIndex), [columnRef, refIndex]);

    const widthValues = Object.values(conf.width);
    const isInvalidRage = widthValues.some((value) => value === 0 || value > 12);
    if (isInvalidRage) {
        return (
            <Alert
                id={`${id || ''}-column-error`}
                type="error"
                content={translated.global.column.error}
            />
        );
    }

    const widthClass = Object.keys(conf.width).reduce((newClass, k) => newClass.concat(`col${getSizeClass(k)}${width[k]} `), '');
    const marginGridClass = conf.setMarginOnStack ? 'grid-margin' : '';

    return (
        <div ref={refCall} className={`${widthClass} ${conf.className} ${marginGridClass}`}>
            {children}
        </div>
    );
}

Column.defaultProps = {
    className : '',
    width     : {},
    columnRef : () => {
        // Dummy function to get an empty object by default
    },
    setMarginOnStack : false,
    refIndex         : undefined,
    children         : null,
    id               : null,
};

Column.propTypes = {
    refIndex         : PropTypes.number,
    children         : PropTypes.node,
    className        : PropTypes.string,
    width            : PropTypes.shape(colWidthShape),
    columnRef        : PropTypes.func,
    setMarginOnStack : PropTypes.bool,
    id               : PropTypes.string,
};

export default Column;
