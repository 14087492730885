import io from 'socket.io-client';

export const initialize = (config, onReceive) => {
    const { url, token, payload } = config;

    const socket = io(url, {
        query                : { token },
        reconnection         : true,
        reconnectionAttempts : 10,
        reconnectionDelay    : 1500,
        timeout              : 5000,
        transports           : ['websocket', 'pooling'],
    });

    // Error events: exception, reconnecting, connect_error, error

    socket.on('receive', (message) => {
        onReceive(message);
    });

    socket.on('reconnect', () => {
        socket.emit('subscribe', payload, () => {});
    });

    socket.emit('subscribe', payload, () => {
        // Subscribed
    });
};

const Socket = { initialize };
export default Socket;
