import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import Chips from 'Components/Chips';
import Button from 'Components/Button';
import { useEventScroll, useEventClickOutside } from 'Hooks';
import Resource from 'Classes/Resource';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

const MAX_ELEMENTS = 100;
const SEARCH_TIMEOUT = 500;

function findOption(options, val) {
    let found;

    if (val && typeof options[0].value === 'object') {
        found = options.find((o) => String(o.value.id) === String(val));
    } else {
        found = options.find((opt) => String(opt.value) === String(val));
    }
    return { option: found };
}

const isSameElement = (first, second) => (first?.id || first) === (second?.id || second);

const convertList = (list, sortBy, selectedValues = []) => {
    const convertedList = list ? [...list] : [];
    // Check if the list must be sorted and the element have the field for which it will be sorted.
    if (sortBy && convertedList.length && convertedList[0][sortBy]) {
        convertedList.sort((a, b) => `${a[sortBy]}`.localeCompare(b[sortBy]));
    }

    return convertedList.map((option, index) => {
        const { key = `divider_${index}`, value, content, isDisabled: isOptionDisabled, parentId } = option;

        return {
            value    : typeof value === 'object' ? value.id : value,
            disabled : !!(Array.isArray(selectedValues) && !!selectedValues.find((e) => isSameElement(e, value))) || isOptionDisabled,
            parentId,
            key,
            content,
        };
    });
};

const convertOptions = (list, sortBy, isArray) => {
    let convertedList = list && isArray && Array.isArray(list)
        ? list?.map((each) => ({ content: each.name, value: { id: each.id, name: each.name }, key: `ht_${each.id}` }))
        : [];

    if (!isArray && list) {
        convertedList = [{ content: list.name, value: { id: list.id, name: list.name } }];
    }
    // Check if the list must be sorted and the element have the field for which it will be sorted.
    if (sortBy && convertedList.length && convertedList[0][sortBy]) {
        convertedList.sort((a, b) => `${a[sortBy]}`.localeCompare(b[sortBy]));
    }

    return convertedList;
};

const getResource = (filterLink, field, value = '') => (filterLink?.query && filterLink?.fields
    ? new Resource({ config: { ...filterLink.query } }).getLinkAndUrlWithQueryParams({
        fields         : { ...filterLink.fields },
        values         : { [field || Object.keys(filterLink.fields)?.[0]]: value },
        requiredFields : filterLink.required,
    })
    : null);

const textContains = (text, search) => {
    const convertedText = String(text).toLowerCase();
    const convertedSearch = String(search).toLowerCase();

    return convertedText.includes(convertedSearch);
};

function SelectWithFilter({
    value: selectedValue,
    label,
    options,
    onChange,
    onFocus,
    onBlur,
    isReadOnlyWithoutInputs,
    isMultiSelect,
    isDisabled,
    id,
    sortBy,
    defaultOptionText,
    innerInputRef,
    isBasedOnState,
    filterFunction, // when filterFunction is not set, we filter the options provided
    filterOnError, // Called when the search of the element fails
    filterLink, // When filter is active, we need to receive the non-filtered selectedOptions
    filterField, // Optional, used when the filter must be done with a specific field.
}) {
    const intl = useIntl();
    const { navigator } = useContext(PanelContext);

    const isValueAnArray = Array.isArray(selectedValue);

    const timerRef = useRef(null);
    const selectDivRef = useRef();
    const filterBoxRef = useRef();

    const [availableOptions, setAvailableOptions] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState(convertOptions(selectedValue, sortBy, isValueAnArray));

    const [filter, setFilter] = useState({
        input     : '',
        isOpen    : false,
        isLoading : !options?.length && filterLink,
        elements  : convertList(options, sortBy, selectedValue),
    });

    useEffect(() => {
        const selectedValues = selectedValue || [];
        if (!filterLink) {
            setAvailableOptions(options);
            setFilter((prev) => ({ ...prev, elements: convertList(options, sortBy, selectedValues).filter((e) => textContains(e.content, prev.input)) }));
        }
    }, [filterLink, options, selectedValue, sortBy]);

    useEffect(() => {
        const selectedValues = selectedValue || [];

        if (!filterLink && isBasedOnState && options?.length) {
            setSelectedOptions(options.filter((eachOption) => selectedValues.find((eachSelectedValue) => eachSelectedValue === eachOption.id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const filterRequest = async (link, close = false) => {
        try {
            const contractTypes = await navigator.directRequest(link);
            const convertedElements = filterFunction(contractTypes);

            setAvailableOptions(convertedElements);
            setFilter((prev) => ({
                ...prev,
                isLoading : false,
                elements  : convertList(convertedElements, sortBy, selectedValue),
                isOpen    : !close,
            }));
        } catch (e) {
            if (filterOnError) {
                filterOnError(e);
            }
        }
    };

    useEffect(() => {
        if (filterLink) {
            filterRequest(getResource(filterLink, filterField), true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterLink]);

    const updateComputedStyles = useCallback(() => {
        if (
            innerInputRef.current
            && innerInputRef.current.getBoundingClientRect()
            && filterBoxRef.current
            && filterBoxRef.current.getBoundingClientRect()
        ) {
            const { top, left, height } = innerInputRef.current.getBoundingClientRect();
            const { height: filterBoxHeight } = filterBoxRef.current.getBoundingClientRect();

            const computedTop = top + height + filterBoxHeight <= window.innerHeight ? top + height : top - filterBoxHeight;

            filterBoxRef.current.style.top = `${computedTop}px`;
            filterBoxRef.current.style.left = `${left}px`;
        }
    }, [innerInputRef]);

    const filterOnClickOutside = () => {
        if (filterBoxRef?.current && filter.isOpen) {
            setFilter((prev) => ({ ...prev, isOpen: false }));
        }
    };

    useEffect(() => {
        if (filter.isOpen) {
            updateComputedStyles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.isOpen, filter.elements]);

    useEventScroll(innerInputRef.current, updateComputedStyles, () => filter.isOpen);
    useEventClickOutside(selectDivRef.current, filterOnClickOutside);

    const selectOptionText = defaultOptionText
        || intl.formatMessage({
            id             : translated.global.selectOption,
            defaultMessage : translated.global.selectOption,
        });

    const updateSelectedOptions = (option, closeFilter = false) => {
        let wasSelected = false;
        let updatedList = [];

        if (isMultiSelect) {
            updatedList = selectedOptions.filter((each) => (each.value?.id ? each.value?.id !== option.value?.id : each.value !== option.value));
            wasSelected = updatedList.length !== selectedOptions.length;

            if (!wasSelected) {
                updatedList.push(option);
            }

            setSelectedOptions(updatedList);
            setFilter((prev) => ({
                ...prev,
                elements: prev.elements.map((e) => ({
                    ...e,
                    disabled: !!updatedList.find((updatedElement) => updatedElement.value?.id === e.value),
                })),
                isOpen: closeFilter || prev.isOpen,
            }));
        }

        if (option.callback) {
            option.callback({
                ...option,
                isSelected: isMultiSelect ? !wasSelected : option.value,
            });
        }

        if (onChange) {
            let currentOptions;
            if (isMultiSelect) {
                currentOptions = updatedList.reduce((prev, curr) => [...prev, curr.value], []);
            } else {
                currentOptions = option.value;
            }
            onChange({ target: { value: currentOptions } });
        }
    };

    const filterOnOpen = () => {
        setFilter((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    };

    const filterOnSelect = (newValue) => {
        const { option } = findOption(availableOptions, newValue);

        if (!isMultiSelect) {
            setFilter((prev) => ({ ...prev, isOpen: false }));
        }

        updateSelectedOptions(option);
    };

    // Search selected option to show when 'isReadOnlyWithoutInputs'
    let contentOfSelectedOptions;
    if (isReadOnlyWithoutInputs && availableOptions?.length && selectedValue) {
        if (isValueAnArray) {
            // eslint-disable-next-line max-len
            contentOfSelectedOptions = selectedValue.map((selectedOption) => (!selectedOption ? selectOptionText : findOption(availableOptions, selectedValue).option));
        } else {
            contentOfSelectedOptions = !selectedValue ? [selectOptionText] : [findOption(availableOptions, selectedValue).option];
        }
    }

    const filterOnChange = (newText, close = false) => {
        if (!filterFunction) {
            setFilter((prev) => ({
                ...prev,
                input    : newText,
                elements : convertList(availableOptions.filter((e) => textContains(e.content, newText)).slice(0, MAX_ELEMENTS), sortBy, selectedValue),
                isOpen   : !close,
            }));

            return;
        }

        setFilter((prev) => ({ ...prev, input: newText, isOpen: close ? false : prev.isOpen }));

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        timerRef.current = setTimeout(async () => {
            setFilter((prev) => ({ ...prev, isLoading: true }));
            timerRef.current = null;

            filterRequest(getResource(filterLink, filterField, newText), close);
        }, SEARCH_TIMEOUT);
    };

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            <div ref={selectDivRef}>
                {!isReadOnlyWithoutInputs && (
                    <>
                        <>
                            <input
                                id={id}
                                type="text"
                                value={filter.input}
                                onChange={(newValue) => filterOnChange(newValue?.target?.value)}
                                ref={innerInputRef}
                                tabIndex={isDisabled ? '-1' : '0'}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            />
                            {filter.input && (
                                <Button
                                    id={`${id}-filter-close`}
                                    icon="Close"
                                    onClick={() => {
                                        filterOnChange('', true);
                                    }}
                                    key="filter-result-close"
                                    disabled={!filter.input}
                                    className="filter-clear-button"
                                />
                            )}
                        </>
                        <Button
                            className="filter-view-all-button"
                            icon={(filter.isLoading && 'Loading') || 'FilterMenu'}
                            iconSpin={!!filter.isLoading}
                            onClick={filterOnOpen}
                            tooltip={translated.global.showFiltered}
                            disabled={!!filter.isLoading}
                        />
                        {filter.input && <div className="filter-mark" />}
                    </>
                )}

                {!isDisabled && filter.isOpen && (
                    <ul id={`${id}-filter-result`} className="search-box" ref={filterBoxRef}>
                        {filter.elements.length === 0 && (
                            <li className="search-result no-results" key="filter-result-empty">
                                <FormattedMessage id={translated.global.noResults} defaultMessage={translated.global.noResults} />
                            </li>
                        )}
                        {filter.elements.length > 0
                            && filter.elements.map((eachElement) => (
                                <li
                                    className="search-result"
                                    key={`filter-result-${eachElement.id || eachElement.value}`}
                                    disabled={!!eachElement.disabled}
                                    onClick={() => {
                                        if (!eachElement.disabled) {
                                            filterOnSelect(eachElement.value);
                                        }
                                    }}
                                >
                                    {eachElement.content}
                                </li>
                            ))}
                    </ul>
                )}
            </div>

            {isReadOnlyWithoutInputs
                && contentOfSelectedOptions
                && contentOfSelectedOptions.map((selectedOption) => <span>{(selectedOption && selectedOption.content) || selectOptionText}</span>)}

            {selectedOptions?.length > 0 && (
                <Chips
                    outlined
                    list={selectedOptions.map((opt) => ({
                        onClose : opt.isDisabled ? null : () => updateSelectedOptions(opt),
                        text    : opt.content,
                        key     : opt.key,
                    }))}
                />
            )}
        </>
    );
}

SelectWithFilter.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    onChange                : null,
    options                 : [],
    isReadOnlyWithoutInputs : false,
    isMultiSelect           : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled        : false,
    sortBy            : null,
    defaultOptionText : '',
    innerInputRef     : null,
    isBasedOnState    : false,
    filterFunction    : null,
    filterOnError     : null,
    filterLink        : null,
    filterField       : null,
};

SelectWithFilter.propTypes = {
    id    : PropTypes.string,
    label : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({ id: PropTypes.string.isRequired }),
        PropTypes.arrayOf(PropTypes.number),
    ]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    options                 : PropTypes.arrayOf(
        PropTypes.shape({
            content   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            isEnabled : PropTypes.bool,
            callback  : PropTypes.func,
        }),
    ),
    isDisabled        : PropTypes.bool,
    sortBy            : PropTypes.string,
    defaultOptionText : PropTypes.string,
    innerInputRef     : PropTypes.shape({}),
    isBasedOnState    : PropTypes.bool,
    filterFunction    : PropTypes.func,
    filterOnError     : PropTypes.func,
    filterLink        : PropTypes.shape({}),
    filterField       : PropTypes.string,
};

export default React.memo(SelectWithFilter);
