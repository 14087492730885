module.exports = Object.freeze({
    title : 'Partners',
    name  : 'Name',
    id    : 'ID',

    menuDetails  : 'View details',
    menuInfo     : 'Info',
    menuBookings : 'Bookings',
    menuBalance  : 'Wallet',
    menuNotes    : 'Notes',
    noPartners   : 'There are no partners added',
    contract     : 'Contracts',
    tags         : 'Tags',

    enable: {
        success : 'The partner has been enabled',
        error   : 'The partner could not be enabled',
    },

    disable: {
        success : 'The partner has been disabled',
        error   : 'The partner could not be disabled',
    },

    accountEdition: {
        title           : 'Partner ID',
        titleCreation   : 'New partner',
        invalidDates    : 'End date cannot be previous to the begin date',
        clubWithNoTiers : 'The selected club has no tiers, please select other club',
        creationSuccess : 'The partner has been added',
        creationError   : 'The partner could not be added',
        editionSuccess  : 'The partner has been updated',
        editionError    : 'The partner could not be updated',
        errors          : { entityAlreadyExists: 'A partner with the same legacy ID already exists' },

        partnerSection: {
            title             : 'Partner Information',
            contractStartDate : 'Contract start',
            contractEndDate   : 'Contract end',
            clubWithNoTiers   : 'The selected club has no tiers',
        },
    },

    users: {
        title     : 'Users',
        emptyList : 'There are no users added',

        delete: {
            confirmation : 'Delete user?',
            success      : 'The user has been deleted',
            error        : 'The user could not be deleted',
        },
    },

    user: {
        edition: {
            title   : 'Edit user',
            success : 'The user has been updated',
            error   : 'The user could not be updated',
        },

        creation: {
            title   : 'New user',
            success : 'The user has been added',
            error   : 'The user could not be added',
        },
    },

    errors: {
        entityAlreadyExists : 'There is a user in the system using this email or login token',
        channelIdRequired   : "To retrieve Partner's bookings a valid Channel id is required. Check the Tier configuration",
        failedDependency    : "To retrieve Partner's bookings a valid Channel id is required. Check the Tier configuration",
        contracts           : 'The contract types could not be initialized',
        clubTiers           : 'The club tiers could not be initialized',
        club                : 'The clubs could not be initialized',
    },
});
