import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import SectionsWrapper from '../../Wrapper';
import Subsection from '../../Subsection';
import Form, { FormError } from 'Components/Form';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
// eslint-disable-next-line import/no-cycle
import { Collection, SelectedSection } from 'Components/Sections/Collection';
import Alert from 'Components/Alert';

export function Editor({ data, resources, sections, isReloadingData, isEditing, name }) {
    const { snackbar, navigator } = useContext(PanelContext);

    const isClubTierEdition = !!data?.tier;
    const isOnClubContext = !!data?.club;
    const { additional } = resources;

    // The tier data is in different places inside data,
    // depending whether its a club-tier or a tier edition.
    const tier = isClubTierEdition ? data.tier : data;

    const [alertValues, setAlertValues] = useState({ channel: data?.channel });

    // Shows a the non embedded subsection selected (at the moment only 'RULE' exists)
    const currentType = Object.keys(sections).find(
        (section) => !sections[section]?.isHidden && sections[section]?.isEnabled && sections[section].isSelected && !sections[section].isEmbedded,
    );

    if (currentType) {
        return <SelectedSection selectFrom={sections} />;
    }

    const { links } = data;

    const handleSubmitTier = async (formData) => {
        try {
            let reqConfig = { data: formData.values };

            // First case, we are creating a new Tier
            // Second case, we are updating a Tier inside a ClubTier context
            // Third case, we are updating a Tier inside the Tier edition section
            if (!isEditing) {
                reqConfig = { ...reqConfig, ...resources.available.create };
            } else if (isClubTierEdition) {
                reqConfig = { ...reqConfig, ...tier.links.self.update, shouldReloadData: true };
            } else {
                reqConfig = { ...reqConfig, ...resources.available.update };
            }

            await navigator.requestForCurrentPath({ reqConfig, resources });
        } catch (error) {
            throw new FormError(translated.tiers.errorOnSave, error, translated.tiers.errors);
        }
    };

    const handleFinishTier = () => {
        snackbar.show({ content: !isEditing ? translated.tiers.created : translated.tiers.updated, isSuccess: true });

        if (!isClubTierEdition) {
            navigator.goToParentAndReload();
        }
    };

    const handleSubmitTierConfig = async ({ values }) => {
        try {
            await navigator.requestForCurrentPath({
                reqConfig: {
                    ...resources.available.update,
                    shouldReloadData : true,
                    data             : {
                        ...values,
                        bonusBreak: null,
                    },
                },
                resources,
            });
        } catch (error) {
            throw new FormError(translated.tiers.errorOnUpdatePromoCodes, error);
        }
    };

    const canEditTierConfig = resources.available.update;

    // eslint-disable-next-line max-len
    const isRuleSectionVisible = isClubTierEdition && sections?.[sectionType.RULES]?.config?.resourceName && links[sections[sectionType.RULES].config.resourceName]?.read;

    const RuleList = Collection[sectionType.RULES];

    const isContractTypesVisible = isClubTierEdition
        && sections?.[sectionType.CONTRACT_TYPES]?.config?.resourceName
        && links[sections[sectionType.CONTRACT_TYPES].config.resourceName]?.read;

    const ContractTypeList = Collection[sectionType.CONTRACT_TYPES];

    const ConversionRules = Collection[sectionType.CONVERSION_RULES];
    const ConversionRulesAlt = Collection[sectionType.CONVERSION_RULES_ALT];

    const alertText = !alertValues.channel
        ? translated.tiers.clubTierIncompleteData
        : null;

    const BankingConfigurations = Collection[sectionType.BANKING_CONFIGURATIONS];

    return (
        <SectionsWrapper
            title={`${isEditing ? 'Edit' : 'New'} ${name}`}
            actionButtons={
                isClubTierEdition
                    ? [
                        <Button id="tier-back-button" key="tier-back-button" variant="text" onClick={navigator.goToParentAndReload}>
                            <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
                        </Button>,
                    ]
                    : null
            }
        >
            <Form.Wrapper>
                <Subsection
                    title={translated.tiers.information}
                    actionButtons={
                        !isOnClubContext ? [<Form.Secondary key="fs" onClick={navigator.goToParentAndReload} />, <Form.Primary key="fp" />] : []
                    }
                    className="tier-subsection margin-bottom-medium"
                >
                    <Form
                        id="tier-form"
                        buttonsWidth={{ base: 12, small: 6 }}
                        onSubmit={handleSubmitTier}
                        onFinish={handleFinishTier}
                        isReadOnlyWithoutInputs={isOnClubContext}
                    >
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                submitKey="name"
                                type="text"
                                label={translated.global.name}
                                value={tier.name}
                                validations={{ maxLength: 100 }}
                                isRequired
                                isDense
                                charCount={{ total: 100 }}
                            />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>

            {isOnClubContext && (
                <Form.Wrapper>
                    <Subsection
                        title={(
                            <>
                                <FormattedMessage id={translated.tiers.tierConfig} defaultMessage={translated.tiers.tierConfig} />
                                {alertText && (
                                    <Button id="tier-data-missing-warning" className="margin-left-xsmall" icon="InformationOutline" tooltip={alertText} />
                                )}
                            </>
                        )}
                        actionButtons={
                            canEditTierConfig
                                ? [
                                    <Form.Reset
                                        key="frp"
                                        confirmation={{
                                            title   : translated.global.resetConfirmation.title,
                                            message : translated.global.resetConfirmation.message,
                                        }}
                                        color="primary"
                                    />,
                                    <Form.Primary key="fpp" />,
                                ]
                                : []
                        }
                        className="has-info"
                    >
                        <Form
                            buttonsWidth={{ base: 12, small: 6 }}
                            onSubmit={handleSubmitTierConfig}
                            onFinish={() => snackbar.show({ content: translated.tiers.promoCodesUpdated, isSuccess: true })}
                            initialValues={data}
                            isDisabled={!canEditTierConfig}
                            id="tier-form"
                        >
                            <Form.Column width={{ base: 12, small: 6 }}>
                                <Form.Title className="padding-remove-top margin-bottom-xsmall" isSmall>
                                    <FormattedMessage id={translated.tiers.promoCodes} defaultMessage={translated.tiers.promoCodes} />
                                </Form.Title>
                                <Form.Input
                                    submitKey="clientPromoCode"
                                    type="text"
                                    label={translated.tiers.promoCodeClient}
                                    validations={{ maxLength: 64 }}
                                    charCount={{ total: 64 }}
                                    isDense
                                />
                                <Form.Input
                                    submitKey="agentPromoCode"
                                    type="text"
                                    label={translated.tiers.promoCodeAgent}
                                    validations={{ maxLength: 64 }}
                                    charCount={{ total: 64 }}
                                    isDense
                                />

                                <Form.Title isSmall className="margin-top-xsmall">
                                    <FormattedMessage id={translated.tiers.general} defaultMessage={translated.tiers.general} />
                                </Form.Title>
                                <Alert isBasic type="warning" title={translated.global.advice} content={translated.tiers.channelHelp} className="margin-bottom-medium" />
                                <Form.Input
                                    submitKey="channel"
                                    type="text"
                                    label={translated.tiers.channelId}
                                    validations={{ maxLength: 36 }}
                                    charCount={{ total: 36 }}
                                    isDense
                                    onChange={(newValue) => setAlertValues((prev) => ({ ...prev, channel: newValue }))}
                                />
                                <Form.Hidden submitKey="tierId" value={data?.tier?.id} />
                                <Form.Hidden submitKey="clubId" value={data?.club?.id} />
                                <Form.Hidden submitKey="id" />
                            </Form.Column>
                        </Form>
                    </Subsection>
                </Form.Wrapper>
            )}

            {isRuleSectionVisible && links?.[sections[sectionType.RULES].config.resourceName] && (
                <RuleList
                    {...sections[sectionType.RULES]}
                    shouldReloadData={isReloadingData || sections[sectionType.RULES].shouldReloadData}
                    resources={{
                        ...sections[sectionType.RULES].resources,
                        available: links[sections[sectionType.RULES].config.resourceName],
                    }}
                    pathForResource={[
                        sectionType.CLUBS,
                        sectionType.CLUBS_EDITOR,
                        sectionType.CLUBS_GENERAL,
                        sectionType.CLUBS_TIER_EDITOR,
                        sectionType.RULES,
                    ]}
                    selectRuleEditorMethod="goToClubTierRuleEditor"
                />
            )}

            {isContractTypesVisible && links?.[sections[sectionType.CONTRACT_TYPES].config.resourceName] && (
                <ContractTypeList
                    {...sections[sectionType.CONTRACT_TYPES]}
                    shouldReloadData={isReloadingData || sections[sectionType.CONTRACT_TYPES].shouldReloadData}
                    resources={{
                        ...sections[sectionType.CONTRACT_TYPES].resources,
                        available: links[sections[sectionType.CONTRACT_TYPES].config.resourceName],
                    }}
                    pathForResource={[
                        sectionType.CLUBS,
                        sectionType.CLUBS_EDITOR,
                        sectionType.CLUBS_GENERAL,
                        sectionType.CLUBS_TIER_EDITOR,
                        sectionType.CONTRACT_TYPES,
                    ]}
                />
            )}

            {links?.[sections[sectionType.CONVERSION_RULES]?.config?.resourceName] && (
                <ConversionRules
                    {...sections[sectionType.CONVERSION_RULES]}
                    resources={{
                        ...sections[sectionType.CONVERSION_RULES].resources,
                        available: links[sections[sectionType.CONVERSION_RULES].config.resourceName],
                    }}
                    pathForResource={[
                        sectionType.CLUBS,
                        sectionType.CLUBS_EDITOR,
                        sectionType.CLUBS_GENERAL,
                        sectionType.CLUBS_TIER_EDITOR,
                        sectionType.CONVERSION_RULES,
                    ]}
                />
            )}

            {links?.[sections[sectionType.CONVERSION_RULES_ALT]?.config?.resourceName] && (
                <ConversionRulesAlt
                    {...sections[sectionType.CONVERSION_RULES_ALT]}
                    resources={{
                        ...sections[sectionType.CONVERSION_RULES_ALT].resources,
                        available: links[sections[sectionType.CONVERSION_RULES_ALT].config.resourceName],
                    }}
                    pathForResource={[
                        sectionType.CLUBS,
                        sectionType.CLUBS_EDITOR,
                        sectionType.CLUBS_GENERAL,
                        sectionType.CLUBS_TIER_EDITOR,
                        sectionType.CONVERSION_RULES_ALT,
                    ]}
                />
            )}

            {sections[sectionType.BANKING_CONFIGURATIONS] && links && links[sections[sectionType.BANKING_CONFIGURATIONS].config.resourceName] && (
                <BankingConfigurations
                    {...sections[sectionType.BANKING_CONFIGURATIONS]}
                    resources={{
                        ...sections[sectionType.BANKING_CONFIGURATIONS].resources,
                        available: links[sections[sectionType.BANKING_CONFIGURATIONS].config.resourceName],
                    }}
                    pathForResource={[
                        sectionType.CLUBS,
                        sectionType.CLUBS_EDITOR,
                        sectionType.CLUBS_GENERAL,
                        sectionType.CLUBS_TIER_EDITOR,
                        sectionType.BANKING_CONFIGURATIONS,
                    ]}
                    balanceTypes={additional?.balanceTypes}
                    contractData={{}}
                    isEditEnabled
                    isTemplate
                    showValidation={false}
                />
            )}
        </SectionsWrapper>
    );
}

Editor.displayName = 'TierGeneral';

Editor.defaultProps = {
    sections        : null,
    isReloadingData : false,
};

Editor.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    name            : PropTypes.string.isRequired,
    isEditing       : PropTypes.bool.isRequired,
    isReloadingData : PropTypes.bool,
    sections        : PropTypes.shape({}),
};

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" />
                    <div className="flex">
                        <Skeleton.Paragraph className="margin-right-small" quantity={1} width={70} />
                        <Skeleton.Paragraph quantity={1} width={180} />
                    </div>
                </Grid.Column>
            </Grid>
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading className="margin-bottom-medium" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubtitle width={110} className="margin-bottom-small" />
                            <Skeleton.Form type="input" quantity={2} />
                            <Skeleton.Title isSubtitle width={70} className="margin-top-medium margin-bottom-small" />
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(Editor);
