import React from 'react';
import EchoPanel from '../pages/_app';
import Panel from '../pages/panel';
import { Provider } from 'react-redux';
import initStore from './state/configureStore';

const store = initStore();

function App() {
    return (
        <Provider store={store}>
            <EchoPanel>
                <Panel />
            </EchoPanel>
        </Provider>
    );
}
export default App;
