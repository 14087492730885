import React from 'react';
import PropTypes from 'prop-types';
import RcSlider from 'rc-slider';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';

function Slider({
    label, value, onChange, min, max, onFocus, onBlur, isReadOnlyWithoutInputs, isDisabled, id, innerInputRef,
}) {
    const intl = useIntl();

    const handleOnChange = (valMin, valMax) => onChange({ target: { value: { min: valMin, max: valMax } } });
    const handleOnChangeMin = (e) => handleOnChange(e.target.value, value.max);
    const handleOnChangeMax = (e) => handleOnChange(value.min, e.target.value);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {!isReadOnlyWithoutInputs && (
                <>
                    <input
                        id={`${id}-min`}
                        type="number"
                        value={value.min}
                        onChange={handleOnChangeMin}
                        placeholder={intl?.formatMessage({ id: translated.global.min })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        tabIndex={isDisabled ? '-1' : '0'}
                        autoComplete="off"
                        ref={innerInputRef}
                    />
                    <RcSlider
                        range
                        className="slider"
                        min={min}
                        max={max}
                        defaultValue={[value.min, value.max]}
                        value={[value.min, value.max]}
                        tipFormatter={`${value}%`}
                        onChange={(val) => handleOnChange(val[0], val[1])}
                        disabled={isDisabled}
                    />
                    <input
                        id={`${id}-max`}
                        type="number"
                        value={value.max}
                        onChange={handleOnChangeMax}
                        placeholder={intl?.formatMessage({ id: translated.global.max })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        tabIndex={isDisabled ? '-1' : '0'}
                        autoComplete="off"
                    />
                </>
            )}
            {isReadOnlyWithoutInputs && (
                <span id={id}>
                    {value.min}
                    /
                    {value.max}
                </span>
            )}
        </>
    );
}

Slider.defaultProps = {
    id       : '',
    label    : '',
    value    : { min: 0, max: 10 },
    onChange : null,
    min      : 0,
    max      : 10,
    onFocus  : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isReadOnlyWithoutInputs : false,
    isDisabled              : false,
    innerInputRef           : null,
};

Slider.propTypes = {
    id    : PropTypes.string,
    label : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    value : PropTypes.shape({
        min : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        max : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    min                     : PropTypes.number,
    max                     : PropTypes.number,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Slider);
