import React, { useMemo, useContext } from 'react';
import Form, { FormError, FormNew } from 'Components/Form';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import yup, { alphanumericUnderScore } from 'Utils/yupHelper';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';

const schema = yup.object().shape({
    slug  : alphanumericUnderScore.required().max(100),
    label : yup.string().required().max(100),
});

function Editor({ onClose, data, isEditing, resources }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const handleOnSubmit = async (values) => {
        const reqConfig = {
            data: {
                ...values,
                isActive: typeof data.isActive !== 'undefined' ? data.isActive : true,
            },
            ...resources.available[isEditing ? 'patch' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });

            snackbar.show({ content: isEditing ? translated.tags.update.success : translated.tags.save.success, isSuccess: true });
        } catch (error) {
            throw new FormError(isEditing ? translated.tags.update.error : translated.tags.save.error, error, translated.tags.errors);
        }
    };

    const modalPropertiesForForm = useMemo(
        () => ({
            title   : isEditing ? translated.tags.editTitle : translated.tags.createTitle,
            buttons : [
                <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={onClose}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.SecondaryNew>,
                <Form.PrimaryNew variant="text" color="primary" key="fp">
                    {isEditing ? (
                        <FormattedMessage id={translated.global.buttons.edit} defaultMessage={translated.global.buttons.edit} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    )}
                </Form.PrimaryNew>,
            ],
        }),
        [isEditing, onClose],
    );

    const onFinish = () => {
        navigator.goToParentAndReload(false, false);
    };

    return (
        <Form.WrapperNew
            modalProperties={modalPropertiesForForm}
            schema={schema}
            initialValues={{ slug: data?.slug, label: data?.label }}
            formId="tags-form"
        >
            <FormNew buttonsWidth={{ base: 12, small: 6 }} onSubmit={handleOnSubmit} onFinish={onFinish}>
                <Form.InputNew
                    submitKey="slug"
                    isDense
                    type="text"
                    label={translated.tags.slug}
                    maxCharCount={100}
                    isDisabled={isEditing}
                />

                <Form.InputNew
                    submitKey="label"
                    isDense
                    type="text"
                    label={translated.tags.label}
                    maxCharCount={100}
                />
            </FormNew>
        </Form.WrapperNew>
    );
}

Editor.propTypes = {};

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default withRequest(Editor);
