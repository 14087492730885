import { hot } from 'react-hot-loader/root';
import React, { useContext, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import MainMenuItem from './MainMenuItem';
import Form from 'Components/Form';
import Dropdown from 'Components/Dropdown';
import getClassName from 'Utils/getClassName';
import getSections from './SideDrawerHelper';
import translated from 'Constants/labels/translated';
import { logout } from 'Utils/authMethods';
import { REFRESH_NOW } from 'Constants/global';
import { FormattedMessage, useIntl } from 'react-intl';
import About from '../NavBar/About';
import Settings from '../NavBar/Settings';
import LanguageSelector from '../NavBar/LanguageSelector';

const formatSectionName = (name) => name?.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);

function SideDrawer({ sections, refresh, language }) {
    const intl = useIntl();

    const { navigator, app, toggle, user, QA } = useContext(PanelContext);
    const [isAboutVisible, setAboutVisible] = useState(false);
    const [isSettingsVisible, setSettingsVisible] = useState(false);
    const [isChangeLanguageVisible, setChangeLanguageVisible] = useState(false);

    const dropdownRef = useRef(null);

    const renderMenuItem = useCallback((item) => {
        let elements = [];
        if (item.children) {
            elements.push(
                <li className="main-menu-item title animated fade-in-left fastest" id={`menu-item-group-${item.key}`} key={item.key}>
                    <span>{app.isSideDrawerCollapsed ? item.collapsedName : item.name}</span>
                </li>,
            );

            item.children
                .sort((first, second) => first.position - second.position)
                .forEach((e) => {
                    elements = [...elements, ...renderMenuItem(e)];
                });

            return [elements];
        }

        return [
            <MainMenuItem
                id={`menu-item-${formatSectionName(item.id)}`}
                section={item.section}
                selectSection={navigator.goWithoutClean}
                name={translated.sectionName[item.id]}
                isSelected={item.isSelected}
                isEnabled={item.isEnabled}
                icon={item.icon}
                hasNotifications={item.hasNotifications}
                notifications={item.notificationsAmount}
                key={item.name?.replace(' ', '').toLowerCase()}
                defaultSection={item.defaultSection}
                toggleSideDrawerVisibility={toggle.sideDrawerVisibility}
            />,
        ];
    }, [app.isSideDrawerCollapsed, navigator.goWithoutClean, toggle.sideDrawerVisibility]);

    const items = useMemo(() => (
        getSections(sections)
            .filter((item) => !!item.position)
            .sort((first, second) => first.position - second.position)
            .reduce((result, current) => {
                result.push(...renderMenuItem(current));

                return result;
            }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [language, sections, renderMenuItem]);

    const { firstName, lastName, links, role } = user || {};
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

    const handleDocumentationClick = useCallback((link) => {
        navigator.goToDocumentation({ available: link });

        // If the SideDrawer is visible it has 'show-menu' class and we need to hide it
        if (document.getElementsByClassName('show-menu').length) {
            toggle.sideDrawerVisibility();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator]);

    const handleReleasesClick = useCallback((link) => {
        navigator.goToReleases({ available: link });

        // If the SideDrawer is visible it has 'show-menu' class and we need to hide it
        if (document.getElementsByClassName('show-menu').length) {
            toggle.sideDrawerVisibility();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator]);

    const onOverlayClick = useCallback(() => {
        toggle.sideDrawerVisibility();

        // We close the dropdown menu
        if (dropdownRef?.current) {
            dropdownRef.current.close();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isAboutVisible && <About onClose={() => setAboutVisible(false)} />}
            {isSettingsVisible && <Settings onClose={() => setSettingsVisible(false)} links={links} />}
            {isChangeLanguageVisible && <LanguageSelector onClose={() => setChangeLanguageVisible(false)} />}
            <div
                id="side-drawer"
                key="side-drawer"
                className={getClassName(
                    {
                        showMenu    : app.isSideDrawerVisible,
                        isCollapsed : app.isSideDrawerCollapsed && !app.isSideDrawerVisible,
                    },
                    'side-drawer',
                )}
            >
                <div className="side-drawer-header">
                    <img
                        src={`/static/clients/${window.env.CLIENT_FILENAMES}/img/logo.png`}
                        className="logo"
                        alt="Logo"
                        onClick={navigator.goToDefaultSection}
                    />
                </div>
                <nav id="side-drawer-container" className="side-drawer-container">
                    <ul className="main-menu">{items}</ul>
                </nav>
                <div className="side-drawer-user">
                    <div id="user-name">
                        <div className="user-name">{fullName}</div>
                        <div className="user-name-id">{role}</div>
                    </div>
                    <Dropdown
                        id="user-settings"
                        className="user-menu-button"
                        icon="CogOutline"
                        tooltip={intl.formatMessage({
                            id             : translated.settings.options.title,
                            defaultMessage : translated.settings.options.title,
                        })}
                        classNameMenu="user-settings-dropdown"
                        ref={dropdownRef}
                    >
                        <Dropdown.Option id="settings-dark-mode" itemKey="dark-mode">
                            <Form.Input
                                id="settings-dark-mode-switch"
                                type="switch"
                                label={translated.settings.options.darkMode}
                                value={app.isDarkModeEnabled}
                                onClick={toggle.darkMode}
                            />
                        </Dropdown.Option>
                        <Dropdown.Option
                            id="settings-title"
                            itemKey="settings"
                            isEnabled={!!links?.configurations}
                            onClick={() => setSettingsVisible(true)}
                        >
                            <FormattedMessage id={translated.settings.title} defaultMessage={translated.settings.title} />
                        </Dropdown.Option>
                        {QA.isActive && (
                            <Dropdown.Option id="settings-refresh" itemKey="refresh" onClick={() => refresh(REFRESH_NOW)}>
                                <FormattedMessage id={translated.settings.buttons.refresh} defaultMessage={translated.settings.buttons.refresh} />
                            </Dropdown.Option>
                        )}
                        <Dropdown.Option id="change-language" itemKey="change-language" onClick={() => setChangeLanguageVisible(true)}>
                            <FormattedMessage
                                id={translated.settings.buttons.changeLanguage}
                                defaultMessage={translated.settings.buttons.changeLanguage}
                            />
                        </Dropdown.Option>
                        <Dropdown.Submenu title={translated.settings.buttons.help} id="help-submenu-documentation" itemKey="help-submenu-documentation">
                            <Dropdown.Option id="help-dropdown-documentation" itemKey="help-dropdown-documentation" onClick={() => handleDocumentationClick(links.documentation)}>
                                <FormattedMessage
                                    id={translated.settings.buttons.documentation}
                                />
                            </Dropdown.Option>
                            <Dropdown.Option id="help-dropdown-releases" itemKey="help-dropdown-releases" onClick={() => handleReleasesClick(links.releases)}>
                                <FormattedMessage id={translated.settings.buttons.releases} />
                            </Dropdown.Option>
                        </Dropdown.Submenu>
                        <Dropdown.Option id="settings-about" itemKey="about" onClick={() => setAboutVisible(true)}>
                            <FormattedMessage id={translated.settings.buttons.about} defaultMessage={translated.settings.buttons.about} />
                        </Dropdown.Option>
                        <Dropdown.Option id="settings-logout" itemKey="logout" onClick={logout}>
                            <FormattedMessage id={translated.settings.buttons.logout} defaultMessage={translated.settings.buttons.logout} />
                        </Dropdown.Option>
                    </Dropdown>
                </div>
                <div className="overlay" onClick={onOverlayClick} />
            </div>
        </>
    );
}

SideDrawer.propTypes = {
    sections : PropTypes.shape({}).isRequired,
    refresh  : PropTypes.func.isRequired,
    language : PropTypes.string.isRequired,
};

export default hot(SideDrawer);
