import * as types from 'State/types/panel';
import { APP_LOADED, SET_QA_ACTIVE } from 'State/types/qa';
import { requestStates, requestActions, requestsToHandle } from 'Constants/qa';
import { initialize, updateLastAction } from './helpers/qaGenerator';

const initialState = {};

const getLastAction = (method, data, state) => {
    if (requestsToHandle.indexOf(method) === -1) {
        return null;
    }

    let lastAction = requestActions[method];
    if (data?.id) {
        lastAction = `${lastAction}-${data.id}`;
    }

    lastAction = `${lastAction}-${state}`;

    return lastAction;
};

const updateAction = (method, data, state) => {
    const lastAction = getLastAction(method, data, state);

    if (lastAction) {
        updateLastAction(lastAction);
    }
};

const qaReducer = (state = initialState, action = { type: null }) => {
    if (action.type === SET_QA_ACTIVE) {
        return {
            ...state,
            isActive: true,
        };
    }

    if (state.isActive) {
        const { method, response } = action?.payload || {};
        switch (action.type) {
            case APP_LOADED: {
                initialize();
                break;
            }

            case types.REQUEST_START: {
                updateAction(method, response, requestStates.start);
                break;
            }

            case types.REQUEST_END: {
                updateAction(method, response, requestStates.end);
                break;
            }

            case types.REQUEST_ERROR: {
                updateAction(method, response, requestStates.error);
                break;
            }

            case types.DIRECT_REQUEST_START: {
                updateAction(method, response, requestStates.start);
                break;
            }

            case types.DIRECT_REQUEST_END: {
                updateAction(method, response, requestStates.end);
                break;
            }

            case types.DIRECT_REQUEST_ERROR: {
                updateAction(method, response, requestStates.error);
                break;
            }

            default:
                break;
        }
    }

    return state;
};

export default qaReducer;
