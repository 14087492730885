import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import ModalList from 'Components/Sections/Shared/Rules/ModalList';
import { sectionType } from 'Constants/types';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from 'Components/Sections/Collection';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';

const getActions = (navigator, confirmation, snackbar, handleClickChangeStatus, handleOnEditClick, handleOnViewRules, resources) => {
    const actions = [
        {
            content  : translated.properties.viewRules,
            callback : handleOnViewRules,
            key      : 'viewRules',
            when     : (propertyGroup) => propertyGroup?.links?.rules?.read,
        },
        {
            content  : translated.global.buttons.disable,
            callback : (propertyGroup) => handleClickChangeStatus(propertyGroup, false),
            key      : 'disable',
            when     : (propertyGroup) => propertyGroup?.links?.self?.patch && propertyGroup.isActive,
        },
        {
            content  : translated.global.buttons.enable,
            callback : (propertyGroup) => handleClickChangeStatus(propertyGroup, true),
            key      : 'enable',
            when     : (propertyGroup) => propertyGroup?.links?.self?.patch && !propertyGroup.isActive,
        },
        {
            callback          : handleOnEditClick,
            key               : 'view',
            isOutsideDropdown : true,
            getIcon           : (propertyGroup) => (propertyGroup?.links?.self?.update ? 'Pencil' : 'Eye'),
            getTooltip        : (propertyGroup) => (propertyGroup?.links?.self?.update
                ? translated.global.buttons.edit
                : translated.global.buttons.view
            ),
            when: (propertyGroup) => propertyGroup?.links?.self?.read,
        },

        {
            content  : translated.global.buttons.delete,
            callback : (propertyGroup) => {
                confirmation.show({
                    message       : translated.propertyGroups.delete.confirmation,
                    acceptMessage : translated.global.buttons.delete,
                    onAccept      : async () => {
                        const { id } = propertyGroup;
                        const reqConfig = {
                            ...propertyGroup.links.self.delete,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : [id],
                            shouldReloadData : true,
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.PROPERTY_GROUPS_LIST, resources });

                            snackbar.show({ content: translated.propertyGroups.delete.success, isSuccess: true });
                        } catch (error) {
                            snackbar.show({
                                content     : translated.propertyGroups.delete.error,
                                errorLabels : translated.propertyGroups.delete,
                                isError     : true,
                                error,
                            });
                        }
                    },
                });
            },
            key  : 'delete',
            when : (propertyGroup) => propertyGroup?.links?.self?.delete,
        },
    ];

    return actions;
};

const convertList = (list) => list?.map((each) => ({
    ...each,
    convertedCategories: each.categories?.join(', ') || '',
})) || [];

function List({
    data, sections, fetching, reloadData, resources, setSectionState, state: { appliedFilters },
}) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const handleOnAddClick = () => navigator.goToPropertyGroupEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });

    const handleOnEditClick = (pg) => {
        navigator.goToPropertyGroupEditor({ available: pg?.links ? pg.links.self : {} });
    };

    const handleClickChangeStatus = async (propertyGroup, enable) => {
        const { id, links } = propertyGroup;
        const reqConfig = {
            ...links.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.PROPERTY_GROUPS, resources });

            snackbar.show({ content: enable ? translated.propertyGroups.enable.success : translated.propertyGroups.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.propertyGroups.enable.error : translated.propertyGroups.disable.error,
                isError : true,
                error,
            });
        }
    };

    const { links, data: list, order, pagination, filter: filterLinks } = data || {};

    const handleOnViewRules = async (propertyGroup) => {
        await navigator.goToPropertiesGroupRules(propertyGroup);
    };

    const handleClickCloseModal = async () => {
        await navigator.goToPropertiesGroup(links);
    };

    const propertyGroups = convertList(list);

    const columns = [
        {
            title     : translated.propertyGroups.code,
            key       : 'code',
            isVisible : true,
        },
        {
            title     : translated.propertyGroups.name,
            key       : 'name',
            isVisible : true,
        },
        {
            title     : translated.propertyGroups.isActive,
            key       : 'isActive',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
        },
    ];

    const rows = {
        actions  : getActions(navigator, confirmation, snackbar, handleClickChangeStatus, handleOnEditClick, handleOnViewRules, resources),
        subTable : {
            columns,
            id    : 'children',
            key   : 'children',
            title : translated.propertyGroups.subTableTitle,
            rows  : { actions: [] },
        },
    };

    const filterResources = {
        filters: [
            {
                type        : 'text',
                submitKey   : 'code',
                resourceKey : 'code',
                label       : translated.propertyGroups.code,
            },
            {
                type        : 'text',
                submitKey   : 'name',
                resourceKey : 'name',
                label       : translated.propertyGroups.name,
            },
            {
                type        : 'select',
                submitKey   : 'isActive',
                resourceKey : 'isActive',
                label       : translated.global.status,
                options     : [
                    { id: 'true', name: translated.global.state.enabled },
                    { id: 'false', name: translated.global.state.disabled },
                ],
            },
        ],
        filterLinks,
        resources,
    };

    const { Section: EditorSection, props: subsectionProps } = SelectedSection.getAny([
        { [sectionType.PROPERTY_GROUPS_EDITOR]: sections[sectionType.PROPERTY_GROUPS_EDITOR] },
    ]);
    const { Section: SectionRule, props: subsectionRuleProps } = SelectedSection.getAny([{ [sectionType.RULE]: sections[sectionType.RULE] }]);

    const rulesSection = sections[sectionType.RULES];
    const isModalOpen = rulesSection.isEnabled && rulesSection.isSelected;

    if (SectionRule) {
        return <SectionRule {...subsectionRuleProps} onClose={() => navigator.goToParentAndReload(true, false)} />;
    }

    const actionButtons = links?.self?.create
        ? [
            <Button id="property-groups-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <>
            {EditorSection && <EditorSection {...subsectionProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            {isModalOpen && <ModalList {...rulesSection} onClose={handleClickCloseModal} />}
            <Wrapper title={translated.propertyGroups.title} actionButtons={actionButtons}>
                <Table
                    id="property-groups"
                    key="property-groups"
                    columns={columns}
                    rows={rows}
                    items={propertyGroups}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    filterResources={filterResources}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config);
                    }}
                    appliedFilters={appliedFilters}
                    whenEmpty={(
                        <Alert
                            id="property-groups-empty"
                            content={translated.propertyGroups.empty}
                            actionText={translated.global.buttons.add}
                            onClick={handleOnAddClick}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={340} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    data            : [],
    fetching        : {},
    state           : null,
    setSectionState : null,
};

List.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

export default withRequest(List);
