import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import Subsection from 'Components/Sections/Subsection';
import Loading from 'Components/Loading';
import BalanceEditor from './BalanceEditor';
import CurrentBalance from './CurrentBalance';

const columns = [
    {
        title     : translated.owners.balance.transactions.balance,
        key       : 'pointsType',
        isVisible : true,
    },
    {
        title     : translated.owners.balance.transactions.contract,
        key       : 'legacyContractId',
        isVisible : true,
    },
    {
        title     : translated.owners.balance.clubPoints.date,
        key       : 'effectiveDate',
        isVisible : true,
        isDate    : true,
    },
    {
        title     : translated.owners.balance.clubPoints.expiryDate,
        key       : 'expiryDate',
        isVisible : true,
        isDate    : true,
    },
    {
        title       : translated.owners.balance.clubPoints.balanceType,
        key         : 'balanceType.level',
        isVisible   : true,
        fieldFormat : (data) => ({ value: String(data?.balanceType?.level) }),
    },
    {
        title       : translated.owners.balance.transactions.currency,
        key         : 'balanceType.currency.name',
        isVisible   : true,
        fieldFormat : (data) => ({ value: data?.balanceType?.currency?.name }),
    },
    {
        title     : translated.owners.balance.transactions.points,
        key       : 'transactionValue',
        isVisible : true,
        isNumeric : true,
    },
];

function WalletTransactions({ link, transactions, updateTransactions, isEditionEnabled, walletTransactionRef }) {
    const intl = useIntl();
    const { navigator, snackbar, dateManager } = useContext(PanelContext);
    const [isLoading, setLoading] = useState(false);
    const [balances, setBalances] = useState({ initial: [], filtered: [] });
    const [balancesView, setBalancesView] = useState({ isVisible: false, selected: [] });
    const [balanceEditor, setBalanceEditor] = useState({ isVisible: false, selected: [] });

    const byExpiryDate = (a, b) => {
        if (dateManager.isAfter(a.expiryDate, b.expiryDate)) {
            return 1;
        }
        if (dateManager.isSameOrAfter(a.expiryDate, b.expiryDate)) {
            return 0;
        }
        return -1;
    };

    useEffect(() => {
        const getBalances = async () => {
            if (link) {
                setLoading(true);
                const { summary: { data } } = await navigator.directRequest(link);
                const converted = data?.map((item) => ({
                    ...item,
                    balanceTypeLevel : item.balanceType?.level,
                    id               : item.id || Math.random(),
                }));
                // We need to clone the data to manipulate it and not to loose the object reference
                setBalances({ initial: converted, filtered: JSON.parse(JSON.stringify(converted)) });
                setLoading(false);
            }
        };
        getBalances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(
        walletTransactionRef,
        () => ({
            // Function used in the parent component to update the wallet transactions
            updateElements: (list) => {
                updateTransactions(list);

                setBalances((prev) => ({
                    ...prev,
                    filtered: prev.initial.filter((eachInitial) => !list.find((e) => e.id === eachInitial.id)),
                }));
            },
        }),
        [updateTransactions],
    );

    const handleManageTransactionsClick = () => {
        setBalancesView({ isVisible: true });
    };

    const handleBalanceEdition = (values) => {
        // Updates transactions
        const idsToFilter = values.map((value) => value.id);
        const newValues = transactions.filter((item) => !idsToFilter.includes(item.id));
        values.forEach((element) => {
            newValues.push(element);
        });
        newValues.sort(byExpiryDate);
        updateTransactions(newValues);

        // Updates balances filter
        let filteredBalances = balances.filtered;
        // Filter children
        filteredBalances.forEach((balance) => {
            if (balance.children.length > 0) {
                const filteredChildren = balance.children.filter((item) => !idsToFilter.includes(item.id));
                if (filteredChildren.length === 0) {
                    idsToFilter.push(balance.id);
                } else if (filteredChildren.length < balance.children.length) {
                    // eslint-disable-next-line no-param-reassign
                    balance.children = filteredChildren;
                }
            }
        });
        // Filter balances
        filteredBalances = filteredBalances.filter((item) => !idsToFilter.includes(item.id));
        setBalances((prev) => ({
            ...prev,
            filtered: filteredBalances,
        }));

        // Update views
        setBalancesView({ isVisible: false });
        setBalanceEditor({ isVisible: false, selected: [] });
    };

    const handleOnRemove = ({ id }) => {
        const updatedTransactions = transactions.filter((item) => item.id !== id);
        let removedBalance = balances.initial.find((item) => item.id === id);
        const updatedFilteredBalances = balances.filtered;
        // The removed balance it's not a child element
        if (removedBalance) {
            updatedFilteredBalances.push(removedBalance);
        } else {
            // Find the balance which contains the removed element
            balances.initial.forEach((balance) => {
                if (balance.children.length > 0) {
                    removedBalance = balance.children.find((item) => item.id === id);
                    if (removedBalance) {
                        const filterBalance = updatedFilteredBalances.find((b) => b.id === balance.id);
                        if (filterBalance) {
                            filterBalance.children.push(removedBalance);
                        } else {
                            // Should use the original balance with the children
                            const balanceCopy = { ...balance };
                            balanceCopy.children = balance.children.filter((item) => item.id === id);
                            updatedFilteredBalances.push(balanceCopy);
                        }
                    }
                }
            });
        }
        updatedFilteredBalances.sort(byExpiryDate);
        setBalances((prev) => ({
            ...prev,
            filtered: updatedFilteredBalances,
        }));
        updateTransactions(updatedTransactions);
    };

    const handleOnEdit = (balance) => {
        setBalanceEditor({ isVisible: true, selected: [balance] });
    };

    const shouldAddButton = transactions.length > 0 && transactions.length <= balances.filtered.length && isEditionEnabled;
    const actionButtons = shouldAddButton
        ? [
            <Button id="contracts-wallet-transaction" variant="outlined" color="primary" key="button_primary" onClick={handleManageTransactionsClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    const balanceBulkActions = [
        {
            key      : 'edit',
            content  : translated.global.buttons.edit,
            callback : (selected) => {
                setBalanceEditor({ isVisible: true, selected });
                setBalancesView({ isVisible: false });
            },
        },
    ];

    const actions = isEditionEnabled
        ? [
            {
                key               : 'edit',
                content           : translated.global.buttons.edit,
                callback          : handleOnEdit,
                isOutsideDropdown : true,
                icon              : 'Pencil',
                tooltip           : intl.formatMessage({
                    id             : translated.global.buttons.edit,
                    defaultMessage : translated.global.buttons.edit,
                }),
            },
            {
                key               : 'delete',
                content           : translated.global.buttons.delete,
                callback          : handleOnRemove,
                isOutsideDropdown : true,
                icon              : 'TrashCan',
                tooltip           : intl.formatMessage({
                    id             : translated.global.buttons.delete,
                    defaultMessage : translated.global.buttons.delete,
                }),
            },
        ]
        : [];

    return (
        <>
            {balancesView.isVisible && (
                <Modal
                    title={translated.owners.balance.editor.previewTitle}
                    cancelButton={{
                        text    : translated.global.buttons.back,
                        onClick : () => {
                            setBalancesView({ isVisible: false });
                        },
                    }}
                    className="balance-preview-modal"
                >
                    {isLoading && <Loading />}

                    {!balances.initial?.length && (
                        <p>
                            <FormattedMessage id={translated.upgrades.balances.empty} defaultMessage={translated.upgrades.balances.empty} />
                        </p>
                    )}

                    {!!balances.initial?.length && (
                        <>
                            <p className="margin-bottom-medium">
                                <FormattedMessage
                                    id={translated.owners.balance.editor.acceptOrRefuse}
                                    defaultMessage={translated.owners.balance.editor.acceptOrRefuse}
                                />
                            </p>
                            <CurrentBalance id="current-balance" items={balances.filtered} bulkActions={balanceBulkActions} />
                        </>
                    )}
                </Modal>
            )}
            {balanceEditor.isVisible && <BalanceEditor selectedBalances={balanceEditor.selected} onFinish={handleBalanceEdition} />}
            <Subsection title={translated.owners.contracts.wallet.title} actionButtons={actionButtons} snackbar={snackbar}>
                <Table
                    id="contract-wallet-transactions"
                    key="contract-wallet-transactions"
                    columns={columns}
                    actions={actions}
                    items={transactions}
                    hasTools={false}
                    rows={{ actions }}
                    whenEmpty={(
                        <Alert
                            id="contract-wallet-transactions-empty"
                            buttonId="contract-wallet-transactions-add-button"
                            content={translated.owners.contracts.wallet.empty}
                            actionText={isEditionEnabled ? translated.global.buttons.add : null}
                            onClick={isEditionEnabled ? handleManageTransactionsClick : null}
                        />
                    )}
                />
            </Subsection>
        </>
    );
}

WalletTransactions.displayName = 'WalletTransactions';

WalletTransactions.defaultProps = {
    link                 : null,
    transactions         : [],
    updateTransactions   : null,
    isEditionEnabled     : true,
    walletTransactionRef : () => {
        // Default
    },
};

WalletTransactions.propTypes = {
    link                 : PropTypes.shape({}),
    transactions         : PropTypes.arrayOf(PropTypes.shape({})),
    updateTransactions   : PropTypes.func,
    isEditionEnabled     : PropTypes.bool,
    walletTransactionRef : PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

function WalletTransactionsWithRef(props, ref) {
    return <WalletTransactions {...props} walletTransactionRef={ref} />;
}
WalletTransactionsWithRef.displayName = 'WalletTransactions';

export default React.memo(forwardRef(WalletTransactionsWithRef));
