module.exports = Object.freeze(
    {
        title       : 'apf.categories.title',
        emptyList   : 'apf.categories.emptyList',
        addChild    : 'apf.categories.addChild',
        noParent    : 'apf.categories.noParent',
        editTitle   : 'apf.categories.editTitle',
        createTitle : 'apf.categories.createTitle',
        save        : {
            success : 'apf.categories.save.success',
            error   : 'apf.categories.save.error',
        },
        update: {
            success : 'apf.categories.update.success',
            error   : 'apf.categories.update.error',
        },
        name       : 'apf.categories.name',
        parentName : 'apf.categories.parentName',
        enable     : {
            success : 'apf.categories.enable.success',
            error   : 'apf.categories.enable.error',
        },
        disable: {
            success : 'apf.categories.disable.success',
            error   : 'apf.categories.disable.error',
        },
        delete: {
            confirmation               : 'apf.categories.delete.confirmation',
            success                    : 'apf.categories.delete.success',
            error                      : 'apf.categories.delete.error',
            errorEntityCannotBeDeleted : 'apf.categories.delete.errorEntityCannotBeDeleted',
            defaultError               : 'apf.categories.delete.defaultError',
        },
    },
);
