import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import getClassName from 'Utils/getClassName';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function getAlertType(type) {
    let alertTypeClass = '';
    let alertTypeTitle = '';
    let alertTypeIcon = '';

    switch (type) {
        case 'error':
            alertTypeClass = 'is-error';
            alertTypeTitle = translated.global.alert.error;
            alertTypeIcon = 'AlertCircleOutline';
            break;
        case 'warning':
            alertTypeClass = 'is-warning';
            alertTypeTitle = translated.global.alert.warning;
            alertTypeIcon = 'AlertOutline';
            break;
        case 'success':
            alertTypeClass = 'is-success';
            alertTypeTitle = translated.global.alert.success;
            alertTypeIcon = 'CheckCircleOutline';
            break;
        case 'info':
            alertTypeClass = 'is-info';
            alertTypeTitle = translated.global.alert.info;
            alertTypeIcon = 'InformationOutline';
            break;
        default:
            break;
    }

    return { alertTypeClass, alertTypeTitle, alertTypeIcon };
}

function Alert(props) {
    const {
        className,
        content,
        contentValues,
        actionText,
        actionTextValues,
        onClick,
        onCloseClick,
        hasCloseButton: hasClose,
        actionDisabled,
        isBasic,
        icon,
        type,
        title,
        titleValues,
        id,
        buttonId,
        isSnackbar,
        withoutIcon,
        withoutTitle,
    } = props;

    const { alertTypeClass, alertTypeTitle, alertTypeIcon } = getAlertType(type);
    const alertTitle = (title || alertTypeTitle) && !withoutTitle;
    const alertIcon = (icon || alertTypeIcon) && !withoutIcon;

    const alertTypeClassName = getClassName({ hasAction: actionText, hasClose, hasIcon: alertIcon, hasTitle: alertTitle, isBasic, isSnackbar }, alertTypeClass, 'alert', className);

    return (
        <div className={alertTypeClassName}>
            {alertIcon && <Icon className="mdi-icon" path={IconPath[`mdi${icon || alertTypeIcon}`]} />}

            <div className="alert-content-wrapper">
                {alertTitle && (
                    <small className="alert-title">
                        <WrappedFormattedMessage content={title || alertTypeTitle} values={titleValues} />
                    </small>
                )}
                <span id={`${id}-alert-content`} className="alert-content">
                    <WrappedFormattedMessage content={content} values={contentValues} />
                </span>
            </div>

            {(actionText || hasClose) && (
                <div id={`${id}-alert-buttons`} className="alert-buttons">
                    {actionText && (
                        <Button id={buttonId || `${id}-alert-button-primary`} variant="text" color="primary" onClick={onClick} disabled={actionDisabled}>
                            <WrappedFormattedMessage content={actionText} values={actionTextValues} />
                        </Button>
                    )}
                    {hasClose && <Button id={`${id}-alert-button-close`} icon="Close" onClick={onCloseClick} />}
                </div>
            )}
        </div>
    );
}

Alert.defaultProps = {
    content          : '',
    contentValues    : null,
    className        : '',
    actionText       : '',
    actionTextValues : null,
    title            : '',
    titleValues      : null,
    type             : 'info',
    isBasic          : false,
    icon             : '',
    hasCloseButton   : false,
    onClick          : null,
    onCloseClick     : null,
    actionDisabled   : false,
    buttonId         : null,
    isSnackbar       : false,
    withoutIcon      : false,
    withoutTitle     : false,
};

Alert.propTypes = {
    className        : PropTypes.string,
    actionText       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    actionTextValues : PropTypes.shape({}),
    title            : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    titleValues      : PropTypes.shape({}),
    type             : PropTypes.string,
    isBasic          : PropTypes.bool,
    icon             : PropTypes.string,
    hasCloseButton   : PropTypes.bool,
    onClick          : PropTypes.func,
    onCloseClick     : PropTypes.func,
    content          : PropTypes.node,
    contentValues    : PropTypes.shape({}),
    actionDisabled   : PropTypes.bool,
    id               : PropTypes.string.isRequired,
    buttonId         : PropTypes.string,
    isSnackbar       : PropTypes.bool,
    withoutIcon      : PropTypes.bool,
    withoutTitle     : PropTypes.bool,
};

export default Alert;
