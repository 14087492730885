import Card from './components/Card';
import Table from './components/Table';
import Form from './components/Form';
import Title from './components/Title';
import Button from './components/Button';
import Breadcrumb from './components/Breadcrumb';
import Chips from './components/Chips';
import Paragraph from './components/Paragraph';

const skeleton = {
    Card,
    Table,
    Form,
    Title,
    Button,
    Breadcrumb,
    Chips,
    Paragraph,
};

export default skeleton;

export {
    Card,
    Table,
    Form,
    Title,
    Button,
    Breadcrumb,
    Chips,
    Paragraph,
};
