import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import Button from 'Components/Button';

function ConfirmationButton(props) {
    const { onClick, confirmation, ...restOfProps } = props;
    const { confirmation: confirmMessage } = useContext(PanelContext);

    const handleOnClick = () => {
        if (!confirmation) {
            onClick();
        } else {
            confirmMessage.show({
                title    : confirmation.title,
                message  : confirmation.message,
                onAccept : () => onClick(),
            });
        }
    };

    return <Button onClick={handleOnClick} {...restOfProps} />;
}

ConfirmationButton.defaultProps = {
    children     : null,
    confirmation : null,
    onClick      : () => {
        // Default
    },
};

ConfirmationButton.propTypes = {
    children     : PropTypes.node,
    onClick      : PropTypes.func,
    confirmation : PropTypes.shape({
        title   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        message : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }),
};

export default ConfirmationButton;
