import React from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import * as types from 'State/types/app';
import { Api } from 'Services';
import { socketNotificationTypes } from 'Constants/global';

export function setTitle(title) {
    return { type: types.TITLE_SET, payload: title };
}

export function toggleSideDrawerVisibility() {
    return { type: types.SIDE_DRAWER_VISIBLE_TOGGLE };
}

export function toggleSideDrawerCollapsed() {
    return { type: types.SIDE_DRAWER_COLLAPSE_TOGGLE };
}

export function toggleDarkMode() {
    return { type: types.DARK_MODE_TOGGLE };
}

export function toggleTour() {
    return { type: types.TOUR_TOGGLE };
}

export function toggleBreadcrumbTabsVisibility() {
    return { type: types.BREADCRUMB_TABS_TOGGLE };
}

export function clearAppError() {
    return { type: types.APP_ERROR_CLEAR };
}

export function toggleUseMock() {
    return { type: types.USE_MOCK_TOGGLE };
}

export function toggleIsLocalStorageLoaded() {
    return { type: types.IS_LOCAL_STORAGE_LOADED_TOGGLE };
}

export function setAppErrorState(title, status, isCritical, shouldRefreshUser) {
    return { type: types.APP_ERROR_STATE, payload: { title, status, isCritical, shouldRefreshUser } };
}

export function refreshUser(refresh = true) {
    return { type: types.USER_SHOULD_REFRESH, payload: refresh };
}

export function hideSnackbar(id) {
    return { type: types.SNACKBAR_HIDE, payload: { id } };
}

export function hideConfirmation() {
    return { type: types.CONFIRMATION_HIDE };
}

export function setUser(user) {
    return { type: types.USER_SET, payload: user };
}

export function setLanguage(language) {
    return { type: types.SET_LANGUAGE, payload: language };
}

export function setAppState({
    isSideDrawerVisible,
    isSideDrawerCollapsed,
    isDarkModeEnabled,
    areBreadcrumbTabsVisible,
    useMock,
    isLocalStorageLoaded,
    language,
}) {
    return {
        type    : types.APP_STATE_SET,
        payload : {
            isSideDrawerVisible,
            isSideDrawerCollapsed,
            isDarkModeEnabled,
            areBreadcrumbTabsVisible,
            useMock,
            isLocalStorageLoaded,
            language,
        },
    };
}

export const showSnackbar = ({
    className,
    content,
    contentValues,
    actionText,
    onClick,
    onCloseClick,
    isError,
    isWarning,
    isSuccess,
    error,
    errorLabels,
    errorHelp,
    timeout = 8000,
}) => async (dispatch, store) => {
    await dispatch({
        type    : types.SNACKBAR_SHOW,
        payload : {
            className,
            content,
            contentValues,
            actionText,
            onClick,
            onCloseClick,
            isError,
            isWarning,
            isSuccess,
            error,
            errorLabels,
            errorHelp,
            timeout,
        },
    });

    // Returns the id of the snackbar shown.
    const { app: { snackbar: { system: { id } } } } = store();
    return id;
};

export const handleNotification = ({ category, links, extra }, navigator) => {
    switch (category) {
        case socketNotificationTypes.CALL:
            // Check if the notification has the required elements
            if (extra?.firstName && extra?.lastName && links?.owners?.read) {
                const phoneMessage = extra.isMobile != null
                    ? (extra.isMobile && (
                        <FormattedMessage
                            id={translated.notifications.callNotification.mobile}
                            defaultMessage={translated.notifications.callNotification.mobile}
                        />
                    )) || (
                        <FormattedMessage
                            id={translated.notifications.callNotification.phone}
                            defaultMessage={translated.notifications.callNotification.phone}
                        />
                    )
                    : '';
                const content = (
                    <FormattedMessage
                        id={translated.notifications.callNotification.callFrom}
                        defaultMessage={translated.notifications.callNotification.callFrom}
                        values={{
                            number : extra.number || '-',
                            data   : `${extra.firstName} ${extra.lastName}`,
                        }}
                    />
                );
                const title = (
                    <FormattedMessage
                        id={translated.notifications.callNotification.title}
                        defaultMessage={translated.notifications.callNotification.title}
                        values={{ message: phoneMessage }}
                    />
                );
                const actionText = (
                    <FormattedMessage
                        id={translated.notifications.callNotification.goToProfile}
                        defaultMessage={translated.notifications.callNotification.goToProfile}
                    />
                );
                const onClick = () => {
                    navigator.goToDetails({ links: { self: { ...links.owners } } }, true);
                };

                return {
                    type    : types.SNACKBAR_SHOW,
                    payload : { content, actionText, onClick, title, isNotification: true },
                };
            }

            return {
                type    : types.SNACKBAR_SHOW,
                payload : {
                    content: (
                        <FormattedMessage
                            id={translated.notifications.callNotification.callFrom}
                            defaultMessage={translated.notifications.callNotification.callFrom}
                            values={{
                                number : extra.number || '-',
                                data   : (
                                    <FormattedMessage
                                        id={translated.notifications.callNotification.unknownOwner}
                                        defaultMessage={translated.notifications.callNotification.unknownOwner}
                                    />
                                ),
                            }}
                        />
                    ),
                    actionText : null,
                    onClick    : null,
                    title      : (
                        <FormattedMessage
                            id={translated.notifications.callNotification.title}
                            defaultMessage={translated.notifications.callNotification.title}
                        />
                    ),
                    isNotification: true,
                },
            };

        case socketNotificationTypes.PERMISSIONS_UPDATED:
            return { type: types.USER_SHOULD_REFRESH, payload: { refresh: true } };

        default:
        // Notification not handled
    }

    return null;
};

export function showConfirmation({
    className, title, titleValues, message, messageValues, onAccept, onCancel, acceptMessage, cancelMessage,
}) {
    return {
        type    : types.CONFIRMATION_SHOW,
        payload : {
            className,
            title,
            titleValues,
            message,
            messageValues,
            onAccept,
            onCancel,
            acceptMessage,
            cancelMessage,
        },
    };
}

export const initializeNotifications = ({ count, read }) => async (dispatch) => {
    let amount = null;

    if (count) {
        try {
            const { response } = await Api.request(count);

            amount = response.count;
        } catch (error) {
            // It wasn't possible to get the notification amount
        }
    }

    dispatch({
        type    : types.NOTIFICATIONS_SET,
        payload : {
            resources : { count, read },
            isEnabled : !!count,
            amount,
        },
    });
};

export const updateNotificationsAmount = () => async (dispatch, store) => {
    const { app: { notifications } } = store();

    const { resources: { count } } = notifications || { resources: {} };

    if (count) {
        try {
            const { response } = await Api.request(count);
            const amount = response.count;

            dispatch({
                type    : types.NOTIFICATIONS_SET,
                payload : { amount },
            });
        } catch (error) {
            // It wasn't possible to update the notification amount
        }
    }
};

export const updateNotifications = () => async (dispatch, store) => {
    const { app: { notifications } } = store();

    const {
        resources: { read },
        isLoading,
    } = notifications || { resources: {} };

    if (isLoading) {
        return;
    }

    if (!read) {
        dispatch({
            type    : types.NOTIFICATIONS_SET,
            payload : {
                isLoading : false,
                error     : (
                    <FormattedMessage
                        id={translated.notifications.updateNotificationNoLink}
                        defaultMessage={translated.notifications.updateNotificationNoLink}
                    />
                ),
            },
        });
        return;
    }

    try {
        dispatch({ type: types.NOTIFICATIONS_SET, payload: { isLoading: true, error: null } });
        const { response } = await Api.request(read);

        const newNotifications = {
            isLoading : false,
            data      : response?.data,
            error     : null,
        };

        dispatch({ type: types.NOTIFICATIONS_SET, payload: newNotifications });
        return;
    } catch (error) {
        dispatch({
            type    : types.NOTIFICATIONS_SET,
            payload : {
                isLoading : false,
                error     : (
                    <FormattedMessage
                        id={translated.notifications.updateNotificationGetError}
                        defaultMessage={translated.notifications.updateNotificationGetError}
                    />
                ),
            },
        });
    }
};

export const setAppAsRestricted = (isAppRestricted, message, reason) => ({
    type    : types.SET_APP_AS_RESTRICTED,
    payload : {
        isAppRestricted,
        message,
        reason,
    },
});
