import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
import { SelectedSection } from '../../Collection';

function Editor({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

Editor.propTypes = sectionPropTypes;

export default Editor;
