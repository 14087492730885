/* eslint-disable no-else-return */
import React, { useState, useMemo, useCallback, useContext } from 'react';
import Form, { FormError } from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import labels from 'Constants/labels';
import { codes } from 'Constants/requestErrors';
import { transactionState } from 'Constants/global';
import PanelContext from 'State/panelContext';

const MAX_POINTS_AMOUNT = 9999999;

const CONVERSION_PREFERENCE_DEFAULT = 'all';
const CONVERSION_PREFERENCE_CLUB_POINTS = 'club';
const CONVERSION_PREFERENCE_BONUS_POINTS = 'bonus';

function ConversionManager({ onClose, resources, data }) {
    const { confirmation, snackbar, navigator } = useContext(PanelContext);

    const { available } = resources;

    const [selectedRate, setSelectedRate] = useState(null);
    const [selectedConversionPreference, setSelectedConversionPreference] = useState();
    const [availableConversionPoints, setAvailableConversionPoints] = useState({
        all      : {},
        selected : null,
        loading  : false,
        error    : false,
    });
    const [availableExternalClubs, setAvailableExternalClubs] = useState({
        list     : [],
        selected : null,
        loaded   : false,
    });

    const [bookingCost, setBookingCost] = useState(MAX_POINTS_AMOUNT);
    const [conversionAmount, setConversionAmount] = useState(null);

    const contracts = data?.additionalResources?.contracts;
    const availableClubs = data?.additionalResources?.conversionRules?.map((each) => ({
        ...each,
        id               : each?.externalClub?.id,
        conversionRuleId : each?.id,
        value            : each?.externalClub?.id,
        content          : each?.externalClub?.name,
        links            : each.links,
    })) || [];

    // Use of Memo avoid re-renders on Form.Wrapper
    const modalPropertiesForForm = useMemo(() => ({
        title     : labels.owners.balance.pointsOperations,
        className : 'points-conversion-modal',
        buttons   : [
            <Form.Secondary variant="text" color="primary" key="fs" onClick={onClose}>{labels.global.buttons.cancel}</Form.Secondary>,
            <Form.Primary variant="text" color="primary" key="fp">{labels.owners.balance.convert}</Form.Primary>,
        ],
    }), [onClose]);

    const onContractChange = useCallback(async (contractId) => {
        try {
            const selectedContract = contracts.find((e) => e.id === contractId);

            const response = await navigator.directRequest({ ...selectedContract.links.conversionsRules.read });

            setAvailableExternalClubs({
                loaded : true,
                list   : response?.data?.map((e) => ({
                    ...e,
                    id               : e?.externalClub?.id,
                    value            : e?.externalClub?.id,
                    content          : e?.externalClub?.name,
                    conversionRuleId : e?.id,
                    links            : e.links,
                })),
            });
        } catch (e) {
            snackbar.show({
                isError : true,
                content : labels.owners.balance.conversionInitError,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts]);

    const onExternalClubChange = useCallback(async (externalClubId) => {
        const selectedExternalClub = availableExternalClubs.list.find((e) => e.id === externalClubId);
        setSelectedRate(selectedExternalClub);

        if (availableConversionPoints?.all?.[externalClubId]) {
            setAvailableConversionPoints((prev) => ({ ...prev, selected: prev?.all?.[externalClubId], error: false }));
        } else {
            if (selectedExternalClub?.links?.availableConversionPoints?.read) {
                try {
                    setAvailableConversionPoints((prev) => ({ ...prev, loading: true, error: false }));
                    const {
                        availablePointsConversions: {
                            bonus,
                            club: clubPoints,
                            total,
                            transfer: transactionPoints,
                        },
                    } = await navigator.directRequest(selectedExternalClub.links.availableConversionPoints.read);
                    const newAvailableConversionPoints = {
                        total,
                        clubPoints,
                        transactionPoints,
                        bonusPoints: bonus,
                    };

                    setAvailableConversionPoints((prev) => ({
                        all      : { ...prev.all, rateId: newAvailableConversionPoints },
                        selected : newAvailableConversionPoints,
                        loading  : false,
                    }));
                    return;
                } catch (e) {
                    snackbar.show({ content: labels.owners.balance.conversionInitError, isError: true });
                    setAvailableConversionPoints((prev) => ({
                        ...prev,
                        selected : null,
                        loading  : false,
                        error    : true,
                    }));
                    return;
                }
            }

            setAvailableConversionPoints((prev) => ({ ...prev, selected: null }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableConversionPoints, availableExternalClubs.list]);

    const sendRequest = useCallback(async (reqConfig, operation) => {
        try {
            const { state } = await navigator.requestForCurrentPath({ reqConfig, resources });

            if (state === transactionState.CONFIRMED) {
                snackbar.show({ content: labels.owners.balance.conversionSaved, isSuccess: true });
            } else if (available.charge) {
                snackbar.show({ content: labels.owners.balance.chargeSaved, isSuccess: true });
            }
        } catch (e) {
            // Its a conversion, the backend said that the owner has unpaid balances and the retry hasn't been made
            if (
                !reqConfig.data.allowUnpaidBalances
                && (e?.data?.errors?.[0]?.code === codes.futureBalanceUnpaid || e?.data?.errors?.[0]?.code === codes.balanceUnpaid || e?.data?.errors?.[0]?.code === codes.ownerNoReservationAllowed)
            ) {
                if (e?.data?.errors?.[0]?.code === codes.ownerNoReservationAllowed) {
                    return new Promise((resolve, reject) => {
                        // Ask the agent if we should retry the operation, ignoring the no reservation allowed.
                        confirmation.show({
                            message       : labels.owners.balance.ownerNoReservationAllowed,
                            acceptMessage : labels.global.buttons.yes,
                            cancelMessage : labels.global.buttons.no,
                            onAccept      : async () => {
                                try {
                                    await sendRequest({ ...reqConfig, data: { ...reqConfig.data, noReservationAllowed: false } }, operation);
                                    resolve();
                                } catch (er) {
                                    reject(er);
                                }
                            },
                            onCancel: async () => {
                                reject();
                            },
                        });
                    });
                } else {
                    return new Promise((resolve, reject) => {
                        // Ask the agent if we should retry the operation, ignoring the unpaid balances.
                        confirmation.show({
                            message       : labels.owners.balance.balanceUnpaid,
                            acceptMessage : labels.global.buttons.yes,
                            cancelMessage : labels.global.buttons.no,
                            onAccept      : async () => {
                                try {
                                    await sendRequest({ ...reqConfig, data: { ...reqConfig.data, allowUnpaidBalances: true } }, operation);
                                    resolve();
                                } catch (er) {
                                    reject(er);
                                }
                            },
                            onCancel: async () => {
                                reject();
                            },
                        });
                    });
                }
            }
            if (e?.data?.errors?.[0]?.code === codes.errorFundsAmount) {
                setBookingCost(Number(e.data.errors[0].messages?.[2]));
            }
            throw new FormError(labels.owners.balance.cantSaveConversion, e, labels.bookings.errors);
        }

        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available.charge, resources]);

    const handleOnSubmit = useCallback(async ({ values: { operation, amount, reason, conversionPreference, contract } }) => {
        // Fullfil the config for the request according to the selected operation
        // We don't need to check for the existence of available.allocation/conversion/transfer because if the user
        // has no permissions he will not see the buttons to select that operation, this check is made in the view
        const reqConfig = {
            ...available.create,
            shouldReloadData : true,
            data             : {
                reason              : reason || null,
                conversionRule      : { id: selectedRate.conversionRuleId },
                burn                : conversionPreference,
                feePaymentReference : null,
                contract,
                amount,
            },
        };

        await (sendRequest(reqConfig, operation));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available.create, selectedRate?.conversionRuleId, sendRequest]);

    let minimumPointsAmount = 1;
    let maximumPointsAmount = bookingCost;

    // Summary - Information fot the user about the current conversion, according to selected operation we show the corresponding fields
    // Each conditional adds some information of the current selections to the summary
    let summaryFields = [];

    let convertedPoints = null;
    if (selectedRate) {
        summaryFields = [...summaryFields, {
            label        : labels.owners.balance.minimumPoints,
            value        : selectedRate.minimum,
            key          : 'minimum-points',
            summaryIndex : 10,
        }, {
            label        : labels.owners.balance.rate,
            value        : selectedRate.ratio,
            key          : 'rate',
            summaryIndex : 11,
        }];

        minimumPointsAmount = selectedRate.minimum;
        maximumPointsAmount = availableConversionPoints?.selected?.total;

        if (conversionAmount) {
            const remainingPoints = Math.round(parseFloat(maximumPointsAmount) - conversionAmount);
            convertedPoints = Math.round(parseFloat(selectedRate.ratio) * conversionAmount);

            summaryFields = [...summaryFields,
                {
                    label : labels.owners.balance.remainingPoints,
                    // eslint-disable-next-line no-restricted-globals
                    value : isNaN(remainingPoints)
                        ? <span className="text-color-error">-</span>
                        : <span className={remainingPoints >= 0 ? 'text-color-warning' : 'text-color-error'}>{remainingPoints}</span>,
                    key          : 'remaining-points',
                    summaryIndex : 20,
                },
                {
                    label        : labels.owners.balance.convertedPoints,
                    value        : <span className="text-color-success">{convertedPoints}</span>,
                    key          : 'converted-points',
                    summaryIndex : 21,
                },
            ];
        }
    }

    if (conversionAmount && availableConversionPoints?.selected) {
        const selectedClub = availableClubs.find((e) => e.id === availableConversionPoints?.selected?.id);
        if (selectedClub?.fee) {
            summaryFields = [...summaryFields,
                {
                    label        : labels.owners.balance.conversionPreference.summary.fee,
                    value        : parseFloat(selectedClub.fee) * conversionAmount,
                    key          : 'conversion-fee',
                    summaryIndex : 12,
                },
            ];
        }
    }

    if (availableConversionPoints?.selected || availableConversionPoints?.loading || availableConversionPoints?.error) {
        summaryFields = [...summaryFields,
            {
                label : labels.owners.balance.conversionPreference.summary.title,
                value :
                    (availableConversionPoints?.loading && <span className="text-color-muted">{labels.owners.balance.conversionPreference.summary.loading}</span>)
                    || (availableConversionPoints?.error && <span className="text-color-error">{labels.owners.balance.conversionPreference.summary.error}</span>)
                    || null,
                key          : 'conversion-title',
                summaryIndex : 13,
                type         : 'title',
            },
        ];
    }

    if (availableConversionPoints?.selected) {
        summaryFields = [...summaryFields,
            {
                label        : labels.owners.balance.conversionPreference.summary.total,
                value        : availableConversionPoints.selected.total,
                key          : 'conversion-total',
                summaryIndex : 14,
            },
            {
                label        : labels.owners.balance.conversionPreference.summary.clubPoints,
                value        : availableConversionPoints.selected.clubPoints,
                summaryIndex : 15,
            },
            {
                label        : labels.owners.balance.conversionPreference.summary.transactionPoints,
                value        : availableConversionPoints.selected.transactionPoints,
                key          : 'conversion-transaction-points',
                summaryIndex : 16,
            },
            {
                label        : labels.owners.balance.conversionPreference.summary.bonusPoints,
                value        : availableConversionPoints.selected.bonusPoints,
                key          : 'conversion-balance-types',
                summaryIndex : 17,
            },
        ];
    }

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form onSubmit={handleOnSubmit} onFinish={onClose} avoidConfirmation>
                <Form.Input
                    type="select"
                    submitKey="contract"
                    label={labels.owners.balance.contract}
                    options={contracts.map((each) => ({ id: each.id, value: each.id, content: each.legacyId }))}
                    submitFormat={(id) => ({ id })}
                    summaryFormat={({ value }) => contracts.find((contract) => contract.id === value).legacyId}
                    summaryIndex={1}
                    onChange={onContractChange}
                    isDense
                />
                <Form.Input
                    type="select"
                    label={labels.owners.balance.vacationClub}
                    submitKey="externalClub"
                    avoidOnSummary
                    options={availableExternalClubs.list}
                    onChange={onExternalClubChange}
                    summaryIndex={2}
                    isDisabled={!availableExternalClubs.loaded}
                    isDense
                    isRequired
                    avoidOnSubmit
                />

                <Form.Input
                    type="select"
                    label={labels.owners.balance.conversionPreference.title}
                    submitKey="conversionPreference"
                    avoidOnSummary
                    options={[
                        {
                            value   : CONVERSION_PREFERENCE_DEFAULT,
                            content : labels.owners.balance.conversionPreference.default,
                        },
                        {
                            value   : CONVERSION_PREFERENCE_CLUB_POINTS,
                            content : labels.owners.balance.conversionPreference.clubPoints,
                        },
                        {
                            value   : CONVERSION_PREFERENCE_BONUS_POINTS,
                            content : labels.owners.balance.conversionPreference.bonusPoints,
                        },
                    ]}
                    onChange={(newValue) => setSelectedConversionPreference(newValue)}
                    helperText={{
                        label: (selectedConversionPreference === CONVERSION_PREFERENCE_DEFAULT
                            && labels.owners.balance.conversionPreference.defaultSupport)
                            || (selectedConversionPreference === CONVERSION_PREFERENCE_CLUB_POINTS
                                && labels.owners.balance.conversionPreference.clubPointsSupport)
                            || (selectedConversionPreference === CONVERSION_PREFERENCE_BONUS_POINTS
                                && labels.owners.balance.conversionPreference.bonusPointsSupport),
                    }}
                    isDense
                    isRequired
                />

                <Form.Input
                    type="number"
                    onChange={(val) => setConversionAmount(val)}
                    label={labels.owners.balance.amount}
                    validations={{ minValue: minimumPointsAmount, maxValue: maximumPointsAmount != null ? maximumPointsAmount : MAX_POINTS_AMOUNT, unary: ['numbers'] }}
                    submitKey="amount"
                    suffix={labels.owners.balance.suffixPoints}
                    avoidOnSummary
                    isRequired
                />

                <Form.Input
                    type="textarea"
                    label={labels.owners.balance.reason}
                    submitKey="reason"
                    avoidOnSummary
                    isDense
                    validations={{ maxLength: 100 }}
                    charCount={{ total: 100 }}
                />
            </Form>
            <div className="points-conversion-summary-wrapper">
                <Form.Summary className="points-conversion-summary" extraFields={summaryFields} />
            </div>
        </Form.Wrapper>
    );
}

ConversionManager.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default withRequest(ConversionManager);
