import React from 'react';
import PropTypes from 'prop-types';

function Card({ className, hasPicture, hasTitle, hasDescription, hasActions, unelevated }) {
    const hoverClass = unelevated ? '' : 'card-hover';

    return (
        <div className={`skeleton skeleton-card ${hoverClass} ${className}`}>
            {hasPicture && <div className="skeleton-element skeleton-card-picture" />}
            {hasTitle && (
                <div className="skeleton-card-header">
                    <div className="skeleton-element skeleton-card-title" />
                    <div className="skeleton-element skeleton-card-subtitle" />
                </div>
            )}
            {hasDescription && hasDescription?.paragraphs && (
                <div className="skeleton-card-body">
                    {Array.apply(0, Array(hasDescription?.paragraphs)).map((x, i) => <div className="skeleton-element skeleton-card-paragraph" key={`${hasDescription.paragraphs + i}`} />)}
                </div>
            )}
            {hasActions && (
                <div className="skeleton-card-actions">
                    <div className="skeleton-element skeleton-card-button" />
                </div>
            )}
        </div>
    );
}

Card.defaultProps = {
    className      : '',
    hasPicture     : true,
    hasTitle       : true,
    hasDescription : null,
    hasActions     : true,
    unelevated     : false,
};

Card.propTypes = {
    className      : PropTypes.string,
    hasPicture     : PropTypes.bool,
    hasTitle       : PropTypes.bool,
    hasDescription : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ paragraphs: PropTypes.number })]),
    hasActions     : PropTypes.bool,
    unelevated     : PropTypes.bool,
};

export default Card;
