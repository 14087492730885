import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';

function Text({
    label,
    value,
    onChange,
    isReadOnlyWithoutInputs,
    onFocus,
    onBlur,
    isDisabled,
    id,
    innerInputRef,
    linkHref,
    linkClassName,
    onClick,
    validations,
    forceLimit,
    onKeyPress,
}) {
    const intl = useIntl();

    const maxLength = forceLimit && validations?.maxLength ? validations?.maxLength : null;
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    useEffect(() => {
        if (onKeyPress && innerInputRef?.current) {
            innerInputRef.current.addEventListener('keyup', onKeyPress, false);
        }
        return () => {
            if (onKeyPress && innerInputRef?.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                innerInputRef.current.removeEventListener('keyup', onKeyPress, false);
            }
        };
    }, [innerInputRef, onKeyPress]);

    return (
        <>
            {!isReadOnlyWithoutInputs && (
                <input
                    id={id}
                    key={id}
                    ref={innerInputRef}
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={isDisabled ? '-1' : '0'}
                    autoComplete="off"
                    maxLength={maxLength}
                />
            )}
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {isReadOnlyWithoutInputs && (linkHref || onClick) && (
                <a className={linkClassName} href={linkHref || null} onClick={onClick}>
                    <span id={id}>{value}</span>
                </a>
            )}
            {isReadOnlyWithoutInputs && !linkHref && !onClick && <span id={id}>{value}</span>}
        </>
    );
}

Text.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange      : null,
    isDisabled    : false,
    innerInputRef : null,
    linkHref      : '',
    linkClassName : '',
    validations   : null,
    forceLimit    : false,
    onClick       : null,
    onKeyPress    : null,
};

Text.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    value                   : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
    linkHref                : PropTypes.string,
    linkClassName           : PropTypes.string,
    validations             : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
    forceLimit              : PropTypes.bool,
    onClick                 : PropTypes.func,
    onKeyPress              : PropTypes.func,
};

export default React.memo(Text);
