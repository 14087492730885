module.exports = Object.freeze({
    title                    : 'Roles',
    creationTitle            : 'New Role',
    editionTitle             : 'Edit',
    permissions              : 'Permissions',
    name                     : 'Name',
    publicPermissionsTooltip : 'Public permissions',
    all                      : 'all',

    enable: {
        success : 'The role has been enabled',
        error   : 'The role could not be enabled',
    },
    disable: {
        success : 'The role has been disabled',
        error   : 'The role could not be disabled',
    },

    delete: {
        confirmation               : 'Delete role?',
        success                    : 'The role has been removed',
        error                      : 'The role could not be removed',
        errorEntityCannotBeDeleted : 'The role could not be removed since it is being used',
    },

    create: {
        success : 'The role has been added',
        error   : 'The role could not be added',
    },

    save: {
        success : 'The role has been updated',
        error   : 'The role could not be updated',
    },

    errors: { entityIsReadonly: 'The role is read-only' },

    wizard: {
        step1   : 'Activate/deactivate all permissions by clicking here',
        step2   : 'Show/hide all permissions by clicking here',
        step3   : 'Activate/deactivate permissions by clicking the checkbox next to it',
        step4_1 : 'The permissions with this icon (',
        step4_2 : ') will be activated because the selected permission requires them',
        step5_1 : 'Permissions with this icon (',
        step5_2 : ') will be deactivated because they require the permission that is being unselected',
    },
});
