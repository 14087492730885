import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'Components/Modal';
import { Link } from 'Components/Button';
import translated from 'Constants/labels/translated';

function CSVHelp({ onClose: onClick }) {
    return (
        <Modal
            title={translated.owners.imports.title}
            className="csv-modal"
            closeButton={{
                text: translated.global.buttons.close,
                onClick,
            }}
        >
            <ul className="text-color-secondary">
                <li className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                    <FormattedMessage defaultMessage={translated.owners.imports.template} id={translated.owners.imports.template} />
                </li>
                <li>
                    <FormattedMessage defaultMessage={translated.owners.imports.help} id={translated.owners.imports.help} />
                </li>
            </ul>
            <Link
                id="owners-import-csv-help-download"
                withoutRipple
                variant="unelevated"
                link={{
                    url    : '/static/resources/example.csv',
                    method : 'GET',
                }}
                icon="FileDownload"
                className="margin-top-small margin-bottom-small"
            >
                <FormattedMessage defaultMessage={translated.owners.imports.download} id={translated.owners.imports.download} />
            </Link>

            <ul className="text-color-secondary margin-top-medium">
                <li className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                    <FormattedMessage defaultMessage={translated.owners.imports.csv} id={translated.owners.imports.csv} />
                </li>
                <li>
                    <FormattedMessage defaultMessage={translated.owners.imports.csvFormat} id={translated.owners.imports.csvFormat} />
                </li>
            </ul>

            <Link
                id="owners-import-csv-help-download"
                withoutRipple
                variant="unelevated"
                link={{
                    url    : '/static/resources/CSV_Format_Documentation.pdf',
                    method : 'GET',
                }}
                icon="FileDownload"
                className="margin-top-small"
            >
                <FormattedMessage defaultMessage={translated.owners.imports.pdf} id={translated.owners.imports.pdf} />
            </Link>
        </Modal>
    );
}

CSVHelp.propTypes = { onClose: PropTypes.func.isRequired };

export default CSVHelp;
