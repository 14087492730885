import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Form, { FormError } from 'Components/Form';
import Alert from 'Components/Alert';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';
import { codes } from 'Constants/requestErrors';
import globalConstants, { transactionState } from 'Constants/global';
import PanelContext from 'State/panelContext';
import Loading from 'Components/Loading';

const ERROR_TYPE = -1;
const TYPE_ALLOCATION = 0;
const TYPE_TRANSFER = 2;
const TYPE_REFUND = 3;
const TYPE_CHARGE = 4;
const MAX_POINTS_AMOUNT = 9999999;
const MINIMUM_INTEGER_POINTS = 1;
const MINIMUM_FLOAT_POINTS = 0.01;

const newBalanceOption = (intl) => ({
    value   : -9999,
    content : intl.formatMessage({ id: translated.owners.contracts.wallet.newBalance, defaultMessage: translated.owners.contracts.wallet.newBalance }),
});

function WalletManager({ onClose, resources, directRequest, requestForCurrentPath, showSnackbar }) {
    const intl = useIntl();
    const { snackbar, navigator, dateManager } = useContext(PanelContext);

    const { available, additional } = resources;
    const { allocation, transfer, charge, refund } = available || {};
    const {
        contracts = [], balanceTypes = [], balances = [], balancesDestination = [], balancesCharge = [], balancesRefund = [], links,
    } = additional || {};

    const [selectedBalance, setSelectedBalance] = useState(null);
    const [selectedChargeBalance, setSelectedChargeBalance] = useState(null);
    const [selectedRefundBalance, setSelectedRefundBalance] = useState(null);

    const [bookingCost, setBookingCost] = useState(MAX_POINTS_AMOUNT);
    const [dateToTransferTo, setDateToTransferTo] = useState(null);
    const [conversionAmount, setConversionAmount] = useState(null);
    const [operationType, setOperationType] = useState(() => {
        if (charge) {
            return TYPE_CHARGE;
        }
        if (allocation) {
            return TYPE_ALLOCATION;
        }
        if (transfer) {
            return TYPE_TRANSFER;
        }
        return ERROR_TYPE;
    });

    let maximumPointsAmount = operationType === TYPE_TRANSFER && selectedBalance ? selectedBalance.balance : bookingCost;
    const [validations, setValidations] = useState({ minValue: MINIMUM_INTEGER_POINTS, maxValue: MAX_POINTS_AMOUNT, unary: ['numbers'] });

    const [availableAllocationPoints, setAvailableAllocationPoints] = useState({
        allocation : 0,
        state      : available.charge ? globalConstants.DONE : globalConstants.GETTING_DATA,
    });

    const [conversionData, setConversionData] = useState({
        filteredDestinations  : [],
        selectedOrigin        : null,
        selectedDestiny       : null,
        isNewDestination      : false,
        availableBalanceTypes : [],
    });

    const [availableBalanceTypes, setAvailableBalanceTypes] = useState({
        isLoading : false,
        elements  : {
            '-1': balanceTypes?.map((e) => ({
                ...e,
                value   : e.id,
                content : e.name,
            })),
        },
    });

    const [selectedContracts, setSelectedContracts] = useState({
        [TYPE_ALLOCATION]      : -1,
        [TYPE_TRANSFER]        : -1,
        transactionBalanceType : null,
        allocationBalanceType  : null,
    });

    const [transferDates, setTransferDates] = useState({ shoulderLeft: null, shoulderRight: null });

    const availableBalancesForTransfer = useMemo(
        () => balances.map((each) => ({
            id      : each.id,
            value   : each.id,
            content : intl.formatMessage(
                {
                    id             : translated.owners.balance.availableBalance,
                    defaultMessage : translated.owners.balance.availableBalance,
                },
                {
                    id        : each.id,
                    effective : dateManager.defaultDateFormat(each.effectiveDate),
                    expiry    : dateManager.defaultDateFormat(each.expiryDate),
                    balance   : each.balance,
                },
            ),
            summaryContent: (
                <>
                    <div>
                        {`${intl.formatMessage({ id: translated.global.id, defaultMessage: translated.global.id })}: ${each.id} (${dateManager.defaultDateFormat(
                            each.effectiveDate,
                        )} - ${dateManager.defaultDateFormat(each.expiryDate)})`}
                    </div>
                    <div>
                        {`${intl.formatMessage({
                            id             : translated.owners.balance.shoulderLeft,
                            defaultMessage : translated.owners.balance.shoulderLeft,
                        })}: ${each.shoulderLeft}`}
                    </div>
                    <div>
                        {`${intl.formatMessage({
                            id             : translated.owners.balance.shoulderRight,
                            defaultMessage : translated.owners.balance.shoulderRight,
                        })}: ${each.shoulderRight}`}
                    </div>
                    <div>
                        {`${intl.formatMessage({
                            id             : translated.owners.balance.balanceType,
                            defaultMessage : translated.owners.balance.balanceType,
                        })}: ${each.balanceType.name}`}
                    </div>
                    <div>
                        {`${intl.formatMessage({ id: translated.owners.balance.currency, defaultMessage: translated.owners.balance.currency })}: ${
                            each.balanceType.currency?.name
                        }`}
                    </div>
                    <div>
                        {`${intl.formatMessage({ id: translated.owners.balance.available, defaultMessage: translated.owners.balance.available })}: ${
                            each.balance
                        }`}
                    </div>
                </>
            ),
        })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [balances],
    );

    const getBalanceTypes = async (contractId) => {
        if (!contractId || availableBalanceTypes?.elements?.[contractId]) {
            // The contract balance types are already loaded.
            return;
        }

        setAvailableBalanceTypes((prev) => ({ ...prev, isLoading: true }));

        try {
            const selectedContract = contracts.find((e) => e.base?.id === contractId);

            const response = await navigator.directRequest({ ...selectedContract.links.balanceTypes.read });

            setAvailableBalanceTypes((prev) => ({
                isLoading : false,
                elements  : {
                    ...prev.elements,
                    [contractId]: response?.data?.map((e) => ({
                        ...e,
                        value   : e.id,
                        content : e.name,
                    })),
                },
            }));
        } catch (e) {
            snackbar.show({
                content : translated.owners.balance.balanceTypeInitError,
                isError : true,
            });
        }
    };

    const updateValidations = (balanceType) => {
        const newValidations = balanceType.currency.isInteger
            ? { minValue: MINIMUM_INTEGER_POINTS, maxValue: maximumPointsAmount != null ? maximumPointsAmount : MAX_POINTS_AMOUNT, unary: ['numbers'] }
            : { minValue: MINIMUM_FLOAT_POINTS, maxValue: maximumPointsAmount != null ? maximumPointsAmount : MAX_POINTS_AMOUNT, unary: ['float'] };
        setValidations(newValidations);
    };

    const contractOnChange = async (contractId, operation) => {
        getBalanceTypes(contractId);
        setSelectedContracts((prev) => ({
            ...prev,
            [operation]            : contractId || -1,
            transactionBalanceType : '',
            allocationBalanceType  : '',
        }));
    };

    const onConversionOriginChange = (balanceId) => {
        const selectedOrigin = balances.find((e) => e.id === balanceId);
        setSelectedBalance(selectedOrigin);

        if (selectedOrigin) {
            setConversionData((prev) => {
                const filteredDestinations = balancesDestination
                    .filter((e) => e.id !== balanceId && e.balanceType?.currency?.id === selectedOrigin.balanceType?.currency?.id)
                    .map((e) => ({ id: e.id, value: e.id, content: e.pointsType }));

                return { ...prev, filteredDestinations: [newBalanceOption(intl), ...filteredDestinations], selectedOrigin };
            });
            maximumPointsAmount = operationType === TYPE_TRANSFER && selectedOrigin ? selectedOrigin.balance : bookingCost;
            updateValidations(selectedOrigin.balanceType);
        }
    };

    const onConversionDestinationChange = (balanceId) => {
        const selected = availableBalancesForTransfer.find((e) => e.id === balanceId);
        setConversionData((prev) => ({
            ...prev,
            selectedDestiny       : selected,
            isNewDestination      : balanceId === newBalanceOption(intl).value,
            availableBalanceTypes :
                balanceId === newBalanceOption(intl).value
                    ? balanceTypes.filter((e) => e.currency?.id === prev.selectedOrigin.balanceType?.currency?.id)
                    : [],
        }));
    };

    // Use of Memo avoid re-renders on Form.Wrapper
    const modalPropertiesForForm = useMemo(() => {
        let buttonText = translated.owners.balance.allocate;
        switch (operationType) {
            case TYPE_TRANSFER:
                buttonText = translated.owners.balance.transact;
                break;
            case TYPE_REFUND:
                buttonText = translated.owners.balance.refund;
                break;
            case TYPE_CHARGE:
                buttonText = translated.owners.balance.charge;
                break;
            default:
                break;
        }

        return {
            title     : translated.owners.balance.walletOperations,
            className : 'points-conversion-modal',
            buttons   : [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={onClose}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    <FormattedMessage id={buttonText} defaultMessage={buttonText} />
                </Form.Primary>,
            ],
        };
    }, [operationType, onClose]);

    const availablePointsIsError = useMemo(() => availableAllocationPoints.state !== globalConstants.DONE, [availableAllocationPoints]);

    // Available points need to be fetched after load the component
    useEffect(() => {
        async function getAvailablePoints() {
            if (allocation && links?.availableAllocationPoints) {
                try {
                    const { availablePointsAllocation } = await directRequest(links.availableAllocationPoints);
                    if (typeof availablePointsAllocation === 'number') {
                        setAvailableAllocationPoints({
                            allocation : availablePointsAllocation,
                            state      : globalConstants.DONE,
                        });
                    } else {
                        setAvailableAllocationPoints({
                            allocation : availablePointsAllocation,
                            state      : globalConstants.ERROR,
                        });
                    }
                } catch (e) {
                    showSnackbar({ content: translated.owners.balance.conversionInitError, isError: true });

                    setAvailableAllocationPoints((prev) => ({ ...prev, state: globalConstants.ERROR }));
                }
            }
        }
        // In charge and refund sections this is not needed nor available
        if (!available.charge) {
            getAvailablePoints();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directRequest, links]);

    const handleOnSubmit = useCallback(
        async ({
            values: {
                operation,
                amount,
                allocationBalanceType,
                transactionBalanceType,
                balanceCharge,
                balanceRefund,
                effectiveAndExpiryDates,
                reason,
                target,
                shoulders,
                source,
                allocationContract,
                transactionContract,
                destination,
            },
        }) => {
            // Fullfil the config for the request according to the selected operation
            // We don't need to check for the existence of available.allocation/conversion/transfer because if the user
            // has no permissions he will not see the buttons to select that operation, this check is made in the view
            let reqConfig;

            switch (operation) {
                case TYPE_ALLOCATION:
                    reqConfig = {
                        ...allocation.create,
                        shouldReloadData : true,
                        data             : {
                            reason,
                            balances: [
                                {
                                    balanceType  : allocationBalanceType,
                                    amount,
                                    parentId     : null,
                                    baseContract : allocationContract?.id ? allocationContract : null,
                                    ...effectiveAndExpiryDates,
                                },
                            ],
                        },
                    };
                    break;
                case TYPE_TRANSFER:
                    const contractData = destination.id !== newBalanceOption(intl).value
                        ? destination
                        : {
                            baseContract : transactionContract?.id ? transactionContract : undefined,
                            balanceType  : transactionBalanceType,
                            ...target,
                            ...shoulders,
                        };

                    reqConfig = {
                        ...transfer.create,
                        shouldReloadData : true,
                        data             : {
                            reason,
                            amount,
                            balanceOrigin      : source,
                            balanceDestination : contractData,
                        },
                    };
                    break;

                case TYPE_CHARGE:
                    reqConfig = { ...charge, data: { amount, balance: balanceCharge }, shouldReloadData: true };
                    break;

                case TYPE_REFUND:
                    reqConfig = { ...refund, data: { amount, balance: balanceRefund }, shouldReloadData: true };
                    break;

                default:
                    break;
            }

            const sendRequest = async (config) => {
                try {
                    const { state } = await requestForCurrentPath({ reqConfig: config, resources });

                    if (state === transactionState.CONFIRMED) {
                        showSnackbar({ content: translated.owners.balance.conversionSaved });
                    } else if (available.charge) {
                        showSnackbar({ content: translated.owners.balance.chargeSaved });
                    }
                } catch (e) {
                    if (e?.data?.errors?.[0]?.code === codes.errorFundsAmount) {
                        setBookingCost(Number(e.data.errors[0].messages?.[2]));
                    }

                    throw new FormError(translated.owners.balance.cantSaveConversion, e, translated.bookings.errors);
                }

                return null;
            };

            await sendRequest(reqConfig);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [allocation.create, intl, transfer.create, charge, refund, requestForCurrentPath, resources, available.charge, showSnackbar],
    );

    const handleOnChangeBalanceType = (id) => {
        const balanceType = availableBalanceTypes.elements[selectedContracts[TYPE_ALLOCATION]].find((item) => item.id === id);
        updateValidations(balanceType);

        setSelectedContracts((prev) => ({ ...prev, allocationBalanceType: id }));
    };

    // Summary - Information fot the user about the current conversion, according to selected operation we show the corresponding fields
    // Each conditional adds some information of the current selections to the summary
    let summaryFields = [];

    if (operationType === TYPE_TRANSFER && dateToTransferTo) {
        summaryFields = [
            ...summaryFields,
            {
                label        : translated.owners.balance.newPeriod,
                value        : <span>{dateToTransferTo}</span>,
                key          : 'points-type',
                summaryIndex : 6,
            },
        ];
    }

    if (
        ((operationType === TYPE_TRANSFER && selectedBalance)
            || (operationType === TYPE_CHARGE && selectedChargeBalance)
            || (operationType === TYPE_REFUND && selectedRefundBalance))
        && conversionAmount
    ) {
        let balance;
        let conversionAmountAux = conversionAmount;

        if (operationType === TYPE_TRANSFER) {
            ({ balance } = selectedBalance);
            conversionAmountAux *= -1;
        } else if (operationType === TYPE_REFUND) {
            ({ balance } = selectedRefundBalance);
        } else if (operationType === TYPE_CHARGE) {
            ({ balance } = selectedChargeBalance);
            conversionAmountAux *= -1;
        }
        const remainingPoints = parseFloat(balance) + parseFloat(conversionAmountAux);

        summaryFields = [
            ...summaryFields,
            {
                label : translated.owners.balance.remainingInPeriod,
                value : availablePointsIsError ? (
                    <span className="text-color-error">-</span>
                ) : (
                    <span className={remainingPoints > 0 ? 'text-color-warning' : 'text-color-error'}>{remainingPoints}</span>
                ),
                key          : 'remaining-points',
                summaryIndex : 9,
            },
        ];
    }

    if ((operationType === TYPE_CHARGE && selectedChargeBalance) || (operationType === TYPE_REFUND && selectedRefundBalance)) {
        const selectedBalanceType = operationType === TYPE_CHARGE ? selectedChargeBalance : selectedRefundBalance;
        const duration = (
            <FormattedMessage
                id={translated.owners.balance.duration}
                defaultMessage={translated.owners.balance.duration}
                values={{
                    start : dateManager.defaultDateFormat(selectedBalanceType.beginDate),
                    end   : dateManager.defaultDateFormat(selectedBalanceType.expiryDate),
                }}
            />
        );

        maximumPointsAmount = operationType === TYPE_CHARGE ? selectedChargeBalance.balance : maximumPointsAmount;

        summaryFields = [
            ...summaryFields,
            {
                label : translated.owners.balance.year,
                value : <span>{selectedBalanceType.year}</span>,
                key   : 'year',
            },
            {
                label : translated.owners.balance.periodDates,
                value : <span>{duration}</span>,
                key   : 'dates',
            },
        ];
    }

    // Ranges limit dates
    const datesMinValue = useMemo(() => {
        if (
            (operationType === TYPE_ALLOCATION || operationType === TYPE_TRANSFER)
            && selectedContracts[operationType]
            && selectedContracts[operationType] !== -1
        ) {
            const selectedContract = contracts.find((each) => each?.base?.id === selectedContracts[operationType]);

            if (selectedContract?.begin) {
                return dateManager.defaultDateFormat(selectedContract.begin);
            }
        }

        return dateManager.defaultDateFormat('2000-01-01');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationType, selectedContracts]);

    const datesMaxValue = useMemo(() => {
        if (
            (operationType === TYPE_ALLOCATION || operationType === TYPE_TRANSFER)
            && selectedContracts[operationType]
            && selectedContracts[operationType] !== -1
        ) {
            const selectedContract = contracts.find((each) => each?.base?.id === selectedContracts[operationType]);

            if (selectedContract?.end) {
                return dateManager.defaultDateFormat(selectedContract.end);
            }
        }

        return dateManager.maxDateInDefaultFormat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationType, selectedContracts]);

    const datesSupportText = useMemo(
        () => (
            <div>
                {datesMinValue ? (
                    <span>
                        <FormattedMessage
                            id={translated.global.validMinDate}
                            defaultMessage={translated.global.validMinDate}
                            values={{ date: datesMinValue }}
                        />
                    </span>
                ) : null}
                {datesMinValue ? <br /> : null}
                {datesMaxValue ? (
                    <span>
                        <FormattedMessage
                            id={translated.global.validMaxDate}
                            defaultMessage={translated.global.validMaxDate}
                            values={{ date: datesMaxValue }}
                        />
                    </span>
                ) : null}
            </div>
        ),
        [datesMinValue, datesMaxValue],
    );

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form onSubmit={handleOnSubmit} onFinish={onClose} avoidConfirmation>
                {availableBalanceTypes.isLoading && <Loading />}

                {(allocation || transfer || charge || refund) && (
                    <Form.Group
                        type="radio"
                        submitKey="operation"
                        avoidOnSummary
                        label={translated.owners.contracts.wallet.operation}
                        onChange={(op) => setOperationType(op)}
                        isRequired
                        name="operation"
                    >
                        {allocation && (
                            <Form.Input
                                value={TYPE_ALLOCATION}
                                label={translated.owners.balance.allocation}
                                supportText={translated.owners.balance.allocationInfo}
                            />
                        )}
                        {transfer && (
                            <Form.Input
                                value={TYPE_TRANSFER}
                                label={translated.owners.balance.transfer}
                                supportText={translated.owners.balance.transferInfo}
                            />
                        )}
                        {charge && (
                            <Form.Input
                                value={TYPE_CHARGE}
                                label={translated.owners.balance.charge}
                                supportText={translated.owners.balance.chargeInfo}
                            />
                        )}
                        {refund && (
                            <Form.Input
                                value={TYPE_REFUND}
                                label={translated.owners.balance.refund}
                                supportText={translated.owners.balance.refundInfo}
                            />
                        )}
                    </Form.Group>
                )}

                {operationType === TYPE_ALLOCATION && (
                    <Form.Input
                        type="select"
                        submitKey="allocationContract"
                        label={translated.owners.balance.contract}
                        options={contracts.map((each) => ({ id: each.id, value: each?.base?.id, content: each.legacyId }))}
                        submitFormat={(id) => (id ? { id } : null)}
                        summaryFormat={({ value }) => contracts.find((contract) => contract?.base?.id === value).legacyId}
                        summaryIndex={0}
                        isDense
                        hasCleanButton
                        onChange={(newValue) => contractOnChange(newValue, TYPE_ALLOCATION)}
                    />
                )}

                {operationType === TYPE_ALLOCATION && (
                    <Form.Input
                        type="select"
                        submitKey="allocationBalanceType"
                        label={translated.owners.balance.balanceType}
                        options={(selectedContracts[TYPE_ALLOCATION] && availableBalanceTypes.elements[selectedContracts[TYPE_ALLOCATION]]) || []}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value: balanceTypeId }) => {
                            const list = (selectedContracts[TYPE_ALLOCATION] && availableBalanceTypes.elements[selectedContracts[TYPE_ALLOCATION]]) || [];

                            return list.find((balanceType) => balanceType.id === balanceTypeId).name;
                        }}
                        onChange={handleOnChangeBalanceType}
                        summaryIndex={1}
                        isDense
                        isRequired
                        isDisabled={availableBalanceTypes.isLoading}
                        value={selectedContracts.allocationBalanceType}
                        isBasedOnState
                    />
                )}

                {operationType === TYPE_CHARGE && (
                    <Form.Input
                        type="select"
                        submitKey="balanceCharge"
                        label={translated.owners.balance.availablePeriods}
                        options={balancesCharge.map((each) => ({ id: each.id, value: each.id, content: `${each.pointsType} - Available: ${each.balance}` }))}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value: balanceId }) => balancesCharge.find((balance) => balance.id === balanceId).pointsType}
                        onChange={(balanceId) => setSelectedChargeBalance(balancesCharge.find((balance) => balance.id === balanceId))}
                        isDense
                        isRequired
                    />
                )}
                {operationType === TYPE_REFUND && (
                    <Form.Input
                        type="select"
                        submitKey="balanceRefund"
                        label={translated.owners.balance.availablePeriods}
                        options={balancesRefund.map((each) => ({ id: each.id, value: each.id, content: `${each.pointsType} - Available: ${each.balance}` }))}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value: balanceId }) => balancesRefund.find((balance) => balance.id === balanceId).pointsType}
                        onChange={(balanceId) => setSelectedRefundBalance(balancesRefund.find((balance) => balance.id === balanceId))}
                        isDense
                        isRequired
                    />
                )}

                {operationType === TYPE_TRANSFER && (
                    <Form.Input
                        type="select"
                        submitKey="source"
                        label={translated.owners.balance.availablePeriods}
                        options={availableBalancesForTransfer}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value }) => availableBalancesForTransfer.find((e) => e.value === value)?.summaryContent}
                        summaryIndex={1}
                        onChange={onConversionOriginChange}
                        isDense
                        isRequired
                    />
                )}

                {operationType === TYPE_TRANSFER && (
                    <Form.Input
                        type="select"
                        submitKey="destination"
                        label={translated.owners.balance.balanceDestination}
                        options={conversionData?.filteredDestinations}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value }) => conversionData?.filteredDestinations?.find((e) => e.value === value)?.content}
                        summaryIndex={2}
                        onChange={onConversionDestinationChange}
                        isDisabled={!conversionData?.selectedOrigin}
                        isDense
                        isRequired
                    />
                )}

                {operationType === TYPE_TRANSFER && conversionData?.isNewDestination && (
                    <Form.Input
                        type="select"
                        submitKey="transactionContract"
                        label={translated.owners.balance.contract}
                        options={contracts.map((each) => ({ id: each.id, value: each?.base?.id, content: each.legacyId }))}
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value }) => contracts.find((contract) => contract?.base?.id === value).legacyId}
                        summaryIndex={3}
                        isDense
                        hasCleanButton
                        onChange={(newValue) => contractOnChange(newValue, TYPE_TRANSFER)}
                    />
                )}

                {operationType === TYPE_TRANSFER && conversionData?.isNewDestination && (
                    <Form.Input
                        type="select"
                        submitKey="transactionBalanceType"
                        label={translated.owners.balance.balanceType}
                        options={
                            (selectedContracts[TYPE_TRANSFER]
                                // Filter the balance types for the selected balance.
                                && availableBalanceTypes.elements[selectedContracts[TYPE_TRANSFER]]?.filter(
                                    (e) => e.currency?.id === selectedBalance?.balanceType?.currency?.id,
                                ))
                            || []
                        }
                        submitFormat={(id) => ({ id })}
                        summaryFormat={({ value: balanceTypeId }) => {
                            const list = (selectedContracts[TYPE_TRANSFER] && availableBalanceTypes.elements[selectedContracts[TYPE_TRANSFER]]) || [];

                            return list.find((balanceType) => balanceType.id === balanceTypeId).name;
                        }}
                        summaryIndex={4}
                        isDense
                        isRequired
                        isDisabled={availableBalanceTypes.isLoading}
                        value={selectedContracts.transactionBalanceType}
                        onChange={(newValue) => setSelectedContracts((prev) => ({ ...prev, transactionBalanceType: newValue }))}
                        isBasedOnState
                    />
                )}

                {operationType === TYPE_ALLOCATION && (
                    <Form.Input
                        type="picker"
                        isRange
                        submitKey="effectiveAndExpiryDates"
                        label={translated.owners.balance.dates}
                        icon={{ name: 'Calendar', position: 'right' }}
                        minDate={datesMinValue}
                        maxDate={datesMaxValue}
                        validations={{
                            pickerMinDate : datesMinValue,
                            pickerMaxDate : datesMaxValue,
                            unary         : ['pickerDate'],
                        }}
                        submitFormat={(value) => ({ effectiveDate: value?.begin, expiryDate: value?.end })}
                        summaryFormat={({ value }) => (
                            <FormattedMessage
                                id={translated.global.effectiveToExpiry}
                                defaultMessage={translated.global.effectiveToExpiry}
                                values={{
                                    start : dateManager.defaultDateFormat(value.begin),
                                    end   : dateManager.defaultDateFormat(value.end),
                                }}
                            />
                        )}
                        summaryIndex={2}
                        shouldCenterInViewport
                        isDense
                        isRequired
                        supportText={datesSupportText}
                    />
                )}

                {operationType === TYPE_TRANSFER && conversionData?.isNewDestination && (
                    <Form.Input
                        type="picker"
                        isRange
                        submitKey="shoulders"
                        label={translated.owners.balance.newPeriodShoulders}
                        icon={{ name: 'Calendar', position: 'right' }}
                        minDate={datesMinValue}
                        maxDate={datesMaxValue}
                        validations={{
                            pickerMinDate : datesMinValue,
                            pickerMaxDate : datesMaxValue,
                            unary         : ['pickerDate'],
                        }}
                        submitFormat={(value) => ({ shoulderLeft: value?.begin, shoulderRight: value?.end })}
                        onChange={(value) => setTransferDates({ shoulderLeft: value.begin, shoulderRight: value.end })}
                        summaryIndex={5}
                        summaryFormat={({ value }) => (
                            <FormattedMessage
                                id={translated.owners.balance.duration}
                                defaultMessage={translated.owners.balance.duration}
                                values={{
                                    start : dateManager.defaultDateFormat(value.begin),
                                    end   : dateManager.defaultDateFormat(value.end),
                                }}
                            />
                        )}
                        shouldCenterInViewport
                        isDense
                        isRequired
                        supportText={datesSupportText}
                    />
                )}

                {operationType === TYPE_TRANSFER && conversionData?.isNewDestination && (
                    <Form.Input
                        type="picker"
                        isRange
                        submitKey="target"
                        label={translated.owners.balance.newPeriod}
                        icon={{ name: 'Calendar', position: 'right' }}
                        minDate={transferDates.shoulderLeft}
                        maxDate={transferDates.shoulderRight}
                        validations={{
                            pickerMinDate : transferDates.shoulderLeft,
                            pickerMaxDate : transferDates.shoulderRight,
                            unary         : ['pickerDate'],
                        }}
                        submitFormat={(value) => ({ beginDate: value?.begin, effectiveDate: value?.begin, expiryDate: value?.end })}
                        avoidOnSummary
                        onChange={(value) => setDateToTransferTo(
                            <FormattedMessage
                                id={translated.global.fromTo}
                                defaultMessage={translated.global.fromTo}
                                values={{
                                    start : dateManager.defaultDateFormat(value.begin),
                                    end   : dateManager.defaultDateFormat(value.end),
                                }}
                            />,
                        )}
                        summaryIndex={6}
                        shouldCenterInViewport
                        isDisabled={!transferDates.shoulderLeft || !transferDates.shoulderRight}
                        isDense
                        isRequired
                    />
                )}

                <Form.Input
                    type="number"
                    onChange={(val) => setConversionAmount(val)}
                    label={translated.owners.balance.amount}
                    validations={validations}
                    submitKey="amount"
                    isRequired
                />
                {(operationType === TYPE_ALLOCATION || operationType === TYPE_TRANSFER) && (
                    <Form.Input
                        type="textarea"
                        label={translated.owners.balance.reason}
                        submitKey="reason"
                        isDense
                        validations={{ maxLength: 100 }}
                        charCount={{ total: 100 }}
                    />
                )}
            </Form>
            <div className="points-conversion-summary-wrapper">
                <Form.Summary className="points-conversion-summary" extraFields={summaryFields} />
                {availableAllocationPoints.state === globalConstants.ERROR && (
                    <Alert
                        id="points-error"
                        className="margin-top-small"
                        type="error"
                        content={translated.owners.balance.errorGettingPoints}
                    />
                )}
                {!(allocation || transfer || charge || refund) && (
                    <Alert
                        id="operation-error"
                        className="margin-top-small"
                        type="error"
                        content={translated.owners.balance.errorNotConversionOrAllocation}
                    />
                )}
            </div>
        </Form.Wrapper>
    );
}

WalletManager.propTypes = {
    data                  : PropTypes.shape({}).isRequired,
    resources             : PropTypes.shape({}).isRequired,
    onClose               : PropTypes.func.isRequired,
    showSnackbar          : PropTypes.func.isRequired,
    directRequest         : PropTypes.func.isRequired,
    requestForCurrentPath : PropTypes.func.isRequired,
};

export default withRequest(WalletManager);
