import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'Components/Modal';
import Grid from 'Components/Grid';
import translated from 'Constants/labels/translated';

function PermissionsHelp({ onClose: onClick, permissions }) {
    const publicPermissions = {};

    permissions.forEach((element) => {
        Object.keys(element).forEach((key) => {
            const role = element[key];

            Object.keys(role).forEach((permissionName) => {
                const { name } = role[permissionName];
                if (name === 'PUBLIC') {
                    if (!publicPermissions[key]) {
                        publicPermissions[key] = [];
                    }
                    publicPermissions[key].push(translated.roles.permissionNames[permissionName]?.title || permissionName);
                }
            });
        });
    });

    return (
        <Modal
            title={translated.roles.modal.title}
            closeButton={{
                text: translated.global.buttons.close,
                onClick,
            }}
        >
            <div className="text-color-muted">
                <FormattedMessage id={translated.roles.modal.description} defaultMessage={translated.roles.modal.description} />
            </div>
            <Grid addMargin="onStackedColumns" gutter="small">
                {Object.keys(publicPermissions).map((eachKey) => (
                    <Grid.Column width={{ base: 12, small: 4 }} className="margin-top-small" key={`permissions-${eachKey}`}>
                        <div className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                            <FormattedMessage
                                id={translated.roles.permissionNames[eachKey].title}
                                defaultMessage={translated.roles.permissionNames[eachKey].title}
                            />
                        </div>
                        <ul className="clear-ul">
                            {publicPermissions[eachKey].map((eachPermission) => (
                                <li key={eachKey + eachPermission}>
                                    <FormattedMessage
                                        id={translated.roles.permissionNames[eachKey][eachPermission]}
                                        defaultMessage={translated.roles.permissionNames[eachKey][eachPermission]}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Grid.Column>
                ))}
            </Grid>
        </Modal>
    );
}

PermissionsHelp.propTypes = {
    onClose     : PropTypes.func.isRequired,
    permissions : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(PermissionsHelp);
