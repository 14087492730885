import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'Components/Grid';

function Column({ className, children, width }) {
    return (
        <Grid.Column width={width || { base: 12 }} className={className}>
            { children }
        </Grid.Column>
    );
}

Column.defaultProps = {
    className : '',
    children  : null,
    width     : null,
};

Column.propTypes = {
    className : PropTypes.string,
    children  : PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    width     : PropTypes.shape({}),
};

export default Column;
