export default function convertRanges(ranges = []) {
    const translated = {};
    ranges.forEach((element) => {
        const { label, begin: from, end: to, id, steps } = element;
        translated[id] = { id, label, from, to, steps, isHidden: false };
    });

    return translated;
}

export function getUsedBalanceTypesIds(ranges) {
    const usedBalanceTypes = ranges
        .map((range) => range.balanceTypeConversionRates
            .map(({ fromBalanceType, currency }) => ({ balanceType: fromBalanceType, currency })))
        .flat();

    return Array.from(new Set(usedBalanceTypes.map((bt) => bt.balanceType.id)));
}

export function getUsedSteps(ranges) {
    return ranges.map((range) => range.steps).flat().sort((first, second) => {
        if (first === second) {
            return first < second ? -1 : 1;
        }
        return first < second ? -1 : 1;
    }) || [];
}

export function transformToSortList(ranges = {}) {
    const list = ranges ? Object.keys(ranges).map((key) => ({ id: key, ...ranges[key] })) : [];
    const sorted = list.filter((range) => !range.isHidden)
        .sort((first, second) => {
            if (first.from === second.from) {
                return first.id < second.id ? -1 : 1;
            }

            return first.from < second.from ? -1 : 1;
        });

    return sorted;
}

// To avoid problems with the rates with same id for currency and balance type.
const isBalanceTypeRate = (key) => key.match(/-/g)?.length === 2;

export function transformForSubmit({
    ranges, conversionMode, steps,
    conversions, balanceTypes, availableBalanceTypes,
}) {
    const convertedRanges = [];

    Object.keys(ranges).forEach((key) => {
        const range = ranges[key];
        if (!range.isHidden) {
            const { id, from: begin, to: end, label } = range;
            const transformedRange = {
                id,
                begin,
                end,
                label,
                balanceTypeConversionRates : [],
                steps                      : steps.filter((step) => step >= begin && step <= end),
            };

            balanceTypes.forEach?.((eachBalanceTypeId) => {
                const conversionKey = Object.keys(conversions).find((eachKey) => isBalanceTypeRate(eachKey) && eachKey.startsWith(`${id}-`) && eachKey.endsWith(`-${eachBalanceTypeId}`));

                const conversionFound = conversionKey && conversions[conversionKey];
                if (conversionFound) {
                    transformedRange.balanceTypeConversionRates.push({
                        fromBalanceType : { id: eachBalanceTypeId },
                        id              : conversionFound?.id ? conversionFound.id : undefined,
                        rate            : conversionFound?.roomValue,
                        auxiliaryRate   : conversionFound?.serviceValue,
                    });
                }
            });

            convertedRanges.push(transformedRange);
        }
    });

    return {
        convertedRanges,
        conversionMode,
        ranges,
        conversions,
        balanceTypes,
        availableBalanceTypes,
    };
}

export const getCurrencyId = (balanceId, balanceTypes) => {
    const found = balanceTypes?.find?.((e) => e?.value?.id === balanceId);

    return found?.currency?.id;
};

export const getConversionsInitialValues = (ranges, balanceTypes) => {
    const converted = {};

    const currencies = new Set();

    ranges?.forEach?.(({ id: rangeId, balanceTypeConversionRates }) => {
        balanceTypeConversionRates?.forEach?.(({ rate, auxiliaryRate, id, fromBalanceType }) => {
            const balanceId = fromBalanceType?.id;
            const currencyId = getCurrencyId(balanceId, balanceTypes);

            if (currencyId) {
                currencies.add(`${rangeId}-${currencyId}`);
                converted[`${rangeId}-${currencyId}-${balanceId}`] = { roomValue: rate, serviceValue: auxiliaryRate, id, isRequired: true };
            }
        });
    });

    Array.from(currencies).forEach((eachCurrencyId) => {
        const roomValueRates = new Set();
        Object.keys(converted)
            ?.filter((e) => e.startsWith(`${eachCurrencyId}-`))
            ?.forEach((e) => roomValueRates.add(converted[e]?.roomValue));

        const serviceValueRates = new Set();
        Object.keys(converted)
            ?.filter((e) => e.startsWith(`${eachCurrencyId}-`))
            ?.forEach((e) => serviceValueRates.add(converted[e]?.serviceValue));

        converted[eachCurrencyId] = {
            roomValue             : Array.from(roomValueRates)[0],
            serviceValue          : Array.from(serviceValueRates)[0],
            isRequired            : true,
            roomValueAreEquals    : roomValueRates.size === 1,
            serviceValueAreEquals : serviceValueRates.size === 1,
        };
    });

    return converted;
};

export const convertBalanceTypes = (balanceTypes, currencies) => {
    const converted = [];

    balanceTypes?.forEach?.((eachBalanceType) => {
        const currencyFound = currencies?.find?.((eachCurrency) => eachCurrency.id === eachBalanceType?.currency?.id);

        converted.push({
            ...eachBalanceType,
            id       : eachBalanceType?.value?.id,
            name     : eachBalanceType?.value?.name,
            currency : currencyFound
                ? { ...currencyFound }
                : null,
        });
    });

    return converted;
};
