import React from 'react';
import PropTypes from 'prop-types';

function Chips({ className, quantity }) {
    return (
        <div className={`skeleton skeleton-chips ${className}`}>
            {Array.apply(0, Array(quantity)).map((x, index) => (
                <div className="skeleton-element skeleton-chip" key={x || `skeleton-chip-${index}`} />
            ))}
        </div>
    );
}

Chips.defaultProps = {
    className : '',
    quantity  : 2,
};

Chips.propTypes = {
    className : PropTypes.string,
    quantity  : PropTypes.number,
};

export default Chips;
