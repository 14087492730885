import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import { maxBalanceAmount } from 'Constants/global';
import Form from 'Components/Form';

const ADD = 'Add';
const SUB = 'Substract';

function BalanceEditor({ selectedBalances, snackbar, onFinish }) {
    const intl = useIntl();
    const balancesAndChildren = selectedBalances.reduce((acc, current) => [...acc, ...(current.children?.length ? current.children : [current])], []);
    const [isAdding, setIsAdding] = useState(balancesAndChildren.reduce((acc, current) => ({ ...acc, [current.id]: true }), {}));

    const handleSubmit = async ({ values }) => {
        const rawBalances = Object.values(values.balances).filter((v) => v?.transactionValue?.inputValue);
        const balances = rawBalances.length
            ? rawBalances.map(({ transactionValue, id }) => {
                const original = balancesAndChildren.find((balance) => balance.id === id);
                const newAmount = transactionValue.selectedOption === ADD ? transactionValue.inputValue : -transactionValue.inputValue;
                return {
                    ...original,
                    transactionValue: newAmount,
                };
            })
            : null;

        if (!balances) {
            snackbar.show({
                content : translated.owners.balance.editor.errors.shouldEnterSomeValue,
                isError : true,
            });
            return;
        }

        onFinish(balances);
    };

    const getMaxValue = (balanceIsAdding, balance) => {
        if (balanceIsAdding) {
            return balance < 0 ? maxBalanceAmount : maxBalanceAmount - balance;
        }
        return balance < 0 ? 0 : balance;
    };

    const handleOnCancelClick = () => {
        onFinish([]);
    };

    return (
        <Form.Wrapper
            modalProperties={{
                title     : translated.owners.balance.editor.title,
                className : 'balance-editor-modal',
                buttons   : [
                    <Form.Secondary variant="text" color="primary" key="fs" onClick={handleOnCancelClick}>
                        <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                    </Form.Secondary>,
                    <Form.Primary
                        variant="text"
                        color="primary"
                        key="fp"
                        tooltip={intl.formatMessage({
                            id             : translated.owners.balance.editor.previewExplains,
                            defaultMessage : translated.owners.balance.editor.previewExplains,
                        })}
                    >
                        <FormattedMessage
                            id={translated.owners.balance.editor.previewBefore}
                            defaultMessage={translated.owners.balance.editor.previewBefore}
                        />
                    </Form.Primary>,
                ],
            }}
        >
            <p className="margin-bottom-medium">
                <FormattedMessage id={translated.owners.balance.editor.indicate} defaultMessage={translated.owners.balance.editor.indicate} />
            </p>
            <Form onSubmit={handleSubmit}>
                {balancesAndChildren.map(({
                    id, pointsType, balanceType, balance, amount, expiryDate, effectiveDate, transactionValue,
                }) => {
                    const { currency } = balanceType;
                    let validation;
                    let minBalanceAmount;
                    let inputValue;
                    let selectedOption;
                    if (currency.isInteger) {
                        validation = ['numbers'];
                        minBalanceAmount = 1;
                    } else {
                        validation = ['float'];
                        minBalanceAmount = 0.01;
                    }
                    if (transactionValue) {
                        inputValue = Math.abs(transactionValue);
                        selectedOption = transactionValue > 0 ? ADD : SUB;
                    } else {
                        inputValue = null;
                        selectedOption = ADD;
                    }

                    return (
                        <Form.Input
                            type="split"
                            submitKey={`balances.balance-${id}`}
                            options={[
                                {
                                    value   : ADD,
                                    content : intl.formatMessage({
                                        id             : translated.owners.balance.balanceAdd,
                                        defaultMessage : translated.owners.balance.balanceAdd,
                                    }),
                                },
                                {
                                    value   : SUB,
                                    content : intl.formatMessage({
                                        id             : translated.owners.balance.balanceSub,
                                        defaultMessage : translated.owners.balance.balanceSub,
                                    }),
                                },
                            ]}
                            min={minBalanceAmount}
                            max={getMaxValue(isAdding[id], balance)}
                            validations={{
                                minValue : minBalanceAmount,
                                maxValue : getMaxValue(isAdding[id], balance),
                                unary    : validation,
                            }}
                            onChange={(newValue) => {
                                const currentlyAdding = newValue?.selectedOption === ADD;
                                if (currentlyAdding !== isAdding[id]) {
                                    setIsAdding((prev) => ({
                                        ...prev,
                                        [id]: currentlyAdding,
                                    }));
                                }
                            }}
                            value={{ inputValue, selectedOption }}
                            submitFormat={(value) => ({ transactionValue: value, id })}
                            suffix={balanceType.currency.shorthandCode}
                            label={pointsType}
                            helperText={{
                                label: (
                                    <FormattedMessage
                                        id={translated.owners.balance.balanceHelper}
                                        defaultMessage={translated.owners.balance.balanceHelper}
                                        values={{ amount, balance, effectiveDate, expiryDate }}
                                    />
                                ),
                            }}
                        />
                    );
                })}
            </Form>
        </Form.Wrapper>
    );
}

BalanceEditor.displayName = 'CurrentBalance';

BalanceEditor.propTypes = {
    onFinish         : PropTypes.func.isRequired,
    snackbar         : PropTypes.shape({}).isRequired,
    selectedBalances : PropTypes.arrayOf(
        PropTypes.shape({
            id          : PropTypes.number,
            year        : PropTypes.string,
            pointsType  : PropTypes.string,
            balanceType : PropTypes.shape({
                id       : PropTypes.number,
                name     : PropTypes.string,
                category : PropTypes.string,
                isActive : PropTypes.bool,
            }),
            balance       : PropTypes.string,
            amount        : PropTypes.string,
            beginDate     : PropTypes.string,
            expiryDate    : PropTypes.string,
            effectiveDate : PropTypes.string,
            children      : PropTypes.arrayOf(PropTypes.shape({})),
        }),
    ).isRequired,
};

export default React.memo(BalanceEditor);
