import { sectionType } from './types';

const maxBalanceAmount = 10000000;
const minBalanceAmount = -10000000;

const applyToDefaultOption = 'both';

const statusStats = {
    ENABLED             : 'Enabled',
    CANCELLED           : 'Cancelled',
    ERROR               : 'Error',
    INCOMPLETE          : 'Incomplete',
    PENDING             : 'Pending',
    COMPLETED           : 'Completed',
    DOWNLOADING         : 'Downloading',
    INVALID             : 'Invalid',
    LOCKED              : 'Locked',
    ATTENTION_REQUIRED  : 'Attention_required',
    RESERVED            : 'Reserved',
    EXPIRED             : 'Expired',
    MODIFY              : 'Modify',
    SUSPENDED           : 'Suspended',
    ON_HOLD             : 'On_Hold',
    REQUEST             : 'Requested',
    DENIED              : 'Denied',
    IMPORTED            : 'Imported',
    CONFIRMED           : 'Confirmed',
    INSUFFICIENT_FOUNDS : 'Insufficient_founds',
    NOT_DEFINED         : 'Not_Defined',
    MODIFIED            : 'Modified',
};

const rangeMode = {
    ALWAYS : 'Always',
    EXCEPT : 'Except',
    ONLY   : 'Only',
};

const countriesMode = {
    ALWAYS : 'always',
    EXCEPT : 'except',
    ONLY   : 'only',
};

const balanceTypeCategory = {
    BONUS : 'Bonus',
    CLUB  : 'Club',
};

const clientType = {
    OWNER   : 'Owner',
    PARTNER : 'Partner',
};

const clubDuration = {
    DYNAMIC : 'Dynamic',
    FIXED   : 'Fixed',
};

const transactionType = {
    ALLOCATION : 'Allocation',
    BOOKING    : 'Booking',
    CONVERSION : 'Conversion',
    MANAGEMENT : 'Management',
};

const transactionState = {
    CANCELLED         : 'Cancelled',
    CONFIRMED         : 'Confirmed',
    INSUFFICIENTFUNDS : 'InsufficientFunds',
    PENDING           : 'Pending',
};

const display = {
    ONLY   : 'only',
    EXCEPT : 'except',
    ALL    : 'all',
};

const views = {
    SEARCH : 'Search',
    OFFERS : 'Offers',
};

const pricingType = {
    FIXEDNIGHT : 'FixedNight',
    FIXEDTOTAL : 'FixedTotal',
    RELATIVE   : 'Relative',
};

const dateRangeType = {
    BOOKINGWINDOW : 'BookingWindow',
    TRAVELWINDOW  : 'TravelWindow',
};

const clientStatus = {
    ACTIVE    : 'Active',
    CANCELLED : 'Cancelled',
    ERROR     : 'Error',
    PENDING   : 'Pending',
    SUSPENDED : 'Suspended',
};

const attributeCategory = {
    BOTH   : 'Both',
    CLIENT : 'Client',
    USER   : 'User',
};

const csvStatus = {
    DOWNLOADING : 'Downloading',
    PENDING     : 'Pending',
    ERROR       : 'Error',
    LOCKED      : 'Locked',
    COMPLETED   : 'Completed',
    COMPLETE    : 'Complete',
    INCOMPLETE  : 'Incomplete',
    INVALID     : 'Invalid',
};

const bookingsStatus = {
    RESERVED   : 'Reserved',
    EXPIRED    : 'Expired',
    CANCELLED  : 'Cancelled',
    MODIFY     : 'Modify',
    ON_HOLD    : 'OnHold',
    ON_REQUEST : 'Requested',
    DENIED     : 'RequestDenied',
    IMPORTED   : 'Imported',
};

const transactionsStatus = {
    PENDING             : 'Pending',
    PENDING_APPROVAL    : 'PendingForApproval',
    CONFIRMED           : 'Confirmed',
    CANCELLED           : 'Cancelled',
    INSUFFICIENT_FOUNDS : 'InsufficientFunds',
    MODIFIED            : 'Modified',
};

const restrictedAppReasons = {
    MAINTENANCE    : 'maintenance',
    NO_PERMISSIONS : 'NoPermissions',
};

const csvFileStatus = { INVALID: 'INVALID_CSV' };

const publicPermission = 'PUBLIC';

const LANGUAGE_SWITCH = 'LANGUAGE_SWITCH';
const REFRESH_NOW = 'REFRESH_NOW';
const REFRESH_OWN_MODIFICATION = 'REFRESH_OWN_MODIFICATION';

const notificationTypes = { CONVERSION: 'conversion' };

const socketNotificationTypes = {
    CALL                : 'CALL-INCOMING',
    PERMISSIONS_UPDATED : 'UPDATE-PERMISSIONS',
};

const rulesFilterConfigurations = {
    INCLUDE              : 'HOLD',
    EXCLUDE_HIDE         : 'HIDE',
    EXCLUDE_SHOW_UPGRADE : 'SHOULD_UPGRADE',
};

const sectionsPriority = [sectionType.OWNERS, sectionType.PARTNERS, sectionType.RESERVATIONS];

const rolesNames = {
    // Owners
    OWNERS_CREATE              : 'Add',
    OWNERS_READ                : 'Visualize',
    OWNER_UPDATE               : 'Edit',
    USERS_SEND_INVITATION_LINK : 'Re-send invitation link',
    POINTS_STATEMENT           : 'Access to Point statements',

    // Partners
    PARTNERS_CREATE : 'Add',
    PARTNERS_READ   : 'Visualize',
    PARTNERS_UPDATE : 'Edit',

    // Partners
    AGENTS_CREATE : 'Add',
    AGENTS_READ   : 'Visualize',
    AGENTS_UPDATE : 'Edit',

    // Contract Rules
    CONTRACT_RULES_CREATE : 'Create',
    CONTRACT_RULES_READ   : 'Visualize',
    CONTRACT_RULES_UPDATE : 'Edit',
    CONTRACT_RULES_DELETE : 'Delete',

    // Wallet
    WALLETS_MANAGE            : 'Edit Current Balance',
    BALANCES_MANAGE           : "Edit Transaction's Balances",
    TRANSACTIONS_MANAGE       : 'Remove transactions of types Management, Allocation and Transaction',
    WALLET_ALLOCATION_CREATE  : 'Perform an Allocation',
    WALLET_CONVERSION_CREATE  : 'Perform a Conversion',
    WALLET_READ               : 'Visualize Wallet',
    WALLET_TRANSFERS          : 'Transaction between periods',
    CONVERSIONS_READ          : 'Read conversions',
    CONVERSIONS_REASON_UPDATE : 'Update Reason conversions',

    // Contract
    OWNERS_CONTRACT_CREATE   : 'Create owners contract',
    OWNER_CONTRACTS_READ     : 'Visualize owners contracts',
    PARTNERS_CONTRACT_CREATE : 'Create Partners contracts',
    PARTNER_CONTRACTS_READ   : 'Visualize Partners contracts',

    // Bookings
    BOOKINGS_DETAILS          : 'Bookings details',
    BOOKINGS_READ             : 'Visualize Bookings',
    BOOKING_CANCEL            : 'Bookings cancellation',
    OWNERS_BOOKINGS_READ      : 'Visualize Owners bookings',
    PARTNERS_BOOKINGS_READ    : 'Visualize Partners bookings',
    SKIP_PAYMENT_CHECK        : 'Book avoiding ARMNet payment',
    BOOKINGS_CREATE           : 'Perform on behalf of an Owner',
    BOOKINGS_CHARGE_OR_REFUND : "Charge and Refund on Owner's Wallet",
    EXCHANGE_PERFORM          : 'Deposits/Bankings on behalf of an owner',

    // Notes
    NOTES_CREATE : 'Create',
    NOTES_READ   : 'Visualize',
    NOTES_UPDATE : 'Edit',
    NOTES_DELETE : 'Delete',

    // Tiers
    TIERS_CREATE : 'Create',
    TIERS_READ   : 'Visualize',
    TIERS_UPDATE : 'Edit',
    TIERS_DELETE : 'Delete',

    // Balance Types
    BALANCE_TYPES_CREATE : 'Create',
    BALANCE_TYPES_READ   : 'Visualize',
    BALANCE_TYPES_UPDATE : 'Edit',
    BALANCE_TYPES_DELETE : 'Delete',

    // Clubs
    CLUBS_CREATE : 'Create',
    CLUBS_READ   : 'Visualize',
    CLUBS_UPDATE : 'Edit',
    CLUBS_DELETE : 'Delete',

    // Contract Types Rules
    CONTRACT_TYPE_RULES_CREATE  : 'Create',
    CONTRACT_TYPE_RULES_READ    : 'Visualize',
    CONTRACT_TYPE_RULES_UPDATE  : 'Edit',
    CONTRACT_TYPES_RULES_DELETE : 'Delete',

    // System
    SYSTEM_RULES_CREATE : 'Create',
    SYSTEM_RULES_READ   : 'Visualize',
    SYSTEM_RULES_UPDATE : 'Edit',
    SYSTEM_RULES_DELETE : 'Delete',

    // Contract Types
    CONTRACT_TYPES_CREATE      : 'Create',
    CONTRACT_TYPES_READ        : 'Visualize',
    CONTRACT_TYPES_UPDATE      : 'Edit',
    CONTRACT_TYPE_RULES_DELETE : 'Delete',

    // Roles
    ROLES_CREATE : 'Create',
    ROLES_READ   : 'Visualize',
    ROLES_UPDATE : 'Edit',
    ROLES_DELETE : 'Delete',

    // Upgrades
    UPGRADES_CREATE : 'Perform an Upgrade/Downgrade',
    UPGRADES_READ   : 'Visualize Upgrades requests',
    UPGRADES_PATCH  : 'Mark Upgrade as processed',

    // Users
    USERS_CREATE : 'Add Users in Owners/Partners',
    USERS_READ   : 'Visualize Users in Owners/Partners',
    USERS_UPDATE : 'Edit Users in Owners/Partners',

    // Agents
    SYSTEM_USERS_CREATE         : 'Add',
    SYSTEM_USERS_READ           : 'Visualize',
    SYSTEM_USERS_UPDATE         : 'Edit',
    SYSTEM_USERS_DELETE         : 'Delete',
    AGENTS_SEND_INVITATION_LINK : 'Re-send invitation link',
    // Reports
    REPORTS_READ                : 'View List',

    // Notifications
    NOTIFICATIONS_READ   : 'Visualize',
    NOTIFICATIONS_UPDATE : 'Mark as read',

    // External Clubs
    EXTERNAL_CLUBS_READ   : 'Visualize',
    EXTERNAL_CLUBS_CREATE : 'Add',
    EXTERNAL_CLUBS_UPDATE : 'Edit',
    EXTERNAL_CLUBS_DELETE : 'Delete',

    // PUBLIC
    read          : 'visualize',
    importInit    : 'perform an import',
    importList    : "import's list",
    importReadAll : 'read all imports',
};

const attachmentAllowedFiles = '.pdf,.doc,.docx,.jpg,.jpeg,.xls,.xlsx,.msg';
const attachmentFilesWithPreview = ['pdf', 'jpg', 'jpeg'];

const fileUploadIdleTimeout = 10000; // 10 seconds
const fileUploadTimeout = 300000; // 5 minutes
const fileUploadMaxSize = 104857600; // 100 MB

const inputSeparators = [','];

const extraAttributesFilters = {
    CLIENT   : 'Client',
    USER     : 'User',
    CONTRACT : 'Contract',
};

export {
    statusStats,
    applyToDefaultOption,
    rangeMode,
    countriesMode,
    balanceTypeCategory,
    clientType,
    clubDuration,
    transactionType,
    transactionState,
    pricingType,
    dateRangeType,
    clientStatus,
    attributeCategory,
    csvStatus,
    csvFileStatus,
    publicPermission,
    notificationTypes,
    socketNotificationTypes,
    rulesFilterConfigurations,
    bookingsStatus,
    transactionsStatus,
    sectionsPriority,
    REFRESH_NOW,
    LANGUAGE_SWITCH,
    REFRESH_OWN_MODIFICATION,
    rolesNames,
    restrictedAppReasons,
    maxBalanceAmount,
    minBalanceAmount,
    attachmentAllowedFiles,
    attachmentFilesWithPreview,
    fileUploadIdleTimeout,
    fileUploadTimeout,
    fileUploadMaxSize,
    inputSeparators,
    display,
    views,
    extraAttributesFilters,
};

export default {
    INIT        : 'init',
    DONE        : 'done',
    IN_PROGRESS : 'inProgress',
    READ        : 'read',
    GETTINGDATA : 'gettingData',
    ERROR       : 'error',
};
