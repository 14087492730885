import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Table from 'Components/Table';
import Alert from 'Components/Alert';
import Subsection from '../../Subsection';
import PanelContext from 'State/panelContext';
import Button from 'Components/Button';
import ConfirmationButton from 'Components/ConfirmationButton';
import Form from 'Components/Form';

function ReferredReferral({ data }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const { links, referral, referredAccounts } = data;

    const referred = referredAccounts?.map((e) => ({ ...e, firstName: e?.primary?.firstName, lastName: e?.primary?.lastName, email: e?.primary?.email }));

    const [referralStatus, setReferralStatus] = useState({
        isEnabled  : !referral,
        user       : referral,
        loadedUser : referral,
    });

    useEffect(() => {
        setReferralStatus((prev) => ({ ...prev, loadedUser: referral }));
    }, [referral]);

    const updateLink = links && links.self && links.self.update ? { ...links.self.update } : null;
    const searchLink = links && links.referral && links.referral.read ? { ...links.referral.read } : null;
    const referralLoaded = referral && referral.id;

    // Returns the data needed for the request that changes the owner.
    const getRequestValues = () => ({
        status          : data.status,
        membershipId    : data.membershipId,
        id              : data.id,
        legacyId        : data.legacyId,
        primary         : data.primary && data.primary.id ? { id: String(data.primary.id) } : {},
        secondary       : data.secondary && data.secondary.id ? { id: String(data.secondary.id) } : {},
        extraAttributes : data.extraAttributes,
        anniversaryDate : data.anniversaryDate,
        department      : data.department,
        type            : data.type,
        address1        : data.address1,
        address2        : data.address2,
        zipCode         : data.zipCode,
        city            : data.city,
        state           : data.state,
        country         : data?.country?.id ? { id: data.country.id } : null,
    });

    const saveReferral = async () => {
        if (!referralStatus.user) {
            snackbar.show({
                content : translated.owners.referral.edit.noUser,
                isError : true,
            });
            return;
        }

        const { id } = referralStatus.user;

        const reqConfig = {
            ...updateLink,
            data             : { ...getRequestValues(), referral: { id: String(id) } },
            shouldReloadData : true,
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });
            snackbar.show({
                content   : translated.owners.referral.edit.saveSuccess,
                isSuccess : true,
            });
            setReferralStatus((previous) => ({
                ...previous,
                isEnabled: false,
            }));
        } catch (saveError) {
            snackbar.show({
                content     : translated.owners.referral.edit.errors.save,
                errorLabels : translated.owners.referral.edit.errors,
                isError     : true,
                error       : saveError,
            });
        }
    };

    const onReferralEditClick = async () => {
        if (!referralStatus.isEnabled) {
            setReferralStatus((previous) => ({
                ...previous,
                isEnabled : true,
                user      : null,
            }));
        } else {
            saveReferral();
        }
    };

    const onReferralCancelClick = async () => {
        if (referralStatus.isEnabled && referralLoaded) {
            setReferralStatus((previous) => ({
                ...previous,
                isEnabled : false,
                user      : referral,
            }));
        }
    };

    const onReferralRemoveClick = async () => {
        try {
            const reqConfig = {
                ...updateLink,
                data             : { ...getRequestValues(), referral: null },
                shouldReloadData : true,
            };

            await navigator.requestForCurrentPath({ reqConfig });
            snackbar.show({
                content   : translated.owners.referral.remove.success,
                isSuccess : true,
            });
            setReferralStatus((previous) => ({
                ...previous,
                isEnabled  : true,
                user       : null,
                loadedUser : null,
            }));
        } catch (saveError) {
            snackbar.show({
                content : translated.owners.referral.remove.error,
                isError : true,
                error   : saveError,
            });
        }
    };

    let iconType = '';
    let buttonId = 'edit-referral-button';
    let tooltipContent = '';
    if (referralStatus.isEnabled) {
        buttonId = 'save-referral-button';
        iconType = 'ContentSave';
        tooltipContent = translated.global.buttons.save;
    } else if (referralLoaded) {
        iconType = 'Pencil';
        tooltipContent = translated.global.buttons.edit;
    } else {
        iconType = 'ContentSave';
        tooltipContent = translated.global.buttons.save;
    }

    return (
        <Subsection title={translated.owners.referredReferral}>
            <div className="subsection-header-subtitle">
                <FormattedMessage defaultMessage={translated.owners.referred.title} id={translated.owners.referred.title} />
            </div>
            <Table
                id="users"
                key="users"
                hasTools={false}
                items={referred}
                onSearch={() => {
                    // Default
                }}
                columns={[
                    {
                        title     : translated.global.firstName,
                        key       : 'firstName',
                        isVisible : true,
                    },
                    {
                        title     : translated.global.lastName,
                        key       : 'lastName',
                        isVisible : true,
                    },
                    {
                        title     : translated.global.id,
                        key       : 'id',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title     : translated.global.email,
                        key       : 'email',
                        isVisible : true,
                    },
                ]}
                rows={{
                    actions: [
                        {
                            content           : translated.global.buttons.view,
                            callback          : (owner) => navigator.goToDetails(owner, true),
                            key               : 'view',
                            isOutsideDropdown : true,
                            icon              : 'AccountArrowRight',
                            tooltip           : translated.global.buttons.view,
                            when              : (owner) => owner?.links?.self?.read,
                        },
                    ],
                }}
                whenEmpty={<Alert id="owners-users-empty" content={translated.owners.referred.emptyList} />}
            />
            <div className="subsection-header-subtitle margin-top-medium margin-bottom-small">
                <FormattedMessage defaultMessage={translated.owners.referral.title} id={translated.owners.referral.title} />
            </div>
            <div className="referral-edition">
                {(referralStatus.isEnabled || !referralLoaded) && updateLink && searchLink && (
                    <Form
                        id="referral-search"
                        onSubmit={() => {
                            // Default
                        }}
                    >
                        <Form.Input
                            id="referral-search-input"
                            submitKey="search"
                            isDense
                            type="search"
                            value=""
                            showLoading
                            searchValidation={(email) => email && email.length > 3}
                            searchFunction={async (email) => {
                                const result = await navigator.directRequest({
                                    ...searchLink,
                                    params: { email },
                                });

                                return result && result.data && result.data.map((eachOwner) => ({ ...eachOwner, display: eachOwner?.primary?.email }));
                            }}
                            renderSearch={(user) => {
                                if (!user) {
                                    return (
                                        <span id="search-empty" className="no-results">
                                            <FormattedMessage
                                                defaultMessage={translated.owners.referral.edit.searchEmpty}
                                                id={translated.owners.referral.edit.searchEmpty}
                                            />
                                        </span>
                                    );
                                }
                                return (
                                    <>
                                        <span id={`referral-search-email-${user.id}`} className="result-user-email">
                                            {user?.primary?.email}
                                        </span>
                                        <span id={`referral-search-name-${user.id}`} className="result-user-name">
                                            {`${user?.primary?.firstName} ${user?.primary?.lastName}`}
                                        </span>
                                    </>
                                );
                            }}
                            onSearchSelect={(user) => {
                                setReferralStatus((previous) => ({ ...previous, user }));
                            }}
                        />
                    </Form>
                )}

                {(referralStatus.isEnabled || !referralLoaded) && (!updateLink || !searchLink) && (
                    <Alert id="owners-referral-empty" content={translated.owners.referral.empty} />
                )}

                {!referralStatus.isEnabled && referralLoaded && (
                    <div className="form is-read-only-without-inputs">
                        <div className="form-field">
                            <div className="form-field-input">
                                <span>{referral?.primary?.email}</span>
                            </div>
                        </div>
                        <span className="referral-name text-color-secondary">{`${referral?.primary?.firstName} ${referral?.primary?.lastName}`}</span>
                    </div>
                )}

                {updateLink && searchLink && (
                    <>
                        <Button id={buttonId} icon={iconType} tooltip={tooltipContent} onClick={onReferralEditClick} disabled={!referralStatus.user} />
                        {referralStatus.isEnabled && referralStatus.loadedUser && (
                            <Button
                                id="cancel-referral-button"
                                icon="Close"
                                tooltip={translated.global.buttons.cancel}
                                onClick={onReferralCancelClick}
                            />
                        )}
                    </>
                )}
                {updateLink && !referralStatus.isEnabled && referralLoaded && (
                // Show the 'Remove' button only when the user has the action available,
                // the referral isn't being modified and there is a referral.
                    <ConfirmationButton
                        id="remove-referral-button"
                        icon="TrashCan"
                        tooltip={translated.global.buttons.remove}
                        onClick={onReferralRemoveClick}
                        confirmation={{
                            title   : translated.owners.referral.remove.confirmationTitle,
                            message : translated.owners.referral.remove.confirmationMessage,
                        }}
                    />
                )}
            </div>
        </Subsection>
    );
}

ReferredReferral.defaultProps = {};

ReferredReferral.propTypes = { data: PropTypes.shape({}).isRequired };

export default ReferredReferral;
