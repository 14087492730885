import requestErrors from 'Constants/requestErrors';
import translated from 'Constants/labels/translated';

/**
 * Creates an error object
 */
const createError = ({ title, isCritical }) => {
    const error = new Error(title);
    // Title is shown to the user.
    error.title = title;
    // Flag used to decide if the error is shown either as a modal or as a page.
    error.isCritical = isCritical;

    return error;
};

/**
 * Return the properties needed in the redux app's state when handling errors.
 */
const getErrorProperties = (error) => ({
    title      : error.title,
    isCritical : !!error.isCritical,
});

/**
 * Returns the error to be used in the snackbar when a request fails.
 *
 * @param {Error} error Request error
 * @param {Object} entityLabels Labels of the entity that triggered the request. Optional.
 * @param {String} defaultMessage Message to show when the request error is not found. Optional.
 */
const getRequestErrorMessage = (error, entityLabels = null, defaultMessage = null) => {
    const keys = [];

    if (Array.isArray(error?.data?.errors)) {
        const errorCodes = error.data.errors.map((eachError) => {
            if (eachError?.detail?.errors?.[0]?.code) {
                return eachError?.detail?.errors?.[0]?.code;
            }

            return eachError.code;
        });

        Object.entries(requestErrors)
            .filter(([errorCode]) => errorCodes.indexOf(errorCode) > -1)
            .forEach((e) => {
                const [, key] = e;
                if (typeof key === 'object') {
                    const subErrorCode = error?.data?.errors?.[0].messages?.[0]?.code;
                    if (subErrorCode && key[subErrorCode]) {
                        keys.push(key[subErrorCode]);
                    } else {
                        keys.push(key.default);
                    }
                } else {
                    keys.push(key);
                }
            });
    } else if (error?.status && requestErrors.status[error.status]) {
        // Check if the error status code is between the code handled
        keys.push(requestErrors.status[error.status]);
    }

    const specific = keys.find((key) => (entityLabels?.[key]));
    const global = keys.find((key) => (translated.global.errors?.[key]));

    return (
        (specific && entityLabels[specific]) // Generic message for the error code
        || (global && entityLabels[global]) // Generic message for the error code
        || defaultMessage // Default message received
        || entityLabels?.defaultError // Default error for the entity labels
        || translated?.global?.errors?.default
    ); // Default error for all entities
};

function getErrorDetails(error) {
    if (error) {
        const { response } = error;
        const { codes } = requestErrors;
        return {
            isTimeout         : typeof error === 'object' && error.code === codes.aborted,
            hasUserError      : response?.status === codes.unauthorized,
            shouldRefreshUser : response?.status === codes.forbiden && !response.data?.errors?.some((e) => e.code === codes.APTForbiden),
            shouldRestrictApp : response?.status === codes.underMaintenance,
            error             : response || error,
        };
    }

    return {
        isTimeout         : typeof error === 'object' && error.code === codes.aborted,
        hasUserError      : false,
        shouldRefreshUser : false,
        error             : null,
    };
}

const errorService = { createError, getErrorProperties, getErrorDetails, getRequestErrorMessage };

export { createError, getErrorProperties, getRequestErrorMessage, getErrorDetails };
export default errorService;
