import React, { useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller, get } from 'react-hook-form';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import AceEditor from 'react-ace';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

const TYPE_ERROR = 'error';
const CUSTOM_ERROR = 'customError';

function Code({
    label,
    value,
    isReadOnlyWithoutInputs,
    onFocus,
    isDisabled,
    id,
    linkHref,
    linkClassName,
    onClick,
    name,
    onChange,
    onBlur,
    defaultValue,
}) {
    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const context = useFormContext();
    const { setValue, control, setError, clearErrors, formState: { errors } } = context || {};

    const { app } = useContext(PanelContext);

    const changeTimer = useRef(null);
    const validationsTimer = useRef(null);

    const handleOnChange = useCallback((newValue) => {
        // Without the timer, the form changes destroy the app performance
        if (changeTimer.current) {
            clearTimeout(changeTimer.current);
            changeTimer.current = null;
        }

        changeTimer.current = setTimeout(async () => {
            if (onChange) {
                onChange(newValue);
            }

            setValue(name, newValue, { shouldDirty: true, shouldValidate: true });

            changeTimer.current = null;
        }, 500);
    }, [name, onChange, setValue]);

    const handleOnValidate = useCallback((validations) => {
        // Without the timer, the form changes destroy the app performance. It must be higher than the onChange's timer to avoid update problems
        if (validationsTimer.current) {
            clearTimeout(validationsTimer.current);
            validationsTimer.current = null;
        }

        validationsTimer.current = setTimeout(async () => {
            const currentError = get(errors, name);

            if (!currentError && validations?.find?.((e) => e.type === TYPE_ERROR)) {
                setError(name, { type: CUSTOM_ERROR, message: translated.global.invalidHtml });
            } else if (currentError?.type === CUSTOM_ERROR) {
                clearErrors(name);
            }

            changeTimer.current = null;
        }, 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const theme = useMemo(() => (app.isDarkModeEnabled ? 'solarized_dark' : 'tomorrow'), [app.isDarkModeEnabled]);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!isReadOnlyWithoutInputs && (
                // We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types).
                <Controller
                    render={
                        ({ field }) => (
                            <AceEditor
                                id={id}
                                key={id}
                                name={name}
                                placeholder={placeholder}
                                mode="html"
                                theme={theme}
                                tabIndex={isDisabled ? '-1' : '0'}
                                fontSize={14}
                                showPrintMargin
                                showGutter
                                highlightActiveLine
                                height="180px"
                                width="100%"
                                readOnly={isDisabled}
                                enableBasicAutocompletion
                                enableLiveAutocompletion
                                showLineNumbers
                                tabSize={4}
                                onValidate={handleOnValidate}
                                {...field}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onChange={handleOnChange}
                            />
                        )
                    }
                    control={control}
                    name={name}
                    defaultValue={defaultValue || ''}
                />
            )}

            {isReadOnlyWithoutInputs && (linkHref || onClick) && (
                <a className={linkClassName} href={linkHref || null} onClick={onClick}>
                    <span id={id}>{value}</span>
                </a>
            )}

            {isReadOnlyWithoutInputs && !linkHref && !onClick && <span id={id}>{value}</span>}
        </>
    );
}

Code.defaultProps = {
    id                      : '',
    label                   : '',
    name                    : '',
    value                   : '',
    defaultValue            : null,
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange      : null,
    isDisabled    : false,
    linkHref      : '',
    linkClassName : '',
    onClick       : null,
};

Code.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name                    : PropTypes.string,
    value                   : PropTypes.string,
    defaultValue            : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    linkHref                : PropTypes.string,
    linkClassName           : PropTypes.string,
    onClick                 : PropTypes.func,
};

export default React.memo(Code);
