import React from 'react';
import Form from './Form';
import FormNew from './FormNew';
/* eslint import/no-cycle: [1, { maxDepth: 1 }] */
import Input from './Input';
/* eslint import/no-cycle: [1, { maxDepth: 1 }] */
import InputNew from './Input/InputNew';
import InputError from './Input/InputError';
import Group from './Group';
import Title from './Title';
import Custom from './Custom';
import Wrapper from './Wrapper';
import WrapperNew from './WrapperNew';
import Summary from './Summary';
import Primary from './PrimaryButton';
import PrimaryNew from './PrimaryButtonNew';
import Secondary from './SecondaryButton';
import SecondaryNew from './SecondaryButtonNew';
import Reset from './ResetButton';
import ResetNew from './ResetButtonNew';
import Clear from './ClearButton';
import FormError from './FormError';
import ColumnNew from './Column';

// Components children of Form
Form.Input = Input;
Form.InputNew = InputNew;
Form.InputError = InputError;
Form.Group = Group;
Form.Title = Title;
Form.Custom = Custom;

// Components that will be interpreted by Form component
Form.Column = function Column() {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
Form.ColumnNew = ColumnNew;
Form.Hidden = function Hidden() {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};

// Component to wrap all Form elements to share a context
Form.Wrapper = Wrapper;
Form.WrapperNew = WrapperNew;

// Provides a component to show the current state of the form
Form.Summary = Summary;

// Submit, cancel and reset buttons
Form.Primary = Primary;
Form.PrimaryNew = PrimaryNew;
Form.Secondary = Secondary;
Form.SecondaryNew = SecondaryNew;
Form.Reset = Reset;
Form.ResetNew = ResetNew;
Form.Clear = Clear;

// A FormError object will display an error message that is thrown when the submit fails
export {
    Input, Group, Title, Wrapper, Primary, Secondary, Reset, FormError, FormNew,
};

/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
export default Form;
