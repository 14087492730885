import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import Card from 'Components/Card';
import Button from 'Components/Button';
import PanelContext from 'State/panelContext';
import { contractsStatus } from 'Constants/translator';
import { sectionType } from 'Constants/types';

export function ContractCard({ contract, className, isPartner, resources }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const membershipUpgradeOnClick = () => {
        if (isPartner) {
            navigator.selectPartnerUpgradeDowngradeEditor(contract);
        } else {
            navigator.selectOwnerUpgradeDowngradeEditor(contract);
        }
    };

    const membershipEditOnClick = () => {
        if (isPartner) {
            navigator.goToPartnerContractEditor({ available: contract.links.self });
        } else {
            navigator.goToOwnerContractEditor({ available: contract.links.self });
        }
    };

    const membershipDetailsOnClick = () => {
        if (isPartner) {
            navigator.goToPartnerContractDetail({ available: contract.links.self });
        } else {
            navigator.goToOwnerContractDetail({ available: contract.links.self });
        }
    };

    const contractStatusChangeOnClick = async (status) => {
        try {
            const reqConfig = {
                ...contract.links.self.patch,
                isCritical       : false,
                isGlobal         : false,
                shouldReloadData : true,
                data             : {
                    isActive: true,
                    status,
                },
            };
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLIENT_CONTRACTS, resources });
            snackbar.show({
                content   : translated.owners.contracts.statusChange.success,
                isSuccess : true,
            });
            const path = isPartner ? [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS] : [sectionType.OWNERS, sectionType.DETAILS];
            await navigator.setSectionData(path, { shouldReloadData: true });
        } catch (e) {
            snackbar.show({
                content : translated.global.errors.default,
                error   : e,
                isError : true,
            });
        }
    };

    let secondaryCardButton;
    if (contract.status === contractsStatus.ACTIVE && contract?.links?.self?.patch) {
        secondaryCardButton = {
            id      : `contract-${contract.id}-suspend-button`,
            onClick : () => {
                contractStatusChangeOnClick(contractsStatus.SUSPENDED);
            },
            text: translated.owners.contracts.actions.suspend,
        };
    }

    if (contract.status === contractsStatus.SUSPENDED && contract?.links?.self?.patch) {
        secondaryCardButton = {
            id      : `contract-${contract.id}-activate-button`,
            onClick : () => {
                contractStatusChangeOnClick(contractsStatus.ACTIVE);
            },
            text: translated.owners.contracts.actions.activate,
        };
    }

    const getStatusClass = (status) => {
        switch (status) {
            case contractsStatus.SUSPENDED:
                return 'status-disabled';
            case contractsStatus.ACTIVE:
                return 'status-enabled';
            case contractsStatus.EXPIRED:
                return 'status-error';
            default:
                return '';
        }
    };

    const highlightButton = {
        id      : !contract.highlight ? `contract-${contract.id}-set-featured` : `contract-${contract.id}-unset-featured`,
        icon    : !contract.highlight ? 'Star' : 'StarOff',
        onClick : async () => {
            try {
                const reqConfig = {
                    ...contract.links.self.patch,
                    isCritical       : false,
                    isGlobal         : false,
                    shouldReloadData : true,
                    data             : {
                        isActive  : true,
                        highlight : !contract.highlight,
                    },
                };
                await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLIENT_CONTRACTS, resources });
                const path = isPartner ? [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS] : [sectionType.OWNERS, sectionType.DETAILS];
                await navigator.setSectionData(path, { shouldReloadData: true });
                snackbar.show({
                    content   : translated.owners.contracts.statusChange.success,
                    isSuccess : true,
                });
            } catch (e) {
                snackbar.show({
                    error   : e,
                    content : translated.global.errors.default,
                    isError : true,
                });
            }
        },
        tooltip: !contract.highlight ? translated.owners.contracts.featured.highlight : translated.owners.contracts.featured.disable,
    };

    const editButton = {
        id      : `contract-${contract.id}-edit-button`,
        icon    : 'Pencil',
        onClick : () => {
            membershipEditOnClick();
        },
        text: translated.owners.contracts.actions.edit,
    };

    const featuredTitle = contract.highlight ? (
        <>
            <span>
                <FormattedMessage id={translated.owners.membership} defaultMessage={translated.owners.membership} />
            </span>
            <span className="status">
                <FormattedMessage id={translated.owners.contracts.featured.label} defaultMessage={translated.owners.contracts.featured.label} />
            </span>
        </>
    ) : (
        <span>
            <FormattedMessage id={translated.owners.membership} defaultMessage={translated.owners.membership} />
        </span>
    );

    return (
        <Card
            className={`contract-card ${className} ${contract.highlight ? 'is-featured' : ''}`}
            id={`membership-card-${contract.id}`}
            key={`membership-card-${contract.id}`}
            title={featuredTitle}
            unelevated
            primaryButton={
                contract.status === contractsStatus.ACTIVE && contract?.links?.self?.create
                    ? {
                        id      : 'owners-contract-create-button',
                        text    : translated.upgrades.titleShort,
                        onClick : membershipUpgradeOnClick,
                    }
                    : null
            }
            secondaryButton={contract.status !== contractsStatus.EXPIRED ? secondaryCardButton : null}
            primaryRightButton={contract.status === contractsStatus.ACTIVE && contract?.links?.self?.patch ? highlightButton : null}
            secondaryRightButton={contract?.links?.self?.read ? editButton : null}
        >
            <Form isReadOnlyWithoutInputs>
                {contract?.contractType?.clubTier?.club?.name && (
                    <Form.Input
                        id={`contract-${contract.id}-club`}
                        value={contract.contractType.clubTier.club.name}
                        type="text"
                        label={translated.owners.club}
                        onClick={
                            contract.contractType?.clubTier?.club?.links?.self?.update
                                ? () => navigator.goToClubEditor({ available: contract.contractType.clubTier.club.links.self })
                                : null
                        }
                        linkClassName="clickable-link"
                    />
                )}
                {contract?.contractType?.clubTier?.tier?.name && (
                    <Form.Input
                        id={`contract-${contract.id}-tier`}
                        value={contract.contractType.clubTier.tier.name}
                        type="text"
                        label={translated.owners.tier}
                        onClick={
                            contract.contractType?.clubTier?.club?.links?.self?.update && contract.contractType?.clubTier?.links?.self?.update
                                ? () => navigator.goToClubTierEditor({
                                    available    : contract.contractType.clubTier.links.self,
                                    previousPath : [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL],
                                    pathProps    : [
                                        {
                                            with : { resources: { available: contract.contractType.clubTier.club.links.self, current: 'read' } },
                                            on   : sectionType.CLUBS_GENERAL,
                                        },
                                    ],
                                })
                                : null
                        }
                        linkClassName="clickable-link"
                    />
                )}
                {contract?.contractType?.name && (
                    <Form.Input
                        id={`contract-${contract.id}-contract-type`}
                        value={contract.contractType.name}
                        type="text"
                        label={translated.owners.contractType}
                        onClick={
                            contract.contractType?.clubTier?.club?.links?.self?.update
                            && contract.contractType?.clubTier?.links?.self?.update
                            && contract.contractType?.links?.self?.update
                                ? () => navigator.goToClubTierContractTypeEditor({
                                    available : contract.contractType.links.self,
                                    pathProps : [
                                        {
                                            with : { resources: { available: contract.contractType.clubTier.club.links.self, current: 'read' } },
                                            on   : sectionType.CLUBS_GENERAL,
                                        },
                                        {
                                            with : { resources: { available: contract.contractType.clubTier.links.self, current: 'read' } },
                                            on   : sectionType.CLUBS_TIER_EDITOR,
                                        },
                                    ],
                                })
                                : null
                        }
                        linkClassName="clickable-link"
                    />
                )}
                {contract.begin && (
                    <Form.Input
                        id={`contract-${contract.id}-begin`}
                        value={contract.begin}
                        type="text"
                        label={translated.owners.contractTypeStartDate}
                    />
                )}
                {contract.end && (
                    <Form.Input
                        id={`contract-${contract.id}-end`}
                        value={contract.end}
                        type="text"
                        label={translated.owners.contractTypeEndDate}
                    />
                )}
                {contract.base?.id && (
                    <Form.Input
                        id={`contract-${contract.base.id}-baseId`}
                        value={contract.base.id}
                        type="text"
                        label={translated.owners.base}
                    />
                )}
                {contract.legacyId && (
                    <Form.Input
                        id={`contract-${contract.id}-legacyId`}
                        value={contract.legacyId}
                        type="text"
                        label={translated.owners.contractId}
                    />
                )}
                {contract.status && (
                    <Form.Input
                        id={`contract-${contract.status}-legacyId`}
                        value={contract.status}
                        type="text"
                        label={translated.owners.contracts.status}
                        className={`input-with-status ${getStatusClass(contract.status)}`}
                    />
                )}
            </Form>
            {contract?.links?.self?.read && (
                <div className="card-extra-attributes margin-top-small">
                    <Button
                        id={`contract-${contract.id}-view-button`}
                        variant="text"
                        color="primary"
                        className="button-details"
                        onClick={membershipDetailsOnClick}
                    >
                        <FormattedMessage id={translated.global.buttons.viewDetails} defaultMessage={translated.global.buttons.viewDetails} />
                    </Button>
                </div>
            )}
        </Card>
    );
}

ContractCard.defaultProps = {
    className : '',
    isPartner : false,
};

ContractCard.propTypes = {
    contract  : PropTypes.shape({}).isRequired,
    className : PropTypes.string,
    isPartner : PropTypes.bool,
    resources : PropTypes.shape({}).isRequired,
};

export default ContractCard;
