import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
/* eslint-disable-next-line import/no-cycle */
import Sections from 'Components/Sections';
import Button from 'Components/Button';
import Grid from 'Components/Grid';
import Alert from 'Components/Alert';
import Modal from 'Components/Modal';
import Subsection from 'Components/Sections/Subsection';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
import translated from 'Constants/labels/translated';
import { sectionType } from 'Constants/types';
import BalanceEditor from './BalanceEditor';
import TransactionsEditor from './TransactionsEditor';
import Transactions from './Transactions';
import CurrentBalance from './CurrentBalance';
import Loading from 'Components/Loading';
import { walletUtils } from './utils';

const convertSummary = (balances) => {
    const items = walletUtils.formatPointItems(balances);
    return items?.map((item) => ({
        ...item,
        balanceTypeLevel : item.balanceType?.level,
        id               : item.id || item.children?.[0]?.id,
        isUncheckable    : item.isExpired,
    }));
};

export function Wallet({ data, sections, resources, isPartner }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);
    const { summary, transactions } = data;

    // we will manage transactions in the component state to allow use pagination in transactions table with
    // independence, otherwise we would to manage as a independent section
    const [transactionsObject, setTransactionObject] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [transactionEditor, setTransactionEditor] = useState({ isVisible: false, selected: null });
    const [balanceEditor, setBalanceEditor] = useState({ isVisible: false, selected: [] });
    const [balancePreview, setBalancePreview] = useState({ isVisible: false, itemsAndPagination: {}, resource: null });
    const [isHide, setIsHide] = useState(false);
    const [storedSummary, setStoredSummary] = useState({ active: [], expiry: [] });
    const [balances, setBalances] = useState([]);

    const balanceBulkActions = [];

    if (summary?.links?.manage) {
        balanceBulkActions.push({
            key      : 'edit',
            content  : translated.global.buttons.edit,
            callback : (selected) => setBalanceEditor({ isVisible: true, selected }),
        });
    }

    useEffect(() => {
        const fetchExpiryBalances = async (active) => {
            try {
                const link = summary?.filter?.query;
                const params = summary?.filter?.fields;
                params.alreadyExpired = true;
                const wallet = await navigator.directRequest({ ...link, params });
                const hasExpiredBalances = wallet.summary.data.find((balance) => balance.isExpired);
                const expiry = hasExpiredBalances ? convertSummary(wallet.summary) : [];
                setStoredSummary({ active, expiry });
                if (!active) {
                    setBalances(expiry);
                }
            } catch (e) {
                snackbar.show({
                    content : translated.owners.balance.transactions.loadError,
                    isError : true,
                    error   : e,
                });
                setStoredSummary({ active, expiry: undefined });
            }
            setLoading(false);
        };
        const active = convertSummary(summary);
        setBalances(active);

        fetchExpiryBalances(active);
        setIsHide(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const parsedTransactions = walletUtils.getTransactionsObject(transactions);
        setTransactionObject(parsedTransactions);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [data]);

    const pointItems = useMemo(() => walletUtils.formatPointItems(summary), [summary]);

    const transactionsObjectConverted = transactionsObject?.items?.map((item) => ({
        ...item,
        balances: item?.balances?.map((e) => ({ ...e, level: e.balanceType?.level })) || [],
    }));

    const handleShowHideClick = async () => {
        setBalances(!isHide ? storedSummary.expiry : storedSummary.active);
        setIsHide(!isHide);
    };

    const headerActions = storedSummary.expiry?.length > 0 && storedSummary.active?.length > 0
        ? [
            <Button
                id="show-hide-bulk-actions-button"
                key="show-hide-bulk-actions-button"
                variant="text"
                color="primary"
                size="small"
                className="table-selected-items"
                onClick={handleShowHideClick}
            >
                {isHide ? (
                    <FormattedMessage id={translated.owners.balance.buttons.hide} defaultMessage={translated.owners.balance.buttons.hide} />
                ) : (
                    <FormattedMessage id={translated.owners.balance.buttons.show} defaultMessage={translated.owners.balance.buttons.show} />
                )}
            </Button>,
        ]
        : [];

    const { Section, props: subsecProps } = Sections.get(sections);

    const loadTransactionData = async (link) => {
        try {
            const updatedTransactionsData = await navigator.directRequest(link);
            setTransactionObject(walletUtils.getTransactionsObject(updatedTransactionsData));
        } catch (e) {
            snackbar.show({
                content : translated.owners.balance.transactions.loadError,
                isError : true,
                error   : e,
            });
        }
    };

    const handleTransactionDelete = async (transaction) => {
        const handleDeleteWithNoPreview = () => {
            confirmation.show({
                title         : translated.global.sectionChangedConfirmation.title,
                message       : translated.owners.balance.transactions.cancel.confirmationMessage,
                acceptMessage : translated.global.buttons.delete,
                onAccept      : async () => {
                    try {
                        // The delete link does not send the wallet so we need to reload
                        await navigator.requestForCurrentPath({
                            reqConfig : { ...transaction.links.self.delete, shouldReloadData: true },
                            section   : sectionType.WALLET,
                            resources,
                        });
                        snackbar.show({
                            content   : translated.owners.balance.transactions.cancel.success,
                            isSuccess : true,
                        });
                    } catch (e) {
                        snackbar.show({
                            content     : translated.owners.balance.transactions.cancel.error,
                            errorLabels : translated.owners.balance.transactions.cancel.errors,
                            isError     : true,
                            error       : e,
                        });
                    }
                },
            });
        };

        // * If the user has the permissions to access the wallet's preview (the 'manage' link), we request that data.
        //   - If that request is successful, we show the preview and ask for a confirmation to the user. For the actual delete,
        //   we will try to use the link for the transaction delete, because that action is less restrictive than the 'manage' delete.
        //   - If that request fails, we do the same we do when the user can delete the transaction. (the step below)
        // * If the user has the permission to delete the transaction, we show a simple confirmation. If the user confirms, we execute
        // the transaction's delete link.
        // * If the user has none permission, we show an error snackbar.
        if (transaction?.links?.manage) {
            setLoading(true);

            try {
                // We show the preview of the balance the we get directly
                const previewData = await navigator.directRequest(transaction.links.manage.preview);
                const parsedBalancePreview = walletUtils.getTransactionsObject(previewData.summary);

                setBalancePreview({
                    isVisible          : true,
                    itemsAndPagination : parsedBalancePreview,
                    resource           : transaction.links.self.delete || transaction.links.manage.delete,
                });
            } catch (e) {
                if (!transaction?.links?.self?.delete) {
                    // The link for the manage fails and there is no link self.delete.
                    snackbar.show({
                        content     : translated.owners.balance.transactions.cancel.error,
                        errorLabels : translated.owners.balance.transactions.cancel.errors,
                        isError     : true,
                        error       : e,
                    });
                } else {
                    // We try to delete the transaction without the preview.
                    handleDeleteWithNoPreview();
                }
            }

            setLoading(false);
        } else if (transaction?.links?.self?.delete) {
            handleDeleteWithNoPreview();
        } else {
            snackbar.show({
                content : translated.owners.balance.transactions.deleteError,
                isError : true,
            });
        }
    };

    const handlePerformRequestAfterPreview = async () => {
        try {
            await navigator.requestForCurrentPath({
                reqConfig : { ...balancePreview.resource, shouldReloadData: true },
                section   : sectionType.WALLET,
                resources,
            });
            setBalancePreview({ isVisible: false, data: [], resource: null });
            snackbar.show({
                content   : translated.owners.balance.transactions.cancel.success,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content     : translated.owners.balance.transactions.cancel.error,
                errorLabels : translated.owners.balance.transactions.cancel.errors,
                isError     : true,
                error       : e,
            });
        }
    };

    const buttons = [];

    if (data?.links?.points) {
        buttons.push(
            <Button
                id="wallet-manage-button"
                variant="outlined"
                color="primary"
                key="button_primary"
                onClick={navigator.goToWalletManager}
                disabled={subsecProps?.fetching.isGlobal}
                isLoading={subsecProps?.fetching.isGlobal && subsecProps.config.resourceName === 'points'}
            >
                <FormattedMessage
                    id={translated.owners.balance.pointsOperationsButton}
                    defaultMessage={translated.owners.balance.pointsOperationsButton}
                />
            </Button>,
        );
    }

    const getContractRow = (id, link) => ({
        value: (
            <a
                className="clickable-link"
                onClick={() => {
                    if (isPartner) {
                        navigator.goToPartnerContractEditor({ available: link });
                    } else {
                        navigator.goToOwnerContractEditor({ available: link });
                    }
                }}
            >
                {id}
            </a>
        ),
    });

    return (
        <Subsection title={translated.owners.balance.title} actionButtons={buttons}>
            {isLoading && <Loading />}

            {Section && (
                <Section
                    {...subsecProps}
                    resources={{ ...subsecProps.resources, available: data.links[subsecProps.config.resourceName], current: 'init' }}
                    onClose={() => navigator.goToParentAndReload(true, false)}
                    directRequest={navigator.directRequest}
                    requestForCurrentPath={navigator.requestForCurrentPath}
                    showSnackbar={snackbar.show}
                    isPartner={isPartner}
                />
            )}

            {balanceEditor.isVisible && (
                <BalanceEditor
                    selectedBalances={balanceEditor.selected}
                    navigator={navigator}
                    resources={summary.links.manage}
                    snackbar={snackbar}
                    onFinish={(changed) => {
                        setBalanceEditor({ isVisible: false, selected: [] });
                        if (changed) {
                            navigator.setSectionData([sectionType.WALLET], { shouldReloadData: true });
                        }
                    }}
                    parentResources={resources}
                />
            )}

            {transactionEditor.isVisible && (
                <TransactionsEditor
                    selectedTransaction={transactionEditor.selected}
                    resources={resources}
                    navigator={navigator}
                    snackbar={snackbar}
                    onFinishSubmit={() => setTransactionEditor({ isVisible: false, selected: [] })}
                />
            )}

            {balancePreview.isVisible && (
                <Modal
                    title={translated.global.sectionChangedConfirmation.title}
                    className="balance-preview-modal"
                    closeButton={{
                        text    : translated.global.buttons.delete,
                        onClick : handlePerformRequestAfterPreview,
                    }}
                    cancelButton={{
                        text    : translated.global.buttons.cancel,
                        onClick : () => setBalancePreview({ isVisible: false, itemsAndPagination: {}, resource: null }),
                    }}
                >
                    {!balancePreview?.itemsAndPagination?.items?.length && (
                        <p>
                            <FormattedMessage
                                id={translated.owners.balance.editor.emptySummary}
                                defaultMessage={translated.owners.balance.editor.emptySummary}
                            />
                        </p>
                    )}
                    {!!balancePreview?.itemsAndPagination?.items?.length && (
                        <>
                            <p>
                                <FormattedMessage
                                    id={translated.owners.balance.editor.cancelWarning}
                                    defaultMessage={translated.owners.balance.editor.cancelWarning}
                                />
                            </p>
                            <p className="margin-bottom-medium">
                                <FormattedMessage
                                    id={translated.owners.balance.editor.acceptOrRefuse}
                                    defaultMessage={translated.owners.balance.editor.acceptOrRefuse}
                                />
                            </p>
                            <CurrentBalance id="current-balance-preview" items={balancePreview.itemsAndPagination.items} />
                        </>
                    )}
                </Modal>
            )}

            {!pointItems && !transactionsObject.items && !storedSummary.active?.length && (
                <Alert id="balance-empty" content={translated.owners.balance.clubPoints.empty} className="margin-top-small" />
            )}

            {balances?.length > 0 && (
                <CurrentBalance
                    id="current-balance"
                    isEditable={!!summary?.links?.manage && storedSummary?.active?.length > 0}
                    bulkActions={balanceBulkActions}
                    headerActions={headerActions}
                    items={balances}
                    getContractRow={getContractRow}
                    totals={summary?.totals}
                />
            )}

            {transactionsObject.items ? (
                <Transactions
                    id="transactions"
                    loadTransactionData={loadTransactionData}
                    itemsAndPagination={{ items: transactionsObjectConverted, pagination: transactionsObject.pagination }}
                    onDelete={handleTransactionDelete}
                    getContractRow={getContractRow}
                />
            ) : (
                !!pointItems && <Alert id="transactions-empty" className="margin-top-small" content={translated.owners.balance.transactions.empty} />
            )}
        </Subsection>
    );
}

Wallet.defaultProps = {
    isPartner : false,
    name      : '',
};

Wallet.propTypes = {
    name      : PropTypes.string,
    isPartner : PropTypes.bool,
    data      : PropTypes.shape({}).isRequired,
    sections  : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
};

Wallet.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isSubHeading width={115} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table hasTitle hasActions={false} />
                <Skeleton.Table hasTitle hasActions={false} />
                <Skeleton.Table hasTitle hasActions={false} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(Wallet);
