/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localStorage } from 'Services';
import * as panelActions from 'State/actions/panel';
import * as appActions from 'State/actions/app';
import * as qaActions from 'State/actions/qa';
import { getSelectableParentPath } from 'State/configureStore';
import { EventManager, Navigator, SharedConfigurations, DateManager } from 'Classes';
import setDarkMode from 'Utils/setDarkMode';
import PanelContext from 'State/panelContext';
import NavBar from 'Components/NavBar';
import SideDrawer from 'Components/SideDrawer';
import Sections from 'Components/Sections';
import AuthenticationWrapper from 'Components/AuthenticationWrapper';
import ErrorHandler from 'Components/ErrorHandler';
import Snackbar from 'Components/Snackbar';
import ConfirmationModal from 'Components/ConfirmationModal';
import LocaleProvider from 'Components/locale/LocaleProvider';

/**
 * In charge of making the necessary initializations. It acts as an intermediary between the system components and the state in Redux.
 * Takes the current state of the application, organizes it and makes it available to the different modules through a React context.
 * Creates the main instances needed to render the sections and navigation elements, as well as to manage the feedback and user interaction.
 */
function Panel({
    sections,
    path,
    currentURL,
    appError,
    appState,
    toggle,
    snackbar,
    confirmation,
    showSnackbar,
    hideSnackbar,
    showConfirmation,
    setUser,
    setLanguage,
    setQAActive,
    hideConfirmation,
    selectDefaultSection,
    setRootAndTemplates,
    sectionsHandlers,
    appHandlers,
    initializeNotifications,
    notifications,
    shouldRefreshUser,
    getParentPath,
    shouldReloadUrlStage,
    user,
    pushToUrl,
    setShouldReloadUrlStage,
    updateNotifications,
    updateNotificationsAmount,
    isQAActive,
    handleNotification,
}) {
    // Only need to recompute when the permissions changes
    const sharedConfigurations = useMemo(() => new SharedConfigurations(sections), [shouldRefreshUser]);
    const eventManager = useMemo(() => new EventManager(), []);
    const navigator = useMemo(() => new Navigator(sectionsHandlers, getParentPath, selectDefaultSection, path, appState), [path.current]);

    const dateManager = useMemo(() => new DateManager(appState.language), []);
    useEffect(() => {
        dateManager.setLanguage(appState.language);
    }, [appState.language]);

    const QA = useMemo(() => ({ isActive: isQAActive, setActive: setQAActive }), [isQAActive]);
    const url = useMemo(() => ({ current: currentURL, pushToUrl, shouldReloadUrlStage, setShouldReloadUrlStage }), [shouldReloadUrlStage, currentURL]);
    const app = useMemo(
        () => ({
            ...appState,
            ...appHandlers,
            shouldRefreshUser,
            setRootAndTemplates,
            setLanguage,
            handleNotification: (notification) => handleNotification(notification, navigator),
        }),
        [appState.isSideDrawerVisible, appState.isSideDrawerCollapsed, appState.isDarkModeEnabled, appState.areBreadcrumbTabsVisible, shouldRefreshUser],
    );

    const panelContext = useMemo(() => {
        if (!app.isLocalStorageLoaded) {
            localStorage.loadInitialAppConfiguration(appHandlers.setAppState);
        }

        return {
            isContextInitialized : true,
            user                 : { ...user, set: setUser },
            snackbar             : { show: showSnackbar, hide: hideSnackbar },
            confirmation         : { show: showConfirmation, hide: hideConfirmation },
            app,
            url,
            navigator,
            sharedConfigurations,
            eventManager,
            toggle,
            path,
            sections,
            QA,
            dateManager,
        };
    }, [app, path.current, shouldRefreshUser, user, url]);

    useEffect(() => {
        setDarkMode(appState.isDarkModeEnabled);
    }, [appState.isDarkModeEnabled]);

    return (
        <PanelContext.Provider value={panelContext}>
            <LocaleProvider defaultLanguage={appState.language} setLanguage={setLanguage} refresh={appHandlers.refreshUser}>
                <ErrorHandler restrictedApp={appState.restrictedApp} clearErrors={appHandlers.clearError} {...appError}>
                    <AuthenticationWrapper currentState={appState} initializeNotifications={initializeNotifications}>
                        <NavBar
                            sections={sections}
                            selectDefaultSection={selectDefaultSection}
                            notification={{ ...notifications, update: updateNotifications, updateAmount: updateNotificationsAmount }}
                            isCallNotificationActive={!!snackbar?.notification?.elements?.length}
                        />
                        <SideDrawer sections={sections} refresh={appHandlers.refreshUser} language={appState.language} />
                        <Sections.Workspace app={app} sections={sections} navigator={navigator} path={path} url={url} QA={QA} />
                        <Snackbar {...snackbar} />
                        <ConfirmationModal {...confirmation} />
                    </AuthenticationWrapper>
                </ErrorHandler>
            </LocaleProvider>
        </PanelContext.Provider>
    );
}

const mapStateToProps = (state) => ({
    sections             : state.panel.sections,
    path                 : state.panel.path,
    shouldReloadUrlStage : state.panel.shouldReloadUrlStage,
    currentURL           : state.panel.path.url,
    appState             : state.app,
    snackbar             : state.app.snackbar,
    confirmation         : state.app.confirmation,
    appError             : state.app.error,
    shouldRefreshUser    : state.app.error.shouldRefreshUser,
    user                 : state.app.user,
    notifications        : state.app.notifications,
    isQAActive           : state.qa.isActive,
    getParentPath        : () => getSelectableParentPath(state),
});

const mapDispatchToProps = (dispatch) => ({
    sectionsHandlers: {
        canLeaveSection                       : async () => dispatch(panelActions.canLeaveSection()),
        directRequest                         : (reqConfig) => dispatch(panelActions.directRequest(reqConfig)),
        disableSections                       : (paths) => dispatch(panelActions.disableSections(paths)),
        selectSectionForBackAction            : () => dispatch(panelActions.selectSectionForBackAction()),
        setSectionState                       : (path, state) => dispatch(panelActions.setSectionState(path, state)),
        setSectionData                        : (path, state) => dispatch(panelActions.setSectionData(path, state)),
        setSectionsIds                        : (idsList) => dispatch(panelActions.setSectionsIds(idsList)),
        sectionLoaded                         : (path, data) => dispatch(qaActions.sectionLoaded(path, data)),
        // eslint-disable-next-line max-len
        selectSection                         : (path, selectedBranchProps, unselectedBranchProps, oneLevelDeepPropSet) => dispatch(panelActions.selectSection(path, selectedBranchProps, unselectedBranchProps, oneLevelDeepPropSet)),
        setCurrentSectionComponentWithChanges : async (isChanged, subsection = 'all') => dispatch(panelActions.setCurrentSectionComponentWithChanges(isChanged, subsection)),
        // eslint-disable-next-line max-len
        request                               : (reqConfig, path, isCurrentSection, resources, useMock) => dispatch(panelActions.request(reqConfig, path, isCurrentSection, resources, useMock)),
    },
    appHandlers: {
        refreshUser     : (refreshType) => dispatch(appActions.refreshUser(refreshType)),
        setErrorState   : (title, status, isCritical) => dispatch(appActions.setAppErrorState(title, status, isCritical)),
        loaded          : () => dispatch(qaActions.appLoaded()),
        setAppState     : (state) => dispatch(appActions.setAppState(state)),
        setAsRestricted : (isAppRestricted, message, reason) => dispatch(appActions.setAppAsRestricted(isAppRestricted, message, reason)),
        clearError      : () => dispatch(appActions.clearAppError()),
    },
    setQAActive               : () => dispatch(qaActions.setQAActive()),
    selectDefaultSection      : () => dispatch(panelActions.selectDefaultSection()),
    pushToUrl                 : (url) => dispatch(panelActions.pushToUrl(url)),
    setShouldReloadUrlStage   : (stage) => dispatch(panelActions.setShouldReloadUrlStage(stage)),
    setRootAndTemplates       : async (rootAndTemplates) => dispatch(panelActions.setRootAndTemplates(rootAndTemplates)),
    showSnackbar              : async (props) => dispatch(appActions.showSnackbar(props)),
    hideSnackbar              : (id = null) => dispatch(appActions.hideSnackbar(id)),
    showConfirmation          : (props) => dispatch(appActions.showConfirmation(props)),
    hideConfirmation          : () => dispatch(appActions.hideConfirmation()),
    setUser                   : async (loggedUser) => dispatch(appActions.setUser(loggedUser)),
    setLanguage               : async (language) => dispatch(appActions.setLanguage(language)),
    initializeNotifications   : async (config) => dispatch(appActions.initializeNotifications(config)),
    updateNotifications       : async () => dispatch(appActions.updateNotifications()),
    updateNotificationsAmount : async () => dispatch(appActions.updateNotificationsAmount()),
    handleNotification        : (notification, navigator) => {
        const action = appActions.handleNotification(notification, navigator);
        if (action) {
            dispatch(action);
        }
    },
    toggle: {
        darkMode                 : () => dispatch(appActions.toggleDarkMode()),
        mock                     : () => dispatch(appActions.toggleUseMock()),
        tour                     : () => dispatch(appActions.toggleTour()),
        breadcrumbTabsVisibility : () => dispatch(appActions.toggleBreadcrumbTabsVisibility()),
        sideDrawerVisibility     : () => dispatch(appActions.toggleSideDrawerVisibility()),
        sideDrawerCollapsed      : () => dispatch(appActions.toggleSideDrawerCollapsed()),
        localStorageLoaded       : () => dispatch(appActions.toggleIsLocalStorageLoaded()),
    },
});

Panel.defaultProps = { user: null };

Panel.propTypes = {
    isQAActive                : PropTypes.bool.isRequired,
    shouldRefreshUser         : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    setQAActive               : PropTypes.func.isRequired,
    // Redux props.
    sections                  : PropTypes.shape({}).isRequired,
    path                      : PropTypes.shape({}).isRequired,
    currentURL                : PropTypes.string.isRequired,
    appError                  : PropTypes.shape({}).isRequired,
    appState                  : PropTypes.shape({}).isRequired,
    snackbar                  : PropTypes.shape({}).isRequired,
    confirmation              : PropTypes.shape({}).isRequired,
    shouldReloadUrlStage      : PropTypes.string.isRequired,
    user                      : PropTypes.shape({}),
    notifications             : PropTypes.shape({}).isRequired,
    // Redux actions.
    pushToUrl                 : PropTypes.func.isRequired,
    setShouldReloadUrlStage   : PropTypes.func.isRequired,
    getParentPath             : PropTypes.func.isRequired,
    setRootAndTemplates       : PropTypes.func.isRequired,
    selectDefaultSection      : PropTypes.func.isRequired,
    setUser                   : PropTypes.func.isRequired,
    setLanguage               : PropTypes.func.isRequired,
    updateNotifications       : PropTypes.func.isRequired,
    updateNotificationsAmount : PropTypes.func.isRequired,
    initializeNotifications   : PropTypes.func.isRequired,
    showSnackbar              : PropTypes.func.isRequired,
    hideSnackbar              : PropTypes.func.isRequired,
    showConfirmation          : PropTypes.func.isRequired,
    hideConfirmation          : PropTypes.func.isRequired,
    handleNotification        : PropTypes.func.isRequired,
    appHandlers               : PropTypes.shape({
        refreshUser     : PropTypes.func.isRequired,
        setErrorState   : PropTypes.func.isRequired,
        loaded          : PropTypes.func.isRequired,
        setAppState     : PropTypes.func.isRequired,
        setAsRestricted : PropTypes.func.isRequired,
        clearError      : PropTypes.func.isRequired,
    }).isRequired,
    sectionsHandlers: PropTypes.shape({
        selectSection                         : PropTypes.func.isRequired,
        sectionLoaded                         : PropTypes.func.isRequired,
        request                               : PropTypes.func.isRequired,
        setSectionState                       : PropTypes.func.isRequired,
        setSectionData                        : PropTypes.func.isRequired,
        setSectionsIds                        : PropTypes.func.isRequired,
        setCurrentSectionComponentWithChanges : PropTypes.func.isRequired,
        canLeaveSection                       : PropTypes.func.isRequired,
        directRequest                         : PropTypes.func.isRequired,
        disableSections                       : PropTypes.func.isRequired,
        selectSectionForBackAction            : PropTypes.func.isRequired,
    }).isRequired,
    toggle: PropTypes.shape({
        darkMode                 : PropTypes.func.isRequired,
        tour                     : PropTypes.func.isRequired,
        useMock                  : PropTypes.func,
        sideDrawerVisibility     : PropTypes.func.isRequired,
        breadcrumbTabsVisibility : PropTypes.func.isRequired,
        sideDrawerCollapsed      : PropTypes.func.isRequired,
        localStorageLoaded       : PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
