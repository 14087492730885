import React from 'react';
import PropTypes from 'prop-types';
import { withAuthSync } from '../../utils/auth';

export function AuthenticationWrapper({ children }) {
    return (
        <div id="main-wrapper">
            {children}
        </div>
    );
}

AuthenticationWrapper.propTypes = {
    children     : PropTypes.node.isRequired,
    currentState : PropTypes.shape({ isDarkModeEnabled: PropTypes.bool.isRequired }).isRequired,
};

export default withAuthSync(AuthenticationWrapper);
