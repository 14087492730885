import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Checkbox({
    className, label, onChange, onFocus, onBlur, value, shouldPreventDefault, isDisabled, id, innerInputRef, isReadOnlyWithoutInputs,
}) {
    // If we do not handle onChange event inside this component, we need to avoid the propagation of
    // the onClick event produced by label, which is going to trigger a new onClick event inside
    // the element that actually handles the onChange.
    if (isReadOnlyWithoutInputs) {
        return (
            <>
                {label && (
                    <label id={`${id}-label`} className="label">
                        <WrappedFormattedMessage content={label} />
                    </label>
                )}
                <span id={id}>
                    {value ? (
                        <FormattedMessage id={translated.global.state.yes} defaultMessage={translated.global.state.yes} />
                    ) : (
                        <FormattedMessage id={translated.global.state.no} defaultMessage={translated.global.state.no} />
                    )}
                </span>
            </>
        );
    }

    return (
        <label
            id={`${id}-label`}
            onClick={(e) => shouldPreventDefault && e.preventDefault()}
            onFocus={(e) => {
                if (onFocus) {
                    onFocus(e);
                }
            }}
            onBlur={(e) => {
                if (onBlur) {
                    onBlur(e);
                }
            }}
            onMouseOver={(e) => {
                if (onFocus) {
                    onFocus(e);
                }
            }}
            onMouseOut={(e) => {
                if (onBlur) {
                    onBlur(e);
                }
            }}
        >
            <div className={`checkbox ${className}`}>
                <input
                    id={id}
                    type="checkbox"
                    onChange={(e) => onChange({ target: { value: e.target.checked } })}
                    checked={value}
                    tabIndex={isDisabled ? '-1' : '0'}
                    ref={innerInputRef}
                    disabled={isDisabled}
                />
                <span />
            </div>
            {label && (
                <span>
                    <WrappedFormattedMessage content={label} />
                </span>
            )}
        </label>
    );
}

Checkbox.defaultProps = {
    id                      : '',
    className               : '',
    label                   : '',
    onChange                : null,
    onFocus                 : null,
    onBlur                  : null,
    value                   : false,
    shouldPreventDefault    : false,
    isDisabled              : false,
    innerInputRef           : null,
    isReadOnlyWithoutInputs : false,
};

Checkbox.propTypes = {
    id                      : PropTypes.string,
    className               : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    value                   : PropTypes.bool,
    shouldPreventDefault    : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Checkbox);
