import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import Form from 'Components/Form';
import ConversionCard from './ConversionCard';
import { transformToSortList, getCurrencyId } from './utils';

function ConversionRates() {
    const { watch, setValue } = useFormContext();

    const [
        ranges,
        updatedBalanceTypes,
        balanceTypesOptions,
        availableBalanceTypes,
        availableConversionModes,
        conversions,
        balanceTypes,
    ] = watch(['ranges', 'balanceTypes', 'balanceTypesOptions', 'availableBalanceTypes', 'availableConversionModes', 'conversions', 'balanceTypes']);

    const list = transformToSortList(ranges);

    const options = useMemo(() => availableConversionModes?.map((mode) => ({
        label : translated.rules.dynamicRanges.conversionRates.modes[mode],
        value : mode,
    }) || []), [availableConversionModes]);

    const handleOnChangeBalanceTypes = useCallback((ids) => {
        // Get the range ids
        const rangeIds = Object.keys(ranges);

        // We find the elements that have been added or removed.
        const sameElements = ids?.filter?.((newBt) => !!balanceTypes?.find((oldBt) => newBt === oldBt)) || [];

        const newElements = ids?.filter?.((newBt) => !balanceTypes?.find((oldBt) => newBt === oldBt)) || [];
        newElements?.forEach((eachNew) => {
            const currencyId = getCurrencyId(eachNew, availableBalanceTypes);

            rangeIds?.forEach?.((eachRangeId) => {
                const value = { roomValue: 1, serviceValue: 1, serviceValueAreEquals: true, roomValueAreEquals: true, isRequired: true };
                // We set the balance type conversion value
                setValue(`conversions.${eachRangeId}-${currencyId}-${eachNew}`, value);

                // If the balance is the first one of the currency, we add the range's currency values
                if (!sameElements.find((e) => getCurrencyId(e, availableBalanceTypes) === currencyId)) {
                    const key = `conversions.${eachRangeId}-${currencyId}`;
                    const formOptions = { shouldDirty: true, shouldValidate: true };

                    setValue(key, value, formOptions);
                } else {
                    // We calculate the 'areEquals' for the currency's rate
                    const roomValues = new Set();
                    roomValues.add('1');
                    const serviceValues = new Set();
                    serviceValues.add('1');

                    Object.keys(conversions)
                        ?.filter((e) => e.startsWith(`${eachRangeId}-${currencyId}-`))
                        ?.forEach((e) => {
                            if (conversions[e]?.roomValue != null) {
                                roomValues.add(String(conversions[e]?.roomValue));
                            }
                            if (conversions[e]?.serviceValue != null) {
                                serviceValues.add(String(conversions[e]?.serviceValue));
                            }
                        });

                    let areEquals = roomValues.size === 1;
                    let changedKey = `conversions.${eachRangeId}-${currencyId}.roomValueAreEquals`;
                    setValue(changedKey, areEquals);

                    areEquals = serviceValues.size === 1;
                    changedKey = `conversions.${eachRangeId}-${currencyId}.serviceValueAreEquals`;
                    setValue(changedKey, areEquals);
                }
            });
        });

        const deletedElements = balanceTypes?.filter?.((newBt) => !ids?.find((oldBt) => newBt === oldBt)) || [];
        deletedElements?.forEach((eachDeleted) => {
            const currencyId = getCurrencyId(eachDeleted, availableBalanceTypes);

            rangeIds?.forEach?.((eachRangeId) => {
                // We remove the balance type conversion value
                setValue(`conversions.${eachRangeId}-${currencyId}-${eachDeleted}`, null);

                // If the balance was the last one of the currency, we remove the range's currency values
                if (!sameElements.find((e) => getCurrencyId(e, availableBalanceTypes) === currencyId)) {
                    setValue(`conversions.${eachRangeId}-${currencyId}`, null);
                } else {
                    // We calculate the 'areEquals' for the currency's rate
                    const roomValues = new Set();
                    const serviceValues = new Set();

                    Object.keys(conversions)
                        ?.filter((e) => e.startsWith(`${eachRangeId}-${currencyId}-`))
                        ?.filter((e) => !e.endsWith(`-${eachDeleted}`))
                        ?.forEach((e) => {
                            if (conversions[e]?.roomValue != null) {
                                roomValues.add(String(conversions[e]?.roomValue));
                            }
                            if (conversions[e]?.serviceValue != null) {
                                serviceValues.add(String(conversions[e]?.serviceValue));
                            }
                        });

                    const roomValueAreEquals = roomValues.size === 1;
                    const serviceValueAreEquals = serviceValues.size === 1;
                    const key = `conversions.${eachRangeId}-${currencyId}`;
                    const value = {
                        roomValue    : roomValueAreEquals ? Array.from(roomValues)[0] : '',
                        serviceValue : serviceValueAreEquals ? Array.from(serviceValues)[0] : '',
                        isRequired   : true,
                        roomValueAreEquals,
                        serviceValueAreEquals,
                    };
                    const formOptions = { shouldDirty: true, shouldValidate: true };

                    setValue(key, value, formOptions);
                }
            });
        });
    }, [availableBalanceTypes, conversions, ranges, setValue, balanceTypes]);

    const currenciesConfigured = useMemo(
        () => {
            const converted = [];

            updatedBalanceTypes?.forEach?.((eachBalanceType) => {
                const foundBalanceType = availableBalanceTypes?.find?.((eachBalanceCurrency) => eachBalanceCurrency.id === eachBalanceType);

                if (foundBalanceType) {
                    let foundCurrency = converted.find((eachConverted) => eachConverted.id === foundBalanceType.currency?.id);

                    if (!foundCurrency) {
                        foundCurrency = { ...foundBalanceType.currency, balanceTypes: [] };
                        converted.push(foundCurrency);
                    }

                    foundCurrency.balanceTypes.push({
                        id   : foundBalanceType.id,
                        name : foundBalanceType.name,
                    });
                }
            });

            return converted;
        },
        [availableBalanceTypes, updatedBalanceTypes],
    );

    return (
        <Subsection title={translated.rules.dynamicRanges.conversionRates.title}>
            <Form.InputNew
                type="chipPicker"
                submitKey="conversionMode"
                label={translated.rules.dynamicRanges.conversionRates.sourceBalances}
                options={options}
                isDense
            />
            <Form.InputNew
                type="select"
                submitKey="balanceTypes"
                label={translated.balanceTypes.title}
                options={balanceTypesOptions}
                onChange={handleOnChangeBalanceTypes}
                isDense
                isMultiSelect
            />

            { !!updatedBalanceTypes?.length && list.map(
                (eachCurrency) => (
                    <ConversionCard
                        key={eachCurrency.id}
                        range={eachCurrency}
                        currencies={currenciesConfigured}
                    />
                ),
            )}
        </Subsection>
    );
}

export default ConversionRates;
