import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import PanelContext from 'State/panelContext';
import LocaleContext from 'State/localeContext';
import Loading from 'Components/Loading';
import List from './List';
import withRequest from 'Components/Sections/withRequest';

function buildMenuItems(data, onClick) {
    const items = [];
    data.forEach((item) => {
        const children = item.children ? buildMenuItems(item.children, onClick) : undefined;
        const value = {
            id      : item.id,
            text    : item.title,
            onClick : () => onClick(item.title, item.links.content),
            children,
        };
        items.push(value);
    });

    return items;
}

export function Doc({ data, title }) {
    const { locale: [language] } = useContext(LocaleContext);
    const { navigator, snackbar } = useContext(PanelContext);
    const [document, setDocument] = useState();
    const [documentTitle, setDocumentTitle] = useState();
    const [isLoading, setLoading] = useState(false);

    const handleItemClick = useCallback(async (text, link) => {
        setLoading(true);
        setDocumentTitle(text);
        try {
            const params = { language };
            const config = {
                ...link.read,
                params,
            };
            const { body } = await navigator.directRequest(config);
            setDocument(body);
        } catch (error) {
            snackbar.show({
                error,
                isError     : true,
                errorLabels : translated.documentation.error,
            });
        } finally {
            setLoading(false);
        }
    }, [language, navigator, snackbar]);

    const items = buildMenuItems(data.data, handleItemClick);

    return (
        <Wrapper title={title}>
            <Grid className="docs-grid">
                <Grid.Column className="docs-list-column" width={{ base: 12, small: 3 }}>
                    <List items={items} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 9 }}>
                    {isLoading && <Loading />}
                    {!isLoading && (
                        <>
                            <h5 className="font-weight-bold">{documentTitle}</h5>
                            <div dangerouslySetInnerHTML={{ __html: document }} />
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Wrapper>
    );
}

Doc.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium">
            <Grid.Column width={{ base: 12, small: 3 }}>
                <Skeleton.Title isHeading width={315} />
                <Skeleton.Paragraph width={200} quantity={15} className="margin-top-xlarge" />
            </Grid.Column>
            <Grid.Column width={{ base: 12, small: 9 }} className="text-align-right" />
        </Grid>
    );
};

Doc.defaultProps = { title: '' };

Doc.propTypes = {
    data  : PropTypes.shape({}).isRequired,
    title : PropTypes.string,
};

export default withRequest(Doc);
