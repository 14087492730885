import { hot } from 'react-hot-loader/root';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import Modal from 'Components/Modal';
import Button from 'Components/Button';
import Alert from 'Components/Alert';

function Settings({ onClose, links }) {
    const { snackbar, navigator } = useContext(PanelContext);

    const handleClickHotelSync = async () => {
        try {
            const { sync } = links.configurations;
            await navigator.directRequest(sync);
            snackbar.show({ content: translated.settings.success.sync, isSuccess: true });
        } catch (e) {
            snackbar.show({ error: e, content: translated.settings.errors.sync, isError: true });
        }
    };

    return (
        <Modal
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : () => onClose(),
            }}
            className="sync-modal"
            title={translated.settings.title}
        >
            <p className="margin-remove-bottom">
                <FormattedMessage id={translated.settings.content.sync} defaultMessage={translated.settings.content.sync} />
            </p>
            <p className="margin-remove-top">
                <FormattedMessage id={translated.settings.buttons.tooltip} defaultMessage={translated.settings.buttons.tooltip} />
            </p>
            <Alert
                id="alert-sync"
                type="warning"
                content={translated.settings.alert.sync}
            />
            <Button
                id="sync"
                onClick={handleClickHotelSync}
                icon="Sync"
                className="margin-top-medium button-expanded"
            >
                <FormattedMessage id={translated.settings.buttons.label} defaultMessage={translated.settings.buttons.label} />
            </Button>
        </Modal>
    );
}

Settings.propTypes = {
    onClose : PropTypes.func.isRequired,
    links   : PropTypes.shape({}).isRequired,
};

export default hot(Settings);
