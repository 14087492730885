import { mdiCounter } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const balanceTypeId = null;

export default {
    [sectionType.BALANCE_TYPES]: {
        isEnabled : false,
        config    : { url: '/panel/balanceTypes', icon: mdiCounter },
        sections  : {
            [sectionType.BALANCE_TYPES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.BALANCE_TYPES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.BALANCE_TYPES_EDITOR]: {
                ids       : { balanceTypeId, self: 'balanceTypeId' },
                resources : { templates: { name: resourceNames.BALANCE_TYPE } },
                isRoot    : true,
                config    : { url: { withId: '/(\\d+)', withoutId: '/add' } },
                sections  : {
                    [sectionType.ENTITY_TAGS]: {
                        ids             : { balanceTypeId },
                        config          : { resourceName: resourceNames.TAGS },
                        pathForResource : [
                            sectionType.BALANCE_TYPES, sectionType.BALANCE_TYPES_EDITOR, sectionType.ENTITY_TAGS,
                        ],
                        isEmbedded: true,
                    },
                },
            },
        },
    },
};
