import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from '../Collection';

function PropertyGroups({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

PropertyGroups.propTypes = sectionPropTypes;

export default PropertyGroups;
