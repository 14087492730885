import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Form, { FormError, FormNew } from 'Components/Form';
import Table from 'Components/Table';
import Alert from 'Components/Alert';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';
import yup from 'Utils/yupHelper';

const convertList = (list) => list?.map((each) => ({
    ...each,
    convertedCategories: each.categories?.join(', ') || '',
})) || [];

const schema = yup.object().shape({
    name : yup.string().required().max(20),
    code : yup.string().required().max(20),
});

function Editor({ onClose, data, isEditing, resources }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const handleOnSubmit = async (values) => {
        const reqConfig = {
            data: {
                ...values,
                isActive: typeof data.isActive !== 'undefined' ? data.isActive : true,
            },
            ...resources.available[isEditing ? 'update' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });

            snackbar.show({ content: isEditing ? translated.propertyGroups.update.success : translated.propertyGroups.save.success, isSuccess: true });
        } catch (e) {
            throw new FormError(isEditing ? translated.propertyGroups.update.error : translated.propertyGroups.save.error, e);
        }
    };

    const onFinish = () => {
        navigator.goToParentAndReload(false, false);
    };

    const propertyGroups = convertList(data.children);

    const columns = [
        {
            title     : translated.propertyGroups.id,
            key       : 'id',
            isVisible : false,
        },
        {
            title     : translated.propertyGroups.code,
            key       : 'code',
            isVisible : true,
        },
        {
            title     : translated.propertyGroups.name,
            key       : 'name',
            isVisible : true,
        },
        {
            title     : translated.propertyGroups.isActive,
            key       : 'isActive',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
        },
    ];

    const initialValues = {
        name : data?.name || '',
        code : data?.code || '',
    };

    const canMakeChanges = isEditing ? !!resources?.available?.update : !!resources?.available?.create;

    const title = (isEditing && (canMakeChanges ? translated.propertyGroups.editTitle : translated.propertyGroups.viewTitle))
        || translated.propertyGroups.createTitle;

    const commonProps = { isDisabled: !canMakeChanges };

    const modalPropertiesForForm = useMemo(
        () => ({
            title,
            buttons:
                canMakeChanges
                    ? [
                        <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={onClose}>
                            <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                        </Form.SecondaryNew>,
                        <Form.PrimaryNew variant="text" color="primary" key="fp">
                            {isEditing ? (
                                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                            ) : (
                                <FormattedMessage id={translated.global.buttons.create} defaultMessage={translated.global.buttons.create} />
                            )}
                        </Form.PrimaryNew>,
                    ]
                    : [
                        <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                            <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
                        </Form.SecondaryNew>,
                    ]
            ,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEditing, onClose],
    );

    return (
        <Form.WrapperNew
            modalProperties={modalPropertiesForForm}
            formId="property-groups-form"
            schema={schema}
            initialValues={initialValues}
        >
            <FormNew
                buttonsWidth={{ base: 12, small: 6 }}
                onSubmit={handleOnSubmit}
                onFinish={onFinish}
            >
                <Form.InputNew
                    isDense
                    isRequired
                    submitKey="name"
                    type="text"
                    label={translated.propertyGroups.name}
                    maxCharCount={20}
                    {...commonProps}
                />

                <Form.InputNew
                    isRequired
                    submitKey="code"
                    isDense
                    type="text"
                    label={translated.propertyGroups.code}
                    maxCharCount={20}
                    {...commonProps}
                    isDisabled={isEditing || commonProps?.isDisabled}
                />

                {isEditing && (
                    <Form.Custom>
                        <Table
                            canChangeSettings
                            id="children"
                            key="children"
                            title={translated.propertyGroups.subTableTitle}
                            rows={{ actions: [] }}
                            columns={columns}
                            items={propertyGroups}
                            hasTools={false}
                            whenEmpty={(
                                <Alert
                                    id="children-empty"
                                    content={translated.propertyGroups.emptyChildren}
                                    className="margin-top-small"
                                />
                            )}
                        />
                    </Form.Custom>
                )}
            </FormNew>
        </Form.WrapperNew>
    );
}

Editor.propTypes = {};

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default withRequest(Editor);
