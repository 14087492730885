module.exports = Object.freeze({
    userNotSaved               : 'apf.agents.userNotSaved',
    onPermissionsChange        : 'apf.agents.onPermissionsChange',
    onYourPermissionsChange    : 'apf.agents.onYourPermissionsChange',
    noUsers                    : 'apf.agents.noUsers',
    sendingInvitation          : 'apf.agents.sendingInvitation',
    sendInvitationConfirmation : 'apf.agents.sendInvitationConfirmation',
    sendInvitationSuccess      : 'apf.agents.sendInvitationSuccess',
    enable                     : { success: 'apf.agents.enable.success', error: 'apf.agents.enable.error' },
    disable                    : { success: 'apf.agents.disable.success', error: 'apf.agents.disable.error' },
    delete                     : { confirmation: 'apf.agents.delete.confirmation', success: 'apf.agents.delete.success', error: 'apf.agents.delete.error' },
    errors                     : { invitation: 'apf.agents.errors.invitation', entityAlreadyExists: 'apf.agents.errors.entityAlreadyExists' },
    success                    : { creation: 'apf.agents.success.creation', edition: 'apf.agents.success.edition' },
    title                      : {
        new     : 'apf.agents.title.new',
        edition : 'apf.agents.title.edition',
        view    : 'apf.agents.title.view',
    },
});
