import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Subsection from 'Components/Sections/Subsection';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Alert from 'Components/Alert';
import { isQaEnabled } from 'Utils';
import Form from 'Components/Form';

const getActions = (data, navigator, confirmation, snackbar, resources) => {
    const actions = [
        {
            content  : translated.global.buttons.edit,
            key      : 'edit',
            callback : (tier) => {
                navigator.goToClubTierEditor({ available: tier.links.self, previousPath: [sectionType.CLUBS_GENERAL] });
            },
        },
        {
            isEnabled : true,
            content   : translated.global.buttons.disassociate,
            when      : (tier) => tier?.links?.self?.delete,
            callback  : (tier) => {
                confirmation.show({
                    message       : translated.clubs.tiers.delete.confirmation,
                    acceptMessage : translated.global.buttons.disassociate,
                    onAccept      : async () => {
                        const { id } = tier;
                        const reqConfig = {
                            ...tier.links.self.delete,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : [id],
                            shouldReloadData : true,
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS_TIERS, isEmbedded: true, resources });

                            snackbar.show({ content: translated.clubs.tiers.delete.success, isSuccess: true });
                        } catch (error) {
                            snackbar.show({
                                content     : translated.clubs.tiers.delete.defaultError,
                                errorLabels : translated.clubs.tiers.delete,
                                isError     : true,
                                error,
                            });
                        }
                    },
                });
            },
            key               : 'remove',
            icon              : 'LinkVariantOff',
            isOutsideDropdown : true,
            tooltip           : translated.clubs.desassociateTier,
        },
    ];

    return actions;
};

const listHasElement = (list, id) => !!list.find((e) => e === id);

const hasListChanged = (list, ids) => !!(list.length !== ids.length || ids.find((e) => listHasElement(list, e)));

function Tiers({ data, fetching, reloadData, resources, clubId, customClass }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const [availableTiers, setAvailableTiers] = useState({ loaded: false, list: null, error: null });
    // List of tiers that are filtered of the available tiers
    const loadedTiers = useRef(null);

    const { data: list, order } = data || {};
    const { available } = resources || {};

    const updateList = async () => {
        try {
            loadedTiers.current = list?.map((e) => e?.tier?.id);

            const response = await navigator.directRequest({ ...available.all });

            const convertedTiers = response?.data
                ?.filter((e) => !listHasElement(loadedTiers.current, e?.id))
                .map((e) => ({ id: e.id, value: { id: e.id, name: e.name }, content: e.name }));

            setAvailableTiers({ loaded: true, list: convertedTiers });
        } catch (e) {
            setAvailableTiers({ list: [], error: true });

            snackbar.show({
                content : translated.clubs.tiers.initializationError,
                isError : true,
            });
        }
    };

    useEffect(() => {
        if (available?.add && available?.all) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            loadedTiers.current
            && available?.add
            && available?.all
            && hasListChanged(
                list?.map((e) => e.id),
                loadedTiers.current,
            )
        ) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    const handleOnSubmit = async ({ values }) => {
        const { tiers } = values;
        let promises = [];

        if (tiers?.length) {
            promises = tiers.map(
                async (each) => new Promise(async (resolve, reject) => {
                    try {
                        const reqConfig = {
                            ...available.add,
                            data: {
                                clubId,
                                tierId          : each?.id,
                                channelId       : null,
                                agentPromoCode  : null,
                                clientPromoCode : null,
                                bonusBreak      : null,
                            },
                            isCritical       : false,
                            isGlobal         : false,
                            shouldReloadData : true,
                        };

                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS_TIERS, isEmbedded: true, resources });

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }),
            );

            return Promise.all(promises);
        }

        return null;
    };

    const handleOnFinish = ({ values }) => {
        if (values?.balanceTypes?.length) {
            setAvailableTiers({ loaded: false, list: [], selected: null });
        }
    };

    const handleOnError = () => {
        snackbar.show({
            content : translated.clubs.tiers.addTierError,
            isError : true,
        });
    };

    const handleOnChange = (newValue) => {
        setAvailableTiers((prev) => ({ ...prev, selected: newValue }));
    };

    const tiers = list?.map((e) => ({
        ...e,
        name       : e?.tier?.name,
        clientType : e?.tier?.clientType,
    })) || [];

    // The order of the array determines the order of the columns
    const rows = { actions: getActions(list, navigator, confirmation, snackbar, resources) };

    return (
        <Subsection title={translated.clubs.tiers.title} className={customClass}>
            {available?.add && available?.all && !availableTiers.error && (
                <Form.Wrapper>
                    <Form
                        id="add-clubs-tier-form"
                        onSubmit={handleOnSubmit}
                        onFinish={handleOnFinish}
                        onError={handleOnError}
                        isDisabled={!availableTiers.loaded}
                        avoidSetComponentWithChanges
                    >
                        <Form.Column width={{ base: 9, small: 5 }}>
                            <Form.Input
                                submitKey="tiers"
                                type="select"
                                label={translated.clubs.tiers.associate}
                                options={availableTiers?.list || []}
                                onChange={handleOnChange}
                                value={availableTiers?.selected}
                                isDense
                                isMultiSelect
                                isBasedOnState
                                avoidMarkAsChangedOnValidationsChange
                            />
                        </Form.Column>
                        <Form.Column width={{ base: 3, small: 7 }}>
                            <Form.Primary variant="outlined" color="primary" key="fp" isDisabled={!availableTiers?.list?.length}>
                                <FormattedMessage defaultMessage={translated.global.buttons.add} id={translated.global.buttons.add} />
                            </Form.Primary>
                        </Form.Column>
                    </Form>
                </Form.Wrapper>
            )}

            <Table
                id="clubs-tier"
                key="clubs-tier"
                className="margin-top-small"
                hasTools={false}
                columns={[
                    {
                        title       : translated.global.tier,
                        key         : 'name',
                        isVisible   : true,
                        hasMaxWidth : true,
                    },
                    {
                        title    : translated.global.status,
                        key      : 'isActive',
                        whenTrue : {
                            text      : translated.global.state.enabled,
                            className : 'status status-enabled',
                        },
                        whenFalse: {
                            text      : translated.global.state.disabled,
                            className : 'status status-disabled',
                        },
                        isVisible: true,
                    },
                ]}
                rows={rows}
                items={tiers}
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="clubs-tiers-empty"
                        content={translated.clubs.tiers.empty}
                        className="alert margin-top-small"
                    />
                )}
                getColumnCustomAttributes={isQaEnabled() ? (element) => ({ 'data-test-tier-id': element.tier?.id }) : null}
            />
        </Subsection>
    );
}

Tiers.defaultProps = {
    data        : [],
    fetching    : {},
    customClass : '',
};

Tiers.propTypes = {
    data        : PropTypes.shape({}),
    fetching    : PropTypes.shape({}),
    reloadData  : PropTypes.func.isRequired,
    resources   : PropTypes.shape({}).isRequired,
    clubId      : PropTypes.number.isRequired,
    customClass : PropTypes.string,
};

Tiers.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-xxlarge" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 6 }}>
                <Skeleton.Title isSubHeading width={190} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-small">
                <Grid>
                    <Grid.Column width={{ base: 4 }}>
                        <Skeleton.Form type="input" />
                    </Grid.Column>
                    <Grid.Column width={{ base: 8 }}>
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
                <Skeleton.Table hasTitle={false} hasActions={false} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(Tiers);
