import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'Components/Grid';
import Button from 'Components/Button';

function DefaultButtons({ primaryButton, secondaryButton, resetButton, buttonsWidth }) {
    return (
        <Grid>
            <Grid.Column width={buttonsWidth || { base: 12 }}>
                <div className="form-buttons text-align-right">
                    {resetButton && (
                        <Button
                            id={resetButton.id}
                            variant={resetButton.variant || 'text'}
                            icon={resetButton.icon}
                            color={resetButton.color || 'primary'}
                            onClick={resetButton.onClick}
                            disabled={!resetButton.isEnabled}
                            tooltip={resetButton.tooltip}
                        >
                            {resetButton.text}
                        </Button>
                    )}
                    {secondaryButton && (
                        <Button
                            id={secondaryButton.id}
                            variant={secondaryButton.variant || 'text'}
                            icon={secondaryButton.icon}
                            color={secondaryButton.color || 'primary'}
                            onClick={secondaryButton.onClick}
                            disabled={!secondaryButton.isEnabled}
                            tooltip={secondaryButton.tooltip}
                        >
                            {secondaryButton.text}
                        </Button>
                    )}
                    {primaryButton && (
                        <Button
                            id={primaryButton.id}
                            variant={primaryButton.variant || 'unelevated'}
                            icon={primaryButton.icon}
                            color={primaryButton.color || 'primary'}
                            onClick={primaryButton.onClick}
                            disabled={primaryButton.isEnabled === false}
                            tooltip={primaryButton.tooltip}
                        >
                            {primaryButton.text}
                        </Button>
                    )}
                </div>
            </Grid.Column>
        </Grid>
    );
}

DefaultButtons.defaultProps = {
    primaryButton   : null,
    secondaryButton : null,
    resetButton     : null,
    buttonsWidth    : null,
};

DefaultButtons.propTypes = {
    buttonsWidth: PropTypes.shape({
        base       : PropTypes.string,
        small      : PropTypes.string,
        medium     : PropTypes.string,
        large      : PropTypes.string,
        extraLarge : PropTypes.string,
    }),
    primaryButton: PropTypes.shape({
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        text      : PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        tooltip: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
    }),
    secondaryButton: PropTypes.shape({
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        text      : PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        tooltip: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
    }),
    resetButton: PropTypes.shape({
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        text      : PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        tooltip: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
    }),
};

export default DefaultButtons;
