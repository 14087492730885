import { mdiDomain } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const externalClubId = null;

export default {
    [sectionType.EXTERNAL_CLUBS]: {
        isEnabled : false,
        config    : { url: '/panel/external-clubs', icon: mdiDomain },
        sections  : {
            [sectionType.EXTERNAL_CLUBS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.EXTERNAL_CLUBS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.EXTERNAL_CLUBS_EDITOR]: {
                        ids       : { externalClubId, self: 'externalClubId' },
                        resources : { templates: { name: resourceNames.EXTERNAL_CLUB } },
                        isRoot    : true,
                        data      : {},
                        config    : {
                            isHiddenInBreadcrumb : true,
                            url                  : { withId: '/(\\d+)', withoutId: '/add' },
                            isModal              : true,
                        },
                    },
                },
            },
        },
    },
};
