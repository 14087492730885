import React, {
    useState, useRef, useEffect, useCallback, useContext, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import List from './List';
import PanelContext from 'State/panelContext';
import isBankingConfigurationValid from './utils';
import Button from 'Components/Button';
import Form, { FormError } from 'Components/Form';
import Modal from 'Components/Modal';

const TEMP_ID = 'temp_';
const MAX_POINTS_AMOUNT = 9999999;

const convertList = (list, contractData, dateManager) => list?.map((e, index) => ({
    ...e,
    balanceTypeName   : e.balanceType?.name,
    belongsToContract : e.belongsToContract != null ? e.belongsToContract : true,
    id                : e.id || TEMP_ID + index,
    isValid           : isBankingConfigurationValid(e, contractData?.begin, contractData?.end, dateManager),
}));

// eslint-disable-next-line max-len
const validateBankingConfiguration = (list, contractData, dateManager) => list.map((e) => ({ ...e, isValid: isBankingConfigurationValid(e, contractData?.begin, contractData?.end, dateManager) }));

export function BankingConfigurations({
    balanceTypes,
    linkBalanceTypes,
    link,
    lastLoadedLink,
    elements,
    updateBankingConfigurations,
    isTemplate,
    contractData,
    isAddEnabled,
    isEditEnabled,
    isEnabled,
    isRemoveEnabled,
    disableWarning,
    newTierId,
    previousTierId,
    showValidation,
    generation, // Prop used to generate bankings and wallet transactions
    bankingConfigurationsRef,
}) {
    const { navigator, snackbar, dateManager } = useContext(PanelContext);

    const [bankingConfigurations, setBankingConfigurations] = useState(convertList(elements, contractData, dateManager) || []);
    const [storedBalanceTypes, setStoredBalanceTypes] = useState(balanceTypes || []);

    const [generationModal, setGenerationModal] = useState({
        isOpen    : false,
        isLoading : false,
        step      : 1,
        data      : null,
        summary   : null,
    });

    const prevLink = useRef(lastLoadedLink);

    const updateList = useCallback(
        (updatedList, availableBalanceTypes = storedBalanceTypes, linkLoaded = null) => {
            const findStoredBalanceType = (element) => element && availableBalanceTypes.find((e) => e.id === element.id);

            if (updateBankingConfigurations) {
                updateBankingConfigurations(
                    updatedList.map((e) => {
                        const converted = {
                            ...e,
                            id            : undefined,
                            isActive      : !!e.isActive,
                            balanceType   : findStoredBalanceType(e?.balanceType) ? { id: findStoredBalanceType(e?.balanceType).id } : null,
                            shoulderLeft  : e.shoulderLeft || 0,
                            shoulderRight : e.shoulderRight || 0,
                        };

                        return { ...converted, isValid: isBankingConfigurationValid(converted, contractData?.begin, contractData?.end, dateManager) };
                    }),
                    linkLoaded ? availableBalanceTypes : null, // Only updates the balance types list when the list has been updated.
                    linkLoaded,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contractData, storedBalanceTypes, updateBankingConfigurations],
    );

    useImperativeHandle(
        bankingConfigurationsRef,
        () => ({
            // Function used in the parent component to update the banking list
            updateElements: (list) => {
                const convertedList = list.map((e, index) => ({
                    ...e,
                    belongsToContract : false,
                    id                : TEMP_ID + new Date().getTime() + index,
                }));

                const updatedList = validateBankingConfiguration(convertedList, contractData, dateManager);
                updateList(updatedList);

                setBankingConfigurations(updatedList);
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contractData, updateList],
    );

    const onAdd = (bankingConfiguration) => {
        setBankingConfigurations((prev) => {
            let updatedList = bankingConfiguration.id
                ? prev.map((e) => (e.id !== bankingConfiguration.id ? { ...e } : { ...bankingConfiguration, belongsToContract: true }))
                : [...prev, { ...bankingConfiguration, id: TEMP_ID + new Date().getTime() }];

            updatedList = validateBankingConfiguration(updatedList, contractData, dateManager);
            updateList(updatedList);

            return updatedList;
        });

        return true;
    };

    const onEdit = (bankingConfiguration) => {
        setBankingConfigurations((prev) => {
            let updatedList = prev.map((e) => (e.id !== bankingConfiguration.id ? { ...e } : { ...bankingConfiguration, belongsToContract: true }));

            updatedList = validateBankingConfiguration(updatedList, contractData, dateManager);
            updateList(updatedList);

            return updatedList;
        });

        return true;
    };

    const onRemove = (bankingConfiguration) => {
        setBankingConfigurations((prev) => {
            let updatedList = prev.filter((e) => e.id !== bankingConfiguration.id);

            updatedList = validateBankingConfiguration(updatedList, contractData, dateManager);
            updateList(updatedList);

            return updatedList;
        });
    };

    const getBankingConfigurations = useCallback(
        async (newLink) => {
            try {
                const { data: configurations, additionalResources } = await navigator.directRequest(newLink);

                const newConfigurations = !previousTierId || !newTierId || newTierId !== previousTierId
                    ? configurations.map((e) => ({ ...e, balanceTypeName: e.balanceType?.name, belongsToContract: false }))
                    : [];

                setBankingConfigurations((prev) => {
                    let updatedList = [
                        ...newConfigurations.filter((e) => !prev.find((p) => p.id === e.id)), // We remove any banking configuration that is already loaded
                        ...prev.filter((e) => e.belongsToContract),
                    ];

                    updatedList = validateBankingConfiguration(updatedList, contractData, dateManager);

                    updateList(updatedList, additionalResources?.balanceTypes, newLink?.url);
                    return updatedList;
                });

                if (additionalResources?.balanceTypes) {
                    setStoredBalanceTypes(additionalResources.balanceTypes);
                }
            } catch (error) {
                snackbar.show({
                    content : translated.owners.contracts.edition.bookingConfigurationError,
                    isError : true,
                    error,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contractData, navigator, newTierId, previousTierId, snackbar, updateList],
    );

    const getBalanceTypes = useCallback(async () => {
        try {
            if (!linkBalanceTypes) {
                return;
            }

            const { data } = await navigator.directRequest(linkBalanceTypes);

            setStoredBalanceTypes(data);
        } catch (error) {
            snackbar.show({
                content : translated.owners.contracts.edition.balanceTypesError,
                isError : true,
                error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (link?.url && prevLink.current !== link.url) {
            prevLink.current = link.url;

            getBankingConfigurations(link);
        }

        if (!link && prevLink.current) {
            // Clean the bankings loaded that belonged to the previous club-tier selected
            setBankingConfigurations((prev) => prev.filter((e) => e.belongsToContract));

            prevLink.current = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link?.url]);

    useEffect(() => {
        setStoredBalanceTypes(balanceTypes);
    }, [balanceTypes]);

    useEffect(() => {
        getBalanceTypes();
    }, [getBalanceTypes, linkBalanceTypes]);

    useEffect(() => {
        setBankingConfigurations((prev) => {
            const updatedList = validateBankingConfiguration(prev, contractData, dateManager);
            updateList(updatedList);

            return updatedList;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractData.begin, contractData.end]);

    useEffect(() => {
        if (linkBalanceTypes) {
            getBalanceTypes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generate = () => {
        setGenerationModal((prev) => ({ ...prev, isOpen: true, step: 1, summary: null }));
    };

    const generateOnSubmit = useCallback(
        async ({ values }) => {
            const reqConfig = {
                ...generation.link,
                data: {
                    ...generation.data,
                    amount          : Number(values.amount),
                    contractEndDate : generation.contractEndDate,
                },
            };

            try {
                const response = await navigator.directRequest(reqConfig);

                setGenerationModal((prev) => ({ ...prev, step: 2, summary: response, data: values }));
            } catch (e) {
                throw new FormError(translated.owners.contracts.edition.bankingConfiguration.generate.errors.default, e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [generation?.data, generation?.link, navigator],
    );

    const generateOnBack = () => {
        setGenerationModal((prev) => ({ ...prev, step: 1, summary: null }));
    };

    const generateOnClose = () => {
        if (generation?.onFinish) {
            generation.onFinish(generationModal.summary, generationModal.data?.amount);
        }

        setGenerationModal({
            isOpen    : false,
            isLoading : false,
            step      : 1,
            data      : null,
            summary   : null,
        });
    };

    const buttons = [];
    if (generation?.link) {
        buttons.push(
            <Button id="contract-banking-generate-button" variant="outlined" color="primary" onClick={generate} key="generate" disabled={!isEnabled}>
                <FormattedMessage
                    defaultMessage={translated.owners.contracts.edition.bankingConfiguration.generate.title}
                    id={translated.owners.contracts.edition.bankingConfiguration.generate.title}
                />
            </Button>,
        );
    }

    const modalProperties = {
        title   : translated.owners.contracts.edition.bankingConfiguration.generate.modalTitle,
        buttons : [
            <Form.Secondary
                key="fs"
                isDisabled={generationModal.isLoading}
                variant="text"
                color="primary"
                onClick={() => setGenerationModal({
                    isOpen    : false,
                    isLoading : false,
                    step      : 1,
                    data      : null,
                    summary   : null,
                })}
            />,
            <Form.Primary
                key="fp"
                variant="text"
                color="primary"
                isDisabled={generationModal.isLoading}
                isEnabled={!!generationModal?.data?.amount}
                ignoreChanges={!!generationModal?.data?.amount}
            >
                <FormattedMessage
                    defaultMessage={translated.owners.contracts.edition.bankingConfiguration.generate.button}
                    id={translated.owners.contracts.edition.bankingConfiguration.generate.button}
                />
            </Form.Primary>,
        ],
    };

    return (
        <>
            {generationModal?.isOpen && (
                <>
                    {generationModal?.step !== 2 && (
                        <Form.Wrapper modalProperties={modalProperties}>
                            <Form
                                className="margin-top-small"
                                id="generate-amount"
                                onSubmit={generateOnSubmit}
                                forceValidationsOnFirstRender={generationModal?.data?.amount}
                                avoidSetComponentWithChanges
                            >
                                <Form.Custom>
                                    <p>
                                        <FormattedMessage
                                            defaultMessage={translated.owners.contracts.edition.bankingConfiguration.generate.message1}
                                            id={translated.owners.contracts.edition.bankingConfiguration.generate.message1}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            defaultMessage={translated.owners.contracts.edition.bankingConfiguration.generate.message2}
                                            id={translated.owners.contracts.edition.bankingConfiguration.generate.message2}
                                        />
                                    </p>
                                </Form.Custom>

                                <Form.Input
                                    type="number"
                                    label={translated.owners.balance.amount}
                                    validations={{ minValue: 1, maxValue: MAX_POINTS_AMOUNT, unary: ['numbers'] }}
                                    submitKey="amount"
                                    suffix={translated.owners.balance.suffixPoints}
                                    value={generationModal?.data?.amount}
                                    avoidOnSummary
                                    isRequired
                                />
                            </Form>
                        </Form.Wrapper>
                    )}
                    {generationModal?.step === 2 && (
                        <Modal
                            title={translated.owners.contracts.edition.bankingConfiguration.generate.modalTitle}
                            cancelButton={{
                                text      : translated.global.buttons.back,
                                onClick   : generateOnBack,
                                isEnabled : !generationModal.isLoading,
                            }}
                            closeButton={{
                                text      : translated.owners.contracts.edition.bankingConfiguration.generate.button,
                                onClick   : generateOnClose,
                                isEnabled : !generationModal.isLoading,
                            }}
                            isVisible
                            className="balance-preview-modal"
                        >
                            <p>
                                <FormattedMessage
                                    defaultMessage={translated.owners.contracts.edition.bankingConfiguration.generate.confirmationMessage}
                                    id={translated.owners.contracts.edition.bankingConfiguration.generate.confirmationMessage}
                                />
                            </p>

                            {generation.createSummary(generationModal.summary)}
                        </Modal>
                    )}
                </>
            )}

            <List
                balanceTypes={storedBalanceTypes}
                elements={bankingConfigurations}
                isTemplate={isTemplate}
                isAddEnabled={isAddEnabled}
                onAdd={onAdd}
                isEditEnabled={isEditEnabled}
                onEdit={onEdit}
                isRemoveEnabled={isRemoveEnabled}
                onRemove={onRemove}
                contractData={contractData}
                isEnabled={isEnabled}
                disableWarning={disableWarning}
                showValidation={showValidation}
                headerButtons={buttons}
            />
        </>
    );
}

BankingConfigurations.defaultProps = {
    balanceTypes                : [],
    linkBalanceTypes            : null,
    link                        : null,
    lastLoadedLink              : null,
    elements                    : [],
    updateBankingConfigurations : null,
    isAddEnabled                : true,
    isEditEnabled               : true,
    isRemoveEnabled             : true,
    isTemplate                  : false,
    isEnabled                   : true,
    disableWarning              : '',
    newTierId                   : null,
    previousTierId              : null,
    showValidation              : true,
    generation                  : null,
    bankingConfigurationsRef    : () => {
        // Default
    },
};

BankingConfigurations.propTypes = {
    balanceTypes                : PropTypes.arrayOf(PropTypes.shape({})),
    linkBalanceTypes            : PropTypes.shape({}),
    link                        : PropTypes.shape({}),
    lastLoadedLink              : PropTypes.string,
    elements                    : PropTypes.arrayOf(PropTypes.shape({})),
    updateBankingConfigurations : PropTypes.func,
    isAddEnabled                : PropTypes.bool,
    isEditEnabled               : PropTypes.bool,
    isRemoveEnabled             : PropTypes.bool,
    isTemplate                  : PropTypes.bool,
    contractData                : PropTypes.shape({}).isRequired,
    isEnabled                   : PropTypes.bool,
    disableWarning              : PropTypes.string,
    newTierId                   : PropTypes.number,
    previousTierId              : PropTypes.number,
    showValidation              : PropTypes.bool,
    generation                  : PropTypes.shape({
        link            : PropTypes.shape({}).isRequired,
        data            : PropTypes.shape({}).isRequired,
        contractEndDate : PropTypes.string.isRequired,
        createSummary   : PropTypes.func.isRequired,
        onFinish        : PropTypes.func.isRequired,
    }),
    bankingConfigurationsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

function BankingConfigurationsWithRef(props, ref) {
    return <BankingConfigurations {...props} bankingConfigurationsRef={ref} />;
}
BankingConfigurationsWithRef.displayName = 'BankingConfigurations';

export default forwardRef(BankingConfigurationsWithRef);
