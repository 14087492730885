import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';

function Tooltip({ id, className, disabled, place, delayShow, children }) {
    const tooltipWrapper = (
        <ReactTooltip id={id} effect="solid" className={className} place={place} delayShow={delayShow} disable={disabled}>
            {children}
        </ReactTooltip>
    );

    // Add tooltip into a portal to avoid overflows
    return ReactDOM.createPortal(tooltipWrapper, document.getElementById('tooltips'));
}

function makeTooltipId(length = 8) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * (charactersLength - 1)));
    }
    return result;
}

Tooltip.generateId = makeTooltipId;

Tooltip.defaultProps = {
    className : '',
    id        : '',
    place     : 'bottom',
    disabled  : false,
    delayShow : 150,
    children  : null,
};

Tooltip.propTypes = {
    className : PropTypes.string,
    id        : PropTypes.string,
    place     : PropTypes.string,
    disabled  : PropTypes.bool,
    delayShow : PropTypes.number,
    children  : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

export default Tooltip;
