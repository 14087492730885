module.exports = Object.freeze({
    noBonusPoints : 'There are no bonus balance added',
    title         : 'Balance types',
    updateTitle   : 'Edit balance type',
    createTitle   : 'New balance type',
    updated       : 'The balance type has been updated',
    added         : 'The balance type has been added',
    errors        : {
        createError      : 'The balance type could not be added',
        updateError      : 'The balance type could not be updated',
        entityInUse      : 'The balance type could not be modified because it is in use',
        entityIsReadonly : 'This entity is read-only, cannot be modified',
    },
    create : 'Create',
    labels : {
        name     : 'Name',
        currency : 'Currency',
        level    : 'Level',
    },
    delete: {
        confirmation               : 'Delete balance type?',
        success                    : 'The balance type has been removed',
        error                      : 'The balance type could not be removed',
        errorEntityCannotBeDeleted : "This balance type can't be removed since it is being used.",
        entityIsReadonly           : 'This entity is read-only, cannot be modified',
    },
    enable: {
        success : 'The balance type has been enabled',
        error   : 'The balance type could not be enabled',
    },
    disable: {
        success : 'The balance type has been disabled',
        error   : 'The balance type could not be disabled',
    },
});
