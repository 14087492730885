import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Toggle({ id, onChange, value, toggleLabel, isDisabled }) {
    const input = (
        <>
            <input id={id || null} tabIndex={isDisabled ? '-1' : '0'} type="checkbox" onChange={(e) => onChange(e.target.checked)} checked={value} />
            <span />
        </>
    );

    return (
        <>
            {!toggleLabel && (
                <label id={id ? `${id}-label` : null} className="checkbox">
                    {input}
                </label>
            )}

            {toggleLabel && (
                <label id={id ? `${id}-label` : null} onChange={(e) => onChange(e.target.checked)}>
                    <div className="checkbox">{input}</div>
                    <span>
                        <WrappedFormattedMessage content={toggleLabel} />
                    </span>
                </label>
            )}
        </>
    );
}

Toggle.defaultProps = {
    id          : '',
    toggleLabel : '',
    isDisabled  : false,
};

Toggle.propTypes = {
    id          : PropTypes.string,
    toggleLabel : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onChange    : PropTypes.func.isRequired,
    value       : PropTypes.bool.isRequired,
    isDisabled  : PropTypes.bool,
};

export default Toggle;
