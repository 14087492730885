import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import getClassName from 'Utils/getClassName';
import { row } from './utils';
import Tooltip from 'Components/Tooltip';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import panelContext from 'State/panelContext';

function getValueFromPath(obj, path) {
    return path.split('.').reduce((a, b) => (a && typeof a === 'object' ? a[b] : null), obj);
}

const getShortMessage = (message, showTooltipWhen) => (message && showTooltipWhen && showTooltipWhen < message.length ? `${message.substring(0, showTooltipWhen).trim()}...` : null);

function getTDFromFiled(data, field, index, styleConfig, rowId, dateManager) {
    const {
        key, subkey, whenFalse, whenTrue, isDate, isDateTime, isNumeric, hasMaxWidth, fieldFormat, customFieldComponent, showTooltipWhen,
    } = field;

    const id = `${rowId}-${key}${subkey ? `-${subkey}` : ''}`;
    const tooltipId = Tooltip.generateId();

    // Data can has a cell data or a string with a unique value for the row
    let rowInfo = typeof data === 'string' ? data : getValueFromPath(data, key);
    if (subkey && rowInfo) {
        rowInfo = rowInfo[0] ? rowInfo[0][subkey] : rowInfo[subkey];
    }

    let className = '';
    if (typeof rowInfo === 'boolean') {
        if (rowInfo) {
            className = whenTrue.className || 'table-data-true';
            rowInfo = whenTrue.text ? whenTrue.text : rowInfo;
        } else {
            className = whenFalse.className || 'table-data-false';
            rowInfo = whenFalse.text ? whenFalse.text : rowInfo;
        }
    }

    rowInfo = isDate ? dateManager.defaultDateFormat(rowInfo) : rowInfo;
    rowInfo = isDateTime ? dateManager.defaultDateTimeFormat(rowInfo) : rowInfo;

    const { customStyle, customClassName } = styleConfig.columns[index];
    const { value: customValue, class: customClass } = fieldFormat ? fieldFormat(data) : {};
    const component = customFieldComponent ? customFieldComponent(data) : null;
    const tdClassName = getClassName({ textAlignRight: isNumeric, textOverflow: hasMaxWidth }, customClassName);

    const shortMessage = getShortMessage(customValue || rowInfo, showTooltipWhen);
    const tooltipProps = shortMessage
        ? {
            'data-tip' : customValue || rowInfo,
            'data-for' : tooltipId,
        }
        : {};

    return (
        <td key={key} className={tdClassName} style={customStyle} id={id} {...tooltipProps}>
            {component || (
                <span className={`${className} ${customClass || ''}`}>
                    <WrappedFormattedMessage content={shortMessage || customValue || rowInfo} />
                    {shortMessage && <Tooltip id={tooltipId} />}
                </span>
            )}
        </td>
    );
}

function Row({
    data,
    fields,
    actions,
    onCheck,
    index,
    checked,
    updateOnCollapse,
    subTable,
    id,
    isSubtable,
    isSortEnabled,
    isSortable,
    isDragging,
    styleConfig,
    isLoading,
    className,
    rowForwardedRef,
    isUncheckable,
    getColumnCustomAttributes,
    getRowCustomClass,
}) {
    const { dateManager } = useContext(panelContext);

    const [isCollapsed, setCollapsed] = useState(true);
    const dragHandlerRef = useRef();
    const subtableRef = useRef();

    useEffect(() => {
        updateOnCollapse(index, isCollapsed);
    }, [updateOnCollapse, index, isCollapsed]);

    const visibleFields = fields.filter((item) => item.isVisible);

    const handleOnClick = () => {
        if (subTable) {
            setCollapsed(!isCollapsed);
        }
    };

    const dragHandlerButton = isSortable && row.getDragHandleButton(styleConfig, dragHandlerRef, isSortEnabled, id);
    const checkboxHandlerButton = onCheck && row.getCheckboxHandlerButton(index, styleConfig, checked, onCheck, id);
    const hasSubTableData = subTable && subTable.key && data[subTable.key] && data[subTable.key].length > 0;
    const subTableHandlerButton = subTable && row.getSubTableHandlerButton(styleConfig, isCollapsed, handleOnClick, hasSubTableData, id);
    let rowClass = row.getRowClass(
        isUncheckable,
        checked,
        subTable,
        isCollapsed,
        isDragging,
        dragHandlerButton,
        onCheck,
        isLoading,
        data.isDisabled,
        className,
    );
    rowClass = getRowCustomClass ? getRowCustomClass(data, rowClass) : rowClass;

    const customAttributes = getColumnCustomAttributes ? getColumnCustomAttributes(data) : {};

    return (
        <>
            <tr id={id} className={rowClass} ref={rowForwardedRef} {...customAttributes}>
                {dragHandlerButton}
                {subTableHandlerButton}
                {checkboxHandlerButton}
                {visibleFields.map((field, fieldIndex) => getTDFromFiled(data, field, fieldIndex, styleConfig, id, dateManager))}
                {actions?.length > 0 && row.buildRightActionColumns(id, data, actions, styleConfig, isSubtable ? 'subtable-actions' : '')}
            </tr>
            {subTable && !isCollapsed && data[subTable.key] && (
                <tr className="subtable">
                    <td colSpan="100">
                        <Table
                            id={subTable.id}
                            key={subTable.key}
                            className="subtable-wrapper"
                            columns={subTable.columns}
                            rows={subTable.rows}
                            items={data[subTable.key]}
                            hasTools={false}
                            wrapperRef={subtableRef}
                            isSubtable
                        />
                    </td>
                </tr>
            )}
            {isLoading && (
                <tr className="loading-bar-wrapper">
                    <td colSpan="100">
                        <div className="loading-bar" />
                    </td>
                </tr>
            )}
        </>
    );
}

Row.defaultProps = {
    actions                   : null,
    checked                   : false,
    subTable                  : null,
    onCheck                   : null,
    isSortEnabled             : false,
    isUncheckable             : false,
    isSortable                : false,
    isDragging                : false,
    isLoading                 : false,
    className                 : '',
    rowForwardedRef           : null,
    isSubtable                : false,
    getColumnCustomAttributes : null,
    getRowCustomClass         : null,
};

Row.propTypes = {
    id               : PropTypes.string.isRequired,
    index            : PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    updateOnCollapse : PropTypes.func.isRequired,
    isSortEnabled    : PropTypes.bool,
    isUncheckable    : PropTypes.bool,
    isSortable       : PropTypes.bool,
    isDragging       : PropTypes.bool,
    isLoading        : PropTypes.bool,
    data             : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    fields           : PropTypes.arrayOf(
        PropTypes.shape({
            title : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            key   : PropTypes.string,
        }),
    ).isRequired,
    actions  : PropTypes.arrayOf(PropTypes.shape({})),
    onCheck  : PropTypes.func,
    checked  : PropTypes.bool,
    subTable : PropTypes.shape({
        key  : PropTypes.string.isRequired,
        rows : PropTypes.shape({
            columns : PropTypes.arrayOf(PropTypes.shape({})),
            actions : PropTypes.arrayOf(PropTypes.shape({})),
        }),
        columns : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        actions : PropTypes.arrayOf(PropTypes.shape({})),
        id      : PropTypes.string,
    }),
    styleConfig               : PropTypes.shape({}).isRequired,
    className                 : PropTypes.string,
    rowForwardedRef           : PropTypes.shape({}),
    isSubtable                : PropTypes.bool,
    getColumnCustomAttributes : PropTypes.func,
    getRowCustomClass         : PropTypes.func,
};

export default React.memo(Row);
