import React from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import * as types from 'State/types/panel';
import * as appTypes from 'State/types/app';
import { Api } from 'Services';
import { hasSectionChanged, getAppAsRestricted } from '../configureStore';
import * as appActions from './app';
import globalConstants, { clientType, restrictedAppReasons } from 'Constants/global';
import sectionName from 'Constants/labels/sectionName';
import { getErrorDetails } from 'Utils/errorService';

function handleErrorsOnResponse(err, currentErrorState, dispatch) {
    const { isTimeout, shouldRefreshUser, hasUserError, shouldRestrictApp, error } = getErrorDetails(err);

    if (hasUserError) {
        dispatch(appActions.setUser(null));
    }

    if (shouldRestrictApp) {
        const restrictedAppErrorMessage = error?.data?.errors[0].messages[0];
        dispatch(appActions.setAppAsRestricted(true, restrictedAppErrorMessage || null, restrictedAppReasons.MAINTENANCE));
    }

    if (shouldRefreshUser && !currentErrorState.shouldRefreshUser) {
        dispatch(appActions.refreshUser());
    }

    return { isTimeout, error, shouldRefreshUser, hasUserError };
}

export function selectSection(sectionPath, selectedBranchProps, unselectedBranchProps, oneLevelDeepPropSet) {
    return {
        type    : types.SECTION_SELECT,
        payload : { sectionPath, selectedBranchProps, unselectedBranchProps, oneLevelDeepPropSet },
    };
}

export function selectSectionForBackAction() {
    return { type: types.SECTION_SELECT_FOR_BACK_ACTION };
}
export const selectDefaultSection = () => async (dispatch, store) => {
    const { panel: { sections } } = store();

    const [section] = Object.entries(sections).find(([, eachSection]) => eachSection?.config?.isDefaultSection);

    await dispatch({
        type    : types.SECTION_SELECT,
        payload : {
            sectionPath         : [section],
            selectedBranchProps : {
                with : { shouldReloadData: true },
                on   : section,
            },
        },
    });
};

export function disableSections(sectionsPaths) {
    return {
        type    : types.SECTION_DISABLE,
        payload : sectionsPaths,
    };
}

export function setSectionState(sectionPath, state) {
    const lastSection = [...sectionPath].pop();

    return {
        type    : types.SECTION_STATE_SET,
        payload : { sectionPath, params: [{ on: lastSection, with: { state } }] },
    };
}

export function setSectionData(sectionPath, data) {
    const lastSection = [...sectionPath].pop();

    return {
        type    : types.SECTION_STATE_SET,
        payload : { sectionPath, params: [{ on: lastSection, with: { ...data } }] },
    };
}

export function setSectionsIds(idsList) {
    return { type: types.SECTIONS_IDS_SET, payload: idsList };
}

export function search() {
    return { type: types.SEARCH };
}

export const pushToUrl = (url) => async (dispatch) => {
    await dispatch({ type: types.PUSH_TO_URL, payload: { shouldReloadUrlStage: globalConstants.INIT } });
    window.history.pushState(null, null, url);
};

export const setShouldReloadUrlStage = (shouldReloadUrlStage) => ({ type: types.SET_SHOULD_RELOAD_URL_STAGE, payload: { shouldReloadUrlStage } });

// Goes to last section in path and returns the mockedResponse for that
const getMockedResponseForPath = (path, getStore) => {
    const { sections: currentSections } = getStore().panel;
    const lastIndex = path.length - 1;
    // Goes through the arrangement that is in path, takes the children 'sections' except at the end where it takes 'config'
    const response = path.reduce((last, sectionType, i) => last[path[i]][i < lastIndex ? 'sections' : 'config'], currentSections).mockedResponse;

    return response ? { response, warnings: [] } : null;
};

export const request = (reqConfig, path, isCurrentSection, sectionResources, useMock) => async (dispatch, getStore) => {
    const {
        url,
        method,
        isTypeFile,
        isGlobal,
        ids,
        shouldReloadData,
        avoidSetDataWithResponse = false,
        requestTimeout,
        retryNumber,
        isCritical: nextRequestIsCritical,
        params,
    } = reqConfig;

    let { data } = reqConfig;
    const lastSection = [...path].pop();
    let formData;

    if (isTypeFile) {
        formData = new FormData();
        formData.append('file', data, data.name);
        data = formData;
    }

    const httpReq = { url, method, data, params };

    // If is isCurrentSection its saved in that key in the store to reload the data when the user wants it
    try {
        dispatch({
            type    : types.REQUEST_START,
            payload : {
                sectionPath           : path,
                shouldReloadUrlStage  : globalConstants.IN_PROGRESS,
                currentSectionRequest : isCurrentSection ? httpReq : null,
                params                : [
                    {
                        on   : lastSection,
                        with : { fetching: { isGlobal, ids } },
                    },
                ],
                method,
            },
        });

        let response;
        let warnings;

        if (useMock && window.env.IS_DEV === 'true') {
            // Gets the value stored in the key mockedResponse if the last element of the path
            ({ response, warnings } = getMockedResponseForPath(path, getStore) || (await Api.request(httpReq, isTypeFile, requestTimeout, retryNumber)));
        } else {
            ({ response, warnings } = await Api.request(httpReq, isTypeFile, requestTimeout, retryNumber));
        }
        let resources = sectionResources;

        if (!sectionResources) {
            resources = Array.isArray(response)
                ? { available: response?.[0]?.links?.self || {}, current: 'read' }
                : { available: response?.links?.self || {}, current: 'read' };
        }

        // When it must reload the data, don't change the loaded data, so it doesn't remove the visual component.
        const sectionProps = !shouldReloadData && !avoidSetDataWithResponse ? { data: response } : {};

        // The name will be shown on the section view, if there is no name in the entity we show the ID (or legacyId), if not, the default section name
        if (response) {
            sectionProps.name = String(
                response.name
                    || response.tier?.name
                    || (response.type === clientType.OWNER || response.type === clientType.PARTNER ? response.legacyId : response.id)
                    || sectionName[lastSection]
                    || '',
            );
        }

        const restrictedApp = getAppAsRestricted(getStore());

        if (restrictedApp.isAppRestricted) {
            dispatch(appActions.setAppAsRestricted(false, null, null));
        }

        dispatch({
            type    : types.REQUEST_END,
            payload : {
                sectionPath          : path,
                shouldReloadUrlStage : globalConstants.DONE,
                params               : [
                    {
                        on   : lastSection,
                        with : {
                            ...sectionProps,
                            resources,
                            fetching         : { isGlobal: false, ids: [] },
                            shouldReloadData : shouldReloadData != null ? shouldReloadData : false,
                            error            : null,
                        },
                    },
                ],
                method,
                response,
            },
        });

        return Promise.resolve({ ...response, warnings });
    } catch (err) {
        const { isTimeout, error, shouldRefreshUser, hasUserError } = handleErrorsOnResponse(err, getStore().app.error, dispatch);

        dispatch({
            type    : types.REQUEST_ERROR,
            payload : {
                shouldReloadUrlStage : globalConstants.INIT,
                sectionPath          : path,
                params               : [
                    {
                        on   : lastSection,
                        with : {
                            fetching : { isGlobal: false, ids: [] },
                            error    : {
                                isTimeout,
                                error,
                                shouldRefreshUser,
                                hasUserError,
                                isCritical: !!nextRequestIsCritical,
                            },
                            shouldReloadData: false,
                        },
                    },
                ],
                request: httpReq,
                method,
            },
        });

        return Promise.reject(error);
    }
};

/**
 * It is used to make HTTP requests that do not go through Redux to get a better performance
 * when you need to make many fetchs one behind the other avoiding extra renderings
 */
export const directRequest = (reqConfig) => async (dispatch) => {
    const { method, params, isTypeFile, file, timeout, isFileDownload } = reqConfig;
    let { url } = reqConfig;

    let { data } = reqConfig;
    let formData;
    let uploadHandlers = {};

    if (isTypeFile) {
        formData = new FormData();
        formData.append('file', file, file.name);
        if (data) {
            Object.keys(data).forEach((eachKey) => {
                const value = encodeURIComponent(data[eachKey]);
                // APT can't receive the parameters in the formData, we need to send them as queryParameters
                url += url.indexOf('?') > -1 ? `&${eachKey}=${value}` : `?${eachKey}=${value}`;
            });
        }
        data = formData;

        uploadHandlers = {
            onUploadProgress: (percentage) => {
                dispatch({
                    type    : appTypes.PROGRESS_SHOW,
                    payload : {
                        content: (
                            <FormattedMessage
                                id={translated.global.uploadingSnackbar}
                                defaultMessage={translated.global.uploadingSnackbar}
                                values={{ percentage }}
                            />
                        ),
                    },
                });
            },
            onUploadFinish: () => {
                dispatch({ type: appTypes.PROGRESS_HIDE });
            },
        };
    }

    const httpReq = { url, method, data, params };

    try {
        dispatch({ type: types.DIRECT_REQUEST_START, payload: { method: reqConfig?.method } });
        const { response } = await Api.request(httpReq, isTypeFile, timeout, null, uploadHandlers, isFileDownload);
        dispatch({ type: types.DIRECT_REQUEST_END, payload: { response, method: reqConfig?.method } });

        return response;
    } catch (err) {
        dispatch({ type: types.DIRECT_REQUEST_ERROR, payload: { response: handleErrorsOnResponse(err), method: reqConfig?.method } });

        throw err;
    }
};

export const canLeaveSection = () => async (dispatch, getState) => {
    if (hasSectionChanged(getState())) {
        return new Promise((resolve) => {
            dispatch(
                appActions.showConfirmation({
                    title: (
                        <FormattedMessage
                            id={translated.global.sectionChangedConfirmation.title}
                            defaultMessage={translated.global.sectionChangedConfirmation.title}
                        />
                    ),
                    message: (
                        <FormattedMessage
                            id={translated.global.sectionChangedConfirmation.message}
                            defaultMessage={translated.global.sectionChangedConfirmation.message}
                        />
                    ),
                    onAccept : () => resolve(true),
                    onReject : () => resolve(false),
                }),
            );
        });
    }

    return true;
};

// Lint automatically align this in a single line, but later it complains about the line's length ⎺\_o_/⎺
// eslint-disable-next-line max-len
export const setCurrentSectionComponentWithChanges = (isChanged, formId) => async (dispatch) => dispatch({
    type    : types.CURRENT_SECTION_COMPONENT_WITH_CHANGES_SET,
    payload : { isChanged, formId },
});

export const setRootAndTemplates = (rootAndTemplates) => async (dispatch, store) => {
    await dispatch({ type: types.ROOT_AND_TEMPLATES_SET, payload: rootAndTemplates });

    // Loads the notifications of the sections
    const { panel: { sections } } = store();

    Object.entries(sections).forEach(
        async ([
            section,
            {
                isEnabled,
                isSelected,
                config: { notifications },
                resources: { current, templates },
            },
        ]) => {
            if (isEnabled && !isSelected && notifications && templates && templates[current]) {
                try {
                    const response = await dispatch(directRequest(templates[current]));

                    dispatch(setSectionState([section], notifications.getNotification(response)));
                } catch (e) {
                    // It was not possible to get the notifications.
                }
            }
        },
    );
};
