module.exports = Object.freeze({
    empty         : 'There are no external clubs',
    createTitle   : 'Create external club',
    editTitle     : 'Edit external club',
    updateFailed  : 'The external club could not be updated',
    updateSuccess : 'The external club has been updated',
    saveSuccess   : 'The external club has been added',

    enable: {
        success : 'The external club has been enabled',
        error   : 'The external club could not be enabled',
    },
    disable: {
        success : 'The external club has been disabled',
        error   : 'The external club could not be disabled',
    },
    delete: {
        confirmation               : 'Delete external club?',
        success                    : 'The external club has been removed',
        error                      : 'The external club could not be removed',
        errorEntityCannotBeDeleted : 'The external club could not be removed since it is being used',
        defaultError               : 'The external club could not be removed',
    },
});
