import React, { useMemo, useState, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import { useEventClick, useEventScroll, useEvent } from 'Hooks';
import Button from 'Components/Button';
import DropdownContext from './dropdownContext';
import { useIntl } from 'react-intl';
import { getMessage } from 'Components/WrappedFormattedMessage';

function Dropdown({
    id, onClick, className, classNameMenu, children, onOpen, dropRef, shouldCloseOnChildClick, label, ...props
}) {
    const intl = useIntl();

    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef();
    const dropdownRef = useRef();

    const translatedLabel = useMemo(() => getMessage(intl, label), [intl, label]);

    const updateComputedStyles = () => {
        if (buttonRef.current && buttonRef.current.getBoundingClientRect()
                && dropdownRef.current && dropdownRef.current.getBoundingClientRect()) {
            const { top, left, height, width: buttonWidth } = buttonRef.current.getBoundingClientRect();
            const { width, height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();

            const hasSpaceOnBottom = (top + height + dropdownHeight) <= window.innerHeight;
            const hasSpaceOnTop = (top + height) > dropdownHeight;

            let computedLeft = left - width + buttonWidth;
            let computedTop;

            if (hasSpaceOnBottom) {
                computedTop = top + height;
            } else if (hasSpaceOnTop) {
                computedTop = top - dropdownHeight;
            } else {
                computedTop = dropdownHeight / 2 - top + height;
                computedLeft = left - width;
            }

            dropdownRef.current.style.top = `${computedTop}px`;
            dropdownRef.current.style.left = `${computedLeft}px`;
        }
    };

    useEventScroll(buttonRef.current, updateComputedStyles, () => isOpen);
    useImperativeHandle(dropRef, () => ({ close: () => setIsOpen(false) }));

    const isClickOutside = useCallback(({ target }) => {
        const isElementStillVisible = document.contains(target);
        const isInsideDropdown = dropdownRef.current && dropdownRef.current.contains(target);
        const isInsideButton = buttonRef.current && buttonRef.current.contains(target);

        return (isOpen && isElementStillVisible && !(isInsideButton || isInsideDropdown));
    }, [isOpen]);

    useEventClick(dropdownRef.current, () => {
        setIsOpen(false);
    }, isClickOutside);

    const handleClickButton = () => {
        setIsOpen((previous) => {
            if (!previous && onOpen) {
                onOpen();
            }

            return !previous;
        });
        updateComputedStyles();
    };

    const dropdownClass = getClassName({ isOpen }, 'button-dropdown', className);
    const value = useMemo(() => ({ isOpen, setIsOpen, shouldCloseOnChildClick }), [isOpen, shouldCloseOnChildClick]);

    const close = useCallback(() => { setIsOpen(false); }, []);
    useEvent(dropdownRef.current, 'ButtonClicked', close, isClickOutside);

    return (
        <DropdownContext.Provider value={value}>
            <Button
                forwardedRef={buttonRef}
                className={dropdownClass}
                onClick={handleClickButton}
                id={id}
                isMultiClickEnabled
                {...props}
            >
                {translatedLabel}
            </Button>
            {ReactDOM.createPortal(
                (
                    <ul
                        id={`${id}-ul`}
                        data-id={id}
                        className={getClassName({ isOpen }, 'dropdown-menu', classNameMenu)}
                        ref={dropdownRef}
                    >
                        {children}
                    </ul>),
                document.getElementById('dropdowns'),
            )}
        </DropdownContext.Provider>
    );
}

Dropdown.defaultProps = {
    id                      : 'id',
    onClick                 : null,
    className               : '',
    classNameMenu           : '',
    onOpen                  : null,
    externalToggle          : null,
    dropRef                 : null,
    shouldCloseOnChildClick : true,
};

Dropdown.propTypes = {
    id                      : PropTypes.string,
    className               : PropTypes.string,
    classNameMenu           : PropTypes.string,
    onClick                 : PropTypes.func,
    children                : PropTypes.node.isRequired,
    onOpen                  : PropTypes.func,
    dropRef                 : PropTypes.shape({}),
    externalToggle          : PropTypes.bool,
    shouldCloseOnChildClick : PropTypes.bool,
};

function DropdownWithRef(props, ref) {
    return <Dropdown {...props} dropRef={ref} />;
}
DropdownWithRef.displayName = 'Dropdown';

export default forwardRef(DropdownWithRef);
