module.exports = Object.freeze({
    enable: {
        success : 'The contract type has been enabled',
        error   : 'The contract type could not be enabled',
    },
    disable: {
        success : 'The contract type has been disabled',
        error   : 'The contract type could not be disabled',
    },
    delete: {
        confirm                    : 'Delete contract type?',
        success                    : 'The contract type has been deleted',
        error                      : 'The contract type could not be removed',
        errorEntityCannotBeDeleted : 'This contract type could not be removed since it is being used',
    },
    cancel: {
        title   : 'Cancel changes?',
        message : 'Unsaved data will be lost.',
    },
    save: {
        success : 'The contract type has been updated',
        error   : 'The contract type could not be updated',
    },
    list: {
        sectionTitle : 'Contract Types',
        empty        : 'There are no contract types',
    },
    create: {
        title        : 'New contract type',
        sectionTitle : 'Information',
    },
    edit: {
        title        : 'Edit',
        sectionTitle : 'Information',
    },
    addRulesWhenUnsaved: 'It is not possible to add rules until the contract type is saved',

    // Entity fields
    name              : 'Name',
    code              : 'Code',
    id                : 'ID',
    primaryCurrency   : 'Primary currency',
    secondaryCurrency : 'Secondary currency',
    currency          : 'Currency',
    delta             : 'Delta',
});
