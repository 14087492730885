import { sectionType } from 'Constants/types';

const groups = Object.freeze({});
// Group example:
// global: {
//     name          : <FormattedMessage id={translated.global.sideMenu.global} defaultMessage={translated.global.sideMenu.global} />,
//     key           : 'global',
//     collapsedName : <FormattedMessage id={translated.global.sideMenu.more} defaultMessage={translated.global.sideMenu.more} />,
//     position      : 6,
//     isAccordion   : false, // TODO Complete accordion functionality once it is required
// },

const sectionsConf = Object.freeze({
    // SectionName                   : { position : Int, parent: (Opt) String }
    [sectionType.OWNERS]             : { position: 1 },
    [sectionType.CLUBS]              : { position: 2 },
    [sectionType.PARTNERS]           : { position: 3 },
    [sectionType.CONTRACT]           : { position: 4 },
    [sectionType.RESERVATIONS]       : { position: 5 },
    [sectionType.TIERS]              : { position: 6 },
    [sectionType.BALANCE_TYPES]      : { position: 7 },
    [sectionType.EXTERNAL_CLUBS]     : { position: 8 },
    [sectionType.CATEGORIES]         : { position: 9 },
    [sectionType.AGENTS]             : { position: 10 },
    [sectionType.ROLES]              : { position: 11 },
    [sectionType.EXTRA_ATTRIBUTES]   : { position: 12 },
    [sectionType.CURRENCIES]         : { position: 13 },
    [sectionType.SYSTEM_RULES]       : { position: 14 },
    [sectionType.TAGS]               : { position: 15 },
    [sectionType.PROPERTY_GROUPS]    : { position: 16 },
    [sectionType.PROPERTIES]         : { position: 17 },
    [sectionType.ACCOUNT_STATEMENTS] : { position: 18 },
});

const getListParent = (list, name) => {
    const elem = list.find((e) => e.key === name);

    if (elem) {
        return elem;
    }

    const parent = { ...groups[name], key: name, children: [] };

    list.push(parent);

    return parent;
};

export default (sections) => {
    const filteredSections = Object.keys(sections).filter((section) => !sections[section].isHidden && sections[section].isEnabled);

    const converted = [];

    filteredSections.forEach((each) => {
        const sectionData = {
            id                  : sections[each].id || each,
            name                : sections[each].name,
            isSelected          : sections[each].isSelected,
            isEnabled           : sections[each].isEnabled,
            icon                : sections[each].config?.icon,
            defaultSection      : sections[each].config?.defaultSection,
            hasNotifications    : sections[each].state?.hasNotifications,
            notificationsAmount : sections[each].state?.notificationsAmount,
            position            : sectionsConf[each]?.position || sectionsConf[each]?.position,
            section             : each,
        };

        if (sectionsConf[each]?.parent) {
            const parent = getListParent(converted, sectionsConf[each]?.parent);

            parent.children.push(sectionData);
        } else {
            converted.push(sectionData);
        }
    });

    return converted;
};
