import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Radio({
    className, label, name, onChange, checked, value, index, isReadOnlyWithoutInputs, isDisabled, id, innerInputRef,
}) {
    return (
        <>
            {!isReadOnlyWithoutInputs && (
                <label>
                    <div className={`radio ${className}`}>
                        <input
                            id={id}
                            type="checkbox"
                            name={name}
                            checked={checked}
                            onChange={() => onChange({ target: { value: value !== null ? value : index, label } })}
                            tabIndex={isDisabled ? '-1' : '0'}
                            ref={innerInputRef}
                        />
                        <span />
                    </div>
                    {label && (
                        <span id={`${id}-label`}>
                            <WrappedFormattedMessage content={label} />
                        </span>
                    )}
                </label>
            )}
            {isReadOnlyWithoutInputs && checked && (
                <span id={id}>
                    <WrappedFormattedMessage content={label} />
                </span>
            )}
        </>
    );
}

Radio.defaultProps = {
    id        : '',
    className : '',
    label     : '',
    value     : null,
    index     : 0,
    checked   : false,
    onChange  : () => {
        // Default
    },
    isReadOnlyWithoutInputs : false,
    isDisabled              : false,
    innerInputRef           : null,
};

Radio.propTypes = {
    id                      : PropTypes.string,
    name                    : PropTypes.string.isRequired,
    value                   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    checked                 : PropTypes.bool,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    index                   : PropTypes.number,
    className               : PropTypes.string,
    onChange                : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Radio);
