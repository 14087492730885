import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { notificationTypes } from 'Constants/global';
import translated from 'Constants/labels/translated';

function Notification({ notification, dateManager }) {
    const {
        data: {
            client: { firstName, lastName },
            clubName,
        },
        creationDatetime,
        type,
    } = notification;

    const name = `${firstName} ${lastName}`;
    let action = '';
    const message = clubName;

    switch (type) {
        case notificationTypes.CONVERSION:
            action = (
                <FormattedMessage id={translated.notifications.notificationConversion} defaultMessage={translated.notifications.notificationConversion} />
            );
            break;
        default:
            break;
    }

    return (
        <div className="user-notification">
            <div className="user-notification-content">
                <span className="user-notification-name">{name}</span>
                <span className="user-notification-action">{action}</span>
                <span className="user-notification-message">{message}</span>
                <small className="user-notification-time" title={creationDatetime}>
                    {creationDatetime ? dateManager.fromNow(creationDatetime) : ''}
                </small>
            </div>
        </div>
    );
}

Notification.defaultProps = {};

Notification.propTypes = {
    notification : PropTypes.shape({}).isRequired,
    dateManager  : PropTypes.shape({}).isRequired,
};

export default Notification;
