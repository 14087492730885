import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import ConfirmationButton from 'Components/ConfirmationButton';
import FormContext from './formContext';
import { useFormContext } from 'react-hook-form';

function ResetButton({
    id, children, isDisabled, collapsed, confirmation, onClick, isLoading, variant, color, size,
}) {
    const intl = useIntl();
    const { formId } = useContext(FormContext);

    const { formState, reset: formReset } = useFormContext() || {};

    const { isDirty = true, isSubmitting = false } = formState || {};

    const reset = () => {
        formReset();

        if (onClick) {
            onClick();
        }
    };

    const isButtonDisabled = isDisabled || !isDirty || isSubmitting;

    if (collapsed) {
        return (
            <ConfirmationButton
                id={id || formId ? `${formId}-reset-button` : null}
                confirmation={confirmation}
                icon="UndoVariant"
                tooltip={intl.formatMessage({
                    id             : translated.global.buttons.reset,
                    defaultMessage : translated.global.buttons.reset,
                })}
                onClick={reset}
                disabled={isButtonDisabled}
                isLoading={isLoading}
            />
        );
    }

    return (
        <ConfirmationButton
            id={id || formId ? `${formId}-reset-button` : null}
            confirmation={confirmation}
            variant={variant || 'text'}
            color={color}
            onClick={reset}
            disabled={isButtonDisabled}
            isLoading={isLoading}
            size={size}
        >
            {children || <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />}
        </ConfirmationButton>
    );
}

ResetButton.defaultProps = {
    id           : null,
    children     : null,
    isDisabled   : false,
    collapsed    : false,
    confirmation : null,
    isLoading    : false,
    onClick      : null,
    variant      : '',
    color        : '',
    size         : '',
};

ResetButton.propTypes = {
    id           : PropTypes.string,
    children     : PropTypes.node,
    isDisabled   : PropTypes.bool,
    collapsed    : PropTypes.bool,
    isLoading    : PropTypes.bool,
    onClick      : PropTypes.func,
    confirmation : PropTypes.shape({
        title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    variant : PropTypes.string,
    color   : PropTypes.string,
    size    : PropTypes.string,
};

export default ResetButton;
