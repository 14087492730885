import React, { useContext, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormContext from './formContext';
import getClassName from 'Utils/getClassName';
import PanelContext from 'State/panelContext';
import { useFormContext } from 'react-hook-form';

const FILTER_LOCK_DURATION = 2000;

/**
 * Manages the state of all children components, maintains an object with the values entered by
 * the user and invokes the function provided for the submit passing this information as arguments,
 * it has all kinds of Input components, as well as Groups, Titles, Columns, a Wrapper and a Summary
 */
function FormNew({
    className, children, onError,
    isReadOnly, isReadOnlyWithoutInputs, onSubmit, onFinish,
    shouldSubmitOnEnter, forceValidationsOnFirstRender, resetFormOnSubmit,
    avoidConfirmation,
}) {
    const { isContextInitialized: isPanelContextInitialized, snackbar, navigator } = useContext(PanelContext);

    const lockTimerRef = useRef(null);

    const {
        isContextInitialized,
        setEvents,
        setIsSubmitting,
        formRef,
        formId,
    } = useContext(FormContext);

    const { handleSubmit, trigger, reset, formState: { isDirty } } = useFormContext();

    useEffect(() => {
        if (isContextInitialized
            && isPanelContextInitialized
            && !avoidConfirmation
            && !isReadOnlyWithoutInputs
            && !isReadOnly
        ) {
            navigator.setCurrentSectionComponentWithChanges(isDirty, formId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    // This handler and listener found below allows us to submit a form by click enter.
    const handlerEnterClicked = useCallback((e) => {
        if (shouldSubmitOnEnter && e.keyCode === 13) {
            if (lockTimerRef.current) {
                // The action is locked for a short period of time.
                return null;
            }

            lockTimerRef.current = setTimeout(async () => {
                // After the lock timer finishes, it enables the action
                lockTimerRef.current = null;
            }, FILTER_LOCK_DURATION);
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSubmitOnEnter, onSubmit]);

    useEffect(() => {
        //
        if (shouldSubmitOnEnter && formRef?.current) {
            formRef.current.addEventListener('keyup', handlerEnterClicked, false);
        }
        return () => {
            if (shouldSubmitOnEnter && formRef?.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                formRef.current.removeEventListener('keyup', handlerEnterClicked, false);
            }
        };
    }, [formRef, handlerEnterClicked, shouldSubmitOnEnter]);

    useEffect(() => {
        if (isContextInitialized) {
            setEvents((prev) => ({
                ...prev,
                onSubmit: async (submittedValues) => {
                    if (!onSubmit) {
                        if (isPanelContextInitialized) {
                            navigator.setCurrentSectionComponentWithChanges(false, formId);
                        }
                        return;
                    }
                    try {
                        setIsSubmitting(true);
                        const response = await onSubmit(submittedValues);

                        if (isPanelContextInitialized) {
                            // Remove the form of the list of components with changes
                            await navigator.setCurrentSectionComponentWithChanges(false, formId);
                        }

                        if (onFinish) {
                            await onFinish(submittedValues, response);
                        }
                    } catch (error) {
                        // Save failed, The implementation of the onSubmit should throw
                        // an FormError() when the user cancels or the submit fails
                        if (error?.name === 'FormError' && isPanelContextInitialized) {
                            snackbar.show({
                                isError     : true,
                                content     : error.snackbarMessage,
                                error       : error.originalError,
                                errorLabels : error.errorLabels,
                            });
                        } else if (onError) {
                            onError(submittedValues);
                        }
                    } finally {
                        setIsSubmitting(false);
                    }
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmit, isContextInitialized]);

    // The key
    useEffect(() => {
        if (forceValidationsOnFirstRender && trigger) {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formClassName = useMemo(() => getClassName(
        {
            isReadOnly,
            isReadOnlyWithoutInputs,
        },
        'form',
        className,
    ), [className, isReadOnly, isReadOnlyWithoutInputs]);

    const onSubmitWrapper = async (submitProps) => {
        try {
            setIsSubmitting(true);
            await onSubmit(submitProps);

            if (isPanelContextInitialized) {
                // Remove the form of the list of components with changes
                await navigator.setCurrentSectionComponentWithChanges(false, formId);
            }

            if (resetFormOnSubmit) {
                reset(submitProps);
            }

            if (onFinish) {
                await onFinish();
            }
        } catch (error) {
            // Save failed, The implementation of the onSubmit should throw
            // an FormError() when the user cancels or the submit fails
            if (error?.name === 'FormError' && isPanelContextInitialized) {
                snackbar.show({
                    isError     : true,
                    content     : error.snackbarMessage,
                    error       : error.originalError,
                    errorLabels : error.errorLabels,
                });
            }

            if (onError) {
                onError(error);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOnSubmit = async (event) => {
        // It stops parent form's submit
        if (event) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
        }

        return handleSubmit(async (values) => {
            onSubmitWrapper(values);
        })(event);
    };

    return (
        <form
            onSubmit={handleOnSubmit}
            ref={formRef}
        >
            <div className={formClassName}>
                {children}
            </div>
        </form>
    );
}

FormNew.defaultProps = {
    className                     : '',
    initialValues                 : null,
    onSubmit                      : null,
    onFinish                      : null,
    onError                       : null,
    buttonsWidth                  : null,
    isReadOnly                    : false,
    isReadOnlyWithoutInputs       : false,
    shouldSubmitOnEnter           : false,
    forceValidationsOnFirstRender : false,
    resetFormOnSubmit             : false,
    avoidConfirmation             : false,
};

FormNew.propTypes = {
    className                     : PropTypes.string,
    initialValues                 : PropTypes.shape({}),
    isReadOnly                    : PropTypes.bool,
    isReadOnlyWithoutInputs       : PropTypes.bool,
    onSubmit                      : PropTypes.func,
    onFinish                      : PropTypes.func,
    onError                       : PropTypes.func,
    children                      : PropTypes.node.isRequired,
    buttonsWidth                  : PropTypes.shape({}),
    shouldSubmitOnEnter           : PropTypes.bool,
    forceValidationsOnFirstRender : PropTypes.bool,
    resetFormOnSubmit             : PropTypes.bool,
    avoidConfirmation             : PropTypes.bool,
};

export default FormNew;
