import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Table from 'Components/Table';
import Modal from 'Components/Modal';
import Alert from 'Components/Alert';
import Status from 'Components/Status';
import Loading from 'Components/Loading';
import { fetchingShape } from 'Constants/PropTypes';
import PanelContext from 'State/panelContext';
import ModalBooking from './ModalBooking';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';

function BookingList({
    data, fetching, reloadData, isUserSection, sections, resources, setSectionState, state: { appliedFilters },
}) {
    const { navigator, snackbar } = useContext(PanelContext);
    const [loadingBooking, setLoadingBooking] = useState(null);
    const [modalData, setModalData] = useState({ isOpen: false, cancellation: null });
    const [isCheckingIfCanBeModified, setCheckingIfCanBeModified] = useState(false);
    const [cantPerformBookingModification, setCantPerformBookingModification] = useState(false);

    const items = data?.data?.map((booking) => ({
        ...booking,
        id               : booking.reservationId,
        reservationRooms : booking.reservationRooms ? booking.reservationRooms.map((each, index) => ({ id: `room${index}`, name: each })) : [],
        contractId       : booking.contract?.legacyId,
    })) || [];

    const { pagination, filter: filterLinks } = data || {};

    const cancelBooking = async (booking) => {
        setLoadingBooking(booking.reservationId);

        let bookingData = null;
        try {
            bookingData = await navigator.directRequest(booking.links.self.detail);
        } catch (error) {
            snackbar.show({ error, isError: true, content: translated.bookings.errors.getBookingError });
        }

        setLoadingBooking(null);
        if (bookingData) {
            setModalData({ isOpen: true, cancellation: bookingData });
        }
    };

    const modifyBooking = async (booking) => {
        setCheckingIfCanBeModified(true);

        try {
            const { canBeModified, links } = await navigator.directRequest(booking.links.self.modify);
            setCheckingIfCanBeModified(false);

            if (canBeModified && links?.self?.modify) {
                window.open(links.self.modify.url, '_blank');
            } else {
                setCantPerformBookingModification(true);
            }
        } catch (error) {
            snackbar.show({ error, isError: true, content: translated.bookings.errors.getModify });
            setCheckingIfCanBeModified(false);
        }
    };

    const onModalClose = (shouldUpdate) => {
        setModalData({ isOpen: false, data: null, cancellation: null });

        if (shouldUpdate) {
            reloadData();
        }
    };

    const columns = [
        {
            title     : translated.bookings.hotel,
            key       : 'hotelName',
            isVisible : true,
        },
        {
            title                : translated.bookings.status,
            key                  : 'status',
            isVisible            : true,
            customFieldComponent : (bookings) => <Status status={bookings.status} />,
        },
        {
            title     : translated.bookings.bookingCode,
            key       : 'reservationId',
            isVisible : true,
        },
        {
            title     : translated.bookings.bookingDate,
            key       : 'bookingDate',
            isVisible : true,
            isDate    : true,
        },
        {
            title     : translated.bookings.checkIn,
            key       : 'arrivalDate',
            isVisible : true,
            isDate    : true,
        },
        {
            title     : translated.bookings.checkOut,
            key       : 'departureDate',
            isVisible : true,
            isDate    : true,
        },
    ];
    if (!isUserSection) {
        columns.unshift({
            title     : translated.bookings.guest,
            key       : 'guestName',
            isVisible : true,
        });
    } else {
        columns.push({
            title     : translated.bookings.contract,
            key       : 'contractId',
            isVisible : true,
        });
    }

    return (
        <>
            {isCheckingIfCanBeModified && (
                <>
                    <Loading />
                    <Modal title={translated.global.pleaseWait}>
                        <span>
                            <FormattedMessage
                                id={translated.bookings.modifications.checking}
                                defaultMessage={translated.bookings.modifications.checking}
                            />
                        </span>
                    </Modal>
                </>
            )}
            {cantPerformBookingModification && (
                <Modal
                    title={translated.bookings.modifications.cantModify}
                    closeButton={{
                        text    : translated.global.buttons.close,
                        onClick : () => setCantPerformBookingModification(false),
                    }}
                >
                    <Alert
                        id="edit-warning"
                        type="warning"
                        content={translated.bookings.modifications.cantModifyDetails}
                    />
                </Modal>
            )}
            {modalData.isOpen && <ModalBooking cancellation={modalData.cancellation} onClose={onModalClose} />}
            <Sections.Selected
                selectFrom={sections}
                onClose={() => navigator.goToParentAndReload(true, false)}
                directRequest={navigator.directRequest}
                requestForCurrentPath={navigator.requestForCurrentPath}
                showSnackbar={snackbar.show}
                showErrorOnMissingSection={false}
            />
            <Table
                id="owners-bookings"
                key="owners-bookings"
                columns={columns}
                rows={{
                    actions: [
                        {
                            content   : translated.bookings.actions.modify,
                            callback  : modifyBooking,
                            key       : 'modify',
                            when      : (booking) => booking?.links?.self?.modify,
                            isEnabled : !loadingBooking,
                        },
                        {
                            content   : translated.bookings.actions.cancel,
                            callback  : cancelBooking,
                            key       : 'cancel',
                            when      : (booking) => booking?.links?.self?.detail,
                            isEnabled : !loadingBooking,
                        },
                        {
                            content           : translated.owners.view,
                            tooltip           : translated.owners.view,
                            key               : 'view',
                            icon              : 'FileEye',
                            isOutsideDropdown : true,
                            disabled          : false,
                            getLink           : (booking) => booking?.links?.self?.read,
                            when              : (booking) => booking?.links?.self?.read,
                        },
                    ],
                    subTable: {
                        id      : 'booking-rooms',
                        key     : 'reservationRooms',
                        columns : [
                            {
                                title     : translated.bookings.reservedRooms,
                                key       : 'name',
                                isVisible : true,
                            },
                        ],
                        rows: { actions: [] },
                    },
                }}
                items={items}
                loadingIds={[...fetching.ids, loadingBooking]}
                whenEmpty={(
                    <Alert
                        id="owners-bookings-empty"
                        content={isUserSection ? translated.bookings.userEmptyBookings : translated.bookings.userEmptyBookings}
                    />
                )}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                filterResources={{
                    filters: [
                        {
                            type        : 'text',
                            submitKey   : 'keyword',
                            resourceKey : 'keyword',
                        },
                    ],
                    filterLinks,
                    resources,
                    supportText: translated.bookings.filterSupportText,
                }}
            />
        </>
    );
}
BookingList.defaultProps = { isUserSection: false };
BookingList.propTypes = {
    data: PropTypes.shape({
        bookings : PropTypes.arrayOf(PropTypes.shape({})),
        links    : PropTypes.shape({}),
    }).isRequired,
    sections        : PropTypes.shape({}).isRequired,
    fetching        : fetchingShape.isRequired,
    reloadData      : PropTypes.func.isRequired,
    isUserSection   : PropTypes.bool,
    state           : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func.isRequired,
};

export default BookingList;
