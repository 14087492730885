module.exports = Object.freeze({
    titleImport                    : 'apf.imports.titleImport',
    titleCreate                    : 'apf.imports.titleCreate',
    selectFile                     : 'apf.imports.selectFile',
    noFiles                        : 'apf.imports.noFiles',
    upload                         : 'apf.imports.upload',
    create                         : 'apf.imports.create',
    go                             : 'apf.imports.go',
    status                         : 'apf.imports.status',
    createThisOwner                : 'apf.imports.createThisOwner',
    openFile                       : 'apf.imports.openFile',
    goToThisOwner                  : 'apf.imports.goToThisOwner',
    firstName                      : 'apf.imports.firstName',
    filename                       : 'apf.imports.filename',
    lastName                       : 'apf.imports.lastName',
    email                          : 'apf.imports.email',
    cantSaveTheFile                : 'apf.imports.cantSaveTheFile',
    theFileHasErrors               : 'apf.imports.theFileHasErrors',
    valid                          : 'apf.imports.valid',
    owners                         : { title: 'apf.imports.owners.title', empty: 'apf.imports.owners.empty' },
    saveInvalidContract            : 'apf.imports.saveInvalidContract',
    saveInvalidQuantityOfHighlight : 'apf.imports.saveInvalidQuantityOfHighlight',
    saveInvalidLegacyContractsId   : 'apf.imports.saveInvalidLegacyContractsId',
    error                          : 'apf.imports.error',
    contractInitializeError        : 'apf.imports.contractInitializeError',
    initializeError                : 'apf.imports.initializeError',
    loadTiersError                 : 'apf.imports.loadTiersError',
    loadContractTypesError         : 'apf.imports.loadContractTypesError',
    fileManagerError               : 'apf.imports.fileManagerError',
    success                        : 'apf.imports.success',
    viewProfile                    : 'apf.imports.viewProfile',
    saveError                      : 'apf.imports.saveError',

    cantDeleteTheFile : 'apf.imports.cantDeleteTheFile',
    delete            : {
        confirmation : 'apf.imports.delete.confirmation',
        success      : 'apf.imports.delete.success',
        error        : 'apf.imports.delete.error',
    },

    saveWarning  : 'apf.imports.saveWarning',
    saveWarnings : {
        default               : 'apf.imports.saveWarnings.default',
        contractLegacyIdInUse : 'apf.imports.saveWarnings.contractLegacyIdInUse',
        errorAlphanumeric     : 'apf.imports.saveWarnings.schemaValidation',
    },
});
