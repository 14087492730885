import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form, { FormError } from 'Components/Form';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';

export function Editor({ data, resources, isEditing, clubsInUse }) {
    const { navigator } = useContext(PanelContext);

    const { additionalResources } = data;

    const [ratioData, setRatioData] = useState({
        externalClub : data?.externalClub?.name,
        ratio        : data?.ratio,
    });

    const externalClubs = additionalResources?.externalClubs?.map((each) => ({
        value      : each.id,
        content    : each.name,
        isDisabled : !!clubsInUse?.find((e) => e === each.id) && data?.externalClub?.id !== each.id,
    })) || [];

    const modalPropertiesForForm = useMemo(
        () => ({
            title     : translated.conversionRules.editor.title,
            className : 'modal-wallet-config',
            buttons   : [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={() => navigator.goToParentAndReload(true, false)}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    {isEditing ? (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                    )}
                </Form.Primary>,
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }),
        [isEditing, navigator],
    );

    const handleOnSubmit = async ({ values }) => {
        // The Form.Group elements of the form cant validate for required elements, so we have to do it manually.
        if (!values?.allowedPoints?.length) {
            throw new FormError(translated.conversionRules.editor.errors.allowedPointRequired);
        }

        const reqConfig = {
            data: {
                ...values,
                isActive : typeof data.isActive !== 'undefined' ? data.isActive : true,
                fee      : values.fee || 0,
                minimum  : values.minimum || 1,
            },
            ...resources.available[isEditing ? 'update' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(
                translated.conversionRules.editor.errors.default,
                e,
                translated.conversionRules.editor.errors,
            );
        }
    };

    const handleOnFinish = () => {
        navigator.goToParentAndReload(true, false);
    };

    const initialValues = { ...data, externalClub: data?.externalClub?.id, allowedPoints: data?.allowedPoints?.map((each) => each.id) || [] };

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form
                id="conversion-rule"
                buttonsWidth={{ base: 12, small: 6 }}
                onSubmit={handleOnSubmit}
                onFinish={handleOnFinish}
                initialValues={initialValues}
                className="margin-top-small"
            >
                {data.id && <Form.Hidden submitKey="id" value={data.id} />}
                <Form.Input
                    submitKey="externalClub"
                    isDense
                    type="select"
                    label={translated.conversionRules.editor.club}
                    options={externalClubs}
                    submitFormat={(value) => ({ id: value })}
                    onChange={(newClub) => {
                        setRatioData((prev) => ({ ...prev, externalClub: additionalResources?.externalClubs?.find((each) => each.id === newClub)?.name }));
                    }}
                    isRequired
                />
                <Form.Input
                    submitKey="ratio"
                    label={translated.conversionRules.editor.conversionRate}
                    isDense
                    type="number"
                    validations={{ minValue: 0.01, maxValue: 99999999, maxLength: 11, unary: ['float'] }}
                    onChange={(newRatio) => {
                        setRatioData((prev) => ({ ...prev, ratio: newRatio }));
                    }}
                    helperText={
                        ratioData?.externalClub && ratioData?.ratio
                            ? {
                                label: (
                                    <FormattedMessage
                                        id={translated.conversionRules.editor.conversionRate}
                                        defaultMessage={translated.conversionRules.editor.conversionRate}
                                        values={{
                                            ratio : ratioData.ratio,
                                            club  : ratioData.externalClub,
                                        }}
                                    />
                                ),
                            }
                            : null
                    }
                    isRequired
                />
                <Form.Input
                    submitKey="fee"
                    label={translated.conversionRules.editor.conversionFee}
                    isDense
                    type="number"
                    validations={{ minValue: 0, maxValue: 99999999, maxLength: 11, unary: ['float'] }}
                    supportText={translated.conversionRules.editor.conversionFeeSupportText}
                />

                <Form.Group
                    id="conversion-rule-allowed-point"
                    type="chip"
                    name="allowedPoints"
                    submitKey="allowedPoints"
                    label={translated.conversionRules.editor.allowedPoints}
                    isMultiSelect
                    submitFormat={(values) => values?.map?.((each) => ({ id: each })) || []}
                >
                    {additionalResources?.allowedPoints?.map((each) => (
                        <Form.Input value={each.id} label={each.name} />
                    ))}
                </Form.Group>

                <Form.Input
                    submitKey="minimum"
                    label={translated.conversionRules.editor.minValue}
                    isDense
                    type="number"
                    validations={{ minValue: 1, maxValue: 99999999, maxLength: 11, unary: ['numbers'] }}
                />
            </Form>
        </Form.Wrapper>
    );
}

Editor.defaultProps = { clubsInUse: null };

Editor.propTypes = {
    data       : PropTypes.shape({}).isRequired,
    resources  : PropTypes.shape({}).isRequired,
    isEditing  : PropTypes.bool.isRequired,
    clubsInUse : PropTypes.arrayOf(PropTypes.number),
};

export default withRequest(Editor);
