import React, { useContext, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'Components/Skeletons';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import Grid from 'Components/Grid';
import SectionsWrapper from '../../Wrapper';
import Subsection from '../../Subsection';
import constants from './constants';
import translated from 'Constants/labels/translated';
import { sectionType } from 'Constants/types';
import Form, { FormError, FormNew } from 'Components/Form';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import Tiers from './Tiers';
import BalanceTypes from './BalanceTypes';
import EntityTags from 'Components/Sections/Tags/EntityTags';
import yup from 'Utils/yupHelper';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';

const schema = yup.object().shape({
    name         : yup.string().required().max(100),
    clubDuration : yup.string().required(),
    currency     : yup.number(),
    duration     : yup.number().integer().min(1).max(999)
        .when('clubDuration', {
            is   : constants.FIXED,
            then : (currentSchema) => currentSchema.required(),
        }),
    dateRange: yup.object()
        .when('clubDuration', {
            is   : constants.DYNAMIC,
            then : () => yup.dateRange().required(),
        }),
    overrideCosts: yup.bool(),
});

const MIN_DATE = '2000-01-01';

export function ClubsGeneral({ data, isEditing, sections, resources, name: sectionName }) {
    const { available } = resources;

    const { links } = data;

    const availableAttributes = data?.additionalResources?.extraAttributes || [];

    const isRecentlyCreated = useRef(false);

    const { snackbar, navigator, dateManager } = useContext(PanelContext);

    const handleOnSubmit = useCallback(async (values) => {
        const reqConfig = {
            data: {
                ...values,
                id        : isEditing ? data.id : undefined,
                dateRange : values.dateRange || null,
                duration  : values.duration || null,
            },
            ...(isEditing ? available.update : available.create),
        };

        let response;
        try {
            response = await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(translated.clubs.clubNotSaved, e);
        }

        return response;
    }, [available.create, available.update, data.id, isEditing, navigator]);

    const handleOnFinish = useCallback(
        async (values, response) => {
            let snackbarMessage = translated.clubs.clubUpdated;
            if (!isEditing && !isRecentlyCreated.current) {
                snackbarMessage = translated.clubs.clubSaved;
            }

            if (response?.warnings?.length) {
                snackbar.show({ content: response.warnings[0]?.message?.[0] || translated.global.errors.default, isWarning: true });
            } else {
                snackbar.show({ content: snackbarMessage, isSuccess: true });
            }

            isRecentlyCreated.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [isEditing, snackbar],
    );

    // Hooks must be executed always, so this code is below them
    const { Section, props } = Sections.get(sections);
    if (Section) {
        return <Section {...props} />;
    }

    const resetAndSaveButtons = !isEditing
        ? [<Form.SecondaryNew color="primary" key="fs" onClick={navigator.goToRoot} />]
        : [
            <Form.ResetNew
                color="primary"
                key="fs"
                confirmation={{
                    title   : translated.clubs.areYouSureToReset,
                    message : translated.clubs.unsavedDataWillBeLost,
                }}
            />,
        ];
    resetAndSaveButtons.push(<Form.PrimaryNew key="fp" />);

    // Ranges limit dates
    const clubMinValue = dateManager.defaultDateFormat(MIN_DATE);
    const clubMaxValue = dateManager.maxDateInDefaultFormat();

    const initialValues = {
        ...data,
        overrideCosts : !!data?.overrideCosts,
        clubDuration  : data?.clubDuration || constants.FIXED,
    };

    const steps = [
        <FormattedMessage id={translated.clubs.wizard.step1} defaultMessage={translated.clubs.wizard.step1} />,
        <>
            <p>
                <FormattedMessage id={translated.clubs.wizard.step2} defaultMessage={translated.clubs.wizard.step2} />
            </p>
            <p>
                <strong>
                    <FormattedMessage id={translated.clubs.durationFixed} defaultMessage={translated.clubs.durationFixed} />
                </strong>
                <span>
                    {' '}
                    <FormattedMessage id={translated.clubs.wizard.step2Fixed} defaultMessage={translated.clubs.wizard.step2Fixed} />
                </span>
            </p>
            <p>
                <strong>
                    <FormattedMessage id={translated.clubs.durationDynamic} defaultMessage={translated.clubs.durationDynamic} />
                </strong>
                <span>
                    {' '}
                    <FormattedMessage id={translated.clubs.wizard.step2Dynamic} defaultMessage={translated.clubs.wizard.step2Dynamic} />
                </span>
            </p>
        </>,
        <FormattedMessage id={translated.clubs.wizard.step3} defaultMessage={translated.clubs.wizard.step3} />,
    ];

    if (isEditing) {
        if (links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName]) {
            steps.push(<FormattedMessage id={translated.clubs.wizard.step4} defaultMessage={translated.clubs.wizard.step4} />);
        }
        if (links?.[sections[sectionType.CLUBS_TIERS]?.config?.resourceName]) {
            steps.push(<FormattedMessage id={translated.clubs.wizard.step5} defaultMessage={translated.clubs.wizard.step5} />);
        }
        if (links?.[sections[sectionType.CLUBS_BALANCE_TYPES]?.config?.resourceName]) {
            steps.push(<FormattedMessage id={translated.clubs.wizard.step6} defaultMessage={translated.clubs.wizard.step6} />);
        }
    }

    const handleOnSubmitExtraAttributes = async ({ values }) => {
        try {
            validateDynamicInputs(values.extraAttributes, dateManager);

            const convertedData = {
                ...data,
                links               : undefined,
                additionalResources : undefined,
                extraAttributes     : { ...values.extraAttributes },
            };

            const reqConfig = {
                ...available.update,
                data: convertedData,
            };

            await navigator.requestForCurrentPath({ reqConfig, resources });

            snackbar.show({
                content   : translated.clubs.extraAttributes.save.success,
                isSuccess : true,
            });
        } catch (saveError) {
            throw new FormError(
                translated.clubs.extraAttributes.save.error,
                saveError,
            );
        }
    };

    const resetConfirmation = {
        title   : translated.global.resetConfirmation.title,
        message : translated.global.resetConfirmation.message,
    };

    return (
        <Form.WrapperNew
            schema={schema}
            initialValues={initialValues}
            formId="clubs-form"
        >
            <SectionsWrapper title={sectionName} actionButtons={resetAndSaveButtons} steps={steps}>
                <Subsection title={translated.global.information}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <FormNew
                                onSubmit={handleOnSubmit}
                                onFinish={handleOnFinish}
                                buttonsWidth={{ base: 12, small: 6 }}
                                resetFormOnSubmit={isEditing}
                            >
                                <Form.InputNew
                                    submitKey="name"
                                    type="text"
                                    label={translated.global.name}
                                    charCount={{ total: 100 }}
                                    isDense
                                    className="step-1"
                                />

                                <Form.InputNew
                                    submitKey="clubDuration"
                                    type="chipPicker"
                                    label={translated.clubs.currentDuration}
                                    className="step-2"
                                    isDense
                                    options={[
                                        { label: translated.clubs.durationFixed, value: constants.FIXED },
                                        { label: translated.clubs.durationDynamic, value: constants.DYNAMIC },
                                    ]}
                                />

                                <Form.InputNew
                                    submitKey="dateRange"
                                    type="picker"
                                    isRange
                                    label={translated.global.dates}
                                    minDate={clubMinValue}
                                    maxDate={clubMaxValue}
                                    icon={{ name: 'Calendar', position: 'right' }}
                                    className="range-input"
                                    isDense
                                    minRangeLength={1}
                                    showWhen={{
                                        field : 'clubDuration',
                                        is    : constants.DYNAMIC,
                                    }}
                                />

                                <Form.InputNew
                                    submitKey="duration"
                                    type="number"
                                    label={translated.clubs.numberOfMonths}
                                    className="small-number-input"
                                    isDense
                                    showWhen={{
                                        field : 'clubDuration',
                                        is    : constants.FIXED,
                                    }}
                                />

                                <Form.InputNew
                                    type="checkbox"
                                    submitKey="overrideCosts"
                                    label={translated.clubs.overrideCosts}
                                    isDense
                                    supportText={translated.clubs.overrideCostsWarning}
                                    className="step-3"
                                />
                            </FormNew>
                        </Grid.Column>
                    </Grid>

                    { isEditing && (
                        <Form.Wrapper>
                            <Subsection
                                canCollapse
                                title={translated.clubs.extraAttributes.title}
                                shouldShowFormInfoOnCollapse
                                className="margin-top-xxlarge"
                                actionButtons={[
                                    <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                                        <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                                    </Form.Reset>,
                                    <Form.Primary key="fp">
                                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                                    </Form.Primary>,
                                ]}
                            >
                                <Form id="extra-attributes-club-form" onSubmit={handleOnSubmitExtraAttributes}>
                                    <Form.Column width={{ base: 12, small: 6 }}>
                                        <Form.Input
                                            id="extra-attributes"
                                            key="extra-attributes"
                                            submitKey="extraAttributes"
                                            type="dynamicInputs"
                                            value={data?.extraAttributes}
                                            availableAttributes={availableAttributes}
                                            submitFormat={(values) => transformDynamicInputs(values)}
                                        />
                                    </Form.Column>
                                </Form>
                            </Subsection>
                        </Form.Wrapper>
                    )}

                    {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                        <EntityTags
                            {...sections[sectionType.ENTITY_TAGS]}
                            resources={{
                                ...sections[sectionType.ENTITY_TAGS].resources,
                                available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                            }}
                            customClass="step-4 margin-top-xxlarge"
                        />
                    )}

                    {links?.[sections[sectionType.CLUBS_TIERS]?.config?.resourceName] && (
                        <Tiers
                            {...sections[sectionType.CLUBS_TIERS]}
                            resources={{
                                ...sections[sectionType.CLUBS_TIERS].resources,
                                available: links[sections[sectionType.CLUBS_TIERS].config.resourceName],
                            }}
                            pathForResource={[sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL, sectionType.CLUBS_TIERS]}
                            clubId={data.id}
                            customClass="step-5"
                        />
                    )}

                    {links?.[sections[sectionType.CLUBS_BALANCE_TYPES]?.config?.resourceName] && (
                        <BalanceTypes
                            {...sections[sectionType.CLUBS_BALANCE_TYPES]}
                            resources={{
                                ...sections[sectionType.CLUBS_BALANCE_TYPES].resources,
                                available: links[sections[sectionType.CLUBS_BALANCE_TYPES].config.resourceName],
                            }}
                            pathForResource={[sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL, sectionType.CLUBS_BALANCE_TYPES]}
                            clubId={data.id}
                            customClass="step-6"
                        />
                    )}
                </Subsection>
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

ClubsGeneral.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" />
                    <Skeleton.Form type="input" className="margin-bottom-small" />
                    <Skeleton.Chips quantity={2} className="margin-bottom-small" />
                    <Skeleton.Form type="input" smallInput />
                    <Skeleton.Form type="checkbox" />
                </Grid.Column>
            </Grid>
        </>
    );
};

ClubsGeneral.defaultProps = { name: '' };

ClubsGeneral.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    state     : PropTypes.shape({}).isRequired,
    sections  : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    name      : PropTypes.string,
};

export default withRequest(ClubsGeneral);
