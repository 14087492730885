import React, { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import Skeleton from 'Components/Skeletons';
import Loading from 'Components/Loading';
import Table from 'Components/Table';
import Grid from 'Components/Grid';
import Alert from 'Components/Alert';
import FileContent from './FileContent';
import translated from 'Constants/labels/translated';
import Resource from 'Classes/Resource';
import { sectionType } from 'constants/types';

function FileManager({ performRequest, filterLinks, filterBy, sections }) {
    const intl = useIntl();
    const { snackbar, navigator } = useContext(PanelContext);

    const [isFetching, setIsFetching] = useState();
    const [isFiltering, setIsFiltering] = useState();

    const [appliedFilters, setAppliedFilters] = useState({});

    const [selectedFile, setSelectedFile] = useState();
    const [availableFiles, setAvailableFiles] = useState({});

    const getFilesList = useCallback(
        async (link, cleanSelectedFile) => {
            if (cleanSelectedFile) {
                // We clean the previously chosen file.
                setSelectedFile(null);
            }

            try {
                if (link) {
                    setIsFiltering(true);
                } else {
                    setIsFetching(true);
                }

                let reqConfig;
                if (!link) {
                    const resourceForFilter = new Resource({ config: { ...filterLinks.query } });
                    reqConfig = resourceForFilter.getLinkAndUrlWithQueryParams({ fields: filterLinks.fields, values: filterBy });
                } else {
                    reqConfig = link;
                }

                const fileResponse = await performRequest(reqConfig);

                if (fileResponse) {
                    setAvailableFiles(fileResponse);
                }
            } catch (e) {
                snackbar.show({
                    content : translated.imports.fileManagerError,
                    isError : true,
                });
            }

            if (link) {
                setIsFiltering(false);
            } else {
                setIsFetching(false);
            }
        },
        [performRequest, filterLinks.query, filterLinks.fields, filterBy, snackbar],
    );

    const cleanFileSection = async () => {
        if (selectedFile && sections[sectionType.OWNERS_IMPORT_CSV]) {
            // We clean the previously chosen file.
            setSelectedFile(null);

            // We clean the csv and the clients section manually data.
            // eslint-disable-next-line max-len
            await navigator.setSectionData([...sections[sectionType.OWNERS_IMPORT_CSV].pathForResource, sectionType.OWNERS_IMPORT_CSV_CLIENTS], { data: null });
            await navigator.setSectionData([...sections[sectionType.OWNERS_IMPORT_CSV].pathForResource, sectionType.ENTITY_TAGS], { data: null });
            await navigator.setSectionData(sections[sectionType.OWNERS_IMPORT_CSV].pathForResource, { data: null });
        }
    };

    useEffect(() => {
        cleanFileSection();
        getFilesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterLinks]);

    if (isFetching) {
        return <FileManager.Loading />;
    }

    if (!availableFiles?.data?.length && !Object.keys(appliedFilters).length) {
        return <Alert id="files-empty" content={translated.imports.noFiles} />;
    }

    return (
        <>
            {isFiltering && <Loading />}
            {
                <Grid>
                    <Grid.Column width={{ base: 12, small: 4, medium: 4 }}>
                        <Table
                            className="csv-table"
                            id="file-list"
                            key="file-list"
                            hasTools
                            columns={[
                                {
                                    title     : translated.imports.filename,
                                    key       : 'filename',
                                    isVisible : true,
                                },
                            ]}
                            rows={{
                                actions: [
                                    {
                                        tooltip  : translated.imports.openFile,
                                        callback : async (csv) => {
                                            await cleanFileSection();
                                            setSelectedFile(csv);
                                        },
                                        when              : (file) => file?.links?.self?.read,
                                        key               : 'create',
                                        icon              : 'FileEye',
                                        isOutsideDropdown : true,
                                    },
                                ],
                            }}
                            items={availableFiles?.data?.map((f) => ({ ...f, className: f.id === selectedFile?.id ? 'is-selected' : '' })) || []}
                            onPaginationClick={getFilesList}
                            pagination={availableFiles?.pagination}
                            order={availableFiles?.order}
                            isPaginationSmall
                            onApplyFilter={(link, config) => {
                                cleanFileSection();
                                getFilesList(link);
                                setAppliedFilters(config?.appliedFilters);
                            }}
                            appliedFilters={appliedFilters}
                            filterResources={{
                                filters: [
                                    {
                                        type        : 'text',
                                        submitKey   : 'name',
                                        resourceKey : 'name',
                                        label       : intl.formatMessage({
                                            id             : translated.imports.filename,
                                            defaultMessage : translated.imports.filename,
                                        }),
                                    },
                                    {
                                        id               : 'file-filter-tags',
                                        type             : 'search',
                                        submitKey        : 'slug',
                                        resourceKey      : 'slug',
                                        onChangeKey      : 'slug',
                                        label            : translated.global.tags,
                                        showLoading      : true,
                                        searchValidation : (newValue) => newValue && newValue.length > 2,
                                        searchFunction   : async (tagName) => {
                                            // eslint-disable-next-line max-len
                                            const tags = filterLinks?.additionalResources?.tags?.map((tag) => ({ ...tag, lower: tag.label.toLowerCase() })) || [];
                                            const lowerTag = tagName.toLowerCase();
                                            const found = tags.filter((tag) => tag.lower.includes(lowerTag));

                                            return found?.map((tag) => ({ ...tag, display: tag.label }));
                                        },
                                        renderSearch: (tag) => {
                                            if (!tag) {
                                                return (
                                                    <span className="no-results">
                                                        <FormattedMessage defaultMessage={translated.tags.empty} id={translated.tags.empty} />
                                                    </span>
                                                );
                                            }

                                            return <span className="result-user-name">{`${tag.label}`}</span>;
                                        },
                                    },
                                ],
                                filterLinks: availableFiles.filter,
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={{ base: 12, small: 8, medium: 8 }}>
                        {selectedFile?.links?.self && (
                            <FileContent
                                {...sections[sectionType.OWNERS_IMPORT_CSV]}
                                resources={{
                                    ...sections[sectionType.OWNERS_IMPORT_CSV].resources,
                                    available: selectedFile.links.self,
                                }}
                                reloadList={getFilesList}
                            />
                        )}
                    </Grid.Column>
                </Grid>
            }
        </>
    );
}

FileManager.Loading = function LoadingSkeleton() {
    return (
        <Grid>
            <Grid.Column width={{ base: 12, small: 4, medium: 4 }}>
                <Skeleton.Card unelevated hasPicture={false} hasActions={false} hasDescription={{ paragraphs: 15 }} />
            </Grid.Column>
            <Grid.Column width={{ base: 12, small: 8, medium: 8 }}>
                <Skeleton.Card unelevated hasTitle hasPicture={false} hasActions={false} hasDescription={{ paragraphs: 15 }} />
            </Grid.Column>
        </Grid>
    );
};

FileManager.defaultProps = { avoidFetchingFiles: false, sections: {} };

FileManager.propTypes = {
    filterLinks            : PropTypes.shape({}).isRequired,
    filterBy               : PropTypes.shape({}).isRequired,
    onCompletedFieldClick  : PropTypes.func.isRequired,
    onIncompleteFieldClick : PropTypes.func.isRequired,
    performRequest         : PropTypes.func.isRequired,
    avoidFetchingFiles     : PropTypes.bool,
    sections               : PropTypes.shape({}),
};

export default FileManager;
