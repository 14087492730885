module.exports = Object.freeze({
    title                : 'apf.bookings.title',
    hotel                : 'apf.bookings.hotel',
    status               : 'apf.bookings.status',
    bookingCode          : 'apf.bookings.bookingCode',
    bookingDate          : 'apf.bookings.bookingDate',
    checkIn              : 'apf.bookings.checkIn',
    checkOut             : 'apf.bookings.checkOut',
    contract             : 'apf.bookings.contract',
    reservedRooms        : 'apf.bookings.reservedRooms',
    userEmptyBookings    : 'apf.bookings.userEmptyBookings',
    generalEmptyBookings : 'apf.bookings.generalEmptyBookings',
    guest                : 'apf.bookings.guest',
    filterSupportText    : 'apf.bookings.filterSupportText',
    text                 : 'apf.bookings.text',
    actions              : {
        cancel          : 'apf.bookings.actions.cancel',
        modify          : 'apf.bookings.actions.modify',
        chargeAndRefund : 'apf.bookings.actions.chargeAndRefund',
    },
    modifications: {
        checking          : 'apf.bookings.modifications.checking',
        cantModify        : 'apf.bookings.modifications.cantModify',
        cantModifyDetails : 'apf.bookings.modifications.cantModifyDetails',
    },
    errors: {
        getBookingError             : 'apf.bookings.errors.getBookingError',
        getModify                   : 'apf.bookings.errors.getModify',
        updateError                 : 'apf.bookings.errors.updateError',
        errorFundsAmount            : 'apf.bookings.errors.errorFundsAmount',
        noArmnetId                  : 'apf.bookings.errors.noArmnetId',
        clientHasDebt               : 'apf.bookings.errors.clientHasDebt',
        incompatibleBalanceCurrency : 'apf.bookings.errors.incompatibleBalanceCurrency',
    },
    cancellation: {
        title                    : 'apf.bookings.cancellation.title',
        notCancellable           : 'apf.bookings.cancellation.notCancellable',
        cancellableWithRefound   : 'apf.bookings.cancellation.cancellableWithRefound',
        cancellableWithNoRefound : 'apf.bookings.cancellation.cancellableWithNoRefound',
        unknownState             : 'apf.bookings.cancellation.unknownState',
    },
});
