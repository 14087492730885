import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType, resourceNames } from 'Constants/types';
import translated from 'Constants/labels/translated';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import withRequest from 'Components/Sections/withRequest';
import { clientStatus } from 'Constants/global';
import { fetchingShape } from 'Constants/PropTypes';
import Status from 'Components/Status';
import Alert from 'Components/Alert';

const getColumns = [
    {
        title       : translated.global.name,
        key         : 'company',
        isVisible   : true,
        hasMaxWidth : true,
    },
    {
        title                : translated.global.status,
        key                  : 'status',
        isVisible            : true,
        customFieldComponent : (partner) => <Status status={partner.status} highlightedAttributes={partner.highlightedAttributes} />,
    },
    {
        title     : translated.global.id,
        key       : 'legacyId',
        isVisible : true,
        isNumeric : true,
    },
    {
        title           : translated.global.tags,
        key             : 'tagNames',
        isVisible       : true,
        showTooltipWhen : 80,
        customClassName : 'text-overflow',
    },
];

const getActions = (config, navigator, handleClickChangeStatus) => {
    let actions = [
        {
            content   : translated.owners.viewDetails,
            key       : 'submenu',
            isSubmenu : true,
            actions   : [],
        },
    ];

    const submenu = actions[0].actions;

    Object.keys(config).forEach((key) => {
        switch (key) {
            case sectionType.PARTNERS_INFO:
                submenu.push({
                    ...config[key],
                    content  : translated.sectionName.info,
                    callback : (params) => navigator.goToPartnerInfo(params),
                    key      : 'info',
                    when     : (partner) => partner?.links?.self?.read,
                });
                break;

            case sectionType.BOOKINGS:
                submenu.push({
                    ...config[key],
                    content  : translated.sectionName.bookings,
                    callback : (params) => navigator.goToPartnerBookings(params),
                    key      : 'bookings',
                    when     : (partner) => partner?.links?.bookings?.read,
                });
                break;

            case sectionType.WALLET:
                submenu.push({
                    ...config[key],
                    content  : translated.sectionName.wallet,
                    callback : (params) => navigator.goToPartnerBalances(params),
                    key      : 'balance',
                    when     : (partner) => partner?.links?.wallet?.read,
                });
                break;

            case sectionType.NOTES:
                submenu.push({
                    ...config[key],
                    content  : translated.sectionName.notes,
                    callback : (params) => navigator.goToPartnerNotes(params),
                    key      : 'notes',
                    when     : (partner) => partner?.links?.notes?.read,
                });
                break;

            case sectionType.PARTNERS_CONTRACTS:
                submenu.push({
                    ...config[key],
                    key      : 'contract',
                    content  : translated.sectionName.contracts,
                    callback : (params) => navigator.goToPartnerContracts(params),
                    when     : (partner) => partner?.links?.contracts?.read,
                });
                break;

            case sectionType.USERS:
                submenu.push({
                    ...config[key],
                    key      : 'users',
                    content  : translated.sectionName.users,
                    callback : (params) => navigator.goToPartnerUsers(params),
                    when     : (partner) => partner?.links?.[resourceNames.USERS]?.read,
                });
                break;

            case sectionType.PARTNERS_EDITOR:
                const { isEnabled } = config[key];
                actions = [
                    ...actions,
                    { isDivider: true, key: 'divider' },
                    {
                        content  : translated.global.buttons.disable,
                        callback : (partner) => handleClickChangeStatus(partner, clientStatus.SUSPENDED),
                        key      : 'disable',
                        when     : (partner) => partner?.links?.self?.patch && partner.status === clientStatus.ACTIVE,
                    },
                    {
                        content  : translated.global.buttons.enable,
                        callback : (partner) => handleClickChangeStatus(partner, clientStatus.ACTIVE),
                        key      : 'enable',
                        when     : (partner) => partner?.links?.self?.patch && partner.status === clientStatus.SUSPENDED,
                    },

                    {
                        content           : translated.global.buttons.edit,
                        callback          : (params) => navigator.goToPartnerEditor({ available: params?.links?.self, current: 'read' }),
                        key               : 'edit',
                        icon              : 'Pencil',
                        tooltip           : translated.global.buttons.edit,
                        isEnabled,
                        when              : (partner) => partner?.links?.self?.update,
                        isOutsideDropdown : true,
                    },
                ];
                break;

            default:
                break;
        }
    });

    return actions;
};

function List({ data, reloadData, resources, fetching, setSectionState, state: { appliedFilters } }) {
    const { sharedConfigurations, navigator, snackbar } = useContext(PanelContext);

    const { order, data: partners, pagination, links, filter: filterLinks } = data || {};

    // The order of the array determines the order of the columns
    const columns = getColumns;

    const handleOnAddClick = () => {
        navigator.goToPartnerEditor({ available: links?.self || {}, current: 'init' });
    };

    const sectionButtons = [];

    if (links?.self?.create) {
        sectionButtons.push(
            <Button id="partners-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                <FormattedMessage defaultMessage={translated.global.buttons.add} id={translated.global.buttons.add} />
            </Button>,
        );
    }

    const handleClickChangeStatus = async (partner, newStatus) => {
        const reqConfig = {
            ...partner.links.self.patch,
            data             : { status: newStatus },
            isCritical       : false,
            isGlobal         : false,
            ids              : [partner.id],
            shouldReloadData : true,
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.PARTNERS, resources });

            snackbar.show({
                content   : newStatus === clientStatus.ACTIVE ? translated.partners.enable.success : translated.partners.disable.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                error,
                content : newStatus === clientStatus.ACTIVE ? translated.partners.enable.error : translated.partners.disable.error,
                isError : true,
            });
        }
    };

    const rows = { actions: getActions(sharedConfigurations.partners, navigator, handleClickChangeStatus) };

    const items = !partners
        ? []
        : partners.map((partner) => ({
            ...partner,
            tagNames: partner.tags
                ?.map((e) => e.label)
                .sort()
                .join(', '),
        }));

    return (
        <Wrapper title={translated.sectionName.partners} actionButtons={sectionButtons}>
            <Table
                id="partners"
                key="partners"
                columns={columns}
                rows={rows}
                items={items}
                onSearch={() => {
                    // Default
                }}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                currentSortOrder="legacyId"
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="partners-empty"
                        content={translated.partners.noPartners}
                        actionText={translated.global.buttons.add}
                        onClick={handleOnAddClick}
                    />
                )}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                filterResources={{
                    filters: [
                        {
                            type        : 'text',
                            submitKey   : 'company',
                            resourceKey : 'company',
                            label       : translated.global.name,
                        },
                        {
                            id               : 'partners-filter-tags',
                            type             : 'search',
                            submitKey        : 'slug',
                            resourceKey      : 'slug',
                            onChangeKey      : 'slug',
                            label            : translated.global.tags,
                            showLoading      : true,
                            searchValidation : (newValue) => newValue && newValue.length > 2,
                            searchFunction   : async (tagName) => {
                                const tags = filterLinks?.additionalResources?.tags?.map((tag) => ({ ...tag, lower: tag.label.toLowerCase() })) || [];
                                const lowerTag = tagName.toLowerCase();
                                const found = tags.filter((tag) => tag.lower.includes(lowerTag));

                                return found?.map((tag) => ({ ...tag, display: tag.label }));
                            },
                            renderSearch: (tag) => {
                                if (!tag) {
                                    return (
                                        <span className="no-results">
                                            <FormattedMessage defaultMessage={translated.tags.empty} id={translated.tags.empty} />
                                        </span>
                                    );
                                }

                                return <span className="result-user-name">{`${tag.label}`}</span>;
                            },
                        },
                    ],
                    filterLinks,
                    resources,
                }}
            />
        </Wrapper>
    );
}

List.defaultProps = {
    setSectionState: () => {
        // Default
    },
    state: {},
};

List.propTypes = {
    data: PropTypes.shape({
        data  : PropTypes.arrayOf(PropTypes.shape({})),
        order : PropTypes.shape({ orderedBy: PropTypes.shape({}) }),
    }).isRequired,
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    fetching        : fetchingShape.isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={180} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
