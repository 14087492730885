import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import Tooltip from 'Components/Tooltip';
import { useIntl } from 'react-intl';
import { getMessage } from 'Components/WrappedFormattedMessage';
import getClassName from 'Utils/getClassName';
import { getVariantClass, getColorClass, getSizeClass, getIconVariant } from './utils';

const BUTTON_TIMER = 1000;

function Button(props) {
    const intl = useIntl();

    const {
        icon,
        iconSpin = false,
        size,
        variant,
        color,
        onClick,
        id,
        className,
        disabled,
        children,
        tooltip,
        forwardedRef,
        isLoading,
        isMultiClickEnabled,
        showTooltipDisabled,
        ignoreTimer,
        expanded,
    } = props;

    const tooltipMessage = useMemo(() => getMessage(intl, tooltip), [intl, tooltip]);

    const timerRef = useRef(null);

    const iconVariant = getIconVariant(icon, children);

    const { sizeClass, iconSize, iconSizeLabel } = getSizeClass(size);
    const buttonIconSize = (children !== '' ? iconSizeLabel : iconSize);

    const isDisabled = disabled || isLoading;
    const mustShowTooltip = tooltip && showTooltipDisabled;

    const buttonClassName = getClassName({ isExpanded: expanded, isLoading, disabledTooltip: (isDisabled && mustShowTooltip) }, getVariantClass(variant), getColorClass(color), sizeClass, iconVariant, 'button', className);

    const tooltipId = Tooltip.generateId();

    const buttonOnClick = (...params) => {
        // Adds a little timeout to the click action, so the user cant trigger the action several times.
        if (isDisabled || !onClick || timerRef.current) {
            return;
        }

        if (!isMultiClickEnabled) {
            timerRef.current = setTimeout(async () => {
                timerRef.current = null;
            }, ignoreTimer ? 100 : BUTTON_TIMER);
        }

        const { currentTarget } = params[0];
        const buttonClicked = new CustomEvent('ButtonClicked', {
            bubbles    : true,
            cancelable : true,
        });
        currentTarget.dispatchEvent(buttonClicked);

        onClick(...params);
    };

    return (
        <a
            id={id}
            ref={forwardedRef}
            className={buttonClassName}
            onClick={buttonOnClick}
            disabled={mustShowTooltip ? false : isDisabled}
            data-tip={tooltipMessage}
            data-for={tooltipId}
        >
            {tooltip && !isLoading && <Tooltip id={tooltipId} disable={disabled} />}
            {icon && !isLoading && <Icon className="mdi-icon" spin={iconSpin} path={IconPath[`mdi${icon}`]} size={buttonIconSize} />}
            {isLoading && <Icon spin className="mdi-icon" path={IconPath.mdiLoading} size="20px" />}
            {iconVariant !== 'button-icon' && !isLoading && children}
        </a>
    );
}

Button.defaultProps = {
    disabled            : false,
    size                : 'medium',
    iconSpin            : false,
    onClick             : null,
    id                  : '',
    icon                : '',
    color               : '',
    variant             : '',
    className           : '',
    tooltip             : '',
    children            : '',
    forwardedRef        : null,
    isLoading           : false,
    isMultiClickEnabled : false,
    showTooltipDisabled : false,
    ignoreTimer         : undefined,
    expanded            : false,
};

Button.propTypes = {
    id                  : PropTypes.string,
    disabled            : PropTypes.bool,
    size                : PropTypes.string,
    iconSpin            : PropTypes.bool,
    icon                : PropTypes.string,
    color               : PropTypes.string,
    className           : PropTypes.string,
    tooltip             : PropTypes.string,
    children            : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant             : PropTypes.string,
    onClick             : PropTypes.func,
    forwardedRef        : PropTypes.shape({}),
    isLoading           : PropTypes.bool,
    isMultiClickEnabled : PropTypes.bool,
    showTooltipDisabled : PropTypes.bool,
    ignoreTimer         : PropTypes.bool,
    expanded            : PropTypes.bool,
};

export default Button;
