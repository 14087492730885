import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Chips from 'Components/Chips';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';

function findOption(options, val) {
    let index;
    if (val && typeof options[0].value === 'object') {
        const auxIndx = options.findIndex((o) => `${o.value.id}` === val);
        if (options[auxIndx].value.id === undefined) {
            // An ID is necessary in select`s with objects as values
        }
        index = auxIndx;
    } else {
        index = options.findIndex((opt) => `${opt.value}` === `${val}`);
    }
    return { index, option: options[index] };
}

function Select({
    value: selectedValue,
    label,
    options,
    onChange,
    onFocus,
    onBlur,
    isReadOnlyWithoutInputs,
    isMultiSelect,
    isDisabled,
    id,
    displayOptionsAmount,
    sortBy,
    withoutDefaultOption,
    defaultOptionText,
    innerInputRef,
    optionParentLabel,
    isBasedOnState,
}) {
    const intl = useIntl();
    const isValueAnArray = Array.isArray(selectedValue);

    const getInitiallySelectedOptions = useCallback(() => {
        let initiallySelectedOptions;
        if (isValueAnArray) {
            initiallySelectedOptions = options.map((opt) => !!selectedValue.find((selVal) => selVal.id && selVal.id === opt.value.id));
        } else if (typeof selectedValue === 'object' && selectedValue !== null) {
            initiallySelectedOptions = options.map((opt) => opt.id === selectedValue.id);
        } else {
            initiallySelectedOptions = Array(options.length).fill(false);
        }
        return initiallySelectedOptions;
    }, [isValueAnArray, options, selectedValue]);

    const [selectedOptions, setSelectedOptions] = useState(getInitiallySelectedOptions());

    useEffect(() => {
        if (isBasedOnState) {
            setSelectedOptions(getInitiallySelectedOptions());
        }
    }, [getInitiallySelectedOptions, isBasedOnState, options, selectedValue]);

    const selectOptionText = defaultOptionText || translated.global.selectOption;

    const getOption = useCallback(
        ({ value, disabled, key, content }) => (
            <option value={value} disabled={disabled} key={key}>
                {getMessage(intl, content)}
            </option>
        ),
        [intl],
    );

    const setSelectedOption = (index, option) => {
        let newSelectedOptions;
        if (isMultiSelect) {
            newSelectedOptions = [...selectedOptions];
            newSelectedOptions[index] = !newSelectedOptions[index];
            setSelectedOptions(newSelectedOptions);
        }

        if (option.callback) {
            option.callback({
                ...option,
                isSelected: isMultiSelect ? newSelectedOptions[index] : option.value,
            });
        }

        if (onChange) {
            let currentOptions;
            if (isMultiSelect) {
                currentOptions = options.reduce((prev, curr, i) => (newSelectedOptions[i] ? [...prev, curr.value] : prev), []);
                currentOptions = !currentOptions.length ? [] : currentOptions;
            } else {
                currentOptions = option.value;
            }
            onChange({ target: { value: currentOptions } });
        }
    };

    const handlerOnChange = (e) => {
        const { option, index } = e.target.value === '__default' ? undefined : findOption(options, e.target.value);
        setSelectedOption(index, option);
    };

    // Search selected option to show when 'isReadOnlyWithoutInputs'
    let contentOfSelectedOptions;
    if (isReadOnlyWithoutInputs && options && options.length && selectedValue) {
        if (isValueAnArray) {
            // eslint-disable-next-line max-len
            contentOfSelectedOptions = selectedValue.map((selectedOption) => (!selectedOption ? selectOptionText : findOption(options, selectedValue).option));
        } else {
            contentOfSelectedOptions = !selectedValue ? [selectOptionText] : [findOption(options, selectedValue).option];
        }
    }

    let optionList = options ? [...options] : [];
    // Check if the list must be sorted and the element have the field for which it will be sorted.
    if (sortBy && optionList.length && optionList[0][sortBy]) {
        optionList.sort((a, b) => `${a[sortBy]}`.localeCompare(b[sortBy]));
    }

    // When the selection can be multiple, the first element is shown as selected and the others as disabled.
    let realValue;
    if (isMultiSelect) {
        realValue = '__default';
    } else if (isValueAnArray) {
        realValue = selectedValue[0].id;
    } else {
        realValue = typeof selectedValue === 'object' && selectedValue != null ? selectedValue.id : selectedValue;

        // When the value received is invalid (there is no options with the element) it shows the 'default' value.
        const isValid = optionList.find((e) => (typeof e === 'object' ? String(e.value) === String(realValue) : String(e) === String(realValue)));
        realValue = isValid ? realValue : '__default';
    }

    optionList = optionList.map((option, index) => {
        const { key = `divider_${index}`, value, content, isDisabled: isOptionDisabled, parentId } = option;

        return {
            value    : typeof value === 'object' ? value.id : value,
            disabled : selectedOptions[index] || isOptionDisabled,
            parentId,
            key,
            content,
        };
    });

    return (
        <>
            {!isReadOnlyWithoutInputs && (
                <select
                    id={id}
                    onChange={handlerOnChange}
                    value={realValue || '__default'}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={isDisabled ? '-1' : '0'}
                    size={displayOptionsAmount}
                    className={displayOptionsAmount ? 'is-multi-line' : null}
                    ref={innerInputRef}
                >
                    {!withoutDefaultOption && (
                        <option disabled value="__default" key="select_option" className="select-default-option">
                            {getMessage(intl, selectOptionText)}
                        </option>
                    )}
                    {optionList
                        .filter((e) => !e.parentId)
                        .map((option) => {
                            const children = optionList.filter((e) => e.parentId && option.value === e.parentId);

                            if (!children.length) {
                                return getOption(option);
                            }

                            return (
                                <>
                                    {getOption(option)}
                                    <optgroup label={optionParentLabel || option.content}>{children.map((child) => getOption(child))}</optgroup>
                                </>
                            );
                        })}
                </select>
            )}
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {isReadOnlyWithoutInputs
                && contentOfSelectedOptions
                && contentOfSelectedOptions.map((selectedOption) => <span>{(selectedOption && selectedOption.content) || selectOptionText}</span>)}
            {isMultiSelect && selectedOptions.some((opt) => opt) && (
                <Chips
                    outlined
                    list={options
                        .map((opt, auxIndex) => ({ ...opt, auxIndex }))
                        .filter((opt, index) => selectedOptions[index])
                        .map((opt) => ({
                            onClose : opt.isDisabled ? null : () => setSelectedOption(opt.auxIndex, opt),
                            text    : opt.content,
                            key     : opt.key,
                        }))}
                />
            )}
        </>
    );
}

Select.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    onChange                : null,
    options                 : [],
    isReadOnlyWithoutInputs : false,
    isMultiSelect           : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled           : false,
    displayOptionsAmount : null,
    sortBy               : null,
    withoutDefaultOption : false,
    defaultOptionText    : '',
    innerInputRef        : null,
    optionParentLabel    : '',
    isBasedOnState       : false,
};

Select.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({ id: PropTypes.string.isRequired })]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    options                 : PropTypes.arrayOf(
        PropTypes.shape({
            content   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            isEnabled : PropTypes.bool,
            callback  : PropTypes.func,
        }),
    ),
    isDisabled           : PropTypes.bool,
    displayOptionsAmount : PropTypes.number,
    sortBy               : PropTypes.string,
    withoutDefaultOption : PropTypes.bool,
    defaultOptionText    : PropTypes.string,
    innerInputRef        : PropTypes.shape({}),
    optionParentLabel    : PropTypes.string,
    isBasedOnState       : PropTypes.bool,
};

export default React.memo(Select);
