import { pageStates } from 'Constants/qa';

const getBody = () => document.querySelector('body');

function setAttribute(element, value, attribute) {
    if (element?.setAttribute) {
        element.setAttribute(attribute, value);
    }
}

export const initialize = () => {
    setAttribute(getBody(), true, 'data-test-app-loaded');
};

export const updatePageStatus = (isLoading) => {
    setAttribute(getBody(), isLoading ? pageStates.loading : pageStates.loaded, 'data-test-page-status');
};

export const updateLastAction = (value) => {
    setAttribute(getBody(), value, 'data-test-action');
};
