import React from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';

function Divider({ className, itemKey }) {
    const dropdownItemClass = getClassName({}, 'dropdown-menu-item-divider', className);

    return <li className={dropdownItemClass} key={itemKey} />;
}

Divider.defaultProps = { className: '' };

Divider.propTypes = {
    className : PropTypes.string,
    itemKey   : PropTypes.string.isRequired,
};
export default Divider;
