
import React, { useMemo, useContext } from 'react';
import Form from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import labels from 'Constants/labels';
import { clubBalanceTypes as balanceTypes } from 'Constants/translator';
import PanelContext from 'State/panelContext';
import FormError from 'Components/Form/FormError';

function WalletAddBalance({ onClose, resources, data }) {
    const { navigator } = useContext(PanelContext);

    const { available } = resources;

    const { clubPointsAvailable = [] } = data?.additionalResources || {};

    // Use of Memo avoid re-renders on Form.Wrapper
    const modalPropertiesForForm = useMemo(() => ({
        title   : labels.owners.walletAddBalance.title,
        buttons : [
            <Form.Secondary variant="text" color="primary" key="fs" onClick={onClose}>{labels.global.buttons.cancel}</Form.Secondary>,
            <Form.Primary variant="text" color="primary" key="fp" isEnabled ignoreChanges>{labels.global.buttons.add}</Form.Primary>,
        ],
    }), [onClose]);

    const handleOnSubmit = async ({ values }) => {
        try {
            await navigator.requestForCurrentPath({
                reqConfig: {
                    data: values,
                    ...available.create,
                },
            });
        } catch (e) {
            throw new FormError(labels.owners.walletAddBalance.errors.default, e);
        }
    };

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <p>{labels.owners.walletAddBalance.warning}</p>
            <Form
                onSubmit={handleOnSubmit}
                onFinish={onClose}
                avoidConfirmation
                initialValues={{ point: clubPointsAvailable[0] }}
                forceValidationsOnFirstRender
            >
                <Form.Group
                    type="radio"
                    submitKey="point"
                    name="point"
                    avoidOnSummary
                    label={labels.owners.walletAddBalance.typeLabel}
                    isRequired
                >
                    <Form.Input
                        value={balanceTypes.PREV}
                        label={labels.owners.walletAddBalance.types.prev}
                        isDisabled={clubPointsAvailable.indexOf(balanceTypes.PREV) === -1}
                    />
                    <Form.Input
                        value={balanceTypes.CURRENT}
                        label={labels.owners.walletAddBalance.types.current}
                        isDisabled={clubPointsAvailable.indexOf(balanceTypes.CURRENT) === -1}
                    />
                    <Form.Input
                        value={balanceTypes.NEXT}
                        label={labels.owners.walletAddBalance.types.next}
                        isDisabled={clubPointsAvailable.indexOf(balanceTypes.NEXT) === -1}
                    />
                </Form.Group>
            </Form>
        </Form.Wrapper>
    );
}

WalletAddBalance.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default withRequest(WalletAddBalance);
