import useErrorState from './useErrorState';
import { shouldRetry } from './utils';

export default function useErrorHandler(error, shouldAvoidRetry = false, callback, handledErrors = null) {
    const [errorState, tryAgain, resetCount, stopRetrying] = useErrorState(shouldRetry(error) ? 4 : 0, 2000);

    if (!error) {
        return [true, {
            isTrying        : errorState.isWaitingToTryAgain,
            hasReachedLimit : errorState.hasReachedLimit,
        }, resetCount, stopRetrying];
    }

    const { isCritical, isTimeout, error: requestError } = error;

    // Checks if the section handle errors
    if (
        // The section handles request status errors
        (handledErrors?.status?.length && requestError?.status && handledErrors.status.find((each) => each === requestError.status))
        // The section handles response code errors
        || (handledErrors?.code?.length && requestError?.errors?.[0]?.code
            && handledErrors.code.find((each) => each === String(requestError.errors[0].code)))
    ) {
        return [false, { isTrying: false, hasReachedLimit: true, isTimeout, isCritical, error: requestError }, resetCount, stopRetrying];
    }

    if ((isCritical || isTimeout) && !errorState.hasReachedLimit && !errorState.isWaitingToTryAgain) {
        if (!shouldAvoidRetry) {
            tryAgain(callback);
        }
        return [false, { isTrying: true, isTimeout, isCritical, error: requestError }, resetCount, stopRetrying];
    }

    return [true, {
        isTrying        : errorState.isWaitingToTryAgain,
        hasReachedLimit : errorState.hasReachedLimit,
        error           : requestError,
        isTimeout,
        isCritical,
    }, resetCount, stopRetrying];
}
