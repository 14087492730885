import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';

function SectionBlocker({ children, isBlocked, onRetry }) {
    const intl = useIntl();

    return (
        <>
            {onRetry && (
                <div className="error-reload animated fade-in-down fastest">
                    <Button
                        id="reload-button"
                        icon="Reload"
                        color="grey"
                        tooltip={intl.formatMessage({
                            id             : translated.global.buttons.reload,
                            defaultMessage : translated.global.buttons.reload,
                        })}
                        onClick={onRetry}
                    />
                </div>
            )}
            <div className={`subsection ${isBlocked ? 'section-blocked' : ''}`}>{children}</div>
        </>
    );
}

SectionBlocker.defaultProps = {
    isBlocked : false,
    onRetry   : null,
};

SectionBlocker.propTypes = {
    children  : PropTypes.node.isRequired,
    isBlocked : PropTypes.bool,
    onRetry   : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default SectionBlocker;
