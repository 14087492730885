module.exports = Object.freeze({
    title     : 'Categories',
    emptyList : 'There are no categories',
    addChild  : 'Add child',
    noParent  : 'None',

    editTitle   : 'Edit category',
    createTitle : 'Create category',
    save        : {
        success : 'The category has been added',
        error   : 'The category could not be updated',
    },
    update: {
        success : 'The category has been updated',
        error   : 'The category could not be updated',
    },

    name       : 'Name',
    parentName : 'Parent name',

    enable: {
        success : 'The category has been enabled',
        error   : 'The category could not be enabled',
    },
    disable: {
        success : 'The category has been disabled',
        error   : 'The category could not be disabled',
    },
    delete: {
        confirmation               : 'Delete category?',
        success                    : 'The category has been removed',
        error                      : 'The category could not be removed',
        errorEntityCannotBeDeleted : 'This category could not be removed since it is being used',
        defaultError               : 'The category could not be removed',
    },
});
