/* eslint-disable comma-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import LocaleContext from 'State/localeContext';
import { LANGUAGE_SWITCH } from 'Constants/global';
import en from 'Static/langs/en.json';
import es from 'Static/langs/es.json';
import countriesToCurrenciesMapping from './countriesToCurrenciesMapping';

/**
 * Locale Provider
 *
 * Provides the capability of access to localized strings and a price formatting tool
 * according to the browser language
 *
 * Current country and currency codes are in the countriesToCurrenciesMapping module
 */

// English language is in the main bundle by default
const defaultMessages = {
    es,
    en,
    dummy: {},
};

function LocaleProvider({ children, defaultLanguage, setLanguage, refresh }) {
    const [messages] = useState(defaultMessages);
    const [currentLocale, setCurrentLocale] = useState();
    const [priceFormatter, setPriceFormatter] = useState();
    const [currentMessages, setCurrentMessages] = useState();

    const setLocale = useCallback(async (language) => {
        setCurrentLocale(language);
        await setLanguage(language);
        refresh(LANGUAGE_SWITCH);
    }, [setLanguage, refresh]);

    useEffect(() => {
        const userLocale = defaultLanguage;
        setCurrentLocale(userLocale);
        setCurrentMessages(defaultMessages[userLocale]);

        const userCurrency = countriesToCurrenciesMapping[userLocale.toUpperCase()] || countriesToCurrenciesMapping.EN;
        const aPriceFormatter = (price, currency) => Intl.NumberFormat(userLocale, { style: 'currency', currency: currency || userCurrency }).format(price);
        setPriceFormatter(aPriceFormatter);
    }, [defaultLanguage]);

    useEffect(() => {
        if (currentLocale) {
            setCurrentMessages(messages[currentLocale]);
        }
    }, [currentLocale, messages]);

    const value = useMemo(() => ({ locale: [currentLocale, setLocale], priceFormatter }), [currentLocale, priceFormatter, setLocale]);

    const handleOnError = useCallback(() => {}, []);

    return (
        <LocaleContext.Provider value={value}>
            <IntlProvider locale={currentLocale} messages={currentMessages} onError={handleOnError}>
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
}

LocaleProvider.defaultProps = { defaultLanguage: 'en' };

LocaleProvider.propTypes = {
    defaultLanguage : PropTypes.string,
    children        : PropTypes.node.isRequired,
    setLanguage     : PropTypes.func.isRequired,
    refresh         : PropTypes.func.isRequired,
};

export default LocaleProvider;
