import React, { useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Form, { FormError, FormNew } from 'Components/Form';
import translated from 'Constants/labels/translated';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import yup from 'Utils/yupHelper';

const schema = yup.object().shape({ name: yup.string().required().max(50) });

const OWNER_TYPE = 'Owner';

function TierEditor({ data, isEditing, resources }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const modalPropertiesForForm = useMemo(
        () => ({
            title   : isEditing ? translated.tiers.editTitle : translated.tiers.createTitle,
            buttons : [
                <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={() => navigator.goToParentAndReload(false, false)}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.SecondaryNew>,
                <Form.PrimaryNew variant="text" color="primary" key="fp">
                    {isEditing ? (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.create} defaultMessage={translated.global.buttons.create} />
                    )}
                </Form.PrimaryNew>,
            ],
        }),
        [isEditing, navigator],
    );

    const handleOnSubmit = async (values) => {
        const reqConfig = {
            data: {
                ...values,
                id         : isEditing ? data.id : undefined,
                clientType : OWNER_TYPE,
            },
            ...resources.available[isEditing ? 'update' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(translated.externalClubs.updateFailed, e);
        }
    };

    const onFinish = () => {
        snackbar.show({ content: isEditing ? translated.tiers.updated : translated.tiers.created, isSuccess: true });

        navigator.goToParentAndReload(false, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialValues = useMemo(() => ({ name: data?.name || '' }), []);

    return (
        <Form.WrapperNew
            modalProperties={modalPropertiesForForm}
            schema={schema}
            initialValues={initialValues}
            formId="tiers-form"
        >
            <FormNew buttonsWidth={{ base: 12, small: 6 }} onSubmit={handleOnSubmit} onFinish={onFinish}>
                <Form.InputNew
                    isDense
                    submitKey="name"
                    type="text"
                    label={translated.global.name}
                    value={data?.name}
                    charCount={{ total: 50 }}
                    key="name"
                />
            </FormNew>
        </Form.WrapperNew>
    );
}

TierEditor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
};

export default withRequest(TierEditor);
