import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import ConfirmationButton from 'Components/ConfirmationButton';
import PanelContext from 'State/panelContext';
import FormContext from './formContext';
import { useFormContext } from 'react-hook-form';

function PrimaryButton({
    id, children, variant, color, isDisabled, collapsed, confirmation, isEnabled, icon, text, className, ignoreChanges, size,
}) {
    const intl = useIntl();
    const { snackbar } = useContext(PanelContext);
    const { formId, formRef } = useContext(FormContext);

    const { formState } = useFormContext() || {};

    const { isDirty = true, isSubmitting = false, errors } = formState || {};

    const submit = () => {
        if (isDisabled || isSubmitting) {
            // The form shouldn't be submitted
            return;
        }

        if (errors && Object.keys(errors)?.length) {
            snackbar.show({
                content : translated.global.errors.formInvalid,
                isError : true,
            });
            return;
        }

        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    };

    const isButtonDisabled = (isDisabled || (!ignoreChanges && !isDirty) || isSubmitting) && !isEnabled;

    if (collapsed || icon) {
        return (
            <ConfirmationButton
                id={id || formId ? `${formId}-primary-button` : null}
                confirmation={confirmation}
                icon={icon || 'ContentSave'}
                tooltip={
                    text
                    || intl.formatMessage({
                        id             : translated.global.buttons.save,
                        defaultMessage : translated.global.buttons.save,
                    })
                }
                onClick={submit}
                disabled={isButtonDisabled}
                isLoading={isSubmitting}
                className={className}
            />
        );
    }

    return (
        <ConfirmationButton
            id={id || formId ? `${formId}-primary-button` : null}
            confirmation={confirmation}
            variant={variant || 'unelevated'}
            color={color}
            onClick={submit}
            disabled={isButtonDisabled}
            isLoading={isSubmitting}
            className={className}
            size={size}
        >
            {children || text || <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />}
        </ConfirmationButton>
    );
}

PrimaryButton.defaultProps = {
    id            : null,
    children      : null,
    variant       : '',
    color         : '',
    icon          : '',
    text          : null,
    className     : '',
    isDisabled    : false,
    isEnabled     : false,
    collapsed     : false,
    confirmation  : null,
    ignoreChanges : false,
    size          : '',
};

PrimaryButton.propTypes = {
    id       : PropTypes.string,
    children : PropTypes.node,
    variant  : PropTypes.string,
    color    : PropTypes.string,
    icon     : PropTypes.string,
    text     : PropTypes.shape({
        title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    className    : PropTypes.string,
    isDisabled   : PropTypes.bool,
    isEnabled    : PropTypes.bool,
    collapsed    : PropTypes.bool,
    confirmation : PropTypes.shape({
        title: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        message: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
    }),
    ignoreChanges : PropTypes.bool,
    size          : PropTypes.string,
};

export default PrimaryButton;
