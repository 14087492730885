module.exports = Object.freeze({
    home: {
        title    : 'Welcome to Capital vacations Admin Panel',
        imageAlt : 'Capital vacations',
    },
    errors: {
        default            : 'The data could not be retrieved',
        agentDisabled      : 'Your account has been disabled. Contact your system administrator for more details',
        agentNotFound      : 'Ensure you have an existing account in Echo System',
        unexpected         : 'There was an unexpected problem, please try again later',
        loggedUser         : 'There is no active session, you need to login',
        noLongerValidToken : 'Your session has expired, you will be redirected to the home page',
    },
});
