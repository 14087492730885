import React, {
    useState, useRef, useImperativeHandle, forwardRef, useEffect, useMemo, useContext,
} from 'react';
import { useDeepEffect } from 'Hooks';
import PropTypes from 'prop-types';
import FormContext from './formContext';
import Modal from 'Components/Modal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import panelContext from 'State/panelContext';

const convertKeyObject = (value) => {
    if (typeof value === 'object' && value != null && !Array.isArray(value)) {
        const converted = {};

        Object.keys(value).forEach((e) => {
            converted[e] = e !== 'ref' ? convertKeyObject(value[e]) : null;
        });

        return converted;
    }

    if (typeof value === 'object' && value != null && Array.isArray(value)) {
        return value.map((e) => convertKeyObject(e));
    }

    return value;
};

const convertErrorsObject = (errors) => {
    // Needed to avoid an error in the useDeepEffect function, related to the error's ref values.
    if (!errors) {
        return {};
    }

    const converted = {};
    Object.keys(errors).forEach((eachKey) => {
        converted[eachKey] = convertKeyObject(errors[eachKey]);
    });
    return converted;
};

/**
 * This wrapper provides a context that will use both the Form and the Primary button, the form will put in
 * context the onSubmit method and the information entered in the inputs and the Primary button will execute
 * the method by sending the info, in turn also provides the possibility of converting the form into a modal.
 */
function Wrapper({
    children,
    modalProperties,
    hasChanged,
    schema, // Validation schema
    initialValues,
    onInputChange, // Method to subscribe to input changes.
    watchFields, // Inputs to which it will subscribe. When null, it subscribes to all fields.
    onValidationChange, // Method to subscribe to validation changes.
    externalRef,
    formId,
    keepValuesOnInputRemove,
}) {
    const [submitData, setSubmitData] = useState(() => (hasChanged ? { hasChanged } : {}));
    const [events, setEvents] = useState({});
    const [contextInitialValues, setContextInitialValues] = useState({});
    const [contextInitialSubmitData, setContextInitialSubmitData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { dateManager } = useContext(panelContext);

    // We dont use the 'defaultValues' keys because on the first render, the inputs don't receive the value, messing our input labels.
    const methods = useForm({
        resolver         : yupResolver(schema),
        mode             : 'onChange',
        shouldFocusError : true,
        shouldUnregister : !keepValuesOnInputRemove,
    });
    const formRef = useRef();

    const newValues = methods.watch(watchFields);

    useDeepEffect(() => {
        if (onInputChange) {
            onInputChange(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValues]);

    useEffect(() => {
        if (methods?.reset) {
            methods.reset(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formErrors = convertErrorsObject(methods.formState?.errors);

    useDeepEffect(() => {
        if (onValidationChange) {
            onValidationChange(formErrors, newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    // Provide to the father the ability to run the validations
    useImperativeHandle(externalRef, () => ({
        setValue: (key, newValue, options = {}) => {
            // Sets a form value
            if (methods?.setValue) {
                methods.setValue(key, newValue, options);
            }
        },
        setError: (field, type, message) => {
            if (methods?.setError) {
                methods.setError(field, { type, message });
            }
        },
        clearErrors: (field) => {
            if (methods?.clearErrors) {
                methods.clearErrors(field);
            }
        },
        trigger: (field) => {
            if (methods?.trigger) {
                // We use a timeout to avoid the action taking place before the form input change (in which case it would show un-updated validations)
                setTimeout(() => {
                    methods.trigger(field);
                }, 100);
            }
        },
        reset: (values) => {
            if (methods?.reset) {
                methods.reset(values);
            }
        },
        element: externalRef,
    }));

    const providerValues = useMemo(() => ({
        isContextInitialized: true,
        submitData,
        setSubmitData,
        events,
        setEvents,
        contextInitialValues,
        setContextInitialValues,
        contextInitialSubmitData,
        setContextInitialSubmitData,
        isSubmitting,
        setIsSubmitting,
        formId,
        formRef,
        dateManager,
    }), [contextInitialSubmitData, contextInitialValues, events, formId, isSubmitting, submitData, dateManager]);

    // When modalProperties is set, it turns the content into a modal
    return (
        <div ref={externalRef}>
            <FormProvider {...methods}>
                <FormContext.Provider value={providerValues}>
                    {modalProperties ? <Modal {...modalProperties}>{children}</Modal> : children}
                </FormContext.Provider>
            </FormProvider>
        </div>
    );
}

Wrapper.defaultProps = {
    modalProperties         : null,
    hasChanged              : false,
    schema                  : {},
    initialValues           : null,
    onInputChange           : null,
    watchFields             : null,
    onValidationChange      : null,
    externalRef             : null,
    keepValuesOnInputRemove : false,
};

Wrapper.propTypes = {
    formId          : PropTypes.string.isRequired,
    hasChanged      : PropTypes.bool,
    children        : PropTypes.node.isRequired,
    modalProperties : PropTypes.shape({
        title       : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        closeButton : PropTypes.shape({
            text      : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            onClick   : PropTypes.func,
            isEnabled : PropTypes.bool,
        }),
        cancelButton: PropTypes.shape({
            text      : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            onClick   : PropTypes.func,
            isEnabled : PropTypes.bool,
        }),
        backdropClick : PropTypes.func,
        isVisible     : PropTypes.bool,
    }),
    schema                  : PropTypes.shape(),
    initialValues           : PropTypes.shape(),
    onInputChange           : PropTypes.func,
    watchFields             : PropTypes.arrayOf(PropTypes.string),
    onValidationChange      : PropTypes.func,
    externalRef             : PropTypes.shape({}),
    keepValuesOnInputRemove : PropTypes.bool,
};

const wrapperWithRef = (props, ref) => <Wrapper {...props} externalRef={ref} />;
wrapperWithRef.displayName = 'Wrapper';

export default forwardRef(wrapperWithRef);
