import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';

const capitalize = (s) => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
function Layout({ children, title, currentPath }) {
    const dynamicTitle = `${title} | ${currentPath.map((section) => `${capitalize(section)} `)}`;

    const [bannerHeight, setBannerHeight] = useState(0);

    useEffect(() => {
        if (window.env.SHOW_BANNER) {
            const height = document.getElementsByClassName('environment-banner')?.item(0)?.clientHeight;
            setBannerHeight(height);
        }
    }, []);

    return (
        <>
            {window.env.SHOW_BANNER && (
                <div className="environment-banner">
                    <FormattedMessage id={translated.global.devEnvironment} defaultMessage={translated.global.devEnvironment} />
                </div>
            )}
            <title>{dynamicTitle}</title>
            <div className="layout-wrapper" style={{ marginTop: bannerHeight }}>
                {children}
            </div>
            <div id="tooltips" className="tooltips" />
            <div id="dropdowns" className="dropdowns" />
            <div id="modals" className="modals" />
        </>
    );
}

Layout.propTypes = {
    children    : PropTypes.node.isRequired,
    title       : PropTypes.string.isRequired,
    currentPath : PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Layout;
