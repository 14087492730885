import React from 'react';
import PropTypes from 'prop-types';

function Button({ className, isIcon, quantity }) {
    return (
        <div className={`skeleton skeleton-button ${className}`}>
            {Array.from(Array(quantity).keys()).map((x) => (
                isIcon
                    ? <div className="skeleton-element skeleton-button-icon" key={x} />
                    : <div className="skeleton-element skeleton-button" key={x} />
            ))}
        </div>
    );
}

Button.defaultProps = {
    className : '',
    isIcon    : false,
    quantity  : 1,
};

Button.propTypes = {
    className : PropTypes.string,
    isIcon    : PropTypes.bool,
    quantity  : PropTypes.number,
};

export default Button;
