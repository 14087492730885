import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

/**
 * Draw the input for the dates with a mask.
 */
function PickerInput({ id, value, onChange, label, error }) {
    return (
        <div className="form-field-input is-completed">
            <InputMask id={id} mask="9999-99-99" value={value} onChange={onChange} alwaysShowMask />
            <label className="label">
                <WrappedFormattedMessage content={label} />
            </label>
            <div className="form-field-helper-text">
                <span className={`helper-text ${error ? 'text-color-error' : ''}`}>
                    {error || <FormattedMessage id={translated.global.pickerInput.format} defaultMessage={translated.global.pickerInput.format} />}
                </span>
            </div>
        </div>
    );
}

PickerInput.propTypes = {
    id       : PropTypes.string,
    value    : PropTypes.string,
    onChange : PropTypes.func,
    label    : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    error    : PropTypes.string,
};

PickerInput.defaultProps = {
    id       : '',
    value    : '',
    onChange : () => {
        // Default
    },
    label : '',
    error : '',
};

export default React.memo(PickerInput);
