import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

function SortableContent(props) {
    const {
        items,
        displayColumns,
        actions,
        handleOnRowCheck,
        checkedElements,
        updateOnCollapse,
        subTable,
        collapsedRows,
        onSort,
        isSortEnabled,
        styleConfig,
        loadingIds,
        tableId,
        rowForwardedRef,
        getColumnCustomAttributes,
        getRowCustomClass,
    } = props;

    const SortableItem = SortableElement(({ item }) => (
        <Row
            id={`${tableId}-${item.id}`}
            key={`${tableId}-${item.id}`}
            index={item.id}
            data={item}
            fields={displayColumns}
            actions={actions}
            onCheck={handleOnRowCheck}
            checked={checkedElements[item.id]}
            updateOnCollapse={updateOnCollapse}
            subTable={subTable}
            isCollapsed={collapsedRows[item.id]}
            styleConfig={styleConfig}
            isLoading={loadingIds.includes(item.visualId || item.id)}
            isSortEnabled={isSortEnabled}
            isSortable
            rowForwardedRef={rowForwardedRef}
            getColumnCustomAttributes={getColumnCustomAttributes}
            getRowCustomClass={getRowCustomClass}
        />
    ));

    const SortableList = SortableContainer(({ items: elements }) => (
        <tbody>
            {elements.map((item, index) => (
                <SortableItem key={`item-${item.id}`} index={index} item={item} />
            ))}
        </tbody>
    ));

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex != null && newIndex != null && oldIndex !== newIndex) {
            onSort(items, oldIndex, newIndex);
        }
    };

    return <SortableList items={items} onSortEnd={onSortEnd} lockAxis="y" useDragHandle helperClass="row-dragged" />;
}

SortableContent.defaultProps = {
    subTable         : null,
    handleOnRowCheck : () => {
        // Default
    },
    updateOnCollapse: () => {
        // Default
    },
    checkedElements           : [],
    collapsedRows             : [],
    styleConfig               : {},
    loadingIds                : [],
    isSortEnabled             : false,
    rowForwardedRef           : null,
    getColumnCustomAttributes : null,
    getRowCustomClass         : null,
};

SortableContent.propTypes = {
    tableId                   : PropTypes.string.isRequired,
    items                     : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayColumns            : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    actions                   : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSort                    : PropTypes.func.isRequired,
    loadingIds                : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    subTable                  : PropTypes.shape({}),
    updateOnCollapse          : PropTypes.func,
    handleOnRowCheck          : PropTypes.func,
    checkedElements           : PropTypes.shape({}),
    collapsedRows             : PropTypes.shape({}),
    styleConfig               : PropTypes.shape({}),
    isSortEnabled             : PropTypes.bool,
    rowForwardedRef           : PropTypes.shape({}),
    getColumnCustomAttributes : PropTypes.func,
    getRowCustomClass         : PropTypes.func,
};

export default React.memo(SortableContent);
