import React, { useRef, useEffect, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Chips from 'Components/Chips';
import { getSeparatorUsed, getTextBeforeSeparator, getTextAfterSeparator } from 'Utils/text';
import areRulesValid from 'Components/Form/Validator';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import panelContext from 'State/panelContext';

function InputWithChip({
    label, value, onChange, onFocus, onBlur, id, validations, isDisabled, sortFunction,
}) {
    const { dateManager } = useContext(panelContext);

    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const textRef = useRef();

    const addChip = useCallback(
        (newValue = value?.text, ignoreSeparator = false) => {
            const updatedList = value?.addedValues || [];
            let valueToUpdate = newValue;

            const isValid = !areRulesValid(validations, { text: newValue }, false, dateManager)?.length;
            if (isValid) {
                const hasSeparator = !!getSeparatorUsed(newValue);

                if ((hasSeparator || ignoreSeparator) && isValid) {
                    const valueToAdd = getTextBeforeSeparator(newValue);
                    valueToUpdate = getTextAfterSeparator(newValue);

                    if (ignoreSeparator && valueToAdd === valueToUpdate) {
                        valueToUpdate = '';
                    }

                    if (updatedList.every((e) => String(e) !== String(valueToAdd))) {
                        updatedList.push(valueToAdd);
                    }
                }
            }

            onChange({ target: { value: { addedValues: updatedList, text: valueToUpdate }, label } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [label, onChange, validations, value],
    );

    const onKeyPress = useCallback(
        (event) => {
            if (event.keyCode === 13 && event.target.value !== '') {
                // The 'enter' key must add the option written
                addChip(undefined, true);
            }
        },
        [addChip],
    );

    useEffect(() => {
        if (textRef?.current) {
            textRef.current.addEventListener('keyup', onKeyPress, false);
        }
        return () => {
            if (textRef?.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                textRef.current.removeEventListener('keyup', onKeyPress, false);
            }
        };
    }, [onKeyPress]);

    const textHandleOnChange = (e) => {
        addChip(e.target.value);
    };

    const handleOnBlur = () => {
        if (value?.text) {
            addChip(undefined, true);
        }

        if (onBlur) {
            onBlur();
        }
    };

    const removeOption = (valueToRemove) => {
        const updatedList = value?.addedValues.filter((e) => e !== valueToRemove);

        onChange({ target: { value: { addedValues: updatedList, text: value?.text }, label } });
    };

    const sortedList = value?.addedValues;

    if (sortFunction) {
        sortedList.sort(sortFunction);
    }

    return (
        <>
            <input
                id={`${id}-input-text`}
                type="text"
                value={value?.text}
                onChange={textHandleOnChange}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={handleOnBlur}
                tabIndex={isDisabled ? '-1' : '0'}
                autoComplete="off"
                ref={textRef}
            />
            {label && (
                <label id={`${id}-input-text-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!!sortedList?.length && (
                <Chips
                    outlined
                    list={sortedList.map((opt) => ({
                        onClose : () => removeOption(opt),
                        text    : opt,
                        key     : opt,
                    }))}
                />
            )}
        </>
    );
}

InputWithChip.defaultProps = {
    id       : '',
    label    : '',
    onChange : null,
    onFocus  : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    innerInputRef : null,
    validations   : {},
    isDisabled    : false,
    sortFunction  : null,
};

InputWithChip.propTypes = {
    id            : PropTypes.string,
    label         : PropTypes.string,
    value         : PropTypes.shape({}).isRequired,
    onChange      : PropTypes.func,
    onFocus       : PropTypes.func,
    onBlur        : PropTypes.func,
    innerInputRef : PropTypes.shape({}),
    validations   : PropTypes.shape({}),
    isDisabled    : PropTypes.bool,
    sortFunction  : PropTypes.func,
};

export default React.memo(InputWithChip);
