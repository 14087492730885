import React, { useContext, useState } from 'react';
import Grid from 'Components/Grid';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import Subsection from 'Components/Sections/Subsection';
import withRequest from 'Components/Sections/withRequest';
import { attachmentFilesWithPreview } from 'Constants/global';

const getActions = (data, navigator, confirmation, snackbar, resources) => [
    {
        content  : translated.global.buttons.edit,
        callback : (attachment) => navigator.goToOwnerAttachmentEditor({ available: attachment.links.self }),
        key      : 'edit',
        when     : (attachment) => attachment?.links?.self?.patch,
    },
    {
        content  : translated.global.buttons.delete,
        callback : (attachment) => {
            confirmation.show({
                message       : translated.owners.attachments.delete.confirmation,
                acceptMessage : translated.global.buttons.delete,
                onAccept      : async () => {
                    const { id } = attachment;
                    const reqConfig = {
                        ...attachment.links.self.delete,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [id],
                        shouldReloadData : true,
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.ATTACHMENTS, resources });

                        snackbar.show({ content: translated.owners.attachments.delete.success, isSuccess: true });
                    } catch (error) {
                        snackbar.show({
                            content : translated.owners.attachments.delete.error,
                            isError : true,
                        });
                    }
                },
            });
        },
        key  : 'delete',
        when : (attachment) => attachment?.links?.self?.delete && !attachment.isParent,
    },
    {
        callback: async (attachment) => {
            const element = document.createElement('a');
            element.setAttribute('href', attachment.links.self.preview.url);
            element.setAttribute('target', '_blank');
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        key               : 'preview',
        isOutsideDropdown : true,
        icon              : 'FileEye',
        tooltip           : translated.global.buttons.preview,
        when              : (attachment) => attachment?.links?.self?.preview && attachment.hasPreview,
    },
    {
        callback: async (attachment) => {
            const element = document.createElement('a');
            element.setAttribute('href', attachment.links.self.download.url);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        key               : 'download',
        isOutsideDropdown : true,
        icon              : 'FileDownload',
        tooltip           : translated.global.buttons.download,
        // The client asked us to hide the 'download' when the preview button is visible.
        when              : (attachment) => attachment?.links?.self?.download && !(attachment?.links?.self?.preview && attachment.hasPreview),
    },
];

function AttachmentsList({
    data, sections, fetching, reloadData, resources, setSectionState, state: { appliedFilters },
}) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const [categoryChildren, setCategoryChildren] = useState({ isVisible: false, list: [] });

    const handleOnAddClick = () => {
        navigator.goToOwnerAttachmentEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
    };

    const { links, data: list, order, pagination, filter: filterLinks } = data || {};

    const { Section, props: subsecProps } = Sections.get(sections);

    const attachments = list?.map((each) => ({
        name        : each.name,
        category    : each.category?.parent?.name || each.category?.name,
        subCategory : each.category?.parent ? (
            each.category.name
        ) : (
            <FormattedMessage id={translated.owners.attachments.noCategory} defaultMessage={translated.owners.attachments.noCategory} />
        ),
        extension    : each.extension || '',
        links        : each.links,
        id           : each.id,
        creationDate : each.creationDate,
        hasPreview   : attachmentFilesWithPreview.indexOf(each.extension) > -1,
    }));

    const filterFields = [
        {
            type        : 'select',
            options     : filterLinks?.additionalResources?.attachmentCategories.filter((e) => !e.parent),
            submitKey   : 'category',
            resourceKey : 'categories',
            label       : translated.owners.attachments.category,
            onChange    : (newValue) => {
                setCategoryChildren((prev) => ({
                    ...prev,
                    list: filterLinks?.additionalResources?.attachmentCategories.filter((e) => e.parent?.id === newValue),
                }));
            },
            onSwitch: (isSelected) => {
                if (categoryChildren.isVisible !== isSelected) {
                    setCategoryChildren((prev) => ({
                        ...prev,
                        isVisible: isSelected,
                    }));
                }
            },
        },
    ];

    if (categoryChildren?.isVisible) {
        filterFields.push({
            type        : 'select',
            options     : categoryChildren.list,
            submitKey   : 'subCategory',
            resourceKey : 'subCategories',
            // eslint-disable-next-line max-len
            label       : translated.owners.attachments.categoryChild,
            isDisabled  : !categoryChildren.list?.length,
        });
    }

    return (
        <>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            <Subsection
                title={translated.owners.attachments.title}
                actionButtons={
                    links?.self?.create && (attachments?.length || !!appliedFilters?.filters)
                        ? [
                            <Button id="attachments-add-button" variant="outlined" color="primary" key="button_primary" onClick={handleOnAddClick}>
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Button>,
                        ]
                        : []
                }
            >
                <Table
                    id="attachments"
                    key="attachments"
                    columns={[
                        {
                            title       : translated.owners.attachments.name,
                            key         : 'name',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title       : translated.owners.attachments.creationDate,
                            key         : 'creationDate',
                            isVisible   : true,
                            hasMaxWidth : true,
                            isDate      : true,
                        },
                        {
                            title       : translated.owners.attachments.category,
                            key         : 'category',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title       : translated.owners.attachments.subCategory,
                            key         : 'subCategory',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title       : translated.owners.attachments.extension,
                            key         : 'extension',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                    ]}
                    rows={{ actions: getActions(attachments, navigator, confirmation, snackbar, resources) }}
                    items={attachments || []}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    whenEmpty={(
                        <Alert
                            id="attachments-empty"
                            content={translated.owners.attachments.emptyList}
                            actionText={links?.self?.create ? translated.global.buttons.add : null}
                            onClick={handleOnAddClick}
                        />
                    )}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config);
                    }}
                    appliedFilters={appliedFilters}
                    filterResources={{
                        showDropdown        : true,
                        filters             : filterFields,
                        submitDataConverter : (submitData) => ({ category: submitData.subCategory || submitData.category }),
                        onReset             : () => {
                            setCategoryChildren({ list: [], isVisible: false });
                        },
                        filterLinks,
                        resources,
                        onInit: (values) => {
                            // The user left the page and came back, with filters loaded
                            if (values?.category) {
                                setCategoryChildren({
                                    isVisible : true,
                                    list      : filterLinks?.additionalResources?.attachmentCategories.filter((e) => e.parent?.id === values?.category),
                                });
                            }
                        },
                    }}
                />
            </Subsection>
        </>
    );
}

AttachmentsList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isSubHeading width={150} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table />
            </Grid.Column>
        </Grid>
    );
};

AttachmentsList.defaultProps = {
    data     : [],
    fetching : {},
};

AttachmentsList.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func.isRequired,
    state           : PropTypes.shape({}).isRequired,
};

export default withRequest(AttachmentsList);
