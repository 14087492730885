module.exports = Object.freeze({
    title         : 'Property Groups',
    empty         : 'There are no property groups',
    emptyChildren : 'There are no property groups associated',
    createTitle   : 'Create a property group',
    editTitle     : 'Edit a property group',
    update        : {
        success : 'The property group has been updated',
        error   : 'The property group could not be updated',
    },
    save: {
        success : 'The property group has been saved',
        error   : 'The property group could not be saved',
    },

    id            : 'Id',
    code          : 'Code',
    name          : 'Name',
    hidden        : 'Is hidden',
    isActive      : 'Status',
    subTableTitle : 'Children',

    delete: {
        confirmation               : 'Delete property group?',
        success                    : 'The property group has been removed',
        error                      : 'The property group could not be removed',
        errorEntityCannotBeDeleted : 'This property group could not be removed since it is being used',
        defaultError               : 'The property group could not be removed',
    },

    enable: {
        success : 'The property group has been enabled',
        error   : 'The property group could not be enabled',
    },
    disable: {
        success : 'The property group has been disabled',
        error   : 'The property group could not be disabled',
    },
});
