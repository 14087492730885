import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Number({
    label, name, value, isReadOnlyWithoutInputs, isDisabled, id, onFocus, onChange, onBlur, defaultValue, wrapperRef, onInput,
}) {
    const context = useFormContext();
    const { setValue, control } = context || {};

    const handleOnChange = useCallback((event) => {
        const newValue = event?.target?.value;

        const fixedValue = onInput ? onInput(newValue) : newValue;
        if (onChange) {
            onChange(fixedValue);
        }

        setValue(name, fixedValue, { shouldDirty: true, shouldValidate: true });

        return null;
    }, [name, onChange, setValue, onInput]);

    const handleOnBlur = useCallback((props) => {
        if (onBlur) {
            onBlur(props);
        }
    }, [onBlur]);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!isReadOnlyWithoutInputs && (
                // We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types).
                <Controller
                    render={
                        ({ field }) => (
                            <input
                                id={id}
                                key={id}
                                name={name}
                                type="number"
                                onFocus={onFocus}
                                tabIndex={isDisabled ? '-1' : '0'}
                                autoComplete="off"
                                {...field}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                disabled={isDisabled}
                                ref={wrapperRef}
                            />
                        )
                    }
                    control={control}
                    name={name}
                    defaultValue={defaultValue || ''}
                />
            )}

            {isReadOnlyWithoutInputs && <span id={id}>{value}</span>}
        </>
    );
}

Number.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : null,
    defaultValue            : null,
    min                     : '',
    max                     : '',
    onChange                : null,
    isReadOnlyWithoutInputs : false,
    onInput                 : (value) => (value),
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled    : false,
    innerInputRef : null,
};

Number.propTypes = {
    id                      : PropTypes.string,
    value                   : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue            : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name                    : PropTypes.string.isRequired,
    min                     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max                     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadOnlyWithoutInputs : PropTypes.bool,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onInput                 : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

function NumberWithRef(props, ref) {
    return <Number {...props} wrapperRef={ref} />;
}
NumberWithRef.displayName = 'Input';

export default React.memo(forwardRef(NumberWithRef));
