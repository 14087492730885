import React, { useContext, useState } from 'react';
import Grid from 'Components/Grid';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import { csvStatus } from 'Constants/global';
import Status from 'Components/Status';

function List({ data, reloadData, resources }) {
    const { navigator } = useContext(PanelContext);

    const [appliedFilters, setAppliedFilters] = useState({});

    const { data: list, order, pagination, filter: filterLinks } = data || {};

    return (
        <Table
            title={translated.sectionName.owners}
            className="margin-top-large"
            id="csv-owners"
            key="csv-owners"
            columns={[
                {
                    title     : translated.imports.firstName,
                    key       : 'pFirstName',
                    isVisible : true,
                },
                {
                    title     : translated.imports.lastName,
                    key       : 'pLastName',
                    isVisible : true,
                },
                {
                    title     : translated.imports.email,
                    key       : 'pEmail',
                    isVisible : true,
                },
                {
                    title                : translated.imports.status,
                    customFieldComponent : (csv) => <Status status={csv.status} />,
                    key                  : 'status',
                    isVisible            : true,
                },
            ]}
            rows={{
                actions: [
                    {
                        content           : translated.imports.create,
                        tooltip           : translated.imports.createThisOwner,
                        callback          : navigator.goToOwnerImport,
                        when              : (owner) => owner.status !== csvStatus.COMPLETE && owner.links?.self?.read,
                        key               : 'create',
                        icon              : 'AccountPlus',
                        isOutsideDropdown : true,
                        disabledWhen      : (owner) => !owner.links?.self?.create,
                    },
                    {
                        content           : translated.imports.go,
                        tooltip           : translated.imports.goToThisOwner,
                        callback          : navigator.goToDetails,
                        when              : (owner) => owner.status === csvStatus.COMPLETE,
                        key               : 'go',
                        icon              : 'AccountArrowRight',
                        isOutsideDropdown : true,
                        disabledWhen      : (owner) => !owner.links?.self?.read,
                    },
                ],
            }}
            items={list}
            canCollapse
            onApplyFilter={(link, config) => {
                reloadData(link);
                setAppliedFilters(config?.appliedFilters);
            }}
            appliedFilters={appliedFilters}
            filterResources={{
                filters: [
                    {
                        type        : 'text',
                        submitKey   : 'ownerId',
                        resourceKey : 'ownerId',
                        label       : translated.owners.id,
                        validations : ['alphanumericUnderScore'],
                    },
                    {
                        type        : 'text',
                        submitKey   : 'firstName',
                        resourceKey : 'firstName',
                        label       : translated.global.firstName,
                    },
                    {
                        type        : 'text',
                        submitKey   : 'lastName',
                        resourceKey : 'lastName',
                        label       : translated.global.lastName,
                    },
                    {
                        type        : 'select',
                        options     : data?.filter?.additionalResources?.countries || [],
                        submitKey   : 'country',
                        resourceKey : 'country',
                        label       : translated.global.country,
                    },
                    {
                        type        : 'text',
                        submitKey   : 'city',
                        resourceKey : 'city',
                        label       : translated.global.city,
                    },
                    {
                        type        : 'text',
                        submitKey   : 'email',
                        resourceKey : 'email',
                        label       : translated.global.email,
                    },
                    {
                        type        : 'number',
                        submitKey   : 'phoneNumber',
                        resourceKey : 'phoneNumber',
                        label       : translated.global.phone,
                        validations : { maxLength: 30, unary: ['phone'] },
                    },
                ],
                filterLinks,
                resources,
            }}
            order={order}
            onOrder={(link) => reloadData(link)}
            pagination={pagination}
            onPaginationClick={(link) => reloadData(link)}
            whenEmpty={(
                <Alert
                    id="csv-owners-empty"
                    content={translated.imports.owners.empty}
                />
            )}
        />
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-small">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading className="animated fade-in" />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button className="animated fade-in delay-01" />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-remove-top">
                <Skeleton.Table className="animated fade-in-up-small fastest delay-02" />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    data     : [],
    fetching : {},
    state    : null,
};

List.propTypes = {
    sections   : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({}),
    fetching   : PropTypes.shape({}),
    reloadData : PropTypes.func.isRequired,
    resources  : PropTypes.shape({}).isRequired,
    state      : PropTypes.shape({}),
};

export default withRequest(List);
