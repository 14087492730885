import React from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import getClassName from 'Utils/getClassName';
import Button from 'Components/Button';
import { errorService } from 'Utils';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function ErrorMessage({
    children, data, title, onRetry, isSmall, error, sectionErrors, isEmbedded, header,
}) {
    return (
        <>
            {isEmbedded && (
                <div className="padding padding-remove-horizontal h5">
                    <WrappedFormattedMessage content={header} />
                </div>
            )}
            <div className={getClassName({ errorSmall: isSmall, isEmbedded }, 'error-wrapper')}>
                {onRetry && (
                    <div className="error-reload">
                        <Button
                            id="error-message-reload-button"
                            icon="Reload"
                            color="grey"
                            tooltip={translated.global.buttons.reload}
                            onClick={onRetry}
                        />
                    </div>
                )}
                <div className="error-content">
                    <h3 className="error-code">
                        <WrappedFormattedMessage content={title} />
                    </h3>
                    {isSmall && !isEmbedded && <img className="crash" src="/static/img/crash.svg" alt="crash" />}
                    {isEmbedded && <img className="astronaut" src="/static/img/astronaut.svg" alt="astronaut" />}
                    {(children || data || error) && (
                        <h4 className="error-message">
                            <WrappedFormattedMessage content={(error && errorService.getRequestErrorMessage(error, sectionErrors)) || data || children} />
                        </h4>
                    )}
                    {!isSmall && (
                        <>
                            <img className="moonland" src="/static/img/moonland.svg" alt="moon" />
                            <img className="astronaut2" src="/static/img/astronaut2.svg" alt="astronaut" />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

ErrorMessage.defaultProps = {
    title         : '',
    header        : '',
    data          : '',
    children      : null,
    onRetry       : null,
    isSmall       : false,
    isEmbedded    : false,
    error         : null,
    sectionErrors : null,
};

ErrorMessage.propTypes = {
    children      : PropTypes.node,
    onRetry       : PropTypes.func,
    title         : PropTypes.string,
    header        : PropTypes.string,
    data          : PropTypes.string,
    isSmall       : PropTypes.bool,
    isEmbedded    : PropTypes.bool,
    error         : PropTypes.shape({}),
    sectionErrors : PropTypes.shape({}),
};

export default ErrorMessage;
