// eslint-disable-next-line import/no-cycle
import { SelectedSection } from './Collection';
import Wrapper from './Wrapper';
import Workspace from './Workspace';

const Sections = {
    Selected : SelectedSection,
    get      : SelectedSection.get,
    Wrapper,
    Workspace,
};

export default Sections;
