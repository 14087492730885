import Cookie from 'js-cookie';

const KEY = 'debugMode';

const setUp = (enabled) => {
    if (enabled === 'true') {
        Cookie.set(KEY, enabled, { expires: 0.33 }); // It expires in 8 hours
    } else {
        Cookie.remove(KEY);
    }
};

const isEnabled = () => Cookie.get(KEY) === 'true';

export {
    setUp,
    isEnabled,
};
