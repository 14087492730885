module.exports = Object.freeze({
    information        : 'apf.global.information',
    general            : 'apf.global.general',
    name               : 'apf.global.name',
    type               : 'apf.global.type',
    wallet             : 'apf.global.wallet',
    client             : 'apf.global.client',
    dates              : 'apf.global.dates',
    user               : 'apf.global.user',
    loginToken         : 'apf.global.loginToken',
    firstName          : 'apf.global.firstName',
    lastName           : 'apf.global.lastName',
    id                 : 'apf.global.id',
    status             : 'apf.global.status',
    email              : 'apf.global.email',
    phoneOrMobile      : 'apf.global.phoneOrMobile',
    resendEmail        : 'apf.global.resendEmail',
    dissociateSecOwner : 'apf.global.dissociateSecOwner',
    phone              : 'apf.global.phone',
    mobile             : 'apf.global.mobile',
    department         : 'apf.global.department',
    role               : 'apf.global.role',
    birthday           : 'apf.global.birthday',
    total              : 'apf.global.total',
    notProvided        : 'apf.global.notProvided',
    club               : 'apf.global.club',
    tier               : 'apf.global.tier',
    address            : 'apf.global.address',
    zipCode            : 'apf.global.zipCode',
    country            : 'apf.global.country',
    city               : 'apf.global.city',
    estate             : 'apf.global.estate',
    tags               : 'apf.global.tags',
    tagsFilter         : 'apf.global.tagsFilter',
    language           : 'apf.global.language',
    contractType       : 'apf.global.contractType',
    contractId         : 'apf.global.contractId',
    contract           : 'apf.global.contract',
    contractWithId     : 'apf.global.contractWithId',
    goToStart          : 'apf.global.goToStart',
    goToInit           : 'apf.global.goToInit',
    devEnvironment     : 'apf.global.devEnvironment',
    messageForNoData   : 'apf.global.messageForNoData',
    state              : {
        enabled  : 'apf.global.state.enabled',
        disabled : 'apf.global.state.disabled',
        yes      : 'apf.global.state.yes',
        no       : 'apf.global.state.no',
        error    : 'apf.global.state.error',
    },
    buttons: {
        delete         : 'apf.global.buttons.delete',
        edit           : 'apf.global.buttons.edit',
        new            : 'apf.global.buttons.new',
        add            : 'apf.global.buttons.add',
        view           : 'apf.global.buttons.view',
        enable         : 'apf.global.buttons.enable',
        disable        : 'apf.global.buttons.disable',
        save           : 'apf.global.buttons.save',
        confirm        : 'apf.global.buttons.confirm',
        ok             : 'apf.global.buttons.ok',
        refresh        : 'apf.global.buttons.refresh',
        cancel         : 'apf.global.buttons.cancel',
        close          : 'apf.global.buttons.close',
        reset          : 'apf.global.buttons.reset',
        remove         : 'apf.global.buttons.remove',
        retry          : 'apf.global.buttons.retry',
        download       : 'apf.global.buttons.download',
        preview        : 'apf.global.buttons.preview',
        import         : 'apf.global.buttons.import',
        clear          : 'apf.global.buttons.clear',
        create         : 'apf.global.buttons.create',
        apply          : 'apf.global.buttons.apply',
        search         : 'apf.global.buttons.search',
        back           : 'apf.global.buttons.back',
        sendInvitation : 'apf.global.buttons.sendInvitation',
        viewDetails    : 'apf.global.buttons.viewDetails',
        moreInfo       : 'apf.global.buttons.moreInfo',
        upload         : 'apf.global.buttons.upload',
        yes            : 'apf.global.buttons.yes',
        no             : 'apf.global.buttons.no',
        update         : 'apf.global.buttons.update',
        disassociate   : 'apf.global.buttons.disassociate',
        restore        : 'apf.global.buttons.restore',
        show           : 'apf.global.buttons.show',
        hide           : 'apf.global.buttons.hide',
        reload         : 'apf.global.buttons.reload',
        unselect       : 'apf.global.buttons.unselect',
        select         : 'apf.global.buttons.select',
        collapse       : 'apf.global.buttons.collapse',
        expand         : 'apf.global.buttons.expand',
        plus           : 'apf.global.buttons.plus',
        less           : 'apf.global.buttons.less',
        more           : 'apf.global.buttons.more',
        equal          : 'apf.global.buttons.equal',
        reasonChange   : 'apf.global.buttons.reasonChange',
    },
    errors: {
        error                           : 'apf.global.errors.error',
        default                         : 'apf.global.errors.default',
        cantGetTheData                  : 'apf.global.errors.cantGetTheData',
        retrying                        : 'apf.global.errors.retrying',
        retryingEntity                  : 'apf.global.errors.retryingEntity',
        serverDoesNotRespond            : 'apf.global.errors.serverDoesNotRespond',
        reloadDataFail                  : 'apf.global.errors.reloadDataFail',
        sectionNotFound                 : 'apf.global.errors.sectionNotFound',
        errorEntityNotFound             : 'apf.global.errors.errorEntityNotFound',
        errorContractTypeInUse          : 'apf.global.errors.errorContractTypeInUse',
        errorUserRelationshipWithItself : 'apf.global.errors.errorUserRelationshipWithItself',
        errorResourceNotActive          : 'apf.global.errors.errorResourceNotActive',
        errorEntityCannotBeDeleted      : 'apf.global.errors.errorEntityCannotBeDeleted',
        entityAlreadyExists             : 'apf.global.errors.entityAlreadyExists',
        failedDependency                : 'apf.global.errors.failedDependency',
        errorUnauthorized               : 'apf.global.errors.errorUnauthorized',
        errorForbidden                  : 'apf.global.errors.errorForbidden',
        errorBadRequest                 : 'apf.global.errors.errorBadRequest',
        errorServerFails                : 'apf.global.errors.errorServerFails',
        errorOnExtraAttributes          : 'apf.global.errors.errorOnExtraAttributes',
        formInvalid                     : 'apf.global.errors.formInvalid',
    },
    noResults         : 'apf.global.noResults',
    range             : 'apf.global.range',
    date              : 'apf.global.date',
    fromTo            : 'apf.global.betweenDates',
    effectiveToExpiry : 'apf.global.effectiveToExpiry',
    feb29Warn         : 'apf.global.feb29Warn',
    selectFile        : 'apf.global.selectFile',
    selectAnOption    : 'apf.global.selectAnOption',
    from              : 'apf.global.from',
    to                : 'apf.global.to',
    duration          : 'apf.global.duration',
    startDate         : 'apf.global.startDate',
    endDate           : 'apf.global.endDate',
    advice            : 'apf.global.advice',
    warning           : 'apf.global.warning',

    required            : 'apf.global.required',
    invalid             : 'apf.global.invalid',
    invalidMin          : 'apf.global.invalidMin',
    invalidMax          : 'apf.global.invalidMax',
    invalidEmail        : 'apf.global.invalidEmail',
    invalidNumber       : 'apf.global.invalidNumber',
    invalidNoNumber     : 'apf.global.invalidNoNumber',
    invalidOnlyNumber   : 'apf.global.invalidOnlyNumber',
    invalidPhone        : 'apf.global.invalidPhone',
    invalidRange        : 'apf.global.invalidRange',
    invalidDate         : 'apf.global.invalidDate',
    invalidChar         : 'apf.global.invalidChar',
    invalidColor        : 'apf.global.invalidColor',
    invalidHtml         : 'apf.global.invalidHtml',
    invalidHtmlContent  : 'apf.global.invalidHtmlContent',
    alphanumeric        : 'apf.global.alphanumeric',
    validMinDate        : 'apf.global.validMinDate',
    validMaxDate        : 'apf.global.validMaxDate',
    incompleteAndErrors : 'apf.global.incompleteAndErrors',
    formErrors          : 'apf.global.formErrors',
    incomplete          : 'apf.global.incomplete',

    days                       : 'apf.global.days',
    selectOption               : 'apf.global.selectOption',
    missingPermissions         : 'apf.global.missingPermissions',
    sectionChangedConfirmation : { title: 'apf.global.sectionChangedConfirmation.title', message: 'apf.global.sectionChangedConfirmation.message' },
    search                     : { error: 'apf.global.search.error' },
    resetConfirmation          : { title: 'apf.global.resetConfirmation.title', message: 'apf.global.resetConfirmation.message' },
    calendar                   : {
        fromLabel        : 'apf.global.calendar.fromLabel',
        toLabel          : 'apf.global.calendar.toLabel',
        dateLabel        : 'apf.global.calendar.dateLabel',
        switchToCalendar : 'apf.global.calendar.switchToCalendar',
        switchToInput    : 'apf.global.calendar.switchToInput',
        errors           : {
            invalidDate    : 'apf.global.calendar.errors.invalidDate',
            startBeforeEnd : 'apf.global.calendar.errors.startBeforeEnd',
            rangeMinLength : 'apf.global.calendar.errors.rangeMinLength',
            dateInRange    : 'apf.global.calendar.errors.dateInRange',
            includesRange  : 'apf.global.calendar.errors.includesRange',
        },
    },
    restrictedApp: {
        noPermissions : { title: 'apf.global.restrictedApp.noPermissions.title', subTitle: 'apf.global.restrictedApp.noPermissions.subTitle' },
        maintenance   : { title: 'apf.global.restrictedApp.maintenance.title', subTitle: 'apf.global.restrictedApp.maintenance.subTitle' },
    },
    longRequestWarning : 'apf.global.longRequestWarning',
    uploadingSnackbar  : 'apf.global.uploadingSnackbar',
    pleaseWait         : 'apf.global.pleaseWait',
    about              : { appName: 'apf.global.about.appName', version: 'apf.global.about.version', build: 'apf.global.about.build' },
    sideMenu           : { global: 'apf.global.sideMenu.global', more: 'apf.global.sideMenu.more' },
    alert              : {
        error   : 'apf.global.alert.error',
        warning : 'apf.global.alert.warning',
        success : 'apf.global.alert.success',
        info    : 'apf.global.alert.info',
    },
    breadcrumb           : { closeGoTo: 'apf.global.breadcrumb.closeGoTo', goTo: 'apf.global.breadcrumb.goTo', upperLevel: 'apf.global.breadcrumb.upperLevel' },
    pickerInput          : { format: 'apf.global.pickerInput.format' },
    card                 : { avatarError: 'apf.global.card.avatarError' },
    modal                : { confirmation: 'apf.global.modal.confirmation' },
    errorHandler         : { title: 'apf.global.errorHandler.title' },
    floatingActionButton : { goUp: 'apf.global.floatingActionButton.goUp' },
    walletSummary        : { field: 'apf.global.walletSummary.field' },
    summary              : 'apf.global.summary',
    column               : { error: 'apf.global.column.error' },
    grid                 : { error: 'apf.global.grid.error' },
    table                : {
        header : { reverseSort: 'apf.global.table.header.reverseSort' },
        footer : {
            prev      : 'apf.global.table.footer.prev',
            next      : 'apf.global.table.footer.next',
            first     : 'apf.global.table.footer.first',
            last      : 'apf.global.table.footer.last',
            count     : 'apf.global.table.footer.count',
            pageCount : 'apf.global.table.footer.pageCount',
        },
        buttons: {
            filter        : 'apf.global.table.buttons.filter',
            viewColumns   : 'apf.global.table.buttons.viewColumns',
            freezeColumns : 'apf.global.table.buttons.freezeColumns',
            denseContent  : 'apf.global.table.buttons.denseContent',
            settings      : 'apf.global.table.buttons.settings',
            edition       : { enable: 'apf.global.table.buttons.edition.enable', disable: 'apf.global.table.buttons.edition.disable' },
        },
        empty         : 'apf.global.table.empty',
        selectedItems : 'apf.global.table.selectedItems',
        filters       : 'apf.global.table.filters',
        quantity      : 'apf.global.table.quantity',
        searchBy      : 'apf.global.table.searchBy',
    },
    min          : 'apf.global.min',
    max          : 'apf.global.max',
    showFiltered : 'apf.global.showFiltered',
    statusList   : {
        active              : 'apf.global.statusList.active',
        cancelled           : 'apf.global.statusList.cancelled',
        error               : 'apf.global.statusList.error',
        pending             : 'apf.global.statusList.pending',
        enabled             : 'apf.global.statusList.enabled',
        incomplete          : 'apf.global.statusList.incomplete',
        completed           : 'apf.global.statusList.completed',
        downloading         : 'apf.global.statusList.downloading',
        invalid             : 'apf.global.statusList.invalid',
        locked              : 'apf.global.statusList.locked',
        attention_required  : 'apf.global.statusList.attentionRequired',
        reserved            : 'apf.global.statusList.reserved',
        expired             : 'apf.global.statusList.expired',
        modify              : 'apf.global.statusList.modify',
        suspended           : 'apf.global.statusList.suspended',
        on_hold             : 'apf.global.statusList.onHold',
        requested           : 'apf.global.statusList.requested',
        denied              : 'apf.global.statusList.denied',
        imported            : 'apf.global.statusList.imported',
        confirmed           : 'apf.global.statusList.confirmed',
        insufficient_founds : 'apf.global.statusList.insufficientFounds',
        not_defined         : 'apf.global.statusList.notDefined',
    },
    imagePicker: {
        imageAlt    : 'apf.global.imagePicker.imageAlt',
        placeHolder : 'apf.global.imagePicker.placeHolder',
    },
    languageChange: 'apf.global.changeLanguage',
});
