import React from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Cookie from 'js-cookie';
import { requestLoggedUser } from '../services/Api';
import { loginErrors } from 'Constants/translator';

export const login = async () => {
    Cookie.remove('echo.api');

    window.location.replace(`${window.env.LOGIN_REDIRECT_URL}${window.location.href}`);
};

export const getLoggedUser = async (config) => new Promise(async (resolve, reject) => {
    try {
        resolve(await requestLoggedUser(config));
    } catch (error) {
        reject(error);
    }
});

export const logout = () => {
    Cookie.remove('echo.api');
    window.localStorage.setItem('logout', Date.now());

    window.location.replace(window.env.LOGOUT_REDIRECT_URL);
};

export const syncLogout = (event) => {
    if (event.key === 'logout') {
        Cookie.remove('echo.api');
        window.location.replace('/');
    }
};

export const getToken = (pushToUrl) => {
    const token = Cookie.get('echo.api');

    if (token) {
        return token;
    }

    const { href } = window.location;

    const [urlWithoutToken, urlToken] = href.split('?token=');

    if (urlToken) {
        Cookie.set('echo.api', urlToken, { expires: 0.33 }); // It expires in 8 hours
        pushToUrl(urlWithoutToken);
        return urlToken;
    }

    return null;
};

export const getUrlError = () => {
    const { href } = window.location;

    const [, urlError] = href.split('?error=');

    if (urlError) {
        return (
            <FormattedMessage
                id={translated.login.errors[loginErrors[urlError] || 'default']}
                defaultMessage={translated.login.errors[loginErrors[urlError] || 'default']}
            />
        );
    }

    return null;
};
