import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Switch({ name, className, label, id, onChange, isDisabled }) {
    const labelClass = label ? 'has-label' : '';

    const context = useFormContext();
    const { register, watch, setValue, getValues } = context || {};

    const formValue = watch(name);

    const formProps = useMemo(() => (register ? register(name) : {}), [name, register]);

    const handleOnClick = () => {
        if (onChange) {
            onChange(!formValue);
        }

        setValue(name, !formValue, { shouldDirty: true, shouldValidate: true });
    };

    return (
        <div className={`switch ${className} ${labelClass}`} onClick={handleOnClick}>
            {label && (
                <span>
                    <WrappedFormattedMessage content={label} />
                </span>
            )}
            <input
                id={id}
                type="checkbox"
                tabIndex={isDisabled ? '-1' : '0'}
                className="switch-input"
                checked={getValues(name)}
                {...formProps}
            />
            <label className="switch-label" />
        </div>
    );
}

Switch.defaultProps = {
    id         : '',
    className  : '',
    label      : '',
    onChange   : null,
    isDisabled : false,
};

Switch.propTypes = {
    name       : PropTypes.string.isRequired,
    className  : PropTypes.string,
    label      : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    id         : PropTypes.string,
    onChange   : PropTypes.func,
    isDisabled : PropTypes.bool,
};

export default React.memo(Switch);
