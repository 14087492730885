import { useState, useCallback, useRef } from 'react';

export default function useErrorState(maxNumberOfTries, millisecondsToWait) {
    const [errorState, setErrorState] = useState({
        count               : 0,
        hasReachedLimit     : maxNumberOfTries <= 0,
        isWaitingToTryAgain : false,
    });

    const timerRef = useRef(null);

    const incrementErrorCountAndTryAgain = useCallback((callback) => {
        let retryNumber;
        setErrorState((prevState) => {
            retryNumber = prevState.count;
            return {
                ...prevState,
                isWaitingToTryAgain: !prevState.hasReachedLimit ? true : prevState.isWaitingToTryAgain,
            };
        });

        timerRef.current = setTimeout(async () => {
            await callback(null, null, retryNumber + 1);
            setErrorState((prevState) => ({
                count               : prevState.count + 1,
                hasReachedLimit     : (prevState.count + 1) >= maxNumberOfTries,
                isWaitingToTryAgain : false,
            }));
        }, millisecondsToWait);
    }, [maxNumberOfTries, millisecondsToWait]);

    const resetErrorCount = useCallback(() => {
        setErrorState((prevState) => ({ ...prevState, count: 0, hasReachedLimit: false }));
    }, []);

    const stopRetrying = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);

    return [errorState, incrementErrorCountAndTryAgain, resetErrorCount, stopRetrying];
}
