import { mdiSeal } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const tierId = null;

export default {
    [sectionType.TIERS]: {
        config    : { url: '/panel/tiers', icon: mdiSeal },
        isEnabled : false,
        sections  : {
            [sectionType.TIERS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.TIERS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.TIER_EDITOR]: {
                        ids       : { tierId, self: 'tierId' },
                        resources : { templates: { name: resourceNames.TIER } },
                        config    : { url: { withId: '/(\\d+)', withoutId: '/add' } },
                    },
                },
            },
            [sectionType.TIER_EDITOR]: {
                ids       : { tierId, self: 'tierId' },
                resources : { templates: { name: resourceNames.TIER } },
                config    : { url: { withId: '/(\\d+)', withoutId: '/add' } },
            },
        },
    },
};
