import React from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated/sectionName';

const toCamelCase = (input) => {
    const inputArray = input.split(' ');
    let result = '';

    for (let i = 0, len = inputArray.length; i < len; i++) {
        const currentStr = inputArray[i];

        let tempStr = currentStr.toLowerCase();

        if (i !== 0) {
            // convert first letter to upper case (the word is in lowercase)
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }

        result += tempStr;
    }

    return result;
};

export function getSectionsForBreadcrumb(intl, currentSections = [], section = {}) {
    const sectionsData = [];

    let currentNode = section;

    currentSections.forEach((eachSection) => {
        const sectionNode = currentNode[eachSection];

        if (sectionNode) {
            const { config = {}, isRoot } = sectionNode;
            let { name } = sectionNode;
            const {
                isHiddenInBreadcrumb: isHidden,
                isDefaultSection: isDefault,
                hasTypePreffixInBreadcrumb,
                hasOnlyTypeInBreadcrumb,
                isLockedInBreadcrumb,
                isHiddenInBreadcrumbTabs,
            } = config;

            let preffixedName = '';

            if (translated?.[eachSection]) {
                if (name && !Object.keys(translated).includes(toCamelCase(name))) {
                    preffixedName = `${intl.formatMessage({
                        id             : translated[eachSection],
                        defaultMessage : translated[eachSection],
                    })}: ${name}`;
                } else {
                    preffixedName = <FormattedMessage id={translated[eachSection]} defaultMessage={translated[eachSection]} />;
                }
            }

            if (hasTypePreffixInBreadcrumb) {
                name = preffixedName;
            } else if (hasOnlyTypeInBreadcrumb) {
                name = <FormattedMessage id={translated[eachSection]} defaultMessage={translated[eachSection]} />;
            }

            sectionsData.push({
                name       : preffixedName,
                preffixedName,
                isHidden,
                isHiddenInBreadcrumbTabs,
                isDefault,
                isRoot,
                key        : eachSection,
                isDisabled : isLockedInBreadcrumb,
            });
        } else {
            // When a node is invalid, it will return an empty array;
            sectionsData.splice(0);
        }

        currentNode = sectionNode?.sections || {};
    });

    // Disable the last node
    const lastElements = [...sectionsData].reverse().find((each) => !each.isHidden);
    if (lastElements) {
        lastElements.isDisabled = true;
    }

    return sectionsData;
}

export function isBreadcrumbVisible(currentSections, section) {
    const sections = getSectionsForBreadcrumb(currentSections, section);

    return sections.filter((e) => !e.isHidden && !e.isDisabled).length > 0;
}

export default getSectionsForBreadcrumb;
