const pageStates = Object.freeze({
    loading : 'loading',
    loaded  : 'loaded',
});

const requestStates = Object.freeze({
    start : 'loading',
    end   : 'complete',
    error : 'error',
});

const requestActions = Object.freeze({
    GET    : 'get',
    PATCH  : 'edit',
    PUT    : 'edit',
    POST   : 'create',
    DELETE : 'delete',
});

const requestsToHandle = ['PATCH', 'PUT', 'POST', 'DELETE'];

export { pageStates, requestStates, requestActions, requestsToHandle };
