import React from 'react';
import PropTypes from 'prop-types';

/*
*   Element used when the form uses components completely different to the form inputs.
*   These kind of element are just drawn in the form.
*/
function Custom({ children }) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

Custom.defaultProps = {};

Custom.propTypes = { children: PropTypes.node.isRequired };

export default React.memo(Custom);
