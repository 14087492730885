module.exports = Object.freeze({
    initializeImages          : { error: 'apf.accountStatement.initializeImages ' },
    title                     : 'apf.accountStatement.title',
    banner                    : 'apf.accountStatement.banner',
    sectionsCombined          : 'apf.accountStatement.sectionsCombined',
    sections                  : 'apf.accountStatement.sections',
    footerConfiguration       : 'apf.accountStatement.footerConfiguration',
    styles                    : 'apf.accountStatement.styles',
    fontFamily                : 'apf.accountStatement.fontFamily',
    accentColor               : 'apf.accountStatement.accentColor',
    backgroundColor           : 'apf.accountStatement.backgroundColor',
    combinedLabel             : 'apf.accountStatement.combinedLabel',
    combinedReservationsLabel : 'apf.accountStatement.combinedReservationsLabel',
    columnsTitle              : 'apf.accountStatement.columnsTitle',
    summarizedExpiredBalances : 'apf.accountStatement.summarizedExpiredBalances',
    save                      : {
        success : 'apf.accountStatement.save.success',
        error   : 'apf.accountStatement.save.error',
    },
    preview: {
        title  : 'apf.accountStatement.preview.title',
        error  : 'apf.accountStatement.preview.error',
        helper : 'apf.accountStatement.preview.helper',
    },

    balanceTypeName   : 'apf.accountStatement.balanceName',
    balanceType       : 'apf.accountStatement.balanceType',
    leftShoulder      : 'apf.accountStatement.leftShoulder',
    rightShoulder     : 'apf.accountStatement.rightShoulder',
    effectiveDate     : 'apf.accountStatement.effectiveDate',
    expiryDate        : 'apf.accountStatement.expiryDate',
    year              : 'apf.accountStatement.year',
    anniversary       : 'apf.accountStatement.anniversary',
    date              : 'apf.accountStatement.date',
    contract          : 'apf.accountStatement.contract',
    level             : 'apf.accountStatement.level',
    currency          : 'apf.accountStatement.currency',
    type              : 'apf.accountStatement.type',
    tags              : 'apf.accountStatement.tags',
    category          : 'apf.accountStatement.category',
    balance           : 'apf.accountStatement.balance',
    issued            : 'apf.accountStatement.issued',
    creationDate      : 'apf.accountStatement.creationDate',
    bookingDate       : 'apf.accountStatement.bookingDate',
    property          : 'apf.accountStatement.property',
    hotel             : 'apf.accountStatement.hotel',
    arrivalDate       : 'apf.accountStatement.arrivalDate',
    checkInDate       : 'apf.accountStatement.checkInDate',
    checkOutDate      : 'apf.accountStatement.checkOutDate',
    departureDate     : 'apf.accountStatement.departureDate',
    paymentTotals     : 'apf.accountStatement.paymentTotals',
    conversionDate    : 'apf.accountStatement.conversionDate',
    destinationClub   : 'apf.accountStatement.destinationClub',
    sourceAmount      : 'apf.accountStatement.sourceAmount',
    exchangedAmount   : 'apf.accountStatement.exchangedAmount',
    destinationAmount : 'apf.accountStatement.destinationAmount',
    receivedAmount    : 'apf.accountStatement.receivedAmount',
    fee               : 'apf.accountStatement.fee',
    paymentAmount     : 'apf.accountStatement.paymentAmount',
    reason            : 'apf.accountStatement.reason',
    id                : 'apf.accountStatement.id',
    transactionType   : 'apf.accountStatement.transactionType',
    status            : 'apf.accountStatement.status',
    label             : 'apf.accountStatement.label',
    amount            : 'apf.accountStatement.amount',
    contractID        : 'apf.accountStatement.contractID',
    allocation        : 'apf.accountStatement.allocation',

    expiredBalancesSummary  : 'apf.accountStatement.expiredBalancesSummary',
    activeBalancesSummary   : 'apf.accountStatement.activeBalancesSummary',
    reservationList         : 'apf.accountStatement.reservationList',
    legactReservationList   : 'apf.accountStatement.legacyReservationList',
    extendedBalancesSummary : 'apf.accountStatement.extendedBalanceSummary',
    importedReservationList : 'apf.accountStatement.importedReservationList',
    conversionList          : 'apf.accountStatement.conversionList',
    managementList          : 'apf.accountStatement.managementList',
    adjustmentList          : 'apf.accountStatement.adjustmentList',
    rawTransactionList      : 'apf.accountStatement.rawTransactionList',
    combinedBalancesSummary : 'apf.accountStatement.combinedBalancesSummary',
    reservationId           : 'apf.accountStatement.reservationId',
    propertyName            : 'apf.accountStatement.propertyName',
    conversionCode          : 'apf.accountStatement.conversionCode',
    bookingCode             : 'apf.accountStatement.bookingCode',
    reservationCode         : 'apf.accountStatement.reservationCode',

    summarizedLegacyReservations : 'apf.accountStatement.summarizedLegacyReservations',
    combinedReservations         : 'apf.accountStatement.combinedReservations',
});
