import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import StepBuilder from './StepBuilder';
import RangeConfiguration from './RangeConfiguration';
import ConversionRates from './ConversionRates';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Form, { FormNew } from 'Components/Form';
import Summary from './Summary';
import Grid from 'Components/Grid';
import { scrollToTop } from 'Utils';
import yup from 'Utils/yupHelper';
import convertRanges,
{
    getUsedSteps,
    getUsedBalanceTypesIds,
    transformForSubmit,
    getConversionsInitialValues,
    convertBalanceTypes,
} from './utils';

const schema = yup.object().shape({
    steps          : yup.array().of(yup.number().integer().min(1).max(100)),
    conversionMode : yup.string().required(),
    // Used for validation purposes
    marks          : yup.array().of(yup.number()).unique(translated.rules.dynamicRanges.repeatedFrom),
    ranges         : yup.lazy((value) => {
        const convertedBody = {};

        if (value) {
            Object.entries(value).forEach(([eachKey]) => {
                convertedBody[eachKey] = yup
                    .object()
                    .shape({
                        label: yup.string().max(50).required().unique(translated.rules.dynamicRanges.repeatedRange, ({ from }) => {
                            const labelValue = from?.[0]?.value?.label;

                            const list = Object.values(from[1].value)
                                .filter((e) => !e.isHidden)
                                .map((e) => e.label);
                            const found = list?.filter((e) => e === labelValue) || [];

                            return found?.length <= 1;
                        }),
                    })
                    .nullable(true);
            });
        }

        return yup.object().shape(convertedBody).test(
            'validRanges',
            translated.rules.dynamicRanges.noRanges,
            function test() {
                const { originalValue } = this;

                return originalValue && Object.values(originalValue).find((e) => !e.isHidden);
            },
        );
    }),
    conversions: yup.lazy((value) => {
        const convertedBody = {};

        if (value) {
            Object.entries(value).forEach(([eachKey, eachValue]) => {
                if (eachValue?.isRequired) {
                    convertedBody[eachKey] = yup.object().shape({
                        roomValue:
                            eachKey.match(/-/g)?.length === 2 // We avoid the 'required' field for the currency rates
                                ? yup.number().min(0.0001).max(99999999).required()
                                : yup.number().min(0.0001).max(99999999),
                        serviceValue:
                                eachKey.match(/-/g)?.length === 2 // We avoid the 'required' field for the currency rates
                                    ? yup.number().min(0.0001).max(99999999).required()
                                    : yup.number().min(0.0001).max(99999999),
                        roomValueAreEquals    : yup.bool().nullable(true),
                        serviceValueAreEquals : yup.bool().nullable(true),
                        isRequired            : yup.bool().nullable(true),
                    });
                }
            });
        }

        return yup.object().shape(convertedBody);
    }),
    balanceTypes: yup.array().of(yup.number()).required().min(1),
});

function DynamicRangeEditor({
    ranges, conversionMode, availableBalanceTypes, availableCurrencies, availableConversionModes, onSave, onCancel,
}) {
    useEffect(() => { scrollToTop(); }, []);

    const initialValues = useMemo(() => ({
        availableCurrencies,
        conversionMode,
        availableConversionModes,
        ranges                : convertRanges(ranges),
        marks                 : ranges.map((range) => range.begin),
        balanceTypes          : getUsedBalanceTypesIds(ranges),
        availableBalanceTypes : convertBalanceTypes(availableBalanceTypes, availableCurrencies),
        balanceTypesOptions   : availableBalanceTypes.map((bt) => ({
            value   : bt.value.id,
            content : bt.content,
        })),
        steps       : getUsedSteps(ranges),
        conversions : getConversionsInitialValues(ranges, availableBalanceTypes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const handleOnSubmit = useCallback((values) => {
        onSave(transformForSubmit(values));
    }, [onSave]);

    const buttons = useMemo(() => ([
        <Form.SecondaryNew key="fp" onClick={onCancel} />,
        <Form.PrimaryNew key="fp" />,
    ]), [onCancel]);

    return (
        <Form.WrapperNew
            key="dynamic-ranges-form"
            formId="dynamic-ranges-form"
            initialValues={initialValues}
            schema={schema}
            keepValuesOnInputRemove
        >
            <FormNew onSubmit={handleOnSubmit} avoidConfirmation>
                <SectionsWrapper title={translated.rules.dynamicRanges.title} actionButtons={buttons}>
                    <Grid addMargin="onStackedColumns">
                        <Form.ColumnNew key="components" width={{ base: 12, small: 6 }}>
                            <StepBuilder key="stepBuilder" />

                            <RangeConfiguration key="rangeConfiguration" />

                            <ConversionRates key="conversionRates" />
                        </Form.ColumnNew>
                        <Form.ColumnNew key="summary" width={{ small: 6 }} className="hidden@s">
                            <Summary
                                key="dynamicRangesSummary"
                                className="dynamic-range-summary margin-left-xlarge"
                                title={translated.global.summary}
                            />
                        </Form.ColumnNew>
                    </Grid>
                </SectionsWrapper>
            </FormNew>
        </Form.WrapperNew>
    );
}

DynamicRangeEditor.propTypes = {
    ranges                   : PropTypes.shape({}).isRequired,
    conversionMode           : PropTypes.string.isRequired,
    availableBalanceTypes    : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    availableCurrencies      : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    availableConversionModes : PropTypes.arrayOf(PropTypes.string).isRequired,
    onSave                   : PropTypes.func.isRequired,
    onCancel                 : PropTypes.func.isRequired,
};

export default DynamicRangeEditor;
