import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
/* eslint-disable react-hooks/exhaustive-deps */

function Upload({
    value, isDisabled, id, onChange, onUpload, uploadText, acceptedFiles, onFileSelect,
}) {
    const [filename, setFilename] = useState(translated.global.selectFile);
    const inputRef = useRef();

    const uploadFileGet = useCallback(() => {
        if (inputRef.current?.files?.length) {
            if (inputRef.current.files[0].name) {
                setFilename(inputRef.current.files[0].name);
            }

            if (onChange) {
                onChange({ target: { value: inputRef.current.files[0].name } });
            }

            if (onFileSelect) {
                onFileSelect(inputRef.current.files[0]);
            }
        }
    }, []);

    return (
        <>
            <input
                id={id}
                type="file"
                tabIndex={isDisabled ? '-1' : '0'}
                onChange={uploadFileGet}
                ref={inputRef}
                files={value}
                className="file-upload"
                accept={acceptedFiles}
            />
            <label className="label">
                <WrappedFormattedMessage content={filename} />
            </label>
            {onUpload && uploadText && (
                <Button
                    id={`${id}-upload-button`}
                    variant="unelevated"
                    onClick={() => {
                        onUpload(inputRef?.current?.files?.[0]);
                    }}
                    disabled={!inputRef.current?.files?.length}
                >
                    <WrappedFormattedMessage content={uploadText} />
                </Button>
            )}
        </>
    );
}

Upload.defaultProps = {
    id       : '',
    value    : '',
    onChange : () => {
        // Default
    },
    isDisabled    : false,
    onUpload      : null,
    uploadText    : null,
    acceptedFiles : '*',
    onFileSelect  : null,
};

Upload.propTypes = {
    id            : PropTypes.string,
    value         : PropTypes.string,
    onChange      : PropTypes.func,
    isDisabled    : PropTypes.bool,
    onUpload      : PropTypes.func,
    uploadText    : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    acceptedFiles : PropTypes.string,
    onFileSelect  : PropTypes.func,
};

export default React.memo(Upload);
