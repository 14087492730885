import { mdiAccountTie } from '@mdi/js';
import { sectionType, resourceNames } from 'Constants/types';

const ownerId = null;
const userId = null;
const walletConfigId = null;
const noteId = null;
const contractId = null;
const ruleId = null;
const ruleType = null;
const conversionId = null;

export default {
    [sectionType.OWNERS]: {
        isSelected : true,
        isEnabled  : false,
        config     : {
            url  : '/panel/owners',
            icon : mdiAccountTie,
        },
        sections: {
            [sectionType.OWNERS_LIST]: {
                isSelected : true,
                isRoot     : true,
                config     : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                resources  : { templates: { name: resourceNames.OWNERS } },
            },
            [sectionType.OWNERS_IMPORT]: {
                config    : { url: '/import' },
                resources : { templates: { name: resourceNames.OWNERS_IMPORT } },
                sections  : {
                    [sectionType.OWNERS_GENERAL_IMPORTED] : { config: { isHiddenInBreadcrumb: true } },
                    [sectionType.OWNERS_IMPORT_CSV]       : {
                        pathForResource: [
                            sectionType.OWNERS, sectionType.OWNERS_IMPORT, sectionType.OWNERS_IMPORT_CSV,
                        ],
                        isEmbedded : true,
                        sections   : {
                            [sectionType.OWNERS_IMPORT_CSV_CLIENTS]: {
                                config          : { resourceName: resourceNames.OWNERS_IMPORT_CSV_CLIENTS },
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.OWNERS_IMPORT, sectionType.OWNERS_IMPORT_CSV, sectionType.OWNERS_IMPORT_CSV_CLIENTS,
                                ],
                                isEmbedded: true,
                            },
                            [sectionType.ENTITY_TAGS]: {
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.OWNERS_IMPORT, sectionType.OWNERS_IMPORT_CSV, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                        },
                    },
                },
            },
            [sectionType.OWNERS_CREATOR] : { customProps: { isCreation: true } },
            [sectionType.OWNERS_EDITOR]  : {
                config   : { isHiddenInBreadcrumb: true },
                sections : {
                    [sectionType.OWNERS_GENERAL]: {
                        resources : { templates: { name: resourceNames.OWNER } },
                        ids       : { ownerId, self: 'ownerId' },
                        config    : { isDefaultSection: true, url: '/(\\d+)' },
                    },
                },
            },
            [sectionType.DETAILS]: {
                resources : { templates: { name: resourceNames.OWNER } },
                ids       : { ownerId, self: 'ownerId' },
                config    : {
                    url              : '/(\\d+)/view',
                    cleanWhenLeaving : sectionType.DETAILS,
                },
                sections: {
                    [sectionType.INFO]: {
                        ids       : { ownerId },
                        resources : { templates: { name: resourceNames.OWNER } },
                        config    : {
                            hasOnlyTypeInBreadcrumb : true,
                            isDefaultSection        : true,
                            url                     : '/info',
                            resourceName            : resourceNames.SELF,
                            isTab                   : true,
                        },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.INFO],
                        sections        : {
                            [sectionType.ENTITY_TAGS]: {
                                ids             : { ownerId },
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.DETAILS, sectionType.INFO, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                        },
                    },
                    [sectionType.BOOKINGS]: {
                        ids    : { ownerId },
                        config : {
                            url            : '/bookings',
                            resourceName   : resourceNames.BOOKINGS,
                            requestTimeout : 20000,
                            handledErrors  : { status: [424] },
                            isTab          : true,
                        },
                        resources: { templates: { name: resourceNames.OWNER_BOOKINGS } },
                    },
                    [sectionType.WALLET]: {
                        ids       : { ownerId },
                        resources : { name: resourceNames.WALLET, templates: { name: resourceNames.OWNER_WALLET } },
                        config    : {
                            url          : '/wallet',
                            resourceName : resourceNames.WALLET,
                            isTab        : true,
                        },
                        sections: {
                            [sectionType.WALLET_MANAGER]: {
                                ids       : { ownerId },
                                resources : { templates: { name: resourceNames.OWNER_POINTS } },
                                config    : { resourceName: resourceNames.POINTS, isModal: true },
                            },
                            [sectionType.WALLET_ADD_BALANCE]: { config: { resourceName: resourceNames.addBalance, isModal: true } },
                        },
                    },
                    [sectionType.CONVERSIONS]: {
                        ids       : { ownerId },
                        resources : { templates: { name: resourceNames.OWNER_CONVERSIONS } },
                        config    : {
                            url          : '/conversions',
                            resourceName : resourceNames.CONVERSIONS,
                            isTab        : true,
                        },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.CONVERSIONS],
                        sections        : {
                            [sectionType.CONVERSION_MANAGER]: {
                                ids    : { ownerId },
                                config : { resourceName: resourceNames.POINTS, isModal: true },
                            },
                            [sectionType.REASON]: {
                                ids       : { ownerId, conversionId, self: 'conversionId' },
                                config    : { url: { withId: '/(\\d*)*', withoutId: '/update' }, isModal: true },
                                resources : { templates: { name: resourceNames.OWNER_CONVERSIONS_REASON } },
                            },
                            [sectionType.CONVERSION_MODIFICATION]: {
                                ids       : { ownerId, conversionId, self: 'conversionId' },
                                config    : { url: { withId: '/(\\d*)*', withoutId: '/update' }, isModal: true },
                                resources : { templates: { name: resourceNames.OWNER_CONVERSIONS_MODIFICATION } },
                            },
                        },
                    },
                    [sectionType.NOTES]: {
                        ids    : { ownerId },
                        config : {
                            url          : '/notes',
                            resourceName : resourceNames.NOTES,
                            isTab        : true,
                        },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.NOTES],
                        resources       : { templates: { name: resourceNames.OWNER_NOTES } },
                        sections        : {
                            [sectionType.NOTE]: {
                                ids       : { ownerId, noteId, self: 'noteId' },
                                config    : { url: { withId: '/(\\d*)*', withoutId: '/add' }, isModal: true },
                                resources : { templates: { name: resourceNames.OWNER_NOTE } },
                            },
                        },
                    },
                    [sectionType.ATTACHMENTS]: {
                        ids    : { ownerId },
                        config : {
                            url          : '/attachments',
                            resourceName : resourceNames.ATTACHMENTS,
                            isTab        : true,
                        },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.ATTACHMENTS],
                        sections        : { [sectionType.ATTACHMENT]: { config: { isModal: true } } },
                    },
                    [sectionType.CLIENT_CONTRACTS]: {
                        ids    : { ownerId },
                        config : {
                            url          : '/contracts',
                            resourceName : resourceNames.CLIENT_CONTRACTS,
                            isTab        : true,
                        },
                        resources       : { templates: { name: resourceNames.OWNER_CONTRACTS } },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS],
                    },
                    [sectionType.CONTRACT_EDITOR]: {
                        ids       : { ownerId, contractId, self: 'contractId' },
                        resources : { templates: { name: resourceNames.OWNER_CONTRACT } },
                        config    : {
                            url          : '/contracts/upgrade/(\\d*)*',
                            resourceName : resourceNames.OWNER_UPGRADE_DOWNGRADE,
                        },
                        customProps: { navigateToParent: 'goToOwnerContracts' },
                    },
                    [sectionType.CLIENT_CONTRACTS_EDITOR]: {
                        ids    : { ownerId, contractId, self: 'contractId' },
                        config : {
                            url          : { withId: '/contracts/(\\d*)*', withoutId: '/contracts/add' },
                            resourceName : resourceNames.CLIENT_CONTRACT,
                        },
                        resources   : { templates: { name: resourceNames.OWNER_CONTRACT } },
                        customProps : { navigateToParent: 'goToOwnerContracts' },
                        sections    : {
                            [sectionType.BANKING_CONFIGURATIONS]: {
                                ids        : { ownerId },
                                config     : { resourceName: resourceNames.BANKING_CONFIGURATIONS },
                                isEmbedded : true,
                            },
                            [sectionType.RULES]: {
                                ids        : { ownerId, contractId },
                                config     : { resourceName: resourceNames.RULES },
                                isEmbedded : true,
                            },
                            [sectionType.RULE]: {
                                ids    : { ownerId, contractId, ruleId, ruleType, self: 'ruleId' },
                                config : {
                                    url                        : { withId: '/rules/(\\d*-\\w*)*', withoutId: '/rules/add' },
                                    resourceName               : resourceNames.RULE,
                                    combinedIdKey              : 'type',
                                    hasTypePreffixInBreadcrumb : true,
                                    requestTimeout             : 40000,
                                },
                                resources: { templates: { name: resourceNames.CONTRACT_RULE } },
                            },
                            [sectionType.CONVERSION_RULES]: {
                                ids             : { ownerId },
                                config          : { resourceName: resourceNames.WALLET_CONFIG },
                                isEmbedded      : true,
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR, sectionType.CONVERSION_RULES,
                                ],
                                sections: {
                                    [sectionType.CONVERSION_RULE_EDITOR]: {
                                        ids       : { ownerId, walletConfigId, self: 'walletConfigId' },
                                        resources : { templates: { name: resourceNames.OWNER_CONVERSION_RULE } },
                                        config    : { isHiddenInBreadcrumb: true, isModal: true },
                                    },
                                },
                            },
                            [sectionType.CONVERSION_RULES_ALT]: {
                                ids             : { ownerId },
                                config          : { resourceName: resourceNames.CONVERSION_RULES_ALT },
                                isEmbedded      : true,
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR, sectionType.CONVERSION_RULES_ALT,
                                ],
                            },
                            [sectionType.CONVERSION_RULE_EDITOR_ALT]: { config: { isHiddenInBreadcrumb: true } },
                        },
                    },

                    [sectionType.USERS]: {
                        ids    : { ownerId },
                        config : {
                            url          : '/users',
                            resourceName : resourceNames.USERS,
                            isTab        : true,
                        },
                        customProps     : { navigateToUserEdition: 'goToOwnerUserEdition' },
                        resources       : { templates: { name: resourceNames.OWNER_USERS } },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.USERS],
                        sections        : {
                            [sectionType.RELATIONSHIP]: {
                                resources : { templates: { name: resourceNames.RELATIONSHIP } },
                                config    : { isHiddenInBreadcrumb: true },
                            },
                        },
                    },

                    [sectionType.USER]: {
                        ids    : { ownerId, userId, self: 'userId' },
                        config : {
                            url                  : { withId: '/users/(\\d*)*', withoutId: '/users/add' },
                            resourceName         : resourceNames.OWNER_USER,
                            isHiddenInBreadcrumb : true,
                        },
                        resources       : { templates: { name: resourceNames.OWNER_USER } },
                        pathForResource : [sectionType.OWNERS, sectionType.DETAILS, sectionType.USER],
                        customProps     : { navigateBack: 'goToOwnerUsers' },
                        sections        : {
                            [sectionType.ENTITY_TAGS]: {
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.DETAILS, sectionType.USER, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                            [sectionType.RELATIONSHIPS]: {
                                config          : { resourceName: resourceNames.RELATIONSHIPS },
                                pathForResource : [
                                    sectionType.OWNERS, sectionType.DETAILS, sectionType.USER, sectionType.RELATIONSHIPS,
                                ],
                                isEmbedded : true,
                                sections   : {
                                    [sectionType.RELATIONSHIP]: {
                                        resources : { templates: { name: resourceNames.RELATIONSHIP } },
                                        config    : { isHiddenInBreadcrumb: true },
                                    },
                                },
                            },

                        },
                    },
                },
            },
        },
    },
};
