import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';

function Text({
    label,
    isReadOnlyWithoutInputs,
    isDisabled,
    id,
    name,
    onChange,
    onBlur,
    defaultValue,
}) {
    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    const context = useFormContext();
    const { setValue, control } = context || {};

    const changeTimer = useRef(null);

    const pickerHandleOnChange = useCallback((props) => {
        const newValue = props?.target?.value;

        if (changeTimer.current) {
            clearTimeout(changeTimer.current);
            changeTimer.current = null;
        }

        changeTimer.current = setTimeout(async () => {
            if (onChange) {
                onChange(props);
            }

            setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
        }, 200);
    }, [name, onChange, setValue]);

    const textHandleOnChange = useCallback((props) => {
        const newValue = props?.target?.value;

        if (onChange) {
            onChange(props);
        }

        setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
    }, [name, onChange, setValue]);

    const handleOnBlur = useCallback((props) => {
        if (onBlur) {
            onBlur(props);
        }
    }, [onBlur]);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {/* // We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types). */}
            <Controller
                render={
                    ({ field }) => (
                        <>
                            {isReadOnlyWithoutInputs && (
                                <input
                                    id={id}
                                    type="color"
                                    placeholder={placeholder}
                                    tabIndex={isDisabled ? '-1' : '0'}
                                    autoComplete="no"
                                    className="input-color-box"
                                    {...field}
                                    disabled
                                />
                            )}
                            {!isReadOnlyWithoutInputs && (
                                <>
                                    <input
                                        id={id}
                                        type="color"
                                        placeholder={placeholder}
                                        tabIndex={isDisabled ? '-1' : '0'}
                                        autoComplete="no"
                                        className="input-color-box"
                                        disabled={isDisabled}
                                        {...field}
                                        onChange={pickerHandleOnChange}
                                    />
                                    <input
                                        id={`${id}-text`}
                                        type="text"
                                        onChange={textHandleOnChange}
                                        placeholder={placeholder}
                                        onBlur={handleOnBlur}
                                        tabIndex={isDisabled ? '-1' : '0'}
                                        autoComplete="no"
                                        maxLength="7"
                                        className="input-color-text"
                                        value={field.value}
                                        disabled={isDisabled}
                                    />
                                </>
                            )}
                        </>
                    )
                }
                control={control}
                name={name}
                defaultValue={defaultValue || ''}
            />
        </>
    );
}

Text.defaultProps = {
    id                      : '',
    label                   : '',
    name                    : '',
    defaultValue            : null,
    isReadOnlyWithoutInputs : false,
    onBlur                  : () => {
        // Default
    },
    onChange      : null,
    isDisabled    : false,
    innerInputRef : null,
};

Text.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name                    : PropTypes.string,
    defaultValue            : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Text);
