import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import Alert from 'Components/Alert';
import { maxBalanceAmount, minBalanceAmount } from 'Constants/global';
import panelContext from 'State/panelContext';

const MIN_DATE = '1975-01-01';
const MAX_DATE = '2100-01-01';

function TransactionsEditor({ selectedTransaction, navigator, onFinishSubmit, snackbar, resources }) {
    const { dateManager } = useContext(panelContext);

    const [effectiveDate, setEffectiveDate] = useState(MIN_DATE);
    const [expiryDate, setExpiryDate] = useState(MAX_DATE);

    const handleSubmit = async ({ values }) => {
        try {
            const data = {};
            data.amount = values.amount !== selectedTransaction.amount ? values.amount : undefined;
            data.expiryDate = values.expiryDate !== selectedTransaction.expiryDate ? values.expiryDate : undefined;
            data.effectiveDate = values.effectiveDate !== selectedTransaction.effectiveDate ? values.effectiveDate : undefined;

            await navigator.requestForCurrentPath({ reqConfig: { ...selectedTransaction.links.manage.update, data }, resources });
            snackbar.show({
                content   : translated.owners.balance.editor.balanceUpdated,
                isSuccess : true,
            });
            onFinishSubmit();
        } catch (e) {
            snackbar.show({
                errorLabels : translated.owners.balance.editor.errors,
                content     : translated.owners.balance.editor.errors.defaultError,
                isError     : true,
                error       : e,
            });
        }
    };

    return (
        <Form.Wrapper
            modalProperties={{
                title     : translated.owners.balance.transactions.editor.title,
                className : 'balance-editor-modal',
                buttons   : [
                    <Form.Secondary variant="text" color="primary" key="fs" onClick={onFinishSubmit}>
                        <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                    </Form.Secondary>,
                    <Form.Primary variant="text" color="primary" key="fp">
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    </Form.Primary>,
                ],
            }}
        >
            <table className={`table-details margin-bottom-${selectedTransaction.areDatesEditable ? 'medium' : 'small'}`}>
                <tbody>
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage
                                id={translated.owners.balance.clubPoints.pointsType}
                                defaultMessage={translated.owners.balance.clubPoints.pointsType}
                            />
                        </td>
                        <td className="text-small padding-left-small">{selectedTransaction.activity}</td>
                    </tr>
                    <tr>
                        <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                            <FormattedMessage
                                id={translated.owners.balance.transactions.editor.creation}
                                defaultMessage={translated.owners.balance.transactions.editor.creation}
                            />
                        </td>
                        <td className="text-small padding-left-small">{dateManager.defaultDateFormat(selectedTransaction.creationDatetime)}</td>
                    </tr>
                </tbody>
            </table>
            {!selectedTransaction.areDatesEditable && (
                <>
                    <table className="table-details margin-bottom">
                        <tbody>
                            <tr>
                                <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                                    <FormattedMessage
                                        id={translated.owners.balance.clubPoints.date}
                                        defaultMessage={translated.owners.balance.clubPoints.date}
                                    />
                                </td>
                                <td className="text-small padding-left-small">{dateManager.defaultDateFormat(selectedTransaction.effectiveDate)}</td>
                            </tr>
                            <tr>
                                <td className="text-transform-uppercase text-small text-color-secondary font-weight-bold">
                                    <FormattedMessage
                                        id={translated.owners.balance.clubPoints.expiryDate}
                                        defaultMessage={translated.owners.balance.clubPoints.expiryDate}
                                    />
                                </td>
                                <td className="text-small padding-left-small">{dateManager.defaultDateFormat(selectedTransaction.expiryDate)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Alert
                        id="transaction-warning"
                        title={translated.owners.balance.transactions.info}
                        content={translated.owners.balance.transactions.editor.cantEditDates}
                        className="table-details margin-bottom-medium"
                    />
                </>
            )}
            <Form
                onSubmit={handleSubmit}
                onError={() => snackbar.show({
                    content : translated.owners.balance.editor.errors.defaultError,
                    isError : true,
                })}
                initialValues={selectedTransaction}
            >
                {selectedTransaction.areDatesEditable && (
                    <Form.Input
                        type="picker"
                        submitKey="effectiveDate"
                        label={translated.owners.balance.clubPoints.date}
                        icon={{ name: 'Calendar', position: 'right' }}
                        minDate={MIN_DATE}
                        maxDate={expiryDate}
                        onChange={(val) => setEffectiveDate(val)}
                    />
                )}
                {selectedTransaction.areDatesEditable && (
                    <Form.Input
                        type="picker"
                        submitKey="expiryDate"
                        label={translated.owners.balance.clubPoints.expiryDate}
                        icon={{ name: 'Calendar', position: 'right' }}
                        minDate={effectiveDate}
                        maxDate={MAX_DATE}
                        onChange={(val) => setExpiryDate(val)}
                    />
                )}
                <Form.Input
                    submitKey="amount"
                    type="number"
                    suffix="pts"
                    validations={{ minValue: minBalanceAmount, maxValue: maxBalanceAmount, unary: ['numbers'] }}
                    label={translated.owners.balance.transactions.points}
                />
            </Form>
        </Form.Wrapper>
    );
}

TransactionsEditor.propTypes = {
    navigator           : PropTypes.shape({}).isRequired,
    onFinishSubmit      : PropTypes.func.isRequired,
    snackbar            : PropTypes.shape({}).isRequired,
    resources           : PropTypes.shape({}).isRequired,
    selectedTransaction : PropTypes.arrayOf(
        PropTypes.shape({
            links            : PropTypes.shape({}),
            id               : PropTypes.number,
            activity         : PropTypes.string,
            amount           : PropTypes.string,
            creationDatetime : PropTypes.string,
            expiryDate       : PropTypes.string,
            effectiveDate    : PropTypes.string,
        }),
    ).isRequired,
};

export default TransactionsEditor;
