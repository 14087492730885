/* eslint-disable no-useless-escape */
function encode(string) {
    return encodeURIComponent(string)
        .replace(/\*/g, '%2A')
        .replace(/\-/g, '%2D')
        .replace(/\_/g, '%5F')
        .replace(/\./g, '%2E')
        .replace(/\!/g, '%21')
        .replace(/\~/g, '%7E')
        .replace(/\'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');
}

export default class Resource {

    constructor({ config = {}, queryParams = null }) {
        this.config = config;
        this.queryParams = queryParams;
    }

    static replaceIdWithKey(key, val, urlWithTemplates) {
        let url = urlWithTemplates;
        let id = val;

        if (key !== 'self' && id) {
            if (key === 'ruleId') {
                let type;
                [id, type] = id.split('-');
                url = url.replace(':ruleType', type);
            }
            url = url.replace(`:${key}`, id);
        }

        return url;
    }

    static replaceIds(url, idsList) {
        let urlWithIds = url;
        Object.keys(idsList).forEach((idKey) => {
            urlWithIds = Resource.replaceIdWithKey(idKey, idsList[idKey], urlWithIds);
        });
        return urlWithIds;
    }

    static getLink(link, idsList, queryParams) {
        let { url } = link || {};

        if (idsList && link && url) {
            url = Resource.replaceIds(url, idsList);
        }

        if (queryParams) {
            url = `${url}?${queryParams}`;
        }
        return { ...link, url };
    }

    read(idsList) {
        return Resource.getLink(this?.config?.read, idsList, this.queryParams);
    }

    init(idsList) {
        return Resource.getLink(this?.config?.init, idsList, this.queryParams);
    }

    getRelatedLinks(linkName, idsList) {
        const relatedLinks = {};

        const parameterName = idsList.self;
        const parameter = parameterName && idsList[parameterName];

        Object.entries(this.config).forEach(([eachLinkName, eachLink]) => {
            if (linkName !== eachLinkName && typeof eachLink === 'object' && eachLink?.url) {
                // Work on the links different to the link name, and ignores
                // the values in 'config' that are not links
                if ((parameter && eachLink?.url.indexOf(parameterName) > -1)
                    || (!parameter && eachLink?.url.indexOf(parameterName) === -1)
                ) {
                    relatedLinks[eachLinkName] = Resource.getLink(eachLink, idsList);
                }
            }
        });

        if (parameter) {
            relatedLinks.id = Number(parameter);
        }

        return relatedLinks;
    }

    /**
     * @param {array} fields                    Fields keys sent by translator, available to use as query param on the url
     * @param {object} values                   Object that contains a key - value with we want to sent on the url as param
     * @param {string || array} extraParams     Values that wanted to be added at the end of the url as param, but are independent of the fields
    */

    getLinkAndUrlWithQueryParams({ fields, values, extraParams = null, requiredFields = null }) {
        let finalUrl = this.config.url;
        let queryParams = '';

        if (fields && values) {
            const fieldsKeys = Object.keys(fields);
            const valuesKeys = Object.keys(values);

            valuesKeys.forEach((key) => {
                if (fieldsKeys.includes(key) && values[key]) {
                    if (Array.isArray(values[key])) {
                        values[key].forEach((val, i) => {
                            queryParams += `${fields[key]}${encode(values[key][i])}&`;
                        });
                    } else {
                        queryParams += `${fields[key]}${(encode(values[key]))}&`;
                    }
                }
            });
        }

        finalUrl += queryParams;

        if (extraParams) {
            if (Array.isArray(extraParams)) {
                extraParams.forEach((param) => {
                    if (param) {
                        finalUrl += `${encode(param)}&`;
                    }
                });
            } else {
                finalUrl += encode(extraParams);
            }
        }

        if (requiredFields) {
            // Work similarly to extraParams, but its not a list
            Object.keys(requiredFields).forEach((key) => {
                finalUrl += `${key}=${requiredFields[key]}&`;
            });
        }

        this.config.url = finalUrl;

        return this.config;
    }

}
