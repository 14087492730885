import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Subsection from 'Components/Sections/Subsection';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Alert from 'Components/Alert';
import Form from 'Components/Form';

const getActions = (data, navigator, confirmation, snackbar, resources, intl) => {
    const actions = [
        {
            isEnabled : true,
            content   : translated.global.buttons.disassociate,
            when      : (balanceType) => balanceType?.links?.self?.disassociate,
            callback  : (balanceType) => {
                confirmation.show({
                    message       : translated.balanceTypes.delete.confirmation,
                    acceptMessage : translated.global.buttons.disassociate,
                    onAccept      : async () => {
                        const { id } = balanceType;
                        const reqConfig = {
                            ...balanceType.links.self.disassociate,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : [id],
                            shouldReloadData : true,
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS_BALANCE_TYPES, isEmbedded: true, resources });

                            snackbar.show({
                                content   : translated.clubs.balanceTypes.delete.success,
                                isSuccess : true,
                            });
                        } catch (error) {
                            snackbar.show({
                                content     : translated.clubs.balanceTypes.delete.defaultError,
                                errorLabels : translated.clubs.balanceTypes.delete,
                                isError     : true,
                                error,
                            });
                        }
                    },
                });
            },
            key               : 'remove',
            icon              : 'LinkVariantOff',
            isOutsideDropdown : true,
            tooltip           : intl.formatMessage({
                id             : translated.clubs.balanceTypes.remove,
                defaultMessage : translated.clubs.balanceTypes.remove,
            }),
        },
    ];

    return actions;
};

const hasElement = (list, id) => !!list.find((e) => e.id === id);

const hasListChanged = (list, ids) => !!(list.length !== ids.length || ids.find((e) => hasElement(list, e)));

function BalanceTypes({ data, fetching, resources, customClass }) {
    const intl = useIntl();
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const [availableBalanceTypes, setAvailableBalanceTypes] = useState({ loaded: false, list: null, error: null, selected: null });
    // List of balance types that are filtered of the available balance types
    const loadedBalanceTypes = useRef(null);

    const { data: list } = data || {};
    const { available } = resources || {};

    const updateList = useCallback(async () => {
        try {
            loadedBalanceTypes.current = list?.map((e) => e.id);

            const response = await navigator.directRequest({ ...available.all });

            const converted = response?.data
                ?.filter((e) => !hasElement(list, e.id))
                .map((e) => ({ id: e.id, value: { id: e.id, name: e.name }, content: e.name }));

            setAvailableBalanceTypes({ loaded: true, list: converted });
        } catch (e) {
            setAvailableBalanceTypes({ list: [], error: true });

            snackbar.show({
                content : translated.clubs.balanceTypes.initializationError,
                isError : true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        if (available?.add && available?.all) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loadedBalanceTypes.current && available?.add && available?.all && hasListChanged(list, loadedBalanceTypes.current)) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    const handleOnSubmit = async ({ values }) => {
        const { balanceTypes } = values;
        let promises = [];

        if (balanceTypes?.length) {
            promises = balanceTypes.map(
                async (each) => new Promise(async (resolve, reject) => {
                    try {
                        const reqConfig = {
                            ...available.add,
                            data             : { id: each?.id },
                            isCritical       : false,
                            isGlobal         : false,
                            shouldReloadData : true,
                        };

                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS_BALANCE_TYPES, isEmbedded: true, resources });

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }),
            );

            return Promise.all(promises);
        }

        return null;
    };

    const handleOnFinish = ({ values }) => {
        if (values?.balanceTypes?.length) {
            setAvailableBalanceTypes({ loaded: false, list: [], selected: null });
        }
    };

    const handleOnError = () => {
        snackbar.show({
            content : translated.clubs.balanceTypes.addBalanceTypeError,
            isError : true,
        });
    };

    const handleOnChange = (newValue) => {
        setAvailableBalanceTypes((prev) => ({ ...prev, selected: newValue }));
    };

    const rows = { actions: getActions(list, navigator, confirmation, snackbar, resources, intl) };

    return (
        <Subsection
            className={`margin-top-small ${customClass}`}
            title={translated.clubs.balanceTypes.title}
        >
            {available?.add && available?.all && !availableBalanceTypes.error && (
                <Form.Wrapper>
                    <Form
                        id="add-clubs-balance-type-form"
                        onSubmit={handleOnSubmit}
                        onFinish={handleOnFinish}
                        onError={handleOnError}
                        isDisabled={!availableBalanceTypes.loaded}
                        avoidSetComponentWithChanges
                    >
                        <Form.Column width={{ base: 9, small: 5 }}>
                            <Form.Input
                                isDense
                                isMultiSelect
                                isBasedOnState
                                avoidMarkAsChangedOnValidationsChange
                                type="select"
                                submitKey="balanceTypes"
                                label={translated.clubs.balanceTypes.associate}
                                options={availableBalanceTypes?.list || []}
                                onChange={handleOnChange}
                                value={availableBalanceTypes?.selected}
                            />
                        </Form.Column>
                        <Form.Column width={{ base: 3, small: 7 }}>
                            <Form.Primary variant="outlined" color="primary" key="fp" isDisabled={!availableBalanceTypes?.list?.length}>
                                <FormattedMessage defaultMessage={translated.global.buttons.add} id={translated.global.buttons.add} />
                            </Form.Primary>
                        </Form.Column>
                    </Form>
                </Form.Wrapper>
            )}

            <Table
                id="clubs-balance-types"
                key="clubs-balance-types"
                className="margin-top-small"
                hasTools={false}
                columns={[
                    {
                        title       : translated.global.name,
                        key         : 'name',
                        isVisible   : true,
                        hasMaxWidth : true,
                    },
                    {
                        title    : translated.global.status,
                        key      : 'isActive',
                        whenTrue : {
                            text      : translated.global.state.enabled,
                            className : 'status status-enabled',
                        },
                        whenFalse: {
                            text      : translated.global.state.disabled,
                            className : 'status status-disabled',
                        },
                        isVisible: true,
                    },
                    {
                        title     : translated.global.id,
                        key       : 'id',
                        isVisible : true,
                        isNumeric : true,
                    },
                ]}
                rows={rows}
                items={data ? data.data : []}
                loadingIds={fetching.ids}
                whenEmpty={(
                    <Alert
                        id="clubs-balance-types-empty"
                        content={translated.clubs.balanceTypes.empty}
                        className="margin-top-small"
                    />
                )}
            />
        </Subsection>
    );
}

BalanceTypes.defaultProps = {
    data        : [],
    fetching    : {},
    customClass : '',
};

BalanceTypes.propTypes = {
    data        : PropTypes.shape({}),
    fetching    : PropTypes.shape({}),
    resources   : PropTypes.shape({}).isRequired,
    customClass : PropTypes.string,
};

BalanceTypes.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-xxlarge" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 6 }}>
                <Skeleton.Title isSubHeading width={165} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-small">
                <Grid>
                    <Grid.Column width={{ base: 4 }}>
                        <Skeleton.Form type="input" />
                    </Grid.Column>
                    <Grid.Column width={{ base: 8 }}>
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
                <Skeleton.Table hasTitle={false} hasActions={false} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(BalanceTypes);
