import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from 'Components/Button';
import Status from 'Components/Status';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

export function Card({
    title,
    subtitle,
    secondaryButton,
    primaryButton,
    customButton,
    status,
    className,
    picture,
    unelevated,
    pictureOnHeader,
    children,
    id,
    expandableContent,
    headerButton,
    secondaryRightButton,
    primaryRightButton,
    customRightButton,
    isToggleVisible,
    onToggle,
    cardRef,
}) {
    const intl = useIntl();
    const [isVisible, setVisibility] = useState(isToggleVisible);
    const element = useRef();

    useEffect(() => setVisibility(isToggleVisible), [isToggleVisible]);
    useImperativeHandle(cardRef, () => ({ setVisibility, isVisible, element }));

    // Undef: Hide the image. Null or Empty: Shows the default image and a String displays the actual image.
    const pictureSrc = !picture && picture !== undefined ? '/static/img/no-user.svg' : picture;
    const hoverClass = unelevated ? '' : 'card-hover';

    const shouldRenderLeftButtons = secondaryButton || primaryButton || customButton;
    const shouldRenderRightButtons = secondaryRightButton || primaryRightButton || customRightButton;

    const handleToggle = useCallback(
        (newValue) => {
            setVisibility(!newValue);

            if (onToggle) {
                onToggle(!newValue);
            }
        },
        [onToggle],
    );

    return (
        <div id={id} ref={element} className={`card ${hoverClass} ${className}`}>
            {pictureSrc && (
                <div className="card-picture">
                    <img
                        src={pictureSrc}
                        alt={intl.formatMessage({
                            id             : translated.global.card.avatarError,
                            defaultMessage : translated.global.card.avatarError,
                        })}
                    />
                </div>
            )}

            {(pictureOnHeader || title || subtitle || expandableContent || headerButton || status) && (
                <div className="card-header">
                    <div className="card-data">
                        {pictureOnHeader && (
                            <div className="card-picture">
                                <img
                                    src={pictureSrc}
                                    alt={intl.formatMessage({
                                        id             : translated.global.card.avatarError,
                                        defaultMessage : translated.global.card.avatarError,
                                    })}
                                />
                            </div>
                        )}
                        <div className="card-headings">
                            <div className="card-title">
                                <WrappedFormattedMessage content={title} />
                                {status && <Status status={status} className="margin-left-small" />}
                            </div>
                            {subtitle && (
                                <div className="card-subtitle">
                                    <WrappedFormattedMessage content={subtitle} />
                                </div>
                            )}
                        </div>
                    </div>

                    {(expandableContent || headerButton) && (
                        <div className="card-toggle-expand">
                            {expandableContent && (
                                <Button
                                    id={`${id}-expand-button`}
                                    icon="ChevronDown"
                                    className={`card-toggle-expand-button ${isVisible ? 'is-visible' : ''}`}
                                    onClick={() => handleToggle(isVisible)}
                                />
                            )}
                            {headerButton}
                        </div>
                    )}
                </div>
            )}

            {((expandableContent && isVisible) || !expandableContent) && <div className="card-body">{children}</div>}

            {(shouldRenderLeftButtons || shouldRenderRightButtons) && (
                <div className="card-actions-wrapper">
                    {shouldRenderLeftButtons && (
                        <div className="card-actions">
                            {customButton}
                            {secondaryButton && (
                                <Button
                                    id={secondaryButton.id}
                                    variant="text"
                                    color="primary"
                                    onClick={secondaryButton.onClick}
                                    disabled={secondaryButton.isEnabled === false}
                                >
                                    <WrappedFormattedMessage content={secondaryButton.text} />
                                </Button>
                            )}
                            {primaryButton && (
                                <Button
                                    id={primaryButton.id}
                                    variant="text"
                                    color="primary"
                                    onClick={primaryButton.onClick}
                                    disabled={primaryButton.isEnabled === false}
                                >
                                    <WrappedFormattedMessage content={primaryButton.text} />
                                </Button>
                            )}
                        </div>
                    )}

                    {shouldRenderRightButtons && (
                        <div className="card-extra-actions">
                            {customRightButton}
                            {secondaryRightButton && (
                                <Button
                                    id={secondaryRightButton.id}
                                    icon={secondaryRightButton.icon}
                                    onClick={secondaryRightButton.onClick}
                                    disabled={secondaryRightButton.isEnabled === false}
                                    tooltip={secondaryRightButton.tooltip}
                                    color={secondaryRightButton.color}
                                />
                            )}
                            {primaryRightButton && (
                                <Button
                                    id={primaryRightButton.id}
                                    icon={primaryRightButton.icon}
                                    onClick={primaryRightButton.onClick}
                                    disabled={primaryRightButton.isEnabled === false}
                                    tooltip={primaryRightButton.tooltip}
                                    color={primaryRightButton.color}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

Card.defaultProps = {
    id                   : '',
    className            : '',
    subtitle             : '',
    status               : null,
    picture              : undefined,
    unelevated           : false,
    expandableContent    : false,
    pictureOnHeader      : false,
    isToggleVisible      : false,
    onToggle             : null,
    headerButton         : null,
    secondaryButton      : null,
    primaryButton        : null,
    customButton         : null,
    secondaryRightButton : null,
    primaryRightButton   : null,
    customRightButton    : null,
    cardRef              : () => {
        // Default
    },
};

Card.propTypes = {
    id                : PropTypes.string,
    className         : PropTypes.string,
    title             : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    subtitle          : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    status            : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    picture           : PropTypes.string,
    unelevated        : PropTypes.bool,
    expandableContent : PropTypes.bool,
    pictureOnHeader   : PropTypes.bool,
    isToggleVisible   : PropTypes.bool,
    onToggle          : PropTypes.func,
    children          : PropTypes.node.isRequired,
    customButton      : PropTypes.shape({}),
    customRightButton : PropTypes.shape({}),
    headerButton      : PropTypes.shape({}),
    cardRef           : PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    secondaryButton   : PropTypes.shape({
        id        : PropTypes.string,
        text      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        color     : PropTypes.string,
    }),
    primaryButton: PropTypes.shape({
        id        : PropTypes.string,
        text      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        color     : PropTypes.string,
    }),
    secondaryRightButton: PropTypes.shape({
        id        : PropTypes.string,
        icon      : PropTypes.string,
        tooltip   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        color     : PropTypes.string,
    }),
    primaryRightButton: PropTypes.shape({
        id        : PropTypes.string,
        icon      : PropTypes.string,
        tooltip   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
        color     : PropTypes.string,
    }),
};

function CardWithRef(props, ref) {
    return <Card {...props} cardRef={ref} />;
}
CardWithRef.displayName = 'Card';

export default forwardRef(CardWithRef);
