import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import IconPath from 'Utils/IconsPath';
import { useEventScroll } from 'Hooks';
import Icon from '@mdi/react';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Submenu({ isEnabled, className, classNameSubmenu, children, title, id }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownItemClass = getClassName({ isDisabled: !(isEnabled || isEnabled === undefined) }, 'dropdown-menu-item', className);

    const submenuRef = useRef();
    const submenuTriggerRef = useRef();

    const updateComputedStyles = () => {
        if (
            submenuRef.current
            && submenuRef.current.getBoundingClientRect()
            && submenuTriggerRef.current
            && submenuTriggerRef.current.getBoundingClientRect()
        ) {
            const { top, left, height, width : elementWidth } = submenuTriggerRef.current.getBoundingClientRect();
            const { width, height: submenuHeight } = submenuRef.current.getBoundingClientRect();

            const hasSpaceOnBottom = top + height + submenuHeight <= window.innerHeight;
            const hasSpaceOnTop = top + height > submenuHeight;

            // We check if the element fits on the left side.
            const computedLeft = left > width ? left - width : left + elementWidth;
            let computedTop;

            if (hasSpaceOnBottom) {
                computedTop = top;
            } else if (hasSpaceOnTop) {
                computedTop = top + height - submenuHeight;
            } else {
                computedTop = 0;
            }

            submenuRef.current.style.top = `${computedTop}px`;
            submenuRef.current.style.left = `${computedLeft}px`;
        }
    };

    useEventScroll(submenuTriggerRef.current, updateComputedStyles, () => isOpen);

    useEffect(() => {
        if (isOpen) {
            updateComputedStyles();
        }
    }, [isOpen]);

    return (
        <li
            className={dropdownItemClass}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            ref={submenuTriggerRef}
            key={id}
            id={id}
        >
            <WrappedFormattedMessage content={title} />
            <Icon className="mdi-icon submenu-icon" path={IconPath.mdiMenuRight} />
            <ul id={`${id}-ul`} data-id={id} className={getClassName({ isOpen }, 'dropdown-menu dropdown-submenu', classNameSubmenu)} ref={submenuRef}>
                {children}
            </ul>
        </li>
    );
}

Submenu.defaultProps = {
    isEnabled        : true,
    className        : '',
    classNameSubmenu : '',
};

Submenu.propTypes = {
    isEnabled        : PropTypes.bool,
    children         : PropTypes.node.isRequired,
    id               : PropTypes.string.isRequired,
    classNameSubmenu : PropTypes.string,
    className        : PropTypes.string,
    title            : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
};

export default Submenu;
