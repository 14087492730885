import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Card from 'Components/Card';
import Button, { Link } from 'Components/Button';
import FileDetails from './FileDetails';
import FileContentOwners from './FileContentOwners';
import { sectionType } from 'constants/types';
import Subsection from 'Components/Sections/Subsection';
import EntityTags from 'Components/Sections/Tags/EntityTags';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';

function FileContent({ data, sections, reloadList }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const {
        id, filename, invalidRows, creationDatetime, links, updateDatetime, status,
    } = data;

    const deleteCsv = async () => {
        confirmation.show({
            message  : translated.imports.delete.confirmation,
            onAccept : async () => {
                try {
                    await navigator.directRequest(links?.self?.delete);

                    snackbar.show({ content: translated.imports.delete.success, isSuccess: true });

                    reloadList(null, true);
                } catch (e) {
                    snackbar.show({ error: e, content: translated.imports.delete.error, isError: true });
                }
            },
        });
    };

    const headerButtons = [];

    if (links?.self?.delete) {
        const cantDelete = Array.isArray(data?.onboardedRows) && data.onboardedRows.length > 0;

        headerButtons.push(
            <Button
                id="csv-delete"
                icon="MinusCircle"
                onClick={deleteCsv}
                tooltip={cantDelete ? translated.imports.cantDeleteTheFile : translated.global.buttons.remove}
                disabled={cantDelete}
                showTooltipDisabled
            />,
        );
    }

    if (links?.self?.download) {
        headerButtons.push(
            <Link
                id="csv-download"
                withoutRipple
                variant="link"
                link={links?.self?.download}
                icon="FileDownload"
                tooltip={translated.owners.imports.download}
            />,
        );
    }

    return (
        <Subsection>
            <Card
                id="file-info"
                className="csv-card"
                title={filename}
                status={status}
                headerButton={headerButtons}
                unelevated
            >
                <FileDetails
                    creation={creationDatetime}
                    modification={updateDatetime}
                    invalidRows={invalidRows}
                    id={id}
                    className="margin-bottom-small"
                    sections={sections}
                />
            </Card>

            {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                <EntityTags
                    {...sections[sectionType.ENTITY_TAGS]}
                    resources={{
                        ...sections[sectionType.ENTITY_TAGS].resources,
                        available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                    }}
                    customClass="margin-top-xxlarge"
                />
            )}

            {links?.[sections[sectionType.OWNERS_IMPORT_CSV_CLIENTS]?.config?.resourceName] && (
                <FileContentOwners
                    {...sections[sectionType.OWNERS_IMPORT_CSV_CLIENTS]}
                    resources={{
                        ...sections[sectionType.OWNERS_IMPORT_CSV_CLIENTS].resources,
                        available: links[sections[sectionType.OWNERS_IMPORT_CSV_CLIENTS].config.resourceName],
                    }}
                />
            )}
        </Subsection>
    );
}

FileContent.defaultProps = {};

FileContent.propTypes = {
    data       : PropTypes.shape({}).isRequired,
    resources  : PropTypes.shape({}).isRequired,
    sections   : PropTypes.shape({}).isRequired,
    reloadList : PropTypes.func.isRequired,
};

FileContent.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Card unelevated key="csv" hasPicture={false} hasDescription={{ paragraphs: 2 }} hasActions={false} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Title />
                <Skeleton.Form type="input" smallInput />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(FileContent);
