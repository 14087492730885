import { useEffect, useRef } from 'react';

/*
    It's used when the useEffect hook is not enough.

    UseEffect doesn't work well for arrays and objects, as it triggers when the pointer changes not the content.
*/

function deepCompareEquals(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

function useDeepCompareMemoize(value) {
    const ref = useRef();
    if (!deepCompareEquals(value, ref.current)) {
        ref.current = JSON.parse(JSON.stringify(value));
    }

    return ref.current;
}

export default function useDeepEffect(callback, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, useDeepCompareMemoize(dependencies));
}
