export default class FormError extends Error {

    constructor(snackbarMessage, originalError = null, errorLabels = null) {
        super('Error');
        this.name = 'FormError';
        this.snackbarMessage = snackbarMessage;
        this.originalError = originalError;
        this.errorLabels = { ...errorLabels };
    }

}
