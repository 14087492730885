import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import withRequest from 'Components/Sections/withRequest';

const getClubsActions = (data, navigator, confirmation, snackbar, handleClickChangeStatus, resources) => [
    {
        content  : translated.global.buttons.disable,
        callback : (club) => handleClickChangeStatus(club, false),
        key      : 'disable',
        when     : (club) => club?.links?.self?.patch && club.isActive,
    },
    {
        content  : translated.global.buttons.enable,
        callback : (club) => handleClickChangeStatus(club, true),
        key      : 'enable',
        when     : (club) => club?.links?.self?.patch && !club.isActive,
    },
    {
        content  : translated.global.buttons.delete,
        callback : (club) => {
            confirmation.show({
                message       : translated.externalClubs.delete.confirmation,
                acceptMessage : translated.global.buttons.delete,
                onAccept      : async () => {
                    const { id } = club;
                    const reqConfig = {
                        ...club.links.self.delete,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [id],
                        shouldReloadData : true,
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.EXTERNAL_CLUBS, resources });

                        snackbar.show({ content: translated.externalClubs.delete.success, isSuccess: true });
                    } catch (error) {
                        snackbar.show({
                            errorLabels : translated.externalClubs.delete,
                            content     : translated.externalClubs.delete.defaultError,
                            isError     : true,
                            error,
                        });
                    }
                },
            });
        },
        key  : 'delete',
        when : (club) => club?.links?.self?.delete,
    },
    {
        content           : translated.global.buttons.edit,
        callback          : (club) => navigator.goToExternalClubEditor({ available: club && club.links ? club.links.self : {} }),
        key               : 'edit',
        isOutsideDropdown : true,
        icon              : 'Pencil',
        tooltip           : translated.global.buttons.edit,
        when              : (club) => club?.links?.self?.update,
    },
];

function ExternalClubsList({ data, sections, fetching, reloadData, resources }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const handleOnAddClick = () => navigator.goToExternalClubEditor({ available: data && data.links ? data.links.self : {} });

    const handleClickChangeStatus = async (club, enable) => {
        const { id, links } = club;
        const reqConfig = {
            ...links.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.EXTERNAL_CLUBS, resources });

            snackbar.show({ content: enable ? translated.externalClubs.enable.success : translated.externalClubs.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.externalClubs.enable.error : translated.externalClubs.disable.error,
                isError : true,
                error,
            });
        }
    };

    const { links, data: clubs, order, pagination } = data || {};

    const rows = { actions: getClubsActions(clubs, navigator, confirmation, snackbar, handleClickChangeStatus, resources) };

    const { Section, props: subsecProps } = Sections.get(sections);

    return (
        <>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            <Wrapper
                title={translated.externalClubs.title}
                actionButtons={
                    links?.self?.create
                        ? [
                            <Button id="external-clubs-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Button>,
                        ]
                        : []
                }
            >
                <Table
                    id="external-clubs"
                    key="external-clubs"
                    columns={[
                        {
                            title       : translated.global.name,
                            key         : 'name',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title    : translated.global.status,
                            key      : 'isActive',
                            whenTrue : {
                                text      : translated.global.state.enabled,
                                className : 'status status-enabled',
                            },
                            whenFalse: {
                                text      : translated.global.state.disabled,
                                className : 'status status-disabled',
                            },
                            isVisible: true,
                        },
                        {
                            title     : translated.global.id,
                            key       : 'id',
                            isVisible : true,
                            isNumeric : true,
                        },
                    ]}
                    rows={rows}
                    items={data ? data.data : []}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    whenEmpty={(
                        <Alert
                            id="external-clubs-empty"
                            content={translated.externalClubs.empty}
                            actionText={translated.global.buttons.add}
                            onClick={handleOnAddClick}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

ExternalClubsList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={300} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

ExternalClubsList.defaultProps = {
    data     : [],
    fetching : {},
};

ExternalClubsList.propTypes = {
    sections   : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({}),
    fetching   : PropTypes.shape({}),
    reloadData : PropTypes.func.isRequired,
    resources  : PropTypes.shape({}).isRequired,
};

export default withRequest(ExternalClubsList);
