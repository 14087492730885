import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from './Input/Toggle';
import getClassName from 'Utils/getClassName';
import { analyzeValue } from './Validator';
import { transformToSnake } from 'Utils';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

/**
 * Component used not only to group inputs inside the form, but also to manage the status
 * of Radio and Chip types components, if there is no value in the radios, it uses an index
 */
function Group({
    id,
    formId,
    name,
    type,
    value,
    label,
    children,
    className,
    isSelectable,
    isSelected,
    submitKey,
    summaryFormat,
    onSwitch,
    onSelect,
    onChange,
    isReadOnlyWithoutInputs,
    isDisabled,
    isBasedOnState,
    isMultiSelect,
    ...restOfProps
}) {
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [selectedMulti, setSelectedMulti] = useState([]);

    useEffect(() => {
        const notMultiSelectAndChanged = !isMultiSelect && value !== selectedRadio;
        // When its not a multiSelect and the value changed or when its multiSelect, chip and the value changed,
        // we have to update the internal state and "notify" the Form about the changes.
        if (notMultiSelectAndChanged || (isMultiSelect && type === 'chip' && value !== null && selectedMulti.toString() !== value.toString())) {
            if (isBasedOnState) {
                onChange(value, submitKey);
            }

            if (notMultiSelectAndChanged) {
                setSelectedRadio(value);
            } else {
                setSelectedMulti(value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const shouldPassPropsToChildren = name || type || onChange || value !== undefined;

    // Checkbox Toggle
    const handleOnSelect = (checked) => {
        if (onSelect) {
            onSelect(checked);
        }
        if (onSwitch) {
            onSwitch(checked);
        }
    };

    const isChildrenSelected = (selected, childValue, index) => {
        if (selected !== null) {
            if (typeof childValue !== 'undefined') {
                return Array.isArray(selected) ? selected.includes(childValue) : selected === childValue;
            }

            return Array.isArray(selected) ? selected.includes(index) : selected === index;
        }

        // Depending on whether we have value set in the Group, in the Children
        // or do not have any, is how we will initialize the selectedRadio
        if (index === 0) {
            let val = 0;
            if (analyzeValue(value, isMultiSelect).isFullyComplete) {
                val = value;
            } else if (analyzeValue(childValue, isMultiSelect).isFullyComplete) {
                val = childValue;
            }

            setSelectedRadio(val);
            return val;
        }
        return null;
    };

    let globalProps = {};
    globalProps = name ? { name } : globalProps;
    globalProps = type ? { ...globalProps, type } : globalProps;

    const groupClassName = getClassName(
        { isSelected, isDisabled, withCheckbox: isSelectable, isChip: type === 'chip' },
        'form-field',
        'is-group',
        className,
    );

    let convertedId = id || '';
    if (!id) {
        if (formId) {
            convertedId += `${convertedId ? '-' : ''}${formId}`;
        }
        if (submitKey) {
            convertedId += `${convertedId ? '-' : ''}${transformToSnake(submitKey)}`;
        }
    }

    /* eslint-disable react/no-array-index-key */
    return (
        <div className={groupClassName}>
            {!isReadOnlyWithoutInputs && isSelectable && (
                <Toggle onChange={handleOnSelect} value={isSelected} id={convertedId ? `${convertedId}-check` : null} />
            )}
            {label && (
                <label className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {shouldPassPropsToChildren
                ? React.Children.map(
                    children,
                    (child, index) => child
                          && React.cloneElement(child, {
                              ...child.props,
                              ...globalProps,
                              id      : child.props.id || `${convertedId}${child?.props?.value != null ? `-${child?.props?.value}` : ''}`,
                              isReadOnlyWithoutInputs,
                              index,
                              key     : `${restOfProps.submitKey}_${index}`,
                              checked :
                                  isMultiSelect && type === 'chip'
                                      ? isChildrenSelected(selectedMulti, child.props.value, index)
                                      : isChildrenSelected(selectedRadio, child.props.value, index),
                              onChange: (val, childSubmitKey, properties) => {
                                  const actualValue = val === undefined || val === '' ? index : val;
                                  let newValue;

                                  if (isMultiSelect && type === 'chip') {
                                      if (selectedMulti.includes(actualValue)) {
                                          newValue = selectedMulti.filter((stateValue) => stateValue !== actualValue);
                                          setSelectedMulti(newValue);
                                      } else {
                                          newValue = [...selectedMulti, actualValue];
                                          setSelectedMulti(newValue);
                                      }
                                  } else {
                                      setSelectedRadio(actualValue);
                                  }

                                  if (child.props.onChange) {
                                      child.props.onChange(actualValue, submitKey || childSubmitKey, { ...properties, isSelectable });
                                  }

                                  if (onChange) {
                                      if (isMultiSelect && type === 'chip') {
                                          onChange(newValue, submitKey || childSubmitKey, { ...properties, summaryFormat, isSelectable });
                                      } else {
                                          onChange(actualValue, submitKey || childSubmitKey, { ...properties, summaryFormat, isSelectable });
                                      }
                                  }
                              },
                              isDisabled: isDisabled || child.props.isDisabled || (isSelectable && !isSelected),
                          }),
                )
                : children}
        </div>
    );
}

Group.defaultProps = {
    id                      : '',
    formId                  : '',
    name                    : '',
    type                    : '',
    className               : '',
    label                   : '',
    submitKey               : '',
    value                   : null,
    isSelectable            : false,
    isSelected              : false,
    isDisabled              : false,
    icon                    : null,
    onChange                : null,
    onSelect                : null,
    onSwitch                : null,
    summaryFormat           : null,
    isReadOnlyWithoutInputs : false,
    isBasedOnState          : false,
    isMultiSelect           : false,
};

Group.propTypes = {
    id                      : PropTypes.string,
    formId                  : PropTypes.string,
    className               : PropTypes.string,
    name                    : PropTypes.string,
    type                    : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    submitKey               : PropTypes.string,
    isSelectable            : PropTypes.bool,
    isSelected              : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    children                : PropTypes.node.isRequired,
    onChange                : PropTypes.func,
    onSwitch                : PropTypes.func,
    onSelect                : PropTypes.func,
    summaryFormat           : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isBasedOnState          : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    icon                    : PropTypes.shape({
        name     : PropTypes.string,
        position : PropTypes.string,
    }),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({}), PropTypes.array, PropTypes.bool]),
};

export default Group;
