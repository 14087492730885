import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Subsection from 'Components/Sections/Subsection';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import Button from 'Components/Button';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';

export function List({ data, isEmbedded, fetching, reloadData, resources }) {
    const intl = useIntl();
    const { navigator, confirmation, snackbar } = useContext(PanelContext);

    const { data: items, links, order } = data;

    const handleClickChangeActive = async (contractType, isActive) => {
        const { links: contractTypeLinks } = contractType;
        const reqConfig = {
            ...contractTypeLinks.self.patch,
            shouldReloadData : true,
            isCritical       : false,
            isGlobal         : false,
            ids              : [contractType.id],
            data             : { isActive },
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.CONTRACT_TYPES, resources });

            snackbar.show({
                content   : isActive ? translated.contractTypes.enable.success : translated.contractTypes.disable.success,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content : isActive ? translated.contractTypes.enable.error : translated.contractTypes.disable.error,
                isError : true,
                error   : e,
            });
        }
    };

    const actions = [
        {
            content : translated.global.buttons.edit,
            key     : 'edit',
            icon    : 'Pencil',
            tooltip : intl.formatMessage({
                id             : translated.global.buttons.edit,
                defaultMessage : translated.global.buttons.edit,
            }),
            callback: (contractType) => {
                navigator.goToClubTierContractTypeEditor({
                    available : contractType.links.self,
                    current   : 'read',
                });
            },
            isOutsideDropdown : true,
            when              : (ct) => ct?.links?.self?.update,
        },
        {
            content  : translated.global.buttons.enable,
            callback : (ct) => handleClickChangeActive(ct, true),
            key      : 'enable',
            when     : (ct) => !ct.isActive && ct.links?.self?.patch,
        },
        {
            content  : translated.global.buttons.disable,
            callback : (ct) => handleClickChangeActive(ct, false),
            key      : 'disable',
            when     : (ct) => ct.isActive && ct.links?.self?.patch,
        },
        {
            content  : translated.global.buttons.delete,
            key      : 'delete',
            when     : (ct) => ct?.links?.self?.delete,
            callback : async (contractType) => {
                const { id } = contractType;
                confirmation.show({
                    // eslint-disable-next-line max-len
                    message       : translated.contractTypes.delete.confirm,
                    acceptMessage : translated.global.buttons.delete,
                    onAccept      : async () => {
                        try {
                            const reqConfig = {
                                ...contractType.links.self.delete,
                                isCritical       : false,
                                isGlobal         : false,
                                ids              : [id],
                                shouldReloadData : true,
                            };
                            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.CONTRACT_TYPES, resources });

                            snackbar.show({
                                content   : translated.contractTypes.delete.success,
                                isSuccess : true,
                            });
                        } catch (error) {
                            snackbar.show({
                                errorLabels : translated.contractTypes.delete,
                                isError     : true,
                                error,
                            });
                        }
                    },
                });
            },
        },
    ];

    const addContractType = () => {
        navigator.goToClubTierContractTypeEditor({
            available : resources.available,
            current   : 'init',
        });
    };

    const sectionButtons = items?.length && links?.self?.create
        ? [
            <Button
                id="contract-types-add-button"
                variant="outlined"
                color="primary"
                key="button_primary"
                className="animated fade-in delay-02"
                onClick={addContractType}
            >
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <Subsection title={translated.contractTypes.list.sectionTitle} className="account-subsection margin-top-medium" actionButtons={sectionButtons}>
            <Table
                id="contract-types"
                key="contract-types"
                columns={[
                    {
                        title     : translated.contractTypes.name,
                        key       : 'name',
                        isVisible : true,
                    },
                    {
                        title     : translated.contractTypes.id,
                        key       : 'id',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title    : translated.global.status,
                        key      : 'isActive',
                        whenTrue : {
                            text      : translated.global.state.enabled,
                            className : 'status status-enabled',
                        },
                        whenFalse: {
                            text      : translated.global.state.disabled,
                            className : 'status status-disabled',
                        },
                        isVisible: true,
                    },
                ]}
                rows={{ actions }}
                items={items}
                onSearch={() => {
                    // Default
                }}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="contract-types-empty"
                        buttonId="contract-types-add-button"
                        content={translated.contractTypes.list.empty}
                        actionText={translated.global.buttons.add}
                        onClick={addContractType}
                    />
                )}
            />
        </Subsection>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="animated fade-in-up-small fastest">
                <Skeleton.Table hasTitle />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    data: {
        contractTypes : [],
        links         : {},
    },
};
List.propTypes = {
    data       : PropTypes.shape({}),
    isEmbedded : PropTypes.bool.isRequired,
    fetching   : PropTypes.shape({}).isRequired,
    reloadData : PropTypes.func.isRequired,
    resources  : PropTypes.shape({}).isRequired,
};

export default withRequest(List);
