import React, { useMemo, useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Form, { FormError } from 'Components/Form';
import translated from 'Constants/labels/translated';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import { attachmentAllowedFiles, fileUploadMaxSize } from 'Constants/global';

const MAX_LENGTH = 50;

const convertCategories = (list) => list?.map((each) => ({ value: each.id, content: each.name })) || [];

function AttachmentEditor({ onClose, data, isEditing, resources }) {
    const intl = useIntl();
    const { navigator, snackbar } = useContext(PanelContext);

    const { additional } = resources;

    const file = useRef(null);

    const childId = data?.category?.id;
    const parentId = data?.category?.parent?.id;

    const filteredCategories = additional?.attachmentCategories?.filter((each) => each?.isActive || each?.id === childId) || [];

    // eslint-disable-next-line max-len
    const availableParents = additional?.attachmentCategories?.filter((each) => !each?.parent && (each?.isActive || each?.id === (parentId || childId))) || [];

    const [category, setCategory] = useState({
        selected      : parentId || childId || null,
        selectedChild : parentId ? childId : null,
        all           : convertCategories(availableParents),
        children      : convertCategories(
            additional?.attachmentCategories.filter(
                // each?.parent?.isActive !== false is used when choosing a category parent, but without choosing a children.
                (each) => (parentId || childId) === each?.parent?.id && ((each?.parent?.isActive !== false && each?.isActive) || each?.id === childId),
            ),
        ),
    });
    const [name, setName] = useState(data?.name);

    const modalPropertiesForForm = useMemo(
        () => ({
            title   : isEditing ? translated.owners.attachments.edition.title : translated.owners.attachments.save.title,
            buttons : [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={onClose}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    {isEditing ? (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.upload} defaultMessage={translated.global.buttons.upload} />
                    )}
                </Form.Primary>,
            ],
            className: 'attachments-modal',
        }),
        [isEditing, onClose],
    );

    const handleOnSubmit = async ({ values }) => {
        if (!isEditing) {
            if (file?.current?.name && file?.current?.name.length > 60) {
                // TODO fix once APT solves this problem
                throw new FormError(translated.owners.attachments.invalidFileName);
            }

            if (file?.current?.name && !attachmentAllowedFiles.split(',').find((e) => file?.current?.name.endsWith(e))) {
                throw new FormError(translated.owners.attachments.invalidFileFormat);
            }

            if (file?.current?.size > fileUploadMaxSize) {
                throw new FormError(translated.owners.attachments.invalidFileSize);
            }
        }

        let categoryData = values?.categoryChild?.id ? values.categoryChild : values.category;
        if (!isEditing) {
            categoryData = categoryData.id;
        }

        const reqConfig = {
            data       : { ...values, category: categoryData },
            file       : !isEditing ? file.current : undefined,
            isTypeFile : !isEditing,
            ...resources.available[isEditing ? 'patch' : 'create'],
        };

        delete reqConfig.data.categoryChild;

        try {
            await navigator.directRequest(reqConfig);
        } catch (e) {
            throw new FormError(isEditing ? translated.owners.attachments.edition.errors.default : translated.owners.attachments.save.errors.default, e);
        }
    };

    const onFinish = () => {
        snackbar.show({
            content   : isEditing ? translated.owners.attachments.edition.success : translated.owners.attachments.save.success,
            isSuccess : true,
        });

        navigator.goToParentAndReload(false, false);
    };

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form id="owners-attachments" buttonsWidth={{ base: 12, small: 6 }} onSubmit={handleOnSubmit} onFinish={onFinish}>
                {isEditing && <Form.Input type="hidden" submitKey="id" value={data.id} />}
                {!isEditing && (
                    <Form.Input
                        id="file"
                        submitKey="file"
                        isDense
                        type="upload"
                        label={translated.owners.attachments.fileTitle}
                        icon={{ name: 'FileUpload' }}
                        isRequired
                        key="file"
                        onFileSelect={(newFile) => {
                            file.current = newFile;
                            setName(newFile?.name?.slice(0, MAX_LENGTH));
                        }}
                        acceptedFiles={attachmentAllowedFiles}
                        avoidOnSubmit
                        helperText={intl.formatMessage({
                            id             : translated.owners.attachments.fileHelper,
                            defaultMessage : translated.owners.attachments.fileHelper,
                        })}
                        avoidMarkAsChangedOnValidationsChange
                    />
                )}
                <Form.Input
                    isDense
                    id="name"
                    submitKey="name"
                    type="text"
                    label={translated.global.name}
                    isRequired
                    value={name}
                    validations={{ maxLength: MAX_LENGTH }}
                    forceLimit
                    charCount={{ total: MAX_LENGTH }}
                    key="name"
                    avoidMarkAsChangedOnValidationsChange
                    isBasedOnState
                    onChange={(newValue) => {
                        if (newValue !== name) {
                            setName(newValue);
                        }
                    }}
                />
                <Form.Input
                    isDense
                    id="category"
                    submitKey="category"
                    type="select"
                    label={translated.owners.attachments.category}
                    value={category?.selected}
                    options={category.all || []}
                    isRequired
                    isBasedOnState
                    avoidMarkAsChangedOnValidationsChange
                    onChange={(newValue) => {
                        setCategory((prev) => ({
                            ...prev,
                            selected      : newValue,
                            selectedChild : null,
                            children      : convertCategories(filteredCategories.filter((each) => newValue === each?.parent?.id)),
                        }));
                    }}
                    submitFormat={(id) => ({ id })}
                />
                <Form.Input
                    isDense
                    id="categoryChild"
                    submitKey="categoryChild"
                    type="select"
                    label={translated.owners.attachments.categoryChild}
                    value={category?.selectedChild}
                    options={category.children || []}
                    isBasedOnState
                    avoidMarkAsChangedOnValidationsChange
                    onChange={(newValue) => setCategory((prev) => ({ ...prev, selectedChild: newValue }))}
                    hasCleanButton
                    defaultOptionText={translated.owners.attachments.noCategory}
                    isDisabled={!category?.children?.length}
                    submitFormat={(id) => ({ id })}
                />
            </Form>
        </Form.Wrapper>
    );
}

AttachmentEditor.defaultProps = {};

AttachmentEditor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default withRequest(AttachmentEditor);
