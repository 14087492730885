import React from 'react';
// eslint-disable-next-line import/no-cycle
import Form from 'Components/Form';
import areRulesValid from '../Validator';

export default (data, onChange, onClean, dateManager) => {
    const availableAttributes = data || [];
    const attributes = Object.keys(availableAttributes).sort().map((id) => {
        const { dataType, name, options = [], slug, isDisabled } = availableAttributes[id];

        let type;
        let className = '';
        let validations;
        let processedOptions;
        let transformValue;
        let { value } = availableAttributes[id];
        let isRequired = false;
        let step;

        switch (dataType) {
            case 'Date':
                type = 'date';
                className = 'date-input';
                transformValue = (val) => ({ id, dataType, value: val, name, slug, isDisabled });
                validations = { unary: ['date'] };
                break;
            case 'Integer':
                type = 'number';
                className = 'number-input';
                transformValue = function transform(val) {
                    return { id, dataType, value: (areRulesValid(['numbers'], val, false, dateManager).length === 0 ? val : null), name, slug, isDisabled };
                };
                validations = { unary: ['numbers'] };
                break;
            case 'Float':
                type = 'number';
                className = 'number-input';
                step = 0.1;
                transformValue = function transform(val) {
                    return { id, dataType, value: (areRulesValid(['float'], val, false, dateManager).length === 0 ? val : null), name, slug, isDisabled };
                };
                validations = { unary: ['float'] };
                break;
            case 'Bool':
                type = 'checkbox';
                transformValue = (val) => (({ id, dataType, value: Boolean(val), name, slug, isDisabled }));
                value = !!value;
                break;
            case 'List':
                type = 'select';
                processedOptions = options.map((option) => ({
                    id      : option,
                    content : option,
                    value   : option,
                }));
                transformValue = (val) => ({
                    id, dataType, value: val, name, options, slug, isDisabled,
                });
                isRequired = true;
                if (value != null && options.length === 0) {
                    const option = {
                        id      : value,
                        content : value,
                        value,
                    };
                    processedOptions.push(option);
                }
                if (!options.includes(value) && options.length > 0) {
                    value = null;
                }
                break;
            case 'Text':
            case 'String':
                type = 'text';
                transformValue = (val) => ({ id, dataType, value: val, name, slug, isDisabled });
                break;
            default:
                // Invalid type
                break;
        }

        if (!type) {
            return null;
        }

        return (
            <Form.Input
                isDense
                id={`dynamic-input-${id}`}
                submitKey={id}
                key={id}
                type={type}
                value={value}
                label={name}
                className={className}
                options={processedOptions}
                onClean={(() => onClean(id))}
                hasCleanButton={onClean}
                validations={validations}
                onChange={(val) => onChange(id, transformValue(val))}
                isRequired={isRequired}
                step={step}
                isDisabled={isDisabled}
                isBasedOnState
            />
        );
    });

    return attributes;
};
