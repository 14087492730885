import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Components/Modal';
import translated from 'Constants/labels/translated';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';

export default function PhotoGallery({ id, title, customClass, onSelect, photos, selected: selectedImage }) {
    const [selected, setSelected] = useState(selectedImage ? photos?.find?.((e) => String(e.id) === String(selectedImage)) : null);

    const handleOnPhotoClick = useCallback((photo) => {
        setSelected(photo);
    }, []);

    return (
        <Modal
            title={title}
            closeButton={{
                text    : translated.global.buttons.select,
                onClick : () => onSelect(selected),
            }}
            cancelButton={{
                text    : translated.global.buttons.cancel,
                onClick : () => onSelect(),
            }}
            className="modal-photo-view"
        >
            <ul className="photo-gallery">
                {photos.map((photo) => (
                    <li
                        id={`${id}-${photo.id}`}
                        key={`${id}-${photo.id}`}
                        className={`photo-gallery-item ${customClass} ${selected?.id === photo.id ? 'is-selected' : ''}`}
                    >
                        {selected?.id === photo.id && <Icon className="mdi-icon" path={IconPath.mdiCheckCircle} size="20px" />}
                        <img
                            id={`photo-${photo.id}`}
                            key={`photo-${photo.id}`}
                            src={photo.url}
                            alt={photo.caption}
                            onClick={() => handleOnPhotoClick(photo)}
                        />
                    </li>
                ))}
            </ul>
        </Modal>
    );
}

PhotoGallery.defaultProps = {
    id          : '',
    customClass : '',
    photos      : [],
    onSelect    : () => {
        // default
    },
};

PhotoGallery.propTypes = {
    id          : PropTypes.string,
    customClass : PropTypes.string,
    photos      : PropTypes.arrayOf(PropTypes.shape({})),
    onSelect    : PropTypes.func,
};
