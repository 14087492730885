import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import ConfirmationButton from 'Components/ConfirmationButton';
import FormContext from './formContext';

function ClearButton({
    id, children, isDisabled, collapsed, confirmation, isLoading, variant, color, enabledOnInit, icon, text, className, size,
}) {
    const intl = useIntl();
    const { events, submitData, isSubmitting, formId } = useContext(FormContext);

    const clear = () => {
        events.onClear(submitData);
    };

    // eslint-disable-next-line max-len
    const isButtonDisabled = isDisabled || (submitData && !submitData.hasChanged && !enabledOnInit) || (submitData && submitData.isDisabled) || isSubmitting;

    if (collapsed || icon) {
        return (
            <ConfirmationButton
                id={id || formId ? `${formId}-clear-button` : null}
                confirmation={confirmation}
                icon={icon || 'UndoVariant'}
                tooltip={
                    text
                    || intl.formatMessage({
                        id             : translated.global.buttons.clear,
                        defaultMessage : translated.global.buttons.clear,
                    })
                }
                onClick={clear}
                disabled={isButtonDisabled}
                isLoading={isLoading}
                className={className}
                size={size}
            />
        );
    }

    return (
        <ConfirmationButton
            id={id || formId ? `${formId}-clear-button` : null}
            confirmation={confirmation}
            variant={variant || 'text'}
            color={color}
            onClick={clear}
            disabled={isButtonDisabled}
            isLoading={isLoading}
            className={className}
            size={size}
        >
            {children || <FormattedMessage id={translated.global.buttons.clear} defaultMessage={translated.global.buttons.clear} />}
        </ConfirmationButton>
    );
}

ClearButton.defaultProps = {
    id            : null,
    children      : null,
    isDisabled    : false,
    collapsed     : false,
    confirmation  : null,
    isLoading     : false,
    variant       : '',
    color         : '',
    icon          : '',
    text          : '',
    className     : '',
    enabledOnInit : false,
    size          : '',
};

ClearButton.propTypes = {
    id           : PropTypes.string,
    children     : PropTypes.node,
    isDisabled   : PropTypes.bool,
    collapsed    : PropTypes.bool,
    isLoading    : PropTypes.bool,
    confirmation : PropTypes.shape({
        title: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        message: PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
    }),
    variant       : PropTypes.string,
    color         : PropTypes.string,
    icon          : PropTypes.string,
    text          : PropTypes.string,
    className     : PropTypes.string,
    enabledOnInit : PropTypes.bool,
    size          : PropTypes.string,
};

export default ClearButton;
