import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Provides information about the window and the element that
 * can be obtained with the selector passed through parameters
 */
function getClassAccordingToWidth(htmlNode) {
    let containerWidthClass;
    let containerWidth;
    if (htmlNode) {
        containerWidth = htmlNode.clientWidth;
        if (containerWidth <= 640) {
            containerWidthClass = 'parent-small';
        } else if (containerWidth <= 960) {
            containerWidthClass = 'parent-medium';
        } else {
            containerWidthClass = 'parent-large';
        }
    }

    return {
        windowWidth: window && window.innerWidth,
        containerWidth,
        containerWidthClass,
    };
}

/**
 * It provides functionalities to maintain the order of the elements when changing the size
 * of the window, and to accommodate the different elements that are children of this one
 */
function Responsive({ children, container }) {
    /**
     * Values corresponding to the children are stored in childrenValues to manage
     * the comparison that can be requested by them, the property debounceTimeout
     * is used in the function that manages the resizes of the window
     */
    const [debounceTimeout, setDebounceTimeout] = useState(false);
    const [childrenValues, setChildrenValues] = useState([]);
    const [config, setConfig] = useState({
        ...getClassAccordingToWidth(container),
        areChildrenStacked: false,
    });

    /**
     * A debounce function is executed to maintain the performance and in each
     * iteration the parameters provided by the component are recalculated.
     */
    const handleWindowResize = useCallback(() => {
        clearTimeout(debounceTimeout);

        const timer = setTimeout(() => {
            setConfig(getClassAccordingToWidth(container));
        }, 250);
        setDebounceTimeout(timer);
    }, [container, debounceTimeout]);
    /**
     * When the screen changes size, the values provided by the component
     * are recalculated
     */
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    /**
     * An array of values is loaded as the children of the component are loaded,
     * and 'true' is returned in those whose value is less than the first.
     */
    const isBelowFirstChild = useCallback((index, element) => {
        try {
            const elementClientRect = element.getBoundingClientRect();
            const totalHeightFromTop = Math.trunc(elementClientRect.top + elementClientRect.height);
            const distanceFromTop = Math.trunc(elementClientRect.top);

            childrenValues[index] = totalHeightFromTop;
            setChildrenValues(childrenValues);

            if (index !== 0 && distanceFromTop >= this.childrenValues[0]) {
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }, [childrenValues]);

    /**
     * The component receives a function that (normally) returns a component which
     * has the possibility of receiving through the parameters of the function certain
     * values to manage changes according to the size of the window
     */
    const { windowWidth, containerWidth, containerWidthClass, areChildrenStacked } = config;

    return children({
        windowWidth,
        containerWidth,
        containerWidthClass,
        isBelowFirstChild,
        areChildrenStacked,
    });
}

Responsive.defaultProps = { container: null };

Responsive.propTypes = {
    children  : PropTypes.func.isRequired,
    container : PropTypes.oneOfType([PropTypes.any, PropTypes.object]),
};

export default Responsive;
