import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import { getCurrencyId } from './utils';

function Range({ id, index }) {
    const { setValue, watch, trigger } = useFormContext();

    const rangeId = `ranges.${id}`;

    const [marks, ranges, rangeFrom, rangeTo, balanceTypes, availableBalanceTypes] = watch(['marks', 'ranges', `${rangeId}.from`, `${rangeId}.to`, 'balanceTypes', 'availableBalanceTypes']);

    const removeRangeConversion = useCallback(() => {
        // Remove the conversions from the form context
        const removedCurrencies = [];

        balanceTypes?.forEach?.((eachBalanceTypeId) => {
            const currencyId = getCurrencyId(eachBalanceTypeId, availableBalanceTypes);

            if (removedCurrencies.indexOf(currencyId) === -1) {
                removedCurrencies.push(currencyId);

                setValue(
                    `conversions.${id}-${currencyId}`,
                    null,
                    { shouldDirty: true, shouldValidate: true },
                );
            }

            setValue(
                `conversions.${id}-${currencyId}-${eachBalanceTypeId}`,
                null,
                { shouldDirty: true, shouldValidate: true },
            );
        });
    }, [balanceTypes, availableBalanceTypes, id, setValue]);

    const handleOnRemove = useCallback(() => {
        setValue(`${rangeId}.isHidden`, true, { shouldDirty: true, shouldValidate: true });

        // We update the slider's marks
        setValue('marks', marks?.filter((e) => e !== rangeFrom), { shouldDirty: true, shouldValidate: true });

        // We update the previous range's "to" value
        const previousRange = Object.values(ranges)?.find((e) => !e.isHidden && Number(e.to) === Number(rangeFrom - 1));
        if (previousRange) {
            setValue(`ranges.${previousRange.id}.to`, rangeTo);
        }

        trigger('ranges');

        removeRangeConversion(rangeId);
    }, [setValue, rangeId, marks, ranges, trigger, removeRangeConversion, rangeFrom, rangeTo]);

    return (
        <Form.ColumnNew key="range-generator" className="form-column-group margin-top-small">
            <span className="flex-1 text-color-secondary">
                <WrappedFormattedMessage
                    content={translated.rules.dynamicRanges.rangeConfiguration.range}
                    values={{ id: index }}
                />
            </span>
            <Form.InputNew
                isDense
                submitKey={`${rangeId}.label`}
                type="text"
                maxCharCount={50}
                label={translated.rules.dynamicRanges.rangeConfiguration.label}
            />
            <Form.InputNew
                isDense
                isDisabled
                submitKey={`${rangeId}.from`}
                type="number"
                label={translated.global.from}
                className="xsmall-number-input"
            />
            <Form.InputNew
                isDense
                isDisabled
                submitKey={`${rangeId}.to`}
                type="number"
                label={translated.global.to}
                className="xsmall-number-input"
            />
            <Button
                className="margin-left-xsmall"
                icon="MinusCircle"
                tooltip={translated.global.buttons.remove}
                onClick={handleOnRemove}
            />
        </Form.ColumnNew>
    );
}

Range.propTypes = {
    id    : PropTypes.number.isRequired,
    index : PropTypes.number.isRequired,
};

export default Range;
