module.exports = Object.freeze({
    title   : 'Sync',
    content : { sync: 'This feature allows you to synchronize properties' },
    alert   : { sync: 'This task might take a while, please, be patient' },
    buttons : {
        label   : 'Start syncing',
        tooltip : 'You can continue with your work while the properties synchronization is running',
        refresh : 'Refresh',
        about   : 'About',
        logout  : 'Logout',
        mute    : 'Mute calls',
        unmute  : 'Unmute calls',
    },
    errors  : { sync: 'The properties could not be synchronized' },
    success : { sync: 'Synchronization started' },
    options : {
        title    : 'User settings',
        darkMode : 'Dark Mode',
    },
});
