import { sectionType } from '../constants/types';
import RenderingTree from './RenderingTree';
import sections from './sections';

/**
 * Defines the structure that has the application, each section can make requests, hold states and other features,
 * it is not always necessary to create a new section to add a feature
 * All sections are disabled by default and enabled according to the user permissions
 *
 * @prop {string} name                          Name of the section, updated with the name of the entity after a response from the server
 * @prop {object} ids                           Here are defined the ids that this section needs to perform a read or init action
 * @prop {object} data                          The response of the server, data about the entity
 * @prop {object} error                         When the response fails
 * @prop {object} sections                      Child of this section (other sections)
 *
 * @prop {bool} isEnabled                       Hide the section
 * @prop {bool} isSelected                      Selected sections are the ones that are displayed on screen
 * @prop {bool} isEmbedded                      When some children select this section, it will falls to the father, because is embedded
 * @prop {bool} shouldReloadData                When set to true, the section will reload the data (and set back to false)
 *
 * @prop {object} resources                     Object containing the necessary data to perform requests
 * @prop {string} resources.name                The name of this resource in the responses and communication with the API
 * @prop {string} resources.current             The resource that is currently being used in the availables
 * @prop {object} resources.available           All the resources each one with actions inside to perform updates, reads, etc
 * @prop {object} resources.templates           Used to make the requests when there is no available, for example in the first load
 *
 * @prop {array} fetching.ids                   Used in tables or when the performed request was fired by a table
 * @prop {bool} fetching.isGlobal               While performing a fetch is true
 *
 * @prop {string} config.url                    Set the URL, can pass a 'withId' and 'withoutId' to determine 2 urls
 * @prop {string} config.isDefaultSection       When the father is selected, the selection falls to the default section, if there is any
 * @prop {string} config.isHiddenInBreadcrumb   When selected, this section won't be shown in the Breadcrumb component
 *
 * @prop {string} customProps                   An object that contains custom properties needed by that section.
 *
 * @prop {bool} snackbar.id                     Used to identify different notifications (that could have the same properties and values)
 */

// Ids used to define the dependencies of each section
// When the application starts and the id's are obtained from the URL,
// each section needs some specific data that is requested by using the Ids
// Those Ids are passed to the templates, used to get the data and render the section
// This is indicated by adding the corresponding id of the section to the 'ids' prop, as an object

/*
To add a new section:

- Create in /Sections a folder for the new section. Inside it place a "SECTION_NAME.js" with the same
    content as,for instance, src\components\Sections\Owners\Owners.js: it is used to correctly render the sections.
- Create the component you will need. We usually use 'List' or 'Editor' for the entities common components.
- In src\constants\types.js you will have to add the keys and values for the new sections.
- In src\state\sections\ add a file "SECTION_NAME.js". It will have all the related sections configuration. In this file you have the doc for the props available.
- In src\state\sections\index.js export the file from previous step.
- In this file, import the section's configuration and add it to 'preloadedState.panel.sections'.
- In src\components\Sections\Collection.js you have to map the new sections keys to the corresponding component.
- If needed, you can add in src\classes\Navigator.js the navigation methods to access the new sections.
- If the new sections must be added to the side menu, you will have to add the root section in src\components\SideDrawer\SideDrawerHelper.js.
- In src\constants\labels\sectionName.js add the sections names.
- In src\constants\labels\translated\ add a file "SECTION_NAME.js". You will have to add all the needed translated messages with it's webTranslateIt key.
- In src\constants\labels\translated\index.js add the translation file added in the previous step.
*/

const preloadedState = {
    qa  : { isActive: false },
    app : {
        user          : null,
        notifications : {
            isEnabled : false,
            resources : {},
            amount    : 0,
            isLoading : false,
            data      : null,
            error     : null,
        },

        title   : 'Capital vacations | Administrator',
        version : {
            number : '1.8.0 EEC',
            build  : '404',
        },
        isSideDrawerVisible      : false,
        isSideDrawerCollapsed    : false,
        isDarkModeEnabled        : false,
        areBreadcrumbTabsVisible : false,
        isLocalStorageLoaded     : false,
        useMock                  : false,
        language                 : 'en',

        error: {
            title             : null,
            status            : null,
            isCritical        : false,
            shouldRefreshUser : false,
            mustShow          : false,
        },

        snackbar: {
            system: {
                isVisible : false,
                id        : 'SYSTEM-0',
            },
            progress     : { isVisible: false },
            notification : {
                nextId   : 'NOTIFICATION-1',
                elements : [],
            },
        },
        confirmation: { isVisible: false },

        restrictedApp: {
            isAppRestricted : false,
            message         : null,
            reason          : null,
        },
    },
    panel: {
        path: {
            current           : [sectionType.OWNERS, sectionType.OWNERS_LIST],
            url               : '',
            urlHistory        : [],
            selectionsHistory : [],
        },
        shouldReloadUrlStage                  : 'init',
        currentSection                        : { lastRequest: null },
        componentsWithChangesInCurrentSection : [],
        sections                              : {
            ...sections.owners,
            ...sections.clubs,
            ...sections.partners,
            ...sections.contracts,
            ...sections.reservations,
            ...sections.tiers,
            ...sections.balanceTypes,
            ...sections.externalClubs,
            ...sections.attachmentCategories,
            ...sections.agents,
            ...sections.roles,
            ...sections.extraAttributes,
            ...sections.tags,
            ...sections.propertyGroups,
            ...sections.properties,
            ...sections.systemRules,
            ...sections.currencies,
            ...sections.documentation,
            ...sections.accountStatement,
        },
    },
};

preloadedState.panel = RenderingTree.addDefaultSectionProps(preloadedState.panel);
preloadedState.panel = RenderingTree.addDefaultResourceName(preloadedState.panel);

export default preloadedState;
