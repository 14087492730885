import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Subsection from 'Components/Sections/Subsection';
import Table from 'Components/Table';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import Status from 'Components/Status';
import { sectionType } from 'Constants/types';
import Button from 'Components/Button';
import PanelContext from 'State/panelContext';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import Reason from './Reason';
import Modification from './Modification';

const getItems = (data) => data?.map((each) => ({
    ...each,
    club: each?.externalClub?.name,
}));

export function List({ data, reloadData, setSectionState, state: { appliedFilters }, resources, sections }) {
    const { navigator, confirmation, snackbar } = useContext(PanelContext);

    const reasonProps = sections[sectionType.REASON];
    const modificationProps = sections[sectionType.CONVERSION_MODIFICATION];
    const isModalReasonOpen = reasonProps.isEnabled && reasonProps.isSelected;
    const isModalModificationOpen = modificationProps.isEnabled && modificationProps.isSelected;

    const { data: conversions, pagination, filter: filterLinks, version } = data || {};

    const sectionButtons = window.env.CLIENT_NAME !== 'CV' && data?.links?.conversion?.create
        ? [
            data.links.conversion.create.shouldRedirect ? (
                <Button
                    id="conversion-manage-button"
                    variant="outlined"
                    color="primary"
                    key="button_primary"
                    onClick={() => {
                        navigator.directRequest(data.links.conversion.create);
                    }}
                >
                    <FormattedMessage defaultMessage={translated.conversions.add} id={translated.conversions.add} />
                </Button>
            ) : (
                <Button
                    id="conversion-manage-button"
                    variant="outlined"
                    color="primary"
                    key="button_primary"
                    onClick={() => {
                        navigator.goToConversionManager(data.links.conversion);
                    }}
                >
                    <FormattedMessage defaultMessage={translated.conversions.add} id={translated.conversions.add} />
                </Button>
            ),
        ]
        : null;

    const { Section, props: subsecProps } = Sections.get(sections);

    const isEmbedded = false;

    const handleClickPerformCancel = async (conversion) => {
        try {
            const reqConfig = {
                ...conversion.links.self.cancel,
                isCritical       : false,
                isGlobal         : false,
                ids              : [conversion.id],
                shouldReloadData : true,
            };
            await navigator.requestForCurrentPath({
                reqConfig,
                isEmbedded,
                section: sectionType.CONVERSIONS,
                resources,
            });
            snackbar.show({
                content   : translated.conversions.cancel.success,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content : translated.conversions.cancel.error,
                isError : true,
                error   : e,
            });
        }
    };

    const handleClickChangeReason = (conversion) => {
        navigator.goToReasonEditor({ available: conversion.links.self, current: 'read', section: sectionType.CONVERSIONS, isEmbedded });
    };

    const handleClickCancelConversion = (conversion) => {
        confirmation.show({
            // eslint-disable-next-line max-len
            message       : translated.conversions.cancelConfirmation,
            acceptMessage : translated.global.buttons.confirm,
            onAccept      : () => handleClickPerformCancel(conversion),
        });
    };

    const handleClickModifyConversion = (conversion) => {
        navigator.goToModificationConversion({ available: conversion.links.self, current: 'read', section: sectionType.CONVERSIONS, isEmbedded });
    };

    function getConversionActions() {
        return [
            {
                content  : translated.global.buttons.reasonChange,
                callback : (conversion) => handleClickChangeReason(conversion),
                key      : 'reasonChange',
                when     : (conversion) => conversion?.links?.self?.patch,
            },
            {
                content  : translated.global.buttons.cancel,
                callback : (conversion) => handleClickCancelConversion(conversion),
                key      : 'cancelConversion',
                when     : (conversion) => conversion?.links?.self?.cancel,
            },
            {
                content  : translated.global.buttons.edit,
                callback : (conversion) => handleClickModifyConversion(conversion),
                key      : 'modifyConversion',
                when     : (conversion) => conversion?.links?.self?.modify,
            },
        ];
    }

    if (version === 'v1') {
        return (
            <Subsection title={translated.conversions.title} actionButtons={sectionButtons}>
                {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
                {isModalReasonOpen && <Reason {...reasonProps} />}
                {isModalModificationOpen && <Modification {...modificationProps} />}
                <Table
                    id="conversions"
                    key="conversions"
                    columns={[
                        {
                            title     : translated.conversions.club,
                            key       : 'club',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversions.date,
                            key       : 'creationDate',
                            isVisible : true,
                            isDate    : true,
                        },
                        {
                            title     : translated.conversions.code,
                            key       : 'code',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversions.fee,
                            key       : 'fee',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversions.pointsUsed,
                            key       : 'pointsConsumed',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversions.pointsConverted,
                            key       : 'pointsConverted',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversions.reference,
                            key       : 'feePaymentReference',
                            isVisible : true,
                        },
                        {
                            title                : translated.conversions.status,
                            key                  : 'status',
                            isVisible            : true,
                            customFieldComponent : (transaction) => <Status status={transaction.transactionStatus} />,
                        },
                        {
                            title           : translated.conversions.reason,
                            key             : 'reason',
                            isVisible       : true,
                            showTooltipWhen : 20,
                        },
                    ]}
                    rows={{ actions: getConversionActions() }}
                    items={getItems(conversions)}
                    onSearch={() => {
                        // Default
                    }}
                    whenEmpty={<Alert id="conversions-empty" content={translated.conversions.emptyList} />}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config, false, sectionType.CONVERSIONS);
                    }}
                    appliedFilters={appliedFilters}
                    filterResources={{
                        filters: [
                            {
                                type        : 'text',
                                submitKey   : 'code',
                                resourceKey : 'code',
                            },
                        ],
                        supportText: translated.conversions.filterText,
                        filterLinks,
                        resources,
                    }}
                    canViewColumns
                    canChangeSettings
                />
            </Subsection>
        );
    }
    const getContractRow = (id, link) => ({
        value: (
            <a
                className="clickable-link"
                onClick={() => {
                    navigator.goToOwnerContractEditor({ available: link });
                }}
            >
                {id}
            </a>
        ),
    });

    return (
        <Subsection title={translated.conversions.title} actionButtons={sectionButtons}>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            <Table
                className="conversions-table"
                id="conversions"
                key="conversions"
                columns={[
                    {
                        title     : translated.conversions.destinationClub,
                        key       : 'destinationClub.name',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.type,
                        key       : 'destinationClub.isExternal',
                        isVisible : true,
                        whenTrue  : { text: translated.conversions.external },
                        whenFalse : { text: translated.conversions.internal },
                    },
                    {
                        title     : translated.conversions.date,
                        key       : 'creationDatetime',
                        isDate    : true,
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.code,
                        key       : 'code',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.originBalanceType,
                        key       : 'origin.balanceType.name',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.amount,
                        key       : 'origin.amount',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.destinationBalanceType,
                        key       : 'destination.balanceType.name',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.destinationAmount,
                        key       : 'destination.amount',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.balanceName,
                        key       : 'fee.balanceType.name',
                        isVisible : true,
                    },
                    {
                        title     : translated.conversions.fee,
                        key       : 'fee.amount',
                        isVisible : true,
                    },
                    {
                        title       : translated.conversions.legacyId,
                        key         : 'contract.legacyId',
                        isVisible   : true,
                        fieldFormat : (conversion) => getContractRow(conversion.contract.legacyId, conversion.contract?.links?.self),
                        isNumeric   : !getContractRow,
                    },
                ]}
                rows={{ actions: [] }}
                items={conversions}
                onSearch={() => {
                    // Default
                }}
                whenEmpty={<Alert id="conversions-empty" content={translated.conversions.emptyList} />}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                canViewColumns
                canChangeSettings
            />
        </Subsection>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isSubHeading width={145} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table hasTitle={false} hasActions={false} />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    setSectionState : null,
    state           : null,
};

List.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    sections        : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    reloadData      : PropTypes.func.isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

export default withRequest(List);
