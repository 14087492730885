import { sectionType } from 'Constants/types';
import { scrollToTop } from 'Utils';

const CLEAN_PROPS = { data: null, error: null };

/** Class for switching from one section to another */
export default class Navigator {

    constructor(
        {
            selectSection,
            request,
            setSectionState,
            setSectionData,
            setSectionsIds,
            selectSectionForBackAction,
            sectionLoaded,
            setCurrentSectionComponentWithChanges,
            canLeaveSection,
            directRequest,
            disableSections,
        },
        getSelectableParentPath,
        selectDefaultSection,
        path,
        app,
    ) {
        this.select = selectSection;
        this.goToPrevSection = selectSectionForBackAction;
        this.setSectionState = setSectionState;
        this.setSectionData = setSectionData;
        this.disableSections = disableSections;
        this.setSectionsIds = setSectionsIds;
        this.request = request;
        this.directRequest = directRequest;
        this.currentPath = path.current;
        this.getSelectableParentPath = getSelectableParentPath;
        this.setCurrentSectionComponentWithChanges = setCurrentSectionComponentWithChanges;
        this.canLeaveSection = canLeaveSection;
        this.goToDefaultSection = selectDefaultSection;
        this.useMock = app.useMock;
        this.sectionLoaded = sectionLoaded;

        this.initAndCheck = this.initAndCheck.bind(this);
        this.setCurrentSectionState = this.setCurrentSectionState.bind(this);
        this.goToDetails = this.goToDetails.bind(this);
        this.goToBookings = this.goToBookings.bind(this);
        this.goToBalances = this.goToBalances.bind(this);
        this.goToConversions = this.goToConversions.bind(this);
        this.goToNotes = this.goToNotes.bind(this);
        this.goToNoteEditor = this.goToNoteEditor.bind(this);
        this.goToRuleEditor = this.goToRuleEditor.bind(this);

        this.goToClubTierRuleEditor = this.goToClubTierRuleEditor.bind(this);
        this.goToSystemRuleEditor = this.goToSystemRuleEditor.bind(this);
        this.goToContractTypeRuleEditor = this.goToContractTypeRuleEditor.bind(this);
        this.goToOwnerContractRuleEditor = this.goToOwnerContractRuleEditor.bind(this);

        this.goToClubTierContractTypeEditor = this.goToClubTierContractTypeEditor.bind(this);
        this.goToList = this.goToList.bind(this);
        this.goToRules = this.goToRules.bind(this);
        this.goToOwnerWalletManager = this.goToOwnerWalletManager.bind(this);
        this.goToOwnerAttachments = this.goToOwnerAttachments.bind(this);
        this.goToOwnerUsers = this.goToOwnerUsers.bind(this);
        this.goToOwnerContracts = this.goToOwnerContracts.bind(this);
        this.goToOwnerRules = this.goToOwnerRules.bind(this);
        this.goToOwnerEditor = this.goToOwnerEditor.bind(this);
        this.goToOwnerImport = this.goToOwnerImport.bind(this);
        this.goToOwnersCreator = this.goToOwnersCreator.bind(this);
        this.goToPartnerEditor = this.goToPartnerEditor.bind(this);
        this.goToPartnerInfo = this.goToPartnerInfo.bind(this);
        this.goToPartnerBookings = this.goToPartnerBookings.bind(this);
        this.goToPartnerBalances = this.goToPartnerBalances.bind(this);
        this.goToPartnerNotes = this.goToPartnerNotes.bind(this);
        this.goToPartnerContracts = this.goToPartnerContracts.bind(this);
        this.goToPartnerUsers = this.goToPartnerUsers.bind(this);
        this.goToPartnerUserEdition = this.goToPartnerUserEdition.bind(this);
        this.goToOwnerUserEdition = this.goToOwnerUserEdition.bind(this);
        this.goToRelationshipEditor = this.goToRelationshipEditor.bind(this);

        this.cleanDataForCurrentSection = this.cleanDataForCurrentSection.bind(this);
        this.goAndCleanChildrenData = this.goAndCleanChildrenData.bind(this);
        this.goAndCleanAll = this.goAndCleanAll.bind(this);
        this.goWithoutClean = this.goWithoutClean.bind(this);
        this.requestForCurrentPath = this.requestForCurrentPath.bind(this);
        this.getParentPath = this.getParentPath.bind(this);
        this.requestAndSetToParent = this.requestAndSetToParent.bind(this);
        this.goToRoot = this.goToRoot.bind(this);
        this.goToTierEditor = this.goToTierEditor.bind(this);
        this.goToClubTierEditor = this.goToClubTierEditor.bind(this);
        this.goToClubEditor = this.goToClubEditor.bind(this);
        this.goToUserCreator = this.goToUserCreator.bind(this);
        this.goToRoleEditor = this.goToRoleEditor.bind(this);
        this.goToBalanceTypeEditor = this.goToBalanceTypeEditor.bind(this);
        this.selectOwnerUpgradeDowngradeEditor = this.selectOwnerUpgradeDowngradeEditor.bind(this);
        this.selectUpgradeDowngradeEditor = this.selectUpgradeDowngradeEditor.bind(this);
        this.goToCategoryEditor = this.goToCategoryEditor.bind(this);
        this.goToOwnerAttachmentEditor = this.goToOwnerAttachmentEditor.bind(this);
        this.goToOwnerContractEditor = this.goToOwnerContractEditor.bind(this);
        this.goToReservationEditor = this.goToReservationEditor.bind(this);
        this.goToUserEditor = this.goToUserEditor.bind(this);
        this.goToOwnersImport = this.goToOwnersImport.bind(this);

        this.goToWalletManager = this.goToWalletManager.bind(this);
        this.goToAddBalance = this.goToAddBalance.bind(this);
        this.goToConversionManager = this.goToConversionManager.bind(this);

        this.goToExternalClubEditor = this.goToExternalClubEditor.bind(this);

        this.goToExtraAttributesEditor = this.goToExtraAttributesEditor.bind(this);
        this.goToRatesEditor = this.goToRatesEditor.bind(this);

        this.goToParentAndReload = this.goToParentAndReload.bind(this);
        this.goTo = this.goTo.bind(this);
        this.goAndReloadCleaningTheCurrent = this.goAndReloadCleaningTheCurrent.bind(this);
        this.goAndReload = this.goAndReload.bind(this);
        this.initialRequestAndGoTo = this.initialRequestAndGoTo.bind(this);
        this.cleanMeAndGoTo = this.cleanMeAndGoTo.bind(this);
        this.requestForOwnerDetails = this.requestForOwnerDetails.bind(this);

        this.goToPartnerContractEditor = this.goToPartnerContractEditor.bind(this);
        this.selectPartnerUpgradeDowngradeEditor = this.selectPartnerUpgradeDowngradeEditor.bind(this);
        this.goToConversionRuleEditor = this.goToConversionRuleEditor.bind(this);
        this.goToConversionRuleEditorAlt = this.goToConversionRuleEditorAlt.bind(this);
        this.goToTier = this.goToTier.bind(this);
        this.goToTags = this.goToTags.bind(this);
        this.goToTagsEditor = this.goToTagsEditor.bind(this);
        this.goToPropertyGroup = this.goToPropertyGroup.bind(this);
        this.goToPropertyGroupEditor = this.goToPropertyGroupEditor.bind(this);

        this.goToProperties = this.goToProperties.bind(this);
        this.goToPropertiesRules = this.goToPropertiesRules.bind(this);

        this.goToPropertiesGroup = this.goToPropertiesGroup.bind(this);
        this.goToPropertiesGroupRules = this.goToPropertiesGroupRules.bind(this);

        this.goToDocumentation = this.goToDocumentation.bind(this);
        this.goToReleases = this.goToReleases.bind(this);

        this.goToPartnerContractDetail = this.goToPartnerContractDetail.bind(this);
        this.goToOwnerContractDetail = this.goToOwnerContractDetail.bind(this);

        this.goToReasonEditor = this.goToReasonEditor.bind(this);
        this.goToModificationConversion = this.goToModificationConversion.bind(this);
    }

    async initAndCheck(hasToScroll) {
        const canLeave = await this.canLeaveSection();

        if (canLeave && hasToScroll) {
            scrollToTop();
        }

        return canLeave;
    }

    async goToDetails(data, shouldReloadData = false) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.INFO];
        const resources = { available: data?.links?.self || {}, current: 'read' };

        const params = [{ with: { resources, shouldReloadData, ...CLEAN_PROPS }, on: sectionType.DETAILS }];
        if (shouldReloadData) {
            params.push({ with: { shouldReloadData, ...CLEAN_PROPS }, on: sectionType.INFO });
        }

        return this.select(path, params);
    }

    async goToBookings(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.BOOKINGS];
        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToBalances(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.WALLET];
        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToConversions(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CONVERSIONS];
        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToNotes(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select([sectionType.DETAILS, sectionType.NOTES], [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToRules() {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const last = [...this.currentPath].pop();
        const unselectedBranchParams = [{ with: CLEAN_PROPS, on: last }];
        const selectedBranchProps = [{ with: CLEAN_PROPS, on: sectionType.RULES }];

        return this.select([sectionType.RULES], selectedBranchProps, unselectedBranchParams);
    }

    async goToOwnerRules(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.RULES];

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToOwnerWalletManager(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CONVERSION_RULES];

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToOwnerAttachments(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.ATTACHMENTS];

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToOwnerUsers(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.USERS];

        return this.select(path, [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToOwnerContracts(data, selectedContractId = null) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS];

        const contractProps = [
            {
                with : { state: { selectedContract: selectedContractId } },
                on   : sectionType.CLIENT_CONTRACTS,
            },
        ];

        if (data?.links?.self) {
            contractProps.push({ with: { resources: { available: data?.links?.self || {}, current: 'read' } }, on: sectionType.DETAILS });
        }

        return this.select(path, [...contractProps]);
    }

    async goToOwnerAttachmentEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.ATTACHMENTS, sectionType.ATTACHMENT];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.ATTACHMENT }]);
    }

    async goToOwnerContractEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR];

        const props = {
            isReadOnly: false,
            ...CLEAN_PROPS,
        };

        return this.select(path, [{ with: { resources, ...props }, on: sectionType.CLIENT_CONTRACTS_EDITOR }]);
    }

    async goToPartnerContractEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR];

        const props = {
            isReadOnly: false,
            ...CLEAN_PROPS,
        };

        return this.select(path, [{ with: { resources, ...props }, on: sectionType.CLIENT_CONTRACTS_EDITOR }]);
    }

    async goToOwnerContractDetail({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR];

        const props = {
            isReadOnly: true,
            ...CLEAN_PROPS,
        };

        return this.select(path, [{ with: { resources, ...props }, on: sectionType.CLIENT_CONTRACTS_EDITOR }]);
    }

    async goToPartnerContractDetail({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR];

        const props = {
            isReadOnly: true,
            ...CLEAN_PROPS,
        };

        return this.select(path, [{ with: { resources, ...props }, on: sectionType.CLIENT_CONTRACTS_EDITOR }]);
    }

    async selectPartnerUpgradeDowngradeEditor(data, current = 'read') {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const available = data?.links?.self || {};
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.CONTRACT_EDITOR];

        return this.select(path, [
            {
                with: {
                    resources  : { available, current },
                    data       : null,
                    isReadOnly : false,
                },
                on: sectionType.CONTRACT_EDITOR,
            },
        ]);
    }

    async goToNoteEditor({ available, current, isEmbedded, section }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        const pathWithEmbeddedSection = isEmbedded ? [...this.currentPath, section, sectionType.NOTE] : [...this.currentPath, sectionType.NOTE];
        return this.select(pathWithEmbeddedSection, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.NOTE }]);
    }

    async goToClubTierRuleEditor({ available, current, isEmbedded }) {
        const path = isEmbedded
            ? [...this.currentPath, sectionType.RULE]
            : [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL, sectionType.CLUBS_TIER_EDITOR, sectionType.RULE];

        const resources = { available, current };
        const params = [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RULE }];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToOwnerContractRuleEditor({ available, current }) {
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR, sectionType.RULE];

        const resources = { available, current };

        const params = [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RULE }];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToSystemRuleEditor({ available, current }) {
        const path = [sectionType.SYSTEM_RULES, sectionType.RULE];

        const resources = { available, current };

        const params = [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RULE }];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToContractTypeRuleEditor({ available, current, isEmbedded }) {
        const path = isEmbedded
            ? [...this.currentPath, sectionType.RULE]
            : [
                sectionType.CLUBS,
                sectionType.CLUBS_EDITOR,
                sectionType.CLUBS_GENERAL,
                sectionType.CLUBS_TIER_EDITOR,
                sectionType.CONTRACT_TYPE,
                sectionType.RULE,
            ];

        const resources = { available, current };

        const params = [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RULE }];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToPartnerContractRuleEditor({ available, current }) {
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR, sectionType.RULE];

        const resources = { available, current };

        const params = [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RULE }];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToClubTierContractTypeEditor({ available, current, pathProps = [] }) {
        const path = [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL, sectionType.CLUBS_TIER_EDITOR, sectionType.CONTRACT_TYPE];
        const resources = { available, current };
        const params = [
            {
                with : { resources, ...CLEAN_PROPS },
                on   : sectionType.CONTRACT_TYPE,
            },
            ...pathProps,
        ];

        return this.goAndCleanChildrenData(path, params, { scroll: true });
    }

    async goToOwnerEditor({ available, current = 'read', selectedForm = null }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }
        const resources = { available, current };
        const path = [sectionType.OWNERS, sectionType.OWNERS_EDITOR, sectionType.OWNERS_GENERAL];

        return this.select(path, [
            {
                with : { resources, ...CLEAN_PROPS, state: { selectedForm } },
                on   : sectionType.OWNERS_GENERAL,
            },
        ]);
    }

    async goToOwnerImport({ links }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }
        const resources = { available: links.self, current: 'read' };
        const path = [sectionType.OWNERS_IMPORT, sectionType.OWNERS_GENERAL_IMPORTED];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.OWNERS_GENERAL_IMPORTED }]);
    }

    async goToOwnersCreator({ links, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }
        const resources = { available: links.self, current };
        const path = [sectionType.OWNERS, sectionType.OWNERS_CREATOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.OWNERS_CREATOR }]);
    }

    async goToPartnerEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_EDITOR, sectionType.PARTNERS_GENERAL];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.PARTNERS_GENERAL }]);
    }

    async goToPartnerInfo(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select(
            [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.PARTNERS_INFO],
            [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }],
        );
    }

    async goToPartnerBookings(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select([sectionType.PARTNERS_DETAILS, sectionType.BOOKINGS], [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }]);
    }

    async goToPartnerBalances(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select([sectionType.PARTNERS_DETAILS, sectionType.WALLET], [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }]);
    }

    async goToPartnerNotes(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        return this.select([sectionType.PARTNERS_DETAILS, sectionType.NOTES], [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }]);
    }

    async goToPartnerContracts(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        return this.select(
            [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.PARTNERS_CONTRACTS],
            [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }],
        );
    }

    async goToPartnerUsers(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };
        const path = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USERS];

        return this.select(path, [{ with: { resources }, on: sectionType.PARTNERS_DETAILS }]);
    }

    async goToPartnerUserEdition({ available, current }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        const pathWithEmbeddedSection = [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USER];

        return this.select(pathWithEmbeddedSection, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.USER }]);
    }

    async goToOwnerUserEdition({ available, current = 'read' }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        const pathWithEmbeddedSection = [sectionType.OWNERS, sectionType.DETAILS, sectionType.USER];

        return this.select(pathWithEmbeddedSection, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.USER }]);
    }

    async goToOwnerDetails() {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.INFO];
        const lastSection = [...path].pop();
        return this.select(path, [{ with: { shouldReloadData: true }, on: lastSection }]);
    }

    async goToList(data) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        return this.select([sectionType.OWNERS_LIST], [{ with: { data }, on: sectionType.OWNERS_LIST }]);
    }

    async goToRelationshipEditor({ available, current = 'read', path = [] }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        return this.select([...path, sectionType.RELATIONSHIP], [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.RELATIONSHIP }]);
    }

    // Cleans the section's data.
    cleanDataForCurrentSection(cleanData = false, cleanChildren = false) {
        const last = [...this.currentPath].pop();
        const paramWith = {
            ...(cleanData ? { cleanData } : {}),
            ...(cleanChildren ? { cleanChildren } : {}),
        };
        return this.select([...this.currentPath], [{ with: paramWith, on: last }]);
    }

    // Goes to a section and clean the current section's direct embedded children's data.
    async goAndCleanChildrenData(section, params, hasToScroll = false) {
        if (!(await this.initAndCheck(hasToScroll))) {
            return null;
        }

        this.cleanDataForCurrentSection(false, true);
        return this.select(section, params);
    }

    /**
     * Fires a request for current path (or arbitrary path)
     * @param {object} reqConfig - axios config for this request
     * @param {string} path - the path for this request
     * @param {boolean} isEmbedded - is this section embedded?
     * @param {string} section - If embedded, set this to a section
     * @param {string} currentSection - The current section
     * @param {object} resources - The resources to use in this request
     *
     */
    async requestForCurrentPath({ reqConfig, path = this.currentPath, isEmbedded = false, section, currentSection = null, resources = null }) {
        let pathForRequest = isEmbedded ? [...path, section] : [...path];
        /*
         * 'currentSection' is used by the component 'withRequest' to specify where the retrieved data needs to be loaded.
         * It is necessary for the section owners/details. This section needs data loaded, but it will never be the
         *  last section selected.
         */

        if (currentSection) {
            const currentSectionPosition = pathForRequest.indexOf(currentSection);
            if (currentSectionPosition > -1) {
                pathForRequest = pathForRequest.slice(0, currentSectionPosition + 1);
            }
        }

        // TODO: This should be implemented for the Section Reload Button
        const isCurrentSection = path === this.currentPath; // TODO: Ask if the current resource is read

        return this.request(reqConfig, pathForRequest, isCurrentSection, resources, this.useMock);
    }

    async requestForOwnerDetails(config) {
        await this.requestForCurrentPath({ ...config, path: [sectionType.OWNERS, sectionType.DETAILS] });
    }

    async goAndReloadCleaningTheCurrent(sectionPath) {
        if (!(await this.initAndCheck(true))) {
            return;
        }

        this.cleanDataForCurrentSection(true, true);
        const lastSection = [...sectionPath].pop();
        this.select(sectionPath, [{ with: { shouldReloadData: true }, on: lastSection }]);
    }

    setCurrentSectionState(state, isEmbedded = false, section) {
        const pathWithEmbeddedSection = isEmbedded ? [...this.currentPath, section] : [...this.currentPath];
        return this.setSectionState(pathWithEmbeddedSection, state);
    }

    getParentPath() {
        return this.currentPath.slice(0, -1);
    }

    async requestAndSetToParent({
        data, resource, isCritical = false, isGlobal = false, ids = [], shouldReloadData, resources = {},
    }) {
        const reqConfig = {
            ...resource,
            data,
            isCritical,
            isGlobal,
            ids,
            shouldReloadData,
        };

        const path = this.getParentPath();
        return this.requestForCurrentPath({ reqConfig, path, resources });
    }

    async requestAndSetToOwnersInfo({
        data, resource, isCritical = false, isGlobal = false, ids = [], shouldReloadData, resources = {},
    }) {
        const reqConfig = {
            ...resource,
            data,
            isCritical,
            isGlobal,
            ids,
            shouldReloadData,
        };

        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.INFO];

        return this.requestForCurrentPath({ reqConfig, path, resources });
    }

    async goToRoot() {
        return this.goWithoutClean(this.currentPath.slice(0, 1));
    }

    async goToTierEditor({ available = {}, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.TIERS, sectionType.TIER_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.TIER_EDITOR }]);
    }

    async goToClubTierEditor({ available = {}, previousPath = [], current = 'read', pathProps = [] }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };

        return this.select(
            [...previousPath, sectionType.CLUBS_TIER_EDITOR],
            [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.CLUBS_TIER_EDITOR }, ...pathProps],
        );
    }

    async goToClubEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.CLUBS_GENERAL }]);
    }

    async goToUserCreator(data, current = 'read') {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const available = data?.links?.self || {};
        const path = [sectionType.AGENTS, sectionType.AGENTS_EDITOR];

        return this.select(path, [{ with: { resources: { available, current }, ...CLEAN_PROPS }, on: sectionType.AGENTS_EDITOR }]);
    }

    async goToUserEditor(data, current = 'read') {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const available = data?.links?.self || {};
        const resources = { available, current };

        const path = [sectionType.AGENTS, sectionType.AGENTS_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.AGENTS_EDITOR }]);
    }

    async goToRoleEditor(data, current = 'read') {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const available = data?.links?.self || {};
        const path = [sectionType.ROLES, sectionType.ROLES_EDITOR];

        return this.select(path, [{ with: { resources: { available, current }, ...CLEAN_PROPS }, on: sectionType.ROLES_EDITOR }]);
    }

    async goToBalanceTypeEditor(resources) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const path = [sectionType.BALANCE_TYPES, sectionType.BALANCE_TYPES_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.BALANCE_TYPES_EDITOR }]);
    }

    async selectOwnerUpgradeDowngradeEditor(data, current = 'read') {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const available = data?.links?.self || {};
        const path = [sectionType.OWNERS, sectionType.DETAILS, sectionType.CONTRACT_EDITOR];

        return this.select(path, [
            {
                with: {
                    resources  : { available, current },
                    data       : null,
                    isReadOnly : false,
                },
                on: sectionType.CONTRACT_EDITOR,
            },
        ]);
    }

    async selectUpgradeDowngradeEditor(data, current = 'read') {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const available = data?.links?.self || {};
        const path = [sectionType.CONTRACT, sectionType.CONTRACT_EDITOR];

        return this.select(path, [{ with: { resources: { available, current }, ...CLEAN_PROPS }, on: sectionType.CONTRACT_EDITOR }]);
    }

    async goToReservationEditor() {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const path = [sectionType.RESERVATIONS, sectionType.RESERVATIONS_EDITOR];
        return this.select(path, [{ with: { data: {} }, on: sectionType.RESERVATIONS_EDITOR }]);
    }

    async goToWalletManager() {
        return this.select([sectionType.WALLET, sectionType.WALLET_MANAGER]);
    }

    async goToConversionManager(available) {
        return this.select(
            [sectionType.CONVERSIONS, sectionType.CONVERSION_MANAGER],
            [
                {
                    with : { resources: { available, current: 'init' } },
                    on   : sectionType.CONVERSION_MANAGER,
                },
            ],
        );
    }

    async goToAddBalance() {
        return this.select([sectionType.WALLET, sectionType.WALLET_ADD_BALANCE]);
    }

    async goToConversionRuleEditor({ available, isEmbedded, section, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const pathWithEmbeddedSection = isEmbedded
            ? [...this.currentPath, section, sectionType.CONVERSION_RULE_EDITOR]
            : [...this.currentPath, sectionType.CONVERSION_RULE_EDITOR];

        return this.select(pathWithEmbeddedSection, [{ with: { resources: { available, current } }, on: sectionType.CONVERSION_RULE_EDITOR }]);
    }

    async goToConversionRuleEditorAlt({ available, current = 'read' }) {
        if (!(await this.initAndCheck(false))) {
            return null;
        }

        const pathWithEmbeddedSection = [...this.currentPath, sectionType.CONVERSION_RULE_EDITOR_ALT];

        return this.select(pathWithEmbeddedSection, [{ with: { resources: { available, current } }, on: sectionType.CONVERSION_RULE_EDITOR_ALT }]);
    }

    async goToOwnersImport({ available, current }) {
        if (!(await this.initAndCheck())) {
            return null;
        }

        const path = [sectionType.OWNERS_IMPORT];
        return this.select(path, [{ with: { resources: { available, current } }, on: sectionType.OWNERS_IMPORT }]);
    }

    // Goes to a section and clean the current section's direct embedded children's data.
    async goAndCleanAll(section, params, hasToScroll = false) {
        if (!(await this.initAndCheck(hasToScroll))) {
            return null;
        }

        this.cleanDataForCurrentSection(true, true);
        return this.select(section, params);
    }

    async goWithoutClean(section, params, hasToScroll = false) {
        if (!(await this.initAndCheck(hasToScroll))) {
            return null;
        }

        return this.select(section, params);
    }

    async goToRuleEditor({ available, current, isEmbedded }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const path = isEmbedded ? [...this.currentPath, sectionType.RULE] : [...this.getParentPath(), sectionType.RULE];

        const resources = { available, current };

        return this.select(path, [{ with: { resources }, on: sectionType.RULE }]);
    }

    async goToExternalClubEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.EXTERNAL_CLUBS, sectionType.EXTERNAL_CLUBS_LIST, sectionType.EXTERNAL_CLUBS_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.EXTERNAL_CLUBS_EDITOR }]);
    }

    async goToTier({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.TIERS, sectionType.TIERS_LIST, sectionType.TIER_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.TIER_EDITOR }]);
    }

    async goToTags(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select([sectionType.DETAILS, sectionType.TAGS], [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToTagsEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.TAGS, sectionType.TAGS_LIST, sectionType.TAGS_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.TAGS_EDITOR }]);
    }

    async goToPropertyGroup(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self || {}, current: 'read' };

        return this.select([sectionType.DETAILS, sectionType.PROPERTY_GROUPS_LIST], [{ with: { resources }, on: sectionType.DETAILS }]);
    }

    async goToPropertyGroupEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.PROPERTY_GROUPS, sectionType.PROPERTY_GROUPS_LIST, sectionType.PROPERTY_GROUPS_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.PROPERTY_GROUPS_EDITOR }]);
    }

    async goToProperties(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self?.parent || {}, current: 'read' };

        return this.select([sectionType.PROPERTIES, sectionType.PROPERTIES_LIST], [{ with: { resources }, on: sectionType.RULES }]);
    }

    async goToPropertiesRules(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.rules || {}, current: 'read' };

        return this.select(
            [sectionType.PROPERTIES, sectionType.PROPERTIES_LIST, sectionType.RULES],
            [{ with: { resources, propertyName: data.name }, on: sectionType.RULES }],
        );
    }

    async goToPropertiesGroup(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.self?.parent || {}, current: 'read' };

        return this.select([sectionType.PROPERTY_GROUPS, sectionType.PROPERTY_GROUPS_LIST], [{ with: { resources }, on: sectionType.RULES }]);
    }

    async goToPropertiesGroupRules(data) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available: data?.links?.rules || {}, current: 'read' };

        return this.select(
            [sectionType.PROPERTY_GROUPS, sectionType.PROPERTY_GROUPS_LIST, sectionType.RULES],
            [{ with: { resources, propertyName: data.name }, on: sectionType.RULES }],
        );
    }

    async goToExtraAttributesEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.EXTRA_ATTRIBUTES, sectionType.EXTRA_ATTRIBUTES_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.EXTRA_ATTRIBUTES_EDITOR }]);
    }

    async goToCategoryEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.CATEGORIES, sectionType.CATEGORIES_LIST, sectionType.CATEGORIES_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.CATEGORIES_EDITOR }]);
    }

    async goToRatesEditor({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.CURRENCIES, sectionType.CURRENCIES_EDITOR];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.CURRENCIES_EDITOR }]);
    }

    async goToDocumentation({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.DOCUMENTATION, sectionType.DOCUMENTS];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS, shouldReloadData: true }, on: sectionType.DOCUMENTS }]);
    }

    async goToReleases({ available, current = 'read' }) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const resources = { available, current };
        const path = [sectionType.DOCUMENTATION, sectionType.RELEASES];

        return this.select(path, [{ with: { resources, ...CLEAN_PROPS, shouldReloadData: true }, on: sectionType.RELEASES }]);
    }

    async goToParentAndReload(avoidCheck, hasToScroll = true) {
        if (!(await this.initAndCheck(hasToScroll)) && !avoidCheck) {
            return null;
        }

        const last = [...this.currentPath].pop();
        const selectableParentPath = this.getSelectableParentPath();
        return this.select(
            selectableParentPath,
            [{ with: { shouldReloadData: true }, on: [...selectableParentPath].pop() }],
            [{ with: CLEAN_PROPS, on: last }],
        );
    }

    async goTo(sectionPath, resources = null, parentResources = null) {
        if (!(await this.initAndCheck(true))) {
            return null;
        }

        const lastSection = [...sectionPath].pop();
        // parentResources has the purpose to keep parent resources in our selectionHistory,
        // that is useful when you move among tabs and click back button, in that case it is necessary have the resources related to 'details' selection.
        const params = parentResources
            ? [
                { with: { resources }, on: lastSection },
                { with: { resources: parentResources, shouldReloadData: true }, on: parentResources.name },
            ]
            : [{ with: { resources }, on: lastSection }];

        return this.select(sectionPath, params);
    }

    async goToWith(sectionPath, { resources, data }, hasToScroll = true) {
        if (!(await this.initAndCheck(hasToScroll))) {
            return null;
        }

        let params = { shouldReloadData: true, resources };

        if (typeof data !== 'undefined') {
            params = { ...params, data };
        }

        const lastSection = [...sectionPath].pop();
        return this.select(sectionPath, [{ with: params, on: lastSection }]);
    }

    goAndReload(sectionPath) {
        const lastSection = [...sectionPath].pop();
        this.select(sectionPath, [{ with: { shouldReloadData: true }, on: lastSection }]);
    }

    async initialRequestAndGoTo(idsList, path, sectionProps, shouldReloadData) {
        if (shouldReloadData === 'useTemplate') {
            await this.setSectionsIds(idsList);
        }

        this.select(path, sectionProps, null, true);
    }

    cleanMeAndGoTo(sectionPath) {
        const lastSection = [...sectionPath].pop();
        const unselectedBranchParams = [{ with: CLEAN_PROPS, on: lastSection }];

        this.select(sectionPath, [{ with: { shouldReloadData: true }, on: lastSection }], unselectedBranchParams);
    }

    async goToReasonEditor({ available, current, isEmbedded, section }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        const pathWithEmbeddedSection = isEmbedded ? [...this.currentPath, section, sectionType.REASON] : [...this.currentPath, sectionType.REASON];
        return this.select(pathWithEmbeddedSection, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.REASON }]);
    }

    async goToModificationConversion({ available, current, isEmbedded, section }) {
        if (!(await this.initAndCheck())) {
            return null;
        }
        const resources = { available, current };

        const pathWithEmbeddedSection = isEmbedded ? [...this.currentPath, section, sectionType.CONVERSION_MODIFICATION] : [...this.currentPath, sectionType.CONVERSION_MODIFICATION];
        return this.select(pathWithEmbeddedSection, [{ with: { resources, ...CLEAN_PROPS }, on: sectionType.CONVERSION_MODIFICATION }]);
    }

}
