import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormContext from '../formContext';
import Card from 'Components/Card';
import SummaryField from './SummaryField';
import { transformToSnake } from 'Utils';
import translated from 'Constants/labels/translated';

/**
 * Shows a summary of the information loaded so far, for the
 * moment is only available in forms wrapped in a Form.Wrapper
 */
function Summary({ extraFields, title, className }) {
    const { submitData } = useContext(FormContext);
    const { values, rawValues } = submitData;

    if (!(values && rawValues && Object.keys(values).length)) {
        return (
            <Card id="summary" title={title} className={className} unelevated>
                <table className="summary-field-table">
                    <tbody>
                        <SummaryField
                            label={translated.global.walletSummary.field}
                            value="..."
                            formValues={{}}
                            type=""
                        />
                    </tbody>
                </table>
            </Card>
        );
    }

    // The filter starts with the formatted values, which are the ones that will
    // be submitted, and continues with the information contained in the rawValues
    // We compare the isCompleted value with 'false', because 'undefined' is a not
    // yet setted value in the inputs
    const fieldsThatCanBeRendered = Object.keys(values).filter((key) => {
        const { isCompleted, isRendered, isSelectable, isSelected, avoidOnSummary } = rawValues[key];
        let { value } = rawValues[key];

        // Split, Slider, Date and Range have their values inside an object
        if (typeof value === 'object' && value !== null) {
            if ('inputValue' in value) {
                value = value.inputValue;
            } else if ('min' in value) {
                value = value.min;
            } else if ('max' in value) {
                value = value.max;
            } else if ('begin' in value) {
                value = value.begin;
            } else if ('end' in value) {
                value = value.end;
            }
        }

        return (
            (value || typeof value === 'boolean' || typeof value === 'number' || isCompleted === false)
            && ((isSelectable && isSelected) || !isSelectable)
            && !avoidOnSummary
            && isRendered
        );
    });

    const titles = Object.keys(rawValues).filter((inputKey) => rawValues[inputKey].type === 'title');

    const allSummaryFields = [...titles, ...fieldsThatCanBeRendered, ...extraFields];

    const sortedFields = allSummaryFields.sort((oneField, anotherField) => {
        const oneInput = rawValues[oneField] || oneField;
        const anotherInput = rawValues[anotherField] || anotherField;

        if (typeof oneInput.summaryIndex === 'number' && typeof anotherInput.summaryIndex === 'number') {
            return oneInput.summaryIndex - anotherInput.summaryIndex;
        }
        if (typeof oneInput.summaryIndex === 'number') {
            return -1;
        }
        return 1;
    });

    return (
        <Card id="summary" title={title} className={className} unelevated>
            <table className="summary-field-table">
                <tbody>
                    {sortedFields.map((field, index) => (
                        <SummaryField
                            {...(rawValues[field] ? rawValues[field] : field)}
                            id={transformToSnake(typeof field === 'string' ? field : field.label)}
                            formValues={values}
                            key={(rawValues[field] ? field : field.key) || `summary-${index}`}
                        />
                    ))}
                </tbody>
            </table>
        </Card>
    );
}

Summary.defaultProps = {
    title       : <FormattedMessage id={translated.global.summary} defaultMessage={translated.global.summary} />,
    className   : '',
    extraFields : [],
};

Summary.propTypes = {
    title       : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className   : PropTypes.string,
    extraFields : PropTypes.arrayOf(
        PropTypes.shape({
            label         : PropTypes.string,
            value         : PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
            summaryFormat : PropTypes.func,
            summaryIndex  : PropTypes.string,
            type          : PropTypes.string,
            isValid       : PropTypes.bool,
            isCompleted   : PropTypes.bool,
            key           : PropTypes.string,
        }),
    ),
};

export default Summary;
