import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'Components/Button';
import { useEventScroll } from 'Hooks';
import translated from 'Constants/labels/translated';

function FloatingActionButton() {
    const [isVisible, setIsVisible] = useState(false);

    const visibleOnScroll = () => {
        // set the offet to match the navbar height
        if (window.pageYOffset > 72) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEventScroll('.floating-action-button-wrapper', visibleOnScroll, () => setIsVisible);

    return (
        <div id="floating-action-button-wrapper" className="floating-action-button-wrapper">
            <Button
                id="go-up"
                className={`fab-go-up ${isVisible ? 'visible' : 'invisible'}`}
                icon="ArrowUp"
                color="secondary"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
                <span>
                    <FormattedMessage id={translated.global.floatingActionButton.goUp} defaultMessage={translated.global.floatingActionButton.goUp} />
                </span>
            </Button>
        </div>
    );
}

export default FloatingActionButton;
