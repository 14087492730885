module.exports = Object.freeze({
    title   : 'apf.tiers.title',
    enable  : { success: 'apf.tiers.enable.success', error: 'apf.tiers.enable.error' },
    disable : { success: 'apf.tiers.disable.success', error: 'apf.tiers.disable.error' },
    delete  : {
        confirmation               : 'apf.tiers.delete.confirmation',
        success                    : 'apf.tiers.delete.success',
        error                      : 'apf.tiers.delete.error',
        errorEntityCannotBeDeleted : 'apf.tiers.delete.errorEntityCannotBeDeleted',
    },
    createTitle             : 'apf.tiers.createTitle',
    editTitle               : 'apf.tiers.editTitle',
    created                 : 'apf.tiers.created',
    updated                 : 'apf.tiers.updated',
    promoCodesUpdated       : 'apf.tiers.promoCodesUpdated',
    errorOnUpdatePromoCodes : 'apf.tiers.errorOnUpdatePromoCodes',
    errorOnSave             : 'apf.tiers.errorOnSave',
    information             : 'apf.tiers.information',
    tierConfig              : 'apf.tiers.tierConfig',
    hotelTypes              : 'apf.tiers.hotelTypes',
    general                 : 'apf.tiers.general',
    channelId               : 'apf.tiers.channelId',
    promoCodes              : 'apf.tiers.promoCodes',
    promoCodeClient         : 'apf.tiers.promoCodeClient',
    promoCodeAgent          : 'apf.tiers.promoCodeAgent',
    doesNot                 : 'apf.tiers.doesNot',
    noTiers                 : 'apf.tiers.noTiers',
    clubTierIncompleteData  : 'apf.tiers.clubTierIncompleteData',
    channelHelp             : 'apf.tiers.channelHelp',
    errors                  : { errorEntityCannotBeModified: 'apf.tiers.errors.errorEntityCannotBeModified' },
});
