import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import useDataLoader from './useDataLoader';
import useErrorHandler from './useErrorHandler';
import SectionBlocker from './SectionBlocker';
import ErrorMessage from 'Components/ErrorMessage';
import PanelContext from 'State/panelContext';
import Loading from 'Components/Loading';
import { fetchingShape } from 'Constants/PropTypes';
import globalConstants from 'Constants/global';
import Resource from 'Classes/Resource';
import { shouldRetry } from './utils';
import Modal from 'Components/Modal';

/**
 * It handles fetching and data reloading, as well as error handling for the sections in which it is used.
 * Manages alerts and error screens, as well as loadings that have defined the sections or the default.
 *
 * @param {object} data                     The data returned from server, normally contains an ID
 * @param {object} fetching                 Object that contains info about the current fetching status
 * @param {object} error                    Setted by the server with information about errors
 * @param {object} resources                Has the available, current, templates, and other data about the possible actions
 * @param {bool string} shouldReloadData    Indicates that the component need to get the data from the server again
 * @param {array} pathForResource           Defines where the data from the requests will be stored in the store
 * @param {array} name                      The section's name
 * @param {string} currentSection           The type of the wrapped section
 * @param {object} customProps              Section custom properties
 */

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
const withRequest = (WrappedSection) => function WithRequest({
    data,
    fetching,
    resources: _resources,
    error,
    shouldReloadData,
    pathForResource,
    ids,
    config,
    sectionErrors,
    customProps,
    ...restOfProps
}) {
    WithRequest.displayName = `withRequest(${WrappedSection.name})`;
    const { navigator, snackbar, QA, url, app } = useContext(PanelContext);
    const { currentSection, name, state } = restOfProps;

    const isEditing = !!data?.id;
    const [resources, requestDataForThisSection] = useDataLoader(pathForResource, currentSection, data, _resources, config?.requestTimeout);

    const hasChangedTheEntity = data?.id && resources.available?.id && data.id !== resources.available.id;

    const [canLoadData, errorState, resetErrors, stopRetrying] = useErrorHandler(
        error,
        app.shouldRefreshUser,
        requestDataForThisSection,
        config?.handledErrors,
    );
    const [reloadDataFail, setReloadDataFail] = useState(false);

    // Only fetch on mount, if shouldReloadData is marked, the fetch is done in the previous effect
    useEffect(() => {
        if (!fetching.isGlobal && !shouldReloadData && canLoadData) {
            requestDataForThisSection();
        }

        // When the section unmounts it stops the possible retries that could be happening.
        return stopRetrying;
    }, []);

    // If shouldReloadData is true or we are retrying from a previous error, we try to get the data here
    useEffect(() => {
        if (shouldReloadData && canLoadData && !fetching.isGlobal) {
            // When shouldReloadData is equal to 'useTemplate' should use the templates to request the data by using the ids
            if (shouldReloadData === 'useTemplate' && _resources.templates?.[resources.current]) {
                const resourceForRequest = new Resource({
                    config      : _resources.templates,
                    queryParams : state?.appliedFilters?.queryParams,
                });

                const allAvailableResources = resourceForRequest.getRelatedLinks(resources.current, ids);

                requestDataForThisSection(resourceForRequest[resources.current](ids), allAvailableResources);
            } else if (shouldReloadData === 'useTemplate' || !requestDataForThisSection()) {
                setReloadDataFail(true);
            }
        }
    }, [shouldReloadData]);

    useEffect(() => {
        if (data && config?.updateNotifications) {
            // The sections is configured update notifications in some other section.
            navigator.setSectionState(config.updateNotifications.sectionToUpdateOnChange, config.updateNotifications.getNotification(data));
        }
    }, [data]);

    // Marks section as loaded
    useEffect(() => {
        if (data && QA.isActive) {
            navigator.sectionLoaded(navigator.currentPath, data);
        }
    }, [QA.isActive, data, navigator.currentPath]);

    const retry = () => {
        snackbar.hide();
        resetErrors();
        requestDataForThisSection();
    };

    if (!canLoadData && !errorState.hasReachedLimit && !app.shouldRefreshUser) {
        const entity = name
            ? (
                <FormattedMessage
                    id={translated.global.errors.retryingEntity}
                    defaultMessage={translated.global.errors.retryingEntity}
                    values={{ entity: name }}
                />
            )
            : null;

        const showRetryError = shouldRetry(errorState);

        snackbar.show({
            error         : errorState?.error,
            content       : showRetryError ? translated.global.errors.retrying : translated.global.errors.default,
            contentValues : showRetryError ? { entity } : null,
        });
    }

    if ((!data && errorState.hasReachedLimit) || reloadDataFail) {
        const errorContent = (
            <ErrorMessage
                isSmall
                title={!config?.isModal && 'Error'}
                header={!config?.isModal && name}
                data={reloadDataFail ? translated.global.errors.reloadDataFail : translated.global.errors.serverDoesNotRespond}
                onRetry={errorState.hasReachedLimit ? retry : null}
                error={errorState?.error}
                sectionErrors={sectionErrors}
                isEmbedded={restOfProps.isEmbedded || config?.isModal}
            />
        );

        if (config?.isModal) {
            return (
                <Modal
                    title={translated.global.state.error}
                    className="modal-error"
                    closeButton={{
                        text    : translated.global.buttons.close,
                        onClick : navigator.goToParentAndReload,
                    }}
                >
                    {errorContent}
                </Modal>
            );
        }

        return errorContent;
    }

    // When shouldReloadData is true, means that there is already loaded data in the section, so we don't need to show the
    // Loading Skeleton if hasChangedTheEntity avoid to make an instance with the Section until we have the correct data
    // Otherwise we don't need to desinstantiate the whole component and mount it again
    // when the data comes we only show the Loading bar at the top as a feedback
    if (!data || (url.shouldReloadUrlStage && url.shouldReloadUrlStage !== globalConstants.INIT && !data) || hasChangedTheEntity) {
        return WrappedSection.Loading ? <WrappedSection.Loading /> : <Loading />;
    }

    return (
        <>
            {(shouldReloadData || fetching.isGlobal || errorState.isTrying) && <Loading />}
            <SectionBlocker isBlocked={errorState.isTimeout || errorState.isCritical} onRetry={errorState.hasReachedLimit && retry}>
                <WrappedSection
                    {...customProps}
                    {...restOfProps}
                    data={data}
                    resources={resources}
                    fetching={fetching}
                    error={error}
                    isEditing={isEditing}
                    reloadData={requestDataForThisSection}
                    isReloadingData={shouldReloadData}
                    setSectionState={navigator.setCurrentSectionState}
                    pathForResource={pathForResource}
                />
            </SectionBlocker>
        </>
    );
};

withRequest.defaultProps = { config: null, customProps: {} };

withRequest.propTypes = {
    data        : PropTypes.shape({}).isRequired,
    resources   : PropTypes.shape({}).isRequired,
    fetching    : fetchingShape.isRequired,
    config      : PropTypes.shape({}),
    customProps : PropTypes.shape({}),
};

export default withRequest;
