import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import { scrollToTop } from 'Utils';
import Button from 'Components/Button';
import { getSectionsForBreadcrumb } from './utils';
import translated from 'Constants/labels/translated';

function Breadcrumb({ className, style, sections: storeSections, breadRef }) {
    const intl = useIntl();
    const { app, toggle, path, navigator } = useContext(PanelContext);
    const { current } = path;
    const sections = getSectionsForBreadcrumb(intl, current, storeSections);
    const lastSection = [...sections].pop();

    if (!sections || sections.length <= 1 || sections.every((section) => section.isHidden)) {
        // Breadcrumb is hidden on root sections
        return null;
    }

    const onSectionClick = async (index) => {
        // If the section has a default as child, we select that one
        const sectionChunk = sections[index + 1] && sections[index + 1].isDefault ? index + 2 : index + 1;
        const selectedSection = sections.map((s) => s.key).slice(0, sectionChunk);
        navigator.goAndReloadCleaningTheCurrent(selectedSection);
    };

    const onSwitcherClick = () => {
        toggle.breadcrumbTabsVisibility();
        scrollToTop();
    };

    const onGoBack = () => {
        if (app.areBreadcrumbTabsVisible) {
            toggle.breadcrumbTabsVisibility();
        }
        navigator.goToParentAndReload();
    };

    return (
        <div className={`breadcrumb-wrapper ${className}`} style={style} ref={breadRef}>
            {lastSection && !lastSection.isRoot && (
                <>
                    <Button
                        id="breadcrumb-go-to"
                        className={`switcher ${app.areBreadcrumbTabsVisible ? 'switcher-open' : ''}`}
                        icon={`${app.areBreadcrumbTabsVisible ? 'Close' : 'AnimationOutline'}`}
                        onClick={onSwitcherClick}
                        tooltip={
                            app.areBreadcrumbTabsVisible
                                ? intl.formatMessage({
                                    id             : translated.global.breadcrumb.closeGoTo,
                                    defaultMessage : translated.global.breadcrumb.closeGoTo,
                                })
                                : intl.formatMessage({
                                    id             : translated.global.breadcrumb.goTo,
                                    defaultMessage : translated.global.breadcrumb.goTo,
                                })
                        }
                    />
                    <Button
                        id="breadcrumb-go-back"
                        className="go-back"
                        icon="SubdirectoryArrowLeft"
                        onClick={onGoBack}
                        tooltip={intl.formatMessage({
                            id             : translated.global.breadcrumb.upperLevel,
                            defaultMessage : translated.global.breadcrumb.upperLevel,
                        })}
                    />
                </>
            )}
            <ul id="breadcrumb" className="breadcrumb">
                {sections.length >= 1
                    && sections.map(
                        (section, index) => !section.isHidden && (
                            <li
                                id={`breadcrumb-${section.key}`}
                                onClick={() => onSectionClick(index)}
                                className={`breadcrumb-item ${section.isDisabled ? 'is-disabled' : ''}`}
                                key={section.key}
                            >
                                {section.name}
                            </li>
                        ),
                    )}
            </ul>
        </div>
    );
}

Breadcrumb.defaultProps = {
    className : '',
    style     : null,
    breadRef  : () => {
        // Default
    },
};

Breadcrumb.propTypes = {
    className : PropTypes.string,
    style     : PropTypes.shape({}),
    sections  : PropTypes.shape({}).isRequired,
    breadRef  : PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

export default forwardRef((props, ref) => <Breadcrumb {...props} breadRef={ref} />);
