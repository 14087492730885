/* eslint-disable class-methods-use-this */
import {
    format, isValid, isSameSecond, isAfter, isBefore, formatRelative, add, sub,
    getYear, getMonth, set, differenceInDays, startOfMonth, isToday, getDaysInMonth,
    eachDayOfInterval, startOfWeek, endOfWeek, getDay, getDate, isMatch,
} from 'date-fns';
import { enGB, es } from 'date-fns/locale';

const locales = { en: enGB, es };

export const MAX_YEARS_FROM_NOW = 50;
export const OLD_DEFAULT_YEAR = 1930;

function isDateWithoutTime(value) {
    return typeof value === 'string'
        ? /^[\d]{2,4}-[\d]{2}-[\d]{2,4}$/.test(value)
        : false;
}

function isDateFormatValid(value) {
    // If the value is a string, we check for the format and that month-day are valid (to avoid problems with "2000-02-31")
    if (value && typeof value === 'string' && /^[\d]{4}-[\d]{2}-[\d]{2}/.test(value)) {
        const partialDate = value.substring(0, 10);
        // We check that the date has the correct format and that the month-day pair is valid.
        return isMatch(partialDate, 'yyyy-MM-dd');
    }

    return true;
}

export default class DateManager {

    constructor(language) {
        this.language = language;
        this.locale = locales[language];
    }

    setLanguage(language) {
        this.locale = locales[language];
    }

    generateDate(value) {
        if (!value) {
            return null;
        }

        let date = new Date(value);

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(date)) {
            return null;
        }

        if (isDateWithoutTime(value)) {
            // When the string received does NOT have the hours (only has the date)
            //  we modify the date generated to avoid 'changing' the chosen date.
            const offset = new Date().getTimezoneOffset();
            if (offset !== 0) {
                date = add(date, { minutes: offset });
            }
        }

        return date;
    }

    create(value) {
        if (!value) {
            return this.getCurrentDay();
        }

        return this.generateDate(value);
    }

    convertDate(date, granularity = 'days') {
        if (!date) {
            return null;
        }

        const convertedDate = this.generateDate(date);

        const conversionParams = {
            hours        : 0,
            minutes      : 0,
            seconds      : 0,
            milliseconds : 0,
        };

        switch (granularity) {
            case 'months':
                conversionParams.date = 0;
                break;

            case 'years':
                conversionParams.month = 0;
                conversionParams.date = 0;
                break;

            default:
                break;
        }

        return set(convertedDate, conversionParams);
    }

    getCurrentDay() {
        return this.generateDate(new Date());
    }

    isValidDate(value) {
        return value && isValid(this.generateDate(value)) && isDateFormatValid(value);
    }

    isValidRange(start, end) {
        return start && end
            && this.isValidDate(start)
            && this.isValidDate(end)
            && this.isSameOrBefore(start, end);
    }

    isSameOrBefore(start = new Date(), end = new Date(), granularity = 'day') {
        const convertedStart = this.convertDate(start, granularity);
        const convertedEnd = this.convertDate(end, granularity);

        return isSameSecond(convertedStart, convertedEnd) || !!isBefore(convertedStart, convertedEnd);
    }

    isSameOrAfter(start = new Date(), end = new Date(), granularity = 'day') {
        const convertedStart = this.convertDate(start, granularity);
        const convertedEnd = this.convertDate(end, granularity);

        return isSameSecond(convertedStart, convertedEnd) || !!isAfter(convertedStart, convertedEnd);
    }

    isBefore(first, second, granularity = 'day') {
        if (!first || !second) {
            return false;
        }

        const convertedFirst = this.convertDate(first, granularity);
        const convertedSecond = this.convertDate(second, granularity);

        return isBefore(convertedFirst, convertedSecond);
    }

    isAfter(first, second, granularity = 'day') {
        if (!first || !second) {
            return false;
        }

        const convertedFirst = this.convertDate(first, granularity);
        const convertedSecond = this.convertDate(second, granularity);

        return isAfter(convertedFirst, convertedSecond);
    }

    isSame(first, second, granularity = 'day') {
        if (!first || !second) {
            return false;
        }

        const convertedFirst = this.convertDate(first, granularity);
        const convertedSecond = this.convertDate(second, granularity);

        return isSameSecond(convertedFirst, convertedSecond);
    }

    isBetweenEqual(date, start, end, granularity = 'day') {
        if (!date || !start || !end) {
            return false;
        }

        const convertedDate = this.convertDate(date, granularity);
        const convertedStart = this.convertDate(start, granularity);
        const convertedEnd = this.convertDate(end, granularity);

        return (
            (isSameSecond(convertedStart, convertedEnd) || isBefore(convertedStart, convertedEnd)) // Validate the start and end itself
            && ((isSameSecond(convertedDate, convertedStart) || isSameSecond(convertedDate, convertedEnd))
                || (isAfter(convertedDate, convertedStart) && isBefore(convertedDate, convertedEnd))
            )
        );
    }

    isBetween(date, start, end, granularity = 'day') {
        if (!date || !start || !end) {
            return false;
        }

        const convertedDate = this.convertDate(date, granularity);
        const convertedStart = this.convertDate(start, granularity);
        const convertedEnd = this.convertDate(end, granularity);

        return (
            (isBefore(convertedStart, convertedEnd)) // Validate the start and end itself
            && (isAfter(convertedDate, convertedStart) && isBefore(convertedDate, convertedEnd)
            )
        );
    }

    convertToDisplayableDate(value) {
        const date = this.generateDate(value);

        return this.isValidDate(date) ? this.format(date, 'yyyy-MM-dd') : null;
    }

    convertToDisplayableDateTime(value) {
        const date = this.generateDate(value);

        return this.isValidDate(date) ? this.format(date, 'yyyy-MM-dd hh:mm aaa') : null;
    }

    format(date, pattern) {
        const value = this.generateDate(date);

        if (!value) {
            return '';
        }

        return format(value, pattern, { locale: this.locale });
    }

    defaultDateFormat(date) {
        return this.format(date, 'yyyy-MM-dd');
    }

    defaultDateTimeFormat(date) {
        return this.format(date, 'yyyy-MM-dd h:mm aa');
    }

    fromNow(value) {
        const date = this.generateDate(value);
        const today = this.getCurrentDay();

        return formatRelative(date, today, { locale: this.locale });
    }

    yearInDefaultFormat(year = OLD_DEFAULT_YEAR) {
        return this.defaultDateFormat(
            this.setYear(
                this.getCurrentDay(),
                year,
            ),
        );
    }

    maxDateInDefaultFormat(years = MAX_YEARS_FROM_NOW) {
        return this.defaultDateFormat(
            this.add(
                this.getCurrentDay(),
                years,
                'years',
            ),
        );
    }

    getMonth(value) {
        return getMonth(this.generateDate(value));
    }

    setMonth(value, newMonth) {
        return set(this.generateDate(value), { month: newMonth });
    }

    getYear(value) {
        return getYear(this.generateDate(value));
    }

    setYear(value, newYear) {
        return set(this.generateDate(value), { year: newYear });
    }

    getDayOfMonth(value) {
        return getDate(this.generateDate(value));
    }

    setDayOfMonth(value, newYear) {
        return set(this.generateDate(value), { date: newYear });
    }

    getDayOfWeek(value) {
        return getDay(this.generateDate(value));
    }

    add(value, amount, unit) {
        return add(this.generateDate(value), { [unit]: amount });
    }

    sub(value, amount, unit) {
        return sub(this.generateDate(value), { [unit]: amount });
    }

    differenceInDays(first, second) {
        return differenceInDays(this.generateDate(first), this.generateDate(second));
    }

    firstDayOfMonth(date) {
        return startOfMonth(this.generateDate(date));
    }

    isToday(date) {
        return isToday(this.generateDate(date));
    }

    getDaysInMonth(date) {
        return getDaysInMonth(this.generateDate(date));
    }

    getDaysOfWeek() {
        const now = new Date();
        const arr = eachDayOfInterval({ start: startOfWeek(now), end: endOfWeek(now) });

        return arr.reduce((a, d) => {
            a.push(format(d, 'EEEEEE'));
            return a;
        }, []);
    }

    getNextDayFormatted(date) {
        let convertedDate = this.create(date);

        // eslint-disable-next-line no-restricted-globals
        if (!convertedDate || isNaN(convertedDate)) {
            return null;
        }

        const is29Feb = getMonth(convertedDate) === 1 && getDate(convertedDate) === 29;

        convertedDate = is29Feb
            ? this.add(date, 2, 'days')
            : this.add(date, 1, 'days');

        return this.defaultDateFormat(convertedDate);
    }

}
