module.exports = Object.freeze({
    enable: {
        success : 'The tier has been enabled',
        error   : 'The tier could not be enabled',
    },
    disable: {
        success : 'The tier has been disabled',
        error   : 'The tier could not be disabled',
    },
    delete: {
        confirmation               : 'Delete tier?',
        success                    : 'The tier has been removed',
        error                      : 'The tier could not be removed',
        errorEntityCannotBeDeleted : 'The tier could not be removed since it is being used',
    },
    createTitle             : 'Create Tier',
    editTitle               : 'Edit tier',
    created                 : 'The tier has been added',
    updated                 : 'The tier has been updated',
    promoCodesUpdated       : 'The configuration has been updated',
    errorOnUpdatePromoCodes : 'The configuration could not be updated',
    errorOnSave             : 'The tier could not be saved',
    information             : 'Information',
    tierConfig              : 'Configuration',
    hotelTypes              : 'Hotel Types',
    general                 : 'General',
    channelId               : 'Channel ID',
    promoCodes              : 'Promo codes',
    promoCodeClient         : 'Client',
    promoCodeAgent          : 'Agent',
    doesNot                 : "Doesn't ",
    noTiers                 : 'There are no tiers added',
    clubTierIncompleteData  : 'Field "Channel ID" can be filled later but it must have a value so the system can work properly',

    errors: { errorEntityCannotBeModified: 'The tier could not be modified because it is being used' },
});
