import { mdiFileCogOutline } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

export default {
    [sectionType.ACCOUNT_STATEMENTS]: {
        isEnabled : false,
        config    : {
            url            : '/panel/accountStatement',
            icon           : mdiFileCogOutline,
            defaultSection : sectionType.ACCOUNT_STATEMENT,
        },
        sections: {
            [sectionType.ACCOUNT_STATEMENT]: {
                isSelected : true,
                isRoot     : true,
                config     : {
                    isDefaultSection     : true,
                    isHiddenInBreadcrumb : true,
                    cleanWhenLeaving     : sectionType.ACCOUNT_STATEMENT,
                },
                resources: { templates: { name: resourceNames.ACCOUNT_STATEMENTS } },
            },
        },
    },
};
