module.exports = Object.freeze({
    enable  : { success: 'apf.contractTypes.enable.success', error: 'apf.contractTypes.enable.error' },
    disable : { success: 'apf.contractTypes.disable.success', error: 'apf.contractTypes.disable.error' },
    delete  : {
        confirm                    : 'apf.contractTypes.delete.confirm',
        success                    : 'apf.contractTypes.delete.success',
        error                      : 'apf.contractTypes.delete.error',
        errorEntityCannotBeDeleted : 'apf.contractTypes.delete.errorEntityCannotBeDeleted',
    },
    cancel : { title: 'apf.contractTypes.cancel.title', message: 'apf.contractTypes.cancel.message' },
    save   : { success: 'apf.contractTypes.save.success', error: 'apf.contractTypes.save.error' },
    list   : { sectionTitle: 'apf.contractTypes.list.sectionTitle', empty: 'apf.contractTypes.list.empty' },
    create : {
        title           : 'apf.contractTypes.create.title',
        sectionTitle    : 'apf.contractTypes.create.sectionTitle',
        entitiesWarning : 'apf.contractTypes.create.entitiesWarning',
    },
    edit: {
        title        : 'apf.contractTypes.edit.title',
        sectionTitle : 'apf.contractTypes.edit.sectionTitle',
        success      : 'apf.contractTypes.edit.success',
        error        : 'apf.contractTypes.edit.error',
    },
    rulesName         : 'apf.contractTypes.rulesName',
    name              : 'apf.contractTypes.name',
    id                : 'apf.contractTypes.id',
    primaryCurrency   : 'apf.contractTypes.primaryCurrency',
    secondaryCurrency : 'apf.contractTypes.secondaryCurrency',
    currency          : 'apf.contractTypes.currency',
    delta             : 'apf.contractTypes.delta',
    code              : 'apf.contractTypes.code',
});
