
export function getVariantClass(variant) {
    let variantClass = '';

    switch (variant) {
        case 'outlined':
            variantClass = 'button-outlined';
            break;
        case 'text':
            variantClass = 'button-text';
            break;
        case 'unelevated':
            variantClass = 'button-unelevated';
            break;
        default:
            break;
    }

    return variantClass;
}

export function getColorClass(color) {
    let colorClass = '';
    switch (color) {
        case 'primary':
            colorClass = 'button-primary';
            break;
        case 'secondary':
            colorClass = 'button-secondary';
            break;
        case 'grey':
            colorClass = 'button-grey';
            break;
        default:
            break;
    }

    return colorClass;
}

export function getIconVariant(icon, children) {
    let iconVariant = '';
    if (icon) {
        iconVariant = children !== '' && children != null ? 'button-with-icon' : 'button-icon';
    }

    return iconVariant;
}

export function getSizeClass(size) {
    let sizeClass = '';
    let iconSize = '';
    let iconSizeLabel = '';

    switch (size) {
        case 'small':
            sizeClass = 'button-small';
            iconSize = '20px';
            iconSizeLabel = '16px';
            break;
        case 'medium':
            sizeClass = 'button-medium';
            iconSize = '24px';
            iconSizeLabel = '20px';
            break;
        case 'large':
            sizeClass = 'button-large';
            iconSize = '28px';
            iconSizeLabel = '24px';
            break;
        default: break;
    }

    return { sizeClass, iconSize, iconSizeLabel };
}
