import translated from 'Constants/labels/translated';
import { transactionType } from 'Constants/global';
import { sectionType } from 'Constants/types';

// Wallet Utils

const formatPointItems = (summary) => {
    if (!summary || !summary.items || !summary.items.length) {
        // There are no Club Points for this owner
        return null;
    }

    const { items } = summary;

    return items?.map((e) => ({ ...e, currencyName: e?.balanceType?.currency?.name }));
};

function transformTransaction(item) {
    const newItem = { ...item };

    newItem.balances = newItem.balances?.map((each) => ({
        ...each,
        transactionType        : newItem.type,
        belongsToCurrentWallet : newItem.belongsToCurrentWallet,
        currency               : each.balanceType?.currency?.name,
    }));

    if (item.type === transactionType.ALLOCATION || item.type === transactionType.MANAGEMENT) {
        return { ...newItem, processedName: item.type };
    }
    return { ...newItem, processedName: item.name };
}

const getTransactionsObject = (transactions) => {
    if (!transactions || !transactions.items || !transactions.items.length) {
        // There are no transactions for this owner
        return { items: null, pagination: null };
    }

    return { items: transactions.items.map(transformTransaction), pagination: transactions.pagination };
};

// Rules Utils

const createReqConfigForActive = (link, rules, isActive) => {
    const selectedRules = rules.map((item) => ({ id: item.id, isActive }));

    const disableRules = {
        type   : rules[0].type,
        enable : selectedRules,
    };

    return {
        ...link,
        data             : disableRules,
        shouldReloadData : true,
        isCritical       : false,
        isGlobal         : false,
        ids              : rules.map((item) => item.visualId),
    };
};

const getRulesTableConfig = ({
    rulesData,
    collectionName,
    links,
    fetching,
    isEmbedded,
    navigator,
    snackbar,
    confirmation,
    selectRuleEditorMethod,
    resources,
    isEditEnabled,
}) => {
    const bulkActions = [];
    if (links?.bulks?.enable) {
        bulkActions.push({
            content  : translated.global.buttons.enable,
            key      : 'enable',
            callback : async (selected) => {
                const reqConfig = createReqConfigForActive(links.bulks.disable, selected, true);
                try {
                    await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                    snackbar.show({ content: translated.rules.success.rulesEnabled, isSuccess: true });
                } catch (e) {
                    snackbar.show({ error: e, content: translated.rules.errors.rulesEnabled, isError: true });
                }
            },
        });
    }

    if (links?.bulks?.disable) {
        bulkActions.push({
            key      : 'disable',
            content  : translated.global.buttons.disable,
            callback : async (selected) => {
                const reqConfig = createReqConfigForActive(links.bulks.disable, selected, false);

                try {
                    await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                    snackbar.show({
                        content   : translated.rules.success.rulesDisabled,
                        isSuccess : true,
                    });
                } catch (e) {
                    snackbar.show({
                        content : translated.rules.errors.rulesDisabled,
                        isError : true,
                        error   : e,
                    });
                }
            },
        });
    }

    if (links?.bulks?.delete) {
        const content = translated.global.buttons.delete;
        bulkActions.push({
            key      : 'delete',
            content,
            callback : (selected) => {
                const acceptMessage = translated.global.buttons.delete;
                confirmation.show({
                    message       : translated.rules.deleteConfirmation,
                    messageValues : { amount: selected.length },
                    acceptMessage,
                    onAccept      : async () => {
                        const rules = selected.map((item) => ({ id: item.id }));
                        const deleteRules = {
                            type   : selected[0].type,
                            delete : rules,
                        };
                        const reqConfig = {
                            ...links.bulks.delete,
                            data             : deleteRules,
                            shouldReloadData : true,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : selected.map((item) => item.visualId),
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                            snackbar.show({
                                content   : translated.rules.success.rulesRemoved,
                                isSuccess : true,
                            });
                        } catch (e) {
                            snackbar.show({
                                content : translated.rules.errors.rulesRemoved,
                                isError : true,
                                error   : e,
                            });
                        }
                    },
                });
            },
        });
    }

    return {
        columns: [
            {
                title       : translated.global.name,
                key         : 'name',
                isVisible   : true,
                hasMaxWidth : true,
            },
            {
                title    : translated.global.status,
                key      : 'isActive',
                whenTrue : {
                    text      : translated.global.state.enabled,
                    className : 'status status-enabled',
                },
                whenFalse: {
                    text      : translated.global.state.disabled,
                    className : 'status status-disabled',
                },
                isVisible: true,
            },
        ],
        rows: {
            actions: isEditEnabled ? [
                {
                    key      : 'view',
                    content  : translated.global.buttons.view,
                    when     : (rule) => rule?.links?.self?.read && !rule?.links?.self?.update,
                    callback : async (rule) => {
                        navigator[selectRuleEditorMethod]({
                            available : rule.links.self,
                            current   : 'read',
                            isEmbedded,
                        });
                    },
                },
                {
                    key      : 'edit',
                    content  : translated.global.buttons.edit,
                    when     : (rule) => rule?.links?.self?.update,
                    callback : async (rule) => {
                        navigator[selectRuleEditorMethod]({
                            available : rule.links.self,
                            current   : 'read',
                            isEmbedded,
                        });
                    },
                },
                {
                    key             : 'delete',
                    content         : translated.global.buttons.delete,
                    when            : (rule) => rule?.links?.self?.delete,
                    getConfirmation : (rule) => ({
                        message       : translated.rules.delete,
                        messageValues : { name: rule.name },
                        acceptMessage : translated.global.buttons.delete,
                    }),
                    callback: async (rule) => {
                        const { id, visualId } = rule;
                        const reqConfig = {
                            ...rule.links.self.delete,
                            data: {
                                type   : collectionName,
                                delete : [{ id }],
                            },
                            shouldReloadData : true,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : [visualId],
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                            snackbar.show({
                                content   : translated.rules.success.ruleRemoved,
                                isSuccess : true,
                            });
                        } catch (error) {
                            snackbar.show({
                                content : translated.rules.errors.ruleRemoved,
                                isError : true,
                                error,
                            });
                        }
                    },
                },
                {
                    when     : () => links?.bulks?.enable,
                    callback : async (rule) => {
                        const { id, visualId } = rule;
                        const reqConfig = {
                            ...links.bulks.enable,
                            data: {
                                type   : collectionName,
                                enable : [{ id, isActive: !rule.isActive }],
                            },
                            shouldReloadData : true,
                            isCritical       : false,
                            isGlobal         : false,
                            ids              : [visualId],
                        };
                        try {
                            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                            snackbar.show({
                                content   : rule.isActive ? translated.rules.success.ruleDisabled : translated.rules.success.ruleEnabled,
                                isSuccess : true,
                            });
                        } catch (error) {
                            snackbar.show({
                                content : rule.isActive ? translated.rules.error.ruleDisabled : translated.rules.error.ruleEnabled,
                                isError : true,
                                error,
                            });
                        }
                    },
                    key               : 'isActive',
                    isOutsideDropdown : true,
                    isSwitch          : true,
                },
            ] : [],
        },
        items       : rulesData?.map((e) => ({ ...e, visualId: `${collectionName}-${e.id}`, fetching })) || [],
        bulkActions : isEditEnabled ? bulkActions : [],
    };
};

export const rulesUtils = { createReqConfigForActive, getRulesTableConfig };
export const walletUtils = { formatPointItems, addTypeAndReasonToTransactionName: transformTransaction, getTransactionsObject };

export const getOwnerName = (salutation, firstName, lastName) => `${salutation ? `${salutation} ` : ''}${firstName} ${lastName}`;
