import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import SectionsWrapper from '../../Wrapper';
import Subsection from '../../Subsection';
import Form, { FormError } from 'Components/Form';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import RulesList from 'Components/Sections/Rules/List';
// eslint-disable-next-line import/no-cycle
import { Collection, SelectedSection } from 'Components/Sections/Collection';
import Alert from 'Components/Alert';

export function Editor({ data, resources, sections, isReloadingData, isEditing, name }) {
    const intl = useIntl();

    const { navigator, snackbar } = useContext(PanelContext);

    const { available } = resources;
    const { Section, props } = SelectedSection.get(sections);

    const { id, links, additionalResources } = data;

    const rulesSubSectionApiName = sections?.[sectionType.RULES]?.config?.resourceName;
    const isRulesSubSectionEnabled = !!rulesSubSectionApiName;
    const addingRulesWhileUnsaved = isRulesSubSectionEnabled && (!id || !links || !links[rulesSubSectionApiName]);

    const entitiesDisabledNames = useMemo(() => {
        const entities = [];

        if (isRulesSubSectionEnabled && addingRulesWhileUnsaved) {
            entities.push(intl.formatMessage({
                id             : translated.contractTypes.rulesName,
                defaultMessage : translated.contractTypes.rulesName,
            }));
        }

        if (sections[sectionType.CONVERSION_RULES] && !isEditing) {
            entities.push(intl.formatMessage({
                id             : translated.conversionRules.conversionsName,
                defaultMessage : translated.conversionRules.conversionsName,
            }));
        }

        if (sections[sectionType.CONVERSION_RULES_ALT] && !isEditing) {
            entities.push(intl.formatMessage({
                id             : translated.conversionRules.exchangeRulesName,
                defaultMessage : translated.conversionRules.exchangeRulesName,
            }));
        }

        return entities;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    if (Section) {
        return <Section {...props} />;
    }

    const availableCurrencies = additionalResources?.currencies?.map((e) => ({ content: e.name, value: e.id })) || [];

    const contractTypeOnSubmit = async ({ values }) => {
        try {
            const { resourceForSubmit, ...formValues } = values;
            await navigator.requestForCurrentPath({ reqConfig: { ...resourceForSubmit, data: formValues } });

            snackbar.show({
                content   : isEditing ? translated.contractTypes.edit.success : translated.contractTypes.save.success,
                isSuccess : true,
            });
        } catch (err) {
            throw new FormError(
                isEditing ? translated.contractTypes.edit.error : translated.contractTypes.save.error,
                err,
            );
        }
    };

    const ConversionRules = Collection[sectionType.CONVERSION_RULES];
    const ConversionRulesAlt = Collection[sectionType.CONVERSION_RULES_ALT];

    return (
        <Form.Wrapper>
            <SectionsWrapper
                title={id ? translated.contractTypes.edit.title : translated.contractTypes.create.title}
                titleValues={id ? { name } : null}
                actionButtons={[<Form.Secondary key="fs" onClick={navigator.goToParentAndReload} />, <Form.Primary key="fp" />]}
            >
                <Subsection
                    title={translated.contractTypes.edit.sectionTitle}
                    className="contract-subsection margin-top-medium"
                >
                    <Form
                        buttonsWidth={{ base: 12, small: 6 }}
                        onSubmit={contractTypeOnSubmit}
                        id="contract-types-form"
                    >
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                submitKey="name"
                                isDense
                                type="text"
                                label={translated.contractTypes.name}
                                value={data.name}
                                validations={{ maxLength: 40 }}
                                charCount={{ total: 40 }}
                                isRequired
                            />
                            {isEditing && (
                                <Form.Input
                                    submitKey="code"
                                    isDense
                                    type="text"
                                    label={translated.contractTypes.code}
                                    value={data.code}
                                    isDisabled
                                    avoidOnSubmit
                                />
                            )}
                            <Form.Title isSmall className="margin-bottom-xsmall">
                                <FormattedMessage
                                    id={translated.contractTypes.primaryCurrency}
                                    defaultMessage={translated.contractTypes.primaryCurrency}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="primaryCurrency"
                                type="select"
                                label={translated.contractTypes.currency}
                                options={availableCurrencies}
                                value={data?.primaryCurrency?.id}
                                submitFormat={(value) => ({ id: value })}
                                isDisabled={isEditing}
                                isDense
                                isRequired
                            />
                            <Form.Input
                                type="number"
                                submitKey="primaryCurrencyDelta"
                                value={data.primaryCurrencyDelta}
                                validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                                label={translated.contractTypes.delta}
                                className="small-number-input"
                                isDense
                                isRequired
                            />
                            <Form.Title isSmall className="margin-top-xsmall margin-bottom-xsmall">
                                <FormattedMessage
                                    id={translated.contractTypes.secondaryCurrency}
                                    defaultMessage={translated.contractTypes.secondaryCurrency}
                                />
                            </Form.Title>
                            <Form.Input
                                submitKey="secondaryCurrency"
                                type="select"
                                label={translated.contractTypes.currency}
                                options={availableCurrencies}
                                value={data?.secondaryCurrency?.id}
                                submitFormat={(value) => ({ id: value })}
                                isDisabled={isEditing}
                                isDense
                                isRequired
                            />
                            <Form.Input
                                type="number"
                                submitKey="secondaryCurrencyDelta"
                                value={data.secondaryCurrencyDelta}
                                validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                                label={translated.contractTypes.delta}
                                className="small-number-input"
                                isDense
                                isRequired
                            />
                            <Form.Input type="hidden" submitKey="resourceForSubmit" value={isEditing ? available.update : available.create} />
                        </Form.Column>
                    </Form>
                </Subsection>

                {isRulesSubSectionEnabled && !addingRulesWhileUnsaved && (
                    <RulesList
                        {...sections[sectionType.RULES]}
                        resources={{ ...sections[sectionType.RULES].resources, available: links[sections[sectionType.RULES].config.resourceName] }}
                        shouldReloadData={isReloadingData || sections[sectionType.RULES].shouldReloadData}
                        pathForResource={[
                            sectionType.CLUBS,
                            sectionType.CLUBS_EDITOR,
                            sectionType.CLUBS_GENERAL,
                            sectionType.CLUBS_TIER_EDITOR,
                            sectionType.CONTRACT_TYPE,
                            sectionType.RULES,
                        ]}
                        selectRuleEditorMethod="goToContractTypeRuleEditor"
                    />
                )}

                {sections[sectionType.CONVERSION_RULES]
                    && isEditing && links?.[sections[sectionType.CONVERSION_RULES]?.config?.resourceName]
                    && (
                        <ConversionRules
                            {...sections[sectionType.CONVERSION_RULES]}
                            resources={{
                                ...sections[sectionType.CONVERSION_RULES].resources,
                                available: links[sections[sectionType.CONVERSION_RULES].config.resourceName],
                            }}
                            pathForResource={[
                                sectionType.CLUBS,
                                sectionType.CLUBS_EDITOR,
                                sectionType.CLUBS_GENERAL,
                                sectionType.CLUBS_TIER_EDITOR,
                                sectionType.CONTRACT_TYPE,
                                sectionType.CONVERSION_RULES,
                            ]}
                        />

                    )}

                {sections[sectionType.CONVERSION_RULES_ALT] && isEditing
                    && links?.[sections[sectionType.CONVERSION_RULES_ALT]?.config?.resourceName]
                    && (
                        <ConversionRulesAlt
                            {...sections[sectionType.CONVERSION_RULES_ALT]}
                            resources={{
                                ...sections[sectionType.CONVERSION_RULES_ALT].resources,
                                available: links[sections[sectionType.CONVERSION_RULES_ALT].config.resourceName],
                            }}
                            pathForResource={[
                                sectionType.CLUBS,
                                sectionType.CLUBS_EDITOR,
                                sectionType.CLUBS_GENERAL,
                                sectionType.CLUBS_TIER_EDITOR,
                                sectionType.CONTRACT_TYPE,
                                sectionType.CONVERSION_RULES_ALT,
                            ]}
                        />
                    )}

                {!!entitiesDisabledNames?.length && (
                    <Alert
                        id="conversions-save-warning"
                        type="warning"
                        className="margin-top-small"
                        content={translated.contractTypes.create.entitiesWarning}
                        contentValues={{ names: entitiesDisabledNames.join(', ').toLowerCase() }}
                    />
                )}
            </SectionsWrapper>
        </Form.Wrapper>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-small">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }} className="animated fade-in-up-small fastest">
                    <Skeleton.Title />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
        </>
    );
};

Editor.propTypes = {
    name            : PropTypes.string.isRequired,
    data            : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    sections        : PropTypes.shape({}).isRequired,
    isReloadingData : PropTypes.bool.isRequired,
    isEditing       : PropTypes.bool.isRequired,
};

export default withRequest(Editor);
