module.exports = Object.freeze({
    title       : 'apf.tags.title',
    empty       : 'apf.tags.empty',
    createTitle : 'apf.tags.createTitle',
    editTitle   : 'apf.tags.editTitle',
    update      : { success: 'apf.tags.update.success', error: 'apf.tags.update.error' },
    save        : { success: 'apf.tags.save.success', error: 'apf.tags.save.error' },
    errors      : { errorAlphanumeric: 'apf.tags.errors.errorAlphanumeric', entityIsReadonly: 'apf.tags.errors.entityIsReadonly' },
    id          : 'apf.tags.id',
    name        : 'apf.tags.name',
    slug        : 'apf.tags.slug',
    label       : 'apf.tags.label',
    hidden      : 'apf.tags.hidden',
    status      : 'apf.tags.status',
    isActive    : 'apf.tags.isActive',
    enable      : { success: 'apf.tags.enable.success', error: 'apf.tags.enable.error' },
    disable     : { success: 'apf.tags.disable.success', error: 'apf.tags.disable.error' },
    entityTags  : {
        title     : 'apf.tags.entityTags.title',
        empty     : 'apf.tags.entityTags.empty',
        associate : {
            message : 'apf.tags.entityTags.associate.message',
            success : 'apf.tags.entityTags.associate.success',
            error   : 'apf.tags.entityTags.associate.error',
        },
        disassociate: { success: 'apf.tags.entityTags.disassociate.success', error: 'apf.tags.entityTags.disassociate.error' },
    },
    search: 'apf.tags.search',
});
