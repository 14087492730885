import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Column from './Column';
import Responsive from 'Components/Responsive';
import { colWidthShape } from 'Constants/PropTypes';
import getSizeClass from './Utils';
import translated from 'Constants/labels/translated';

/**
* Only some sizes are allowed
*/
function isOutOfRange(childWidth) {
    return Object.values(childWidth).some((size) => (size > 6 && size < 12) || size < 1 || size > 12);
}

/**
 * The parameters indicated in the prop width are transformed into CSS classes
 */
function getColumnCSSClasses(childWidth) {
    if (!isOutOfRange(childWidth)) {
        return Object.keys(childWidth).reduce((newClass, key) => {
            let width = childWidth[key];
            width = width === 'expand' || width === 'auto' ? width : `1-${width}`;

            return newClass.concat(`grid-child-width${getSizeClass(key)}${width} `);
        }, '');
    }

    throw new Error(translated.global.grid.error);
}

/**
 * Informs you whether the columns already mounted in the component are in stack
 */
function areColumnsStacked(allColumnsMounted, childColumnsRefs) {
    if (allColumnsMounted) {
        return childColumnsRefs.reduce(
            (prev, element) => {
                let { left } = element.getBoundingClientRect();
                left = Math.round(left);
                let { areStacked } = prev;
                if (left !== prev.left && prev.left !== null) {
                    areStacked = false;
                }
                return { areStacked, left };
            },
            { areStacked: true, left: null },
        ).areStacked;
    }

    return false;
}

function Grid({
    gutter, match, className, children, addMargin, isResponsive, container, childWidth,
}) {
    const [childColumnsRefs, setChildColumnsRefs] = useState([]);
    const [allColumnsMounted, setAllColumnsMounted] = useState(false);

    /**
     * The component has an array of refs that is updated by this method, informing the moment
     * in which all the refs were completed.
     */
    const setRef = useCallback((ref, index) => {
        childColumnsRefs[index] = ref;
        setChildColumnsRefs(childColumnsRefs);

        // if the number of saved refs equals the number of children, all columns were mounted
        if (childColumnsRefs.length >= React.Children.count(children)) {
            setAllColumnsMounted(true);
        }
    }, [childColumnsRefs, children]);

    // This functionality is used to set the width of the children
    const childWidthClass = getColumnCSSClasses(childWidth);

    let gutterClass = '';
    switch (gutter) {
        case 'small':
            gutterClass = 'grid-small';
            break;
        case 'medium':
            gutterClass = 'grid-medium';
            break;
        case 'large':
            gutterClass = 'grid-large';
            break;
        case 'collapsed':
            gutterClass = 'grid-collapse';
            break;
        default:
            break;
    }

    // The prop match equals the height of the columns
    const classesConcat = `grid ${gutterClass} ${childWidthClass} ${match ? 'grid-match' : ''} `;

    // If isResponsive has been indicated the children will use properties
    // granted by the Responsive component to find out if they are stacked
    if (isResponsive) {
        // We send the prop container to the Responsive component indicating who is the  reference to take
        // for calculating widths and so on. Margins will be added to the columns below the first child
        return (
            <Responsive container={container}>
                {({ containerWidthClass, isBelowFirstChild }) => (
                    <div className={`${classesConcat} ${className} ${containerWidthClass}`}>
                        {React.Children.map(children, (column, i, key = `${i}_col`) => {
                            if (typeof column?.type !== 'function') {
                                // Its an html element
                                return column;
                            }

                            return React.cloneElement(column, {
                                setMarginOnStack:
                                    addMargin === 'belowFirstChild' && allColumnsMounted
                                        ? isBelowFirstChild(i, childColumnsRefs[i])
                                        : column.props.setMarginOnStack,
                                isColumnMounted : typeof childColumnsRefs[i] !== 'undefined',
                                refIndex        : i,
                                columnRef       : setRef,
                                key,
                            });
                        })}
                    </div>
                )}
            </Responsive>
        );
    }

    // In this case, margins will be added only when the columns are stacked,
    // or when the columns themselves have the prop setMarginOnStack
    return (
        <Responsive>
            {() => (
                <div className={`${classesConcat}${className}`}>
                    {React.Children.map(children, (column, i) => {
                        if (typeof column?.type !== 'function') {
                            // Its an html element
                            return column;
                        }

                        const isStackMarginSetted = column && column.props.setMarginOnStack;
                        const areColumnStacked = addMargin === 'onStackedColumns' && areColumnsStacked(allColumnsMounted, childColumnsRefs) && i !== 0;
                        const key = `${i}_col`;
                        return React.cloneElement(column, {
                            setMarginOnStack : areColumnStacked || isStackMarginSetted,
                            isColumnMounted  : typeof childColumnsRefs[i] !== 'undefined',
                            refIndex         : i,
                            columnRef        : setRef,
                            key,
                        });
                    })}
                </div>
            )}
        </Responsive>
    );
}

Grid.Column = Column;

Grid.defaultProps = {
    className    : '',
    addMargin    : '',
    gutter       : '',
    container    : null,
    childWidth   : {},
    match        : false,
    isResponsive : false,
};

Grid.propTypes = {
    children     : PropTypes.node.isRequired,
    className    : PropTypes.string,
    addMargin    : PropTypes.string,
    gutter       : PropTypes.string,
    container    : PropTypes.oneOfType([PropTypes.any, PropTypes.object]),
    childWidth   : PropTypes.shape(colWidthShape),
    match        : PropTypes.bool,
    isResponsive : PropTypes.bool,
};

export default Grid;
