import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray, get } from 'react-hook-form';
import PropTypes from 'prop-types';
import Form from 'Components/Form';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';
import { FormattedMessage } from 'react-intl';

const getCurrencyTypes = () => [
    {
        content : translated.currencies.editor.types.perNight,
        value   : 1,
    },
    {
        content : translated.currencies.editor.types.perStay,
        value   : 2,
    },
];

function CurrenciesTable({ elements, onAdd, onEdit, onRemove, canModify }) {
    const { control, setValue, trigger, formState, getValues, watch } = useFormContext();

    const [isLoading, setIsLoading] = useState(false);

    const { errors } = formState;

    const { fields, append } = useFieldArray({
        control,
        name: 'upgradeRates',
    });

    useEffect(() => {
        setValue('upgradeRates', elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const areValuesValid = (list) => list?.every((e) => e != null && e !== '');

    const addRate = async ([newFrom, newTo, newValue, newType]) => {
        if (!areValuesValid([newFrom, newTo, newValue, newType])) {
            return;
        }

        setIsLoading(true);

        const created = await onAdd({
            from  : newFrom,
            to    : newTo,
            value : newValue,
            type  : newType,
        });

        if (created) {
            append({
                from      : created.fromLevel,
                savedFrom : created.fromLevel,
                to        : created.toLevel,
                savedTo   : created.toLevel,
                value     : created.rate,
                type      : created.type?.id,
                entityId  : created.id,
                links     : created.links,
            });
            setValue('newFrom', '', { shouldDirty: false, shouldTouch: false });
            setValue('newTo', '', { shouldDirty: false, shouldTouch: false });
            setValue('newValue', '', { shouldDirty: false, shouldTouch: false });
            setValue('newType', '', { shouldDirty: false, shouldTouch: false });
        }

        setIsLoading(false);
    };

    const removeRate = async (element, index) => {
        setIsLoading(true);

        const removed = await onRemove(element);

        if (removed) {
            // remove(index);
            setValue(`upgradeRates.${index}.isHidden`, true);
        }

        trigger();
        setIsLoading(false);
    };

    const saveRate = async (element, index) => {
        setIsLoading(true);

        const saved = await onEdit(element);

        if (saved) {
            setValue(`upgradeRates.${index}.changed`, false);
            setValue(`upgradeRates.${index}.savedFrom`, saved.fromLevel);
            setValue(`upgradeRates.${index}.savedTo`, saved.toLevel);
            trigger();
        }

        setIsLoading(false);
    };

    const validateFields = (index) => {
        if (index != null) {
            setValue(`upgradeRates.${index}.changed`, true);
        }

        setTimeout(() => {
            // To avoid a problem with the validations showing old values
            trigger();
        }, 100);
    };

    const hasErrors = !!get(errors, 'newFrom') || !!get(errors, 'newTo') || !!get(errors, 'newValue') || !!get(errors, 'newType');

    return (
        <>
            <Form.Title className="upgrade-rates-title margin-bottom-small">
                <FormattedMessage id={translated.currencies.editor.rates} defaultMessage={translated.currencies.editor.rates} />
            </Form.Title>

            {canModify && (
                <div className="form-column-group">
                    <Form.InputNew
                        label={translated.currencies.editor.from}
                        id="newFrom"
                        submitKey="newFrom"
                        isDense
                        type="number"
                        className="small-number-input"
                        onChange={() => validateFields()}
                        forceValidationsOnFirstRender
                    />

                    <Form.InputNew
                        label={translated.currencies.editor.to}
                        id="newTo"
                        submitKey="newTo"
                        isDense
                        type="number"
                        className="small-number-input"
                        onChange={() => validateFields()}
                        forceValidationsOnFirstRender
                    />

                    <Form.InputNew
                        label={translated.currencies.editor.value}
                        id="newValue"
                        submitKey="newValue"
                        isDense
                        type="number"
                        className="small-number-input"
                        onChange={() => validateFields()}
                        forceValidationsOnFirstRender
                    />

                    <Form.InputNew
                        label={translated.currencies.editor.type}
                        id="newType"
                        submitKey="newType"
                        isDense
                        type="select"
                        className="small-number-input"
                        options={getCurrencyTypes()}
                        onChange={() => validateFields()}
                        forceValidationsOnFirstRender
                    />

                    <Form.Custom>
                        <Button
                            id="rate-add"
                            className="add-column-button margin-left-small"
                            onClick={() => {
                                addRate(getValues(['newFrom', 'newTo', 'newValue', 'newType']));
                            }}
                            variant="outlined"
                            color="primary"
                            disabled={isLoading || hasErrors}
                            tooltip={translated.global.buttons.add}
                        >
                            <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                        </Button>
                    </Form.Custom>
                </div>
            )}

            { fields.map((field, index) => {
                const fieldHasErrors = !!get(errors, `upgradeRates.${index}`);
                const hasChanged = watch(`upgradeRates.${index}.changed`);
                const isHidden = watch(`upgradeRates.${index}.isHidden`);

                if (isHidden) {
                    return null;
                }

                return (
                    <div className="form-column-group" key={field.entityId}>
                        <Form.InputNew
                            id={`id-${field.entityId}`}
                            submitKey={`upgradeRates.${index}.entityId`}
                            isDense
                            type="hidden"
                        />

                        <Form.InputNew
                            label={translated.currencies.editor.from}
                            id={`from-${field.entityId}`}
                            submitKey={`upgradeRates.${index}.from`}
                            isDense
                            type="number"
                            className="small-number-input"
                            onChange={() => validateFields(index)}
                            forceValidationsOnFirstRender
                            isDisabled={!canModify}
                        />

                        <Form.InputNew
                            label={translated.currencies.editor.to}
                            id={`to-${field.entityId}`}
                            submitKey={`upgradeRates.${index}.to`}
                            isDense
                            type="number"
                            className="small-number-input"
                            onChange={() => validateFields(index)}
                            forceValidationsOnFirstRender
                            isDisabled={!canModify}
                        />

                        <Form.InputNew
                            label={translated.currencies.editor.value}
                            id={`value-${field.entityId}`}
                            submitKey={`upgradeRates.${index}.value`}
                            isDense
                            type="number"
                            className="small-number-input"
                            onChange={() => validateFields(index)}
                            forceValidationsOnFirstRender
                            isDisabled={!canModify}
                        />

                        <Form.InputNew
                            label={translated.currencies.editor.type}
                            id={`type-${field.entityId}`}
                            submitKey={`upgradeRates.${index}.type`}
                            isDense
                            type="select"
                            className="small-number-input"
                            options={getCurrencyTypes()}
                            onChange={() => validateFields(index)}
                            forceValidationsOnFirstRender
                            isDisabled={!canModify}
                        />

                        <Form.Custom>
                            {canModify && !!onRemove && (
                                <Button
                                    id={`rate-remove-${field.entityId}`}
                                    className="margin-left-xsmall"
                                    icon="MinusCircle"
                                    onClick={() => {
                                        removeRate(getValues(`upgradeRates.${index}`), index);
                                    }}
                                    tooltip={translated.global.buttons.remove}
                                    disabled={isLoading}
                                />
                            )}

                            {canModify && !!onEdit && (
                                <Button
                                    id={`rate-update-${field.entityId}`}
                                    className="margin-left-xsmall"
                                    icon="ContentSave"
                                    onClick={() => {
                                        saveRate(getValues(`upgradeRates.${index}`), index);
                                    }}
                                    tooltip={translated.global.buttons.save}
                                    disabled={isLoading || fieldHasErrors || !hasChanged}
                                />
                            )}
                        </Form.Custom>
                    </div>
                );
            })}
        </>
    );
}

CurrenciesTable.defaultProps = {};

CurrenciesTable.propTypes = {
    elements  : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onAdd     : PropTypes.func.isRequired,
    onEdit    : PropTypes.func.isRequired,
    onRemove  : PropTypes.func.isRequired,
    canModify : PropTypes.bool.isRequired,
};

export default CurrenciesTable;
