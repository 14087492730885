import { publicPermission } from 'Constants/global';
import translated from 'Constants/labels/translated';
import { createRef } from 'react';

// Get the permission from the list, or creates it when it doesn't exist
function getInitializePermission(partialResult, name, userPermissions) {
    let permission = partialResult[name];
    if (!permission) {
        permission = {
            name,
            dependents     : [],
            provides       : [],
            isSelected     : !!(userPermissions && userPermissions.indexOf(name) > -1),
            isLinkedAdd    : false,
            isLinkedRemove : false,
        };
    }

    return permission;
}

function getCompletePermissionRelations(permissions, relationName) {
    let relations = Object.entries(permissions).reduce(
        (prev, [name, relation]) => ({
            ...prev,
            ...{
                [name]: {
                    name,
                    relation   : [...relation[relationName]],
                    hasChanged : relation[relationName].length > 0,
                },
            },
        }),
        {},
    );

    const getFirstChanged = (list) => Object.keys(list).find((name) => relations[name].hasChanged);

    let firstChanged = getFirstChanged(relations);
    while (firstChanged) {
        const relationWithChanges = relations[firstChanged];

        // eslint-disable-next-line no-loop-func
        relations = Object.entries(relations).reduce((prev, [name, eachRelation]) => {
            let { hasChanged, relation } = eachRelation;

            if (firstChanged !== name && relation.indexOf(firstChanged) > -1) {
                const oldValues = [...new Set([...relation])];
                const newValues = [...new Set([...relation, ...relationWithChanges.relation])];
                if (oldValues.length !== newValues.length) {
                    hasChanged = true;
                    relation = [...newValues];
                }
            }

            return {
                ...prev,
                ...{
                    [name]: {
                        ...eachRelation,
                        relation,
                        hasChanged: firstChanged !== name && hasChanged,
                    },
                },
            };
        }, {});

        relationWithChanges.hasChanged = false;
        firstChanged = getFirstChanged(relations);
    }

    return Object.entries(permissions).reduce(
        (prev, [name, relation]) => ({
            ...prev,
            ...{
                [name]: {
                    ...relation,
                    [relationName]: relations[name].relation,
                },
            },
        }),
        {},
    );
}

export function getInitialPermissions(allPermissions, userPermissions) {
    let permissions = {};

    allPermissions.forEach((eachPermission) => {
        Object.values(eachPermission).forEach((eachEntity) => {
            Object.values(eachEntity).forEach((eachEntityPermission) => {
                const { name, depends, provides } = eachEntityPermission;

                const permission = getInitializePermission(permissions, name, userPermissions);
                permission.provides = [...new Set([...permission.provides, ...(provides || [])])];

                permissions[name] = permission;
                if (depends) {
                    depends.forEach((eachDependentName) => {
                        const dependent = getInitializePermission(permissions, eachDependentName, userPermissions);
                        dependent.dependents = [...new Set([...dependent.dependents, name])];

                        permissions[eachDependentName] = dependent;
                    });
                }
            });
        });
    });

    // Completes each permission's dependents list.
    permissions = getCompletePermissionRelations(permissions, 'dependents');
    // Completes each permission's provided list.
    permissions = getCompletePermissionRelations(permissions, 'provides');

    return permissions;
}

export function getAllPermissions(permissions) {
    if (!permissions) {
        return [];
    }

    const convertedResponse = [];

    permissions.forEach((each) => {
        Object.entries(each).forEach(([permissionName, permission]) => {
            const convertedLines = [];

            Object.entries(permission).forEach(([lineName, { name }]) => {
                if (name !== publicPermission) {
                    convertedLines.push({
                        name,
                        action : lineName,
                        ref    : createRef(),
                        label  : translated.roles.permissionNames[name],
                    });
                }
            });

            if (Object.keys(convertedLines).length) {
                let deps = convertedLines.sort((left, right) => (left.name > right.name ? 1 : -1));

                // Group equal permissions to show them as one
                deps = deps.reduce((accumulator, currentVal) => {
                    const lastVal = (accumulator.length && accumulator[accumulator.length - 1]) || {};
                    if (lastVal.name === currentVal.name) {
                        lastVal.label = lastVal.label !== currentVal.label ? `${lastVal.label} | ${currentVal.label}` : currentVal.label;
                        return accumulator;
                    }
                    accumulator.push(currentVal);
                    return accumulator;
                }, []);

                convertedResponse.push({
                    key     : permissionName,
                    cardRef : createRef(),
                    deps,
                });
            }
        });

        return convertedResponse;
    });

    return convertedResponse;
}
