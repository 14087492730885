import React from 'react';
import PropTypes from 'prop-types';

function Loading({ size, strokeSize, isCircle }) {
    return isCircle
        ? (
            <div className="loading-wrapper">
                <svg className="loading-circle" width={size} height={size} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="loading-circle-path" fill="none" strokeWidth={strokeSize} cx="33" cy="33" r="30" />
                </svg>
            </div>
        )
        : <div className="loading-line" />;
}

Loading.defaultProps = {
    size       : '48px',
    strokeSize : '4',
    isCircle   : false,
};

Loading.propTypes = {
    size       : PropTypes.string,
    strokeSize : PropTypes.string,
    isCircle   : PropTypes.bool,
};

export default Loading;
