import React from 'react';
import Button, { Link } from 'Components/Button';
import ConfirmationButton from 'Components/ConfirmationButton';
import Dropdown from 'Components/Dropdown';
import Form from 'Components/Form';
import getClassName from 'Utils/getClassName';
import { sortableHandle } from 'react-sortable-hoc';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function checkActionWhen(action, data) {
    return !action.when || action.when(data);
}

function buildRightActionColumns(id, data, actions, styleConfig, extraClass) {
    // Filter the actions that are in the dropdown, that must be shown (the ones that fulfil the 'when' function) and have no empty sub-menu.
    const dropdownActions = actions.filter(
        (a) => !a.isOutsideDropdown
            && checkActionWhen(a, data)
            && (!a.isSubmenu || a?.actions?.filter((eachSubAction) => checkActionWhen(eachSubAction, data)).length),
    );

    const hasDropdown = dropdownActions.length > 0;
    const { actions: actionsPadding } = styleConfig;

    return (
        <td className={`table-actions ${actionsPadding.customClassName} ${extraClass}`} style={{ ...actionsPadding.customStyle }} id={`${id}-actions`}>
            {hasDropdown && (
                <Dropdown
                    id={`row-dropdown-${id}`}
                    key={`row-dropdown-${id}`}
                    actions={dropdownActions.map((a) => ({ ...a, callback: () => a.callback(data) }))}
                    icon="DotsVertical"
                    disabled={dropdownActions.every((action) => action.isEnabled === false || action.isDivider)}
                >
                    {dropdownActions.map((a) => (
                        <React.Fragment key={a.key}>
                            {a.isDivider && <Dropdown.Divider itemKey={a.key} />}
                            {a.isSubmenu && (
                                <Dropdown.Submenu title={a.content} id={`${id}-actions-dropdown-${a.key}`}>
                                    {a.actions
                                        .filter((submenuAction) => checkActionWhen(submenuAction, data))
                                        .map((submenuAction) => (
                                            <Dropdown.Option
                                                onClick={() => submenuAction.callback(data)}
                                                confirmationProps={
                                                    submenuAction.getConfirmation ? submenuAction.getConfirmation(data) : submenuAction.confirmation
                                                }
                                                itemKey={submenuAction.key}
                                                isEnabled={submenuAction.isEnabled && !(submenuAction.disabledWhen && submenuAction.disabledWhen(data))}
                                                key={submenuAction.key}
                                                id={`${id}-actions-dropdown-${submenuAction.key}`}
                                            >
                                                <WrappedFormattedMessage content={submenuAction.content} />
                                            </Dropdown.Option>
                                        ))}
                                </Dropdown.Submenu>
                            )}
                            {!a.isDivider && !a.isSubmenu && !a.getLink && (
                                <Dropdown.Option
                                    onClick={() => a.callback(data)}
                                    confirmationProps={a.getConfirmation ? a.getConfirmation(data) : a.confirmation}
                                    itemKey={a.key}
                                    isEnabled={a.isEnabled && !(a.disabledWhen && a.disabledWhen(data))}
                                    id={`${id}-actions-${a.key}`}
                                >
                                    <WrappedFormattedMessage content={a.content} />
                                </Dropdown.Option>
                            )}

                            {!a.isDivider && !a.isSubmenu && a.getLink && (
                                <Dropdown.Option
                                    itemKey={a.key}
                                    key={a.key}
                                    isEnabled={a.isEnabled && !(a.disabledWhen && a.disabledWhen(data))}
                                    id={`${id}-actions-${a.key}`}
                                >
                                    <Link withoutRipple variant="link" key={`${a.key}Link`} link={a.getLink(data)}>
                                        <WrappedFormattedMessage content={a.content} />
                                    </Link>
                                </Dropdown.Option>
                            )}
                        </React.Fragment>
                    ))}
                </Dropdown>
            )}
            {actions
                .filter((a) => a.isOutsideDropdown && !a.isDivider && checkActionWhen(a, data))
                .map((action, index) => {
                    if (action.isSwitch) {
                        return (
                            <Form.Input
                                className="margin-left-xsmall"
                                type="switch"
                                isSelected={false}
                                value={data[action.key]}
                                isDisabled={action.disabled || (action.disabledWhen && action.disabledWhen(data))}
                                onClick={() => action.callback(data)}
                                id={`${id}-actions-${action.key}`}
                                key={`${id}-actions-${action.key}`}
                            />
                        );
                    }

                    if (action.getLink) {
                        return (
                            <Link
                                key={`${action.key}-${index + 1}`}
                                tooltip={action.getTooltip ? action.getTooltip(data) : action.tooltip}
                                icon={action.getIcon ? action.getIcon(data) : action.icon}
                                className={action.className || action.getClassName?.(data)}
                                disabled={action.disabled || (action.disabledWhen && action.disabledWhen(data))}
                                link={action.getLink(data)}
                                id={`${id}-actions-${action.key}`}
                            />
                        );
                    }

                    if (action.hasDropdown) {
                        return (
                            <Dropdown
                                id={`row-dropdown-outside-${id}`}
                                key={`row-dropdown-outside-${id}`}
                                actions={action.actions.map((a) => ({ ...a, callback: () => a.callback(data) }))}
                                icon={action.getIcon ? action.getIcon(data) : action.icon}
                                disabled={action.actions.every((internal) => internal.isEnabled === false || internal.isDivider)}
                                className={action.className}
                            >
                                {action.actions
                                    .filter((a) => checkActionWhen(a, data))
                                    .map((a) => (
                                        <React.Fragment key={a.key}>
                                            {a.isDivider && <Dropdown.Divider itemKey={a.key} />}
                                            {a.isSubmenu && (
                                                <Dropdown.Submenu title={a.content} id={`${id}-actions-dropdown-${a.key}`}>
                                                    {a.actions
                                                        .filter((submenuAction) => checkActionWhen(submenuAction, data))
                                                        .map((submenuAction) => (
                                                            <Dropdown.Option
                                                                onClick={() => submenuAction.callback(data)}
                                                                itemKey={submenuAction.key}
                                                                isEnabled={
                                                                    submenuAction.isEnabled
                                                                    && !(submenuAction.disabledWhen && submenuAction.disabledWhen(data))
                                                                }
                                                                key={submenuAction.key}
                                                                id={`${id}-actions-dropdown-${submenuAction.key}`}
                                                            >
                                                                <WrappedFormattedMessage content={submenuAction.content} />
                                                            </Dropdown.Option>
                                                        ))}
                                                </Dropdown.Submenu>
                                            )}
                                            {!a.isDivider && !a.isSubmenu && !a.getLink && (
                                                <Dropdown.Option
                                                    onClick={() => a.callback(data)}
                                                    itemKey={a.key}
                                                    isEnabled={a.isEnabled && !(a.disabledWhen && a.disabledWhen(data))}
                                                    id={`${id}-actions-${a.key}`}
                                                >
                                                    <WrappedFormattedMessage content={a.content} />
                                                </Dropdown.Option>
                                            )}

                                            {!a.isDivider && !a.isSubmenu && a.getLink && (
                                                <Dropdown.Option
                                                    itemKey={a.key}
                                                    key={a.key}
                                                    isEnabled={a.isEnabled && !(a.disabledWhen && a.disabledWhen(data))}
                                                    id={`${id}-actions-${a.key}`}
                                                >
                                                    <Link withoutRipple variant="link" key={`${a.key}Link`} link={a.getLink(data)}>
                                                        <WrappedFormattedMessage content={a.content} />
                                                    </Link>
                                                </Dropdown.Option>
                                            )}
                                        </React.Fragment>
                                    ))}
                            </Dropdown>
                        );
                    }

                    if (action.confirmation || action.getConfirmation) {
                        const confirmationProps = action.confirmation || action.getConfirmation(data);

                        return (
                            <ConfirmationButton
                                onClick={() => action.callback(data)}
                                key={`${action.key}-${index + 1}`}
                                tooltip={action.getTooltip ? action.getTooltip(data) : action.tooltip}
                                icon={action.getIcon ? action.getIcon(data) : action.icon}
                                className={action.className || action.getClassName?.(data)}
                                disabled={action.disabled || (action.disabledWhen && action.disabledWhen(data))}
                                id={`${id}-actions-${action.key}`}
                                showTooltipDisabled={action.showTooltipDisabled}
                                confirmation={confirmationProps}
                            />
                        );
                    }

                    return (
                        <Button
                            onClick={() => action.callback(data)}
                            key={`${action.key}-${index + 1}`}
                            tooltip={action.getTooltip ? action.getTooltip(data) : action.tooltip}
                            icon={action.getIcon ? action.getIcon(data) : action.icon}
                            className={action.className || action.getClassName?.(data)}
                            disabled={action.disabled || (action.disabledWhen && action.disabledWhen(data))}
                            id={`${id}-actions-${action.key}`}
                            showTooltipDisabled={action.showTooltipDisabled}
                        />
                    );
                })}
        </td>
    );
}

function getDragHandleButton(styleConfig, dragHandlerRef, isSortEnabled, id) {
    const { sortable } = styleConfig;

    const DragHandle = sortableHandle(() => <Button id={`${id}-sortable`} icon="DragVertical" className="row-handler-button" disabled={!isSortEnabled} />);

    return (
        <td ref={dragHandlerRef} className={`table-sortable ${sortable.customClassName}`} style={sortable.customStyle} id={`${id}-drag`}>
            <DragHandle />
        </td>
    );
}

function getCheckboxHandlerButton(index, styleConfig, checked, onCheck, id) {
    const { checkbox } = styleConfig;

    return (
        <td className={`table-checkbox ${checkbox.customClassName}`} style={checkbox.customStyle} id={`${id}-checkbox`}>
            <label className="checkbox">
                <input
                    type="checkbox"
                    onChange={(e) => {
                        onCheck(e.target.checked, index);
                    }}
                    checked={checked}
                />
                <span />
            </label>
        </td>
    );
}

function getSubTableHandlerButton(styleConfig, isCollapsed, handleOnClick, hasData, id) {
    const { subTable: subTablePadding } = styleConfig;

    return (
        <td
            className={`table-subtable ${subTablePadding.customClassName} ${hasData ? '' : 'no-buttons'}`}
            style={subTablePadding.customStyle}
            id={`${id}-subtable`}
        >
            {hasData && (
                <Button
                    id={`${id}-subtable-collapse-button`}
                    icon="ChevronUp"
                    className={`subtable-collapse-button ${isCollapsed ? 'is-collapsed' : ''} ${hasData ? '' : 'hidden'}`}
                    onClick={handleOnClick}
                />
            )}
        </td>
    );
}

function getRowClass(isUncheckable, isChecked, hasSubtable, isCollapsed, isDragging, hasDragging, hasCheckbox, isLoading, isDisabled, className) {
    const classesObject = {
        isUncheckable,
        isDragging,
        isChecked,
        hasCheckbox,
        hasSubtable,
        hasDragging,
        isLoading,
        isDisabled,
    };
    if (hasSubtable) {
        classesObject.isCollapsed = isCollapsed;
    }
    return getClassName(classesObject, [className]);
}

export const row = {
    buildRightActionColumns,
    getDragHandleButton,
    getCheckboxHandlerButton,
    getSubTableHandlerButton,
    getRowClass,
};

export default { row };
