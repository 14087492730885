import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Split({
    label, value, onChange, options, onFocus, onBlur, isDisabled, id, innerInputRef,
}) {
    const { selectedOption = options?.[0]?.value, inputValue } = value;

    const handleOnChange = (e, type) => {
        const element = type === 'selectedOption' ? options.find((a) => a.value === e.target.value) : e.target;
        onChange({ target: { value: { selectedOption, inputValue, [type]: element.value } } });
    };

    return (
        <>
            <select
                id={`${id}-select`}
                className="select"
                onChange={(e) => handleOnChange(e, 'selectedOption')}
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={isDisabled ? '-1' : '0'}
                ref={innerInputRef}
            >
                {options?.map((option, index) => {
                    const { key = index, callback, content } = option;
                    return (
                        <option value={option.value} selected={option.value === selectedOption} onClick={callback} key={key}>
                            {content}
                        </option>
                    );
                })}
            </select>
            <input
                id={id}
                className="input"
                type="text"
                value={inputValue}
                onChange={(e) => handleOnChange(e, 'inputValue')}
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={isDisabled ? '-1' : '0'}
                autoComplete="off"
            />
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
        </>
    );
}

Split.defaultProps = {
    id       : '',
    label    : '',
    onChange : null,
    options  : null,
    onFocus  : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled    : false,
    innerInputRef : null,
};

Split.propTypes = {
    id       : PropTypes.string,
    label    : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    value    : PropTypes.shape({}).isRequired,
    onChange : PropTypes.func,
    onFocus  : PropTypes.func,
    onBlur   : PropTypes.func,
    options  : PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                content   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
                isEnabled : PropTypes.bool,
                callback  : PropTypes.func,
            }),
            PropTypes.shape({ isDivider: PropTypes.bool }),
        ]),
    ),
    isDisabled    : PropTypes.bool,
    innerInputRef : PropTypes.shape({}),
};

export default React.memo(Split);
