import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Components/Modal';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function ConfirmationModal({
    className, title, titleValues, message, messageValues, onAccept, onCancel, acceptMessage, cancelMessage, isVisible,
}) {
    const { confirmation } = useContext(PanelContext);

    const cancelClick = () => {
        confirmation.hide();

        if (onCancel) {
            onCancel();
        }
    };

    const modalProperties = {
        className,
        title,
        titleValues,
        closeButton: {
            text    : acceptMessage || translated.global.buttons.confirm,
            onClick : () => {
                confirmation.hide();

                if (onAccept) {
                    onAccept();
                }
            },
            isEnabled: true,
        },
        cancelButton: {
            text      : cancelMessage || translated.global.buttons.cancel,
            onClick   : cancelClick,
            isEnabled : true,
        },
        isVisible     : false,
        backdropClick : cancelClick,
    };

    if (!isVisible) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <Modal {...modalProperties}>
            <span>
                <WrappedFormattedMessage content={message || translated.global.modal.confirmation} values={messageValues} />
            </span>
        </Modal>
    );
}

ConfirmationModal.defaultProps = {
    className     : '',
    title         : '',
    titleValues   : null,
    message       : '',
    messageValues : null,
    onAccept      : () => {
        // Default
    },
    onCancel: () => {
        // Default
    },
    acceptMessage : '',
    cancelMessage : '',
};

ConfirmationModal.propTypes = {
    className     : PropTypes.string,
    title         : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    titleValues   : PropTypes.shape({}),
    message       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    messageValues : PropTypes.shape({}),
    onAccept      : PropTypes.func,
    onCancel      : PropTypes.func,
    acceptMessage : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    cancelMessage : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    isVisible     : PropTypes.bool.isRequired,
};

export default ConfirmationModal;
