import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';
import Form, { FormError } from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';

function getParams(data, availableResources, isEditing, additional) {
    let title;
    let resource;
    let note;
    let primaryButtonText;

    if (isEditing) {
        title = translated.global.buttons.edit;
        primaryButtonText = translated.global.buttons.save;
        resource = data.links.self.update;
        const currentCategory = additional && additional.categories && additional.categories.find((c) => c.id === data.category.id);
        note = {
            id       : data.id,
            text     : data.note,
            agent    : data.agent,
            category : currentCategory.id.toString(),
        };
    } else {
        title = translated.owners.notes.new;
        primaryButtonText = translated.global.buttons.add;
        resource = availableResources.create;
        note = {
            text     : '',
            category : '1',
        };
    }

    return { title, resource, note, primaryButtonText, shouldReloadData: true };
}

export function NoteEditor({ data, resources, isEditing }) {
    const { navigator, snackbar } = useContext(PanelContext);
    const [isLoading, setIsLoading] = useState(false);

    const { available, additional } = resources;

    const { title, resource, note, primaryButtonText, shouldReloadData } = getParams(data, available, isEditing, additional);

    const handleOnSubmit = async (submitData) => {
        setIsLoading(true);
        try {
            await navigator.requestAndSetToParent({ data: submitData.values, resource, shouldReloadData });
        } catch (e) {
            setIsLoading(false);
            throw new FormError(translated.owners.notes.error.add, e);
        }
    };

    const onFinish = () => {
        snackbar.show({ content: isEditing ? translated.owners.notes.success.update : translated.owners.notes.success.new, isSuccess: true });

        navigator.goToParentAndReload(false, false);
    };

    const modalProperties = {
        title,
        isLoading,
        buttons: [
            <Form.Secondary key="fs" isDisabled={isLoading} variant="text" color="primary" onClick={() => navigator.goToParentAndReload(false, false)}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary key="fp" variant="text" color="primary" isDisabled={isLoading}>
                <FormattedMessage id={primaryButtonText} defaultMessage={primaryButtonText} />
            </Form.Primary>,
        ],
    };

    const categories = additional && additional.categories && additional.categories.map((each) => ({ value: each.id, content: each.name }));
    return (
        <Form.Wrapper modalProperties={modalProperties}>
            <Form className="margin-top-small" id="note" onSubmit={handleOnSubmit} onFinish={onFinish}>
                {note.id && <Form.Hidden submitKey="id" value={note.id} />}
                {isEditing && <Form.Hidden submitKey="agent.id" value={data.agent.id} />}
                <Form.Input
                    isDense
                    isRequired
                    id="notes-category-id"
                    submitKey="category.id"
                    type="select"
                    label={translated.owners.notes.category}
                    value={note.category}
                    options={categories}
                />
                <Form.Input
                    isRequired
                    isDense
                    id="notes-text"
                    submitKey="note"
                    type="textarea"
                    label={translated.owners.notes.text}
                    value={note.text}
                    rows="8"
                    cols="55"
                />
            </Form>
        </Form.Wrapper>
    );
}

NoteEditor.defaultProps = { data: null };

NoteEditor.propTypes = {
    isEditing : PropTypes.bool.isRequired,
    resources : PropTypes.shape({}).isRequired,
    data      : PropTypes.shape({}),
};

export default withRequest(NoteEditor);
