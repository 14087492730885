module.exports = Object.freeze({
    clubNotSaved          : 'apf.clubs.clubNotSaved',
    clubSaved             : 'apf.clubs.clubSaved',
    clubUpdated           : 'apf.clubs.clubUpdated',
    modifyClub            : 'apf.clubs.modifyClub',
    areYouSure            : 'apf.clubs.areYouSure',
    areYouSureToReset     : 'apf.clubs.areYouSureToReset',
    unsavedDataWillBeLost : 'apf.clubs.unsavedDataWillBeLost',
    currentDuration       : 'apf.clubs.currentDuration',
    durationFixed         : 'apf.clubs.durationFixed',
    durationDynamic       : 'apf.clubs.durationDynamic',
    associatedTiers       : 'apf.clubs.associatedTiers',
    availableTiers        : 'apf.clubs.availableTiers',
    desassociateTier      : 'apf.clubs.desassociateTier',
    selectedTiers         : 'apf.clubs.selectedTiers',
    editTiers             : 'apf.clubs.editTiers',
    info                  : 'apf.clubs.info',
    noChannelId           : 'apf.clubs.noChannelId',
    unsavedTiers          : 'apf.clubs.unsavedTiers',
    addChannelID          : 'apf.clubs.addChannelID',
    numberOfMonths        : 'apf.clubs.numberOfMonths',
    overrideCosts         : 'apf.clubs.overrideCosts',
    overrideCostsWarning  : 'apf.clubs.overrideCostsWarning',
    channelId             : 'apf.clubs.channelId',
    noClubs               : 'apf.clubs.noClubs',
    createTitle           : 'apf.clubs.createTitle',
    editTitle             : 'apf.clubs.editTitle',
    wizard                : {
        step1        : 'apf.clubs.wizard.step1',
        step2        : 'apf.clubs.wizard.step2',
        step2Fixed   : 'apf.clubs.wizard.step2Fixed',
        step2Dynamic : 'apf.clubs.wizard.step2Dynamic',
        step3        : 'apf.clubs.wizard.step3',
        step4        : 'apf.clubs.wizard.step4',
        step5        : 'apf.clubs.wizard.step5',
        step6        : 'apf.clubs.wizard.step6',
    },
    enable  : { success: 'apf.clubs.enable.success', error: 'apf.clubs.enable.error' },
    disable : { success: 'apf.clubs.disable.success', error: 'apf.clubs.disable.error' },
    delete  : {
        confirmation               : 'apf.clubs.delete.confirmation',
        success                    : 'apf.clubs.delete.success',
        error                      : 'apf.clubs.delete.error',
        errorEntityCannotBeDeleted : 'apf.clubs.delete.errorEntityCannotBeDeleted',
        defaultError               : 'apf.clubs.delete.defaultError',
    },
    tiers: {
        title               : 'apf.clubs.tiers.title',
        empty               : 'apf.clubs.tiers.empty',
        associate           : 'apf.clubs.tiers.associate',
        remove              : 'apf.clubs.tiers.remove',
        initializationError : 'apf.clubs.tiers.initializationError',
        addTierError        : 'apf.clubs.tiers.addTierError',
        delete              : {
            confirmation : 'apf.clubs.tiers.delete.confirmation',
            success      : 'apf.clubs.tiers.delete.success',
            error        : 'apf.clubs.tiers.delete.error',
            defaultError : 'apf.clubs.tiers.delete.defaultError',
        },
    },
    balanceTypes: {
        title               : 'apf.clubs.balanceTypes.title',
        empty               : 'apf.clubs.balanceTypes.empty',
        associate           : 'apf.clubs.balanceTypes.associate',
        remove              : 'apf.clubs.balanceTypes.remove',
        initializationError : 'apf.clubs.balanceTypes.initializationError',
        addBalanceTypeError : 'apf.clubs.balanceTypes.addBalanceTypeError',
        delete              : {
            confirmation : 'apf.clubs.balanceTypes.delete.confirmation',
            success      : 'apf.clubs.balanceTypes.delete.success',
            error        : 'apf.clubs.balanceTypes.delete.error',
            defaultError : 'apf.clubs.balanceTypes.delete.defaultError',
        },
    },
    title: { new: 'apf.clubs.title.new', edition: 'apf.clubs.title.edition', section: 'apf.clubs.title.section' },

    extraAttributes: {
        title : 'apf.clubs.extraAttributes.title',
        save  : {
            success : 'apf.clubs.extraAttributes.save.success',
            error   : 'apf.clubs.extraAttributes.save.error',
        },
    },
});
