import { sectionType } from 'Constants/types';

/**
 * Exposes in a property all the sections that we want to access in a different structure that
 * the one in the Rendering Tree, for example, we pass a list of the owners sections to display
 * those in a menú somewhere in the app
 */
export default class SharedConfigurations {

    constructor(sections) {
        this.owners = {
            ...sections[sectionType.OWNERS].sections[sectionType.DETAILS].sections,
            [sectionType.EDITOR]: { ...sections[sectionType.OWNERS].sections[sectionType.OWNERS_EDITOR] },
        };
        this.partners = {
            ...sections[sectionType.PARTNERS].sections[sectionType.PARTNERS_DETAILS].sections,
            [sectionType.PARTNERS_EDITOR]: { ...sections[sectionType.PARTNERS].sections[sectionType.PARTNERS_EDITOR] },
        };
    }

}
