import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from '../Collection';

function ExtraAttributes({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

ExtraAttributes.propTypes = sectionPropTypes;

export default ExtraAttributes;
