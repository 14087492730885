import { hot } from 'react-hot-loader/root';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import Modal from 'Components/Modal';
import Alert from 'Components/Alert';

function About({ onClose }) {
    const { app } = useContext(PanelContext);

    return (
        <Modal
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : () => onClose(),
            }}
            className="about-modal"
        >
            <img src={`/static/clients/${window.env.CLIENT_FILENAMES}/img/logo.png`} className="logo ext-align-center margin-bottom-medium" alt="Logo" />
            <Alert
                id="app-information"
                isBasic
                content={(
                    <>
                        <FormattedMessage
                            id={translated.global.about.appName}
                            defaultMessage={translated.global.about.appName}
                            values={{ clientName: window.env.CLIENT_TITLE }}
                        />
                        <br />
                        <FormattedMessage
                            id={translated.global.about.version}
                            defaultMessage={translated.global.about.version}
                            values={{ number: app.version.number, build: app.version.build }}
                        />
                    </>
                )}
            />
        </Modal>
    );
}

About.propTypes = { onClose: PropTypes.func.isRequired };

export default hot(About);
