export default function convertBankingConfigurations(element) {
    return {
        ...element,
        repeat            : !!element.repeat,
        repeatDelta       : element.repeatDelta || null,
        repeatAnniversary : element.repeatAnniversary || null,
        effectiveDate     : element.effectiveDate || null,
        expiryDate        : element.expiryDate || null,
        amount            : Number(element.amount),
        shoulderLeft      : element.shoulderLeft || 0,
        shoulderRight     : element.shoulderRight || 0,
        balanceType       : element.balanceType?.id ? { id: element.balanceType.id } : null,
        links             : undefined,
        belongsToContract : undefined,
    };
}
