import React, { useCallback } from 'react';
import Form, { FormNew } from 'Components/Form';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import { useFormContext, useFieldArray, get } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';

export function EditorOptions({ onFinish }) {
    const intl = useIntl();

    const { control, setValue, trigger, formState, getValues, watch } = useFormContext();

    const { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'options',
    });

    const newOption = watch('newOption');

    const validateOptions = useCallback((values) => {
        const convertedData = values?.options?.map((e) => e?.name);

        onFinish(convertedData);
    }, [onFinish]);

    const addRate = async (element) => {
        if (String(element).trim()) {
            append({ name: element });

            setValue('newOption', '');

            trigger();
        }
    };

    const removeRate = async (element, index) => {
        remove(index);

        trigger();
    };

    const newOptionHasErrors = !!get(errors, 'newOption');

    return (
        <FormNew id="options-form" buttonsWidth={{ base: 12, small: 6 }} onSubmit={validateOptions}>
            <div className="form-column-group">
                <Form.InputNew
                    id="newOption"
                    submitKey="newOption"
                    label={(<FormattedMessage id={translated.extraAttributes.options.edition.option} />)}
                    isDense
                    type="text"
                    className="small-number-input"
                />

                <Button
                    id="rate-add"
                    className="add-column-button margin-left-xsmall"
                    onClick={() => {
                        addRate(getValues('newOption'));
                    }}
                    variant="outlined"
                    color="primary"
                    disabled={!newOption || newOptionHasErrors}
                    tooltip={intl.formatMessage({
                        id             : translated.global.buttons.add,
                        defaultMessage : translated.global.buttons.add,
                    })}
                >
                    <FormattedMessage id={translated.global.buttons.add} />
                </Button>
            </div>

            <Form.InputError submitKey="options" className="margin-bottom-small" />

            { fields.map((field, index) => (
                <div className="form-column-group" key={field.id}>
                    <Form.InputNew
                        id={`options-${index}`}
                        submitKey={`options.${index}.name`}
                        label={(<FormattedMessage id={translated.extraAttributes.options.edition.option} />)}
                        isDense
                        type="text"
                        className="small-number-input"
                        onChange={() => trigger()}
                    />

                    <Button
                        id={`rate-remove-${index}`}
                        className="margin-left-xsmall"
                        icon="MinusCircle"
                        onClick={() => {
                            removeRate(getValues(`options.${index}`), index);
                        }}
                        tooltip={intl.formatMessage({
                            id             : translated.global.buttons.remove,
                            defaultMessage : translated.global.buttons.remove,
                        })}
                    />
                </div>
            ))}
        </FormNew>
    );
}

EditorOptions.defaultProps = {};

EditorOptions.propTypes = { onFinish: PropTypes.func.isRequired };

export default EditorOptions;
