import React from 'react';
import PropTypes from 'prop-types';
import { yearsPerRow } from './constants';

/**
 * Draws the year picker.
 */
function PickerCalendarYear({
    elementKey,
    calendarStartDate,
    calendarEndDate,
    displayedDate,
    onYearClick,
    getMonthYearClasses,
    calendarYearsRef,
    dateManager,
}) {
    const getDates = (startDate, stopDate) => {
        const dateArray = [];
        let currentDate = dateManager.create(startDate);
        const stopDates = dateManager.create(stopDate);
        while (currentDate <= stopDates) {
            dateArray.push(dateManager.format(currentDate, 'yyyy'));
            currentDate = dateManager.add(currentDate, 1, 'years');
        }
        return dateArray;
    };

    const years = [];

    const yearsList = getDates(calendarStartDate, calendarEndDate);

    yearsList.forEach((year) => {
        const date = dateManager.setYear(displayedDate, year);

        years.push(
            <td
                className={`calendar-year${getMonthYearClasses(date, 'year')}`}
                onClick={() => onYearClick(year)}
                key={`year-${elementKey}-${year}`}
            >
                <span className="calendar-year-number">{year}</span>
            </td>,
        );
    });

    const rows = [];
    let cells = [];

    years.forEach((row, i) => {
        if (i % yearsPerRow !== 0 || i === 0) {
            cells.push(row);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(row);
        }
    });
    rows.push(cells);

    return (
        <tbody className="calendar-years" ref={calendarYearsRef}>
            {rows.map((data, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr className="calendar-row-years" key={`year-row-${elementKey}-${i}`}>{data}</tr>
            ))}
        </tbody>
    );
}

PickerCalendarYear.propTypes = {
    elementKey          : PropTypes.string.isRequired,
    calendarStartDate   : PropTypes.string.isRequired,
    calendarEndDate     : PropTypes.string.isRequired,
    displayedDate       : PropTypes.shape({}).isRequired,
    onYearClick         : PropTypes.func.isRequired,
    getMonthYearClasses : PropTypes.func.isRequired,
    calendarYearsRef    : PropTypes.shape({}).isRequired,
    dateManager         : PropTypes.shape({}).isRequired,
};

PickerCalendarYear.defaultProps = {};

export default React.memo(PickerCalendarYear);
