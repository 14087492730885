import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';
import FormContext from './formContext';

function SecondaryButton({
    id, children, onClick, variant, color, isDisabled, collapsed, size,
}) {
    const intl = useIntl();
    const { isSubmitting, formId } = useContext(FormContext);

    if (collapsed) {
        return (
            <Button
                id={id || formId ? `${formId}-secondary-button` : null}
                icon="CloseCircle"
                tooltip={intl.formatMessage({
                    id             : translated.global.buttons.cancel,
                    defaultMessage : translated.global.buttons.cancel,
                })}
                onClick={onClick}
                disabled={isDisabled || isSubmitting}
                size={size}
            />
        );
    }

    return (
        <Button
            id={id || formId ? `${formId}-secondary-button` : null}
            onClick={onClick}
            variant={variant || 'text'}
            color={color}
            disabled={isDisabled || isSubmitting}
            size={size}
        >
            {children || <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />}
        </Button>
    );
}

SecondaryButton.defaultProps = {
    id         : null,
    children   : null,
    onClick    : null,
    variant    : '',
    color      : '',
    isDisabled : false,
    collapsed  : false,
    size       : '',
};

SecondaryButton.propTypes = {
    id         : PropTypes.string,
    children   : PropTypes.node,
    onClick    : PropTypes.func,
    variant    : PropTypes.string,
    color      : PropTypes.string,
    isDisabled : PropTypes.bool,
    collapsed  : PropTypes.bool,
    size       : PropTypes.string,
};

export default SecondaryButton;
