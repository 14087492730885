import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Button from 'Components/Button';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Modal(props) {
    const {
        className, title, children, closeButton, cancelButton, backdropClick, isLoading, buttons,
    } = props;

    const modalContentRef = useRef();
    const modalHeadRef = useRef();
    const modalBodyRef = useRef();
    const modalFooterRef = useRef();

    useEffect(() => {
        if (modalContentRef?.current) {
            // We set the class to the child component to avoid visual bugs with child modals.
            modalContentRef.current.classList.add('no-animated');
        }
    }, []);

    // When the user clicks in the modal body, cancel the event propagation.
    const handleModalBodyClick = (event) => event.stopPropagation();
    // When the user clicks in the modal background, trigger the close
    const handleModalBackgroundClick = () => backdropClick?.();
    const handleCloseClick = () => closeButton.onClick();
    const handleCancelClick = () => cancelButton.onClick();

    const modalClassName = `is-scrollable modal animated fade-in fastest ${className}`;

    const modal = (
        <div id="modal" className={modalClassName} onClick={handleModalBackgroundClick}>
            <div className="modal-content animated popup fastest" onClick={handleModalBodyClick} ref={modalContentRef}>
                {isLoading && <div className="loading-line" />}
                {title && (
                    <div className="modal-head" ref={modalHeadRef}>
                        <WrappedFormattedMessage content={title} />
                    </div>
                )}
                <div className="modal-body-wrapper">
                    <div className="modal-body" ref={modalBodyRef}>
                        {children}
                    </div>
                </div>
                {(closeButton || cancelButton || buttons.length > 0) && (
                    <div className="modal-footer" ref={modalFooterRef}>
                        {buttons}
                        {cancelButton && (
                            <Button
                                id="modal-cancel-button"
                                variant="text"
                                color="primary"
                                onClick={handleCancelClick}
                                disabled={cancelButton.isEnabled === false}
                            >
                                <WrappedFormattedMessage content={cancelButton.text} />
                            </Button>
                        )}
                        {closeButton && (
                            <Button
                                id="modal-close-button"
                                variant="text"
                                color="primary"
                                onClick={handleCloseClick}
                                disabled={closeButton.isEnabled === false}
                            >
                                <WrappedFormattedMessage content={closeButton.text} />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

    return ReactDOM.createPortal(modal, document.getElementById('modals'));
}

Modal.defaultProps = {
    className     : '',
    title         : '',
    closeButton   : null,
    cancelButton  : null,
    backdropClick : null,
    isLoading     : false,
    buttons       : [],
};

Modal.propTypes = {
    className   : PropTypes.string,
    title       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    children    : PropTypes.node.isRequired,
    closeButton : PropTypes.shape({
        id        : PropTypes.string,
        text      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
    }),
    cancelButton: PropTypes.shape({
        id        : PropTypes.string,
        text      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
    }),
    backdropClick : PropTypes.func,
    isLoading     : PropTypes.bool,
};

export default Modal;
