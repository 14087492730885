import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import Toggle from './Toggle';
import Button from 'Components/Button';
import Tooltip from 'Components/Tooltip';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';

function Wrapper({
    id,
    children,
    inputClass,
    className,
    hasToggle,
    helperText,
    charCount,
    onToggleChange,
    toggleLabel,
    wrapperRef,
    validationErrors,
    isSelected,
    icon,
    onCleanClick,
    isCleanDisabled,
    prefix,
    suffix,
    isReadOnlyWithoutInputs,
    supportText,
    hideErrorText,
    isDisabled,
}) {
    const intl = useIntl();

    const helperError = validationErrors?.length ? validationErrors[0] : null;
    const errorIconClass = helperError ? 'form-icon form-icon-flip' : '';

    const wrapperClass = getClassName(
        {
            isSelected,
            withCheckbox      : hasToggle && !toggleLabel,
            withLabelCheckbox : toggleLabel,
            hasRemove         : onCleanClick,
            hasSupportText    : supportText,
        },
        className,
    );

    const tooltipId = Tooltip.generateId();

    const supportTextTranslated = useMemo(() => getMessage(intl, supportText), [intl, supportText]);

    return (
        <div className={`form-field ${wrapperClass}`} ref={wrapperRef} id={`${id}-container`}>
            {hasToggle && (
                <Toggle id={id ? `${id}-check` : null} onChange={onToggleChange} value={isSelected} toggleLabel={toggleLabel} isDisabled={isDisabled} />
            )}

            <div className={`form-field-input ${inputClass} ${errorIconClass}`}>
                {icon && !helperError && <Icon className="mdi-icon" path={IconPath[`mdi${icon.name}`]} />}
                {helperError && <Icon className="mdi-icon" path={IconPath.mdiAlertCircle} />}
                {prefix && (
                    <span className="prefix-suffix">
                        <WrappedFormattedMessage content={prefix} />
                    </span>
                )}
                {children}
                {suffix && !prefix && (
                    <span className="prefix-suffix">
                        <WrappedFormattedMessage content={suffix} />
                    </span>
                )}
            </div>

            {supportText && (
                <div className="form-field-input-support-text" data-tip={supportTextTranslated} data-for={tooltipId}>
                    <Tooltip id={tooltipId}>
                        {supportTextTranslated}
                    </Tooltip>
                    <Icon className="mdi-icon" path={IconPath.mdiHelpCircleOutline} />
                </div>
            )}

            {!isReadOnlyWithoutInputs && !isDisabled && onCleanClick && (
                <Button
                    id={id ? `${id}-clean` : null}
                    className="remove-input-button"
                    icon="MinusCircle"
                    tooltip={translated.global.buttons.clear}
                    onClick={onCleanClick}
                    disabled={isCleanDisabled}
                />
            )}

            {(helperText || charCount || helperError) && (
                <div
                    className={`form-field-helper-text ${helperText && helperText?.onClick ? 'link' : ''} ${
                        !helperError && helperText ? helperText?.className : ''
                    }`}
                >
                    {!hideErrorText && helperError && (
                        <span className="helper-text">
                            <WrappedFormattedMessage content={helperError} />
                        </span>
                    )}

                    {!helperError && helperText && helperText.label && (
                        <span className={`helper-text ${helperText?.className}`} onClick={helperText.onClick}>
                            <WrappedFormattedMessage content={helperError || helperText.label} />
                        </span>
                    )}

                    {charCount && <span className="char-counter">{`${charCount.current}/${charCount.total}`}</span>}
                </div>
            )}
        </div>
    );
}

Wrapper.defaultProps = {
    id                      : false,
    className               : '',
    inputClass              : '',
    helperText              : null,
    supportText             : null,
    prefix                  : '',
    suffix                  : '',
    toggleLabel             : '',
    hasToggle               : false,
    isSelected              : false,
    charCount               : null,
    icon                    : null,
    onCleanClick            : null,
    isCleanDisabled         : false,
    validationErrors        : [],
    isReadOnlyWithoutInputs : false,
    hideErrorText           : false,
    isDisabled              : false,
    wrapperRef              : () => {
        // Default
    },
};

Wrapper.propTypes = {
    id              : PropTypes.string,
    children        : PropTypes.node.isRequired,
    className       : PropTypes.string,
    inputClass      : PropTypes.string,
    toggleLabel     : PropTypes.string,
    prefix          : PropTypes.string,
    suffix          : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hasToggle       : PropTypes.bool,
    isSelected      : PropTypes.oneOf([true, false, null]),
    onToggleChange  : PropTypes.func.isRequired,
    onCleanClick    : PropTypes.func,
    isCleanDisabled : PropTypes.bool,
    supportText     : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    wrapperRef      : PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    helperText      : PropTypes.shape({
        label     : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        className : PropTypes.string,
        onClick   : PropTypes.func,
    }),
    charCount: PropTypes.shape({
        current : PropTypes.number,
        total   : PropTypes.number,
    }),
    icon: PropTypes.shape({
        name     : PropTypes.string,
        position : PropTypes.string,
    }),
    validationErrors        : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    isReadOnlyWithoutInputs : PropTypes.bool,
    hideErrorText           : PropTypes.bool,
    isDisabled              : PropTypes.bool,
};

function WrapperWithRef(props, ref) {
    return <Wrapper {...props} wrapperRef={ref} />;
}
WrapperWithRef.displayName = 'Wrapper';

export default forwardRef(WrapperWithRef);
