import * as types from 'State/types/panel';
import update from 'immutability-helper';
import { getDefaultChildSections, getDefaultSection } from 'Utils/sectionsHelper';
import RenderingTree from '../RenderingTree';
import { updatePageStatus } from './helpers/qaGenerator';
import { isQaEnabled } from 'Utils';

function getPayloadDataForSection(payload, sectionName) {
    let found = [];
    if (payload && payload.length) {
        payload.forEach((each) => {
            if (each.on === sectionName && each.with) {
                found = [...found, each.with];
            }
        });
    }
    return found;
}

function getOneLevelDeepTranslatePayloadForSection(payload) {
    const flatPayload = payload.reduce((prev, current) => ({ ...prev, ...current }), {});
    return Object.keys(flatPayload).reduce((prev, current) => {
        let flatted;
        if (typeof flatPayload[current] === 'object' && flatPayload[current] !== null) {
            const propKey = Object.keys(flatPayload[current])[0];
            flatted = {
                ...prev,
                [current]: { [propKey]: { $set: flatPayload[current][propKey] } },
            };
        } else {
            flatted = { ...prev, [current]: { $set: flatPayload[current] } };
        }
        return flatted;
    }, {});
}

const keysForResources = (obj) => Object.keys(obj).reduce(
    (prev, current) => ({
        ...prev,
        [current]: { $set: obj[current] },
    }),
    {},
);

function getTranslatePayloadForSection(payload, oneLevelDeepPropSet) {
    if (oneLevelDeepPropSet) {
        return getOneLevelDeepTranslatePayloadForSection(payload);
    }
    const flatPayload = payload.reduce((prev, current) => ({ ...prev, ...current }), {});
    return Object.keys(flatPayload).reduce(
        (prev, current) => ({
            ...prev,
            [current]: current !== 'resources' ? { $set: flatPayload[current] } : keysForResources(flatPayload[current]),
        }),
        {},
    );
}

/**
 * Mixes the children nodes received with the children nodes of the section
 *  in the redux store. It adds the keys necessary for the embedded children
 *  so 'immutability-helper' erases the section's data.
 */
function getSectionEmbeddedChildren(childrenNodes, sectionNode) {
    const children = {};
    if (sectionNode.sections) {
        Object.keys(sectionNode.sections).forEach((childKey) => {
            const child = sectionNode.sections[childKey];
            if (child.isEmbedded) {
                let found = childrenNodes[childKey];

                if (!childrenNodes[childKey]) {
                    found = {};
                }
                found.data = { $set: null };
                found.availableResources = { $set: null };

                children[childKey] = found;
            }
        });
    }

    return children;
}

/**
 * Recursively it convert each node received and its children.
 *  It removes the data of the child sections of the sections with the flag 'cleanChildren'.
 *  It also removes the node data when then flag 'cleanData' is present.
 */
function convertTreeNode(sectionNode, baseNode = {}) {
    let childrenNodes = {};

    const { cleanChildren, cleanData, ...baseNodeProps } = baseNode || {};

    const baseNodeSections = baseNode.sections || {};

    if (sectionNode.sections) {
        // Convert each child node.
        Object.keys(sectionNode.sections).forEach((key) => {
            childrenNodes[key] = convertTreeNode(sectionNode.sections[key], baseNodeSections[key]);
        });
    }

    // When the node has the flag 'cleanChildren', it erases the child node's data.
    if (cleanChildren) {
        childrenNodes = getSectionEmbeddedChildren(childrenNodes, sectionNode);
    }

    const nodeData = cleanData ? { data: { $set: null } } : {};

    return {
        ...baseNodeProps,
        ...nodeData,
        sections: childrenNodes,
    };
}

function cleanTreeData(baseTree, sections) {
    const result = {};
    Object.keys(baseTree).forEach((eachKey) => {
        const node = baseTree[eachKey];
        const sectionNode = sections[eachKey];

        result[eachKey] = convertTreeNode(sectionNode, node);
    });

    return result;
}

function isLastSectionNotEmbedded(sections, path) {
    if (!sections || !path || !path.length) {
        return true;
    }

    let currentSection = sections;
    path.forEach((eachSection, index) => {
        if (currentSection && currentSection[eachSection]) {
            currentSection = currentSection[eachSection];
            if (currentSection && index < path.length - 1) {
                currentSection = currentSection.sections;
            }
        }
    });

    if (!currentSection) {
        return true;
    }

    return !currentSection.isEmbedded;
}

/**
 * You Either Die A Hero, Or You Live Long Enough To See Yourself Become The Villain
 */
function getPathForUpdateBranch(sectionPath, params, shouldSelectBranch = false, isSelected = false, sections = null, oneLevelDeepPropSet) {
    const lastSectionIndex = sectionPath.length - 1;
    const baseTree = {};
    let currentTree = baseTree;

    let stateSections = sections;

    if (!isSelected) {
        stateSections = null;
    }

    for (let i = 0; i < lastSectionIndex; ++i) {
        const section = sectionPath[i];

        if (!(section in currentTree)) {
            currentTree[section] = { sections: {} };
            if (shouldSelectBranch) {
                currentTree[section].isSelected = { $set: isSelected };
            }
            const sectionData = getPayloadDataForSection(params, section);

            if (sectionData.length) {
                const immutableSetter = getTranslatePayloadForSection(sectionData, oneLevelDeepPropSet);
                currentTree[section] = {
                    ...currentTree[section],
                    ...immutableSetter,
                };
            }
        }
        currentTree = currentTree[section].sections;
    }

    if (shouldSelectBranch && (!stateSections || isLastSectionNotEmbedded(stateSections, sectionPath))) {
        currentTree[sectionPath[lastSectionIndex]] = { isSelected: { $set: isSelected } };

        // When the select does not set a data, we set the flag "shouldReloadData".
        if (!currentTree[sectionPath[lastSectionIndex]].data && currentTree[sectionPath[lastSectionIndex]].shouldReloadData != null) {
            currentTree[sectionPath[lastSectionIndex]].shouldReloadData = { $set: true };
        }
    }
    const sectionData = getPayloadDataForSection(params, sectionPath[lastSectionIndex]);

    if (sectionData.length) {
        const immutableSetter = getTranslatePayloadForSection(sectionData, oneLevelDeepPropSet);
        currentTree[sectionPath[lastSectionIndex]] = {
            ...currentTree[sectionPath[lastSectionIndex]],
            ...immutableSetter,
        };
    }
    if (params && sections) {
        /* When 'stateSections' is received and there are params to be added to some section,
         *   it must clean:
         *   - the data of the stateSections with the flag: 'cleanData'.
         *   - The data of the children of the stateSections with the flag 'cleanChildren'.
         */

        return cleanTreeData(baseTree, sections);
    }

    return baseTree;
}

function pathFromState({ sections }, shouldAvoidEmbeddedSection = false) {
    const path = [];

    let currentSection = sections;
    while (currentSection) {
        const sectionToShow = RenderingTree.findSelectedAndEnabledSection(currentSection, shouldAvoidEmbeddedSection);

        if (sectionToShow) {
            path.push(sectionToShow);
            currentSection = currentSection[sectionToShow].sections;
        } else {
            currentSection = null;
        }
    }

    return path;
}

/**
 * If the first key does not correspond with any of the keys that are in the first level of the state,
 * it means that the first part of the path, is the one that is now set as selected
 */
function isPathImplicit(newPath, allPathsTree) {
    const isFirstKeyInFirstLevel = Object.keys(allPathsTree).find((sec) => newPath[0] === sec);
    return !isFirstKeyInFirstLevel;
}

function getNewFullPath(sectionPath, currentPathArray, currentSections) {
    let path = [...sectionPath];
    if (isPathImplicit(sectionPath, currentSections)) {
        path = [...currentPathArray.slice(0, -1), ...sectionPath];
    }

    // Look for the child nodes marked as default.
    return [...path, ...getDefaultChildSections(path)];
}

function getCurrentSection(sectionPath, sections) {
    let activeSection;
    sectionPath.forEach((each, i) => {
        if (i === 0) {
            activeSection = sections[each];
        } else {
            activeSection = activeSection.sections[each];
        }
    });
    return activeSection;
}

/**
 * Converts the string used as regex to a valid url.
 * When the regex has a parameter, this function must receive it's value in 'urlParam'.
 */
function regexToUrl(url, urlParam, combinedIdPart) {
    const parameterRegEx = /\(.*?\)/;
    // Remove the pattern for the optional '/' (/?).
    const urlConverted = url.replace(/\/\?\(/g, '/(');

    if (urlConverted.match(parameterRegEx)) {
        // When the url has a parameter

        // Divide the regex by the url parameter
        const urlParts = urlConverted.split(parameterRegEx);

        let result = urlParts[0];

        // Remove the optional '*' used in reg ex.
        const lastPart = urlParts[1] && urlParts[1].indexOf('*') === 0 ? urlParts[1].replace('*', '') : urlParts[1] || '';

        // NOTE
        // This only works for TWO parameters, and is used on Rule section mainly
        const convertedId = !urlParam ? '' : `${urlParam}${combinedIdPart ? `-${combinedIdPart}` : ''}`;

        // If the url has a parameter, add it to the result.
        result = convertedId ? result + convertedId + lastPart : lastPart;

        return result;
    }

    return urlConverted;
}

/**
 * Returns the url of the section selected.
 */
function getSelectedSectionUrl(parentSection, pathArray) {
    let childrenSections = parentSection;
    let url = '';

    pathArray.forEach((sectionType) => {
        const eachSection = childrenSections[sectionType];
        if (eachSection?.config?.url) {
            const { url: eachSectionUrl, combinedIdKey } = eachSection.config;

            // We change the url only when there is no combinedKey, or the section's data is loaded.
            if (!combinedIdKey || (eachSection.data != null && Object.keys(eachSection.data).length)) {
                // When we are loading data from url, we might not have the data.id yet
                // so if we are loading using templates, we use the id stored in 'ids'
                let id;
                if (eachSection.shouldReloadData === 'useTemplate') {
                    id = eachSection.ids[eachSection.ids.self];
                } else {
                    id = eachSection.data?.id || eachSection.resources.available?.id || null;
                }

                let tempUrl = eachSectionUrl;
                if (typeof eachSectionUrl === 'object') {
                    tempUrl = id ? eachSectionUrl.withId : eachSectionUrl.withoutId;
                }

                let combinedId = null;
                if (id && combinedIdKey) {
                    if (eachSection.data?.[combinedIdKey]) {
                        combinedId = eachSection.data[combinedIdKey];
                    }
                }

                url += regexToUrl(tempUrl, id, combinedId);
            }
        }
        childrenSections = eachSection.sections;
    });

    return url;
}

function getCurrentPath(section, path) {
    return section && RenderingTree.isLastSectionNotEmbedded(section, path) ? path : path.slice(0, -1);
}

function getUpdatedPath({
    sections,
    path,
    currentSections,
    selectedBranchProps,
    sectionPath,
    hasToAddToSelectionsHistory,
    popHistory = false,
    checkForDataChanges = false,
}) {
    const { url, urlHistory, current } = path;
    let { selectionsHistory } = path;

    const currentPath = getCurrentPath(sections, popHistory ? sectionPath : currentSections || current);
    // Get the url for the current selected section.
    const currentUrl = getSelectedSectionUrl(sections, currentPath);

    const addToHistory = !popHistory && (currentUrl !== url || (urlHistory?.length && urlHistory[urlHistory.length - 1] !== currentUrl));

    if (currentUrl === url) {
        // The url has not changed
        return { ...path, current: currentPath };
    }

    if (!popHistory) {
        selectionsHistory = hasToAddToSelectionsHistory || (checkForDataChanges && addToHistory)
            ? [...selectionsHistory, { path: currentPath, props: selectedBranchProps }]
            : [...selectionsHistory];
    } else {
        selectionsHistory = [...selectionsHistory.slice(0, selectionsHistory.length - 1)];
    }
    const currentHistory = [...urlHistory, currentUrl];

    return {
        ...path,
        urlHistory : currentHistory,
        url        : currentUrl,
        current    : currentPath,
        selectionsHistory,
    };
}

const initialState = {};

function pathsDiffer(currentPath, newPath) {
    return currentPath.join() !== newPath.join();
}

const isSectionLoadingData = (section, isRootSection = false) => {
    if ((section?.isHidden || !section?.isEnabled || !section?.isSelected) && !section?.isEmbedded) {
        return false;
    }

    if (!isRootSection && (section?.shouldReloadData === true || (!section.data && Object.keys(section.resources?.available || {}).length > 0))) {
        return true;
    }

    return section?.sections && Object.values(section.sections).find((each) => isSectionLoadingData(each));
};

const checkPageStatus = (sections) => {
    if (isQaEnabled()) {
        const isLoading = !!Object.values(sections).find((value) => isSectionLoadingData(value, true));

        updatePageStatus(isLoading);
    }
};

/* eslint-disable no-param-reassign */
const cleanSectionsResources = (section, currentPath, newSectionsPath, shouldClean = false) => {
    const hasToClean = shouldClean || (section?.config?.cleanWhenLeaving && newSectionsPath.indexOf(section.config.cleanWhenLeaving) === -1);

    // The section's data and resources will be erased
    if (hasToClean) {
        section.data = null;
        section.error = null;
        if (section.state?.appliedFilters) {
            section.state.appliedFilters = {};
        }
        section.shouldReloadData = false;
        section.fetching = {
            ids      : [],
            isGlobal : false,
        };
    }

    // We have to clean each child when 'hasToClean' is true, and when its false we should check if it has to clean its data.
    if (section.sections) {
        Object.entries(section.sections).forEach(([childName, child]) => {
            cleanSectionsResources(child, [...currentPath, childName], newSectionsPath, hasToClean);
        });
    }
};

// Clean the deselected sections data and applied filters
const cleanOldSections = (sections, currentPathArray, newPathArray) => {
    if (currentPathArray.join() !== newPathArray.join()) {
        const path = [];
        let currentSection = sections;

        let i = 0;
        let found = false;
        while (i < currentPathArray.length && !found) {
            const currentSectionName = currentPathArray[i];
            path.push(currentSectionName);
            currentSection = currentSection.sections ? currentSection.sections[currentSectionName] : currentSection[currentSectionName];

            // Clean the section deselected.
            if (!newPathArray.join().startsWith(path.join())) {
                found = true;
                cleanSectionsResources(currentSection, path, newPathArray);
            }

            ++i;
        }
    }

    return sections;
};

const getSectionToOverride = (sections, path = []) => {
    let isSelected = true;
    let currentNode = { sections };
    const dataToOverride = [];

    path.forEach(((e, index) => {
        currentNode = currentNode?.sections?.[path[index]];

        isSelected = isSelected && (currentNode?.isSelected || currentNode?.isEmbedded || currentNode?.isModal);

        if (!currentNode?.isSelected && !currentNode?.isEmbedded && !currentNode?.isModal) {
            dataToOverride.push({
                on   : e,
                with : { data: null, error: null },
            });
        }
    }));

    return dataToOverride;
};

const panelReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.SECTION_SELECT: {
            const { sections: currentSections, path, componentsWithChangesInCurrentSection } = state;
            const { sectionPath, selectedBranchProps, oneLevelDeepPropSet, unselectedBranchProps } = action.payload;

            const hasToAddToSelectionsHistory = Array.isArray(selectedBranchProps)
                ? selectedBranchProps.some((branch) => !branch.with.cleanChildren && !branch.with.cleanData)
                : false;

            // Determine the current path and save it in the history
            const currentPathArray = path.current.length ? path.current : pathFromState(state);
            const currentPathTree = getPathForUpdateBranch(currentPathArray, unselectedBranchProps, true, false);

            // Determine the desired path and create the object for the immutable helper
            const newPathArray = getNewFullPath(sectionPath, currentPathArray, currentSections);
            const newPathTree = getPathForUpdateBranch(newPathArray, selectedBranchProps, true, true, currentSections, oneLevelDeepPropSet);

            // Set current path as unselected
            let sections = update(currentSections, currentPathTree);

            // Set new path as selected
            sections = update(sections, newPathTree);

            // Clean the old section filters data
            sections = cleanOldSections(sections, currentPathArray, newPathArray);

            // Update the page status when qa is enabled
            checkPageStatus(sections);

            return {
                ...state,
                sections,
                path: {
                    ...getUpdatedPath({
                        sections,
                        path,
                        currentSections : newPathArray,
                        selectedBranchProps,
                        sectionPath,
                        hasToAddToSelectionsHistory,
                        popHistory      : false,
                    }),
                    current: [...newPathArray],
                },
                // When the selected section changes, clear the componentsWithChangesInCurrentSection field.
                componentsWithChangesInCurrentSection: pathsDiffer(path.current, newPathArray) ? [] : componentsWithChangesInCurrentSection,
            };
        }

        case types.SECTION_SELECT_FOR_BACK_ACTION: {
            const { sections: currentSections, path, componentsWithChangesInCurrentSection } = state;

            const sectionPath = [...path.selectionsHistory[path.selectionsHistory.length - 2].path];
            let selectedBranchProps = path.selectionsHistory[path.selectionsHistory.length - 2].props;

            // To reload the data when user clicks back
            if (selectedBranchProps?.length) {
                selectedBranchProps = selectedBranchProps.map((eachProp) => ({
                    ...eachProp,
                    with: { ...eachProp.with, shouldReloadData: eachProp.with.shouldReloadData || true },
                }));
            }

            // Determine the current path and save it in the history
            const currentPathTree = getPathForUpdateBranch(path.current, null, true, false);

            // Determine the desired path and create the object for the immutable helper
            const newPathTree = getPathForUpdateBranch(sectionPath, selectedBranchProps, true, true, currentSections, false);

            // Set current path as unselected
            let sections = update(currentSections, currentPathTree);

            const isPopAction = true;
            // Set new path as selected
            sections = update(sections, newPathTree);

            // Clean the old section filters data
            sections = cleanOldSections(sections, path.current, sectionPath);

            // Update the page status when qa is enabled
            checkPageStatus(sections);

            return {
                ...state,
                sections,
                path: {
                    ...getUpdatedPath({ sections, path, currentSections: selectedBranchProps, sectionPath, popHistory: isPopAction }),
                    current: sectionPath,
                },
                // When the selected section changes, clear the componentsWithChangesInCurrentSection field.
                componentsWithChangesInCurrentSection: pathsDiffer(path.current, sectionPath) ? [] : componentsWithChangesInCurrentSection,
            };
        }

        case types.PUSH_TO_URL: {
            const { shouldReloadUrlStage } = action.payload;

            return { ...state, shouldReloadUrlStage };
        }

        case types.SET_SHOULD_RELOAD_URL_STAGE: {
            const { shouldReloadUrlStage } = action.payload;
            return { ...state, shouldReloadUrlStage };
        }

        case types.SECTION_STATE_SET: {
            const { sections: currentSections, path } = state;
            const { sectionPath, params } = action.payload;

            // Determine the current path
            const currentPathArray = path.current.length ? path.current : pathFromState(state);

            // Determine the desired path and create the object for the immutable helper
            const newPathArray = getNewFullPath(sectionPath, currentPathArray, currentSections);

            // If there is an error, we update the error key in the branch, otherwise only update the fetching
            const pathTree = getPathForUpdateBranch(newPathArray, params);
            const sections = update(currentSections, pathTree);

            return {
                ...state,
                sections,
                path: getUpdatedPath({ sections, path }),
            };
        }

        case types.SECTION_DISABLE: {
            let { sections } = state;
            const sectionsPaths = action.payload;

            // If there is an error, we update the error key in the branch, otherwise only update the fetching
            sectionsPaths.forEach((sectionPath) => {
                const pathTree = getPathForUpdateBranch(sectionPath, { isEnabled: false });
                sections = update(sections, pathTree);
            });

            return { ...state, sections };
        }

        case types.SECTIONS_IDS_SET: {
            return { ...RenderingTree.addIds(state, action.payload) };
        }

        case types.REQUEST_END:
        case types.REQUEST_ERROR:
        case types.REQUEST_START: {
            const { sections: storedSections, path, currentSection } = state;
            const { sectionPath, params, currentSectionRequest, shouldReloadUrlStage } = action.payload;

            // Determine the current path
            const currentPathArray = path.current.length ? path.current : pathFromState(state);

            // Determine the desired path and create the object for the immutable helper
            const newPathArray = getNewFullPath(sectionPath, currentPathArray, storedSections);

            // If there is an error, we update the error key in the branch, otherwise only update the fetching
            const pathTree = getPathForUpdateBranch(newPathArray, params);
            // We update the sections
            let sections = update(storedSections, pathTree);

            // In case the selected section changed before the data load completed
            if (action.type === types.REQUEST_END || action.type === types.REQUEST_ERROR) {
                const dataToOverride = getSectionToOverride(storedSections, newPathArray);

                if (dataToOverride?.length) {
                    const pathTreeToOverride = getPathForUpdateBranch(newPathArray, dataToOverride);

                    sections = update(sections, pathTreeToOverride);
                }
            }

            // Update the page status when qa is enabled
            checkPageStatus(sections);

            return {
                ...state,
                sections,
                shouldReloadUrlStage,
                path           : { ...getUpdatedPath({ sections, path, checkForDataChanges: true }) },
                currentSection : {
                    ...currentSection,
                    lastRequest: currentSectionRequest || currentSection.lastRequest,
                },
            };
        }

        case types.ROOT_AND_TEMPLATES_SET: {
            const { templates, root } = action.payload;
            const updatedState = RenderingTree.addTemplates(state, templates);

            const defaultSection = getDefaultSection(root);

            Object.keys(updatedState.sections).forEach((type) => {
                if (root.includes(type) || updatedState.sections[type].isPublic) {
                    updatedState.sections[type].isEnabled = true;
                    updatedState.sections[type].config.isDefaultSection = defaultSection === type;

                    // If the section is enabled, the list section on that type, is enabled too
                    const list = updatedState.sections[type].config.defaultSection || `${type}List`;
                    if (updatedState.sections[type].sections[list] && !updatedState.sections[type].sections[list]?.resources?.available) {
                        // We only update the section's resource when it not already loaded, to avoid "breaking" the filters loaded.
                        updatedState.sections[type].sections[list].resources.available = templates[type];
                    }
                } else {
                    updatedState.sections[type].isEnabled = false;
                    updatedState.sections[type].config.isDefaultSection = false;
                }
            });

            return { ...updatedState };
        }

        case types.CURRENT_SECTION_COMPONENT_WITH_CHANGES_SET: {
            const { isChanged, formId } = action.payload;

            let newComponentsChanged = [...state.componentsWithChangesInCurrentSection];

            if (isChanged) {
                newComponentsChanged.push(formId);
            } else {
                newComponentsChanged = newComponentsChanged.filter((each) => each !== formId);
            }

            return { ...state, componentsWithChangesInCurrentSection: newComponentsChanged };
        }

        default:
            return state;
    }
};

export default panelReducer;

function sectionHasADefaultChild({ sections }) {
    if (!sections || !Object.values(sections).length) {
        return false;
    }

    return !!Object.values(sections).find((eachSection) => eachSection.config && eachSection.config.isDefaultSection);
}

function getSelectableParentPathFromState({ sections }) {
    let selectedSections = [];

    let currentSection = sections;
    while (currentSection) {
        const sectionName = RenderingTree.findSelectedAndEnabledSection(currentSection, false);

        if (sectionName) {
            selectedSections.push({
                ...currentSection[sectionName],
                key: sectionName,
            });
            currentSection = currentSection[sectionName].sections;
        } else {
            currentSection = null;
        }
    }

    // Remove the last section if it is embedded
    let [lastSection] = selectedSections.slice(-1);
    if (lastSection && lastSection.isEmbedded) {
        selectedSections = selectedSections.slice(0, -1);
    }

    // Remove the last section.
    if (selectedSections.length > 1) {
        selectedSections = selectedSections.slice(0, -1);
    }

    // Check if the parent section has a default section
    if (selectedSections.length > 1) {
        [lastSection] = selectedSections.slice(-1);
        if (lastSection && sectionHasADefaultChild(lastSection)) {
            selectedSections = selectedSections.slice(0, -1);
        }
    }

    return selectedSections.map((eachSection) => eachSection.key);
}

export const getSelectableParentPath = (state) => getSelectableParentPathFromState(state);
export const hasSectionChanged = (state) => !!state.componentsWithChangesInCurrentSection.length;
export const getSectionIsFetching = (state) => {
    const currentSection = getCurrentSection(state.panel.path.current, state.panel.sections);
    return !!currentSection.data;
};
