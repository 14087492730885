import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import translated from 'Constants/labels/translated';
import Table from 'Components/Table';
import Button from 'Components/Button';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import Form from 'Components/Form';

const DECIMAL_LIMIT = 5;

function ConversionCard({ range, currencies }) {
    const { setValue, watch } = useFormContext();
    const { id, label, from, to } = range;
    const [conversions, availableBalanceTypes] = watch(['conversions', 'availableBalanceTypes']);

    // Used to force the conversion rate table update
    const [itemsUpdated, setItemsUpdated] = useState(0);

    const forceItemsUpdate = () => {
        setItemsUpdated((prev) => prev + 1);
    };

    const items = useMemo(() => (
        currencies.map((eachCurrency) => ({
            ...eachCurrency,
            roomValueAreEquals: conversions && conversions[`${id}-${eachCurrency.id}`]
                ? conversions[`${id}-${eachCurrency.id}`]?.roomValueAreEquals
                : true,
            serviceValueAreEquals: conversions && conversions[`${id}-${eachCurrency.id}`]
                ? conversions[`${id}-${eachCurrency.id}`]?.serviceValueAreEquals
                : true,
            balanceTypes: eachCurrency?.balanceTypes
                ?.map((eachBalanceType) => ({
                    ...eachBalanceType,
                    currency: eachCurrency.id,
                })) || [],
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [conversions, currencies, id, itemsUpdated]); // itemsUpdated is required to update the list correctly

    const handleGroupRateValueChange = useCallback((rateId, currencyId, newValue, isRoomValue) => {
        const balanceTypes = watch('balanceTypes');

        const updatedBalanceTypes = balanceTypes?.filter(
            (eachBalanceType) => availableBalanceTypes?.find?.(
                (eachAvailable) => eachAvailable.id === eachBalanceType && eachAvailable.currency?.id === currencyId,
            ),
        );

        updatedBalanceTypes.forEach((eachBalanceType) => {
            const path = `${rateId}-${currencyId}-${eachBalanceType}`;
            const key = `conversions.${path}`;
            const value = conversions[path];
            value[isRoomValue ? 'roomValue' : 'serviceValue'] = newValue;
            const options = { shouldDirty: true, shouldValidate: true };

            setValue(key, value, options);
        });
    }, [availableBalanceTypes, conversions, setValue, watch]);

    const updateValue = useCallback((key, value, isRoomValue) => {
        const conversion = conversions[key];
        if (isRoomValue) {
            conversion.roomValue = value;
            conversion.roomValueAreEquals = true;
        } else {
            conversion.serviceValue = value;
            conversion.serviceValueAreEquals = true;
        }
        const options = { shouldDirty: true, shouldValidate: true };
        setValue(key, conversion, options);

        forceItemsUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRoomVariableOnClick = useCallback((rateId, currencyId) => {
        const key = `${rateId}-${currencyId}`;
        updateValue(key, '', true);
    }, [updateValue]);

    const handleServiceVariableOnClick = useCallback((rateId, currencyId) => {
        const key = `${rateId}-${currencyId}`;
        updateValue(key, '', false);
    }, [updateValue]);

    const getUpdatedCurrencies = useCallback(() => {
        const converted = [];
        const balanceTypes = watch('balanceTypes');
        balanceTypes?.forEach?.((eachBalanceType) => {
            const foundBalanceType = availableBalanceTypes?.find?.((eachBalanceCurrency) => eachBalanceCurrency.id === eachBalanceType);

            if (foundBalanceType) {
                let foundCurrency = converted.find((eachConverted) => eachConverted.id === foundBalanceType.currency?.id);

                if (!foundCurrency) {
                    foundCurrency = { ...foundBalanceType.currency, balanceTypes: [] };
                    converted.push(foundCurrency);
                }

                foundCurrency.balanceTypes.push({
                    id   : foundBalanceType.id,
                    name : foundBalanceType.name,
                });
            }
        });

        return converted;
    }, [availableBalanceTypes, watch]);

    const rateOnChange = useCallback((rateId, currencyId, conversionId, newValue, isRoomValue) => {
        const updatedCurrencies = getUpdatedCurrencies();
        const updatedBalanceTypes = updatedCurrencies?.find((e) => e.id === currencyId)?.balanceTypes?.map((e) => e.id) || [];

        const uniqueRates = new Set();
        updatedBalanceTypes.forEach((eachBalanceType) => {
            if (String(eachBalanceType) !== String(conversionId)) {
                const key = `${rateId}-${currencyId}-${eachBalanceType}`;
                const value = isRoomValue ? conversions[key]?.roomValue : conversions[key]?.serviceValue;
                uniqueRates.add(String(value));
            }
        });
        uniqueRates.add(String(newValue)); // We add the value updated

        const areEquals = uniqueRates.size < 2;

        const key = `${rateId}-${currencyId}`;
        const path = `conversions.${key}`;
        const value = isRoomValue
            ? {
                roomValue             : areEquals ? newValue : '',
                roomValueAreEquals    : areEquals,
                serviceValue          : conversions[key].serviceValue,
                serviceValueAreEquals : conversions[key].serviceValueAreEquals,
                isRequired            : true,
            }
            : {
                serviceValue          : areEquals ? newValue : '',
                serviceValueAreEquals : areEquals,
                roomValue             : conversions[key].roomValue,
                roomValueAreEquals    : conversions[key].roomValueAreEquals,
                isRequired            : true,
            };
        const options = { shouldDirty: true, shouldValidate: true };
        setValue(path, value, options);

        forceItemsUpdate();
    }, [conversions, getUpdatedCurrencies, setValue]);

    const limitInput = useCallback((value) => {
        const separator = value.indexOf('.');
        return (separator >= 0)
            ? (value.substr(0, separator) + value.substr(separator, DECIMAL_LIMIT)) : value;
    }, []);

    return (
        <Table
            id={`${id}-currency-group`}
            key={`${id}-currency-group`}
            title={`${label} (${from} - ${to})`}
            className="margin-top-small"
            columns={[
                {
                    title     : translated.rules.currency,
                    key       : 'name',
                    isVisible : true,
                },
                {
                    title                : translated.rules.dynamicRanges.rangeConfiguration.subtitles.roomRate,
                    key                  : `${id}-currency-group-roomRate`,
                    isVisible            : true,
                    isNumeric            : true,
                    customClassName      : 'table-input-field',
                    customFieldComponent : (conversion) => (
                        <>
                            {conversion.roomValueAreEquals && (
                                <div>
                                    <Form.InputNew
                                        isDense
                                        className="zip-input"
                                        submitKey={`conversions.${id}-${conversion.id}.roomValue`}
                                        onInput={limitInput}
                                        onChange={(newRate) => handleGroupRateValueChange(id, conversion.id, newRate, true)}
                                        type="number"
                                    />
                                </div>
                            )}
                            {!conversion.roomValueAreEquals && (
                                <>
                                    <WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.variable} />
                                    <Button
                                        className="margin-left-xsmall"
                                        size="small"
                                        id={`range-${id}-variable`}
                                        onClick={() => handleRoomVariableOnClick(id, conversion.id)}
                                        icon="Pencil"
                                        color="primary"
                                    />
                                </>
                            )}
                        </>
                    ),
                },
                {
                    title                : translated.rules.dynamicRanges.rangeConfiguration.subtitles.serviceRate,
                    key                  : `${id}-currency-group-serviceRate`,
                    isVisible            : true,
                    isNumeric            : true,
                    customClassName      : 'table-input-field',
                    customFieldComponent : (conversion) => (
                        <>
                            {conversion.serviceValueAreEquals && (
                                <div>
                                    <Form.InputNew
                                        isDense
                                        className="zip-input"
                                        submitKey={`conversions.${id}-${conversion.id}.serviceValue`}
                                        onInput={limitInput}
                                        onChange={(newRate) => handleGroupRateValueChange(id, conversion.id, newRate, false)}
                                        type="number"
                                    />
                                </div>
                            )}
                            {!conversion.serviceValueAreEquals && (
                                <>
                                    <WrappedFormattedMessage content={translated.rules.dynamicRanges.rangeConfiguration.variable} />
                                    <Button
                                        className="margin-left-xsmall"
                                        size="small"
                                        id={`range-${id}-variable`}
                                        onClick={() => handleServiceVariableOnClick(id, conversion.id)}
                                        icon="Pencil"
                                        color="primary"
                                    />
                                </>
                            )}
                        </>
                    ),
                },
            ]}
            rows={{
                subTable: {
                    id      : 'balanceTypes',
                    key     : 'balanceTypes',
                    columns : [
                        {
                            title     : translated.rules.types.balanceType,
                            key       : 'name',
                            isVisible : true,
                        },
                        {
                            title                : translated.rules.dynamicRanges.rangeConfiguration.subtitles.roomRate,
                            key                  : `${id}-currency-balance-types-room-rate`,
                            isVisible            : true,
                            isNumeric            : true,
                            customClassName      : 'table-input-field padding-remove',
                            customFieldComponent : (conversion) => (
                                <div>
                                    <Form.InputNew
                                        className="zip-input"
                                        type="number"
                                        submitKey={`conversions.${id}-${conversion.currency}-${conversion.id}.roomValue`}
                                        onInput={limitInput}
                                        onChange={(newValue) => rateOnChange(id, conversion.currency, conversion.id, newValue, true)}
                                        isDense
                                    />
                                </div>
                            ),
                        },
                        {
                            title                : translated.rules.dynamicRanges.rangeConfiguration.subtitles.serviceRate,
                            key                  : `${id}-currency-balance-types-service-rate`,
                            isVisible            : true,
                            isNumeric            : true,
                            customClassName      : 'table-input-field padding-remove',
                            customFieldComponent : (conversion) => (
                                <div>
                                    <Form.InputNew
                                        className="zip-input"
                                        type="number"
                                        submitKey={`conversions.${id}-${conversion.currency}-${conversion.id}.serviceValue`}
                                        onInput={limitInput}
                                        onChange={(newValue) => rateOnChange(id, conversion.currency, conversion.id, newValue, false)}
                                        isDense
                                    />
                                </div>
                            ),
                        },
                    ],
                },
            }}
            items={items}
            canCollapse
        />
    );
}

ConversionCard.defaultProps = { currencies: [] };

ConversionCard.propTypes = {
    range      : PropTypes.shape({}).isRequired,
    currencies : PropTypes.arrayOf(PropTypes.shape({})),
};

export default ConversionCard;
