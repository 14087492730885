import { mdiFileTree } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const attachmentCategoryId = null;

export default {
    [sectionType.CATEGORIES]: {
        id        : 'categories',
        isEnabled : false,
        config    : { url: '/panel/categories', icon: mdiFileTree },
        sections  : {
            [sectionType.CATEGORIES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.CATEGORIES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.CATEGORIES_EDITOR]: {
                        ids       : { attachmentCategoryId, self: 'attachmentCategoryId' },
                        resources : { templates: { name: resourceNames.CATEGORY } },
                        isRoot    : true,
                        data      : {},
                        config    : {
                            isHiddenInBreadcrumb : true,
                            url                  : { withId: '/(\\d+)', withoutId: '/add' },
                            isModal              : true,
                        },
                    },
                },
            },
        },
    },
};
