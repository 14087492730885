import React from 'react';

const DropdownContext = React.createContext({
    isOpen    : false,
    setIsOpen : () => {
        // Default
    },
    shouldCloseOnChildClick: true,
});

export default DropdownContext;
