import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import DropdownContext from './dropdownContext';
import PanelContext from 'State/panelContext';

function Option({
    itemKey, isEnabled, onClick, className, children, id, shouldAvoidCloseOnClick, confirmationProps,
}) {
    const { confirmation } = useContext(PanelContext);

    const dropdownItemClass = getClassName({ isDisabled: !(isEnabled || isEnabled === undefined) }, 'dropdown-menu-item', className);

    const { setIsOpen, shouldCloseOnChildClick } = useContext(DropdownContext);

    const handleOnCLick = () => {
        if (shouldCloseOnChildClick && !shouldAvoidCloseOnClick) {
            setIsOpen(false);
        }

        if (confirmationProps) {
            confirmation.show({
                title         : confirmationProps.title,
                message       : confirmationProps.message,
                messageValues : confirmationProps.messageValues,
                acceptMessage : confirmationProps.acceptMessage,
                onAccept      : async () => {
                    onClick();
                },
            });
        } else {
            onClick();
        }
    };
    return (
        <li className={dropdownItemClass} onClick={handleOnCLick} key={itemKey} id={id}>
            {children}
        </li>
    );
}

Option.defaultProps = {
    className : '',
    isEnabled : true,
    onClick   : () => {
        // Default
    },
    id                      : '',
    shouldAvoidCloseOnClick : false,
    confirmationProps       : null,
};

Option.propTypes = {
    children                : PropTypes.node.isRequired,
    itemKey                 : PropTypes.string.isRequired,
    className               : PropTypes.string,
    isEnabled               : PropTypes.bool,
    onClick                 : PropTypes.func,
    id                      : PropTypes.string,
    shouldAvoidCloseOnClick : PropTypes.bool,
    confirmationProps       : PropTypes.shape({}),
};
export default Option;
