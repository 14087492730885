import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Error(props) {
    const { status, errorMessage } = props;

    return (
        <div className="error-page">
            <h1 className="error-code">{status}</h1>
            <img className="rocket" src="/static/img/rocket.svg" alt="rocket" />
            <img className="moon" src="/static/img/moon.svg" alt="moon" />
            <img className="astronaut" src="/static/img/astronaut.svg" alt="astronaut" />
            <h4 className="error-message">
                <WrappedFormattedMessage content={errorMessage} />
            </h4>
        </div>
    );
}

Error.getInitialProps = async ({ req, res }) => {
    const { query } = req;

    if (res.statusCode === 404) {
        return { status: 404, errorMessage: 'Page not found' };
    }

    const status = query ? query.statusCode : null;
    const errorMessage = query ? query.errorMessage : '';
    return { status, errorMessage };
};

Error.defaultProps = { status: null, errorMessage: null };

Error.propTypes = {
    status       : PropTypes.number,
    errorMessage : PropTypes.string,
};

export default Error;
