import { mdiAccountMultiple } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const agentId = null;

export default {
    [sectionType.AGENTS]: {
        config    : { url: '/panel/agents', icon: mdiAccountMultiple },
        isEnabled : false,
        sections  : {
            [sectionType.AGENTS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.AGENTS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.AGENTS_EDITOR]: {
                ids       : { agentId, self: 'agentId' },
                resources : { templates: { name: resourceNames.AGENT } },
                config    : { url: { withId: '/(\\d+)', withoutId: '/add' } },
            },
        },
    },
};
