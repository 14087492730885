import { mdiDomain } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const clubId = null;
const clubTierId = null;
const contractTypeId = null;
const ruleId = null;

export default {
    [sectionType.CLUBS]: {
        config    : { url: '/panel/clubs', icon: mdiDomain },
        isEnabled : false,
        sections  : {
            [sectionType.CLUBS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.CLUBS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.CLUBS_EDITOR]: {
                config   : { isHiddenInBreadcrumb: true },
                sections : {
                    [sectionType.CLUBS_GENERAL]: {
                        ids       : { clubId, self: 'clubId' },
                        resources : { templates: { name: resourceNames.CLUB } },
                        config    : {
                            url              : { withId: '/(\\d+)', withoutId: '/add' },
                            isDefaultSection : true,
                        },
                        sections: {
                            [sectionType.CLUBS_TIER_EDITOR]: {
                                ids       : { clubTierId, self: 'clubTierId' },
                                resources : { templates: { name: resourceNames.CLUB_TIER } },
                                config    : {
                                    url                        : { withId: '/tiers/(\\d+)', withoutId: '/tiers/add' },
                                    hasTypePreffixInBreadcrumb : true,
                                    requestTimeout             : 40000,
                                },
                                sections: {
                                    [sectionType.CONVERSION_RULES]: {
                                        ids        : { clubTierId },
                                        config     : { resourceName: resourceNames.WALLET_CONFIG },
                                        isEmbedded : true,
                                        sections   : {
                                            [sectionType.CONVERSION_RULE_EDITOR]: {
                                                config: {
                                                    isHiddenInBreadcrumb : true,
                                                    isModal              : true,
                                                },
                                            },
                                        },
                                    },
                                    [sectionType.CONVERSION_RULES_ALT]: {
                                        ids        : { clubTierId },
                                        config     : { resourceName: resourceNames.CONVERSION_RULES_ALT },
                                        isEmbedded : true,
                                    },
                                    [sectionType.CONVERSION_RULE_EDITOR_ALT] : { config: { isHiddenInBreadcrumb: true } },
                                    [sectionType.RULES]                      : {
                                        ids        : { clubTierId },
                                        config     : { resourceName: resourceNames.RULES },
                                        isEmbedded : true,
                                    },
                                    [sectionType.RULE]: {
                                        ids       : { clubTierId, ruleId, self: 'ruleId' },
                                        resources : { templates: { name: resourceNames.CLUB_TIER_RULE } },
                                        config    : {
                                            url                        : { withId: '/rules/(\\d*-\\w*)*', withoutId: '/rules/add' },
                                            combinedIdKey              : 'type',
                                            resourceName               : resourceNames.RULE,
                                            hasTypePreffixInBreadcrumb : true,
                                            requestTimeout             : 40000,
                                        },
                                    },
                                    [sectionType.CONTRACT_TYPES]: {
                                        config     : { resourceName: resourceNames.CONTRACT_TYPES },
                                        isEmbedded : true,
                                    },
                                    [sectionType.CONTRACT_TYPE]: {
                                        ids       : { clubTierId, contractTypeId, self: 'contractTypeId' },
                                        resources : { templates: { name: resourceNames.CLUB_TIER_CONTRACT_TYPE } },
                                        config    : {
                                            url                        : { withId: '/contractTypes/(\\d*)', withoutId: '/contractTypes/add' },
                                            hasTypePreffixInBreadcrumb : true,
                                        },
                                        sections: {
                                            [sectionType.RULES]: {
                                                ids        : { clubTierId },
                                                config     : { resourceName: resourceNames.RULES },
                                                isEmbedded : true,
                                            },
                                            [sectionType.RULE]: {
                                                ids       : { clubTierId, contractTypeId, ruleId, self: 'ruleId' },
                                                resources : { templates: { name: resourceNames.CLUB_TIER_CONTRACT_TYPE_RULE } },
                                                config    : {
                                                    url                        : { withId: '/rules/(\\d*-\\w*)*', withoutId: '/rules/add' },
                                                    resourceName               : resourceNames.RULE,
                                                    combinedIdKey              : 'type',
                                                    hasTypePreffixInBreadcrumb : true,
                                                    requestTimeout             : 40000,
                                                },
                                            },
                                            [sectionType.CONVERSION_RULES]: {
                                                ids        : { clubTierId },
                                                config     : { resourceName: resourceNames.WALLET_CONFIG },
                                                isEmbedded : true,
                                                sections   : {
                                                    [sectionType.CONVERSION_RULE_EDITOR]: {
                                                        config: {
                                                            isHiddenInBreadcrumb : true,
                                                            isModal              : true,
                                                        },
                                                    },
                                                },
                                            },
                                            [sectionType.CONVERSION_RULES_ALT]: {
                                                ids        : { clubTierId },
                                                config     : { resourceName: resourceNames.CONVERSION_RULES_ALT },
                                                isEmbedded : true,
                                            },
                                            [sectionType.CONVERSION_RULE_EDITOR_ALT]: { config: { isHiddenInBreadcrumb: true } },
                                        },
                                    },
                                    [sectionType.BANKING_CONFIGURATIONS]: {
                                        ids        : { clubTierId },
                                        config     : { resourceName: resourceNames.BANKING_CONFIGURATIONS },
                                        isEmbedded : true,
                                    },
                                },
                            },
                            [sectionType.CLUBS_TIERS]: {
                                ids        : { clubId },
                                config     : { resourceName: resourceNames.CLUB_TIERS },
                                isEmbedded : true,
                            },
                            [sectionType.CLUBS_BALANCE_TYPES]: {
                                ids        : { clubId },
                                config     : { resourceName: resourceNames.CLUB_BALANCE_TYPES },
                                isEmbedded : true,
                            },
                            [sectionType.ENTITY_TAGS]: {
                                ids             : { clubId },
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                        },
                    },
                },
            },
        },
    },
};
