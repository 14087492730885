import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import getClassName from 'Utils/getClassName';
import Button from 'Components/Button';
import Dropdown from 'Components/Dropdown';
import Form from 'Components/Form';
import Loading from 'Components/Loading';
import Resource from 'Classes/Resource';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import Alert from 'Components/Alert';

function Tools(props) {
    const {
        title,
        numberOfCheckedElements,
        bulkActions,
        canCollapse,
        canViewColumns,
        canChangeSettings,
        onFilter,
        setIsCollapsed,
        isCollapsed,
        fields,
        handleOnFieldCheck,
        isDense,
        setIsDense,
        hasFixedColumns,
        setFixedColumns,
        fixedColumns,
        onFixedColumnsChange,
        tableId,
        filterResources,
        usedOrder,
        usedPagination,
        appliedFilters,
        onApplyFilter,
        enableBulkActionsButton,
        headerActions,
    } = props;

    const intl = useIntl();
    /*
     * submitDataConverter: function that converts the data shown in the form before the submit
     * onReset: isTriggered when the filter's reset is clicked
     * onInit: isTriggered when the user leaves the page and returns with filters loaded
     */
    const {
        filterLinks, filters, supportText, showDropdown, submitDataConverter, onReset, onInit: onFilterInit,
    } = filterResources || {};

    let resourceForFilter;

    if (filterLinks?.query) {
        resourceForFilter = new Resource({ config: filterLinks.query });
    }

    const [disabledOnLimit, setDisabledOnLimit] = useState({ min: true, max: false });
    const [isLoading, setIsLoading] = useState({ apply: false, reset: false });
    const handleCollapsedClick = () => setIsCollapsed(!isCollapsed);

    const dropDownRef = useRef();

    useEffect(() => {
        if (onFilterInit && appliedFilters?.filters) {
            onFilterInit(appliedFilters?.filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const supportTextTranslated = useMemo(() => getMessage(intl, supportText), [intl, supportText]);

    const onClickMinusFixedColumn = () => {
        const newValue = fixedColumns - 1;
        if (newValue > 0) {
            onFixedColumnsChange(newValue);
            setDisabledOnLimit({ ...disabledOnLimit, max: false });
        } else {
            setDisabledOnLimit({ ...disabledOnLimit, min: true });
        }
    };

    const onClickPlusFixedColumn = () => {
        const newValue = fixedColumns + 1;
        const maxColumn = fields.filter((field) => field.isVisible).length;
        if (newValue <= maxColumn) {
            onFixedColumnsChange(newValue);
            setDisabledOnLimit({ ...disabledOnLimit, min: false });
        } else {
            setDisabledOnLimit({ ...disabledOnLimit, max: true });
        }
    };

    const toolsClassName = getClassName({ tableToolsBulk: bulkActions, tableToolsCollapsible: canCollapse }, 'table-tools');
    const quantityItemClassName = `quantity-menu-item ${hasFixedColumns ? 'is-visible' : ''}`;

    const hasToRenderFilterDropdown = showDropdown || filters?.length > 1;

    const renderFilters = () => filters?.map((filter) => {
        let options = [];
        if (filter.type === 'select' || filter.type === 'selectWithFilter') {
            options = filter?.options?.map((each) => ({ value: each.id, content: each.name }));
        }

        if (hasToRenderFilterDropdown) {
            // Renders the input for the dropdown, with the selectable and without the icon.
            return (
                <Form.Input
                    id={filter.id}
                    submitKey={filter.submitKey}
                    isSelectable
                    isDense
                    type={filter.type}
                    toggleLabel={filter.label}
                    options={filter.type === 'select' || filter.type === 'selectWithFilter' ? options : null}
                    validations={filter.validations ? filter.validations : {}}
                    isFullReset
                    key={`filter-input-${filter.submitKey}`}
                    defaultOptionText={filter.defaultOptionText}
                    onChange={filter.onChange || null}
                    onSwitch={filter.onSwitch || null}
                    filterLink={filter.type === 'selectWithFilter' ? filter.filterLink : undefined}
                    filterFunction={filter.type === 'selectWithFilter' ? filter.filterFunction : undefined}
                    showLoading={filter.showLoading}
                    searchValidation={filter.searchValidation}
                    searchFunction={filter.searchFunction}
                    renderSearch={filter.renderSearch}
                    onSearchStart={filter.onSearchStart}
                    onSearchEnd={filter.onSearchEnd}
                    onSearchSelect={filter.onSearchSelect}
                    onChangeKey={filter.onChangeKey}
                    icon={filter.icon}
                    inputValue={
                        filter.type === 'search' && appliedFilters?.filters?.[`${filter.submitKey}Label`]
                            ? appliedFilters.filters[`${filter.submitKey}Label`]
                            : null
                    }
                />
            );
        }

        // Renders the input used in the table header, without the selectable and with an icon.
        return (
            <Form.Input
                isFullReset
                isDense
                id={filter.id}
                submitKey={filter.submitKey}
                type={filter.type}
                label={filter.label}
                options={filter.type === 'select' || filter.type === 'selectWithFilter' ? options : null}
                validations={filter.validations ? filter.validations : {}}
                searchValidation={filter.searchValidation}
                searchFunction={filter.searchFunction}
                renderSearch={filter.renderSearch}
                key={`filter-input-${filter.submitKey}`}
                icon={filter.icon || { name: 'Magnify' }}
                defaultOptionText={filter.defaultOptionText}
                className={filter.className}
                onChange={filter.onChange || null}
                onChangeKey={filter.onChangeKey}
                inputValue={
                    filter.type === 'search' && appliedFilters?.filters?.[`${filter.submitKey}Label`]
                        ? appliedFilters.filters[`${filter.submitKey}Label`]
                        : null
                }
            />
        );
    });

    const handleOnClickFixedColumns = () => {
        const updateHasFixedColumns = !hasFixedColumns;
        setFixedColumns(updateHasFixedColumns);
        const updatedValue = !updateHasFixedColumns ? 0 : 1;
        onFixedColumnsChange(updatedValue);

        if (updateHasFixedColumns) {
            // Reset the validations for the freeze columns 'quantity' field
            setDisabledOnLimit({
                min : updatedValue <= 1,
                max : updatedValue >= fields.filter((field) => field.isVisible).length,
            });
        }
    };

    const handleOnSubmit = async (formData) => {
        if (dropDownRef?.current?.close) {
            dropDownRef.current.close();
        }
        setIsLoading((prevState) => ({ ...prevState, apply: true }));

        const linkForRequest = resourceForFilter.getLinkAndUrlWithQueryParams({
            fields      : filterLinks.fields,
            values      : submitDataConverter?.(formData.values) || formData.values,
            extraParams : [usedOrder, usedPagination],
        });

        const convertedFilters = Object.values(formData?.values || {})?.find((e) => e != null) ? formData.values : null;

        if (convertedFilters) {
            Object.entries(convertedFilters).forEach(([eachKey, eachValue]) => {
                if (eachValue) {
                    const filterRawValues = formData?.rawValues?.[eachKey];
                    if (filterRawValues?.type === 'search' && filterRawValues.optionLabel) {
                        // For the search inputs we add the selected element name, so its saved on the app state.
                        convertedFilters[`${eachKey}Label`] = filterRawValues.optionLabel;
                    }
                }
            });
        }

        const appliedQueryParams = linkForRequest.url.split('?')[1];

        await onApplyFilter({ ...linkForRequest }, { appliedFilters: { filters: convertedFilters, reset: false, queryParams: appliedQueryParams } });
        setIsLoading((prevState) => ({ ...prevState, apply: false }));
    };

    const handleOnResetFilters = async () => {
        if (dropDownRef?.current?.close) {
            dropDownRef.current.close();
        }

        if (onReset) {
            onReset();
        }

        setIsLoading((prevState) => ({ ...prevState, reset: true }));

        const linkForRequest = resourceForFilter.getLinkAndUrlWithQueryParams({ extraParams: [usedOrder, usedPagination] });
        const reqConfig = { ...linkForRequest };
        await onApplyFilter(reqConfig, { appliedFilters: { filters: null, reset: true } });
        setIsLoading((prevState) => ({ ...prevState, reset: false }));
    };

    return (
        <>
            {(isLoading.reset || isLoading.apply) && <Loading />}
            <div className={toolsClassName}>
                {title && !(numberOfCheckedElements > 0) && (
                    <h6 className="table-title" id={`${tableId}-table-title`}>
                        <WrappedFormattedMessage content={title} />
                        <div>
                            {enableBulkActionsButton}
                            {headerActions}
                        </div>
                    </h6>
                )}

                <div className="table-bulk-actions">
                    {bulkActions && numberOfCheckedElements > 0 && (
                        <>
                            {bulkActions.map((action) => (
                                <Button
                                    id={`${tableId}-${action.key}`}
                                    variant="text"
                                    color="primary"
                                    onClick={action.callback}
                                    key={`${tableId}-${action.key}`}
                                >
                                    <WrappedFormattedMessage content={action.content} />
                                </Button>
                            ))}
                            <span className="table-selected-items">
                                {numberOfCheckedElements}
                                &nbsp;
                                <FormattedMessage id={translated.global.table.selectedItems} defaultMessage={translated.global.table.selectedItems} />
                            </span>
                        </>
                    )}
                </div>

                <div className="table-tools-actions">
                    {filters && hasToRenderFilterDropdown && (
                        <div className="filter-dropdown-wrapper margin-right-xsmall">
                            <Dropdown
                                id={`${tableId}-search`}
                                classNameMenu="filter-dropdown"
                                className="table-filter"
                                icon="FilterOutline"
                                variant="text"
                                color="grey"
                                size="small"
                                label={intl.formatMessage({
                                    id             : translated.global.table.buttons.filter,
                                    defaultMessage : translated.global.table.buttons.filter,
                                })}
                                ref={dropDownRef}
                            >
                                <Form.Wrapper>
                                    <Form
                                        shouldSubmitOnEnter
                                        onClear={handleOnResetFilters}
                                        initialValues={appliedFilters?.filters}
                                        shouldAvoidResetInitialState
                                        avoidSetComponentWithChanges
                                        allFieldsRequired
                                        id={`${tableId}-filter`}
                                        onSubmit={handleOnSubmit}
                                    >
                                        {renderFilters()}
                                    </Form>
                                    <div className="text-align-right padding-xsmall">
                                        <Form.Clear
                                            id={`${tableId}-filter-clear`}
                                            enabledOnInit={!!appliedFilters?.filters}
                                            isLoading={isLoading.reset}
                                            variant="text"
                                            color="primary"
                                            key="fr"
                                            size="small"
                                        >
                                            <FormattedMessage id={translated.global.buttons.clear} defaultMessage={translated.global.buttons.clear} />
                                        </Form.Clear>
                                        <Form.Primary id={`${tableId}-filter-apply`} variant="text" color="primary" key="fp" size="small">
                                            <FormattedMessage id={translated.global.buttons.apply} defaultMessage={translated.global.buttons.apply} />
                                        </Form.Primary>
                                    </div>
                                </Form.Wrapper>
                            </Dropdown>
                            {appliedFilters?.filters && <div className="filter-mark" />}
                        </div>
                    )}

                    {filters && !hasToRenderFilterDropdown && (
                        <div className="filter-dropdown-wrapper margin-right-xsmall">
                            <Dropdown
                                id={`${tableId}-filter-open`}
                                variant="text"
                                color="grey"
                                key="ff"
                                icon="TableSearch"
                                size="small"
                                label={translated.global.table.searchBy}
                                classNameMenu="search-by-dropdown"
                                className="table-filter-search"
                                ref={dropDownRef}
                            >
                                <Form.Wrapper>
                                    <div className="search-by-dropdown-item">
                                        {supportText && (
                                            <Alert content={supportTextTranslated} isBasic withoutTitle className="margin-bottom-xsmall" />
                                        )}
                                        <Form
                                            shouldSubmitOnEnter
                                            onClear={handleOnResetFilters}
                                            initialValues={appliedFilters?.filters}
                                            shouldAvoidResetInitialState
                                            avoidSetComponentWithChanges
                                            id={`${tableId}-filter`}
                                            onSubmit={handleOnSubmit}
                                        >
                                            {renderFilters()}
                                        </Form>
                                    </div>
                                    <div className="text-align-right padding-xsmall">
                                        <Form.Clear
                                            id={`${tableId}-filter-clear`}
                                            enabledOnInit={!!appliedFilters?.filters}
                                            isLoading={isLoading.reset}
                                            variant="text"
                                            color="primary"
                                            key="fr"
                                            size="small"
                                        >
                                            <FormattedMessage id={translated.global.buttons.clear} defaultMessage={translated.global.buttons.clear} />
                                        </Form.Clear>
                                        <Form.Primary id={`${tableId}-filter-apply`} variant="text" color="primary" key="fp" size="small">
                                            <FormattedMessage id={translated.global.buttons.apply} defaultMessage={translated.global.buttons.apply} />
                                        </Form.Primary>
                                    </div>
                                </Form.Wrapper>
                            </Dropdown>
                            {appliedFilters?.filters && <div className="filter-mark" />}
                        </div>
                    )}

                    {canViewColumns && (
                        <Dropdown
                            id={`${tableId}-view`}
                            classNameMenu="view-columns-dropdown"
                            icon="ViewColumnOutline"
                            onClick={onFilter}
                            variant="text"
                            color="grey"
                            size="small"
                            label={intl.formatMessage({
                                id             : translated.global.table.buttons.viewColumns,
                                defaultMessage : translated.global.table.buttons.viewColumns,
                            })}
                            shouldCloseOnChildClick={false}
                        >
                            {fields.map((field, index) => {
                                const isDisabled = index < fixedColumns;
                                return (
                                    <Dropdown.Option
                                        key={field.key}
                                        itemKey={field.key}
                                        onClick={() => !isDisabled && handleOnFieldCheck(field.key, !field.isVisible)}
                                    >
                                        <Form.Input
                                            id={`${tableId}-view-${field.key}`}
                                            type="checkbox"
                                            label={field.title}
                                            key={field.key}
                                            value={field.isVisible}
                                            isDisabled={isDisabled}
                                        />
                                    </Dropdown.Option>
                                );
                            })}
                        </Dropdown>
                    )}

                    {canChangeSettings && (
                        <Dropdown
                            id={`${tableId}-settings`}
                            classNameMenu="settings-dropdown"
                            icon="TableSettings"
                            variant="text"
                            color="grey"
                            size="small"
                            label={intl.formatMessage({
                                id             : translated.global.table.buttons.settings,
                                defaultMessage : translated.global.table.buttons.settings,
                            })}
                        >
                            <Dropdown.Option
                                id={`${tableId}-settings-dense`}
                                onClick={() => setIsDense(!isDense)}
                                itemKey="option-is-dense"
                                key="option-is-dense"
                            >
                                <Form.Input type="checkbox" label={translated.global.table.buttons.denseContent} value={isDense} />
                            </Dropdown.Option>
                            <Dropdown.Option
                                id={`${tableId}-settings-freeze`}
                                onClick={() => handleOnClickFixedColumns()}
                                itemKey="option-fixed-columns"
                                key="option-fixed-columns"
                                shouldAvoidCloseOnClick
                            >
                                <Form.Input type="checkbox" label={translated.global.table.buttons.freezeColumns} value={hasFixedColumns} />
                            </Dropdown.Option>
                            <Dropdown.Option className={quantityItemClassName} itemKey="option-quantity" key="option-quantity" shouldAvoidCloseOnClick>
                                <Form.Input
                                    id={`${tableId}-settings-amount`}
                                    type="quantity"
                                    label={translated.global.table.quantity}
                                    value={fixedColumns}
                                    onClickMinus={onClickMinusFixedColumn}
                                    onClickPlus={onClickPlusFixedColumn}
                                    minDisabled={disabledOnLimit.min}
                                    maxDisabled={disabledOnLimit.max}
                                    isDense
                                    submitKey={`${tableId}-quantity`}
                                    avoidOnSubmit
                                />
                            </Dropdown.Option>
                        </Dropdown>
                    )}

                    {canCollapse && (
                        <Button
                            id={`${tableId}-collapse-button`}
                            key={`${tableId}-collapse-button`}
                            icon="ChevronUp"
                            className={getClassName({ isCollapsed }, 'table-collapse-button')}
                            onClick={handleCollapsedClick}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

Tools.defaultProps = {
    title                   : '',
    numberOfCheckedElements : 0,
    bulkActions             : [],
    handleOnFieldCheck      : () => {
        // Default
    },
    canCollapse       : false,
    canViewColumns    : false,
    canChangeSettings : false,
    isCollapsed       : false,
    isDense           : false,
    onFilter          : () => {
        // Default
    },
    setIsCollapsed: () => {
        // Default
    },
    setIsDense: () => {
        // Default
    },
    hasFixedColumns : false,
    setFixedColumns : () => {
        // Default
    },
    fixedColumns         : 0,
    onFixedColumnsChange : () => {
        // Default
    },
    filterResources         : null,
    usedOrder               : null,
    usedPagination          : null,
    appliedFilters          : null,
    onApplyFilter           : null,
    enableBulkActionsButton : null,
    headerActions           : [],
};

Tools.propTypes = {
    tableId                 : PropTypes.string.isRequired,
    title                   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    numberOfCheckedElements : PropTypes.number,
    bulkActions             : PropTypes.arrayOf(PropTypes.shape({})),
    headerActions           : PropTypes.arrayOf(PropTypes.shape({})),
    fields                  : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleOnFieldCheck      : PropTypes.func,
    canCollapse             : PropTypes.bool,
    canViewColumns          : PropTypes.bool,
    canChangeSettings       : PropTypes.bool,
    isCollapsed             : PropTypes.bool,
    isDense                 : PropTypes.bool,
    setIsDense              : PropTypes.func,
    onFilter                : PropTypes.func,
    setIsCollapsed          : PropTypes.func,
    hasFixedColumns         : PropTypes.bool,
    setFixedColumns         : PropTypes.func,
    fixedColumns            : PropTypes.number,
    onFixedColumnsChange    : PropTypes.func,
    filterResources         : PropTypes.shape({}),
    usedOrder               : PropTypes.string,
    usedPagination          : PropTypes.string,
    appliedFilters          : PropTypes.shape({}),
    onApplyFilter           : PropTypes.func,
    enableBulkActionsButton : PropTypes.shape({}),
};

export default Tools;
