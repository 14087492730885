export default function isBankingConfigurationValid(bankingConfiguration, begin, end, dateManager) {
    if (
        !bankingConfiguration.balanceType?.id
        || (bankingConfiguration.repeat && (!bankingConfiguration.repeatAnniversary))
        || (!bankingConfiguration.repeat && (!bankingConfiguration.effectiveDate || !bankingConfiguration.expiryDate))
        || bankingConfiguration.amount == null
    ) {
        return false;
    }

    // We validate the format of the dates received
    if (
        // Validate the effectiveDate and expiryDate for non-repeated bankings
        (!bankingConfiguration.repeat
            && (
                (bankingConfiguration.effectiveDate && !dateManager.isValidDate(bankingConfiguration.effectiveDate))
                || (bankingConfiguration.expiryDate && !dateManager.isValidDate(bankingConfiguration.expiryDate))
            )
        )
        // Validate the repeatAnniversary for repeated bankings
        || (bankingConfiguration.repeat && bankingConfiguration.repeatAnniversary && !dateManager.isValidDate(bankingConfiguration.repeatAnniversary))

    ) {
        return false;
    }

    if (begin && end) {
        if (!bankingConfiguration.repeat
            && (!dateManager.isBetweenEqual(bankingConfiguration.effectiveDate, begin, end, 'days')
            || !dateManager.isBetweenEqual(bankingConfiguration.expiryDate, bankingConfiguration.effectiveDate, end, 'days'))
        ) {
            return false;
        }

        if (bankingConfiguration.repeat && !dateManager.isBetweenEqual(bankingConfiguration.repeatAnniversary, begin, end, 'days')) {
            return false;
        }
    }

    return true;
}
