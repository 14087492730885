import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Checkbox({
    className, label, onChange, onBlur, onFocus, value, isDisabled, id, isReadOnlyWithoutInputs, name, innerInputRef,
}) {
    const context = useFormContext();
    const { setValue, control, watch } = context || {};
    const updatedValue = watch(name);

    const handleOnChange = useCallback(() => {
        const newValue = !updatedValue;

        if (onChange) {
            onChange(newValue);
        }

        setValue(name, newValue, { shouldDirty: true, shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedValue]);

    const handleOnBlur = useCallback((props) => {
        // We dont use the hook 'onBlur' because it messes with the select value.

        if (onBlur) {
            onBlur(props);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLabelOnClick = useCallback((e) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        handleOnChange();
    }, [handleOnChange]);

    const handleLabelOnFocus = useCallback((e) => {
        if (onFocus) {
            onFocus(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If we do not handle onChange event inside this component, we need to avoid the propagation of
    // the onClick event produced by label, which is going to trigger a new onClick event inside
    // the element that actually handles the onChange.
    if (isReadOnlyWithoutInputs) {
        return (
            <>
                {label && (
                    <label id={`${id}-label`} className="label">
                        <WrappedFormattedMessage content={label} />
                    </label>
                )}
                <span id={id}>
                    {value ? (
                        <FormattedMessage id={translated.global.state.yes} defaultMessage={translated.global.state.yes} />
                    ) : (
                        <FormattedMessage id={translated.global.state.no} defaultMessage={translated.global.state.no} />
                    )}
                </span>
            </>
        );
    }

    return (
        <label
            id={`${id}-label`}
            onClick={handleLabelOnClick}
            onFocus={handleLabelOnFocus}
            onBlur={handleOnBlur}
            onMouseOver={handleLabelOnFocus}
            onMouseOut={handleOnBlur}
        >
            <div className={`checkbox ${className}`}>
                <Controller
                    render={
                        ({ field }) => {
                            if (innerInputRef && !innerInputRef.current) {
                                // eslint-disable-next-line no-param-reassign
                                innerInputRef.current = field.ref;
                            }

                            return (
                                <input
                                    id={id}
                                    type="checkbox"
                                    tabIndex={isDisabled ? '-1' : '0'}
                                    {...field}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                    checked={field.value}
                                />
                            );
                        }
                    }
                    control={control}
                    name={name}
                />

                <span />
            </div>
            {label && (
                <span>
                    <WrappedFormattedMessage content={label} />
                </span>
            )}
        </label>
    );
}

Checkbox.defaultProps = {
    id                      : '',
    className               : '',
    label                   : '',
    onChange                : null,
    onFocus                 : null,
    onBlur                  : null,
    value                   : false,
    isDisabled              : false,
    isReadOnlyWithoutInputs : false,
    innerInputRef           : null,
};

Checkbox.propTypes = {
    id                      : PropTypes.string,
    className               : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    value                   : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    name                    : PropTypes.string.isRequired,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Checkbox);
