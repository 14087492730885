import * as types from 'State/types/app';
import * as panelTypes from 'State/types/panel';
import { localStorage } from 'Services';
import { errorService } from 'Utils';

const initialState = {};

// Ids of the snackbar elements.
const SNACKBAR_NOTIFICATION_ID = 'NOTIFICATION-';

const getSnackbarIncrementalId = (id) => {
    const [key, oldId] = id.split('-');
    return `${key}-${Number(oldId) + 1}`;
};

const isNotificationSnackbar = (id) => id && id.startsWith(SNACKBAR_NOTIFICATION_ID);

const appReducer = (state = initialState, action = { type: null }) => {
    switch (action.type) {
        case types.TITLE_SET:
            return { ...state, title: action.payload };

        case types.SET_LANGUAGE:
            localStorage.saveState('language', action.payload);
            return { ...state, language: action.payload };

        case types.SIDE_DRAWER_VISIBLE_TOGGLE:
            localStorage.saveState('isSideDrawerVisible', !state.isSideDrawerVisible);
            return { ...state, isSideDrawerVisible: !state.isSideDrawerVisible };

        case types.SIDE_DRAWER_COLLAPSE_TOGGLE:
            localStorage.saveState('isSideDrawerCollapsed', !state.isSideDrawerCollapsed);
            return { ...state, isSideDrawerCollapsed: !state.isSideDrawerCollapsed };

        case types.DARK_MODE_TOGGLE:
            localStorage.saveState('isDarkModeEnabled', !state.isDarkModeEnabled);
            return { ...state, isDarkModeEnabled: !state.isDarkModeEnabled };

        case types.BREADCRUMB_TABS_TOGGLE:
            return { ...state, areBreadcrumbTabsVisible: !state.areBreadcrumbTabsVisible };

        case types.APP_ERROR_STATE: {
            const { title, status = null, isCritical = false, shouldRefreshUser = false } = action.payload;
            return {
                ...state,
                error: { title, status, isCritical, shouldRefreshUser, mustShow: true },
            };
        }

        case types.USER_SHOULD_REFRESH: {
            return { ...state, error: { ...state.error, shouldRefreshUser: action.payload } };
        }

        case types.APP_ERROR_CLEAR:
            const { error } = state;
            return { ...state, error: { ...error, mustShow: false } };

        case types.USE_MOCK_TOGGLE:
            localStorage.saveState('useMock', !state.useMock);
            return { ...state, useMock: !state.useMock };

        case types.IS_LOCAL_STORAGE_LOADED_TOGGLE:
            return { ...state, isLocalStorageLoaded: !state.isLocalStorageLoaded };

        case types.APP_STATE_SET:
            return {
                ...state,
                isSideDrawerVisible   : action.payload.isSideDrawerVisible,
                isSideDrawerCollapsed : action.payload.isSideDrawerCollapsed,
                isDarkModeEnabled     : action.payload.isDarkModeEnabled,
                isTourEnabled         : action.payload.isTourEnabled,
                useMock               : action.payload.useMock,
                isLocalStorageLoaded  : action.payload.isLocalStorageLoaded,
                language              : action.payload.language,
            };

        case types.SNACKBAR_SHOW:
            const { error: snackbarError, errorLabels, content, isNotification, ...snackBarProps } = action.payload;

            const { snackbar: oldSnackbar } = state;
            const newSnackbar = {};

            const errorMessage = errorService.getRequestErrorMessage(snackbarError, errorLabels, content);

            if (isNotification) {
                newSnackbar.notification = {
                    ...oldSnackbar.notification,
                    nextId   : getSnackbarIncrementalId(oldSnackbar.notification.nextId),
                    elements : [
                        ...oldSnackbar.notification.elements,
                        {
                            ...snackBarProps,
                            // Assign a new id for the new notification
                            id      : oldSnackbar.notification.nextId,
                            content : errorMessage,
                        },
                    ],
                };
            } else {
                newSnackbar.system = {
                    ...snackBarProps,
                    isVisible : true,
                    // Assign a new id for the new notification
                    id        : getSnackbarIncrementalId(oldSnackbar.system.id),
                    content   : errorMessage,
                };
            }

            return {
                ...state,
                snackbar: {
                    ...oldSnackbar,
                    ...newSnackbar,
                },
            };

        case types.SNACKBAR_HIDE:
            const { id: snackbarToHide = null } = action.payload;

            const isTypeNotification = isNotificationSnackbar(snackbarToHide);

            const { snackbar: prevSnackbar } = state;
            const updatedSnackbar = {};

            if (isTypeNotification) {
                updatedSnackbar.notification = {
                    ...prevSnackbar.notification,
                    elements: prevSnackbar.notification.elements.filter((e) => e.id !== snackbarToHide),
                };
            } else {
                updatedSnackbar.system = {
                    ...prevSnackbar.system,
                    isVisible: prevSnackbar.system.id === snackbarToHide ? false : prevSnackbar.system.isVisible,
                };
            }

            return {
                ...state,
                snackbar: {
                    ...prevSnackbar,
                    ...updatedSnackbar,
                },
            };

        case types.PROGRESS_SHOW:
            const { content: progressContent, className } = action.payload;

            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    progress: {
                        isVisible : true,
                        content   : progressContent,
                        className,
                    },
                },
            };

        case types.PROGRESS_HIDE:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    progress: { isVisible: false },
                },
            };

        case types.CONFIRMATION_SHOW:
            const confirmationProps = action.payload;
            return {
                ...state,
                confirmation: {
                    isVisible: true,
                    ...confirmationProps,
                },
            };

        case types.CONFIRMATION_HIDE:
            return { ...state, confirmation: { isVisible: false } };

        case types.USER_SET:
            const user = action.payload;
            return { ...state, user };

        case types.NOTIFICATIONS_SET:
            const notifications = action.payload;

            return { ...state, notifications: { ...state.notifications, ...notifications } };

        case panelTypes.ROOT_AND_TEMPLATES_SET:
            return { ...state, error: { ...state.error, shouldRefreshUser: false } };

        case types.SET_APP_AS_RESTRICTED: {
            const { isAppRestricted, message, reason } = action.payload;
            return { ...state, restrictedApp: { isAppRestricted, message: message || null, reason } };
        }

        default:
            return state;
    }
};

export const getAppAsRestricted = (state) => state.restrictedApp;

export default appReducer;
