module.exports = Object.freeze({
    title      : 'Upgrade/Downgrade',
    titleShort : 'Upgr./Downgr.',
    emptyList  : 'There are no Upgrade/Downgrade requests',

    buttonUpgrade: 'Open Request',

    markProcessed: {
        button  : 'Mark as processed',
        success : 'The request has been marked as processed',
        error   : 'The request could not be marked as processed',
    },

    list: {
        name  : 'Contact Name',
        email : 'Email',
        phone : 'Phone',
        hotel : 'Hotel',
    },

    request: {
        name                 : 'Contact name',
        email                : 'Email',
        phone                : 'Phone',
        hotel                : 'Requested hotel',
        creationDate         : 'Creation date',
        preferredContactTime : 'Preferred contact time',
    },

    currentContract       : 'Current contract',
    newContract           : 'New contract',
    updateContractWarning : 'After saving, the new contract will be created with no rules attached',

    balances: { empty: 'There are no balances in this contract' },

    contract: {
        club                    : 'Club',
        tier                    : 'Tier',
        tierEmptySelect         : 'Select a tier',
        contractType            : 'Contract Type',
        requiredTier            : 'You should select a tier',
        requiredContractType    : 'You should select a contract type',
        contractTypeEmptySelect : 'Select a contract type',
        anniversaryPoints       : 'Anniversary Points',
        legacyId                : 'Legacy id',
    },

    saveSuccess                 : 'The contract has been updated',
    saveError                   : 'The contract could not be updated',
    saveErrorMarkingAsProcessed : 'The contract has been updated, but it could not be marked as processed',
    clubSearchError             : 'The club data could not be initialized',
    tierSearchError             : 'The tiers data could not be initialized',
    contractTypeSearchError     : 'The contract types data could not be initialized',

    edition: {
        clubWithNoTiers         : 'The selected club has no tiers',
        tierWithNoContractTypes : 'The selected tier has no contract types',
    },

    errors: { contractLegacyIdInUse: 'Contract Id used by another contract' },
});
