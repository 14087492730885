import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import ConfirmationButton from 'Components/ConfirmationButton';
import FormContext from './formContext';

function PrimaryButton({
    id, children, onClick, variant, color, isDisabled, collapsed, confirmation, isEnabled, icon, text, className, ignoreChanges, size,
}) {
    const intl = useIntl();
    const { submitData, events, isSubmitting, formId } = useContext(FormContext);

    const submit = () => {
        if (isDisabled || (submitData && !ignoreChanges && !submitData.hasChanged) || (submitData && submitData.isDisabled) || isSubmitting) {
            // The form shouldn't be submitted
            return;
        }

        if (!submitData.validity.isIncomplete && !submitData.validity.isInvalid && events.onSubmit) {
            if (onClick) {
                onClick({ ...submitData });
            }
            events.onSubmit({ ...submitData });
        } else if (events.onError) {
            events.onError(submitData.validity, submitData.rawValues);
        }
    };

    const isButtonDisabled = (isDisabled || (submitData && !submitData.hasChanged) || (submitData && submitData.isDisabled) || isSubmitting) && !isEnabled;

    if (collapsed || icon) {
        return (
            <ConfirmationButton
                id={id || formId ? `${formId}-primary-button` : null}
                confirmation={confirmation}
                icon={icon || 'ContentSave'}
                tooltip={
                    text
                    || intl.formatMessage({
                        id             : translated.global.buttons.save,
                        defaultMessage : translated.global.buttons.save,
                    })
                }
                onClick={submit}
                disabled={isButtonDisabled}
                isLoading={isSubmitting}
                className={className}
                size={size}
            />
        );
    }

    return (
        <ConfirmationButton
            id={id || formId ? `${formId}-primary-button` : null}
            confirmation={confirmation}
            variant={variant || 'unelevated'}
            color={color}
            onClick={submit}
            disabled={isButtonDisabled}
            isLoading={isSubmitting}
            className={className}
            size={size}
        >
            {children || text || <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />}
        </ConfirmationButton>
    );
}

PrimaryButton.defaultProps = {
    id            : null,
    children      : null,
    onClick       : null,
    variant       : '',
    color         : '',
    icon          : '',
    text          : '',
    className     : '',
    isDisabled    : false,
    isEnabled     : false,
    collapsed     : false,
    confirmation  : null,
    ignoreChanges : false,
    size          : '',
};

PrimaryButton.propTypes = {
    id           : PropTypes.string,
    children     : PropTypes.node,
    onClick      : PropTypes.func,
    variant      : PropTypes.string,
    color        : PropTypes.string,
    icon         : PropTypes.string,
    text         : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className    : PropTypes.string,
    isDisabled   : PropTypes.bool,
    isEnabled    : PropTypes.bool,
    collapsed    : PropTypes.bool,
    confirmation : PropTypes.shape({
        title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    ignoreChanges : PropTypes.bool,
    size          : PropTypes.string,
};

export default PrimaryButton;
