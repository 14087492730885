module.exports = Object.freeze({
    primaryOwner          : 'Primary Owner',
    secondaryOwner        : 'Secondary Owner',
    membership            : 'Membership',
    club                  : 'Club',
    tier                  : 'Tier',
    contractType          : 'Contract Type',
    contractTypeStartDate : 'Contract Start Date',
    contractTypeEndDate   : 'Contract End Date',
    view                  : 'View',
    importActionTooltip   : 'Import and Manage CSV files',
    importAction          : 'Import',
    viewDetails           : 'View Details',
    id                    : 'Owner ID',
    contractId            : 'Contract ID',
    legacyId              : 'Legacy ID',
    contractLegacyId      : 'Legacy Id',
    bookings              : 'Bookings',
    cantBook              : "You can't perform a booking with a non active Owner",
    noOwners              : 'There are no owners added',
    salutation            : 'Salutation',
    activateAccount       : {
        tooltip : 'Activate account',
        confirm : 'Activate owner account?',
        action  : 'Activate',
        success : 'The owner account has been activated',
        error   : 'The owner account could not be activated',
    },
    bankingConfigurationDisabledWarning: 'To add and edit banking configuration you need to complete the contract data',

    contractTypeFilterEmpty: 'There are no contract types',

    address1 : 'Address Line 1',
    address2 : 'Address Line 2',
    zipCode  : 'Zip code',
    city     : 'City',
    state    : 'State',
    country  : 'Country',

    accountCard: 'Account',

    bookDropdown: { title: 'Book for' },

    accountInformation        : 'Account Information',
    primaryOwnerInformation   : 'Primary Owner Information',
    secondaryOwnerInformation : 'Secondary Owner Information',

    listActions: {
        book         : 'Book',
        bookDisabled : 'Book Disabled',
    },

    profile: {
        success : 'The owner has been updated',
        error   : 'The owner could not be updated',
    },

    update: {
        primary: {
            success : 'The primary owner has been updated',
            error   : 'The primary owner could not be updated',
        },
        secondary: {
            success : 'The secondary owner has been updated',
            added   : 'The secondary owner has been added',
            error   : 'The secondary owner could not be updated',
        },
    },

    referredReferral : 'Referred/Referral',
    referred         : {
        title     : 'Referred',
        emptyList : 'There are no referred accounts added',
    },

    referral: {
        title : 'Referral',
        empty : 'There is no referral account added',
        edit  : {
            noUser      : 'No user selected',
            searchEmpty : 'There is no owner with the given email',
            saveSuccess : 'The referral has been updated',
            errors      : {
                save             : 'The referral could not be updated',
                userSelfReferred : "The owner can't be self referred",
            },
        },
        remove: {
            confirmationTitle   : 'Are you sure?',
            confirmationMessage : 'You will lose the referral',
            success             : 'The referral has been removed',
            error               : 'The referral could not be removed',
        },
    },

    extraAttributes: {
        accountTitle     : 'Account Extra Attributes',
        primaryTitle     : 'Primary Extra Attributes',
        secondaryTitle   : 'Secondary Extra Attributes',
        basicInformation : 'Basic Information',
        extraInformation : 'Extra Information',

        basic: {
            club        : 'Club',
            tier        : 'Tier',
            email       : 'Email',
            phone       : 'Phone',
            mobile      : 'Mobile',
            dateOfBirth : 'Date of birth',
            address     : 'Address',
            zipCode     : 'Zip Code',
            city        : 'City',
            state       : 'State',
            country     : 'Country',
            language    : 'Language',
            loginToken  : 'Login Token',
        },
    },

    balance: {
        title   : 'Summary',
        buttons : {
            show : 'Show Expired',
            hide : 'Hide Expired',
        },
        clubPoints: {
            title           : 'Current Balance',
            empty           : 'There is no current balance to show',
            pointsType      : 'Id',
            date            : 'Effective Date',
            expiryDate      : 'Expiry Date',
            leftShoulder    : 'Left Shoulder',
            rightShoulder   : 'Right Shoulder',
            contract        : 'Contract Id',
            currency        : 'Currency',
            points          : 'Amount',
            balance         : 'Wallet',
            total           : 'Total',
            partnersSummary : 'Remaining balance',
            balanceType     : 'Level',
        },
        balanceTotals: {
            title    : 'Balance Totals',
            currency : 'Currency',
            amount   : 'Amount',
            balance  : 'Balance',
            level    : 'Level',
        },
        transactions: {
            title  : 'Transactions',
            info   : 'Info',
            editor : {
                title         : "Edit Transaction's Balance",
                indicate      : 'Indicate the new values',
                creation      : 'Creation',
                cantEditDates : "Some balance's dates cannot be modified",
            },
            empty    : 'There is no point transactions to show',
            date     : 'Date',
            name     : 'Name',
            code     : 'Code',
            reason   : 'Reason',
            currency : 'Currency',
            points   : 'Amount',
            balance  : 'Balance',
            contract : 'Contract Id',

            loadError   : 'The transactions could not be loaded',
            deleteError : 'The transaction could not be deleted',

            cancel: {
                confirmationMessage:
                    "The remaining points of this transaction will be deducted. If the transaction has been partially used, you'll see a new negative transaction deducting the remaining points.",
                success : 'The transaction has been cancelled',
                error   : 'The transaction could not be cancelled',
                errors  : { transactionCantBeErased: 'The transaction could not be cancelled because it has been consumed' },
            },
        },
        editor: {
            title           : 'Edit Balance',
            previewTitle    : 'Balance Preview',
            previewBefore   : 'Next',
            previewExplains : 'Preview the balances before saving',
            indicate        : 'Indicate which value you want to add or subtract from each of the selected fields',
            emptySummary    : 'The selected transaction will be removed',
            acceptOrRefuse  : 'The balance will be updated with the following values',
            cancelWarning   :
                "The remaining points of this transaction will be deducted. If the transaction has been partially used, you'll see a new negative transaction deducting the remaining points.",
            balanceUpdated : 'The balance has been updated',
            errors         : {
                loadingPreview                 : 'The preview could not be loaded',
                datesAreNotEditable            : 'You cannot edit the dates of this balance',
                anniversaryDatesAreNotEditable : 'You cannot edit dates of anniversary points',
                shouldEnterSomeValue           : 'At least one value must be entered in one of the selected fields',
                defaultError                   : 'The balance could not be updated',
                insufficientBalanceFounds      : 'The balance has insufficient founds. Reload the page to get the updated founds',
            },
        },
        amount                         : 'Amount',
        reason                         : 'Reason',
        errorGettingPoints             : 'The available points could not be loaded',
        errorNotConversionOrAllocation : 'There are no permissions to perform any action on the wallet',
        gettingData                    : 'Loading points...',
        selectPeriod                   : 'Select a period',
        errorGettingData               : 'The information about available points could not be loaded',
        noData                         : 'There is no summary to show',
        conversionInfo                 : 'Converts Club Points to another External Club points',
        allocationInfo                 : 'Add points to the account, select one of the available Balance Type in the system',
        transferInfo                   : 'Transfer points from an existing period to a different one',
        chargeInfo                     : "Deduct points from the Owner's wallet after a booking modification is made",
        refundInfo                     : "Refund points to the Owner's wallet after a booking modification is made",
        vacationClub                   : 'External Club',
        contract                       : 'Contract',
        balanceType                    : 'Balance Type',
        currency                       : 'Currency',
        available                      : 'Available',
        availablePeriods               : 'Balance',
        balanceDestination             : 'Destination',
        effective                      : 'Effective',
        expiry                         : 'Expiry',
        from                           : 'From',
        to                             : 'to',
        dates                          : 'Duration',
        newPeriodShoulders             : 'Shoulders',
        shoulderLeft                   : 'Shoulder Left',
        shoulderRight                  : 'Shoulder Right',
        newPeriod                      : 'Effective dates',
        periodDates                    : 'Period Duration',
        year                           : 'Selected Year',
        walletOperations               : 'Manage Wallet',
        pointsOperations               : 'Conversion creation',
        pointsOperationsButton         : 'Manage',
        addMissingBalance              : 'Create',
        allocation                     : 'Allocation',
        conversion                     : 'Conversion',
        transfer                       : 'Transaction',
        allocate                       : 'Allocate',
        convert                        : 'Convert',
        transact                       : 'Transact',
        refund                         : 'Refund',
        charge                         : 'Charge',
        selectedOperation              : 'Selected Operation',
        convertedPoints                : 'Converted Points',
        availablePoints                : 'Available Points',
        availableNights                : 'Available Nights',
        suffixPoints                   : 'Pts',
        suffixNights                   : 'Nights',
        name                           : 'Name',
        rate                           : 'Rate',
        minimumPoints                  : 'Minimum Points',
        remainingPoints                : 'Remaining Points',
        remainingInPeriod              : 'Remaining in Period',
        originalAmount                 : 'Original Amount',
        originalPointsConverted        : 'Original Points Converted',
        conversionPreference           : {
            title              : 'Get from',
            default            : 'Preferred',
            defaultSupport     : 'Use points depending on expiration date',
            clubPoints         : 'Club Points',
            clubPointsSupport  : 'Use Club Points first then Bonus Points',
            bonusPoints        : 'Bonus Points',
            bonusPointsSupport : 'Use Bonus Points first then Club Points',
            summary            : {
                title             : 'Available points',
                loading           : 'Loading',
                error             : 'Error',
                total             : 'Total',
                clubPoints        : 'Club points',
                transactionPoints : 'Transaction points',
                bonusPoints       : 'Bonus points',
                fee               : 'Fee to be paid by the Owner',
            },
        },
        balanceTypeInitError      : 'The contract balance types could not be initialized',
        cantSaveConversion        : 'The operation could not be made',
        conversionInitError       : 'The operation could not be initialized',
        conversionSaved           : 'The conversion/allocation has been made',
        chargeSaved               : 'The charge/refound has been made',
        balanceUnpaid             : 'The account is delinquent. Do you want to proceed anyway?',
        ownerNoReservationAllowed : 'This account is not allowed to make reservations nor conversions. Do you want to continue anyway?',
    },

    relationships: {
        alertNonRelated       : 'Add a user to the system without any relation',
        addRelationship       : 'Add relationship',
        editRelationship      : 'Edit relationship',
        removeRelationship    : 'Remove relationship',
        removeUser            : 'Remove user',
        relationshipConnector : 'of',
        none                  : 'none',
        multipleRelationships : 'multiple relationships',
        errorCreating         : 'The relationship could not be added',
        errorUpdating         : 'The relationship could not be updated',
        errorCreatingUser     : 'The user could not be added',
        errorUpdatingUser     : 'The user could not be updated',
        userAdded             : 'The user has been added',
        userUpdated           : 'The user has been updated',

        errors: { entityAlreadyExists: 'There is a user in the system using this email or login token' },

        delete: {
            error         : 'The relationship could not be removed',
            confirm       : 'Delete relationship?',
            deleted       : 'Relationship has been deleted',
            acceptMessage : 'Delete',
            userDeleted   : 'The user has been deleted',
            errorOnUser   : 'The user could not be deleted',
            userConfirm   : 'Delete user?',
        },

        emptyList: 'There are no relationships added',

        relationshipModal: {
            userSearch  : 'User',
            notFound    : 'User not found.',
            searchError : 'The search could not be made',

            summary           : 'Relationship',
            summaryConnector  : 'of',
            summaryIncomplete : 'Please, search a user and add a relationship first',
        },

        phone     : 'Phone',
        mobile    : 'Mobile',
        firstName : 'First Name',
        lastName  : 'Last Name',
        email     : 'Email',
        birthday  : 'Date of birth',
        language  : 'Language',
    },

    changeStatus: {
        enable: {
            confirmationTitle   : 'Enable Membership',
            confirmationMessage : 'This membership will be enabled. Are you sure?',
            success             : 'The owner has been enabled',
            error               : 'The owner could not be enabled',
        },
        disable: {
            confirmationTitle   : 'Suspend Membership',
            confirmationMessage : 'This membership will be disabled. You can re-enable it whenever you want. Do you wish to continue?',
            success             : 'The owner has been disabled',
            error               : 'The owner could not be disabled',
        },
    },

    edition: {
        clubWithNoTiers        : 'The selected club has no tiers',
        tierWithNoContractType : 'The selected tier has no contract types',
    },

    info: {
        confirmationActivationEmail    : 'Do you want to send the Account activation email?',
        errorSendingEmail              : 'The activation email could not be sent',
        emailSuccessfullySent          : 'The email has been sent',
        sendSecondaryOwnerInvitation   : 'Send invitation link',
        resendEmailButton              : 'RESEND INVITE',
        noContract                     : 'The owner has no contract, you need to create one',
        confirmationDissociateSecOwner : 'Are you sure you want to dissociate the secondary owner?',
        secondaryOwnerDissociated      : 'The secondary owner has been dissociated',
        secondaryOwnerDissociatedError : 'The secondary owner could not be dissociated',
        contractTitle                  : 'Update Contract',
        contractUpdateWarning          : 'Update the date below if it was not loaded correctly',
        contractUpdated                : 'The contract has been updated',
        contractUpdateError            : 'The contract could not be updated',
        sendingEmail                   : 'Your request is being processed',
    },

    errors: {
        entityAlreadyExists : 'There is a user in the system using this legacy owner, legacy contract ids, login token or email',
        channelIdRequired   : "To retrieve Owner's bookings a valid Channel id is required. Check the Tier configuration",
        failedDependency    : "To retrieve Owner's bookings a valid Channel id is required. Check the Tier configuration",
    },

    usersErrors: { entityAlreadyExists: 'There is a user in the system using this email or login token' },

    financialStatus: {
        button               : 'Financial status',
        title                : 'Financial status',
        error                : 'The financial data could not be initialized',
        currentYear          : 'Current Year',
        nextYear             : 'Next Year',
        fees                 : 'Fees',
        loans                : 'Loans',
        noReservationAllowed : 'Booking allowed',
    },

    exchange: 'Exchange',

    pointStatement: 'View Points Statement',

    attachments: {
        title     : 'Attachments',
        emptyList : 'There are no attachments',

        name          : 'Name',
        creationDate  : 'Creation date',
        fileTitle     : 'Select file',
        fileHelper    : 'Supported files: .doc, .docx, .pdf, .jpg, .jpeg, .xls, .xlsx, .msg',
        category      : 'Category',
        categoryChild : 'Sub category',
        noCategory    : 'None',
        subCategory   : 'Sub category',
        extension     : 'Extension',

        invalidFileName   : "The selected file's name can't have a length superior to 60 characters",
        invalidFileFormat : 'The file selected has an invalid format',
        invalidFileSize   : "The file selected can't be bigger than 100MB",

        edition: {
            title: 'Edit attachment',

            success : 'The attachment has been updated',
            errors  : { default: 'The attachment could not be updated' },
        },

        save: {
            title: 'Add attachment',

            success : 'The attachment has been added',
            errors  : { default: 'The attachment could not be added' },
        },

        delete: {
            confirmation : 'Are you sure to delete the attachment?',
            success      : 'The attachment has been removed',
            error        : 'The attachment could not be removed',
        },
    },

    walletAddBalance: {
        title     : 'Create missing balance',
        typeLabel : 'Balance',
        warning   : 'This transaction type can not be undone',
        types     : {
            prev    : 'Prev',
            current : 'Current',
            next    : 'Next',
        },
        errors: { default: 'The balance could not be added' },
    },

    contracts: {
        title   : 'Contracts',
        status  : 'Status',
        empty   : 'There are no contracts',
        warning : "Select a contract to display it's details",

        contractColumn: 'Contracts',

        club              : 'Club',
        tier              : 'Tier',
        consumptionMode   : 'Consumption Mode',
        creation          : 'Creation',
        start             : 'Start date',
        end               : 'End date',
        contractType      : 'Contract type',
        startDate         : 'Contract start date',
        endDate           : 'Contract end date',
        currency          : 'Currency',
        ownerId           : 'Owner ID',
        legacyId          : 'Legacy ID',
        contractId        : 'Contract Id',
        primaryCurrency   : 'Primary currency',
        secondaryCurrency : 'Secondary currency',
        delta             : 'Delta',

        actions: {
            suspend  : 'Suspend',
            activate : 'Activate',
        },

        statusChange: { success: 'The contract has been updated' },

        upgrade  : 'Upgrade/Downgrade',
        details  : 'Details',
        featured : {
            label     : 'Is featured',
            highlight : 'Set as featured',
            disable   : 'Unset as featured',
        },

        wallet: {
            title      : 'Wallet Transactions',
            empty      : 'There are no transactions for this contract',
            newBalance : 'New Balance',
            operation  : 'Operation',
        },

        history: {
            title : 'History',
            empty : 'There is no history for this contract',
        },

        errors: { contractLoad: 'The contract data could not be loaded' },

        edition: {
            title              : 'Membership Configuration',
            titleUpgrade       : 'Membership Upgrade',
            configurationTitle : 'Banking configuration',

            requiredTier                       : 'You should select a tier',
            requiredContractType               : 'You should select a contract type',
            saveError                          : 'The contract could not be updated',
            clubSearchError                    : 'The club data could not be loaded',
            tierSearchError                    : 'The tiers data could not be loaded',
            contractTypeSearchError            : 'The contract types data could not be loaded',
            clubWithNoTiers                    : 'The selected club has no tiers',
            tierWithNoContractTypes            : 'The selected tier has no contract types',
            balanceTypesError                  : "The club tier's balance types could not be loaded",
            bookingConfigurationError          : "The club tier's booking configuration could not be loaded",
            bankingConfigurationMissingFields  : 'There are missing fields in the banking configuration number: ',
            bankingConfigurationDateValidation : 'Effective Date and Expiry Date must be between the contract dates on the banking configuration number: ',

            disabledWarning: 'To edit banking configuration you need to complete the contract data',

            bankingConfiguration: {
                title              : 'Banking Configuration',
                empty              : 'There are no banking configurations',
                deleteConfirmation : 'The banking configuration will be deleted',

                generate: {
                    title      : 'Generate',
                    modalTitle : 'Generate automatic configuration',

                    button              : 'Generate',
                    message1            : 'Points added will be assigned annually.',
                    message2            : 'This actions could affect already allocated balances.',
                    confirmationMessage : 'The balance configuration will be generated with the following values',

                    errors: { default: 'The banking configurations could not be generated' },
                },

                editTitle   : 'Edit banking configuration',
                createTitle : 'Create banking configuration',
                create      : {
                    success : 'The banking configuration has been added',
                    error   : 'The banking configuration could not be added',
                },
                edit: {
                    success : 'The banking configuration has been updated',
                    error   : 'The banking configuration could not be updated',
                },
                delete: {
                    success : 'The banking configuration has been removed',
                    error   : 'The banking configuration could not be removed',
                },
                initError: 'The available balance types could not be initialized',

                history: {
                    title          : 'History',
                    empty          : 'There is no banking configuration history',
                    restoreSuccess : 'The banking configuration has been restored',
                    restoreError   : 'The banking configuration could not be restored',

                    initError: 'The banking configuration history could not be initialized',
                },

                isActive          : 'Is Active',
                balanceType       : 'Balance Type',
                applyOn           : 'Apply on',
                applyGlobal       : 'Globally',
                applyContract     : 'Contract',
                isRepeated        : 'Repeat over time',
                effectiveDate     : 'Effective Date',
                expiryDate        : 'Expiry Date',
                repeatDelta       : 'Repeats every',
                repeatAnniversary : 'Start date',
                amount            : 'Amount',
                shoulderLeft      : 'Shoulder Left',
                shoulderRight     : 'Shoulder Right',

                editInvalidTooltip: 'The banking configuration is invalid. Edit it to fix the problem',

                invalidBankingConfigurationWarning: 'At least one banking configuration is invalid',
            },

            extraAttributes: { success: 'The extra attributes has been updated' },

            errors: { contractLegacyIdInUse: 'Contract Id used by another contract' },
        },
    },

    users: {
        title     : 'Users',
        emptyList : 'There are no users added',

        name          : 'Name',
        creationDate  : 'Creation date',
        fileTitle     : 'Select file',
        fileHelper    : 'Supported files: .doc, .docx, .pdf, .jpg, .jpeg, .xls, .xlsx, .msg',
        category      : 'Category',
        categoryChild : 'Sub category',
        noCategory    : 'None',
        subCategory   : 'Sub category',
        extension     : 'Extension',

        invalidFileName   : "The selected file's name can't have a length superior to 60 characters",
        invalidFileFormat : 'The file selected has an invalid format',
        invalidFileSize   : "The file selected can't be bigger than 100MB",

        edition: {
            title: 'Edit user',

            success : 'The user has been updated',
            errors  : { default: 'The user could not be updated' },
        },

        save: {
            title: 'Add user',

            success : 'The user has been added',
            errors  : { default: 'The user could not be added' },
        },

        delete: {
            confirmation : 'Are you sure to delete the user?',
            success      : 'The user has been deleted',
            error        : 'The user could not be deleted',
        },
    },
    notes: {
        title              : 'Note',
        id                 : 'ID number',
        new                : 'New note',
        category           : 'Category',
        name               : 'name',
        text               : 'text',
        creator            : 'Creator',
        creationDate       : 'Creation date',
        details            : 'Details',
        deleteConfirmation : 'Delete note?',
        empty              : 'There are no notes added',
        success            : {
            new    : 'The note has been added',
            delete : 'The note has been deleted',
            update : 'The note has been updated',
        },
        error: {
            add    : 'The note could not be added',
            delete : 'The note could not be deleted',
        },
    },

    imports: {
        filename     : 'Filename',
        upload       : 'Upload',
        row          : 'Row # with error',
        creation     : 'Creation',
        modification : 'Modification',
        title        : 'CSV Format',
        template     : 'Template file',
        help         : 'Download and use the following file to help you with the creation process',
        download     : 'Download CSV',
        pdf          : 'Download PDF',
        csv          : 'CSV Format Documentation',
        csvFormat    : 'Open the following file to understand the usage and fields of the csv file',
    },
});
