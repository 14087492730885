import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import Chips from 'Components/Chips/Chips';
import Button from 'Components/Button';
import Form, { FormNew } from 'Components/Form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import yup from 'Utils/yupHelper';

const schema = yup.object().shape({
    isSliderActive : yup.bool(),
    step           : yup.number().integer().min(1).max(100)
        .when('isSliderActive', {
            is   : false,
            then : (currentSchema) => currentSchema.required(),
        }),
    from: yup.number().integer().min(1).max(100)
        .when('isSliderActive', {
            is   : true,
            then : (currentSchema) => currentSchema.required(),
        }),
    to: yup.number().integer().min(1).max(100)
        .when(
            ['from', 'isSliderActive'],
            (from, isSliderActive, currentSchema) => {
                let updatedSchema = currentSchema;
                if (isSliderActive) {
                    if (from) {
                        updatedSchema = updatedSchema.min(from + 1);
                    }

                    updatedSchema = updatedSchema.required();
                }

                return updatedSchema;
            },
        ),
    interval: yup.number().integer().min(1).max(100)
        .when('isSliderActive', {
            is   : true,
            then : (currentSchema) => currentSchema.required(),
        }),
});

function StepBuilder() {
    const { setValue, watch } = useFormContext();
    const [steps, ranges] = watch(['steps', 'ranges']);
    const [forceUpdate, setForceUpdate] = useState(JSON.stringify(ranges));
    if (JSON.stringify(ranges) !== forceUpdate) {
        setForceUpdate(JSON.stringify(ranges));
    }

    const formRef = useRef();

    const handleOnSubmit = useCallback((values) => {
        const { isSliderActive, step, from, to, interval } = values;
        let updateSteps = new Set(steps);
        if (isSliderActive) {
            for (let newStep = from; newStep <= to; newStep += interval) {
                updateSteps.add(newStep);
            }
        } else {
            updateSteps.add(step);
        }
        updateSteps = [...updateSteps].sort((a, b) => a - b);
        setValue('steps', updateSteps, { shouldDirty: true, shouldValidate: true });

        const lastStep = updateSteps[updateSteps.length - 1];

        if (!steps?.find((e) => String(e) === String(lastStep)) && !!ranges) {
            let lastRangeKey = null;
            Object.entries(ranges)?.forEach?.(([eachKey, eachRange]) => {
                if (!lastRangeKey || eachRange.to > ranges[lastRangeKey].to) {
                    lastRangeKey = eachKey;
                }
            });

            if (String(ranges[lastRangeKey].to) !== String(lastStep)) {
                setValue(`ranges.${lastRangeKey}.to`, lastStep);
            }
        }

        if (formRef?.current?.reset) {
            formRef.current.reset({
                isSliderActive,
                step     : '',
                from     : '',
                to       : '',
                interval : '',
            });
        }
    }, [steps, setValue, ranges]);

    const handleOnRemove = useCallback((step) => {
        const index = steps.indexOf(step);
        const updated = [...steps];
        updated.splice(index, 1);
        setValue('steps', updated, { shouldDirty: true, shouldValidate: true });
    }, [steps, setValue]);

    const isAStartStep = useCallback((step) => (
        Object.keys(ranges).find((key) => {
            const range = ranges[key];

            return range.from === step && !range.isHidden;
        })
    ), [ranges]);

    const handleOnRemoveAll = useCallback(() => {
        const inUse = steps.filter((step) => isAStartStep(step));
        setValue('steps', inUse, { shouldDirty: true, shouldValidate: true });
    }, [steps, setValue, isAStartStep]);

    const list = useMemo(() => (
        steps?.map((each) => ({
            key        : each,
            text       : each,
            isDisabled : isAStartStep(each),
            onClose    : () => { handleOnRemove(each); },
        })) || []
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [handleOnRemove, steps, ranges, forceUpdate]);

    const showRemoveAllButton = useMemo(() => steps?.filter((step) => !isAStartStep(step)).length > 4, [steps, isAStartStep]);

    const initialValues = useMemo(() => ({
        step           : '',
        from           : '',
        to             : '',
        interval       : '',
        isSliderActive : true,
    }), []);

    const validateToField = useCallback(
        () => {
            if (formRef?.current?.trigger) {
                formRef.current.trigger('to');
            }
        },
        [],
    );

    return (
        <Subsection title={translated.rules.dynamicRanges.stepBuilder.title}>
            <Form.WrapperNew
                schema={schema}
                initialValues={initialValues}
                key="step-builder-form"
                formId="step-builder-form"
                keepValuesOnInputRemove
                ref={formRef}
            >
                <FormNew onSubmit={handleOnSubmit} avoidConfirmation>
                    <Form.InputNew
                        isDense
                        submitKey="isSliderActive"
                        type="switch"
                        label={translated.rules.dynamicRanges.stepBuilder.sliderConfiguration}
                    />
                    <Form.ColumnNew key="range-generator" className="form-column-group margin-top-medium">
                        <Form.InputNew
                            isDense
                            submitKey="from"
                            type="number"
                            label={translated.global.min}
                            showWhen={{
                                field : 'isSliderActive',
                                is    : true,
                            }}
                            className="small-number-input"
                            helperText={{ label: 'Min value: 1' }}
                            onChange={validateToField}
                        />
                        <Form.InputNew
                            isDense
                            submitKey="to"
                            type="number"
                            label={translated.global.max}
                            showWhen={{
                                field : 'isSliderActive',
                                is    : true,
                            }}
                            className="small-number-input"
                            helperText={{ label: 'Max value: 100' }}
                        />
                        <Form.InputNew
                            isDense
                            submitKey="interval"
                            type="number"
                            label={translated.rules.dynamicRanges.stepBuilder.interval}
                            showWhen={{
                                field : 'isSliderActive',
                                is    : true,
                            }}
                            className="small-number-input"
                        />
                        <Form.InputNew
                            isDense
                            submitKey="step"
                            type="number"
                            label={translated.rules.dynamicRanges.stepBuilder.step}
                            showWhen={{
                                field : 'isSliderActive',
                                is    : false,
                            }}
                            className="small-number-input"
                            helperText={{ label: 'Min/max value: 1/100' }}
                            onChange={validateToField}
                        />
                        <Form.PrimaryNew variant="outlined" key="fp" className="add-column-button margin-left-xsmall">
                            <WrappedFormattedMessage content={translated.rules.dynamicRanges.stepBuilder.generate} />
                        </Form.PrimaryNew>
                    </Form.ColumnNew>
                </FormNew>
            </Form.WrapperNew>

            {!!list?.length && (
                <>
                    <div className="flex flex-middle flex-space-between margin-top-xsmall">
                        <Form.Title className="text-color-secondary" isSmall>
                            <WrappedFormattedMessage content={translated.rules.dynamicRanges.stepBuilder.steps} />
                        </Form.Title>
                        {showRemoveAllButton && (
                            <Button size="small" variant="text" onClick={handleOnRemoveAll}>
                                <WrappedFormattedMessage content={translated.rules.dynamicRanges.stepBuilder.removeAll} />
                            </Button>
                        )}
                    </div>
                    <Chips
                        id="steps-chips"
                        key="steps-chips"
                        list={list}
                        outlined
                    />
                </>
            )}
        </Subsection>
    );
}

export default StepBuilder;
