import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from 'Components/Sections/Collection';

function Partners({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

Partners.propTypes = sectionPropTypes;

export default Partners;
