import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from '../Collection';

function Owners({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

Owners.propTypes = sectionPropTypes;

export default Owners;
