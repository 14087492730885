module.exports = Object.freeze({
    title: 'Bookings',

    hotel                : 'Hotel',
    status               : 'Status',
    bookingCode          : 'Booking Code',
    bookingDate          : 'Booking Date',
    checkIn              : 'Check-In',
    checkOut             : 'Check-Out',
    contract             : 'Contract',
    reservedRooms        : 'Reserved rooms',
    userEmptyBookings    : 'There are no bookings for this account',
    generalEmptyBookings : 'There are no bookings',
    guest                : 'Guest name',
    filterSupportText    : 'Filter by Guest Name, Booking code or email',

    actions: {
        cancel          : 'Cancel',
        modify          : 'Modify',
        chargeAndRefund : 'Charge/Refund',
    },

    modifications: {
        checking          : 'Checking if the reservation can be modified, you\'ll be redirected in an second',
        cantModify        : 'The operation could not be performed',
        cantModifyDetails : 'The reservation could not be modified, contact a superior to make the necessary configurations',
    },

    errors: {
        getBookingError             : 'The reservation data could not be initialized',
        getModify                   : 'There was an error getting information from the booking',
        updateError                 : 'The reservation could not be updated',
        errorFundsAmount            : 'The amount entered is bigger than the booking cost',
        noArmnetId                  : 'The client has no armnet id',
        clientHasDebt               : 'The client has a debt',
        incompatibleBalanceCurrency : 'The balance destination and source must have the same currency',
    },

    cancellation: {
        title: 'Cancel reservation',

        notCancellable           : 'This booking could not be canceled. Please contact your supervisor',
        cancellableWithRefound   : 'The reservation will be canceled and the Owner will receive the corresponding refund',
        cancellableWithNoRefound : 'The reservation will be canceled with no refunds taking place',
        unknownState             : 'This booking could not be canceled. Please contact your supervisor',
    },
});
