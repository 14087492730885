import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'Components/Modal';
import translated from 'Constants/labels/translated';
import { maxBalanceAmount } from 'Constants/global';
import Form from 'Components/Form';
import CurrentBalance from './CurrentBalance';
import { walletUtils } from './utils';

const ADD = 'Add';
const SUB = 'Substract';

function BalanceEditor({ selectedBalances, navigator, resources, snackbar, onFinish }) {
    const intl = useIntl();
    const [isLoading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [balanceState, setBalanceState] = useState({
        isPreviewVisible  : false,
        previewData       : null,
        submitDataValues  : null,
        initialFormValues : null,
    });

    const balancesAndChildren = selectedBalances.reduce((acc, current) => [...acc, ...(current.children?.length ? current.children : [current])], []);
    const [isAdding, setIsAdding] = useState(balancesAndChildren.reduce((acc, current) => ({ ...acc, [current.id]: true }), {}));

    const handleSubmitPreview = async ({ values }) => {
        const { reason } = values;
        const rawBalances = Object.values(values.balances).filter((v) => v?.amount?.inputValue);
        const balances = rawBalances.length
            ? rawBalances.map(({ amount, id }) => ({ amount: amount.selectedOption === ADD ? amount.inputValue : -amount.inputValue, id }))
            : null;

        if (!balances) {
            snackbar.show({
                content : translated.owners.balance.editor.errors.shouldEnterSomeValue,
                isError : true,
            });
            return;
        }

        setLoading(true);
        try {
            const { summary } = await navigator.directRequest({ ...resources.preview, data: { reason, balances } });

            // Formatting for use the initialValues Form functionality in the back button from preview
            setBalanceState({
                initialFormValues : rawBalances.reduce((p, { amount, id }) => ({ ...p, [`balances.balance-${id}`]: amount }), { reason }),
                submitDataValues  : { balances, reason },
                previewData       : walletUtils.formatPointItems(summary),
                isPreviewVisible  : true,
            });
        } catch (error) {
            snackbar.show({
                error       : error?.response,
                isError     : true,
                errorLabels : translated.owners.balance.editor.errors,
                content     : translated.owners.balance.editor.errors.loadingPreview,
            });
        }
        setLoading(false);
    };

    const handleSubmitUpdate = async () => {
        try {
            setSubmitting(true);
            await navigator.directRequest({
                ...resources.update,
                data: balanceState.submitDataValues,
            });

            snackbar.show({
                content   : translated.owners.balance.editor.balanceUpdated,
                isSuccess : true,
            });

            onFinish(true);
        } catch (e) {
            setSubmitting(false);

            snackbar.show({
                content : translated.owners.balance.editor.errors.defaultError,
                error   : e,
                isError : true,
            });
        }
    };

    const getMaxValue = (balanceIsAdding, balance) => {
        if (balanceIsAdding) {
            return balance < 0 ? maxBalanceAmount : maxBalanceAmount - balance;
        }
        return balance < 0 ? 0 : balance;
    };

    return (
        <>
            {!balanceState.isPreviewVisible && (
                <Form.Wrapper
                    hasChanged={!!balanceState.previewData}
                    modalProperties={{
                        // eslint-disable-next-line max-len
                        title     : translated.owners.balance.editor.title,
                        className : 'balance-editor-modal',
                        isLoading,
                        buttons   : [
                            <Form.Secondary variant="text" color="primary" key="fs" onClick={onFinish}>
                                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                            </Form.Secondary>,
                            <Form.Primary
                                variant="text"
                                color="primary"
                                key="fp"
                                tooltip={intl.formatMessage({
                                    id             : translated.owners.balance.editor.previewExplains,
                                    defaultMessage : translated.owners.balance.editor.previewExplains,
                                })}
                            >
                                <FormattedMessage
                                    id={translated.owners.balance.editor.previewBefore}
                                    defaultMessage={translated.owners.balance.editor.previewBefore}
                                />
                            </Form.Primary>,
                        ],
                    }}
                >
                    <p className="margin-bottom-medium">
                        <FormattedMessage id={translated.owners.balance.editor.indicate} defaultMessage={translated.owners.balance.editor.indicate} />
                    </p>
                    <Form onSubmit={handleSubmitPreview} initialValues={balanceState.initialFormValues}>
                        {balancesAndChildren.map(({
                            id, pointsType, balanceType, balance, amount, expiryDate, effectiveDate,
                        }) => {
                            const { currency } = balanceType;
                            let validation;
                            let minBalanceAmount;
                            if (currency?.isInteger) {
                                validation = ['numbers'];
                                minBalanceAmount = 1;
                            } else {
                                validation = ['float'];
                                minBalanceAmount = 0.01;
                            }

                            return (
                                <Form.Input
                                    type="split"
                                    submitKey={`balances.balance-${id}`}
                                    options={[
                                        {
                                            value   : ADD,
                                            content : intl.formatMessage({
                                                id             : translated.owners.balance.balanceAdd,
                                                defaultMessage : translated.owners.balance.balanceAdd,
                                            }),
                                        },
                                        {
                                            value   : SUB,
                                            content : intl.formatMessage({
                                                id             : translated.owners.balance.balanceSub,
                                                defaultMessage : translated.owners.balance.balanceSub,
                                            }),
                                        },
                                    ]}
                                    min={minBalanceAmount}
                                    max={getMaxValue(isAdding[id], balance)}
                                    validations={{
                                        minValue : minBalanceAmount,
                                        maxValue : getMaxValue(isAdding[id], balance),
                                        unary    : validation,
                                    }}
                                    onChange={(newValue) => {
                                        const currentlyAdding = newValue?.selectedOption === ADD;
                                        if (currentlyAdding !== isAdding[id]) {
                                            setIsAdding((prev) => ({
                                                ...prev,
                                                [id]: currentlyAdding,
                                            }));
                                        }
                                    }}
                                    submitFormat={(value) => ({ amount: value, id })}
                                    suffix={balanceType?.currency?.shorthandCode}
                                    label={pointsType}
                                    helperText={{
                                        label: (
                                            <FormattedMessage
                                                id={translated.owners.balance.balanceHelper}
                                                defaultMessage={translated.owners.balance.balanceHelper}
                                                values={{ amount, balance, effectiveDate, expiryDate }}
                                            />
                                        ),
                                    }}
                                />
                            );
                        })}
                        <Form.Input
                            label={translated.owners.balance.reason}
                            type="text"
                            submitKey="reason"
                            className="margin-bottom-medium"
                            validations={{ maxLength: 100 }}
                            charCount={{ total: 100 }}
                        />
                    </Form>
                </Form.Wrapper>
            )}
            {balanceState.isPreviewVisible && (
                <Modal
                    title={translated.owners.balance.editor.previewTitle}
                    cancelButton={{
                        text      : translated.global.buttons.back,
                        onClick   : () => setBalanceState((p) => ({ ...p, isPreviewVisible: false })),
                        isEnabled : !isSubmitting,
                    }}
                    closeButton={{
                        text      : translated.global.buttons.save,
                        onClick   : handleSubmitUpdate,
                        isEnabled : !isSubmitting,
                    }}
                    className="balance-preview-modal"
                    isLoading={isSubmitting}
                >
                    <p className="margin-bottom-medium">
                        <FormattedMessage
                            id={translated.owners.balance.editor.acceptOrRefuse}
                            defaultMessage={translated.owners.balance.editor.acceptOrRefuse}
                        />
                    </p>
                    <CurrentBalance id="preview-club-points" items={balanceState.previewData} />
                </Modal>
            )}
        </>
    );
}

BalanceEditor.propTypes = {
    navigator        : PropTypes.shape({}).isRequired,
    onFinish         : PropTypes.func.isRequired,
    resources        : PropTypes.shape({}).isRequired,
    snackbar         : PropTypes.shape({}).isRequired,
    selectedBalances : PropTypes.arrayOf(
        PropTypes.shape({
            id          : PropTypes.number,
            year        : PropTypes.string,
            pointsType  : PropTypes.string,
            balanceType : PropTypes.shape({
                id       : PropTypes.number,
                name     : PropTypes.string,
                category : PropTypes.string,
                isActive : PropTypes.bool,
            }),
            balance       : PropTypes.string,
            amount        : PropTypes.string,
            beginDate     : PropTypes.string,
            expiryDate    : PropTypes.string,
            effectiveDate : PropTypes.string,
            children      : PropTypes.arrayOf(PropTypes.shape({})),
        }),
    ).isRequired,
};

export default BalanceEditor;
