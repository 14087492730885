/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Sections from 'Components/Sections';
import Breadcrumb from 'Components/Breadcrumb';
import FloatingActionButton from 'Components/FloatingActionButton';
import useSectionsConstructor from './useSectionsConstructor';
import getClassName from 'Utils/getClassName';

function Workspace({ sections, QA, app, url, navigator, path }) {
    const areSectionsReady = useSectionsConstructor(url, app, QA, navigator, sections, path);

    useEffect(() => {
        if (QA.isActive && areSectionsReady) {
            app.loaded();
        }
    }, [app.loaded, QA, areSectionsReady, app]);

    const breadcrumbWrapperClass = useMemo(() => getClassName({ isVisible: app.areBreadcrumbTabsVisible, sideDrawerCollapsed: app.isSideDrawerCollapsed }, 'breadcrumb-tabs-wrapper'), [app.areBreadcrumbTabsVisible, app.isSideDrawerCollapsed]);

    if (!areSectionsReady) {
        return null;
    }

    return (
        <div id="workspace" className="workspace">
            <div className="container">
                <Breadcrumb sections={sections} className={`visible@s ${app.areBreadcrumbTabsVisible ? 'breadcrumb-tabs-wrapper-visible' : ''}`} />
                <div className={breadcrumbWrapperClass}>
                    {app.areBreadcrumbTabsVisible && <Breadcrumb.Tabs sections={sections} />}
                    <Sections.Selected selectFrom={sections} />
                </div>
                <FloatingActionButton />
            </div>
        </div>
    );
}

Workspace.propTypes = {
    sections  : PropTypes.shape({}).isRequired,
    QA        : PropTypes.shape({}).isRequired,
    app       : PropTypes.shape({}).isRequired,
    url       : PropTypes.shape({}).isRequired,
    navigator : PropTypes.shape({}).isRequired,
    path      : PropTypes.shape({}).isRequired,
};

export default hot(Workspace);
