import { inputSeparators } from 'Constants/global';

export const transformToSnake = (key) => (key != null ? String(key).replace(/([A-Z])/g, '-$1').replace(/\./g, '-').toLowerCase() : '');

export const transformCamelToSpaces = (key) => (key != null ? String(key).replace(/([A-Z])/g, ' $1') : '');

export const transformCamelToSpacesOnlyFirstCapital = (key) => {
    const camelToSpaces = transformCamelToSpaces(key);
    return camelToSpaces.charAt(0).toUpperCase() + camelToSpaces.slice(1).toLowerCase();
};

// Methods related to inputs with separators.
export const getSeparatorUsed = (text) => inputSeparators.find((e) => String(text).indexOf(e) > -1);

export const getTextBeforeSeparator = (text) => {
    const separator = getSeparatorUsed(text);
    if (!separator) {
        return text;
    }

    const index = text.indexOf(separator);
    return String(text).substring(0, index);
};

export const getTextAfterSeparator = (text) => {
    const separator = getSeparatorUsed(text);
    if (!separator) {
        return text;
    }

    const index = text.indexOf(separator);
    return String(text).substring(index + 1);
};

export const transformToCamel = (name) => {
    const parts = name.split('_');
    let result = parts[0];
    for (let i = 1; i < parts.length; i += 1) {
        result += parts[i].charAt(0).toUpperCase() + parts[i].substring(1);
    }
    return result;
};

export default transformToSnake;
