module.exports = Object.freeze({
    clubNotSaved          : 'The club could not be updated',
    clubSaved             : 'The club has been added',
    clubUpdated           : 'The club has been updated',
    modifyClub            : 'Modify Rule',
    areYouSure            : 'Are you sure you want to modify this Club?',
    areYouSureToReset     : 'Reset changes?',
    unsavedDataWillBeLost : 'Unsaved data will be lost',
    currentDuration       : 'Current duration type',
    durationFixed         : 'Fixed',
    durationDynamic       : 'Dynamic',
    associatedTiers       : 'Associated Tiers',
    availableTiers        : 'Available Tiers',
    desassociateTier      : 'Dissociate',
    selectedTiers         : 'Selected Tiers',
    editTiers             : 'Edit Tier',
    info                  : 'To associate tiers, select them from the list, dissociate them with the button on the right',
    noChannelId           : "There are Tiers without channel ID, if you don't assign it now, you'll have to do it later to have an effect on the users",
    unsavedTiers          : 'There are unsaved changes in the associated Tiers',
    addChannelID          : 'Add Channel ID',
    numberOfMonths        : 'No. of Months',
    overrideCosts         : 'Override costs',
    overrideCostsWarning  :
        'When enabled, whatever payment option the client selected will be sent to the reservation system as the reservation cost as well',
    channelId   : 'Channel ID',
    noClubs     : 'There are no clubs added',
    createTitle : 'Create Club',
    editTitle   : 'Edit Club',
    wizard      : {
        step1        : 'Add a name for the Club',
        step2        : 'Choose the duration type',
        step2Fixed   : 'The club will last for a number of months, starting from the creation date',
        step2Dynamic : 'The club will last between the dates selected',
        step3        : 'Choose if the system should override the costs',

        step4 : 'Administrate the tags of the club',
        step5 : 'Administrate the tiers associated to the club',
        step6 : 'Administrate the balance types that the client will use with this club',
    },

    enable: {
        success : 'The club has been enabled',
        error   : 'The club could not be enabled',
    },
    disable: {
        success : 'The club has been disabled',
        error   : 'The club could not be disabled',
    },
    delete: {
        confirmation               : 'Delete club?',
        success                    : 'The club has been removed',
        error                      : 'The club could not be removed',
        errorEntityCannotBeDeleted : 'This Club could not be removed since it is being used',
        defaultError               : 'The club could not be removed',
    },

    tiers: {
        title     : 'Associated Tiers',
        empty     : 'There are no tiers',
        associate : 'Associate tier',
        remove    : 'Dissociate',

        initializationError : 'The club tiers could not be initialized',
        addTierError        : 'The tier could not be added',

        delete: {
            confirmation : 'Dissociate tier?',
            success      : 'The tier has been dissociated',
            error        : 'The tier could not be removed',
            defaultError : 'The tier could not be removed',
        },
    },

    balanceTypes: {
        title     : 'Balance Types',
        empty     : 'There are no balance types',
        associate : 'Associate balance type',
        remove    : 'Dissociate',

        initializationError : 'The balance types could not be initialized',
        addBalanceTypeError : 'The balance types could not be added',

        delete: {
            confirmation : 'Dissociate balance type?',
            success      : 'The balance type has been dissociated',
            error        : 'The balance type could not be dissociated',
            defaultError : 'The balance type could not be dissociated',
        },
    },
    title: {
        new     : 'New',
        edition : 'Edit',
    },
});
