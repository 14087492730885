import { useContext, useLayoutEffect } from 'react';
import PanelContext from 'State/panelContext';

const useEvent = (ref, event, callback, condition) => {
    const { eventManager } = useContext(PanelContext);

    useLayoutEffect(() => {
        eventManager.subscribe(ref, event, callback, condition);
        return () => {
            eventManager.unsubscribe(ref);
        };
    }, [callback, event, eventManager, ref, condition]);
};

export const useEventClick = (ref, callback, condition) => {
    useEvent(ref, 'click', callback, condition);
};

export const useEventScroll = (ref, callback, condition) => {
    useEvent(ref, 'scroll', callback, condition);
};

export const useEventMouseWheel = (ref, callback, condition) => {
    useEvent(ref, 'mousewheel', callback, condition);
};

export const useEventClickOutside = (ref, callback) => {
    const wrappedCallback = (e) => {
        const isInsideClick = ref && ref.contains(e.target);
        if (!isInsideClick) {
            callback(e);
        }
    };
    useEventClick(ref, wrappedCallback);
};

export default useEvent;

