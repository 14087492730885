import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Number({
    label, value, onChange, min, max, onFocus, onBlur, isReadOnlyWithoutInputs, isDisabled, id, innerInputRef,
}) {
    const handleOnChange = (e) => {
        const valueWithoutDash = e.target.value.replace(/-/g, '');
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(valueWithoutDash)) {
            e.target.value = value;
            return null;
        }

        return e.target.value >= min || e.target.value <= max ? onChange(e) : null;
    };

    return (
        <>
            {!isReadOnlyWithoutInputs && (
                <input
                    ref={innerInputRef}
                    id={id}
                    type="text"
                    min={min}
                    max={max}
                    value={value}
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={isDisabled ? '-1' : '0'}
                    autoComplete="off"
                />
            )}
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {isReadOnlyWithoutInputs && <span id={id}>{value}</span>}
        </>
    );
}

Number.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    min                     : '',
    max                     : '',
    onChange                : null,
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled    : false,
    innerInputRef : null,
};

Number.propTypes = {
    id                      : PropTypes.string,
    value                   : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    min                     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max                     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadOnlyWithoutInputs : PropTypes.bool,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Number);
