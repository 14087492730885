import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Modal from 'Components/Modal';
import Form from 'Components/Form';
import dynamicInputGenerator from 'Components/Form/Input/dynamicInputGenerator';
import panelContext from 'State/panelContext';

function ExtraAttributesModal({ items, selected, onClose }) {
    const { dateManager } = useContext(panelContext);

    const extraAttributesComponents = dynamicInputGenerator(items, null, null, dateManager);

    return (
        <Modal
            title={selected.name.value}
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : onClose,
            }}
            className="extra-attributes-modal"
        >
            <Form.Wrapper>
                <Form isReadOnlyWithoutInputs isReadOnly>
                    <Form.Column
                        key="extra-attribute-form"
                        width={{ base: 12 }}
                        className="extra-attribute-form"
                    >
                        <Form.Title key="extra-attributes-title" isSmall className="margin-bottom-small">
                            <FormattedMessage defaultMessage={translated.extraAttributes.title} id={translated.extraAttributes.title} />
                        </Form.Title>

                        {extraAttributesComponents}
                    </Form.Column>
                </Form>
            </Form.Wrapper>
        </Modal>
    );
}

ExtraAttributesModal.defaultProps = { items: null };

ExtraAttributesModal.propTypes = {
    items    : PropTypes.shape({}),
    selected : PropTypes.shape({}).isRequired,
    onClose  : PropTypes.func.isRequired,
};

ExtraAttributesModal.displayName = 'ExtraAttributesModal';

export default ExtraAttributesModal;
