import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';
import Form, { FormError } from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';

function getParams(data) {
    const title = translated.global.buttons.edit;
    const primaryButtonText = translated.global.buttons.save;
    const resource = data.links.self.patch;
    const conversion = {
        id     : data.id,
        reason : data.reason,
    };

    return { title, resource, conversion, primaryButtonText, shouldReloadData: true };
}

export function ReasonEditor({ data }) {
    const { navigator, snackbar } = useContext(PanelContext);
    const [isLoading, setIsLoading] = useState(false);

    const { title, resource, conversion, primaryButtonText, shouldReloadData } = getParams(data);

    const handleOnSubmit = async (submitData) => {
        setIsLoading(true);
        try {
            await navigator.requestAndSetToParent({ data: submitData.values, resource, shouldReloadData });
        } catch (e) {
            setIsLoading(false);
            throw new FormError(translated.conversions.reasonModal.error.update, e);
        }
    };

    const onFinish = () => {
        snackbar.show({ content: translated.conversions.reasonModal.success.update, isSuccess: true });

        navigator.goToParentAndReload(false, false);
    };

    const modalProperties = {
        title,
        isLoading,
        buttons: [
            <Form.Secondary key="fs" isDisabled={isLoading} variant="text" color="primary" onClick={() => navigator.goToParentAndReload(false, false)}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary key="fp" variant="text" color="primary" isDisabled={isLoading}>
                <FormattedMessage id={primaryButtonText} defaultMessage={primaryButtonText} />
            </Form.Primary>,
        ],
    };

    return (
        <Form.Wrapper modalProperties={modalProperties}>
            <Form className="margin-top-small" id="reason" onSubmit={handleOnSubmit} onFinish={onFinish}>
                {conversion.id && <Form.Hidden submitKey="id" value={conversion.id} />}
                <Form.Input
                    isDense
                    id="notes-text"
                    submitKey="reason"
                    type="text"
                    label={translated.conversions.reasonModal.text}
                    value={conversion.reason}
                    charCount={{ total: 100 }}
                    maxLength={100}
                    validations={{ maxLength: 100 }}
                />
            </Form>
        </Form.Wrapper>
    );
}

ReasonEditor.defaultProps = { data: null };

ReasonEditor.propTypes = { data: PropTypes.shape({}) };

export default withRequest(ReasonEditor);
