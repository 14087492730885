module.exports = Object.freeze({
    title     : 'Notifications',
    emptyList : 'There are no notifications',

    markAsReadNoLink : 'It is not possible to mark the notification as read.',
    markAsReadError  : 'The notification could not be marked as read',

    navigateToNotificationError: "It is impossible to access the owner's balance",

    notificationConversion: 'did a Points Conversion to',

    updateNotificationNoLink   : 'The notifications could not be initialized',
    updateNotificationGetError : 'The notifications could not be initialized',

    callNotification: {
        title        : 'Incoming call',
        mobile       : 'Mobile',
        phone        : 'Phone',
        callFrom     : 'Call from',
        unknownOwner : 'unknown owner',

        goToProfile: 'Go to profile',
    },

    markAsRead: 'Mark as read',
});
