import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fetchingShape } from 'Constants/PropTypes';
import { sectionType } from 'Constants/types';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import Modal from 'Components/Modal';
import Table from 'Components/Table';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Subsection from 'Components/Sections/Subsection';
import Alert from 'Components/Alert';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
import Note from './Note';

const getNotesTableConfig = (data, handleClickEditNote, handleClickOpenDeleteNote, handleClickReadNote) => {
    const columns = [
        {
            title     : translated.owners.notes.creator,
            key       : 'fullName',
            isVisible : true,
        },
        {
            title      : translated.owners.notes.creationDate,
            key        : 'creationDate',
            isVisible  : true,
            isDateTime : true,
        },
        {
            title     : translated.owners.notes.category,
            key       : 'category',
            subkey    : 'name',
            isVisible : true,
        },
        {
            title       : translated.owners.notes.details,
            key         : 'note',
            isVisible   : true,
            hasMaxWidth : true,
        },
    ];

    const items = !data || !data.length ? [] : data.map((e) => ({ ...e, fullName: `${e?.agent?.user?.firstName} ${e?.agent?.user?.lastName}` }));

    const actions = [
        {
            content  : translated.global.buttons.edit,
            callback : (note) => handleClickEditNote(note),
            key      : 'edit',
            icon     : 'Pencil',
            tooltip  : translated.global.buttons.edit,
            when     : (note) => note?.links?.self?.update,
        },
        {
            content  : translated.global.buttons.delete,
            key      : 'delete',
            callback : (note) => handleClickOpenDeleteNote(note),
            when     : (note) => note?.links?.self?.delete,
        },
        {
            content           : translated.global.buttons.view,
            callback          : (note) => handleClickReadNote(note),
            key               : 'View',
            isOutsideDropdown : true,
            icon              : 'Eye',
            tooltip           : translated.global.buttons.view,
            when              : (note) => note?.links?.self?.read,
        },
    ];

    return { items, rows: { actions }, columns };
};

// Just a modal to show the info of one note
function ModalNote({
    category, note, creationDate, agent, id, onClose, dateManager,
}) {
    return (
        <Modal
            title={translated.owners.notes.title}
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : onClose,
            }}
            className="modal-note-view"
        >
            <table className="modal-details-table">
                <tbody>
                    <tr>
                        <td id="notes-details-creator-label" className="details-label">
                            <FormattedMessage id={translated.owners.notes.creator} defaultMessage={translated.owners.notes.creator} />
                        </td>
                        <td id="notes-details-creator-data" className="details-data">
                            {agent}
                        </td>
                    </tr>
                    <tr>
                        <td id="notes-details-category-label" className="details-label">
                            <FormattedMessage id={translated.owners.notes.category} defaultMessage={translated.owners.notes.category} />
                        </td>
                        <td id="notes-details-category-data" className="details-data">
                            {category}
                        </td>
                    </tr>
                    <tr>
                        <td id="notes-details-creation-date-label" className="details-label">
                            <FormattedMessage id={translated.owners.notes.creationDate} defaultMessage={translated.owners.notes.creationDate} />
                        </td>
                        <td id="notes-details-creation-date-data" className="details-data">
                            {dateManager.format(creationDate, 'PPpp')}
                        </td>
                    </tr>
                    <tr>
                        <td id="notes-details-id-label" className="details-label">
                            <FormattedMessage id={translated.owners.notes.id} defaultMessage={translated.owners.notes.id} />
                        </td>
                        <td id="notes-details-id-data" className="details-data">
                            {id}
                        </td>
                    </tr>
                </tbody>
            </table>
            {note && (
                <div id="notes-details-text" className="margin-top-small">
                    {note}
                </div>
            )}
        </Modal>
    );
}

ModalNote.propTypes = {
    category     : PropTypes.string,
    note         : PropTypes.string,
    creationDate : PropTypes.string,
    agent        : PropTypes.string,
    id           : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose      : PropTypes.func.isRequired,
};

ModalNote.defaultProps = {
    category     : '',
    note         : '',
    creationDate : '',
    agent        : '',
    id           : '',
};

export function OwnersNotes({ fetching, data, resources, sections, isEmbedded, reloadData }) {
    const { navigator, snackbar, confirmation, dateManager } = useContext(PanelContext);
    const { available } = resources;
    const [modalNoteData, setModalNoteData] = useState({
        isVisible    : false,
        agent        : '',
        category     : '',
        note         : '',
        creationDate : '',
        id           : '',
    });

    const { order, pagination, data: notes } = data || {};

    const handleClickPerformDelete = async (note) => {
        try {
            const reqConfig = {
                ...note.links.self.delete,
                isCritical       : false,
                isGlobal         : false,
                ids              : [note.id],
                shouldReloadData : true,
            };
            await navigator.requestForCurrentPath({
                reqConfig,
                isEmbedded,
                section: sectionType.NOTES,
                resources,
            });
            snackbar.show({
                content   : translated.owners.notes.success.delete,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content : translated.owners.notes.error.delete,
                isError : true,
                error   : e,
            });
        }
    };

    const handleClickOpenDeleteNote = (noteToDelete) => {
        confirmation.show({
            // eslint-disable-next-line max-len
            message       : translated.owners.notes.deleteConfirmation,
            acceptMessage : translated.global.buttons.delete,
            onAccept      : () => handleClickPerformDelete(noteToDelete),
        });
    };

    const noteProps = sections[sectionType.NOTE];
    const isEditorModalOpen = noteProps.isEnabled && noteProps.isSelected;

    const handleClickEditNote = (noteToEdit) => {
        navigator.goToNoteEditor({ available: noteToEdit.links.self, current: 'read', section: sectionType.NOTES, isEmbedded });
    };

    const handleClickNewNote = () => {
        navigator.goToNoteEditor({ available, isEmbedded, current: 'init', section: sectionType.NOTES });
    };

    const handleClickCloseModal = () => {
        setModalNoteData({ ...modalNoteData, isVisible: false });
    };

    const handleClickReadNote = (noteToRead) => {
        setModalNoteData({ isVisible: true, ...noteToRead, agent: noteToRead.fullName, category: noteToRead.category.name });
    };

    const { columns, rows, items } = getNotesTableConfig(notes, handleClickEditNote, handleClickOpenDeleteNote, handleClickReadNote);

    const notesActions = [];

    if (items?.length > 0 && available?.create) {
        notesActions.push(
            <Button
                id="notes-add-button"
                key="add"
                variant="outlined"
                color="primary"
                disabled={isEditorModalOpen}
                onClick={handleClickNewNote}
                isLoading={sections[sectionType.NOTE].fetching.isGlobal}
            >
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        );
    }

    const alertProps = { content: translated.owners.notes.empty };
    if (items?.length === 0 && available?.create) {
        alertProps.actionText = translated.global.buttons.add;
        alertProps.onClick = () => handleClickNewNote();
    }

    return (
        <>
            {modalNoteData.isVisible && <ModalNote {...modalNoteData} onClose={handleClickCloseModal} dateManager={dateManager} />}
            <Subsection
                className="owners-notes"
                actionButtons={notesActions}
                title={translated.owners.notes.title}
            >
                {isEditorModalOpen && <Note {...noteProps} />}
                <Table
                    id="notes"
                    key="notes"
                    columns={columns}
                    rows={rows}
                    items={items}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    whenEmpty={<Alert id="notes-empty" {...alertProps} />}
                />
            </Subsection>
        </>
    );
}

OwnersNotes.defaultProps = { error: null, data: [] };
OwnersNotes.propTypes = {
    data: PropTypes.shape({
        notes : PropTypes.arrayOf(PropTypes.shape({})),
        links : PropTypes.shape({}),
    }),
    resources  : PropTypes.shape({}).isRequired,
    error      : PropTypes.shape({}),
    fetching   : fetchingShape.isRequired,
    sections   : PropTypes.shape({}).isRequired,
    isEmbedded : PropTypes.bool.isRequired,
    reloadData : PropTypes.func.isRequired,
};

OwnersNotes.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isSubHeading />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(OwnersNotes);
