module.exports = Object.freeze({
    BL05005 : 'userSelfReferred',
    BL05102 : 'errorContractTypeInUse',
    BL05110 : 'errorEntityCannotBeDeleted',
    BL05113 : 'errorEntityCannotBeModified',
    BL05115 : 'transactionCantBeErased',
    BL05116 : 'transactionCantBeErased',
    BL05119 : 'contractLegacyIdInUse',
    BL05126 : 'anniversaryDatesAreNotEditable',
    BL05127 : 'datesAreNotEditable',
    BL05130 : 'conversionRuleExternalClubInUse',
    BL05145 : 'incompatibleBalanceCurrency',
    BL05148 : 'entityIsReadonly',
    BL05149 : 'entityCodeRepeated',
    BL05150 : 'insufficientBalanceFounds',
    MO02002 : 'entityAlreadyExists',
    MO02005 : 'errorEntityNotFound',
    MO02014 : 'errorUserRelationshipWithItself',
    MO02016 : 'errorResourceNotActive',
    DB01020 : 'entityAlreadyExists',

    APT401 : 'errorUnauthorized',
    APT403 : 'errorForbidden',
    APT424 : {
        default : 'failedDependency',
        100     : 'channelIdRequired',
    },
    APT500 : 'errorBadRequest',
    APT602 : 'failedDependency',
    APT604 : 'noArmnetId',
    APT605 : 'clientHasDebt',

    HS00401 : 'errorUnauthorized',
    HS00403 : 'errorForbidden',
    HS00500 : 'errorBadRequest',
    SC06024 : 'entityInUse',
    SC06030 : 'errorFundsAmount',
    SC06016 : 'errorAlphanumeric',
    SC06040 : 'exchangeOverrideRuleOneRulePerClub',
    SC06042 : 'sameToken',

    status: {
        400 : 'errorBadRequest',
        401 : 'errorUnauthorized',
        403 : 'errorForbidden',
        404 : 'errorBadRequest',
        500 : 'errorBadRequest',
        502 : 'errorServerFails',
        599 : 'errorServerFails',
    },

    codes: {
        forbiden                  : 403,
        unauthorized              : 401,
        aborted                   : 'ECONNABORTED',
        APTForbiden               : 'APT403',
        balanceUnpaid             : 'APT605',
        futureBalanceUnpaid       : 'APT606',
        ownerNoReservationAllowed : 'APT607',
        failedDependency          : 424,
        underMaintenance          : 503,
        errorFundsAmount          : 'SC06030',
    },

});
