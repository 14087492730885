import { useRef, useContext, useCallback } from 'react';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import globalConstants from 'Constants/global';

async function requestData({ navigator, requestForSection, onRequestSuccess }) {
    try {
        await navigator.requestForCurrentPath(requestForSection);
        if (onRequestSuccess) {
            onRequestSuccess();
        }
        return true;
    } catch (error) {
        return false;
    }
}

export default function useDataLoader(pathForResource, currentSection, data = {}, sectionResources, requestTimeout) {
    const { navigator, url, snackbar } = useContext(PanelContext);
    const resources = sectionResources ? { ...sectionResources, current: sectionResources.current || 'read' } : { available: {}, current: 'read' };
    const isCurrentlyEditing = !!data?.id;
    const wasPreviouslyEditing = useRef(!!data?.id);

    if (data?.additionalResources) {
        const keys = Object.keys(data);
        let allAdditionalResources = {};

        keys.forEach((key) => {
            if (key === 'additionalResources') {
                allAdditionalResources = { ...allAdditionalResources, ...data.additionalResources };
            }
            if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
                if (data[key].additionalResources) {
                    allAdditionalResources = { ...allAdditionalResources, ...data[key].additionalResources };
                }
            }
        });
        resources.additional = allAdditionalResources;
    }

    if (isCurrentlyEditing && !wasPreviouslyEditing.current && data?.links) {
        // When a resource is being edited, we have to setting in the available resources the new ones, otherwise still will use
        // the older ones, this new availableResources will be passed as prop to the section as availableResources.
        wasPreviouslyEditing.current = true;
        resources.current = 'read';
        resources.available = data.links.self;
    }

    const loadDataInSection = useCallback(
        async (reloadResource = null, allAvailableResources = {}, retryNumber = null) => {
            if (!resources.available?.[resources.current] && !reloadResource) {
                // There is no link to load
                url.setShouldReloadUrlStage(globalConstants.DONE);
                return false;
            }

            const requestLink = reloadResource || resources.available[resources.current];
            const reqConfig = { ...requestLink, isCritical: true, isGlobal: true, ids: [], requestTimeout, retryNumber };

            if (reloadResource) {
                resources.available = { ...resources.available, ...allAvailableResources, [resources.current]: reloadResource };
            }

            let snackbarId = null;
            // When the requests might take a long time, it shows a snackbar warning about it.
            if (requestTimeout && requestTimeout >= 10000) {
                snackbarId = await snackbar.show({ content: translated.global.longRequestWarning });
            }

            return requestData({
                navigator,
                requestForSection : { path: pathForResource, currentSection, reqConfig, resources },
                // Hides the snackbar warning of the long request when the request is finished.
                onRequestSuccess  : snackbarId
                    ? () => {
                        snackbar.hide(snackbarId);
                    }
                    : null,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentSection, pathForResource, navigator, url, resources.current],
    );

    return [resources, loadDataInSection];
}
