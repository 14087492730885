import * as types from 'State/types/qa';

export function setQAActive() {
    return { type: types.SET_QA_ACTIVE };
}

export function appLoaded() {
    return { type: types.APP_LOADED };
}

export function sectionLoaded(path, data) {
    return { type: types.SECTION_LOADED, payload: { path, data } };
}
