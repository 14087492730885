import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Wrapper from 'Components/Sections/Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Alert from 'Components/Alert';

const getClubsColumns = () => [
    {
        title       : translated.global.name,
        key         : 'name',
        isVisible   : true,
        hasMaxWidth : true,
    },
    {
        title    : translated.global.status,
        key      : 'isActive',
        whenTrue : {
            text      : translated.global.state.enabled,
            className : 'status status-enabled',
        },
        whenFalse: {
            text      : translated.global.state.disabled,
            className : 'status status-disabled',
        },
        isVisible: true,
    },
    {
        title     : translated.global.id,
        key       : 'id',
        isVisible : true,
        isNumeric : true,
    },
    {
        title           : translated.global.tags,
        key             : 'tagNames',
        isVisible       : true,
        showTooltipWhen : 80,
        customClassName : 'text-overflow',
    },
];

// eslint-disable-next-line no-unused-vars
const getClubsActions = (data, navigator, confirmation, snackbar, handleClickChangeStatus, resources, intl) => [
    {
        content  : translated.global.buttons.disable,
        callback : (club) => handleClickChangeStatus(club, false),
        key      : 'disable',
        when     : (club) => club?.links?.self?.patch && club.isActive,
    },
    {
        content  : translated.global.buttons.enable,
        callback : (club) => handleClickChangeStatus(club, true),
        key      : 'enable',
        when     : (club) => club?.links?.self?.patch && !club.isActive,
    },
    {
        content           : translated.global.buttons.edit,
        callback          : (club) => navigator.goToClubEditor({ available: club && club.links ? club.links.self : {} }),
        key               : 'edit',
        isOutsideDropdown : true,
        icon              : 'Pencil',
        tooltip           : intl.formatMessage({
            id             : translated.global.buttons.edit,
            defaultMessage : translated.global.buttons.edit,
        }),
        when: (club) => club?.links?.self?.update,
    },
    {
        isEnabled : true,
        content   : translated.global.buttons.delete,
        when      : (clubTier) => clubTier?.links?.self?.delete,
        callback  : (club) => {
            confirmation.show({
                message       : translated.clubs.delete.confirmation,
                acceptMessage : translated.global.buttons.delete,
                onAccept      : async () => {
                    const { id } = club;
                    const reqConfig = {
                        ...club.links.self.delete,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [id],
                        shouldReloadData : true,
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS, resources });

                        snackbar.show({
                            content   : translated.clubs.delete.success,
                            isSuccess : true,
                        });
                    } catch (error) {
                        snackbar.show({
                            content     : translated.clubs.delete.defaultError,
                            errorLabels : translated.clubs.delete,
                            isError     : true,
                            error,
                        });
                    }
                },
            });
        },
        key: 'delete',
    },
];

function ClubsList({ data, fetching, reloadData, resources, setSectionState, state: { appliedFilters } }) {
    const intl = useIntl();
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const handleOnAddClick = () => navigator.goToClubEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
    const handleClickChangeStatus = async (club, enable) => {
        const { id, links } = club;
        const reqConfig = {
            ...links.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CLUBS, resources });

            snackbar.show({
                content   : enable ? translated.clubs.enable.success : translated.clubs.disable.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.clubs.enable.error : translated.clubs.disable.error,
                isError : true,
                error,
            });
        }
    };

    const { links, data: clubs, order, pagination, filter: filterLinks } = data || {};

    // The order of the array determines the order of the columns
    const columns = getClubsColumns();
    const rows = { actions: getClubsActions(clubs, navigator, confirmation, snackbar, handleClickChangeStatus, resources, intl) };

    const convertedClubs = clubs.map((eachClub) => ({
        ...eachClub,
        tagNames: eachClub.tags
            ?.map((e) => e.label)
            .sort()
            .join(', '),
    })) || [];

    return (
        <Wrapper
            title={translated.clubs.title.section}
            actionButtons={
                links?.self?.create
                    ? [
                        <Button id="clubs-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                            <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                        </Button>,
                    ]
                    : []
            }
        >
            <Table
                id="clubs"
                key="clubs"
                columns={columns}
                rows={rows}
                items={convertedClubs}
                onSearch={() => {
                    // Default
                }}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                whenEmpty={
                    <Alert id="clubs-empty" content={translated.clubs.noClubs} actionText={translated.global.buttons.add} onClick={handleOnAddClick} />
                }
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                filterResources={{
                    filters: [
                        {
                            id               : 'clubs-filter-tags',
                            type             : 'search',
                            submitKey        : 'slug',
                            resourceKey      : 'slug',
                            onChangeKey      : 'slug',
                            showLoading      : true,
                            className        : 'tags-filter',
                            searchValidation : (newValue) => newValue && newValue.length > 2,
                            searchFunction   : async (tagName) => {
                                const tags = filterLinks?.additionalResources?.tags?.map((tag) => ({ ...tag, lower: tag.label.toLowerCase() })) || [];
                                const lowerTag = tagName.toLowerCase();
                                const found = tags.filter((tag) => tag.lower.includes(lowerTag));

                                return found?.map((tag) => ({ ...tag, display: tag.label }));
                            },
                            renderSearch: (tag) => {
                                if (!tag) {
                                    return (
                                        <span className="no-results">
                                            <FormattedMessage id={translated.tags.empty} defaultMessage={translated.tags.empty} />
                                        </span>
                                    );
                                }

                                return <span className="result-user-name">{`${tag.label}`}</span>;
                            },
                        },
                    ],
                    supportText: intl.formatMessage({
                        id             : translated.tags.search,
                        defaultMessage : translated.tags.search,
                    }),
                    filterLinks,
                    resources,
                }}
            />
        </Wrapper>
    );
}

ClubsList.defaultProps = {
    data            : [],
    fetching        : {},
    setSectionState : () => {
        // Default
    },
    state: {},
};

ClubsList.propTypes = {
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

ClubsList.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={120} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(ClubsList);
