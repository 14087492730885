import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Subsection from 'Components/Sections/Subsection';
import Form, { FormError, FormNew } from 'Components/Form';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import PanelContext from 'State/panelContext';
import withRequest from 'Components/Sections/withRequest';
import { REFRESH_NOW } from 'Constants/global';
import yup, { email } from 'Utils/yupHelper';
import { FormattedMessage } from 'react-intl';

const schema = yup.object().shape({
    user: yup.object().shape({
        firstName  : yup.string().required().max(100),
        lastName   : yup.string().required().max(100),
        email      : email.required().max(255),
        loginToken : yup.string().max(255)
            .ignoreChar(translated.global.invalidChar, [' ']),
    }),
    role       : yup.number().required(),
    department : yup.number().required(),
});

export function Editor({ data, resources, isEditing, name: sectionName }) {
    const { navigator, snackbar, app, user: loggedUser } = useContext(PanelContext);
    const { user, role: userRole, department } = data;
    const { additional } = resources;
    const isCreate = !!resources.available?.create;
    const departments = additional?.departments.map((dept) => ({ value: dept.id, content: dept.name }));

    const roles = additional?.roles?.filter((role) => role.isActive)?.map((role) => ({ value: role.id, content: role.name })) || [];

    if (userRole?.id && !roles?.find((e) => e.value === userRole?.id)) {
        roles.push({
            value   : userRole.id,
            content : userRole.name,
        });
    }

    const initialValues = {
        user: {
            firstName  : user?.firstName || '',
            lastName   : user?.lastName || '',
            email      : user?.email || '',
            loginToken : user?.loginToken || '',
        },
        role       : userRole?.id,
        department : department?.id,
    };

    const handleOnSubmit = async (values) => {
        const newData = {
            user: {
                ...values.user,
                loginToken      : values?.user?.loginToken || null,
                phone           : null,
                mobile          : null,
                extraAttributes : user?.extraAttributes || {},
                id              : isEditing ? user?.id : undefined,
            },
            role       : { id: values.role },
            department : { id: values.department },
        };

        const resourceForSubmit = isEditing ? resources.available?.update : resources.available?.create;
        const reqConfig = { data: newData, ...resourceForSubmit, shouldReloadData: true };
        try {
            await navigator.requestForCurrentPath({ reqConfig, resources });
        } catch (e) {
            throw new FormError(translated.agents.userNotSaved, e, translated.agents.errors);
        }
    };

    const handleOnFinish = () => {
        // The user is editing his own permissions, need to refresh
        if (loggedUser?.id && String(loggedUser?.id) === String(data.user?.id)) {
            app.refreshUser(REFRESH_NOW);
            snackbar.show({ content: translated.agents.onYourPermissionsChange });
        } else {
            snackbar.show({ content: isCreate ? translated.agents.success.creation : translated.agents.success.edition, isSuccess: true });
        }

        navigator.goToParentAndReload();
    };

    const canMakeChanges = isEditing ? !!resources.available?.update : !!resources.available?.create;

    const title = (isEditing && (canMakeChanges ? translated.agents.title.edition : translated.agents.title.view))
        || translated.agents.title.new;
    const titleValues = isEditing ? { firstName: user?.firstName, lastName: user?.lastName } : { name: sectionName };

    const commonProps = { isDisabled: !canMakeChanges };

    const formButtons = useMemo(
        () => (canMakeChanges
            ? [
                <Form.SecondaryNew key="fs" onClick={navigator.goToRoot} />,
                <Form.PrimaryNew key="fp" />,
            ]
            : [
                <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                    <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
                </Form.SecondaryNew>,
            ]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canMakeChanges],
    );

    return (
        <Form.WrapperNew
            schema={schema}
            initialValues={initialValues}
            formId="agent-form"
        >
            <SectionsWrapper title={title} titleValues={titleValues} actionButtons={formButtons}>
                <Subsection title={translated.global.information} className="tier-subsection margin-top-medium">
                    <FormNew buttonsWidth={{ base: 12, small: 6 }} onFinish={handleOnFinish} onSubmit={handleOnSubmit}>
                        <Form.ColumnNew width={{ base: 12, small: 6 }}>
                            <Form.InputNew
                                isDense
                                submitKey="user.firstName"
                                type="text"
                                label={translated.global.firstName}
                                charCount={{ total: 100 }}
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="user.lastName"
                                type="text"
                                label={translated.global.lastName}
                                charCount={{ total: 100 }}
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="user.email"
                                type="text"
                                label={translated.global.email}
                                charCount={{ total: 255 }}
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="user.loginToken"
                                type="text"
                                label={translated.global.loginToken}
                                charCount={{ total: 255 }}
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="role"
                                value={userRole?.id}
                                type="select"
                                label={translated.global.role}
                                options={roles}
                                sortBy="content"
                                {...commonProps}
                            />
                            <Form.InputNew
                                isDense
                                submitKey="department"
                                type="select"
                                label={translated.global.department}
                                options={departments}
                                {...commonProps}
                            />
                        </Form.ColumnNew>
                    </FormNew>
                </Subsection>
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={224} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={135} className="margin-bottom-small" />
                    <Skeleton.Form type="input" quantity={6} />
                </Grid.Column>
            </Grid>
        </>
    );
};

Editor.defaultProps = { name: null };

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    name      : PropTypes.string,
};

export default withRequest(Editor);
