function mustTranslate(key) {
    return typeof key === 'string' && key.startsWith('apf');
}

function getMessage(intl, key) {
    if (intl && mustTranslate(key)) {
        return intl?.formatMessage({ id: key, defaultMessage: key });
    }

    return key;
}

export { mustTranslate, getMessage };
