import { mdiAccountCircle } from '@mdi/js';
import { sectionType, resourceNames } from 'Constants/types';

const partnerId = null;
const contractId = null;
const noteId = null;
const ruleId = null;
const ruleType = null;
const userId = null;

export default {
    [sectionType.PARTNERS]: {
        isEnabled : false,
        config    : {
            url  : '/panel/partners',
            icon : mdiAccountCircle,
        },
        sections: {
            [sectionType.PARTNERS_LIST]: {
                isRoot    : true,
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                resources : { templates: { name: resourceNames.PARTNERS } },
            },
            [sectionType.PARTNERS_EDITOR]: {
                config   : { isHiddenInBreadcrumb: true },
                sections : {
                    [sectionType.PARTNERS_GENERAL]: {
                        resources : { templates: { name: resourceNames.PARTNER } },
                        ids       : { partnerId, self: 'partnerId' },
                        config    : {
                            url                : { withId: '/(\\d+)', withoutId: '/add' },
                            isDefaultSection   : true,
                            hiddenInBreadcrumb : true,
                        },
                    },
                },
            },
            [sectionType.PARTNERS_DETAILS]: {
                resources : { templates: { name: resourceNames.PARTNER } },
                ids       : { partnerId, self: 'partnerId' },
                config    : {
                    url                  : '/(\\d+)/view',
                    isHiddenInBreadcrumb : true,
                    cleanWhenLeaving     : sectionType.PARTNERS_DETAILS,
                },
                sections: {
                    [sectionType.PARTNERS_INFO]: {
                        ids       : { partnerId },
                        resources : { name: resourceNames.PARTNER, templates: { name: resourceNames.PARTNER } },
                        config    : {
                            hasOnlyTypeInBreadcrumb : true,
                            isDefaultSection        : true,
                            url                     : '/info',
                            resourceName            : resourceNames.SELF,
                            isTab                   : true,
                        },
                        sections: {
                            [sectionType.ENTITY_TAGS]: {
                                ids             : { partnerId },
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.PARTNERS_INFO, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                        },
                    },
                    [sectionType.BOOKINGS]: {
                        ids       : { partnerId },
                        resources : { name: resourceNames.PARTNER_BOOKINGS, templates: { name: resourceNames.PARTNER_BOOKINGS } },
                        config    : {
                            url            : '/bookings',
                            resourceName   : resourceNames.BOOKINGS,
                            requestTimeout : 20000,
                            handledErrors  : {
                                status : [424],
                                code   : ['APT602'],
                            },
                            isTab: true,

                        },
                    },
                    [sectionType.WALLET]: {
                        ids       : { partnerId },
                        resources : { name: resourceNames.WALLET, templates: { name: resourceNames.PARTNER_WALLET } },
                        config    : {
                            url          : '/balance',
                            resourceName : resourceNames.WALLET,
                            isTab        : true,
                        },
                        sections: { [sectionType.WALLET_MANAGER]: { config: { resourceName: resourceNames.POINTS, isModal: true } } },
                    },
                    [sectionType.NOTES]: {
                        ids       : { partnerId },
                        resources : { name: resourceNames.PARTNER_NOTES, templates: { name: resourceNames.PARTNER_NOTES } },
                        config    : {
                            url          : '/notes',
                            resourceName : resourceNames.NOTES,
                            isTab        : true,
                        },
                        pathForResource : [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.NOTES],
                        sections        : {
                            [sectionType.NOTE]: {
                                ids    : { partnerId, noteId, self: 'noteId' },
                                config : { url: { withId: '/(\\d*)*', withoutId: '/add' }, isModal: true },
                            },
                        },
                    },
                    [sectionType.PARTNERS_CONTRACTS]: {
                        ids       : { partnerId },
                        resources : { templates: { name: resourceNames.PARTNER_CONTRACTS } },
                        config    : {
                            url          : '/contracts',
                            resourceName : resourceNames.CONTRACTS,
                            isTab        : true,
                        },
                        pathForResource : [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.PARTNERS_CONTRACTS],
                        customProps     : { isPartner: true },
                    },
                    [sectionType.CONTRACT_EDITOR]: {
                        ids       : { partnerId, contractId, self: 'contractId' },
                        resources : { templates: { name: resourceNames.PARTNER_CONTRACT } },
                        config    : {
                            url          : '/contracts/upgrade/(\\d*)*',
                            resourceName : resourceNames.OWNER_UPGRADE_DOWNGRADE,
                        },
                        customProps: { navigateToParent: 'goToPartnerContracts' },
                    },
                    [sectionType.CLIENT_CONTRACTS_EDITOR]: {
                        ids         : { partnerId, contractId, self: 'contractId' },
                        resources   : { templates: { name: resourceNames.PARTNER_CONTRACT } },
                        customProps : { isPartner: true, navigateToParent: 'goToPartnerContracts' },
                        config      : {
                            url          : { withId: '/contracts/(\\d*)*', withoutId: '/contracts/add' },
                            resourceName : resourceNames.CLIENT_CONTRACT,
                        },
                        sections: {
                            [sectionType.BANKING_CONFIGURATIONS]: {
                                config     : { resourceName: resourceNames.BANKING_CONFIGURATIONS },
                                isEmbedded : true,
                            },
                            [sectionType.RULES]: {
                                config     : { resourceName: resourceNames.RULES },
                                isEmbedded : true,
                            },
                            [sectionType.RULE]: {
                                ids       : { partnerId, contractId, ruleId, ruleType, self: 'ruleId' },
                                resources : { templates: { name: resourceNames.CONTRACT_RULE } },
                                config    : {
                                    url                        : { withId: '/rules/(\\d*-\\w*)*', withoutId: '/rules/add' },
                                    resourceName               : resourceNames.RULE,
                                    combinedIdKey              : 'type',
                                    hasTypePreffixInBreadcrumb : true,
                                    requestTimeout             : 40000,
                                },
                            },
                        },
                    },

                    [sectionType.USERS]: {
                        ids    : { partnerId },
                        config : {
                            url          : '/users',
                            resourceName : resourceNames.USERS,
                            isTab        : true,
                        },
                        customProps     : { navigateToUserEdition: 'goToPartnerUserEdition' },
                        resources       : { templates: { name: resourceNames.PARTNER_USERS } },
                        pathForResource : [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USERS],
                        sections        : {
                            [sectionType.RELATIONSHIP]: {
                                resources : { templates: { name: resourceNames.RELATIONSHIP } },
                                config    : { isHiddenInBreadcrumb: true },
                            },
                        },
                    },

                    [sectionType.USER]: {
                        ids    : { partnerId, userId, self: 'userId' },
                        config : {
                            url                  : { withId: '/users/(\\d*)*', withoutId: '/users/add' },
                            resourceName         : resourceNames.PARTNER_USER,
                            isHiddenInBreadcrumb : true,
                        },
                        pathForResource : [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USER],
                        resources       : { templates: { name: resourceNames.PARTNER_USER } },
                        customProps     : { navigateBack: 'goToPartnerUsers' },
                        sections        : {
                            [sectionType.ENTITY_TAGS]: {
                                config          : { resourceName: resourceNames.TAGS },
                                pathForResource : [
                                    sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USER, sectionType.ENTITY_TAGS,
                                ],
                                isEmbedded: true,
                            },
                            [sectionType.RELATIONSHIPS]: {
                                config          : { resourceName: resourceNames.RELATIONSHIPS },
                                pathForResource : [
                                    sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.USER, sectionType.RELATIONSHIPS,
                                ],
                                isEmbedded : true,
                                sections   : {
                                    [sectionType.RELATIONSHIP]: {
                                        resources : { templates: { name: resourceNames.RELATIONSHIP } },
                                        config    : { isHiddenInBreadcrumb: true },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
