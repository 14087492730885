import React from 'react';
import PropTypes from 'prop-types';

/**
 * Draws the month and year selects for each calendar.
 */
function PickerSelects({ month, onMonthSelect, year, onYearSelect }) {
    return (
        <div className="calendar-navigation-select-wrapper">
            <div className="calendar-navigation-select" onClick={onMonthSelect}>
                <span>{month}</span>
            </div>
            <div className="calendar-navigation-select" onClick={onYearSelect}>
                <span>{year}</span>
            </div>
        </div>
    );
}

PickerSelects.propTypes = {
    month         : PropTypes.string,
    onMonthSelect : PropTypes.func,
    year          : PropTypes.string,
    onYearSelect  : PropTypes.func,
};

PickerSelects.defaultProps = {
    month         : '',
    year          : '',
    onMonthSelect : () => {
        // Default
    },
    onYearSelect: () => {
        // Default
    },
};

export default React.memo(PickerSelects);
