import setDarkMode from 'Utils/setDarkMode';

export const loadState = (key) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return null;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (key, value) => {
    try {
        const serializedState = JSON.stringify(value);
        localStorage.setItem(key, serializedState);
    } catch (err) {
        // Ignore write errors;
    }
};

export const deleteState = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        // Ignore write errors;
    }
};

export const hasNotYetBeenLoaded = () => {
    try {
        const localStorageLoaded = localStorage.getItem('localStorageLoaded');
        if (localStorageLoaded === null) {
            localStorage.setItem('localStorageLoaded', true);
            return true;
        }
        return false;
    } catch (err) {
        return undefined;
    }
};

export const loadInitialAppConfiguration = (setAppState) => {
    const config = {
        isSideDrawerVisible   : !!loadState('isSideDrawerVisible'),
        isSideDrawerCollapsed : !!loadState('isSideDrawerCollapsed'),
        isDarkModeEnabled     : !!loadState('isDarkModeEnabled'),
        isTourEnabled         : !!loadState('isTourEnabled'),
        useMock               : !!loadState('useMock'),
        language              : loadState('language') || 'en',
        isLocalStorageLoaded  : true,
    };

    setDarkMode(config.isDarkModeEnabled);
    // Initial state of all this variables is 'false',
    // so if we get null it will be converted to false
    setAppState(config);
};
