const SEARCH = 'SEARCH';
const SECTION_SELECT = 'SECTION_SELECT';
const SECTION_DISABLE = 'SECTION_DISABLE';
const SECTION_STATE_SET = 'SECTION_STATE_SET';
const SECTIONS_IDS_SET = 'SECTIONS_IDS_SET';
const SECTION_SET_DATA = 'SECTION_SET_DATA';

const REQUEST_START = 'REQUEST_START';
const REQUEST_END = 'REQUEST_END';
const REQUEST_ERROR = 'REQUEST_ERROR';

const ROOT_AND_TEMPLATES_SET = 'ROOT_AND_TEMPLATES_SET';

const DIRECT_REQUEST_START = 'DIRECT_REQUEST_START';
const DIRECT_REQUEST_END = 'DIRECT_REQUEST_END';
const DIRECT_REQUEST_ERROR = 'DIRECT_REQUEST_ERROR';

const PUSH_TO_URL = 'PUSH_TO_URL';
const SET_SHOULD_RELOAD_URL_STAGE = 'SET_SHOULD_RELOAD_URL_STAGE';

const CURRENT_SECTION_COMPONENT_WITH_CHANGES_SET = 'CURRENT_SECTION_COMPONENT_WITH_CHANGES_SET';

const SECTION_SELECT_FOR_BACK_ACTION = 'SECTION_SELECT_FOR_BACK_ACTION';

export {
    SEARCH,
    SECTION_SELECT,
    SECTION_DISABLE,
    SECTION_STATE_SET,
    SECTIONS_IDS_SET,
    SECTION_SET_DATA,

    ROOT_AND_TEMPLATES_SET,

    REQUEST_START,
    REQUEST_END,
    REQUEST_ERROR,

    DIRECT_REQUEST_START,
    DIRECT_REQUEST_END,
    DIRECT_REQUEST_ERROR,

    PUSH_TO_URL,
    SET_SHOULD_RELOAD_URL_STAGE,
    CURRENT_SECTION_COMPONENT_WITH_CHANGES_SET,

    SECTION_SELECT_FOR_BACK_ACTION,
};
