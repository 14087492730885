import React from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function ProfileDetails({ className, items }) {
    return (
        <div className={`owner-details-wrapper ${className}`}>
            <div className="owner-details">
                {items.map((item) => (
                    <div className="card" key={item.title.props?.id?.replace(/ /g, '_') || item.title.replace(/ /g, '_')} id={item.id || undefined}>
                        <div className="card-title">
                            <WrappedFormattedMessage content={item.title} />
                        </div>
                        <span onClick={item.onClick} className={`${item.className || ''}`}>
                            {item.content}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

ProfileDetails.defaultProps = { className: '' };

ProfileDetails.propTypes = {
    className : PropTypes.string,
    items     : PropTypes.arrayOf(
        PropTypes.shape({
            title      : PropTypes.string,
            className  : PropTypes.string,
            content    : PropTypes.string,
            showStatus : PropTypes.bool,
        }),
    ).isRequired,
};

export default ProfileDetails;
