import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';

function Password({
    label, value, onChange, isReadOnlyWithoutInputs, onFocus, onBlur, isDisabled, id, innerInputRef,
}) {
    const intl = useIntl();

    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    return (
        <>
            {!isReadOnlyWithoutInputs && (
                <input
                    id={id}
                    type="password"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={isDisabled ? '-1' : '0'}
                    ref={innerInputRef}
                />
            )}
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {isReadOnlyWithoutInputs && <span id={id}>{value}</span>}
        </>
    );
}

Password.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    isReadOnlyWithoutInputs : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    onChange      : null,
    isDisabled    : false,
    innerInputRef : null,
};

Password.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
};

export default React.memo(Password);
