const { sectionType } = require('../types');

module.exports = Object.freeze({
    [sectionType.AGENTS]        : 'Agents',
    [sectionType.AGENTS_EDITOR] : 'Agent',
    [sectionType.AGENTS_LIST]   : 'Agents',

    [sectionType.OWNERS]                  : 'Owners',
    [sectionType.OWNERS_LIST]             : 'Owners',
    [sectionType.OWNERS_EDITOR]           : 'Owner',
    [sectionType.OWNERS_GENERAL]          : 'Owner',
    [sectionType.OWNERS_GENERAL_IMPORTED] : 'Add Owner',
    [sectionType.OWNERS_CREATOR]          : 'Owner',
    [sectionType.OWNERS_DETAILS]          : 'Owners',
    [sectionType.OWNERS_IMPORT]           : 'Import Owners',

    [sectionType.TIERS]             : 'Tiers',
    [sectionType.TIERS_LIST]        : 'Tiers',
    [sectionType.TIER]              : 'Tier',
    [sectionType.CLUBS_TIER_EDITOR] : 'Tier',

    [sectionType.CLUBS]         : 'Clubs',
    [sectionType.CLUBS_LIST]    : 'Clubs',
    [sectionType.CLUBS_EDITOR]  : 'Club',
    [sectionType.CLUBS_GENERAL] : 'Club',

    [sectionType.USERS]        : 'Users',
    [sectionType.USERS_LIST]   : 'Users',
    [sectionType.USERS_EDITOR] : 'User',

    [sectionType.CONTRACT_TYPE]    : 'Contract Type',
    [sectionType.CONTRACT_TYPES]   : 'Contract Types',
    [sectionType.WALLET]           : 'Wallet',
    [sectionType.BOOKINGS]         : 'Bookings',
    [sectionType.NOTES]            : 'Notes',
    [sectionType.NOTE]             : 'Note',
    [sectionType.INFO]             : 'Info',
    [sectionType.RULES]            : 'Rules',
    [sectionType.RULE]             : 'Rule',
    [sectionType.WALLET_MANAGER]   : 'Manage',
    [sectionType.LIST]             : 'List',
    [sectionType.DETAILS]          : 'View Details',
    [sectionType.EDITOR]           : 'Edit',
    [sectionType.GENERAL]          : 'General',
    [sectionType.TIER_EDITOR]      : 'Tier',
    [sectionType.CONVERSION_RULES] : 'Conversions',
    [sectionType.ATTACHMENTS]      : 'Attachments',
    [sectionType.ATTACHMENT]       : 'Attachment',

    [sectionType.CLIENT_CONTRACTS]        : 'Contracts',
    [sectionType.CLIENT_CONTRACTS_EDITOR] : 'Contract',

    [sectionType.RESERVATIONS]      : 'Bookings',
    [sectionType.RESERVATIONS_LIST] : 'Bookings',

    [sectionType.ROLES]        : 'Roles',
    [sectionType.ROLES_LIST]   : 'Roles',
    [sectionType.ROLES_EDITOR] : 'Role',

    [sectionType.BALANCE_TYPES]        : 'Balance Types',
    [sectionType.BALANCE_TYPES_LIST]   : 'Balance Types',
    [sectionType.BALANCE_TYPES_EDITOR] : 'Balance Types',

    [sectionType.CATEGORIES]        : 'Categories',
    [sectionType.CATEGORIES_LIST]   : 'Categories',
    [sectionType.CATEGORIES_EDITOR] : 'Category',

    [sectionType.PARTNERS]           : 'Partners',
    [sectionType.PARTNERS_LIST]      : 'Partners',
    [sectionType.PARTNERS_EDITOR]    : 'Partner',
    [sectionType.PARTNERS_GENERAL]   : 'Partner',
    [sectionType.PARTNERS_DETAILS]   : 'Partners',
    [sectionType.PARTNERS_INFO]      : 'Info',
    [sectionType.PARTNERS_USERS]     : 'Users',
    [sectionType.PARTNERS_CONTRACTS] : 'Contracts',

    [sectionType.CONTRACT]      : 'Upgrade/Downgrade',
    [sectionType.CONTRACT_LIST] : 'Upgrades/Downgrades',

    [sectionType.RELATIONSHIP]: 'Relationship Editor',

    [sectionType.EXTERNAL_CLUBS]        : 'External Clubs',
    [sectionType.EXTERNAL_CLUBS_EDITOR] : 'External Clubs',
    [sectionType.EXTERNAL_CLUBS_LIST]   : 'External Clubs',

    [sectionType.EXTRA_ATTRIBUTES]        : 'Extra Attributes',
    [sectionType.EXTRA_ATTRIBUTES_EDITOR] : 'Extra Attribute',

    [sectionType.CURRENCIES]        : 'Currencies',
    [sectionType.CURRENCIES_EDITOR] : 'Currency',

    [sectionType.SYSTEM_RULES]: 'System Rules',

    [sectionType.CONVERSIONS]: 'Conversions',

    [sectionType.TAGS]        : 'Tags',
    [sectionType.TAGS_LIST]   : 'Tags',
    [sectionType.TAGS_EDITOR] : 'Tag',
    [sectionType.ENTITY_TAGS] : 'Tags',

    [sectionType.PROPERTY_GROUPS]        : 'Property Groups',
    [sectionType.PROPERTY_GROUPS_LIST]   : 'Property Groups',
    [sectionType.PROPERTY_GROUPS_EDITOR] : 'Property Group',

    [sectionType.PROPERTIES]      : 'Properties',
    [sectionType.PROPERTIES_LIST] : 'Properties',

    [sectionType.ACCOUNT_STATEMENTS]: 'Account Statement',
});
