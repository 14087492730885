import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Form from 'Components/Form';
import PropTypes from 'prop-types';

export function Translation({ title, data, availableLanguages, onSave, onCancel }) {
    const modalPropertiesForForm = useMemo(
        () => ({
            title,
            buttons: [
                <Form.Secondary variant="text" color="primary" key="fs" onClick={onCancel}>
                    <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                </Form.Secondary>,
                <Form.Primary variant="text" color="primary" key="fp">
                    {data?.id ? (
                        <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                    ) : (
                        <FormattedMessage id={translated.global.buttons.create} defaultMessage={translated.global.buttons.create} />
                    )}
                </Form.Primary>,
            ],
        }),
        [data, onCancel, title],
    );

    const onSubmit = async ({ values }) => onSave({ ...values });

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form id="translation-form" buttonsWidth={{ base: 12, small: 6 }} initialValues={data} onSubmit={onSubmit}>
                {data.id && <Form.Input type="hidden" submitKey="id" value={data.id} />}

                <Form.Input
                    type="select"
                    submitKey="language"
                    options={availableLanguages.map((e) => ({ ...e, content: e.name, value: e.id }))}
                    label={translated.rules.translations.language}
                    defaultOptionText={translated.global.selectAnOption}
                    sortBy="content"
                    isRequired
                    isDense
                />

                <Form.Input
                    type="text"
                    submitKey="title"
                    label={translated.rules.translations.name}
                    isRequired
                    isDense
                />

                <Form.Input
                    type="text"
                    submitKey="description"
                    label={translated.rules.translations.description}
                    isRequired
                    isDense
                />
            </Form>
        </Form.Wrapper>
    );
}

Translation.defaultProps = {};

Translation.propTypes = {
    title              : PropTypes.string.isRequired,
    data               : PropTypes.shape({}).isRequired,
    onSave             : PropTypes.func.isRequired,
    onCancel           : PropTypes.func.isRequired,
    availableLanguages : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Translation;
