import { sectionsPriority } from 'Constants/global';

const DEFAULT_URL = '/panel';

const urlIsFullyParsed = (url) => {
    const convertedUrl = url.replace('/', '');
    return convertedUrl.length === 0 || url.indexOf('?') === 0;
};

const urlIsValid = (url) => url.indexOf('/') === 0;

/**
 * Returns the data needed of the node.
 */
const getConfiguredNode = (section, node, parentNode, parameterKey, matchedParameter, matched) => {
    const configuredNode = { section };

    if (node?.config?.url?.withoutId && matched === node.config.url.withoutId) {
        configuredNode.resources = { current: 'init' };
    } else {
        configuredNode.ids = {};
    }

    if (matchedParameter && parameterKey) {
        configuredNode.ids[parameterKey] = matchedParameter;
    }

    return configuredNode;
};

/**
 * Returns the nodes marked as 'defaultSection'.
 */
const getDefaultNodes = (sectionsConfig, lastNode) => {
    const nodes = [];
    let parameters = lastNode && lastNode.parameters ? lastNode.parameters : {};

    if (sectionsConfig && Object.keys(sectionsConfig).length) {
        let nodesLeft = true;
        let currentSections = sectionsConfig;

        while (nodesLeft) {
            let keyFound = null;
            let found = null;

            // Look for a section marked as 'defaultSection'.
            Object.entries(currentSections).forEach((each) => {
                const [, eachValue] = each;

                if (eachValue.config && eachValue.config.isDefaultSection) {
                    [keyFound, found] = each;
                }
            });

            if (found) {
                // When a default node is found, adds it to the node list.
                const defaultNodeConfig = getConfiguredNode(keyFound, found, { parameters });

                parameters = { ...defaultNodeConfig.parameters };
                nodes.push(defaultNodeConfig);
            }

            // Set the next section in which we will search for default nodes.
            currentSections = found ? found.sections : null;
            nodesLeft = !!currentSections && !!Object.keys(currentSections).length;
        }
    }

    return nodes;
};

/**
 * Look for the nodes that matches with the url given.
 * @param {*} url               String used to match against the nodes
 * @param {*} matchingNodes     Array of nodes that matches with the url previously worked
 * @param {*} sectionsConfig    Object containing the nodes
 */
const getRequestUrlNodes = (url, matchingNodes, sectionsConfig) => {
    const lastConfiguredNode = [...matchingNodes].pop();

    // The url has been completely parsed.
    if (urlIsFullyParsed(url)) {
        // Look for sections marked as default for the given node.
        const defaultNodes = getDefaultNodes(sectionsConfig, lastConfiguredNode) || [];
        return [...matchingNodes, ...defaultNodes];
    }

    // The remaining url is invalid OR 'sectionsConfig' is null or has no children.
    if (!urlIsValid(url) || !sectionsConfig || !Object.keys(sectionsConfig).length) {
        return null;
    }

    // Node that contains the set of nodes received in 'sectionsConfig'.
    const parentNode = [...matchingNodes].pop();

    // Key of the sections received.
    const sectionKeys = Object.keys(sectionsConfig);
    let index = 0;
    let currentNode = sectionsConfig[sectionKeys[index]];

    let matchingSections = null;

    while (!matchingSections && currentNode) {
        const nodesFound = [...matchingNodes];
        const { url: configUrl } = currentNode.config;
        const parameterName = currentNode.ids.self;

        // Match the url received against the current node's configured url
        let urlMatch;
        if (typeof configUrl === 'object') {
            Object.values(configUrl).forEach((aConfigUrl) => {
                const tempMatch = url.match(new RegExp(aConfigUrl));
                if (tempMatch && tempMatch.index === 0) {
                    urlMatch = tempMatch;
                }
            });
        } else {
            urlMatch = url.match(new RegExp(configUrl));
        }

        // The current node has no url.
        if (!configUrl) {
            const configuredNode = getConfiguredNode(sectionKeys[index], currentNode, parentNode);

            nodesFound.push(configuredNode);

            // Look for the matching node in the node's sections.
            matchingSections = getRequestUrlNodes(url, nodesFound, currentNode.sections);
        } else if (urlMatch) {
            // Part of the url that matches the pattern
            const [matched, matchParameter] = urlMatch;

            // Remove the matched url of the url to process
            const partialPath = url.slice(matched.length);

            // Adds to the list of nodes found, the configuration needed for the node.
            nodesFound.push(getConfiguredNode(sectionKeys[index], currentNode, parentNode, parameterName, matchParameter, matched));
            // nodesFound.push({ [parameterName]: matchParameter });

            // Look for the matching node in the node's sections.
            matchingSections = getRequestUrlNodes(partialPath, nodesFound, currentNode.sections);
        }
        // When the current node has an url configured, but it doesn't match with the url, it just continues with the next node.

        index++;
        currentNode = sectionsConfig[sectionKeys[index]];
    }

    return matchingSections;
};

const shouldLoadDefaultNodes = (url) => {
    if (url.indexOf(DEFAULT_URL) === 0) {
        const urlLeft = url.replace(DEFAULT_URL, '').replace('/', '');

        return !urlLeft.length || urlLeft.indexOf('?') === 0;
    }
    return false;
};

const getRequestNodes = (url, sectionsConfig) => {
    // Look for the url nodes.
    let nodes = getRequestUrlNodes(url, [], sectionsConfig);

    // When no node has been found, return the app's default nodes.
    if ((!nodes || !nodes.length) && shouldLoadDefaultNodes(url)) {
        nodes = getDefaultNodes(sectionsConfig);
    }

    return nodes;
};

const getRequestParameters = (query, url, sectionsConfig, propsToAddToSection) => {
    // Look for the nodes involved in the url.
    const nodes = getRequestNodes(url, sectionsConfig);

    if (!nodes || !nodes.length) {
        const error = new Error('Page not found');
        error.errorMessage = 'Page not found';
        error.statusCode = 404;
        return Promise.reject(error);
    }

    let ids = {};
    const path = [];
    const sectionProps = [];

    // For each node involved in the url, look for it's data.
    nodes.forEach((each, index) => {
        const { section, ids:idsInNode, resources } = each;
        path.push(section);

        ids = { ...ids, ...idsInNode };

        const propsToPush = { };
        if (propsToAddToSection) {
            propsToAddToSection.forEach((prop) => {
                const dataProp = nodes.length - 1 === index ? { data: null } : null;
                propsToPush.with = { ...prop, ...dataProp };
            });
        }

        if (resources) {
            propsToPush.with = { ...propsToPush.with, resources: { ...resources } };
        }

        if (propsToPush.with) {
            sectionProps.push({ with: { ...propsToPush.with }, on: each.section });
        }
    });

    return { path, ids, sectionProps, isQAActive: url.includes('qa') };
};

/**
 * Returns the page that must be rendered for the given url.
 */
const getPageToRedirect = (query, url) => {
    const { path } = url;
    if (path.indexOf('.') === -1 && path.indexOf('/panel') === 0) {
        // When no .css,.png or any other kind of file is requested
        // and a page of '/panel' is requested
        return routes.findByName('panel');
    }

    return null;
};

/**
 * Returns a promise that is resolved with the data of the selected section
 *  and the data needed for all the sections that require it.
 */
const getPageParameters = (query, url, sectionsConfig, propsToAddToSection) => {
    let { path } = url;
    if (!path) {
        path = url;
    }
    return getRequestParameters(query, path, sectionsConfig, propsToAddToSection);
};

/**
 * Returns the default section for the list of sections received as parameter.
 *
 * Returns the first section of the priority list that is enabled. When no section of the priority is enabled, it returns the first section available
 * @param {List<String>} enabledSections
 */
const getDefaultSection = (enabledSections) => sectionsPriority.find((eachSection) => enabledSections.find((e) => e === eachSection))
        || enabledSections[0];

export { getPageToRedirect, getPageParameters, getDefaultSection };
