import React from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import Alert from 'Components/Alert';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import Tooltip from 'Components/Tooltip';
import { useIntl } from 'react-intl';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';

function Tab({ tabs, position, children, className, headButtons }) {
    const intl = useIntl();

    const activeTabs = tabs.filter((e) => !e.isHidden);

    if (React.Children.count(children) !== tabs.length) {
        return <Alert id="tab-error" type="error" data={`${React.Children.count(children)}, ${tabs.length}`} content="Invalid number of tabs" />;
    }

    let positionClass = '';
    if (position) {
        positionClass = position === 'right' ? 'tab-right' : 'tab-center';
    }

    const tooltipId = Tooltip.generateId();

    return (
        <div className={`tab-wrapper ${className}`}>
            <div className={`tab ${positionClass}`}>
                {activeTabs.map((tab) => {
                    const {
                        isSelected, onClick, id, name, tooltip, isEnabled, className: tabClassName, icon, iconOnClick, iconClassName,
                    } = tab;

                    const tooltipMessage = getMessage(intl, tooltip);

                    const handleOnClick = icon && iconOnClick
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            iconOnClick(e);
                        }
                        : null;

                    const key = typeof name !== 'object' ? `${name.replace(/ /g, '_')}-${id}` : `${name.props.id.replace(/ /g, '_')}-${id}`;

                    return (
                        <div
                            className={getClassName({ selected: isSelected, disabled: !isEnabled }, 'tab-item', tabClassName)}
                            onClick={() => isEnabled && onClick()}
                            key={key}
                            id={id}
                        >
                            <div className={`tab-title ${icon ? 'has-icon' : ''}`}>
                                <WrappedFormattedMessage content={name} />
                                {icon && (
                                    <Icon
                                        data-tip={tooltipMessage}
                                        data-for={tooltipId}
                                        className={`mdi-icon ${iconClassName}`}
                                        path={IconPath[`mdi${icon}`]}
                                        onClick={handleOnClick}
                                    />
                                )}
                                {tooltip && <Tooltip id={tooltipId} disable={!isEnabled} />}
                            </div>
                        </div>
                    );
                })}

                {!!headButtons?.length && <div className="tab-item tab-item-button">{!!headButtons?.length && headButtons.map((e) => e)}</div>}
            </div>
            <div className="tab-content">{React.Children.toArray(children)[tabs.findIndex((t) => t.isSelected)]}</div>
        </div>
    );
}

Tab.defaultProps = {
    className : '',
    position  : '',
    tabs      : [
        {
            id         : '',
            name       : '',
            icon       : '',
            tooltip    : '',
            isSelected : false,
            isEnabled  : false,
        },
    ],
    headButtons: [],
};

Tab.propTypes = {
    className : PropTypes.string,
    position  : PropTypes.string,
    tabs      : PropTypes.arrayOf(
        PropTypes.shape({
            id         : PropTypes.string,
            name       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            icon       : PropTypes.string,
            tooltip    : PropTypes.string,
            isSelected : PropTypes.bool,
            isEnabled  : PropTypes.bool,
        }),
    ),
    children    : PropTypes.node.isRequired,
    headButtons : PropTypes.arrayOf(PropTypes.node),
};

export default Tab;
