import React, { useContext, useRef, useEffect, useCallback } from 'react';
import getClassName from 'Utils/getClassName';
import Alert from 'Components/Alert';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';

function Snackbar({ notification, system, progress }) {
    const { snackbar } = useContext(PanelContext);

    const timerRef = useRef(null);

    useEffect(() => {
        // The id is used to identify different messages with the same content
        if (system.isVisible) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }

            timerRef.current = setTimeout(() => {
                timerRef.current = null;
                snackbar.hide(system.id);
            }, system.timeout || 8000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [system.id]);

    const onCloseNotificationSnackbar = useCallback((notificationClicked) => {
        snackbar.hide(notificationClicked.id);

        if (notificationClicked.onCloseClick) {
            notificationClicked.onCloseClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNotificationSnackbarClick = useCallback((notificationClicked) => {
        snackbar.hide(notificationClicked.id);

        if (notificationClicked.onClick) {
            notificationClicked.onClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCloseSystemSnackbar = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        snackbar.hide(system.id);

        if (system.onCloseClick) {
            system.onCloseClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [system.id]);

    const onSystemSnackbarClick = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        snackbar.hide(system.id);

        if (system.onClick) {
            system.onClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [system.id]);

    const className = getClassName(
        { isVisible: !!notification.elements.length || system.isVisible || progress.isVisible },
        'snackbar-wrapper',
        system.className,
    );

    return (
        <div id="snackbar-wrapper" className={className}>
            {notification.elements.map((eachNotification) => (
                <Alert
                    id="snackbar-notification"
                    hasCloseButton
                    onCloseClick={() => onCloseNotificationSnackbar(eachNotification)}
                    className={eachNotification.className}
                    content={eachNotification.content}
                    contentValues={eachNotification.contentValues}
                    actionText={eachNotification.actionText}
                    actionTextValues={eachNotification.actionTextValues}
                    onClick={
                        eachNotification.onClick
                        && (() => {
                            onNotificationSnackbarClick(eachNotification);
                        })
                    }
                    title={eachNotification.title}
                    titleValues={eachNotification.titleValues}
                    isSnackbar
                    withoutIcon
                />
            ))}
            {system.isVisible && (
                <Alert
                    id="snackbar-system"
                    hasCloseButton
                    onCloseClick={onCloseSystemSnackbar}
                    className={system.className}
                    content={system.content}
                    contentValues={system.contentValues}
                    actionText={system.actionText}
                    onClick={system.onClick && onSystemSnackbarClick}
                    type={(system.isError && 'error') || (system.isWarning && 'warning') || (system.isSuccess && 'success') || ''}
                    isSnackbar
                    withoutIcon
                />
            )}

            {progress.isVisible && <Alert id="snackbar-progress" className={progress.className} content={progress.content} isSnackbar withoutIcon />}
        </div>
    );
}

Snackbar.propTypes = {
    notification : PropTypes.shape({}).isRequired,
    system       : PropTypes.shape({
        className    : PropTypes.string,
        content      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        actionText   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        onClick      : PropTypes.func,
        onCloseClick : PropTypes.func,
        isError      : PropTypes.bool,
        isWarning    : PropTypes.bool,
        isSuccess    : PropTypes.bool,
        isVisible    : PropTypes.bool.isRequired,
        id           : PropTypes.string.isRequired,
    }).isRequired,
    progress: PropTypes.shape({
        className : PropTypes.string,
        content   : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        isVisible : PropTypes.bool.isRequired,
    }).isRequired,
};

export default React.memo(Snackbar);
