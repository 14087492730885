import React from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';

function Title({ className, isHeading, isSubHeading, isSubtitle, width }) {
    const titleClassName = getClassName({ isHeading, isSubHeading, isSubtitle }, 'skeleton-element skeleton-title', className);

    return (
        <div className="skeleton skeleton-headings" style={{ width: `${width}px` }}>
            <div className={titleClassName} />
        </div>
    );
}

Title.defaultProps = {
    className    : '',
    isHeading    : false,
    isSubHeading : false,
    isSubtitle   : false,
    width        : 200,
};

Title.propTypes = {
    className    : PropTypes.string,
    isHeading    : PropTypes.bool,
    isSubHeading : PropTypes.bool,
    isSubtitle   : PropTypes.bool,
    width        : PropTypes.number,
};

export default Title;
