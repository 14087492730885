import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SectionsWrapper from '../../Wrapper';
import Subsection from 'Components/Sections/Subsection';
import PanelContext from 'State/panelContext';
import Form, { FormError } from 'Components/Form';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from 'Components/Sections/Collection';
import Status from 'Components/Status';
import translated from 'Constants/labels/translated';
import { clientType, extraAttributesFilters } from 'Constants/global';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';
import { sectionType } from 'Constants/types';

export function OwnersGeneral({ data, sections, resources, state }) {
    const {
        status, primary, secondary, referral, extraAttributes, legacyId, department,
    } = data;

    const { navigator, snackbar, dateManager } = useContext(PanelContext);
    const { available, additional } = resources;

    const accountFormRef = useRef();
    const primaryFormRef = useRef();
    const secondaryFormRef = useRef();

    const [secondaryHasEmail, setSecondaryHasEmail] = useState(!!secondary?.email);

    useEffect(() => {
        let form = null;
        switch (state?.selectedForm) {
            case 'account':
                form = accountFormRef?.current?.element?.current;
                break;
            case 'primary':
                form = primaryFormRef?.current?.element?.current;
                break;
            case 'secondary':
                form = secondaryFormRef?.current?.element?.current;
                break;
            default:
                break;
        }

        if (form?.scrollIntoView) {
            form.scrollIntoView({
                behavior : 'smooth',
                block    : 'center',
                inline   : 'center',
            });
        }
    }, [state]);

    const updateAccount = async (payload) => {
        const reqConfig = {
            ...available.update,
            data             : payload,
            shouldReloadData : true,
        };

        if (!reqConfig.data?.secondary?.id && !reqConfig.data?.secondary?.firstName) {
            reqConfig.data.secondary = null;
        }

        try {
            await navigator.requestForCurrentPath({ reqConfig, resources });
            snackbar.show({ content: translated.owners.profile.success, isSuccess: true });
        } catch (saveError) {
            throw new FormError(translated.owners.profile.error, saveError);
        }
    };

    const handleOnSubmitAccountExtraAttributes = async ({ values }) => {
        validateDynamicInputs(values.extraAttributes, dateManager);

        const {
            id, membershipId, address1, address2, zipCode, city, country,
        } = data;

        const payload = {
            id,
            status,
            legacyId,
            membershipId,
            department,
            address1,
            address2,
            zipCode,
            city,
            country,
            state           : data.state,
            referral        : referral?.id ? { id: String(referral.id) } : null,
            primary         : { id: String(primary.id) },
            secondary       : secondary?.id ? { id: String(secondary.id) } : null,
            type            : clientType.OWNER,
            extraAttributes : { ...values.extraAttributes },
        };

        await updateAccount(payload);
    };

    const accountOnSubmit = async ({ values }) => {
        const payload = {
            ...values,
            extraAttributes,
        };

        await updateAccount(payload);
    };

    const updatePrimary = async (payload) => {
        const reqConfig = {
            ...primary.links.self.update,
            data             : payload,
            shouldReloadData : true,
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig, resources });

            snackbar.show({
                content   : translated.owners.update.primary.success,
                isSuccess : true,
            });
        } catch (saveError) {
            throw new FormError(translated.owners.update.primary.error, saveError, translated.owners.usersErrors);
        }
    };

    const primaryOwnerOnSubmit = async ({ values }) => {
        const payload = {
            ...values,
            extraAttributes: primary.extraAttributes,
        };

        await updatePrimary(payload);
    };

    const handleOnSubmitPrimaryExtraAttributes = async ({ values }) => {
        validateDynamicInputs(values.extraAttributes, dateManager);

        const {
            firstName, lastName, email, phone, mobile, birthday, language, loginToken,
        } = primary;
        const user = {
            firstName,
            lastName,
            email,
            phone,
            mobile,
            birthday,
            language,
            loginToken,
        };
        const payload = {
            ...user,
            extraAttributes: { ...values.extraAttributes },
        };

        await updatePrimary(payload);
    };

    const updateSecondary = async (payload) => {
        const link = secondary.links.self.update ? { ...secondary.links.self.update } : { ...secondary.links.self.create };
        // When there is no id, we cant send 'null' because APT fails.
        const reqConfig = {
            ...link,
            data             : payload,
            shouldReloadData : true,
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig, resources, section: sectionType.OWNERS_GENERAL });

            snackbar.show({
                content   : secondary?.id ? translated.owners.update.secondary.success : translated.owners.update.secondary.added,
                isSuccess : true,
            });
        } catch (saveError) {
            throw new FormError(translated.owners.update.secondary.error, saveError, translated.owners.usersErrors);
        }
    };

    const secondaryOwnerOnSubmit = async ({ values }) => {
        const payload = {
            ...values,
            id                 : values.id || undefined,
            sendInvitationLink : values.email ? values.sendInvitationLink : undefined,
            extraAttributes    : { ...secondary?.extraAttributes },
        };

        await updateSecondary(payload);
    };

    const handleOnSubmitSecondaryExtraAttributes = async ({ values }) => {
        validateDynamicInputs(values.extraAttributes, dateManager);

        const {
            firstName, lastName, email, phone, mobile, birthday, language, loginToken,
        } = secondary;
        const user = {
            firstName,
            lastName,
            email,
            phone,
            mobile,
            birthday,
            language,
            loginToken,
        };
        const payload = {
            ...user,
            extraAttributes: { ...values.extraAttributes },
        };

        await updateSecondary(payload);
    };

    // Turn the languages and countries list to the needed structure.
    const languages = additional?.languages?.length ? additional.languages.map((each) => ({ value: each.id, content: each.name })) : [];
    const countries = additional?.countries?.length ? additional.countries.map((each) => ({ value: each.id, content: each.name })) : [];

    const resetConfirmation = {
        title   : translated.global.resetConfirmation.title,
        message : translated.global.resetConfirmation.message,
    };

    const { Section, props } = SelectedSection.get(sections);
    if (Section) {
        return <Section {...props} />;
    }

    const currentDate = dateManager.defaultDateFormat(dateManager.getCurrentDay());
    const birthdayMinValue = dateManager.yearInDefaultFormat();
    const birthdayMaxValue = currentDate;

    const profileTitle = (
        <>
            <FormattedMessage id={translated.owners.id} defaultMessage={translated.owners.id} />
            <span className="section-header-id">{legacyId}</span>
            <Status status={status} className="margin-left-small" highlightedAttributes={data.highlightedAttributes} />
        </>
    );

    const canEditOrCreateSecondaryOwner = !!(secondary?.links?.self?.update || secondary?.links?.self?.create);
    const hasSecondaryOwner = secondary?.id;

    const availableAccountAttributes = additional?.extraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.CLIENT));
    const availableUserAttributes = additional?.extraAttributes.filter((attribute) => attribute.categories.includes(extraAttributesFilters.USER));

    return (
        <SectionsWrapper title={profileTitle} className="owner-wrapper owner-editor">
            <Form.Wrapper>
                <Subsection
                    title={translated.owners.accountInformation}
                    className="account-subsection"
                    canCollapse
                    shouldShowFormInfoOnCollapse
                    actionButtons={[
                        <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                            <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                        </Form.Reset>,
                        <Form.Primary key="fp">
                            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                        </Form.Primary>,
                    ]}
                    ref={accountFormRef}
                >
                    <Form id="owners-account" buttonsWidth={{ base: 12, medium: 6 }} onSubmit={accountOnSubmit}>
                        <Form.Hidden submitKey="status" value={status} />
                        <Form.Hidden submitKey="membershipId" value={data.membershipId} />
                        <Form.Hidden submitKey="id" value={data.id} />
                        <Form.Hidden submitKey="legacyId" value={legacyId} />
                        <Form.Hidden submitKey="primary.id" value={primary && primary.id} submitFormat={(val) => String(val)} />
                        <Form.Hidden submitKey="secondary.id" value={secondary && secondary.id} submitFormat={(val) => String(val)} />
                        <Form.Hidden submitKey="referral" value={referral && referral.id} submitFormat={(id) => (id ? { id: String(id) } : null)} />
                        <Form.Hidden submitKey="type" value={clientType.OWNER} />
                        <Form.Input submitKey="department" type="hidden" value={department} />
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                submitKey="address1"
                                isDense
                                type="text"
                                label={translated.owners.address1}
                                value={data.address1}
                                validations={{ maxLength: 100 }}
                                isRequired
                            />
                            <Form.Input
                                submitKey="address2"
                                isDense
                                type="text"
                                label={translated.owners.address2}
                                value={data.address2}
                                validations={{ maxLength: 100 }}
                            />
                            <Form.Input
                                submitKey="zipCode"
                                isDense
                                type="text"
                                label={translated.owners.zipCode}
                                value={data.zipCode}
                                validations={{ maxLength: 25 }}
                                className="zip-input"
                            />
                            <Form.Input
                                submitKey="city"
                                isDense
                                type="text"
                                label={translated.global.city}
                                value={data.city}
                                validations={{ maxLength: 25 }}
                                isRequired
                            />
                            <Form.Input
                                submitKey="state"
                                isDense
                                type="text"
                                label={translated.owners.state}
                                value={data.state}
                                validations={{ maxLength: 25 }}
                            />
                            <Form.Input
                                submitKey="country"
                                isDense
                                type="select"
                                label={translated.owners.country}
                                value={data.country?.id}
                                submitFormat={(id) => (id ? { id } : null)}
                                options={countries}
                            />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            <Form.Wrapper>
                <Subsection
                    canCollapse
                    title={translated.owners.extraAttributes.accountTitle}
                    className="account-subsection"
                    shouldShowFormInfoOnCollapse
                    actionButtons={[
                        <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                            <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                        </Form.Reset>,
                        <Form.Primary key="fp">
                            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                        </Form.Primary>,
                    ]}
                >
                    <Form id="extra-attributes-account-form" onSubmit={handleOnSubmitAccountExtraAttributes}>
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                id="extra-attributes-account"
                                key="extra-attributes-account"
                                submitKey="extraAttributes"
                                type="dynamicInputs"
                                value={extraAttributes}
                                availableAttributes={availableAccountAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            <Form.Wrapper>
                <Subsection
                    title={translated.owners.primaryOwnerInformation}
                    className="primary-subsection"
                    canCollapse={!!primary?.links?.self?.update}
                    shouldShowFormInfoOnCollapse
                    actionButtons={
                        primary?.links?.self?.update
                            ? [
                                <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                                    <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                                </Form.Reset>,
                                <Form.Primary key="fp">
                                    <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                                </Form.Primary>,
                            ]
                            : []
                    }
                    ref={primaryFormRef}
                >
                    <Form
                        id="primary-owner"
                        buttonsWidth={{ base: 12, medium: 6 }}
                        onSubmit={primaryOwnerOnSubmit}
                        isReadOnlyWithoutInputs={!primary?.links?.self?.update}
                    >
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Hidden submitKey="id" value={primary?.id} />
                            <Form.Input
                                submitKey="firstName"
                                isDense
                                type="text"
                                label={translated.global.firstName}
                                isRequired
                                value={primary?.firstName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="lastName"
                                isDense
                                type="text"
                                label={translated.global.lastName}
                                isRequired
                                value={primary?.lastName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="email"
                                isDense
                                type="text"
                                label={translated.global.email}
                                value={primary?.email}
                                validations={{ maxLength: 255, unary: ['email'] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Input
                                submitKey="phone"
                                isDense
                                type="number"
                                label={translated.global.phone}
                                value={primary?.phone}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="mobile"
                                isDense
                                type="number"
                                label={translated.global.mobile}
                                value={primary?.mobile}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="birthday"
                                icon={{ name: 'Calendar', position: 'right' }}
                                type="date"
                                label={translated.global.birthday}
                                value={primary?.birthday}
                                minDate={birthdayMinValue}
                                maxDate={birthdayMaxValue}
                                validations={{ minDate: birthdayMinValue, maxDate: birthdayMaxValue, unary: ['date'] }}
                                isDense
                                helperText={{ label: translated.global.pickerInput.format }}
                                className="date-input"
                            />
                            <Form.Input
                                submitKey="language"
                                isDense
                                type="select"
                                label={translated.global.language}
                                value={primary?.language}
                                options={languages}
                                sortBy="content"
                            />
                            <Form.Input
                                submitKey="loginToken"
                                isDense
                                type="text"
                                label={translated.global.loginToken}
                                value={primary?.loginToken}
                                validations={{ maxLength: 255, ignoreChars: [' '] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Hidden submitKey="pictureUrl" value={primary?.pictureUrl} />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            <Form.Wrapper>
                <Subsection
                    canCollapse
                    title={translated.owners.extraAttributes.primaryTitle}
                    className="account-subsection"
                    shouldShowFormInfoOnCollapse
                    actionButtons={[
                        <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                            <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                        </Form.Reset>,
                        <Form.Primary key="fp">
                            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                        </Form.Primary>,
                    ]}
                >
                    <Form id="extra-attributes-account-form" onSubmit={handleOnSubmitPrimaryExtraAttributes}>
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                id="primary-extra-attributes"
                                key="primary-extra-attributes"
                                submitKey="extraAttributes"
                                type="dynamicInputs"
                                value={primary?.extraAttributes}
                                availableAttributes={availableUserAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                            />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            <Form.Wrapper>
                <Subsection
                    title={translated.owners.secondaryOwnerInformation}
                    className="secondary-subsection"
                    canCollapse={canEditOrCreateSecondaryOwner}
                    shouldShowFormInfoOnCollapse
                    actionButtons={
                        canEditOrCreateSecondaryOwner
                            ? [
                                <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                                    <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                                </Form.Reset>,
                                <Form.Primary key="fp">
                                    <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                                </Form.Primary>,
                            ]
                            : []
                    }
                    ref={secondaryFormRef}
                >
                    <Form
                        id="secondary-owner"
                        buttonsWidth={{ base: 12, medium: 6 }}
                        onSubmit={secondaryOwnerOnSubmit}
                        onReset={() => {
                            if (!hasSecondaryOwner && secondaryHasEmail) {
                                setSecondaryHasEmail(false);
                            }
                        }}
                        isReadOnlyWithoutInputs={!canEditOrCreateSecondaryOwner}
                    >
                        <Form.Column width={{ base: 12, small: 6 }}>
                            {canEditOrCreateSecondaryOwner && !hasSecondaryOwner && (
                                <Form.Input
                                    type="checkbox"
                                    submitKey="sendInvitationLink"
                                    label={translated.owners.info.sendSecondaryOwnerInvitation}
                                    isDisabled={!secondaryHasEmail}
                                    value={false}
                                />
                            )}
                            <Form.Hidden submitKey="id" value={secondary?.id} />
                            <Form.Input
                                submitKey="firstName"
                                isDense
                                type="text"
                                label={translated.global.firstName}
                                isRequired
                                value={secondary?.firstName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="lastName"
                                isDense
                                type="text"
                                label={translated.global.lastName}
                                isRequired
                                value={secondary?.lastName}
                                validations={{ maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            <Form.Input
                                submitKey="email"
                                isDense
                                type="text"
                                label={translated.global.email}
                                value={secondary?.email}
                                validations={{ maxLength: 255, unary: ['email'] }}
                                charCount={{ total: 255 }}
                                onChange={(newEmail) => {
                                    if (!hasSecondaryOwner && secondaryHasEmail !== !!newEmail) {
                                        // When the user has a secondary owner, the input 'sendInvitationLink is hidden'
                                        setSecondaryHasEmail(!!newEmail);
                                    }
                                }}
                            />
                            <Form.Input
                                submitKey="phone"
                                isDense
                                type="number"
                                label={translated.global.phone}
                                value={secondary?.phone}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="mobile"
                                isDense
                                type="number"
                                label={translated.global.mobile}
                                value={secondary?.mobile}
                                validations={{ maxLength: 30, unary: ['phone'] }}
                                className="phone-input"
                            />
                            <Form.Input
                                submitKey="birthday"
                                icon={{ name: 'Calendar', position: 'right' }}
                                type="date"
                                label={translated.global.birthday}
                                value={secondary?.birthday}
                                minDate={birthdayMinValue}
                                maxDate={birthdayMaxValue}
                                validations={{ minDate: birthdayMinValue, maxDate: birthdayMaxValue, unary: ['date'] }}
                                isDense
                                helperText={{ label: translated.global.pickerInput.format }}
                                className="date-input"
                            />
                            <Form.Input
                                submitKey="language"
                                isDense
                                type="select"
                                label={translated.global.language}
                                value={secondary?.language}
                                options={languages}
                                sortBy="content"
                            />
                            <Form.Input
                                submitKey="loginToken"
                                isDense
                                type="text"
                                label={translated.global.loginToken}
                                value={secondary?.loginToken}
                                validations={{ maxLength: 255, ignoreChars: [' '] }}
                                charCount={{ total: 255 }}
                            />
                            <Form.Hidden submitKey="pictureUrl" value={secondary?.pictureUrl} />
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>
            {secondary?.id && (
                <Form.Wrapper>
                    <Subsection
                        title={translated.owners.extraAttributes.secondaryTitle}
                        className="account-subsection"
                        canCollapse
                        shouldShowFormInfoOnCollapse
                        actionButtons={[
                            <Form.Reset key="fs" color="primary" confirmation={resetConfirmation}>
                                <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                            </Form.Reset>,
                            <Form.Primary key="fp">
                                <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                            </Form.Primary>,
                        ]}
                    >
                        <Form id="extra-attributes-account-form" onSubmit={handleOnSubmitSecondaryExtraAttributes}>
                            <Form.Column width={{ base: 12, small: 6 }}>
                                <Form.Input
                                    id="secondary-extra-attributes"
                                    key="secondary-extra-attributes"
                                    submitKey="extraAttributes"
                                    type="dynamicInputs"
                                    value={secondary?.extraAttributes}
                                    availableAttributes={availableUserAttributes}
                                    submitFormat={(values) => transformDynamicInputs(values)}
                                />
                            </Form.Column>
                        </Form>
                    </Subsection>
                </Form.Wrapper>
            )}
        </SectionsWrapper>
    );
}

OwnersGeneral.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    sections  : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
    state     : PropTypes.shape({}).isRequired,
};

OwnersGeneral.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-bottom-xxlarge margin-top-medium">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={240} />
                </Grid.Column>
            </Grid>
            {/* Account information */}
            <Grid>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading width={230} className="margin-bottom-small" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" quantity={2} />
                    <Skeleton.Form type="input" smallInput />
                    <Skeleton.Form type="input" quantity={3} />
                </Grid.Column>
            </Grid>
            {/* Account Extra Attributes */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading className="margin-bottom-small" width={270} />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Primary Owner information */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading width={230} className="margin-bottom-small" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" quantity={3} />
                    <Skeleton.Form type="input" quantity={3} smallInput />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
            {/* Primary Extra Attributes */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading className="margin-bottom-small" width={270} />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Secondary Owner information */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 12, small: 6 }}>
                            <Skeleton.Title isSubHeading width={230} className="margin-bottom-small" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                            <Skeleton.Button quantity={2} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="checkbox" />
                    <Skeleton.Form type="input" quantity={3} />
                    <Skeleton.Form type="input" quantity={3} smallInput />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(OwnersGeneral);
