import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'Components/Modal';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';

function toCamel(str) {
    return str
        .replace('-', ' ')
        .replace(/\s(.)/g, ($1) => $1.toUpperCase())
        .replace(/\s/g, '')
        .replace(/^(.)/, ($1) => $1.toLowerCase());
}

function ModalList({ data, onClose, reloadData, propertyName }) {
    const { navigator } = useContext(PanelContext);
    const { data: items, pagination } = data;

    const convertedList = items?.map((e) => ({ ...e, id: `${e.type}-${e.id}` }));

    return (
        <Modal
            title={propertyName}
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : onClose,
            }}
            className="balance-preview-modal"
        >
            <Table
                title={propertyName}
                id="rules-modal"
                key="rules-modal"
                columns={[
                    {
                        title     : translated.rules.name,
                        key       : 'name',
                        isVisible : true,
                    },
                    {
                        title                : translated.rules.level,
                        key                  : 'level',
                        isVisible            : true,
                        customFieldComponent : (i) => (
                            <FormattedMessage
                                id={translated.rules.levels[toCamel(i.belongToLevel)]}
                                defaultMessage={translated.rules.levels[toCamel(i.belongToLevel)]}
                            />
                        ),
                    },
                    {
                        title                : translated.rules.type,
                        key                  : 'type',
                        isVisible            : true,
                        customFieldComponent : (i) => (
                            <FormattedMessage id={translated.rules.types[toCamel(i.type)]} defaultMessage={translated.rules.types[toCamel(i.type)]} />
                        ),
                    },
                    {
                        title    : translated.global.status,
                        key      : 'isActive',
                        whenTrue : {
                            text      : translated.global.state.enabled,
                            className : 'status status-enabled',
                        },
                        whenFalse: {
                            text      : translated.global.state.disabled,
                            className : 'status status-disabled',
                        },
                        isVisible: true,
                    },
                ]}
                rows={{
                    actions: [
                        {
                            key      : 'View',
                            callback : async (rule) => {
                                navigator.goToRuleEditor({
                                    available : rule.links.self,
                                    current   : 'read',
                                });
                            },
                            isOutsideDropdown : true,
                            when              : (item) => item?.links?.self?.read || item?.links?.self?.update,
                            getIcon           : (item) => (item?.links?.self?.update ? 'Pencil' : 'Eye'),
                            getTooltip        : (item) => (item?.links?.self?.update ? translated.global.buttons.edit : translated.global.buttons.view),
                        },
                    ],
                }}
                items={convertedList}
                onPaginationClick={(link) => reloadData(link)}
                pagination={pagination}
                whenEmpty={<Alert id="properties-empty" content={translated.rules.empty} />}
                hasTools={false}
                canChangeSettings={false}
            />
        </Modal>
    );
}

ModalList.propTypes = {
    propertyName : PropTypes.string.isRequired,
    onClose      : PropTypes.func.isRequired,
    data         : PropTypes.shape({}).isRequired,
    reloadData   : PropTypes.func.isRequired,
};

export default withRequest(ModalList);
