import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';
import { useIntl } from 'react-intl';

function Textarea({
    label, value, onChange, rows, cols, onFocus, onBlur, isDisabled, id, innerInputRef,
}) {
    const intl = useIntl();
    const placeholder = useMemo(() => getMessage(intl, label), [intl, label]);

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            <textarea
                id={id}
                rows={rows}
                cols={cols}
                type="textarea"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={isDisabled ? '-1' : '0'}
                autoComplete="off"
                ref={innerInputRef}
            />
        </>
    );
}

Textarea.defaultProps = {
    id       : '',
    label    : '',
    value    : '',
    rows     : '3',
    cols     : '',
    onChange : null,
    onFocus  : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled    : false,
    innerInputRef : null,
};

Textarea.propTypes = {
    id            : PropTypes.string,
    label         : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    value         : PropTypes.string,
    rows          : PropTypes.string,
    cols          : PropTypes.string,
    onChange      : PropTypes.func,
    onFocus       : PropTypes.func,
    onBlur        : PropTypes.func,
    isDisabled    : PropTypes.bool,
    innerInputRef : PropTypes.shape({}),
};

export default React.memo(Textarea);
