import { mdiFormSelect } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const extraAttributeId = null;

export default {
    [sectionType.EXTRA_ATTRIBUTES]: {
        isEnabled : false,
        config    : { url: '/panel/extra-attributes', icon: mdiFormSelect },
        sections  : {
            [sectionType.EXTRA_ATTRIBUTES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.EXTRA_ATTRIBUTES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.EXTRA_ATTRIBUTES_EDITOR]: {
                ids       : { extraAttributeId, self: 'extraAttributeId' },
                resources : { templates: { name: resourceNames.EXTRA_ATTRIBUTE } },
                config    : { url: { withId: '/(\\d+)', withoutId: '/add' } },
            },
        },
    },
};
