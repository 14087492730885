import React from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Chip({
    id, className, label, onChange, checked, value, index, isReadOnlyWithoutInputs, isDisabled,
}) {
    const chipClassName = getClassName({ isSelected: checked, isDisabled }, 'chip', className);

    return (
        <div id={id} className="chips-wrapper is-choice is-outlined">
            {!isReadOnlyWithoutInputs && (
                <div className={chipClassName} onClick={() => onChange({ target: { value: value !== null ? value : index, label } })}>
                    {label && (
                        <div className="chip-text">
                            <WrappedFormattedMessage content={label} />
                        </div>
                    )}
                </div>
            )}
            {isReadOnlyWithoutInputs && checked && <span>{label ? <WrappedFormattedMessage content={label} /> : ''}</span>}
        </div>
    );
}

Chip.defaultProps = {
    id        : '',
    className : '',
    label     : '',
    onChange  : () => {
        // Default
    },
    index                   : 0,
    checked                 : false,
    isReadOnlyWithoutInputs : false,
    isDisabled              : false,
    value                   : null,
};

Chip.propTypes = {
    id                      : PropTypes.string,
    checked                 : PropTypes.bool,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isDisabled              : PropTypes.bool,
    onChange                : PropTypes.func,
    index                   : PropTypes.number,
    value                   : PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    className               : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default React.memo(Chip);
