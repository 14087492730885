import { mdiHomeGroup } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

export default {
    [sectionType.PROPERTIES]: {
        isEnabled : false,
        config    : { url: '/panel/properties', icon: mdiHomeGroup },
        sections  : {
            [sectionType.PROPERTIES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.PROPERTIES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.RULES]: {
                        resources : { templates: { name: resourceNames.SYSTEM_RULE } },
                        config    : {
                            isModal              : true,
                            isHiddenInBreadcrumb : true,
                            resourceName         : resourceNames.SYSTEM_RULE,
                            requestTimeout       : 40000,
                            cleanWhenLeaving     : sectionType.RULES,
                        },
                        customProps: { navigateToParent: 'goToRoot' },
                    },
                    [sectionType.RULE]: {
                        resources : { templates: { name: resourceNames.SYSTEM_RULE } },
                        config    : {
                            resourceName               : resourceNames.SYSTEM_RULE,
                            hasTypePreffixInBreadcrumb : true,
                            requestTimeout             : 40000,
                        },
                        customProps: { navigateToParent: 'goToRoot' },
                    },
                },
            },
        },
    },
};
