import { hot } from 'react-hot-loader/root';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PanelContext from 'State/panelContext';
import { Waypoint } from 'react-waypoint';
import getClassName from 'Utils/getClassName';
import User from './User';
import Button from 'Components/Button';
import Breadcrumb from 'Components/Breadcrumb';

function NavBar({ sections, notification, isCallNotificationActive }) {
    const { toggle, app, navigator } = useContext(PanelContext);
    const [isSticky, setIsSticky] = useState(false);

    const setStickyWhenHitsTop = ({ currentPosition }) => {
        const { inside, above, below } = Waypoint;

        if (!isSticky && currentPosition === above) {
            setIsSticky(true);
        } else if (isSticky && (currentPosition === inside || currentPosition === below)) {
            setIsSticky(false);
        }
    };

    const navbarClassName = getClassName({ isSticky, sideDrawerCollapsed: app.isSideDrawerCollapsed && !app.isSideDrawerVisible }, 'navbar');

    return (
        <>
            <Waypoint onPositionChange={setStickyWhenHitsTop} topOffset={0} />
            <div id="navbar" className={navbarClassName}>
                <div className="navbar-breadcrumb-wrapper">
                    <Button id="nav-bar-menu" className="open-menu-button visible@l" icon="Menu" onClick={toggle.sideDrawerVisibility} withoutRipple />
                    <img
                        src={`/static/clients/${window.env.CLIENT_FILENAMES}/img/logo.png`}
                        className="logo visible@l"
                        alt="Logo"
                        onClick={navigator.goToDefaultSection}
                    />
                    <Breadcrumb className="hidden@s" sections={sections} />
                </div>
                <User notification={notification} hasCall={isCallNotificationActive} refresh={app.refreshUser} />
            </div>
        </>
    );
}

NavBar.propTypes = {
    sections                 : PropTypes.shape({}).isRequired,
    notification             : PropTypes.shape({}).isRequired,
    isCallNotificationActive : PropTypes.bool.isRequired,
};

export default hot(NavBar);
