import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Form, { FormError } from 'Components/Form';
import PanelContext from 'State/panelContext';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';

function Relationship({ data, isEditing, resources, user }) {
    const intl = useIntl();
    const { navigator, snackbar } = useContext(PanelContext);

    let relationship;

    const getInitialSummary = () => ({
        userA        : null,
        relationship : null,
        userB        : user,
    });

    const [summaryRelationship, setSummaryRelationship] = useState(getInitialSummary());
    const [listsEnabled, setListsEnabled] = useState(isEditing);

    const relationshipTypes = [];
    if (data?.additionalResources?.relationshipTypes) {
        data.additionalResources.relationshipTypes.forEach((eachRelationshipType) => {
            const { id, relationshipRoleA, relationshipRoleB } = eachRelationshipType;
            const b = intl.formatMessage({
                id             : translated.owners.relationships.types[relationshipRoleB.toLowerCase()],
                defaultMessage : translated.owners.relationships.types[relationshipRoleB.toLowerCase()],
            });
            const a = intl.formatMessage({
                id             : translated.owners.relationships.types[relationshipRoleA.toLowerCase()],
                defaultMessage : translated.owners.relationships.types[relationshipRoleA.toLowerCase()],
            });
            relationshipTypes.push({
                value    : `${id}-A`,
                content  : `${a} ⇄ ${b}`,
                original : eachRelationshipType,
            });
            if (a !== b) {
                relationshipTypes.push({
                    value      : `${id}-B`,
                    content    : `${b} ⇄ ${a}`,
                    original   : eachRelationshipType,
                    isInverted : true,
                });
            }
        });
    }

    const getRelationshipById = (id) => {
        const found = relationshipTypes.find((eachRelationship) => eachRelationship.value === id);

        if (found && found.original) {
            return { ...found.original, isInverted: found.isInverted };
        }

        return null;
    };

    const getRelationshipTypeId = () => {
        if (!relationship) {
            return '';
        }

        const { isInverted, relationshipType } = relationship;
        return isInverted ? `${relationshipType.id}-B` : `${relationshipType.id}-A`;
    };

    const handleOnSubmit = async () => {
        const { relationship: userRelationship, userA, userB } = summaryRelationship;
        const { isInverted } = userRelationship;

        const submitData = {
            userA            : { id: isInverted ? userB.id : userA.id },
            relationshipType : { id: userRelationship.id },
            userB            : { id: isInverted ? userA.id : userB.id },
        };

        const resourceLink = isEditing ? resources.available.update : resources.available.create;

        try {
            await navigator.requestAndSetToOwnersInfo({
                resource         : resourceLink,
                data             : submitData,
                shouldReloadData : true,
            });
        } catch (e) {
            throw new FormError(isEditing ? translated.owners.relationships.errorUpdating : translated.owners.relationships.errorCreating, e);
        }
    };

    const onFinish = () => {
        snackbar.show({
            content   : isEditing ? translated.owners.relationships.success.update : translated.owners.relationships.success.add,
            isSuccess : true,
        });

        navigator.goToParentAndReload();
    };

    useEffect(() => {
        if (isEditing) {
            const { userA, relationshipType, isInverted } = relationship || {};
            setSummaryRelationship({
                userA,
                relationship : isInverted ? getRelationshipById(`${relationshipType.id}-B`) : getRelationshipById(`${relationshipType.id}-A`),
                userB        : user,
            });
        } else {
            setSummaryRelationship(getInitialSummary());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, data]);

    if (isEditing) {
        relationship = { ...data };
        if (user.id !== relationship.userB.id) {
            relationship = {
                ...relationship,
                userA      : data.userB,
                userB      : data.userA,
                isInverted : true,
            };
        }
    } else {
        relationship = {
            relationshipType : '',
            userB            : null,
        };
    }
    const modalProperties = {
        title: `${user.firstName} ${user.lastName} - ${
            isEditing
                ? intl.formatMessage({
                    id             : translated.owners.relationships.editRelationship,
                    defaultMessage : translated.owners.relationships.editRelationship,
                })
                : intl.formatMessage({
                    id             : translated.owners.relationships.addRelationship,
                    defaultMessage : translated.owners.relationships.addRelationship,
                })
        }`,
        buttons: [
            <Form.Secondary key="fs" variant="text" color="primary" onClick={() => navigator.goToParentAndReload()} />,
            <Form.Primary key="fp" variant="text" color="primary">
                {isEditing ? (
                    <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                ) : (
                    <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                )}
            </Form.Primary>,
        ],
        className: 'relationships-modal',
    };

    return (
        <Form.Wrapper modalProperties={modalProperties}>
            <Form className="relationships-form" id="relationship" onSubmit={handleOnSubmit} onFinish={onFinish}>
                {relationship.id && <Form.Hidden submitKey="id" value={relationship && relationship.id} />}
                <Form.Column width={{ base: 12, small: 4 }}>
                    <Form.Input
                        submitKey="userA"
                        isDense
                        type="search"
                        value={relationship && relationship.userA && relationship.userA.email}
                        searchValidation={(email) => email && email.length > 3}
                        searchFunction={async (email) => {
                            const { data: result } = await navigator.directRequest({
                                ...data.additionalResources.users.read,
                                params: { email },
                            });
                            return result && result.map((eachUser) => ({ ...eachUser, display: eachUser.email }));
                        }}
                        renderSearch={(aUser) => {
                            if (!aUser) {
                                return (
                                    <span className="no-results">
                                        <FormattedMessage
                                            id={translated.owners.referral.edit.searchEmpty}
                                            defaultMessage={translated.owners.referral.edit.searchEmpty}
                                        />
                                    </span>
                                );
                            }
                            return (
                                <>
                                    <span className="result-user-email">{aUser.email}</span>
                                    <span className="result-user-name">{`${aUser.firstName} ${aUser.lastName}`}</span>
                                </>
                            );
                        }}
                        showLoading
                        onSearchStart={() => {
                            setListsEnabled(false);
                        }}
                        onSearchSelect={(aUser) => {
                            setSummaryRelationship((previous) => ({ ...previous, userA: aUser }));
                            setListsEnabled(true);
                        }}
                    />
                </Form.Column>
                <Form.Column width={{ base: 12, small: 4 }}>
                    <Form.Input
                        className="relationship-select"
                        isRequired
                        isDense
                        submitKey="relationshipType"
                        type="select"
                        label=""
                        value={relationship && relationship.relationshipType && getRelationshipTypeId()}
                        options={relationshipTypes}
                        displayOptionsAmount={8}
                        onChange={(value) => {
                            setSummaryRelationship((previous) => ({ ...previous, relationship: getRelationshipById(value) }));
                        }}
                        isDisabled={!listsEnabled}
                    />
                </Form.Column>
                <Form.Column width={{ base: 12, small: 4 }}>
                    <Form.Input submitKey="userB" type="text" label="" value={`${user.firstName} ${user.lastName}`} isDisabled avoidOnSubmit />
                </Form.Column>
            </Form>

            <div className="relationship-resume margin-top-medium">
                <div className="relationship-title">
                    <FormattedMessage
                        id={translated.owners.relationships.relationshipModal.summary}
                        defaultMessage={translated.owners.relationships.relationshipModal.summary}
                    />
                </div>
                <span className="relationship">
                    {summaryRelationship.userA && summaryRelationship.userB && summaryRelationship.relationship ? (
                        <>
                            {`${summaryRelationship.userA.lastName} ${summaryRelationship.userA.firstName}`}
                            {summaryRelationship.relationship.isInverted
                                ? ` (${intl.formatMessage({
                                    // eslint-disable-next-line max-len
                                    id             : translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleB.toLowerCase()],
                                    defaultMessage :
                                          translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleB.toLowerCase()],
                                })}) `
                                : ` (${intl.formatMessage({
                                    // eslint-disable-next-line max-len
                                    id             : translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleA.toLowerCase()],
                                    defaultMessage :
                                          translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleA.toLowerCase()],
                                })}) `}
                            <FormattedMessage
                                id={translated.owners.relationships.relationshipConnector}
                                defaultMessage={translated.owners.relationships.relationshipConnector}
                            />
                            {` ${summaryRelationship.userB.lastName} ${summaryRelationship.userB.firstName}`}
                            {summaryRelationship.relationship.isInverted
                                ? ` (${intl.formatMessage({
                                    // eslint-disable-next-line max-len
                                    id             : translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleA.toLowerCase()],
                                    defaultMessage :
                                          translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleA.toLowerCase()],
                                })}) `
                                : `(${intl.formatMessage({
                                    // eslint-disable-next-line max-len
                                    id             : translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleB.toLowerCase()],
                                    defaultMessage :
                                          translated.owners.relationships.types[summaryRelationship.relationship.relationshipRoleB.toLowerCase()],
                                })}) `}
                        </>
                    ) : (
                        <FormattedMessage
                            id={translated.owners.relationships.relationshipModal.summaryIncomplete}
                            defaultMessage={translated.owners.relationships.relationshipModal.summaryIncomplete}
                        />
                    )}
                </span>
            </div>
        </Form.Wrapper>
    );
}

Relationship.defaultProps = {
    data      : null,
    isEditing : false,
};

Relationship.propTypes = {
    isEditing : PropTypes.bool,
    resources : PropTypes.shape({}).isRequired,
    data      : PropTypes.shape({}),
    sections  : PropTypes.shape({}).isRequired,
    user      : PropTypes.shape({}).isRequired,
};

export default withRequest(Relationship);
