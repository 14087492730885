import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from 'Components/Sections/Collection';
import ModalList from 'Components/Sections/Shared/Rules/ModalList';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';

const getActions = (handleOnViewRules) => {
    const actions = [
        {
            callback          : handleOnViewRules,
            key               : 'viewRules',
            isOutsideDropdown : true,
            icon              : 'FileEye',
            tooltip           : translated.properties.viewRules,
            when              : (property) => property?.links?.rules?.read,
        },
    ];

    return actions;
};

function List({
    data, sections, fetching, reloadData, resources, setSectionState, state: { appliedFilters },
}) {
    const { navigator } = useContext(PanelContext);
    const { data: properties, order, pagination, filter: filterLinks, links } = data || {};

    const handleOnViewRules = async (property) => {
        await navigator.goToPropertiesRules(property);
    };

    const handleClickCloseModal = async () => {
        await navigator.goToProperties(links);
    };

    const columns = [
        {
            title     : translated.properties.code,
            key       : 'code',
            isVisible : true,
        },
        {
            title     : translated.global.name,
            key       : 'name',
            isVisible : true,
        },
        {
            title     : translated.global.status,
            key       : 'isActive',
            isVisible : true,
            whenTrue  : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
        },
    ];

    const rows = { actions: getActions(handleOnViewRules) };

    const filterResources = {
        filters: [
            {
                type        : 'text',
                submitKey   : 'code',
                resourceKey : 'code',
                label       : translated.properties.code,
            },
            {
                type        : 'text',
                submitKey   : 'name',
                resourceKey : 'name',
                label       : translated.global.name,
            },
            {
                type        : 'select',
                submitKey   : 'isActive',
                resourceKey : 'isActive',
                label       : translated.global.status,
                options     : [
                    { id: 'true', name: translated.global.state.enabled },
                    { id: 'false', name: translated.global.state.disabled },
                ],
            },
        ],
        filterLinks,
        resources,
    };

    const { Section, props: subsectionProps } = SelectedSection.getAny([{ [sectionType.RULE]: sections[sectionType.RULE] }]);

    const rulesSection = sections[sectionType.RULES];
    const isModalOpen = rulesSection.isEnabled && rulesSection.isSelected;

    if (Section) {
        return <Section {...subsectionProps} onClose={() => navigator.goToParentAndReload(true, false)} />;
    }

    return (
        <>
            {isModalOpen && <ModalList {...rulesSection} onClose={handleClickCloseModal} />}
            <Wrapper title={translated.properties.title}>
                <Table
                    id="properties"
                    key="properties"
                    columns={columns}
                    rows={rows}
                    items={properties}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    filterResources={filterResources}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config);
                    }}
                    appliedFilters={appliedFilters}
                    whenEmpty={(
                        <Alert
                            id="properties-empty"
                            content={translated.properties.empty}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 6 }}>
                <Skeleton.Title isHeading width={215} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    data            : [],
    fetching        : {},
    state           : null,
    setSectionState : null,
};

List.propTypes = {
    sections        : PropTypes.shape({}).isRequired,
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

export default withRequest(List);
