import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import Button from 'Components/Button';
import { Waypoint } from 'react-waypoint';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function Subsection({
    className, title, titleValues, actionButtons, hasNumbers, children, shouldShowFormInfoOnCollapse, canCollapse, sectionRef,
}) {
    const [isCollapsed, setIsCollapsed] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [bannerHeight, setBannerHeight] = useState(0);
    const [sectionHeight, setSectionHeight] = useState(0);

    const subsectionRef = useRef();

    let childrenToRender = null;
    if (shouldShowFormInfoOnCollapse) {
        childrenToRender = React.Children.map(children, (child) => {
            const newChildProps = isCollapsed
                ? { isReadOnlyWithoutInputs: true }
                : {
                    onChange: (currentFormState, ...restOfParam) => {
                        if (child.props.onChange) {
                            child.props.onChange(currentFormState, ...restOfParam);
                        }
                    },
                };

            return React.cloneElement(child, { ...newChildProps });
        });
    } else {
        childrenToRender = isCollapsed ? null : children;
    }

    const collapsedClass = isCollapsed ? 'is-collapsed' : '';
    const sectionHeaderClass = getClassName({ hasNumbers, isCollapsed, isSticky }, 'subsection-header');

    const actionButtonsToRender = !actionButtons || !actionButtons.length
        ? null
        : React.Children.map(actionButtons, (child) => React.cloneElement(child, {
            collapsed  : isCollapsed,
            isDisabled : (child.props ? child.props.isDisabled : false) || false,
        }));

    const setStickyWhenHitsTop = ({ currentPosition }) => {
        const { inside, above, below } = Waypoint;

        if (!isSticky && currentPosition === above) {
            setIsSticky(true);
        } else if (isSticky && (currentPosition === inside || currentPosition === below)) {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        const subSectionHeight = document.getElementsByClassName('is-sticky')?.item(0)?.clientHeight;
        setSectionHeight(subSectionHeight || 0);
        if (window.env.SHOW_BANNER) {
            const height = document.getElementsByClassName('environment-banner')?.item(0)?.clientHeight;
            setBannerHeight(height || 0);
        }
    }, [isSticky]);

    useImperativeHandle(sectionRef, () => ({ element: subsectionRef }));

    return (
        <div className={`subsection ${className}`} ref={subsectionRef}>
            <Waypoint onPositionChange={setStickyWhenHitsTop} topOffset={80} />
            {(title || actionButtons) && (
                <div className={sectionHeaderClass} style={isSticky ? { top: bannerHeight + sectionHeight } : {}}>
                    {title && (
                        <div className="subsection-header-title h5">
                            <WrappedFormattedMessage content={title} values={titleValues} />
                        </div>
                    )}
                    {(actionButtons || canCollapse) && (
                        <div className="subsection-header-buttons">
                            {actionButtonsToRender}
                            {canCollapse && <Button id="subsection-collapse-button" className={collapsedClass} icon="ChevronDown" onClick={() => setIsCollapsed(!isCollapsed)} />}
                        </div>
                    )}
                </div>
            )}
            <div className="subsection-content">
                {childrenToRender}
            </div>
        </div>
    );
}

Subsection.defaultProps = {
    className                    : '',
    title                        : '',
    titleValues                  : null,
    hasNumbers                   : false,
    canCollapse                  : false,
    actionButtons                : null,
    shouldShowFormInfoOnCollapse : false,
    sectionRef                   : null,
};

Subsection.propTypes = {
    className                    : PropTypes.string,
    title                        : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleValues                  : PropTypes.shape({}),
    hasNumbers                   : PropTypes.bool,
    canCollapse                  : PropTypes.bool,
    shouldShowFormInfoOnCollapse : PropTypes.bool,
    children                     : PropTypes.node.isRequired,
    actionButtons                : PropTypes.arrayOf(PropTypes.node),
    sectionRef                   : PropTypes.shape({}),
};

export default forwardRef((props, ref) => <Subsection {...props} sectionRef={ref} />);
