import React from 'react';
import PropTypes from 'prop-types';

function Breadcrumb({ className }) {
    return (
        <ul className={`skeleton skeleton-breadcrumb ${className}`}>
            <li className="skeleton-element skeleton-button-icon" />
            <li className="skeleton-element skeleton-button-icon" />
            <li className="skeleton-element skeleton-breadcrumb-item" />
            <li className="skeleton-element skeleton-breadcrumb-item" />
            <li className="skeleton-element skeleton-breadcrumb-item" />
        </ul>
    );
}

Breadcrumb.defaultProps = { className: '' };

Breadcrumb.propTypes = { className: PropTypes.string };

export default Breadcrumb;
