/* eslint-disable no-restricted-globals */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Form, { FormNew } from 'Components/Form';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import PanelContext from 'State/panelContext';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';
import Loading from 'Components/Loading';
import CurrenciesTable from './CurrenciesTable';
import yup from 'Utils/yupHelper';

const areValuesRequired = (list) => list?.find((e) => e != null && !isNaN(e));

const schema = yup.object().shape({
    name: yup.string(),

    newFrom: yup.number().min(0).max(20)
        .when(['newTo', 'newValue', 'newType'], (to, value, type) => (areValuesRequired([to, value, type])
            ? yup.number().required().min(0).max(20)
                .unique(translated.currencies.editor.errors.repeatedRate, ({ from }) => {
                    // The new rate can't have the same 'from' and 'to' that other rate has.
                    const values = from?.[0]?.value;

                    const list = values?.upgradeRates || [];
                    // eslint-disable-next-line max-len
                    const found = list?.filter((e) => !e.isHidden && String(e?.savedFrom) === String(values?.newFrom) && String(e?.savedTo) === String(values?.newTo)) || [];

                    return found?.length === 0;
                })
            : yup.number())),
    newTo: yup.number().min(0).max(20)
        .when(['newFrom', 'newValue', 'newType'], (from, value, type) => (areValuesRequired([from, value, type])
            ? yup.number().required().min(from || 0).max(20)
            : yup.number().min(from || 0).max(20))),
    newValue: yup.number().min(0).max(99999999)
        .when(['newFrom', 'newTo', 'newType'], (from, to, type) => (areValuesRequired([from, to, type])
            ? yup.number().required().min(from || 0).max(99999999)
            : yup.number().min(from || 0).max(99999999))),
    newType: yup.string()
        .when(['newFrom', 'newTo', 'newValue'], (from, to, value) => (areValuesRequired([from, to, value])
            ? yup.string().required()
            : yup.string())),
    upgradeRates: yup.array().of(
        yup.object().shape({
            changed  : yup.bool(),
            entityId : yup.number(),
            from     : yup.number().min(0).max(20).required()
                .unique(translated.currencies.editor.errors.repeatedRate, ({ from }) => {
                    // The rates can't have same 'from' and 'to'
                    const values = from?.[0]?.value;

                    const list = from?.[1]?.value?.upgradeRates || [];
                    const found = list?.filter((e) => !e.isHidden
                        && String(e?.entityId) !== String(values?.entityId)
                        && String(e?.savedFrom) === String(values?.from)
                        && String(e?.savedTo) === String(values?.to)) || [];

                    return found?.length <= 0;
                }),
            to: yup.number().required().min(0).max(20)
                .when('from', (from) => yup.number().required().min(from).max(20)),
            value    : yup.number().required().min(0).max(99999999),
            type     : yup.string().required(),
            isHidden : yup.boolean(),
        }),
    ),
// Undocumented functionality from yup to avoid the cycle dependencies errors.
}, [['newFrom', 'newTo'], ['newFrom', 'newValue'], ['newFrom', 'newType'], ['newTo', 'newValue'], ['newTo', 'newType'], ['newValue', 'newType']]);

export function Editor({ data }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const wrapperRef = useRef();
    const [initialRates, setInitialRates] = useState({
        isEnabled : !!data?.links?.levelUpgradeRates?.read,
        isLoaded  : false,
        list      : null,
    });

    // eslint-disable-next-line max-len
    const rateSortFunction = (first, second) => (Number(first.fromLevel) === Number(second.fromLevel) ? Number(first.toLevel) - Number(second.toLevel) : Number(first.fromLevel) - Number(second.fromLevel));

    const initializeRates = async () => {
        try {
            const ratesList = await navigator.directRequest(data.links.levelUpgradeRates.read);

            setInitialRates((prev) => ({
                ...prev,
                isLoaded : true,
                list     : ratesList?.data
                    ?.sort(rateSortFunction)
                    ?.map((e, index) => ({
                        from      : e.fromLevel,
                        savedFrom : e.fromLevel,
                        to        : e.toLevel,
                        savedTo   : e.toLevel,
                        value     : e.rate,
                        type      : e.type?.id,
                        id        : index + 2,
                        entityId  : e.id,
                        links     : e.links,
                    })) || [],
            }));
        } catch (e) {
            setInitialRates((prev) => ({ ...prev, isLoaded: true }));

            snackbar.show({
                content : translated.currencies.editor.errors.ratesInitError,
                isError : true,
            });
        }
    };

    useEffect(() => {
        if (data?.links?.levelUpgradeRates?.read) {
            initializeRates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onAdd = async (rate) => {
        if (!rate) {
            return null;
        }

        try {
            const entityCreated = await navigator.directRequest({
                ...data.links?.levelUpgradeRates?.create,
                data: {
                    fromLevel : rate.from,
                    toLevel   : rate.to,
                    rate      : rate.value,
                    type      : { id: rate.type },
                },
            });

            snackbar.show({
                content   : translated.currencies.editor.addRateSuccess,
                isSuccess : true,
            });

            return entityCreated;
        } catch (e) {
            snackbar.show({
                content : translated.currencies.editor.errors.addRateError,
                isError : true,
            });
        }

        return null;
    };

    const onEdit = async (rate) => {
        if (!rate) {
            return null;
        }

        try {
            const updated = await navigator.directRequest({
                ...rate.links.self.update,
                data: {
                    fromLevel : rate.from,
                    toLevel   : rate.to,
                    rate      : rate.value,
                    type      : { id: rate.type },
                    id        : rate.entityId,
                },
            });

            snackbar.show({
                content   : translated.currencies.editor.editRateSuccess,
                isSuccess : true,
            });

            return updated;
        } catch (e) {
            snackbar.show({
                content : translated.currencies.editor.errors.editRateError,
                isError : true,
            });

            return null;
        }
    };

    const onRemove = async (rate) => {
        if (!rate?.links?.self?.delete) {
            return null;
        }

        try {
            await navigator.directRequest(rate.links.self.delete);

            snackbar.show({
                content   : translated.currencies.editor.removeRateSuccess,
                isSuccess : true,
            });

            return true;
        } catch (e) {
            snackbar.show({
                content : translated.currencies.editor.errors.removeRateError,
                isError : true,
            });
        }

        return null;
    };

    const canMakeChanges = !!data.links?.levelUpgradeRates?.create;

    const title = canMakeChanges ? translated.currencies.editor.editTitle : translated.currencies.editor.viewTitle;

    return (
        <Form.WrapperNew
            schema={schema}
            ref={wrapperRef}
            initialValues={{ name: data?.name, upgradeRates: [] }}
            formId="currency-data-form"
        >
            <SectionsWrapper
                title={title}
                actionButtons={[
                    <Form.SecondaryNew key="fs" onClick={navigator.goToRoot}>
                        <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
                    </Form.SecondaryNew>,
                ]}
            >
                {initialRates.isEnabled && !initialRates.isLoaded && <Loading />}

                <FormNew forceValidationsOnFirstRender avoidSetComponentWithChanges>
                    <Form.Title className="margin-bottom-small">
                        <FormattedMessage id={translated.currencies.editor.information} defaultMessage={translated.currencies.editor.information} />
                    </Form.Title>
                    <Form.InputNew
                        submitKey="name"
                        isDense
                        type="text"
                        label={translated.global.name}
                        isRequired
                        isDisabled
                        className="text-input margin-bottom-xxlarge"
                    />

                    {initialRates.isEnabled && initialRates.isLoaded && initialRates.list && (
                        <CurrenciesTable
                            elements={initialRates.list}
                            onAdd={onAdd}
                            onEdit={onEdit}
                            onRemove={onRemove}
                            canModify={canMakeChanges}
                        />
                    )}
                </FormNew>

            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={80} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={135} className="margin-bottom-medium" />
                    <Skeleton.Form type="input" />
                </Grid.Column>
            </Grid>
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={145} className="margin-bottom-medium" />
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
        </>
    );
};

Editor.defaultProps = {};

Editor.propTypes = { data: PropTypes.shape({}).isRequired };

export default withRequest(Editor);
