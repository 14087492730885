import React from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import { analyzeValue } from 'Components/Form/Validator';
import ruleTypes from './constants';
import { rulesFilterConfigurations } from 'Constants/global';

export function exampleTextForConversionValue(isFixedPerNight, isFixedOnTotal, value, option) {
    const pricePerNight = 10000;
    const totalNights = 5;
    const originalValue = pricePerNight * totalNights;
    const conversionValue = Number(value);
    const hasToSum = option !== 'Less';
    let result;

    if (isFixedPerNight) {
        result = hasToSum ? pricePerNight + conversionValue : pricePerNight - conversionValue;
        result = Math.round(result);

        return (
            <FormattedMessage
                id={translated.rules.examples.fixedPerNight}
                defaultMessage={translated.rules.examples.fixedPerNight}
                values={{ totalNights, originalValue, result }}
            />
        );
    }

    if (isFixedOnTotal) {
        result = hasToSum ? originalValue + conversionValue : originalValue - conversionValue;
        result = Math.round(result);

        return (
            <FormattedMessage
                id={translated.rules.examples.fixedOnTotal}
                defaultMessage={translated.rules.examples.fixedOnTotal}
                values={{ originalValue, result }}
            />
        );
    }

    // Percentage
    const percent = (originalValue * conversionValue) / 100;
    result = hasToSum ? originalValue + percent : originalValue - percent;

    return (
        <FormattedMessage
            id={translated.rules.examples.percentage}
            defaultMessage={translated.rules.examples.percentage}
            values={{ originalValue, result }}
        />
    );
}

export function getOccupancy(minOccupancy, maxOccupancy) {
    let condition;
    let value;

    if (minOccupancy === maxOccupancy && minOccupancy !== null) {
        // Equal to
        condition = 'Equal';
        value = minOccupancy;
    } else if (maxOccupancy === 999) {
        // Less than
        condition = 'More';
        value = minOccupancy - 1;
    } else if (minOccupancy === 1 && maxOccupancy !== 1) {
        // More than
        condition = 'Less';
        value = maxOccupancy + 1;
    } else {
        return null;
    }

    return { condition, value };
}

export function occupancyForSummary({ value, condition, properties }) {
    if (typeof value === 'string' || typeof value === 'number') {
        const summary = {
            label        : translated.rules.occupancy,
            summaryIndex : 26,
        };

        if (!properties) {
            summary.isCompleted = analyzeValue(value).isFullyComplete;
            summary.isValid = condition === 'Less' ? value >= 2 : value >= 1;
        } else {
            summary.isCompleted = properties.isCompleted;
            summary.isValid = properties.isValid;
        }

        if (value) {
            if (condition === 'Less') {
                summary.value = (
                    <FormattedMessage
                        id={translated.rules.summary.occupancy.less}
                        defaultMessage={translated.rules.summary.occupancy.less}
                        values={{ value }}
                    />
                );
            } else if (condition === 'More') {
                summary.value = (
                    <FormattedMessage
                        id={translated.rules.summary.occupancy.more}
                        defaultMessage={translated.rules.summary.occupancy.more}
                        values={{ value }}
                    />
                );
            } else if (condition === 'Equal') {
                summary.value = (
                    <FormattedMessage
                        id={translated.rules.summary.occupancy.equals}
                        defaultMessage={translated.rules.summary.occupancy.equals}
                        values={{ value }}
                    />
                );
            }
        }

        return { condition, value, summary };
    }
    return { value, condition };
}

export function formatDatesForSummary(dateManager, value, neededDateValue) {
    if (value !== 'Always' && !neededDateValue) {
        return (
            <span className="text-color-warning">
                <FormattedMessage
                    id={translated.rules.summary.dates.neededSelection}
                    defaultMessage={translated.rules.summary.dates.neededSelection}
                    // eslint-disable-next-line max-len
                    values={{ value: <FormattedMessage id={translated.rules[value.toLowerCase()]} defaultMessage={translated.rules[value.toLowerCase()]} /> }}
                />
            </span>
        );
    }
    if (value === 'Always') {
        return <FormattedMessage id={translated.rules.alwaysSummary} defaultMessage={translated.rules.alwaysSummary} />;
    }

    const labelId = value === 'Only' ? translated.rules.occupancyOnlyApplies : translated.rules.occupancyAlwaysApplies;

    let dates = [];
    neededDateValue.reduce((result, val) => {
        const { begin, end } = val;
        const formattedBegin = dateManager.format(begin, 'PPPP');
        const formattedEnd = dateManager.format(end, 'PPPP');
        const range = `${formattedBegin} - ${formattedEnd}`;
        result.push(range);

        return result;
    }, dates);
    dates = dates.join(', ');

    return <FormattedMessage id={labelId} defaultMessage={labelId} values={{ dates }} />;
}

export function formatHotelFilterForSummary({ value, formValues }) {
    if (formValues.type === ruleTypes.EXCEPTION && !formValues.propertyGroups && !formValues.properties) {
        return (
            <span className="text-color-warning">
                <FormattedMessage id={translated.rules.summary.hotelsNeeded} defaultMessage={translated.rules.summary.hotelsNeeded} />
            </span>
        );
    }

    switch (value) {
        case rulesFilterConfigurations.INCLUDE:
            return <FormattedMessage id={translated.rules.includesAvailableSummary} defaultMessage={translated.rules.includesAvailableSummary} />;
        case rulesFilterConfigurations.EXCLUDE_HIDE:
            return <FormattedMessage id={translated.rules.excludesHideSummary} defaultMessage={translated.rules.excludesHideSummary} />;
        case rulesFilterConfigurations.EXCLUDE_SHOW_UPGRADE:
            return <FormattedMessage id={translated.rules.excludesShowUpgradeSummary} defaultMessage={translated.rules.excludesShowUpgradeSummary} />;
        default:
            break;
    }

    return '';
}
