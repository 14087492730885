import React from 'react';
import PropTypes from 'prop-types';

function Table({
    className, hasTools, hasTitle, hasActions, hasPagination, hasCheckbox, rows,
}) {
    return (
        <div className={`skeleton skeleton-table ${hasTitle ? 'has-title' : ''} ${className}`}>
            {hasTools && (
                <div className={`skeleton-table-tools ${hasTitle ? 'has-title' : ''}`}>
                    {hasTitle && <div className="skeleton-element skeleton-table-title" />}
                    {hasActions && (
                        <div className="skeleton-table-tools-actions">
                            <div className="skeleton-element skeleton-table-search" />
                            <div className="skeleton-element skeleton-table-view" />
                            <div className="skeleton-element skeleton-table-settings" />
                        </div>
                    )}
                </div>
            )}
            <table>
                <thead>
                    <tr>
                        {hasCheckbox && (
                            <th className="skeleton-table-cell-checkbox">
                                <div className="skeleton-element skeleton-table-checkbox" />
                            </th>
                        )}
                        <th aria-label="skeleton-element"><div className="skeleton-element skeleton-table-cell" /></th>
                        <th aria-label="skeleton-element"><div className="skeleton-element skeleton-table-cell" /></th>
                        <th aria-label="skeleton-element"><div className="skeleton-element skeleton-table-cell" /></th>
                        <th aria-label="skeleton-element"><div className="skeleton-element skeleton-table-cell" /></th>
                        <th aria-label="skeleton-element" />
                    </tr>
                </thead>
                <tbody>
                    {Array.from(Array(rows).keys()).map((x) => (
                        <tr key={x}>
                            {hasCheckbox && <td><div className="skeleton-element skeleton-table-checkbox" /></td>}
                            <td><div className="skeleton-element skeleton-table-cell" /></td>
                            <td><div className="skeleton-element skeleton-table-cell" /></td>
                            <td><div className="skeleton-element skeleton-table-cell" /></td>
                            <td><div className="skeleton-element skeleton-table-cell" /></td>
                            <td>
                                <div className="skeleton-element skeleton-table-cell" />
                                <div className="skeleton-element skeleton-table-cell" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {(hasPagination || (rows >= 10)) && (
                <div className="skeleton-table-pagination">
                    <div className="skeleton-element skeleton-table-pages" />
                    <div className="skeleton-element skeleton-table-total" />
                    <div className="skeleton-table-page">
                        <div className="skeleton-element skeleton-table-button" />
                        <div className="skeleton-element skeleton-table-button" />
                        <div className="skeleton-element skeleton-table-button" />
                        <div className="skeleton-element skeleton-table-button" />
                    </div>
                </div>
            )}
        </div>
    );
}

Table.defaultProps = {
    className     : '',
    hasTools      : true,
    hasPagination : false,
    hasCheckbox   : false,
    hasTitle      : false,
    hasActions    : true,
    rows          : 3,
};

Table.propTypes = {
    className     : PropTypes.string,
    hasTools      : PropTypes.bool,
    hasPagination : PropTypes.bool,
    hasCheckbox   : PropTypes.bool,
    hasTitle      : PropTypes.bool,
    hasActions    : PropTypes.bool,
    rows          : PropTypes.number,
};

export default Table;
