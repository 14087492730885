import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Alert from 'Components/Alert';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';

const getTiersColumns = () => [
    {
        title       : translated.global.name,
        key         : 'name',
        isVisible   : true,
        hasMaxWidth : true,
    },
    {
        title    : translated.global.status,
        key      : 'isActive',
        whenTrue : {
            text      : translated.global.state.enabled,
            className : 'status status-enabled',
        },
        whenFalse: {
            text      : translated.global.state.disabled,
            className : 'status status-disabled',
        },
        isVisible: true,
    },
    {
        title     : translated.global.id,
        key       : 'id',
        isVisible : true,
        isNumeric : true,
    },
];

// eslint-disable-next-line no-unused-vars
function getTiersActions(navigator, handleClickOpenDelete, handleClickChangeStatus) {
    return [
        {
            content  : translated.global.buttons.disable,
            callback : (tier) => handleClickChangeStatus(tier, false),
            key      : 'disable',
            when     : (tier) => tier?.links?.self?.patch && tier.isActive,
        },
        {
            content  : translated.global.buttons.enable,
            callback : (tier) => handleClickChangeStatus(tier, true),
            key      : 'enable',
            when     : (tier) => tier?.links?.self?.patch && !tier.isActive,
        },
        {
            content  : translated.global.buttons.delete,
            callback : handleClickOpenDelete,
            key      : 'delete',
            when     : (tier) => tier?.links?.self?.delete,
        },
        {
            content           : translated.global.buttons.edit,
            callback          : (data) => navigator.goToTier({ available: data.links.self, current: 'read' }),
            key               : 'edit',
            isOutsideDropdown : true,
            icon              : 'Pencil',
            tooltip           : translated.global.buttons.edit,
            when              : (tier) => tier?.links?.self?.update,
        },
    ];
}

function List({ data, fetching, reloadData, resources, sections }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);
    const { links, data: tiers, order, pagination } = data || {};

    const handleClickPerformDelete = async (tier) => {
        try {
            const reqConfig = {
                ...tier.links.self.delete,
                isCritical       : false,
                isGlobal         : false,
                ids              : [tier.id],
                shouldReloadData : true,
            };
            await navigator.requestForCurrentPath({
                reqConfig,
                section: sectionType.TIERS,
                resources,
            });

            snackbar.show({
                content   : translated.tiers.delete.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                error,
                isError     : true,
                errorLabels : translated.tiers.delete,
            });
        }
    };

    const handleClickOpenDelete = (tier) => {
        confirmation.show({
            message       : translated.tiers.delete.confirmation,
            acceptMessage : translated.global.buttons.delete,
            onAccept      : () => handleClickPerformDelete(tier),
        });
    };

    const handleClickChangeStatus = async (tier, enable) => {
        const { id, links: tierLinks } = tier;
        const reqConfig = {
            ...tierLinks.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({
                reqConfig,
                section: sectionType.TIERS,
                resources,
            });

            snackbar.show({
                content   : enable ? translated.tiers.enable.success : translated.tiers.disable.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.tiers.enable.error : translated.tiers.disable.error,
                isError : true,
                error,
            });
        }
    };
    const { Section, props: subsecProps } = Sections.get(sections);

    const sectionButtons = links?.self?.create
        ? [
            <Button
                id="tiers-add-button"
                variant="unelevated"
                key="button_primary"
                onClick={() => navigator.goToTier({ available: links.self, current: 'init' })}
            >
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />}
            <Wrapper title={translated.tiers.title} actionButtons={sectionButtons}>
                <Table
                    id="tiers"
                    key="tiers"
                    columns={getTiersColumns()}
                    rows={{ actions: getTiersActions(navigator, handleClickOpenDelete, handleClickChangeStatus) }}
                    items={tiers}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    whenEmpty={(
                        <Alert
                            id="tiers-empty"
                            content={translated.tiers.noTiers}
                            actionText={translated.global.buttons.add}
                            onClick={() => navigator.goToTier({ available: links.self, current: 'init' })}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

List.defaultProps = {
    data     : [],
    fetching : {},
};

List.propTypes = {
    data       : PropTypes.shape({}),
    fetching   : PropTypes.shape({}),
    resources  : PropTypes.shape({}).isRequired,
    reloadData : PropTypes.func.isRequired,
    sections   : PropTypes.shape({}).isRequired,
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={120} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
