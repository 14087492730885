import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import PropTypes from 'prop-types';
import Subsection from 'Components/Sections/Subsection';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import Editor from './Translation';
import Button from 'Components/Button';

const getLanguageName = (id, languages) => languages.find((e) => e.id === id)?.name;

const convertList = (list, languages) => list?.map((e) => ({
    ...e,
    id           : e.id,
    language     : e.id,
    languageName : getLanguageName(e.id, languages),
}));

export function Translations({ elements, updateLanguages, availableLanguages }) {
    const intl = useIntl();
    const [translations, setTranslations] = useState(convertList(elements, availableLanguages) || []);
    const [modal, setModal] = useState({ isVisible: false, data: {} });

    const updateList = (updatedList) => {
        updateLanguages(
            updatedList.map((e) => ({
                ...e,
                id: undefined,
            })),
        );
    };

    const onAdd = (element) => {
        setTranslations((prev) => {
            const updatedList = [
                ...prev,
                {
                    ...element,
                    id           : element.language,
                    languageName : getLanguageName(element.language, availableLanguages),
                },
            ];

            updateList(updatedList);

            return updatedList;
        });

        return true;
    };

    const onEdit = (element) => {
        setTranslations((prev) => {
            const updatedList = prev.map((e) => (e.id !== element.id
                ? { ...e }
                : { ...element, languageName: getLanguageName(element.language, availableLanguages), id: element.language }));
            updateList(updatedList);

            return updatedList;
        });

        return true;
    };

    const openModal = (element = null) => {
        setModal((prev) => ({
            ...prev,
            isVisible : true,
            data      : element ? { ...element } : {},
            isEditing : !!element,
        }));
    };

    const removeOnClick = (element) => {
        setTranslations((prev) => {
            const updatedList = prev.filter((e) => e.id !== element.id);
            updateList(updatedList);

            return updatedList;
        });
    };

    const modalOnCancel = () => {
        setModal((prev) => ({ ...prev, isVisible: false }));
    };

    const modalOnSave = async (element) => {
        if (element.id && onEdit ? await onEdit(element) : await onAdd(element)) {
            setModal((prev) => ({ ...prev, isVisible: false }));
        } else {
            throw new Error();
        }
    };

    const buttons = [];

    if (translations?.length) {
        buttons.push(
            <Button id="rule-translations-add-button" variant="outlined" color="primary" onClick={openModal} key="add_language">
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        );
    }

    return (
        <Subsection
            title={translated.rules.translations.title}
            actionButtons={buttons}
            className="margin-top-xxlarge"
        >
            {modal.isVisible && (
                <Editor
                    title={modal.data?.id ? translated.rules.translations.modal.editTitle : translated.rules.translations.modal.addTitle}
                    data={modal.data}
                    onSave={modalOnSave}
                    onCancel={modalOnCancel}
                    availableLanguages={availableLanguages.filter(
                        (available) => modal.data?.language === available.id || !translations.find((used) => used.language === available.id),
                    )}
                />
            )}

            <Table
                id="rule-translations"
                key="rule-translations"
                hasTools={false}
                columns={[
                    {
                        title     : translated.rules.translations.language,
                        key       : 'languageName',
                        isVisible : true,
                    },
                    {
                        title     : translated.rules.translations.name,
                        key       : 'title',
                        isVisible : true,
                    },
                    {
                        title     : translated.rules.translations.description,
                        key       : 'description',
                        isVisible : true,
                    },
                ]}
                rows={{
                    actions: [
                        {
                            key               : 'edit',
                            content           : translated.global.buttons.edit,
                            callback          : openModal,
                            isOutsideDropdown : true,
                            icon              : 'Pencil',
                            tooltip           : intl.formatMessage({
                                id             : translated.global.buttons.edit,
                                defaultMessage : translated.global.buttons.edit,
                            }),
                        },
                        {
                            key               : 'delete',
                            content           : translated.global.buttons.delete,
                            callback          : removeOnClick,
                            isOutsideDropdown : true,
                            icon              : 'TrashCan',
                            tooltip           : intl.formatMessage({
                                id             : translated.global.buttons.delete,
                                defaultMessage : translated.global.buttons.delete,
                            }),
                        },
                    ],
                }}
                items={translations}
                whenEmpty={(
                    <Alert
                        id="rule-translations-empty"
                        buttonId="rule-translations-add-button"
                        content={translated.rules.translations.empty}
                        actionText={translated.global.buttons.add}
                        onClick={openModal}
                    />
                )}
            />
        </Subsection>
    );
}

Translations.defaultProps = { elements: [] };

Translations.propTypes = {
    availableLanguages : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    elements           : PropTypes.arrayOf(PropTypes.shape({})),
    updateLanguages    : PropTypes.func.isRequired,
};

export default Translations;
