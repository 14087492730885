import React, { useContext, useState, useEffect, useCallback, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import SectionsWrapper from 'Components/Sections/Wrapper';
import Subsection from 'Components/Sections/Subsection';
import PanelContext from 'State/panelContext';
import Form, { FormError } from 'Components/Form';
import Grid from 'Components/Grid';
import Status from 'Components/Status';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from 'Components/Sections/Collection';
import translated from 'Constants/labels/translated';
import { extraAttributesFilters, clubDuration } from 'Constants/global';
import BankingConfigurationList from 'Components/Sections/Shared/BankingConfigurations/ListWithoutRequest';
import convertBankingConfigurations from 'Components/Sections/Contracts/utils';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';
import requestErrors from 'Constants/requestErrors';

export function General({ data, sections, resources, isEditing }) {
    const intl = useIntl();
    const { contract = {}, legacyId, status, extraAttributes, company } = data;

    const { navigator, snackbar, dateManager } = useContext(PanelContext);
    const extraAttributesRef = createRef();
    const [change, setChange] = useState(false);
    const [clubsData, setClubsData] = useState({
        clubs         : [],
        selectedClub  : {},
        selectedTier  : {},
        isSearching   : false,
        contractTypes : null,
    });

    const [newMinDateValue, setNewMinDateValue] = useState();

    const consumptionModes = resources?.additional?.consumptionModes?.data.map((e) => ({ value: e.id, content: e.code }));
    const [newPartnerContract, setNewPartnerContract] = useState({
        bankingConfigurations : [],
        begin                 : '',
        consumptionMode       : { id: null },
        contractType          : { id: null },
        end                   : '',
        extraAttributes       : {},
        legacyId              : '',
        highlight             : false,
    });

    const { available, additional } = resources;

    let beginMin;
    let beginMax;
    let minDateValue;
    let maxDateValue;

    const getAllClubs = async (link) => {
        if (isEditing) {
            const { club: selectedClub, tier: selectedTier } = contract?.contractType?.clubTier || {};
            const convertedTier = { content: selectedTier?.name, value: contract?.contractType?.clubTier?.id };
            const convertedClub = { content: selectedClub?.name, value: selectedClub?.id, tiers: [convertedTier] };

            setClubsData((prev) => ({
                ...prev,
                clubs        : [convertedClub],
                selectedClub : convertedClub,
                selectedTier : convertedTier,
            }));

            return;
        }

        if (!link) {
            snackbar.show({
                content : translated.partners.errors.club,
                isError : true,
            });

            return;
        }

        setClubsData((prev) => ({
            ...prev,
            isSearching: true,
        }));

        let allClubs = [];
        try {
            allClubs = await navigator.directRequest(link);
        } catch (e) {
            snackbar.show({
                content : translated.partners.errors.club,
                error   : e,
                isError : true,
            });

            setClubsData((prev) => ({ ...prev, isSearching: false }));

            return;
        }

        const convertedClubs = allClubs.map((each) => ({ ...each, value: each.id, content: each.name }));
        const selectedClub = contract?.clubId ? convertedClubs.find((each) => each.id === contract.clubId) : null;

        setClubsData((prev) => ({
            ...prev,
            clubs       : convertedClubs,
            isSearching : false,
            selectedClub,
        }));
    };

    const convertContractTypes = (list) => (list?.length
        ? list.map((e) => ({
            content : e.name,
            value   : e.id,
        }))
        : []);

    const getAllContractTypes = useCallback(
        async (link) => {
            if (link === undefined) {
                return;
            }
            let allContractTypes = [];
            let convertedContractTypes = [];

            try {
                allContractTypes = await navigator.directRequest(link);
                convertedContractTypes = convertContractTypes(allContractTypes);
            } catch (e) {
                snackbar.show({
                    content : translated.partners.errors.contracts,
                    error   : e,
                    isError : true,
                });
                return;
            }

            setClubsData((prev) => ({
                ...prev,
                contractTypes: convertedContractTypes,
            }));
        },
        [navigator, snackbar],
    );

    useEffect(() => {
        getAllClubs(additional?.clubs?.read);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeBeginDate = useCallback(
        (inputValue) => {
            if (dateManager.isValidDate(inputValue)) {
                const newMinDate = dateManager.getNextDayFormatted(inputValue);
                if (newMinDate !== newMinDateValue) {
                    setNewMinDateValue(newMinDate);
                }
            }
            setNewPartnerContract((prev) => ({ ...prev, begin: inputValue }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [newMinDateValue],
    );

    const handleOnContractTypeChange = useCallback(
        (newContractTypeId) => {
            const selectedContractType = clubsData?.contractTypes?.find((e) => e.value === newContractTypeId);

            setNewPartnerContract((previous) => ({
                ...previous,
                contractType: { id: selectedContractType.value },
            }));
        },
        [clubsData],
    );

    const handleOnConsumptionModeChange = useCallback(
        (newContractTypeId) => {
            const selectedConsumptionMode = consumptionModes?.find((e) => e.value === newContractTypeId);

            setNewPartnerContract((previous) => ({
                ...previous,
                consumptionMode: { id: selectedConsumptionMode.value },
            }));
        },
        [consumptionModes],
    );

    const onTierChange = useCallback(
        async (tierId) => {
            const selectedTier = clubsData.selectedClub?.tiers.find((each) => each.id === tierId);

            await getAllContractTypes(selectedTier?.links?.contractTypes?.read);

            setClubsData((prev) => ({ ...prev, selectedTier }));
        },
        [clubsData, getAllContractTypes],
    );

    const handleOnChangeEndDate = useCallback((inputValue) => {
        setNewPartnerContract((prev) => ({ ...prev, end: inputValue }));
    }, []);

    const handleOnChangeContractLegacyId = useCallback((inputValue) => {
        setNewPartnerContract((prev) => ({ ...prev, legacyId: inputValue }));
    }, []);

    const [bankingConfigurations, setBankingConfigurations] = useState(
        data?.bankingConfigurations?.map((e) => ({
            ...convertBankingConfigurations(e),
            balanceType: { id: e.balanceType?.id },
        })) || [],
    );

    const getWarningsContent = useCallback((warnings) => {
        if (!warnings?.length) {
            return null;
        }

        return warnings.map(({ code, value }) => {
            const key = requestErrors[code] || 'default';
            const message = intl.formatMessage(
                { id: translated.imports.saveWarnings[key] },
                { value },
            );

            return { message, code };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (clubsData?.selectedClub?.clubDuration === clubDuration.FIXED) {
        if (dateManager.isValidDate(newPartnerContract.begin)) {
            minDateValue = dateManager.format(
                dateManager.add(
                    newPartnerContract.begin,
                    1,
                    'days',
                ),
                'yyyy-MM-dd',
            );
            maxDateValue = dateManager.format(
                dateManager.sub(
                    dateManager.add(
                        newPartnerContract.begin,
                        clubsData?.selectedClub?.duration,
                        'months',
                    ),
                    1,
                    'days',
                ),
                'yyyy-MM-dd',
            );
        }
    } else {
        beginMin = clubsData.selectedClub?.dateRange?.begin;
        beginMax = clubsData.selectedClub?.dateRange?.end;
        minDateValue = clubsData?.selectedClub?.endMinDate && dateManager.isValidDate(beginMin)
            ? clubsData?.selectedClub?.endMinDate
            : clubsData?.selectedClub?.dateRange?.begin;
        maxDateValue = clubsData?.selectedClub?.dateRange?.end;
    }

    const getDatesExtraInfo = (minDate, maxDate) => (
        <div>
            <span>
                <FormattedMessage defaultMessage={translated.global.feb29Warn} id={translated.global.feb29Warn} />
            </span>
            <br />
            {minDate ? (
                <span>
                    <FormattedMessage defaultMessage={translated.global.validMinDate} id={translated.global.validMinDate} values={{ date: minDate }} />
                </span>
            ) : null}
            {minDate ? <br /> : null}
            {maxDate ? (
                <span>
                    <FormattedMessage defaultMessage={translated.global.validMaxDate} id={translated.global.validMaxDate} values={{ date: maxDate }} />
                </span>
            ) : null}
        </div>
    );

    const getClubTiers = async (link, clubId) => {
        if (!link) {
            snackbar.show({
                content : translated.partners.errors.clubTiers,
                isError : true,
            });

            return;
        }

        try {
            setClubsData((prev) => ({
                ...prev,
                isSearching: true,
            }));

            const clubTiers = await navigator.directRequest(link);
            const convertedTiers = (clubTiers
                    && clubTiers.map((eachTier) => ({
                        ...eachTier,
                        value   : eachTier?.id,
                        content : eachTier?.tier?.name,
                        id      : eachTier?.id,
                    })))
                || [];

            setClubsData((prev) => {
                const updatedClubs = prev.clubs.map((each) => ({
                    ...each,
                    tiers: each.id === clubId ? convertedTiers : each.tiers,
                }));

                return {
                    ...prev,
                    clubs        : updatedClubs,
                    isSearching  : false,
                    selectedClub : updatedClubs.find((e) => e.id === clubId),
                };
            });
        } catch (e) {
            snackbar.show({
                content : translated.partners.errors.clubTiers,
                error   : e,
                isError : true,
            });

            setClubsData((prev) => ({ ...prev, isSearching: false }));
        }
    };

    const accountOnSubmit = async ({ values }) => {
        const link = isEditing ? available?.update : available?.create;
        let reqConfig = {};

        validateDynamicInputs(values.extraAttributes, dateManager);

        if (values?.contract?.beginDate && values?.contract?.endDate) {
            const begin = values.contract.beginDate;
            const end = values.contract.endDate;

            if (dateManager.isAfter(begin, end)) {
                snackbar.show({
                    content : translated.partners.accountEdition.invalidDates,
                    isError : true,
                });

                return;
            }
        }

        if (!clubsData.selectedClub?.tiers || !clubsData.selectedClub?.tiers.length) {
            snackbar.show({
                content : translated.partners.accountEdition.clubWithNoTiers,
                isError : true,
            });

            return;
        }

        if (bankingConfigurations?.length) {
            bankingConfigurations.forEach((e, index) => {
                if (!e.balanceType?.id || (e.repeat && !e.repeatAnniversary) || (!e.repeat && (!e.effectiveDate || !e.expiryDate)) || e.amount == null) {
                    const message = (
                        <FormattedMessage
                            defaultMessage={translated.owners.contracts.edition.bankingConfigurationMissingFields}
                            id={translated.owners.contracts.edition.bankingConfigurationMissingFields}
                            values={{ number: index + 1 }}
                        />
                    );
                    throw new FormError(message);
                }
                if (
                    !e.repeat
                    && (!dateManager.isBetweenEqual(e.effectiveDate, values.contract.begin, values.contract.end, 'days')
                        || !dateManager.isBetweenEqual(e.expiryDate, e.effectiveDate, values.contract.end, 'days'))
                ) {
                    const message = (
                        <FormattedMessage
                            defaultMessage={translated.owners.contracts.edition.bankingConfigurationDateValidation}
                            id={translated.owners.contracts.edition.bankingConfigurationDateValidation}
                            values={{ number: index + 1 }}
                        />
                    );
                    throw new FormError(message);
                }
            });
        }

        if (isEditing) {
            reqConfig = {
                ...link,
                data: {
                    id              : data.id,
                    type            : data.type,
                    primary         : { id: data.primary.id },
                    company         : values.company,
                    status          : data.status,
                    legacyId        : data.legacyId,
                    extraAttributes : values.extraAttributes,
                },
                shouldReloadData: true,
            };
        } else {
            reqConfig = {
                ...link,
                data: {
                    ...values,
                    contracts: [
                        {
                            ...newPartnerContract,
                            extraAttributes       : values.contract?.extraAttributes,
                            highlight             : values.contract?.highlight,
                            bankingConfigurations : bankingConfigurations?.length
                                ? bankingConfigurations.map((each) => convertBankingConfigurations(each))
                                : [],
                        },
                    ],
                    contract: undefined,
                },
                shouldReloadData: true,
            };

            // TODO: Could all this code be improved using initialValues in the form? when a value in the form has not val
            // it is by default 'null', so it wont be necessary, and passing the data as initialValues, it will take those values
            delete reqConfig.data.clubTier;
            delete reqConfig.data.dateRange;
        }

        try {
            const partner = await navigator.requestForCurrentPath({ reqConfig });
            const snackbarProps = {
                content   : isEditing ? translated.partners.accountEdition.editionSuccess : translated.partners.accountEdition.creationSuccess,
                isSuccess : true,
            };

            if (partner?.warnings?.length) {
                snackbarProps.content = (
                    <>
                        <FormattedMessage id={translated.partners.saveWarning} />
                        <ul className="margin-top-small padding-left">
                            {getWarningsContent(partner?.warnings).map(({ message, code }) => (
                                <li key={code}>{message}</li>
                            ))}
                        </ul>
                    </>
                );
                snackbarProps.isWarning = true;
                snackbarProps.isSuccess = false;
                snackbarProps.timeout = 20 * 1000; // 20 seconds
            }

            snackbar.show(snackbarProps);
        } catch (saveError) {
            throw new FormError(
                isEditing ? translated.partners.accountEdition.editionError : translated.partners.accountEdition.creationError,
                saveError,
                translated.partners.accountEdition.errors,
            );
        }
    };

    const { Section, props } = SelectedSection.get(sections);

    if (Section) {
        return <Section {...props} />;
    }

    const profileTitle = (
        <>
            {isEditing ? (
                <FormattedMessage defaultMessage={translated.partners.accountEdition.title} id={translated.partners.accountEdition.title} />
            ) : (
                <FormattedMessage
                    defaultMessage={translated.partners.accountEdition.titleCreation}
                    id={translated.partners.accountEdition.titleCreation}
                />
            )}
            <span className="section-header-id">{legacyId}</span>
            {status && <Status className="margin-left-small" status={status} />}
        </>
    );

    const resetConfirmation = {
        title   : translated.global.resetConfirmation.title,
        message : translated.global.resetConfirmation.message,
    };

    // eslint-disable-next-line max-len
    const availableAccountAttributes = resources?.additional?.extraAttributes?.filter((attribute) => attribute.categories.includes(extraAttributesFilters.CLIENT)) || [];
    // eslint-disable-next-line max-len
    const availableContractAttributes = resources?.additional?.extraAttributes?.filter((attribute) => attribute.categories.includes(extraAttributesFilters.CONTRACT)) || [];

    const handleOnReset = () => {
        setChange(!change);
    };

    const actionButtons = [
        isEditing ? (
            <Form.Reset key="fs" confirmation={resetConfirmation} onClick={handleOnReset} color="primary">
                <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
            </Form.Reset>
        ) : (
            <Form.Secondary key="fs" onClick={navigator.goToRoot}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>
        ),
        <Form.Primary key="fp">
            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
        </Form.Primary>,
    ];

    const onClubChange = async (clubId) => {
        let selectedClub;
        setClubsData((prev) => {
            selectedClub = prev.clubs.find((each) => each.id === clubId);
            return {
                ...prev,
                selectedClub,
                selectedTier  : [],
                contractTypes : [],
            };
        });

        if (!selectedClub?.tiers) {
            if (!selectedClub?.links?.tiers?.read) {
                snackbar.show({
                    content : translated.partners.errors.clubTiers,
                    isError : true,
                });
            } else {
                getClubTiers(selectedClub.links.tiers.read, clubId);
            }
        }
    };

    return (
        <SectionsWrapper title={profileTitle} className="owner-wrapper">
            <Form.Wrapper>
                <Subsection
                    title={translated.partners.accountEdition.partnerSection.title}
                    className="account-subsection margin-top-medium"
                    actionButtons={actionButtons}
                >
                    <Form id="partner-account" buttonsWidth={{ base: 12, medium: 6 }} onSubmit={accountOnSubmit}>
                        <Form.Column width={{ base: 12, small: 6 }}>
                            <Form.Input
                                submitKey="company"
                                isDense
                                type="text"
                                label={translated.global.name}
                                isRequired
                                value={company}
                                validations={{ minLength: 2, maxLength: 100 }}
                                charCount={{ total: 100 }}
                            />
                            {!isEditing && (
                                <Form.Input
                                    isDense
                                    isRequired
                                    submitKey="legacyId"
                                    type="text"
                                    label={translated.owners.legacyId}
                                    value={legacyId}
                                    isDisabled={isEditing}
                                    validations={{ maxLength: 36, unary: ['alphanumericUnderScore'] }}
                                    charCount={{ total: 36 }}
                                    avoidMarkAsChangedOnValidationsChange
                                />
                            )}
                            <Form.Input
                                id="extra-attributes"
                                key="extra-attributes"
                                type="dynamicInputs"
                                submitKey="extraAttributes"
                                value={extraAttributes}
                                availableAttributes={availableAccountAttributes}
                                className="margin-top-xlarge"
                                label={translated.owners.extraAttributes.accountTitle}
                                submitFormat={(values) => transformDynamicInputs(values)}
                                ref={extraAttributesRef}
                            />
                            {!isEditing && (
                                <Form.Title className="margin-top-xlarge">
                                    <FormattedMessage
                                        id={translated.global.contract}
                                        defaultMessage={translated.global.contract}
                                    />
                                </Form.Title>
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="club"
                                    avoidOnSubmit
                                    isDense
                                    type="select"
                                    label={translated.global.club}
                                    value={contract?.clubId}
                                    options={clubsData?.clubs || []}
                                    onChange={onClubChange}
                                    isDisabled={isEditing || clubsData.searching}
                                    defaultOptionText={translated.global.selectAnOption}
                                    isRequired
                                    avoidMarkAsChangedOnValidationsChange
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="clubTier"
                                    isDense
                                    type="select"
                                    label={translated.global.tier}
                                    value={contract?.clubTierId}
                                    options={clubsData?.selectedClub?.tiers || []}
                                    onChange={onTierChange}
                                    isDisabled={isEditing || clubsData.searching || !clubsData?.selectedClub?.tiers?.length}
                                    defaultOptionText={
                                        clubsData?.selectedClub?.tiers && !clubsData.selectedClub.tiers.length
                                            ? translated.partners.accountEdition.partnerSection.clubWithNoTiers
                                            : translated.global.selectAnOption
                                    }
                                    isRequired
                                    avoidMarkAsChangedOnValidationsChange
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="contract.contractType"
                                    isDense
                                    isRequired
                                    type="select"
                                    label={translated.owners.contracts.contractType}
                                    value={contract?.contractType?.id || null}
                                    options={clubsData?.contractTypes || []}
                                    isDisabled={isEditing || !clubsData?.contractTypes?.length}
                                    submitFormat={(id) => ({ id })}
                                    avoidMarkAsChangedOnValidationsChange
                                    onChange={handleOnContractTypeChange}
                                    defaultOptionText={
                                        clubsData?.contractTypes?.length === 0 && clubsData?.selectedTier?.tier
                                            ? translated.owners.contracts.edition.tierWithNoContractTypes
                                            : translated.global.selectAnOption
                                    }
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="contract.consumptionMode"
                                    onChange={handleOnConsumptionModeChange}
                                    isDense
                                    isRequired
                                    type="select"
                                    label={translated.owners.contracts.consumptionMode}
                                    defaultOptionText={translated.global.selectAnOption}
                                    value={contract?.consumptionMode?.id}
                                    options={consumptionModes}
                                    submitFormat={(id) => ({ id })}
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="contract.begin"
                                    icon={{ name: 'Calendar', position: 'right' }}
                                    type="date"
                                    onChange={handleOnChangeBeginDate}
                                    label={translated.partners.accountEdition.partnerSection.contractStartDate}
                                    value={contract?.begin}
                                    minDate={beginMin}
                                    maxDate={beginMax}
                                    validations={{
                                        minDate : beginMin,
                                        maxDate : beginMax,
                                        unary   : ['date'],
                                    }}
                                    isDense
                                    helperText={{ label: translated.global.pickerInput.format }}
                                    className="date-input"
                                    supportText={getDatesExtraInfo(beginMin, beginMax)}
                                    isRequired
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="contract.end"
                                    icon={{ name: 'Calendar', position: 'right' }}
                                    type="date"
                                    onChange={handleOnChangeEndDate}
                                    label={translated.partners.accountEdition.partnerSection.contractEndDate}
                                    value={contract?.end}
                                    minDate={minDateValue}
                                    maxDate={maxDateValue}
                                    validations={{
                                        minDate : minDateValue,
                                        maxDate : maxDateValue,
                                        unary   : ['date'],
                                    }}
                                    isDense
                                    helperText={{ label: translated.global.pickerInput.format }}
                                    className="date-input"
                                    supportText={getDatesExtraInfo(minDateValue, maxDateValue)}
                                    isRequired
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    submitKey="contract.legacyId"
                                    onChange={handleOnChangeContractLegacyId}
                                    isDense
                                    type="text"
                                    label={translated.global.contractId}
                                    value={contract?.legacyId}
                                    isDisabled={isEditing}
                                    isRequired
                                    validations={{ maxLength: 36, unary: ['alphanumericUnderScore'] }}
                                    charCount={{ total: 36 }}
                                    avoidMarkAsChangedOnValidationsChange
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    key="highlight"
                                    submitKey="contract.highlight"
                                    isDense
                                    type="checkbox"
                                    value={contract?.highlight || false}
                                    label={translated.owners.contracts.featured.label}
                                />
                            )}
                            {!isEditing && (
                                <Form.Input
                                    id="extra-attributes"
                                    key="extra-attributes"
                                    type="dynamicInputs"
                                    submitKey="contract.extraAttributes"
                                    value={extraAttributes}
                                    availableAttributes={availableContractAttributes}
                                    className="margin-top-xlarge"
                                    label={translated.owners.extraAttributes.contractTitle}
                                    submitFormat={(values) => transformDynamicInputs(values)}
                                    ref={extraAttributesRef}
                                />
                            )}
                        </Form.Column>
                    </Form>
                </Subsection>
            </Form.Wrapper>

            {!isEditing && (
                <div className="margin-top-xlarge">
                    <BankingConfigurationList
                        link={clubsData?.selectedTier?.links?.clubTierBankingConfiguration?.read}
                        elements={[]}
                        updateBankingConfigurations={(updated) => {
                            setBankingConfigurations(updated);
                        }}
                        isEditEnabled
                        contractData={{ begin: newPartnerContract.begin, end: newPartnerContract.end }}
                        isEnabled={!!(newPartnerContract.begin && newPartnerContract.end && newPartnerContract.contractType.id)}
                        disableWarning={translated.owners.contracts.edition.disabledWarning}
                    />
                </div>
            )}
        </SectionsWrapper>
    );
}

General.defaultProps = { data: {} };

General.propTypes = {
    data      : PropTypes.shape({}),
    sections  : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
};

General.Loading = function LoadingSkeleton() {
    return (
        <>
            <Skeleton.Title isHeading className="margin-bottom-xxlarge margin-top-medium" />
            {/* Partner information */}
            <Grid>
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading width={220} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid className="margin-top-small">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" quantity={2} />
                </Grid.Column>
            </Grid>
            {/* Account Extra Attributes */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={270} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            {/* Contract */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={100} />
                    <Skeleton.Form type="input" quantity={4} />
                    <Skeleton.Form type="input" quantity={2} smallInput />
                    <Skeleton.Form type="input" />
                    <Skeleton.Form type="checkbox" />
                </Grid.Column>
            </Grid>
            {/* Contract Extra Attributes */}
            <Grid className="margin-top-xxlarge">
                <Grid.Column width={{ base: 12 }}>
                    <Skeleton.Title isSubHeading className="margin-bottom-small" width={275} />
                </Grid.Column>
                <Grid.Column width={{ base: 12 }}>
                    <Grid>
                        <Grid.Column width={{ base: 4 }}>
                            <Skeleton.Form type="input" />
                        </Grid.Column>
                        <Grid.Column width={{ base: 8 }}>
                            <Skeleton.Button />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(General);
