module.exports = Object.freeze({
    title           : 'Conversions',
    emptyList       : 'There are no conversions',
    add             : 'Convert',
    filterText      : 'Filter by code',
    club            : 'External club name',
    fee             : 'Fee',
    pointsUsed      : 'Points used',
    pointsConverted : 'Converted points',
    reference       : 'Fee payment reference',
    date            : 'Date',
    code            : 'Code',
    status          : 'Status',
    reason          : 'Reason',
});
