export default function getOwnerLinks(links, owner) {
    // Completes the owner links with the link to make a book in BF of the corresponding owner.
    const ownerBookingLinks = (owner?.links?.bookings?.create
        && { create: owner.links.bookings.create }) || {};

    return {
        ...links,
        bookings: {
            ...(links?.bookings ? links?.bookings : {}),
            ...ownerBookingLinks,
        },
    };
}
