import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'Components/Grid';
import Form from 'Components/Form';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
import { sectionType } from 'Constants/types';
import EntityTags from 'Components/Sections/Tags/EntityTags';
import translated from 'Constants/labels/translated';

export function Info({ data, sections }) {
    const { links, extraAttributes } = data;

    return (
        <>
            <Form.Wrapper>
                <Form id="extra-attributes-form" isReadOnlyWithoutInputs>
                    <Form.Input
                        id="extra-attributes"
                        key="extra-attributes"
                        type="dynamicInputs"
                        submitKey="extraAttributes"
                        className="margin-bottom-xxlarge"
                        label={translated.extraAttributes.title}
                        value={extraAttributes}
                        availableAttributes={data?.additionalResources?.extraAttributes}
                    />
                </Form>
            </Form.Wrapper>

            {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                <EntityTags
                    {...sections[sectionType.ENTITY_TAGS]}
                    resources={{
                        ...sections[sectionType.ENTITY_TAGS].resources,
                        available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                    }}
                />
            )}
        </>
    );
}

Info.defaultProps = {};

Info.propTypes = {
    data     : PropTypes.shape({}).isRequired,
    sections : PropTypes.shape({}).isRequired,
};

Info.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-xxlarge margin-bottom-xxlarge">
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Title isSubHeading className="margin-bottom-small" width={65} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 4 }}>
                        <Skeleton.Form type="input" />
                    </Grid.Column>
                    <Grid.Column width={{ base: 8 }}>
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(Info);
