module.exports = Object.freeze({
    title          : 'apf.partners.title',
    name           : 'apf.partners.name',
    id             : 'apf.partners.id',
    menuDetails    : 'apf.partners.menuDetails',
    menuInfo       : 'apf.partners.menuInfo',
    menuBookings   : 'apf.partners.menuBookings',
    menuBalance    : 'apf.partners.menuBalance',
    menuNotes      : 'apf.partners.menuNotes',
    noPartners     : 'apf.partners.noPartners',
    contract       : 'apf.partners.contract',
    tags           : 'apf.partners.tags',
    enable         : { success: 'apf.partners.enable.success', error: 'apf.partners.enable.error' },
    disable        : { success: 'apf.partners.disable.success', error: 'apf.partners.disable.error' },
    accountEdition : {
        title           : 'apf.partners.accountEdition.title',
        titleCreation   : 'apf.partners.accountEdition.titleCreation',
        invalidDates    : 'apf.partners.accountEdition.invalidDates',
        clubWithNoTiers : 'apf.partners.accountEdition.clubWithNoTiers',
        creationSuccess : 'apf.partners.accountEdition.creationSuccess',
        creationError   : 'apf.partners.accountEdition.creationError',
        editionSuccess  : 'apf.partners.accountEdition.editionSuccess',
        editionError    : 'apf.partners.accountEdition.editionError',
        errors          : { entityAlreadyExists: 'apf.partners.accountEdition.errors.entityAlreadyExists' },
        partnerSection  : {
            title             : 'apf.partners.accountEdition.partnerSection.title',
            contractStartDate : 'apf.partners.accountEdition.partnerSection.contractStartDate',
            contractEndDate   : 'apf.partners.accountEdition.partnerSection.contractEndDate',
            clubWithNoTiers   : 'apf.partners.accountEdition.partnerSection.clubWithNoTiers',
        },
    },
    users: {
        title     : 'apf.partners.users.title',
        emptyList : 'apf.partners.users.emptyList',
        delete    : {
            confirmation : 'apf.partners.users.delete.confirmation',
            success      : 'apf.partners.users.delete.success',
            error        : 'apf.partners.users.delete.error',
        },
    },
    user: {
        edition     : { title: 'apf.partners.user.edition.title', success: 'apf.partners.user.edition.success', error: 'apf.partners.user.edition.error' },
        creation    : { title: 'apf.partners.user.creation.title', success: 'apf.partners.user.creation.success', error: 'apf.partners.user.creation.error' },
        viewDetails : { title: 'apf.partners.user.viewDetails.title' },
    },
    errors: {
        entityAlreadyExists : 'apf.partners.errors.entityAlreadyExists',
        channelIdRequired   : 'apf.partners.errors.channelIdRequired',
        failedDependency    : 'apf.partners.errors.failedDependency',
        contracts           : 'apf.partners.errors.contracts',
        clubTiers           : 'apf.partners.errors.clubTiers',
        club                : 'apf.partners.errors.club',
    },
    saveWarning: 'apf.partners.saveWarning',
});
