import React from 'react';
import Grid from 'Components/Grid';
import Subsection from 'Components/Sections/Subsection';
import withRequest from 'Components/Sections/withRequest';
import Skeleton from 'Components/Skeletons';
// eslint-disable-next-line import/no-cycle
import { BookingList } from 'Components/Sections/Reservations';
import translated from 'Constants/labels/translated';

export function OwnersBookings(props) {
    return (
        <Subsection title={translated.owners.bookings}>
            <BookingList {...props} isUserSection />
        </Subsection>
    );
}

OwnersBookings.propTypes = {};

OwnersBookings.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }} className="margin-bottom-small">
                <Skeleton.Title isSubHeading width={110} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(OwnersBookings);
