module.exports = Object.freeze({
    title                      : 'apf.rules.title',
    new                        : 'apf.rules.new',
    edit                       : 'apf.rules.edit',
    conditions                 : 'apf.rules.conditions',
    information                : 'apf.rules.information',
    general                    : 'apf.rules.general',
    overrideSystemRules        : 'apf.rules.overrideSystemRules',
    overrideSystemRulesExplain : 'apf.rules.overrideSystemRulesExplain',
    name                       : 'apf.rules.name',
    type                       : 'apf.rules.type',
    typePoints                 : 'apf.rules.typePoints',
    typeFlatFee                : 'apf.rules.typeFlatFee',
    typeFiat                   : 'apf.rules.typeFiat',
    includes                   : 'apf.rules.includes',
    includesAvailable          : 'apf.rules.includesAvailable',
    includesAvailableSummary   : 'apf.rules.includesAvailableSummary',
    excludesHide               : 'apf.rules.excludesHide',
    excludesHideSummary        : 'apf.rules.excludesHideSummary',
    excludesShowUpgrade        : 'apf.rules.excludesShowUpgrade',
    excludesShowUpgradeSummary : 'apf.rules.excludesShowUpgradeSummary',
    conversion                 : 'apf.rules.conversion',
    conversionValue            : 'apf.rules.conversionValue',
    points                     : 'apf.rules.points',
    currency                   : 'apf.rules.currency',
    amount                     : 'apf.rules.amount',
    primaryCurrencyRate        : 'apf.rules.primaryCurrencyRate',
    secondaryCurrencyRate      : 'apf.rules.secondaryCurrencyRate',
    minAmount                  : 'apf.rules.minAmount',
    minAmountPerReservation    : 'apf.rules.minAmountPerReservation',
    minAmountPerNight          : 'apf.rules.minAmountPerNight',
    blocks                     : 'apf.rules.blocks',
    blocksSupportText          : 'apf.rules.blocksSupportText',
    block                      : { percentage: 'apf.rules.block.percentage', label: 'apf.rules.block.label' },
    feeCalculation             : 'apf.rules.feeCalculation',
    feeCalculationOptions      : { reservation: 'apf.rules.feeCalculationOptions.reservation', night: 'apf.rules.feeCalculationOptions.night' },
    applicationType            : 'apf.rules.applicationType',
    applicationTypeOptions     : { stay: 'apf.rules.applicationTypeOptions.stay', room: 'apf.rules.applicationTypeOptions.room' },
    code                       : 'apf.rules.code',
    propertiesMode             : 'apf.rules.propertiesMode',
    hotelTypeTitle             : 'apf.rules.hotelTypeTitle',
    hotelType                  : 'apf.rules.hotelType',
    hotel                      : 'apf.rules.hotel',
    occupancy                  : 'apf.rules.occupancy',
    occupancyOnlyApplies       : 'apf.rules.occupancyOnlyApplies',
    occupancyAlwaysApplies     : 'apf.rules.occupancyAlwaysApplies',
    stayDates                  : 'apf.rules.stayDates',
    occupancyValue             : 'apf.rules.occupancyValue',
    more                       : 'apf.rules.more',
    less                       : 'apf.rules.less',
    equals                     : 'apf.rules.equals',
    always                     : 'apf.rules.always',
    alwaysSummary              : 'apf.rules.alwaysSummary',
    only                       : 'apf.rules.only',
    except                     : 'apf.rules.except',
    cutoff                     : 'apf.rules.cutoff',
    maxLeadTime                : 'apf.rules.maxLeadTime',
    lengthOfStay               : 'apf.rules.lengthOfStay',
    roomAllotment              : 'apf.rules.roomAllotment',
    bookingCounter             : 'apf.rules.bookingCounter',
    countriesMode              : 'apf.rules.countriesMode',
    countries                  : 'apf.rules.countries',
    dates                      : 'apf.rules.dates',
    bookingWindow              : 'apf.rules.bookingWindow',
    enterAValue                : 'apf.rules.enterAValue',
    fixedPerNight              : 'apf.rules.fixedPerNight',
    fixedOnTotal               : 'apf.rules.fixedOnTotal',
    percentage                 : 'apf.rules.percentage',
    people                     : 'apf.rules.people',
    enabled                    : 'apf.rules.enabled',
    empty                      : 'apf.rules.empty',
    orderChange                : 'apf.rules.orderChange',
    extraAttributes            : {
        title    : 'apf.rules.extraAttributes.title',
        entities : {
            Client   : 'apf.rules.extraAttributes.entities.Client',
            Club     : 'apf.rules.extraAttributes.entities.Club',
            Contract : 'apf.rules.extraAttributes.entities.Contract',
            User     : 'apf.rules.extraAttributes.entities.User',
        },
        name        : 'apf.rules.extraAttributes.name',
        operator    : 'apf.rules.extraAttributes.operator',
        value       : 'apf.rules.extraAttributes.value',
        removeField : 'apf.rules.extraAttributes.removeField',
    },
    hotelAndHotelTypeRequired     : 'apf.rules.hotelAndHotelTypeRequired',
    bWFLessThanSDFMinusCutoff     : 'apf.rules.bWFLessThanSDFMinusCutoff',
    bWELessThanSDEMinusCutoff     : 'apf.rules.bWELessThanSDEMinusCutoff',
    flatFeeWithNoTranslation      : 'apf.rules.flatFeeWithNoTranslation',
    blocksEmpty                   : 'apf.rules.blocksEmpty',
    blocksWithRepeatedPercentage  : 'apf.rules.blocksWithRepeatedPercentage',
    modifyRule                    : 'apf.rules.modifyRule',
    areYouSure                    : 'apf.rules.areYouSure',
    incompleteExtraAttributes     : 'apf.rules.incompleteExtraAttributes',
    repeatedExtraAttributes       : 'apf.rules.repeatedExtraAttributes',
    fiatOptionsRulesNotConfigured : 'apf.rules.fiatOptionsRulesNotConfigured',
    wizard                        : { step1: 'apf.rules.wizard.step1', step2: 'apf.rules.wizard.step2', step3: 'apf.rules.wizard.step3', step4: 'apf.rules.wizard.step4' },
    all                           : 'apf.rules.all',
    filter                        : 'apf.rules.filter',
    excludes                      : 'apf.rules.excludes',
    applyTo                       : 'apf.rules.applyTo',
    applyToOptions                : { both: 'apf.rules.applyToOptions.both', owners: 'apf.rules.applyToOptions.owners', agents: 'apf.rules.applyToOptions.agents' },
    success                       : {
        ruleAdded     : 'apf.rules.success.ruleAdded',
        ruleSaved     : 'apf.rules.success.ruleSaved',
        rulesEnabled  : 'apf.rules.success.rulesEnabled',
        rulesDisabled : 'apf.rules.success.rulesDisabled',
        rulesRemoved  : 'apf.rules.success.rulesRemoved',
        ruleRemoved   : 'apf.rules.success.ruleRemoved',
        ruleDisabled  : 'apf.rules.success.ruleDisabled',
        ruleEnabled   : 'apf.rules.success.ruleEnabled',
    },
    errors: {
        ruleAdded              : 'apf.rules.errors.ruleAdded',
        ruleSaved              : 'apf.rules.errors.ruleSaved',
        rulesEnabled           : 'apf.rules.errors.rulesEnabled',
        rulesDisabled          : 'apf.rules.errors.rulesDisabled',
        rulesRemoved           : 'apf.rules.errors.rulesRemoved',
        ruleRemoved            : 'apf.rules.errors.ruleRemoved',
        ruleDisabled           : 'apf.rules.errors.ruleDisabled',
        ruleEnabled            : 'apf.rules.errors.ruleEnabled',
        reorder                : 'apf.rules.errors.reorder',
        entityCodeRepeated     : 'apf.rules.errors.entityCodeRepeated',
        hotelsFilterError      : 'apf.rules.errors.hotelsFilterError',
        hotelsTypesFilterError : 'apf.rules.errors.hotelsTypesFilterError',
    },
    systemRules  : { title: 'apf.rules.systemRules.title' },
    translations : {
        title       : 'apf.rules.translations.title',
        empty       : 'apf.rules.translations.empty',
        name        : 'apf.rules.translations.name',
        description : 'apf.rules.translations.description',
        language    : 'apf.rules.translations.language',
        modal       : { addTitle: 'apf.rules.translations.modal.addTitle', editTitle: 'apf.rules.translations.modal.editTitle' },
    },
    display     : 'apf.rules.display',
    views       : 'apf.rules.views',
    viewOptions : { search: 'apf.rules.viewOptions.search', offers: 'apf.rules.viewOptions.offers' },
    types       : {
        pricing     : 'apf.rules.types.pricing',
        exception   : 'apf.rules.types.exception',
        balanceType : 'apf.rules.types.balanceType',
        flatFee     : 'apf.rules.types.flatFee',
        fiatOption  : 'apf.rules.types.fiatOption',
        points      : 'apf.rules.types.balanceType',
    },
    summary: {
        roomAllotment  : 'apf.rules.summary.roomAllotment',
        lengthOfStay   : 'apf.rules.summary.lengthOfStay',
        numberOfDays   : 'apf.rules.summary.numberOfDays',
        amount         : 'apf.rules.summary.amount',
        bookingCounter : 'apf.rules.summary.bookingCounter',
        hotelsNeeded   : 'apf.rules.summary.hotelsNeeded',
        dates          : { neededSelection: 'apf.rules.summary.dates.neededSelection' },
        occupancy      : {
            less   : 'apf.rules.summary.occupancy.less',
            more   : 'apf.rules.summary.occupancy.more',
            equals : 'apf.rules.summary.occupancy.equals',
        },
    },
    examples: {
        fixedPerNight : 'apf.rules.examples.fixedPerNight',
        fixedOnTotal  : 'apf.rules.examples.fixedOnTotal',
        percentage    : 'apf.rules.examples.percentage',
    },
    level  : 'apf.rules.level',
    levels : {
        contracts     : 'apf.rules.levels.contracts',
        clubTiers     : 'apf.rules.levels.clubTiers',
        contractTypes : 'apf.rules.levels.contractTypes',
        system        : 'apf.rules.levels.system',
    },
    deleteConfirmation : 'apf.rules.deleteConfirmation',
    delete             : 'apf.rules.delete',
    dynamicRanges      : {
        title         : 'apf.rules.dynamicRanges.title',
        repeatedRange : 'apf.rules.dynamicRanges.repeatedRange',
        repeatedFrom  : 'apf.rules.dynamicRanges.repeatedFrom',
        noRanges      : 'apf.rules.dynamicRanges.noRanges',
        stepBuilder   : {
            title               : 'apf.rules.dynamicRanges.stepBuilder.title',
            interval            : 'apf.rules.dynamicRanges.stepBuilder.interval',
            step                : 'apf.rules.dynamicRanges.stepBuilder.step',
            steps               : 'apf.rules.dynamicRanges.stepBuilder.steps',
            generate            : 'apf.rules.dynamicRanges.stepBuilder.generate',
            sliderConfiguration : 'apf.rules.dynamicRanges.stepBuilder.sliderConfiguration',
            removeAll           : 'apf.rules.dynamicRanges.stepBuilder.removeAll',
        },
        rangeConfiguration: {
            title     : 'apf.rules.dynamicRanges.rangeConfiguration.title',
            warning   : 'apf.rules.dynamicRanges.rangeConfiguration.warning',
            ranges    : 'apf.rules.dynamicRanges.rangeConfiguration.ranges',
            range     : 'apf.rules.dynamicRanges.rangeConfiguration.range',
            label     : 'apf.rules.dynamicRanges.rangeConfiguration.label',
            variable  : 'apf.rules.dynamicRanges.rangeConfiguration.variable',
            subtitles : {
                currency       : 'apf.rules.dynamicRanges.rangeConfiguration.subtitles.currency',
                conversionRate : 'apf.rules.dynamicRanges.rangeConfiguration.subtitles.conversionRate',
                roomRate       : 'apf.rules.dynamicRanges.rangeConfiguration.subtitles.roomConversionRate',
                serviceRate    : 'apf.rules.dynamicRanges.rangeConfiguration.subtitles.serviceConversionRate',
            },
        },
        conversionRates: {
            title          : 'apf.rules.dynamicRanges.conversionRates.title',
            application    : 'apf.rules.dynamicRanges.conversionRates.application',
            sourceBalances : 'apf.rules.dynamicRanges.conversionRates.sourceBalances',
            modes          : {
                Simple      : 'apf.rules.dynamicRanges.conversionRates.modes.simple',
                Incremental : 'apf.rules.dynamicRanges.conversionRates.modes.incremental',
            },
        },
        summary: {
            configure : 'apf.rules.dynamicRanges.summary.configure',
            empty     : 'apf.rules.dynamicRanges.summary.empty',
        },
    },
});
