import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from 'Components/Grid';
import Wrapper from '../Wrapper';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Skeleton from 'Components/Skeletons';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Alert from 'Components/Alert';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import withRequest from 'Components/Sections/withRequest';

const getActions = (data, navigator, confirmation, snackbar, handleClickChangeStatus, resources, handleOnAddChild, handleOnEdit, intl) => [
    {
        content  : translated.categories.addChild,
        callback : handleOnAddChild,
        key      : 'addChild',
        when     : (category) => handleOnAddChild && !category.parent?.id && category.isActive,
    },
    {
        content  : translated.global.buttons.disable,
        callback : (category) => handleClickChangeStatus(category, false),
        key      : 'disable',
        when     : (category) => category?.links?.self?.patch && category.isActive,
    },
    {
        content  : translated.global.buttons.enable,
        callback : (category) => handleClickChangeStatus(category, true),
        key      : 'enable',
        when     : (category) => category?.links?.self?.patch && !category.isActive,
    },
    {
        content  : translated.global.buttons.delete,
        callback : (category) => {
            confirmation.show({
                message       : translated.categories.delete.confirmation,
                acceptMessage : translated.global.buttons.delete,
                onAccept      : async () => {
                    const { id } = category;
                    const reqConfig = {
                        ...category.links.self.delete,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [id],
                        shouldReloadData : true,
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CATEGORIES, resources });

                        snackbar.show({ content: translated.categories.delete.success, isSuccess: true });
                    } catch (error) {
                        snackbar.show({
                            content     : translated.categories.delete.defaultError,
                            errorLabels : translated.categories.delete,
                            isError     : true,
                            error,
                        });
                    }
                },
            });
        },
        key  : 'delete',
        when : (category) => category?.links?.self?.delete && !category.isParent,
    },
    {
        content           : translated.global.buttons.edit,
        callback          : handleOnEdit,
        key               : 'edit',
        isOutsideDropdown : true,
        icon              : 'Pencil',
        tooltip           : intl.formatMessage({
            id             : translated.global.buttons.edit,
            defaultMessage : translated.global.buttons.edit,
        }),
        when: (category) => category?.links?.self?.update,
    },
];

function CategoriesList({ data, sections, fetching, reloadData, resources }) {
    const intl = useIntl();
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const parentId = useRef(null);

    const handleOnAddClick = () => {
        parentId.current = null;
        navigator.goToCategoryEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
    };

    const handleClickChangeStatus = async (category, enable) => {
        const { id, links } = category;
        const reqConfig = {
            ...links.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.CATEGORIES, resources });

            snackbar.show({ content: enable ? translated.categories.enable.success : translated.categories.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.categories.enable.error : translated.categories.disable.error,
                isError : true,
                error,
            });
        }
    };

    const { links, data: list, order, pagination } = data || {};

    const editCategory = (category) => {
        parentId.current = null;
        navigator.goToCategoryEditor({ available: category && category.links ? category.links.self : {} });
    };

    const addChildCategory = links?.self?.create
        ? (category) => {
            parentId.current = category.id;
            navigator.goToCategoryEditor({ available: data && data.links ? data.links.self : {}, current: 'init' });
        }
        : null;

    const categories = list?.length
        ? list.map((category) => ({
            ...category,
            isParent   : list.find((e) => e.parent?.id === category.id),
            parentName :
                  category.parent?.name
                  || intl.formatMessage({
                      id             : translated.categories.noParent,
                      defaultMessage : translated.categories.noParent,
                  }),
        }))
        : [];

    // eslint-disable-next-line max-len
    const rows = { actions: getActions(categories, navigator, confirmation, snackbar, handleClickChangeStatus, resources, addChildCategory, editCategory, intl) };

    const { Section, props: subsecProps } = Sections.get(sections);

    return (
        <>
            {Section && <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} parentId={parentId.current} />}
            <Wrapper
                title={translated.categories.title}
                actionButtons={
                    links?.self?.create
                        ? [
                            <Button id="categories-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Button>,
                        ]
                        : []
                }
            >
                <Table
                    id="categories"
                    key="categories"
                    columns={[
                        {
                            title       : translated.categories.name,
                            key         : 'name',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title       : translated.categories.parentName,
                            key         : 'parentName',
                            isVisible   : true,
                            hasMaxWidth : true,
                        },
                        {
                            title     : translated.global.id,
                            key       : 'id',
                            isVisible : true,
                            isNumeric : true,
                        },
                        {
                            title    : translated.global.status,
                            key      : 'isActive',
                            whenTrue : {
                                text      : translated.global.state.enabled,
                                className : 'status status-enabled',
                            },
                            whenFalse: {
                                text      : translated.global.state.disabled,
                                className : 'status status-disabled',
                            },
                            isVisible: true,
                        },
                    ]}
                    rows={rows}
                    items={categories}
                    onSearch={() => {
                        // Default
                    }}
                    canViewColumns
                    canChangeSettings
                    loadingIds={fetching.ids}
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    whenEmpty={(
                        <Alert
                            id="categories-empty"
                            content={translated.categories.emptyList}
                            actionText={translated.global.buttons.add}
                            onClick={handleOnAddClick}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

CategoriesList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={225} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

CategoriesList.defaultProps = {
    data     : [],
    fetching : {},
};

CategoriesList.propTypes = {
    sections   : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({}),
    fetching   : PropTypes.shape({}),
    reloadData : PropTypes.func.isRequired,
    resources  : PropTypes.shape({}).isRequired,
};

export default withRequest(CategoriesList);
