import React from 'react';

export default React.createContext({
    // Flag used to check whether the context is loaded or not.
    isContextInitialized : false,
    url                  : '',
    QA                   : {},
    sections             : {},
    path                 : {},
    app                  : { shouldRefreshUser: false },
    navigator            : {},
    eventManager         : {},
    sharedConfigurations : {},
    snackbar             : {},
    confirmation         : {},
    toggle               : {},
    user                 : {},
    dateManager          : {},
});
