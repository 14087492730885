/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import validDomains from 'Constants/validDomains';
import translated from 'Constants/labels/translated';

// TODO: add i18n
const labels = {
    email                           : 'Invalid email',
    onlyLetters                     : 'The value must have letters',
    alphanumeric                    : 'The value must have letters and numbers',
    alphanumericUnderScore          : 'The value must have letters, numbers and "_"',
    alphanumericLowercaseUnderScore : 'The value must have lower case letters, numbers and "_"',
    invalidValues                   : 'Invalid values',
    invalidColor                    : translated.global.invalidColor,
    invalidHtmlContent              : translated.global.invalidHtmlContent,
};

// Invalid code for the 'code' type input.
const invalidCodeElements = [
    '<script',
    'onclick=',
    'ondblclick=',
    'onmousedown=',
    'onmouseup=',
    'onmouseover=',
    'onmousemove=',
    'onmouseout=',
    'ondragstart=',
    'ondrag=',
    'ondragenter=',
    'ondragleave=',
    'ondragover=',
    'ondrop=',
    'ondragend=',
    'onkeydown=',
    'onkeypress=',
    'onkeyup=',
    'onload=',
    'onabort=',
    'onerror=',
    'onresize=',
    'onscroll=',
    'onselect=',
    'onchange=',
    'onsubmit=',
    'onreset=',
    'onfocus=',
    'onblur=',
];

// We override the custom error messages. TODO: add i18n.
yup.setLocale({
    mixed: {
        notType  : 'Invalid value',
        required : 'Field required',
    },
    string: {
        min : 'The input should have at least ${min} characters',
        max : 'The input should have at most ${max} characters',
    },
    number: {
        min     : 'The minimum value is ${min}',
        max     : 'The maximum value is ${max}',
        integer : 'The value must be an integer',
    },
    array: {
        min : 'The input needs at least ${min} elements',
        max : 'The input needs at most ${max} elements',
    },
});

// It validates duplicated elements of a list. It's used on array element types. The function received converts the elements so they can be added to a set.
yup.addMethod(yup.array, 'unique', function checkUnique(message, mapper = (a) => a) {
    return this.test(
        'unique',
        message,
        function test() {
            const { path, createError, originalValue: list } = this;
            const isDuplicated = list?.length && list.length !== new Set(list.map(mapper)).size;

            return !isDuplicated || createError({ path, message });
        },
    );
});

// Method used to verify duplicated elements of a list. It is used in a component of the list, so it validates each element.
// The function received CHECKS for the duplicated elements. It must return true when the value is valid
function checkUniqueElement(message, validator) {
    return this.test(
        'unique',
        message,
        function test() {
            const { path, createError, originalValue, parent, from } = this;

            return !validator || validator({ originalValue, parent, from }) || createError({ path, message });
        },
    );
}

// We add custom validations to value types. To use them: 'yup.number().unique(MESSAGE, (val) => VALIDATION)'
yup.addMethod(yup.number, 'unique', checkUniqueElement);
yup.addMethod(yup.string, 'unique', checkUniqueElement);
yup.addMethod(yup.object, 'unique', checkUniqueElement);

// We validate that the sting does NOT have any of the char received in the parameter
yup.addMethod(yup.string, 'ignoreChar', function checkChar(message, invalidCharList) {
    return this.test(
        'ignoreChar',
        message,
        function test() {
            const { path, createError, originalValue } = this;

            const isValid = invalidCharList.every((e) => String(originalValue).indexOf(e) === -1);

            return isValid || createError({ path, message });
        },
    );
});

// Custom validation "types", they just wrap an existing type and add some common validations.
export const onlyLetters = yup.string().trim().matches(/^[a-zA-Z]+$/, labels.onlyLetters);
export const email = yup.string().trim().matches(
    new RegExp(
        `^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+(${validDomains}))$`,
        'i',
    ),
    labels.email,
);
export const alphanumeric = yup.string().trim().matches(/^[a-zA-Z0-9]+$/, labels.alphanumeric);
export const alphanumericUnderScore = yup.string().trim().matches(/^[a-zA-Z0-9_]+$/, labels.alphanumericUnderScore);
export const alphanumericLowercaseUnderScore = yup.string().trim().matches(/^[a-z0-9][a-zA-Z0-9_]+$/, labels.alphanumericUnderScore);

export default {
    ...yup,

    // We wrap the 'number' to add some common props/validations
    number: (props) => yup.number(props)
        // Transform so the inputs with no value dont show a 'Invalid value' because '' is not a number.
        .transform((newValue, originalValue) => (originalValue === '' ? null : newValue))
        // Nullable so yup handles the '' value correctly, and shows 'Field required' instead of 'Invalid value'
        .nullable(true),

    // We add a validation for the 'color' values (ie: #137a7f)
    color: () => yup.string().matches(/^#[0-9a-fA-F]{6}$/, labels.invalidColor),

    // We add a validation for the code editor values
    code: () => yup.string().test('invalid-html', labels.invalidHtmlContent, (value) => {
        const string = String(value)?.toLowerCase();

        return invalidCodeElements.every((e) => string.indexOf(e) === -1);
    }),

    dateRange: () => yup.object().shape({
        begin : yup.string().matches(/^[\d]{4}-[\d]{2}-[\d]{2}$/, labels.invalidValues).required(),
        end   : yup.string().matches(/^[\d]{4}-[\d]{2}-[\d]{2}$/, labels.invalidValues).required(),
    }),
};
