module.exports = Object.freeze({
    sectionTitle      : 'apf.conversionRules.sectionTitle',
    noWalletsConfig   : 'apf.conversionRules.noWalletsConfig',
    conversionsName   : 'apf.conversionRules.conversionsName',
    exchangeRulesName : 'apf.conversionRules.exchangeRulesName',
    conversionRate    : 'apf.conversionRules.conversionRate',
    conversionFee     : 'apf.conversionRules.conversionFee',
    club              : 'apf.conversionRules.club',
    minValue          : 'apf.conversionRules.minValue',
    allowedPoints     : 'apf.conversionRules.allowedPoints',
    enable            : { success: 'apf.conversionRules.enable.success', error: 'apf.conversionRules.enable.error' },
    disable           : { success: 'apf.conversionRules.disable.success', error: 'apf.conversionRules.disable.error' },
    delete            : { title: 'apf.conversionRules.delete.title', success: 'apf.conversionRules.delete.success', error: 'apf.conversionRules.delete.error' },
    editor            : {
        title                    : 'apf.conversionRules.editor.title',
        conversionHelperText     : 'apf.conversionRules.editor.conversionHelperText',
        conversionRate           : 'apf.conversionRules.editor.conversionRate',
        conversionFee            : 'apf.conversionRules.editor.conversionFee',
        conversionFeeSupportText : 'apf.conversionRules.editor.conversionFeeSupportText',
        club                     : 'apf.conversionRules.editor.club',
        minValue                 : 'apf.conversionRules.editor.minValue',
        allowedPoints            : 'apf.conversionRules.editor.allowedPoints',
        errors                   : {
            default                         : 'apf.conversionRules.editor.errors.default',
            allowedPointRequired            : 'apf.conversionRules.editor.errors.allowedPointRequired',
            conversionRuleExternalClubInUse : 'apf.conversionRules.editor.errors.conversionRuleExternalClubInUse',
        },
    },
    listAlt: {
        sectionTitle    : 'apf.conversionRules.listAlt.sectionTitle',
        noWalletsConfig : 'apf.conversionRules.listAlt.noWalletsConfig',

        conversionRate : 'apf.conversionRules.listAlt.conversionRate',
        conversionFee  : 'apf.conversionRules.listAlt.conversionFee',
        club           : 'apf.conversionRules.listAlt.club',
        minValue       : 'apf.conversionRules.listAlt.minValue',
        allowedPoints  : 'apf.conversionRules.listAlt.allowedPoints',

        enable: {
            success : 'apf.conversionRules.listAlt.enable.success',
            error   : 'apf.conversionRules.listAlt.error.success',
        },

        disable: {
            success : 'apf.conversionRules.listAlt.disable.success',
            error   : 'apf.conversionRules.listAlt.disable.error',
        },

        delete: {
            title   : 'apf.conversionRules.listAlt.delete.title',
            success : 'apf.conversionRules.listAlt.delete.success',
            error   : 'apf.conversionRules.listAlt.delete.error',
        },
    },

    editorAlt: {
        title                    : 'apf.conversionRules.editorAlt.title',
        titleEdition             : 'apf.conversionRules.editorAlt.titleEdition',
        conversionHelperText1    : 'apf.conversionRules.editorAlt.conversionHelperText1',
        conversionHelperText2    : 'apf.conversionRules.editorAlt.conversionHelperText2',
        conversionRate           : 'apf.conversionRules.editorAlt.conversionRate',
        conversionFee            : 'apf.conversionRules.editorAlt.conversionFee',
        conversionFeeSupportText : 'apf.conversionRules.editorAlt.conversionFeeSupportText',
        club                     : 'apf.conversionRules.editorAlt.club',
        internalClubs            : 'apf.conversionRules.editorAlt.internalClubs',
        externalClubs            : 'apf.conversionRules.editorAlt.externalClubs',
        source                   : 'apf.conversionRules.editorAlt.source',
        destination              : 'apf.conversionRules.editorAlt.destination',
        balances                 : 'apf.conversionRules.editorAlt.balances',
        balancesSupport          : 'apf.conversionRules.editorAlt.balancesSupport',
        rate                     : 'apf.conversionRules.editorAlt.rate',
        fee                      : 'apf.conversionRules.editorAlt.fee',
        minValue                 : 'apf.conversionRules.editorAlt.minValue',
        noPayment                : 'apf.conversionRules.editorAlt.noPayment',
        noPaymentMessage         : 'apf.conversionRules.editorAlt.noPaymentMessage',
        balanceLabel             : 'apf.conversionRules.editorAlt.balanceLabel',
        sourceLabel              : 'apf.conversionRules.editorAlt.sourceLabel',
        destinationLabel         : 'apf.conversionRules.editorAlt.destinationLabel',

        createSuccess : 'apf.conversionRules.editorAlt.createSuccess',
        saveSuccess   : 'apf.conversionRules.editorAlt.saveSuccess',
        errors        : {
            create                             : 'apf.conversionRules.editorAlt.errors.create',
            save                               : 'apf.conversionRules.editorAlt.errors.save',
            init                               : 'apf.conversionRules.editorAlt.errors.init',
            conversionRuleExternalClubInUse    : 'apf.conversionRules.editorAlt.errors.conversionRuleExternalClubInUse',
            invalidPaymentBalance              : 'apf.conversionRules.editorAlt.errors.invalidPaymentBalance',
            exchangeOverrideRuleOneRulePerClub : 'apf.conversionRules.editorAlt.errors.exchangeOverrideRuleOneRulePerClub',
            noExchangeOverrideRules            : 'There must be at least one exchange rule',
        },
    },
});
