import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import Chips from 'Components/Chips';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage, { getMessage } from 'Components/WrappedFormattedMessage';

function findOption(options, val) {
    return options.find((opt) => `${opt.value}` === `${val}`);
}

const convertValue = (value) => `${value}`;

function Select({
    label,
    name,
    options,
    onChange,
    onFocus,
    onBlur,
    isReadOnlyWithoutInputs,
    isMultiSelect,
    isDisabled,
    id,
    displayOptionsAmount,
    sortBy,
    withoutDefaultOption,
    defaultOptionText,
    optionParentLabel,
}) {
    const intl = useIntl();
    const context = useFormContext();
    const { setValue, watch, control } = context || {};

    const contextValue = watch(name);

    const isValueAnArray = Array.isArray(contextValue);

    const selectOptionText = defaultOptionText
        || intl.formatMessage({
            id             : translated.global.selectOption,
            defaultMessage : translated.global.selectOption,
        });

    const getOption = useCallback(
        ({ value, disabled, key, content, customStyles }) => (
            <option value={value} disabled={disabled} key={key} style={customStyles}>
                {getMessage(intl, content)}
            </option>
        ),
        [intl],
    );

    // The flag 'isRemove' only is used in the multiselect input, for the chips 'remove'
    const setSelectedOption = (option, isRemove = false) => {
        const newValue = option.value;

        let updatedValues = newValue;

        if (isMultiSelect) {
            if (isRemove) {
                updatedValues = contextValue?.filter((e) => convertValue(e) !== convertValue(newValue));
            } else {
                updatedValues = contextValue?.length ? [...contextValue, newValue] : [newValue];
            }
        }

        if (option.callback) {
            option.callback({
                ...option,
                isSelected: isRemove,
            });
        }

        // When shouldValidate is false (default value) it doesn't trigger correctly the schema validations
        setValue(name, updatedValues, { shouldDirty: true, shouldValidate: true });

        if (onChange) {
            onChange({ target: { value: updatedValues } });
        }
    };

    const handleOnChange = (e) => {
        const option = e.target.value === '__default' ? undefined : findOption(options, e.target.value);
        setSelectedOption(option);
    };

    const handleOnBlur = (props) => {
        // We dont use the hook 'onBlur' because it messes with the select value.

        if (onBlur) {
            onBlur(props);
        }
    };

    // Search selected option to show when 'isReadOnlyWithoutInputs'
    let contentOfSelectedOptions;
    if (isReadOnlyWithoutInputs && options && options.length && contextValue) {
        if (isValueAnArray) {
            contentOfSelectedOptions = contextValue.map((selectedOption) => (!selectedOption ? selectOptionText : findOption(options, contextValue)));
        } else {
            contentOfSelectedOptions = !contextValue ? [selectOptionText] : [findOption(options, contextValue)];
        }
    }

    let optionList = options ? [...options] : [];
    // Check if the list must be sorted and the element have the field for which it will be sorted.
    if (sortBy && optionList.length && optionList[0][sortBy]) {
        optionList.sort((a, b) => `${a[sortBy]}`.localeCompare(b[sortBy]));
    }

    optionList = optionList.map((option, index) => {
        const { key = `divider_${index}`, value, content, isDisabled: isOptionDisabled, parentId, customStyles } = option;

        return {
            disabled: (isMultiSelect ? contextValue?.find((e) => `${e}` === `${value}`) : `${contextValue}` === `${value}`) || isOptionDisabled,
            value,
            parentId,
            key,
            content,
            customStyles,
        };
    });

    // eslint-disable-next-line max-len
    const chipsList = isMultiSelect ? options.filter((opt) => contextValue?.find((eachContextValue) => String(eachContextValue) === String(opt.value))) : [];

    return (
        <>
            {label && (
                <label id={`${id}-label`} className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}

            {!isReadOnlyWithoutInputs && (
                <Controller
                    render={
                        ({ field }) => (
                            <select
                                id={id}
                                name={name}
                                onFocus={onFocus}
                                tabIndex={isDisabled ? '-1' : '0'}
                                size={displayOptionsAmount}
                                className={displayOptionsAmount ? 'is-multi-line' : null}
                                ref={isMultiSelect ? null : field?.ref} // To avoid problems when selecting an option with multi-selects
                                value={isMultiSelect ? '' : field?.value} // To make the multi-select show always the 'Select an option' text as selected
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                disabled={isDisabled}
                            >
                                {!withoutDefaultOption && <option selected disabled value="" key="select_option" className="select-default-option">{selectOptionText}</option>}
                                {optionList.filter((e) => !e.parentId).map((option) => {
                                    const children = optionList.filter((e) => e.parentId && option.value === e.parentId);

                                    if (!children.length) {
                                        return getOption(option);
                                    }

                                    return (
                                        <>
                                            {getOption(option)}
                                            <optgroup label={optionParentLabel || option.content}>
                                                {children.map((child) => getOption(child))}
                                            </optgroup>
                                        </>
                                    );
                                })}
                            </select>
                        )
                    }
                    control={control}
                    name={name}
                />
            )}

            {isReadOnlyWithoutInputs
                && contentOfSelectedOptions
                && contentOfSelectedOptions.map((selectedOption) => <span>{(selectedOption && selectedOption.content) || selectOptionText}</span>)}

            {isMultiSelect && !!chipsList.length && (
                <Chips
                    outlined
                    list={chipsList.map((opt) => ({
                        onClose : opt.isDisabled ? null : () => setSelectedOption(opt, true),
                        text    : opt.content,
                        key     : opt.key,
                    }))}
                />
            )}
        </>
    );
}

Select.defaultProps = {
    id                      : '',
    label                   : '',
    onChange                : null,
    options                 : [],
    isReadOnlyWithoutInputs : false,
    isMultiSelect           : false,
    onFocus                 : () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
    isDisabled           : false,
    displayOptionsAmount : null,
    sortBy               : null,
    withoutDefaultOption : false,
    defaultOptionText    : '',
    optionParentLabel    : '',
};

Select.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name                    : PropTypes.string.isRequired,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
    isReadOnlyWithoutInputs : PropTypes.bool,
    isMultiSelect           : PropTypes.bool,
    options                 : PropTypes.arrayOf(
        PropTypes.shape({
            content   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            isEnabled : PropTypes.bool,
            callback  : PropTypes.func,
        }),
    ),
    isDisabled           : PropTypes.bool,
    displayOptionsAmount : PropTypes.number,
    sortBy               : PropTypes.string,
    withoutDefaultOption : PropTypes.bool,
    defaultOptionText    : PropTypes.string,
    optionParentLabel    : PropTypes.string,
};

export default React.memo(Select);
