module.exports = Object.freeze({
    home   : { title: 'apf.login.home.title', imageAlt: 'apf.login.home.imageAlt' },
    errors : {
        default            : 'apf.login.errors.default',
        agentDisabled      : 'apf.login.errors.agentDisabled',
        agentNotFound      : 'apf.login.errors.agentNotFound',
        unexpected         : 'apf.login.errors.unexpected',
        loggedUser         : 'apf.login.errors.loggedUser',
        noLongerValidToken : 'apf.login.errors.noLongerValidToken',
    },
});
