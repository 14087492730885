import React from 'react';
import withRequest from 'Components/Sections/withRequest';
// eslint-disable-next-line import/no-cycle
import EditorWithoutRequest from 'Components/Sections/Contracts/EditorWithoutRequest';

export function Editor({ ...props }) {
    return (
        <EditorWithoutRequest {...props} />
    );
}

Editor.defaultProps = {};

Editor.propTypes = {};

export default withRequest(Editor);
