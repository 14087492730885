import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';

const getRolesActions = (data, navigator, confirmation, snackbar, handleClickChangeStatus, resources) => [
    {
        content  : translated.global.buttons.disable,
        callback : (role) => handleClickChangeStatus(role, false),
        key      : 'disable',
        when     : (role) => role.isActive && role?.links?.self?.patch,
    },
    {
        content  : translated.global.buttons.enable,
        callback : (role) => handleClickChangeStatus(role, true),
        key      : 'enable',
        when     : (role) => !role.isActive && role?.links?.self?.patch,
    },
    {
        callback          : (role) => navigator.goToRoleEditor(role),
        key               : 'view',
        isOutsideDropdown : true,
        getIcon           : (role) => (role.links.self.update ? 'Pencil' : 'Eye'),
        getTooltip        : (role) => (role.links.self.update
            ? translated.global.buttons.edit
            : translated.global.buttons.view
        ),
        when: (role) => role?.links?.self?.read,
    },
    {
        content  : translated.global.buttons.delete,
        key      : 'delete',
        when     : (role) => role.links?.self?.delete,
        callback : (role) => {
            const message = translated.roles.delete.confirmation;
            const acceptMessage = translated.global.buttons.delete;
            confirmation.show({
                message,
                acceptMessage,
                onAccept: async () => {
                    const { id } = role;
                    const reqConfig = {
                        ...role.links.self.delete,
                        isCritical       : false,
                        isGlobal         : false,
                        ids              : [id],
                        shouldReloadData : true,
                    };
                    try {
                        await navigator.requestForCurrentPath({ reqConfig, section: sectionType.ROLES, resources });

                        snackbar.show({
                            content   : translated.roles.delete.success,
                            isSuccess : true,
                        });
                    } catch (error) {
                        snackbar.show({
                            errorLabels : translated.roles.delete,
                            isError     : true,
                            error,
                        });
                    }
                },
            });
        },
    },
];

export function RolesList({ data, fetching, reloadData, resources, setSectionState, state: { appliedFilters } }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const { links, data: roles, order, pagination, filter: filterLinks } = data;

    const handleOnAddClick = () => navigator.goToRoleEditor(data, 'init');

    const handleClickChangeStatus = async (role, enable) => {
        const { id, links: roleLinks } = role;
        const reqConfig = {
            ...roleLinks.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.ROLES, resources });

            snackbar.show({
                content   : enable ? translated.roles.enable.success : translated.roles.disable.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.roles.enable.error : translated.roles.disable.error,
                isError : true,
                error,
            });
        }
    };

    // The order of the array determines the order of the columns
    const columns = useMemo(
        () => [
            {
                title           : translated.global.name,
                key             : 'name',
                isVisible       : true,
                customClassName : 'text-overflow role-name',
            },
            {
                title    : translated.global.status,
                key      : 'isActive',
                whenTrue : {
                    text      : translated.global.state.enabled,
                    className : 'status status-enabled',
                },
                whenFalse: {
                    text      : translated.global.state.disabled,
                    className : 'status status-disabled',
                },
                isVisible: true,
            },
            {
                title     : translated.global.id,
                key       : 'id',
                isVisible : true,
                isNumeric : true,
            },
        ],
        [],
    );

    const rowsActions = getRolesActions(roles, navigator, confirmation, snackbar, handleClickChangeStatus, resources);

    const rows = { actions: rowsActions };

    const filterResources = {
        filters: [
            {
                type        : 'text',
                submitKey   : 'name',
                resourceKey : 'name',
                label       : translated.roles.name,
            },
            {
                type        : 'select',
                submitKey   : 'isActive',
                resourceKey : 'isActive',
                label       : translated.global.status,
                options     : [
                    {
                        id   : 'true',
                        name : translated.global.state.enabled,
                    },
                    {
                        id   : 'false',
                        name : translated.global.state.disabled,
                    },
                ],
            },
        ],
        filterLinks,
        resources,
    };

    const actionButtons = links?.self?.create
        ? [
            <Button id="roles-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <Wrapper title={translated.roles.title} actionButtons={actionButtons}>
            <Table
                id="roles"
                key="roles"
                columns={columns}
                rows={rows}
                items={roles}
                onSearch={() => {
                    // Default
                }}
                canViewColumns
                canChangeSettings
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                filterResources={filterResources}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
            />
        </Wrapper>
    );
}

RolesList.defaultProps = {
    fetching        : {},
    state           : null,
    setSectionState : null,
};

RolesList.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    fetching        : PropTypes.shape({}),
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

RolesList.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={115} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(RolesList);
