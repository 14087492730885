import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import translated from 'Constants/labels/translated';
import withRequest from 'Components/Sections/withRequest';
import Form from 'Components/Form';
import Chips from 'Components/Chips/Chips';
import Alert from 'Components/Alert';

const listHasElement = (list, id) => !!list.find((e) => e === id);

const hasListChanged = (list, ids) => !!(list.length !== ids.length || ids.find((e) => listHasElement(list, e)));

// eslint-disable-next-line max-len
const convertAvailableTags = (list, usedTags) => (Array.isArray(list) && list.filter((e) => !usedTags.find((usedTag) => usedTag.id === e.id)).map((e) => ({ ...e, content: e.label, value: e.id }))) || [];

function EntityTags({ data, fetching, isEmbedded, pathForResource, customClass }) {
    const { navigator, snackbar } = useContext(PanelContext);

    const { data: list, additionalResources } = data || {};

    const [availableTags, setAvailableTags] = useState({
        isDisabled : false,
        list       : convertAvailableTags(additionalResources?.tags, list),
    });
    const loadedTags = useRef(list?.map((e) => e?.id));

    const updateList = async () => {
        loadedTags.current = list?.map((e) => e?.tier?.id);
        setAvailableTags({ isDisabled: false, list: convertAvailableTags(additionalResources?.tags, list) });
    };

    useEffect(() => {
        if (
            loadedTags.current
            && hasListChanged(
                list?.map((e) => e.id),
                loadedTags.current,
            )
        ) {
            updateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        // Needed to avoid bugs when selecting a import file, and mid-load change to other file (the available tag would not reload)
        updateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalResources]);

    const handleOnSubmit = async ({ values }) => {
        const { tags } = values;

        const selectedTags = availableTags?.list?.filter((eachAvailable) => tags.find((eachId) => eachId === eachAvailable.id));
        let promises = [];

        if (selectedTags?.length) {
            promises = selectedTags.map(
                async (each) => new Promise(async (resolve, reject) => {
                    try {
                        const reqConfig = {
                            ...each.links.self.associate,
                            data: {},
                        };

                        await navigator.directRequest(reqConfig);

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }),
            );

            return Promise.all(promises);
        }

        return null;
    };

    const reloadList = async () => {
        // This section is used in the import files, to fix a bug with the reload, we must do it this way.
        if (isEmbedded) {
            await navigator.setSectionData(pathForResource, { shouldReloadData: true });
        } else {
            await navigator.setSectionData([sectionType.ENTITY_TAGS], { shouldReloadData: true });
        }
    };

    const handleOnFinish = async () => {
        snackbar.show({ content: translated.tags.entityTags.associate.success, isSuccess: true });

        setAvailableTags((prev) => ({ ...prev, selected: [] }));

        reloadList();
    };

    const handleOnError = () => {
        snackbar.show({
            content : translated.tags.entityTags.associate.error,
            isError : true,
        });
    };

    const handleOnChange = (newValue) => {
        setAvailableTags((prev) => ({ ...prev, selected: newValue || [] }));
    };

    const removeTag = async (tag) => {
        try {
            await navigator.directRequest({ ...tag.links.self.disassociate });

            await reloadList();

            snackbar.show({ content: translated.tags.entityTags.disassociate.success, isSuccess: true });
        } catch (error) {
            snackbar.show({
                content : translated.tags.entityTags.disassociate.error,
                isError : true,
                error,
            });
        }
    };

    const isAddEnabled = !!availableTags?.list?.find((e) => !!e.links.self.associate);

    return (
        <div className={customClass}>
            <Form.Title className="margin-bottom-xsmall">
                <FormattedMessage id={translated.tags.entityTags.title} defaultMessage={translated.tags.entityTags.title} />
            </Form.Title>
            {availableTags?.list && isAddEnabled && (
                <Form.Wrapper>
                    <Form
                        id="add-entity-tag-form"
                        onSubmit={handleOnSubmit}
                        onFinish={handleOnFinish}
                        onError={handleOnError}
                        isDisabled={availableTags.isDisabled}
                        avoidSetComponentWithChanges
                    >
                        <Form.Column width={{ base: 9, small: 5 }}>
                            <Form.Input
                                submitKey="tags"
                                type="selectWithFilter"
                                label={translated.tags.entityTags.associate.message}
                                options={availableTags?.list || []}
                                sortBy="content"
                                onChange={handleOnChange}
                                value={availableTags?.selected}
                                isDense
                                isMultiSelect
                                isBasedOnState
                                avoidMarkAsChangedOnValidationsChange
                            />
                        </Form.Column>
                        <Form.Column width={{ base: 3, small: 7 }}>
                            <Form.Primary
                                variant="outlined"
                                color="primary"
                                key="fp"
                                isDisabled={!availableTags?.list?.length || !availableTags?.selected?.length}
                            >
                                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                            </Form.Primary>
                        </Form.Column>
                    </Form>
                </Form.Wrapper>
            )}

            {!list?.length && (
                <Alert
                    id="tags-empty"
                    content={translated.tags.entityTags.empty}
                    className="margin-top-xsmall"
                />
            )}

            {!!list?.length && (
                <Chips
                    id="tags"
                    className="margin-top-xsmall"
                    outlined
                    list={list
                        .sort((f, s) => (String(f.label).toLowerCase() < String(s.label).toLowerCase() ? -1 : 1))
                        .map((each) => ({
                            onClose: each?.links?.self?.disassociate
                                ? () => {
                                    removeTag(each);
                                }
                                : null,
                            text       : each.label,
                            isDisabled : fetching.ids?.find((id) => id === each.id),
                        }))}
                />
            )}
        </div>
    );
}

EntityTags.defaultProps = {
    data            : [],
    fetching        : {},
    pathForResource : null,
    customClass     : '',
};

EntityTags.propTypes = {
    data            : PropTypes.shape({}),
    fetching        : PropTypes.shape({}),
    isEmbedded      : PropTypes.bool.isRequired,
    pathForResource : PropTypes.arrayOf(PropTypes.string),
    customClass     : PropTypes.string,
};

EntityTags.Loading = function Loading() {
    return (
        <Grid className="margin-top-xxlarge margin-bottom-xxlarge">
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Title isSubHeading className="margin-bottom-small" width={65} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 4 }}>
                        <Skeleton.Form type="input" />
                    </Grid.Column>
                    <Grid.Column width={{ base: 8 }}>
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(EntityTags);
