import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import Alert from 'Components/Alert';
import { login, logout } from '../src/utils/authMethods';

function Home({ error, shouldForceLogin }) {
    if (shouldForceLogin) {
        login();
    }

    const backImg = `/static/clients/${window.env.CLIENT_FILENAMES}/img/back.jpg`;

    return (
        <div className="login-wrapper login-error">
            <div className="login-cover" style={{ backgroundImage: `url(${backImg})` }}>
                <div className="login-content animated fade-in slowest">
                    <div id="logo-wrapper" className="logo-wrapper text-align-center">
                        <img src={`/static/clients/${window.env.CLIENT_FILENAMES}/img/logo.png`} className="logo" alt="Logo" />
                    </div>
                    {shouldForceLogin && (
                        <h5 className="login-loading text-align-center">
                            <Icon className="mdi-icon margin-right" spin path={IconPath.mdiLoading} />
                        </h5>
                    )}
                    {error && (
                        <Alert
                            id="home-error"
                            className="margin-top-large"
                            content={error}
                            actionText={!shouldForceLogin ? 'Logout' : null}
                            onClick={!shouldForceLogin ? () => { logout(); } : null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

Home.defaultProps = {
    error            : null,
    shouldForceLogin : false,
};
Home.propTypes = {
    error            : PropTypes.string,
    shouldForceLogin : PropTypes.bool,
};

export default Home;
