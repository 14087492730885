/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import translated from 'Constants/labels/translated';
import { useDeepEffect } from 'Hooks';
import PropTypes from 'prop-types';
import Subsection from 'Components/Sections/Subsection';
import Grid from 'Components/Grid';
import Table from 'Components/Table';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import Skeleton from 'Components/Skeletons';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';
import PanelContext from 'State/panelContext';
import { rulesUtils } from './utils';

export function Rules({
    data, resources, fetching, isEmbedded, selectRuleEditorMethod, arePriceRulesEnabled, isAddEnabled, hideTitle, isEditEnabled,
}) {
    const intl = useIntl();
    const { navigator, snackbar, confirmation } = useContext(PanelContext);
    const { links, exception = [], points = [], pricing = [], flatFee = [], fiatOption = [] } = data || {};

    const ruleLinks = links?.self;

    const commonProps = {
        links: ruleLinks,
        fetching,
        isEmbedded,
        navigator,
        snackbar,
        confirmation,
        selectRuleEditorMethod,
        resources,
        intl,
        isEditEnabled,
    };

    const getExceptionRulesTableConfig = () => rulesUtils.getRulesTableConfig({ ...commonProps, rulesData: exception, collectionName: 'Exception' });
    const getPointsRulesTableConfig = () => rulesUtils.getRulesTableConfig({ ...commonProps, rulesData: points, collectionName: 'Points' });
    const getPricingRulesTableConfig = () => rulesUtils.getRulesTableConfig({ ...commonProps, rulesData: pricing, collectionName: 'Pricing' });
    const getFlatFeeRulesTableConfig = () => rulesUtils.getRulesTableConfig({ ...commonProps, rulesData: flatFee, collectionName: 'FlatFee' });
    const getFiatRulesTableConfig = () => rulesUtils.getRulesTableConfig({ ...commonProps, rulesData: fiatOption, collectionName: 'FiatOption' });

    const [isSorting, setIsSorting] = useState(false);
    const [exceptionTableConfig, setExceptionTableConfig] = useState(getExceptionRulesTableConfig());
    const [pointsTableConfig, setPointsTableConfig] = useState(getPointsRulesTableConfig());
    const pricingTableConfig = getPricingRulesTableConfig();
    const flatFeeTableConfig = getFlatFeeRulesTableConfig();
    const fiatTableConfig = getFiatRulesTableConfig();

    useDeepEffect(() => {
        setPointsTableConfig(getPointsRulesTableConfig());
    }, [points, navigator]);

    useDeepEffect(() => {
        setExceptionTableConfig(getExceptionRulesTableConfig());
    }, [exception, navigator]);

    const sectionAssociateButtons = [];

    const canAddRule = isEditEnabled && !!ruleLinks?.create;

    const thereAreRules = (arePriceRulesEnabled && pricing.length) || exception.length || points.length || flatFee.length || fiatOption.length;

    const addRule = async () => {
        navigator[selectRuleEditorMethod]({ available: ruleLinks, current: 'init', isEmbedded });
    };

    const alertProperties = {
        content    : translated.rules.empty,
        actionText : canAddRule ? translated.global.buttons.add : null,
        onClick    : canAddRule ? addRule : null,
    };

    if (canAddRule && thereAreRules) {
        // When the user has the permission to add rules AND there is at least a rule loaded
        sectionAssociateButtons.push(
            <Button id="rules-add-button" variant="outlined" color="primary" onClick={addRule} key="add_associated">
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        );
    }

    const sortList = async (list, sourcePosition, destinyPosition, collectionName, collectionSetter) => {
        if (!isSorting && sourcePosition !== destinyPosition) {
            const clonedList = [...list];
            const [elementToMove] = clonedList.splice(sourcePosition, 1);
            clonedList.splice(destinyPosition, 0, elementToMove);

            const oldTableConfig = rulesUtils.getRulesTableConfig({
                list,
                collectionName,
                ruleLinks,
                fetching,
                isEmbedded,
                navigator,
                snackbar,
                confirmation,
                selectRuleEditorMethod,
                resources,
                intl,
            });
            const newTableConfig = rulesUtils.getRulesTableConfig({
                clonedList,
                collectionName,
                ruleLinks,
                fetching,
                isEmbedded,
                navigator,
                snackbar,
                confirmation,
                selectRuleEditorMethod,
                resources,
                intl,
            });
            collectionSetter({ ...newTableConfig, isDisabled: true });
            setIsSorting(true);

            const reqConfig = {
                ...ruleLinks.bulks.reorder,
                data: {
                    type       : collectionName,
                    priorities : clonedList.map((eachRule) => ({ id: eachRule.id })),
                },
                shouldReloadData : true,
                isCritical       : false,
                isGlobal         : false,
                ids              : [elementToMove.visualId],
            };

            try {
                await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.RULES, resources });

                snackbar.show({
                    content   : translated.rules.orderChange,
                    isSuccess : true,
                });
                collectionSetter({ ...newTableConfig, isDisabled: false });
            } catch (error) {
                snackbar.show({
                    content : translated.rules.errors.reorder,
                    isError : true,
                    error,
                });
                collectionSetter({ ...oldTableConfig, isDisabled: false });
            } finally {
                setIsSorting(false);
            }
        }
    };

    const onExceptionSort = async (list, sourcePosition, destinyPosition) => {
        sortList(list, sourcePosition, destinyPosition, 'Exception', setExceptionTableConfig);
    };

    const onPointsSort = (list, sourcePosition, destinyPosition) => {
        sortList(list, sourcePosition, destinyPosition, 'Points', setPointsTableConfig);
    };

    return (
        <Subsection
            className="owners-rules"
            title={!hideTitle && translated.rules.title}
            actionButtons={sectionAssociateButtons}
        >
            {!thereAreRules ? (
                <Alert id="rules-empty" {...alertProperties} />
            ) : (
                <Grid>
                    <Grid.Column width={{ base: 12 }}>
                        {arePriceRulesEnabled && pricing.length > 0 && (
                            <Table
                                {...pricingTableConfig}
                                id="pricing-rules"
                                key="pricing-rules"
                                title={translated.rules.types.pricing}
                                loadingIds={fetching.ids}
                                canCollapse
                                canCheck={isEditEnabled}
                            />
                        )}
                        {exception.length > 0 && (
                            <Table
                                {...exceptionTableConfig}
                                id="exception-rules"
                                key="exception-rules"
                                title={translated.rules.types.exception}
                                loadingIds={fetching.ids}
                                canCollapse
                                canCheck={isEditEnabled}
                                isSortable
                                isSortEnabled={!isSorting}
                                onSort={onExceptionSort}
                            />
                        )}
                        {points.length > 0 && (
                            <Table
                                {...pointsTableConfig}
                                id="balance-type-rules"
                                key="balance-type-rules"
                                title={translated.rules.types.balanceType}
                                loadingIds={fetching.ids}
                                canCollapse
                                canCheck={isEditEnabled}
                                isSortable
                                isSortEnabled={!isSorting}
                                onSort={onPointsSort}
                            />
                        )}

                        {flatFee.length > 0 && (
                            <Table
                                {...flatFeeTableConfig}
                                id="flat-fee-rules"
                                key="flat-fee-rules"
                                title={translated.rules.types.flatFee}
                                loadingIds={fetching.ids}
                                canCollapse
                                canCheck={isEditEnabled}
                            />
                        )}

                        {fiatOption.length > 0 && (
                            <Table
                                {...fiatTableConfig}
                                id="fiat-rules"
                                key="fiat-rules"
                                title={translated.rules.types.fiatOption}
                                loadingIds={fetching.ids}
                                canCollapse
                                canCheck={isEditEnabled}
                            />
                        )}
                    </Grid.Column>
                </Grid>
            )}
        </Subsection>
    );
}

Rules.defaultProps = {
    isEmbedded             : false,
    selectRuleEditorMethod : 'goToRuleEditor',
    arePriceRulesEnabled   : true,
    isAddEnabled           : true,
    isSystemRule           : false,
    hideTitle              : false,
    isEditEnabled          : true,
};
Rules.propTypes = {
    data                   : PropTypes.shape({}).isRequired,
    resources              : PropTypes.shape({}).isRequired,
    fetching               : PropTypes.shape({}).isRequired,
    isEmbedded             : PropTypes.bool,
    selectRuleEditorMethod : PropTypes.string,
    arePriceRulesEnabled   : PropTypes.bool,
    isAddEnabled           : PropTypes.bool,
    isSystemRule           : PropTypes.bool,
    hideTitle              : PropTypes.bool,
    isEditEnabled          : PropTypes.bool,
};

Rules.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={280} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table hasTitle rows={2} hasPagination={false} />
                <Skeleton.Table hasTitle rows={2} hasPagination={false} />
                <Skeleton.Table hasTitle rows={2} hasPagination={false} />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(Rules);
