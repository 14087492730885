import React from 'react';
import PropTypes from 'prop-types';

export default function List({ items }) {
    return (
        <ul className="clear-ul padding-right">
            {
                items.map((item) => {
                    const { children, id, text, onClick } = item;
                    const subList = children.length ? <List items={children} /> : undefined;

                    return (
                        <>
                            <li id={id} key={id}>
                                <span className="link" onClick={onClick}>
                                    {text}
                                </span>
                            </li>
                            {subList && (
                                <li className="margin-left-small">
                                    {subList}
                                </li>
                            )}
                        </>
                    );
                })
            }
        </ul>
    );
}

List.defaultProps = { items: [] };

List.propTypes = { items: PropTypes.arrayOf(PropTypes.shape({})) };
