module.exports = Object.freeze({
    title         : 'apf.propertyGroups.title',
    empty         : 'apf.propertyGroups.empty',
    emptyChildren : 'apf.propertyGroups.emptyChildren',
    createTitle   : 'apf.propertyGroups.createTitle',
    editTitle     : 'apf.propertyGroups.editTitle',
    viewTitle     : 'apf.propertyGroups.viewTitle',
    update        : { success: 'apf.propertyGroups.update.success', error: 'apf.propertyGroups.update.error' },
    save          : { success: 'apf.propertyGroups.save.success', error: 'apf.propertyGroups.save.error' },
    id            : 'apf.propertyGroups.id',
    code          : 'apf.propertyGroups.code',
    name          : 'apf.propertyGroups.name',
    hidden        : 'apf.propertyGroups.hidden',
    isActive      : 'apf.propertyGroups.isActive',
    subTableTitle : 'apf.propertyGroups.subTableTitle',
    delete        : {
        confirmation               : 'apf.propertyGroups.delete.confirmation',
        success                    : 'apf.propertyGroups.delete.success',
        error                      : 'apf.propertyGroups.delete.error',
        errorEntityCannotBeDeleted : 'apf.propertyGroups.delete.errorEntityCannotBeDeleted',
        defaultError               : 'apf.propertyGroups.delete.defaultError',
    },
    enable  : { success: 'apf.propertyGroups.enable.success', error: 'apf.propertyGroups.enable.error' },
    disable : { success: 'apf.propertyGroups.disable.success', error: 'apf.propertyGroups.disable.error' },
});
