import preloadedState from '../state/storeInitialState';
import { SECTION_SELECT as sectionSelect } from '../state/types/panel';
import { APP_ERROR_STATE } from '../state/types/app';
import { createError, getErrorProperties } from './errorService';

/**
 * Returns a boolean that represent if the section selected exists
 *
 * @param {*} sections      Sections that will be validated.
 * @param {*} currentPath   Path used to complete 'sections' when the path is relative.
 */
const sectionsAreValid = (sections, currentPath) => {
    let valid = false;

    if (sections && sections.length) {
        valid = true;
        let currentNode = preloadedState.panel.sections;
        let sectionPath = [...sections];
        const [firstNode] = sectionPath;

        const isAbsolutePath = Object.keys(currentNode).find((eachKey) => eachKey === firstNode);

        if (!isAbsolutePath) {
            // When the path is relative, the first node of the new path is sibling of the last node of the previous path.
            const currentAbsolutePath = [...currentPath];
            currentAbsolutePath.pop();
            sectionPath = [...currentAbsolutePath, ...sections];
        }

        sectionPath.forEach((section) => {
            if (valid && currentNode && currentNode[section]) {
                currentNode = currentNode[section] ? currentNode[section].sections : null;
            } else {
                valid = false;
            }
        });
    }
    return valid;
};

/**
 * Handles the unexpected exceptions thrown by other middlewares and redux actions.
 */
const exceptionsHandler = () => (next) => (action) => {
    try {
        return next(action);
    } catch (error) {
        const errorProperties = getErrorProperties(error);
        return next({ type: APP_ERROR_STATE, payload: errorProperties });
    }
};

/**
 * Checks, when a 'sectionSelect' action is triggered, if the section is valid.
 */
const validateSectionSelect = (store) => (next) => (action) => {
    const { type, payload } = action;
    if (type === sectionSelect) {
        const { sectionPath } = payload;
        const { panel } = store.getState();
        const { path } = panel;
        const { current } = path;

        if (!sectionsAreValid(sectionPath, current)) {
            throw createError({ title: 'The section you are trying to access is invalid', isCritical: false });
        }
    }

    return next(action);
};

export { exceptionsHandler, validateSectionSelect };
