import { mdiApplicationCog } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const ruleType = null;
const ruleId = null;

export default {
    [sectionType.SYSTEM_RULES]: {
        isEnabled : false,
        config    : { url: '/panel/rules', icon: mdiApplicationCog },
        sections  : {
            [sectionType.SYSTEM_RULES_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.SYSTEM_RULES } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
            },
            [sectionType.RULE]: {
                ids       : { ruleId, ruleType, self: 'ruleId' },
                resources : { templates: { name: resourceNames.SYSTEM_RULE } },
                config    : {
                    url                        : { withId: '/(\\d*-\\w*)*', withoutId: '/add' },
                    resourceName               : resourceNames.SYSTEM_RULE,
                    combinedIdKey              : 'type',
                    hasTypePreffixInBreadcrumb : true,
                    requestTimeout             : 40000,
                },
                customProps: { isSystemRule: true, navigateToParent: 'goToRoot' },
            },
        },
    },
};
