/**
 * Returns the corresponding class for the size that has been indicated
 */
function getSizeClass(childWidthKey) {
    switch (childWidthKey) {
        case 'small':
            return '-sm-';
        case 'medium':
            return '-md-';
        case 'large':
            return '-lg-';
        case 'extraLarge':
            return '-xlg-';
        case 'base':
        default:
            return '-';
    }
}
export default getSizeClass;
