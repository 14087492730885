module.exports = Object.freeze({
    userNotSaved        : 'The agent could not be updated',
    onPermissionsChange :
        'We have detected a possible change in the permissions of your agent, the section will be closed, the data that has not been saved will be lost, please press "Refresh" to continue',
    onYourPermissionsChange    : 'You have changed your own permissions, your information has been refreshed',
    noUsers                    : 'There are no agents added',
    sendingInvitation          : 'Your request is being processed',
    sendInvitationConfirmation : 'Are you sure you want to send the invitation',
    sendInvitationSuccess      : 'Invitation email was sent',
    enable                     : {
        success : 'The agent has been enabled',
        error   : 'The agent could not be enabled',
    },
    disable: {
        success : 'The agent has been disabled',
        error   : 'The agent could not be disabled',
    },
    delete: {
        confirmation : 'Delete Agent?',
        success      : 'The agent has been removed',
        error        : 'The agent could not be removed',
    },
    errors: {
        invitation          : 'The invitation could not be sent',
        entityAlreadyExists : 'There is a user in the system using this email or login token',
    },
    success: {
        creation : 'The agent has been added',
        edition  : 'The agent has been updated',
    },
    title: {
        new     : 'New',
        edition : 'Edit',
    },
});
