import { sectionType, resourceNames } from '../../constants/types';
import translated from 'Constants/labels/translated';

export default {
    [sectionType.DOCUMENTATION]: {
        isEnabled : true,
        isPublic  : true,
        config    : { url: '/panel/help', isHiddenInBreadcrumb: true },
        sections  : {
            [sectionType.DOCUMENTS]: {
                isEnabled   : true,
                resources   : { templates: { name: resourceNames.DOCUMENTATION } },
                config      : { url: '/documentation', isHiddenInBreadcrumb: true },
                customProps : { title: translated.documentation.titles.docs },
            },
            [sectionType.RELEASES]: {
                isEnabled   : true,
                resources   : { templates: { name: resourceNames.RELEASE } },
                config      : { url: '/releases', isHiddenInBreadcrumb: true },
                customProps : { title: translated.documentation.titles.releases },
            },
        },
    },
};
