import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import appReducer, * as fromAppReducer from 'State/reducers/app';
import panelReducer, * as fromPanelReducer from 'State/reducers/panel';
import qaReducer from 'State/reducers/qa';
import preloadedState from './storeInitialState';
import { exceptionsHandler, validateSectionSelect } from 'Utils/middlewares';

const composeEnhancers = composeWithDevTools({});

const defaultReducers = {
    app   : appReducer,
    panel : panelReducer,
    qa    : qaReducer,
};

const initStore = (initialState = preloadedState) => createStore(
    combineReducers(defaultReducers),
    initialState,
    composeEnhancers(applyMiddleware(thunk, exceptionsHandler, validateSectionSelect)),
);

export default initStore;

export const getSelectableParentPath = (state) => fromPanelReducer.getSelectableParentPath(state.panel);
export const hasSectionChanged = (state) => fromPanelReducer.hasSectionChanged(state.panel);
export const getSectionIsFetching = (state) => fromPanelReducer.getSectionIsFetching(state);
export const getAppAsRestricted = (state) => fromAppReducer.getAppAsRestricted(state.app);
