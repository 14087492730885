import React, { useEffect } from 'react';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import { scrollToTop } from 'Utils';
import withRequest from 'Components/Sections/withRequest';
import translated from 'Constants/labels/translated';
// eslint-disable-next-line import/no-cycle
import BookingList from './BookingList';

function List(props) {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper title={translated.bookings.title}>
            <BookingList {...props} />
        </Wrapper>
    );
}

List.propTypes = {};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Title isHeading width={190} />
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
