import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import getClassName from 'Utils/getClassName';
import { startDateInput, endDateInput } from './constants';
import PickerInput from './PickerInput';
import PickerSelects from './PickerSelects';
import PickerCalendarDay from './PickerCalendarDay';
import PickerCalendarMonth from './PickerCalendarMonth';
import PickerCalendarYear from './PickerCalendarYear';
import PickerCalendarHeader from './PickerCalendarHeader';

/**
 * Given a the previously field edited, it returns the next field that the calendar must enable.
 * @param {string} editedInput
 * @param {boolean} isRange
 * @param {date} prevStart
 * @param {date} prevEnd
 */
function getNextEditableInput(editedInput, isRange, prevStart, prevEnd) {
    switch (editedInput) {
        case startDateInput:
            return isRange ? (!prevEnd && endDateInput) || null : startDateInput;
        default:
            // when editedInput is endDateInput, it also returns null
            return null;
    }
}

function CalendarDate({
    className, isRange, isInline, onClose, value, minDate, maxDate, label, labelFrom, labelTo, minRangeLength, dateManager,
}) {
    const initialValue = isRange ? value?.begin : value;
    const endValue = isRange ? value?.end : null;

    const [state, setState] = useState({
        showYearSelect         : false,
        showMonthSelect        : false,
        showDateSelect         : true,
        showInputDate          : false,
        inputStartDateValue    : dateManager.isValidDate(initialValue) ? dateManager.convertToDisplayableDate(initialValue) : null,
        inputStartDateError    : null,
        inputEndDateValue      : dateManager.isValidDate(endValue) ? dateManager.convertToDisplayableDate(endValue) : null,
        inputEndDateError      : null,
        startDateObject        : dateManager.isValidDate(initialValue) ? dateManager.create(initialValue) : null,
        endDateObject          : dateManager.isValidDate(endValue) ? dateManager.create(endValue) : null,
        dateError              : null,
        dateChanged            : false,
        // Input currently being edited. Used in the range calendars.
        editableInput          : !isRange || !endValue ? startDateInput : null,
        displayedDate          : dateManager.isValidDate(initialValue) ? dateManager.create(initialValue) : dateManager.create(),
        displayedDateNextMonth : dateManager.add(
            dateManager.isValidDate(initialValue) ? dateManager.create(initialValue) : dateManager.create(),
            1,
            'months',
        ),
        calendarHeight: 0,
    });

    const calendarRef = useRef();
    const calendarMonthsRef = useRef();
    const calendarYearsRef = useRef();

    const calendarDates = useRef({});

    const calendarStartDate = minDate;
    const calendarEndDate = maxDate;

    const isDateInRange = useCallback(
        (date, granularity = 'days', elementRef) => dateManager.isValidDate(date)
                && dateManager.isBetweenEqual(date, calendarStartDate, calendarEndDate, granularity)
                && !elementRef?.ref?.current.classList.contains('is-disabled'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [calendarEndDate, calendarStartDate],
    );

    useEffect(() => {
        if (calendarRef.current) {
            setState((prev) => ({
                ...prev,
                calendarHeight: calendarRef.current.clientHeight,
            }));
        }
    }, []);

    useEffect(() => {
        if (state.calendarHeight && calendarYearsRef.current) {
            calendarYearsRef.current.style.height = `${state.calendarHeight}px`;
        }
    }, [state.calendarHeight, state.showYearSelect]);

    useEffect(() => {
        if (isInline && state.dateChanged && dateManager.isValidDate(state.startDateObject)
            && (!isRange || dateManager.isValidDate(state.endDateObject))) {
            onClose(
                isRange
                    ? {
                        begin : dateManager.convertToDisplayableDate(state.startDateObject),
                        end   : dateManager.convertToDisplayableDate(state.endDateObject),
                    }
                    : dateManager.convertToDisplayableDate(state.startDateObject),
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dateChanged, state.endDateObject, state.startDateObject]);

    // Returns the level at which the dates will be compared.
    const getDateGranularity = () => (state.showDateSelect ? 'months' : 'years');

    const getMonth = useCallback((date = state.displayedDate) => dateManager.format(date, 'MMMM'), [dateManager, state.displayedDate]);
    const getYear = useCallback((date = state.displayedDate) => dateManager.format(date, 'Y'), [dateManager, state.displayedDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSelectedDay = useCallback(() => state.startDateObject && dateManager.format(state.startDateObject, 'EEE, MMM dd'), [state.startDateObject]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSelectedStartDay = useCallback(() => state.startDateObject && dateManager.format(state.startDateObject, 'MMM dd yyyy'), [state.startDateObject]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSelectedEndDay = useCallback(() => state.endDateObject && dateManager.format(state.endDateObject, 'MMM dd yyyy'), [state.endDateObject]);

    // Returns the classes that the date calendar will use.
    const getDateClasses = useCallback(
        (date, granularity) => {
            let classes = '';

            if (dateManager.isSame(date, state.startDateObject, granularity)) {
                classes += ' selected';
                if (isRange) {
                    classes += ' selected-start';
                }
            }

            if (Array.isArray(value)) {
                const start = state.startDateObject || null;
                const end = state.endDateObject || null;

                const formattedDate = dateManager.format(date, 'yyyy-MM-dd');
                const startDates = value.map((d) => dateManager.format(d.begin, 'yyyy-MM-dd'));

                if (startDates.includes(formattedDate)) {
                    classes += ' selected-start in-use';
                }
                const endDates = value.map((d) => dateManager.format(d.end, 'yyyy-MM-dd'));

                if (endDates.includes(formattedDate)) {
                    classes += ' selected-end in-use';
                }

                value.forEach((val) => {
                    const rangeBegin = val.begin;
                    const rangeEnd = val.end;
                    if (dateManager.isBetween(date, rangeBegin, rangeEnd, granularity)) {
                        classes += ' in-between in-use';
                    }
                    if (start && end) {
                        const isBefore = dateManager.isBetweenEqual(date, start, rangeBegin, 'days') && dateManager.isBetweenEqual(rangeBegin, date, end, 'days');
                        const isAfter = dateManager.isBetweenEqual(date, rangeEnd, end, 'days') && dateManager.isBetweenEqual(rangeBegin, start, date, 'days');
                        if (isBefore || isAfter) {
                            classes += ' in-use-error';
                        }
                    }
                });
            }

            if (isRange && dateManager.isSame(date, state.endDateObject, granularity)) {
                classes += ' selected selected-end';
            }

            if (isRange && dateManager.isBetween(date, state.startDateObject, state.endDateObject, granularity)) {
                classes += ' in-between';
            }

            if ((dateManager.isBefore(date, minDate, 'days') || dateManager.isAfter(date, maxDate, 'days')) && !classes.includes('in-use')) {
                classes += ' is-disabled';
            }

            return classes;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRange, maxDate, minDate, state.endDateObject, state.startDateObject, value],
    );

    // Returns the classes that the month and year calendar will use.
    const getMonthYearClasses = useCallback(
        (date, granularity) => {
            let classes = '';

            if (dateManager.isSame(date, state.startDateObject || state.endDateObject, granularity)) {
                classes += ' selected';
            }

            if (!isDateInRange(date, granularity)) {
                classes += ' is-disabled';
            }

            return classes;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDateInRange, state.endDateObject, state.startDateObject],
    );

    // Validates the date received and returns the error found.
    const getInputDateError = useCallback(
        (date) => {
            if (date && !dateManager.isValidDate(date)) {
                return (
                    <FormattedMessage id={translated.global.calendar.errors.invalidDate} defaultMessage={translated.global.calendar.errors.invalidDate} />
                );
            }
            if (date && !isDateInRange(date)) {
                return (
                    <FormattedMessage
                        id={translated.global.calendar.errors.dateInRange}
                        defaultMessage={translated.global.calendar.errors.dateInRange}
                        values={{
                            start : calendarStartDate,
                            end   : calendarEndDate,
                        }}
                    />
                );
            }

            return null;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [calendarEndDate, calendarStartDate],
    );

    // Validates the start and end date.
    const validateDates = useCallback(
        (showInputDate, startDate, endDate) => {
            const validations = { dateError: null, inputStartDateError: null, inputEndDateError: null };

            if (showInputDate) {
                validations.inputStartDateError = getInputDateError(startDate);
            }

            if (isRange) {
                if (showInputDate) {
                    validations.inputEndDateError = getInputDateError(endDate);
                }

                if (dateManager.isValidDate(endDate)) {
                    if (!validations.inputStartDateError && !validations.inputEndDateError && !dateManager.isValidRange(startDate, endDate)) {
                        validations.dateError = (
                            <FormattedMessage
                                id={translated.global.calendar.errors.startBeforeEnd}
                                defaultMessage={translated.global.calendar.errors.startBeforeEnd}
                            />
                        );
                    } else if (minRangeLength && dateManager.differenceInDays(endDate, startDate) < minRangeLength) {
                        validations.dateError = (
                            <FormattedMessage
                                id={translated.global.calendar.errors.rangeMinLength}
                                defaultMessage={translated.global.calendar.errors.rangeMinLength}
                            />
                        );
                    }
                    if (Array.isArray(value)) {
                        const inUse = value.map((d) => ({
                            begin : d.begin,
                            end   : d.end,
                        }));
                        const start = startDate;
                        const end = endDate;
                        inUse.forEach((val) => {
                            if (dateManager.isBetweenEqual(val.begin, start, end, 'days') || dateManager.isBetweenEqual(val.end, start, end, 'days')) {
                                validations.dateError = (
                                    <FormattedMessage
                                        id={translated.global.calendar.errors.includesRange}
                                        defaultMessage={translated.global.calendar.errors.includesRange}
                                    />
                                );
                            }
                        });
                    }
                }
            }

            return validations;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value],
    );

    // Functions related to the 'Previous' button.
    const isPrevEnabled = () => dateManager.isSameOrBefore(
        calendarStartDate,
        dateManager.sub(state.displayedDate, 1, getDateGranularity()),
        getDateGranularity(),
    );

    const onPrevClick = () => {
        setState((prev) => ({
            ...prev,
            displayedDate          : dateManager.sub(prev.displayedDate, 1, getDateGranularity()),
            displayedDateNextMonth : dateManager.add(
                dateManager.sub(prev.displayedDate, 1, getDateGranularity()),
                1,
                'months',
            ),
        }));
    };

    // Functions related to the 'Next' button.
    const isNextEnabled = () => dateManager.isSameOrBefore(
        dateManager.add(state.displayedDate, 1, getDateGranularity()),
        calendarEndDate,
        getDateGranularity(),
    );

    const onNextClick = () => {
        setState((prev) => ({
            ...prev,
            displayedDate          : dateManager.add(prev.displayedDate, 1, getDateGranularity()),
            displayedDateNextMonth : dateManager.add(
                dateManager.add(prev.displayedDate, 1, getDateGranularity()),
                1,
                'months',
            ),
        }));
    };

    // The year's select is clicked
    const onYearSelect = useCallback(() => {
        setState((prev) => ({
            ...prev,
            showYearSelect  : true,
            showMonthSelect : false,
            showDateSelect  : false,
        }));
    }, []);

    // The month's select is clicked
    const onMonthSelect = useCallback(() => {
        setState((prev) => ({
            ...prev,
            showYearSelect  : false,
            showMonthSelect : true,
            showDateSelect  : false,
        }));
    }, []);

    // Changes the view between the input and calendar
    const onTypeSelect = useCallback(() => {
        setState((prev) => {
            const showInputDate = !prev.showInputDate;

            return {
                ...prev,
                ...validateDates(showInputDate, prev.startDateObject, prev.endDateObject),
                showInputDate,
                inputStartDateValue : dateManager.convertToDisplayableDate(prev.startDateObject),
                inputEndDateValue   : dateManager.convertToDisplayableDate(prev.endDateObject),
                showYearSelect      : false,
                showMonthSelect     : false,
                showDateSelect      : true,
            };
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // A year of the calendar is clicked
    const onYearClick = useCallback((y) => {
        calendarDates.current = {};

        setState((prev) => ({
            ...prev,
            showYearSelect         : false,
            showMonthSelect        : false,
            showDateSelect         : true,
            displayedDate          : dateManager.setYear(prev.displayedDate, y),
            displayedDateNextMonth : dateManager.add(
                dateManager.setYear(prev.displayedDate, y),
                1,
                'months',
            ),
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // A month of the calendar is clicked
    const onMonthClick = useCallback((m) => {
        calendarDates.current = {};

        setState((prev) => ({
            ...prev,
            showYearSelect         : false,
            showMonthSelect        : false,
            showDateSelect         : true,
            displayedDate          : dateManager.setMonth(prev.displayedDate, m),
            displayedDateNextMonth : dateManager.setMonth(prev.displayedDate, m + 1),
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // A day of the calendar is clicked
    const onDayClick = useCallback(
        (date) => {
            calendarDates.current = {};

            setState((prev) => {
                const newStartDate = prev.editableInput === startDateInput ? date : prev.startDateObject;
                const newEndDate = prev.editableInput === endDateInput ? date : prev.endDateObject;

                return {
                    ...prev,
                    ...validateDates(prev.showInputDate, newStartDate, newEndDate),
                    startDateObject : newStartDate,
                    endDateObject   : newEndDate,
                    dateChanged     : true,
                    editableInput   : getNextEditableInput(prev.editableInput, isRange, prev.startDateObject, prev.endDateObject),
                };
            });
        },
        [isRange, validateDates],
    );

    // Handle the calendar days classes as the user triggers the 'OnMouseEnter' event.
    const calendarOnMouseEnter = useCallback(
        (date, granularity) => {
            if (isRange && state.editableInput) {
                const start = state.editableInput === startDateInput ? date : state.startDateObject;
                const end = state.editableInput === endDateInput ? date : state.endDateObject;

                if (start && end && start !== end) {
                    if (!Array.isArray(value)) {
                        Object.values(calendarDates.current).forEach(({ date: eachDate, ref }) => {
                            if (ref?.current) {
                                ref.current.classList.remove('in-selection');

                                if (dateManager.isBetweenEqual(eachDate, start, end, granularity)) {
                                    ref.current.classList.add('in-selection');
                                }
                            }
                        });
                    } else {
                        const inUse = value.map((d) => ({
                            begin : d.begin,
                            end   : d.end,
                        }));
                        const overlaps = inUse.find((range) => dateManager.isSameOrBefore(start, range.end)
                            && dateManager.isSameOrAfter(end, range.begin));
                        Object.values(calendarDates.current).forEach(({ date: eachDate, ref }) => {
                            if (ref?.current) {
                                ref.current.classList.remove('in-selection');
                                ref.current.classList.remove('in-use-error');
                                ref.current.classList.remove('is-disabled');

                                const isBetween = dateManager.isBetweenEqual(eachDate, start, end, granularity);

                                if (overlaps && isBetween) {
                                    ref.current.classList.add('in-use-error');
                                    ref.current.classList.add('in-selection');
                                }
                                if (!overlaps && isBetween) {
                                    ref.current.classList.add('in-selection');
                                }
                                if (
                                    (dateManager.isBefore(eachDate, minDate, 'days') || dateManager.isAfter(eachDate, maxDate, 'days'))
                                    && !ref.current.classList.contains('in-use')
                                ) {
                                    ref.current.classList.add('is-disabled');
                                }
                            }
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [maxDate, minDate, state.editableInput, state.endDateObject, state.startDateObject, value],
    );

    // Handle the calendar days classes as the user triggers the 'OnMouseLeave' event.
    const calendarOnMouseLeave = useCallback(() => {
        if (isRange) {
            Object.values(calendarDates.current).forEach(({ ref }) => {
                if (ref?.current) {
                    ref.current.classList.remove('in-selection');
                }
            });
        }

        return true;
    }, [isRange]);

    // Handles the changes on the start date input
    const onInputChange = useCallback(
        ({ target: { value: newValue } }) => {
            setState((prev) => {
                const newState = { ...validateDates(prev.showInputDate, newValue, prev.inputEndDateValue) };

                if (!Object.values(newState).find((e) => !!e)) {
                    const convertedValue = dateManager.create(newValue);

                    newState.startDateObject = convertedValue;
                    newState.endDateObject = prev.inputEndDateValue;
                    newState.displayedDate = dateManager.defaultDateFormat(convertedValue);
                    newState.displayedDateNextMonth = dateManager.add(
                        newValue,
                        1,
                        'months',
                    );
                    newState.dateChanged = true;

                    if (prev.editableInput === startDateInput) {
                        newState.editableInput = getNextEditableInput(prev.editableInput, isRange, prev.startDateObject, prev.endDateObject);
                    }
                }

                return { ...prev, ...newState, inputStartDateValue: newValue };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // Handles the changes on the end date input
    const onEndDateInputChange = useCallback(
        ({ target: { value: newValue } }) => {
            setState((prev) => {
                const newState = { ...validateDates(prev.showInputDate, prev.inputStartDateValue, newValue) };

                if (!Object.values(newState).find((e) => !!e)) {
                    const convertedValue = dateManager.create(newValue);

                    newState.startDateObject = prev.inputStartDateValue;
                    newState.endDateObject = convertedValue;
                    newState.dateChanged = true;

                    if (prev.editableInput === startDateInput) {
                        newState.editableInput = getNextEditableInput(prev.editableInput, isRange, prev.startDateObject, prev.endDateObject);
                    }
                }

                return { ...prev, ...newState, inputEndDateValue: newValue };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const calendarClassName = getClassName(
        { editingFrom: state.editableInput === startDateInput, editingTo: state.editableInput === endDateInput },
        'calendar',
    );
    const calendarInputClassName = getClassName({ marginBottomMedium: state.inputError }, 'calendar-input form-field');

    // Resets the calendar start and end date for the inline calendar.
    const clearInlineCalendar = () => {
        setState((prev) => ({
            ...prev,
            startDateObject : null,
            endDateObject   : null,
            editableInput   : startDateInput,
            dateError       : null,
        }));
    };

    const addDayRef = useCallback(
        (dayKey, dayValue) => {
            calendarDates.current[dayKey] = dayValue;
        },
        [calendarDates],
    );

    return (
        <div className={getClassName({ isRange, isInline }, 'calendar-wrapper', className)}>
            <div className="calendar-container">
                <PickerCalendarHeader
                    label={label}
                    labelFrom={labelFrom}
                    labelTo={labelTo}
                    editableInput={state.editableInput}
                    showInputDate={state.showInputDate}
                    isRange={isRange}
                    getSelectedDay={getSelectedDay}
                    getSelectedStartDay={getSelectedStartDay}
                    getSelectedEndDay={getSelectedEndDay}
                    onStartDayClick={() => setState((prev) => ({ ...prev, editableInput: startDateInput }))}
                    onEndDayClick={() => setState((prev) => ({ ...prev, editableInput: endDateInput }))}
                    onTypeSelect={onTypeSelect}
                />
                {!state.showInputDate && (
                    <>
                        <div className="calendar-navigation">
                            {!isRange && <PickerSelects month={getMonth()} onMonthSelect={onMonthSelect} year={getYear()} onYearSelect={onYearSelect} />}
                            {(state.showDateSelect || state.showMonthSelect) && (
                                <div className="calendar-navigation-arrows">
                                    <Button
                                        id="calendar-arrow-left"
                                        icon="ChevronLeft"
                                        onClick={onPrevClick}
                                        disabled={!isPrevEnabled()}
                                        isMultiClickEnabled
                                    />
                                    <Button
                                        id="calendar-arrow-right"
                                        icon="ChevronRight"
                                        onClick={onNextClick}
                                        disabled={!isNextEnabled()}
                                        isMultiClickEnabled
                                    />
                                </div>
                            )}
                        </div>
                        <table className={calendarClassName} ref={calendarRef}>
                            {!isRange && state.showDateSelect && (
                                <PickerCalendarDay
                                    date={state.displayedDate}
                                    elementKey={dateManager.format(state.displayedDate, 'yyyy-MM-dd')}
                                    getDateClasses={getDateClasses}
                                    isDateInRange={isDateInRange}
                                    onDayClick={onDayClick}
                                    calendarOnMouseEnter={calendarOnMouseEnter}
                                    calendarOnMouseLeave={calendarOnMouseLeave}
                                    addDayRef={addDayRef}
                                    dateManager={dateManager}
                                />
                            )}
                            {isRange && (
                                <>
                                    <div className="calendar-range">
                                        <PickerSelects month={getMonth()} onMonthSelect={onMonthSelect} year={getYear()} onYearSelect={onYearSelect} />
                                        {state.showDateSelect && (
                                            <PickerCalendarDay
                                                date={state.displayedDate}
                                                elementKey={dateManager.format(state.displayedDate, 'yyyy-MM-dd')}
                                                getDateClasses={getDateClasses}
                                                isDateInRange={isDateInRange}
                                                onDayClick={onDayClick}
                                                calendarOnMouseEnter={calendarOnMouseEnter}
                                                calendarOnMouseLeave={calendarOnMouseLeave}
                                                addDayRef={addDayRef}
                                                dateManager={dateManager}
                                            />
                                        )}
                                    </div>
                                    <div className="calendar-range">
                                        <PickerSelects
                                            month={getMonth(state.displayedDateNextMonth)}
                                            onMonthSelect={onMonthSelect}
                                            year={getYear(state.displayedDateNextMonth)}
                                            onYearSelect={onYearSelect}
                                        />
                                        {state.showDateSelect && (
                                            <PickerCalendarDay
                                                date={state.displayedDateNextMonth}
                                                elementKey={dateManager.format(state.displayedDateNextMonth, 'yyyy-MM-dd')}
                                                getDateClasses={getDateClasses}
                                                isDateInRange={isDateInRange}
                                                onDayClick={onDayClick}
                                                calendarOnMouseEnter={calendarOnMouseEnter}
                                                calendarOnMouseLeave={calendarOnMouseLeave}
                                                addDayRef={addDayRef}
                                                dateManager={dateManager}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                            {state.showMonthSelect && (
                                <PickerCalendarMonth
                                    displayedDate={state.displayedDate}
                                    elementKey={dateManager.format(state.displayedDate, 'yyyy-MM-dd')}
                                    onMonthClick={onMonthClick}
                                    getMonthYearClasses={getMonthYearClasses}
                                    calendarMonthsRef={calendarMonthsRef}
                                    isDateInRange={isDateInRange}
                                    dateManager={dateManager}
                                />
                            )}
                            {state.showYearSelect && (
                                <PickerCalendarYear
                                    elementKey={dateManager.format(state.displayedDate, 'yyyy-MM-dd')}
                                    calendarStartDate={calendarStartDate}
                                    calendarEndDate={calendarEndDate}
                                    displayedDate={state.displayedDate}
                                    onYearClick={onYearClick}
                                    getMonthYearClasses={getMonthYearClasses}
                                    calendarYearsRef={calendarYearsRef}
                                    dateManager={dateManager}
                                />
                            )}
                        </table>
                        {state.dateError && (
                            <Alert
                                className="margin-top-small margin-left-small"
                                type="error"
                                content={state.dateError}
                                actionText={translated.global.buttons.reset}
                                onClick={clearInlineCalendar}
                            />
                        )}
                    </>
                )}
                {state.showInputDate && (
                    <>
                        <div className={calendarInputClassName}>
                            <PickerInput
                                id="from"
                                value={state.inputStartDateValue}
                                onChange={onInputChange}
                                label={labelFrom || (isRange ? translated.global.calendar.fromLabel : translated.global.calendar.dateLabel)}
                                error={state.inputStartDateError}
                            />
                            {isRange && (
                                <PickerInput
                                    id="to"
                                    value={state.inputEndDateValue}
                                    onChange={onEndDateInputChange}
                                    label={labelTo || translated.global.calendar.toLabel}
                                    error={state.inputEndDateError}
                                />
                            )}
                        </div>
                        {state.dateError && <div className="calendar-error-msg padding-remove-top">{state.dateError}</div>}
                    </>
                )}
                {!isInline && (
                    <div className="calendar-footer">
                        <Button
                            id="calendar-cancel"
                            variant="text"
                            color="primary"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                        </Button>
                        <Button
                            id="calendar-close"
                            variant="text"
                            color="primary"
                            disabled={
                                !dateManager.isValidDate(state.startDateObject)
                                || (isRange && !dateManager.isValidDate(state.endDateObject))
                                || !!state.inputStartDateError
                                || !!state.inputEndDateError
                                || !!state.dateError
                            }
                            onClick={() => {
                                onClose(
                                    isRange
                                        ? {
                                            begin : dateManager.convertToDisplayableDate(state.startDateObject),
                                            end   : dateManager.convertToDisplayableDate(state.endDateObject),
                                        }
                                        : dateManager.convertToDisplayableDate(state.startDateObject),
                                );
                            }}
                        >
                            <FormattedMessage id={translated.global.buttons.ok} defaultMessage={translated.global.buttons.ok} />
                        </Button>
                    </div>
                )}

                {isInline && state.startDateObject && state.endDateObject && !state.dateError && (
                    <div className="calendar-footer">
                        <Button id="calendar-reset" variant="text" color="primary" onClick={clearInlineCalendar}>
                            <FormattedMessage id={translated.global.buttons.reset} defaultMessage={translated.global.buttons.reset} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

CalendarDate.defaultProps = {
    label          : '',
    className      : '',
    isRange        : false,
    isInline       : false,
    onClose        : null,
    value          : null,
    labelFrom      : null,
    labelTo        : null,
    minRangeLength : 0,
};

CalendarDate.propTypes = {
    label          : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    className      : PropTypes.string,
    isRange        : PropTypes.bool,
    isInline       : PropTypes.bool,
    onClose        : PropTypes.func,
    value          : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    minDate        : PropTypes.string.isRequired,
    maxDate        : PropTypes.string.isRequired,
    labelFrom      : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    labelTo        : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    minRangeLength : PropTypes.number,
    dateManager    : PropTypes.shape({}).isRequired,
};

export default CalendarDate;
