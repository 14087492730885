import axios from 'axios';
import Cookie from 'js-cookie';
import { fileUploadIdleTimeout, fileUploadTimeout } from 'Constants/global';
import { isEnabled as isDebugModeEnabled } from 'Utils/debugMode';

function getHeaders(isTypeFile, retryNumber) {
    const headers = {};
    const cookie = Cookie.get('echo.api');

    if (cookie) {
        headers.Authorization = cookie;
    }

    if (retryNumber != null) {
        headers.Retry = retryNumber;
    }

    if (isTypeFile) {
        headers['Content-Type'] = 'multipart/form-data';
    }

    return headers;
}

function convertResponse({ data, order, pagination, warnings }) {
    // When the request involves a single entity, it returns it's data
    // For the requests that involve lists, it returns the data and the
    // objects to handle pagination and re-order
    return (!order && !pagination)
        ? { response: data, warnings }
        : { response: { data, order, pagination }, warnings };
}

// eslint-disable-next-line max-len
export const request = (
    config,
    isTypeFile = false,
    timeout = 12000,
    retryNumber = null,
    { onUploadProgress, onUploadFinish } = {},
    isFileDownload = false,
) => {
    const requestConfig = { ...config, headers: getHeaders(isTypeFile, retryNumber), timeout };

    if (isFileDownload) {
        requestConfig.responseType = 'blob';
    }

    // When the debugMode is enabled, we send debug=true to all the backend requests.
    if (isDebugModeEnabled()) {
        const paramConnector = requestConfig?.url?.indexOf('?') === -1 ? '?' : '&';
        requestConfig.url = `${requestConfig.url + paramConnector}debug=true`;
    }

    let timer = null;

    if (isTypeFile) {
        const { CancelToken } = axios;
        let cancel = null;

        requestConfig.timeout = fileUploadTimeout;
        requestConfig.cancelToken = new CancelToken(((c) => {
            cancel = c;
        }));

        requestConfig.onUploadProgress = (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onUploadProgress(percentage);

            if (timer) {
                clearTimeout(timer);
            }

            // When the upload does not progress for x seconds, it cancels the upload. When the upload is complete, we wait for the APT answer.
            if (percentage < 100) {
                timer = setTimeout(async () => {
                    timer = null;

                    cancel();
                }, fileUploadIdleTimeout);
            }
        };
    }

    return axios(requestConfig)
        .then((response) => {
            const { data, status } = response;

            if (onUploadFinish) {
                onUploadFinish();
            }

            if (!isFileDownload && (data?.success || status === 204)) {
                return convertResponse(data);
            }

            // Used particularly for the file downloads
            if (status === 200 && isFileDownload) {
                return { response };
            }

            throw data.error;
        })
        .catch((e) => {
            if (onUploadFinish) {
                onUploadFinish();
            }

            throw e;
        })
        .finally(() => {
            if (timer) {
                clearTimeout(timer);
            }
        });
};

export const requestLoggedUser = (config = { method: 'GET', url: window.env.GET_USER_URL }) => axios({ ...config, headers: getHeaders() })
    .then((response) => {
        const { data } = response;
        return data && data.data;
    })
    .catch((e) => {
        throw e;
    });

const Api = { request, requestLoggedUser };
export default Api;
