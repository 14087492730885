module.exports = Object.freeze({
    title   : 'apf.settings.title',
    content : { sync: 'apf.settings.content.sync' },
    alert   : { sync: 'apf.settings.alert.sync' },
    buttons : {
        help           : 'apf.settings.buttons.help',
        documentation  : 'apf.settings.buttons.documentation',
        releases       : 'apf.settings.buttons.releases',
        label          : 'apf.settings.buttons.label',
        tooltip        : 'apf.settings.buttons.tooltip',
        refresh        : 'apf.settings.buttons.refresh',
        about          : 'apf.settings.buttons.about',
        logout         : 'apf.settings.buttons.logout',
        mute           : 'apf.settings.buttons.mute',
        unmute         : 'apf.settings.buttons.unmute',
        changeLanguage : 'apf.settings.buttons.changeLanguage',
    },
    errors         : { sync: 'apf.settings.errors.sync' },
    success        : { sync: 'apf.settings.success.sync' },
    options        : { title: 'apf.settings.options.title', darkMode: 'apf.settings.options.darkMode' },
    changeLanguage : {
        title     : 'apf.settings.changeLanguage.title',
        label     : 'apf.settings.changeLanguage.label',
        languages : {
            english : 'apf.settings.changeLanguage.languages.english',
            spanish : 'apf.settings.changeLanguage.languages.spanish',
        },
        success : 'apf.settings.changeLanguage.languages.success',
        error   : 'apf.settings.changeLanguage.languages.error',
    },
});
