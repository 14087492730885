import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import Tooltip from 'Components/Tooltip';
import { useIntl } from 'react-intl';
import { getMessage } from 'Components/WrappedFormattedMessage';

function getVariantClass(variant) {
    switch (variant) {
        case 'outlined': return 'button-outlined';
        case 'text': return 'button-text';
        case 'unelevated': return 'button-unelevated';
        case 'link': return 'button-link';
        default: return '';
    }
}

function getColorClass(color) {
    switch (color) {
        case 'primary': return 'button-primary';
        case 'secondary': return 'button-secondary';
        case 'grey': return 'button-grey';
        default: return '';
    }
}

function getIconPositionClass(iconRight) {
    return iconRight ? 'button-with-icon-flip' : '';
}

function getIconVariant(icon, children) {
    if (icon) {
        return (children !== '' && children != null) ? 'button-with-icon' : 'button-icon';
    }
    return '';
}

function Link({
    icon, iconRight, variant, color, id,
    className, disabled, children, tooltip, forwardedRef, isLoading, link,
}) {
    const intl = useIntl();

    const tooltipMessage = useMemo(() => getMessage(intl, tooltip), [intl, tooltip]);

    const iconVariant = getIconVariant(icon, children);
    const buttonClassName = `button ${getIconPositionClass(iconRight)} ${getVariantClass(variant)} ${iconVariant} ${getColorClass(color)} ${className}`;
    const tooltipId = Tooltip.generateId();

    return (
        <a
            id={id}
            ref={forwardedRef}
            className={buttonClassName}
            disabled={disabled}
            data-tip={tooltipMessage}
            data-for={tooltipId}
            target="_blank"
            rel="noreferrer noopener"
            href={link.url}
        >
            {tooltip && !isLoading && <Tooltip id={tooltipId} disable={disabled} />}
            {icon && !isLoading && <Icon className="mdi-icon" path={IconPath[`mdi${icon}`]} />}
            {isLoading && <Icon spin className="mdi-icon" path={IconPath.mdiLoading} size="20px" />}
            {(iconVariant !== 'button-icon') && !isLoading && children}
        </a>
    );
}

Link.defaultProps = {
    disabled     : false,
    iconRight    : false,
    id           : '',
    icon         : '',
    color        : '',
    variant      : '',
    className    : '',
    tooltip      : '',
    children     : '',
    forwardedRef : null,
    isLoading    : false,
};

Link.propTypes = {
    id           : PropTypes.string,
    disabled     : PropTypes.bool,
    iconRight    : PropTypes.bool,
    icon         : PropTypes.string,
    color        : PropTypes.string,
    className    : PropTypes.string,
    tooltip      : PropTypes.string,
    children     : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant      : PropTypes.string,
    forwardedRef : PropTypes.shape({}),
    isLoading    : PropTypes.bool,
    link         : PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
};

export default Link;
