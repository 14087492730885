import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import formContext from '../formContext';

function Date({
    id, label, value, onChange, onFocus, onBlur, isReadOnlyWithoutInputs,
}) {
    const { dateManager } = useContext(formContext);

    const [oldValue, setOldValue] = useState(value);

    useEffect(() => {
        if (oldValue !== value) {
            setOldValue(value);
        }
    }, [value, oldValue]);

    const readOnlyValue = value ? dateManager.format(value, 'yyyy-MM-dd') : null;

    return (
        <>
            {label && (
                <label className="label">
                    <WrappedFormattedMessage content={label} />
                </label>
            )}
            {!isReadOnlyWithoutInputs && (
                <InputMask
                    id={id}
                    mask="9999-99-99"
                    value={value}
                    onChange={(event) => {
                        if (event.target.value === '____-__-__') {
                            onChange({ ...event, target: { ...event.target, value: '' } });
                        } else {
                            onChange(event);
                        }
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    alwaysShowMask
                />
            )}
            {isReadOnlyWithoutInputs && <span>{readOnlyValue}</span>}
        </>
    );
}

Date.defaultProps = {
    id                      : '',
    label                   : '',
    value                   : '',
    isReadOnlyWithoutInputs : false,
    onChange                : () => {
        // Default
    },
    onFocus: () => {
        // Default
    },
    onBlur: () => {
        // Default
    },
};

Date.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value                   : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onChange                : PropTypes.func,
    onFocus                 : PropTypes.func,
    onBlur                  : PropTypes.func,
};

export default React.memo(Date);
