/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FormContext from './formContext';
import Modal from 'Components/Modal';
import panelContext from 'State/panelContext';

/**
 * This wrapper provides a context that will use both the Form and the Primary button, the form will put in
 * context the onSubmit method and the information entered in the inputs and the Primary button will execute
 * the method by sending the info, in turn also provides the possibility of converting the form into a modal.
 */
function Wrapper({ children, modalProperties, hasChanged }) {
    const [submitData, setSubmitData] = useState(() => (hasChanged ? { hasChanged } : {}));
    const [events, setEvents] = useState({});
    const [contextInitialValues, setContextInitialValues] = useState({});
    const [contextInitialSubmitData, setContextInitialSubmitData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formId, setFormId] = useState(null);

    const { dateManager } = useContext(panelContext);

    // When modalProperties is set, it turns the content into a modal
    return (
        <FormContext.Provider
            value={{
                isContextInitialized: true,
                submitData,
                setSubmitData,
                events,
                setEvents,
                contextInitialValues,
                setContextInitialValues,
                contextInitialSubmitData,
                setContextInitialSubmitData,
                isSubmitting,
                setIsSubmitting,
                formId,
                setFormId,
                dateManager,
            }}
        >
            {modalProperties ? <Modal {...modalProperties}>{children}</Modal> : children}
        </FormContext.Provider>
    );
}

Wrapper.defaultProps = {
    modalProperties : null,
    hasChanged      : false,
};

Wrapper.propTypes = {
    hasChanged      : PropTypes.bool,
    children        : PropTypes.node.isRequired,
    modalProperties : PropTypes.shape({
        title       : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        closeButton : PropTypes.shape({
            text      : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            onClick   : PropTypes.func,
            isEnabled : PropTypes.bool,
        }),
        cancelButton: PropTypes.shape({
            text      : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            onClick   : PropTypes.func,
            isEnabled : PropTypes.bool,
        }),
        backdropClick : PropTypes.func,
        isVisible     : PropTypes.bool,
    }),
};

export default Wrapper;
