import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactReduxContext } from 'react-redux';
import Layout from 'Components/Layout/Layout';
import '../src/styles/main-app.less';

const getClientPath = () => window.env.CLIENT_FILENAMES;

const STYLES_FILE = '/main.css';

function EchoPanel({ children }) {
    const reduxContext = useContext(ReactReduxContext);
    const { title } = reduxContext.store.getState().app;
    const { path } = reduxContext.store.getState().panel;

    const linkUrl = process.env.NODE_ENV === 'production' && getClientPath()
        ? `/${getClientPath()}.min.css`
        : null;

    useEffect(
        () => {
            if (linkUrl) {
                document.head.querySelectorAll('link').forEach((link) => {
                    // We remove the main.css styles in prod, so we don't use duplicated styles once we load the client's styles
                    if (link.getAttribute('href')?.endsWith(STYLES_FILE)) {
                        link.parentNode.removeChild(link);
                    }
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <Layout title={title} currentPath={path.current}>
            { linkUrl && (<link label="layout" rel="stylesheet" type="text/css" href={linkUrl} />)}
            {children}
        </Layout>
    );
}

EchoPanel.propTypes = { children: PropTypes.node.isRequired };

export default EchoPanel;
