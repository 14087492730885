import React from 'react';
import { sectionPropTypes } from 'Constants/PropTypes';
// eslint-disable-next-line import/no-cycle
import { SelectedSection } from '../Collection';

function Tags({ sections }) {
    return <SelectedSection selectFrom={sections} />;
}

Tags.propTypes = sectionPropTypes;

export default Tags;
