import preloadedState from '../state/storeInitialState';
import { sectionsPriority } from 'Constants/global';

/**
 * Return a list of the child nodes that are marked as 'isDefaultSection'.
 */
export const getDefaultChildSections = (sections) => {
    const childNodes = [];

    let currentNodeSections = preloadedState.panel.sections;

    // Look for the section selected.
    sections.forEach((eachSection) => {
        currentNodeSections = currentNodeSections && currentNodeSections[eachSection] ? currentNodeSections[eachSection].sections : null;
    });

    // Look for the selected section's children marked as 'isDefaultSection'.
    while (currentNodeSections) {
        let keyFound = null;
        let sectionFound = null;

        Object.entries(currentNodeSections).forEach(([sectionKey, nodeSection]) => {
            if (nodeSection.config && nodeSection.config.isDefaultSection) {
                keyFound = sectionKey;
                sectionFound = nodeSection;
            }
        });

        if (keyFound) {
            childNodes.push(keyFound);
        }
        currentNodeSections = sectionFound ? sectionFound.sections : null;
    }

    return childNodes;
};

/**
 * Returns the default section for the list of sections received as parameter.
 *
 * Returns the first section of the priority list that is enabled. When no section of the priority is enabled, it returns the first section available
 * @param {List<String>} enabledSections
 */
export const getDefaultSection = (enabledSections) => sectionsPriority.find((eachSection) => enabledSections.find((e) => e === eachSection))
        || enabledSections[0];
