import { mdiSwapVerticalBold } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

export default {
    [sectionType.CONTRACT]: {
        isEnabled : false,
        config    : {
            url           : '/panel/upgrade-downgrade',
            icon          : mdiSwapVerticalBold,
            notifications : { getNotification: (data) => ({ hasNotifications: true, notificationsAmount: data?.pagination?.total }) },
        },
        sections: {
            [sectionType.CONTRACT_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.UPGRADES_DOWNGRADES } },
                config    : {
                    isDefaultSection     : true,
                    isHiddenInBreadcrumb : true,
                    updateNotifications  : {
                        sectionToUpdateOnChange : [sectionType.CONTRACT],
                        getNotification         : (data) => ({ hasNotifications: true, notificationsAmount: data?.pagination?.total }),
                    },
                },
            },
            [sectionType.CONTRACT_EDITOR]: {
                isRoot      : true,
                resources   : { templates: { name: resourceNames.UPGRADE_DOWNGRADE } },
                config      : { isHiddenInBreadcrumb: true },
                customProps : { navigateToParent: 'goToRoot', isUpgradeOutsideUserRequest: false, isUpgrade: true },
            },
        },
    },
};
