import React from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import translated from 'Constants/labels/translated';

function CurrentBalance({
    isEditable, bulkActions, items, id, getContractRow, totals, headerActions,
}) {
    const getRowCustomClass = (balance, rowClass) => (balance.isExpired ? `${rowClass} text-color-error` : rowClass);

    return (
        <>
            <Table
                bulkActionsDynamic
                canCollapse
                title={translated.owners.balance.clubPoints.title}
                id={id}
                key={id}
                canCheck={isEditable}
                bulkActions={bulkActions}
                headerActions={headerActions}
                items={items}
                getRowCustomClass={getRowCustomClass}
                className="margin-top-small"
                columns={[
                    {
                        title           : translated.owners.balance.clubPoints.pointsType,
                        key             : 'pointsType',
                        isVisible       : true,
                        showTooltipWhen : 20,
                        customClassName : 'text-overflow',
                    },
                    {
                        title     : translated.owners.balance.clubPoints.leftShoulder,
                        key       : 'shoulderLeft',
                        isVisible : true,
                        isDate    : true,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.date,
                        key       : 'effectiveDate',
                        isVisible : true,
                        isDate    : true,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.expiryDate,
                        key       : 'expiryDate',
                        isVisible : true,
                        isDate    : true,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.rightShoulder,
                        key       : 'shoulderRight',
                        isVisible : true,
                        isDate    : true,
                    },
                    {
                        title       : translated.owners.balance.clubPoints.contract,
                        key         : 'legacyContractId',
                        isVisible   : true,
                        fieldFormat : getContractRow ? (balance) => getContractRow(balance?.legacyContractId, balance?.links?.contracts) : null,
                        isNumeric   : !getContractRow,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.balanceType,
                        key       : 'balanceTypeLevel',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title     : translated.owners.balance.balanceTotals.currency,
                        key       : 'currencyName',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.points,
                        key       : 'amount',
                        isVisible : true,
                        isNumeric : true,
                    },
                    {
                        title     : translated.owners.balance.clubPoints.balance,
                        key       : 'balance',
                        isVisible : true,
                        isNumeric : true,
                    },
                ]}
                rows={{
                    actions  : [],
                    subTable :
                        // For some reason when the table have a subTable but the elements have no child, the balance bulk breaks
                        items?.find((e) => e.children?.length)
                            ? {
                                id      : 'children',
                                key     : 'children',
                                rows    : { actions: [] },
                                columns : [
                                    {
                                        title     : translated.owners.balance.clubPoints.pointsType,
                                        key       : 'id',
                                        isVisible : true,
                                    },
                                    {
                                        title     : translated.owners.balance.clubPoints.points,
                                        key       : 'amount',
                                        isVisible : true,
                                        isNumeric : true,
                                    },
                                    {
                                        title     : translated.owners.balance.clubPoints.balance,
                                        key       : 'balance',
                                        isVisible : true,
                                        isNumeric : true,
                                    },
                                ],
                            }
                            : null,
                }}
            />

            {!!totals?.length && (
                <Table
                    title={translated.owners.balance.balanceTotals.title}
                    className="margin-top-small"
                    canCollapse
                    id={`${id}-totals`}
                    key={`${id}-totals`}
                    items={totals?.sort((first, second) => {
                        const fistCurrency = String(first.label).toLowerCase();
                        const secondCurrency = String(second.label).toLowerCase();

                        if (fistCurrency === secondCurrency) {
                            return first.level < second.level ? -1 : 1;
                        }

                        return fistCurrency < secondCurrency ? -1 : 1;
                    })}
                    columns={[
                        {
                            title           : translated.owners.balance.balanceTotals.currency,
                            key             : 'label',
                            isVisible       : true,
                            showTooltipWhen : 20,
                            customClassName : 'text-overflow',
                        },
                        {
                            title     : translated.owners.balance.balanceTotals.level,
                            key       : 'level',
                            isVisible : true,
                        },
                        {
                            title     : translated.owners.balance.balanceTotals.amount,
                            key       : 'originalAmount',
                            isVisible : true,
                        },
                        {
                            title     : translated.owners.balance.balanceTotals.balance,
                            key       : 'amount',
                            isVisible : true,
                        },
                    ]}
                    rows={{}}
                />
            )}
        </>
    );
}

CurrentBalance.displayName = 'CurrentBalance';

CurrentBalance.defaultProps = {
    isEditable     : false,
    bulkActions    : [],
    headerActions  : [],
    items          : [],
    id             : 'balance',
    getContractRow : null,
    totals         : [],
};

CurrentBalance.propTypes = {
    isEditable     : PropTypes.bool,
    bulkActions    : PropTypes.arrayOf(PropTypes.shape({})),
    headerActions  : PropTypes.arrayOf(PropTypes.shape({})),
    items          : PropTypes.arrayOf(PropTypes.shape({})),
    id             : PropTypes.string,
    getContractRow : PropTypes.func,
    totals         : PropTypes.arrayOf(PropTypes.shape({})),
};

export default React.memo(CurrentBalance);
