import React from 'react';
import PropTypes from 'prop-types';

function Title({ children, isSmall, hidden, className: extraClassName }) {
    const titleClassName = isSmall ? 'h6 text-color-secondary margin-remove' : 'h5 subsection-header-title';
    let className = '';
    if (extraClassName) {
        className = `${className} ${extraClassName}`;
    }
    return (
        <div className={`subsection-header ${className}`}>
            <div className={titleClassName} hidden={hidden}>{children}</div>
        </div>
    );
}

Title.defaultProps = {
    hidden    : false,
    isSmall   : false,
    className : '',
};

Title.propTypes = {
    children  : PropTypes.node.isRequired,
    hidden    : PropTypes.bool,
    isSmall   : PropTypes.bool,
    className : PropTypes.string,
};

export default React.memo(Title);
