import { mdiTagMultipleOutline } from '@mdi/js';
import { sectionType, resourceNames } from '../../constants/types';

const tagId = null;

export default {
    [sectionType.TAGS]: {
        isEnabled : false,
        config    : { url: '/panel/tags', icon: mdiTagMultipleOutline },
        sections  : {
            [sectionType.TAGS_LIST]: {
                isRoot    : true,
                resources : { templates: { name: resourceNames.TAGS } },
                config    : { isDefaultSection: true, isHiddenInBreadcrumb: true },
                sections  : {
                    [sectionType.TAGS_EDITOR]: {
                        isRoot    : true,
                        ids       : { tagId, self: 'tagId' },
                        resources : { templates: { name: resourceNames.TAG } },
                        data      : {},
                        config    : {
                            isModal              : true,
                            isHiddenInBreadcrumb : true,
                            url                  : {
                                withId    : '/(\\d+)',
                                withoutId : '/add',
                            },
                        },
                    },
                },
            },
        },
    },
};
