module.exports = {
    orderKeys: Object.freeze({
        ASC  : 'asc',
        DESC : 'desc',
    }),

    balanceTypes: Object.freeze({
        // The category of the balance types for 'club points'
        clubPointsCategory: 'Club',
    }),

    loginErrors: {
        403 : 'agentDisabled',
        404 : 'agentNotFound',
        500 : 'unexpected',
    },

    requestErrorsWithNoReload: [401, 403, 404],

    // Codes used when cancelling bookings.
    bookingStates: {
        notCancellable           : ['MANUAL_CANCEL', 'NOT_CANCELABLE', 'PAYMENT_PROCESSING_PENDING', 'ONLINE_PAYMENT'],
        cancellableWithRefound   : ['ONLINE_REFUND', 'OFFLINE_REFUND'],
        cancellableWithNoRefound : ['NO_MONEY_MOVE'],
    },

    // Possible club balance types, used when creating a club balance for an owner.
    clubBalanceTypes: {
        PREV    : 'last',
        CURRENT : 'current',
        NEXT    : 'next',
    },

    contractsStatus: {
        ACTIVE    : 'ACTIVE',
        SUSPENDED : 'SUSPENDED',
        EXPIRED   : 'EXPIRED',
    },

    anniversaryDateKey : 'anniversary_date',
    contractAmount     : 'contract_amount',
};
