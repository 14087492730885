import sectionName from 'Constants/labels/sectionName';

/**
 * Provides the functionality to manage a rendering tree, adds default props that will receive sections, and other tasks
 *
 * @prop {string} name                              Name of the section, updated with the name of the entity after a response from the server
 * @prop {object} ids                               Here are defined the ids that this section needs to perform a read or init action
 * @prop {object} data                              The response of the server, data about the entity
 * @prop {object} error                             When the response fails
 *
 * @prop {bool} isEnabled                           Hide the section
 * @prop {bool} isSelected                          Selected sections are the ones that are displayed on screen
 * @prop {bool} isEmbedded                          When a section is embedded inside another, we need to indicate that
 * @prop {bool} shouldReloadData                    When set to true, the section will reload the data (and set back to false)
 *
 * @prop {array} fetching.ids                       Used in tables or when the performed request was fired by a table
 * @prop {bool} fetching.isGlobal                   While performing a fetch is true
 *
 * @prop {object} resources                         Object containing the necessary data to perform requests
 * @prop {string} resources.name                    The name of this resource in the responses and communication with the API
 * @prop {string} resources.current                 The resource that is currently being used in the availables
 * @prop {object} resources.available               All the resources each one with actions inside to perform updates, reads, etc
 * @prop {object} resources.templates               Used to make the requests when there is no available, for example in the first load
 *
 * @prop {string} config.url                        Set the URL, can pass a 'withId' and 'withoutId' to determine 2 urls
 * @prop {string} config.isDefaultSection           When the parent is the last one selected, this section must be selected as well
 * @prop {string} config.isHiddenInBreadcrumb       When selected, this section won't be shown in the Breadcrumb component
 * @prop {string} config.hasTypePreffixInBreadcrumb When selected, will show the section type in breadcrumb, like: 'Rule: Some Rule'
 * @prop {string} config.hasOnlyTypeInBreadcrumb    When selected, will shown only the type in the breadcrumb, like 'Rule'
 * @prop {string} config.isLockedInBreadcrumb       When selected, wont be clickable in breadcrumb
 * @prop {string} config.isHiddenInBreadcrumbTabs   When selected, wont be shown in the breadcrumb tabs
 *
 * @prop {object} sections                          Child of this section (other sections)
 */

export default class RenderingTree {

    static defaultSectionProps = {
        name             : '',
        ids              : {},
        data             : null,
        error            : null,
        isEnabled        : true,
        isSelected       : false,
        isEmbedded       : false,
        isRoot           : false,
        shouldReloadData : false,
        state            : {
            hasNotifications    : false,
            notificationsAmount : 0,
        },
        fetching: {
            ids      : [],
            isGlobal : false,
        },
        resources: {
            current   : 'read',
            available : {},
            templates : { name: '' },
        },
        config: {
            resourceName               : '',
            url                        : '',
            mockedResponse             : null,
            isDefaultSection           : false,
            isHiddenInBreadcrumb       : false,
            hasTypePreffixInBreadcrumb : false,
            hasOnlyTypeInBreadcrumb    : false,
            isLockedInBreadcrumb       : false,
            isHiddenInBreadcrumbTabs   : false,
        },
        sections: {},
    };

    /**
     * Iterates over the sections tree and process each sections props with a function
     *
     * @param {object} tree                     The tree where are the sections
     * @param {function} processSectionProps   A function that will be applied to each section props
     */
    static processEachSection(tree, processSectionProps = (p) => p) {
        const treeRef = tree;
        const currentSectionsTypes = Object.keys(treeRef.sections);
        const amountOfSectionsTypes = currentSectionsTypes.length;
        let i = 0;

        while (i < amountOfSectionsTypes) {
            // Applies the function passed by to make the changes we want in the current section
            treeRef.sections[currentSectionsTypes[i]] = processSectionProps(treeRef.sections[currentSectionsTypes[i]], currentSectionsTypes[i]);

            if (treeRef.sections[currentSectionsTypes[i]]) {
                treeRef.sections[currentSectionsTypes[i]] = this.processEachSection(treeRef.sections[currentSectionsTypes[i]], processSectionProps);
                i++;
            }
        }

        return tree;
    }

    static addDefaultResourceName(tree) {
        return this.processEachSection(tree, (sectionProps, name) => {
            const newSectionProps = sectionProps.resources.name ? sectionProps : { ...sectionProps, resources: { ...sectionProps.resources, name } };
            return newSectionProps.resources.templates.name
                ? newSectionProps
                : { ...newSectionProps, resources: { ...sectionProps.resources, templates: { ...sectionProps.resources.templates, name } } };
        });
    }

    static addDefaultSectionProps(tree) {
        return this.processEachSection(tree, (sectionProps, sectionType) => ({
            ...this.defaultSectionProps,
            name: sectionName[sectionType],
            ...sectionProps,
        }));
    }

    static addTemplates(tree, templates) {
        // You can avoid the resource.name if the returned resource from the API is equal to the sectionType on the store
        return this.processEachSection(tree, (sectionProps, sectionType) => {
            const resourceKey = sectionProps.resources.templates.name || sectionType;
            return templates[resourceKey]
                ? {
                    ...sectionProps,
                    resources: { ...sectionProps.resources, templates: { ...sectionProps.resources.templates, ...templates[resourceKey] } },
                }
                : sectionProps;
        });
    }

    static addIds(tree, idsList) {
        // Adds in each section the corresponding ID according to what is already setted inside the initial store
        return this.processEachSection(tree, (sectionProps) => {
            const newSectionProps = { ...sectionProps };

            // The key self indicates which id is for that section
            Object.keys(sectionProps.ids).forEach((idType) => {
                if (idType !== 'self') {
                    newSectionProps.ids[idType] = idsList[idType];
                }
            });

            return newSectionProps;
        });
    }

    static findSelectedAndEnabledSection(section, shouldAvoidEmbeddedSection) {
        return Object.keys(section).find(
            (k) => section[k].isSelected
                && !section[k].isHidden
                && section[k].isEnabled
                && ((shouldAvoidEmbeddedSection && !section[k].isEmbedded) || !shouldAvoidEmbeddedSection),
        );
    }

    static isLastSectionNotEmbedded(sections, path) {
        if (!sections || !path || !path.length) {
            return true;
        }

        let currentSection = sections;
        path.forEach((eachSection, index) => {
            if (currentSection?.[eachSection]) {
                currentSection = currentSection[eachSection];
                if (currentSection && index < path.length - 1) {
                    currentSection = currentSection.sections;
                }
            }
        });

        if (!currentSection) {
            return true;
        }

        return !currentSection.isEmbedded;
    }

}
