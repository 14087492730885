module.exports = Object.freeze(
    {
        title  : 'apf.currencies.title',
        empty  : 'apf.currencies.empty',
        name   : 'apf.currencies.name',
        linear : 'apf.currencies.linear',
        code   : 'apf.currencies.code',
        editor : {
            viewTitle         : 'apf.currencies.editor.viewTitle',
            editTitle         : 'apf.currencies.editor.editTitle',
            information       : 'apf.currencies.editor.information',
            rates             : 'apf.currencies.editor.rates',
            from              : 'apf.currencies.editor.from',
            to                : 'apf.currencies.editor.to',
            value             : 'apf.currencies.editor.value',
            type              : 'apf.currencies.editor.type',
            types             : { perStay: 'apf.currencies.editor.types.perStay', perNight: 'apf.currencies.editor.types.perNight' },
            addRateSuccess    : 'apf.currencies.editor.addRateSuccess',
            removeRateSuccess : 'apf.currencies.editor.removeRateSuccess',
            editRateSuccess   : 'apf.currencies.editor.editRateSuccess',
            errors            : {
                ratesInitError  : 'apf.currencies.editor.errors.ratesInitError',
                addRateError    : 'apf.currencies.editor.errors.addRateError',
                removeRateError : 'apf.currencies.editor.errors.removeRateError',
                editRateError   : 'apf.currencies.editor.errors.editRateError',
                repeatedRate    : 'apf.currencies.editor.errors.repeatedRate',
            },
        },
    },
);
