import owners from './owners';
import clubs from './clubs';
import partners from './partners';
import contracts from './contracts';
import reservations from './reservations';
import tiers from './tiers';
import balanceTypes from './balanceTypes';
import externalClubs from './externalClubs';
import attachmentCategories from './attachmentCategories';
import agents from './agents';
import roles from './roles';
import extraAttributes from './extraAttributes';
import tags from './tags';
import propertyGroups from './propertyGroups';
import properties from './properties';
import systemRules from './systemRules';
import currencies from './currencies';
import documentation from './documentation';
import accountStatement from './accountStatement';

export default {
    owners,
    clubs,
    partners,
    contracts,
    reservations,
    tiers,
    balanceTypes,
    externalClubs,
    attachmentCategories,
    agents,
    roles,
    extraAttributes,
    tags,
    propertyGroups,
    properties,
    systemRules,
    currencies,
    documentation,
    accountStatement,
};
