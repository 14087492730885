import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Modal from 'Components/Modal';
import Grid from 'Components/Grid';

function FinancialStatus({ data, onClose }) {
    const current = data.find((e) => e.label === 'current')?.attributes;
    const next = data.find((e) => e.label === 'next')?.attributes;
    const information = data.find((e) => e.label === 'information')?.attributes;

    let bookingEnable = 'YES';
    if (information?.noReservationAllowed) {
        bookingEnable = 'NO';
    }

    return (
        <Modal
            title={translated.owners.financialStatus.title}
            closeButton={{
                text    : translated.global.buttons.close,
                onClick : onClose,
            }}
            className="financial-status-modal"
        >
            <Grid childWidth={{ base: 1, small: 2 }} gutter="medium" className="margin-top-small">
                <div className="margin-bottom-small">
                    <div className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                        <FormattedMessage
                            id={translated.owners.financialStatus.currentYear}
                            defaultMessage={translated.owners.financialStatus.currentYear}
                        />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.owners.financialStatus.fees}
                                        defaultMessage={translated.owners.financialStatus.fees}
                                    />
                                </td>
                                <td className="details-data text-align-right">{current?.fees?.amount}</td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.owners.financialStatus.loans}
                                        defaultMessage={translated.owners.financialStatus.loans}
                                    />
                                </td>
                                <td className="details-data text-align-right">{current?.loans?.amount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <div className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                        <FormattedMessage id={translated.owners.financialStatus.nextYear} defaultMessage={translated.owners.financialStatus.nextYear} />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.owners.financialStatus.fees}
                                        defaultMessage={translated.owners.financialStatus.fees}
                                    />
                                </td>
                                <td className="details-data text-align-right">{next?.fees?.amount}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table className="padding-left">
                                        <tbody>
                                            <tr>
                                                <td className="details-label">
                                                    <FormattedMessage
                                                        id={translated.owners.financialStatus.currentYear}
                                                        defaultMessage={translated.owners.financialStatus.currentYear}
                                                    />
                                                </td>
                                                <td className="details-data text-align-right">{next?.fees?.attributes?.current}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">
                                                    <FormattedMessage
                                                        id={translated.owners.financialStatus.nextYear}
                                                        defaultMessage={translated.owners.financialStatus.nextYear}
                                                    />
                                                </td>
                                                <td className="details-data text-align-right">{next?.fees?.attributes?.next}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.owners.financialStatus.loans}
                                        defaultMessage={translated.owners.financialStatus.loans}
                                    />
                                </td>
                                <td className="details-data text-align-right">{next?.loans?.amount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Grid>
            {information ? (
                <div>
                    <div className="font-weight-bold text-transform-uppercase text-color-secondary margin-bottom-small">
                        <FormattedMessage id={translated.owners.financialStatus.noReservationAllowed} defaultMessage={translated.owners.financialStatus.noReservationAllowed} />
                    </div>
                    {information.noReservationAllowed ? (
                        <div className="status status-error margin-left-small">
                            {bookingEnable}
                        </div>
                    ) : (
                        <div className="status status-enabled margin-left-small">
                            {bookingEnable}
                        </div>
                    )}
                </div>
            ) : (undefined)}
        </Modal>
    );
}

FinancialStatus.propTypes = {
    data    : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClose : PropTypes.func.isRequired,
};

export default FinancialStatus;
