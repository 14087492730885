import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';
import Icon from '@mdi/react';
import Tooltip from 'Components/Tooltip';
import { useIntl } from 'react-intl';

function MainMenuItem({
    section,
    selectSection,
    name,
    isSelected,
    isEnabled,
    icon,
    hasNotifications,
    notifications,
    toggleSideDrawerVisibility,
    id,
    defaultSection,
}) {
    const intl = useIntl();
    const ref = useRef(null);

    const tooltipId = Tooltip.generateId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const itemName = useMemo(() => intl.formatMessage({ id: name, defaultMessage: name }), [name]);

    const handleOnClick = () => {
        selectSection([section], [{ with: { shouldReloadData: true }, on: defaultSection || `${section}List` }]);

        // If the SideDrawer is visible it has 'show-menu' class and we need to hide it
        if (document.getElementsByClassName('show-menu').length) {
            toggleSideDrawerVisibility();
        }

        const buttonClicked = new CustomEvent('ButtonClicked', {
            bubbles    : true,
            cancelable : true,
        });
        ref.current.dispatchEvent(buttonClicked);
    };

    return (
        <li
            id={id}
            className={getClassName({ isSelected, isDisabled: !isEnabled }, 'main-menu-item animated fade-in-left fastest')}
            onClick={handleOnClick}
            key={section}
            data-tip={itemName}
            data-for={tooltipId}
            ref={ref}
        >
            <Icon className="mdi-icon" path={icon} size="20px" />
            <span>{itemName}</span>
            {hasNotifications && notifications > 0 && <div className="main-menu-item-notification">{notifications}</div>}
        </li>
    );
}

MainMenuItem.defaultProps = { notifications: null, defaultSection: '' };

MainMenuItem.propTypes = {
    id                         : PropTypes.string.isRequired,
    section                    : PropTypes.string.isRequired,
    selectSection              : PropTypes.func.isRequired,
    toggleSideDrawerVisibility : PropTypes.func.isRequired,
    name                       : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    isSelected                 : PropTypes.bool.isRequired,
    isEnabled                  : PropTypes.bool.isRequired,
    hasNotifications           : PropTypes.bool.isRequired,
    notifications              : PropTypes.number,
    icon                       : PropTypes.string.isRequired,
    defaultSection             : PropTypes.string,
};

export default MainMenuItem;
