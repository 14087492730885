import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import Button from 'Components/Button';
import Table from 'Components/Table';
import Alert from 'Components/Alert';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import withRequest from 'Components/Sections/withRequest';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';

const getAllowedPoints = (points) => points?.map((each) => each?.name)?.join() || '';

const getItems = (data) => data?.map((each) => ({ ...each, club: each?.externalClub?.name, allowedPoints: getAllowedPoints(each.allowedPoints) }));

export function List({ sections, data, isEmbedded, resources, sectionTitle, isEditEnabled }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);

    const handleClickChangeStatus = async (wallet, enable) => {
        const { id, links: walletLinks } = wallet;

        const reqConfig = {
            ...walletLinks.self.enable,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, isEmbedded, section: sectionType.CONVERSION_RULES, resources });

            snackbar.show({
                content   : enable ? translated.conversionRules.enable.success : translated.conversionRules.disable.success,
                isSuccess : true,
            });
        } catch (error) {
            snackbar.show({
                content : enable ? translated.conversionRules.enable.error : translated.conversionRules.disable.error,
                isError : true,
                error,
            });
        }
    };

    const addConversionRule = () => {
        navigator.goToConversionRuleEditor({
            available : data.links.self,
            section   : sectionType.CONVERSION_RULES,
            current   : 'init',
            isEmbedded,
        });
    };

    const sectionsButtons = (data?.links?.self?.init && data.links.self.create && data.data?.length && isEditEnabled)
        ? [
            <Button id="conversion-rule-add-button" variant="outlined" color="primary" onClick={addConversionRule} key="add_associated">
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    const { Section, props: subsecProps } = Sections.get(sections);

    return (
        <>
            {Section && (
                <Section
                    {...subsecProps}
                    onClose={() => navigator.goToParentAndReload(true, false)}
                    clubsInUse={data?.data?.map((each) => each?.externalClub?.id)}
                />
            )}
            <Subsection title={sectionTitle || translated.conversionRules.sectionTitle} className="conversion-subsection" actionButtons={sectionsButtons}>
                <Table
                    id="conversion-rules"
                    key="conversion-rules"
                    canChangeSettings
                    canViewColumns
                    columns={[
                        {
                            title     : translated.conversionRules.conversionRate,
                            key       : 'ratio',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversionRules.conversionFee,
                            key       : 'fee',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversionRules.club,
                            key       : 'club',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversionRules.minValue,
                            key       : 'minimum',
                            isVisible : true,
                        },
                        {
                            title     : translated.conversionRules.allowedPoints,
                            key       : 'allowedPoints',
                            isVisible : true,
                        },
                        {
                            title    : translated.global.status,
                            key      : 'isActive',
                            whenTrue : {
                                text      : translated.global.state.enabled,
                                className : 'status status-enabled',
                            },
                            whenFalse: {
                                text      : translated.global.state.disabled,
                                className : 'status status-disabled',
                            },
                            isVisible: true,
                        },
                    ]}
                    rows={{
                        actions: isEditEnabled ? [
                            {
                                content  : translated.global.buttons.disable,
                                callback : (wallet) => handleClickChangeStatus(wallet, false),
                                key      : 'disable',
                                when     : (wallet) => wallet.isActive && wallet.links?.self?.enable,
                            },
                            {
                                content  : translated.global.buttons.enable,
                                callback : (wallet) => handleClickChangeStatus(wallet, true),
                                key      : 'enable',
                                when     : (wallet) => !wallet.isActive && wallet.links?.self?.enable,
                            },
                            {
                                content  : translated.global.buttons.delete,
                                when     : (rule) => rule?.links?.self?.delete,
                                callback : async (wallet) => {
                                    const { id } = wallet;
                                    confirmation.show({
                                        message       : translated.conversionRules.delete.title,
                                        acceptMessage : translated.global.buttons.delete,
                                        onAccept      : async () => {
                                            const reqConfig = {
                                                ...wallet.links.self.delete,
                                                shouldReloadData : true,
                                                isCritical       : false,
                                                isGlobal         : false,
                                                ids              : [id],
                                            };
                                            try {
                                                await navigator.requestForCurrentPath({
                                                    reqConfig,
                                                    isEmbedded,
                                                    section: sectionType.CONVERSION_RULES,
                                                    resources,
                                                });

                                                snackbar.show({
                                                    content   : translated.conversionRules.delete.success,
                                                    isSuccess : true,
                                                });
                                            } catch (error) {
                                                snackbar.show({
                                                    content : translated.conversionRules.delete.error,
                                                    isError : true,
                                                    error,
                                                });
                                            }
                                        },
                                    });
                                },
                            },
                            {
                                content  : translated.global.buttons.edit,
                                callback : (wallet) => {
                                    navigator.goToConversionRuleEditor({
                                        available : { ...wallet.links.self },
                                        section   : sectionType.CONVERSION_RULES,
                                        current   : 'read',
                                        isEmbedded,
                                    });
                                },
                                key               : 'edit',
                                isOutsideDropdown : true,
                                icon              : 'Pencil',
                                tooltip           : translated.global.buttons.edit,
                                when              : (wallet) => wallet?.links?.self?.update,
                            },
                        ] : [],
                    }}
                    items={getItems(data.data)}
                    onSearch={() => {
                        // Default
                    }}
                    whenEmpty={(
                        <Alert
                            id="conversion-rules-empty"
                            buttonId="conversion-rules-add-button"
                            content={translated.conversionRules.noWalletsConfig}
                            actionText={data?.links?.self?.init && data?.links?.self?.create && isEditEnabled ? translated.global.buttons.add : null}
                            onClick={data?.links?.self?.init && data?.links?.self?.create && isEditEnabled ? addConversionRule : null}
                        />
                    )}
                />
            </Subsection>
        </>
    );
}

List.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }}>
                <Skeleton.Table />
            </Grid.Column>
        </Grid>
    );
};

List.defaultProps = {
    isEmbedded      : false,
    sectionTitle    : null,
    parentResources : null,
    parentPath      : null,
    isEditEnabled   : true,
};

List.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    sections        : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    isEmbedded      : PropTypes.bool,
    sectionTitle    : PropTypes.string,
    parentResources : PropTypes.shape({}),
    parentPath      : PropTypes.arrayOf(PropTypes.string),
    isEditEnabled   : PropTypes.bool,
};

export default withRequest(List);
