/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import Form, { FormError } from 'Components/Form';
import PropTypes from 'prop-types';
import translated from 'Constants/labels/translated';
import Subsection from 'Components/Sections/Subsection';
import SectionsWrapper from 'Components/Sections/Wrapper';
import BankingConfigurationList from 'Components/Sections/Shared/BankingConfigurations/ListWithoutRequest';
import { sectionType } from 'Constants/types';
// eslint-disable-next-line import/no-cycle
import { Collection } from 'Components/Sections/Collection';
// eslint-disable-next-line import/no-cycle
import Sections from 'Components/Sections';
import Alert from 'Components/Alert';
import Icon from '@mdi/react';
import IconPath from 'Utils/IconsPath';
import convertBankingConfigurations from './utils';
import History from './History';
import WalletTransactions from './WalletTransactions';
import { contractsStatus, anniversaryDateKey, contractAmount } from 'Constants/translator';
import { validateDynamicInputs, transformDynamicInputs } from 'Components/Form/DynamicInputWrapperHelper';
import { clubDuration } from 'Constants/global';

const convertTiers = (list) => (list?.length
    ? list.map((e) => ({
        ...e,
        content : e?.tier?.name,
        value   : e.id,
    }))
    : []);

const convertContractTypes = (list) => (list?.length
    ? list.map((e) => ({
        ...e,
        content : e.name,
        value   : e.id,
    }))
    : []);

const validateDates = (begin, end, dateManager) => {
    const beginDate = dateManager.create(begin);
    const endDate = dateManager.create(end);
    return dateManager.isValidDate(beginDate) && dateManager.isValidDate(endDate) && dateManager.isSameOrBefore(beginDate, endDate);
};

const validateMinMaxDates = (begin, value, end, dateManager) => {
    const beginDate = dateManager.create(begin);
    const date = dateManager.create(value);
    const endDate = dateManager.create(end);
    return dateManager.isValidDate(date)
        && (!beginDate || !dateManager.isValidDate(beginDate) || dateManager.isSameOrBefore(beginDate, date))
        && (!end || !dateManager.isValidDate(endDate) || dateManager.isSameOrBefore(date, endDate));
};

const getDatesExtraInfo = (minDate, maxDate, intl) => (
    <div>
        <span>
            {intl.formatMessage({
                id             : translated.global.feb29Warn,
                defaultMessage : translated.global.feb29Warn,
            })}
        </span>
        <br />
        {minDate ? (
            <span>
                {intl.formatMessage(
                    {
                        id             : translated.global.validMinDate,
                        defaultMessage : translated.global.validMinDate,
                    },
                    { date: minDate },
                )}
            </span>
        ) : null}
        {minDate ? <br /> : null}
        {maxDate ? (
            <span>
                <FormattedMessage defaultMessage={translated.global.validMaxDate} id={translated.global.validMaxDate} values={{ date: maxDate }} />
            </span>
        ) : null}
    </div>
);

function Editor({
    data, resources, sections, isEditing, isUpgrade, isReadOnly, isUpgradeOutsideUserRequest, onFinish, isPartner, navigateToParent,
}) {
    const intl = useIntl();
    const { navigator, snackbar, dateManager } = useContext(PanelContext);
    const { links, additionalResources, history = null } = data;

    const bankingConfigurationsRef = useRef();
    const walletTransactionsRef = useRef();
    const isGenerated = useRef(false);
    // Used to force the extraAttributes input to update the amount value.
    const [forceAmountUpdate, setForceAmountUpdate] = useState(-1);

    const contract = isUpgrade && !isUpgradeOutsideUserRequest ? data?.contract : data;
    const clubTier = contract?.contractType?.clubTier;

    const club = { content: clubTier?.club?.name, value: clubTier?.club?.id, links: clubTier?.club?.links };
    const tier = { content: clubTier?.tier?.name, value: clubTier?.tier?.id, links: clubTier?.tier?.links };
    const contractType = { content: contract?.contractType?.name, value: contract?.contractType?.id, links: contract?.contractType?.links };
    const primaryCurrency = { content: contract?.primaryCurrency?.name, value: contract?.primaryCurrency?.id };
    const secondaryCurrency = { content: contract?.secondaryCurrency?.name, value: contract?.secondaryCurrency?.id };
    const availableCurrencies = additionalResources?.currencies?.map((e) => ({ content: e.name, value: e.id })) || [];
    const availableExtraAttributes = additionalResources?.extraAttributes || [];
    const balances = additionalResources?.balances;

    // Needed for some avc custom functionality
    const isAvcClient = window.env.CLIENT_NAME === 'AVC';
    const isNotOnReadOnlyDate = isAvcClient && !!isUpgrade;

    const consumptionModes = resources?.additional?.consumptionModes.map((e) => ({ id: e.id, value: e.id, content: e.code }));
    const selectedPath = isPartner
        ? [sectionType.PARTNERS, sectionType.PARTNERS_DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR]
        : [sectionType.OWNERS, sectionType.DETAILS, sectionType.CLIENT_CONTRACTS_EDITOR];

    const navigateToRuleEditor = isPartner ? 'goToPartnerContractRuleEditor' : 'goToOwnerContractRuleEditor';

    const convertExtraAttributes = (attributes) => {
        if (!attributes) {
            return null;
        }

        const convertedAttributes = { ...attributes };
        // Special rules for AVC client
        if (isAvcClient && isUpgrade) {
            Object.keys(convertedAttributes).forEach((e) => {
                if (convertedAttributes[e].slug === anniversaryDateKey || convertedAttributes[e].slug === contractAmount) {
                    convertedAttributes[e].isDisabled = true;
                }
            });
        }

        return convertedAttributes;
    };

    const [newContract, setNewContract] = useState({
        searchingClubs : true,
        allClubs       : !isEditing ? [] : [{ value: clubTier?.club?.id, content: clubTier?.club?.name }],
        selectedClub   : !isEditing ? null : { value: clubTier?.club?.id },
        allTiers       : !isEditing ? [] : [{ value: clubTier?.tier?.id, content: clubTier?.tier?.name }],
        selectedTier   : !isEditing
            ? null
            : {
                id            : clubTier?.tier?.id,
                value         : clubTier?.tier?.id,
                contractTypes : [{ value: contract?.contractType?.id, content: contract?.contractType?.name }],
                links         : clubTier?.tier?.links,
            },
        selectedContractType   : !isEditing ? null : { value: contract?.contractType?.id },
        searchingTiers         : false,
        searchingContractTypes : false,
        begin                  : contract?.begin || null,
        end                    : contract?.end || null,
        endMinDate             : contract?.begin ? dateManager.getNextDayFormatted(contract?.begin) : null,
        areDatesValid          : true,
        highlight              : contract.highlight || false,
        extraAttributes        : convertExtraAttributes(contract?.extraAttributes) || null,
    });

    const [walletTransactions, setWalletTransactions] = useState([]);
    const [bankingConfigurationsChanged, setBankingConfigurationsChanged] = useState(false);
    const [bankingConfigurations, setBankingConfigurations] = useState(
        contract?.bankingConfigurations?.map((e) => ({
            ...convertBankingConfigurations(e),
            balanceType: { id: e.balanceType?.id },
        })) || [],
    );

    const [currencies, setCurrencies] = useState({
        primaryCurrency        : contract?.primaryCurrency,
        primaryCurrencyDelta   : contract?.primaryCurrencyDelta,
        secondaryCurrency      : contract?.secondaryCurrency,
        secondaryCurrencyDelta : contract?.secondaryCurrencyDelta,
    });

    const RuleList = Collection[sectionType.RULES];

    const ConversionRules = Collection[sectionType.CONVERSION_RULES];
    const ConversionRulesAlt = Collection[sectionType.CONVERSION_RULES_ALT];

    const BankingConfigurations = Collection[sectionType.BANKING_CONFIGURATIONS];

    const checkBalanceTypesAndWalletTransactions = () => {
        if (isGenerated.current) {
            if (bankingConfigurationsRef?.current?.updateElements) {
                bankingConfigurationsRef.current.updateElements([]);
            }

            if (walletTransactionsRef?.current?.updateElements) {
                walletTransactionsRef.current.updateElements([]);
            }

            isGenerated.current = false;
        }
    };

    const handleOnChangeBeginDate = useCallback((inputValue) => {
        if (dateManager.isValidDate(inputValue)) {
            const newMinDate = dateManager.getNextDayFormatted(inputValue);

            setNewContract((prev) => ({
                ...prev,
                begin      : inputValue,
                endMinDate : newMinDate,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeEndDate = useCallback((inputValue) => {
        checkBalanceTypesAndWalletTransactions();

        setNewContract((prev) => ({
            ...prev,
            end: inputValue,
        }));
    }, []);

    const searchTierContractType = useCallback(
        async (tierId) => {
            if (!tierId) {
                return;
            }

            const link = newContract?.allTiers.find((e) => e.id === tierId)?.links?.contractTypes?.read;

            if (!link) {
                snackbar.show({ content: translated.owners.contracts.edition.contractTypeSearchError, isError: true });
                return;
            }

            setNewContract((previous) => ({
                ...previous,
                searchingContractTypes: true,
            }));

            try {
                const contractTypes = await navigator.directRequest(link);

                const convertedContractTypes = convertContractTypes(contractTypes);

                setNewContract((previous) => {
                    const newTiers = previous.allTiers.map((e) => ({
                        ...e,
                        contractTypes: e.id !== tierId ? e.contractTypes : convertedContractTypes,
                    }));

                    return {
                        ...previous,
                        searchingContractTypes : false,
                        allTiers               : newTiers,
                        selectedTier           : newTiers.find((e) => e.id === tierId),
                    };
                });
            } catch (error) {
                snackbar.show({ error, content: translated.owners.contracts.edition.contractTypeSearchError, isError: true });
            }
        },
        [navigator, newContract, snackbar],
    );

    const searchClubTiers = useCallback(
        async (searchClub) => {
            const link = searchClub?.links?.tiers?.read;

            if (!link) {
                snackbar.show({ content: translated.owners.contracts.edition.tierSearchError, isError: true });
                return;
            }
            try {
                const tiers = await navigator.directRequest(link);

                const convertedTiers = convertTiers(tiers);

                setNewContract((previous) => {
                    const allClubs = previous.allClubs.map((eachClub) => {
                        if (eachClub.id === searchClub.id) {
                            return { ...eachClub, tiers: convertedTiers };
                        }
                        return { ...eachClub };
                    });

                    return {
                        ...previous,
                        allClubs,
                        searchingTiers       : false,
                        selectedClub         : allClubs.find((eachClub) => eachClub.id === searchClub.id),
                        selectedTier         : null,
                        selectedContractType : null,
                        allTiers             : convertedTiers,
                    };
                });
            } catch (error) {
                snackbar.show({ error, content: translated.owners.contracts.edition.tierSearchError, isError: true });
            }
        },
        [navigator, snackbar],
    );

    const setupClubs = useCallback(async () => {
        try {
            const clubs = await navigator.directRequest(data.additionalResources.club.read);

            const clubsConverted = clubs.map((e) => ({
                ...e,
                content : e?.name,
                value   : e.id,
            }));

            const selectedClub = clubsConverted.find(({ value }) => value === clubTier?.club?.id);

            let allTiers = [];
            let selectedTier = null;
            let selectedContractType = null;

            if (contract?.contractType?.clubTier?.tier?.id && selectedClub?.links?.tiers?.read) {
                const tiers = await navigator.directRequest(selectedClub.links.tiers.read);

                allTiers = convertTiers(tiers);
                selectedTier = allTiers.find((e) => e.tier?.id === contract.contractType.clubTier.tier.id);

                if (contract?.contractType?.id && selectedTier?.links?.contractTypes?.read) {
                    const contractTypes = await navigator.directRequest(selectedTier.links.contractTypes.read);
                    selectedTier.contractTypes = convertContractTypes(contractTypes);
                    selectedContractType = selectedTier.contractTypes.find((e) => e.id === contract.contractType.id);
                }
            }

            setNewContract((previous) => ({
                ...previous,
                allClubs       : clubsConverted,
                allTiers,
                selectedClub,
                selectedTier,
                selectedContractType,
                searchingClubs : false,
                searchingTiers : false,
            }));
        } catch (error) {
            snackbar.show({ error, content: translated.owners.contracts.edition.clubSearchError, isError: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (!isEditing || isUpgrade) {
            setupClubs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = async ({ values }) => {
        if (!values.contractType) {
            throw new FormError(translated.owners.contracts.edition.requiredContractType);
        }

        if (!newContract?.selectedTier?.value) {
            throw new FormError(translated.owners.contracts.edition.requiredTier);
        }

        validateDynamicInputs(values.extraAttributes, dateManager);

        if (bankingConfigurations?.length) {
            bankingConfigurations.forEach((e, index) => {
                if (!e.balanceType?.id || (e.repeat && !e.repeatAnniversary) || (!e.repeat && (!e.effectiveDate || !e.expiryDate)) || e.amount == null) {
                    const content = (
                        <FormattedMessage
                            defaultMessage={translated.owners.contracts.edition.bankingConfigurationMissingFields}
                            id={translated.owners.contracts.edition.bankingConfigurationMissingFields}
                            values={{ number: index + 1 }}
                        />
                    );
                    throw new FormError(content);
                }

                if (
                    !e.repeat
                    && (!dateManager.isBetweenEqual(e.effectiveDate, values.begin, values.end, 'days')
                        || !dateManager.isBetweenEqual(e.expiryDate, e.effectiveDate, values.end, 'days'))
                ) {
                    const content = (
                        <FormattedMessage
                            defaultMessage={translated.owners.contracts.edition.bankingConfigurationDateValidation}
                            id={translated.owners.contracts.edition.bankingConfigurationDateValidation}
                            values={{ number: index + 1 }}
                        />
                    );
                    throw new FormError(content);
                }
            });
        }

        let walletManagementTransactions;
        if (isUpgrade) {
            walletManagementTransactions = {};
            walletManagementTransactions.balances = walletTransactions.map(({ id, transactionValue }) => ({ id, amount: transactionValue }));
        }

        const reqConfig = {
            data: {
                ...values,
                walletManagementTransactions,
                bankingConfigurations : bankingConfigurations.map((each) => convertBankingConfigurations(each)),
                base                  : isUpgrade ? contract?.base : undefined,
            },
            ...(links?.self?.upgrade || contract?.links?.self?.upgrade || resources?.available?.create || links?.contracts?.create || links?.self?.create),
        };

        delete reqConfig.data.undefined;

        try {
            await navigator.requestForCurrentPath({ reqConfig, resources });

            const link = links?.self?.patch;

            if (isUpgrade && !isUpgradeOutsideUserRequest && link) {
                try {
                    await navigator.directRequest({
                        ...link,
                        data             : { isProcessed: true },
                        shouldReloadData : true,
                        isCritical       : false,
                        isGlobal         : false,
                    });
                } catch (error) {
                    snackbar.show({
                        content : translated.upgrades.markProcessed.error,
                        error,
                        isError : true,
                    });
                }
            }
        } catch (e) {
            throw new FormError(translated.owners.contracts.edition.saveError, e, translated.owners.contracts.edition.errors);
        }
    };

    const onClose = async () => {
        if (onFinish) {
            onFinish();
            return;
        }

        if (navigateToParent) {
            navigator[navigateToParent]();
            return;
        }

        navigator.goToParentAndReload(true, false);
    };

    const onContractChange = ({ values }) => {
        setNewContract((prev) => {
            const { endMinDate = null } = prev;

            const contractDateRange = prev?.selectedClub?.dateRange;

            const beginMin = contractDateRange?.begin;
            const beginMax = contractDateRange?.end;
            const endMin = endMinDate && dateManager.isValidDate(endMinDate) ? endMinDate : contractDateRange?.begin;
            const endMax = contractDateRange?.end;

            const areDatesValid = validateDates(values.begin, values.end, dateManager)
                && ((isAvcClient && isUpgrade) || (!beginMin && !beginMax) || validateMinMaxDates(beginMin, values.begin, beginMax, dateManager))
                && ((!endMin && !endMax) || validateMinMaxDates(endMin, values.end, endMax, dateManager));

            return {
                ...prev,
                areDatesValid,
            };
        });
    };

    const handleOnClubChange = useCallback(
        (newClubId) => {
            if (!newClubId || newClubId === newContract?.selectedClub?.id) {
                return;
            }

            checkBalanceTypesAndWalletTransactions();

            const selectedClub = newContract?.allClubs?.find((e) => e.id === newClubId);

            if (selectedClub?.tiers) {
                setNewContract((previous) => ({
                    ...previous,
                    selectedClub,
                    selectedTier         : null,
                    selectedContractType : null,
                    allTiers             : selectedClub.tiers,
                }));

                return;
            }

            setNewContract((previous) => ({
                ...previous,
                selectedClub,
                selectedTier         : null,
                selectedContractType : null,
                allTiers             : [],
            }));

            searchClubTiers(selectedClub);
        },
        [newContract, searchClubTiers],
    );

    const handleOnTierChange = useCallback(
        (newTierId = null) => {
            const selectedTier = newContract?.allTiers?.find((e) => e.id === newTierId);

            if (selectedTier?.contractTypes) {
                setNewContract((previous) => ({
                    ...previous,
                    selectedTier,
                }));

                return;
            }

            checkBalanceTypesAndWalletTransactions();

            setNewContract((previous) => ({
                ...previous,
                selectedTier,
                selectedContractType: null,
            }));

            if (selectedTier) {
                searchTierContractType(newTierId);
            }
        },
        [newContract, searchTierContractType],
    );

    const handleOnContractTypeChange = useCallback(
        (newContractTypeId) => {
            checkBalanceTypesAndWalletTransactions();

            const selectedContractType = newContract?.selectedTier?.contractTypes?.find((e) => e.id === newContractTypeId);

            setNewContract((previous) => ({
                ...previous,
                selectedContractType,
            }));

            setCurrencies({
                primaryCurrency        : selectedContractType?.primaryCurrency,
                primaryCurrencyDelta   : selectedContractType?.primaryCurrencyDelta,
                secondaryCurrency      : selectedContractType?.secondaryCurrency,
                secondaryCurrencyDelta : selectedContractType?.secondaryCurrencyDelta,
            });
        },
        [newContract],
    );

    const handleOnSubmitExtraAttributes = async ({ values }) => {
        const { extraAttributes } = values;
        validateDynamicInputs(extraAttributes, dateManager);

        const link = links?.self?.patch;
        const reqConfig = {
            data: {
                isActive: contract.isActive,
                extraAttributes,
            },
            ...link,
        };

        try {
            const response = await navigator.directRequest(reqConfig);

            snackbar.show({ content: translated.owners.contracts.edition.extraAttributes.success, isSuccess: true });

            // We update the contract extra attributes to fix a bug with the section reload. (it would show the old extra attributes)
            setNewContract((prev) => ({ ...prev, extraAttributes: response.extraAttributes }));
        } catch (e) {
            snackbar.show({
                content : translated.owners.contracts.edition.extraAttributes.error,
                isError : true,
                e,
            });

            throw e;
        }
    };

    // Test fails when using Sections.get -.-
    const { Section, props: subsecProps } = Sections.Selected.get(sections);

    if (Section) {
        return <Section {...subsecProps} onClose={() => navigator.goToParentAndReload(true, false)} />;
    }

    const isContractEdition = isEditing && !isUpgrade;

    const buttons = isContractEdition || isReadOnly
        ? [
            <Form.Secondary id="contract-cancel-button" variant="text" color="primary" key="fs" onClick={onClose}>
                <FormattedMessage defaultMessage={translated.global.buttons.back} id={translated.global.buttons.back} />
            </Form.Secondary>,
        ]
        : [
            <Form.Secondary id="contract-cancel-button" variant="text" color="primary" key="fs" onClick={onClose}>
                <FormattedMessage defaultMessage={translated.global.buttons.cancel} id={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary id="contract-save-button" color="primary" key="fp">
                {isUpgrade ? (
                    <FormattedMessage defaultMessage={translated.global.buttons.save} id={translated.global.buttons.save} />
                ) : (
                    <FormattedMessage defaultMessage={translated.global.buttons.create} id={translated.global.buttons.create} />
                )}
            </Form.Primary>,
        ];

    let beginMin;
    let beginMax;
    let endMin;
    let endMax;
    if (newContract?.selectedClub?.clubDuration === clubDuration.FIXED) {
        if (dateManager.isValidDate(newContract?.begin)) {
            endMin = dateManager.format(dateManager.add(newContract.begin, 1, 'days'), 'yyyy-MM-dd');
            endMax = dateManager.format(
                dateManager.sub(
                    dateManager.add(newContract.begin, newContract.selectedClub.duration, 'months'),
                    1,
                    'days',
                ),
                'yyyy-MM-dd',
            );
        }
    } else {
        beginMin = newContract?.selectedClub?.dateRange?.begin;
        beginMax = newContract?.selectedClub?.dateRange?.end;
        // eslint-disable-next-line max-len
        endMin = newContract?.endMinDate && dateManager.isValidDate(newContract?.endMinDate) ? newContract?.endMinDate : newContract?.selectedClub?.dateRange?.begin;
        endMax = newContract?.selectedClub?.dateRange?.end;
    }

    // AVC custom functionality. The contract begin date should not be validated, as it cant be modified
    if (isAvcClient && !!isUpgrade) {
        beginMin = null;
        beginMax = null;

        if (newContract?.selectedClub?.clubDuration === clubDuration.FIXED) {
            if (dateManager.isValidDate(newContract?.begin)) {
                endMin = dateManager.format(dateManager.getCurrentDay(), 'yyyy-MM-dd');
                endMax = dateManager.format(
                    dateManager.sub(
                        dateManager.add(dateManager.getCurrentDay(), newContract.selectedClub.duration, 'months'),
                        1,
                        'days',
                    ),
                    'yyyy-MM-dd',
                );
            }
        } else {
            endMin = dateManager.isAfter(
                dateManager.getCurrentDay(),
                newContract?.selectedClub?.dateRange?.begin,
            ) ? dateManager.format(dateManager.getCurrentDay(), 'yyyy-MM-dd')
                : newContract?.selectedClub?.dateRange?.begin;
            endMax = newContract?.selectedClub?.dateRange?.end;
        }
    }

    const getStatusClass = (status) => {
        switch (status) {
            case contractsStatus.SUSPENDED:
                return 'status-disabled';
            case contractsStatus.ACTIVE:
                return 'status-enabled';
            case contractsStatus.EXPIRED:
                return 'status-error';
            default:
                return '';
        }
    };

    return (
        <Form.Wrapper>
            <SectionsWrapper
                title={isUpgrade ? translated.owners.contracts.edition.titleUpgrade : translated.owners.contracts.edition.title}
                actionButtons={buttons}
                className={`modal-upgrade-downgrade${!isUpgrade ? ' new-contract' : ''}`}
            >
                {isUpgrade && !isUpgradeOutsideUserRequest && (
                    <table className="modal-details-table margin-bottom">
                        <tbody>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage id={translated.upgrades.request.name} defaultMessage={translated.upgrades.request.name} />
                                </td>
                                <td className="details-data">{data?.name}</td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage id={translated.upgrades.request.email} defaultMessage={translated.upgrades.request.email} />
                                </td>
                                <td className="details-data">
                                    {data?.email || (
                                        <FormattedMessage id={translated.global.notProvided} defaultMessage={translated.global.notProvided} />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage id={translated.upgrades.request.phone} defaultMessage={translated.upgrades.request.phone} />
                                </td>
                                <td className="details-data">
                                    {data?.phone || (
                                        <FormattedMessage id={translated.global.notProvided} defaultMessage={translated.global.notProvided} />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.upgrades.request.preferredContactTime}
                                        defaultMessage={translated.upgrades.request.preferredContactTime}
                                    />
                                </td>
                                <td className="details-data">
                                    {data?.preferredContactTime || (
                                        <FormattedMessage id={translated.global.notProvided} defaultMessage={translated.global.notProvided} />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage id={translated.upgrades.request.hotel} defaultMessage={translated.upgrades.request.hotel} />
                                </td>
                                <td className="details-data">{data?.property?.name}</td>
                            </tr>
                            <tr>
                                <td className="details-label">
                                    <FormattedMessage
                                        id={translated.upgrades.request.creationDate}
                                        defaultMessage={translated.upgrades.request.creationDate}
                                    />
                                </td>
                                <td className="details-data">
                                    {data?.creationDatetime ? dateManager.defaultDateTimeFormat(data.creationDatetime) : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}

                {isUpgrade && (
                    <Alert
                        id="contract-old"
                        type="warning"
                        isBasic
                        title={translated.global.advice}
                        content={translated.upgrades.updateContractWarning}
                        className="margin-top-small"
                    />
                )}

                <div
                    className={`margin-top-small form-upgrade-downgrade
                        ${isUpgrade ? 'grid grid-child-width-1-3' : ''}
                        ${!isUpgrade ? 'subsection view-details' : ''}`}
                >
                    {isUpgrade && (
                        <>
                            <Form.Wrapper>
                                <Form
                                    id="old-contract"
                                    isDisabled
                                    buttonsWidth={{ base: 12, small: 6 }}
                                    onSubmit={() => {
                                        // Default
                                    }}
                                >
                                    <Form.Title isSmall>
                                        <FormattedMessage
                                            id={translated.upgrades.currentContract}
                                            defaultMessage={translated.upgrades.currentContract}
                                        />
                                    </Form.Title>

                                    <Form.Input
                                        submitKey="club"
                                        isDense
                                        type="select"
                                        label={translated.owners.contracts.club}
                                        value={club && club.value}
                                        options={club ? [club] : []}
                                    />
                                    <Form.Input
                                        submitKey="tier"
                                        isDense
                                        type="select"
                                        isRequired
                                        label={translated.owners.contracts.tier}
                                        value={tier && tier.value}
                                        options={tier ? [tier] : []}
                                    />
                                    <Form.Input
                                        submitKey="contractType"
                                        isDense
                                        type="select"
                                        isRequired
                                        label={translated.owners.contracts.contractType}
                                        value={contractType && contractType.value}
                                        options={contractType ? [contractType] : []}
                                    />

                                    <Form.Input
                                        submitKey="consumptionMode"
                                        isDense
                                        isRequired
                                        type="select"
                                        label={translated.owners.contracts.consumptionMode}
                                        value={contract?.consumptionMode?.id}
                                        options={consumptionModes}
                                        submitFormat={(id) => ({ id })}
                                    />

                                    <Form.Input
                                        submitKey="begin"
                                        isDense
                                        isRequired
                                        type="date"
                                        icon={{ name: 'Calendar', position: 'right' }}
                                        label={translated.owners.contracts.start}
                                        value={contract.begin}
                                        className="small-number-input"
                                    />

                                    <Form.Input
                                        submitKey="end"
                                        isDense
                                        isRequired
                                        type="date"
                                        icon={{ name: 'Calendar', position: 'right' }}
                                        label={translated.owners.contracts.end}
                                        value={contract.end}
                                        className="small-number-input"
                                    />

                                    <Form.Input
                                        submitKey="baseId"
                                        isDense
                                        type="text"
                                        label={translated.owners.base}
                                        value={contract?.base?.id}
                                        className="small-number-input"
                                    />

                                    <Form.Input
                                        submitKey="legacyId"
                                        isDense
                                        type="text"
                                        label={translated.owners.contracts.contractId}
                                        value={contract?.legacyId}
                                        className="small-number-input"
                                    />

                                    <Form.Input
                                        key="highlight"
                                        submitKey="highlight"
                                        isDense
                                        type="checkbox"
                                        value={contract?.highlight}
                                        label={translated.owners.contracts.featured.label}
                                    />

                                    <Form.Title isSmall>
                                        <FormattedMessage
                                            id={translated.owners.contracts.primaryCurrency}
                                            defaultMessage={translated.owners.contracts.primaryCurrency}
                                        />
                                    </Form.Title>

                                    <Form.Input
                                        submitKey="primaryCurrency"
                                        isDense
                                        type="select"
                                        label={translated.owners.contracts.currency}
                                        value={primaryCurrency?.value}
                                        options={availableCurrencies}
                                    />

                                    <Form.Input
                                        submitKey="primaryCurrencyDelta"
                                        isDense
                                        type="number"
                                        className="small-number-input"
                                        label={translated.owners.contracts.delta}
                                        value={contract?.primaryCurrencyDelta}
                                    />

                                    <Form.Title isSmall>
                                        <FormattedMessage
                                            id={translated.owners.contracts.secondaryCurrency}
                                            defaultMessage={translated.owners.contracts.secondaryCurrency}
                                        />
                                    </Form.Title>

                                    <Form.Input
                                        submitKey="secondaryCurrency"
                                        isDense
                                        type="select"
                                        label={translated.owners.contracts.currency}
                                        value={secondaryCurrency?.value}
                                        options={availableCurrencies}
                                    />

                                    <Form.Input
                                        submitKey="secondaryCurrencyDelta"
                                        isDense
                                        type="number"
                                        className="small-number-input"
                                        label={translated.owners.contracts.delta}
                                        value={contract?.secondaryCurrencyDelta}
                                    />

                                    <Form.Input
                                        id="extra-attributes-current"
                                        key="extra-attributes-current"
                                        submitKey="extraAttributes"
                                        type="dynamicInputs"
                                        className="margin-top-small"
                                        label={translated.extraAttributes.title}
                                        value={contract?.extraAttributes}
                                        availableAttributes={availableExtraAttributes}
                                        isReadOnlyWithoutInputs
                                    />
                                </Form>
                            </Form.Wrapper>

                            <Icon className={`mdi-icon arrow-icon ${isUpgrade ? 'is-upgrade' : ''}`} path={IconPath.mdiArrowRight} size="30px" />
                        </>
                    )}

                    <Form
                        id="new-contract"
                        buttonsWidth={{ base: 12, small: 6 }}
                        onSubmit={handleOnSubmit}
                        onFinish={onClose}
                        onChange={onContractChange}
                        isReadOnlyWithoutInputs={isContractEdition || isReadOnly}
                        forceValidationsOnFirstRender={isUpgrade}
                    >
                        {isUpgrade && (
                            <Form.Title isSmall>
                                <FormattedMessage id={translated.upgrades.newContract} defaultMessage={translated.upgrades.newContract} />
                            </Form.Title>
                        )}

                        {(isContractEdition || isReadOnly) && (
                            <Form.Input
                                submitKey="clubText"
                                value={club.content}
                                type="text"
                                label={translated.owners.contracts.club}
                                onClick={club.links?.self?.update ? () => navigator.goToClubEditor({ available: club.links.self }) : null}
                                linkClassName="clickable-link"
                            />
                        )}

                        {!isContractEdition && !isReadOnly && (
                            <Form.Input
                                submitKey="club"
                                avoidOnSubmit
                                isDense
                                isRequired
                                type="select"
                                label={translated.owners.contracts.club}
                                value={newContract?.selectedClub?.value || null}
                                options={newContract?.allClubs || []}
                                onChange={handleOnClubChange}
                                avoidMarkAsChangedOnValidationsChange
                                isBasedOnState
                                isDisabled={newContract?.searchingClubs || newContract?.searchingTiers}
                            />
                        )}

                        {(isContractEdition || isReadOnly) && (
                            <Form.Input
                                submitKey="tierText"
                                value={tier.content}
                                type="text"
                                label={translated.owners.contracts.tier}
                                onClick={
                                    club?.links?.self?.update && clubTier?.links?.self?.update
                                        ? () => navigator.goToClubTierEditor({
                                            available    : clubTier.links.self,
                                            previousPath : [sectionType.CLUBS, sectionType.CLUBS_EDITOR, sectionType.CLUBS_GENERAL],
                                            pathProps    : [
                                                {
                                                    with : { resources: { available: club.links.self, current: 'read' } },
                                                    on   : sectionType.CLUBS_GENERAL,
                                                },
                                            ],
                                        })
                                        : null
                                }
                                linkClassName="clickable-link"
                            />
                        )}

                        {!isContractEdition && !isReadOnly && (
                            <Form.Input
                                submitKey="tier"
                                avoidOnSubmit
                                isDense
                                isRequired
                                type="select"
                                label={translated.owners.contracts.tier}
                                value={newContract?.selectedTier?.value || null}
                                options={newContract?.allTiers || []}
                                onChange={handleOnTierChange}
                                isDisabled={
                                    newContract.searchingClubs
                                        || newContract.searchingTiers
                                        || !newContract?.selectedClub?.value
                                        || !newContract?.allTiers?.length
                                }
                                defaultOptionText={
                                    (!newContract?.allTiers?.length && translated.owners.contracts.edition.clubWithNoTiers)
                                        || translated.global.selectAnOption
                                }
                                avoidMarkAsChangedOnValidationsChange
                                isBasedOnState
                            />
                        )}

                        {(isContractEdition || isReadOnly) && (
                            <Form.Input
                                submitKey="contractTypeText"
                                value={contractType.content}
                                type="text"
                                label={translated.owners.contracts.contractType}
                                onClick={
                                    club?.links?.self?.update && clubTier?.links?.self?.update && contractType?.links?.self?.update
                                        ? () => navigator.goToClubTierContractTypeEditor({
                                            available : contractType.links.self,
                                            pathProps : [
                                                {
                                                    with : { resources: { available: club.links.self, current: 'read' } },
                                                    on   : sectionType.CLUBS_GENERAL,
                                                },
                                                {
                                                    with : { resources: { available: clubTier.links.self, current: 'read' } },
                                                    on   : sectionType.CLUBS_TIER_EDITOR,
                                                },
                                            ],
                                        })
                                        : null
                                }
                                linkClassName="clickable-link"
                            />
                        )}

                        {!isContractEdition && !isReadOnly && (
                            <Form.Input
                                submitKey="contractType"
                                isDense
                                isRequired
                                type="select"
                                label={translated.owners.contracts.contractType}
                                value={newContract?.selectedContractType?.value || null}
                                options={newContract?.selectedTier?.contractTypes || []}
                                onChange={handleOnContractTypeChange}
                                isDisabled={
                                    newContract.searchingClubs
                                        || newContract.searchingTiers
                                        || newContract.searchingContractTypes
                                        || !newContract?.selectedTier?.value
                                        || !newContract?.selectedTier?.contractTypes?.length
                                }
                                avoidMarkAsChangedOnValidationsChange
                                submitFormat={(id) => ({ id })}
                                defaultOptionText={
                                    (newContract?.selectedTier?.value
                                            && !newContract?.selectedTier?.contractTypes?.length
                                            && translated.owners.contracts.edition.tierWithNoContractTypes)
                                        || translated.global.selectAnOption
                                }
                                isBasedOnState
                            />
                        )}

                        <Form.Input
                            submitKey="consumptionMode"
                            isDense
                            isRequired
                            type="select"
                            label={translated.owners.contracts.consumptionMode}
                            value={contract?.consumptionMode?.id}
                            options={consumptionModes}
                            submitFormat={(id) => ({ id })}
                        />

                        <Form.Input
                            submitKey="begin"
                            onChange={handleOnChangeBeginDate}
                            isDense
                            isRequired
                            supportText={getDatesExtraInfo(beginMin, beginMax, intl)}
                            type="date"
                            icon={{ name: 'Calendar', position: 'right' }}
                            label={translated.global.startDate}
                            minDate={beginMin}
                            maxDate={beginMax}
                            value={newContract.begin}
                            validations={{
                                minDate : beginMin,
                                maxDate : beginMax,
                                unary   : ['date'],
                            }}
                            className={isNotOnReadOnlyDate ? 'small-number-input' : ''}
                            isDisabled={isAvcClient && !!isUpgrade}
                        />

                        <Form.Input
                            submitKey="end"
                            onChange={handleOnChangeEndDate}
                            isDense
                            isRequired
                            supportText={getDatesExtraInfo(endMin, endMax, intl)}
                            type="date"
                            icon={{ name: 'Calendar', position: 'right' }}
                            label={translated.global.endDate}
                            value={newContract.end}
                            minDate={endMin}
                            maxDate={endMax}
                            validations={{
                                minDate : endMin,
                                maxDate : endMax,
                                unary   : ['date'],
                            }}
                            className={isNotOnReadOnlyDate ? 'small-number-input' : ''}
                            isBasedOnState
                        />

                        {(isUpgrade || isContractEdition || isReadOnly) && (
                            <Form.Input
                                isDense
                                isDisabled
                                submitKey="baseId"
                                type="text"
                                label={translated.owners.base}
                                value={contract?.base?.id}
                                className="small-number-input"
                                helperText={{ label: translated.owners.internalUse }}
                            />
                        )}

                        <Form.Input
                            submitKey="legacyId"
                            isDense
                            type="text"
                            label={translated.owners.contracts.contractId}
                            value={contract?.legacyId}
                            isRequired
                            validations={{ maxLength: 36, unary: ['alphanumericUnderScore'] }}
                            charCount={{ total: 36 }}
                            className="small-number-input"
                            isDisabled={isContractEdition}
                        />

                        {(isContractEdition || isReadOnly) && (
                            <Form.Input
                                submitKey="status"
                                isDense
                                type="text"
                                label={translated.owners.contracts.status}
                                value={contract?.status}
                                className={`input-with-status small-number-input ${getStatusClass(contract?.status)}`}
                                isDisabled
                            />
                        )}

                        <Form.Input
                            key="highlight"
                            submitKey="highlight"
                            isDense
                            type="checkbox"
                            value={newContract.highlight}
                            label={translated.owners.contracts.featured.label}
                        />
                        <Form.Title isSmall>
                            <FormattedMessage
                                id={translated.owners.contracts.primaryCurrency}
                                defaultMessage={translated.owners.contracts.primaryCurrency}
                            />
                        </Form.Title>
                        <Form.Input
                            submitKey="primaryCurrency"
                            type="select"
                            label={translated.owners.contracts.currency}
                            options={availableCurrencies}
                            value={currencies?.primaryCurrency?.id}
                            submitFormat={(value) => ({ id: value })}
                            isDense
                            isDisabled
                            isBasedOnState
                        />
                        <Form.Input
                            type="number"
                            submitKey="primaryCurrencyDelta"
                            value={currencies?.primaryCurrencyDelta}
                            validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                            label={translated.owners.contracts.delta}
                            className="small-number-input"
                            onChange={(newValue) => {
                                setCurrencies((prev) => ({ ...prev, primaryCurrencyDelta: newValue }));
                            }}
                            isDense
                            isRequired
                            isDisabled={isUpgrade}
                            isBasedOnState
                        />

                        <Form.Title isSmall>
                            <FormattedMessage
                                id={translated.owners.contracts.secondaryCurrency}
                                defaultMessage={translated.owners.contracts.secondaryCurrency}
                            />
                        </Form.Title>
                        <Form.Input
                            submitKey="secondaryCurrency"
                            type="select"
                            label={translated.owners.contracts.currency}
                            options={availableCurrencies}
                            value={currencies?.secondaryCurrency?.id}
                            submitFormat={(value) => ({ id: value })}
                            isDense
                            isDisabled
                            isBasedOnState
                        />
                        <Form.Input
                            type="number"
                            submitKey="secondaryCurrencyDelta"
                            value={currencies?.secondaryCurrencyDelta}
                            validations={{ minValue: 0.0001, maxValue: 99999999, unary: ['float'] }}
                            label={translated.owners.contracts.delta}
                            className="small-number-input"
                            onChange={(newValue) => {
                                setCurrencies((prev) => ({ ...prev, secondaryCurrencyDelta: newValue }));
                            }}
                            isDense
                            isRequired
                            isDisabled={isUpgrade}
                            isBasedOnState
                        />

                        <Form.Input
                            // Flag used to identify when the list change.
                            type="hidden"
                            submitKey="bankingConfigurationsChanged"
                            avoidOnSubmit
                            markAsChanged
                            value={bankingConfigurationsChanged}
                        />

                        {(!isEditing || isUpgrade) && (
                            <Form.Input
                                isDense
                                id="extra-attributes"
                                key="extra-attributes"
                                submitKey="extraAttributes"
                                type="dynamicInputs"
                                className="margin-top-small"
                                label={translated.extraAttributes.title}
                                value={newContract.extraAttributes}
                                availableAttributes={availableExtraAttributes}
                                submitFormat={(values) => transformDynamicInputs(values)}
                                forceAmountUpdate={forceAmountUpdate}
                            />
                        )}
                    </Form>
                </div>

                {isEditing && !isUpgrade && (
                    <Form.Wrapper>
                        <Subsection
                            title={translated.extraAttributes.title}
                            className="primary-subsection"
                            canCollapse={!!links?.self?.patch}
                            shouldShowFormInfoOnCollapse
                            actionButtons={
                                (!isReadOnly && links?.self?.patch)
                                    ? [
                                        <Form.Reset key="fs" color="primary">
                                            <FormattedMessage
                                                id={translated.global.buttons.reset}
                                                defaultMessage={translated.global.buttons.reset}
                                            />
                                        </Form.Reset>,
                                        <Form.Primary key="fp">
                                            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                                        </Form.Primary>,
                                    ]
                                    : []
                            }
                        >
                            <Form id="new-contract-extra-attributes-form" isReadOnly={isReadOnly} onSubmit={handleOnSubmitExtraAttributes}>
                                <Form.Column width={{ base: 12, small: 6 }}>
                                    <Form.Input
                                        id="extra-attributes"
                                        key="extra-attributes"
                                        type="dynamicInputs"
                                        submitKey="extraAttributes"
                                        value={newContract.extraAttributes}
                                        availableAttributes={availableExtraAttributes}
                                        submitFormat={(values) => transformDynamicInputs(values)}
                                        isDisabled={!links?.self?.patch}
                                        isReadOnly={!links?.self?.patch}
                                        isReadOnlyWithoutInputs={!links?.self?.patch}
                                    />
                                </Form.Column>
                            </Form>
                        </Subsection>
                    </Form.Wrapper>
                )}

                {(!isReadOnly && (!isEditing || isUpgrade)) && (
                    <div className="margin-top-xxlarge margin-bottom-xxlarge">
                        <BankingConfigurationList
                            link={newContract?.selectedTier?.links?.clubTierBankingConfiguration?.read}
                            previousTierId={contract?.contractType?.clubTier?.id}
                            newTierId={newContract?.selectedTier?.id}
                            elements={contract?.bankingConfigurations || []}
                            updateBankingConfigurations={(updated) => {
                                if (!bankingConfigurationsChanged) {
                                    setBankingConfigurationsChanged(true);
                                }
                                setBankingConfigurations(updated);
                            }}
                            isEditEnabled
                            contractData={{
                                begin           : newContract.begin,
                                end             : newContract.end,
                                extraAttributes : data?.extraAttributes,
                            }}
                            isEnabled={!!(newContract.begin && newContract.end && newContract.selectedContractType && newContract.areDatesValid)}
                            disableWarning={translated.owners.contracts.edition.disabledWarning}
                            generation={
                                contract?.links?.self?.automaticConfiguration
                                    ? {
                                        link            : contract.links.self.automaticConfiguration,
                                        data            : { base: contract?.base, club: { id: newContract?.selectedClub?.value } },
                                        contractEndDate : newContract.end,
                                        createSummary   : (params) => {
                                            const { bankingConfigurations: summaryBankings, walletManagements: summaryWallet } = params;

                                            return (
                                                <>
                                                    <BankingConfigurationList
                                                        link={newContract?.selectedTier?.links?.clubTierBankingConfiguration?.read}
                                                        elements={summaryBankings || []}
                                                        isAddEnabled={false}
                                                        isEditEnabled={false}
                                                        isRemoveEnabled={false}
                                                        contractData={{}}
                                                    />
                                                    {balances?.read && (
                                                        <WalletTransactions
                                                            link={balances.read}
                                                            transactions={summaryWallet.map((e) => ({ ...e, transactionValue: e.amount }))}
                                                            isEditionEnabled={false}
                                                        />
                                                    )}
                                                </>
                                            );
                                        },
                                        onFinish: (params, amount) => {
                                            const { bankingConfigurations: bankings = [], walletManagements = [] } = params;

                                            if (bankingConfigurationsRef?.current?.updateElements) {
                                                bankingConfigurationsRef.current.updateElements(bankings);
                                            }

                                            if (walletTransactionsRef?.current?.updateElements) {
                                                walletTransactionsRef.current.updateElements(
                                                    walletManagements.map((e) => ({ ...e, transactionValue: e.amount })),
                                                );
                                            }

                                            if (amount) {
                                                setForceAmountUpdate(Number(amount));
                                            }

                                            isGenerated.current = true;
                                        },
                                    }
                                    : null
                            }
                            ref={bankingConfigurationsRef}
                        />
                    </div>
                )}

                {isEditing && isUpgrade && balances?.read && (
                    <WalletTransactions
                        link={balances.read}
                        transactions={walletTransactions}
                        updateTransactions={setWalletTransactions}
                        ref={walletTransactionsRef}
                    />
                )}

                {(isEditing || isReadOnly)
                    && sections[sectionType.BANKING_CONFIGURATIONS]?.config?.resourceName
                    && links?.[sections[sectionType.BANKING_CONFIGURATIONS].config.resourceName]?.read && (
                    <BankingConfigurations
                        {...sections[sectionType.BANKING_CONFIGURATIONS]}
                        resources={{
                            ...sections[sectionType.BANKING_CONFIGURATIONS].resources,
                            available: links[sections[sectionType.BANKING_CONFIGURATIONS].config.resourceName],
                        }}
                        pathForResource={[...selectedPath, sectionType.BANKING_CONFIGURATIONS]}
                        contractData={{ begin: data?.begin, end: data?.end, extraAttributes: data?.extraAttributes }}
                        shouldReloadData={
                            sections[sectionType.BANKING_CONFIGURATIONS].shouldReloadData || !sections[sectionType.BANKING_CONFIGURATIONS].data
                        }
                        showValidation={false}
                        isEditEnabled={!isReadOnly}
                    />
                )}

                {(isEditing || isReadOnly) && sections[sectionType.RULES]?.config?.resourceName && links?.[sections[sectionType.RULES].config.resourceName]?.read && (
                    <RuleList
                        {...sections[sectionType.RULES]}
                        resources={{
                            ...sections[sectionType.RULES].resources,
                            available: links[sections[sectionType.RULES].config.resourceName],
                        }}
                        pathForResource={[...selectedPath, sectionType.RULES]}
                        selectRuleEditorMethod={navigateToRuleEditor}
                        shouldReloadData={sections[sectionType.RULES].shouldReloadData || !sections[sectionType.RULES].data}
                        isEditEnabled={!isReadOnly}
                    />
                )}

                {(isEditing || isReadOnly)
                        && sections[sectionType.CONVERSION_RULES]?.config?.resourceName
                        && links?.[sections[sectionType.CONVERSION_RULES].config.resourceName]?.read && (
                    <ConversionRules
                        {...sections[sectionType.CONVERSION_RULES]}
                        resources={{
                            ...sections[sectionType.CONVERSION_RULES].resources,
                            available: links[sections[sectionType.CONVERSION_RULES].config.resourceName],
                        }}
                        pathForResource={[...selectedPath, sectionType.CONVERSION_RULES]}
                        selectRuleEditorMethod={navigateToRuleEditor}
                        shouldReloadData={sections[sectionType.CONVERSION_RULES].shouldReloadData || !sections[sectionType.CONVERSION_RULES].data}
                        isEditEnabled={!isReadOnly}
                    />
                )}

                {(isEditing || isReadOnly)
                        && sections[sectionType.CONVERSION_RULES_ALT]?.config?.resourceName
                        && links?.[sections[sectionType.CONVERSION_RULES_ALT].config.resourceName]?.read && (
                    <ConversionRulesAlt
                        {...sections[sectionType.CONVERSION_RULES_ALT]}
                        resources={{
                            ...sections[sectionType.CONVERSION_RULES_ALT].resources,
                            available: links[sections[sectionType.CONVERSION_RULES_ALT].config.resourceName],
                        }}
                        pathForResource={[...selectedPath, sectionType.CONVERSION_RULES_ALT]}
                        shouldReloadData={
                            sections[sectionType.CONVERSION_RULES_ALT].shouldReloadData || !sections[sectionType.CONVERSION_RULES_ALT].data
                        }
                        isEditEnabled={!isReadOnly}
                    />
                )}

                {(isReadOnly || isEditing) && !isUpgrade && history && <History list={history} />}
            </SectionsWrapper>
        </Form.Wrapper>
    );
}

Editor.defaultProps = {
    isEditing                   : false,
    isUpgrade                   : false,
    isReadOnly                  : false,
    isUpgradeOutsideUserRequest : true,
    onFinish                    : null,
    navigateToParent            : null,
    isPartner                   : false,
};

Editor.propTypes = {
    sections                    : PropTypes.shape({}).isRequired,
    data                        : PropTypes.shape({}).isRequired,
    resources                   : PropTypes.shape({}).isRequired,
    isEditing                   : PropTypes.bool,
    isUpgrade                   : PropTypes.bool,
    isReadOnly                  : PropTypes.bool,
    isUpgradeOutsideUserRequest : PropTypes.bool,
    onFinish                    : PropTypes.func,
    navigateToParent            : PropTypes.string,
    isPartner                   : PropTypes.bool,
};

export default Editor;
