import React, { useMemo, useContext, useRef, useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Slider from 'rc-slider';
import yup from 'Utils/yupHelper';
import Form, { FormNew } from 'Components/Form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import { getCurrencyId } from './utils';

const STYLES = [
    { backgroundColor: '#C8B66C' },
    { backgroundColor: '#B4A258' },
    { backgroundColor: '#908246' },
    { backgroundColor: '#6C6135' },
    { backgroundColor: '#584D21' },
];

const schema = yup.object().shape({
    name : yup.string().required(),
    from : yup.number().integer().min(1).max(100)
        .required(),
});

function RangeSlider() {
    const { snackbar } = useContext(PanelContext);

    const { watch, setValue, trigger } = useFormContext();

    const formRef = useRef();

    const [updatedSteps, updatedRanges, balanceTypes, availableBalanceTypes, marks] = watch(['steps', 'ranges', 'balanceTypes', 'availableBalanceTypes', 'marks']);

    const isSliderDisabled = !updatedRanges || !Object.values(updatedRanges)?.find((e) => !e.isHidden);

    const [cachedMarks, setCachedMarks] = useState(marks || []);
    const changeTimer = useRef(null);

    useEffect(() => {
        setCachedMarks(marks);
    }, [marks]);

    useEffect(
        () => {
            if (!updatedRanges || !Object.keys(updatedRanges) || !updatedSteps?.length) {
                return;
            }

            const lastStep = updatedSteps[updatedSteps.length - 1];

            Object.values(updatedRanges)
                ?.filter((e) => !e.isHidden)
                ?.forEach(({ id, to }) => {
                    if (Number(to) > lastStep) {
                        setValue(`ranges.${id}.to`, lastStep);
                    }
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updatedSteps],
    );

    const startPoint = useMemo(
        () => (updatedSteps?.length ? updatedSteps[0] : 1),
        [updatedSteps],
    );

    const lastPoint = useMemo(
        () => (updatedSteps?.length ? updatedSteps[updatedSteps.length - 1] : 100),
        [updatedSteps],
    );

    // We update the steps with the form's context generated steps
    const steps = useMemo(() => {
        if (!Array.isArray(updatedSteps) || !updatedSteps?.length) {
            return {};
        }

        const firstStep = updatedSteps?.[0];
        const lastStep = updatedSteps?.[updatedSteps.length - 1];

        return updatedSteps?.reduce?.((obj, each, index) => ({
            ...obj,
            [each]: each === firstStep || each === lastStep || each % 5 === 0 || (each - updatedSteps[index - 1]) >= 5 ? `${each}%` : ' ',
        }), {});
    }, [updatedSteps]);

    const availableFrom = useMemo(() => {
        const filteredList = updatedSteps?.filter?.((e) => !marks?.find((eachMark) => Number(eachMark) === Number(e)));

        return filteredList?.map?.((e) => ({ content: e, value: e })) || [];
    }, [marks, updatedSteps]);

    const onChange = (newMarks) => {
        if (!updatedRanges || !Object.keys(updatedRanges)) {
            return;
        }

        const newRanges = JSON.parse(JSON.stringify(updatedRanges));

        const sortedRanges = Object.values(newRanges)
            ?.filter((e) => !e.isHidden)
            ?.sort((first, second) => {
                if (first.from === second.from) {
                    return first.id < second.id ? -1 : 1;
                }

                return first.from < second.from ? -1 : 1;
            });

        // This solution is used to prevent lag on the slider when a marker is dragged. Without this:
        //    when you drag a marker there would be a delay in the slider animation, the downside is that
        //    it takes half a second to update the ranges min and max.
        setCachedMarks(newMarks);
        if (changeTimer.current) {
            clearTimeout(changeTimer.current);
            changeTimer.current = null;
        }

        changeTimer.current = setTimeout(async () => {
            sortedRanges.forEach(({ id }, eachIndex) => {
                const newFrom = newMarks[eachIndex];
                const newTo = newMarks[eachIndex + 1] ? newMarks[eachIndex + 1] - 1 : lastPoint;

                newRanges[id].from = newFrom;
                newRanges[id].to = newTo;

                setValue(`ranges.${id}.from`, newFrom);
                setValue(`ranges.${id}.to`, newTo);
            });

            setValue('marks', newMarks, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        }, 500);
    };

    const initialValues = useMemo(() => ({ name: '', from: '' }), []);

    const updateRangeConversions = useCallback((rangeId) => {
        const loadedCurrencies = [];

        balanceTypes?.forEach?.((eachBalanceTypeId) => {
            const currencyId = getCurrencyId(eachBalanceTypeId, availableBalanceTypes);
            const options = { shouldDirty: true, shouldValidate: true };

            if (loadedCurrencies.indexOf(currencyId) === -1) {
                const key = `conversions.${rangeId}-${currencyId}`;
                const value = {
                    roomValue             : 1,
                    serviceValue          : 1,
                    roomValueAreEquals    : true,
                    serviceValueAreEquals : true,
                    isRequired            : true,
                };
                setValue(key, value, options);

                loadedCurrencies.push(currencyId);
            }

            const key = `conversions.${rangeId}-${currencyId}-${eachBalanceTypeId}`;
            const value = {
                roomValue             : 1,
                serviceValue          : 1,
                roomValueAreEquals    : true,
                serviceValueAreEquals : true,
                isRequired            : true,
            };

            setValue(key, value, options);
        });
    }, [availableBalanceTypes, setValue, balanceTypes]);

    const onAddRange = ({ name, from }) => {
        if (!updatedRanges || !Object.keys(updatedRanges)) {
            return;
        }

        if (Object.values(updatedRanges)?.find?.((e) => !e.isHidden && (e.label === name || e.from === from))) {
            // There is a range with the same name or from
            snackbar.show({
                content : translated.rules.dynamicRanges.repeatedRange,
                isError : true,
            });

            return;
        }

        const newRanges = JSON.parse(JSON.stringify(updatedRanges));

        const newId = `new_range_${new Date().getTime()}`;

        const newRange = {
            label : name,
            id    : newId,
            to    : lastPoint,
            from,
        };

        newRanges[newId] = newRange;

        const sortedRanges = Object.values(newRanges)
            ?.filter((e) => !e.isHidden)
            ?.sort((first, second) => {
                if (first.from === second.from) {
                    return first.id < second.id ? -1 : 1;
                }

                return first.from < second.from ? -1 : 1;
            });

        sortedRanges.forEach(({ id }, eachIndex) => {
            newRanges[id].to = sortedRanges[eachIndex + 1] ? sortedRanges[eachIndex + 1].from - 1 : lastPoint;
            const newTo = sortedRanges[eachIndex + 1] ? (sortedRanges[eachIndex + 1].from - 1) : lastPoint;

            if (updatedRanges[id] && updatedRanges[id]?.to !== newTo) {
                setValue(`ranges.${id}.to`, newTo, { shouldDirty: true, shouldTouch: true });
            }
        });

        setValue(`ranges.${newId}`, newRange, { shouldDirty: true, shouldTouch: true });
        setValue('marks', sortedRanges?.filter((e) => e.from != null && !e.isHidden)?.map((e) => e.from));

        trigger('ranges');

        updateRangeConversions(newId);

        formRef?.current?.reset();
    };

    return (
        <>
            <Slider
                range
                min={startPoint}
                max={lastPoint}
                value={cachedMarks}
                marks={steps}
                step={null} // Makes the slider use the marks as steps
                trackStyle={STYLES}
                handleStyle={STYLES}
                railStyle={{ backgroundColor: '#C2C2C2' }}
                onChange={onChange}
                disabled={isSliderDisabled}
            />

            <Form.InputError submitKey="marks" className="text-small margin-top-large" />

            <Form.WrapperNew
                schema={schema}
                initialValues={initialValues}
                key="range-create-form"
                formId="range-create-form"
                keepValuesOnInputRemove
                ref={formRef}
            >
                <FormNew onSubmit={onAddRange} avoidConfirmation>
                    <Form.ColumnNew key="range-create" className="form-column-group margin-top-xlarge">
                        <Form.InputNew
                            isDense
                            submitKey="name"
                            type="text"
                            label={translated.rules.dynamicRanges.rangeConfiguration.label}
                        />

                        <Form.InputNew
                            isDense
                            submitKey="from"
                            type="select"
                            label={translated.global.from}
                            options={availableFrom || []}
                        />

                        <Form.PrimaryNew key="fp" variant="outlined" className="add-column-button margin-left-small">
                            <WrappedFormattedMessage content={translated.global.buttons.add} />
                        </Form.PrimaryNew>
                    </Form.ColumnNew>
                </FormNew>
            </Form.WrapperNew>

            <Form.InputError submitKey="ranges" />
        </>
    );
}

export default RangeSlider;
