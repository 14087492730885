function setDarkMode(isDarkModeEnabled) {
    if (isDarkModeEnabled) {
        document.body.classList.remove('dark-mode');
        document.body.classList.add('dark-mode');
    } else {
        document.body.classList.remove('dark-mode');
    }
}

export default setDarkMode;
