import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PanelContext from 'State/panelContext';
import { getSectionsForBreadcrumb } from '../utils';

function Tabs({ className, sections: storeSections }) {
    const intl = useIntl();
    const ref = useRef(null);
    const { path, navigator, toggle } = useContext(PanelContext);
    const { current } = path;
    const sections = getSectionsForBreadcrumb(intl, current, storeSections);

    if (!sections || sections.length <= 1) {
        // Breadcrumb is hidden on root sections
        return null;
    }

    const onSectionClick = async (sectionIndex) => {
        // If the section has a default as child, we select that one
        const sectionChunk = sections[sectionIndex + 1] && sections[sectionIndex + 1].isDefault ? sectionIndex + 2 : sectionIndex + 1;
        const selectedSection = sections.map((s) => s.key).slice(0, sectionChunk);

        navigator.goAndReloadCleaningTheCurrent(selectedSection);
        toggle.breadcrumbTabsVisibility();

        const buttonClicked = new CustomEvent('ButtonClicked', {
            bubbles    : true,
            cancelable : true,
        });
        ref.current.dispatchEvent(buttonClicked);
    };

    // Backwards index to match the reversed sections
    let index = sections.length - 1;
    const reversedSections = [...sections].reverse();

    return (
        <ul className={`breadcrumb-tabs ${sections.length <= 5 ? 'breadcrumb-tabs-larger' : ''} ${className}`} ref={ref}>
            {sections.length > 1
                && reversedSections.slice(1).map(({ isHidden, isHiddenInBreadcrumbTabs, key, preffixedName }) => {
                    index--;
                    const currentIndex = index;
                    return (
                        !isHidden
                        && !isHiddenInBreadcrumbTabs && (
                            <li
                                onClick={() => onSectionClick(currentIndex)}
                                className={`breadcrumb-tabs-item${index === sections.length - 1 ? ' is-disabled' : ''}`}
                                key={key}
                            >
                                {preffixedName}
                            </li>
                        )
                    );
                })}
        </ul>
    );
}

Tabs.displayName = 'Breadcrumb.Tabs';
Tabs.defaultProps = { className: '' };
Tabs.propTypes = {
    className : PropTypes.string,
    sections  : PropTypes.shape({}).isRequired,
};

export default Tabs;
