import React from 'react';
import PropTypes from 'prop-types';
import Row from './Row';

function UnSortableContent({
    title,
    items,
    displayColumns,
    actions,
    handleOnRowCheck,
    styleConfig,
    isSubtable,
    checkedElements,
    updateOnCollapse,
    subTable,
    loadingIds,
    tableId,
    rowForwardedRef,
    getColumnCustomAttributes,
    getRowCustomClass,
}) {
    return (
        <tbody>
            {items?.map((item, i) => {
                const itemKey = item.id || i;
                return (
                    <Row
                        id={`${tableId}-${item.id}`}
                        key={`${tableId || title || 'UnSortableContent'}-${itemKey}`}
                        data={item}
                        fields={displayColumns}
                        className={item.className}
                        actions={actions}
                        onCheck={handleOnRowCheck}
                        isUncheckable={item.isUncheckable}
                        checked={checkedElements[item.id]}
                        index={itemKey}
                        updateOnCollapse={updateOnCollapse}
                        subTable={subTable}
                        styleConfig={styleConfig}
                        isLoading={loadingIds.includes(item.visualId || item.id)}
                        rowForwardedRef={rowForwardedRef}
                        isSubtable={isSubtable}
                        getColumnCustomAttributes={getColumnCustomAttributes}
                        getRowCustomClass={getRowCustomClass}
                    />
                );
            })}
        </tbody>
    );
}

UnSortableContent.defaultProps = {
    title            : '',
    subTable         : null,
    checkedElements  : [],
    handleOnRowCheck : () => {
        // Default
    },
    styleConfig               : {},
    actions                   : [],
    loadingIds                : [],
    rowForwardedRef           : null,
    isSubtable                : false,
    getColumnCustomAttributes : null,
    getRowCustomClass         : null,
};

UnSortableContent.propTypes = {
    tableId                   : PropTypes.string.isRequired,
    title                     : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    items                     : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayColumns            : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    actions                   : PropTypes.arrayOf(PropTypes.shape({})),
    loadingIds                : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    checkedElements           : PropTypes.shape({}),
    subTable                  : PropTypes.shape({}),
    updateOnCollapse          : PropTypes.func.isRequired,
    handleOnRowCheck          : PropTypes.func,
    styleConfig               : PropTypes.shape({}),
    rowForwardedRef           : PropTypes.shape({}),
    isSubtable                : PropTypes.bool,
    getColumnCustomAttributes : PropTypes.func,
    getRowCustomClass         : PropTypes.func,
};

export default React.memo(UnSortableContent);
