import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';
import Chip from './Chip';

function ChipPicker({
    label,
    value,
    isReadOnlyWithoutInputs,
    isDisabled,
    id,
    onClick,
    name,
    onChange,
    defaultValue,
    options,
    isMultiSelect,
}) {
    const context = useFormContext();
    const { setValue, control, watch } = context || {};

    const updatedValue = watch(name);

    const optionOnChange = useCallback((newValue, wasChecked) => {
        let inputNewValue = isMultiSelect
            ? updatedValue || []
            : newValue;

        if (isMultiSelect) {
            if (wasChecked) {
                inputNewValue = inputNewValue.filter((each) => each !== newValue);
            } else {
                inputNewValue.push(newValue);
            }
        }

        setValue(name, inputNewValue, { shouldDirty: true, shouldValidate: true });

        if (onChange) {
            onChange(inputNewValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedValue]);

    return (
        <>
            {!isReadOnlyWithoutInputs && (
                // We use the controller because to avoid a problem with the tests: an endless loop of renders (that does NOT happen with other input types).
                <Controller
                    render={
                        ({ field }) => {
                            const selectedOptions = field.value || (isMultiSelect && []) || '';

                            return (
                                <>
                                    {label && (
                                        <label className="label">
                                            <WrappedFormattedMessage content={label} />
                                        </label>
                                    )}

                                    <div className="chip-picker">
                                        {options?.map((each) => {
                                            const isChecked = isMultiSelect ? selectedOptions?.indexOf(each.value) !== -1 : selectedOptions === each.value;

                                            return (
                                                <Chip
                                                    label={each.label}
                                                    value={each.value}
                                                    id={`${id}-${String(each.value).toLowerCase()}`}
                                                    checked={isChecked}
                                                    onChange={() => optionOnChange(each.value, isChecked)}
                                                    isDisabled={isDisabled}
                                                />
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        }
                    }
                    control={control}
                    name={name}
                    defaultValue={defaultValue || ''}
                />
            )}

            {isReadOnlyWithoutInputs && !onClick && <span id={id}>{value}</span>}
        </>
    );
}

ChipPicker.defaultProps = {
    id                      : '',
    label                   : '',
    name                    : '',
    value                   : '',
    defaultValue            : null,
    isReadOnlyWithoutInputs : false,
    onChange                : null,
    isDisabled              : false,
    innerInputRef           : null,
    onClick                 : null,
    isMultiSelect           : false,
};

ChipPicker.propTypes = {
    id                      : PropTypes.string,
    label                   : PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    name                    : PropTypes.string,
    value                   : PropTypes.string,
    defaultValue            : PropTypes.string,
    isReadOnlyWithoutInputs : PropTypes.bool,
    onChange                : PropTypes.func,
    isDisabled              : PropTypes.bool,
    innerInputRef           : PropTypes.shape({}),
    onClick                 : PropTypes.func,
    options                 : PropTypes.arrayOf(
        PropTypes.shape({
            content : PropTypes.string,
            value   : PropTypes.string,
        }),
    ).isRequired,
    isMultiSelect: PropTypes.bool,
};

export default React.memo(ChipPicker);
