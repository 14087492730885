export default class EventManager {

    constructor() {
        this.listeners = [];
        this.fireEvent = this.fireEvent.bind(this);
        this.findListener = this.findListener.bind(this);
        this.findEvent = this.findEvent.bind(this);
    }

    findEvent(event) {
        return this.listeners.find((listener) => listener.event === event);
    }

    findListener(ref) {
        return this.listeners.find((listener) => (typeof listener.ref === 'undefined') || listener.ref === ref);
    }

    fireEvent(e) {
        this.listeners
            .filter((listener) => (listener.event === e.type) && listener.condition(e))
            .forEach((listener) => listener.callback(e));
    }

    subscribe(ref, event, callback, condition = () => true) {
        if (!this.findEvent(event)) {
            document.addEventListener(event, this.fireEvent, false);
        }
        this.listeners.push({ ref, event, callback, condition });
    }

    unsubscribe(ref) {
        const found = this.findListener(ref);
        if (found) {
            this.listeners = this.listeners.filter((listener) => listener !== found);
            const { event } = found;
            const eventFound = this.findEvent(event);
            if (!eventFound) {
                document.removeEventListener(event, this.fireEvent);
            }
        }
    }

}
