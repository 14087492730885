import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, get } from 'react-hook-form';
import WrappedFormattedMessage from 'Components/WrappedFormattedMessage';

function InputError({ submitKey, className }) {
    const { formState } = useFormContext();

    const inputError = get(formState?.errors, `${submitKey}.message`);

    if (!inputError) {
        return null;
    }

    return (
        <div className={`form-input-error ${className}`}>
            <span>
                <WrappedFormattedMessage content={inputError} />
            </span>
        </div>
    );
}

InputError.defaultProps = { className: '' };

InputError.propTypes = {
    submitKey : PropTypes.string.isRequired,
    className : PropTypes.string,
};

export default InputError;
