import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import PanelContext from 'State/panelContext';
import { sectionType } from 'Constants/types';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import { scrollToTop } from 'Utils';
import withRequest from 'Components/Sections/withRequest';
import { fetchingShape } from 'Constants/PropTypes';
import Loading from 'Components/Loading';
import Modal from 'Components/Modal';

export function List({ data = {}, reloadData, resources, fetching, setSectionState, state: { appliedFilters } }) {
    const { navigator, snackbar, confirmation } = useContext(PanelContext);
    const [isSendingInvitationLink, setIsSendingInvitationLink] = useState(false);
    useEffect(() => {
        scrollToTop();
    }, []);

    const { links, data: agents, order, pagination, filter: filterLinks } = data;

    const users = agents
        ? agents.map((agent) => ({
            ...agent.user,
            role       : agent.role && agent.role.name,
            department : agent.department && agent.department.name,
            links      : agent.links,
            isActive   : agent.isActive,
        }))
        : [];

    const handleClickChangeStatus = async (user, enable) => {
        const { id, links: userLinks } = user;

        const reqConfig = {
            ...userLinks.self.patch,
            data             : { isActive: enable },
            isCritical       : false,
            isGlobal         : false,
            ids              : [id],
            shouldReloadData : true,
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.AGENTS, resources });

            snackbar.show({ content: enable ? translated.agents.enable.success : translated.agents.disable.success, isSuccess: true });
        } catch (error) {
            snackbar.show({ error, content: enable ? translated.agents.enable.error : translated.agents.disable.error, isError: true });
        }
    };

    const handleClickSendInvitation = async (invitationLink) => {
        confirmation.show({
            message       : translated.agents.sendInvitationConfirmation,
            acceptMessage : translated.global.buttons.confirm,
            onAccept      : async () => {
                setIsSendingInvitationLink(true);

                try {
                    await navigator.directRequest(invitationLink);

                    snackbar.show({ content: translated.agents.sendInvitationSuccess, isSuccess: true });
                } catch (error) {
                    snackbar.show({ content: translated.agents.errors.invitation, error, isError: true });
                } finally {
                    setIsSendingInvitationLink(false);
                }
            },
        });
    };

    const handleOnAddClick = () => navigator.goToUserCreator(data, 'init');

    const columns = [
        {
            title    : translated.global.status,
            key      : 'isActive',
            whenTrue : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
            isVisible: true,
        },
        {
            title           : translated.global.firstName,
            key             : 'firstName',
            isVisible       : true,
            showTooltipWhen : 80,
            customClassName : 'text-overflow',
        },
        {
            title           : translated.global.lastName,
            key             : 'lastName',
            isVisible       : true,
            showTooltipWhen : 80,
            customClassName : 'text-overflow',
        },
        {
            title     : translated.global.id,
            key       : 'id',
            isVisible : true,
            isNumeric : true,
        },
        {
            title     : translated.global.email,
            key       : 'email',
            isVisible : true,
        },
        {
            title     : translated.global.role,
            key       : 'role',
            isVisible : true,
        },
        {
            title     : translated.global.department,
            key       : 'department',
            isVisible : true,
        },
    ];

    const sectionButtons = links?.self?.create && (users?.length || !!appliedFilters?.filters)
        ? [
            <Button id="agents-add-button" variant="unelevated" key="button_primary" onClick={handleOnAddClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    const filterResources = {
        filters: [
            {
                type        : 'text',
                submitKey   : 'firstName',
                resourceKey : 'firstName',
                label       : translated.global.firstName,
                value       : '',
            },
            {
                type        : 'text',
                submitKey   : 'lastName',
                resourceKey : 'lastName',
                label       : translated.global.lastName,
            },
            {
                type        : 'text',
                submitKey   : 'email',
                resourceKey : 'email',
                label       : translated.global.email,
            },
            {
                type        : 'select',
                options     : filterLinks?.additionalResources?.departments,
                submitKey   : 'department',
                resourceKey : 'departmentId',
                label       : translated.global.department,
            },
            {
                type        : 'select',
                options     : filterLinks?.additionalResources?.roles,
                submitKey   : 'role',
                resourceKey : 'roleId',
                label       : translated.global.role,
            },
        ],
        filterLinks,
        resources,
    };

    return (
        <>
            {isSendingInvitationLink && (
                <>
                    <Loading />
                    <Modal title={translated.global.pleaseWait}>
                        <span>
                            <FormattedMessage id={translated.agents.sendingInvitation} defaultMessage={translated.agents.sendingInvitation} />
                        </span>
                    </Modal>
                </>
            )}

            <Wrapper
                title={translated.sectionName.agents}
                actionButtons={sectionButtons}
            >
                <Table
                    id="agents"
                    key="agents"
                    columns={columns}
                    rows={{
                        actions: [
                            {
                                content  : translated.global.buttons.disable,
                                callback : (user) => handleClickChangeStatus(user, false),
                                key      : 'disable',
                                when     : (user) => user.isActive && user.links?.self?.patch,
                            },
                            {
                                content  : translated.global.buttons.enable,
                                callback : (user) => handleClickChangeStatus(user, true),
                                key      : 'enable',
                                when     : (user) => !user.isActive && user.links?.self?.patch,
                            },
                            {
                                content  : translated.global.buttons.sendInvitation,
                                callback : (user) => handleClickSendInvitation(user.links.self.activation),
                                key      : 'invitation',
                                when     : (user) => user.links?.self?.activation,
                            },
                            {
                                callback          : (user) => navigator.goToUserEditor(user),
                                key               : 'view',
                                isOutsideDropdown : true,
                                getIcon           : (user) => (user?.links?.self?.update ? 'Pencil' : 'Eye'),
                                getTooltip        : (user) => (user?.links?.self?.update
                                    ? translated.global.buttons.edit
                                    : translated.global.buttons.view
                                ),

                                when: (user) => user.links?.self?.read,
                            },
                        ],
                    }}
                    items={users}
                    canViewColumns
                    canChangeSettings
                    order={order}
                    onOrder={(link) => reloadData(link)}
                    pagination={pagination}
                    onPaginationClick={(link) => reloadData(link)}
                    loadingIds={fetching.ids}
                    filterResources={filterResources}
                    onApplyFilter={(link, config) => {
                        reloadData(link);
                        setSectionState(config);
                    }}
                    appliedFilters={appliedFilters}
                    whenEmpty={(
                        <Alert
                            id="agents-empty"
                            content={translated.agents.noUsers}
                            actionText={translated.global.buttons.add}
                            onClick={handleOnAddClick}
                        />
                    )}
                />
            </Wrapper>
        </>
    );
}

List.defaultProps = {
    setSectionState : null,
    state           : null,
};

List.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    fetching        : fetchingShape.isRequired,
    reloadData      : PropTypes.func.isRequired,
    resources       : PropTypes.shape({}).isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={150} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
