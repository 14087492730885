import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import RulesList from './List';
import Wrapper from 'Components/Sections/Wrapper';
import Button from 'Components/Button';
import PanelContext from 'State/panelContext';

export function Rules({ ...props }) {
    const { navigator } = useContext(PanelContext);

    const ruleLinks = props?.data?.links?.self;
    const showSectionButton = (props?.data?.exception?.length > 0 || props?.data?.points?.length > 0) && ruleLinks?.create;

    return (
        <Wrapper
            // We hide the rules list title while the section is being loaded and there is no data to show
            title={props?.fetching?.isGlobal && !props?.data ? null : translated.rules.systemRules.title}
            actionButtons={
                showSectionButton
                    ? [
                        <Button
                            id="system-rules-add-button"
                            variant="unelevated"
                            key="button_primary"
                            onClick={() => {
                                navigator.goToSystemRuleEditor({ available: ruleLinks, current: 'init' });
                            }}
                        >
                            <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
                        </Button>,
                    ]
                    : []
            }
        >
            <RulesList {...props} isAddEnabled={!showSectionButton} hideTitle isSystemRule selectRuleEditorMethod="goToSystemRuleEditor" />
        </Wrapper>
    );
}

Rules.defaultProps = {};

Rules.propTypes = {};

export default Rules;
