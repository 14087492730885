import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form, { FormError } from 'Components/Form';
import { bookingStates } from 'Constants/translator';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';

function ModalBooking({ cancellation, onClose }) {
    const { navigator } = useContext(PanelContext);

    const [isLoading, setIsLoading] = useState(false);

    const modalProperties = {
        title   : translated.bookings.cancellation.title,
        buttons : [
            <Form.Secondary key="fs" variant="text" color="primary" onClick={() => onClose(false)}>
                <FormattedMessage id={translated.global.buttons.close} defaultMessage={translated.global.buttons.close} />
            </Form.Secondary>,
        ],
    };

    const cancellationLink = cancellation?.links?.self?.cancel;

    let canCancel = true;
    let cancelMessage = '';

    if (bookingStates.notCancellable.includes(cancellation.code)) {
        canCancel = false;
        cancelMessage = translated.bookings.cancellation.notCancellable;
    } else if (bookingStates.cancellableWithRefound.includes(cancellation.code)) {
        cancelMessage = translated.bookings.cancellation.cancellableWithRefound;
    } else if (bookingStates.cancellableWithNoRefound.includes(cancellation.code)) {
        cancelMessage = translated.bookings.cancellation.cancellableWithNoRefound;
    } else {
        canCancel = false;
        cancelMessage = translated.bookings.cancellation.unknownState;
    }

    if (cancellationLink && canCancel) {
        modalProperties.buttons.push(
            <Form.Primary key="fp" variant="text" color="primary" isDisabled={isLoading}>
                <FormattedMessage id={translated.global.buttons.confirm} defaultMessage={translated.global.buttons.cancelMessage} />
            </Form.Primary>,
        );
    }

    const onFinish = () => {
        onClose(true);
    };

    const onSubmit = async ({ values }) => {
        setIsLoading(true);
        try {
            await navigator.directRequest({ ...cancellationLink, data: values });
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);

            throw new FormError(translated.bookings.errors.updateError, e);
        }
    };

    return (
        <Form.Wrapper modalProperties={modalProperties}>
            <p>
                <FormattedMessage id={cancelMessage} defaultMessage={cancelMessage} />
            </p>
            {canCancel && (
                <Form id="booking-edit" className="margin-top-small" onSubmit={onSubmit} onFinish={onFinish}>
                    <Form.Input
                        isRequired
                        isDense
                        submitKey="comment"
                        type="textarea"
                        label={translated.bookings.text}
                        value=""
                        rows="8"
                        cols="55"
                        validations={{ maxLength: 600 }}
                        charCount={{ total: 600 }}
                    />
                </Form>
            )}
        </Form.Wrapper>
    );
}

ModalBooking.propTypes = {
    cancellation : PropTypes.shape({}).isRequired,
    onClose      : PropTypes.func.isRequired,
};

export default ModalBooking;
