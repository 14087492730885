import React from 'react';
import PropTypes from 'prop-types';
import getClassName from 'Utils/getClassName';

function Wrapper({ className, children, canCollapse, isCollapsed, style, wrapperForwardedRef }) {
    return canCollapse
        ? (
            <div
                className={getClassName({ isCollapsed }, 'table-wrapper', 'table-collapse', className)}
                style={style}
                ref={wrapperForwardedRef}
            >
                {children}
            </div>
        )
        : <div className={`table-wrapper ${className}`} ref={wrapperForwardedRef}>{children}</div>;
}

Wrapper.defaultProps = {
    className           : '',
    style               : {},
    wrapperForwardedRef : null,
};

Wrapper.propTypes = {
    className           : PropTypes.string,
    children            : PropTypes.node.isRequired,
    canCollapse         : PropTypes.bool.isRequired,
    isCollapsed         : PropTypes.bool.isRequired,
    style               : PropTypes.shape({}),
    wrapperForwardedRef : PropTypes.shape({}),
};

export default Wrapper;
