module.exports = Object.freeze({
    title                      : 'Associate Rules',
    conditions                 : 'Conditions',
    information                : 'Information',
    general                    : 'General',
    overrideSystemRules        : 'Override system rules',
    overrideSystemRulesExplain : 'This rule will override any global rule at property level',
    name                       : 'Name',
    type                       : 'Type',
    typePoints                 : 'Balance type',
    typeFlatFee                : 'Flat Fee',
    typeFiat                   : 'Fiat Option',
    includes                   : 'Property Filter',
    includesAvailable          : 'Include',
    includesAvailableSummary   : 'The selected Properties and Property Groups will be available for bookings',
    excludesHide               : 'Exclude and hide',
    excludesHideSummary        : 'The selected Properties and Property Groups will be hidden',
    excludesShowUpgrade        : 'Exclude and show upgrade message',
    excludesShowUpgradeSummary : 'The selected Properties and Property Groups will show a message informing to upgrade the tier for bookings',
    conversion                 : 'Conversion Type',
    conversionValue            : 'Conversion Value',
    points                     : 'Book only with',
    currency                   : 'Currency',
    amount                     : 'Amount',
    primaryCurrencyRate        : 'Primary currency rate',
    secondaryCurrencyRate      : 'Secondary currency rate',
    minAmount                  : 'Minimum Amount',
    minAmountPerReservation    : 'Per booking total',
    minAmountPerNight          : 'Per night',
    blocks                     : 'Blocks',
    blocksSupportText          :
        'Percentage of the reservation that can be paid with a fiat currency. Write the blocks separated by a comma (,) or press the enter key to add an option. For instance: "50,75"',
    block: {
        percentage : 'Percentage',
        label      : 'Label',
    },
    feeCalculation        : 'Fee calculation',
    feeCalculationOptions : {
        reservation : 'Whole Reservation',
        night       : 'Each night',
    },
    applicationType        : 'Application type',
    applicationTypeOptions : {
        stay : 'Per stay',
        room : 'Per room',
    },
    code                   : 'Code',
    propertiesMode         : 'Properties Mode',
    hotelTypeTitle         : 'Property groups',
    hotelType              : 'Groups',
    hotel                  : 'Properties',
    occupancy              : 'Occupancy',
    occupancyOnlyApplies   : 'Only applies between dates',
    occupancyAlwaysApplies : 'Always applies, except between dates',
    stayDates              : 'Stay Dates',
    occupancyValue         : 'Value',
    more                   : 'More than',
    less                   : 'Less than',
    equals                 : 'Equals to',
    always                 : 'Always',
    alwaysSummary          : 'Always applies',
    only                   : 'Only',
    except                 : 'Except',
    cutoff                 : 'Cutoff',
    maxLeadTime            : 'Max lead time',
    lengthOfStay           : 'Length of Stay',
    roomAllotment          : 'Room allotment',
    bookingCounter         : 'Booking counter',
    countriesMode          : 'Countries mode',
    countries              : 'Countries',
    dates                  : 'Dates',
    bookingWindow          : 'Booking window',
    enterAValue            : 'Enter the conversion value that will be applied to the rule',
    fixedPerNight          : 'Fixed per night',
    fixedOnTotal           : 'Fixed on total',
    percentage             : 'Percentage',
    people                 : 'people',
    enabled                : 'Enabled',
    empty                  : 'There are no rules added',
    orderChange            : 'Rules order changed',

    extraAttributes: {
        title    : 'Extra Attributes',
        entities : {
            Client   : 'Client',
            Contract : 'Contract',
            User     : 'User',
        },

        name        : 'Name',
        operator    : 'Operator',
        value       : 'Value',
        removeField : 'Remove field',
    },

    hotelAndHotelTypeRequired    : 'At least one Property or one Property Group is required',
    bWFLessThanSDFMinusCutoff    : 'The Booking Window from date, at most, must be less than the Stay Date from minus the Cutoff',
    bWELessThanSDEMinusCutoff    : 'The Booking Window end date, at most, must be less than the Stay Date end minus the Cutoff',
    flatFeeWithNoTranslation     : 'There should be at least one translation',
    blocksEmpty                  : 'You should add at least a block',
    blocksWithRepeatedPercentage : 'There are at least 2 blocks with the same percentage',
    modifyRule                   : 'Modify Rule',
    areYouSure                   : 'Are you sure you want to modify this rule?',

    incompleteExtraAttributes : 'There is at least one incomplete condition',
    repeatedExtraAttributes   : 'There is at least two conditions with the same name and operation',

    wizard: {
        step1 : 'Set the initial status for the rule ',
        step2 : 'Select the Rule name',
        step3 : 'Select the Rule type',
        step4 : 'Depending on the rule type, select the conditions',
    },

    all      : 'All',
    filter   : 'Apply to',
    excludes : 'Except',

    applyTo        : 'Apply To',
    applyToOptions : {
        both   : 'Both',
        owners : 'Owners',
        agents : 'Agents',
    },

    success: {
        ruleAdded     : 'The rule has been added',
        ruleSaved     : 'The rule has been updated',
        rulesEnabled  : 'The rules have been enabled',
        rulesDisabled : 'The rules have been disabled',
        rulesRemoved  : 'The rules have been removed',
        ruleRemoved   : 'The rule has been removed',
        ruleDisabled  : 'The rule has been disabled',
        ruleEnabled   : 'The rule has been enabled',
    },

    errors: {
        ruleAdded              : 'The rule could not be added',
        ruleSaved              : 'The rule could not be updated',
        rulesEnabled           : 'The rules could not be enabled',
        rulesDisabled          : 'The rules could not be disabled',
        rulesRemoved           : 'The rules could not be removed',
        ruleRemoved            : 'The rule could not be removed',
        ruleDisabled           : 'The rule could not be disabled',
        ruleEnabled            : 'The rule could not be enabled',
        reorder                : 'The rules could not be reordered',
        entityCodeRepeated     : 'There is a flat fee rule with the same code',
        hotelsFilterError      : 'The property groups could not be initialized',
        hotelsTypesFilterError : 'The property group types could not be initialized',
    },

    systemRules: { title: 'System Rules' },

    translations: {
        title : 'Translations',
        empty : 'There are no translations loaded',

        name        : 'Title',
        description : 'Description',
        language    : 'Language',

        modal: {
            addTitle  : 'Add translation',
            editTitle : 'Edit translation',
        },
    },
    display     : 'Display in',
    views       : 'Views',
    viewOptions : {
        search : 'Search',
        offers : 'Offers',
    },

    types: {
        pricing     : 'Pricing',
        exception   : 'Exception',
        balanceType : 'Balance type',
        flatFee     : 'Flat Fee',
        fiatOption  : 'Fiat Option',
    },
});
