import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import Wrapper from '../Wrapper';
import Skeleton from 'Components/Skeletons';
import Grid from 'Components/Grid';
import Alert from 'Components/Alert';
import Table from 'Components/Table';
import Button from 'Components/Button';
import { scrollToTop } from 'Utils';
import withRequest from 'Components/Sections/withRequest';
import { sectionType } from 'Constants/types';
import translated from 'Constants/labels/translated';
import { fetchingShape } from 'Constants/PropTypes';

function List({ data, resources, fetching, reloadData, setSectionState, state: { appliedFilters } }) {
    const { navigator, confirmation, snackbar } = useContext(PanelContext);

    useEffect(() => {
        scrollToTop();
    }, []);

    const onCreateClick = () => {
        navigator.goToBalanceTypeEditor({ available: data?.links?.self || {}, current: 'init' });
    };

    const handleClickEditBonusPoint = (bonusPoint) => {
        navigator.goToBalanceTypeEditor({ available: bonusPoint.links.self, current: 'read' });
    };

    const { data: items, order, pagination, filter: filterLinks } = data || {};

    const convertedItems = items?.map((e) => ({
        ...e,
        currencyName : e.currency?.name,
        tagNames     : e.tags
            ?.map((tag) => tag.label)
            .sort()
            .join(', '),
    })) || [];

    const handleClickPerformDelete = async (bonusPoint) => {
        try {
            const reqConfig = {
                ...bonusPoint.links.self.delete,
                isCritical       : false,
                isGlobal         : false,
                ids              : [bonusPoint.id],
                shouldReloadData : true,
            };
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BALANCE_TYPES, resources });

            snackbar.show({ content: translated.balanceTypes.delete.success, isSuccess: true });
        } catch (e) {
            snackbar.show({
                error       : e,
                content     : translated.balanceTypes.delete.error,
                isError     : true,
                errorLabels : translated.balanceTypes.delete,
            });
        }
    };

    const handleClickOpenDeleteBonusPoint = (bonusPointToDelete) => {
        confirmation.show({
            message       : translated.balanceTypes.delete.confirmation,
            acceptMessage : translated.global.buttons.delete,
            onAccept      : () => handleClickPerformDelete(bonusPointToDelete),
        });
    };

    const handleClickChangeActive = async (bonusPoint, isActive) => {
        const { links } = bonusPoint;
        const reqConfig = {
            ...links.self.patch,
            shouldReloadData : true,
            isCritical       : false,
            isGlobal         : false,
            ids              : [bonusPoint.id],
            data             : { isActive },
        };
        try {
            await navigator.requestForCurrentPath({ reqConfig, section: sectionType.BALANCE_TYPES, resources });

            snackbar.show({
                content   : isActive ? translated.balanceTypes.enable.success : translated.balanceTypes.disable.success,
                isSuccess : true,
            });
        } catch (e) {
            snackbar.show({
                content     : isActive ? translated.balanceTypes.enable.error : translated.balanceTypes.disable.error,
                error       : e,
                isError     : true,
                errorLabels : translated.balanceTypes.errors,
            });
        }
    };

    const columns = [
        {
            title     : translated.global.name,
            key       : 'name',
            isVisible : true,
        },
        {
            title    : translated.global.status,
            key      : 'isActive',
            whenTrue : {
                text      : translated.global.state.enabled,
                className : 'status status-enabled',
            },
            whenFalse: {
                text      : translated.global.state.disabled,
                className : 'status status-disabled',
            },
            isVisible: true,
        },
        {
            title     : translated.global.id,
            key       : 'id',
            isVisible : true,
            isNumeric : true,
        },
        {
            title     : translated.balanceTypes.labels.currency,
            key       : 'currencyName',
            isVisible : true,
        },
        {
            title     : translated.balanceTypes.labels.level,
            key       : 'level',
            isVisible : true,
            isNumeric : true,
        },
        {
            title           : translated.global.tags,
            key             : 'tagNames',
            isVisible       : true,
            showTooltipWhen : 40,
            customClassName : 'text-overflow',
        },
    ];

    const rows = {
        actions: [
            {
                content  : translated.global.buttons.enable,
                callback : (bonusPoint) => handleClickChangeActive(bonusPoint, true),
                key      : 'enable',
                when     : (bonusPoint) => !bonusPoint.isActive && bonusPoint.links?.self?.patch,
            },
            {
                content  : translated.global.buttons.disable,
                callback : (bonusPoint) => handleClickChangeActive(bonusPoint, false),
                key      : 'disable',
                when     : (bonusPoint) => bonusPoint.isActive && bonusPoint.links?.self?.patch,
            },
            {
                content  : translated.global.buttons.delete,
                callback : (bonusPoint) => handleClickOpenDeleteBonusPoint(bonusPoint),
                key      : 'delete',
                when     : (bonusPoint) => bonusPoint.links?.self?.delete,
            },
            {
                callback          : (bonusPoint) => handleClickEditBonusPoint(bonusPoint),
                key               : 'view',
                isOutsideDropdown : true,
                getIcon           : (balanceType) => (balanceType?.links?.self?.update ? 'Pencil' : 'Eye'),
                getTooltip        : (balanceType) => (balanceType?.links?.self?.update
                    ? translated.global.buttons.edit
                    : translated.global.buttons.view
                ),
                when: (balanceType) => balanceType?.links?.self?.read,
            },
        ],
    };

    const existItems = convertedItems?.length > 0;

    const sectionButtons = data?.links?.self?.create && existItems
        ? [
            <Button id="balance-types-add-button" variant="unelevated" key="button_primary" onClick={onCreateClick}>
                <FormattedMessage id={translated.global.buttons.add} defaultMessage={translated.global.buttons.add} />
            </Button>,
        ]
        : [];

    return (
        <Wrapper title={translated.sectionName.balanceTypes} actionButtons={existItems ? sectionButtons : []}>
            <Table
                id="balance-types"
                key="balance-types"
                columns={columns}
                canViewColumns
                canChangeSettings
                rows={rows}
                items={convertedItems}
                loadingIds={fetching.ids}
                order={order}
                onOrder={(link) => reloadData(link)}
                whenEmpty={(
                    <Alert
                        id="balance-types-empty"
                        content={translated.balanceTypes.noBonusPoints}
                        actionText={translated.global.buttons.add}
                        onClick={onCreateClick}
                    />
                )}
                pagination={pagination}
                onPaginationClick={(link) => reloadData(link)}
                onApplyFilter={(link, config) => {
                    reloadData(link);
                    setSectionState(config);
                }}
                appliedFilters={appliedFilters}
                filterResources={{
                    filters: [
                        {
                            type        : 'text',
                            submitKey   : 'name',
                            resourceKey : 'name',
                            label       : translated.global.name,
                        },
                        {
                            type    : 'select',
                            options : [
                                {
                                    name : translated.global.state.enabled,
                                    id   : 'true',
                                },
                                {
                                    name : translated.global.state.disabled,
                                    id   : 'false',
                                },
                            ],
                            submitKey   : 'isActive',
                            resourceKey : 'isActive',
                            label       : translated.global.status,
                        },
                        {
                            type        : 'select',
                            options     : filterLinks?.additionalResources?.currencies,
                            submitKey   : 'currency',
                            resourceKey : 'currency',
                            label       : translated.balanceTypes.labels.currency,
                        },
                        {
                            type        : 'number',
                            submitKey   : 'level',
                            resourceKey : 'level',
                            label       : translated.balanceTypes.labels.level,
                        },
                        {
                            id               : 'balance-types-filter-tags',
                            type             : 'search',
                            submitKey        : 'slug',
                            resourceKey      : 'slug',
                            label            : translated.global.tags,
                            onChangeKey      : 'slug',
                            className        : 'tags-filter',
                            showLoading      : true,
                            searchValidation : (newValue) => newValue && newValue.length > 2,
                            searchFunction   : async (tagName) => {
                                const tags = filterLinks?.additionalResources?.tags?.map((tag) => ({ ...tag, lower: tag.label.toLowerCase() })) || [];
                                const lowerTag = tagName.toLowerCase();
                                const found = tags.filter((tag) => tag.lower.includes(lowerTag));

                                return found?.map((tag) => ({ ...tag, display: tag.label }));
                            },
                            renderSearch: (tag) => {
                                if (!tag) {
                                    return (
                                        <span className="no-results">
                                            <FormattedMessage id={translated.tags.empty} defaultMessage={translated.tags.empty} />
                                        </span>
                                    );
                                }

                                return <span className="result-user-name">{`${tag.label}`}</span>;
                            },
                        },
                    ],
                    supportText: translated.tags.search,
                    filterLinks,
                    resources,
                }}
            />
        </Wrapper>
    );
}

List.defaultProps = {
    setSectionState: () => {
        // Default
    },
    state: {},
};

List.propTypes = {
    data            : PropTypes.shape({}).isRequired,
    sections        : PropTypes.shape({}).isRequired,
    resources       : PropTypes.shape({}).isRequired,
    fetching        : fetchingShape.isRequired,
    reloadData      : PropTypes.func.isRequired,
    setSectionState : PropTypes.func,
    state           : PropTypes.shape({}),
};

List.Loading = function LoadingSkeleton() {
    return (
        <Grid className="margin-top-medium" addMargin="onStackedColumns">
            <Grid.Column width={{ base: 12 }}>
                <Grid>
                    <Grid.Column width={{ base: 6 }}>
                        <Skeleton.Title isHeading width={290} />
                    </Grid.Column>
                    <Grid.Column width={{ base: 6 }} className="text-align-right">
                        <Skeleton.Button />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column width={{ base: 12 }} className="margin-top-xlarge">
                <Skeleton.Table rows={10} hasPagination />
            </Grid.Column>
        </Grid>
    );
};

export default withRequest(List);
