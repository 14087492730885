/* eslint-disable no-unused-vars */
import React, {
    useState, useContext, useRef, useEffect, createRef, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import FormContext from './formContext';
import DefaultButtons from './DefaultButtons';
import Grid from 'Components/Grid';
import getClassName from 'Utils/getClassName';
import PanelContext from 'State/panelContext';
import { analyzeValue } from './Validator';

const FILTER_LOCK_DURATION = 2000;

function resetInputs(rawValues) {
    Object.values(rawValues).forEach((input) => input?.ref?.current?.reset && input.ref.current.reset());
}

/**
 * Manages the state of all children components, maintains an object with the values entered by
 * the user and invokes the function provided for the submit passing this information as arguments,
 * it has all kinds of Input components, as well as Groups, Titles, Columns, a Wrapper and a Summary
 */
function Form({
    className,
    children,
    primaryButton,
    secondaryButton,
    allFieldsRequired,
    buttonsWidth,
    onError,
    initialValues,
    isReadOnly,
    isReadOnlyWithoutInputs,
    onValidationError,
    onSubmit,
    onFinish,
    onChange,
    onReset,
    onClear,
    resetButton,
    isDisabled,
    id,
    avoidConfirmation,
    shouldAvoidResetInitialState,
    avoidSetComponentWithChanges,
    shouldSubmitOnEnter,
    forceValidationsOnFirstRender,
    scrollToError,
}) {
    const { isContextInitialized: isPanelContextInitialized, confirmation, snackbar, navigator } = useContext(PanelContext);
    // The values of the inputs contained by the Form are in inputsData, in the
    // context the same is found but with the necessary format to send it in the
    // submit, and in events we put also in the context the functions
    // that will be executed when making the submit from the PrimaryButton
    // or when the ResetButton is clicked

    const formRef = useRef();
    const lockTimerRef = useRef(null);

    const {
        isContextInitialized,
        submitData,
        setSubmitData,
        setEvents,
        setContextInitialValues,
        setContextInitialSubmitData,
        setIsSubmitting,
        setFormId,
    } = useContext(FormContext);

    const [inputsData, setInputsData] = useState({});
    const [validity, setValidity] = useState({ isIncomplete: false, isInvalid: false, labels: [] });

    // Form initial values, used to reset
    const [formInitialValues, setFormInitialValues] = useState({});
    const [formInitialSubmitData, setFormInitialSubmitData] = useState({});
    const initialFormValues = {};

    const setInitialValues = useCallback(
        (values) => {
            setFormInitialValues(values);
            setContextInitialValues(values);
        },
        [setFormInitialValues, setContextInitialValues],
    );

    const setInitialSubmitData = useCallback(
        (submittedValues) => {
            setFormInitialSubmitData(submittedValues);
            setContextInitialSubmitData(submittedValues);
        },
        [setContextInitialSubmitData, setFormInitialSubmitData],
    );

    // The form becomes as the first render, disabling the buttons (reset, etc)
    const setCurrentValuesAsInitial = useCallback(
        (submittedValues) => {
            const newInitialSubmitData = { ...submittedValues, hasChanged: false };

            // TODO: Join this functions/states to avoid some re-renders
            setInitialValues(newInitialSubmitData);
            setInitialSubmitData(newInitialSubmitData);

            setSubmitData(newInitialSubmitData);

            // Each Input exposes a reset() function witch sets the firstRender variable in 'true'
            resetInputs(newInitialSubmitData.rawValues);
        },
        [setInitialSubmitData, setInitialValues, setSubmitData],
    );

    // Receives an object with the current state and returns a key:value object
    // to be submitted by the form with the information entered by the user
    const getValidatedAndFormattedSubmitData = useCallback(
        (unformattedValues) => {
            const newValidity = { isIncomplete: false, isInvalid: false };

            // We consider only the elements that are being rendered
            const submitValues = Object.values(unformattedValues).filter((val) => val.isRendered);

            // Check if there is some element that is required and not disabled which doesn't have valid values
            const incompleteInputs = submitValues.filter(
                (v) => ((!v.isSelectable && (typeof v.value === 'undefined' || v.value === '' || (Array.isArray(v.value) && !v.value.length)))
                        || (v.isSelectable && v.isSelected && !v.isCompleted)
                        || (!v.isSelectable && !v.isCompleted))
                    && !v.isDisabled
                    && v.isRequired,
            );

            newValidity.labels = incompleteInputs.map((input) => input.label);
            newValidity.isIncomplete = incompleteInputs.length > 0;

            if (submitValues.some((v) => typeof v.isValid !== 'undefined' && !v.isValid && !v.isDisabled)) {
                newValidity.isInvalid = true;
            }

            if (newValidity.isIncomplete || newValidity.isInvalid) {
                setValidity(newValidity);
                if (onValidationError) {
                    onValidationError(newValidity);
                }
            } else if (newValidity.isInvalid !== validity.isInvalid || newValidity.isIncomplete !== validity.isIncomplete) {
                setValidity(newValidity);
            }

            // We convert the array into an object with the correct format to submit
            const formattedValues = Object.keys(unformattedValues).reduce((prev, submitKey) => {
                const {
                    value, isSelectable, isSelected, isRendered, avoidOnSubmit, submitFormat, type,
                } = unformattedValues[submitKey];
                if (avoidOnSubmit) {
                    return prev;
                }

                const splitKey = submitKey.split('.');
                const lastKey = splitKey.pop();
                const updatedValues = { ...prev };
                let current = updatedValues;

                splitKey.forEach((key) => {
                    if (!updatedValues[key]) {
                        updatedValues[key] = {};
                    }
                    current = updatedValues[key];
                });

                // We convert the value.
                let newValue = value && type === 'text' ? String(value).trim() : value;
                if (submitFormat) {
                    newValue = submitFormat(newValue);
                }

                if (isRendered && ((isSelectable && isSelected) || !isSelectable) && newValue !== undefined && newValue !== '') {
                    current[lastKey] = newValue;
                } else {
                    current[lastKey] = null;
                }

                return updatedValues;
            }, {});

            return { values: formattedValues, validity: newValidity };
        },
        [onValidationError, setValidity, validity.isIncomplete, validity.isInvalid],
    );

    // Sets both in the local state and in the context the received values
    const setFormSubmitDataValues = useCallback(
        (newInputsData, hasChanged = false, submitKey) => setInputsData((previousInputsData) => {
            const completeInputData = newInputsData;
            if (submitKey) {
                completeInputData[submitKey] = { ...previousInputsData[submitKey], ...newInputsData[submitKey] };
            }

            const updatedInputsData = { ...previousInputsData, ...completeInputData };

            if (
                isContextInitialized
                    && submitData
                    && hasChanged
                    && !submitData.hasChanged
                    && isPanelContextInitialized
                    && !avoidConfirmation
                    && !avoidSetComponentWithChanges
                    && !isReadOnlyWithoutInputs
                    && !isReadOnly
            ) {
                // Marks the form as changed in the panel context
                navigator.setCurrentSectionComponentWithChanges(true, id);
            }

            const data = getValidatedAndFormattedSubmitData(updatedInputsData);

            const updatedSubmitData = {
                ...data,
                isDisabled,
                rawValues  : updatedInputsData,
                hasChanged : submitData.hasChanged || hasChanged,
            };

            if (!formInitialSubmitData || !Object.keys(formInitialSubmitData).length) {
                // Sets the initial submit data, used in the Reset and in the Form.Subsection to show the summary
                setInitialSubmitData(updatedSubmitData);
            }

            if (isContextInitialized) {
                setSubmitData(updatedSubmitData);
            }

            if (onChange) {
                onChange(updatedSubmitData);
            }

            return updatedInputsData;

            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }),
        [
            isContextInitialized,
            submitData,
            isPanelContextInitialized,
            avoidConfirmation,
            avoidSetComponentWithChanges,
            isReadOnlyWithoutInputs,
            isReadOnly,
            getValidatedAndFormattedSubmitData,
            isDisabled,
            formInitialSubmitData,
            onChange,
            navigator,
            id,
            setInitialSubmitData,
            setSubmitData,
        ],
    );

    const runValidationsOnInputs = useCallback(
        (rawValues = {}, hasChanged = false) => {
            const newInputsData = { ...rawValues };
            let thereIsSomeError = false;
            let firstInputWithError;
            Object.keys(rawValues).forEach((submitKey) => {
                const { isRequired, isSelectable, isSelected, ref } = rawValues[submitKey];

                if (ref && ref.current && (isRequired || forceValidationsOnFirstRender) && ((isSelectable && isSelected) || !isSelectable)) {
                    const { isValid, isCompleted } = ref.current.runValidations();
                    if (!isValid || !isCompleted) {
                        firstInputWithError = firstInputWithError || ref.current.element;
                        thereIsSomeError = true;
                        newInputsData[submitKey] = { ...newInputsData[submitKey], isValid, isCompleted };
                    }
                }
            });

            if (thereIsSomeError) {
                if (firstInputWithError?.current?.scrollIntoView && scrollToError) {
                    firstInputWithError.current.scrollIntoView({
                        behavior : 'smooth',
                        block    : 'center',
                        inline   : 'center',
                    });
                }
                setFormSubmitDataValues(newInputsData, hasChanged);
            }
        },
        [forceValidationsOnFirstRender, scrollToError, setFormSubmitDataValues],
    );

    // This handler and listener found below allows us to submit a form by click enter.
    const handlerEnterClicked = useCallback(
        (e) => {
            if (shouldSubmitOnEnter && e.keyCode === 13) {
                if (lockTimerRef.current) {
                    // The action is locked for a short period of time.
                    return null;
                }

                lockTimerRef.current = setTimeout(async () => {
                    // After the lock timer finishes, it enables the action
                    lockTimerRef.current = null;
                }, FILTER_LOCK_DURATION);

                if (submitData.validity.isIncomplete || submitData.validity.isInvalid) {
                    return runValidationsOnInputs(inputsData);
                }

                const data = getValidatedAndFormattedSubmitData(inputsData);
                onSubmit({ ...data, rawValues: inputsData });
            }

            return null;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [getValidatedAndFormattedSubmitData, shouldSubmitOnEnter, inputsData, onSubmit, runValidationsOnInputs, submitData],
    );

    useEffect(() => {
        if (shouldSubmitOnEnter && formRef?.current) {
            formRef.current.addEventListener('keyup', handlerEnterClicked, false);
        }
        return () => {
            if (shouldSubmitOnEnter && formRef?.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                formRef.current.removeEventListener('keyup', handlerEnterClicked, false);
            }
        };
    }, [handlerEnterClicked, shouldSubmitOnEnter]);

    // In the first load the onSubmit function provided by the user is set in context
    useEffect(() => {
        if (isContextInitialized) {
            setEvents((prev) => ({
                ...prev,
                onReset: (initialInputValues, initialSubmitData) => {
                    if (setInputsData) {
                        setInputsData(initialInputValues);
                    }

                    setSubmitData({ ...initialSubmitData, hasChanged: false });
                    if (isPanelContextInitialized) {
                        navigator.setCurrentSectionComponentWithChanges(false, id);
                    }

                    if (onChange) {
                        onChange(initialSubmitData);
                    }

                    if (onReset) {
                        onReset(initialSubmitData);
                    }

                    resetInputs(initialSubmitData.rawValues);
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onReset, isContextInitialized]);

    useEffect(() => {
        if (isContextInitialized) {
            setEvents((prev) => ({
                ...prev,
                onClear: (currentSubmitData) => {
                    const updatedCurrentSubmitData = { ...currentSubmitData };
                    Object.keys(updatedCurrentSubmitData.values).forEach((input) => {
                        updatedCurrentSubmitData.values[input] = null;
                    });

                    Object.keys(updatedCurrentSubmitData.rawValues).forEach((input) => {
                        updatedCurrentSubmitData.rawValues[input].value = undefined;
                        updatedCurrentSubmitData.rawValues[input].isSelected = false;
                        updatedCurrentSubmitData.rawValues[input].isCompleted = false;
                    });

                    if (setInputsData) {
                        setInputsData(updatedCurrentSubmitData);
                    }

                    setSubmitData({ ...updatedCurrentSubmitData, hasChanged: false });
                    if (isPanelContextInitialized) {
                        navigator.setCurrentSectionComponentWithChanges(false, id);
                    }

                    if (onChange) {
                        onChange(updatedCurrentSubmitData);
                    }

                    if (onClear) {
                        onClear(updatedCurrentSubmitData);
                    }

                    resetInputs(updatedCurrentSubmitData.rawValues);
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClear, isContextInitialized]);

    useEffect(() => {
        if (isContextInitialized) {
            setEvents((prev) => ({
                ...prev,
                onError: (data, rawValues) => {
                    if (onError) {
                        onError(data);
                    }
                    runValidationsOnInputs(rawValues, true);
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onError, isContextInitialized]);

    useEffect(() => {
        if (isContextInitialized) {
            setEvents((prev) => ({
                ...prev,
                onSubmit: async (submittedValues) => {
                    if (!onSubmit) {
                        if (!shouldAvoidResetInitialState) {
                            setCurrentValuesAsInitial(submittedValues);
                        }
                        if (isPanelContextInitialized) {
                            navigator.setCurrentSectionComponentWithChanges(false, id);
                        }
                        return;
                    }
                    try {
                        setIsSubmitting(true);
                        const response = await onSubmit(submittedValues);
                        if (response !== false) {
                            if (!shouldAvoidResetInitialState) {
                                setCurrentValuesAsInitial(submittedValues);
                            }

                            if (isPanelContextInitialized) {
                                // Remove the form of the list of components with changes
                                await navigator.setCurrentSectionComponentWithChanges(false, id);
                            }
                        }

                        if (onFinish) {
                            // We assume that when 'response' is not set, the request has been successful
                            await onFinish(submittedValues, response !== false);
                        }
                    } catch (error) {
                        // Save failed, The implementation of the onSubmit should throw
                        // an FormError() when the user cancels or the submit fails
                        if (error?.name === 'FormError' && isPanelContextInitialized) {
                            snackbar.show({
                                isError     : true,
                                content     : error.snackbarMessage,
                                error       : error.originalError,
                                errorLabels : error.errorLabels,
                            });
                        } else if (onError) {
                            onError(submittedValues);
                        }
                    } finally {
                        setIsSubmitting(false);
                    }
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmit, isContextInitialized]);

    // The key
    useEffect(() => {
        if (forceValidationsOnFirstRender) {
            runValidationsOnInputs(inputsData);
        }

        if (id && setFormId) {
            setFormId(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formClassName = useMemo(
        () => getClassName(
            {
                isReadOnly,
                isReadOnlyWithoutInputs,
                isCompleted : validity.isIncomplete,
                isInvalid   : validity.isInvalid,
            },
            'form',
            className,
        ),
        [className, isReadOnly, isReadOnlyWithoutInputs, validity.isIncomplete, validity.isInvalid],
    );

    // Props that will be passed on to all children
    const globalProps = { isReadOnly, isReadOnlyWithoutInputs, formId: id };
    if (allFieldsRequired) {
        globalProps.isRequired = true;
    }

    // Receives the properties sent by the input that determine the current state of
    // the component: submitKey, isValid, isCompleted, isSelectable. That are saved
    // in the state to later determine how to generate the object that will be submitted
    const handleOnChangeChildren = useCallback(
        async (value, submitKey, properties, markAsChanged, childOnChange) => {
            setFormSubmitDataValues({ [submitKey]: { value, ...properties } }, markAsChanged, submitKey);

            if (childOnChange) {
                childOnChange(value, properties);
            }
        },
        [setFormSubmitDataValues],
    );

    const updateChildrenProps = useCallback(
        async (submitKey, properties, markAsChanged = true) => {
            setFormSubmitDataValues({ [submitKey]: properties }, markAsChanged, submitKey);
        },
        [setFormSubmitDataValues],
    );

    // The elements can have a checkbox with which the user determines
    // if it is active or not, here the states are managed
    const handleOnSelectChildren = useCallback(
        async (isSelected, submitKey) => {
            setFormSubmitDataValues({ [submitKey]: { isSelected } }, true, submitKey);
        },
        [setFormSubmitDataValues],
    );

    // The primary button is optional, if it was set, in the click it will invoke the
    // functions that have been defined to send the information entered by the user
    const handleOnClick = useCallback(() => {
        const data = getValidatedAndFormattedSubmitData(inputsData);
        if (data.validity && !data.validity.isInvalid && !data.validity.isIncomplete) {
            if (onSubmit) {
                onSubmit({ ...data, rawValues: inputsData });
            }
            if (primaryButton.onClick) {
                primaryButton.onClick(data.values);
            }
        } else if (onError) {
            onError(data.validity);
            runValidationsOnInputs(inputsData);
        }
    }, [getValidatedAndFormattedSubmitData, inputsData, onError, onSubmit, primaryButton, runValidationsOnInputs]);

    // The secondary button is optional
    // When clicked, if there is a 'confirmationMessage', it shows a confirmation modal
    const handleOnSecondaryClick = useCallback(() => {
        // Check for changes
        const { onClick, confirmation: buttonConfirmation } = secondaryButton;

        if (onClick) {
            if (!buttonConfirmation || !submitData.hasChanged) {
                onClick();
                return;
            }

            if (isPanelContextInitialized) {
                confirmation.show({
                    title    : buttonConfirmation ? confirmation.title : null,
                    message  : buttonConfirmation ? confirmation.message : null,
                    onAccept : () => onClick(),
                });
            }
        }
    }, [confirmation, isPanelContextInitialized, secondaryButton, submitData.hasChanged]);

    // The reset button is optional.
    // When clicked, if there is a 'confirmationMessage', it shows a confirmation modal.
    const handleOnResetClick = useCallback(() => {
        const { onClick, confirmation: buttonConfirmation } = resetButton;

        if (submitData.hasChanged) {
            if (!buttonConfirmation) {
                setInputsData(formInitialValues);
                if (onClick) {
                    onClick();
                }
                return;
            }

            if (isPanelContextInitialized) {
                confirmation.show({
                    title    : buttonConfirmation ? confirmation.title : null,
                    message  : buttonConfirmation ? confirmation.message : null,
                    onAccept : () => {
                        setInputsData(formInitialValues);
                        if (onClick) {
                            onClick();
                        }
                    },
                });
            }
        }
    }, [confirmation, formInitialValues, isPanelContextInitialized, resetButton, submitData.hasChanged]);

    // Connect the children to the form state
    const addEventsAndPropsToChild = (child, childProperties) => {
        if (child.type === Form.Hidden) {
            return null;
        }

        const { submitKey, onChange: childOnChange } = child.props;

        // eslint-disable-next-line max-len
        const isSelected = inputsData[submitKey] && inputsData[submitKey].isSelected !== undefined ? inputsData[submitKey].isSelected : childProperties.isSelected;
        // NOTE  If isBasedOnState is defined means that value is defined by a state change dispatched for other input and not with it's onChange,
        // so it is necessary use the value set in the value prop and do not take the one's defined by the form state (inputsData[submitKey])

        const { value } = inputsData[submitKey]?.value != null && !childProperties.isBasedOnState && inputsData[submitKey].type !== 'hidden'
            ? inputsData[submitKey]
            : childProperties;

        const childNewProps = {
            ...globalProps,
            value,
            isCompleted   : inputsData[submitKey] && inputsData[submitKey].isCompleted,
            isDisabled    : isReadOnly || isReadOnlyWithoutInputs ? false : childProperties.isDisabled,
            summaryFormat : childProperties.summaryFormat || (inputsData[submitKey] && inputsData[submitKey].summaryFormat),
            submitFormat  : childProperties.submitFormat || (inputsData[submitKey] && inputsData[submitKey].submitFormat),
            onSelect      : (checked) => handleOnSelectChildren(checked, submitKey),
            // When splitting this into 2 lines, lint automatically merge it into a line and then complains about its length -.-
            // eslint-disable-next-line max-len
            onChange      : (val, childSubmitKey, properties, markAsChanged = true) => handleOnChangeChildren(val, childSubmitKey, properties, markAsChanged, childOnChange),
            onPropsChange : updateChildrenProps,
            key           : submitKey,
            isSelected,
        };

        // To avoid a warning in console about using refs in component that shouldn't.
        if (submitKey) {
            childNewProps.ref = inputsData[submitKey]?.ref ? inputsData[submitKey].ref : childProperties.ref;
        }

        if (childProperties.isRequired === false) {
            childNewProps.isRequired = false;
        }

        return React.cloneElement(child, childNewProps);
    };

    // Until we start going through the children we won't know what submitKeys and isSelectable
    // values we're going to need, then in the first render we'll save these values
    const setInitialInputData = (value, submitKey, { isDisabled: inputIsDisabled, type, ...restOfProperties }) => {
        if (!submitKey && !(isReadOnlyWithoutInputs || isReadOnly)) {
            // A submitKey is necessary when the inputs are placed inside a Form
        } else if (initialFormValues[submitKey] === undefined && Object.keys(inputsData).length === 0) {
            initialFormValues[submitKey] = {
                ...restOfProperties,
                // We need to set the values as '' for the input to receive the prop correctly when reset happens
                value       : value != null ? value : '',
                isDisabled  : isDisabled || inputIsDisabled,
                isCompleted : analyzeValue(value, restOfProperties.isMultiSelect, restOfProperties.options).isFullyComplete ? true : undefined,
                isRendered  : true,
                type,
            };
        }
    };

    // Initialization of the values that will be in the context, values are set when the state
    // (inputsData) is still empty, the initialization consists of creating the state indicating
    // which inputs are selectable, which are the submitKeys, which are started with a default value, etc
    const initializeInputData = (submitKey, child) => {
        if (!(isReadOnlyWithoutInputs || isReadOnly)) {
            const {
                type,
                label,
                isSelectable,
                isDisabled: childIsDisabled,
                isRequired,
                isSelected: isSelectedOnChild,
                isBasedOnState,
                avoidOnSubmit,
                avoidOnSummary,
                summaryFormat,
                submitFormat,
                prefix,
                suffix,
                defaultValue,
                summaryIndex,
                isMultiSelect,
                options,
            } = child.props;
            const { isFullyComplete, isPartiallyComplete } = analyzeValue(child.props.value, isMultiSelect, options);

            let value;
            let isSelected = false;

            if (isFullyComplete || isPartiallyComplete) {
                ({ value } = child.props);
                isSelected = isSelectedOnChild != null ? isSelectedOnChild : !!(isFullyComplete && isSelectable);
            } else if (initialValues && analyzeValue(initialValues[submitKey], isMultiSelect, options).isFullyComplete) {
                value = initialValues[submitKey];
                // eslint-disable-next-line max-len
                isSelected = isSelectedOnChild != null ? isSelectedOnChild : !!(analyzeValue(value, isMultiSelect, options).isFullyComplete && isSelectable);
            } else if (defaultValue) {
                value = defaultValue;
            } else if (type === 'hidden' || type === 'dynamicInputs') {
                ({ value } = child.props);
            }

            const initialProperties = {
                ...globalProps,
                type,
                label,
                prefix,
                suffix,
                summaryIndex,
                summaryFormat,
                submitFormat,
                avoidOnSubmit,
                avoidOnSummary,
                isBasedOnState,
                isSelected,
                isSelectable : !!isSelectable,
                isRequired   : isRequired || globalProps.isRequired,
                isDisabled   : isDisabled || childIsDisabled,
            };

            // If we have a Group with radio buttons and no value, we find the value of the first or the selected one
            if (child.type === Form.Group && (type === 'radio' || type === 'chip')) {
                if (typeof value === 'undefined' && !isMultiSelect) {
                    const [firstRadioChildren] = React.Children.toArray(child.props.children);
                    ({ value } = firstRadioChildren.props);
                    initialProperties.optionLabel = firstRadioChildren.props.label;
                } else {
                    React.Children.forEach(child.props.children, (c, i) => {
                        if (value === c.props.value) {
                            initialProperties.optionLabel = c.props.label;
                        }
                    });
                }
            } else if (child.type !== Form.Hidden) {
                // Group and Hidden does not need refs, his values are always validated or 'fine'
                initialProperties.ref = createRef();
            }

            if (inputsData && Object.keys(inputsData).length === 0) {
                setInitialInputData(value, submitKey, initialProperties);
            }

            return { ...initialProperties, value, isCompleted: analyzeValue(value, isMultiSelect, options).isFullyComplete };
        }
        return { value: child.props.value, isDisabled: child.props.isDisabled };
    };

    // If some elements has been deleted or added to the
    // children, we update the isRendered property on each
    const updateRenderedInputsData = async (currentlyRenderedChildren) => {
        if (inputsData && Object.keys(inputsData).length === 0) {
            return;
        }

        let thereAreSomeSubmitKeyDeleted = false;
        let thereAreSomeSubmitKeyAdded = false;

        const updatedRenderedInputsData = {};
        const currentKeys = Object.keys(currentlyRenderedChildren);
        const storedKeys = Object.keys(inputsData);

        // Deleted inputs
        storedKeys.forEach((currentKey) => {
            const isRendered = !!currentKeys.find((k) => k === currentKey);
            if (inputsData[currentKey].isRendered !== isRendered) {
                thereAreSomeSubmitKeyDeleted = true;
                updatedRenderedInputsData[currentKey] = { ...inputsData[currentKey], isRendered };
            }
        });

        // Added inputs
        currentKeys.forEach((currentKey) => {
            if (!storedKeys.find((k) => k === currentKey)) {
                thereAreSomeSubmitKeyAdded = true;
                updatedRenderedInputsData[currentKey] = { ...initializeInputData(currentKey, currentlyRenderedChildren[currentKey]) };
            }
        });

        if (thereAreSomeSubmitKeyDeleted || thereAreSomeSubmitKeyAdded) {
            await setFormSubmitDataValues(updatedRenderedInputsData);
        }
    };

    let childrenAreWrappedInColumns = false;

    // There can be Input type elements, such as column or title type elements, then we see if there are columns,
    // if there are titles and we pass the props necessary to manage the state of the children components
    const connectStateToChildren = (formChildren) => {
        // In currentlyRenderedChildren we save the submitKeys that we are rendering, one of them could be deleted or added
        const currentlyRenderedChildren = {};

        const childrenWithEventsAndProps = React.Children.map(formChildren, (child, index) => {
            if (child) {
                if (child.type === Form.Custom) {
                    // Returns the child as is.
                    return child;
                }

                if (child.type === Form.Title) {
                    // Shows the title labels in the Summary
                    setInitialInputData('', `title_${index}`, {
                        type          : 'title',
                        avoidOnSubmit : true,
                        isRendered    : true,
                        label         : child.props.children,
                        summaryIndex  : child.props.summaryIndex || index,
                    });
                    return child;
                }

                if (child.type === Form.Column) {
                    childrenAreWrappedInColumns = true;
                    const columnProps = child?.props || {};

                    return (
                        <Grid.Column width={columnProps.width || { base: 12 }} className={columnProps.className}>
                            {React.Children.map(child.props.children, (columnChild) => {
                                if (columnChild && columnChild.type !== Form.Title && columnChild.type !== Form.Custom) {
                                    const { props: childProps } = columnChild;
                                    const { submitKey } = childProps;

                                    currentlyRenderedChildren[submitKey] = columnChild;

                                    const childInputData = initializeInputData(submitKey, columnChild);
                                    return addEventsAndPropsToChild(columnChild, childInputData);
                                }
                                return columnChild;
                            })}
                        </Grid.Column>
                    );
                }

                const { submitKey } = child.props;
                currentlyRenderedChildren[submitKey] = child;

                const childInputData = initializeInputData(submitKey, child);
                return addEventsAndPropsToChild(child, childInputData);
            }
            return null;
        });

        // When elements are removed from the Form children we update the isRendered value,
        // so those elements will not be returned on the event calls
        updateRenderedInputsData(currentlyRenderedChildren);

        return childrenWithEventsAndProps;
    };

    // The children props and state are managed by the Form component
    const connectedChildren = connectStateToChildren(children);

    // Only after we collect all the info of the children by mapping them, we set the initial state
    if ((!inputsData || Object.keys(inputsData).length === 0) && Object.keys(initialFormValues).length > 0) {
        setFormSubmitDataValues(initialFormValues);
        setInitialValues(initialFormValues);
    }

    return (
        <div className={formClassName} ref={formRef}>
            {childrenAreWrappedInColumns ? <Grid>{connectedChildren}</Grid> : connectedChildren}
            {(primaryButton || secondaryButton || resetButton) && (
                <DefaultButtons
                    primaryButton={primaryButton ? { ...primaryButton, onClick: handleOnClick } : null}
                    secondaryButton={secondaryButton ? { ...secondaryButton, onClick: handleOnSecondaryClick } : null}
                    resetButton={resetButton ? { ...resetButton, onClick: handleOnResetClick } : null}
                    buttonsWidth={buttonsWidth}
                />
            )}
        </div>
    );
}

Form.defaultProps = {
    className                     : '',
    initialValues                 : null,
    primaryButton                 : null,
    secondaryButton               : null,
    resetButton                   : null,
    onValidationError             : null,
    onSubmit                      : null,
    onFinish                      : null,
    onChange                      : null,
    onError                       : null,
    onReset                       : null,
    onClear                       : null,
    buttonsWidth                  : null,
    isReadOnly                    : false,
    isReadOnlyWithoutInputs       : false,
    allFieldsRequired             : false,
    isDisabled                    : false,
    avoidConfirmation             : false,
    id                            : null,
    shouldAvoidResetInitialState  : false,
    avoidSetComponentWithChanges  : false,
    shouldSubmitOnEnter           : false,
    forceValidationsOnFirstRender : false,
    scrollToError                 : true,
};

Form.propTypes = {
    className                     : PropTypes.string,
    initialValues                 : PropTypes.shape({}),
    isReadOnly                    : PropTypes.bool,
    isReadOnlyWithoutInputs       : PropTypes.bool,
    onValidationError             : PropTypes.func,
    onSubmit                      : PropTypes.func,
    onFinish                      : PropTypes.func,
    onChange                      : PropTypes.func,
    onError                       : PropTypes.func,
    onReset                       : PropTypes.func,
    onClear                       : PropTypes.func,
    allFieldsRequired             : PropTypes.bool,
    avoidConfirmation             : PropTypes.bool,
    children                      : PropTypes.node.isRequired,
    buttonsWidth                  : PropTypes.shape({}),
    isDisabled                    : PropTypes.bool,
    id                            : PropTypes.string,
    shouldAvoidResetInitialState  : PropTypes.bool,
    avoidSetComponentWithChanges  : PropTypes.bool,
    shouldSubmitOnEnter           : PropTypes.bool,
    forceValidationsOnFirstRender : PropTypes.bool,
    scrollToError                 : PropTypes.bool,
    primaryButton                 : PropTypes.shape({
        text      : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        tooltip   : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
    }),
    secondaryButton: PropTypes.shape({
        text         : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        confirmation : PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        tooltip   : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
    }),
    resetButton: PropTypes.shape({
        text         : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        confirmation : PropTypes.shape({
            title   : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            message : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
        variant   : PropTypes.string,
        icon      : PropTypes.string,
        color     : PropTypes.string,
        tooltip   : PropTypes.string,
        onClick   : PropTypes.func,
        isEnabled : PropTypes.bool,
    }),
};

export default Form;
