import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Form, { FormError, FormNew } from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import panelContext from 'State/panelContext';
import SectionsWrapper from 'Components/Sections/Wrapper';
import { sectionType } from 'Constants/types';
import EntityTags from 'Components/Sections/Tags/EntityTags';
import translated from 'Constants/labels/translated';
import yup from 'Utils/yupHelper';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';

const schema = yup.object().shape({
    name     : yup.string().required().max(50),
    currency : yup.number().required(),
    level    : yup.number().integer().min(0).max(999999999),
});

const DEFAULT_CATEGORY = 'Bonus';

function Editor({ data, resources, isEditing, sections }) {
    const { navigator, snackbar } = useContext(panelContext);

    const { links } = data;

    const currencies = resources?.additional?.currencies?.length
        ? resources.additional.currencies.map((each) => ({ value: each.id, content: each.name }))
        : [];

    const handleOnSubmit = async (formValues) => {
        const formValuesConverted = {
            ...formValues,
            id       : isEditing ? data.id : undefined,
            type     : isEditing ? data.type : undefined,
            category : isEditing ? data.category : DEFAULT_CATEGORY,
            level    : formValues.level || 0,
            currency : { id: formValues.currency },
        };

        const reqConfig = {
            data: {
                ...formValuesConverted,
                isActive: typeof data.isActive !== 'undefined' ? data.isActive : true,
            },
            ...resources.available[isEditing ? 'update' : 'create'],
        };

        try {
            await navigator.requestForCurrentPath({ reqConfig });
        } catch (e) {
            throw new FormError(
                isEditing ? translated.balanceTypes.errors.updateError : translated.balanceTypes.errors.createError,
                e,
                translated.balanceTypes.errors,
            );
        }
    };

    const onFinish = () => {
        snackbar.show({ content: isEditing ? translated.balanceTypes.updated : translated.balanceTypes.added, isSuccess: true });
        navigator.goToParentAndReload(false, false);
    };

    const initialValues = useMemo(() => ({
        name     : data?.name || '',
        currency : data?.currency?.id || '',
        level    : data?.level != null ? Number(data.level) : '',
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const canMakeChanges = isEditing ? (!!resources?.available?.update && !data?.readOnly) : !!resources?.available?.create;

    const commonProps = { isDisabled: !canMakeChanges };

    const formButtons = useMemo(
        () => {
            if (canMakeChanges) {
                return [
                    <Form.SecondaryNew variant="text" color="primary" key="fs" onClick={() => navigator.goToRoot()}>
                        <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
                    </Form.SecondaryNew>,
                    <Form.PrimaryNew variant="text" color="primary" key="fp">
                        {isEditing ? (
                            <FormattedMessage id={translated.global.buttons.save} defaultMessage={translated.global.buttons.save} />
                        ) : (
                            <FormattedMessage id={translated.global.buttons.create} defaultMessage={translated.global.buttons.create} />
                        )}
                    </Form.PrimaryNew>,
                ];
            }
            return [
                <Form.SecondaryNew onClick={navigator.goToRoot} key="fs" color="primary">
                    <FormattedMessage id={translated.global.buttons.back} defaultMessage={translated.global.buttons.back} />
                </Form.SecondaryNew>,
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canMakeChanges, isEditing],
    );

    const title = (isEditing && (canMakeChanges ? translated.balanceTypes.updateTitle : translated.balanceTypes.viewTitle))
        || translated.balanceTypes.createTitle;

    return (
        <Form.WrapperNew
            schema={schema}
            initialValues={initialValues}
            formId="balance-types-form"
        >
            <SectionsWrapper
                title={title}
                actionButtons={formButtons}
            >
                <FormNew buttonsWidth={{ base: 12, small: 6 }} onSubmit={handleOnSubmit} onFinish={onFinish}>
                    <Form.ColumnNew width={{ base: 12, small: 6 }}>
                        <Form.InputNew
                            isDense
                            submitKey="name"
                            type="text"
                            label={translated.balanceTypes.labels.name}
                            maxCharCount={50}
                            {...commonProps}
                        />
                        <Form.InputNew
                            isDense
                            submitKey="currency"
                            type="select"
                            label={translated.balanceTypes.labels.currency}
                            options={currencies}
                            {...commonProps}
                            isDisabled={isEditing || commonProps?.isDisabled}
                        />
                        <Form.InputNew
                            isDense
                            submitKey="level"
                            type="number"
                            label={translated.balanceTypes.labels.level}
                            {...commonProps}
                        />
                    </Form.ColumnNew>
                </FormNew>

                {links?.[sections[sectionType.ENTITY_TAGS]?.config?.resourceName] && (
                    <EntityTags
                        {...sections[sectionType.ENTITY_TAGS]}
                        resources={{
                            ...sections[sectionType.ENTITY_TAGS].resources,
                            available: links[sections[sectionType.ENTITY_TAGS].config.resourceName],
                        }}
                        customClass="margin-top-xxlarge"
                    />
                )}
            </SectionsWrapper>
        </Form.WrapperNew>
    );
}

Editor.defaultProps = {};

Editor.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
    sections  : PropTypes.shape({}).isRequired,
    isEditing : PropTypes.bool.isRequired,
};

Editor.Loading = function LoadingSkeleton() {
    return (
        <>
            <Grid className="margin-top-medium margin-bottom-xxlarge">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Title isHeading width={370} />
                </Grid.Column>
                <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                    <Skeleton.Button quantity={2} />
                </Grid.Column>
            </Grid>
            <Grid className="margin-top-small">
                <Grid.Column width={{ base: 12, small: 6 }}>
                    <Skeleton.Form type="input" quantity={3} />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRequest(Editor);
