/* eslint-disable no-else-return */
import React, { useState, useMemo, useCallback, useContext } from 'react';
import Form, { FormError } from 'Components/Form';
import PropTypes from 'prop-types';
import withRequest from 'Components/Sections/withRequest';
import labels from 'Constants/labels';
import { codes } from 'Constants/requestErrors';
import { transactionState } from 'Constants/global';
import { FormattedMessage } from 'react-intl';
import PanelContext from 'State/panelContext';
import translated from 'Constants/labels/translated';

function Modification({ onClose, resources, data }) {
    const MAX_POINTS_AMOUNT = data.pointsConsumed;
    const { confirmation, snackbar, navigator } = useContext(PanelContext);

    const { available } = resources;

    const [bookingCost, setBookingCost] = useState(MAX_POINTS_AMOUNT);
    const [conversionAmount, setConversionAmount] = useState(data.pointsConsumed);

    // Use of Memo avoid re-renders on Form.Wrapper
    const modalPropertiesForForm = useMemo(() => ({
        title     : labels.owners.balance.pointsOperations,
        className : 'points-conversion-modal',
        buttons   : [
            <Form.Secondary key="fs" isDisabled={isLoading} variant="text" color="primary" onClick={() => navigator.goToParentAndReload(false, false)}>
                <FormattedMessage id={translated.global.buttons.cancel} defaultMessage={translated.global.buttons.cancel} />
            </Form.Secondary>,
            <Form.Primary variant="text" color="primary" key="fp">{labels.owners.balance.convert}</Form.Primary>,
        ],
    }));

    const sendRequest = useCallback(async (reqConfig, operation) => {
        try {
            const { state } = await navigator.requestForCurrentPath({ reqConfig, resources });

            if (state === transactionState.CONFIRMED) {
                snackbar.show({ content: labels.owners.balance.conversionSaved, isSuccess: true });
            } else if (available.charge) {
                snackbar.show({ content: labels.owners.balance.chargeSaved, isSuccess: true });
            }
        } catch (e) {
            // Its a conversion, the backend said that the owner has unpaid balances and the retry hasn't been made
            if (
                !reqConfig.data.allowUnpaidBalances
                && (e?.data?.errors?.[0]?.code === codes.futureBalanceUnpaid || e?.data?.errors?.[0]?.code === codes.balanceUnpaid || e?.data?.errors?.[0]?.code === codes.ownerNoReservationAllowed)
            ) {
                if (e?.data?.errors?.[0]?.code === codes.ownerNoReservationAllowed) {
                    return new Promise((resolve, reject) => {
                        // Ask the agent if we should retry the operation, ignoring the no reservation allowed.
                        confirmation.show({
                            message       : labels.owners.balance.ownerNoReservationAllowed,
                            acceptMessage : labels.global.buttons.yes,
                            cancelMessage : labels.global.buttons.no,
                            onAccept      : async () => {
                                try {
                                    await sendRequest({ ...reqConfig, data: { ...reqConfig.data, noReservationAllowed: false } }, operation);
                                    resolve();
                                } catch (er) {
                                    reject(er);
                                }
                            },
                            onCancel: async () => {
                                reject();
                            },
                        });
                    });
                } else {
                    return new Promise((resolve, reject) => {
                        // Ask the agent if we should retry the operation, ignoring the unpaid balances.
                        confirmation.show({
                            message       : labels.owners.balance.balanceUnpaid,
                            acceptMessage : labels.global.buttons.yes,
                            cancelMessage : labels.global.buttons.no,
                            onAccept      : async () => {
                                try {
                                    await sendRequest({ ...reqConfig, data: { ...reqConfig.data, allowUnpaidBalances: true } }, operation);
                                    resolve();
                                } catch (er) {
                                    reject(er);
                                }
                            },
                            onCancel: async () => {
                                reject();
                            },
                        });
                    });
                }
            }
            if (e?.data?.errors?.[0]?.code === codes.errorFundsAmount) {
                setBookingCost(Number(e.data.errors[0].messages?.[2]));
            }
            throw new FormError(labels.owners.balance.cantSaveConversion, e, labels.bookings.errors);
        }

        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available.charge, resources]);

    let minimumPointsAmount = 1;
    let maximumPointsAmount = bookingCost;

    let convertedPoints = null;

    // Summary - Information fot the user about the current conversion, according to selected operation we show the corresponding fields
    // Each conditional adds some information of the current selections to the summary
    let summaryFields = [];

    summaryFields = [...summaryFields, {
        label        : labels.owners.balance.originalAmount,
        value        : data.pointsConsumed,
        key          : 'original-amount',
        summaryIndex : 8,
    }, {
        label        : labels.owners.balance.originalPointsConverted,
        value        : data.pointsConverted,
        key          : 'points-converted',
        summaryIndex : 9,
    }];

    summaryFields = [...summaryFields, {
        label        : labels.owners.balance.minimumPoints,
        value        : data.minimumPoints,
        key          : 'minimum-points',
        summaryIndex : 10,
    }, {
        label        : labels.owners.balance.rate,
        value        : data.ratio,
        key          : 'rate',
        summaryIndex : 11,
    }];
    minimumPointsAmount = data.minimumPoints;
    maximumPointsAmount = data.pointsConsumed;
    if (conversionAmount) {
        convertedPoints = Math.round(parseFloat(data.ratio) * conversionAmount);
        summaryFields = [...summaryFields,
            {
                label        : labels.owners.balance.convertedPoints,
                value        : <span className="text-color-success">{convertedPoints}</span>,
                key          : 'converted-points',
                summaryIndex : 21,
            },
        ];
    }

    const handleOnSubmit = useCallback(async ({ values: { operation, amount, reason, conversionPreference, contract } }) => {
        // Fullfil the config for the request according to the selected operation
        // We don't need to check for the existence of available.allocation/conversion/transfer because if the user
        // has no permissions he will not see the buttons to select that operation, this check is made in the view
        const reqConfig = {
            ...available.create,
            shouldReloadData : true,
            data             : {
                reason              : reason || null,
                conversionRule      : { id: selectedRate.conversionRuleId },
                burn                : conversionPreference,
                feePaymentReference : null,
                contract,
                amount,
            },
        };

        await (sendRequest(reqConfig, operation));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available.create, selectedRate?.conversionRuleId, sendRequest]);

    return (
        <Form.Wrapper modalProperties={modalPropertiesForForm}>
            <Form onSubmit={handleOnSubmit} onFinish={onClose} avoidConfirmation>

                <Form.Input
                    type="textarea"
                    onChange={(val) => setConversionAmount(val)}
                    label={labels.owners.balance.amount}
                    value={data.pointsConsumed}
                    validations={{ minValue: minimumPointsAmount, maxValue: maximumPointsAmount != null ? maximumPointsAmount : MAX_POINTS_AMOUNT, unary: ['numbers'] }}
                    submitKey="amount"
                    suffix={labels.owners.balance.suffixPoints}
                    avoidOnSummary
                    isRequired
                />

            </Form>
            <div className="points-conversion-summary-wrapper">
                <Form.Summary className="points-conversion-summary" extraFields={summaryFields} />
            </div>
        </Form.Wrapper>
    );
}

Modification.propTypes = {
    data      : PropTypes.shape({}).isRequired,
    resources : PropTypes.shape({}).isRequired,
};

export default withRequest(Modification);
