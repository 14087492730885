import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import translated from 'Constants/labels/translated';
import UnSortableContent from './UnSortableContent';
import SortableContent from './SortableContent';
import Header from './Header';
import Tools from './Tools';
import Wrapper from './Wrapper';
import Footer from './Footer';
import Button from 'Components/Button';

const COLLAPSED_PX_HEIGHT = 56;
const PAGINATION_PX_HEIGHT = 56;
const ICON_PX_WIDTH = 40;
const BASE_PX_RIGHT_MARGIN = 32;
const RIGHT_ACTION_ICON_PX = 40;

const getSelectedItems = (checkedElements, items) => {
    const selectedItems = [];
    Object.entries(checkedElements).forEach(([id, checked]) => {
        if (checked) {
            const itemFound = items.find((e) => String(e.id) === id);
            if (itemFound) {
                selectedItems.push(itemFound);
            }
        }
    });

    return selectedItems;
};

function Table(props) {
    const {
        className,
        title,
        items,
        columns,
        onSearch,
        canViewColumns,
        canChangeSettings,
        isPaginationSmall,
        isSortable,
        onSort,
        isSortEnabled,
        fixedColumns,
        minPerPage,
        rows,
        hasTools,
        canCollapse,
        canCheck,
        loadingIds,
        bulkActions,
        bulkActionsDynamic,
        id,
        order,
        onOrder,
        onPaginationClick,
        pagination,
        filterResources,
        currentSortOrder,
        whenEmpty,
        onApplyFilter,
        appliedFilters,
        wrapperRef,
        isSubtable,
        getColumnCustomAttributes,
        headerActions,
        getRowCustomClass,
    } = props;

    const fillObject = (value) => {
        if (!items) {
            return {};
        }

        return items.reduce((obj, each) => ({ ...obj, [each.id]: each.isUncheckable ? false : value }), {});
    };

    const [checkedElements, setCheckedElements] = useState(fillObject(false));
    const [styleConfig, setStyleConfig] = useState({
        startUpdate : false,
        heights     : { collapsed: COLLAPSED_PX_HEIGHT, expanded: 0, prev: 0 },
        actions     : { customStyle: {}, customClassName: '' },
        sortable    : { customStyle: {}, customClassName: '' },
        subTable    : { customStyle: {}, customClassName: '' },
        checkbox    : { customStyle: {}, customClassName: '' },
        columns     : Array(columns.length).fill({ customStyle: {}, customClassName: '' }),
    });
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isDense, setIsDense] = useState(false);
    const [hasFixedColumns, setFixedColumns] = useState(false);
    const [displayColumns, setDisplayColumns] = useState(columns);
    const [fixedColumnsQuantity, setFixedColumnsQuantity] = useState(fixedColumns);
    const [width, setWidth] = useState({ rowWidth: 0, tableWidth: 0 });
    const [usedOrder, setUsedOrder] = useState(null);
    const [usedPagination, setUsedPagination] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [areBulkActionsEnabled, setAreBulkActionsEnabled] = useState(canCheck && !bulkActionsDynamic);

    const tableWrapperRef = useRef();
    const tableContainerRef = useRef();
    const tableRef = useRef();
    const rowRef = useRef();

    useImperativeHandle(wrapperRef, () => ({ width, element: tableWrapperRef }));
    // Update the checkbox selected elements when the amount of items changes
    useEffect(() => {
        if (canCheck && Object.values(checkedElements).length !== items.length) {
            setCheckedElements(fillObject(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canCheck, checkedElements, items, hasFixedColumns]);

    useEffect(() => {
        if (tableWrapperRef.current && rowRef.current) {
            const { width: rowWidth } = rowRef.current.getBoundingClientRect();
            const { width: tableWidth } = tableWrapperRef.current.getBoundingClientRect();

            setWidth({ rowWidth, tableWidth });
            if (rowWidth > tableWidth) {
                setFixedColumns(true);
                setFixedColumnsQuantity(1);
            }
        }
    }, [items]);

    // This useEffect go to previous page when a table is updated and it doesn't have data
    const paginationPreviousLinks = pagination?.links?.previous;
    useEffect(() => {
        setShowAlert(false);
        if (items.length === 0 && paginationPreviousLinks) {
            onPaginationClick(paginationPreviousLinks);
        } else {
            setShowAlert(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length, paginationPreviousLinks]);

    const numberOfCheckedElements = Object.values(checkedElements).filter((el) => el).length;
    let headerCheckedState = '';
    if (numberOfCheckedElements === 0) {
        headerCheckedState = 'unchecked';
    } else {
        headerCheckedState = numberOfCheckedElements === items.filter((i) => !i.isUncheckable).length ? 'checked' : 'indeterminate';
    }

    const wrappedBulkActions = bulkActions.map((bulk) => ({
        key      : bulk.key,
        content  : bulk.content,
        callback : () => bulk.callback(getSelectedItems(checkedElements, items)),
    }));

    const handleOnRowCheck = areBulkActionsEnabled ? (checked, i) => setCheckedElements({ ...checkedElements, [i]: checked }) : null;
    const handleOnHeaderCheck = (checked) => setCheckedElements(fillObject(checked));

    const handleOnFieldCheck = (key, isVisible) => {
        setDisplayColumns(() => {
            const fieldsToShow = displayColumns.filter((field) => field.isVisible);
            if (fieldsToShow.length > 1 || isVisible) {
                return displayColumns.map((element) => (element.key === key ? { ...element, isVisible } : { ...element }));
            }
            return displayColumns;
        });
        setStyleConfig({ ...styleConfig, startUpdate: true });
    };

    const onFixedColumnsChange = (numberOfFixedColumns) => {
        setFixedColumnsQuantity(numberOfFixedColumns);
        setStyleConfig({ ...styleConfig, startUpdate: true });
    };

    /** To avoid re render and infinite loop we not put this variable into internal state */
    const collapsedRows = {};
    /**
     * The component has an array of refs that is updated by this method,
     * informing the moment in which all the refs were completed.
     */
    const updateHeight = (index, isRowCollapsed) => {
        const prevCollapsedValue = collapsedRows[index] ? collapsedRows[index] : null;
        collapsedRows[index] = isRowCollapsed;
        const hasChangedCollapsedValue = prevCollapsedValue !== isRowCollapsed;

        // if the number of saved refs equals the number of children, all rows were mounted
        const areAllRowsRender = collapsedRows.length >= items.length;
        if ((areAllRowsRender || hasChangedCollapsedValue) && tableContainerRef.current) {
            const updatedHeight = tableContainerRef.current.clientHeight
                + (hasTools ? COLLAPSED_PX_HEIGHT : 0)
                + (items && pagination && pagination.total > minPerPage ? PAGINATION_PX_HEIGHT : 0);
            const { collapsed, expanded } = styleConfig.heights;
            if (updatedHeight !== expanded) {
                setStyleConfig({
                    ...styleConfig,
                    startUpdate : true,
                    heights     : { collapsed, expanded: updatedHeight },
                });
            }
        }
    };

    /**
     * After table header and rows are mounted is possible to fix the columns.
     * With the ref of the header row this method calculates and updates the
     * styles of each row.
     */
    const handleOnMountHeader = (headerRowRef) => {
        // Avoid infinite loop of updating state
        if (styleConfig.startUpdate) {
            let updatedStyleConfig = { ...styleConfig, startUpdate: false };
            updatedStyleConfig.columns = displayColumns.map((field, index) => {
                const customClassName = columns[index].customClassName ? columns[index].customClassName : '';
                return { customClassName };
            });

            updatedStyleConfig = {
                ...updatedStyleConfig,
                actions: {
                    customStyle     : { right: '0' },
                    customClassName : '',
                },
                sortable: {
                    customStyle     : { left: '0' },
                    customClassName : '',
                },
                subTable: {
                    customStyle     : { left: '0' },
                    customClassName : '',
                },
                checkbox: {
                    customStyle     : { left: '0' },
                    customClassName : '',
                },
            };

            if (hasFixedColumns) {
                // Each icon has a width of 40 px, so we have to sum each to compute all margin left
                const subTableMargin = isSortable ? ICON_PX_WIDTH : 0;
                const checkboxMargin = subTableMargin + (rows?.subTable ? ICON_PX_WIDTH : 0);
                updatedStyleConfig = {
                    ...updatedStyleConfig,
                    actions: {
                        customStyle     : { right: '0' },
                        customClassName : 'fixed-column',
                    },
                    sortable: {
                        customStyle     : { left: '0' },
                        customClassName : isSortable ? 'fixed-column' : '',
                    },
                    subTable: {
                        customStyle     : { left: `${subTableMargin}px` },
                        customClassName : rows?.subTable ? 'fixed-column' : '',
                    },
                    checkbox: {
                        customStyle     : { left: `${checkboxMargin}px` },
                        customClassName : areBulkActionsEnabled ? 'fixed-column' : '',
                    },
                };
                // Black and esoteric magic for check how fields columns had to
                // fix depending on left buttons using booleans instead of numbers.
                // JS in his maximum splendor
                const leftActionsColumnsCount = isSortable + Boolean(rows?.subTable) + areBulkActionsEnabled;
                const arrayOfColumns = Array.from(headerRowRef.current.children);
                // Computes styles for each field column to fix or not
                updatedStyleConfig.columns = displayColumns.map((field, index) => {
                    const isFixedColumn = index + 1 <= fixedColumnsQuantity;
                    const isLastFixedColumn = index + 1 === fixedColumnsQuantity;
                    const baseClassName = updatedStyleConfig.columns[index].customClassName ? `${updatedStyleConfig.columns[index].customClassName} ` : '';
                    const customClassName = `${baseClassName}${isFixedColumn ? 'fixed-column' : ''} ${isLastFixedColumn ? 'last-fixed-column' : ''}`;
                    const currentColumn = leftActionsColumnsCount + index;
                    const prevColumns = arrayOfColumns.slice(0, currentColumn);
                    // Sums all previous columns width for the current column
                    // only if the current column must be fixed
                    const left = fixedColumnsQuantity ? prevColumns.reduce((accumulator, column) => accumulator + column.clientWidth, 0) : 0;
                    const fieldWidth = field.isVisible && arrayOfColumns[currentColumn] ? arrayOfColumns[currentColumn].clientWidth : 0;
                    const customStyle = { left: `${left}px`, width: `${fieldWidth}px` };
                    return { customClassName, customStyle };
                });
                // Now computes styles for table container to move on left
                // const visibleColumns = leftActionsColumnsCount + fixedColumns;
                // const visible = arrayOfColumns.slice(0, visibleColumns);
                // const marginLeft = visible.reduce((acc, col) => acc + col.clientWidth, 0);
                // Same for right buttons and menu
                const baseMarginRight = BASE_PX_RIGHT_MARGIN;
                let marginRight = 0;
                const outsideActions = rows?.actions?.filter((action) => action.isOutsideDropdown).length || 0;
                marginRight = baseMarginRight + RIGHT_ACTION_ICON_PX * outsideActions;

                updatedStyleConfig.actions = {
                    customClassName : 'fixed-column',
                    customStyle     : { right: '0', width: `${marginRight}px` },
                };
            }
            setStyleConfig(updatedStyleConfig);
        }
    };

    const onSetOrderReference = (orderSet) => {
        setUsedOrder(orderSet);
    };

    const onSetPaginationReference = (paginationSet) => {
        setUsedPagination(paginationSet);
    };

    const isDenseClass = isDense ? 'is-dense' : '';
    const isFixedClass = hasFixedColumns ? 'is-fixed' : '';

    if (!items?.length && !appliedFilters?.filters && !appliedFilters?.reset && showAlert) {
        if (whenEmpty) {
            return whenEmpty;
        }
    }

    const enableBulkActionsButton = canCheck && bulkActionsDynamic ? (
        <Button
            id={`${id}-dynamic-bulk-actions-button`}
            key={`${id}-dynamic-bulk-actions-button`}
            variant="text"
            color="primary"
            size="small"
            className="table-selected-items margin-left-small"
            onClick={() => {
                setAreBulkActionsEnabled((prev) => !prev);
            }}
        >
            {areBulkActionsEnabled ? (
                <FormattedMessage
                    id={translated.global.table.buttons.edition.disable}
                    defaultMessage={translated.global.table.buttons.edition.disable}
                />
            ) : (
                <FormattedMessage
                    id={translated.global.table.buttons.edition.enable}
                    defaultMessage={translated.global.table.buttons.edition.enable}
                />
            )}
        </Button>
    ) : null;

    return (
        <Table.Wrapper
            className={className}
            canCollapse={canCollapse}
            isCollapsed={isCollapsed}
            style={{ height: `${isCollapsed ? styleConfig.heights.collapsed : styleConfig.heights.expanded}px` }}
            wrapperForwardedRef={tableWrapperRef}
        >
            {hasTools && (
                <Tools
                    tableId={id}
                    key={`${id}-tools`}
                    title={title}
                    fields={displayColumns}
                    bulkActions={wrappedBulkActions}
                    handleOnFieldCheck={handleOnFieldCheck}
                    numberOfCheckedElements={numberOfCheckedElements}
                    hasFixedColumns={hasFixedColumns}
                    fixedColumns={fixedColumnsQuantity}
                    filterResources={filterResources}
                    appliedFilters={appliedFilters}
                    usedOrder={usedOrder}
                    usedPagination={usedPagination}
                    isCollapsed={isCollapsed}
                    isDense={isDense}
                    canCollapse={canCollapse}
                    canViewColumns={canViewColumns}
                    canChangeSettings={canChangeSettings}
                    setIsCollapsed={setIsCollapsed}
                    setIsDense={setIsDense}
                    setFixedColumns={setFixedColumns}
                    onFilter={onSearch}
                    onFixedColumnsChange={onFixedColumnsChange}
                    onApplyFilter={onApplyFilter}
                    canCheck={areBulkActionsEnabled}
                    enableBulkActionsButton={enableBulkActionsButton}
                    headerActions={headerActions}
                />
            )}
            <div ref={tableContainerRef} className={`table-container ${isFixedClass}`}>
                <table id={id} className={`table ${isDenseClass}`} ref={tableRef}>
                    <Header
                        id={`${id}-header`}
                        tableId={id}
                        items={displayColumns}
                        hasActions={rows?.actions?.length > 0}
                        canCheck={areBulkActionsEnabled}
                        onCheck={handleOnHeaderCheck}
                        onSetOrder={onSearch}
                        currentSortOrder={currentSortOrder}
                        checked={headerCheckedState}
                        hasSubTable={!!rows?.subTable}
                        isSortable={isSortable}
                        handleOnMount={handleOnMountHeader}
                        styleConfig={styleConfig}
                        order={order}
                        onOrder={onOrder}
                        onSetOrderReference={onSetOrderReference}
                        appliedFilters={appliedFilters}
                    />
                    {appliedFilters?.filters && items.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan="100" className="text-align-center font-style-italic text-color-secondary">
                                    <FormattedMessage id={translated.global.table.empty} defaultMessage={translated.global.table.empty} />
                                </td>
                            </tr>
                        </tbody>
                    ) : null}
                    {isSortable ? (
                        <SortableContent
                            key={`${id}-sortable-content`}
                            tableId={id}
                            title={title}
                            items={items}
                            displayColumns={displayColumns}
                            actions={rows?.actions}
                            handleOnRowCheck={handleOnRowCheck}
                            checkedElements={checkedElements}
                            updateOnCollapse={updateHeight}
                            subTable={rows?.subTable}
                            collapsedRows={collapsedRows}
                            onSort={onSort}
                            styleConfig={styleConfig}
                            loadingIds={loadingIds}
                            isSortEnabled={isSortEnabled}
                            rowForwardedRef={rowRef}
                            isSubtable={isSubtable}
                            getColumnCustomAttributes={getColumnCustomAttributes}
                            getRowCustomClass={getRowCustomClass}
                        />
                    ) : (
                        <UnSortableContent
                            key={`${id}-un-sortable-content`}
                            tableId={id}
                            title={title}
                            items={items}
                            displayColumns={displayColumns}
                            actions={rows?.actions}
                            handleOnRowCheck={handleOnRowCheck}
                            checkedElements={checkedElements}
                            updateOnCollapse={updateHeight}
                            subTable={rows?.subTable}
                            collapsedRows={collapsedRows}
                            styleConfig={styleConfig}
                            loadingIds={loadingIds}
                            rowForwardedRef={rowRef}
                            isSubtable={isSubtable}
                            getColumnCustomAttributes={getColumnCustomAttributes}
                            getRowCustomClass={getRowCustomClass}
                        />
                    )}
                </table>
            </div>
            {items && pagination && pagination.total > minPerPage && (
                <Footer
                    id={`${id}-footer`}
                    onChange={onSearch}
                    pagination={pagination}
                    onPaginationClick={onPaginationClick}
                    isPaginationSmall={isPaginationSmall}
                    onSetPaginationReference={onSetPaginationReference}
                />
            )}
        </Table.Wrapper>
    );
}

Table.Wrapper = Wrapper;

Table.defaultProps = {
    className : '',
    title     : '',
    onSearch  : () => {
        // Default
    },
    rows               : null,
    minPerPage         : 10,
    canCheck           : false,
    bulkActionsDynamic : false,
    headerActions      : [],
    canCollapse        : false,
    canViewColumns     : false,
    canChangeSettings  : false,
    hasTools           : true,
    isSortable         : false,
    isSortEnabled      : false,
    isPaginationSmall  : false,
    onSort             : () => {
        // Default
    },
    fixedColumns              : 0,
    loadingIds                : [],
    items                     : [],
    bulkActions               : [],
    order                     : null,
    onOrder                   : null,
    pagination                : null,
    onPaginationClick         : null,
    filterResources           : null,
    currentSortOrder          : 'id',
    whenEmpty                 : null,
    wrapperRef                : null,
    isSubtable                : false,
    onApplyFilter             : null,
    appliedFilters            : null,
    getColumnCustomAttributes : null,
    getRowCustomClass         : null,
};

Table.propTypes = {
    id                        : PropTypes.string.isRequired,
    className                 : PropTypes.string,
    title                     : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    items                     : PropTypes.arrayOf(PropTypes.shape({})),
    columns                   : PropTypes.arrayOf(PropTypes.shape({ customClassName: PropTypes.string })).isRequired,
    getColumnCustomAttributes : PropTypes.func,
    getRowCustomClass         : PropTypes.func,
    rows                      : PropTypes.shape({
        actions  : PropTypes.arrayOf(PropTypes.shape({})),
        subTable : PropTypes.shape({}),
    }),
    bulkActions: PropTypes.arrayOf(
        PropTypes.shape({
            content  : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            callback : PropTypes.func,
        }),
    ),
    headerActions      : PropTypes.arrayOf(PropTypes.shape({})),
    loadingIds         : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    fixedColumns       : PropTypes.number,
    onSearch           : PropTypes.func,
    onSort             : PropTypes.func,
    minPerPage         : PropTypes.number,
    canViewColumns     : PropTypes.bool,
    canChangeSettings  : PropTypes.bool,
    hasTools           : PropTypes.bool,
    canCheck           : PropTypes.bool,
    bulkActionsDynamic : PropTypes.bool,
    canCollapse        : PropTypes.bool,
    isSortable         : PropTypes.bool,
    isSortEnabled      : PropTypes.bool,
    isPaginationSmall  : PropTypes.bool,
    order              : PropTypes.shape({}),
    onOrder            : PropTypes.func,
    pagination         : PropTypes.shape({ total: PropTypes.number }),
    onPaginationClick  : PropTypes.func,
    filterResources    : PropTypes.shape({}),
    currentSortOrder   : PropTypes.string,
    whenEmpty          : PropTypes.element,
    wrapperRef         : PropTypes.shape({}),
    isSubtable         : PropTypes.bool,
    onApplyFilter      : PropTypes.func,
    appliedFilters     : PropTypes.shape({}),
};

export default React.memo(Table);
