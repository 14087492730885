import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Card from 'Components/Card';
import translated from 'Constants/labels/translated';
import Grid from 'Components/Grid';
import Skeleton from 'Components/Skeletons';
import SummaryRangeField from './SummaryRangeField';
import { transformToSortList } from './utils';

function Summary({ title, openConfiguration, className }) {
    const { watch } = useFormContext();
    const ranges = watch('ranges');
    const list = transformToSortList(ranges);

    return (
        <Card
            id="dynamic-ranges-summary"
            key="dynamic-ranges-summary"
            className={className}
            unelevated
            title={title}
            primaryButton={
                openConfiguration
                    ? {
                        id      : 'dynamic-ranges-summary-button',
                        text    : translated.rules.dynamicRanges.summary.configure,
                        onClick : () => openConfiguration(),
                    }
                    : null
            }
        >
            { list.map((range) => <SummaryRangeField key={range.id} range={range} />) }
        </Card>
    );
}

Summary.defaultProps = {
    className         : '',
    openConfiguration : null,
};

Summary.propTypes = {
    className         : PropTypes.string,
    title             : PropTypes.string.isRequired,
    openConfiguration : PropTypes.func,
};

Summary.Loading = function LoadingSkeleton() {
    return (
        <Grid addMargin="onStackedColumns" className="margin-top-medium margin-bottom-xxlarge">
            <Grid.Column width={{ base: 12, small: 6 }}>
                <Skeleton.Title isHeading />
            </Grid.Column>
            <Grid.Column width={{ base: 12, small: 6 }} className="text-align-right">
                <Skeleton.Button quantity={2} />
            </Grid.Column>
        </Grid>
    );
};
export default Summary;
