import PropTypes from 'prop-types';

export const sectionPropTypes = {
    name       : PropTypes.string.isRequired,
    resources  : PropTypes.shape({}).isRequired,
    data       : PropTypes.shape({}),
    error      : PropTypes.shape({}),
    isEnabled  : PropTypes.bool.isRequired,
    isSelected : PropTypes.bool.isRequired,
    isEmbedded : PropTypes.bool.isRequired,
    fetching   : PropTypes.shape({
        ids      : PropTypes.instanceOf(Array).isRequired,
        isGlobal : PropTypes.bool.isRequired,
    }).isRequired,
    state  : PropTypes.shape({ hasNotifications: PropTypes.bool.isRequired }),
    config : PropTypes.shape({
        apiName              : PropTypes.string,
        url                  : PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        getDataMethod        : PropTypes.string,
        parameterName        : PropTypes.string,
        isDefaultSection     : PropTypes.bool,
        isHiddenInBreadcrumb : PropTypes.bool,
        parameters           : PropTypes.shape({}),
    }),
    sections: PropTypes.shape({}).isRequired,
};

export const colWidthShape = {
    base       : PropTypes.number,
    small      : PropTypes.number,
    medium     : PropTypes.number,
    large      : PropTypes.number,
    extraLarge : PropTypes.number,
};

export const fetchingShape = PropTypes.shape({
    ids      : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    isGlobal : PropTypes.bool.isRequired,
});
