import React from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/Table';
import translated from 'Constants/labels/translated';

function CurrentBalance({ bulkActions, items, id }) {
    return (
        <Table
            canCheck
            title={translated.owners.balance.clubPoints.title}
            id={id}
            key={id}
            items={items}
            bulkActions={bulkActions}
            columns={[
                {
                    title           : translated.owners.balance.clubPoints.pointsType,
                    key             : 'pointsType',
                    isVisible       : true,
                    showTooltipWhen : 20,
                    customClassName : 'text-overflow',
                },
                {
                    title     : translated.owners.balance.clubPoints.leftShoulder,
                    key       : 'shoulderLeft',
                    isVisible : true,
                    isDate    : true,
                },
                {
                    title     : translated.owners.balance.clubPoints.date,
                    key       : 'effectiveDate',
                    isVisible : true,
                    isDate    : true,
                },
                {
                    title     : translated.owners.balance.clubPoints.expiryDate,
                    key       : 'expiryDate',
                    isVisible : true,
                    isDate    : true,
                },
                {
                    title     : translated.owners.balance.clubPoints.rightShoulder,
                    key       : 'shoulderRight',
                    isVisible : true,
                    isDate    : true,
                },
                {
                    title     : translated.owners.balance.clubPoints.contract,
                    key       : 'legacyContractId',
                    isVisible : true,
                },
                {
                    title       : translated.owners.balance.clubPoints.balanceType,
                    key         : 'balanceType.level',
                    isVisible   : true,
                    fieldFormat : (data) => ({ value: String(data.balanceType.level) }),
                },
                {
                    title       : translated.owners.balance.balanceTotals.currency,
                    key         : 'balanceType.currency.name',
                    isVisible   : true,
                    fieldFormat : (data) => ({ value: data.balanceType.currency.name }),
                },
                {
                    title     : translated.owners.balance.clubPoints.points,
                    key       : 'amount',
                    isVisible : true,
                    isNumeric : true,
                },
                {
                    title     : translated.owners.balance.clubPoints.balance,
                    key       : 'balance',
                    isVisible : true,
                    isNumeric : true,
                },
            ]}
            rows={{
                actions  : [],
                subTable :
                    // For some reason when the table have a subTable but the elements have no child, the balance bulk breaks
                    items?.find((e) => e.children?.length)
                        ? {
                            id      : 'children',
                            key     : 'children',
                            rows    : { actions: [] },
                            columns : [
                                {
                                    title     : translated.owners.balance.clubPoints.pointsType,
                                    key       : 'id',
                                    isVisible : true,
                                },
                                {
                                    title     : translated.owners.balance.clubPoints.points,
                                    key       : 'amount',
                                    isVisible : true,
                                    isNumeric : true,
                                },
                                {
                                    title     : translated.owners.balance.clubPoints.balance,
                                    key       : 'balance',
                                    isVisible : true,
                                    isNumeric : true,
                                },
                            ],
                        }
                        : null,
            }}
        />
    );
}

CurrentBalance.displayName = 'CurrentBalance';

CurrentBalance.defaultProps = {
    bulkActions : [],
    items       : [],
    id          : 'balance',
};

CurrentBalance.propTypes = {
    bulkActions : PropTypes.arrayOf(PropTypes.shape({})),
    items       : PropTypes.arrayOf(PropTypes.shape({})),
    id          : PropTypes.string,
};

export default React.memo(CurrentBalance);
