import { hot } from 'react-hot-loader/root';
import React, { useState } from 'react';
import Notifications from 'Components/Notifications';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import translated from 'Constants/labels/translated';

function User({ notification, hasCall }) {
    const [isRingMuted, toggleIsRingMuted] = useState(false);

    let phoneIcon = '';
    let phoneTooltip = '';
    if (isRingMuted) {
        phoneIcon = 'PhoneOffOutline';
        phoneTooltip = translated.settings.buttons.unmute;
    }
    if (!isRingMuted) {
        phoneIcon = 'PhoneOutline';
        phoneTooltip = translated.settings.buttons.mute;
    }
    if (hasCall && !isRingMuted) {
        phoneIcon = 'PhoneInTalkOutline';
    }

    const onPhoneClick = () => {
        toggleIsRingMuted((prev) => !prev);
    };

    return (
        <div id="user-wrapper" className="user-wrapper">
            <div className="user-data-wrapper">
                {hasCall && !isRingMuted && (
                    <audio src="/static/img/ring.wav" type="audio/wav" autoPlay className="hidden">
                        <track kind="captions" />
                    </audio>
                )}
                <Button
                    id="call-sound"
                    className={`user-call-button ${hasCall ? 'has-call animated swing' : ''}`}
                    icon={phoneIcon}
                    tooltip={phoneTooltip}
                    onClick={onPhoneClick}
                />
                <Notifications {...notification} />
            </div>
        </div>
    );
}

User.defaultProps = { hasCall: null };

User.propTypes = {
    notification : PropTypes.shape({}).isRequired,
    hasCall      : PropTypes.bool,
};

export default hot(User);
